export const DASHBOARD_ENDPOINT = {
  getUserCountListUrl: {
    url: '/v1/admin/dashboard/user-count',
    method: 'GET',
  },
  getProviderRatingListsUrl: {
    url: '/v1/admin/dashboard/provider-rating',
    method: 'GET',
  },
  getProviderCountListUrl: {
    url: '/v1/admin/dashboard/provider-count',
    method: 'GET',
  },
  getEmergencyServiceDetailsUrl: {
    url: '/v1/admin/dashboard/emergency-service',
    method: 'GET',
  },
  getRegisteredProviderListUrl: {
    url: '/v1/admin/dashboard/register-provider',
    method: 'GET',
  },
  getSalesDetailsUrl: {
    url: '/v1/admin/dashboard/sales',
    method: 'GET',
  },
  getServiceCategoryListUrl: {
    url: '/v1/admin/dashboard/service-category',
    method: 'GET',
  },
  getBookingEstimatesDetailsUrl: {
    url: '/v1/admin/dashboard/booking-estimates',
    method: 'GET',
  },
  getAvailableProviderDetailsUrl: {
    url: '/v1/admin/dashboard/available-provider',
    method: 'GET',
  },
  getPromoCodeListsUrl: {
    url: '/v1/admin/dashboard/promo-code',
    method: 'GET',
  },
  getProviderSalesDetailsUrl: {
    url: '/v1/admin/dashboard/provider-sales',
    method: 'GET',
  },
  getInvoiceTotalDetailsUrl: {
    url: '/v1/admin/dashboard/invoice-total',
    method: 'GET',
  },
  getSalesGraphDetailsUrl: {
    url: '/v1/admin/dashboard/sales-graph',
    method: 'GET',
  },
  getHeatMapListUrl: {
    url: '/v1/admin/dashboard/heat-map',
    method: 'GET',
  },
  getServiceCategoryFilterListUrl: {
    url: '/v1/admin/dashboard/service-category-list',
    method: 'GET',
  },
  getBookingEstimatesListUrl: {
    url: '/v1/admin/booking-estimates',
    method: 'GET',
  },
  getOnlineProviderList: {
    url: '/v1/admin/dashboard/available-provider',
    method: 'GET',
  },
};
