import { APIRequest } from '../../helpers/index.helper';
import { LOCALIZATION_ENDPOINT } from '../../apiEndpoints/Localization/index.endpoint';
import { logger } from '../../utils';

export const ListCityService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...LOCALIZATION_ENDPOINT.getCityApi,
      queryParams,
      // isAdminToken: true,
    };

    const res = await APIRequest(payload);
    if (type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const addCityService = async ({ body }) => {
  try {
    const payload = {
      ...LOCALIZATION_ENDPOINT.addCity,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const editCityService = async ({ body, id }) => {
  try {
    const payload = {
      ...LOCALIZATION_ENDPOINT.editCity(id),
      bodyData: body,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const deleteCityService = async ({ bodyData }) => {
  try {
    const payload = {
      ...LOCALIZATION_ENDPOINT.deleteCity,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const getCityByIdService = async ({ resourceId }) => {
  try {
    const payload = {
      ...LOCALIZATION_ENDPOINT.getCityById(resourceId),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const updateCityStatus = async (body) => {
  try {
    const payload = {
      ...LOCALIZATION_ENDPOINT.updateCityStatus,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
