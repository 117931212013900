export const VARIANT_MANAGEMENT_ENDPOINT = {
  getVariantList: {
    url: 'v1/admin/all-variant',
    method: 'GET',
  },

  addVariant: {
    url: 'v1/variant',
    method: 'POST',
  },
  changeVariantStatus: (id) => ({
    url: `v1/variant-status-change/${id}`,
    method: 'PUT',
  }),
  deleteVariant: (id) => ({
    url: `v1/variant/${id}`,
    method: 'DELETE',
  }),
  editVariant: (id) => ({
    url: `v1/variant-detail-update/${id}`,
    method: 'PUT',
  }),
  getVariantDetails: (id) => ({
    url: `v1/admin/variant/${id}`,
    method: 'GET',
  }),
};
