import { MANAGE_DOCUMENT_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';

export const manageDocumentService = async ({ queryParams }) => {
  try {
    const payload = {
      ...MANAGE_DOCUMENT_ENDPOINT?.getDocumentList,
      queryParams,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const documentDeleteService = async ({ bodyData }) => {
  try {
    const payload = {
      ...MANAGE_DOCUMENT_ENDPOINT?.deleteDocument,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const addDocumentService = async ({ bodyData }) => {
  try {
    const payload = {
      ...MANAGE_DOCUMENT_ENDPOINT.addDocument,
      bodyData,
    };
    return await APIRequest(payload);
  } catch (err) {
    logger(err);
  }
};

export const editDocumentService = async ({ id, bodyData }) => {
  try {
    const payload = {
      ...MANAGE_DOCUMENT_ENDPOINT.editDocument(id),
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const updateDocumentStatusService = async (bodyData) => {
  try {
    const payload = {
      ...MANAGE_DOCUMENT_ENDPOINT.documentStatusUpdate,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getDocumentDetailsService = async (id) => {
  try {
    const payload = {
      ...MANAGE_DOCUMENT_ENDPOINT?.getDocumentDetails(id),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

// get provider document
export const getProviderDocumentService = async (queryParams) => {
  try {
    const payload = {
      ...MANAGE_DOCUMENT_ENDPOINT?.getDocumentList,
      queryParams,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
