import { TipsOfMonth } from '../../views/CustomerApp';

export default function route() {
  return [
    {
      path: accessRoute.Tip.path,
      private: true,
      name: accessRoute.Tip.name,
      key: accessRoute.Tip.key,
      belongsToSidebar: true,
      icon: accessRoute.Tip.icon,
      element: <TipsOfMonth />,
    },
  ];
}

export const accessRoute = {
  Tip: {
    path: '/customer/tips',
    name: 'Tips of the Month',
    key: 'tip_of_the_month',
    for: 'customer',
    icon: <em className="gg-month"></em>,
  },
};

export const getChildrenRoutes = () => {
  return route();
};
