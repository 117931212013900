// import { Outlet } from 'react-router-dom';
import CompanyProfileCancel from '../../views/Auth/Register/NCA/CompanyProfileCancel';
import {
  BankDetails,
  CompanyProfileOne,
  CompanyProfileTwo,
  // CreateProfile,
  BusinessType,
  SuccessRegisterMessage,
  // CancelNcaService,
} from '../../views/index.view';

export default function route() {
  return [
    {
      path: accessRegisterRoute.COMPANY_PROFILE_ONE.path,
      private: true,
      name: 'Company Profile One',
      key: accessRegisterRoute.COMPANY_PROFILE_ONE.path,
      belongsToSidebar: false,
      ignorePermission: true,
      icon: accessRegisterRoute.COMPANY_PROFILE_ONE.icon,
      element: <CompanyProfileOne />,
    },
    {
      path: accessRegisterRoute.COMPANY_PROFILE_TWO.path,
      private: true,
      name: 'Company Profile Two',
      key: accessRegisterRoute.COMPANY_PROFILE_TWO.path,
      belongsToSidebar: false,
      ignorePermission: true,
      icon: accessRegisterRoute.COMPANY_PROFILE_TWO.icon,
      element: <CompanyProfileTwo />,
    },
    {
      path: accessRegisterRoute.BUSINESS_TYPE.path,
      private: true,
      name: 'Business Type',
      key: accessRegisterRoute.BUSINESS_TYPE.path,
      belongsToSidebar: false,
      ignorePermission: true,
      icon: accessRegisterRoute.BUSINESS_TYPE.icon,
      element: <BusinessType />,
    },
    {
      path: accessRegisterRoute.BANK_DETAILS.path,
      private: true,
      name: 'Bank Details',
      key: accessRegisterRoute.BANK_DETAILS.path,
      belongsToSidebar: false,
      ignorePermission: true,
      icon: accessRegisterRoute.BANK_DETAILS.icon,
      element: <BankDetails />,
    },
    {
      path: accessRegisterRoute.SUCCESS_REGISTER_MESSAGE.path,
      private: true,
      ignorePermission: true,
      name: 'Success Register Message',
      key: accessRegisterRoute.SUCCESS_REGISTER_MESSAGE.path,
      belongsToSidebar: false,
      element: <SuccessRegisterMessage />,
    },
    {
      path: accessRegisterRoute.CANCEL_COMPANY_PROFILE.path,
      private: true,
      ignorePermission: true,
      name: 'Company Profile Cancel',
      key: accessRegisterRoute.CANCEL_COMPANY_PROFILE.path,
      belongsToSidebar: false,
      element: <CompanyProfileCancel />,
    },
  ];
}

export const accessRegisterRoute = {
  COMPANY_PROFILE_ONE: {
    path: '/nca/register-company-step-one',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
  },
  COMPANY_PROFILE_TWO: {
    path: '/nca/register-company-step-two',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
  },
  BUSINESS_TYPE: {
    path: '/nca/register-business-type',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
  },
  BANK_DETAILS: {
    path: '/nca/register-bank-details',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
  },
  SUCCESS_REGISTER_MESSAGE: {
    path: '/nca/successfully-register',
  },
  CANCEL_COMPANY_PROFILE: {
    path: '/nca/company-profile-role-assign',
  },
};

export const getRegisterRoutes = () => {
  return route();
};
