import React from 'react';
import { useParams } from 'react-router-dom';
import { EditServiceCategory } from '../../../PriceManagement';

function EditNCACategory() {
  const { userId } = useParams();
  return <EditServiceCategory userId={userId} />;
}

export default EditNCACategory;
