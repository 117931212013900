import { Checkbox } from 'antd';
import React, { useState, useEffect } from 'react';
import { MakeProviderFavorite } from '../../services/CustomerApp/index.service';
import { logger, modalNotification } from '../../utils';
import GlobalLoader from '../UIElements/GlobalLoader';

const Favorite = ({ isFavorite, userId }) => {
  const [favLoader, setFavLoader] = useState();
  const [favorite, setFavorite] = useState();
  const onCheckboxChange = async () => {
    try {
      setFavLoader(true);
      let bodyData = {};
      bodyData.providerUserId = userId;
      const { success, message, data } = await MakeProviderFavorite({
        bodyData,
      });
      if (success) {
        if (data === 1) {
          modalNotification({
            type: 'success',
            message: 'Unfavorite provider successfully',
          });
          setFavorite(false);
        } else {
          setFavorite(true);
          modalNotification({
            type: 'success',
            message,
          });
        }
      }
      setFavLoader(false);
    } catch (error) {
      logger(error);
    }
    setFavLoader(false);
  };

  useEffect(() => {
    setFavorite(isFavorite);
  }, [isFavorite]);

  return (
    <Checkbox checked={favorite} onChange={onCheckboxChange}>
      {favLoader ? (
        <GlobalLoader />
      ) : (
        <em className="appointmentList_like_icon gg-heart-outline" />
      )}
    </Checkbox>
  );
};

export default Favorite;
