import {
  SHOPPING_CART_ENDPOINT,
  UPLOAD_BULK_PRODUCT_ENDPOINT,
} from '../../apiEndpoints/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';

export const getAllVariantListService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...SHOPPING_CART_ENDPOINT?.getAllVariantList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getAllCategoryListService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...SHOPPING_CART_ENDPOINT?.getCategoryList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const addProductService = async (bodyData) => {
  try {
    const payload = {
      ...SHOPPING_CART_ENDPOINT?.addProduct,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getProductVariantAttributeService = async (id) => {
  try {
    const payload = {
      ...SHOPPING_CART_ENDPOINT?.getVariantAttributeDetail(id),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getProductListService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...SHOPPING_CART_ENDPOINT?.getProductList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const deleteProductService = async ({ bodyData }) => {
  const { productId } = bodyData;
  try {
    const payload = {
      ...SHOPPING_CART_ENDPOINT.deleteProduct(productId[0]),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const updateProductStatusService = async (bodyData) => {
  const { productId } = bodyData;
  delete bodyData.productId;
  try {
    const payload = {
      ...SHOPPING_CART_ENDPOINT.statusProduct(productId[0]),
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getProductDetailsService = async (id) => {
  try {
    const queryParams = {};
    const payload = {
      ...SHOPPING_CART_ENDPOINT?.getProductDetails(id),
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const editProductService = async (id, bodyData) => {
  try {
    const payload = {
      ...SHOPPING_CART_ENDPOINT?.editProduct(id),
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const downloadDemoProductFileService = async () => {
  try {
    const payload = {
      ...SHOPPING_CART_ENDPOINT?.downloadSampleFile,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const uploadBulkProductFileService = async ({ bodyData }) => {
  try {
    const payload = {
      ...SHOPPING_CART_ENDPOINT?.uploadBulkProductFile,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const uploadBulkProductImageService = async ({ bodyData }) => {
  try {
    const payload = {
      ...SHOPPING_CART_ENDPOINT?.uploadBulkProductImages,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const listViewProductImageService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...SHOPPING_CART_ENDPOINT.getProductViewImageList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const deleteViewProductImageService = async ({ id }) => {
  try {
    const payload = {
      ...SHOPPING_CART_ENDPOINT.deleteProductViewImage(id),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};


export const importProductService = async ({ body }) => {
  const data ={
    basePath :body.file
  }
  try {
    const payload = {
      ...SHOPPING_CART_ENDPOINT.importProductItems,
      bodyData: data,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};


export const saveImportProductService = async ({ body }) => {
  try {
    const payload = {
      ...SHOPPING_CART_ENDPOINT.saveImport,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};


export const importFileProductListService = async (queryParams) => {
  try {
    const payload = {
      ...SHOPPING_CART_ENDPOINT.getImportFileDetails,
      queryParams,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

// Discard the all change of
export const discardImportProductService = async ({ body }) => {
  try {
    const payload = {
      ...SHOPPING_CART_ENDPOINT.discardImport,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};