export const text = {
  title: 'Request Details',
  servicesDetails: 'Services Details',
  subcategoryQuote: 'Subcategory Quote Yes Sch',
  promoCodeDiscount: 'Promo Code Discount',
  subTotal: 'Sub Total',
  total: 'Total',
  monthlyPayment: 'Monthly Payment',
  signForApproval: 'Please Sign Here For Estimation Approval',
  pleaseNote: 'Please Note',
  quoteExpire: ' Quote Expire',
  decline: 'DECLINE',
  approve: 'APPROVE',
  requestViewDetails: 'Request / View Details',
  note: 'Payment Is Collected Only After Work Is Done. For Easier Payment Processing Please Add Mastercard, Visa, Discover Or American Express Card. You Will Not Be Charged Until Work Is Completed',
  description: 'Description',
  seerRating: 'Up to SEER Rating ',
  appliedOn: 'Applied On',
  discount: 'Discount',
  discountAmount: 'Discount Amount',
  term: 'Term',
  months: 'months',
  minLoanAmount: 'Min Loan Amount',
  paymentFactor: ' Payment Factor',
  df: 'DF',
  pleaseSelect: 'Please Select an option below',
  sizeDescription: ' Size Description:',
  quoteDescription: 'Quote Description:',
  capacity: ' Capacity:',
  directional: 'Directional:',
  minimumBlowerDrive: '  Minimum Blower Drive:',
  efficiency: 'Efficiency:',
  partsWarranty: 'Parts Warranty:',
  energyGuideLabel: ' Energy Guide Label:',
  partWarranty: ' Part Warranty :',
  monthlyApprovedCredit: 'Monthly payment (with approved credit)',
  totalCost: 'Total Cost',
  selectAllTechnicianOptions: ' Select All Technician Options',
  totalCostS: ' Total Cost:',
  lineItems: 'Line Items',
  quoteAmount: 'Quote Amount:',
  discountS: 'Service Provider Discount:',
  discountApplied: 'Discount Applied',
  rebates: 'Rebates',
  optionsList: 'Options List',
  subTotalCost: 'Sub Total:',
  days: '30 Days',
  discountedPrice: 'Discounted Price',
  options: 'Options',
  selectedAllTechnicianOptions: ' Selected All Technician Options',
  yourQuoteExpire: ' Your quote expire please contact',
};
