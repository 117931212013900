import { Outlet } from 'react-router';
import { AboutUs } from '../../views/Store';
import { accessRoute as SignIn } from '../Auth/store';

export default function route() {
  return [
    {
      path: SignIn.STORE_LOGIN.path,
      name: 'General-Links',
      key: SignIn.STORE_LOGIN.path,
      label: 'General Links',
      private: false,
      belongsToFooter: true,
      element: <Outlet />,
      children: [
        {
          path: accessRoute.ABOUT_US.path,
          name: 'About Us',
          label: 'About Us',
          key: accessRoute.ABOUT_US.key,
          private: false,
          belongsToFooter: true,
          element: <AboutUs />,
        },
      ],
    },
  ];
}

export const accessRoute = {
  ABOUT_US: {
    path: '/store/about-us',
    name: 'About Us',
    key: 'About-Us',
  },
};

export const getChildrenRoutes = () => {
  return route();
};
