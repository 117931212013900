let url = '/v1/admin/quote/sample-file';

export const UPLOAD_BULK_ENDPOINT = {
  downloadSampleFile: {
    url,
    method: 'GET',
  },
  uploadBulkFile: {
    url: '/v1/admin/quote/file-upload',
    method: 'POST',
  },
  uploadBulkImages: {
    url: '/v1/admin/quote/media-file-used',
    method: 'POST',
  },
};
