import i18next from 'i18next';
import {
  checkValidData,
  checkValidPrice,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { accessRoute } from '../../routes/Dashboard';
import {
  getInputSearchFilter,
  getColumnSelectSearchProps,
} from '../../components';
import { getTupleStatusList } from '../index.service';
import { accessRoute as FinanceAccessRoute } from '../../routes/PriceManagement';

export const financeOptionBreadcrumbBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.quote.financingOption'),
    },
  ];
};

export const financeOptionBreadcrumbTableColumn = ({
  Action,
  onSort,
  onFilterChange,
  Toggle,
  filter,
  page,
  sizePerPage,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'financingTitle',
      title: i18next.t('text.common.title'),
      headerClasses: 'sorting',
      sorter: true,
      ...getInputSearchFilter({
        dataIndex: 'financingTitle',
        placeholder: 'title',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'termsInMonth',
      title: i18next.t('text.common.months'),
      headerClasses: 'sorting',
      sorter: true,
      ...getInputSearchFilter({
        dataIndex: 'termsInMonth',
        placeholder: 'months',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'minimumLoanAmount',
      title: i18next.t('text.quote.minLoadAmount'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => checkValidPrice(cell),
      ...getInputSearchFilter({
        dataIndex: 'minimumLoanAmount',
        placeholder: 'min loan amount',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'paymentFactor',
      title: i18next.t('text.quote.paymentFactor'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => {return `$ ${(cell)}`},
      ...getInputSearchFilter({
        dataIndex: 'paymentFactor',
        placeholder: 'payment factor',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'dealerFee',
      title: i18next.t('text.quote.dealerFee'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => checkValidPrice(cell),
      ...getInputSearchFilter({
        dataIndex: 'dealerFee',
        placeholder: 'dealer fee',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'status',
      title: i18next.t('text.common.status'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => <Toggle row={row} cell={cell} />,
      ...getColumnSelectSearchProps({
        dataIndex: 'status',
        list: getTupleStatusList(true),
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.common.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => <Action row={row} cell={cell} />,
    },
  ];
};
export const addFinanceOptionBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: FinanceAccessRoute.LIST_FINANCING_BANK.path,
      name: i18next.t('text.quote.financingOption'),
    },
    {
      path: '#',
      name: i18next.t('text.quote.addFinancingOption'),
    },
  ];
};
export const editFinanceOptionBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: FinanceAccessRoute.LIST_FINANCING_BANK.path,
      name: i18next.t('text.quote.financingOption'),
    },
    {
      path: '#',
      name: i18next.t('text.quote.editFinancingOption'),
    },
  ];
};
