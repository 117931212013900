import { getLocalStorageToken, removeLocalStorage } from '.';
import { removeLocalStorageToken } from '../utils';
/** ****Function is driver function for authenticate*******
 * ****user and route for which it will**************
 * ****return true and false************************** */

function authDriver(
  route,
  userRole = '',
  pathname,
  isAdmin = false,
  isLayout = false
) {
  try {
    let checkData =
      isLayout === 'customerLayout'
        ? getLocalStorageToken('customer')
        : isLayout === 'storeLayout'
        ? getLocalStorageToken('store')
        : getLocalStorageToken();

    if (
      (route?.private && userRole === pathname) ||
      (isAdmin && route?.private)
    ) {
      // ********For secure route**************
      return !!checkData;
    } else {
      // **Non secure route****
      if (!!checkData) {
        if (!userRole) {
          removeLocalStorageToken(null, pathname);
          return true;
        }
        if (userRole === 'admin' && isLayout === 'customerLayout') {
          return true;
        }
        if (userRole === 'admin' && isLayout === 'storeLayout') {
          return true;
        }
        return false;
      }
      return route?.private === false;
    }
  } catch (err) {
    removeLocalStorageToken();
    return false;
  }
}

export default authDriver;
