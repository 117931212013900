import React, { useState } from 'react';
import Tabs from '../../../../../components/UIElements/Tabs';
import Case from './Cash';
import CheckComponent from './Check';
import CreditCard from './CreditCard';
import FinancingComponent from './Financing';
import i18next from 'i18next';

export default function MemberShipPaymentMethods({
  rowData,
  setIsOpenModal,
  memberShipAPIData,
  setMemberShipAPIData,
}) {
  const [defaultKey, setDefaultKey] = useState(
    rowData?.planType === 'monthly' ? 'creditCard' : 'check'
  );

  const isAlreadyActivate = memberShipAPIData?.rows?.find(
    (e) => e?.CustomerActivePlan?.membershipPlanId
  );

  const tabContent = [
    ...(rowData?.planType === 'yearly'
      ? [
          {
            name: i18next.t('text.report.check'),
            key: 'check',
            content: (
              <CheckComponent
                defaultKey={defaultKey}
                rowData={rowData}
                setIsOpenModal={setIsOpenModal}
                memberShip={true}
                isAlreadyActivate={isAlreadyActivate}
                setMemberShipAPIData={setMemberShipAPIData}
              />
            ),
          },
        ]
      : []),
    ...(rowData?.planType === 'yearly'
      ? [
          {
            name: i18next.t('text.report.financing'),
            key: 'financing',
            content: (
              <FinancingComponent
                defaultKey={defaultKey}
                rowData={rowData}
                setIsOpenModal={setIsOpenModal}
                memberShip={true}
                isAlreadyActivate={isAlreadyActivate}
                setMemberShipAPIData={setMemberShipAPIData}
              />
            ),
          },
        ]
      : []),
    ...(rowData?.planType === 'yearly' || rowData?.planType === 'monthly'
      ? [
          {
            name: i18next.t('text.report.creditCard'),
            key: 'creditCard',
            content: (
              <CreditCard
                rowData={rowData}
                setIsOpenModal={setIsOpenModal}
                memberShip={true}
                isAlreadyActivate={isAlreadyActivate}
                setMemberShipAPIData={setMemberShipAPIData}
              />
            ),
          },
        ]
      : []),
    ...(rowData?.planType === 'yearly'
      ? [
          {
            name: i18next.t('text.report.cash'),
            key: 'cash',
            content: (
              <Case
                rowData={rowData}
                setIsOpenModal={setIsOpenModal}
                memberShip={true}
                isAlreadyActivate={isAlreadyActivate}
                setMemberShipAPIData={setMemberShipAPIData}
              />
            ),
          },
        ]
      : []),
  ];

  return (
    <>
      <div>
        <Tabs
          tabContent={tabContent}
          tabsFor="table"
          activeKey={defaultKey}
          setActiveKey={setDefaultKey}
          tabsOnModal={true}
          setParams={false}
        />
      </div>
    </>
  );
}
