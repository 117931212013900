import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';
import { MANAGE_SERVICES_ENDPOINT } from '../../apiEndpoints/index.endpoint';

export const ListServiceCategoryService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...MANAGE_SERVICES_ENDPOINT.getServiceCategoryList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const ListCustomerServiceCategoryService = async ({
  queryParams,
  type,
}) => {
  try {
    const payload = {
      ...MANAGE_SERVICES_ENDPOINT.getCustomerServiceCategoryList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const DeleteServiceCategoryService = async ({ bodyData }) => {
  try {
    const payload = {
      ...MANAGE_SERVICES_ENDPOINT.deleteServiceCategory,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const StatusServiceCategoryService = async (bodyData) => {
  try {
    const payload = {
      ...MANAGE_SERVICES_ENDPOINT.statusServiceCategory,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const addServiceCategoryService = async ({ values }) => {
  try {
    const payload = {
      ...MANAGE_SERVICES_ENDPOINT.addServiceCategory,
      bodyData: values,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const viewServiceCategoryService = async (id) => {
  try {
    const payload = {
      ...MANAGE_SERVICES_ENDPOINT?.getServiceCategoryDetails(id),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const editServiceCategoryService = async ({ values, id }) => {
  try {
    const payload = {
      ...MANAGE_SERVICES_ENDPOINT.editServiceCategory(id),
      bodyData: values,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
