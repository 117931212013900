import i18next from 'i18next';
import {
  getInputSearchFilter,
  getMultiSelectSearchFilter,
} from '../../components';
import { getStatusData, getTupleStatusList } from '../index.service';
import { accessRoute } from '../../routes/Dashboard';
import {
  checkValidData,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
export const categoryListBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.shoppingCart.categoryTitle'),
    },
  ];
};
export const subCategoryListBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.shoppingCart.subCategoryManagement'),
    },
  ];
};
export const categoryTableColumn = ({
  Action,
  Toggle,
  page,
  sizePerPage,
  onFilterChange,
  filter,
  onSort,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportIdRemove: true,
    },
    {
      dataIndex: 'categoryName',
      filterDataIndex: ['search'],
      title: i18next.t('text.shoppingCart.categoryName'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => checkValidData(row?.categoryName),
      // onSort: (field, order) => onSort('categoryName', order),
      // exportKey: 'search',
      exportName: 'categoryName',
      exportFormatter: (col, row) => checkValidData(row?.categoryName),
      ...getInputSearchFilter({
        dataIndex: 'search',
        placeholder: 'category name',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'status',
      title: i18next.t('text.common.status'),
      headerClasses: 'sorting',
      render: (cell, row) => <Toggle cell={cell} row={row} />,
      sorter: true,
      onSort: (field, order) => onSort('status', order),
      ...getMultiSelectSearchFilter({
        dataIndex: 'status',
        onFilterChange,
        filter,
        list: [],
        name: 'status',
        listService: getStatusData,
        mode: false,
      }),
    },
    {
      dataIndex: 'Action',
      title: i18next.t('text.common.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => <Action cell={cell} row={row} />,
    },
  ];
};
export const subCategoryTableColumn = ({
  Action,
  Toggle,
  page,
  sizePerPage,
  onFilterChange,
  filter,
  onSort,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      // render: (cell, row, index) =>
      //   serialNumberFormatter({ page, sizePerPage, index }),
      // exportFormatter: (cell, row, index) =>
      //   serialNumberFormatter({ page, sizePerPage, index }),
    },

    {
      dataIndex: 'categoryName',
      title: i18next.t('text.shoppingCart.categoryName'),
      headerClasses: 'sorting',
      sorter: true,
      // onSort: (field, order) => onSort('categoryName', order),
      ...getInputSearchFilter({
        dataIndex: 'categoryName',
        placeholder: 'category name',
        onFilterChange,
        filter,
      }),
      // render: (cell, row) => checkValidData(row?.State?.name),
      exportKey: 'categoryName',
      // exportFormatter: (col, row) => checkValidData(''),
    },
    {
      dataIndex: 'subCategoryName',
      title: i18next.t('text.shoppingCart.subCategoryName'),
      headerClasses: 'sorting',
      sorter: true,
      // onSort: (field, order) => onSort('subCategoryName', order),
      ...getInputSearchFilter({
        dataIndex: 'subCategoryName',
        placeholder: 'sub category name',
        onFilterChange,
        filter,
      }),
      // render: (cell, row) => checkValidData(''),
      exportKey: 'subCategoryName',
      // exportFormatter: (col, row) => checkValidData(''),
    },
    {
      dataIndex: 'status',
      title: i18next.t('text.common.status'),
      headerClasses: 'sorting',
      render: (cell, row) => <Toggle cell={cell} row={row} />,
      sorter: true,
      onSort: (field, order) => onSort('status', order),
      ...getMultiSelectSearchFilter({
        dataIndex: 'status',
        onFilterChange,
        filter,
        name: 'status',
        listService: getTupleStatusList,
        mode: false,
      }),
    },
    {
      dataIndex: 'Action',
      title: i18next.t('text.common.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => <Action cell={cell} row={row} />,
    },
  ];
};
