import {
  AddAdminForm,
  getColumnSelectSearchProps,
  getInputSearchFilter,
  getMultiSelectSearchFilter,
} from '../../components';
import { firstLetterCaps, getFullName } from '../../utils/text.util';
import { getTupleStatusList } from '../index.service';
import { TeamSetting } from '../../services/TeamSetting';
import { accessRoute } from '../../routes/Dashboard';
import { accessRoute as accessTeamRoute } from '../../routes/TeamSetting';
import i18next from 'i18next';
import LessonsDetails from '../../views/TeamSetting/Admin/LessonsDetails';
import { serialNumberFormatter } from '../../components/UIElements/Formatter';

export const getAdminListColumns = ({
  Action,
  Toggle,
  onSort,
  onFilterChange,
  filter,
  page,
  sizePerPage,
}) => [
  {
    dataIndex: 'id',
    title: i18next.t('text.common.serialNo'),
    render: (cell, row, index) =>
      serialNumberFormatter({ page, sizePerPage, index }),
    exportFormatter: (cell, row, index) =>
      serialNumberFormatter({ page, sizePerPage, index }),
  },
  {
    dataIndex: 'firstName',
    filterDataIndex: ['name'],
    headerClasses: 'sorting',
    sorter: true,
    // sortType: 'name',
    title: i18next.t('text.common.name'),
    exportName: 'name',
    // sorter: true,
    export: true,
    // onSort: (field, order) => onSort('name', order),
    exportFormatter: (cell, row) =>
      getFullName(row?.firstName, undefined, row?.lastName),
    render: (cell, row) =>
      getFullName(row?.firstName, undefined, row?.lastName),
    ...getInputSearchFilter({ dataIndex: 'name', onFilterChange, filter }),
  },
  {
    dataIndex: 'email',
    title: i18next.t('text.common.email'),
    sorter: true,
    onSort: (field, order) => onSort('email', order),
    ...getInputSearchFilter({ dataIndex: 'email', onFilterChange, filter }),
  },
  {
    sortType: 'type',
    filterDataIndex: ['roleType'],
    dataIndex: 'type',
    headerClasses: 'sorting',
    sorter: true,
    title: 'Type',
    export: false,
    exportName: 'Type',
    onSort: (field, order) => onSort('type', order),
    render: (cell, row) => firstLetterCaps(row?.Role?.type),
    ...getInputSearchFilter({ dataIndex: 'roleType', onFilterChange, filter }),
  },
  {
    // sortType: 'roleName',
    dataIndex: 'roleName',
    filterDataIndex: ['roleId'],
    title: i18next.t('text.common.roles'),
    exportName: 'Admin Roles',
    sorter: true,
    export: true,
    exportKey: 'Role',
    exportFormatter: (cell, row) => firstLetterCaps(row?.Role?.name),
    onSort: (field, order) => onSort('roleName', order),
    render: (cell, row) => firstLetterCaps(row?.Role?.name),
    // ...getColumnSelectSearchProps({
    //   dataIndex: 'roleId',
    //   list: [],
    //   listService: TeamSetting.listRoleService,
    //   onFilterChange,
    //   filter,
    //   name: 'roles',
    //   extraQuary: { type: 'subadmin', status: 'all' },
    // }),
    ...getMultiSelectSearchFilter({
      dataIndex: 'roleId',
      list: [],
      listService: TeamSetting.listRoleService,
      onFilterChange,
      filter,
      name: 'roles',
      mode: false,
      extraQuary: { type: 'subadmin', status: 'all' },
    }),
  },
  {
    dataIndex: 'status',
    title: i18next.t('text.common.status'),
    sorter: true,
    render: (cell, row) => {
      return <Toggle row={row} cell={cell} />;
    },
    ...getMultiSelectSearchFilter({
      dataIndex: 'status',
      onFilterChange,
      filter,
      name: 'status',
      list: [],
      mode: false,
      listService: getTupleStatusList,
    }),
  },
  {
    dataIndex: 'action',
    title: i18next.t('text.common.action'),
    className: 'nk-tb-col-tools text-end',
    headerClasses: 'nk-tb-col-tools title-end',
    render: (cell, row) => <Action cell={cell} row={row} />,
  },
];

export const adminListBreadcrumbs = () => [
  {
    path: accessRoute.DASHBOARD.path,
    name: i18next.t('text.common.dashboard'),
  },
  {
    path: '#',
    name: i18next.t('text.common.admin'),
  },
];

export const adminEditBreadcrumbs = () => [
  {
    path: accessRoute.DASHBOARD.path,
    name: i18next.t('text.common.dashboard'),
  },
  {
    path: accessTeamRoute.ADMIN.path,
    name: i18next.t('text.common.admin'),
  },
  {
    path: '#',
    name: i18next.t('text.common.editSubAdmin'),
  },
];

export const adminTabContent = ({
  formData,
  onSubmit,
  isLoading,
  id,
  redirectUrl,
}) => {
  return [
    {
      name: i18next.t('text.common.profile'),
      key: 'profile',
      content: (
        <div className="card">
          <div className="card-inner">
            <AddAdminForm
              loading={isLoading}
              submitText="edit"
              formData={formData}
              onSubmit={onSubmit}
              editSubAdmin={true}
              modalId={id}
              redirectUrl={redirectUrl}
            />
          </div>
        </div>
      ),
    },
    {
      name: i18next.t('text.provider.lessonProfile'),
      key: 'companyProfileForm',
      content: <LessonsDetails userId={id} />,
    },
  ];
};
