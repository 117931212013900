export const validation = {
  titleError: 'Please enter valid title',
  title: 'Please enter lesson title',
  maxTitle: 'Please do not enter more than 200 characters',
  serviceIds: 'Please select any service from the list.',
  description: 'Please enter some description.',
  moduleIds: 'Please select any module from the list.',
  questionType: 'Please select question type',
  type: 'Please select type of operating procedure.',
  required: 'Please select lesson is required or not.',
  percentage: 'Please enter percentage.',
  maxPercentage: 'Please enter a value between 1 and 100.',
  questionTitle: 'Please enter question. ',
  optionValue: 'Please enter value in option.',
  correctOption: 'Please select correct option.',
  oneOption: 'Please select any one option.',
};
