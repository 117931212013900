import React, { useContext, useState } from 'react';
import { ChatContext } from '../../../../context/chat.context';
import { useTranslation } from 'react-i18next';

import ImageElement from '../../../../components/UIElements/ImageElement';
import { CommonButton } from '../../../../components';
import notification from '../../../../utils/notifications';

import { postAddRoomService } from '../../../../services/Chat/index.service';
import { firstLetterCaps } from '../../../../utils/text.util';
import { capitalizeFirstLetter } from '../../../../utils';
import { useSelector } from 'react-redux';

const ChatServiceProviderList = ({ serviceProvider, getChatRoomUserLists }) => {
  const {
    handleOpenChatWithSPModal,
    handleIncrementPerPage,
    isDisabledLoadMore,
    setSelectUser,
    isLoadMoreLoading,
    handleNewMessageSend,
  } = useContext(ChatContext);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(null);
  const mentalHeathPermission = useSelector(
    (state) => state?.permission?.isAllowMentalHealthModule
  );

  const handleSendMessage = async (serviceProviderId) => {
    setIsLoading(serviceProviderId);
    try {
      let body = {
        roomName: '',
        chatType: 'single',
        image: '',
        userIds: [serviceProviderId],
      };
      const response = await postAddRoomService({ body });
      if (response?.success) {
        handleNewMessageSend(serviceProviderId);
        setSelectUser({
          chatRoom: response?.data?.rows,
        });
        getChatRoomUserLists();
        handleOpenChatWithSPModal();
      } else {
        if (response?.data?.roomId) {
          handleNewMessageSend(serviceProviderId);
          setSelectUser({
            chatRoom: {
              id: response?.data?.roomId,
            },
          });
          getChatRoomUserLists();
          handleOpenChatWithSPModal();
        }
      }
    } catch (error) {
      notification({ type: 'error', message: error?.message });
    }
    setIsLoading(null);
  };
  return (
    <>
      <ul className="chat-list">
        {serviceProvider?.length &&
          serviceProvider?.map((serviceProviderItem, idx) => (
            <li key={idx} className="chat-item mb-2">
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <div className="d-flex align-items-center pe-1 pe-sm-2 mb-1 mb-sm-0">
                  <div className="chat-media user-avatar bg-purple">
                    <ImageElement
                      previewSource={
                        serviceProviderItem?.profilePictureThumbUrl
                      }
                      alt="img"
                      isProfile={true}
                    />
                    <span className="status dot dot-lg dot-gray"></span>
                  </div>
                  <div className="chat-from ms-2 d-block">
                    <div className="name">
                      {firstLetterCaps(serviceProviderItem?.firstName ?? '')}
                      &nbsp;
                      {firstLetterCaps(serviceProviderItem?.lastName ?? '')}
                    </div>
                    {mentalHeathPermission && (
                      <p className="text-gray">
                        <span>
                          {capitalizeFirstLetter(
                            serviceProviderItem?.Provider?.mentalStatus
                          )}
                        </span>
                      </p>
                    )}
                  </div>
                </div>
                <CommonButton
                  disabled={isLoading}
                  loading={isLoading === serviceProviderItem?.id}
                  onClick={() => handleSendMessage(serviceProviderItem?.id)}
                  className="btn w-auto justify-content-center btn-primary mb-1 mb-sm-0"
                >
                  <span className='d-none d-sm-block'>{t('text.chat.sendMessage')}</span>
                  <em class="icon ni ni-chat-fill d-block p-0 m-0 d-sm-none"></em>
                </CommonButton>
              </div>
            </li>
          ))}
      </ul>
      <div className="text-center mt-1">
        <CommonButton
          disabled={isDisabledLoadMore || isLoadMoreLoading}
          loading={isLoadMoreLoading}
          onClick={handleIncrementPerPage}
          className="btn btn-primary"
        >
          {t('text.chat.loadMore')}
        </CommonButton>
      </div>
    </>
  );
};

export default ChatServiceProviderList;
