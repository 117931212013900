import React, { useState } from 'react';
import moment from 'moment';
import { Row, FormGroup } from 'react-bootstrap';
import { Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import {
  postBookingEmergencyFree,
  updatePromoCode,
} from '../../../services/CustomerApp/index.service';
import { modalNotification } from '../../../utils';
import { checkValidPrice } from '../../../components/UIElements/Formatter';
import { useLocation } from 'react-router';
import { CommonButton } from '../../../components';
import { firstLetterCaps } from '../../../utils/text.util';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Regex } from '../../../helpers/regex.helper';
import { useTranslation } from 'react-i18next';

function CustomerBooking({
  submitText = 'Booking Now',
  emergencyProviderUserId = false,
  setModal,
  stateModal,
  requestAll = false,
  isSuccess = true,
  isBookingAll = false,
  sortBy,
}) {
  const { t } = useTranslation();
  const location = useLocation();
  const { state: locationState } = location;
  const prevPromoCode = useSelector(
    (state) => state?.auth?.customerData?.Customer?.promoCode
  );

  const [state, setState] = useState({
    promoCode: prevPromoCode || '',
    isAppliedPromoCode: false,
    discount: undefined,
    note: '',
    isLoading: false,
    promoCodeDetails: null,
  });
  const { promoCode, isAppliedPromoCode, discount, note, isLoading } = state;

  const handleApplyPromoCode = async () => {
    let stateObj = {};
    try {
      if (!promoCode) {
        modalNotification({
          type: 'error',
          message: 'Please enter promo code.',
        });
        setState({
          ...state,
          isLoading: false,
        });
        return;
      } else if (promoCode && !Regex.onlyAlphaNumeric?.test(promoCode)) {
        modalNotification({
          type: 'error',
          message: t('validation.customerAppProfile.invalidPromoCode'),
        });
        return;
      }
      setState({
        ...state,
        isLoading: true,
      });
      const { data, message, success } = await updatePromoCode(promoCode);
      if (success) {
        modalNotification({
          type: 'success',
          message,
        });
        if (data?.discountType === 'percentage') {
          stateObj = {
            ...stateObj,
            discount:
              Number(location?.state?.price) &&
              ((Number(location?.state?.price) *
                (Number(location?.state?.quantity) || 1)) /
                100) *
                Number(data?.discountValue),
          };
        } else {
          stateObj = {
            ...stateObj,
            discount:
              Number(data?.discountValue) *
              (Number(location.state?.quantity) || 1),
          };
        }

        stateObj = {
          ...stateObj,
          isAppliedPromoCode: true,
          promoCodeDetails: data,
          modal: isSuccess ? 'success' : '',
        };
      } else {
        stateObj = {
          ...stateObj,
          prevPromoCode: undefined,
          isAppliedPromoCode: false,
        };
      }
    } catch (error) {
      console.log(error);
    }
    setState({
      ...state,
      ...stateObj,
      isLoading: false,
    });
  };

  const handleBookingNow = async () => {
    if (setModal) {
      setModal({ ...stateModal, isLoading: true });
    }
    try {
      const bodyData = {
        providerUserId: emergencyProviderUserId
          ? emergencyProviderUserId
          : locationState?.providerDetails?.Provider?.UserId,
        customerLocationId: locationState?.locationId,
        serviceTypeId: locationState?.serviceTypeDetails?.id,
        serviceCategoryId: locationState?.id,
        quantity: locationState?.quantity ? locationState?.quantity : 1,
        ...(sortBy === 'favorite' && {
          sortBy,
        }),
        ...(isAppliedPromoCode && {
          promoCode: promoCode,
        }),
        ...(note && { note }),
        ...(discount && { discount }),
        ...(locationState?.dateTime?.slotId && {
          slotId: locationState?.dateTime?.slotId,
        }),
        ...(locationState?.dateTime?.bookingDate && {
          bookingDate: locationState?.dateTime?.bookingDate,
        }),
        ...(isBookingAll && { bookingType: 'all' }),
      };

      const { message, success, data } = await postBookingEmergencyFree(
        bodyData
      );
      if (success) {
        modalNotification({
          type: 'success',
          message,
        });
        if (setModal) {
          setModal({
            ...stateModal,
            isLoading: false,
            thankyouLoader: true,
            modal: isSuccess ? 'success' : '',
            bookingId: data?.id,
            // bookingStatusId: data?.id,
          });
        }
      }
      setModal((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    } catch (error) {
      if (setModal) {
        setModal({
          ...stateModal,
          isLoading: false,
          thankyouLoader: false,
        });
      }
    }
  };

  const handleRequestAll = async () => {
    let stateObj = {
      isLoading: false,
    };
    try {
      if (setModal) {
        setModal({ ...stateModal, isLoading: true });
      }
      const bodyData = {
        bookingType: 'all',
        customerLocationId: locationState?.locationId,
        serviceCategoryId: locationState?.id,
        quantity: locationState?.quantity ? locationState?.quantity : 1,
        serviceTypeId: locationState?.serviceTypeDetails?.id,

        ...((isAppliedPromoCode || prevPromoCode) && {
          promoCode: isAppliedPromoCode ? promoCode : prevPromoCode,
        }),
        ...(note && { note }),
        ...(discount && { discount }),
        ...(locationState?.dateTime?.slotId && {
          slotId: locationState?.dateTime?.slotId,
        }),
        ...(locationState?.dateTime?.bookingDate && {
          bookingDate: locationState?.dateTime?.bookingDate,
        }),
      };
      const res = await postBookingEmergencyFree(bodyData);
      if (res?.success) {
        modalNotification({
          type: 'success',
          message: res?.message,
        });
        stateObj = {
          isLoading: false,
          modal: 'success',
        };
      }
    } catch (error) {
      console.log(error);
    }
    if (setModal) {
      setModal({
        ...stateModal,
        ...stateObj,
      });
    }
  };

  const handleBooking = () => {
    if (requestAll) {
      handleRequestAll();
    } else {
      handleBookingNow();
    }
  };

  useEffect(() => {
    if (prevPromoCode && location?.state?.servicePrice === 'paid') {
      setState({
        ...state,
        promoCode: prevPromoCode,
      });
    }
    if (
      location?.state?.isSalesQuote === 0 ||
      location?.state?.servicePrice === 'paid'
    ) {
      handleApplyPromoCode();
    }
  }, [prevPromoCode]);

  return (
    <div>
      <Row className="align-items-center">
        {location?.state?.serviceType === 'scheduled' &&
          (location?.state?.servicePrice === 'paid' ||
            location?.state?.isSalesQuote === 0 ||
            isBookingAll) && (
            <ul className="bookingDetails list-unstyled mb-0">
              {location.state?.name && (
                <li className="bookingDetails_list d-flex">
                  <label>
                    {firstLetterCaps(location?.state?.name)}
                    <br />
                    {location?.state?.servicePrice === 'paid' &&
                      ` (Quantity) : ${checkValidPrice(
                        location?.state?.price
                      )} x
                    ${
                      location?.state?.quantity ? location?.state?.quantity : 1
                    }`}
                  </label>
                  <span className="font-sm">
                    {location?.state?.servicePrice === 'paid'
                      ? checkValidPrice(
                          location?.state?.price *
                            (location?.state?.quantity
                              ? location?.state?.quantity
                              : 1)
                        )
                      : 'Free'}
                  </span>
                </li>
              )}

              {/* {location.state?.price && (
                <li className="bookingDetails_list d-flex">
                  <label>
                    {firstLetterCaps(location.state?.servicePrice)}{' '}
                    {firstLetterCaps(location.state?.serviceType)}
                  </label>
                  <span className="font-sm">
                    $ &nbsp;{ToFixedPrice(location.state?.price)}
                  </span>
                </li>
              )} */}
              {locationState?.providerDetails && !isBookingAll && (
                <li className="bookingDetails_list d-flex">
                  <label>Provider</label>
                  <span className="font-sm">
                    {firstLetterCaps(locationState?.providerDetails?.firstName)}
                    &nbsp;
                    {firstLetterCaps(locationState?.providerDetails?.lastName)}
                  </span>
                </li>
              )}

              {!isBookingAll && (
                <li className="bookingDetails_list d-flex">
                  <label>Company Name</label>
                  <span className="font-sm">
                    {locationState?.providerDetails?.Provider?.CompanyNca?.name
                      ? locationState?.providerDetails?.Provider?.CompanyNca
                          ?.name
                      : 'Good Guys Admin'}
                  </span>
                </li>
              )}

              {location?.state?.dateTime && (
                <>
                  <li className="bookingDetails_list d-flex">
                    <label>Booking Date</label>
                    <span className="font-sm">
                      {moment(location?.state?.dateTime?.bookingDate).format(
                        'LL'
                      )}
                    </span>
                  </li>
                  <li className="bookingDetails_list d-flex">
                    <label>Booking Time</label>
                    <span className="font-sm">
                      {location?.state?.dateTime?.slotTime}
                    </span>
                  </li>
                </>
              )}
            </ul>
          )}
      </Row>

      {(location?.state?.servicePrice === 'paid' ||
        location?.state?.isSalesQuote === 0) && (
        <div className="promoCode">
          <div className="d-flex align-items-end">
            <div className="form-group promoCode_wrapper mb-0">
              <label className="ant-label font-md">Promo Code</label>
              <Input
                size="large"
                placeholder="Promo Code"
                value={promoCode}
                onChange={(e) =>
                  setState({ ...state, promoCode: e?.target?.value })
                }
              />
            </div>
            <CommonButton
              variant="primary"
              className="ms-2 font-sm d-inline-block"
              disabled={isLoading}
              loading={isLoading}
              onClick={handleApplyPromoCode}
            >
              {t('text.common.apply')}
            </CommonButton>
          </div>
          {prevPromoCode && isAppliedPromoCode && (
            <div className="promoCode_info mt-2 d-flex align-items-center">
              <em className="gg-check-circle me-2" />{' '}
              <span>{t('text.common.applyPromoCode')}</span>
            </div>
          )}
        </div>
      )}

      {location?.state?.serviceType === 'scheduled' &&
        (location?.state?.servicePrice === 'paid' ||
          location?.state?.isSalesQuote === 0 ||
          isBookingAll) && (
          <div className="mb-3 mt-3">
            <ul className="serviceCost list-unstyled mt-lg-0 mt-3 mb-0">
              <li className="serviceCost_list">
                <label>Total Cost</label>
                <span className="font-sm">
                  {Number(location?.state?.price) > 0
                    ? checkValidPrice(
                        Number(
                          location?.state?.price *
                            (location?.state?.quantity
                              ? location?.state?.quantity
                              : 1)
                        ) - (Number(discount) || 0)
                      )
                    : 'Free'}

                  {/* {ToFixedPrice(
                    location.state?.price *
                      (location.state?.quantity ? location.state?.quantity : 1)
                  )} */}
                </span>
              </li>
            </ul>
          </div>
        )}
      <FormGroup className="form-group">
        <label className="ant-label font-md ps-0">
          Add Special Instruction For Provider Below
        </label>
        <TextArea
          value={note}
          onChange={(e) => setState({ ...state, note: e?.target?.value })}
          rows={5}
        />
      </FormGroup>
      <CommonButton
        variant="primary"
        className="ms-auto min-200 mt-lg-4 mt-3"
        loading={stateModal?.isLoading}
        disabled={stateModal?.isLoading || state?.isLoading}
        // onClick={handleRequestAll}
        onClick={handleBooking}
      >
        {submitText}
      </CommonButton>
    </div>
  );
}

export default CustomerBooking;
