import i18next from 'i18next';
import { checkValidPrice } from '../../components/UIElements/Formatter';
import { getFullName } from '../../utils/text.util';
import moment from 'moment';
import { capitalizeFirstLetter } from '../../utils';

export const promoCodeJobsTableColumn = () => {
  return [
    {
      dataIndex: 'jobNo',
      title: i18next.t('text.common.jobNo'),
      headerClasses: 'w_70',
      render: (_, row) => row?.bookingNo || '-',
    },
    {
      dataIndex: 'providerName',
      title: i18next.t('text.common.providerName'),
      headerClasses: 'w_70',
      render: (_, row) =>
        row?.Provider?.firstName
          ? getFullName(row?.Provider?.firstName, row?.Provider?.lastName)
          : '-',
      sorter: true,
    },
    {
      dataIndex: 'userName',
      title: i18next.t('text.common.user'),
      headerClasses: 'w_70',
      render: (_, row) =>
        row?.Customer?.firstName
          ? getFullName(row?.Customer?.firstName, row?.Customer?.lastName)
          : '-',
      sorter: true,
    },
    {
      dataIndex: 'bookingScheduleDate',
      title: i18next.t('text.report.jobsDate'),
      headerClasses: 'w_70',
      render: (_, row) =>
        row?.bookingScheduleDate
          ? moment(row?.bookingScheduleDate).format('Do MMMM, YYYY hh:mm A')
          : '-',
      sorter: true,
    },
    {
      dataIndex: 'jobLocationAddress',
      title: i18next.t('text.common.location'),
      headerClasses: 'w_70',
      render: (_, row) => row?.jobLocationAddress || '-',
      sorter: true,
    },
    {
      dataIndex: 'promoCode',
      title: i18next.t('text.promoCode.couponCode'),
      headerClasses: 'w_70',
      render: (_, row) => row?.promoCode || '-',
      sorter: true,
    },
    {
      dataIndex: 'totalJobFee',
      title: i18next.t('text.serviceManagement.totalFees'),
      headerClasses: 'w_70',
      render: (_, row) =>
        row?.totalJobFee ? checkValidPrice(row?.totalJobFee) : '-',
      sorter: true,
    },
    {
      dataIndex: 'commissionAmount',
      title: i18next.t('text.marketing.aCommissonAmount'),
      headerClasses: 'w_70',
      render: (_, row) =>
        row?.commissionAmount ? checkValidPrice(row?.commissionAmount) : '-',
      sorter: true,
    },
    {
      dataIndex: 'transferAmount',
      title: i18next.t('text.marketing.bTransferAmount'),
      headerClasses: 'w_70',
      render: (_, row) =>
        row?.transferAmount ? checkValidPrice(row?.transferAmount) : '-',
      sorter: true,
    },
    {
      dataIndex: 'pendingAmount',
      title: i18next.t('text.marketing.cABPendingAmount'),
      headerClasses: 'w_70',
      render: (_, row) =>
        row?.pendingAmount ? checkValidPrice(row?.pendingAmount) : '-',
      sorter: true,
    },
    {
      dataIndex: 'transferStatus',
      title: i18next.t('text.report.paymentTransferStatus'),
      headerClasses: 'w_70',
      render: (_, row) =>
        row?.transferStatus ? capitalizeFirstLetter(row?.transferStatus) : '-',
      sorter: true,
    },
  ];
};
