import React from 'react';
import { useParams } from 'react-router-dom';
import AddSubServiceCategory from '../../../PriceManagement/ManageService/ServiceCategory/SubServiceCategory/Create';

function AddNCASubCategory() {
  const { id, userId } = useParams();
  return <AddSubServiceCategory userId={userId} ncaId={id} ncaEdit={true} />;
}

export default AddNCASubCategory;
