export const text = {
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  phoneNumber: 'Phone Number',
  subject: 'Enter Subject Here',
  details: 'Please Enter Details',
  captcha: 'Enter Captcha',
  contactText:"Can't read the image?",
  

};
