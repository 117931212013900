export const text = {
  scheduledBookings: 'Scheduled Bookings',
  bookingDate: 'Booking Date',
  expectedSourceLocation: 'Expected Source Location',
  specialInstruction: 'Special Instructions',
  jobDetails: 'Job Details',
  cancelReason: 'Cancel Reason',
  declineReason: 'Decline Reason',
  sendQuote: 'Send Quote',
  jobCompletion: 'Job Completion Time',
  cancelBy: 'Cancel By',
  bookingCancelReason: 'Booking Cancel Reason',
  noteBookings: 'Bookings module will list all Bookings on this page.',
  noteAdministrator:
    '   Administrator can Activate / Deactivate / Delete any booking.',
  noteExport: 'Administrator can export data in XLS format.',
  busyProviders: 'Busy Providers',
  availableProviders: 'Available Providers',
  rescheduleNote: 'Reschedule Note',
};
