import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Address from '../../features/address/address.feature';
import ServiceAddress from '../../features/serviceAddress/index.feature';
import { useDispatch } from 'react-redux';
import { updateLocationId } from '../../redux/index.redux';

function LocationCard({
  children,
  setSelectedAddress: setAddress,
  onAddressChange,
  selectAddress,
  addressListLoaded,
  getCustomerCategoryList,
  getAddressList,
  addressList,
  setShow,
  show,
}) {
  const [selectedAddress, setSelectedAddress] = useState('');
  const dispatch = useDispatch();
  useEffect(() => {
    getAddressList();
  }, []);

  function onAddressSelect(e) {
    setAddress(e?.id || e);
    setSelectedAddress(e?.id || e);
    dispatch(updateLocationId(e?.id || e));
    if (onAddressChange) {
      onAddressChange(e);
    }
  }

  return (
    <Container className="container-lg">
      <Address
        addressListLoaded={addressListLoaded}
        getAddressList={getAddressList}
        selectAddress={selectAddress}
        addressList={addressList}
        onAddressSelect={onAddressSelect}
        getCustomerCategoryList={getCustomerCategoryList}
        setShow={setShow}
        show={show}
      />
      <ServiceAddress
        selectedAddress={selectedAddress}
        addressList={addressList}
      />
      {children}
    </Container>
  );
}

export default LocationCard;
