let url = '/v1/admin/home-content';
export const HOME_PAGE_ENDPOINT = {
  getHomePageList: {
    url,
    method: 'GET',
  },
  editHomePage: {
    url,
    method: 'PUT',
  },
};
