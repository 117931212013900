import { Empty } from 'antd';
import { t } from 'i18next';
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  ActionWrapper,
  CommonButton,
  GoogleServiceFilter,
  PageContainerWrapper,
  Toggle,
} from '../../../../components';
import TablePlus from '../../../../components/UIElements/TablePlus';
import { accessRoute } from '../../../../routes/GoogleConnect';
import { GoogleServiceList } from '../../../../services/GoogleConnect';
import {
  getGoogleTokenService,
  statusGoogleServiceList,
  updateSPControlStatus,
} from '../../../../services/GoogleConnect/googleGMB.service';
import { Columns, getActions } from '../../../../services/index.service';
import { decodeQueryData, modalNotification } from '../../../../utils';
import { checkValidPrice } from '../../../../components/UIElements/Formatter';

function GoogleService() {
  const location = useLocation();
  const breadcrumb = Columns.googleServiceBreadcrumb();
  const [globalFilter, setGlobalFilter] = useState(false);
  const [googleServiceList, setGoogleServiceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState();
  const [statusLoading, setStatusLoading] = useState(false);
  const [serviceListCopy, setServiceListCopy] = useState([]);
  const [healthToggleLoading, setToggleHealthLoading] = useState(false);
  const params = location?.search && decodeQueryData(location?.search);
  const { sortBy, sortType } = params;
  async function getGoogleServiceData(value) {
    setLoading(true);
    try {
      let queryParams = {
        accountId: value,
        ...filterData,
      };
      const response = await GoogleServiceList.getGoogleServiceList({
        queryParams,
      });

      const { data, success } = response;
      if (success) {
        setGoogleServiceList(data);
        setServiceListCopy(data);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }
  const checkGoogleRefreshToken = async () => {
    try {
      let queryParams = {};
      const response = await getGoogleTokenService({ queryParams });
      const { data } = response;
      if (data === true) {
        localStorage.removeItem('googleAuth');
      } else {
        localStorage.setItem('googleAuth', location?.pathname);
        window.open(data?.url, '_self');
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    checkGoogleRefreshToken();
  }, []);

  useEffect(() => {
    getGoogleServiceData();
  }, [filterData]);

  function onAction(e, handler, item) {
    e.preventDefault();
    if (handler) handler(item);
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler, item)}
              key={key}
              className="action_list"
              action={item?.action}
            >
              {item?.name === 'View Leads' ? (
                <Link to={`${accessRoute.GOOGLE_SERVICE_LEAD.path}/${item.id}`}>
                  <em className={item?.icon} /> {item?.name}
                </Link>
              ) : (
                <CommonButton>
                  <em className={item?.icon} /> {item?.name}
                </CommonButton>
              )}
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  if (globalFilter) {
    document.querySelector('body').classList.add('toggle-shown');
  } else {
    document.querySelector('body').classList.remove('toggle-shown');
  }

  const onToggleChange = async (value, data) => {
    setStatusLoading(true);
    try {
      let bodyData = {
        accountId: data?.account?.accountId,
        resourceName:
          data?.localServiceCampaignData?.results[0]?.campaign?.resourceName,
        status: value === 'active' ? 'ENABLED' : 'PAUSED',
      };

      const response = await statusGoogleServiceList({ bodyData });
      const { success, message } = response;
      if (success) {
        modalNotification({
          type: 'success',
          message,
        });
        getGoogleServiceData();
      }
    } catch (error) {
      console.log(error);
    }
    setStatusLoading(false);
  };

  async function onToggleMentalChange(value) {
    setToggleHealthLoading(true);
    try {
      const bodyData = {
        accountId: value?.account?.accountId,
        campaignId: value?.localServiceCampaignData?.results[0]?.campaign?.id,
        status: value?.spControlStatus === true ? false : true,
      };
      const response = await updateSPControlStatus({
        bodyData,
      });
      const { success, message } = response;
      if (success) {
        modalNotification({
          type: 'success',
          message,
        });
        getGoogleServiceData();
      }
    } catch (err) {
      return false;
    }
    setToggleHealthLoading(false);
  }
  const sortingTable = (e) => {
    let selectedData;
    const status = e.hasOwnProperty('status') ? 'status' : 'spControlStatus';
    if (status === 'status') {
      if (Array.isArray(e.status)) {
        selectedData = null;
      } else {
        selectedData = e?.status;
      }
    } else if (status === 'spControlStatus') {
      if (Array.isArray(e.spControlStatus)) {
        selectedData = null;
      } else {
        selectedData = e?.spControlStatus;
      }
    } else {
      selectedData = null;
    }
    const data = googleServiceList;
    if (![null].includes(selectedData) && status) {
      const getFilterData =
        status === 'status'
          ? data.filter(
              (v) =>
                v?.localServiceCampaignData?.results[0]?.campaign?.status ===
                selectedData
            )
          : data.filter((v) => String(v?.spControlStatus) === selectedData);
      setServiceListCopy(getFilterData);
    } else {
      setServiceListCopy();
    }
  };

  const columns = Columns.googleServiceListTableColumn({
    onFilterChange: (e) => sortingTable(e),
    Action: (props) =>
      actionFormatter([
        {
          name: t('text.googleConnect.viewLeads'),
          icon: 'icon ni ni-eye',
          id: props?.row?.account?.accountId,
          action: getActions('VIEW').value,
        },
      ]),

    Toggle: (props) => (
      <Toggle
        // isGoogleType={true}
        isDropDown={true}
        shouldDeleteInclude={false}
        defaultChecked={
          props?.row?.localServiceCampaignData?.results[0]?.campaign?.status ===
          'PAUSED'
            ? 'inactive'
            : 'active'
        }
        onChange={(e) => {
          onToggleChange(e, props?.row);
        }}
        loading={statusLoading}
      />
    ),
    ToggleHealth: (props) => (
      <Toggle
        defaultChecked={props?.row?.spControlStatus ? true : false}
        onChange={(e) => onToggleMentalChange(props?.row)}
        loading={healthToggleLoading}
      />
    ),
  });
  let exportData = googleServiceList.map((item) => {
    const AverageBudget = checkValidPrice(item?.account?.averageWeeklyBudget);
    return {
      Id: item?.account?.accountId,
      Business: item?.account?.businessName,
      'Average Weekly Budget': AverageBudget?.props?.children,
      'Total Review': item?.account?.totalReview,
      'Average Five Star Rating': item?.account?.averageFiveStarRating,
      'Current Period Charged Leads': item?.account?.currentPeriodChargedLeads,
      'Current Period Connected Phone Calls':
        item?.account?.currentPeriodConnectedPhoneCalls,
      'Current Period Phone Calls': item?.account?.currentPeriodPhoneCalls,
      'Current Period Total Cost': `${'$'}${
        item?.account?.currentPeriodTotalCost
      }`,
      'Impressions Last Two Days': item?.account?.impressionsLastTwoDays,
      'Phone Lead Responsiveness': item?.account?.phoneLeadResponsiveness,
      'Previous Period Charged Leads':
        item?.account?.previousPeriodChargedLeads,
      'Previous Period Connected Phone Calls':
        item?.account?.previousPeriodConnectedPhoneCalls,
      'Previous Period Phone Calls': item?.account?.previousPeriodPhoneCalls,
      'Previous Period Total Cost': item?.account?.currentPeriodTotalCost,
      Status:
        item?.localServiceCampaignData?.results[0]?.campaign?.status ===
        'PAUSED'
          ? 'Hide'
          : 'Show',
      'SP in control': item?.spControlStatus === true ? 'Yes' : 'No',
    };
  });

  const headerBtn = {
    add: {
      name: (
        <>
          <em className="icon ni ni-filter-fill me-1" />{' '}
          {t('text.common.filter')}
        </>
      ),
      handler: () => {
        OpenBtnFilter();
      },
    },
    csvExport: { handler: () => exportData },
  };
  const BackBtnFilter = () => {
    setGlobalFilter(false);
  };

  const OpenBtnFilter = () => {
    setGlobalFilter(true);
  };
  const onSubmitFilter = (values) => {
    setFilterData({ ...values });
    setGlobalFilter(false);
  };

  return (
    <>
      <PageContainerWrapper
        heading={t('text.googleConnect.titleGoogleService')}
        breadcrumb={breadcrumb}
        btn={headerBtn}
        btnArray={['create', 'csvExport']}
        extraClass="btn btn-primary d-block d-xl-none"
      >
        <div className="">
          <div className="globalFilters">
            <div className="card ">
              <div className="card-inner">
                <div
                  className={`globalFilters_inner ${
                    globalFilter ? 'filterToggle' : ''
                  }`}
                >
                  <div className="mb-3 d-block d-xl-none">
                    <Link to="#" onClick={() => BackBtnFilter()}>
                      <em className="icon ni ni-arrow-long-left me-1"></em>
                      {t('text.common.back')}
                    </Link>
                  </div>

                  <GoogleServiceFilter
                    onSubmit={onSubmitFilter}
                    setFilterData={setFilterData}
                    filterData={filterData}
                    setGlobalFilter={setGlobalFilter}
                  />
                </div>
                <div
                  className={` ${globalFilter ? 'toggle-overlay' : ''} `}
                  onClick={() => BackBtnFilter()}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <TablePlus
          useTableHook={false}
          hasLimit
          tableData={serviceListCopy ? serviceListCopy : googleServiceList}
          tableColumns={columns}
          tableLoader={loading}
          pagination={false}
          isSearch={false}
          resetAll={false}
          showPageSizeAction={false}
          locale={{
            emptyText: <Empty description={t('text.common.noDataFound')} />,
          }}
          sortBy={sortBy}
          sortType={sortType}
          // filter={filter}
        />
      </PageContainerWrapper>
    </>
  );
}

export default GoogleService;
