import { Checkbox } from 'antd';
import DatePicker from 'sassy-datepicker';

import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { CommonButton } from '../../UIElements/Button';
import '../../../../node_modules/sassy-datepicker/dist/styles.css';
import { dateFormatter } from '../../../utils';

function ChooseBookingForm({ onClose, t, show }) {
  const [date, setDate] = useState(new Date());

  const [value, setValue] = useState([]);
  const onSlotChange = (checkedValues) => {
    let data = { ...value };
    data = checkedValues;
    setValue(data);
    // if (checkedValues && checkedValues.length) setCategoriesError('');
  };

  const onChange = (newDate) => {
    console.log(dateFormatter(newDate, 'DD-MM-YYYY'));

    setDate(newDate);
  };

  const Time = [
    { availablity: 0, time: '5:00 AM - 7:00 AM', id: 1 },
    { availablity: 1, time: '5:00 AM - 7:00 AM', id: 2 },
    { availablity: 0, time: ' 5:00 AM - 7:00 AM', id: 3 },
    { availablity: 0, time: ' 5:00 AM - 7:00 AM', id: 4 },
    { availablity: 1, time: ' 5:00 AM - 7:00 AM', id: 5 },
    { availablity: 0, time: ' 5:00 AM - 7:00 AM', id: 6 },
    { availablity: 0, time: ' 5:00 AM - 7:00 AM', id: 7 },
    { availablity: 0, time: ' 5:00 AM - 7:00 AM', id: 8 },
    { availablity: 0, time: ' 5:00 AM - 7:00 AM', id: 9 },
    { availablity: 0, time: ' 5:00 AM - 7:00 AM', id: 10 },
    { availablity: 0, time: ' 5:00 AM - 7:00 AM', id: 11 },
    { availablity: 0, time: ' 5:00 AM - 7:00 AM', id: 12 },
    { availablity: 0, time: ' 5:00 AM - 7:00 AM', id: 13 },
    { availablity: 0, time: ' 5:00 AM - 7:00 AM', id: 14 },
  ];

  const onSubmit = () => {
    console.log(value);
    show();
  };

  return (
    <Row>
      <Col lg={6} sm={12}>
        <div className="selectDate">
          <h3 className="selectDate_heading">Select Date</h3>
          <div className="selectDate_content">
            <DatePicker onChange={onChange} value={date} />
          </div>
        </div>
      </Col>
      <Col lg={6} sm={12}>
        <div className="selectTime mt-lg-0 mt-3 d-flex flex-column selectHeight justify-content-between">
          <h3 className="selectDate_heading">
            {t('text.customerService.time')}
          </h3>
          <div className="d-flex flex-column selectHeight justify-content-between">
            <div className="selectTime_content">
              <Row>
                <Checkbox.Group
                  className="row"
                  onChange={(e) => {
                    onSlotChange(e);
                  }}
                >
                  <>
                    {Time?.map((item, key) => {
                      if (item?.availablity === 0) {
                        return (
                          <div className="col-xl-4 col-6" key={key}>
                            <div className="slot">
                              <Checkbox
                                id="checked"
                                name="checked"
                                value={item?.id}
                                validation={false}
                              >
                                {item?.time}
                              </Checkbox>
                            </div>
                          </div>
                        );
                      } else
                        return (
                          <div className="col-xl-4 col-6" key={key}>
                            <div className="slot">
                              <Checkbox
                                id="checked"
                                name="checked"
                                value={item?.id}
                                disabled={true}
                                validation={false}
                              >
                                {item?.time}
                              </Checkbox>
                            </div>
                          </div>
                        );
                    })}
                  </>
                </Checkbox.Group>
              </Row>
            </div>
            <div className="d-flex justify-content-end align-items-end chooseBookingmodal_button">
              <CommonButton className="btn btn-light" onClick={onClose}>
                {t('text.common.cancel')}
              </CommonButton>
              <CommonButton
                className="btn btn-primary ms-3"
                onClick={() => {
                  onSubmit();
                }}
              >
                {t('text.customerService.continue')}
              </CommonButton>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default ChooseBookingForm;
