import i18next from 'i18next';
import { accessRoute } from '../../routes/Dashboard';

import { getFullName } from '../../utils/text.util';
import { getInputSearchFilter } from '../../components';
import { capitalizeFirstLetter } from '../../utils';
import { checkValidPrice } from '../../components/UIElements/Formatter';

export const providerSalesLeadersBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.dashboard.providers'),
    },
  ];
};

export const providerSalesLeadersTableColumn = ({
  onFilterChange,
  filter = {},
}) => {
  return [
    {
      dataIndex: 'providerName',
      title: i18next.t('text.common.providerName'),
      headerClasses: 'sorting',
      render: (_, row) =>
        getFullName(row?.Provider?.firstName, row?.Provider?.lastName),
      ...getInputSearchFilter({
        dataIndex: 'providerName',
        placeholder: 'provider name',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'locationName',
      title: i18next.t('text.quote.locationName'),
      headerClasses: 'sorting',
      sorter: true,
      render: (_, row) =>
        row?.locationName ? capitalizeFirstLetter(row?.locationName) : '-',
    },
    {
      dataIndex: 'bookingCount',
      title: i18next.t('text.dashboard.JobCount'),
      headerClasses: 'sorting',
      sorter: true,
      render: (_, row) => (row?.bookingCount ? row?.bookingCount : '-'),
    },
    {
      dataIndex: 'bookingTotal',
      title: i18next.t('text.dashboard.totalSales'),
      headerClasses: 'sorting',
      sorter: true,
      render: (_, row) => checkValidPrice(row?.bookingTotal),
    },
    {
      dataIndex: 'bookingAvg',
      title: i18next.t('text.dashboard.avgTicket'),
      headerClasses: 'sorting',
      sorter: true,
      render: (_, row) => checkValidPrice(row?.bookingAvg),
    },
  ];
};
