import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  EditBankingDetailsForm,
  GlobalLoader,
} from '../../../../../components';
import {
  editBankingDetailsService,
  getBankingDetailsService,
} from '../../../../../services/Providers/provider.service';

import { logger, modalNotification } from '../../../../../utils';
import { firstLetterCaps } from '../../../../../utils/text.util';

function BankingDetails({ defaultKey }) {
  // const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const param = useParams();
  const { id } = param;
  const [formData, setFormData] = useState();

  async function onSubmit(values) {
    setIsLoading(true);
    try {
      const response = await editBankingDetailsService({ id, values });
      const { success, message } = response;
      if (success) {
        modalNotification({
          type: 'success',
          message,
        });
        getBankingDetails(id);
      }
    } catch (error) {
      logger(error);
    }
    setIsLoading(false);
  }

  const getBankingDetails = async (id) => {
    setFormLoading(true);
    try {
      const response = await getBankingDetailsService(id);
      const { success, data } = response;
      if (success) {
        setFormData(data);
      }
    } catch (error) {
      logger(error);
    }
    setFormLoading(false);
  };

  useEffect(() => {
    if (defaultKey === 'bankingDetails') {
      getBankingDetails(id);
    }
  }, [defaultKey]);

  if (formLoading) {
    return <GlobalLoader />;
  } else if (formData && Object.keys(formData).length > 0) {
    return (
      <div className="nk-block">
        <div className="card">
          <div className="card-inner">
            <BankDetailCard data={formData} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="wide-md mx-auto">
      <EditBankingDetailsForm
        onSubmit={onSubmit}
        isLoading={isLoading}
        formData={formData}
      />
    </div>
  );
}

export default BankingDetails;

const BankDetailCard = ({ data }) => {
  const { t } = useTranslation();
  return (
    <>
      <Row>
        <Col md={6}>
          <ul className="list-unstyled mb-0">
            <li>
              <label className="sub-text">{t('text.provider.bankName')}</label>
              {firstLetterCaps(data?.bankName)}
            </li>
            <li>
              <label className="sub-text">
                {t('text.provider.bankHolderName')}
              </label>
              {data?.bankAccountHolderName || '-'}
            </li>
            <li>
              <label className="sub-text">
                {t('text.provider.accountNumber')}
              </label>
              {data?.accountNumber || '-'}
            </li>
          </ul>
        </Col>
        <Col md={6}>
          <ul className="list-unstyled">
            <li>
              <label className="sub-text">
                {t('text.provider.routingNumber')}
              </label>{' '}
              {data?.bankAccountRoutingNumber || '-'}
            </li>
            <li>
              <label className="sub-text">
                {t('text.provider.bankLocation')}
              </label>{' '}
              {data?.bankLocation || '-'}
            </li>
            <li>
              <label className="sub-text">{t('text.common.email')}</label>{' '}
              {data?.email || '-'}
            </li>
          </ul>
        </Col>
      </Row>
    </>
  );
};
