import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';
import {
  EMAIL_TEMPLATE_ENDPOINT,
  SMS_TEMPLATE_ENDPOINT,
} from '../../apiEndpoints/index.endpoint';

export const listEmailService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...EMAIL_TEMPLATE_ENDPOINT.getEmailTemplateList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const editEmailService = async ({ body, id }) => {
  try {
    const payload = {
      ...EMAIL_TEMPLATE_ENDPOINT.editEmailTemplate(id),
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const viewEmailService = async ({ id }) => {
  try {
    const payload = {
      ...EMAIL_TEMPLATE_ENDPOINT?.getEmailTemplateDetails(id),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

// For sms template

export const listSmsService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...SMS_TEMPLATE_ENDPOINT.getSmsTemplateList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const editSmsService = async ({ body, id }) => {
  try {
    const payload = {
      ...SMS_TEMPLATE_ENDPOINT.editSmsTemplate(id),
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const viewSmsService = async ({ id }) => {
  try {
    const payload = {
      ...SMS_TEMPLATE_ENDPOINT?.getSmsTemplateDetails(id),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const viewShortCodeService = async () => {
  try {
    const payload = {
      ...SMS_TEMPLATE_ENDPOINT?.getSmsShortCodeList,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
