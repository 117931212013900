import React, { useContext, useEffect, useState } from 'react';
import {
  ModalWrapper,
  FromDateToDateFilter,
  PageContainerWrapper,
  SweetAlert,
  TablePlus,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import {
  Columns,
  NewEquipmentListService,
  getActions,
  putUpdateEquipmentStatusService,
} from '../../../services/index.service';
import useTable from '../../../hooks/useTable.hook';
import InstalledEquipment from '../View';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import { logger, modalNotification } from '../../../utils';
import { PermissionContext } from '../../../context/permission.context';
import { useSelector } from 'react-redux';

export default function NewEquipmentInstall() {
  const { t } = useTranslation();
  const [fetchAPI, setFetchAPI] = useState(false);
  const [state, setState] = useState({
    loader: null,
    modal: null,
    modalId: null,
    selectedRows: [],
    submitText: 'add',
    options: {},
    isAlertVisible: false,
    updateId: null,
    updateStatus: 'save',
    isLoading: false,
    globalFilter: false,
  });
  const [filterValue, setFilter] = useState({
    fromDate: '',
    toDate: '',
    location: '',
    dateType: undefined,
  });
  const formRef = useRef(null);
  const breadcrumb = Columns.newEquipmentBreadcrumb();
  const permissionContext = useContext(PermissionContext);

  const action = permissionContext;
  const userType = useSelector((state) => state?.auth?.userData?.Role?.type);
  const [tableHandler, tableProps] = useTable(NewEquipmentListService);
  const {
    count,
    data,
    page,
    noOfPages,
    sizePerPage,
    filter,
    isLoading,
    sortBy,
    sortType,
  } = tableProps;
  const { onFilterResetChange, onTableSort, onFilterChange, onRefresh } =
    tableHandler;

  const OpenBtnFilter = () => {
    handleStateChange('globalFilter', true);
  };

  const headerBtn = {
    add: {
      name: (
        <>
          <em className="icon ni ni-filter-fill me-1" />{' '}
          {t('text.common.filter')}
        </>
      ),
      handler: () => {
        OpenBtnFilter();
      },
    },
  };

  const handleStateChange = (name, value) => {
    if (typeof name === 'string') {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        ...name,
      }));
    }
  };

  const handleChangeOption = (name, value, id) => {
    setState((prevState) => ({
      ...prevState,
      options: {
        ...prevState.options,
        [id]: {
          ...prevState.options?.[id],
          [name]: value,
        },
      },
    }));
  };

  const BackBtnFilter = () => {
    handleStateChange('globalFilter', false);
  };

  const onReset = () => {
    const resetData = {
      fromDate: '',
      toDate: '',
      location: '',
      dateType: undefined,
    };
    setFilter({
      ...resetData,
    });
    onFilterChange(resetData);
    handleStateChange('globalFilter', false);
  };

  function onAction(e, data) {
    e.preventDefault();
    setState({
      ...state,
      modal: 'equipment',
      selectedRows: data,
    });
  }

  const actionFormatter = (options, data) => {
    return (
      <Link to="#" onClick={(e) => onAction(e, data)} className="text-primary">
        {options}
      </Link>
    );
  };
  const isUserCanEdit =
    (action && action?.arr?.includes(getActions('CREATE').value)) ||
    userType === 'admin';
  const NewEquipmentColumn = Columns.NewEquipmentInstallTableColumn({
    onSort: onTableSort,
    onFilterChange: onFilterChange,
    saveUpdates,
    cancelUpdates,
    formRef,
    filter,
    Action: (props) => actionFormatter(props.cell, props.row),
    page,
    sizePerPage,
    options: state.options,
    handleChangeOption,
    isUserCanEdit,
  });

  function saveUpdates(id) {
    handleStateChange('updateId', id);
    handleStateChange('isAlertVisible', true);
  }

  function cancelUpdates(id) {
    let prevOptions = { ...state.options };
    delete prevOptions[id];
    setState((prevState) => ({
      ...prevState,
      options: prevOptions,
    }));
  }

  const onCancel = () => {
    handleStateChange('modal', '');
  };

  const addModalHide = () => {
    handleStateChange('modal', '');
  };

  const onConfirmAlert = async () => {
    try {
      handleStateChange('isLoading', true);
      const bodyData = {
        permitStatus: '',
        warrantyRegistered: '',
        laborWarrantyPurchased: '',
        ...state.options[state.updateId],
      };
      const response = await putUpdateEquipmentStatusService({
        bodyData,
        id: state.updateId,
      });
      if (response?.success) {
        handleStateChange({ isLoading: false, isAlertVisible: false });
        onRefresh();
        modalNotification({
          type: 'success',
          message: response?.message,
        });
        cancelUpdates(state.updateId);
        return true;
      }
    } catch (error) {
      handleStateChange('isLoading', false);
      modalNotification({
        type: 'error',
        message: error?.message,
      });
    }
  };
  const onSubmitFilter = (values) => {
    try {
      let val = {
        ...values,
      };
      setFilter((prevState) => ({
        ...prevState,
        ...val,
      }));
      onFilterChange(values);
      handleStateChange('globalFilter', false);
    } catch (error) {
      logger(error);
    }
  };

  function onTableResetFilter() {
    const resetData = {
      fromDate: '',
      toDate: '',
      location: '',
      dateType: undefined,
    };
    setFilter({
      ...resetData,
    });
    onFilterChange(resetData);
    onFilterResetChange();
  }

  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      setFetchAPI(true);
    }
  });

  useEffect(() => {
    if (fetchAPI) {
      onRefresh();
      setFetchAPI(false);
    }
  }, [fetchAPI]);

  return (
    <>
      <PageContainerWrapper
        heading={t('text.equipmentInstall.title')}
        breadcrumb={breadcrumb}
        btn={headerBtn}
        extraClass="btn btn-primary d-block d-xl-none"
      >
        <div className="">
          <div className="globalFilters">
            <div className="card ">
              <div className="card-inner">
                <div
                  className={`globalFilters_inner ${
                    state.globalFilter ? 'filterToggle' : ''
                  }`}
                >
                  <div className="mb-3 d-block d-xl-none">
                    <Link to="#" onClick={() => BackBtnFilter()}>
                      <em className="icon ni ni-arrow-long-left me-1"></em>
                      {t('text.common.back')}
                    </Link>
                  </div>
                  <FromDateToDateFilter
                    onSubmit={onSubmitFilter}
                    onReset={onReset}
                    loading={isLoading}
                    filterValue={filterValue}
                    setFilter={setFilter}
                    isEnabledFields={['dateType']}
                  />
                </div>
                <div
                  className={` ${state.globalFilter ? 'toggle-overlay' : ''} `}
                  onClick={() => BackBtnFilter()}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className="tableScrollHide">
          <TablePlus
            hasLimit
            noOfPage={noOfPages}
            sizePerPage={sizePerPage}
            page={page}
            count={count}
            tableData={data}
            tableColumns={NewEquipmentColumn}
            onFilterResetChange={onTableResetFilter}
            selectRow
            showAction={false}
            tableLoader={isLoading}
            filter={filter}
            sortBy={sortBy}
            sortType={sortType}
            scrollTopPosition
          />
        </div>
        <ModalWrapper
          show={['equipment'].includes(state.modal)}
          onCancel={onCancel}
          content={
            <InstalledEquipment
              selectedRows={state.selectedRows}
              addModalHide={addModalHide}
            />
          }
          closeButton={true}
          title={t('text.equipmentInstall.installedEquipment')}
          extraTitleClassName="justify-content-center"
        />
        {state.isAlertVisible && (
          <SweetAlert
            title={i18next.t('text.common.areYouSure')}
            text={i18next.t('text.common.youWantToSave')}
            show={state.isAlertVisible}
            icon="warning"
            showCancelButton
            confirmButtonText="Yes"
            cancelButtonText="No"
            setIsAlertVisible={(value) =>
              handleStateChange('isAlertVisible', value)
            }
            showLoaderOnConfirm={state.isLoading}
            loading={state.isLoading}
            onConfirmAlert={onConfirmAlert}
          />
        )}
      </PageContainerWrapper>
    </>
  );
}
