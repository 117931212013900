import { APIRequest } from '../../helpers/index.helper';
import { LOG_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { logger } from '../../utils';

export const LogViewListService = async () => {
  try {
    const payload = {
      ...LOG_ENDPOINT.getLogApi,
    };
    const res = await APIRequest(payload);
    return res?.data;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const LogViewDetailsService = async ({ queryParams }) => {
  try {
    const payload = {
      ...LOG_ENDPOINT.getLogDetailApi,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res?.data;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const LogViewCountService = async ({ queryParams }) => {
  try {
    queryParams = {
      fileName: queryParams.fileName,
    };
    const payload = {
      ...LOG_ENDPOINT.getCountApi,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res?.data;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
