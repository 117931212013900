import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CommonButton, CustomTable } from '../../../../../components';
import useTable from '../../../../../hooks/useTable.hook';
import {
  Columns,
  updateProviderDiscountService,
} from '../../../../../services/index.service';
import { modalNotification } from '../../../../../utils';

export default function JobProviderDiscount({
  providerDiscountData,
  setIsOpenModal,
  getData,
}) {
  const jobProvideData = [providerDiscountData];
  const [discount, setDiscount] = useState('');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const refForm = useRef();

  const columns = Columns.columns({
    refForm,
    setDiscount,
  });
  const submitForm = async () => {
    setLoading(true);
    try {
      let bodyData = {
        discount: discount,
      };
      const response = await updateProviderDiscountService(
        providerDiscountData?.id,
        bodyData
      );
      if (response?.success) {
        modalNotification({
          type: 'success',
          message: t('text.report.discountMessage'),
        });
        setIsOpenModal('');
        getData();
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <>
      {/* <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={jobProvideData}
        tableColumns={columns}
        selectRow
        setSizePerPage=""
        isModalPadding={true}
        isModalShadow={true}
        showAction={false}
        resetAll={false}
        showPageSizeAction={false}
        pagination={false}
      /> */}
      <CustomTable
        tableData={jobProvideData}
        tableColumns={columns}
        count="100"
        setPage="1"
        sizePerPage="10"
        currentPage="10"
        isModalPadding={true}
        hasLimit
        modalParams={false}
        showPageSizeAction={false}
        pagination={false}
      />
      <div className="text-end mt-3">
        <CommonButton
          type="submit"
          onClick={() => {
            if (discount >= 0 && discount !== '') {
              submitForm();
            }
          }}
          className="btn btn-primary"
          loading={loading}
        >
          {t('text.common.submit')}
        </CommonButton>
      </div>
    </>
  );
}
