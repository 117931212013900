import loadable from '@loadable/component';
import FullPageLoader from '../UIElements/Loader/fullPageLoader';

export const PageLoader = loadable(() => import('./PageLoader/index.wrapper'), {
  fallback: <FullPageLoader />,
});
export const SidebarWrapper = loadable(
  () => import('./SidebarLayout/sidebar.wrapper'),
  {
    fallback: <FullPageLoader />,
  }
);
export const GlobalWrapper = loadable(
  () => import('./PageWrapper/page.wrapper'),
  {
    fallback: <FullPageLoader />,
  }
);
export const ErrorWrapper = loadable(
  () => import('./ErrorBoundary/index.boundary'),
  {
    fallback: <FullPageLoader />,
  }
);
export const AuthGuardWrapper = loadable(
  () => import('./AuthGuard/authGuard.wrapper'),
  {
    fallback: <FullPageLoader />,
  }
);
export const FormWrapper = loadable(() => import('./Form/index.wrapper'), {
  fallback: <FullPageLoader />,
});
export const FormElementWrapper = loadable(
  () => import('./FormElement/index.wrapper'),
  {
    fallback: <FullPageLoader />,
  }
);
export const ShimmerText = loadable(() => import('./Shimmer'), {
  fallback: <FullPageLoader />,
});
export const ActionWrapper = loadable(
  () => import('./ActionWrapper/action.wrapper'),
  {
    fallback: <FullPageLoader />,
  }
);
export const ModalWrapper = loadable(
  () => import('./ModalWrapper/index.wrapper'),
  {
    fallback: <FullPageLoader />,
  }
);
export const PageContainerWrapper = loadable(
  () => import('./PageContainer/index.wrapper'),
  {
    fallback: <FullPageLoader />,
  }
);
export const StorePageContainer = loadable(
  () => import('./StorePageContainer.js'),
  {
    fallback: <FullPageLoader />,
  }
);
