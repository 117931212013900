import React, { useEffect, useState, useRef } from 'react';
import { compose, lifecycle } from 'recompose';
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Polygon,
  Marker,
} from 'react-google-maps';
import { DrawingManager } from 'react-google-maps/lib/components/drawing/DrawingManager';
import { MAP } from 'react-google-maps/lib/constants';
import { StandaloneSearchBox } from 'react-google-maps/lib/components/places/StandaloneSearchBox';
import { CommonButton } from '../UIElements/Button';

function getObjectValue(obj, path, defaultValue) {
  const keys = path.split('.');
  let value = obj;

  for (let key of keys) {
    if (value && typeof value === 'object' && key in value) {
      value = value[key];
    } else {
      return defaultValue;
    }
  }

  return value;
}

const MapComponent = compose(
  lifecycle({
    componentWillMount() {
      const refs = {};

      this.setState({
        bounds: null,
        location: {
          lat: -34.397,
          lng: 150.644,
        },
        searchLocation: {},
        defaultZoom: 4,
        states: [],
        onHandleChangeState: (e) => {
          this.setState({
            states: e,
          });
        },
        onMapMounted: (ref) => {
          refs.map = ref;
        },
        onBoundsChanged: () => {
          this.setState({
            bounds: refs.map.getBounds(),
            location: refs.map.getCenter(),
          });
        },
        onSearchBoxMounted: (ref) => {
          refs.searchBox = ref;
        },
        onPlacesChanged: () => {
          const places = refs.searchBox.getPlaces();
          const bounds = new window.google.maps.LatLngBounds();

          places.forEach((place) => {
            if (place.geometry.viewport) {
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });
          const nextMarkers = places.map((place) => ({
            position: place.geometry.location,
          }));
          const nextCenter = getObjectValue(
            nextMarkers,
            '0.position',
            this.state.location
          );

          const newLocation = {
            lat: nextCenter.lat(),
            lng: nextCenter.lng(),
          };

          this.setState({
            location: newLocation,
            searchLocation: newLocation,
            defaultZoom: 10,
          });
          refs.map.fitBounds(bounds);
        },
        updateLocation: (newLocation) => {
          this.setState({
            ...this.state,
            location: newLocation,
          });
        },
        ...this,
        refs,
      });
    },
  }),
  withScriptjs,
  withGoogleMap
)(
  ({
    address,
    oldPolygon,
    setPolygon,
    props,
    onSearchBoxMounted,
    bounds,
    onPlacesChanged,
    location,
    updateLocation,
    searchLocation,
    defaultZoom,
    states,
    onHandleChangeState,
  }) => {
    const [add, setAdd] = useState([]);

    const formRef = useRef(null);
    const mapRef = useRef(null);

    useEffect(() => {
      if (address) {
        updateLocation(address, formRef?.current);
      }
      updatePolygon(oldPolygon);
    }, [address]);

    function updatePolygon(oldPoly) {
      if (oldPoly) {
        const lat = oldPoly?.lat?.split(',');
        const lng = oldPoly?.lng?.split(',');
        const loc = [];
        if (!Array.isArray(lat)) {
          return false;
        }
        lat.forEach((e, i) => {
          loc.push({ lat: e, lng: lng[i] });
        });
        setAdd(loc);
      }
    }

    useEffect(() => {
      if (oldPolygon?.lat && oldPolygon?.lng) {
        const lat = oldPolygon?.lat.split(',');
        const lng = oldPolygon?.lng.split(',');
        const data = (lat || []).reduce((pre, curr, index) => {
          const arr = [...pre];
          arr.push({ lat: Number(lat[index]), lng: Number(lng[index]) });
          return arr;
        }, []);
        setAdd([...data]);
      }
    }, [oldPolygon]);

    function updateLocation(address, map) {
      try {
        getCountry(address, map);
      } catch (error) {
        console.log(error);
      }
    }

    function getCountry(address) {
      try {
        const geocoder = new window.google.maps.Geocoder();
        if (address?.type && address?.coordinates) {
          const { coordinates } = address;
          const bounds = new window.google.maps.LatLngBounds();
          coordinates[0].forEach((el) => {
            const latLng = new window.google.maps.LatLng(el[0], el[1]);
            bounds.extend(latLng);
          });
          mapRef?.current?.context[MAP]?.fitBounds(bounds);
          // setLocation({ lat: coordinates[0][0][0], lng: coordinates[0][0][1] })
        }
        geocoder.geocode({ address: address }, (results, status) => {
          if (status === 'OK') {
            updateLocation({
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            });
          } else {
            alert(
              'Geo code was not successful for the following reason: ' + status
            );
          }
        });
      } catch (error) {
        console.log(error);
      }
    }

    function onPolygonCompleted(e) {
      try {
        const lat = [];
        const lng = [];
        const latLng = [];
        onHandleChangeState([...states, e]);

        for (let i = 0; i < e.getPath().getLength(); i++) {
          let latlong = e.getPath().getAt(i).toUrlValue().split(',');
          lat.push(latlong[0]);
          lng.push(latlong[1]);
          latLng.push([Number(latlong[0]), Number(latlong[1])]);
        }
        latLng.push(latLng[0]);
        setAdd({ lat: lat.join(','), lng: lng.join(',') });

        setPolygon({
          lat: lat.join(','),
          lng: lng.join(','),
          latLng: latLng,
        });
      } catch (err) {
        console.log(err);
      }
    }

    useEffect(() => {
      var head = document.getElementsByTagName('head')[0];
      var insertBefore = head.insertBefore;
      head.insertBefore = function (newElement, referenceElement) {
        if (
          newElement.href &&
          newElement.href.indexOf(
            'https://fonts.googleapis.com/css?family=Roboto'
          ) === 0
        ) {
          return;
        }
        insertBefore.call(head, newElement, referenceElement);
      };
    }, []);

    useEffect(() => {
      if (
        props?.props?.mapLocation &&
        Object.keys(props?.props?.mapLocation)?.length > 0
      ) {
        updateLocation(props.props.mapLocation);
      }
    }, [props.props?.mapLocation]);

    const handleRemove = () => {
      states.forEach((e) => e.setMap(null));
      setAdd([]);
    };

    return (
      <div className="relative">
        <GoogleMap
          defaultZoom={defaultZoom}
          zoom={defaultZoom}
          defaultCenter={{ ...location }}
          center={location}
          ref={mapRef}
        >
          <StandaloneSearchBox
            ref={onSearchBoxMounted}
            bounds={bounds}
            onPlacesChanged={onPlacesChanged}
          >
            <input
              type="text"
              placeholder="Enter Location For More Focus"
              className="mapSearch"
            />
          </StandaloneSearchBox>
          <Polygon
            paths={add}
            strokeColor="#0000FF"
            strokeOpacity={0.8}
            strokeWeight={2}
            fillColor="#0000FF"
            fillOpacity={0.35}
          />

          <DrawingManager
            drawingMode="polygon"
            onPolygonComplete={onPolygonCompleted}
            defaultOptions={{
              drawingControl: true,
              drawingControlOptions: {
                position: window.google.maps.ControlPosition.TOP_RIGHT,
                drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
              },
              circleOptions: {
                fillColor: `#ffff00`,
                fillOpacity: 1,
                strokeWeight: 5,
                clickable: false,
                editable: true,
                zIndex: 1,
              },
            }}
          />
          {Object?.keys?.(searchLocation)?.length > 0 && (
            <Marker position={searchLocation} />
          )}
        </GoogleMap>
        <div className="text-center mt-2">
          <CommonButton
            type="button"
            onClick={() => {
              handleRemove();
            }}
            className="btn-primary"
          >
            Delete Selected Shape
          </CommonButton>
        </div>
      </div>
    );
  }
);

export default MapComponent;
