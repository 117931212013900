import React from 'react';
import { useParams } from 'react-router-dom';
import EditSubServiceCategory from '../../../PriceManagement/ManageService/ServiceCategory/SubServiceCategory/Edit';

function EditNCASubCategory() {
  const { userId } = useParams();
  return <EditSubServiceCategory userId={userId} ncaEdit={true} />;
}

export default EditNCASubCategory;
