import React from 'react';
import { PhotoAddForm } from '../../../../../components';
import { addPostService } from '../../../../../services/GoogleConnect/googleGMB.service';
import { useLocation } from 'react-router-dom';
import { decodeQueryData, modalNotification } from '../../../../../utils';
import { useState } from 'react';

function GMBPostAdd({ createPostModalHide, getBusinessPostData }) {
  const [learnUrl, setLearnUrl] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const params = decodeQueryData(location?.search);
  const { accountId, name } = params;
  let postUpload = true;

  const onSubmit = async (value) => {
    setLoading(true);
    try {
      let bodyData = {
        accountId: accountId,
        name: name,
        summary: value.description,
        sourceUrl: value.postImage,
        url: learnUrl === true ? value.url : null,
        urlAttachment: learnUrl === true ? 'yes' : 'no',
      };

      const response = await addPostService({ bodyData });
      const { success, message } = response;
      if (success) {
        modalNotification({
          type: 'success',
          message,
        });
        createPostModalHide();
        getBusinessPostData();
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  return (
    <>
      <div className="wide-md mx-auto">
        <PhotoAddForm
          postUpload={postUpload}
          onSubmit={onSubmit}
          createPostModalHide={createPostModalHide}
          setLearnUrl={setLearnUrl}
          learnUrl={learnUrl}
          loading={loading}
        />
      </div>
    </>
  );
}

export default GMBPostAdd;
