import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';

import DatePicker from 'sassy-datepicker';
import notification from '../../../utils/notifications';

import { getTimeSlotLists } from '../../../services/CustomerApp/index.service';
import { convertTo12HourFormat, logger } from '../../../utils';
import { useEffect } from 'react';
import { Spin } from 'antd';
import { NoDataFound } from '../../../components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const DateTimeModal = (props) => {
  const { t } = useTranslation();
  const { handleContinueDateTime, state } = props;

  const userId = useSelector(
    (state) => state?.auth?.customerData?.Customer?.userId
  );

  const [isLoading, setIsLoading] = useState(false);

  let result = new Date(
    new Date().getTime() +
      (state?.serviceBlockDay === 0
        ? 1
        : Math.abs(state?.serviceBlockDay + 1)) *
        24 *
        60 *
        60 *
        1000
  );
  const [details, setDetails] = useState({
    date: result,
    time: '',
  });
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedSlotId, setSelectedSlotId] = useState(null);

  const handleSelect = (type, value) => {
    setDetails({
      ...details,
      [type]: value,
    });
  };

  const getTimeSlotDetails = async () => {
    try {
      setIsLoading(true);
      let query = {
        userId,
        current: details?.date?.getDay(),
        fromDate: moment(details?.date).format('YYYY-MM-DD'),
      };

      const response = await getTimeSlotLists(query);
      setTimeSlots(response?.data?.rows[0]?.slots);
      setIsLoading(false);
    } catch (error) {
      logger(error);
      setIsLoading(false);
    }
  };

  const handleContinue = (e) => {
    e.preventDefault();
    if (!selectedSlotId) {
      notification({
        type: 'error',
        message: 'The meridian time is required.',
      });
    } else {
      handleContinueDateTime({
        bookingDate: moment(details?.date).format('YYYY-MM-DD'),
        slotId: selectedSlotId,
        slotTime: timeSlots?.find((timeItem) => timeItem?.id === selectedSlotId)
          ?.slotTime,
      });
    }
  };
  useEffect(() => {
    getTimeSlotDetails();
  }, []);

  useEffect(() => {
    setSelectedSlotId(null);
    getTimeSlotDetails();
  }, [details.date]);

  return (
    <div>
      <Row>
        <Col lg={6} sm={12}>
          <div className="selectDate">
            <h3 className="selectDate_heading">
              {t('text.common.selectDate')}
            </h3>
            <div className="selectDate_content">
              <DatePicker
                value={details.date}
                onChange={(date) => handleSelect('date', date)}
                minDate={moment().subtract(
                  state?.serviceBlockDay === 0
                    ? -1
                    : -Math.abs(state?.serviceBlockDay + 1),
                  'day'
                )}
              />
            </div>
          </div>
        </Col>
        <Col lg={6} sm={12}>
          <div className="selectTime mt-lg-0 mt-3 d-flex flex-column selectHeight justify-content-between">
            <h3 className="selectDate_heading">
              {t('text.common.selectTime')}
            </h3>
            <div className="d-flex flex-column selectHeight justify-content-between">
              {!isLoading && timeSlots?.length > 0 ? (
                <div className="selectTime_content">
                  <Row>
                    {timeSlots?.map((slotItem, idx) => (
                      <Col
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedSlotId(slotItem?.id);
                        }}
                        key={idx}
                        xl={4}
                        xs={6}
                      >
                        <div className="slot">
                          <input
                            type="checkbox"
                            id={slotItem?.id}
                            name={slotItem?.id}
                            value={slotItem?.id}
                            checked={true}
                          />
                          <label
                            className={`${
                              selectedSlotId === slotItem?.id
                                ? 'ant-checkbox-wrapper-checked'
                                : ''
                            }`}
                            htmlFor={slotItem?.id}
                          >
                            {convertTo12HourFormat(slotItem?.slotTime)}
                          </label>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              ) : !isLoading && !timeSlots?.length ? (
                <NoDataFound
                  source={`/no-time.png`}
                  text={t('text.common.noSlotFound')}
                  extraClass="noDataFoundWrapper w-100"
                  isCustomer={'/assets/images/admin/no-time.png'}
                />
              ) : (
                <Spin />
              )}
              <div className="d-flex justify-content-end align-items-end chooseBookingmodal_button">
                <Link
                  className="btn btn-primary mt-3"
                  to="#"
                  onClick={handleContinue}
                >
                  {t('text.common.continue')}
                </Link>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DateTimeModal;
