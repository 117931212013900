import { Input } from 'antd';
import React, { useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import { CommonButton } from '../../UIElements/Button';
import AntTextArea from '../../UIElements/TextArea';
import { FormElementWrapper, FormWrapper } from '../../Wrappers';

function BookingDetailsForm({ onClose, t, show }) {
  const [promoCode, setPromoCode] = useState('');
  const [applied, setApplied] = useState(false);
  const initialValues = {
    instruction: '',
  };

  const onSubmit = (values) => {
    console.log('values', values);
  };

  const onPromoCodeApply = () => {
    setApplied(true);
  };

  // console.log(promoCode);

  return (
    <FormWrapper
      row={true}
      extraClassName="g-3"
      initialValues={{ ...initialValues }}
      onSubmit={onSubmit}
      // enableReinitialize={true}
      // validation={(e) => validation(e, params?.id)}
      // data={formData}
    >
      {(props) => {
        return (
          <>
            <div className="promoCode">
              <div className="d-flex align-items-end">
                <FormGroup className="form-group promoCode_wrapper mb-0">
                  <label className="ant-label font-md">
                    {t('text.customerService.promoCode')}
                  </label>
                  <Input
                    placeholder="Promo Code"
                    value={promoCode}
                    onChange={(e) => setPromoCode(e.target.value)}
                  />
                </FormGroup>
                {!applied ? (
                  <CommonButton
                    variant="primary"
                    // type="submit"
                    onClick={onPromoCodeApply}
                    className="ms-2 font-sm d-inline-block"
                  >
                    {t('text.customerService.apply')}
                  </CommonButton>
                ) : (
                  <CommonButton
                    variant="primary"
                    // type="submit"
                    onClick={() => {
                      setApplied(false);
                      setPromoCode('');
                    }}
                    className="ms-2 font-sm d-inline-block"
                  >
                    Remove Code
                  </CommonButton>
                )}
              </div>
              {applied && (
                <div className="promoCode_info mt-2 d-flex align-items-center">
                  <em className="gg-check-circle me-2" />{' '}
                  <span> {t('text.customerService.promoCodeApplied')}</span>
                </div>
              )}
            </div>
            <FormElementWrapper
              md={12}
              extraLabel={t('text.customerService.addInstruction')}
              extraLabelClass="ant-label font-md ps-0"
              element={() => (
                <AntTextArea
                  placeholder={t('text.customerService.addInstruction')}
                  formik={props}
                  name="instruction"
                  extraClassName="ant-input ant-input-lg"
                  rows={4}
                />
              )}
            />
            <div className="d-flex justify-content-end align-items-end mt-lg-4 mt-3  chooseBookingmodal_button">
              <CommonButton className="btn btn-light" onClick={onClose}>
                {t('text.common.cancel')}
              </CommonButton>
              <CommonButton
                variant="primary"
                type="submit"
                className=" min-200 ms-3"
                onClick={show}
              >
                {t('text.customerService.confirmBooking')}
              </CommonButton>
            </div>
          </>
        );
      }}
    </FormWrapper>
  );
}

export default BookingDetailsForm;
