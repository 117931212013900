import React from 'react';
import { ModalComponent } from '../..';

function ModalWrapper({
  content,
  heading,
  show,
  onCancel,
  subHeading,
  ...rest
}) {
  return (
    <ModalComponent
      backdrop
      title={heading}
      subTitle={subHeading}
      show={show}
      onHandleCancel={onCancel}
      {...rest}
    >
      {content}
    </ModalComponent>
  );
}

export default ModalWrapper;
