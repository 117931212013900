import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CommonButton,
  ModalComponent,
  PageContainerWrapper,
} from '../../../components';
import { questionAnswerBreadcrumbs } from '../../../services/Columns';
import { accessRoute as lessonRoute } from '../../../routes/LessonList';
import { logger, modalNotification } from '../../../utils';
import {
  completeTestService,
  getInfoListService,
  lessonTestService,
} from '../../../services/LessonList/lessonList.service';
import { useEffect } from 'react';
import GlobalLoader from '../../../components/UIElements/GlobalLoader';
import QuestionAnswerForm from '../../../components/Forms/LessonList/questionAnswer.form';
import { useDispatch } from 'react-redux';
import { loadPermission } from '../../../redux/index.redux';

function QuestionAnswer(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const [lessonData, setLessonData] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const infoList = async (id) => {
    setLoading(true);
    try {
      const { success, data } = await getInfoListService(id);
      if (success) {
        setLessonData(data);
      }
    } catch (error) {
      logger(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      infoList(id);
    }
  }, [id]);

  const navigate = useNavigate();
  const breadcrumb = questionAnswerBreadcrumbs();
  const backPage = () => {
    navigate(-1);
  };
  const [questionAnswer, setQuestionAnswer] = useState([]);
  const [data, setData] = useState('');
  const [formError, setFormError] = useState(false);

  const handleChangeSelect = (e, Question, type) => {
    let que = [];
    let option = [];
    option.push(e);
    let queAns = {};
    queAns = {
      lessonQuestionId: Question,
      optionIds: type === 'single' ? option : e,
    };
    que.push(queAns);
    let questionArr = questionAnswer?.length
      ? questionAnswer.filter((e) => e.lessonQuestionId !== Question)
      : [];

    let finalArr = [...questionArr, queAns];
    finalArr?.map((item) => {
      if (!item?.optionIds?.length > 0) {
        let arr = questionAnswer.filter((e) => e.lessonQuestionId !== Question);
        setQuestionAnswer(arr);
      } else {
        setQuestionAnswer(finalArr);
      }
    });
  };

  const tryAgainButton = (e) => {
    e.preventDefault();
    navigate(`${lessonRoute.LESSON_INFO.path}/${id}`);
  };
  const [checkAnswerPassModal, setCheckAnswerPassModal] = useState(false);

  const onCheckSubmit = async (percentage) => {
    try {
      let bodyData = {
        lessonId: Number(id),
        resultInPercentage: Number(percentage),
      };
      const { success, message } = await completeTestService({ bodyData });
      if (success) {
        modalNotification({
          type: 'success',
          message,
        });
        setCheckAnswerPassModal(false);
        dispatch(loadPermission());
        navigate(lessonRoute.LESSON_LIST_VIEW.path);
      }
    } catch (error) {
      logger(error);
    }
  };
  useEffect(() => {
    if (questionAnswer?.length > 0) {
      setFormError(false);
    }
  }, [questionAnswer]);

  const onRes = async () => {
    setSubmitLoading(true);
    try {
      if (questionAnswer?.length === lessonData?.LessonQuestions?.length) {
        setFormError(false);
        let bodyData = { questionAnswer };
        const { success, message, data } = await lessonTestService({
          bodyData,
        });
        if (success) {
          modalNotification({
            type: 'success',
            message,
          });
          setData(data);
          setCheckAnswerPassModal(true);
        }
      } else {
        setFormError(true);
        modalNotification({
          type: 'error',
          message:
            questionAnswer?.length > 0
              ? t('text.common.queAnsWarningOne')
              : t('text.common.queAnsWarningTwo'),
        });
      }
    } catch (error) {
      logger(error);
      setQuestionAnswer([]);
    }
    setSubmitLoading(false);
  };

  return (
    <>
      <div className="wide-xl mx-auto">
        <PageContainerWrapper
          heading={t('text.operating.title')}
          breadcrumb={breadcrumb}
          btnArray={['extraButton']}
          extraBtnText={t('text.common.back')}
          extraBtnClass="btn btn-outline-light bg-white"
          onExtraButtonHandleShow={backPage}
        >
          {loading ? (
            <GlobalLoader />
          ) : (
            <div className="nk-block">
              <div className="card">
                {lessonData?.LessonQuestions?.length > 0 &&
                  lessonData?.LessonQuestions?.map((item, key) => {
                    return (
                      <div className="card-inner">
                        <div className="bq-note">
                          <div className="bq-note-text">
                            <div className="nk-block-head nk-block-head-sm">
                              <h5 className="title">{item?.question}</h5>
                            </div>
                            <QuestionAnswerForm
                              item={item}
                              key={key}
                              handleChangeSelect={handleChangeSelect}
                              formError={formError}
                            />
                          </div>{' '}
                        </div>
                      </div>
                    );
                  })}
              </div>
              {/* {formError && (
                <div className="ant-form-item-explain-error">
                  {questionAnswer?.length > 0
                    ? 'All questions are compulsory'
                    : 'Please select any option'}
                </div>
              )} */}
              <div className="text-end mt-3 mt-lg-4">
                <CommonButton
                  className="btn btn-primary"
                  onClick={() => onRes()}
                  loading={submitLoading}
                >
                  {t('text.operating.checkAnswer')}{' '}
                </CommonButton>
                <CommonButton
                  className="btn btn-light ms-2"
                  onClick={() => {
                    infoList(id);
                    setQuestionAnswer([]);
                  }}
                >
                  {t('text.common.reset')}
                </CommonButton>
              </div>
            </div>
          )}
        </PageContainerWrapper>
      </div>

      <ModalComponent
        backdrop
        show={checkAnswerPassModal}
        onHandleCancel={() => {
          setCheckAnswerPassModal(false);
          // infoList();
        }}
        title="Score"
        size="sm"
        closable={false}
      >
        <div className="text-center">
          <h4>Score {parseFloat(data?.percentage)}%</h4>
          <h5
            className={`${
              data?.testStatus === 'FAILED' ? 'text-danger' : 'text-success'
            }`}
          >
            {data?.testStatus === 'FAILED' ? 'Fail' : 'Pass'}
          </h5>
          {data?.testStatus === 'FAILED' ? (
            <p> {t('text.operating.tryAgainText')}</p>
          ) : (
            <p> {t('text.operating.testCompleted')}</p>
          )}
        </div>
        {data?.testStatus === 'FAILED' ? (
          <div className="text-center mt-3 mt-lg-4">
            <CommonButton
              className="btn btn-secondary"
              onClick={(e) => tryAgainButton(e)}
            >
              {t('text.operating.tryAgain')}
            </CommonButton>
          </div>
        ) : (
          <div className="text-center mt-3 mt-lg-4">
            <CommonButton
              className="btn btn-primary"
              onClick={() => onCheckSubmit(data?.percentage)}
            >
              {t('text.operating.markCompleted')}
            </CommonButton>
          </div>
        )}
      </ModalComponent>
    </>
  );
}

export default QuestionAnswer;
