import { REBATE_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';

export const getRebateService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...REBATE_ENDPOINT?.getRebateList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const deleteRebateService = async ({ bodyData }) => {
  try {
    const payload = {
      ...REBATE_ENDPOINT.deleteRebate,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const updateRebateStatus = async (bodyData) => {
  try {
    const payload = {
      ...REBATE_ENDPOINT.statusUpdateRebate,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const addRebateService = async ({ body }) => {
  try {
    const payload = {
      ...REBATE_ENDPOINT.addRebate,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const editRebateService = async ({ body, id }) => {
  try {
    const payload = {
      ...REBATE_ENDPOINT.editRebate(id),
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getRebateDetailsService = async ({ id }) => {
  try {
    const payload = {
      ...REBATE_ENDPOINT.getRebateDetails(id),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
