import {
  MyBooking,
  RequestDetails,
  ServiceTrack,
} from '../../views/CustomerApp';
import ApproveQuote from '../../views/CustomerApp/RequestDetails/ApproveQuote';
export default function route() {
  return [
    {
      path: accessRoute.BOOKING.path,
      private: true,
      name: accessRoute.BOOKING.name,
      key: accessRoute.BOOKING.key,
      belongsToSidebar: true,
      icon: accessRoute.BOOKING.icon,
      element: <MyBooking />,
    },
    {
      path: accessRoute.SERVICE_TRACK.path,
      private: true,
      name: accessRoute.SERVICE_TRACK.name,
      key: accessRoute.SERVICE_TRACK.key,
      belongsToSidebar: false,
      icon: accessRoute.SERVICE_TRACK.icon,
      element: <ServiceTrack />,
    },
    {
      path: accessRoute.REQUEST_VIEW_DETAILS.path,
      private: true,
      name: accessRoute.REQUEST_VIEW_DETAILS.name,
      key: accessRoute.REQUEST_VIEW_DETAILS.key,
      belongsToSidebar: false,
      icon: accessRoute.REQUEST_VIEW_DETAILS.icon,
      element: <RequestDetails />,
    },
    {
      path: accessRoute.REQUEST_APPROVE_VIEW_DETAILS.path,
      private: true,
      name: accessRoute.REQUEST_APPROVE_VIEW_DETAILS.name,
      key: accessRoute.REQUEST_APPROVE_VIEW_DETAILS.key,
      belongsToSidebar: false,
      icon: accessRoute.REQUEST_APPROVE_VIEW_DETAILS.icon,
      element: <ApproveQuote />,
    },
  ];
}

export const accessRoute = {
  BOOKING: {
    path: '/customer/booking',
    name: 'My Bookings',
    key: 'booking',
    for: 'customer',
    className: 'gg-my-bookings',
    icon: <em className="gg-my-bookings"></em>,
  },
  SERVICE_TRACK: {
    path: '/customer/service-track/:bookingId',
    name: 'Service Track',
    key: 'service-track',
    for: 'customer',
    className: 'gg-my-bookings',
    icon: <em className="gg-my-bookings"></em>,
  },
  REQUEST_VIEW_DETAILS: {
    path: '/customer/request-view-details/:bookingId',
    name: 'Request Details',
    key: 'request-details',
    for: 'customer',
    className: 'gg-my-bookings',
    icon: <em className="gg-my-bookings"></em>,
  },
  REQUEST_APPROVE_VIEW_DETAILS: {
    path: '/customer/request-approve-view-details/:bookingId',
    name: 'Request Approve Quote Details',
    key: 'request-details',
    for: 'customer',
    className: 'gg-my-bookings',
    icon: <em className="gg-my-bookings"></em>,
  },
};

export const getChildrenRoutes = () => {
  return route();
};
