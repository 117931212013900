import React, { useState, useRef } from 'react';
import {
  ActionWrapper,
  PageContainerWrapper,
  CommonButton,
  TablePlus,
  ModalWrapper,
  FinanceOptionForm,
  Toggle,
} from '../../../../../../components';
import { FireAlert, modalNotification } from '../../../../../../utils';
import {
  Columns,
  optionFinanceListService,
  optionFinanceDeleteService,
  optionFinanceViewService,
  optionFinanceEditService,
  optionFinanceAddService,
  optionFinanceOptionalItemStatus,
  getActions,
} from '../../../../../../services/index.service';
import { useTranslation } from 'react-i18next';
import useTable from '../../../../../../hooks/useTable.hook';
import useSubmit from '../../../../../../hooks/useSubmit.hook';

function FinancingOption() {
  const [state, setState] = useState({
    loader: null,
    modal: null,
    modalId: null,
    selectedRows: [],
    submitText: 'add',
  });

  const { t } = useTranslation();
  const formRef = useRef(null);
  const [tableHandler, tableProps] = useTable(optionFinanceListService);
  const {
    data,
    isLoading,
    count,
    page,
    noOfPages,
    sizePerPage,
    filter,
    sortBy,
    sortType,
  } = tableProps;
  const {
    onRefresh,
    onFilterChange,
    getData,
    onTableSort,
    onFilterResetChange,
    onToggleChange,
  } = tableHandler;
  const [onSubmit, values, loading] = useSubmit({
    service: state?.modalId
      ? optionFinanceEditService
      : optionFinanceAddService,
    detailsService: optionFinanceViewService,
    id: state?.modalId,
    afterSubmitActionType: 'close-modal',
    onModalCancel: onCancel,
    action: onRefresh,
  });
  const { modal, modalId, selectedRows, submitText } = state;
  const breadcrumb = Columns.financeOptionBreadcrumbBreadcrumb();

  const onConfirmAlert = async (id) => {
    await FireAlert({
      title: t('text.common.areYouSure'),
      text: t('text.common.areYouSureDeleteBankOption'),
      icon: t('text.common.warning'),
      confirmButtonText: t('text.common.delete'),
      preConfirm: () => onDelete(id),
    });
    return true;
  };

  async function onDelete(id) {
    try {
      const { success, message } = await optionFinanceDeleteService({
        values: { financingOptionIds: Array.isArray(id) ? id : [id] },
      });
      if (success && message) {
        modalNotification({
          type: 'success',
          message: t('text.common.locationDeleteSuccess'),
        });
        onRefresh();
      }
      return success ? true : false;
    } catch (err) {
      console.log(err);
    }
  }

  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler)}
              key={key}
              className="action_list"
              action={item?.action}
            >
              <CommonButton>
                <em className={item.icon} />
                {item?.name}
              </CommonButton>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };

  function onAction(e, handler) {
    e.preventDefault();
    handler();
  }

  function onCancel() {
    setState({ ...state, modal: null, modalId: null });
    // renewState();
  }

  const columns = Columns.financeOptionBreadcrumbTableColumn({
    onSort: onTableSort,
    onFilterChange: onFilterChange,
    filter,
    page,
    sizePerPage,
    Action: (props) =>
      actionFormatter([
        {
          name: t('text.common.edit'),
          icon: 'icon ni ni-edit',
          action: getActions('EDIT').value,
          handler: () => {
            setState({
              ...state,
              modal: 'edit-finance-option',
              submitText: 'Update',
              modalId: props?.row?.id,
            });
          },
        },
      ]),
    Toggle: (props) => (
      <Toggle
        isDropDown={true}
        defaultChecked={props?.row?.status}
        onChange={(e) =>
          onToggleChange(
            e,
            props?.row,
            '',
            optionFinanceOptionalItemStatus,
            'financingOptionIds',
            true
          )
        }
      />
    ),
  });

  const modalForm = {
    filter: <></>,
    add: (
      <FinanceOptionForm
        onCancel={onCancel}
        loading={loading}
        formId={modalId}
        submitText={submitText}
        formData={modalId ? values : {}}
        onSubmit={onSubmit}
      />
    ),
  };

  const headerBtn = {
    add: {
      name: t('text.quote.addFinancingOption'),
      handler: () => {
        setState({ ...state, submitText: 'Add', modal: 'add-finance-option' });
      },
    },
    // csvExport: { handler: () => onExport('', columns) },
    // csvImport: { name: 'import', handler: () => { } },
  };

  function onRowSelect(e) {
    try {
      setState({ ...state, selectedRows: [...e] });
    } catch (err) {
      console.log('err', err);
    }
  }

  async function handleActionSelect(e) {
    try {
      if (e.target.value === 'delete') {
        await onConfirmAlert(selectedRows);
        getData();
      } else {
        await onToggleChange(
          e.target.value,
          selectedRows,
          'action',
          optionFinanceOptionalItemStatus,
          'financingOptionIds'
        );
        getData();
      }
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <>
      <PageContainerWrapper
        formRef={formRef}
        form={modalForm['filter']}
        heading={t('text.quote.financingOption')}
        breadcrumb={breadcrumb}
        filter={filter}
        btn={headerBtn}
      >
        <ModalWrapper
          onCancel={onCancel}
          content={modalForm['add']}
          heading={`${submitText === 'Add'
              ? t('text.quote.addFinancingOption')
              : t('text.quote.editFinancingOption')
            }`}
          show={['add-finance-option', 'edit-finance-option'].includes(modal)}
        />
        <TablePlus
          hasLimit
          noOfPage={noOfPages}
          sizePerPage={sizePerPage}
          page={page}
          handleSelectedRows={onRowSelect}
          handleActionSelect={handleActionSelect}
          count={count}
          selectRow={true}
          disabledAction={['delete']}
          tableData={data}
          tableColumns={columns}
          tableLoader={isLoading}
          onFilterResetChange={onFilterResetChange}
          filter={filter}
          sortBy={sortBy}
          sortType={sortType}
        />
      </PageContainerWrapper>
    </>
  );
}

export default FinancingOption;
