import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useNavigate } from 'react-router-dom';
import {
  AddEditLocationForm,
  NoteFooter,
  PageContainerWrapper,
} from '../../../../components';
import { formSubmit } from '../../../../hooks/useFormSubmit.hook';
import { accessRoute } from '../../../../routes/ManageLocation';
import { accessRoute as NCARoute } from '../../../../routes/AdminSetting';
import {
  breadcrumbAdd,
  ncaAddGeoLocationBreadcrumb,
} from '../../../../services/Columns';
import {
  addManageLocationService,
  addNCALocationService,
} from '../../../../services/GeoLocation';
import { backToPage } from '../../../../utils';
import { getGeneralSetting } from '../../../../services/Common/index.service';
import Map from '../../../../components/Map/index';
import {
  GOOGLE_MAPS_API_KEY,
  GOOGLE_MAP_URL,
  MANAGE_LOCATION_VIDEO,
} from '../../../../config';
import { useSelector } from 'react-redux';
import AddEditNCAForm from '../../../../components/Forms/ManageLocation/addEditLocationNca.form';

function AddGeoLocation({ userId = false }) {
  const [isLoading, setIsLoading] = useState(false);
  const userType = useSelector((state) => state?.auth?.userData?.Role?.type);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const url =
    userId &&
    `${generatePath(NCARoute?.EDIT_NCA.path, {
      id: userId,
    })}`;

  const redirectUrl = !userId ? accessRoute.MANAGE_LOCATION.path : url;
  const breadcrumbData = userId
    ? ncaAddGeoLocationBreadcrumb(url)
    : breadcrumbAdd();
  const [state, setState] = useState({ mapKey: '', loading: true });
  const [location, setLocation] = useState({
    lat: null,
    lng: null,
    latLng: null,
  });
  const [countryLocation, setCountryLocation] = useState({});
  const [address, setAddress] = useState(null);
  const googleMapApiKey = useSelector((state) => state?.googleMap?.apiKey);

  console.log(googleMapApiKey);

  useEffect(() => {
    getApiKeys();
  }, []);

  async function getApiKeys() {
    try {
      const res = await getGeneralSetting({
        queryParams: { type: 'installation_setting' },
      });
      if (Array.isArray(res?.data?.rows)) {
        const mapKey = res?.data?.rows.find(
          (e) => e?.key === 'google_server_key'
        );
        if (mapKey?.value) {
          setState({ ...state, mapKey: mapKey?.value, loading: false });
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <PageContainerWrapper
        breadcrumb={breadcrumbData}
        btnArray={['extraButton']}
        extraBtnText={t('text.common.back')}
        extraBtnClass="btn btn-outline-light bg-white"
        onExtraButtonHandleShow={() => backToPage(navigate, redirectUrl)}
        heading={t('text.manageLocation.addLocation')}
      >
        <div className="nk-block">
          <div className="card">
            <div className="card-inner">
              <div className="row">
                <div className="col-lg-6">
                  {userType !== 'nca' ? (
                    <AddEditLocationForm
                      onSubmit={(values) =>
                        formSubmit({
                          values: {
                            ...values,
                            latLong: location?.latLng,
                            ...(userId && { userId: userId }),
                          },
                          service: addManageLocationService,
                          navigate,
                          redirectUrl,
                          setIsLoading,
                        })
                      }
                      handleChangeLocation={(newLocation) => {
                        setCountryLocation(newLocation);
                      }}
                      setAddress={setAddress}
                      isLoading={isLoading}
                      backToPage={() => backToPage(navigate, redirectUrl)}
                    />
                  ) : (
                    <AddEditNCAForm
                      onSubmit={(values) =>
                        formSubmit({
                          values: {
                            ...values,
                            latLong: location?.latLng,
                            ...(userId && { userId: userId }),
                          },
                          service: addNCALocationService,
                          navigate,
                          redirectUrl,
                          setIsLoading,
                        })
                      }
                      handleChangeLocation={(newLocation) =>
                        setCountryLocation(newLocation)
                      }
                      setAddress={setAddress}
                      isLoading={isLoading}
                      backToPage={() => backToPage(navigate, redirectUrl)}
                    />
                  )}
                  <NoteFooter
                    title={`${t('text.common.notes')} :`}
                    card={false}
                    customIcon={true}
                  >
                    <li>
                      <img
                        src="/assets/images/admin/select.svg"
                        className="p-0"
                        alt="select"
                      />
                      {t('text.manageLocation.noteListOne')}
                    </li>
                    <li>
                      <img
                        src="/assets/images/admin/polygon.svg"
                        className="p-0"
                        alt="polygon"
                      />
                      {t('text.manageLocation.noteListTwo')}
                    </li>
                    <li>
                      {' '}
                      <img
                        src="/assets/images/admin/chart.svg"
                        className="p-0"
                        alt="lines"
                      />
                      {t('text.manageLocation.noteListThree')}
                    </li>
                  </NoteFooter>
                </div>
                <div className="col-lg-6 mt-3 mt-lg-0">
                  <p className="mb-0">
                    <b>
                      Draw Location Point Here In Map :
                      <span className="text-danger">*</span>
                    </b>
                  </p>
                  <p>
                    Please select the area by putting the points around it.
                    Please
                    <a
                      href={MANAGE_LOCATION_VIDEO}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {' '}
                      click here{' '}
                    </a>{' '}
                    to view how to select the area and add it.
                  </p>
                  <div className="card card-bordered w-100">
                    {/* {false ? (
                      <>Loading Map</>
                    ) : ( */}
                    <Map
                      address={address}
                      setPolygon={setLocation}
                      googleMapURL={GOOGLE_MAP_URL(
                        googleMapApiKey || GOOGLE_MAPS_API_KEY,
                        ['drawing,places']
                      )}
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: `480px` }} />}
                      mapElement={
                        <div style={{ height: `calc(100% - 62px)` }} />
                      }
                      mapLocation={countryLocation}
                    />
                    {/* )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageContainerWrapper>
    </>
  );
}

export default AddGeoLocation;
