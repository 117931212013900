export const SCHEDULED_BOOKING_ENDPOINT = {
  getList: {
    url: '/v1/admin/schedule-booking',
    method: 'GET',
  },
  getGraph: {
    url: '/v1/booking/graph',
    method: 'GET',
  },
  getInvoiceDetails: (id) => ({
    url: `/v1/admin/booking/invoice/${id}`,
    method: 'GET',
  }),
  getSendQuote: (bookingId) => ({
    url: `/v1/admin/send-quote/${bookingId}`,
    method: 'GET',
  }),
  adminBookingCancel: (bookingId) => ({
    url: `/v1/admin/bookings/decline/${bookingId}`,
    method: 'POST',
  }),
};
