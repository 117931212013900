export const text = {
  title: 'Equipment Order',
  serviceProviderName: 'Service Provider Name',
  serviceProviderNumber: 'Service Provider Number',
  customerName: 'Customer Name',
  customerNumber: 'Customer Number',
  customerPhoneNumber: 'Customer Phone Number',
  customerAddress: 'Customer Address',
  needInstallPermit: 'Need Install Permit',
  CityCountyName: 'City/County Name',
  floodZone: 'Flood Zone',
  amountJobApprovedFor: 'Amount Job Approved For',
  supplyHouseName: 'Supply House Name  ',
  supplyHouseLocation: 'Equipment Location',
  equipmentPickupDate: 'Equipment Pickup Date',
  equipment: 'Equipment',
  materialList: 'Material List',
  otherNotes: 'Other Notes',
  permitStatus: 'Permit Status',
  ordered: 'Ordered',
  lastUpdatedSave: 'Last Updated/Save',
  areYouSure: 'Are you sure',
  youWantToSave: 'you want to save changes ?',
  youWantToDiscard: 'You want to discard changes ?',
  brandName: 'Brand Name',
  outdoorUnit: 'Outdoor Unit - Model Number',
  systemPhysicalSize: 'System Physical Size',
  efficiency: 'Efficiency',
  partsWarranty: 'Parts Warranty',
};
