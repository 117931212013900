import i18next from 'i18next';

import {
  checkValidCount,
  checkValidData,
  checkValidDiscountType,
  checkValidPrice,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { getLoginRoute } from '../../routes/index.routes';
import { dateFormatter } from '../../utils';
import { firstLetterCaps } from '../../utils/text.util';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const forgetPasswordBreadcrumb = () => {
  return [
    {
      path: getLoginRoute('store'),
      name: i18next.t('text.common.home'),
    },

    {
      path: '#',
      name: i18next.t('text.store.forgetPassword'),
    },
  ];
};
export const signUpBreadcrumb = () => {
  return [
    {
      path: getLoginRoute('store'),
      name: i18next.t('text.common.home'),
    },

    {
      path: '#',
      // name: i18next.t('text.common.signUp'),
      name: i18next.t('Become A Super Fan'),
    },
  ];
};
export const resetPasswordBreadcrumb = () => {
  return [
    {
      path: getLoginRoute('store'),
      name: i18next.t('text.common.home'),
    },

    {
      path: '#',
      name: i18next.t('text.common.resetPassword'),
    },
  ];
};
export const storeProfileBreadcrumb = [
  {
    path: getLoginRoute('store'),
    name: i18next.t('text.common.home'),
  },
];

export const storePromoTableColumn = ({ onSort, page, sizePerPage }) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'code',
      title: i18next.t('text.promoCode.heading'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => checkValidData(cell),
    },
    {
      dataIndex: 'discountValue',
      title: i18next.t('text.promoCode.discount'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('tagType', order),
      render: (cell, row) => checkValidDiscountType(row?.discountType, cell),
      exportFormatter: (cell, row, index) =>
        checkValidDiscountType(row?.discountType, cell),
    },
    {
      dataIndex: 'validityType',
      title: i18next.t('text.promoCode.validity'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => checkValidData(firstLetterCaps(cell)),
    },
    {
      dataIndex: 'activeDate',
      title: i18next.t('text.promoCode.activationDate'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('tagType', order),
      render: (cell, row) => (cell ? dateFormatter(cell, 'DD-MM-YYYY') : '-'),
    },
    {
      dataIndex: 'expiryDate',
      title: i18next.t('text.promoCode.expiryDate'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('tagType', order),
      render: (cell, row) => (cell ? dateFormatter(cell, 'DD-MM-YYYY') : '-'),
    },
    {
      dataIndex: 'usageLimit',
      exportName: i18next.t('text.promoCode.usageLimit'),
      title: () => {
        return (
          <>
            {i18next.t('text.promoCode.usageLimit')}
            <OverlayTrigger
              key="top"
              placement="top"
              overlay={
                <Tooltip id="tooltip-top">
                  {i18next.t('text.promoCode.usageLimitTooltip')}
                </Tooltip>
              }
            >
              <em className="gg-infomore ms-2" />
            </OverlayTrigger>
          </>
        );
      },
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('tagType', order),
      render: (cell, row) => checkValidData(checkValidCount(cell)),
      exportFormatter: (cell, row, index) => cell,
    },
    {
      dataIndex: 'used',
      title: () => {
        return (
          <>
            {i18next.t('text.store.customerUsage')}
            <OverlayTrigger
              key="top"
              placement="top"
              overlay={
                <Tooltip id="tooltip-top">
                  {i18next.t('text.promoCode.customerUsageTooltip')}
                </Tooltip>
              }
            >
              <em className="gg-infomore ms-2" />
            </OverlayTrigger>
          </>
        );
      },

      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('tagType', order),
      render: (cell, row) => checkValidData(checkValidCount(cell)),
      exportFormatter: (cell, row, index) => cell,
    },
    {
      dataIndex: 'pendingAmount',
      exportName: i18next.t('text.store.pendingAmount'),
      title: () => {
        return (
          <>
            {i18next.t('text.store.pendingAmount')}
            <OverlayTrigger
              key="top"
              placement="top"
              overlay={
                <Tooltip id="tooltip-top">
                  {' '}
                  {i18next.t('text.promoCode.pendingAmountTooltip')}
                </Tooltip>
              }
            >
              <em className="gg-infomore ms-2" />
            </OverlayTrigger>
          </>
        );
      },
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('tagType', order),
      render: (cell, row) => checkValidPrice(cell),
      exportFormatter: (cell, row, index) => checkValidPrice(cell),
    },
    {
      dataIndex: 'transferredAmount',
      title: i18next.t('text.store.transferredAmount'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('tagType', order),
      render: (cell, row) => checkValidPrice(cell),
      exportFormatter: (cell, row, index) => checkValidPrice(cell),
    },
  ];
};
export const contentStore = [
  {
    name: 'Today',
    key: 'today',
  },
  {
    name: 'Yesterday',
    key: 'yesterday',
  },
  {
    name: 'Current Week',
    key: 'currentWeek',
  },
  {
    name: 'Previous Week',
    key: 'previousWeek',
  },
  {
    name: 'Current Month',
    key: 'currentMonth',
  },
  {
    name: 'Previous Month',
    key: 'previousMonth',
  },
  {
    name: 'Current Year',
    key: 'currentYear',
  },
  {
    name: 'Previous Year',
    key: 'previousYear',
  },
];
