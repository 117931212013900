import React, { useEffect, useState } from 'react';

import { CommonButton, CustomTable, TablePlus } from '../../../../components';
import useTable from '../../../../hooks/useTable.hook';
import {
  Columns,
  getProviderPaymentReportTotalSublaborPayService,
} from '../../../../services/index.service';

import { t } from 'i18next';

function SubLabourList({ subLabourModalHide, rowData, filterValue }) {
  const [sortColumn, setSortColumn] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [searchData, setSearchData] = useState('');
  const [page, setPage] = useState({
    currentPage: 1,
    sizePerPage: 10,
  });
  const { currentPage, sizePerPage } = page;
  const { dateType, ...remainFilter } = filterValue;

  let extraQuery = {
    providerUserId: rowData?.providerUserId,
    ...remainFilter,
  };
  let extraQueryObj = {
    sortColumn,
    sortOrder,
    searchData,
    sizePerPage,
    sizePerPages: sizePerPage,
    currentPage,
  };
  const [tableHandler, tableProps] = useTable(
    getProviderPaymentReportTotalSublaborPayService,
    extraQuery,
    false,
    extraQueryObj,
    false,
    false,
    sizePerPage
  );
  const { data, isLoading, count, noOfPages, filter } = tableProps;
  const { onFilterChange, onTableSort } = tableHandler;

  const columns = Columns.serviceProviderReportSublabourDetailsColumn({
    onSort: onTableSort,
    onFilterChange: onFilterChange,
    page: currentPage,
    sizePerPage,
  });

  return (
    <>
      <CustomTable
        onModal
        hasLimit
        noOfPage={noOfPages}
        sizePerPage={sizePerPage}
        currentPage={currentPage}
        count={count}
        tableData={data}
        tableColumns={columns}
        searchField
        resetAll={false}
        selectRow={false}
        statusAction={false}
        showAction={false}
        tableLoader={isLoading}
        setSizePerPage=""
        filter={filter}
        setSortColumn={setSortColumn}
        setSortOrder={setSortOrder}
        setSearchData={setSearchData}
        setPage={setPage}
        pagination={true}
        page={page}
        searchDisabled={data?.length > 1 || searchData?.length}
      />
      <div className="text-end">
        <CommonButton
          className="btn btn-secondary"
          onClick={() => subLabourModalHide()}
        >
          {t('text.common.close')}
        </CommonButton>
      </div>
    </>
  );
}

export default SubLabourList;
