import { FinanceOption } from '../../views/CustomerApp';

export default function route() {
  return [
    {
      path: accessRoute.PAYMENT.path,
      private: true,
      name: accessRoute.PAYMENT.name,
      key: accessRoute.PAYMENT.key,
      belongsToSidebar: true,
      icon: accessRoute.PAYMENT.icon,
      element: <FinanceOption />,
    },
  ];
}

export const accessRoute = {
  PAYMENT: {
    path: '/customer/financing',
    name: 'Apply For Financing',
    key: 'apply_for_financing',
    for: 'customer',
    icon: <em className="gg-financing"></em>,
  },
};

export const getChildrenRoutes = () => {
  return route();
};
