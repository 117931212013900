import i18next from 'i18next';
import {
  checkValidData,
  checkValidPrice,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import {
  getInputSearchFilter,
  getColumnSelectSearchProps,
  getMultiSelectSearchFilter,
} from '../../components';
import { getTupleStatusList } from '../index.service';
import { getQuoteTypeListService } from '../QuoteManagement/quoteGet.service';
import { firstLetterCaps } from '../../utils/text.util';
import { readMoreTextShow } from '../../utils/common.util';

export const optionalItemsBreadcrumb = () => {
  return [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: i18next.t('text.quote.title'),
    },
  ];
};

export const optionalTableColumn = ({
  Action,
  onSort,
  onFilterChange,
  Toggle,
  filter,
  page,
  sizePerPage,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'title',
      title: i18next.t('text.common.title'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('title', order),
      render: (cell, row) => checkValidData(firstLetterCaps(cell || '')),
      ...getInputSearchFilter({ dataIndex: 'title', onFilterChange, filter }),
    },
    {
      dataIndex: 'cost',
      title: i18next.t('text.common.cost'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('cost', order),
      render: (cell, row) => checkValidPrice(cell),
      ...getInputSearchFilter({ dataIndex: 'cost', onFilterChange, filter }),
    },
    {
      dataIndex: 'displayOrder',
      title: i18next.t('text.common.displayOrder'),
      headerClasses: 'sorting',
      sorter: true,
    },
    {
      dataIndex: 'quoteType',
      title: i18next.t('text.common.quoteType'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) =>
        readMoreTextShow(
          row?.QuoteOptionalItemTypes?.length
            ? row?.QuoteOptionalItemTypes.filter((i) => i?.Quote?.quoteType)
              ?.length
              ? row?.QuoteOptionalItemTypes.filter((i) => i?.Quote?.quoteType)
                .map((i) => i?.Quote?.quoteType)
                ?.join(', ')
              : '-'
            : '-',
          i18next.t('text.common.description'),
          50
        ),
      ...getMultiSelectSearchFilter({
        dataIndex: 'quoteType',
        name: 'quote type',
        list: [],
        mode: false,
        listService: getQuoteTypeListService,
        listKeys: { nameKey: 'quoteType', nameId: 'id' },
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'description',
      title: i18next.t('text.common.description'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell) =>
        readMoreTextShow(cell, i18next.t('text.common.description'), 40),
      ...getInputSearchFilter({
        dataIndex: 'description',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'status',
      title: i18next.t('text.common.status'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => {
        return <Toggle row={row} cell={cell} />;
      },
      ...getMultiSelectSearchFilter({
        dataIndex: 'status',
        onFilterChange,
        name: 'status',
        list: [],
        mode: false,
        filter,
        listService: getTupleStatusList,
      }),
      //   onSort: (field, order) => onSort('customerTag', order),
      //   render: (cell, row) =>
      //     checkValidData(row?.LineItemCustomerTags[0]?.Tag?.name),
      //   ...getMultiSelectSearchFilter({ dataIndex: "customerTag", onFilterChange, name:"customer tag", list: [], listService: getTagService, extraQuary:{tagType:"customer"} })
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.common.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => <Action row={row} cell={cell} />,
    },
  ];
};
