import React from 'react';
import { useTranslation } from 'react-i18next';
import { CommonButton } from '../../../components';

const ApproveModal = ({ handleClose, handleSubmit, isLoading }) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex align-items justify-content-end flex-wrap mt-lg-4 mt-2">
      <CommonButton
        variant="light"
        className="min-200 me-3"
        onClick={handleClose}
      >
        {t('text.common.cancel')}
      </CommonButton>
      <CommonButton
        disabled={isLoading}
        loading={isLoading}
        variant="primary"
        className="min-200"
        onClick={handleSubmit}
      >
        {t('text.common.continue')}
      </CommonButton>
    </div>
  );
};

export default ApproveModal;
