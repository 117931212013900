let url = '/v1/admin/quote-optional-item';
let financeBankUrl = '/v1/admin/financing-bank';
let financeOptionUrl = '/v1/admin/financing-option';

export const OPTIONAL_ITEM_ENDPOINT = {
  getOptionalItemList: {
    url,
    method: 'GET',
  },
  deleteOptionalItem: {
    url,
    method: 'DELETE',
  },
  itemStatusUpdate: {
    url: `${url}/change-status`,
    method: 'PUT',
  },
  addOptionalItem: {
    url,
    method: 'POST',
  },
  editOptionalItem: (id) => ({
    url: `${url}/${id}`,
    method: 'PUT',
  }),
  getOptionalItemDetails: (id) => ({
    url: `${url}/${id}`,
    method: 'GET',
  }),
  getTags: {
    url: '/v1/admin/tag',
    method: 'GET',
  },
  getQuoteList: {
    url: '/v1/admin/quote-order-admin-profit',
    method: 'GET',
  },
};

export const QUOTE_ORDER = {
  getQuoteOrderList: {
    url,
    method: 'GET',
  },
  deleteQuoteOrder: {
    url,
    method: 'DELETE',
  },
  quoteOrderStatusUpdate: {
    url: `${url}/change-status`,
    method: 'PUT',
  },
  addQuoteOrder: {
    url,
    method: 'POST',
  },
  editQuoteOrder: (id) => ({
    url: `${url}/${id}`,
    method: 'PUT',
  }),
  getQuoteOrderDetails: (id) => ({
    url: `${url}/${id}`,
    method: 'GET',
  }),
};

export const BANK_FINANCING = {
  getFinanceBankList: {
    url: financeBankUrl,
    method: 'GET',
  },
  deleteFinanceBank: {
    url: financeBankUrl,
    method: 'DELETE',
  },
  financeBankStatusUpdate: {
    url: `${financeBankUrl}/change-status`,
    method: 'PUT',
  },
  addFinanceBank: {
    url: financeBankUrl,
    method: 'POST',
  },
  editFinanceBank: (id) => ({
    url: `${financeBankUrl}/${id}`,
    method: 'PUT',
  }),
  getFinanceBankDetails: (id) => ({
    url: `${financeBankUrl}/${id}`,
    method: 'GET',
  }),
};

export const BANK_FINANCING_OPTION = {
  getFinanceOptionList: {
    url: financeOptionUrl,
    method: 'GET',
  },
  deleteFinanceOption: {
    url: financeOptionUrl,
    method: 'DELETE',
  },
  financeOptionStatusUpdate: {
    url: `${financeOptionUrl}/change-status`,
    method: 'PUT',
  },
  addFinanceOption: {
    url: financeOptionUrl,
    method: 'POST',
  },
  editFinanceOption: (id) => ({
    url: `${financeOptionUrl}/${id}`,
    method: 'PUT',
  }),
  getFinanceOptionDetails: (id) => ({
    url: `${financeOptionUrl}/${id}`,
    method: 'GET',
  }),
};
