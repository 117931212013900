import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  GlobalLoader,
  ModalWrapper,
  PageContainerWrapper,
} from '../../../../components';
import Tabs from '../../../../components/UIElements/Tabs';
import { accessRoute } from '../../../../routes/GoogleConnect';
import { getBusinessDataService } from '../../../../services/GoogleConnect/googleGMB.service';
import { Columns } from '../../../../services/index.service';
import { decodeQueryData, navigateWithParam } from '../../../../utils';
import PhotoContainer from './List';
function GMBMedia() {
  const [state, setState] = useState({
    loader: null,
    modal: null,
    modalId: null,
    selectedRows: [],
    submitText: 'Post Photo',
  });
  const { modal, submitText } = state;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const breadcrumb = Columns.googleGMBPhotoBreadcrumb();
  const [defaultKey, setDefaultKey] = useState('');
  const location = useLocation();
  const params = decodeQueryData(location.search);
  const { accountId, name } = params;
  const [loading, setLoading] = useState(false);
  const [tabName, setTabName] = useState([]);
  const [mediaData, setMediaData] = useState([]);
  const [tabsData, setTabsData] = useState([]);

  async function getMediaList() {
    setLoading(true);
    try {
      let queryParams = {
        name,
        accountId,
      };
      const response = await getBusinessDataService({ queryParams });
      const { data, success } = response;
      if (success) {
        let tabArr = [];
        setDefaultKey(data?.mediaItems[0]?.locationAssociation?.category);
        data?.mediaItems?.map((item) => {
          tabArr.push(item?.locationAssociation?.category);
        });
        setTabName([...new Set(tabArr)]);
        setMediaData(data?.mediaItems);
        const arr = data?.mediaData?.filter(
          (item) =>
            item?.locationAssociation?.category ===
            data?.mediaItems[0]?.locationAssociation?.category
        );
        setTabsData(arr);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }
  useEffect(() => {
    getMediaList();
  }, []);

  const onTabChange = (key) => {
    setDefaultKey(key);
  };

  useEffect(() => {
    if (defaultKey) {
      let arr = [];
      mediaData?.map((item) => {
        if (item?.locationAssociation?.category === defaultKey) {
          arr.push(item);
        }
      });
      setTabsData(arr);
    }
  }, [defaultKey]);

  const tabContent = tabName?.map((item) => {
    return {
      name: item,
      key: item,
      content: (
        <>
          <PhotoContainer photoData={tabsData} />
        </>
      ),
    };
  });

  const headerBtn = {
    extraButton: {
      name: t('text.common.back'),
      extraBtnClass: 'btn btn-outline-light bg-white',
    },
    add: {
      name: t('text.googleConnect.postPhoto'),
      handler: () => {
        navigateWithParam(
          {
            name: name,
            accountId: accountId,
          },
          navigate,
          accessRoute.GOOGLE_GMB_PHOTO_ADD.path
        );
      },
    },
  };
  const onCancel = () => {
    setState({ ...state, submitText: null, modal: null });
  };
  const backPage = () => {
    navigate('/admin/google-gmb');
  };
  return (
    <>
      <div className="wide-md mx-auto">
        <PageContainerWrapper
          heading={t('text.googleConnect.googleMediaList')}
          breadcrumb={breadcrumb}
          btn={headerBtn}
          extraBtnClass="btn btn-outline-light bg-white"
          onExtraButtonHandleShow={backPage}
        >
          {loading ? (
            <GlobalLoader />
          ) : (
            <Tabs
              tabContent={tabContent}
              tabsFor="table"
              activeKey={defaultKey}
              setActiveKey={setDefaultKey}
              onTabChange={onTabChange}
            />
          )}
          <ModalWrapper
            onCancel={onCancel}
            heading={`${
              submitText === 'Add'
                ? t('text.common.add')
                : t('text.common.edit')
            } Industry`}
            content={'Hello there'}
            show={['post-photo'].includes(modal)}
          />
        </PageContainerWrapper>
      </div>
    </>
  );
}

export default GMBMedia;
