import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ChatContext } from '../../../../context/chat.context';

import Dropdown from 'react-bootstrap/Dropdown';
import { Spin } from 'antd';

import ChatServiceProviderList from './ChatServiceProviderList';
import ChatSearch from '../ChatSearch';
import { NoDataFound } from '../../../../components';

import { getEmojiFilterOptions } from '../../../../services/Common/index.service';
import { useSelector } from 'react-redux';

const ServiceProvider = ({ getChatRoomUserLists, chatRoomUserLists }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    getServiceProviderData,
    providers,
    setProviders,
    handleSearchProviders,
    handleResetProviderDetails,
    isProviderLoading,
    selectedFilter,
    setSelectedFilter,
  } = useContext(ChatContext);
  const [searchServiceProvider, setSearchServiceProvider] = useState('');
  const mentalHeathPermission = useSelector(
    (state) => state?.permission?.isAllowMentalHealthModule
  );

  const handleSelectFilter = (filter) => {
    setSelectedFilter(filter);
  };

  useEffect(() => {
    return () => {
      handleResetProviderDetails();
      navigate(location.pathname, {});
    };
  }, []);

  useEffect(() => {
    getServiceProviderData();
  }, [selectedFilter]);

  return (
    <>
      <div className="nk-chat-aside-search p-0 mb-3  d-flex justify-content-between">
        <ChatSearch
          search={searchServiceProvider}
          handleSearch={(e) => setSearchServiceProvider(e.target.value)}
          setEmptyList={() => setProviders([])}
          getSearchList={() => handleSearchProviders(searchServiceProvider)}
          className="me-2"
        />
        {mentalHeathPermission && (
          <Dropdown as="li" className="user-dropdown">
            <Dropdown.Toggle as="a" className="me-n1" id="dropdown-basic">
              <div className="user-toggle">
                <div className="user-avatar d-inline-flex">
                  <em className="ni ni-filter" />
                  {selectedFilter && (
                    <img
                      src={selectedFilter.img}
                      className="img-fluid medicalStatus_img medicalStatus_img--sm position-absolute top-0 end-0 translate-middle-y"
                      alt="help"
                    />
                  )}
                </div>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu align="end">
              <ul className="link-list p-0">
                <li className="dropdown-inner px-2">
                  <Link onClick={() => handleSelectFilter(null)} to="#">
                    <span>{t('text.chat.all')}</span>
                  </Link>
                </li>
                <li className="dropdown-inner px-2">
                  <Link
                    onClick={() =>
                      handleSelectFilter(getEmojiFilterOptions().positive)
                    }
                    to="#"
                  >
                    <img
                      className="imgFilter"
                      src="/assets/images/admin/positive.png"
                      alt="positive"
                    />
                    <span className="ms-1">{t('text.chat.positive')}</span>
                  </Link>
                </li>
                <li className="dropdown-inner px-2">
                  <Link
                    onClick={() =>
                      handleSelectFilter(getEmojiFilterOptions().help)
                    }
                    to="#"
                  >
                    <img
                      className="imgFilter"
                      src="/assets/images/admin/help.png"
                      alt="help"
                    />
                    <span className="ms-1">{t('text.chat.help')}</span>
                  </Link>
                </li>
                <li className="dropdown-inner px-2">
                  <Link
                    onClick={() =>
                      handleSelectFilter(getEmojiFilterOptions().struggling)
                    }
                    to="#"
                  >
                    <img
                      className="imgFilter"
                      src="/assets/images/admin/struggle.png"
                      alt="struggle"
                    />
                    <span className="ms-1">{t('text.chat.struggling')}</span>
                  </Link>
                </li>
                <li className="dropdown-inner px-2">
                  <Link
                    onClick={() =>
                      handleSelectFilter(getEmojiFilterOptions().medical)
                    }
                    to="#"
                  >
                    <img
                      className="imgFilter"
                      src="/assets/images/admin/medical.png"
                      alt="medical"
                    />
                    <span className="ms-1">{t('text.chat.medical')}</span>
                  </Link>
                </li>
                <li className="dropdown-inner px-2">
                  <Link
                    onClick={() =>
                      handleSelectFilter(getEmojiFilterOptions().vacation)
                    }
                    to="#"
                  >
                    <img
                      src="/assets/images/admin/vacation.png"
                      className="img-fluid medicalStatus_img position-relative"
                      alt="vacation"
                    />
                    <span className="ms-1">Vacation</span>
                  </Link>
                </li>
              </ul>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
      {isProviderLoading && !providers?.length ? (
        <div className="p-2">
          <Spin
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: 'auto',
            }}
          />
        </div>
      ) : !isProviderLoading && providers?.length > 0 ? (
        <ChatServiceProviderList
          chatRoomUserLists={chatRoomUserLists}
          getChatRoomUserLists={getChatRoomUserLists}
          serviceProvider={providers}
        />
      ) : (
        <NoDataFound
          source="/no-provider.svg"
          text={t('text.chat.noServiceProviderList')}
          extraClass="noDataFoundWrapper"
          isCustomer={'/assets/images/admin/no-provider.svg'}
        />
      )}
    </>
  );
};

export default ServiceProvider;
