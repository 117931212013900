import { getCompletePathList } from '../../routes/index.routes';

// const stakeholder = {
//     admin:"Admin",
//     nca:"NCA",
//     customer:"Customer",

// }

// export getStakeholder = () =>{

// }

export const getModules = () => {
  try {
    return getCompletePathList().filter(e=>e.belongsToSidebar);
  } catch (error) {
    return [];
  }
};
