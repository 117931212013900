import * as yup from 'yup';
import i18next from 'i18next';

export const initValues = () => {
  return {
    emailOrMobile: '',
  };
};

export const validation = () => {
  return yup.object().shape({
    emailOrMobile: yup
      .string()
      .trim()
      .nullable()
      .required(i18next.t('validation.store.emailMobile')),
  });
};
