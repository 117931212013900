import { MemberShipPayment, Payment } from '../../views/CustomerApp';
import CustomerPayment from '../../views/CustomerApp/Payment/CustomerPayment';
import PaymentOption from '../../views/CustomerApp/Payment/CustomerPayment/paymentOption';
import PayNowPayment from '../../views/CustomerApp/Payment/PayNowPayment';
import SelectPaymentMethod from '../../views/CustomerApp/Payment/SelectPayment';

export default function route() {
  return [
    {
      path: accessRoute.SELECT_PAYMENT.path,
      private: true,
      name: accessRoute.SELECT_PAYMENT.name,
      key: accessRoute.SELECT_PAYMENT.key,
      belongsToSidebar: true,
      icon: accessRoute.SELECT_PAYMENT.icon,
      element: <SelectPaymentMethod />,
    },
    {
      path: accessRoute.PAYMENT.path,
      private: true,
      name: accessRoute.PAYMENT.name,
      key: accessRoute.PAYMENT.key,
      belongsToSidebar: false,
      icon: accessRoute.PAYMENT.icon,
      element: <Payment />,
    },
    {
      path: accessRoute.CUSTOMER_PAYMENT.path,
      private: true,
      name: accessRoute.CUSTOMER_PAYMENT.name,
      key: accessRoute.CUSTOMER_PAYMENT.key,
      belongsToSidebar: false,
      icon: accessRoute.CUSTOMER_PAYMENT.icon,
      element: <CustomerPayment />,
    },
    {
      path: accessRoute.PAYMENT_OPTION.path,
      private: true,
      name: accessRoute.PAYMENT_OPTION.name,
      key: accessRoute.PAYMENT_OPTION.key,
      belongsToSidebar: false,
      icon: accessRoute.PAYMENT_OPTION.icon,
      element: <PaymentOption />,
    },
    {
      path: accessRoute.PAY_NOW_PAYMENT.path,
      private: true,
      name: accessRoute.PAY_NOW_PAYMENT.name,
      key: accessRoute.PAY_NOW_PAYMENT.key,
      belongsToSidebar: false,
      icon: accessRoute.PAY_NOW_PAYMENT.icon,
      element: <PayNowPayment />,
    },
    // {
    //   path: accessRoute.MEMBER_SHIP_PAYMENT.path,
    //   private: true,
    //   name: accessRoute.MEMBER_SHIP_PAYMENT.name,
    //   key: accessRoute.MEMBER_SHIP_PAYMENT.key,
    //   belongsToSidebar: false,
    //   icon: accessRoute.MEMBER_SHIP_PAYMENT.icon,
    //   element: <MemberShipPayment />,
    // },
  ];
}

export const accessRoute = {
  SELECT_PAYMENT: {
    path: '/customer/select-payment',
    name: 'Payment',
    key: 'payment',
    for: 'payment',
    className: 'gg-payment',
    icon: <em className="gg-payment"></em>,
  },
  PAYMENT: {
    path: '/customer/payment',
    name: 'Card Details',
    key: 'payment',
    for: 'payment',
    className: 'gg-payment',
    icon: <em className="gg-payment"></em>,
  },
  CUSTOMER_PAYMENT: {
    path: '/customer/pay-now/:id',
    name: 'Customer Pay Now',
    key: 'payment',
    for: 'payment',
    className: 'gg-payment',
    icon: <em className="gg-payment"></em>,
  },
  PAYMENT_OPTION: {
    path: '/customer/pay-option/:id',
    name: 'Customer Pay Option',
    key: 'payment',
    for: 'payment',
    className: 'gg-payment',
    icon: <em className="gg-payment"></em>,
  },
  PAY_NOW_PAYMENT: {
    path: '/customer/payment/now/:id',
    name: 'Customer Pay Now',
    key: 'payment',
    for: 'payment',
    className: 'gg-payment',
    icon: <em className="gg-payment"></em>,
  },
  // MEMBER_SHIP_PAYMENT: {
  //   path: '/customer/pay-membership',
  //   name: 'Member Ship Pay ',
  //   key: 'payment',
  //   for: 'payment',
  //   className: 'gg-payment',
  //   icon: <em className="gg-payment"></em>,
  // },
};

export const getChildrenRoutes = () => {
  return route();
};
