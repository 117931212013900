export const VOICE_AI_RECORD_ENDPOINT = {
  getVoiceAIRecordList: {
    url: '/v1/lex-chatbot/customer/booking-log',
    method: 'GET',
  },
  updateAdminStatus: () => ({
    url: `/v1/lex-chatbot/customer/booking-log/update-admin-status`,
    method: 'PUT',
  }),
  viewNote: () => ({
    url: `/v1/lex-chatbot/customer/booking-log/admin-note`,
    method: 'GET',
  }),
  transcriptData: (id) => ({
    url: `/v1/lex-chatbot/transcript/${id}`,
    method: 'GET',
  }),
  addAdminNote: {
    url: 'v1/lex-chatbot/customer/booking-log/update-note',
    method: 'POST',
  },
};
