import { VOICE_AI_RECORD_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';

export const getVoiceAIRecordService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...VOICE_AI_RECORD_ENDPOINT?.getVoiceAIRecordList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const updateAdminStatusService = async ({ bodyData, id }) => {
  try {
    const payload = {
      ...VOICE_AI_RECORD_ENDPOINT?.updateAdminStatus(),
      bodyData: {
        ...bodyData,
        id: id,
      },
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const viewNoteService = async ({ id }) => {
  try {
    const payload = {
      ...VOICE_AI_RECORD_ENDPOINT?.viewNote(),
      queryParams: { id: id },
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getTranscriptData = async (id) => {
  try {
    const payload = {
      ...VOICE_AI_RECORD_ENDPOINT?.transcriptData(id),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const addAdminNoteService = async ({ body }) => {
  try {
    const payload = {
      ...VOICE_AI_RECORD_ENDPOINT?.addAdminNote,
      bodyData: body,
    };
    return await APIRequest(payload);
  } catch (err) {
    console.log(err);
  }
};
