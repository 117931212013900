import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { modalNotification, setLocalStorageToken } from '../utils';

function useSubmit({
  service,
  id,
  afterSubmitActionType,
  action,
  redirectUrl,
  detailsService,
  onModalCancel,
  refresh = null,
  setSubmitCount = false,
  submitCount = false,
}) {
  const navigate = useNavigate();
  const [state, setState] = useState({
    data: null,
    isLoading: null,
  });
  const { data, isLoading } = state;

  useEffect(() => {
    if (id && detailsService) {
      getEntityDetail(id);
    }
  }, [id]);

  function renewState() {
    setState({ data: null, isLoading: null });
  }

  async function getEntityDetail(id) {
    try {
      setState({
        ...state,
        isLoading: 'GET-DETAIL',
      });
      const res = await detailsService({ id });
      if (res?.data) {
        setState({
          ...state,
          data: res.data,
          isLoading: null,
        });
        return true;
      }
      setState({ ...state, isLoading: null });
    } catch (err) {
      console.log(err);
    }
  }

  async function onSubmit(payload) {
    setState({ ...state, isLoading: 'SUBMIT' });
    try {
      let requestPipelineData = {};
      if (payload) {
        requestPipelineData.body = { ...payload };
      }

      if (id) {
        requestPipelineData.id = id;
      }

      const res = await service({ ...requestPipelineData });
      let newState = { ...state, isLoading: null };
      if (res?.success) {
        modalNotification({
          type: 'success',
          message: res?.message,
        });
        if (setSubmitCount) {
          setSubmitCount(submitCount + 1);
        }
        if (redirectUrl) {
          navigate(redirectUrl);
        }
        setState({ ...state, isLoading: null });
        if (refresh) refresh();

        if (afterSubmitActionType === 'redirect') {
          navigate(redirectUrl);
        } else if (afterSubmitActionType === 'close-modal') {
          onModalCancel();
          if (action) {
            action();
          }
        }
      } else {
        setState({ ...state, isLoading: null });
      }
      if (res?.data) {
        newState = { data: res.data };
        if (res?.data?.token) {
          setLocalStorageToken(res?.data?.token);
        }
        setState({ ...state, ...newState });
        if (afterSubmitActionType === 'redirect') {
          navigate(redirectUrl);
        } else if (afterSubmitActionType === 'close-modal') {
          onModalCancel();
          if (action) {
            action();
          }
        }
        return true;
      }
      // setState({ ...newState });
    } catch (err) {
      console.log(err);
      setState({ ...state, isLoading: null });
    }
  }

  return [onSubmit, data, isLoading, renewState];
}

export default useSubmit;
