import i18next from 'i18next';
import { accessRoute } from '../../routes/Dashboard';
import {
  bookingType,
  checkValidData,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { getFullName } from '../../utils/text.util';
import {
  dateFormatter,
  phoneNumberFormate,
  readMoreTextShow,
} from '../../utils';
import { DATE_FULL_MONTH__DATE_TH_YEAR_TIME } from '../../config';

export const requestTableColumn = ({ onSort, page, sizePerPage }) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      sorter: false,
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'userName',
      title: i18next.t('text.common.UserName'),
      sorter: true,
      onSort: (field, order) => onSort('userName', order),
      render: (cell, row) =>
        getFullName(
          row?.Customer?.firstName,
          row?.Customer?.lastName,
          '',
          row?.Customer?.CustomerMembership?.MembershipPlan?.name
          // 'plan name',
          // 101,
          // 'percentage'
        ),
    },
    {
      dataIndex: 'phoneNumberCustomer',
      title: i18next.t('text.common.phoneNumber'),
      sorter: true,
      onSort: (field, order) => onSort('phoneNumberCustomer', order),
      render: (cell, row) => {
        const formatNumber = phoneNumberFormate(
          row?.Customer?.phoneNumber,
          row?.Customer?.countryPhoneCode
        );
        return formatNumber ?? '-';
      },
    },
    {
      dataIndex: 'serviceType',
      dataIndex1: 'serviceTypePara',
      title: i18next.t('text.common.serviceType'),
      sorter: true,
      onSort: (field, order) => onSort('ServiceType', order),
      render: (cell, row) => (
        <>
          <b>
            {checkValidData(row?.ServiceCategory?.ServiceCategoryDetails?.name)}
          </b>{' '}
          - {checkValidData(row?.ServiceCategory?.name)}
        </>
      ),
    },
    {
      dataIndex: 'location',
      title: i18next.t('text.common.location'),
      sorter: true,
      onSort: (field, order) => onSort('Address', order),
      render: (cell, row) => checkValidData(row?.Address?.addressLine1),
    },
    {
      dataIndex: 'providerName',
      title: i18next.t('text.common.providerName'),
      sorter: true,
      onSort: (field, order) => onSort('providerName', order),
      render: (cell, row) => (
        <>
          {row?.providerName?.length
            ? readMoreTextShow(
                row?.providerName
                  .map((e) => `${e?.firstName} ${e?.lastName}`)
                  .join(', '),
                i18next.t('text.common.providerName'),
                30
              )
            : checkValidData(
                getFullName(
                  row?.Provider?.firstName,
                  undefined,
                  row?.Provider?.lastName
                )
              )}
        </>
      ),
    },
    {
      dataIndex: 'phoneNumberProvider',
      title: i18next.t('text.common.phoneNumber'),
      sorter: true,
      onSort: (field, order) => onSort('phoneNumberProvider', order),
      render: (cell, row) => (
        <>
          {row?.NcaBookingRequests?.length
            ? readMoreTextShow(
                row?.NcaBookingRequests.map((e) =>
                  phoneNumberFormate(
                    e?.Provider?.phoneNumber,
                    e?.Provider?.countryPhoneCode
                  )
                ).join(', '),
                i18next.t('text.common.phoneNumber'),
                40
              )
            : phoneNumberFormate(
                row?.Provider?.phoneNumber,
                row?.Provider?.countryPhoneCode
              )}
        </>
      ),
    },
    {
      dataIndex: 'dateTime',
      title: i18next.t('text.common.dateTime'),
      sorter: true,
      onSort: (field, order) => onSort('dateTime', order),
      render: (cell, row) =>
        row?.createdAt
          ? dateFormatter(row?.createdAt, DATE_FULL_MONTH__DATE_TH_YEAR_TIME)
          : '-',
    },
    {
      dataIndex: 'bookingType',
      title: i18next.t('text.common.requestType'),
      sorter: true,
      render: (cell, row) => checkValidData(bookingType(cell)),
    },
  ];
};

export const requestBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.request.title'),
    },
  ];
};

// For temporally
export const RequestsService = () => {
  return [
    {
      id: 1,
      userName: 'Akash customer',
      phoneNumber: '915555555553',
      serviceTypeHeading: 'HVAC - ',
      serviceTypePara: 'Same day free emergency quote',
      location: 'Vijay Nagar, Indore',
      providerName: 'Chris ios',
      providerPhoneNumber: '914444444448',
      dateTime: '	Tue, December 6, 2022 09:49 AM',
      requestType: 'Now',
    },
  ];
};
