import React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ImageElement, NoteFooter } from '../../components';
import { logout } from '../../redux/index.redux';

export default function CancelNcaService() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (!location.state) {
      navigate('/admin/dashboard');
    }
    return () => {
      if (location.state) {
        dispatch(logout());
        navigate('/login');
      }
    };
  }, []);

  return (
    <div className="nk-block nk-block-middle nk-auth-body wide-md">
      <div className="brand-logo pb-4 text-center">
        <Link className="logo-link" to="#">
          <ImageElement
            source="logo.svg"
            className="logo-dark logo-img logo-img-lg"
            alt="logo-dark"
          />
        </Link>
      </div>
      <div className="card card-bordered">
        <div className="card-inner card-inner-lg">
          <div className="nk-block-head">
            <div className="nk-block-head-content">
              <h5 className="text-center">
                Sorry to see you go. We wish you the very best.
              </h5>

              <h6 className="mb-3 mb-xl-4 text-center">
                {' '}
                <b> {t('text.common.thankYouNot')}</b>
              </h6>
              <div className="text-center">
                <Link
                  to="#"
                  className="btn btn-primary btn-lg"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(logout());
                    navigate('/login');
                  }}
                >
                  GO To Home
                </Link>
              </div>
              <NoteFooter title="Note">
                <li>
                  After login please select a plan to get access of all the
                  services.
                </li>
                <li>
                  Now your plan is canceled. so your service providers and
                  customers will not able to access their platforms.
                </li>
                <li>Contact Admin: johnnie@goodguys.app</li>
              </NoteFooter>
              {/* <Link
                className="btn btn-primary btn-lg"
                to={accessRoute.DASHBOARD.path}
              >
                {t('text.common.Ok')}
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
