/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Select as AntSelect, Switch, Skeleton } from 'antd';
import { useField } from 'formik';
import React, { useEffect, useState } from 'react';
import { memo } from 'react';
import './style.scss';
import { debounce, getLocalStorage, removeDuplicates } from '../../../utils';
import moment from 'moment';

const { Option } = AntSelect;

function Select({
  // children,
  options = [],
  label = '',
  name,
  icon,
  listService,
  listKeys = { nameKey: 'name', nameId: 'id', useKey: 'phoneCode' },
  formik,
  extraQuary = {},
  // dependentService,
  isToggle = false,
  onItemChange,
  scope = '',
  // defaultValue = null,
  setFieldValue,
  // dependency,
  size = 'medium',
  selectOptionGroup = false,
  withCountryCode = false,
  setCountry,
  extraClassName,
  loading: isLoading = false,
  isCustomIcon = false,
  isFormik = true,
  extraLoading = false,
  isErrorToast = true,
  checkEmail = false,
  cityLoader = true,
  searchOrNot = false,
  selectOPtionDefault = false,
  selectOPtionValue = null,
  setData,
  isApiCall = false,
  addExtraObj = false,
  isDateFormate = false,
  isOrder = false,
  ...rest
}) {
  let [field, meta, helpers] = useField(name);
  const config = { ...field, ...rest };
  const [isScrollLoader, setIsScrollLoader] = useState(false);

  const [limit, setLimit] = useState(10);
  const [extraLoader, setExtraLoader] = useState(extraLoading);
  const [searchData, setSearchData] = useState('');

  const [state, setState] = useState({
    data: [...options],
    groupData: [...options],
    loading: isLoading,
    count: 0,
  });
  const selectProps = {};

  useEffect(() => {
    if (listService) {
      getList(listService);
      if (isApiCall) {
        setLimit(10);
      }
    } else {
      setState({ ...state, data: [...options], groupData: [...options] });
    }
  }, [isApiCall]);

  useEffect(() => {
    if (!listService && options) {
      setState({ ...state, data: [...options], groupData: [...options] });
    }
  }, [options]);

  // for loading
  useEffect(() => {
    setExtraLoader(extraLoading);
  }, [extraLoading]);

  async function getList(listService, limitQuery, isScroll, search) {
    try {
      if (!isScroll) {
        setState({ ...state, loading: true });
      } else {
        setIsScrollLoader(true);
      }
      const res = await listService({
        type: 'all',
        queryParams: {
          ...extraQuary,
          scope: scope,
          ...(limitQuery && limitQuery),
          ...(search && { search }),
        },
        isErrorToast,
      });
      let list = [];
      let groupList = [];
      if (Array.isArray(res)) {
        list = [
          ...res.map((e) => ({
            ...(!isDateFormate && {
              name: `${e[listKeys?.nameKey]} ${
                listKeys?.lastNameKey ? e[listKeys?.lastNameKey] : ''
              } ${withCountryCode ? `(${e?.countryCode})` : ''}`,
            }),
            ...(isDateFormate && {
              name: `${moment(e[listKeys?.nameKey]).format('YYYY/MM/DD')} ${
                listKeys?.jobText ? listKeys?.jobText : ''
              } ${listKeys?.lastNameKey ? e[listKeys?.lastNameKey] : ''} ${
                withCountryCode ? `(${e?.countryCode})` : ''
              }`,
            }),

            id: e[listKeys?.nameId],
            useKey: e[listKeys?.useKey],
            data: e,
          })),
        ];
      }

      if (setCountry) setCountry(res?.[0]);
      if (selectOptionGroup) {
        groupList = [
          ...res.map((e) => {
            return {
              label: e[listKeys?.nameKey],
              options: [
                ...(e[listKeys?.useKey]
                  ? e[listKeys?.useKey]?.map((item) => ({
                      label: item[listKeys?.innerLabel],
                      value: item[listKeys?.nameId],
                    }))
                  : []),
              ],
            };
          }),
        ];
      }
      if (addExtraObj) {
        list = removeDuplicates([addExtraObj, ...list], false, 'no');
      }
      setState({
        ...state,
        data: [...list],
        groupData: [...groupList],
        loading: false,
        count: res?.count,
      });

      if (setData) setData([...list]);

      setIsScrollLoader(false);
    } catch (err) {
      setState({ ...state, loading: false });
      console.log(err);
    }
  }

  if (formik?.values) {
    selectProps.value =
      (rest?.mode === 'multiple' || rest?.mode === 'tags') &&
      !formik.values[field?.name]
        ? undefined
        : formik.values[field?.name]
        ? formik.values[field?.name]
        : undefined;
  }

  if (meta && meta.touched && meta.error) {
    config.error = true;
    config.helperText = meta.error;
  }
  const { data, loading, groupData } = state;

  const handleChangeSelect = (value, ...rest) => {
    if (checkEmail) {
      var validRegex = /^\S+@\S+\.\S+$/;
      const validValues = value.filter((item) => {
        return item.match(validRegex);
      });
      helpers.setValue(validValues);
      return;
    }

    if (isFormik) {
      helpers.setValue(value);
    }

    if (onItemChange) {
      // for phone code only
      let getPhoneCode =
        data && data.filter((e) => e.id === value).map((code) => code.useKey);
      onItemChange(
        value,
        formik,
        Array.isArray(getPhoneCode) && getPhoneCode.length > 0
          ? getPhoneCode
          : undefined,
        rest,
        state?.data
      );
    }
    if (setFieldValue) setFieldValue(value);
  };

  const handleChangeToggle = (value) => {
    helpers.setValue(value ? 'active' : 'inactive');
  };

  const debouncedFetchData = debounce(getList, 700);

  const onSearch = (e) => {
    if (e?.length > 2 && searchOrNot) {
      setSearchData(e);
      debouncedFetchData(listService, false, true, e);
    }
  };

  let apiLimit = limit;
  const handleScroll = (e) => {
    const { target } = e;
    let apiCount = getLocalStorage({
      keyName: 'isScrollCount',
    });
    if (searchOrNot && apiCount > apiLimit) {
      if (
        Math.ceil(target?.scrollTop + target?.offsetHeight) ===
        target?.scrollHeight
      ) {
        apiLimit = apiLimit + 10;

        getList(
          listService,
          {
            limit: apiLimit,
            offset: 0,
          },
          true,
          searchData
        );
      }
    }
    setLimit(apiLimit);
  };

  const handleCloseSelect = (open) => {
    if (searchOrNot && !open) {
      getList(
        listService,
        {
          limit: 10,
          offset: 0,
        },
        true
      );
    }
    setSearchData('');
    setLimit(10);
  };

  if (isToggle) {
    return (
      <Form.Item
        className="mb-0"
        label={label}
        // name={name}
        help={meta.error && meta?.error && meta?.touched ? meta.error : ''}
        validateStatus={config.error ? 'error' : 'success'}
      >
        <Switch
          checked={formik?.values[name] === 'active' ? true : false}
          onChange={handleChangeToggle}
          {...rest}
        />
      </Form.Item>
    );
  }

  return (
    <Form.Item
      className={`mb-0 ${extraClassName && extraClassName}`}
      label={label}
      // name={name}
      help={meta.error && meta?.error && meta?.touched ? meta.error : ''}
      validateStatus={config.error ? 'error' : 'success'}
    >
      {icon}
      {selectOptionGroup ? (
        <div style={{ position: 'relative' }} className="selectWrapper">
          {extraLoader || loading ? (
            <Skeleton paragraph={{ rows: 0 }} active />
          ) : (
            <AntSelect
              size={size}
              {...rest}
              {...selectProps}
              onChange={handleChangeSelect}
              loading={loading}
              options={groupData}
              getPopupContainer={(trigger) => trigger.parentElement}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option &&
                option.label &&
                typeof option.label === 'string' &&
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          )}
        </div>
      ) : (
        <div style={{ position: 'relative' }} className="selectWrapper">
          {(extraLoader || loading) && cityLoader ? (
            <Skeleton paragraph={{ rows: 0 }} active />
          ) : (
            <AntSelect
              size={size}
              optionFilterProp="children"
              filterOption={(input, option) => {
                return (
                  option?.title &&
                  ('' + option?.title)
                    ?.toLowerCase()
                    ?.indexOf(input?.toLowerCase()) >= 0
                );
              }}
              showSearch
              {...rest}
              {...selectProps}
              onChange={handleChangeSelect}
              loading={extraLoader || loading || isScrollLoader}
              getPopupContainer={
                !isOrder ? (trigger) => trigger.parentElement : undefined
              }
              onPopupScroll={handleScroll}
              onSearch={(e) => onSearch(e)}
              className={`${isScrollLoader ? 'disable-search' : ''}`}
              onDropdownVisibleChange={(open) => handleCloseSelect(open)}
            >
              <>
                {selectOPtionDefault && (
                  <Option key={selectOPtionDefault} value={selectOPtionValue}>
                    {selectOPtionDefault}
                  </Option>
                )}
                {data.length > 0 &&
                  data.map((item, key) => (
                    <Option
                      key={key}
                      value={
                        item.value ||
                        (typeof item?.value === 'boolean' &&
                        item?.value === false
                          ? false
                          : item?.value === 0
                          ? 0
                          : item.id
                          ? item.id
                          : item.value)
                      }
                      title={item.name || item.value}
                      disabled={item?.disabled || false}
                    >
                      <div className="d-flex align-items-center">
                        {isCustomIcon && item.icon ? (
                          <div>{item.icon}</div>
                        ) : null}
                        <div>{item.name || item.value}</div>&nbsp;
                        {item?.chip && (
                          <span className="badge bg-primary">
                            {item?.chipValue || ''}
                          </span>
                        )}
                      </div>
                    </Option>
                  ))}
              </>
            </AntSelect>
          )}
        </div>
      )}
    </Form.Item>
  );
}

export default memo(Select);
