import { t } from 'i18next';
import React from 'react';
import {
  StoreForgetPasswordForm,
  StorePageContainer,
} from '../../../../components';
import useSubmit from '../../../../hooks/useSubmit.hook';
import { forgetPassword } from '../../../../services/CustomerApp/index.service';

function StoreForgetPassword() {
  const [onSubmit, , loading] = useSubmit({
    // service: changePassword,
    service: forgetPassword,
    afterSubmitActionType: 'redirect',
    serverMessage: true,
  });
  return (
    <StorePageContainer breadcrumb={[]}>
      <div className="becomePage_head mb-0">
        <div className="becomePage_signup text-center">
          <h2 className="mb-1 font-sm">{t('text.store.forgetPassword')}</h2>
          <p>{t('text.store.forgetPasswordDetails')}</p>
        </div>
      </div>
      <div className="becomePage_auth mx-auto mb-0">
        <StoreForgetPasswordForm onSubmit={onSubmit} loading={loading} />
      </div>
    </StorePageContainer>
  );
}

export default StoreForgetPassword;
