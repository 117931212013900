import i18next from 'i18next';
import { getInputSearchFilter } from '../../components';
import { accessRoute } from '../../routes/Dashboard';
import {
  checkValidData,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';

export const marketingEmailTemplateBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.marketing.marketingEmailTemplate'),
    },
  ];
};
export const editMarketingEmailTemplateBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '/admin/marketing-email-template',
      name: i18next.t('text.marketing.marketingEmailTemplate'),
    },
    {
      path: '#',
      name: i18next.t('text.marketing.editEmailMarketingTemplate'),
    },
  ];
};
export const addMarketingEmailTemplateBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '/admin/marketing-email-template',
      name: i18next.t('text.marketing.marketingEmailTemplate'),
    },
    {
      path: '#',
      name: i18next.t('text.marketing.addEmailMarketingTemplate'),
    },
  ];
};

export const marketingEmailTemplateColumn = ({
  Action,
  onFilterChange,
  filter,
  page,
  sizePerPage,
  Toggle,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'subject',
      title: i18next.t('text.emailMessage.emailSubject'),
      className: 'sorting',
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'subject',
        placeholder: 'email subject',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'emailCode',
      title: i18next.t('text.emailMessage.emailCode'),
      className: 'sorting',
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'emailCode',
        placeholder: 'email code',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'status',
      title: i18next.t('text.common.status'),
      render: (cell, row) => {
        return <Toggle isDropDown row={row} cell={cell} />;
      },
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.localization.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => {
        return <Action row={row} cell={cell} />;
      },
    },
  ];
};
