export const validation = {
  groupName: 'Please enter the group name',
  groupMembers: 'Please add atleast one service provider',
  groupCreatedSuccess: 'Group created successfully',
  groupEditSuccess: 'Group update successfully',
  groupCreatedFailed: 'Group created failed',
  addGroupMemberSuccess: 'Member added successfully',
  addGroupMemberFailed: 'Member added failed',
  removeGroupMemberSuccess: 'Member removed successfully',
  removeGroupMemberFailed: 'Member removed failed',
  invalidFileError: "You can't upload this type of file.",
  uploadFileSize: "You can't upload file more than 25MB",
  removeGroupSuccess:'Group removed successfully',
  removeGroupFailed: 'Group removed failed',
};
