import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { backToPage } from '../../../../utils';
import { formSubmit } from '../../../../hooks/useFormSubmit.hook';
import { addProviderBreadcrumb } from '../../../../services/Columns';
import { accessRoute } from '../../../../routes/TeamSetting';
import { AddProviderForm, PageContainerWrapper } from '../../../../components';
import { addProviderService } from '../../../../services/TeamSetting/provider.service';

function AddProviderProfile() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const redirectUrl = accessRoute.PROVIDER.path;
  const breadcrumb = addProviderBreadcrumb();

  async function onSubmit(values) {
    if (values.emergencyJobRadius === 'other') {
      values.emergencyJobRadius = values?.emergencyJobRadius1;
      delete values?.emergencyJobRadius1;
    }
    if (values.scheduledJobRadius === 'other') {
      values.scheduledJobRadius = values?.scheduledJobRadius1;
      delete values?.scheduledJobRadius1;
    }
    formSubmit({
      values,
      service: addProviderService,
      navigate,
      redirectUrl,
      setIsLoading,
    });
  }
  return (
    <div className="wide-md mx-auto">
      <PageContainerWrapper
        breadcrumb={breadcrumb}
        btnArray={['extraButton']}
        extraBtnText={t('text.common.back')}
        extraBtnClass="btn btn-outline-light bg-white"
        onExtraButtonHandleShow={() => backToPage(navigate, redirectUrl)}
        heading={t('text.provider.addProviderTitle')}
      >
        <AddProviderForm
          onSubmit={onSubmit}
          isLoading={isLoading}
          backToPage={() => backToPage(navigate, redirectUrl)}
          hideField={true}
        />
      </PageContainerWrapper>
    </div>
  );
}

export default AddProviderProfile;
