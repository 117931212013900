export const text = {
  title: 'General Settings',
  projectNameForFront: 'Project Name For Admin/Front',
  projectNameForFrontTipText:
    'This will appear as a Project name at all places like front and admin panel',
  enterProjectNameForFront: 'Enter project name for admin/front',
  defaultDistanceUnit: 'Default Distance Unit',
  defaultDistanceUnitTipText: 'Admin country distance unit',
  selectDefaultDistanceUnit: 'Select default distance unit',
  adminCountryISDCode: 'Admin Country ISD Code',
  adminCountryISDCodeTipText:
    'This code will be used for calling and sending SMS from application.',
  enterAdminCountryISDCode: 'Enter admin country ISD code',
  displayRecordPerPageInAdminPanel: 'Display Record Per Page In Admin Panel',
  displayRecordPerPageInAdminPanelTipText:
    'Number of records to display per page in admin panel',
  enterDisplayRecord: 'Enter display record per page in admin panel',
  countryCodeTipText:
    'Country selected here will appear as selected by default at all the forms for the website/applications.',
  noteGeneralSetting:
    'Please close the application and open it again to see the settings reflected after saving the new setting values above.',
};
