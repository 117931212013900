import i18next from 'i18next';
import {
  CommonButton,
  getInputSearchFilter,
  getMultiSelectSearchFilter,
  getPriceFilter,
} from '../../components';
import {
  checkValidData,
  checkValidPrice,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { getFullName } from '../../utils/text.util';
import {
  convertMinDotSecToMinutes,
  minuteIntoHHMMSS,
  minutesToTime,
  readMoreTextShow,
} from '../../utils';
import { getProviderListService } from '../index.service';

export const serviceProviderBreadcrumb = () => {
  return [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: i18next.t('text.report.serviceProviderPaymentReport'),
    },
  ];
};

const getProviderListData = () => {
  return [
    {
      name: 'abhishekYadav',
      value: 'Abhishek Yadav',
    },
    {
      name: 'aditiTest',
      value: 'Aditi Test',
    },
  ];
};

const getFilterListData = (value) => {
  return value.map((item) => ({
    name: `${item?.firstName} ${item?.lastName} - (${item?.email})`,
    id: item?.id,
  }));
};

const purchaseDeductFields = {
  fromOperatorField: 'purchaseFirstOperator',
  fromOperatorAmountField: 'purchaseFirstAmount',
  conditionalField: 'purchaseConditional',
  toOperatorField: 'purchaseSecondOperator',
  toOperatorAmountField: 'purchaseSecondAmount',
};

const totalFeesFields = {
  fromOperatorField: 'totalFeesFirstOperator',
  fromOperatorAmountField: 'totalFeesFirstAmount',
  conditionalField: 'totalFeesConditional',
  toOperatorField: 'totalFeesSecondOperator',
  toOperatorAmountField: 'totalFeeSecondAmount',
};

const platformFeesFields = {
  fromOperatorField: 'platformFeesFirstOperator',
  fromOperatorAmountField: 'platformFeesFirstAmount',
  conditionalField: 'platformFeesConditional',
  toOperatorField: 'platformFeesSecondOperator',
  toOperatorAmountField: 'platformFeeSecondAmount',
};
const subLaborFields = {
  fromOperatorField: 'sublaborFirstOperator ',
  fromOperatorAmountField: 'sublaborFirstAmount',
  conditionalField: 'sublaborConditional',
  toOperatorField: 'sublaborSecondOperator',
  toOperatorAmountField: 'sublaborSecondAmount',
};
const providerPayAmountFields = {
  fromOperatorField: 'providerPayFirstOperator ',
  fromOperatorAmountField: 'providerPayFirstAmount',
  conditionalField: 'providerPayConditional',
  toOperatorField: 'providerPaySecondOperator',
  toOperatorAmountField: 'providerPaySecondAmount',
};
const subLaborDeductFields = {
  fromOperatorField: 'sublaborDeductFirstOperator ',
  fromOperatorAmountField: 'sublaborDeductFirstAmount',
  conditionalField: 'sublaborDeductConditional',
  toOperatorField: 'sublaborDeductSecondOperator',
  toOperatorAmountField: 'sublaborDeductSecondAmount',
};

export const serviceProviderReportColumn = ({
  onSort,
  onFilterChange,
  Action,
  setRowData,
  TotalSubLaborPayDetails,
  filter = {},
  page,
  sizePerPage,

  subLabourModalShow,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'providerName',
      filterDataIndex: ['providerUserId'],
      title: i18next.t('text.report.providerName'),
      headerClasses: 'sorting',
      sorter: true,
      sortType: 'providerName',
      onSort: (field, order) => onSort('providerName', order),
      render: (cell, row) =>
        checkValidData(
          getFullName(row?.Provider?.firstName, null, row?.Provider?.lastName)
        ),
      export: true,
      exportName: i18next.t('text.report.providerName'),
      exportKey: 'Provider',
      exportFormatter: (cell, row) =>
        checkValidData(
          getFullName(row?.Provider?.firstName, null, row?.Provider?.lastName)
        ),
      ...getMultiSelectSearchFilter({
        dataIndex: 'providerUserId',
        onFilterChange,
        name: 'Provider Name',
        list: [],
        mode: 'multiple',
        listService: getProviderListService,
        listKeys: { nameKey: ['firstName', 'lastName'], nameId: 'id' },
        searchOrNot: true,
        filter,
      }),
    },
    {
      dataIndex: 'accountName',
      filterDataIndex: ['accountName'],
      title: i18next.t('text.report.providerAccountName'),
      headerClasses: 'sorting',
      //temporary sorting comment
      // sorter: true,
      // sortType: 'accountName',
      exportKey: 'Provider',
      // onSort: (field, order) => onSort('accountName', order),
      export: true,
      exportName: i18next.t('text.report.providerAccountName'),
      exportFormatter: (cell, row) =>
        checkValidData(row?.Provider?.UserBank?.bankAccountHolderName),
      render: (cell, row) =>
        checkValidData(row?.Provider?.UserBank?.bankAccountHolderName),
      ...getInputSearchFilter({
        dataIndex: 'accountName',
        placeholder: 'provider account name',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'bankName',
      filterDataIndex: ['bankName'],
      title: i18next.t('text.report.bankName'),
      headerClasses: 'sorting',
      // sorter: true,
      // sortType: 'bankName',
      // onSort: (field, order) => onSort('bankName', order),
      export: true,
      exportName: i18next.t('text.report.bankName'),
      exportKey: 'Provider',
      exportFormatter: (cell, row) =>
        checkValidData(row?.Provider?.UserBank?.bankName),
      render: (cell, row) => checkValidData(row?.Provider?.UserBank?.bankName),
      ...getInputSearchFilter({
        dataIndex: 'bankName',
        placeholder: 'bank name',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'accountNumber',
      filterDataIndex: ['accountNumber'],
      title: i18next.t('text.report.accountNumber'),
      headerClasses: 'sorting',
      // sorter: true,
      // sortType: 'accountNumber',
      // onSort: (field, order) => onSort('accountNumber', order),
      export: true,
      exportName: i18next.t('text.report.accountNumber'),
      exportKey: 'Provider',
      exportFormatter: (cell, row) =>
        checkValidData(row?.Provider?.UserBank?.accountNumber),
      render: (cell, row) =>
        checkValidData(row?.Provider?.UserBank?.accountNumber),
      ...getInputSearchFilter({
        dataIndex: 'accountNumber',
        placeholder: 'account number',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'routingNumber',
      filterDataIndex: ['RoutingNumber'],
      title: i18next.t('text.report.routingNumber'),
      headerClasses: 'sorting',
      // sorter: true,
      // sortType: 'routingNumber',
      // onSort: (field, order) => onSort('routingNumber', order),
      export: true,
      exportName: i18next.t('text.report.routingNumber'),
      exportKey: 'Provider',
      exportFormatter: (cell, row) =>
        checkValidData(row?.Provider?.UserBank?.bankAccountRoutingNumber),
      render: (cell, row) =>
        checkValidData(row?.Provider?.UserBank?.bankAccountRoutingNumber),
      ...getInputSearchFilter({
        dataIndex: 'RoutingNumber',
        placeholder: 'routing number',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'location',
      title: i18next.t('text.report.location'),
      headerClasses: 'sorting',
      // sorter: true,
      export: true,
      exportName: i18next.t('text.report.location'),
      exportKey: 'Provider',
      exportFormatter: (cell, row) =>
        checkValidData(
          row?.Provider?.Provider?.work_location ||
            row?.Provider?.Provider?.workLocation
        ),
      render: (cell, row) =>
        readMoreTextShow(
          checkValidData(
            row?.Provider?.Provider?.work_location ||
              row?.Provider?.Provider?.workLocation
          ),
          i18next.t('text.report.location'),
          30
        ),
      // sortType: 'location',
      // onSort: (field, order) => onSort('location', order),
    },
    {
      dataIndex: 'totalJobTime',
      title: i18next.t('text.report.totalJobTiming'),
      headerClasses: 'sorting',
      // sorter: true,
      export: true,
      exportName: i18next.t('text.report.totalJobTiming'),
      exportFormatter: (_, row) => {
        if (row?.totalJobTime) {
          return minuteIntoHHMMSS(row?.totalJobTime);
        }
      },
      render: (_, row) => {
        if (row?.totalJobTime) {
          return minuteIntoHHMMSS(row?.totalJobTime);
        }
      },
      // sortType: 'totalJobTime',
      // onSort: (field, order) => onSort('totalJobTime', order),
    },
    {
      dataIndex: 'totalJobFee',
      filterDataIndex: ['totalFeesConditional'],
      title: i18next.t('text.report.aTotalJobTiming'),
      headerClasses: 'sorting',
      // sorter: true,
      exportFormatter: (cell, row) => checkValidPrice(cell),
      // onSort: (field, order) => onSort('totalJobFee', order),
      render: (cell, row) => checkValidPrice(cell),
      ...getPriceFilter({
        dataIndex: 'totalFees',
        onFilterChange,
        fields: totalFeesFields,
        filter,
        dataIndexKey: 'filterColumn',
      }),
    },
    {
      dataIndex: 'TotalPlatFormFees',
      filterDataIndex: ['platformFeesConditional'],
      title: i18next.t('text.report.bTotalPlatFormFee'),
      headerClasses: 'sorting',
      // sorter: true,
      // sortType: 'TotalPlatFormFees',
      // onSort: (field, order) => onSort('TotalPlatFormFees', order),
      export: true,
      exportName: i18next.t('text.report.bTotalPlatFormFee'),
      exportKey: 'BookingDetail',
      exportFormatter: (cell, row) =>
        checkValidPrice(row?.BookingDetail?.TotalPlatFormFees),
      render: (cell, row) =>
        checkValidPrice(row?.BookingDetail?.TotalPlatFormFees),
      ...getPriceFilter({
        dataIndex: 'platformFees',
        onFilterChange,
        fields: platformFeesFields,
        filter,
        dataIndexKey: 'filterColumn',
      }),
    },
    {
      dataIndex: 'sublaborPay',
      filterDataIndex: ['sublaborConditional'],
      title: i18next.t('text.report.cTotalSubLaborPay'),
      headerClasses: 'sorting',
      // sortType: 'sublaborPay',
      exportKey: 'BookingDetail',

      // onSort: (field, order) => onSort('sublaborPay', order),
      export: true,
      exportName: i18next.t('text.report.cTotalSubLaborPay'),

      exportFormatter: (cell, row) =>
        checkValidPrice(row?.BookingDetail?.sublaborPay),
      render: (cell, row) => {
        if (row?.BookingDetail?.sublaborPay > 0) {
          return (
            <>
              {checkValidPrice(row?.BookingDetail?.sublaborPay)} <br />{' '}
              <CommonButton
                onClick={(e) => {
                  subLabourModalShow(e);
                  setRowData(row);
                }}
                className="btn btn-primary btn-sm"
              >
                View Details
              </CommonButton>
            </>
          );
        } else {
          return checkValidPrice(row?.BookingDetail?.sublaborPay);
        }
      },
      ...getPriceFilter({
        dataIndex: 'sublaborTotalFees',
        onFilterChange,
        fields: subLaborFields,
        filter,
        dataIndexKey: 'filterColumn',
      }),

      // sorter: true,
    },
    {
      dataIndex: 'sublaborDeduct',
      filterDataIndex: ['sublaborDeductConditional'],
      title: i18next.t('text.report.dTotalDeductAmount'),
      headerClasses: 'sorting',
      // sorter: true,
      // sortType: 'sublaborDeduct',
      // onSort: (field, order) => onSort('sublaborDeduct', order),
      export: true,
      exportName: i18next.t('text.report.dTotalDeductAmount'),
      exportKey: 'BookingDetail',
      exportFormatter: (cell, row) =>
        checkValidPrice(row?.BookingDetail?.sublaborDeduct),
      render: (cell, row) =>
        checkValidPrice(row?.BookingDetail?.sublaborDeduct),
      ...getPriceFilter({
        dataIndex: 'sublaborDeduct',
        onFilterChange,
        fields: subLaborDeductFields,
        filter,
        dataIndexKey: 'filterColumn',
      }),
    },
    {
      dataIndex: 'sublaborPurchase',
      filterDataIndex: ['purchaseConditional'],
      title: i18next.t('text.report.eTotalDeductAmount'),
      headerClasses: 'sorting',
      // sorter: true,
      // sortType: 'sublaborPurchase',
      // onSort: (field, order) => onSort('sublaborPurchase', order),
      export: true,
      exportName: i18next.t('text.report.eTotalDeductAmount'),
      exportKey: 'BookingDetail',
      exportFormatter: (cell, row) =>
        checkValidPrice(row?.BookingDetail?.sublaborPurchase),
      render: (cell, row) =>
        checkValidPrice(row?.BookingDetail?.sublaborPurchase),
      ...getPriceFilter({
        dataIndex: 'sublaborPurchase',
        onFilterChange,
        fields: purchaseDeductFields,
        filter,
        dataIndexKey: 'filterColumn',
      }),
    },
    {
      dataIndex: 'providerPayAmount',
      filterDataIndex: ['providerPayConditional'],
      title: i18next.t('text.report.fTotalDeductAmount'),
      headerClasses: 'sorting',
      // sorter: true,
      // sortType: 'providerPayAmount',
      // onSort: (field, order) => onSort('providerPayAmount', order),
      export: true,
      exportName: i18next.t('text.report.fTotalDeductAmount'),
      exportKey: 'BookingDetail',
      exportFormatter: (cell, row) =>
        checkValidPrice(row?.BookingDetail?.providerPayAmount),
      render: (cell, row) =>
        checkValidPrice(row?.BookingDetail?.providerPayAmount),
      ...getPriceFilter({
        dataIndex: 'providerPayAmount',
        onFilterChange,
        fields: providerPayAmountFields,
        filter,
        dataIndexKey: 'filterColumn',
      }),
    },
    {
      dataIndex: 'providerPaymentStatus',
      title: i18next.t('text.report.viewDetails'),
      headerClasses: 'nk-tb-col-tools text-end',
      render: (cell, row) => {
        return <Action row={row} cell={cell} />;
      },
    },
  ];
};

export const serviceProviderPaymentData = () => {
  return [
    {
      id: 1,
      providerName: 'jorge neon',
      providerAccountName: '	jorge',
      bankName: '	sniiii',
      accountNumber: '000999999991',
      routingNumber: '110000000',
      location: 'New Palasia, Indore',
      totalJobTiming: '20h 39m 55s	',
      totalJobFees: '$ 5,400.00',
      totalPlatformFees: '$ 0.00',
      totalSublaborPay: '',
      totalDeductAmountSublabor: '$ 500.00',
      totalDeductAmountPurchase: '$ 400.00',
      totalProviderPayment: '$ 4,500.00	',
    },
    {
      id: 2,
      providerName: 'Yash Dev',
      providerAccountName: 'Dev',
      bankName: '	Sbbii',
      accountNumber: '000999999991',
      routingNumber: '110000000',
      location: '101, Bansi Trade Centre',
      totalJobTiming: '19m 33s	',
      totalJobFees: '$ 279.85',
      totalPlatformFees: '$ 27.99',
      totalSublaborPay: '',
      totalDeductAmountSublabor: '$ 0.00',
      totalDeductAmountPurchase: '$ 0.00',
      totalProviderPayment: '$ 251.86		',
    },
    {
      id: 3,
      providerName: 'Jacob butt	',
      providerAccountName: 'Jacob Butt	',
      bankName: '	fbi',
      accountNumber: '000123456789',
      routingNumber: '011401533',
      location: 'Indore',
      totalJobTiming: '05m 36s	',
      totalJobFees: '$ 89.95	',
      totalPlatformFees: '$ 0.00',
      totalSublaborPay: '',
      totalDeductAmountSublabor: '$ 500.00',
      totalDeductAmountPurchase: '$ 400.00',
      totalProviderPayment: '$ 89.95	',
    },
    {
      id: 4,
      providerName: 'Jacob butt	',
      providerAccountName: 'Jacob Butt	',
      bankName: '	fbi',
      accountNumber: '000123456789',
      routingNumber: '011401533',
      location: 'Indore',
      totalJobTiming: '05m 36s	',
      totalJobFees: '$ 89.95	',
      totalPlatformFees: '$ 0.00',
      totalSublaborPay: '$ 165.00',
      totalDeductAmountSublabor: '$ 500.00',
      totalDeductAmountPurchase: '$ 400.00',
      totalProviderPayment: '$ 89.95	',
    },
  ];
};
export const serviceProviderReportSublabourDetailsColumn = ({
  onSort,
  page,
  sizePerPage,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'bookingNumber',
      title: i18next.t('text.common.jobNo'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => checkValidData(row?.Booking?.bookingNo),
      onSort: (field, order) => onSort('bookingNumber', order),
    },
    {
      dataIndex: 'providerName',
      title: i18next.t('text.common.providerName'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) =>
        checkValidData(
          getFullName(
            row?.Booking?.Provider?.firstName,
            null,
            row?.Booking?.Provider?.lastName
          )
        ),
      onSort: (field, order) => onSort('providerName', order),
    },
    {
      dataIndex: 'serviceType',
      title: i18next.t('text.common.serviceType'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) =>
        `${
          row?.Booking?.ServiceCategory?.ServiceCategoryDetails?.mainCategory
            ? `${row?.Booking?.ServiceCategory?.ServiceCategoryDetails?.mainCategory} -`
            : ''
        } ${row?.Booking?.ServiceCategory?.subCategory || ''}`,
      onSort: (field, order) => onSort('serviceType', order),
    },
    {
      dataIndex: 'servicePrice',
      title: i18next.t('text.common.servicePrice'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) =>
        checkValidPrice(row?.Booking?.ServiceCategory?.price),
      onSort: (field, order) => onSort('servicePrice', order),
    },
    {
      dataIndex: 'name',
      title: i18next.t('text.report.sublaborPayName'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) =>
        checkValidData(
          getFullName(row?.labor?.firstName, null, row?.labor?.lastName)
        ),
      onSort: (field, order) => onSort('name', order),
    },
    {
      dataIndex: 'total',
      title: i18next.t('text.common.total'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => checkValidPrice(row?.totalAmount),
      onSort: (field, order) => onSort('total', order),
    },
  ];
};
export const subLabourData = [
  {
    id: 1,
    jobNumber: '75833147',
    providerName: 'jorge neon',
    serviceType: 'HVAC - Same Day Emergency Service1',
    servicePrice: '$99.95',
    sublaborPayName: 'akash provider',
    total: '$5.00',
  },
  {
    id: 2,
    jobNumber: '75833148',
    providerName: 'Ios Pro',
    serviceType: 'QA & CATEGORY - Service Repair',
    servicePrice: '$0.00',
    sublaborPayName: 'akash provider',
    total: '$50.00',
  },
  {
    id: 3,
    jobNumber: '27881123',
    providerName: 'Ron Test',
    serviceType: 'QA & CATEGORY - Service Repair',
    servicePrice: '$10.00',
    sublaborPayName: 'akash provider',
    total: '$500.00',
  },
  {
    id: 4,
    jobNumber: '36974681',
    providerName: 'Ios Pro',
    serviceType: 'QA & CATEGORY - Service Repair',
    servicePrice: '$20.00',
    sublaborPayName: 'akash provider',
    total: '$55.00',
  },
];
