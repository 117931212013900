import { t } from 'i18next';

export const slotBreadCrumbs = () => {
  return [
    {
      path: '/admin/dashboard',
      name: t('text.common.dashboard'),
    },
    {
      path: '#',
      name: t('text.manageSlot.title'),
    },
  ];
};

export const timeIntervalOptions = () => {
  return [
    {
      name: '15 Min',
      value: '15',
    },
    {
      name: '30 Min',
      value: '30',
    },
    {
      name: '45 Min',
      value: '45',
    },
    {
      name: '1 hr',
      value: '60',
    },
    {
      name: '2 hr',
      value: '120',
    },

    {
      name: '3 hr',
      value: '180',
    },

    {
      name: '4 hr',
      value: '240',
    },

    {
      name: '5 hr',
      value: '300',
    },
  ];
};
