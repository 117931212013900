import { APIRequest } from '../../helpers/index.helper';
import { CUSTOMERS_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { logger, setLocalStorage } from '../../utils';

export const CustomersListService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...CUSTOMERS_ENDPOINT?.getCustomersApi,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const addUserService = async ({ values }) => {
  try {
    const payload = {
      ...CUSTOMERS_ENDPOINT?.addCustomer,
      bodyData: values,
    };
    return await APIRequest(payload);
  } catch (err) {
    console.log(err);
  }
};

export const deleteUserService = async ({ bodyData }) => {
  try {
    const payload = {
      ...CUSTOMERS_ENDPOINT?.deleteCustomer,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const userStatusService = async (bodyData) => {
  try {
    const payload = {
      ...CUSTOMERS_ENDPOINT?.statusCustomer,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const viewUserService = async (id) => {
  try {
    const queryParams = { ignore: 'deleted' };
    const payload = {
      ...CUSTOMERS_ENDPOINT?.viewCustomer(id),
      queryParams,
    };
    const res = await APIRequest(payload);
    setLocalStorage({ keyName: 'isScrollCount', data: res?.data?.count });
    return res;
  } catch (err) {
    logger(err);
  }
};
export const editUserService = async ({ id, values }) => {
  try {
    const payload = {
      ...CUSTOMERS_ENDPOINT?.editCustomer(id),
      bodyData: values,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const addCustomerNotesService = async ({ bodyData }) => {
  try {
    const payload = {
      ...CUSTOMERS_ENDPOINT?.addCustomerNote,
      bodyData,
    };
    return await APIRequest(payload);
  } catch (err) {
    console.log(err);
  }
};

export const getCustomerNotesService = async ({ id, limitQuery }) => {
  try {
    const payload = {
      ...CUSTOMERS_ENDPOINT?.getCustomerNote(id),
      queryParams: limitQuery,
    };
    const res = await APIRequest(payload);
    setLocalStorage({ keyName: 'isScrollCount', data: res?.data?.count });
    return res?.data?.rows;
  } catch (err) {
    console.log(err);
  }
};

export const getMemberShipCustomerData = async (id, type) => {
  try {
    const queryParams = { customerId: id };

    const payload = {
      ...CUSTOMERS_ENDPOINT?.getCustomerMemberShip,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const purchaseMemberShipPlan = async ({ memberShipBodyData }) => {
  let bodyData = memberShipBodyData;
  try {
    const payload = {
      ...CUSTOMERS_ENDPOINT?.memberShipPurchase,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
