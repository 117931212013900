import i18next from 'i18next';
import { getFromToDateFilter } from '../../components/Filter/Common/date.filter';
import { getMultiSelectSearchFilter } from '../../components';
import { accessRoute } from '../../routes/Dashboard';
import {
  checkValidData,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { Select } from 'antd';
import {
  capitalizeFirstLetter,
  dateFormatter,
  phoneNumberFormate,
  readMoreTextShow,
  showDateInBrowser,
} from '../../utils';
import { DATE_MONTH__DATE_TH_YEAR, DATE_TH_MONTH_YEAR } from '../../config';
import { CommonButton } from '../../components';

export const voiceRecordsBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.voiceRecords.title'),
    },
  ];
};

const AdminStatus = () => {
  return [
    {
      value: 'needs_attention',
      label: 'Needs Attention',
    },
    {
      value: 'requested',
      label: 'Requested',
    },
    {
      value: 'completed',
      label: 'Completed',
    },
  ];
};

const RequestedChannel = () => {
  return [
    {
      value: 'voice',
      name: 'Voice',
    },
    {
      value: 'chatbot',
      name: 'Chatbot',
    },
  ];
};

export const voiceAIRecordsTable = ({
  handlelogView,
  options,
  handleChangeOption,
  page,
  sizePerPage,
  onFilterChange,
  filter,
  cancelUpdates,
  saveUpdates,
  adminNoteModal,
  Action
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'requestedBy',
      filterDataIndex: ['requestedBy'],
      title: i18next.t('text.voiceRecords.requestChannel'),
      sorter: false,
      render: (cell, row) => checkValidData(capitalizeFirstLetter(cell)),
      ...getMultiSelectSearchFilter({
        dataIndex: 'requestedBy',
        onFilterChange,
        name: 'request channel',
        listKeys: { nameKey: 'name', nameId: 'value' },
        mode: false,
        listService: RequestedChannel,
        filter,
      }),
    },
    {
      dataIndex: 'phoneNumber',
      title: i18next.t('text.common.phoneNumber'),
      sorter: false,
      render: (cell, row) => phoneNumberFormate(cell),
    },
    {
      dataIndex: 'fullName',
      title: i18next.t('text.common.UserName'),
      sorter: false,
      render: (cell, row) =>
        checkValidData(
          row?.User && row?.User?.fullName
            ? row?.User?.fullName
            : i18next.t('text.voiceRecords.newCustomer')
        ),
    },
    {
      dataIndex: 'zipCode',
      title: i18next.t('text.common.zipCode'),
      sorter: false,
      render: (cell, row) => checkValidData(cell),
    },
    {
      dataIndex: 'streetNumber',
      title: i18next.t('text.voiceRecords.streetNo'),
      sorter: false,
      render: (cell, row) => checkValidData(cell),
    },
    {
      dataIndex: 'streetName',
      title: i18next.t('text.voiceRecords.streetName'),
      sorter: false,
      render: (cell, row) => checkValidData(cell),
    },
    {
      dataIndex: 'suiteNumber',
      title: i18next.t('text.voiceRecords.suiteNumber'),
      sorter: false,
      render: (cell, row) => checkValidData(cell),
    },
    {
      dataIndex: 'serviceAddress',
      title: i18next.t('text.common.address'),
      sorter: false,
      render: (cell, row) => readMoreTextShow(cell, 'Address', 25),
    },
    {
      dataIndex: 'serviceType',
      title: i18next.t('text.common.serviceType'),
      sorter: false,
      render: (cell, row) => checkValidData(capitalizeFirstLetter(cell)),
    },
    {
      dataIndex: 'ServiceCategory',
      title: i18next.t('text.common.serviceCategory'),
      sorter: false,
      render: (cell, row) => checkValidData(row?.ServiceCategory?.name),
    },
    {
      dataIndex: 'serviceSubCategory',
      title: i18next.t('text.voiceRecords.serviceSubCategory'),
      sorter: false,
      render: (cell, row) => checkValidData(row?.serviceSubCategory?.name),
    },
    {
      dataIndex: 'serviceDate',
      filterDataIndex: ['serviceDateFrom', 'serviceDateTo'],
      title: i18next.t('text.voiceRecords.serviceDate'),
      sorter: false,
      render: (cell, row) =>
        cell ? dateFormatter(cell, DATE_MONTH__DATE_TH_YEAR) : '-',
      ...getFromToDateFilter({
        dataStartIndex: 'serviceDateFrom',
        dataEndIndex: 'serviceDateTo',
        placeholderStartDate: 'From date',
        placeholderEndDate: 'To date',
        dateFormat: 'YYYY-MM-DD',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'adminNote',
      title: i18next.t('text.voiceRecords.adminNote'),
      sorter: false,
      render: (cell, row) =>
        row?.status !== 'active' ? (
          <CommonButton
            className="btn btn-primary"
            onClick={() => {
              adminNoteModal(row?.id, cell);
            }}
          >
            {cell
              ? i18next.t('text.voiceRecords.editNote')
              : i18next.t('text.voiceRecords.addNote')}
          </CommonButton>
        ) : (
          <span>-</span>
        ),
    },
    {
      dataIndex: 'adminStatus',
      title: i18next.t('text.voiceRecords.adminStatus'),
      sorter: false,
      render: (cell, row) => {
        return (
          <Select
            id="adminStatus"
            size="medium"
            extraClassName="form-control"
            disabled={row?.status === 'active'}
            name="statusId"
            value={
              options?.[row?.id]?.status
                ? options[row?.id].status
                : row?.adminStatus ?? 'Needs Attention'
            }
            onSelect={(e) => handleChangeOption('status', e, row?.id)}
            variant="standard"
            placeholder="Select status"
            options={AdminStatus().map((item) => ({
              label: item.label,
              value: item.value,
            }))}
          />
        );
      },
      ...getMultiSelectSearchFilter({
        dataIndex: 'adminStatus',
        onFilterChange,
        name: 'admin status',
        listKeys: { nameKey: 'label', nameId: 'value' },
        mode: false,
        listService: AdminStatus,
        filter,
      }),
    },
    {
      dataIndex: 'createdAt',
      filterDataIndex: ['fromDate', 'toDate'],
      title: i18next.t('text.voiceRecords.requestDate'),
      sorter: false,
      render: (cell, row) => {
        if (options?.[row?.id]) {
          return (
            <>
              <CommonButton
                onClick={() => saveUpdates(row?.id)}
                className="btn btn-primary btn-sm"
              >
                {i18next.t('text.common.save')}
              </CommonButton>
              <CommonButton
                onClick={() => cancelUpdates(row?.id)}
                className="btn btn-light btn-sm ms-1"
              >
                {i18next.t('text.common.cancel')}
              </CommonButton>
            </>
          );
        } else {
          return (
            <>{cell ? showDateInBrowser(cell, DATE_TH_MONTH_YEAR) : '-'} </>
          );
        }
      },
      ...getFromToDateFilter({
        dataStartIndex: 'fromDate',
        dataEndIndex: 'toDate',
        placeholderStartDate: 'From date',
        placeholderEndDate: 'To date',
        dateFormat: 'YYYY-MM-DD',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'Action',
      title: i18next.t('text.common.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => <>
          <CommonButton
            onClick={() => handlelogView(row?.id)}
            className="btn btn-primary btn-sm"
          >
            {i18next.t('text.voiceRecords.view')}
          </CommonButton>
      </>,
    },
  ];
};
