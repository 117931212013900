import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CommonButton } from '../../../../components';

function ShortCodeModal({ onCancel, data }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="table-responsive">
        <Table bordered size="md" className="mb-0">
          <thead>
            <tr>
              <th>{t('text.emailMessage.shortCodes')}</th>
              <th>{t('text.common.description')}</th>
            </tr>
          </thead>
          <tbody>
            {data && data?.length
              ? data?.map((item, index) => (
                  <tr key={index}>
                    <td>{item?.name}</td>
                    <td>{item?.description}</td>
                  </tr>
                ))
              : null}
          </tbody>
        </Table>
      </div>
      <div className="text-end mt-3">
        <CommonButton className="btn btn-secondary" onClick={onCancel}>
          {t('text.common.close')}
        </CommonButton>
      </div>
    </>
  );
}

export default ShortCodeModal;
