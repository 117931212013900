/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react';
import {
  ImageElement,
  CommonButton,
  SweetAlert,
  GlobalLoader,
  PageContainerWrapper,
} from '../../../../components';
import {
  dateFormatter,
  decodeQueryData,
  modalNotification,
} from '../../../../utils';
import { useLocation, useNavigate } from 'react-router-dom';
import PostReplyForm from '../../../../components/Forms/PostReply';
import {
  deleteReplyService,
  getReviewDataService,
} from '../../../../services/GoogleConnect/googleGMB.service';
import { starRate } from '../../../../components/UIElements/Formatter';
import { useTranslation } from 'react-i18next';
import { accessRoute } from '../../../../routes/GoogleConnect';
import { getActions } from '../../../../services/index.service';
import { useSelector } from 'react-redux';
import { PermissionContext } from '../../../../context/permission.context';
import { useContext } from 'react';

function ReviewList() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const action = useContext(PermissionContext);
  const params = decodeQueryData(location.search);
  const { accountId, name, businessName } = params;
  const userType = useSelector((state) => state?.auth?.userData?.Role?.type);
  const [reviewData, setReviewData] = useState([]);
  const [token, setToken] = useState('');
  const [nextPageToken, setNextPageToken] = useState('');
  const [reviewDataLoading, setReviewDataLoading] = useState(false);
  const [reviewName, setReviewName] = useState('');
  const [loading, setLoading] = useState(false);

  const isUserCanEdit =
    (action && action?.arr?.includes(getActions('CREATE').value)) ||
    userType === 'admin';

  async function getReviewList() {
    setReviewDataLoading(true);
    try {
      let queryParams = {
        name,
        accountId,
        nextPageToken,
      };
      const response = await getReviewDataService({ queryParams });
      const { data, success } = response;
      if (success) {
        setReviewData(data?.reviews);
        setToken(data?.nextPageToken);
      }
    } catch (err) {
      console.log(err);
    }
    setReviewDataLoading(false);
  }
  useEffect(() => {
    getReviewList();
  }, [nextPageToken]);

  const onConfirmAlert = async () => {
    setLoading(true);
    try {
      let bodyData = {
        name: reviewName,
      };
      const response = await deleteReplyService({ bodyData });
      const { success, message } = response;
      if (success) {
        setTimeout(() => {
          modalNotification({
            type: 'success',
            message,
          });
          setLoading(false);
          getReviewList();
        }, 20000);
        return true;
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      return true;
    }
  };
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: t('text.common.dashboard'),
    },
    {
      path: '/admin/google-gmb',
      name: t('text.googleConnect.titleGMB'),
    },
    {
      path: '#',
      name: t('text.googleConnect.googleReviewList'),
    },
  ];

  function backPage() {
    navigate('/admin/google-gmb');
  }

  return (
    <div className="wide-md mx-auto reviewSec">
      <div className="nk-block-head nk-block-head-sm">
        <PageContainerWrapper
          breadcrumb={breadcrumb}
          heading={t('text.googleConnect.googleReviewList')}
          btnArray={['extraButton']}
          extraBtnText={t('text.common.back')}
          extraBtnClass="btn btn-outline-light bg-white"
          onExtraButtonHandleShow={backPage}
        >
          {reviewDataLoading || loading ? (
            <GlobalLoader />
          ) : (
            <div className="nk-block">
              <div className="card">
                <div className="card-inner">
                  <div className="reviewSec_head  text-center">
                    <ImageElement
                      source="customer-review.svg"
                      className="img-fluid mb-2"
                      alt="customer-review"
                    />
                    <h6 className="mb-1">
                      {t('text.googleConnect.showCustomerYouCare')}
                    </h6>
                    <p>{t('text.googleConnect.ourCustomersNowGet')}</p>
                  </div>
                  <div className="reviewSec_body card border">
                    {reviewData?.map((item) => (
                      <div className="reviewSec_item">
                        <div className="d-flex reviewSec_item_inner position-relative">
                          <div
                            className={
                              !item?.reviewReply
                                ? 'reviewSec_item_img reviewSec_item_img-withoutBorder'
                                : 'reviewSec_item_img '
                            }
                          >
                            <ImageElement
                              previewSource={item?.reviewer?.profilePhotoUrl}
                              alt="image"
                            />
                          </div>
                          <div>
                            <h6 className="mb-0">
                              {item?.reviewer?.displayName}
                            </h6>
                            <div className="reviewSec_item_rating">
                              {starRate(item?.starRating)}
                              <span className="date ms-1">
                                {' '}
                                {dateFormatter(
                                  item?.createTime,
                                  'ddd, MMMM, DD, YYYY'
                                )}
                              </span>
                            </div>
                            <p>{item?.comment}</p>
                          </div>
                        </div>
                        <div className="d-flex reviewSec_item_inner position-relative">
                          {item?.reviewReply ? (
                            <>
                              <div className="reviewSec_item_img">
                                <ImageElement
                                  src="/assets/images/admin/small-logo.svg"
                                  className="img-fluid rounded-circle w-100 h-100"
                                  alt="gghs"
                                />
                              </div>
                              <div className="flex-grow-1">
                                <h6 className="mb-0">{businessName}</h6>
                                <div className="reviewSec_item_rating">
                                  <span className="date">
                                    {dateFormatter(
                                      item?.reviewReply?.updateTime,
                                      'ddd, MMMM, DD, YYYY'
                                    )}
                                  </span>
                                </div>
                                <p>{item?.reviewReply?.comment}</p>
                                {isUserCanEdit && (
                                  <PostReplyForm
                                    setIsAlertVisible={setIsAlertVisible}
                                    name={item?.name}
                                    comment={item?.reviewReply?.comment}
                                    setReviewName={setReviewName}
                                    getReviewList={getReviewList}
                                  />
                                )}
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="reviewSec_item_img "></div>
                              <div className="flex-grow-1">
                                <PostReplyForm
                                  setIsAlertVisible={setIsAlertVisible}
                                  name={item?.name}
                                  reply={true}
                                  getReviewList={getReviewList}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                  <CommonButton
                    className="btn btn-primary float-end mt-3"
                    type="submit"
                    onClick={() => setNextPageToken(token)}
                  >
                    {t('text.googleConnect.next')}
                  </CommonButton>
                </div>
              </div>
            </div>
          )}
          <SweetAlert
            title={t('text.common.areYouSure')}
            text={t('text.googleConnect.onDeleteConfirm')}
            show={isAlertVisible}
            icon="warning"
            showCancelButton
            confirmButtonText={t('text.common.yes')}
            cancelButtonText={t('text.common.no')}
            setIsAlertVisible={setIsAlertVisible}
            // showLoaderOnConfirm
            // loading={loading}
            onConfirmAlert={onConfirmAlert}
          />
        </PageContainerWrapper>
      </div>
    </div>
  );
}

export default ReviewList;
