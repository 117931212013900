export const text = {
  providerListTitle: 'Book Appointment',
  providerDetailsTitle: 'Service Provider Detail',
  loadingMore: 'Loading more',
  successMessage: `Your provider has received the booking request and will get back to you shortly. You can check out the status of your request on the 'Your Bookings' menu item.`,
  userGuidelines:
    'You must be at least 18 years of age to use this Mobile Application. By using this Mobile Application and by agreeing to this Agreement you warrant and represent that you are at least 18 years of age. If you create an account in the Mobile Application, you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it. Providing false contact information of any kind may result in the termination of your account.',
  accountGuidelines:
    'You must immediately notify us of any unauthorized uses of your account or any other breaches of security. We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions.',
  continueBooking: 'CONTINUE BOOKING',
  moreInfo: 'MORE INFO',
};
