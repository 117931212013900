import loadable from '@loadable/component';
import FullPageLoader from '../../components/UIElements/Loader/fullPageLoader';

export const ListEquipmentOrder = loadable(() => import('./List'), {
  fallback: <FullPageLoader />,
});
export const ViewEquipmentOrder = loadable(() => import('./View'), {
  fallback: <FullPageLoader />,
});
export const BookingLog = loadable(() => import('./BookingView'), {
  fallback: <FullPageLoader />,
});
