import { APIRequest } from '../../helpers/index.helper';
import { ADMIN_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { logger } from '../../utils';

export const listAdminService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...ADMIN_ENDPOINT.getAdminApi,
      queryParams,
    };

    const res = await APIRequest(payload);
    if (type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const addAdminService = async ({ body }) => {
  try {
    const payload = {
      ...ADMIN_ENDPOINT.addAdmin,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const deleteAdminService = async ({ bodyData }) => {
  try {
    const payload = {
      ...ADMIN_ENDPOINT.deleteAdmin,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const getAdminByIdService = async ({ id }) => {
  try {
    const payload = {
      ...ADMIN_ENDPOINT.getAdminById(id),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const updateAdminStatus = async (bodyData) => {
  try {
    const payload = {
      ...ADMIN_ENDPOINT.updateAdminStatusId,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const editAdminService = async ({ id, body }) => {
  try {
    if (id) {
      delete body?.confirmPassword;
      delete body?.userType;
      if(!body?.password){
        delete body?.password;
      }
    }
    const payload = {
      ...ADMIN_ENDPOINT?.updateAdminById(id),
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
