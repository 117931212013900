import i18next from 'i18next';
import { accessRoute } from '../../routes/Dashboard';
import { capitalizeFirstLetter } from '../../utils';
import { getMultiSelectSearchFilter } from '../../components';
import { getServiceCategoryListsService } from '../Dashboard/index.service';
import { checkValidPrice } from '../../components/UIElements/Formatter';

export const serviceCategoryReportBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.dashboard.serviceCategory'),
    },
  ];
};

export const serviceCategoryReportTableColumn = ({
  onFilterChange,
  filter = {},
}) => {
  return [
    {
      dataIndex: 'serviceCategory',
      filterDataIndex: ['serviceName'],
      title: i18next.t('text.manageServices.heading'),
      headerClasses: 'sorting',
      render: (_, row) =>
        row?.ServiceCategory?.name
          ? capitalizeFirstLetter(row?.ServiceCategory?.name)
          : '-',
      ...getMultiSelectSearchFilter({
        dataIndex: 'serviceName',
        onFilterChange,
        name: 'service name',
        list: [],
        mode: 'single',
        innerKey: 'ServiceCategory',
        listKeys: { nameKey: 'name', nameId: 'name' },
        listService: getServiceCategoryListsService,
        filter,
        searchOrNot: true,
      }),
    },
    // {
    //   dataIndex: 'locationName',
    //   title: i18next.t('text.quote.locationName'),
    //   headerClasses: 'sorting',
    //   sorter: true,
    //   render: (_, row) =>
    //     row?.locationName ? capitalizeFirstLetter(row?.locationName) : '-',
    // },
    {
      dataIndex: 'bookingCount',
      title: i18next.t('text.dashboard.quantity'),
      headerClasses: 'sorting',
      sorter: true,
      render: (_, row) => (row?.bookingCount ? row?.bookingCount : '-'),
    },
    // {
    //   dataIndex: 'bookingTotal',
    //   title: i18next.t('text.dashboard.totalSales'),
    //   headerClasses: 'sorting',
    //   sorter: true,
    //   render: (_, row) => (row?.bookingCount ? row?.bookingCount : '-'),
    // },
    {
      dataIndex: 'bookingTotal',
      title: i18next.t('text.dashboard.avgTicket'),
      headerClasses: 'sorting',
      sorter: true,
      render: (_, row) => checkValidPrice(row?.bookingTotal),
    },
  ];
};
