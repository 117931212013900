import i18next from 'i18next';
import { Link, generatePath } from 'react-router-dom';
import {
  ButtonTextFormatter,
  checkValidData,
  checkValidPrice,
  serialNumberFormatter,
  textFormatter,
  ButtonFormatter,
  // showTagsFormatter,
} from '../../components/UIElements/Formatter';
import { accessRoute } from '../../routes/Dashboard';
import { accessRoute as teamSettingRoute } from '../../routes/TeamSetting';
import { accessRoute as serviceManagementRoutes } from '../../routes/ServiceManagement';
import { accessRoute as reportRoute } from '../../routes/Reports';
import { accessRoute as customerRoute } from '../../routes/Customers';
import {
  // AmountFilter,
  CommonButton,
  getInputSearchFilter,
  getMultiSelectSearchFilter,
  getPriceFilter,
  getColumnSelectSearchProps,
} from '../../components';
import { getFullName } from '../../utils/text.util';
// import { FilterFilled } from '@ant-design/icons';
import {
  dateFormatter,
  minuteIntoHHMMSS,
  removeDuplicates,
  showDateInBrowser,
  // startTimer,
  viewMoreTagShowArray,
} from '../../utils';
import { providerListService } from '../TeamSetting/provider.service';
import { CustomersService } from '../Customers';
import { getTagService, memberData } from '../index.service';
import { ListSubServiceCategoryService } from '../../services/ManageServices/serviceSubcategory.service';
import { accessRoute as commonAccessRoutes } from '../../routes/CommonRoutes';
import { firstLetterCaps } from '../../utils/text.util';
import { DATE_TH_MONTH_YEAR } from '../../config';
import { getFromToDateFilter } from '../../components/Filter/Common/date.filter';

export const serviceManagementBreadcrumb = () => {
  return [
    {
      path: '/admin/dashboard',
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.serviceManagement.title'),
    },
  ];
};

export const servicemanagementInvoiceServiceBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: serviceManagementRoutes.LIST_SERVICE.path,
      name: i18next.t('text.serviceManagement.title'),
    },
    {
      path: '#',
      name: i18next.t('text.report.invoiceHeading'),
    },
  ];
};
export const servicemanagementViewQuoteBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: serviceManagementRoutes.LIST_SERVICE.path,
      name: i18next.t('text.serviceManagement.title'),
    },
    {
      path: '#',
      name: i18next.t('text.serviceManagement.viewQuote'),
    },
  ];
};
export const servicemanagementBookingLogBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: serviceManagementRoutes.LIST_SERVICE.path,
      name: i18next.t('text.serviceManagement.title'),
    },
    {
      path: '#',
      name: i18next.t('text.serviceManagement.bookingLog'),
    },
  ];
};
export const generatePaths = (url, id) => {
  if (id) {
    let path = generatePath(url, {
      id: id,
    });
    return path;
  }
};

const totalCostFields = {
  fromOperatorField: 'firstOperator',
  fromOperatorAmountField: 'firstAmount',
  conditionalField: 'conditional',
  toOperatorField: 'secondOperator',
  toOperatorAmountField: 'secondAmount',
};
const getJobStatusData = () => {
  return [
    {
      name: 'Completed',
      id: 'completed',
    },
    {
      name: 'Expired',
      id: 'expired',
    },
    {
      name: 'Accepted',
      id: 'accepted',
    },
    {
      name: 'On Going',
      id: 'ongoing',
    },
    {
      name: 'NAC',
      id: 'nac',
    },
    {
      name: 'Pending',
      id: 'pending',
    },
    {
      name: 'Canceled',
      id: 'cancelled',
    },
    {
      name: 'Declined',
      id: 'declined',
    },
    {
      name: 'Will Call List',
      id: 'willCallList',
    },
  ];
};
// export const serviceManagementColumns = ({ onSort, InvoicePage }) => {

export const bookingLogColumns = ({
  page,
  sizePerPage,
  handleOpenRescheduleNoteModal,
  isNCAUser,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'createdAt',
      title: i18next.t('text.common.date'),
      render: (cell) =>
        checkValidData(`${dateFormatter(cell, 'MM/DD/YYYY')} at ${dateFormatter(
          cell,
          'hh:mm A'
        )}
      `),
    },
    {
      dataIndex: 'logMessage',
      title: i18next.t('text.common.log'),
      render: (_, row) => {
        const message = row?.message && JSON.parse(row?.message);

        return (
          <>
            {message ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: isNCAUser
                    ? message?.nca_message
                    : message?.admin_message,
                }}
              ></span>
            ) : row?.logMessage ? (
              row?.viewNote === 1 ? (
                row?.logMessage.slice(0, -14)
              ) : row?.rescheduleNote ? (
                row?.logMessage.slice(0, -11)
              ) : (
                row?.logMessage
              )
            ) : (
              '-'
            )}
            &nbsp;
            {row?.rescheduleNote && (
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleOpenRescheduleNoteModal(row?.rescheduleNote);
                }}
                className="text-decoration-underline"
              >
                (View Note)
              </Link>
            )}
            {row?.viewNote === 1 && (
              <a
                href={generatePath(commonAccessRoutes.INVOICE.path, {
                  viewInvoice: 'invoice',
                  routePath: 'payment-report',
                  id: row?.bookingId,
                })}
                target="_blank"
                className="text-decoration-underline"
                rel="noreferrer"
              >
                ({i18next.t('text.report.viewInvoice')})
              </a>
            )}
          </>
        );
      },
    },
  ];
};

export const serviceManagementColumns = ({
  onSort,
  InvoicePage,
  cancelPop,
  page,
  sizePerPage,
  onFilterChange,
  filter,
  isUserCanEdit,
  handleSendQuote,
  sendQuoteBookingId,
  id,
  filterObj,
  userType,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'bookingNo',
      headerClasses: 'sorting',
      title: i18next.t('text.serviceManagement.booking'),
      sorter: true,
      onSort: (field, order) => onSort('bookingNo', order),
      render: (cell, row) =>
        ButtonTextFormatter(
          generatePath(serviceManagementRoutes.BOOKING_VIEW.path, {
            id: row?.id,
            viewQuote:
              row?.BookingQuote?.submitQuote === 'submitted'
                ? 'isViewQuote'
                : 'isNoViewQuote',
          }),
          cell,
          false,
          {},
          '_blank'
        ),
      ...getInputSearchFilter({
        dataIndex: 'bookingNo',
        placeholder: 'booking number',
        onFilterChange,
        filter,
        defaultValue: id ? id : '',
      }),
    },
    {
      dataIndex: 'Address.addressLine1',
      exportName: 'Location',
      title: i18next.t('text.serviceManagement.location'),
      headerClasses: 'sorting',
      sorter: true,
      sortType: 'location',
      exportFormatter: (cell, row) =>
        checkValidData(row?.Address?.addressLine1),
      render: (cell, row) => checkValidData(row?.Address?.addressLine1),
      onSort: (field, order) => onSort('location', order),
    },
    {
      dataIndex: 'bookingScheduleDate',
      title: i18next.t('text.serviceManagement.jobDate'),
      headerClasses: 'sorting',
      sortType: 'jobDate',
      sorter: true,
      exportFormatter: (cell, row) => showDateInBrowser(cell),
      onSort: (field, order) => onSort('createdAt', order),
      render: (cell, row) => showDateInBrowser(cell),
    },
    {
      dataIndex: 'Provider.firstName',
      filterDataIndex: ['providerUserId'],
      exportName: 'Provider',
      title: i18next.t('text.common.provider'),
      headerClasses: 'sorting',
      sorter: true,
      sortType: 'providerName',
      onSort: (field, order) => onSort('provider', order),
      exportFormatter: (cell, row) =>
        row?.Provider?.firstName ? (
          checkValidData(
            `${row?.Provider?.firstName} ${row?.Provider?.lastName}`
          )
        ) : (
          <>-</>
        ),
      render: (cell, row) => {
        return !row?.isNacJob ? (
          row?.Provider?.firstName ? (
            ButtonTextFormatter(
              generatePaths(
                teamSettingRoute.EDIT_PROVIDER.path,
                row.providerUserId
              ),
              `${row?.Provider?.firstName} ${row?.Provider?.lastName}`
            )
          ) : (
            <>-</>
          )
        ) : (
          <>-</>
        );
      },
      ...getMultiSelectSearchFilter({
        dataIndex: 'providerUserId',
        onFilterChange,
        filter,
        name: 'provider',
        list: [],
        mode: 'multiple',
        // scope: 'activeUser',
        searchOrNot: true,
        listKeys: { nameKey: ['firstName', 'lastName'], nameId: 'id' },
        listService: providerListService,
      }),
    },
    {
      dataIndex: 'Customer.firstName',
      filterDataIndex: ['customerUserId'],
      exportName: 'Customer',
      title: i18next.t('text.common.customer'),
      headerClasses: 'sorting',
      sorter: true,
      sortType: 'userName',
      onSort: (field, order) => onSort('user', order),
      exportFormatter: (cell, row) =>
        row?.Customer?.firstName ? (
          getFullName(
            row?.Customer?.firstName,
            row?.Customer?.lastName,
            '',
            row?.Customer?.CustomerMembership?.MembershipPlan?.name
          )
        ) : (
          <>-</>
        ),

      render: (cell, row) =>
        ButtonTextFormatter(
          generatePath(customerRoute.CUSTOMERS_EDIT.path, {
            id: row?.customerUserId,
            isBookNow: userType === 'admin' ? 'isBookNow' : 'isNoBookNow',
          }),

          getFullName(
            row?.Customer?.firstName,
            row?.Customer?.lastName,
            '',
            row?.Customer?.CustomerMembership?.MembershipPlan?.name
          ),
          false,
          {},
          '_blank'
        ),
      // render: (cell, row) =>
      //   ButtonTextFormatter(
      //     generatePath(
      //       `${reportRoute.PAYMENT_REPORT.path}?customerUserId=${row?.customerUserId}&customerName=${row?.Customer?.firstName} ${row?.Customer?.lastName}`
      //     ),
      //     getFullName(row?.Customer?.firstName, row?.Customer?.lastName),
      //     false,
      //     {},
      //     '_blank'
      //   ),
      ...getMultiSelectSearchFilter({
        dataIndex: 'customerUserId',
        onFilterChange,
        filter,
        name: 'customer',
        list: [],
        mode: 'multiple',
        // scope: 'all',
        searchOrNot: true,
        listKeys: { nameKey: ['firstName', 'lastName'], nameId: 'id' },
        listService: CustomersService?.CustomersListService,
        addExtraObj: filterObj,
      }),
    },
    // {
    //   dataIndex: 'customerPaid',
    //   headerClasses: 'sorting',
    //   title: i18next.t('text.serviceManagement.customerPaid'),
    //   sorter: true,
    //   // onSort: (field, order) => onSort('customerPaid', order),
    //   render: (cell, row) => checkValidPrice(0),
    //   ...getInputSearchFilter({
    //     dataIndex: 'customerPaid',
    //     placeholder: 'customer paid',
    //     onFilterChange,
    //     filter,
    //   }),
    // },
    {
      dataIndex: 'customerPaid',
      title: i18next.t('text.common.customerPaid'),
      headerClasses: 'sorting',
      sorter: false,
      exportFormatter: (cell, row) => checkValidPrice(row?.customerPaid),
      render: (cell, row) => checkValidPrice(row?.customerPaid),
      // ...getColumnSelectSearchProps({
      //   dataIndex: 'customerPaid',
      //   name: 'Customer Paid',
      //   onFilterChange,
      //   filter,
      //   mode: false,
      //   list: memberData(),
      // }),
    },
    {
      dataIndex: 'memberShipPlan',
      // filterDataIndex: ['customerPaid'],
      title: i18next.t('text.common.memberPlan'),
      headerClasses: 'sorting',
      // sorter: true,
      exportName: i18next.t('text.common.memberPlan'),
      // export: true,
      exportFormatter: (cell, row) =>
        checkValidData(row?.CustomerMembership?.MembershipPlan?.name),
      render: (cell, row) =>
        checkValidData(row?.CustomerMembership?.MembershipPlan?.name),
      // ...getPriceFilter({
      //   dataIndex: 'totalFees',
      //   onFilterChange,
      //   fields: totalFeesFields,
      //   filter,
      //   dataIndexKey: 'filterColumn',
      // }),
      // ...getColumnSelectAmountProps(),
      width: 150,
    },
    // userType !=='nca &&  {
    //   dataIndex: 'membershipStatus',
    //   title: i18next.t('text.quote.memberShipStatus'),
    //   headerClasses: 'sorting',
    //   sorter: true,
    //   render: (cell, row) => (!cell ? 'Non-Member' : checkValidData(cell)),
    //   ...getColumnSelectSearchProps({
    //     dataIndex: 'membershipStatus',
    //     name: 'Member ship status',
    //     onFilterChange,
    //     filter,
    //     mode: false,
    //     list: memberData(),
    //   }),
    // },
    // userType !=='nca && {
    //   dataIndex: 'memberRenewalDate',
    //   filterDataIndex: ['fromDate', 'toDate'],
    //   title: i18next.t('text.quote.memberRenewalDate'),
    //   headerClasses: 'sorting',
    //   sorter: true,
    //   exportFormatter: (cell, row) =>
    //     showDateInBrowser(
    //       row?.Customer?.CustomerMembership?.endDateTime,
    //       DATE_TH_MONTH_YEAR
    //     ),
    //   render: (cell, row) =>
    //     row?.Customer?.CustomerMembership?.endDateTime
    //       ? showDateInBrowser(
    //           row?.Customer?.CustomerMembership?.endDateTime,
    //           DATE_TH_MONTH_YEAR
    //         )
    //       : '-',
    //   ...getFromToDateFilter({
    //     dataStartIndex: 'fromDate',
    //     dataEndIndex: 'toDate',
    //     placeholderStartDate: 'From date',
    //     placeholderEndDate: 'To date',
    //     dateFormat: 'YYYY/MM/DD',
    //     onFilterChange,
    //     filter,
    //   }),
    // },
    {
      dataIndex: 'total',
      filterDataIndex: [
        'conditional',
        'firstAmount',
        'firstOperator',
        'secondAmount',
        'secondOperator',
      ],
      title: i18next.t('text.serviceManagement.jobAmount'),
      headerClasses: 'sorting',
      sorter: true,
      exportFormatter: (cell, row) => checkValidPrice(cell),
      render: (cell, row) => checkValidPrice(cell),
      onSort: (field, order) => onSort('jobAmount', order),
      ...getPriceFilter({
        dataIndex: 'price',
        onFilterChange,
        fields: totalCostFields,
        filter,
      }),
      // ...getColumnSelectAmountProps(),
    },
    {
      dataIndex: 'CustomerTags',
      filterDataIndex: ['customerTags'],
      title: i18next.t('text.serviceManagement.customerTag'),
      // headerClasses: 'sorting',
      // sorter: true,
      export: true,
      exportName: 'Customer Tag',
      exportKey: 'BookingTags',
      // render: (cell, row) => checkValidData(cell),
      exportFormatter: (cell, row) => {
        let data;
        if (Array.isArray(row?.BookingTags)) {
          data =
            removeDuplicates(row?.BookingTags, 'customer')?.length > 0
              ? removeDuplicates(row?.BookingTags, 'customer').map((e) => {
                  return e?.tag;
                })
              : '-';
        }
        if (Array.isArray(data)) {
          return data?.join();
        } else {
          return '-';
        }
      },
      // exportFormatter: (cell, row) => {
      //   if (Array.isArray(row?.BookingTags)) {
      //     return removeDuplicates(row?.BookingTags, 'customer')?.length > 0
      //       ? removeDuplicates(row?.BookingTags, 'customer').map((e) => {
      //           return e?.tag;
      //         })
      //       : '-'.join(',');
      //   }
      //   return '-';
      // },
      render: (cell, row) => {
        if (Array.isArray(row?.BookingTags)) {
          return viewMoreTagShowArray(
            row?.BookingTags?.length > 0
              ? removeDuplicates(row?.BookingTags, 'customer')?.length > 0
                ? removeDuplicates(row?.BookingTags, 'customer').map((e, i) => {
                    return (
                      <>
                        <span className="badge badge-xs bg-gray me-1 mb-1">
                          {e?.tag}
                        </span>
                        <br />
                      </>
                    );
                  })
                : '-'
              : '-',
            'Customer Tags',
            3,
            'tags-layout'
          );
        }
        return <>-</>;
      },

      // render: (cell, row) =>
      //   row?.BookingTags?.length > 0 ? (
      //     viewMoreTagShow(
      //       // showTagsFormatter(row?.BookingTags, 'customer'),
      //       row?.BookingTags,
      //       i18next.t('text.serviceManagement.customerTag'),
      //       4
      //     )
      //   ) : (
      //     <>-</>
      //   ),
      // onSort: (field, order) => onSort('customerTag', order),
      ...getMultiSelectSearchFilter({
        dataIndex: 'customerTags',
        onFilterChange,
        filter,
        name: 'customer tag',
        list: [],
        mode: 'multiple',
        scope: 'activeTag',
        extraQuary: { tagType: 'customer' },
        listService: getTagService,
      }),
    },
    {
      dataIndex: 'jobTags',
      title: i18next.t('text.serviceManagement.jobTag'),
      exportName: 'Job Tag',
      exportKey: 'BookingTags',

      exportFormatter: (cell, row) => {
        let dataTwo;
        if (Array.isArray(row?.BookingTags)) {
          dataTwo =
            removeDuplicates(row?.BookingTags, 'job')?.length > 0
              ? removeDuplicates(row?.BookingTags, 'job').map((e) => {
                  return e?.tag;
                })
              : '-';
        }
        if (Array.isArray(dataTwo)) {
          return dataTwo?.join();
        } else {
          return '-';
        }
      },
      // exportFormatter: (cell, row) => {
      //   if (Array.isArray(row?.BookingTags)) {
      //     return removeDuplicates(row?.BookingTags, 'job')?.length > 0
      //       ? removeDuplicates(row?.BookingTags, 'job').map((e) => {
      //           return e?.tag;
      //         })
      //       : '-'.join(',');
      //   }
      //   return '-';
      // },
      render: (cell, row) => {
        if (Array.isArray(row?.BookingTags)) {
          return viewMoreTagShowArray(
            row?.BookingTags?.length > 0
              ? removeDuplicates(row?.BookingTags, 'job')?.length > 0
                ? removeDuplicates(row?.BookingTags, 'job').map((e, i) => {
                    return (
                      <>
                        <span className="badge badge-xs bg-gray me-1 mb-1">
                          {e?.tag}
                        </span>
                        <br />
                      </>
                    );
                  })
                : '-'
              : '-',
            'Job Tags',
            3,
            'tags-layout'
          );
        }
        return <>-</>;
      },
      // render: (cell, row) =>
      //   row?.BookingTags?.length > 0 ? (
      //     showTagsFormatter(row?.BookingTags, 'job')
      //   ) : (
      //     <>-</>
      //   ),

      // onSort: (field, order) => onSort('jobTag', order),
      ...getMultiSelectSearchFilter({
        dataIndex: 'jobTags',
        onFilterChange,
        filter,
        name: 'job tag',
        list: [],
        mode: 'multiple',
        scope: 'activeTag',
        extraQuary: { tagType: 'job' },
        listService: getTagService,
      }),
    },
    {
      dataIndex: 'ServiceCategory.name',
      filterDataIndex: ['serviceType'],
      exportName: 'Type',
      sortType: 'serviceType',
      title: i18next.t('text.serviceManagement.type'),
      headerClasses: 'sorting',
      sorter: true,
      exportFormatter: (cell, row) =>
        checkValidData(
          `${row?.ServiceCategory?.name}(${row?.ServiceCategory?.servicePrice} - ${row?.ServiceCategory?.serviceType})`
        ),
      render: (cell, row) =>
        row?.ServiceCategory?.name ? (
          <div className="text-capitalize">
            {checkValidData(
              `${row?.ServiceCategory?.name}(${firstLetterCaps(
                row?.ServiceCategory?.servicePrice
              )} - ${firstLetterCaps(
                row?.ServiceCategory?.serviceType?.replace(/_/g, ' ')
              )})`
            )}
          </div>
        ) : (
          <>-</>
        ),
      onSort: (field, order) => onSort('type', order),
      ...getMultiSelectSearchFilter({
        dataIndex: 'serviceType',
        onFilterChange,
        filter,
        name: 'service type',
        list: [],
        mode: false,
        scope: 'activeServiceCategory',
        listService: ListSubServiceCategoryService,
      }),
    },

    {
      dataIndex: 'quoteDetails',
      title: i18next.t('text.serviceManagement.quote'),
      // headerClasses: 'sorting',
      // sorter: true,
      // onSort: (field, order) => onSort('quoteDetail', order),
      render: (cell, row) => {
        if (row?.BookingQuote?.submitQuote === 'submitted') {
          // return viewButtonFormatter(options(cell, row));

          let path = row?.BookingQuote?.isSalesQuotes
            ? generatePath(commonAccessRoutes.INVOICE.path, {
                viewInvoice: 'quote',
                routePath: 'service-management',
                id: row?.id,
              })
            : generatePath(
                serviceManagementRoutes.SERVICE_MANAGEMENT_QUOTE.path,
                { id: row?.id }
              );
          return ButtonFormatter(path, 'View Quote', '_blank');
        } else {
          return <>-</>;
        }
      },
    },
    {
      dataIndex: 'bookingStatus',
      filterDataIndex: ['status'],
      title: i18next.t('text.serviceManagement.jobStatus'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('jobStatus', order),
      render: (cell, row) => {
        if (
          row?.NcaBookingRequests &&
          row?.NcaBookingRequests?.length &&
          row?.NcaBookingRequests[0]?.isWillCallList &&
          cell === 'pending'
        ) {
          return <>Will Call List</>;
        } else if (cell === 'pending' && row?.isNacJob) {
          return <>NAC</>;
        } else if (cell === 'completed') {
          return ButtonFormatter(
            generatePath(commonAccessRoutes.INVOICE.path, {
              viewInvoice: 'invoice',
              routePath: 'service-management',
              id: row?.id,
            }),
            'View Invoice',
            '_blank'
          );
        } else if (row?.cancelReason && cell === 'declined') {
          return (
            <>
              <CommonButton
                onClick={(e) => cancelPop(row, e)}
                className="btn btn-light btn-sm btn btn-none"
              >
                {i18next.t('text.scheduleBooking.declineReason')}
              </CommonButton>
            </>
          );
        } else if (
          (row?.cancelReason || row?.BookingDetail?.cancelReason) &&
          cell === 'canceled'
        ) {
          return (
            <>
              <CommonButton
                onClick={(e) =>
                  cancelPop(row, e, row?.BookingDetail?.cancelReason)
                }
                className="btn btn-light btn-sm btn btn-none"
              >
                {i18next.t('text.scheduleBooking.cancelReason')}
              </CommonButton>
            </>
          );
        } else {
          return <>{checkValidData(textFormatter(cell))}</>;
        }
      },
      ...getMultiSelectSearchFilter({
        dataIndex: 'status',
        onFilterChange,
        filter,
        name: 'status',
        list: [],
        mode: 'multiple',
        listService: getJobStatusData,
      }),
    },
    {
      dataIndex: 'sendQuote',
      title: i18next.t('text.serviceManagement.sendQuote'),
      // headerClasses: 'sorting',
      // sorter: true,
      // onSort: (field, order) => onSort('sendQuote', order),

      render: (cell, row) => {
        if (
          row?.BookingQuote?.submitQuote === 'submitted' &&
          row?.BookingQuote?.customerStatus === 'pending' &&
          row?.BookingQuote?.providerStatus === 'pending'
        ) {
          return (
            <>
              <CommonButton
                disabled={!isUserCanEdit || sendQuoteBookingId}
                loading={row?.id === sendQuoteBookingId}
                onClick={() => handleSendQuote(row?.id)}
                className="btn btn-primary btn-sm"
              >
                {i18next.t('text.serviceManagement.sendQuote')}{' '}
                {`${checkValidPrice(row?.BookingQuote?.quoteAmount)}`}
              </CommonButton>
            </>
          );
        } else {
          return <>-</>;
        }
      },
    },
    {
      dataIndex: 'BookingDetail',
      exportName: 'Job Completion Time',
      title: i18next.t('text.serviceManagement.jobComplaint'),
      // headerClasses: 'sorting',
      // sorter: true,
      // onSort: (field, order) => onSort('completionTime', order),
      exportFormatter: (_, row) => {
        if (
          row?.BookingDetail?.serviceDuration &&
          parseFloat(row?.BookingDetail?.serviceDuration)
        ) {
          return minuteIntoHHMMSS(row?.BookingDetail?.serviceDuration);
        } else {
          return '';
        }
      },
      render: (_, row) => {
        if (
          row?.BookingDetail?.serviceDuration &&
          parseFloat(row?.BookingDetail?.serviceDuration)
        ) {
          return minuteIntoHHMMSS(row?.BookingDetail?.serviceDuration);
        } else {
          return '-';
        }
      },
    },
  ];
};
export const serviceManagementData = () => {
  return [
    {
      id: 1,
      bookingNo: '	14940636',
      location: 'Indore',
      jobDate: '10/10/2022',
      provider: 'Jinu',
      user: 'Shane Watson',
      jobAmount: '90',
      customerTag: 'Allowing',
      jobTag: 'job tag',
      type: 'Same day',
      quoteDetail: 'View Quote',
      jobStatus: 'NAC',
      sendQuote: '12.21$',
      completionTime: '10hr',
    },
    {
      id: 2,
      bookingNo: '	86941331',
      location: 'Bhopal',
      jobDate: '08/11/2022',
      provider: 'sdftgdfg',
      user: 'Shane Watson',
      jobAmount: '99.95	',
      customerTag: '',
      jobTag: '',
      type: 'Same Day',
      quoteDetail: '',
      jobStatus: 'NAC',
      sendQuote: '12$',
      completionTime: '',
    },
    {
      id: 3,
      bookingNo: '	95085075',
      location: 'Indore',
      jobDate: '11/11/2022',
      provider: 'Rupesh B',
      user: 'Shane Watson',
      jobAmount: '99.95',
      customerTag: '',
      jobTag: '',
      type: 'Same day',
      quoteDetail: '',
      jobStatus: 'Invoice',
      sendQuote: '12$',
      completionTime: '',
    },
    {
      id: 3,
      bookingNo: '	95085075',
      location: 'Indore',
      jobDate: '11/11/2022',
      provider: 'Shubhan Cna',
      user: 'Shane Watson',
      jobAmount: '99.95',
      customerTag: '',
      jobTag: '',
      type: 'Same day',
      quoteDetail: '',
      jobStatus: 'Expired',
      sendQuote: '',
      completionTime: '',
    },
    {
      id: 3,
      bookingNo: '	95085075',
      location: 'Indore',
      jobDate: '11/11/2022',
      provider: 'Raman CLL',
      user: 'Shane Watson',
      jobAmount: '99.95',
      customerTag: '',
      jobTag: '',
      type: 'Same day',
      quoteDetail: '',
      jobStatus: 'cancel',
      sendQuote: '',
      completionTime: '',
    },
  ];
};
