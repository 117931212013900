import React, { useContext, useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { ChatContext } from '../../../context/chat.context';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Spin } from 'antd';
import Dropdown from 'react-bootstrap/Dropdown';
import ImageElement from '../../../components/UIElements/ImageElement';
import { ModalComponent } from '../../../components';
import { statusFormatter } from '../../../components/Common/StatusFormatter';

import { capitalizeFirstLetter } from '../../../utils/common.util';
import { getChatRoomListService } from '../../../services/Chat/index.service';
import { acceptChatMediaFiles, logger } from '../../../utils';

import ChatServiceProvider from './ChatServiceProviderModal';
import CreateGroupModal from './CreateGroupModal';
import ChatSearch from './ChatSearch';
import { useSelector } from 'react-redux';

const PER_PAGE_USERS = 10;

const emojiIconObj = {
  Positive: '/assets/images/admin/positive.png',
  Help: '/assets/images/admin/help.png',
  Struggle: '/assets/images/admin/struggle.png',
  Medical: '/assets/images/admin/medical.png',
  Vacation: '/assets/images/admin/vacation.png',
};

const ChatSidebar = (props) => {
  const { newMessages, socket } = props;
  const { t } = useTranslation();
  const {
    selectUser,
    isMobileViewOpen,
    handleOpenChatWithSPModal,
    setSelectUser,
    chatWithSPModal,
    handleOpenGroupModal,
    isOpenCreateGroupModal,
    isUploadItems,
    uploadItemsHandleClick,
    setHasMoreFetch,
    setCurrentMessagePage,
    showChatHandle,
    userDetails,
    getProfileImageUrl,
    isMsgSendUserIds,
    chatRoomUserLists,
    setChatRoomUserLists,
    chatUserList,
    setChatUserList,
    chatroomDetails,
    isLoadUpdate,
    setIsLoadUpdate,
    isLoadingChat, 
    setIsLoadingChat,
  } = useContext(ChatContext);
  const [searchName, setSearchName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [currentSidebarPage, setCurrentSideBarPage] = useState(1);
  const [count, setCount] = useState(0);
  const [usersOnline, setUsersOnline] = useState([]);
  const [isFirstTimeLoad, setIsFirstTimeLoad] = useState(true);
  const [usersLength, setUsersLength] = useState(0);
  const [isStopMoreLoading, setIsStopMoreLoading] = useState(false);
  const mentalHeathPermission = useSelector(
    (state) => state?.permission?.isAllowMentalHealthModule
  );
  const userId = useSelector((state) => state?.auth?.userData?.id);

  const getChatRoomUserLists = async (newPage, isFetch) => {
    !newPage && setIsLoading(true);
    newPage && setIsStopMoreLoading(true);
    try {
      let queryParams = {
        name: searchName,
        offset:
          ((newPage ? newPage : isFetch ? 1 : currentSidebarPage) - 1) *
          PER_PAGE_USERS,
        limit: PER_PAGE_USERS,
      };
      const { success, data } = await getChatRoomListService({
        queryParams,
      });
      if (success) {
        setIsLoadingChat(false)
        setCount(data?.count);
        let roomData = [];
        data?.list?.forEach((d1) => {
          d1?.chatRoom?.ChatRoomMembers?.forEach((chatMem) => {
            roomData.push({
              roomId: d1?.chatRoom?.id,
              userId: chatMem?.memberUserId,
            });
          });
        });
        socket.emit('join_chat_room', roomData);

        const chatRoomUsers = [
          ...(!isFetch ? chatRoomUserLists : []),
          ...data?.list,
        ];
        setUsersLength(chatRoomUsers.length);
        setChatRoomUserLists(
          chatRoomUsers?.sort(
            (a, b) =>
              new Date(a?.chatRoom?.lastCreatedAt ?? a?.chatRoom?.createdAt) -
              new Date(a?.chatRoom?.lastCreatedAt ?? b?.chatRoom?.createdAt)
          )
        );
        handleEmitUserOnline(chatRoomUsers);
      }
      if (newPage) {
        setCurrentSideBarPage(newPage);
        setIsStopMoreLoading(false);
      }
      if (isFetch) {
        setCurrentSideBarPage(1);
      }
    } catch (err) {
      logger(err);
    }
    setIsFirstTimeLoad(false);
    setIsLoading(false);
  };

  const handleSelectUser = (roomUser) => {
    setCurrentMessagePage(0);
    setHasMoreFetch(false);
    setSelectUser(roomUser);
    setChatRoomUserLists(
      chatRoomUserLists?.map((chatRoomItem) =>
        chatRoomItem?.chatRoom?.id === roomUser?.chatRoom?.id
          ? { ...chatRoomItem, unreadCount: 0 }
          : chatRoomItem
      )
    );
  };


  const handleReceiveMessage = () => {
    if (chatRoomUserLists.length > 0) {
      setChatRoomUserLists((prevChatRoomUserLists) => {
        let chatRoomList = prevChatRoomUserLists?.map((chatRoomItem) =>
          chatRoomItem?.chatRoom?.id === newMessages?.roomId
            ? {
                ...chatRoomItem,
                chatRoom: {
                  ...chatRoomItem?.chatRoom,
                  lastCreatedAt: new Date().toISOString(),
                },
                last_message:
                  newMessages?.type === 'media'
                    ? 'Media'
                    : newMessages?.message,
                unreadCount:
                  selectUser && selectUser?.chatRoom?.id === newMessages?.roomId
                    ? 0
                    : chatRoomItem?.unreadCount + 1,
              }
            : chatRoomItem
        );
        if (
          chatRoomList?.[0]?.chatRoom?.id !== newMessages?.roomId &&
          chatRoomList?.find((d) => d?.chatRoom?.id === newMessages?.roomId)
        ) {
          chatRoomList = chatRoomList?.sort(
            (a, b) =>
              (b?.chatRoom?.id === newMessages?.roomId) -
              (a?.chatRoom?.id === newMessages?.roomId)
          );
        }
        return chatRoomList;
      });
    }
  };

  const handleEmitUserOnline = (roomUsers) => {
    roomUsers?.forEach((roomUser) => {
      if (roomUser?.chatRoom?.chatType === 'single') {
        socket.emit('check-user-online', { id: roomUser?.chatRoom?.User?.id });
      }
    });
  };

  const handleOnUserOnline = (user) => {
    setUsersOnline((prev) => [...prev, user]);
  };

  const handleLoadMoreData = () => {
    getChatRoomUserLists(currentSidebarPage + 1);
  };

  const handleChangeSearchName = useCallback((e) => {
    setSearchName(e.target.value);
  }, []);

  const getUserOnlineStatus = (userId) => {
    let user = usersOnline?.find((onlineUser) => onlineUser?.id === userId);
    return user?.status?.toLowerCase();
  };

  useEffect(() => {
    if (chatUserList) {
      getChatRoomUserLists(null, true);
    } else {
      getChatRoomUserLists();
    }
  }, [chatUserList]);
  
  useEffect(() => {
      getChatRoomUserLists(null, true);
      setIsLoadUpdate(false)
    getChatRoomUserLists(null, true);
  }, [isLoadingChat, chatUserList, isLoadUpdate]);
  
  useEffect(() => {
    handleReceiveMessage();
  }, [newMessages]);

  useEffect(() => {
    socket.on('user-online', handleOnUserOnline);
  }, [socket]);
  return (
    <>
      <div
        className={`nk-chat-aside position-relative ${
          isMobileViewOpen ? 'hide-aside' : ''
        }`}
      >
        <div className="nk-chat-aside-head">
          <div className="nk-chat-aside-search px-0 w-100 mb-1">
            <ChatSearch
              search={searchName}
              handleSearch={handleChangeSearchName}
              getSearchList={() => getChatRoomUserLists(null, true)}
              setEmptyList={setChatRoomUserLists}
            />
          </div>
          <div className="nk-chat-aside-panel nk-chat-fav mt-auto position-absolute bottom-0 end-0">
            <ul className="d-flex justify-content-end">
              <li>
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="dropup btn btn-lg btn-icon btn-primary btn-round"
                  >
                    <em className="icon ni ni-plus"></em>
                  </Dropdown.Toggle>

                  <Dropdown.Menu drop="up">
                    <Dropdown.Item href="#" onClick={handleOpenChatWithSPModal}>
                      {t('text.chat.chatWithSP')}
                    </Dropdown.Item>
                    <Dropdown.Item href="#" onClick={handleOpenGroupModal}>
                      {t('text.chat.chatWithGroup')}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </div>
        </div>
        {isLoading ? (
          <Spin
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              height: '100%',
            }}
          />
        ) : chatRoomUserLists?.length > 0 ? (
          <>
            <div className="nk-chat-aside-body d-flex flex-column">
              <div className={`nk-chat-list ${isLoading ? 'h-100' : ''} `}>
                <h6 className="title overline-title-alt">
                  {t('text.chat.messages')}
                </h6>

                <div
                  id="sidebarScrollableDiv"
                  className="chat-list"
                  style={{
                    height: 'calc(100vh - 240px)',
                    overflow: 'auto',
                  }}
                >
                  <InfiniteScroll
                    dataLength={currentSidebarPage * PER_PAGE_USERS}
                    hasMore={usersLength < count}
                    loader={
                      <Spin
                        style={{
                          display: 'flex',
                          margin: 'auto',
                          justifyContent: 'center',
                          zIndex: 1,
                        }}
                      />
                    }
                    next={() =>
                      usersLength < count &&
                      !isStopMoreLoading &&
                      handleLoadMoreData()
                    }
                    scrollableTarget="sidebarScrollableDiv"
                  >
                    {chatRoomUserLists?.map((roomUser, idx) => (
                      <div
                        id={roomUser?.chatRoom?.id}
                        key={idx}
                        className={`chat-item ${
                          roomUser?.chatRoom?.id === selectUser?.chatRoom?.id
                            ? 'current'
                            : roomUser?.unreadCount > 0
                            ? 'is-unread'
                            : ''
                        } `}
                        onClick={(e) => {
                          if (
                            !selectUser ||
                            selectUser?.chatRoom?.id !== roomUser?.chatRoom?.id
                          ) {
                            e.preventDefault();
                            if (isUploadItems) {
                              uploadItemsHandleClick();
                            }
                            handleSelectUser(roomUser);
                            if (window.screen?.width < 860) {
                              showChatHandle();
                            }
                          }
                        }}
                      >
                        <div className="chat-link">
                          <div className="chat-media user-avatar bg-purple">
                            <ImageElement
                              previewSource={getProfileImageUrl(roomUser)}
                              loading="lazy"
                              alt="img"
                              isProfile={true}
                            />

                            {mentalHeathPermission &&
                              roomUser?.chatRoom?.chatType === 'single' &&
                              roomUser?.chatRoom?.ChatRoomMembers?.length &&
                              roomUser?.chatRoom?.ChatRoomMembers?.find(
                                (z) => z?.memberUserId > 1
                              )?.User?.Provider?.mentalStatus && (
                                <span class="status status_img">
                                  <ImageElement
                                    previewSource={
                                      emojiIconObj[
                                        roomUser?.chatRoom?.ChatRoomMembers?.find(
                                          (z) => z?.memberUserId > 1
                                        )?.User?.Provider?.mentalStatus
                                      ]
                                    }
                                    loading="lazy"
                                    alt="img"
                                  />
                                </span>
                              )}

                            {roomUser?.chatRoom?.chatType === 'single' &&
                              usersOnline?.length > 0 && (
                                <span
                                  className={statusFormatter(
                                    getUserOnlineStatus(
                                      roomUser?.chatRoom?.User?.id
                                    )
                                  )}
                                ></span>
                              )}
                          </div>
                          <div className="chat-info">
                            <div className="chat-from">
                              <div className="name">
                                {roomUser?.chatRoom?.chatType === 'group'
                                  ? capitalizeFirstLetter(
                                      roomUser?.chatRoom?.roomName
                                    )
                                  : `${capitalizeFirstLetter(
                                      roomUser?.chatRoom?.ChatRoomMembers?.find(
                                        (d) =>
                                          d?.memberUserId !== userDetails?.id
                                      )?.User?.firstName ?? ''
                                    )} ${capitalizeFirstLetter(
                                      roomUser?.chatRoom?.ChatRoomMembers?.find(
                                        (d) =>
                                          d?.memberUserId !== userDetails?.id
                                      )?.User?.lastName ?? ''
                                    )}`}
                              </div>
                              {roomUser?.chatRoom?.lastCreatedAt && (
                                <span
                                  id={`last-message-time-${roomUser?.chatRoom?.id}`}
                                  className="time"
                                >
                                  {moment(
                                    roomUser?.chatRoom?.lastCreatedAt
                                  )?.fromNow()}
                                </span>
                              )}
                            </div>
                            <div className="chat-context">
                              <div className="text">
                                <p id={`last-message-${roomUser.chatRoom.id}`}>
                                  {roomUser?.last_message ? (
                                    acceptChatMediaFiles?.includes(
                                      roomUser?.last_message?.split?.('.')?.[1]
                                    ) ? (
                                      'Media'
                                    ) : (
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: roomUser?.last_message,
                                        }}
                                      />
                                    )
                                  ) : (
                                    ''
                                  )}
                                </p>
                              </div>
                              {roomUser?.unreadCount > 0 && (
                                <span className="badge bg-secondary">
                                  {roomUser?.unreadCount}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </InfiniteScroll>
                </div>
              </div>
              {/* <div className="nk-chat-aside-panel nk-chat-fav mt-auto position-absolute bottom-0 end-0">
                <ul className="d-flex justify-content-end">
                  <li>
                    <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="dropup btn btn-lg btn-icon btn-primary btn-round"
                      >
                        <em className="icon ni ni-plus"></em>
                      </Dropdown.Toggle>

                      <Dropdown.Menu drop="up">
                        <Dropdown.Item
                          href="#"
                          onClick={handleOpenChatWithSPModal}
                        >
                          {t('text.chat.chatWithSP')}
                        </Dropdown.Item>
                        <Dropdown.Item href="#" onClick={handleOpenGroupModal}>
                          {t('text.chat.chatWithGroup')}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                </ul>
              </div> */}
            </div>
          </>
        ) : (
          <div className="noChatData noChatData-sm d-flex flex-column align-items-center justify-content-center w-100 h-100">
            <img src="/assets/images/admin/chat-img.svg" alt="chat" />
            <h4>No Chat Available</h4>
            <p>You have no new chat. Start Conversation</p>
          </div>
        )}
      </div>

      {chatWithSPModal && (
        <ModalComponent
          backdrop
          show={chatWithSPModal}
          onHandleCancel={handleOpenChatWithSPModal}
          title={t('text.chat.selectServiceProvider')}
        >
          <>
            <div className="selectServiceProviderModal">
              <ChatServiceProvider
                chatRoomUserLists={chatRoomUserLists}
                getChatRoomUserLists={() => getChatRoomUserLists(null, true)}
              />
            </div>
          </>
        </ModalComponent>
      )}

      {isOpenCreateGroupModal && (
        <ModalComponent
          backdrop
          show={isOpenCreateGroupModal}
          onHandleCancel={handleOpenGroupModal}
          title={chatroomDetails?.action === 'chatEdit' ? t('text.chat.chatEdit') : t('text.chat.createGroup')}
        >
          <CreateGroupModal
            getChatRoomUserLists={() => getChatRoomUserLists(null, true)}
            chatroomDetails={chatroomDetails}
          />
        </ModalComponent>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  userDetails: {
    id: state?.auth?.userData?.id,
    fullName: `${capitalizeFirstLetter(
      state?.auth?.userData?.firstName
    )} ${capitalizeFirstLetter(state?.auth?.userData?.lastName)}`,
    profileUrl: state?.auth?.userData?.profilePictureUrl,
  },
});

export default connect(mapStateToProps)(ChatSidebar);
