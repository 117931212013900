export const validation = {
  couponCode: 'Coupon code is required',
  discount: 'Discount  is required',
  description: 'Description  is required',
  validity: 'Validity is required',
  usageLimit: 'Usage limit  is required',
  status: 'Status is required',
  activeDate: 'Activation date is required',
  expDate: 'Expiry date  is required',
  minDiscount: 'Discount should not be negative',
  maxDiscount: 'Discount should not be greater than 100 %',
  validDiscount: 'Discount must be number',
  flatDiscountValid: 'Please enter between 1 to 3000 only',
  promoCodeMin: 'Please enter at least 3 character',
  miFlatDiscountValid: 'Discount should not be negative',
  maxFlatDiscountValid: 'Discount should not be greater than 3000',
};
