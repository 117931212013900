import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { valueMapper } from '../../../helpers/schema.helper';
import { locationForData } from '../../../services/Columns';
import { ListManageLocationService } from '../../../services/GeoLocation/geolocation.service';

import {
  FormWrapper,
  FormElementWrapper,
  TextInput,
  Select,
  CommonButton,
} from '../../index';
import { initNCAValues, validationNCA } from './validation';

function AddEditLocationForm({
  onSubmit,
  backToPage,
  isLoading,
  formData,
  setAddress,
  handleChangeLocation,
}) {
  const { t } = useTranslation();
  const [state, setState] = useState({});
  const locationFor = locationForData();

  useEffect(() => {
    if (formData) {
      if (formData?.locationParentId) {
        formData.geoFenceLocationId = formData?.locationParentId;
      }
      setState({ ...formData });
    }
  }, [formData]);

  async function onCountryChange(e, formik, code, other, list) {
    try {
      const country = list?.flat?.()?.find?.((item) => item?.id === e);
      if (country) {
        handleChangeLocation({
          lat: country?.data?.latitude ? parseFloat(country.data.latitude) : '',
          lng: country?.data?.latitude
            ? parseFloat(country.data.longitude)
            : '',
        });
      }
      if (Array.isArray(other)) {
        const selectedLocation = list?.find((el) => el?.id === e);
        if (selectedLocation) {
          setAddress(selectedLocation?.data?.latLong);
          return true;
        }
        setAddress(other[0]?.children);
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <FormWrapper
      onSubmit={onSubmit}
      validation={validationNCA}
      initialValues={initNCAValues(state)}
      enableReinitialize={true}
      data={valueMapper(state)}
      row={true}
      extraClassName="g-3"
    >
      {(props) => (
        <>
          <FormElementWrapper
            md={12}
            label={t('text.common.selectLocation')}
            mandatory={true}
            element={() => (
              <Select
                name="geoFenceLocationId"
                onItemChange={onCountryChange}
                scope="ncaLocation"
                listService={ListManageLocationService}
                placeholder={t('text.common.selectLocation')}
                formik={props}
              />
            )}
          />
          <FormElementWrapper
            md={12}
            label={t('text.manageLocation.locationName')}
            mandatory={true}
            element={() => (
              <TextInput
                name="name"
                placeholder="Location Name"
                formik={props}
              />
            )}
          />
          <FormElementWrapper
            md={12}
            label={t('text.manageLocation.locationFor')}
            mandatory={true}
            element={() => (
              <Select
                name="locationFor"
                placeholder="Location For"
                options={locationFor}
                showSearch
                formik={props}
              />
            )}
          />
          <FormElementWrapper
            md={12}
            label={t('text.manageLocation.pullPermit')}
            mandatory={true}
            element={() => (
              <TextInput
                type="number"
                name="pullPermitCost"
                placeholder="Enter Pull Permit Cost"
                formik={props}
              />
            )}
          />
          <FormElementWrapper
            md={12}
            element={() => (
              <>
                <CommonButton
                  type="submit"
                  className="btn btn-primary me-2"
                  loading={isLoading}
                >
                  {t('text.common.saveNext')}
                </CommonButton>
                <CommonButton
                  type="button"
                  className="btn btn-light"
                  onClick={backToPage}
                >
                  {t('text.common.cancel')}
                </CommonButton>
              </>
            )}
          />
        </>
      )}
    </FormWrapper>
  );
}

export default AddEditLocationForm;
