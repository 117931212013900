import i18next from 'i18next';
import {
  getInputSearchFilter,
  getMultiSelectSearchFilter,
} from '../../components';
import {
  capitalizeFirstLetterString,
  checkValidData,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { accessRoute } from '../../routes/Dashboard';
import { getTupleStatusList, Localization } from '../index.service';
import { accessRoute as TeamSettingRoute } from '../../routes/TeamSetting';

export const documentManagementColumn = ({
  Action,
  onFilterChange,
  AvailabilityToggle,
  Toggle,
  filter,
  page,
  sizePerPage,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      render: (cell, row, index) =>
        serialNumberFormatter({ page, index, sizePerPage }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'countryName',
      filterDataIndex: ['countryId'],
      title: i18next.t('text.common.country'),
      sorter: true,
      render: (cell, row) =>
        row?.Country?.name ? row?.Country?.name : i18next.t('text.common.all'),
      exportFormatter: (cell, row) =>
        row?.Country?.name ? row?.Country?.name : i18next.t('text.common.all'),
      exportKey: 'Country',
      ...getMultiSelectSearchFilter({
        dataIndex: 'countryId',
        onFilterChange,
        name: 'country',
        scope: 'activeCountry',
        list: [],
        mode: false,
        listService: Localization.CountryListService,
        filter,
      }),
    },
    {
      dataIndex: 'name',
      title: i18next.t('text.documentManagement.documentName'),
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'name',
        placeholder: 'document name',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'documentFor',
      title: i18next.t('text.documentManagement.documentFor'),
      sorter: true,
      className: 'text-capitalize',
      render: (cell, row) => checkValidData(cell),
      ...getMultiSelectSearchFilter({
        dataIndex: 'documentFor',
        onFilterChange,
        name: 'for',
        list: [],
        mode: false,
        listService: documentForOption,
        filter,
      }),
    },

    {
      dataIndex: 'status',
      title: i18next.t('text.localization.status'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => {
        return <Toggle row={row} cell={cell} />;
      },
      ...getMultiSelectSearchFilter({
        dataIndex: 'status',
        onFilterChange,
        name: 'status',
        listService: getTupleStatusList,
        mode: false,
        filter,
      }),
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.localization.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => {
        return <Action row={row} cell={cell} />;
      },
    },
  ];
};

export const documentManagementBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.documentManagement.documentManagement'),
    },
  ];
};

export const manageDocumentAddBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: TeamSettingRoute.MANAGE_DOCUMENT.path,
      name: i18next.t('text.documentManagement.documentManagement'),
    },
    {
      path: '#',
      name: i18next.t('text.documentManagement.documentName'),
    },
  ];
};
export const documentForOption = () => {
  return [
    {
      name: 'Store',
      value: 'store',
      id: 'store',
    },
    {
      name: 'Provider',
      value: 'provider',
      id: 'provider',
    },
  ];
};

export const radioOptions = () => {
  return [
    {
      label: 'Yes',
      value: 1,
    },
    {
      label: 'No',
      value: 0,
    },
  ];
};
