import React, { useEffect, useState } from 'react';
import { Columns, getInvoiceDetailsService, getOrderInvoiceService } from '../../../../services/index.service';
import { GlobalLoader, PageContainerWrapper } from '../../../../components';
import { useTranslation } from 'react-i18next';
import { accessRoute } from '../../../../routes/ShoppingCart';
import { backToPage } from '../../../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { CommonOrderInvoice } from '../../../../features/index.feature';
import { getApiData } from '../../../../hooks/useGetApi.hook';

export default function OrderInvoice() {
  const breadcrumb = Columns.orderViewInvoiceBreadcrumb();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const redirectUrl = accessRoute.ORDER_MANAGEMENT_LIST.path;
  const { id } =    useParams();
  const [invoiceDetails , setInvoiceDetails] = useState()
  const [loading , setIsLoading] = useState()
  useEffect(() => {
    if (id) {
      getApiData({
        setIsLoading:setIsLoading,
        service: getOrderInvoiceService,
        setData: setInvoiceDetails,
        id: id,
      });
    }
  }, [id]);
  return (
    <>
      {' '}
      <PageContainerWrapper
        heading={t('text.common.orderInvoice')}
        breadcrumb={breadcrumb}
        extraClass="btn btn-primary d-block d-xl-none"
        extraMobileClass="p-xl-0"
        btnArray={['extraButton']}
        extraBtnText={t('text.common.back')}
        extraBtnClass="btn btn-outline-light bg-white"
        onExtraButtonHandleShow={() => backToPage(navigate, redirectUrl)}
      >
         {loading ? (
          <GlobalLoader />
        ) : (
          <CommonOrderInvoice t={t}  id={id} invoiceDetails={invoiceDetails} />

        )}
      </PageContainerWrapper>
    </>
  );
}
