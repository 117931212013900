export const text = {
  title: 'PO/Truck Stock',
  addPart: 'Add Part',
  part: 'Part',
  TotalPartAmountYes: 'Total Part Amount (Ordered - Yes)',
  TotalPartAmountNo: 'Total Part Amount (Ordered - No)',
  selectByDate: 'Select by date',
  order: 'Ordered',
  poOption: 'PO Option',
  paymentType: 'Payment Type',
};
