export const text = {
  personalInfo: 'Personal Information',
  basic: 'Basics',
  basicInfoLike:
    'Basic info, like your name and address, that you use on Nio Platform.',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  updateProfile: 'Update Profile',
  fullNamePlaceholder: 'Enter first name',
  displayNamePlaceholder: 'Enter last name',
  emailPlaceholder: 'Enter email Address',
  fullName: 'Full name',
  displayName: 'Display name',
};
