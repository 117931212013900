export const text = {
  title: 'New Equipment Install & Warranty Registration',
  equipmentInstall: 'Equipment Installed',
  permitLocation: 'Permit Location',
  permitStatus: 'Permit Status',
  warrantyRegistered: 'Warranty Registered',
  laborWarranty: 'Labor Warranty Purchased',
  installationDate: 'Installation Date',
  typeOfPurchase: 'Type of Purchased',
  model: 'Model#',
  registerWarranty: 'Register Warranty For New Equipment?',
  applicationType: 'Application Type',
  systemCondition: 'System Condition',
  systemNotes: 'System Notes',
  photos: 'Photos',
  serialHash: 'Serial#',
  totalCost: 'Total Cost',
  lastUpdatedAdSave: 'Last Updated/Save',
  installedEquipment: 'Installed Equipment',
  typeofPurchase: 'Type of Purchase',
  equipmentW: 'Register Warranty For New Equipment?',
};
