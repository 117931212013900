import React, { useState } from 'react';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { marketingUnsubscribeService } from '../../../../services/Marketing/store.service';
import { GlobalLoader, ImageElement } from '../../../../components';
import '../../../PageNotFound/index.css';

export default function MarketingUnSubscribe() {
  const { userId, recipientId } = useParams();
  const [loader, serLoader] = useState(false);

  const unSubscribeTheUser = async () => {
    serLoader(true);
    try {
      const body = {
        recipientId: recipientId,
      };
      const response = await marketingUnsubscribeService({ body, userId });
      if (response) {
      }
      serLoader(false);
    } catch (error) {
      console.log(error);
    }
    serLoader(false);
  };

  useEffect(() => {
    unSubscribeTheUser();
  }, []);

  return (
    <div>
      {loader ? (
        <GlobalLoader />
      ) : (
        <>
          <div className="pageNotFound">
            <div className="pageNotFound_inner d-flex flex-column align-items-center justify-content-center h-100">
              <ImageElement
                source="unsubscribe-icon.svg"
                className="pageNotFound_iconImg"
              />
              <p>
                You are successfully unsubscribed, and <br /> if you want to
                resume marketing message
                <br /> please contact this email
              </p>
              <Link
                to="#"
                className="pageNotFound_link"
                onClick={() =>
                  (window.location = 'mailto:support@goodguyshomeservices.com')
                }
              >
                support@goodguyshomeservices.com
              </Link>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
