import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  CommonButton,
  PageContainerWrapper,
  TablePlus,
} from '../../../../../components';
import useTable from '../../../../../hooks/useTable.hook';
import { accessRoute } from '../../../../../routes/Reports';
import { Columns } from '../../../../../services/index.service';
import { backToPage } from '../../../../../utils';
import i18next from 'i18next';

function SubLaborPayDetails() {
  const breadcrumb = Columns.subLabureBreadcrumb();
  const PaymentReportData = Columns.purchaseOrderData();
  const navigate = useNavigate();
  const [state, setState] = useState({
    loader: null,
    modal: null,
    modalId: null,
    selectedRows: [],
  });
  const [tableHandler, tableProps] = useTable(PaymentReportData);
  const { count, page, noOfPages, sizePerPage } = tableProps;
  const { onFilterChange, getData, onTableSort } = tableHandler;
  // const { loader, modal, modalId, selectedRows, submitText } = state;

  const columns = Columns.purchaseOrderColumn({
    onSort: onTableSort,
    onFilterChange: onFilterChange,
  });
  const { t } = useTranslation();
  // const headerBtn = {};

  function onRowSelect(e) {
    try {
      setState({ ...state, selectedRows: [...e] });
    } catch (err) {
      console.log('err', err);
    }
  }

  async function handleActionSelect(e) {
    try {
      if (e.target.value === 'delete') {
        // await onDelete(selectedRows);
        getData();
      } else {
        getData();
      }
    } catch (err) {
      console.log(err);
    }
  }

  const redirectUrl = accessRoute.PAYMENT_REPORT.path;

  return (
    <>
      {' '}
      <PageContainerWrapper
        heading={t('text.report.SubLaborPayDetail')}
        breadcrumb={breadcrumb}
        btnArray={['extraButton']}
        extraBtnText={t('text.common.back')}
        extraBtnClass="btn btn-outline-light bg-white"
        onExtraButtonHandleShow={() => backToPage(navigate, redirectUrl)}
      >
        <TablePlus
          hasLimit
          noOfPage={noOfPages}
          sizePerPage={sizePerPage}
          page={page}
          handleSelectedRows={onRowSelect}
          handleActionSelect={handleActionSelect}
          count={count}
          tableData={PaymentReportData}
          tableColumns={columns}
          selectRow
        />
        <div className="card">
          <div className="card-inner pt-0">
            <div className="text-end">
              <CommonButton className="btn btn-primary">Add </CommonButton>
              <CommonButton className="btn btn-success ms-2">
                {t('text.common.submit')}
              </CommonButton>
            </div>
          </div>
        </div>
      </PageContainerWrapper>
    </>
  );
}

export default SubLaborPayDetails;
