export const text = {
  documentManagement: 'Manage Documents',
  addDocumentName: 'Add Document Name',
  editDocumentName: 'Edit Document Name',
  documentName: 'Document Name',
  documentFor: 'Document For',
  documentNameEng: 'Document Name (English)',
  expireOnDate: 'Expire On Date',
  engValue: 'English Value',
  areYouSureDeleteDocument: 'you want to delete this Documents',
  note: 'Notes : ',
  docList: ' Document List module will list all document lists on this page.',
  adminList:
    ' Administrator can Activate / Deactivate / Delete any document list.',
  export: ' Administrator can export data in XLS format.',
  askForDate: 'Yes option will ask for Date',
  nameDocument:
    'Name of Document for admin use. e.g. Insurance, Driving Licence... etc',
};
