import { color } from 'highcharts';
import { checkValidDiscountType } from '../components/UIElements/Formatter';

export const getFullName = (first, middle, last, plan, amount, amountType) => {
  try {
    let name = firstLetterCaps(first);
    if (!name) {
      return '';
    }
    if (middle) {
      name += ` ${firstLetterCaps(middle)}`;
    }
    if (last) {
      name += ` ${firstLetterCaps(last)}`;
    }
    if (plan) {
      name += ` - <b style="color: #F47721">( ${firstLetterCaps(plan)} )</b>`;
      return <div dangerouslySetInnerHTML={{ __html: name }} />;
    }
    if (amountType && amount) {
      name += ` -  ${checkValidDiscountType(amountType, amount)}`;
    }
    return name;
  } catch (error) {
    console.log(error);
  }
};

export const firstLetterCaps = (text) => {
  try {
    if (text) {
      const originalString = text?.toLowerCase()?.split('');
      originalString[0] = originalString[0]?.toUpperCase();
      return originalString?.join('');
    } else {
      return text;
    }
  } catch (error) {
    console.log(error);
  }
};

export const splitLetter = (text) => {
  if (text) {
    const words = text.split('_'); 
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)); 
    return capitalizedWords.join(' '); 
  } 
}

export const getNamedAvatar = (name) => {
  try {
    if (!name) {
      return name;
    }
    const splitNames = name?.split(' ').map((e) => e.charAt(0));
    return `${splitNames[0]} ${splitNames[1]}`;
  } catch (error) {
    console.log(error);
  }
};
export function makeid(length) {
  let result = '';
  let characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const getFullNameWithRating = (first, middle, last, rating, plan) => {
  try {
    let name = firstLetterCaps(first);
    if (!name) {
      return '';
    }
    if (middle) {
      name += ` ${firstLetterCaps(middle)}`;
    }
    if (last) {
      name += ` ${firstLetterCaps(last)}`;
    }
    return (
      <div>
        {/* style="color: #F47721" */}
        {name}{' '}
        {
          <>
            <b>{`(${rating ? rating : '0.0'})`}</b>
            {plan ? <b style={{ color: '#F47721' }}>-{plan}</b> : ''}
          </>
        }
      </div>
    );
  } catch (error) {
    console.log(error);
  }
};
