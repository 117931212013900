import i18next from 'i18next';
import {
  checkValidData,
  checkValidPrice,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { accessRoute } from '../../routes/Dashboard';
import {
  getInputSearchFilter,
  getColumnSelectSearchProps,
  getMultiSelectSearchFilter,
} from '../../components';
import { getTupleStatusList, getRebateTypeList } from '../index.service';
import { getQuoteTypeListService } from '../QuoteManagement/quoteGet.service';
import { readMoreTextShow } from '../../utils';

export const rebateItemsBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.quote.quoteItems'),
    },
  ];
};

export const rebateItemsTableColumn = ({
  Action,
  onSort,
  onFilterChange,
  filteredValue,
  filter,
  Toggle,
  page,
  sizePerPage,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'title',
      title: i18next.t('text.common.title'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('title', order),
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'title',
        onFilterChange,
        filteredValue,
        filter,
      }),
    },
    {
      dataIndex: 'cost',
      title: i18next.t('text.common.cost'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('cost', order),
      render: (cell, row) => checkValidPrice(cell),
      ...getInputSearchFilter({
        dataIndex: 'cost',
        onFilterChange,
        filteredValue,
        filter,
      }),
    },
    {
      dataIndex: 'quoteType',
      title: i18next.t('text.quote.quoteType'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('quoteType', order),
      render: (cell, row) =>
        readMoreTextShow(
          checkValidData(row?.rebateQuoteTypeTitle),
          // [
          //   ...new Set(
          //     row?.RebateQuoteTypes?.map(
          //       (item) => item?.QuoteDetails?.quoteType
          //     )
          //   ),
          // ]?.join(',')
          i18next.t('text.quote.quoteType'),
          15
        ),
      ...getMultiSelectSearchFilter({
        dataIndex: 'quoteType',
        name: 'quote type',
        listKeys: { nameKey: 'quoteType', nameId: 'quoteType' },
        list: [],
        listService: getQuoteTypeListService,
        onFilterChange,
        filteredValue,
        filter,
        mode: false,
        addAll: true,
      }),
    },
    {
      dataIndex: 'rebateType',
      title: i18next.t('text.quote.rebateType'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('partNumber', order),
      render: (cell, row) =>
        checkValidData(
          getRebateTypeList().find((e) => e?.value === cell)?.name || cell
        ),
      // ...getColumnSelectSearchProps({
      //   dataIndex: 'rebateType',
      //   placeholder: 'rebate type',
      //   list: getRebateTypeList(),
      //   onFilterChange,
      //   filteredValue,
      //   filter,
      // }),
      ...getMultiSelectSearchFilter({
        dataIndex: 'rebateType',
        name: 'rebate type',
        listService: getRebateTypeList,
        onFilterChange,
        filteredValue,
        filter,
        mode: false,
        addAll: true,
      }),
    },
    {
      dataIndex: 'minCertifiedRating',
      title: i18next.t('text.quote.minRating'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('minCertifiedRating', order),
      render: (cell, row) => checkValidPrice(cell, false),
      ...getInputSearchFilter({
        dataIndex: 'minCertifiedRating',
        placeholder: 'min certified rating',
        onFilterChange,
        filteredValue,
        filter,
      }),
    },
    {
      dataIndex: 'maxCertifiedRating',
      title: i18next.t('text.quote.maxRating'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('maxCertifiedRating', order),
      render: (cell, row) => checkValidPrice(cell, false),
      ...getInputSearchFilter({
        dataIndex: 'maxCertifiedRating',
        placeholder: 'max certified rating',
        onFilterChange,
        filteredValue,
        filter,
      }),
    },
    {
      dataIndex: 'description',
      title: i18next.t('text.common.description'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('description', order),
      render: (cell, row) =>
        readMoreTextShow(cell, i18next.t('text.common.description'), 50),
      ...getInputSearchFilter({
        dataIndex: 'description',
        onFilterChange,
        filteredValue,
        filter,
      }),
    },
    {
      dataIndex: 'status',
      title: i18next.t('text.localization.status'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => {
        return <Toggle row={row} cell={cell} />;
      },
      ...getColumnSelectSearchProps({
        dataIndex: 'status',
        placeholder: 'status',
        list: getTupleStatusList('all'),
        onFilterChange,
        filteredValue,
        filter,
      }),
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.common.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => <Action row={row} cell={cell} />,
    },
  ];
};
