import { createSlice } from '@reduxjs/toolkit';
import { logger } from '../../../utils';
import { getGeneralSetting } from '../../../services/Common/index.service';

const initialState = {
  apiKey: '',
};

export const googleMap = createSlice({
  name: 'googleMap',
  initialState,
  reducers: {
    setApiKey: (state, action) => {
      return (state = {
        ...state,
        apiKey: action.payload,
      });
    },
    resetState: (state) => {
      return (state = {
        ...initialState,
      });
    },
  },
});

const { setApiKey, resetState } = googleMap.actions;

export const setGoogleMapKey = () => async (dispatch) => {
  try {
    const res = await getGeneralSetting({
      queryParams: { type: 'installationSettings', isNcaSignUp: 1 },
    });
    if (res.success) {
      dispatch(
        setApiKey(
          (res?.data?.rows || []).find(
            (e) => e?.key === 'GOOGLE_SEVER_API_KEY_WEB'
          )?.value
        )
      );
    }
  } catch (error) {
    logger(error);
  }
};

export const resetGoogleMapState = () => (dispatch) => {
  dispatch(resetState());
};

export default googleMap.reducer;
