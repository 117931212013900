export const text = {
  city: 'City',
  state: 'State',
  country: 'Country',
  serialNo: 'S.No.',
  status: 'Status',
  action: 'Action',
  stateCode: 'State Code',
  code: 'Code',
  unit: 'Unit(Miles/KMs)',
  notes: 'Notes:',
  cityNoteOne: 'City module will list all countries on this page.',
  cityNoteTwo: 'Can Activate / Deactivate / Delete any city.',
  noteThree: 'Administrator can export data in XLS format.',
  stateNoteOne: 'State module will list all countries on this page.',
  stateNoteTwo: 'Can Activate / Deactivate / Delete any state.',
  countryNoteOne: 'Country module will list all countries on this page.',
  countryNoteTwo:
    ' Administrator can Activate / Deactivate / Delete any country.',
  countryPhoneCode: 'Country Phone Code',
};
