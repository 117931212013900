import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TablePlus,
  ActionWrapper,
  ModalWrapper,
  PageContainerWrapper,
  Toggle,
  CommonButton,
  TagForm,
} from '../../../components';
import { modalNotification } from '../../../utils';
import {
  getTagService,
  deleteTagService,
  updateTagStatus,
  addTagService,
  getTagDetailsService,
  editTagService,
  getActions,
} from '../../../services/index.service';
import { useTable } from '../../../hooks/index.hook';
import { tagsListBreadcrumb, tagsTableColumn } from '../../../services/Columns';

function ListTags() {
  const { t } = useTranslation();
  // For loader, modal, selected row, and form text
  const [state, setState] = useState({
    loader: null,
    modal: null,
    modalId: null,
    selectedRows: [],
    submitText: 'add',
  });
  const { loader, modal, modalId, selectedRows, submitText } = state;

  // For get tags list
  const [tableHandler, tableProps] = useTable(getTagService);
  const {
    data,
    isLoading,
    count,
    page,
    noOfPages,
    sizePerPage,
    filter,
    sortBy,
    sortType,
  } = tableProps;
  const {
    onRefresh,
    getData,
    onTableSort,
    onFilterChange,
    onFilterResetChange,
    onToggleChange,
    onConfirmDeleteAlert,
  } = tableHandler;

  // For breadcrumb
  const breadcrumb = tagsListBreadcrumb();

  // For action with column
  function onAction(e, handler, item) {
    e.preventDefault();
    handler(item);
  }

  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options?.map((item, key) => {
          return (
            <li
              action={item?.action}
              onClick={(e) => onAction(e, item?.handler, item)}
              key={key}
              className="action_list"
            >
              <CommonButton>
                <em className={item?.icon} />
                {item?.name}
              </CommonButton>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };

  const columns = tagsTableColumn({
    onSort: onTableSort,
    onFilterChange: onFilterChange,
    filter,
    page,
    sizePerPage,
    Action: (
      props
      // row
    ) =>
      actionFormatter([
        {
          name: t('text.common.edit'),
          icon: 'icon ni ni-edit',
          action: getActions('EDIT').value,
          handler: () => {
            setState({
              ...state,
              modal: 'edit-tag',
              submitText: t('text.common.update'),
              modalId: props?.row?.id,
            });
          },
        },
        {
          name: t('text.common.delete'),
          icon: 'icon ni ni-trash',
          action: getActions('DELETE').value,
          handler: () => {
            onConfirmDeleteAlert({
              id: props.row.id,
              text: t('text.tags.areYouSureDeleteTag'),
              service: deleteTagService,
              key: 'tagIds',
            });
            document.body.click();
          },
        },
      ]),
    Toggle: (props) => (
      <Toggle
        isDropDown={true}
        loading={loader === 'status-update'}
        defaultChecked={props?.row?.status}
        onChange={(e) =>
          onToggleChange(e, props?.row, '', updateTagStatus, 'tagIds', true)
        }
      />
    ),
  });

  // For Status change toggle function Start

  async function getDetail(id) {
    try {
      const res = await getTagDetailsService({ id });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  function onCancel() {
    setState({ ...state, modal: null, modalId: null });
  }

  // For handle submit
  async function onSubmit(payload) {
    setState({ ...state, loader: 'add' });
    try {
      let response = '';
      if (modalId)
        response = await editTagService({
          bodyData: payload,
          id: modalId,
        });
      else
        response = await addTagService({
          bodyData: payload,
        });
      const { success, message } = response;

      if (success) {
        setState({ ...state, modal: null, loader: null, modalId: null });
        modalNotification({
          type: 'success',
          message,
        });
        onRefresh();
      } else {
        setState({ ...state, loader: null });
      }
    } catch (error) {
      console.log(error);
      setState({ ...state, loader: null });
    }
  }

  const formProps = {
    onCancel: onCancel,
    onSubmit: onSubmit,
  };

  const modalForm = {
    add: (
      <TagForm
        onEdit={getDetail}
        onCancel={onCancel}
        loading={loader === 'add'}
        modalId={modalId}
        submitText={submitText}
        {...formProps}
      />
    ),
  };
  // For bulk action
  function onRowSelect(e) {
    try {
      setState({ ...state, selectedRows: [...e] });
    } catch (err) {
      console.log('err', err);
    }
  }
  // For bulk action
  async function handleActionSelect(e) {
    try {
      if (e?.target?.value === 'delete') {
        await onConfirmDeleteAlert({
          id: selectedRows,
          text: t('text.tags.areYouSureDeleteTag'),
          service: deleteTagService,
          key: 'tagIds',
        });
        getData();
      } else {
        await onToggleChange(
          e?.target?.value,
          selectedRows,
          'action',
          updateTagStatus,
          'tagIds'
        );
        getData();
      }
    } catch (err) {
      console.log(err);
    }
  }
  // For header Button
  const headerBtn = {
    add: {
      name: t('text.tags.addTag'),
      handler: () => {
        setState({ ...state, submitText: 'Add', modal: 'add-tag' });
      },
    },
  };
  return (
    <>
      <PageContainerWrapper
        heading={t('text.tags.title')}
        breadcrumb={breadcrumb}
        btn={headerBtn}
      >
        <ModalWrapper
          heading={`${
            submitText === 'Add' ? t('text.common.add') : t('text.common.edit')
          } Tags Action`}
          onCancel={onCancel}
          content={modalForm['add']}
          show={['add-tag', 'edit-tag'].includes(modal)}
        />
        <TablePlus
          hasLimit
          noOfPage={noOfPages}
          sizePerPage={sizePerPage}
          page={page}
          disabledAction={['delete']}
          handleSelectedRows={onRowSelect}
          handleActionSelect={handleActionSelect}
          count={count}
          tableData={data}
          tableColumns={columns}
          selectRow
          tableLoader={isLoading}
          onFilterResetChange={onFilterResetChange}
          filter={filter}
          sortBy={sortBy}
          sortType={sortType}
        />
      </PageContainerWrapper>
    </>
  );
}

export default ListTags;
