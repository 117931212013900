import { ORDER_MANAGEMENT_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';

export const orderListService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...ORDER_MANAGEMENT_ENDPOINT?.getOrderList,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const updateOrderStatusService = async (bodyData) => {
  const { orderId } = bodyData;
  delete bodyData.orderId;
  try {
    const payload = {
      ...ORDER_MANAGEMENT_ENDPOINT.statusOrderStatus(orderId[0]),
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};


export const getOrderInvoiceService = async (id) => {
  try {
    const payload = {
      ...ORDER_MANAGEMENT_ENDPOINT.getOrderInvoice(id),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};