export const text = {
  title: 'Manage Services',
  heading: 'Service Category',
  deleteThisCategory: 'you want to delete this Category',
  addCategory: 'Add Service Category',
  editCategory: 'Edit Service Category',
  serviceType: 'Service Type',
  addServiceType: 'Add Service Type',
  salesQuote: 'Sales Quote',
  displayOrder: 'Display Order',
  areYouSureDeleteServiceType: 'you want to delete this Service Type',
  serviceCategory: 'Service Category',
  selectServiceCategory: 'Select service category',
  enterServiceType: 'Enter service type',
  areYouSureDeleteServiceCategory: 'you want to delete this Category',
  addServiceCategory: 'Add Service Category',
  selectServiceType: 'Select service type',
  servicePrice: 'Service Price',
  selectServicePrice: 'Select service price',
  serviceStatus: 'Service Status (For Amazon Alexa Calling)',
  selectServiceStatus: 'Select service status',
  talkTechnician: 'Talk to technician Service (For Amazon Alexa Calling)',
  selectTechnicianService: 'Select technician service',
  enterNewLocation: 'Enter New Location',
  note: '[Note: Upload only png image size of 360px*360px.]',
  locationTooltip:
    'Select the location in which you would like to appear this Services. For example "car washing" services to appear for any specific city or state or may be for whole country. You can define these locations from "Manage Locations >> Geo Fence Location" section',
  callingServiceName: 'Calling Service Name',
  enterCallingServiceName: 'Enter calling service name',
  editServiceCategory: 'Edit Service Category',
  subServiceCategory: 'Sub Service Category',
  addSubServiceCategory: 'Add Sub Category',
  callingServiceAmazonAlex: 'Calling Service (For Amazon Alex)',
  talkServiceAmazonAlex: 'Talk to technician Service (For Amazon Alex)',
  areYouSureDeleteSubServiceCategory: 'you want to delete this Sub Category',
  addSubCategory: 'Add Sub Service Category',
  customerVisibilityService: 'Customer Visibility of Service',
  serviceQuantityAvailability: 'Service Quantity Availability',
  manualBooking: 'Manual Booking',
  serviceBlock: 'Service Block (No of Days)',
  selectServiceBlock: 'Select service block',
  editSubCategory: 'Edit Sub Service Category',
  categoryNoteOne:
    'Main Category module will list all Main Category on this page.',
  categoryNoteTwo:
    'Administrator can Activate / Deactivate / Delete any Main Category',
  subCategoryNoteOne:
    'Sub Category module will list all Sub Categories on this page.',
  subCategoryNoteTwo:
    'Administrator can Activate / Deactivate / Delete any Sub Category',
  serviceTypeNoteOne:
    'Service Type module will list all Service types on this page.',
  serviceTypeNoteTwo: 'Administrator can Edit / Delete any Service type.',
  selectDisplayOrder: 'Select display order',
  addEditSubCategoryNoteOne:
  'For listing sub-category service in chatbot and voice, ON either of the toggle under Service Type and Price.',
};
