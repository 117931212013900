import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { PageContainerWrapper } from '../../../components';
import { Invoice } from '../../../features/index.feature';
import { getApiData } from '../../../hooks/useGetApi.hook';
import { accessRoute } from '../../../routes/ServiceManagement';

import {
  Columns,
  getInvoiceDetailsService,
} from '../../../services/index.service';
import { backToPage } from '../../../utils';
import { getFullName } from '../../../utils/text.util';

function ServiceManagementInvoice() {
  const breadcrumb = Columns.servicemanagementInvoiceServiceBreadcrumb();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [getInvoiceData, setGetInvoiceData] = useState();
  const params = useParams();
  const redirectUrl = accessRoute.LIST_SERVICE.path;
  const { id, viewInvoice } = params;
  let notViewInvoice = viewInvoice !== 'isInvoice';

  useEffect(() => {
    if (id) {
      getApiData({
        service: getInvoiceDetailsService,
        setData: setGetInvoiceData,
        id: id,
      });
    }
  }, [id]);

  const invoiceData = {
    providerName: getFullName(
      getInvoiceData?.Provider?.firstName,
      null,
      getInvoiceData?.Provider?.lastName
    ),
    providerEmail: getInvoiceData?.Provider?.email,
    userName: getFullName(
      getInvoiceData?.Customer?.firstName,
      null,
      getInvoiceData?.Customer?.lastName
    ),
    userEmail: getInvoiceData?.Customer?.email,
    bookingAddress: getInvoiceData?.booking?.Address?.addressLine1,
    jobId: getInvoiceData?.bookingNo,
    jobDate: getInvoiceData?.bookingScheduleDate,
    serviceDetails: getInvoiceData?.ServiceCategory?.description,
    serviceName: getInvoiceData?.ServiceCategory?.name,
    qty: getInvoiceData?.quantity,
    serviceCharge: getInvoiceData?.ServiceCategory?.price,
    lineItem: getInvoiceData?.BookingLineItems,
    purchase: '400.00',
    sublabor: getInvoiceData?.SubLabors?.totalAmount,
    returned: '200.00',
    servicePrice: getInvoiceData?.ServiceCategory?.price,
    subtotal: getInvoiceData?.subTotal,
    discount: getInvoiceData?.isQuoteRequest ? null : getInvoiceData?.discount,
    commission: getInvoiceData?.storeCommission,
    total: getInvoiceData?.total,
    notes: getInvoiceData?.bookingNote,
    promoCodeDiscount: getInvoiceData?.promoCodeDiscount,
    discountProvider: getInvoiceData?.isQuoteRequest
      ? getInvoiceData?.BookingDetail?.discount
      : null,
    email: !notViewInvoice,
    signature: !notViewInvoice,
    Rebates: getInvoiceData?.ProviderQuoteDiscountRebates,
    invoiceMessage: getInvoiceData?.invoiceMessage,
    otherNotes: getInvoiceData?.providerNote,
    quoteDetails: getInvoiceData?.ProviderQuote,
    pleaseNote: notViewInvoice,
    bookingStatus: notViewInvoice,
    bookingStatusKey: getInvoiceData?.bookingStatus,
  };
  return (
    <>
      {' '}
      <PageContainerWrapper
        heading={t('text.report.invoiceHeading')}
        breadcrumb={breadcrumb}
        btnArray={['extraButton']}
        extraBtnText={t('text.common.back')}
        extraBtnClass="btn btn-outline-light bg-white"
        onExtraButtonHandleShow={() => backToPage(navigate, redirectUrl)}
      >
        <Invoice invoiceData={invoiceData} bookingId={id} />
      </PageContainerWrapper>
    </>
  );
}

export default ServiceManagementInvoice;
