export const statusFormatter = (cell) => {
  const successArr = ['online'];
  const failedArr = ['offline'];
  const unreadArr = ['unread'];
  const currentArr = ['read'];

  const statusArr = {
    online: 'online',
    offline: 'offline',
    unreadArr: 'unreadArr',
    currentArr: 'currentArr',
  };
  let data;

  if (successArr.includes(cell)) {
    data = (
      <span className="status dot dot-lg dot-success">{statusArr?.[cell]}</span>
    );
  } else if (failedArr.includes(cell)) {
    data = (
      <span className="status dot dot-lg dot-primary">{statusArr?.[cell]}</span>
    );
  } else if (unreadArr.includes(cell)) {
    data = (
      <span className="status dot dot-lg dot-danger">{statusArr?.[cell]}</span>
    );
  } else if (currentArr.includes(cell)) {
    data = (
      <span className="status dot dot-lg dot-gray">{statusArr?.[cell]}</span>
    );
  }
  return data?.props?.className;
};

export const StatusOrderFormatter = (cell) => {
  if (cell === 'canceled') {
    return true;
  } else if (cell === 'refunded') {
    return true;
  } else {
    return false;
  }
};
