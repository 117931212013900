import React from 'react';

import { BookingLogView } from '../../../features/index.feature';

import { accessRoute } from '../../../routes/ScheduledBooking';
import { scheduleBookingLogBreadcrumb } from '../../../services/Columns';
import { getBookingLogDetailsService } from '../../../services/index.service';
import { generatePath, useParams } from 'react-router-dom';
import { accessRoute as commonAccessRoutes } from '../../../routes/CommonRoutes';

function BookingView() {
  const breadcrumb = scheduleBookingLogBreadcrumb();
  const { id, viewQuote } = useParams();
  const invoiceUrl = generatePath(commonAccessRoutes.INVOICE.path, {
    viewInvoice: 'quote',
    routePath: 'scheduled-bookings',
    id: id,
  });
  return (
    <>
      <BookingLogView
        apiService={getBookingLogDetailsService}
        bookingNumberKey={'BookingNumber'}
        backPageUrl={accessRoute.SCHEDULE_MANAGEMENT.path}
        breadcrumb={breadcrumb}
        viewQuoteButton={viewQuote === 'isViewQuote'}
        inVoiceUrl={invoiceUrl}
      />
    </>
  );
}

export default BookingView;
