export const text = {
  faq: 'Faq',
  addFaq: 'Add Faq',
  faqNoteOne: 'FAQ module will list all faqs on this page.',
  faqNoteTwo: 'Administrator can Activate / Deactivate / Delete any faq.',
  faqNoteThree: 'Administrator can export data in XLS format.',
  faqDelete: 'Faq Deleted Successfully',
  englishQuestion: 'English Question',
  pageDescription: 'English Answer ',
  deleteFaq: 'you want to delete this faq',
  questionPlaceholder: 'Question',
  pageDescriptionPlaceholder: 'Page Description',
  selectStatus: 'Select Status',
  selectOrder: 'Select Order',
  
};
