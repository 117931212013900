import React from 'react';
import { useParams } from 'react-router-dom';
import ListSubServiceCategory from '../../../PriceManagement/ManageService/ServiceCategory/SubServiceCategory/List';

function ViewNCASubCategory() {
  const { id, userId } = useParams();
  return <ListSubServiceCategory userId={userId} ncaId={id} ncaEdit={true} />;
}

export default ViewNCASubCategory;
