import loadable from '@loadable/component';
import FullPageLoader from '../UIElements/Loader/fullPageLoader';

export const ListingHeader = loadable(() => import('./ListingHeader/index'), {
  fallback: <FullPageLoader />,
});
export const PageHeader = loadable(() => import('./PageHeader/index'), {
  fallback: <FullPageLoader />,
});
export const NoteFooter = loadable(() => import('./NoteFooter/index'), {
  fallback: <FullPageLoader />,
});
