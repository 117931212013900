import loadable from '@loadable/component';
import { OnlineProvider } from '../../../views/index.view';

const CustomerHeatmap = loadable(() =>
  import('../../../views/Dashboard/CustomerHeatmap')
);
const ProviderSalesLeaders = loadable(() =>
  import('../../../views/Dashboard/ProviderSalesLeaders')
);
const ServiceCategoryReport = loadable(() =>
  import('../../../views/Dashboard/ServiceCategoryReport')
);
const QuoteManagementEstimates = loadable(() =>
  import('../../../views/Dashboard/QuoteManagementEstimates')
);

export default function route() {
  return [
    {
      path: accessRoute.PROVIDER_SALES_LEADERS.path,
      private: true,
      name: accessRoute.PROVIDER_SALES_LEADERS.name,
      key: accessRoute.PROVIDER_SALES_LEADERS.key,
      belongsToSidebar: false,
      element: <ProviderSalesLeaders />,
      ...accessRoute.PROVIDER_SALES_LEADERS,
    },
    {
      path: accessRoute.SERVICE_CATEGORY_REPORT.path,
      private: true,
      name: accessRoute.SERVICE_CATEGORY_REPORT.name,
      key: accessRoute.SERVICE_CATEGORY_REPORT.key,
      belongsToSidebar: false,
      element: <ServiceCategoryReport />,
      ...accessRoute.SERVICE_CATEGORY_REPORT,
    },
    {
      path: accessRoute.CUSTOMER_HEATMAP.path,
      private: true,
      name: accessRoute.CUSTOMER_HEATMAP.name,
      key: accessRoute.CUSTOMER_HEATMAP.key,
      belongsToSidebar: false,
      element: <CustomerHeatmap />,
      ...accessRoute.CUSTOMER_HEATMAP,
    },
    {
      path: accessRoute.QUOTE_MANAGEMENT_ESTIMATES.path,
      private: true,
      name: accessRoute.QUOTE_MANAGEMENT_ESTIMATES.name,
      key: accessRoute.QUOTE_MANAGEMENT_ESTIMATES.key,
      belongsToSidebar: false,
      element: <QuoteManagementEstimates />,
      ...accessRoute.QUOTE_MANAGEMENT_ESTIMATES,
    },
    {
      path: accessRoute.ONLINE_PROVIDERS.path,
      private: true,
      name: accessRoute.ONLINE_PROVIDERS.name,
      key: accessRoute.ONLINE_PROVIDERS.key,
      belongsToSidebar: false,
      element: <OnlineProvider />,
      ...accessRoute.ONLINE_PROVIDERS,
    },
  ];
}

export const accessRoute = {
  PROVIDER_SALES_LEADERS: {
    path: '/admin/provider-sales-leaders',
    name: 'Provider Sales Leaders',
    key: 'provider-sales-leaders',
    moduleKey: 'provider-sales-leaders',
    ignorePermission: true,
    action: 'view',
  },
  SERVICE_CATEGORY_REPORT: {
    path: '/admin/service-category-report',
    name: 'Service Category Report',
    key: 'service-category-report',
    moduleKey: 'service-category-report',
    ignorePermission: true,
    action: 'view',
  },
  CUSTOMER_HEATMAP: {
    path: '/admin/customer-heatmap',
    name: 'Customer Heatmap',
    key: 'customer-heatmap',
    moduleKey: 'customer-heatmap',
    ignorePermission: true,
    action: 'view',
  },
  QUOTE_MANAGEMENT_ESTIMATES: {
    path: '/admin/estimates',
    name: 'Quote Management - Estimates',
    key: 'customer-estimates',
    moduleKey: 'customer-estimates',
    ignorePermission: true,
    action: 'view',
  },
  ONLINE_PROVIDERS: {
    path: '/admin/online-provider',
    name: 'Online Providers',
    key: 'online-providers',
    moduleKey: 'online-providers',
    ignorePermission: true,
    action: 'view',
  },
};

export const getChildrenRoutes = () => {
  return route();
};
