import { APIRequest } from '../../helpers/index.helper';
import { LOCALIZATION_ENDPOINT } from '../../apiEndpoints/Localization/index.endpoint';
import { logger } from '../../utils';

export const getStateService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...LOCALIZATION_ENDPOINT.getStateApi,
      queryParams,
      // isAdminToken: true,
    };
    const res = await APIRequest(payload);
    if (type === 'all') return res?.data?.rows;
    return res;
  } catch (err) {
    logger(err);
  }
};
export const addStateService = async ({ body }) => {
  try {
    const payload = {
      ...LOCALIZATION_ENDPOINT.addState,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const deleteStateService = async ({ bodyData }) => {
  try {
    const payload = {
      ...LOCALIZATION_ENDPOINT.deleteState,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const getStateByIdService = async ({ resourceId }) => {
  try {
    const payload = {
      ...LOCALIZATION_ENDPOINT.getStateById(resourceId),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const updateStateStatus = async (body) => {
  try {
    const payload = {
      ...LOCALIZATION_ENDPOINT.updateStateStatusId,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const editStateService = async ({ body, id }) => {
  try {
    const payload = {
      ...LOCALIZATION_ENDPOINT.editState(id),
      bodyData: body,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
