import { PROMO_CODE_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';

export const getPromoCodeService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...PROMO_CODE_ENDPOINT?.getPromoCodeList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const deletePromoCodeService = async ({ bodyData }) => {
  try {
    const payload = {
      ...PROMO_CODE_ENDPOINT?.deletePromoCode,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const updatePromoCodeStatus = async (body) => {
  try {
    const payload = {
      ...PROMO_CODE_ENDPOINT?.promoCodeStatusUpdate,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const addPromoCodeService = async ({ body }) => {
  try {
    const payload = {
      ...PROMO_CODE_ENDPOINT?.addPromoCode,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const editPromoCodeService = async ({ body, id }) => {
  try {
    const payload = {
      ...PROMO_CODE_ENDPOINT?.editPromoCode(id),
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getPromoCodeDetailsService = async ({ id }) => {
  try {
    const payload = {
      ...PROMO_CODE_ENDPOINT?.getPromoCodeDetails(id),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
