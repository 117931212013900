export const validation = {
  required: 'This field is required',
  phoneNumber: 'Phone number is required.',
  // minPhoneNo: 'Phone number must be at least 10 digits',
  minPhoneNo: 'Please enter valid phone number',
  validNumber: 'Please enter valid number',
  firstName: 'First name must be less then and equal to 30 characters',
  lastName: 'Last name must be less then and equal to 30 characters',
  subjectValid: 'Subject must be less then and equal to 100 characters',
  subjectMax: 'Subject must not be greater than 30 Characters',
  subjectRequired: 'Subject is required',
  messageRequired: 'Message is required',
  messageMax: 'message must not be greater than 500 Characters',
};
