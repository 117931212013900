import { Outlet } from 'react-router-dom';
import { getActions } from '../../services/index.service';
import {
  BookingViewNewEquipment,
  NewEquipmentViewQuote,
} from '../../views/NewEquipment';
import NewEquipmentInstall from '../../views/NewEquipment/List';
// import {
//   NewEquipmentBookingLog,
//   NewEquipmentList,
// } from '../../views/NewEquipment';

export default function route() {
  return [
    {
      private: true,
      path: accessRoute.NEW_EQUIPMENT.path,
      name: 'New Equipment',
      key: accessRoute.NEW_EQUIPMENT.key,
      belongsToSidebar: true,
      icon: accessRoute.NEW_EQUIPMENT.icon,
      element: <NewEquipmentInstall />,
      ...accessRoute.NEW_EQUIPMENT,
    },
    {
      private: true,
      path: accessRoute.BOOKING_LOG.path,
      name: 'Booking Log',
      key: accessRoute.BOOKING_LOG.key,
      belongsToSidebar: false,
      icon: accessRoute.BOOKING_LOG.icon,
      element: <BookingViewNewEquipment />,
      ...accessRoute.BOOKING_LOG,
    },
    {
      private: true,
      path: accessRoute.VIEW_QUOTE.path,
      name: 'View Quote',
      key: accessRoute.VIEW_QUOTE.key,
      belongsToSidebar: false,
      icon: accessRoute.VIEW_QUOTE.icon,
      element: <NewEquipmentViewQuote />,
      ...accessRoute.VIEW_QUOTE,
    },
  ];
}

export const accessRoute = {
  NEW_EQUIPMENT: {
    path: '/admin/new-equipment',
    key: 'new-equipment',
    action: getActions('LIST').value,
    moduleKey: 'new-equipment',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-briefcase" />
      </span>
    ),
    order: 54,
  },
  BOOKING_LOG: {
    path: '/admin/new-equipment/booking-log/:id',
    key: 'new-equipment',
    action: getActions('LIST').value,
    moduleKey: 'new-equipment',
  },
  VIEW_QUOTE: {
    path: '/admin/new-equipment/booking-log/view-quote',
    key: 'new-equipment',
    action: getActions('LIST').value,
    moduleKey: 'new-equipment',
  },
};

export const getChildrenRoutes = () => {
  return route();
};
