let url = '/v1/admin/tag';
export const TAG_ENDPOINT = {
  getTagList: {
    url,
    method: 'GET',
  },
  deleteTag: {
    url,
    method: 'DELETE',
  },
  tagStatusUpdate: {
    url: `${url}/change-status`,
    method: 'PUT',
  },
  addTag: {
    url,
    method: 'POST',
  },
  editTag: (id) => ({
    url: `${url}/${id}`,
    method: 'PUT',
  }),
  getTagDetails: (id) => ({
    url: `${url}/${id}`,
    method: 'GET',
  }),
  customerTag: (id) => ({
    url: `/v1/admin/customer/customer-tag/${id}`,
    method: 'PUT',
  }),
  jobTag: (id) => ({
    url: `/v1/admin/customer/job-tag/${id}`,
    method: 'PUT',
  }),
  tagDetails: {
    url: '/v1/admin/customer/tags/details',
    method: 'GET',
  },
  customerBookingList: (id) => ({
    url: `/v1/admin/customer/booking/${id}`,
    method: 'GET',
  }),
  customerBookingTag: {
    url: '/v1/booking/tag',
    method: 'GET',
  },
};
