import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Tabs, PageContainerWrapper } from '../../components';
import { getApiData } from '../../hooks/useGetApi.hook';
import useSubmit from '../../hooks/useSubmit.hook';
// import { accessRoute } from '../../routes/AdminSetting';
import { ncaEditBreadcrumb } from '../../services/Columns';
import {
  EditApiList,
  editNcaTabContent,
  getApiList,
} from '../../services/Columns/ncaProfile.service';
import {
  addNcaCardService,
  getBusinessDetailsService,
  getCardListService,
  getCompanyProfileService,
  getPageService,
  putPageService,
} from '../../services/index.service';

import {
  getLocalStorage,
  logger,
  modalNotification,
  openInNewTab,
  setLocalStorage,
} from '../../utils';
import { accessRoute } from '../../routes/NcaLogReport';

// import { login } from '../../redux/slices/auth/authSlice';
import { useSelector } from 'react-redux';
import { getNCAProfileService } from '../../services/NCA/CreateProfile/ncaCreateProfile.service';
import { success } from 'toastr';
import { generatePath } from 'react-router-dom';
// import { login, updateUserDataAction } from '../../redux/index.redux';

function NcaProfile() {
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({});
  const [formData, setFormData] = useState([]);
  const [cardLoading, setCardLoading] = useState(false);
  const [cardList, setCardList] = useState([]);
  const { t } = useTranslation();
  const [defaultKey, setDefaultKey] = useState('ncaProfile');
  let breadcrumb = ncaEditBreadcrumb();
  let editApiObj = EditApiList();
  let getApiObj = getApiList();
  const id = formData?.id;
  const [companyProfile, setCompanyProfile] = useState({});
  const [companyLoad, setCompanyLoad] = useState(false);
  const [pageData, setPageData] = useState({});
  const [businessDetail, setBusinessDetails] = useState();
  const [bankDetail, setBankDetails] = useState();
  // const dispatch = useDispatch();
  // const user = useSelector((state) => state?.auth?.userData);
  // For form submit
  const [onSubmit, data, isLoading] = useSubmit({
    service: editApiObj[defaultKey],
    id,
  });
  const getProfile = async () => {
    try {
      const res = await getNCAProfileService();
      if (success) {
        setFormData(res?.data);
      }
    } catch (error) {
      logger(error);
    }
  };

  const onCardSubmit = async (values, resetForm) => {
    setCardLoading(true);
    try {
      let bodyData = { ...values };
      const { success, message } = await addNcaCardService({ bodyData });
      if (success) {
        modalNotification({
          type: 'success',
          message,
        });
        resetForm();
      }
    } catch (error) {
      logger(error);
    }
    getCardList();
    setCardLoading(false);
    getProfile();
  };

  const getCardList = async () => {
    try {
      let queryParams = {};
      const { data } = await getCardListService({ queryParams });
      setCardList(data?.rows);
    } catch (error) {
      logger(error);
    }
  };

  useEffect(() => {
    getCardList();
  }, []);

  const onPermissionSubmit = async (values) => {
    try {
      let bodyData = { ...values };

      const { success, message } = await putPageService({ bodyData });
      if (success) {
        modalNotification({ type: 'success', message });
      }
    } catch (error) {
      logger(error);
    }
    getAllPage();
    getProfile();
  };

  const getAllPage = async () => {
    setLoading(true);
    try {
      const { data } = await getPageService();
      setPageData(data);
    } catch (error) {
      logger(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllPage();
  }, []);

  // For tab content
  let tabContentData = editNcaTabContent({
    onSubmit,
    isLoading,
    defaultKey,
    id,
    setDefaultKey,
    setLoading,
    loading,
    details,
    formData: formData,
    onCardSubmit,
    cardLoading,
    cardList,
    getCardList,
    companyProfile,
    onPermissionSubmit,
    companyLoad,
    setCompanyLoad,
    pageData,
    getCompanyProfile,
    businessDetail,
    setBusinessDetails,
    bankDetail,
    setBankDetails,
    getProfile,
  });

  async function getBusiness(id) {
    try {
      const res = await getBusinessDetailsService(id);
      if (res?.data) {
        setDetails(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    if (formData?.id) getBusiness(formData?.id);
  }, []);

  useEffect(() => {
    if (defaultKey === 'ncaProfile' || defaultKey === 'businessDetails') {
      getApiData({
        service: getApiObj['ncaProfile'],
        setData: setFormData,
        queryParams: { id },
      });
    }
  }, [defaultKey, data]);

  useEffect(() => {
    let getTabsKey = getLocalStorage({ keyName: 'tabsKey' });
    if (getTabsKey) {
      setDefaultKey(getTabsKey);
    }
  }, []);

  useEffect(() => {
    if (defaultKey) {
      setLocalStorage({ keyName: 'tabsKey', data: defaultKey });
    }
  }, [defaultKey]);

  // For header Button
  // const btn = {
  //   extraButton: {},
  // };

  // async function getProfile() {
  //   try {
  //     const res = await getMyAccount();
  //     if (res?.data) {
  //       setProfile(res.data);
  //     }
  //   } catch (err) {
  //     logger(err);
  //   }
  // }
  // useEffect(() => {
  //   getProfile();
  // }, []);
  async function getCompanyProfile() {
    try {
      const { data } = await getCompanyProfileService();
      // dispatch(updateUserDataAction(data));
      setCompanyProfile(data);
      // dispatch(login({ ...user, ...data }));
    } catch (err) {
      logger(err);
    }
  }

  const handleGoToLogReportPage = () => {
    openInNewTab(
      generatePath(accessRoute.NCA_LOG_REPORT.path, {
        id,
      })
    );
  };

  const headerBtn = {
    multipleButton: [
      {
        title: t('text.nca.goLogReport'),
        handler: () => handleGoToLogReportPage(),
      },
    ],
  };

  useEffect(() => {
    getCompanyProfile();
  }, []);

  return (
    <>
      <PageContainerWrapper
        breadcrumb={breadcrumb}
        heading={t('text.common.editNCA')}
        btn={headerBtn}
        // extraBtnText={t('text.common.back')}
        extraBtnClass="btn btn-outline-light bg-white"
        isButtonPermission={true}
        // onExtraButtonHandleShow={() => backToPage(navigate, redirectUrl)}
      >
        <Tabs
          tabContent={tabContentData}
          tabsFor="table"
          activeKey={defaultKey}
          setActiveKey={setDefaultKey}
        />
      </PageContainerWrapper>
    </>
  );
}

export default NcaProfile;
