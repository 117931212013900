import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalLoader, PageContainerWrapper } from '../../../../components';
import { backToPage } from '../../../../utils';
import { getApiData } from '../../../../hooks/index.hook';
import { ncaDocumentBreadcrumb } from '../../../../services/Columns/nca.service';
import { useNavigate, useParams } from 'react-router-dom';
import { accessRoute } from '../../../../routes/AdminSetting';
import { getNCADocumentsService } from '../../../../services/index.service';
import DocumentPreview from '../../../../features/DocumentView/documentPreview.feature';
import { firstLetterCaps } from '../../../../utils/text.util';

function Document() {
  const { id } = useParams();
  const navigate = useNavigate();
  const redirectUrl = accessRoute.LIST_NCA.path;
  const { t } = useTranslation();
  const breadcrumb = ncaDocumentBreadcrumb();

  const [documentList, setDocumentList] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (id) {
      getApiData({
        queryParams: { userId: id },
        service: getNCADocumentsService,
        setData: setDocumentList,
        setIsLoading: setLoading,
      });
    }
  }, [id]);
  return (
    <>
      <PageContainerWrapper
        heading={`${t('text.nca.documentOf')} ${firstLetterCaps(
          documentList?.firstName
        )} ${firstLetterCaps(documentList?.lastName)}`}
        breadcrumb={breadcrumb}
        btnArray={['extraButton']}
        extraBtnText={t('text.common.back')}
        extraBtnClass="btn btn-outline-light bg-white"
        onExtraButtonHandleShow={() => backToPage(navigate, redirectUrl)}
      >
        {loading ? <GlobalLoader /> : <DocumentPreview data={documentList} />}
      </PageContainerWrapper>
    </>
  );
}

export default Document;
