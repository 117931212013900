/* eslint-disable */
const email =
  /^([\w-\.]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!Gmail.com)(?!Yahoo.com)(?!Hotmail.com)([\w-]+\.)+[\w-]{2,4})?$/;

const password =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+!=])[A-Za-z\d@#$%^&+!=]{6,15}$/;

// const phoneNumber = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const phoneNumber = /^[0-9]{10}$/;

const cardNumber =
  /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;

const cvv = /^[0-9]{3,4}$/;

const lastFourDigit = /^[0-9]{4}$/;

const alphaNumeric = /^[0-9]{4}$/;

const onlyAlphaNumeric = /^[a-zA-Z0-9]+$/;

const minNineCharacter = /^(?=.*\d).{0,9}$/;
const stateCode = /^[A-Z0-9]{2}$/;
const countryCode =
  /^(A(D|E|F|G|I|L|M|N|O|R|S|T|Q|U|W|X|Z)|B(A|B|D|E|F|G|H|I|J|L|M|N|O|R|S|T|V|W|Y|Z)|C(A|C|D|F|G|H|I|K|L|M|N|O|R|U|V|X|Y|Z)|D(E|J|K|M|O|Z)|E(C|E|G|H|R|S|T)|F(I|J|K|M|O|R)|G(A|B|D|E|F|G|H|I|L|M|N|P|Q|R|S|T|U|W|Y)|H(K|M|N|R|T|U)|I(D|E|Q|L|M|N|O|R|S|T)|J(E|M|O|P)|K(E|G|H|I|M|N|P|R|W|Y|Z)|L(A|B|C|I|K|R|S|T|U|V|Y)|M(A|C|D|E|F|G|H|K|L|M|N|O|Q|P|R|S|T|U|V|W|X|Y|Z)|N(A|C|E|F|G|I|L|O|P|R|U|Z)|OM|P(A|E|F|G|H|K|L|M|N|R|S|T|W|Y)|QA|R(E|O|S|U|W)|S(A|B|C|D|E|G|H|I|J|K|L|M|N|O|R|T|V|Y|Z)|T(C|D|F|G|H|J|K|L|M|N|O|R|T|V|W|Z)|U(A|G|M|S|Y|Z|K)|V(A|C|E|G|I|N|U)|W(F|S)|Y(E|T)|Z(A|M|W))/;
const countryPhoneCode = /^(\+?\d{1,3}|\d{1,4})$/;

const urlRegex =
  /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;

// zip code temporary
const zipCodeRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;
const zipCodeReg = /^[0-9]{4,10}?$/;

const accountNumberRegex = '[0-9]{9,18}';

// const onlyNumber = /^[0-9]*$/;
const onlyNumber = /[+-]?([0-9]*[.])?[0-9]+/;

const onlyIntNumber = /^-?\d+$/;

const noWhiteSpace = /^\S*$/;

const twoDecimalPoint = /^\d+(\.\d{0,2})?$/;
const numberOnly = /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/;

const month = /^(0?[1-9]|1[012])$/;

// const countryName = /^[a-zA-Z]+$/;
const countryName = /^[A-Za-z\s]*$/;

// const urlRegexLink = /(https?:\/\/[^\s]+)/g;
const urlRegexLink =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}|https?:\/\/localhost[:?\d]*(?:\/[^\s]*)?)/gi;

export const Regex = {
  email,
  password,
  phoneNumber,
  cardNumber,
  cvv,
  lastFourDigit,
  minNineCharacter,
  countryCode,
  countryPhoneCode,
  stateCode,
  urlRegex,
  alphaNumeric,
  zipCodeRegex,
  zipCodeReg,
  accountNumberRegex,
  onlyNumber,
  noWhiteSpace,
  twoDecimalPoint,
  numberOnly,
  month,
  countryName,
  onlyAlphaNumeric,
  onlyIntNumber,
  urlRegexLink,
};
