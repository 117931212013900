import React from 'react';
import { Form, Checkbox as AntCheckbox } from 'antd';

function CheckBox({
  element,
  id,
  value,
  extraClassName = '',
  label = false,
  group = false,
  labelText,
  name,
  defaultChecked = false,
  withoutChecked = true,
  ...rest
}) {
  // const [field, meta] = useField(name);
  // const onChange = (e) => {};

  return (
    <>
      {group ? (
        <>
          <AntCheckbox className={extraClassName} id={id} value={value}>
            {element}
          </AntCheckbox>
          {label && (
            <label className="custom-control-label" htmlFor={id}>
              {labelText}
            </label>
          )}
        </>
      ) : withoutChecked ? (
        <Form.Item valuePropName="checked" {...rest}>
          <AntCheckbox
            id={id}
            name={name}
            // onChange={onChange}
            defaultChecked={defaultChecked}
          >
            {element}
          </AntCheckbox>
        </Form.Item>
      ) : (
        <Form.Item valuePropName="checked" {...rest}>
          <AntCheckbox
            id={id}
            name={name}
            checked={rest?.checked}
            defaultChecked={defaultChecked}
          >
            {element}
          </AntCheckbox>
        </Form.Item>
      )}
    </>
  );
}

export default CheckBox;
