import { Form, Input } from 'antd';
import { useField } from 'formik';

const { TextArea } = Input;

function AntTextArea({
  Children,
  label,
  name,
  extraClassName = '',
  formik,
  ...rest
}) {
  const [field, meta] = useField(name);
  const config = { ...field, ...rest };

  if (meta && meta.touched && meta.error) {
    config.error = true;
    config.helperText = meta.error;
  }

  function onChange(e) {
    // e.stopPropagation();
    formik?.handleChange(e);
  }

  return (
    <Form.Item
      label={label ? label : ''}
      help={meta?.error && meta?.touched ? meta.error : ''}
      validateStatus={config.error ? 'error' : 'success'}
    >
      <textarea
        onChange={onChange}
        {...field}
        {...rest}
        className={`form-control ${extraClassName && extraClassName}`}
      >
        {Children}
      </textarea>
    </Form.Item>
  );
}

export default AntTextArea;
