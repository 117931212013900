let url = '/v1/admin/page';
export const PAGE_ENDPOINT = {
  getPageList: {
    url,
    method: 'GET',
  },
  viewPage: (id) => ({
    url: `${url}/${id}`,
    method: 'GET',
  }),
  editPage: (id) => ({
    url: `${url}/${id}`,
    method: 'PUT',
  }),
};
