import i18next from 'i18next';
import { accessRoute } from '../../routes/CustomerApp/home';
import { accessRoute as accessStoreRoute } from '../../routes/Auth/store';

export const customerBookAppointmentBreadcrumb = () => {
  return [
    {
      path: accessRoute.HOME.path,
      name: i18next.t('text.common.home'),
    },
    {
      path: '#',
      name: i18next.t('text.customerService.serviceProviderList'),
    },
  ];
};

export const customerChangePasswordBreadcrumb = () => {
  return [
    {
      path: accessRoute.HOME.path,
      name: i18next.t('text.common.home'),
    },
    {
      path: '#',
      name: i18next.t('text.changePassword.pageTitle'),
    },
  ];
};

export const customerFinancingBreadcrumb = () => {
  return [
    {
      path: accessRoute.HOME.path,
      name: i18next.t('text.common.home'),
    },
    {
      path: '#',
      name: i18next.t('text.customerFinancing.title'),
    },
  ];
};

export const customerPaymentBreadcrumb = () => {
  return [
    {
      path: accessRoute.HOME.path,
      name: i18next.t('text.common.home'),
    },
    {
      path: '#',
      name: i18next.t('text.customerPayment.title'),
    },
  ];
};

export const customerProfileBreadcrumb = () => {
  return [
    {
      path: accessRoute.HOME.path,
      name: i18next.t('text.common.home'),
    },
    {
      path: '#',
      name: i18next.t('text.customerAppProfile.title'),
    },
  ];
};

export const customerTipOfTheMonthBreadcrumb = () => {
  return [
    {
      path: accessRoute.HOME.path,
      name: i18next.t('text.common.home'),
    },
    {
      path: '#',
      name: i18next.t('text.customerTipOfTheMonth.title'),
    },
  ];
};

export const customerAboutUsBreadcrumb = (isStore) => {
  return [
    {
      path: isStore ? accessStoreRoute.STORE_LOGIN.path : accessRoute.HOME.path,
      name: i18next.t('text.common.home'),
    },
    {
      path: '#',
      name: i18next.t('text.common.aboutUs'),
    },
  ];
};

export const customerContactUsBreadcrumb = (isStore) => {
  return [
    {
      path: isStore ? accessStoreRoute.STORE_LOGIN.path : accessRoute.HOME.path,
      name: i18next.t('text.common.home'),
    },
    {
      path: '#',
      name: i18next.t('text.common.contactUs'),
    },
  ];
};

export const customerFaqsBreadcrumb = (isStore) => {
  return [
    {
      path: isStore ? accessStoreRoute.STORE_LOGIN.path : accessRoute.HOME.path,
      name: i18next.t('text.common.home'),
    },
    {
      path: '#',
      name: i18next.t('text.customerHome.faq'),
    },
  ];
};

export const customerHowItWorksBreadcrumb = (isStore) => {
  return [
    {
      path: isStore ? accessStoreRoute.STORE_LOGIN.path : accessRoute.HOME.path,
      name: i18next.t('text.common.home'),
    },
    {
      path: '#',
      name: i18next.t('text.common.howItWorks'),
    },
  ];
};

export const customerPrivacyPolicyBreadcrumb = (isStore) => {
  return [
    {
      path: isStore ? accessStoreRoute.STORE_LOGIN.path : accessRoute.HOME.path,
      name: i18next.t('text.common.home'),
    },
    {
      path: '#',
      name: i18next.t('text.common.privacyPolicy'),
    },
  ];
};

export const customerTermsConditionsBreadcrumb = (isStore) => {
  return [
    {
      path: isStore ? accessStoreRoute.STORE_LOGIN.path : accessRoute.HOME.path,
      name: i18next.t('text.common.home'),
    },
    {
      path: '#',
      name: i18next.t('text.common.termsAndConditions'),
    },
  ];
};

export const customerServiceTrackBreadcrumb = () => {
  return [
    {
      path: accessRoute.HOME.path,
      name: i18next.t('text.common.home'),
    },
    {
      path: '#',
      name: i18next.t('text.common.serviceTrack'),
    },
  ];
};

export const customerEditProfileBreadcrumb = () => {
  return [
    {
      path: accessRoute.HOME.path,
      name: i18next.t('text.common.home'),
    },
    {
      path: accessRoute.PROFILE.path,
      name: i18next.t('text.customerAppProfile.title'),
    },
    {
      path: '#',
      name: i18next.t('text.customerAppProfile.editProfile'),
    },
  ];
};

export const customerNotificationBreadcrumb = () => {
  return [
    {
      path: accessRoute.HOME.path,
      name: i18next.t('text.common.home'),
    },
    {
      path: '#',
      name: i18next.t('text.common.notification'),
    },
  ];
};
