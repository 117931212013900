import { SCHEDULED_BOOKING_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';

export const getScheduleListService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...SCHEDULED_BOOKING_ENDPOINT?.getList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};
export const getGraphScheduleBooking = async ({ queryParams }) => {
  try {
    const payload = {
      ...SCHEDULED_BOOKING_ENDPOINT?.getGraph,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows) {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getScheduleInvoiceDetailsService = async (id) => {
  try {
    const payload = {
      ...SCHEDULED_BOOKING_ENDPOINT.getInvoiceDetails(id),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getSendQuoteService = async (bookingId) => {
  try {
    const payload = {
      ...SCHEDULED_BOOKING_ENDPOINT.getSendQuote(bookingId),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const putAdminBookingCancel = async ({
  bookingId,
  declineReason = '',
}) => {
  try {
    const payload = {
      ...SCHEDULED_BOOKING_ENDPOINT?.adminBookingCancel(bookingId),
      bodyData: { declineReason },
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
