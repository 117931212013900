import ServiceProviderDetailsMiles from '../../views/CustomerApp/ScheduleServiceProvider/ServiceProviderDetailsMiles';
import ScheduleProviderList from '../../views/CustomerApp/ScheduleServiceProvider/ScheduleProviderList';
import ProviderContactUs from '../../views/CustomerApp/ProviderContactUs';
export default function route() {
  return [
    {
      path: accessRoute.SCHEDULE_PROVIDER_LIST.path,
      private: true,
      name: accessRoute.SCHEDULE_PROVIDER_LIST.name,
      key: accessRoute.SCHEDULE_PROVIDER_LIST.key,
      belongsToSidebar: false,
      icon: accessRoute.SCHEDULE_PROVIDER_LIST.icon,
      element: <ScheduleProviderList />,
    },
    {
      path: accessRoute.SERVICE_PROVIDER_DETAILS_MILES.path,
      private: true,
      name: accessRoute.SERVICE_PROVIDER_DETAILS_MILES.name,
      key: accessRoute.SERVICE_PROVIDER_DETAILS_MILES.key,
      belongsToSidebar: false,
      icon: accessRoute.SERVICE_PROVIDER_DETAILS_MILES.icon,
      element: <ServiceProviderDetailsMiles />,
    },
    {
      path: accessRoute.SERVICE_PROVIDER_CONTACT_US.path,
      private: true,
      name: accessRoute.SERVICE_PROVIDER_CONTACT_US.name,
      key: accessRoute.SERVICE_PROVIDER_CONTACT_US.key,
      belongsToSidebar: false,
      icon: accessRoute.SERVICE_PROVIDER_CONTACT_US.icon,
      element: <ProviderContactUs />,
    },
  ];
}

export const accessRoute = {
  SCHEDULE_PROVIDER_LIST: {
    path: '/customer/schedule-provider-list',
    name: 'Schedule Provider Map',
    key: 'schedule-provider-map',
    for: 'customer',
    className: 'gg-my-bookings',
    icon: <em className="gg-my-bookings"></em>,
  },

  SERVICE_PROVIDER_DETAILS_MILES: {
    path: '/customer/service-provider-profile-details',
    name: 'Service Provider Detail',
    key: 'service-provider-detail',
    for: 'customer',
    className: 'gg-my-bookings',
    icon: <em className="gg-my-bookings"></em>,
  },
  SERVICE_PROVIDER_CONTACT_US: {
    path: '/customer/service-provider-contact-us/:id',
    name: 'Service Provider Contact Us',
    key: 'service-provider-contact-us',
    for: 'customer',
    className: 'gg-my-bookings',
    icon: <em className="gg-my-bookings"></em>,
  },
};

export const getChildrenRoutes = () => {
  return route();
};
