import React, { useState, useEffect } from 'react';
import { useRef } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import moment from 'moment';
import {
  Breadcrumb,
  CommonButton,
  GlobalLoader,
  ImageElement,
  ModalWrapper,
  NoDataFound,
} from '../../../components';
import {
  // addQuoteService,
  getBookingDetailsById,
  getQuoteOptionListService,
  putCustomerBookingApprove,
  putCustomerBookingCancel,
} from '../../../services/CustomerApp/index.service';
import { Columns } from '../../../services/index.service';
import {
  generateStarRating,
  handlePrint,
  logger,
  modalNotification,
  // setLocalStorage,
  // arrayEqual,
} from '../../../utils';

import ApproveModal from './ApproveModal';
import DeclineModal from './DeclineModal';
import axios from 'axios';
import { COMMON_ENDPOINT } from '../../../apiEndpoints/index.endpoint';
import { firstLetterCaps } from '../../../utils/text.util';
import { Link } from 'react-router-dom';

import { checkValidPrice } from '../../../components/UIElements/Formatter';
import RequestDetailsModal from './requestDetailsModal';
import { useSelector } from 'react-redux';

function ApproveQuote() {
  const userType = useSelector((state) => state?.auth?.userData?.Role?.type);
  const { bookingId } = useParams();
  const data = {};
  const { t } = useTranslation();
  const breadcrumb = Columns.requestViewDetailsBreadcrumb();
  const location = useLocation();

  const [spDiscount, setSpDiscount] = useState(0);
  const [optionTotalAmount, setOptionTotalAmount] = useState(0);

  const [quotePrice, setQuotePrice] = useState({});
  const [quoteOptionPrice, setQuoteOptionPrice] = useState({});

  const [quoteDiscountedPrice, setQuoteDiscountedPrice] = useState({});

  const [quoteTotalAmount, setQuoteTotalAmount] = useState(0);

  const [state, setState] = useState({
    isLoading: false,
    details: [],
    showModal: '',
    modalHeading: '',
    reason: '',
    isSigned: false,
    customerSignature: '',
    editQuote: false,

    quoteData: [],
    quoteAllId: {},
    optionAllId: {},
    optionalItemIdObj: {},
    quoteOrderIdObj: {},
    lineItemIdArray: [],
    lineItemAllId: [],

    allQuoteCost: {},
    allOptionQuoteCost: {},
    allOptionCost: {},
    allLineItemCost: {},
    lineItemTotalCost: 0,
    quoteTotalCost: 0,
    optionalTotalCost: 0,
    equipmentIdArray: {},
    equipmentAllIdArray: [],
    equipmentItemTotalCost: 0,
    allEquipmentItemCost: {},
    modalKey: '',
    modalData: '',
    monthlyTotalCostObj: {},
    monthlyTotalCost: 0,
    oldQuoteLineTotalCost: 0,
    discountObj: {},
    totalDiscount: 0,
    rebateObj: {},
    quoteSelectID: [],
    discountQuoteObj: {},
    discountQuoteListObj: {},
    monthlyEquipmentTotalCost: 0,
    monthlyEquipmentTotalCostObj: {},
    monthlyLineItemTotalCost: 0,
    monthlyLineItemTotalCostObj: {},
    equipmentAllIdObj: {},
    equipmentAllSelectedIdArray: [],
    equOptionalItemIdObj: {},
    equOptionAllId: {},
    equOrderIdObj: {},
    allEquOptionCost: {},
    equOptionalTotalCost: 0,
    equAllOptionQuoteCost: {},
    allCheckEquOptionalIdObj: {},
    allCheckEquOptionalTotalCost: 0,
    allQuoteDiscountedCost: {},
  });

  let {
    editQuote,
    details,
    quoteData,
    // optionalItemIdObj,
    quoteOrderIdObj,
    // quoteAllId,
    // optionAllId,
    // lineItemIdArray,
    // lineItemAllId,
    allQuoteCost,
    // allOptionQuoteCost,
    allOptionCost,
    // allLineItemCost,
    lineItemTotalCost,
    quoteTotalCost,
    // optionalTotalCost,
    // equipmentIdArray,
    // equipmentAllIdArray,
    equipmentItemTotalCost,
    // allEquipmentItemCost,
    // monthlyTotalCostObj,
    // monthlyTotalCost,
    // oldQuoteLineTotalCost,
    // discountObj,
    totalDiscount,
    rebateObj,
    quoteSelectID,
    // discountQuoteObj,
    discountQuoteListObj,
    monthlyEquipmentTotalCost,
    // monthlyEquipmentTotalCostObj,
    // monthlyLineItemTotalCost,
    // monthlyLineItemTotalCostObj,
    // equipmentAllIdObj,
    // equipmentAllSelectedIdArray,
    // equOptionalItemIdObj,
    // equOptionAllId,
    // equOrderIdObj,
    // allEquOptionCost,
    equOptionalTotalCost,
    // equAllOptionQuoteCost,
    // allCheckEquOptionalIdObj,
    // allCheckEquOptionalTotalCost,
    allQuoteDiscountedCost,
  } = state;

  let sigPad = useRef();

  const handleClose = () => {
    setState({
      ...state,
      showModal: '',
    });
  };

  const getRequestViewDetails = async (e) => {
    try {
      setState({
        ...state,
        isLoading: true,
      });
      let obj = {};
      const response = await getBookingDetailsById(
        location?.state?.bookingId || bookingId
      );
      if (response?.success) {
        obj = {
          ...obj,
          details: response?.data ?? [],
          isLoading: false,
        };
      }

      // for quote
      const quoteResponse = await getQuoteOptionListService(
        location?.state?.bookingId || bookingId,
        {
          responseType: 'details',
        }
      );
      if (quoteResponse?.success) {
        let quoteTotalAmount = quoteResponse?.data?.quote?.length
          ? quoteResponse?.data?.quote
            .map((e) => e?.admin_material_total_amount)
            ?.reduce(
              (accumulator, currentValue) =>
                parseFloat(accumulator) + parseFloat(currentValue)
            )
          : 0;

        let appliedTotalDiscount = quoteResponse?.data?.quote?.length
          ? quoteResponse?.data?.quote
            ?.map((e) => e?.appliedDiscount)
            ?.reduce(
              (accumulator, currentValue) =>
                parseFloat(accumulator) + parseFloat(currentValue)
            )
          : 0;

        let optionTotalAmount = quoteResponse?.data?.quote?.length
          ? quoteResponse?.data?.quote
            .map((e) => e?.optionalItemForBooking)
            .flat()?.length &&
          quoteResponse?.data?.quote
            .map((e) => e?.optionalItemForBooking)
            .flat()
            ?.map((i) => i?.cost)
            ?.reduce(
              (accumulator, currentValue) =>
                parseFloat(accumulator) + parseFloat(currentValue)
            )
          : 0;

        setOptionTotalAmount(optionTotalAmount);

        let allLineItemTotalCost = response?.data?.BookingLineItems?.length
          ? response?.data?.BookingLineItems.map(
            (id) => (Number(id?.qty) || 1) * parseFloat(id?.unitPrice)
          )?.reduce(
            (accumulator, currentValue) =>
              parseFloat(accumulator) + parseFloat(currentValue)
          )
          : 0;

        let allEquipmentTotalCost = quoteResponse?.data?.otherEquipment?.length
          ? quoteResponse?.data?.otherEquipment
            ?.map((item) => item?.cost)
            ?.reduce(
              (accumulator, currentValue) =>
                parseFloat(accumulator) + parseFloat(currentValue)
            )
          : 0;

        let equOptionalAllCost = quoteResponse?.data?.otherEquipment?.map(
          (e) => e?.otherEquipment
        )?.length
          ? quoteResponse?.data?.otherEquipment
            ?.map((e) => e?.otherEquipment)
            ?.flat()?.length
            ? quoteResponse?.data?.otherEquipment
              ?.map((e) => e?.otherEquipment)
              ?.flat()
              ?.map((i) => i?.cost)
              ?.reduce(
                (accumulator, currentValue) =>
                  parseFloat(accumulator) + parseFloat(currentValue)
              )
            : 0
          : 0;

        let monthlySelectedEquipmentTotalCost =
          parseFloat(
            (parseFloat(equOptionalAllCost) +
              parseFloat(allEquipmentTotalCost)) /
            100
          ) * parseFloat(quoteResponse?.data?.financeOption?.paymentFactor);

        let spTotalDiscount =
          quoteResponse?.data?.discountList?.length &&
          quoteResponse?.data?.discountList?.filter((e) => !e?.bookingQuoteId)
            ?.length &&
          quoteResponse?.data?.discountList
            ?.filter((e) => !e?.bookingQuoteId)
            .map((item) => item?.cost)
            ?.reduce(
              (accumulator, currentValue) =>
                parseFloat(accumulator) + parseFloat(currentValue)
            );

        obj = {
          ...obj,
          quoteData: quoteResponse?.data ?? [],
          lineItemTotalCost: allLineItemTotalCost,
          quoteTotalCost: quoteTotalAmount,
          totalDiscount: appliedTotalDiscount,
          equipmentItemTotalCost: allEquipmentTotalCost,
          equOptionalTotalCost: equOptionalAllCost,
          monthlyEquipmentTotalCost: monthlySelectedEquipmentTotalCost,
        };
        setSpDiscount(spTotalDiscount || 0);
      }

      setState({
        ...state,
        ...obj,
        ...(e === 'approve' && {
          isLoading: false,
          showModal: '',
          editQuote: true,
          // approved: false,
        }),
        ...(e === 'decline' && {
          isLoading: false,
          showModal: '',
          editQuote: true,
        }),
      });
    } catch (error) {
      setState({
        ...state,
        isLoading: false,
      });
    }
  };

  useEffect(() => {
    getRequestViewDetails();
  }, []);

  const handleSubmitDecline = async (reason) => {
    if (!reason) {
      modalNotification({
        type: 'error',
        message: 'Please submit decline reason.',
      });
      return;
    }
    try {
      setState({
        ...state,
        isLoading: true,
      });
      const response = await putCustomerBookingCancel({
        bookingId: location?.state?.bookingId || bookingId,
        declineReason: reason,
      });
      if (response.success) {
        modalNotification({ type: 'success', message: response.message });
        getRequestViewDetails('decline');
      }
    } catch (error) {
      logger(error);
      setState({
        ...state,
        isLoading: false,
      });
    }
  };

  const handleSubmitApprove = async () => {
    setState({
      ...state,
      isLoading: true,
    });
    try {
      let res = await putCustomerBookingApprove({
        customerSignature: state?.customerSignature,
        bookingId: location?.state?.bookingId || bookingId,
      });
      if (res?.success) {
        getRequestViewDetails('approve');
        modalNotification({
          type: 'success',
          message: 'Quote Approve Successfully',
        });
      }
    } catch (error) {
      setState({
        ...state,
        isLoading: false,
      });
    }
  };
  const modalObj = {
    decline: (
      <DeclineModal
        handleClose={handleClose}
        handleSubmit={(reason) => handleSubmitDecline(reason)}
        isLoading={state?.isLoading}
      />
    ),
    approve: (
      <ApproveModal
        handleClose={handleClose}
        handleSubmit={handleSubmitApprove}
        isLoading={state?.isLoading}
      />
    ),

    requestDetails: (
      <RequestDetailsModal
        handleClose={handleClose}
        handleSubmit={handleSubmitApprove}
        isLoading={state?.isLoading}
        type={state?.modalKey}
        modalData={state?.modalData}
      />
    ),

    memberShip: (
      <>
        {state?.modalData?.membershipBenefitAvailable && (
          <div className="listService">
            {(state?.modalData?.membershipType === "service_category" || state?.modalData?.membershipType === "combo") &&
              <div className="listService_items d-flex justify-content-between">
                {quoteData?.booking?.ServiceCategory?.isSalesQuote === 1 ? t('text.memberShip.quoteDiscount') : t('text.common.serviceDiscount')}
                <span>
                  {checkValidPrice(
                    state?.modalData?.serviceMembershipBenefit
                  )}
                </span>
              </div>
            }
            {(state?.modalData?.membershipType === "quote") &&
              <div className="listService_items d-flex justify-content-between">
                {t('text.common.quoteMembershipDiscount')}
                <span>
                  {checkValidPrice(
                    state?.modalData?.serviceMembershipBenefit
                  )}
                </span>
              </div>
            }
            {(state?.modalData?.membershipType === "line_item" || state?.modalData?.membershipType === "combo") &&
              <div className="listService_items d-flex justify-content-between">
                {t('text.common.lineItemDiscount')}
                <span>
                  {checkValidPrice(
                    state?.modalData?.lineItemMembershipBenefit
                  )}
                </span>
              </div>
            }
          </div>
        )}
      </>
    ),
    promoCodeInfo: (
      <>
        <p>{t('text.ratingReview.promoCodeInfoIconDetails')}</p>
      </>
    )
  };
  const isEmpty = () => {
    if (sigPad?.current?.isEmpty && sigPad?.current?.isEmpty()) {
      return true;
    } else {
      return false;
    }
  };

  const handleSignature = async () => {
    let url = COMMON_ENDPOINT.uploadPublicImageFile('image', 'user');
    const signatureBase64 = sigPad?.current
      .getTrimmedCanvas()
      .toDataURL('image/png');

    function dataURLtoFile(base64, filename) {
      var arr = base64.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = window.atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }
    let signatureImageFile = dataURLtoFile(signatureBase64, 'signature.png');

    let formData = new FormData();
    formData.append('file', signatureImageFile);

    let { data } = await axios.post(url?.url, formData, {
      headers: {
        accept: 'application/json',
        'Accept-Language': 'en-US,en;q=0.8',
        'Content-Type': `multipart/form-data`,
      },
    });
    if (data?.success) {
      setState({
        ...state,
        customerSignature: data?.data?.basePath,
      });
    }
  };

  // const handleNext = async (status) => {
  //   if (status === 'edit') {
  //     setState({
  //       ...state,
  //       editQuote: false,
  //     });
  //     setLocalStorage({
  //       keyName: 'optionList',
  //       data: {
  //         quoteOrderIdObj: {},
  //         optionalItemIdObj: {},
  //         lineItemIdArray: [],
  //       },
  //     });
  //     return;
  //   }
  //   let quoteIdLength =
  //     quoteOrderIdObj &&
  //     Object.keys(quoteOrderIdObj)
  //       .map((e) => quoteOrderIdObj[e].join())
  //       .filter((i) => i);

  //   let checkEquipmentEmpty =
  //     Object.keys(equipmentIdArray).length &&
  //     Object.keys(equipmentIdArray)
  //       .map((e) => equipmentIdArray[e])
  //       .flat();

  //   if (quoteIdLength?.length === 0 && checkEquipmentEmpty === 0) {
  //     modalNotification({
  //       type: 'error',
  //       message: 'Please select at least one quote item',
  //     });
  //     return;
  //   }
  //   setState({
  //     ...state,
  //     isLoading: true,
  //   });
  //   try {
  //     let obj = {};
  //     let optionList = [];

  //     quoteOrderIdObj &&
  //       Object.keys(quoteOrderIdObj).forEach((quoteItem) => {
  //         obj = {
  //           quoteOrderId: quoteOrderIdObj[quoteItem].join(),
  //           optionalItemId:
  //             optionalItemIdObj[quoteItem] &&
  //             optionalItemIdObj[quoteItem]?.length
  //               ? optionalItemIdObj[quoteItem]
  //               : [],
  //         };
  //         optionList.push(obj);
  //       });

  //     let otherEquipmentAllSelectedId = Object.keys(equipmentIdArray)?.length
  //       ? Object.keys(equipmentIdArray)
  //           ?.map((e) => equipmentIdArray[e])
  //           .flat()
  //       : [];

  //     let selectedOptionItem = equOptionalItemIdObj;

  //     let optionObj = {};
  //     Object.keys(selectedOptionItem)?.length &&
  //       Object.keys(selectedOptionItem)?.forEach((e) => {
  //         if (otherEquipmentAllSelectedId.includes(Number(e))) {
  //           optionObj = {
  //             [e]: selectedOptionItem[e],
  //           };
  //         }
  //       });

  //     let otherEquipmentOptionAllSelectedId = Object.keys(optionObj)?.length
  //       ? Object.keys(optionObj)
  //           ?.map((e) => optionObj[e])
  //           ?.flat()
  //       : [];

  //     let bodyData = {
  //       optionList,
  //       lineItems: lineItemIdArray,
  //       otherEquipment: [
  //         ...otherEquipmentAllSelectedId,
  //         ...otherEquipmentOptionAllSelectedId,
  //       ],
  //     };

  //     let res = await addQuoteService({
  //       bookingId: location?.state?.bookingId || bookingId,
  //       bodyData,
  //     });
  //     if (res?.success) {
  //       modalNotification({
  //         type: 'success',
  //         message: 'Quote item selected successfully',
  //       });

  //       setState({
  //         ...state,
  //         editQuote: true,
  //         isLoading: false,
  //         equOptionalItemIdObj: optionObj,
  //       });
  //     }
  //   } catch (error) {
  //     setState({
  //       ...state,
  //       isLoading: false,
  //     });
  //   }
  // };

  // for quote id

  // let quoteIdObj = quoteOrderIdObj;
  // let quoteAddCost = quoteTotalCost;
  // let monthlyAddCost = monthlyTotalCost;
  // let quoteDiscount = totalDiscount;

  // const handleQuoteChange = (checkedValues, key) => {
  //   quoteIdObj = {
  //     ...quoteIdObj,
  //     [key]: checkedValues,
  //   };

  //   let itemName = '';
  //   let quoteAllIdObj = '';
  //   quoteAllId &&
  //     Object.keys(quoteAllId)?.length &&
  //     Object.keys(quoteAllId)
  //       .map((e) => e)
  //       .forEach((item) => {
  //         if (itemName === item.replace(/[0-9]/g, '').trim()) {
  //           quoteAllIdObj = {
  //             ...quoteAllIdObj,
  //             [item.replace(/[0-9]/g, '').trim()]: [
  //               ...quoteAllIdObj[item.replace(/[0-9]/g, '').trim()],
  //               ...quoteAllId[item],
  //             ],
  //           };
  //         } else {
  //           quoteAllIdObj = {
  //             ...quoteAllIdObj,
  //             [item.replace(/[0-9]/g, '').trim()]: quoteAllId[item],
  //           };
  //         }
  //         itemName = item.replace(/[0-9]/g, '').trim();
  //       });

  //   let checkedItemName = '';
  //   let checkedQuoteIdObj = '';
  //   quoteIdObj &&
  //     Object.keys(quoteIdObj)?.length &&
  //     Object.keys(quoteIdObj)
  //       .map((e) => e)
  //       .forEach((item) => {
  //         if (checkedItemName === item.replace(/[0-9]/g, '').trim()) {
  //           checkedQuoteIdObj = {
  //             ...checkedQuoteIdObj,
  //             [item.replace(/[0-9]/g, '').trim()]: [
  //               ...checkedQuoteIdObj[item.replace(/[0-9]/g, '').trim()],
  //               ...quoteIdObj[item],
  //             ],
  //           };
  //         } else {
  //           checkedQuoteIdObj = {
  //             ...checkedQuoteIdObj,
  //             [item.replace(/[0-9]/g, '').trim()]: quoteIdObj[item],
  //           };
  //         }
  //         checkedItemName = item.replace(/[0-9]/g, '').trim();
  //       });

  //   let checkAllQuoteId = arrayEqual(
  //     quoteAllIdObj[key.replace(/[0-9]/g, '').trim()],
  //     checkedQuoteIdObj[key.replace(/[0-9]/g, '').trim()]
  //   );

  //   let itemOptionName = '';
  //   let quoteOptionAllIdObj = '';
  //   optionAllId &&
  //     Object.keys(optionAllId)?.length &&
  //     Object.keys(optionAllId)
  //       .map((e) => e)
  //       .forEach((item) => {
  //         if (itemOptionName === item.replace(/[0-9]/g, '')) {
  //           quoteOptionAllIdObj = {
  //             ...quoteOptionAllIdObj,
  //             [item.replace(/[0-9]/g, '')]: [
  //               ...quoteOptionAllIdObj[item.replace(/[0-9]/g, '')],
  //               ...optionAllId[item],
  //             ],
  //           };
  //         } else {
  //           quoteOptionAllIdObj = {
  //             ...quoteOptionAllIdObj,
  //             [item.replace(/[0-9]/g, '')]: optionAllId[item],
  //           };
  //         }
  //         itemOptionName = item.replace(/[0-9]/g, '');
  //       });

  //   let checkedOptionItemName = '';
  //   let checkedQuoteOptionIdObj = '';
  //   optionalItemIdObj &&
  //     Object.keys(optionalItemIdObj)?.length &&
  //     Object.keys(optionalItemIdObj)
  //       .map((e) => e)
  //       .forEach((item) => {
  //         if (checkedOptionItemName === item.replace(/[0-9]/g, '')) {
  //           checkedQuoteOptionIdObj = {
  //             ...checkedQuoteOptionIdObj,
  //             [item.replace(/[0-9]/g, '')]: [
  //               ...checkedQuoteOptionIdObj[item.replace(/[0-9]/g, '')],
  //               ...optionalItemIdObj[item],
  //             ],
  //           };
  //         } else {
  //           checkedQuoteOptionIdObj = {
  //             ...checkedQuoteOptionIdObj,
  //             [item.replace(/[0-9]/g, '')]: optionalItemIdObj[item],
  //           };
  //         }
  //         checkedOptionItemName = item.replace(/[0-9]/g, '');
  //       });

  //   let checkAllOptionId =
  //     optionAllId && optionAllId[key].length
  //       ? arrayEqual(
  //           quoteOptionAllIdObj[key.replace(/[0-9]/g, '')],
  //           checkedQuoteOptionIdObj[key.replace(/[0-9]/g, '')]
  //         )
  //       : true;

  //   setCheckAll({
  //     ...checkAll,
  //     [key.replace(/[0-9]/g, '').trim()]:
  //       checkAllQuoteId && checkAllOptionId ? true : false,
  //   });

  //   quoteAddCost = Object.keys(quoteIdObj).length
  //     ? Object.keys(quoteIdObj)
  //         .map((e) => quoteIdObj[e].join() || 0)
  //         .filter((z) => z)
  //         .map((i) => allQuoteCost[i])?.length &&
  //       Object.keys(quoteIdObj)
  //         .map((e) => quoteIdObj[e].join() || 0)
  //         .filter((z) => z)
  //         .map((i) => allQuoteCost[i])
  //         ?.reduce(
  //           (accumulator, currentValue) =>
  //             Number(accumulator) + Number(currentValue)
  //         )
  //     : 0;

  //   monthlyAddCost = Object.keys(quoteIdObj).length
  //     ? Object.keys(quoteIdObj)
  //         .map((e) => quoteIdObj[e].join() || 0)
  //         .filter((z) => z)
  //         .map((i) => monthlyTotalCostObj[i])?.length &&
  //       Object.keys(quoteIdObj)
  //         .map((e) => quoteIdObj[e].join() || 0)
  //         .filter((z) => z)
  //         .map((i) => monthlyTotalCostObj[i])
  //         ?.reduce(
  //           (accumulator, currentValue) =>
  //             Number(accumulator) + Number(currentValue)
  //         )
  //     : 0;

  //   quoteDiscount = Object.keys(quoteIdObj).length
  //     ? Object.keys(quoteIdObj)
  //         .map((e) => quoteIdObj[e].join() || 0)
  //         .filter((z) => z)
  //         .map((i) => discountObj[i])?.length &&
  //       Object.keys(quoteIdObj)
  //         .map((e) => quoteIdObj[e].join() || 0)
  //         .filter((z) => z)
  //         .map((i) => discountObj[i])
  //         ?.reduce(
  //           (accumulator, currentValue) =>
  //             Number(accumulator) + Number(currentValue)
  //         )
  //     : 0;
  //   setState({
  //     ...state,
  //     quoteOrderIdObj: quoteIdObj,
  //     quoteTotalCost: quoteAddCost,
  //     monthlyTotalCost: monthlyAddCost,
  //     totalDiscount: quoteDiscount,
  //     quoteSelectID: checkedValues,
  //   });
  // };

  // Compare quote and optional id
  // const handleCompareId = ({ idObj, key }) => {
  //   let itemName = '';
  //   let checkedIdObj = '';
  //   idObj &&
  //     Object.keys(idObj)?.length &&
  //     Object.keys(idObj)
  //       .map((e) => e)
  //       .forEach((item) => {
  //         if (itemName === item.replace(/[0-9]/g, '')) {
  //           checkedIdObj = {
  //             ...checkedIdObj,
  //             [item.replace(/[0-9]/g, '')]: checkedIdObj[
  //               item.replace(/[0-9]/g, '')
  //             ]
  //               ? [...checkedIdObj[item.replace(/[0-9]/g, '')], ...idObj[item]]
  //               : [...idObj[item]],
  //           };
  //         } else {
  //           checkedIdObj = {
  //             ...checkedIdObj,
  //             [item.replace(/[0-9]/g, '')]: idObj[item],
  //           };
  //         }
  //         itemName = item.replace(/[0-9]/g, '');
  //       });

  //   return checkedIdObj;
  // };

  // for optional id
  // let optionalId = optionalItemIdObj;
  // let optionCost = allOptionCost;
  // let optionAddCost = 0;
  // const handleOptionalChange = (checkedValues, key) => {
  //   let allQuoteId = handleCompareId({ idObj: quoteAllId, key });
  //   let checkedQuoteId = handleCompareId({ idObj: quoteIdObj, key });

  //   let checkAllQuoteId = arrayEqual(
  //     allQuoteId[key.replace(/[0-9]/g, '')],
  //     checkedQuoteId[key.replace(/[0-9]/g, '')]
  //   );

  //   optionalId = {
  //     ...optionalId,
  //     [key]: checkedValues,
  //   };

  //   let optionalAllId = handleCompareId({ idObj: optionAllId, key });
  //   let checkedOptionalId = handleCompareId({ idObj: optionalId, key });

  //   let checkAllOptionId = arrayEqual(
  //     optionalAllId[key.replace(/[0-9]/g, '')],
  //     checkedOptionalId[key.replace(/[0-9]/g, '')]
  //   );

  //   setCheckAll({
  //     ...checkAll,
  //     [key.replace(/[0-9]/g, '')]:
  //       checkAllQuoteId && checkAllOptionId ? true : false,
  //   });

  //   // For total optional amount
  //   let totalAmount = 0;

  //   allOptionQuoteCost?.forEach((allOption) => {
  //     Object.keys(optionalId)?.length &&
  //       Object.keys(optionalId).forEach((selectOption) => {
  //         if (allOption?.key === selectOption.replace(/[0-9]/g, '')) {
  //           totalAmount =
  //             optionalId[selectOption]?.length &&
  //             optionalId[selectOption]
  //               .map((e) => allOption[e])
  //               ?.reduce(
  //                 (accumulator, currentValue) =>
  //                   Number(accumulator) + Number(currentValue)
  //               );

  //           optionCost = {
  //             ...optionCost,
  //             [selectOption]: totalAmount,
  //           };
  //         }
  //       });
  //   });

  //   optionAddCost = Object.keys(optionCost)
  //     .map((e) => optionCost[e])
  //     ?.reduce(
  //       (accumulator, currentValue) =>
  //         Number(accumulator) + Number(currentValue)
  //     );

  //   setState({
  //     ...state,
  //     optionalItemIdObj: optionalId,
  //     allOptionCost: optionCost,
  //     optionalTotalCost: optionAddCost,
  //   });
  // };

  // For Quote all check
  // let allQuoteCheck = checkAll;
  // let allCheckOptionCost = allOptionCost;

  // let optionalAmount = optionalTotalCost;

  // const onQuoteCheckAllChange = (e, key, optionStatus) => {
  //   allQuoteCheck = {
  //     ...allQuoteCheck,
  //     [key.replace(/[0-9]/g, '').trim()]: e.target.checked,
  //   };

  //   setCheckAll(allQuoteCheck);
  //   let selectQuoteId = {};

  //   optionStatus?.length &&
  //     optionStatus.forEach((item, i) => {
  //       selectQuoteId = {
  //         ...selectQuoteId,
  //         [`${key}${i}`]: e.target.checked ? [item?.id] : [],
  //       };
  //     });

  //   let quoteAllCheckId = {
  //     ...quoteOrderIdObj,
  //     ...selectQuoteId,
  //   };

  //   let totalQuoteCost =
  //     Object.keys(quoteAllCheckId)
  //       .map((e) =>
  //         Array.isArray(quoteAllCheckId[e])
  //           ? quoteAllCheckId[e].join()
  //           : quoteAllCheckId[e]
  //       )
  //       .filter((i) => i)
  //       .map((z) => allQuoteCost[z])?.length &&
  //     Object.keys(quoteAllCheckId)
  //       .map((e) =>
  //         Array.isArray(quoteAllCheckId[e])
  //           ? quoteAllCheckId[e].join()
  //           : quoteAllCheckId[e]
  //       )
  //       .filter((i) => i)
  //       .map((z) => allQuoteCost[z])
  //       ?.reduce(
  //         (accumulator, currentValue) =>
  //           Number(accumulator) + Number(currentValue)
  //       );

  //   let optionalAllCheckId = {};
  //   optionAllId &&
  //     Object.keys(optionAllId)?.length &&
  //     Object.keys(optionAllId).forEach((item) => {
  //       if (item.replace(/[0-9]/g, '') === key) {
  //         optionalAllCheckId = {
  //           ...optionalAllCheckId,
  //           [item]: e.target.checked ? optionAllId[item] : [],
  //         };
  //       }
  //     });

  //   // not using optionalAmount
  //   allOptionQuoteCost.forEach((item, i) => {
  //     if (
  //       optionalAllCheckId &&
  //       Object.keys(optionalAllCheckId)?.length &&
  //       Object.keys(optionalAllCheckId)
  //         .map((e) => e.replace(/[0-9]/g, ''))
  //         .includes(item?.key) &&
  //       Object.keys(optionalAllCheckId).map((e) => optionalAllCheckId[e])
  //         ?.length &&
  //       Object.keys(optionalAllCheckId)
  //         .map((e) => optionalAllCheckId[e])
  //         .flat()?.length
  //     ) {
  //       optionalAmount =
  //         optionalAmount +
  //         parseFloat(
  //           Object.keys(item)
  //             .filter((e) => e !== 'key')
  //             .map((z) => item[z])
  //             ?.reduce(
  //               (accumulator, currentValue) =>
  //                 parseFloat(accumulator) + parseFloat(currentValue)
  //             )
  //         );
  //     }
  //   });

  //   let totalMonthlyCost =
  //     Object.keys(quoteAllCheckId)
  //       .map((e) =>
  //         Array.isArray(quoteAllCheckId[e])
  //           ? quoteAllCheckId[e].join()
  //           : quoteAllCheckId[e]
  //       )
  //       .filter((i) => i)
  //       .map((z) => monthlyTotalCostObj[z])?.length &&
  //     Object.keys(quoteAllCheckId)
  //       .map((e) =>
  //         Array.isArray(quoteAllCheckId[e])
  //           ? quoteAllCheckId[e].join()
  //           : quoteAllCheckId[e]
  //       )
  //       .filter((i) => i)
  //       .map((z) => monthlyTotalCostObj[z])
  //       ?.reduce(
  //         (accumulator, currentValue) =>
  //           Number(accumulator) + Number(currentValue)
  //       );

  //   let allQuoteDiscount =
  //     Object.keys(quoteAllCheckId)
  //       .map((e) =>
  //         Array.isArray(quoteAllCheckId[e])
  //           ? quoteAllCheckId[e].join()
  //           : quoteAllCheckId[e]
  //       )
  //       .filter((i) => i)
  //       .map((z) => discountObj[z])?.length &&
  //     Object.keys(quoteAllCheckId)
  //       .map((e) =>
  //         Array.isArray(quoteAllCheckId[e])
  //           ? quoteAllCheckId[e].join()
  //           : quoteAllCheckId[e]
  //       )
  //       .filter((i) => i)
  //       .map((z) => discountObj[z])
  //       ?.reduce(
  //         (accumulator, currentValue) =>
  //           Number(accumulator) + Number(currentValue)
  //       );

  //   let allQuoteObj = e.target.checked
  //     ? {
  //         ...quoteOrderIdObj,
  //         [key]: quoteAllId[key],
  //       }
  //     : {
  //         ...quoteOrderIdObj,
  //         [key]: [],
  //       };

  //   let allOptionalIdObj = {
  //     ...state.optionalItemIdObj,
  //     ...optionalAllCheckId,
  //   };
  //   let totalAmount = 0;

  //   allOptionQuoteCost?.forEach((allOption) => {
  //     Object.keys(allOptionalIdObj)?.length &&
  //       Object.keys(allOptionalIdObj).forEach((selectOption) => {
  //         if (allOption?.key === selectOption.replace(/[0-9]/g, '')) {
  //           totalAmount =
  //             allOptionalIdObj[selectOption]?.length &&
  //             allOptionalIdObj[selectOption]
  //               .map((e) => allOption[e])
  //               ?.reduce(
  //                 (accumulator, currentValue) =>
  //                   Number(accumulator) + Number(currentValue)
  //               );

  //           allCheckOptionCost = {
  //             ...allCheckOptionCost,
  //             [selectOption]: totalAmount,
  //           };
  //         }
  //       });
  //   });

  //   setState({
  //     ...state,
  //     quoteOrderIdObj: quoteAllCheckId,
  //     optionalItemIdObj: { ...state.optionalItemIdObj, ...optionalAllCheckId },
  //     quoteTotalCost: totalQuoteCost,
  //     allOptionCost: { ...allCheckOptionCost },
  //     optionalTotalCost: optionalAmount,
  //     monthlyTotalCost: totalMonthlyCost,
  //     totalDiscount: allQuoteDiscount,
  //     quoteSelectID: Object.keys(allQuoteObj)
  //       .map((e) =>
  //         Number(
  //           Array.isArray(allQuoteObj[e])
  //             ? allQuoteObj[e].join()
  //             : allQuoteObj[e]
  //         )
  //       )
  //       .filter((id) => id),
  //   });
  // };

  // for Line Item id
  // let monthlyAddLineItemCost = monthlyLineItemTotalCost;
  // const handleLineItemChange = (checkedValues) => {
  //   let checkAllId = arrayEqual(lineItemAllId, checkedValues);
  //   setLineCheckAll(checkAllId);

  //   let totalCost =
  //     checkedValues?.length &&
  //     checkedValues
  //       .map((e) => allLineItemCost[e])
  //       ?.reduce(
  //         (accumulator, currentValue) =>
  //           Number(accumulator) + Number(currentValue)
  //       );

  //   monthlyAddLineItemCost =
  //     checkedValues?.length &&
  //     checkedValues
  //       .map((e) => monthlyLineItemTotalCostObj[e])
  //       ?.reduce(
  //         (accumulator, currentValue) =>
  //           Number(accumulator) + Number(currentValue)
  //       );

  //   setState({
  //     ...state,
  //     lineItemIdArray: checkedValues,
  //     lineItemTotalCost: totalCost,
  //     monthlyLineItemTotalCost: monthlyAddLineItemCost,
  //   });
  // };

  // for All Line Item
  // const onLineItemCheckAllChange = (e) => {
  //   setLineCheckAll(e.target.checked);

  //   let totalCost =
  //     Object.keys(allLineItemCost)?.length &&
  //     Object.keys(allLineItemCost)
  //       .map((e) => allLineItemCost[e])
  //       ?.reduce(
  //         (accumulator, currentValue) =>
  //           Number(accumulator) + Number(currentValue)
  //       );

  //   let getAllSelectedLineItem = e.target.checked ? lineItemAllId : [];

  //   let monthlyAllAddEquipmentCost =
  //     getAllSelectedLineItem?.length &&
  //     getAllSelectedLineItem
  //       .map((e) => monthlyLineItemTotalCostObj[e])
  //       ?.reduce(
  //         (accumulator, currentValue) =>
  //           Number(accumulator) + Number(currentValue)
  //       );

  //   setState({
  //     ...state,
  //     lineItemIdArray: e.target.checked ? lineItemAllId : [],
  //     lineItemTotalCost: e.target.checked ? totalCost : 0,
  //     monthlyLineItemTotalCost: monthlyAllAddEquipmentCost,
  //   });
  // };

  // for all new equipment item
  // let monthlyAddEquipmentCost = monthlyEquipmentTotalCost;
  // let equipmentIdObj = equipmentIdArray;

  // const handleEquipmentItemChange = (checkedValues, key) => {
  //   equipmentIdObj = {
  //     ...equipmentIdObj,
  //     [key]: checkedValues,
  //   };

  //   let allCheckedID =
  //     Object.keys(equipmentIdObj)?.length &&
  //     Object.keys(equipmentIdObj)
  //       ?.map((e) => equipmentIdObj[e])
  //       .flat();

  //   let checkAllId = arrayEqual(equipmentAllIdArray, allCheckedID);

  //   let checkAllOptionId = arrayEqual(
  //     typeof equOptionAllId === 'object' &&
  //       Object.values(equOptionAllId).flat(),
  //     typeof equOptionalItemIdObj === 'object' &&
  //       Object.values(equOptionalItemIdObj).flat()
  //   );
  //   setEquipmentCheckAll(checkAllId && checkAllOptionId);

  //   let totalCost =
  //     allCheckedID?.length &&
  //     allCheckedID
  //       .map((e) => allEquipmentItemCost[e])
  //       ?.reduce(
  //         (accumulator, currentValue) =>
  //           Number(accumulator) + Number(currentValue)
  //       );

  //   monthlyAddEquipmentCost =
  //     allCheckedID?.length &&
  //     allCheckedID
  //       .map((e) => monthlyEquipmentTotalCostObj[e])
  //       ?.reduce(
  //         (accumulator, currentValue) =>
  //           Number(accumulator) + Number(currentValue)
  //       );

  //   setState({
  //     ...state,
  //     equipmentIdArray: equipmentIdObj,
  //     equipmentItemTotalCost: totalCost,
  //     monthlyEquipmentTotalCost: monthlyAddEquipmentCost,
  //   });
  // };

  // const onNewEquipmentCheckAllChange = (e, key) => {
  //   setEquipmentCheckAll(e.target.checked);

  //   let totalCost =
  //     Object.keys(allEquipmentItemCost)?.length &&
  //     Object.keys(allEquipmentItemCost)
  //       .map((e) => allEquipmentItemCost[e])
  //       ?.reduce(
  //         (accumulator, currentValue) =>
  //           Number(accumulator) + Number(currentValue)
  //       );
  //   let getAllSelectedEquip = e.target.checked ? equipmentAllIdArray : [];

  //   let monthlyAllAddEquipmentCost =
  //     getAllSelectedEquip?.length &&
  //     getAllSelectedEquip
  //       .map((e) => monthlyEquipmentTotalCostObj[e])
  //       ?.reduce(
  //         (accumulator, currentValue) =>
  //           Number(accumulator) + Number(currentValue)
  //       );

  //   setState({
  //     ...state,
  //     equipmentIdArray: e.target.checked ? equipmentAllIdObj : {},
  //     equipmentItemTotalCost: e.target.checked ? totalCost : 0,
  //     monthlyEquipmentTotalCost: monthlyAllAddEquipmentCost,
  //     equOptionalItemIdObj: e.target.checked ? allCheckEquOptionalIdObj : {},
  //     equOptionalTotalCost: e.target.checked ? allCheckEquOptionalTotalCost : 0,
  //   });
  // };

  // for optional id
  // let equOptionalId = equOptionalItemIdObj;
  // let equOptionCost = allEquOptionCost;
  // let equOptionAddCost = 0;

  // const handleEquOptionalChange = (checkedValues, key) => {
  //   equOptionalId = {
  //     ...equOptionalId,
  //     [key]: checkedValues,
  //   };

  //   let allCheckedID =
  //     Object.keys(equipmentIdArray)?.length &&
  //     Object.keys(equipmentIdArray)
  //       ?.map((e) => equipmentIdArray[e])
  //       .flat();

  //   let checkAllEquId = arrayEqual(equipmentAllIdArray, allCheckedID);
  //   let checkAllOptionId = arrayEqual(
  //     typeof equOptionAllId === 'object' &&
  //       Object.values(equOptionAllId).flat(),
  //     typeof equOptionalId === 'object' && Object.values(equOptionalId).flat()
  //   );

  //   setEquipmentCheckAll(checkAllEquId && checkAllOptionId);

  //   // For total optional amount
  //   let totalAmount = 0;
  //   equAllOptionQuoteCost?.forEach((allOption) => {
  //     Object.keys(equOptionalId)?.length &&
  //       Object.keys(equOptionalId).forEach((selectOption) => {
  //         if (allOption?.key === Number(selectOption)) {
  //           totalAmount =
  //             equOptionalId[Number(selectOption)]?.length &&
  //             equOptionalId[Number(selectOption)]
  //               .map((e) => allOption[e])
  //               ?.reduce(
  //                 (accumulator, currentValue) =>
  //                   Number(accumulator) + Number(currentValue)
  //               );

  //           equOptionCost = {
  //             ...equOptionCost,
  //             [Number(selectOption)]: Number(totalAmount),
  //           };
  //         }
  //       });
  //   });

  //   equOptionAddCost = Object.keys(equOptionCost)
  //     .map((e) => equOptionCost[e])
  //     ?.reduce(
  //       (accumulator, currentValue) =>
  //         Number(accumulator) + Number(currentValue)
  //     );

  //   setState({
  //     ...state,
  //     equOptionalItemIdObj: equOptionalId,
  //     allEquOptionCost: equOptionCost,
  //     equOptionalTotalCost: equOptionAddCost,
  //   });
  // };

  const compareDiscountApplied = () => {
    let res = [];
    Object.keys(discountQuoteListObj).map((e) => {
      res = [
        ...res,
        {
          ...discountQuoteListObj[e]?.discount_list[0],
          admin_material_total_amount:
            discountQuoteListObj[e]?.admin_material_total_amount,
        },
      ];
    });

    return res;
  };

  // For Quote Price
  let priceObj = quotePrice;
  let priceQuoteDiscountedObj = quoteDiscountedPrice;
  let itemName = '';
  const handlePrice = () => {
    quoteOrderIdObj &&
      Object.keys(quoteOrderIdObj)?.length &&
      Object.keys(quoteOrderIdObj)
        .map((e) => e)
        .forEach((item) => {
          if (itemName === item.replace(/[0-9]/g, '').trim()) {
            priceObj = {
              ...priceObj,
              [item.replace(/[0-9]/g, '').trim()]:
                (parseFloat(allQuoteCost[quoteOrderIdObj[item]]) || 0) +
                (parseFloat(priceObj[item.replace(/[0-9]/g, '').trim()]) || 0),
            };

            priceQuoteDiscountedObj = {
              ...priceQuoteDiscountedObj,
              [item.replace(/[0-9]/g, '').trim()]:
                (parseFloat(allQuoteDiscountedCost[quoteOrderIdObj[item]]) ||
                  0) +
                (parseFloat(
                  priceQuoteDiscountedObj[item.replace(/[0-9]/g, '').trim()]
                ) || 0),
            };
          } else {
            priceObj = {
              ...priceObj,
              [item.replace(/[0-9]/g, '').trim()]:
                allQuoteCost[quoteOrderIdObj[item]] || 0,
            };

            priceQuoteDiscountedObj = {
              ...priceQuoteDiscountedObj,
              [item.replace(/[0-9]/g, '').trim()]:
                allQuoteDiscountedCost[quoteOrderIdObj[item]] || 0,
            };
          }
          itemName = item.replace(/[0-9]/g, '').trim();
        });
    setQuotePrice(priceObj);
    setQuoteDiscountedPrice(priceQuoteDiscountedObj);
  };

  // For Quote option Price
  let priceOptionObj = quoteOptionPrice;
  let itemOptionName = '';
  const handleOptionPrice = () => {
    allOptionCost &&
      Object.keys(allOptionCost)?.length &&
      Object.keys(allOptionCost)
        .map((e) => e)
        .forEach((item) => {
          if (itemOptionName === item.replace(/[0-9]/g, '')) {
            priceOptionObj = {
              ...priceOptionObj,
              [item.replace(/[0-9]/g, '')]:
                (parseFloat(allOptionCost[item]) || 0) +
                (parseFloat(priceOptionObj[item.replace(/[0-9]/g, '')]) || 0),
            };
          } else {
            priceOptionObj = {
              ...priceOptionObj,
              [item.replace(/[0-9]/g, '')]: allOptionCost[item] || 0,
            };
          }
          itemOptionName = item.replace(/[0-9]/g, '');
        });
    // let optionTotalAmount =
    //   Object.keys(priceOptionObj)?.length &&
    //   Object.keys(priceOptionObj)
    //     .map((e) => priceOptionObj[e])
    //     .reduce(
    //       (accumulator, currentValue) =>
    //         Number(accumulator) + Number(currentValue)
    //     );
    // setOptionTotalAmount(optionTotalAmount);
    setQuoteOptionPrice(priceOptionObj);
  };

  const expireMessage = () => {
    let expireDays = moment().diff(
      moment(quoteData?.bookingQuote?.createdAt).add(
        quoteData?.bookingQuote?.quoteExpiredAt,
        'd'
      ),
      'days'
    );
    let providerName = firstLetterCaps(details?.Provider?.firstName ?? '');
    let providerPhoneNumber = details?.Provider?.phoneNumber;
    return `Quote is older than 30 days . Please contact ${providerName} at ${providerPhoneNumber} to confirm quote details `;
  };

  useEffect(() => {
    handlePrice();
  }, [quoteOrderIdObj]);

  useEffect(() => {
    handleOptionPrice();
  }, [allOptionCost]);

  useEffect(() => {
    let total =
      (parseFloat(lineItemTotalCost) || 0) +
      (parseFloat(quoteTotalCost) || 0) +
      (parseFloat(equipmentItemTotalCost) || 0) +
      (parseFloat(optionTotalAmount) || 0) +
      (parseFloat(equOptionalTotalCost) || 0) -
      ((parseFloat(totalDiscount) || 0) + parseFloat(spDiscount) || 0);

    setQuoteTotalAmount(total);
  }, [
    lineItemTotalCost,
    quoteTotalCost,
    equipmentItemTotalCost,
    optionTotalAmount,
    equOptionalTotalCost,
    totalDiscount,
    spDiscount,
  ]);
  return (
    <>
      <div className="main-content ratingPage requestviewdetails">
        <div className="pageContent">
          <div className="pageContent_wide">
            <Container className="container-lg">
              <Breadcrumb
                title={t('text.requestViewDetails.title')}
                breadcrumb={breadcrumb}
                type="customer"
              />
              {!state?.isLoading && Object.keys(details)?.length > 0 ? (
                <>
                  <div
                    id="quoteWrapper"
                    className="ratingPage_Box br-10 p-30 bg-white"
                  >
                    <div className="ratingPage_profile mb-4">
                      <div className="ratingPage_profile_content text-center">
                        <ImageElement
                          previewSource={
                            details?.Provider?.profilePictureThumbUrl
                          }
                          source="view-details.png"
                          alt="view-details"
                        />
                        <h2 className="mb-0">
                          {firstLetterCaps(details?.Provider?.firstName ?? '')}
                          &nbsp;
                          {firstLetterCaps(details?.Provider?.lastName ?? '')}
                        </h2>
                        <p className="mb-0">
                          <ul className="list-unstyled d-flex justify-content-center mt-1 starRating mb-0">
                            {generateStarRating(
                              details?.Provider?.avgRating ?? 0
                            )}
                          </ul>
                        </p>
                      </div>
                    </div>

                    <div className="ratingPage_Payment ">
                      <div className="ratingPage_Services border-bottom-0 p-0">
                        <div className="ratingPage_Services_maintenance">
                          <h5 className="ratingsubhead mb-2">
                            {t('text.requestViewDetails.servicesDetails')}
                          </h5>
                          <p className="ratingpara mb-2">
                            {
                              details?.ServiceCategory?.ServiceCategoryDetails
                                ?.name
                            }
                          </p>
                          <div className="d-flex justify-content-between ">
                            <h5 className="ratingsubhead mb-2">
                              {/* {t('text.requestViewDetails.subcategoryQuote')} */}
                              {details?.ServiceCategory?.name}
                            </h5>
                          </div>
                        </div>
                      </div>
                      {quoteData?.booking?.ServiceCategory?.isSalesQuote === 0 &&
                        <div className="ratingPage_Services_inner d-flex justify-content-between ">
                          <h5 className="ratingsubhead mb-0">
                            {t('text.requestViewDetails.quoteAmount')}
                          </h5>
                          <p className="ratingamount mb-0 ">
                            {quoteData?.bookingQuote?.quoteAmount
                              ? checkValidPrice(
                                quoteData?.bookingQuote?.quoteAmount
                              )
                              : '$0.00'}
                          </p>
                        </div>
                      }
                      {/* Edit quote Start  */}
                      <div
                        className={`ratingPage_Services pt-0 border-bottom-0 ${quoteData?.booking?.ServiceCategory?.isSalesQuote ===
                          0
                          ? 'p-0'
                          : ''
                          }`}
                      >
                        {quoteData?.quote && quoteData?.quote?.length > 0 && (
                          <div className="ratingPage_Services pt-0">
                            <div className="ratingPage_Services_maintenance">
                              <>
                                <div className="ratingPage_Services_inner d-flex justify-content-between">
                                  <h5 className="ratingsubhead mb-0">
                                    Selected All Options
                                  </h5>
                                </div>

                                {quoteData?.quote?.length &&
                                  quoteData?.quote?.map((quoteItem, index) => (
                                    <>
                                      <div
                                        key={index}
                                        className="ratingPage_Services_inner d-flex justify-content-between"
                                      >
                                        <div className="d-flex align-items-sm-start">
                                          <ImageElement
                                            previewSource={
                                              quoteItem?.image?.imageThumbUrl
                                            }
                                            className="me-2"
                                            alt="water-heater"
                                          />
                                          <div className="ratingPage_desc">
                                            <h5 className="ratingsubhead mb-1 d-flex align-items-center">
                                              {quoteItem?.brand} -{' '}
                                              {quoteItem?.quote_type}
                                              <Link
                                                to="#"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  setState({
                                                    ...state,
                                                    modalHeading:
                                                      quoteItem?.quote_type,
                                                    showModal: 'requestDetails',
                                                    modalKey: 'information',
                                                    modalData: quoteItem,
                                                  });
                                                }}
                                              >
                                                <em className="gg-danger-circle ms-2 text-750" />
                                              </Link>
                                            </h5>
                                            {quoteItem?.series_name && (
                                              <p className="ratingamount mb-1 d-flex align-items-center">
                                                {quoteItem?.series_name}
                                              </p>
                                            )}

                                            {/* {quoteItem?.quote_description && (
                                              <p className="ratingamount mb-1 d-flex align-items-center">
                                                {quoteItem?.quote_description}
                                              </p>
                                            )} */}
                                            {quoteItem?.size_description && (
                                              <p className="ratingamount mb-1">
                                                <span className="ratingsubhead">
                                                  {t(
                                                    'text.requestViewDetails.sizeDescription'
                                                  )}
                                                </span>
                                                {quoteItem?.size_description}
                                              </p>
                                            )}
                                            {/* {quoteItem?.quote_description && (
                                              <p className="ratingamount mb-1">
                                                <span className="ratingsubhead">
                                                  {t(
                                                    'text.requestViewDetails.quoteDescription'
                                                  )}
                                                </span>
                                                {quoteItem?.quote_description}
                                              </p>
                                            )} */}
                                            {quoteItem?.system_btu_capacity && (
                                              <p className="ratingamount mb-1">
                                                <span className="ratingsubhead">
                                                  {t(
                                                    'text.requestViewDetails.capacity'
                                                  )}
                                                </span>
                                                {quoteItem?.system_btu_capacity}
                                              </p>
                                            )}
                                            {quoteItem?.directional && (
                                              <p className="ratingamount mb-1">
                                                <span className="ratingsubhead">
                                                  {t(
                                                    'text.requestViewDetails.directional'
                                                  )}
                                                </span>
                                                {quoteItem?.directional}
                                              </p>
                                            )}
                                            {quoteItem?.min_blower_drive && (
                                              <p className="ratingamount mb-1">
                                                <span className="ratingsubhead">
                                                  {t(
                                                    'text.requestViewDetails.minimumBlowerDrive'
                                                  )}
                                                </span>
                                                {quoteItem?.min_blower_drive}
                                              </p>
                                            )}
                                            {quoteItem?.rebate?.brandRebate && (
                                              <p className="ratingamount mb-1">
                                                <span className="ratingsubhead">
                                                  {t(
                                                    'text.requestViewDetails.efficiency'
                                                  )}
                                                </span>
                                                {quoteItem?.rebate?.brandRebate}
                                              </p>
                                            )}
                                            {quoteItem?.system_part_warranty && (
                                              <p className="ratingamount mb-1">
                                                <span className="ratingsubhead">
                                                  {t(
                                                    'text.requestViewDetails.partsWarranty'
                                                  )}
                                                </span>{' '}
                                                {
                                                  quoteItem?.system_part_warranty
                                                }
                                              </p>
                                            )}
                                            {quoteItem?.energy_guide_label && (
                                              <p className="ratingamount mb-1">
                                                <span className="ratingsubhead">
                                                  {t(
                                                    'text.requestViewDetails.energyGuideLabel'
                                                  )}
                                                </span>
                                                {quoteItem?.energy_guide_label}
                                              </p>
                                            )}
                                            {quoteItem?.system_part_warranty && (
                                              <p className="ratingamount mb-1">
                                                <span className="ratingsubhead">
                                                  {t(
                                                    'text.requestViewDetails.partWarranty'
                                                  )}
                                                </span>
                                                {
                                                  quoteItem?.system_part_warranty
                                                }
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                        {quoteItem?.admin_material_total_amount && (
                                          <div className="ratingPage_amount">
                                            <p className="ratingamount mb-1">
                                              {checkValidPrice(
                                                quoteItem?.admin_material_total_amount
                                              )}
                                            </p>
                                          </div>
                                        )}
                                      </div>

                                      {/* Discount price Quote */}

                                      {quoteItem?.discount_list?.length > 0 && (
                                        <div className="ratingPage_Services_inner d-flex justify-content-between ">
                                          <h5 className="ratingsubhead mb-0">
                                            {t('text.common.discountedPrice')}
                                            <Link
                                              to="#"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setState({
                                                  ...state,
                                                  modalHeading: 'Discount',
                                                  showModal: 'requestDetails',
                                                  modalKey: 'discountPrice',
                                                  modalData: quoteItem,
                                                });
                                              }}
                                            >
                                              <em className="gg-danger-circle ms-2 text-750" />
                                            </Link>
                                          </h5>

                                          <p className="ratingamount mb-0 ">
                                            {checkValidPrice(
                                              parseFloat(
                                                quoteItem?.appliedDiscount
                                              ) <
                                                parseFloat(
                                                  quoteItem?.admin_material_total_amount
                                                )
                                                ? parseFloat(
                                                  quoteItem?.admin_material_total_amount
                                                ) -
                                                parseFloat(
                                                  quoteItem?.appliedDiscount
                                                )
                                                : 0
                                            )}
                                          </p>
                                        </div>
                                      )}

                                      {/* Monthly payment */}
                                      {quoteItem?.financeOption &&
                                        Object.keys(quoteItem?.financeOption)
                                          ?.length ? (
                                        <div className="ratingPage_Services_inner d-flex justify-content-between ">
                                          <h5 className="ratingsubhead mb-0">
                                            {t(
                                              'text.requestViewDetails.monthlyApprovedCredit'
                                            )}
                                          </h5>
                                          <p className="ratingamount mb-0 ">
                                            {parseFloat(
                                              quoteItem?.appliedDiscount
                                            ) <
                                              parseFloat(
                                                quoteItem?.admin_material_total_amount
                                              )
                                              ? checkValidPrice(
                                                quoteItem?.monthlyPayment
                                              )
                                              : '$0.00'}
                                          </p>
                                        </div>
                                      ) : null}
                                      {/* Option LIst*/}

                                      {quoteItem?.optionalItemForBooking
                                        ?.length ? (
                                        <div>
                                          <h5 className="ratingsubhead mb-0 mt-2">
                                            {t(
                                              'text.requestViewDetails.optionsList'
                                            )}
                                          </h5>

                                          {quoteItem?.optionalItemForBooking?.map(
                                            (optionData, i) => (
                                              <>
                                                <div className="ratingPage_Services_inner d-flex justify-content-between ">
                                                  <h5 className="ratingsubhead mb-0">
                                                    {optionData?.title}
                                                  </h5>
                                                  <div>
                                                    {checkValidPrice(
                                                      optionData?.cost
                                                    )}
                                                  </div>
                                                </div>
                                              </>
                                            )
                                          )}
                                        </div>
                                      ) : null}

                                      {(quoteData?.booking?.bookingStatus ===
                                        'accepted' ||
                                        quoteData?.booking?.bookingStatus ===
                                        'canceled' ||
                                        quoteData?.booking?.bookingStatus ===
                                        'declined') && (
                                          <div className="ratingPage_Services_inner d-flex justify-content-between ">
                                            <h5 className="ratingsubhead mb-0">
                                              {t(
                                                'text.requestViewDetails.totalCost'
                                              )}{' '}
                                            </h5>

                                            <p className="ratingamount mb-0 ">
                                              {checkValidPrice(
                                                (quoteItem?.appliedDiscount > 0
                                                  ? parseFloat(
                                                    quoteItem?.admin_material_total_amount
                                                  ) -
                                                  parseFloat(
                                                    quoteItem?.appliedDiscount
                                                  )
                                                  : parseFloat(
                                                    quoteItem?.admin_material_total_amount
                                                  )) +
                                                (quoteItem
                                                  ?.optionalItemForBooking
                                                  ?.length
                                                  ? parseFloat(
                                                    quoteItem?.optionalItemForBooking
                                                      ?.map((e) => e?.cost)
                                                      ?.reduce(
                                                        (
                                                          accumulator,
                                                          currentValue
                                                        ) =>
                                                          parseFloat(
                                                            accumulator
                                                          ) +
                                                          parseFloat(
                                                            currentValue
                                                          )
                                                      )
                                                  )
                                                  : 0)
                                              )}
                                            </p>
                                          </div>
                                        )}
                                    </>
                                  ))}
                              </>
                            </div>
                          </div>
                        )}

                        {/* For technician options */}
                        {/* equipmentIdArray?.length */}
                        {quoteData?.otherEquipment?.length > 0 ? (
                          <>
                            <div className="ratingPage_Services">
                              <div className="ratingPage_Services_inner d-flex justify-content-between">
                                <h5 className="ratingsubhead mb-0">
                                  {t(
                                    'text.requestViewDetails.selectedAllTechnicianOptions'
                                  )}
                                </h5>
                              </div>

                              {quoteData?.otherEquipment?.map((val, index) => (
                                <>
                                  <div className="ratingPage_Services_maintenance">
                                    <div
                                      key={val?.id}
                                      class="ratingPage_Services_inner d-flex justify-content-between "
                                    >
                                      <div class="d-flex align-items-sm-center">
                                        <ImageElement
                                          previewSource={val?.imageThumbUrl}
                                          className="me-2"
                                          alt="water-heater"
                                        />
                                        <div class="ratingPage_desc">
                                          <h5 class="ratingsubhead mb-1 d-flex align-items-center">
                                            {val?.title || ''}
                                          </h5>
                                          <p class="ratingamount mb-0 d-flex align-items-center">
                                            {val?.description || ''}
                                          </p>
                                        </div>
                                      </div>
                                      <div class="ratingPage_amount">
                                        <p class="ratingamount mb-0">
                                          {checkValidPrice(val?.cost || '')}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  {val?.otherEquipment?.length ? (
                                    <div>
                                      <h5 className="ratingsubhead mb-0 mt-2">
                                        {t(
                                          'text.requestViewDetails.optionsList'
                                        )}
                                      </h5>

                                      {val?.otherEquipment?.map(
                                        (optionData, i) => (
                                          <>
                                            <div className="ratingPage_Services_inner d-flex justify-content-between ">
                                              <h5 className="ratingsubhead mb-0">
                                                {optionData?.title}
                                              </h5>
                                              <div>
                                                {checkValidPrice(
                                                  optionData?.cost
                                                )}
                                              </div>
                                            </div>
                                          </>
                                        )
                                      )}
                                    </div>
                                  ) : null}
                                </>
                              ))}

                              <div className="ratingPage_Services_inner d-flex justify-content-between ">
                                <h5 className="ratingsubhead mb-0">
                                  {t(
                                    'text.requestViewDetails.monthlyApprovedCredit'
                                  )}
                                </h5>
                                <p className="ratingamount mb-0 ">
                                  {checkValidPrice(monthlyEquipmentTotalCost)}
                                </p>
                              </div>
                              {quoteData?.booking?.bookingStatus ===
                                'accepted' && (
                                  <div className="ratingPage_Services_inner d-flex justify-content-between ">
                                    <h5 className="ratingsubhead mb-0">
                                      {t('text.requestViewDetails.totalCostS')}
                                    </h5>
                                    <p className="ratingamount mb-0 ">
                                      {checkValidPrice(
                                        Number(equipmentItemTotalCost) +
                                        Number(equOptionalTotalCost)
                                      )}
                                    </p>
                                  </div>
                                )}
                            </div>
                          </>
                        ) : null}
                        {/* For technician options End */}
                        {(quoteData?.lineItems?.length > 0 ||
                          quoteData?.booking?.BookingLineItems?.length > 0) && (
                            <>
                              <div className="ratingPage_Services_inner d-flex justify-content-between">
                                <h5 className="ratingsubhead mb-0">
                                  {t('text.requestViewDetails.lineItems')}
                                </h5>
                              </div>

                              <div className="ratingPage_Services_maintenance">
                                {(quoteData?.lineItems?.length ||
                                  quoteData?.booking?.BookingLineItems?.length) &&
                                  (
                                    quoteData?.lineItems ||
                                    quoteData?.booking?.BookingLineItems
                                  )?.map((val) => (
                                    <>
                                      <div
                                        key={val?.id}
                                        class="ratingPage_Services_inner d-flex justify-content-between "
                                      >
                                        <div class="d-flex align-items-sm-center">
                                          <ImageElement
                                            previewSource={
                                              val?.imageThumbUrl || val?.imageUrl
                                            }
                                            className="me-2"
                                            alt="water-heater"
                                          />
                                          <div class="ratingPage_desc">
                                            <h5 class="ratingsubhead mb-1 d-flex align-items-center">
                                              {val?.name || ''} ($
                                              {val?.unitPrice} * {val?.qty})
                                            </h5>
                                            <p class="ratingamount mb-0 d-flex align-items-center">
                                              {val?.description || ''}
                                            </p>
                                          </div>
                                        </div>
                                        <div class="ratingPage_amount">
                                          <p class="ratingamount mb-0">
                                            $
                                            {val?.unitPrice * (val?.qty || 1) ||
                                              '$0.00'}
                                          </p>
                                        </div>
                                      </div>
                                    </>
                                  ))}
                              </div>
                              {quoteData?.booking?.bookingStatus === 'accepted' &&
                                quoteData?.booking?.ServiceCategory
                                  ?.isSalesQuote === 1 && (
                                  <div className="ratingPage_Services">
                                    <div className="d-flex justify-content-between ">
                                      <h5 className="ratingsubhead mb-0">
                                        {t('text.requestViewDetails.totalCostS')}
                                      </h5>
                                      <p className="ratingamount mb-0 ">
                                        {checkValidPrice(lineItemTotalCost)}
                                      </p>
                                    </div>
                                  </div>
                                )}
                            </>
                          )}

                        {/* Block For Display Total Cost   */}
                        <div className='ratingPage_Services_inner d-flex justify-content-between align-items-center mt-2'>
                          <h5 className="ratingsubhead mb-0">
                            {t('text.ratingReview.cost')}
                          </h5>
                          <p className="ratingsubhead mb-0 ratingPage_amount pe-0">
                            {quoteData?.booking?.totalCost
                              ? checkValidPrice(
                                quoteData?.booking?.totalCost
                              )
                              : '0'}
                          </p>
                        </div>

                        {/* for promo code display */}
                        {quoteData?.booking?.ServiceCategory?.isSalesQuote ===
                          0 && (quoteData?.booking?.promoCodeDiscount > 0) &&
                          <>
                            <div className="ratingPage_Services_inner d-flex justify-content-between align-items-center mt-2">
                              <h5 className="ratingsubhead mb-0">
                                {t('text.ratingReview.promoCodeDiscount')}{':'}
                                <Link
                                  to="#"
                                  className="ms-1"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setState({
                                      ...state,
                                      showModal: 'promoCodeInfo',
                                      modalHeading: t('text.ratingReview.promoCodeDiscount'),
                                    });
                                  }
                                  }
                                >
                                  <em className="gg-danger-circle ms-2 text-750" />
                                </Link>
                              </h5>
                              {/* <p className="ratingsubhead mb-0 ratingPage_amount pe-0">
                                {quoteData?.booking?.PromoCode?.discountType
                                  ? quoteData?.booking?.PromoCode
                                    ?.discountType === 'percentage'
                                    ? `${parseFloat(
                                      quoteData?.booking?.PromoCode
                                        ?.discountValue
                                    ).toFixed(2)}%`
                                    : checkValidPrice(
                                      quoteData?.booking?.PromoCode
                                        ?.discountValue *
                                      (quoteData?.booking?.quantity || 1)
                                    )
                                  : Number(
                                    quoteData?.booking?.customerPromoCodeDiscount
                                  ) ? (
                                    checkValidPrice(
                                      quoteData?.booking?.customerPromoCodeDiscount
                                    )
                                  ) : (
                                    '$0.00'
                                  )}
                              </p> */}
                              <p className="ratingsubhead mb-0 ratingPage_amount pe-0">
                                {quoteData?.booking?.promoCodeDiscount
                                  ? checkValidPrice(
                                    quoteData?.booking?.promoCodeDiscount
                                  )
                                  : '0'}
                              </p>
                            </div>
                          </>
                        }

                        {/* Edit quote End  */}

                        {/* Sub Total Display Block */}
                        {quoteData?.booking?.ServiceCategory?.isSalesQuote ===
                          1 ?
                          <div className="ratingPage_Services_inner d-flex justify-content-between ">
                            <h5 className="ratingsubhead mb-0">
                              {t('text.requestViewDetails.subTotalCost')}
                            </h5>
                            <p className="ratingamount mb-0 ">
                              {checkValidPrice(
                                (parseFloat(lineItemTotalCost) || 0) +
                                (parseFloat(quoteTotalCost) || 0) +
                                (parseFloat(equipmentItemTotalCost) || 0) +
                                (parseFloat(optionTotalAmount) || 0) +
                                (parseFloat(equOptionalTotalCost) || 0)
                              )}
                            </p>
                          </div>
                          :
                          <div className="ratingPage_Services_inner d-flex justify-content-between ">
                            <h5 className="ratingsubhead mb-0">
                              {t('text.requestViewDetails.subTotalCost')}
                            </h5>
                            <p className="ratingamount mb-0 ">
                              {quoteData?.booking?.subTotal
                                ? checkValidPrice(
                                  quoteData?.booking?.subTotal
                                )
                                : '0'}
                            </p>
                          </div>
                        }

                        {/* for memberShip display */}
                        {quoteData?.booking?.membershipBenefitAvailable && (
                          <>
                            <div className="ratingPage_Services_inner d-flex justify-content-between ">
                              <h5 className="ratingsubhead mb-0">
                                {t('text.common.memberShipSavings')}
                                :
                                <Link
                                  to="#"
                                  className="ms-1"
                                  onClick={() => {
                                    setState({
                                      ...state,
                                      showModal: 'memberShip',
                                      modalHeading: t(
                                        'text.common.memberShipSavings'
                                      ),
                                      modalKey: 'memberShip',
                                      modalData: {
                                        lineItemMembershipBenefit:
                                          quoteData?.booking
                                            ?.lineItemMembershipBenefit,
                                        serviceMembershipBenefit:
                                          quoteData?.booking
                                            ?.membershipBenefit,
                                        membershipBenefitAvailable:
                                          quoteData?.booking
                                            ?.membershipBenefitAvailable,
                                        membershipType: quoteData?.booking?.membershipType
                                      },
                                    });
                                  }}
                                >
                                  <em className="gg-danger-circle ms-2 text-750" />
                                </Link>
                              </h5>
                              <p className="ratingamount mb-0 ">
                                {quoteData?.booking
                                  ?.membershipBenefitAvailable
                                  ? checkValidPrice(
                                    Number(
                                      quoteData?.booking
                                        ?.membershipBenefit
                                    ) +
                                    Number(
                                      quoteData?.booking
                                        ?.lineItemMembershipBenefit
                                    )
                                  )
                                  : '$0.00'}
                              </p>
                            </div>
                          </>
                        )}

                        {/* for discount display */}
                        {quoteData?.booking?.ServiceCategory?.isSalesQuote ===
                          1 && parseFloat(totalDiscount) > 0 ? (
                          <div className="ratingPage_Services_inner d-flex justify-content-between ">
                            <h5 className="ratingsubhead mb-0">
                              {t('text.requestViewDetails.discountApplied')}

                              {editQuote && (
                                <>
                                  :
                                  <Link
                                    to="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setState({
                                        ...state,
                                        modalHeading: 'Discount',
                                        showModal: 'requestDetails',
                                        modalKey: 'discountApplied',
                                        modalData: compareDiscountApplied(),
                                      });
                                    }}
                                  >
                                    <em className="gg-danger-circle ms-2 text-750" />
                                  </Link>
                                </>
                              )}
                            </h5>
                            <p className="ratingamount mb-0 ">
                              {checkValidPrice(
                                parseFloat(totalDiscount) +
                                parseFloat(spDiscount)
                              )}
                            </p>
                          </div>
                        ) :
                          (
                            quoteData?.bookingQuote?.discount > 0 ?
                              <div className="ratingPage_Services_inner d-flex justify-content-between mt-2 ">
                                <h5 className="ratingsubhead mb-0">
                                  {t('text.requestViewDetails.discountS')}
                                </h5>
                                <p className="ratingamount mb-0 ">
                                  {quoteData?.bookingQuote?.discount
                                    ? checkValidPrice(
                                      quoteData?.bookingQuote?.discount
                                    )
                                    : '$0.00'}
                                </p>
                              </div>
                              :
                              null
                          )
                        }

                        {/* For Display Total Amount Block */}
                        {quoteData?.booking?.ServiceCategory?.isSalesQuote ===
                          1 ?
                          <div className="ratingPage_Services_inner d-flex justify-content-between ">
                            <h5 className="ratingsubhead mb-0">
                              {t('text.requestViewDetails.total')}
                            </h5>
                            <p className="ratingamount mb-0 ">
                              {checkValidPrice(
                                (parseFloat(lineItemTotalCost) || 0) +
                                (parseFloat(quoteTotalCost) || 0) +
                                (parseFloat(equipmentItemTotalCost) || 0) +
                                (parseFloat(optionTotalAmount) || 0) +
                                (parseFloat(equOptionalTotalCost) || 0) -
                                ((parseFloat(totalDiscount) || 0) +
                                  (parseFloat(spDiscount) || 0) +
                                  (parseFloat(
                                    Number(
                                      quoteData?.booking
                                        ?.membershipBenefit
                                    ) +
                                    Number(
                                      quoteData?.booking
                                        ?.lineItemMembershipBenefit
                                    )) || 0)
                                )
                              )}
                            </p>
                          </div>
                          :
                          <div className="ratingPage_Services_inner d-flex justify-content-between ">
                            <h5 className="ratingsubhead mb-0">
                              {t('text.requestViewDetails.total')}
                            </h5>
                            <p className="ratingamount mb-0 ">
                              {quoteData?.booking?.total
                                ? checkValidPrice(
                                  quoteData?.booking?.total
                                )
                                : '0'}
                            </p>
                          </div>
                        }

                        {quoteData?.booking?.ServiceCategory?.isSalesQuote ===
                          1 && (
                            <>
                              {quoteData?.financeOption?.paymentFactor > 0 &&
                                quoteTotalAmount >=
                                (parseFloat(
                                  quoteData?.financeOption?.minimumLoanAmount
                                ) || 0) ? (
                                <div className="ratingPage_Services_inner d-flex justify-content-between ">
                                  <h5 className="ratingsubhead mb-0">
                                    {t(
                                      'text.requestViewDetails.monthlyApprovedCredit'
                                    )}

                                    {editQuote && (
                                      <>
                                        :
                                        <Link
                                          to="#"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setState({
                                              ...state,
                                              modalHeading:
                                                'Monthly Payment (with approved credit)',
                                              showModal: 'requestDetails',
                                              modalKey: 'monthlyPayment',
                                              modalData: quoteData?.financeOption,
                                            });
                                          }}
                                        >
                                          <em className="gg-danger-circle ms-2 text-750" />
                                        </Link>
                                      </>
                                    )}
                                  </h5>
                                  <p className="ratingamount mb-0 ">
                                    {checkValidPrice(
                                      (((parseFloat(lineItemTotalCost) || 0) +
                                        (parseFloat(quoteTotalCost) || 0) +
                                        (parseFloat(equipmentItemTotalCost) ||
                                          0) +
                                        (parseFloat(optionTotalAmount) || 0) +
                                        (parseFloat(equOptionalTotalCost) || 0) -
                                        ((parseFloat(totalDiscount) || 0) +
                                          parseFloat(spDiscount) || 0)) /
                                        100) *
                                      (quoteData?.financeOption
                                        ?.paymentFactor || 1)
                                    )}
                                  </p>
                                </div>
                              ) : null}
                            </>
                          )}

                        {quoteData?.booking?.ServiceCategory?.isSalesQuote ===
                          1 &&
                          !editQuote && (
                            <>
                              {Object.keys(rebateObj)?.length &&
                                Object.keys(rebateObj)?.filter(
                                  (item) => rebateObj[item]?.length
                                )?.length ? (
                                <>
                                  <h5 className="ratingsubhead mb-0 mt-3 ratingPage_desc">
                                    {t('text.requestViewDetails.rebates')}
                                    <Link
                                      to="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setState({
                                          ...state,
                                          modalHeading: 'Rebates',
                                          showModal: 'requestDetails',
                                          modalKey: 'rebates',
                                        });
                                      }}
                                    >
                                      <em className="gg-danger-circle ms-2 text-750" />
                                    </Link>{' '}
                                    <br />
                                  </h5>

                                  {Object.keys(rebateObj)?.map((item) =>
                                    rebateObj[item]?.map(
                                      (rebate, index) =>
                                        quoteSelectID?.includes(
                                          Number(item)
                                        ) && (
                                          <div
                                            key={index}
                                            className="ratingPage_Services_inner d-flex justify-content-between "
                                          >
                                            <span className="ratingPage_desc">
                                              {rebate?.title}
                                            </span>
                                            <p className="ratingamount mb-0 ">
                                              {rebate?.cost}
                                            </p>
                                          </div>
                                        )
                                    )
                                  )}
                                </>
                              ) : null}
                            </>
                          )}
                      </div>
                    </div>
                    <>
                      {quoteData?.booking?.bookingStatus === 'accepted' &&
                        quoteData?.bookingQuote?.customerStatus ===
                        'accepted' &&
                        quoteData?.bookingQuote?.customerSignatureUrl && (
                          <div className="ratingPage_Services_image">
                            <ImageElement
                              previewSource={
                                quoteData?.bookingQuote?.customerSignatureUrl
                              }
                              alt="signature"
                            />
                          </div>
                        )}

                      {quoteData?.booking?.bookingStatus === 'accepted' &&
                        quoteData?.bookingQuote?.customerStatus !==
                        'accepted' &&
                        quoteData?.bookingQuote?.providerStatus !==
                        'accepted' &&
                        editQuote && (
                          <>
                            <div className="ratingPage_Services_image">
                              <SignatureCanvas
                                ref={(ref) => {
                                  sigPad.current = ref;
                                }}
                                onEnd={(e) => [
                                  setState({
                                    ...state,
                                    isSigned: true,
                                  }),
                                  handleSignature(),
                                ]}
                                canvasProps={{
                                  width: 600,
                                  height: 115,
                                  className: 'sigCanvas',
                                }}
                              />
                            </div>
                            <p className="ratingamount text-center">
                              {t('text.requestViewDetails.signForApproval')}
                            </p>
                          </>
                        )}
                      {(quoteData?.booking?.ServiceCategory?.isSalesQuote ===
                        0 ||
                        editQuote) && (
                          <div className="mt-3">
                            <h4 className="ratingheading">
                              {t('text.requestViewDetails.pleaseNote')} :
                            </h4>
                            <div className="note">
                              <p>
                                {data?.note}
                                {t('text.requestViewDetails.note')}
                              </p>
                            </div>
                            {quoteData?.booking?.bookingStatus === 'accepted' &&
                              (quoteData?.bookingQuote?.customerStatus ===
                                'pending' ||
                                quoteData?.bookingQuote?.providerStatus ===
                                'pending') && (
                                <p className="quote text-center">
                                  {quoteData?.bookingQuote?.quoteExpiredAt > 0 ? (
                                    <>
                                      {t('text.requestViewDetails.quoteExpire')}{' '}
                                      <span>
                                        {quoteData?.bookingQuote?.quoteExpiredAt}{' '}
                                        {t('text.common.days')}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      {t('text.requestViewDetails.quoteExpire')}
                                      <br />
                                      {expireMessage()}
                                    </>
                                  )}
                                </p>
                              )}
                          </div>
                        )}
                    </>
                    <div className="d-flex justify-content-end mt-sm-4 mt-3">
                      {quoteData?.bookingQuote?.providerStatus ===
                        'declined' && (
                          <CommonButton
                            type="button"
                            className=" btn-lg me-2 min-170"
                            disabled={true}
                          >
                            {t('text.common.declinedTechnician')}
                          </CommonButton>
                        )}

                      {quoteData?.bookingQuote?.providerStatus !== 'declined' &&
                        quoteData?.bookingQuote?.customerStatus ===
                        'declined' && (
                          <CommonButton
                            type="button"
                            className=" btn-lg me-2 min-170"
                            disabled={true}
                          >
                            {t('text.common.declined')}
                          </CommonButton>
                        )}

                      {quoteData?.bookingQuote?.providerStatus === 'accepted' &&
                        quoteData?.booking?.bookingStatus === 'accepted' && (
                          <CommonButton
                            type="button"
                            variant="primary"
                            className=" btn-lg me-2 min-170"
                            disabled={true}
                          >
                            {t('text.common.approvedTechnician')}
                          </CommonButton>
                        )}
                      {quoteData?.bookingQuote?.customerStatus === 'accepted' &&
                        quoteData?.bookingQuote?.providerStatus !==
                        'accepted' &&
                        quoteData?.booking?.bookingStatus === 'accepted' && (
                          <CommonButton
                            type="button"
                            variant="primary"
                            className=" btn-lg me-2 min-170"
                            disabled={true}
                          >
                            {t('text.common.approved')}
                          </CommonButton>
                        )}
                    </div>
                  </div>
                </>
              ) : !state?.isLoading && !Object.keys(details)?.length ? (
                <NoDataFound
                  source="/no-booking.svg"
                  text={t('text.common.noRequestDetails')}
                  extraClass="noDataFoundWrapper w-100"
                  isCustomer={'/assets/images/admin/no-booking.svg'}
                />
              ) : (
                <GlobalLoader />
              )}
            </Container>
            {!state?.isLoading && Object.keys(state?.details)?.length > 0 && (
              <div className="d-flex justify-content-center mt-sm-3 mt-3">
                <CommonButton
                  type="button"
                  className="font-sm btn btn-info btn-lg min-170 justify-content-center align-center"
                  onClick={() => {
                    handlePrint('quoteWrapper', userType);
                  }}
                >
                  {t('text.common.print')}
                </CommonButton>
              </div>
            )}
          </div>
        </div>
      </div>
      {state?.showModal && (
        <ModalWrapper
          heading={state?.modalHeading}
          show={state?.showModal}
          content={modalObj[state?.showModal]}
          onCancel={handleClose}
          extraClassName={state?.showModal === 'decline' && 'modal-lg'}
          extraTitleClassName="align-items-start modal-title h4"
          size={state?.showModal === 'requestDetails' && 'lg'}
        ></ModalWrapper>
      )}
    </>
  );
}

export default ApproveQuote;
