import { SERVICE_MANAGEMENT_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';

export const getServiceManagementService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...SERVICE_MANAGEMENT_ENDPOINT?.getServiceManagementList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};
export const getViewQuoteDetailsService = async (id) => {
  try {
    const payload = {
      ...SERVICE_MANAGEMENT_ENDPOINT.getViewQuoteDetails(id),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const getInvoiceDetailsService = async (id) => {
  try {
    const payload = {
      ...SERVICE_MANAGEMENT_ENDPOINT.getInvoiceDetails(id),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const getBookingLogDetailsService = async ({ queryParams }) => {
  let { id, ...extraQurey } = queryParams;
  try {
    const payload = {
      ...SERVICE_MANAGEMENT_ENDPOINT.getBookingLogDetails(id),
      queryParams: extraQurey,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const deleteBookingService = async (bodyData) => {
  try {
    const payload = {
      ...SERVICE_MANAGEMENT_ENDPOINT.deleteBooking,
      bodyData: bodyData?.bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const geServiceManagementExcelService = async ({
  queryParams,
  type,
}) => {
  try {
    const payload = {
      ...SERVICE_MANAGEMENT_ENDPOINT?.getServiceManagementExcel,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};
