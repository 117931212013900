import moment from 'moment';
import React, { useState } from 'react';
import { Container, Nav, Button } from 'react-bootstrap';
import { TablePlus, StorePromoCode } from '../../../components';
import Breadcrumb from '../../../components/UIElements/Breadcrumb';
import CsvExport from '../../../components/UIElements/Button/CsvExport';

import { useTable } from '../../../hooks/index.hook';
import { contentStore, storePromoTableColumn } from '../../../services/Columns';
import { getStorePromoService } from '../../../services/Store/store.service';
import { dateFormatter, logger } from '../../../utils';

function PromoCodeStore() {
  let todayDate = new Date();
  // For loader, modal, selected row, and form text
  const [filterValue, setFilter] = useState({
    fromDate: '',
    toDate: '',
    search: '',
    SearchType: undefined,
  });
  const [activeDate, setActiveDate] = useState(null);
  // For get tags list

  const [tableHandler, tableProps] = useTable(getStorePromoService);

  const {
    data,
    isLoading,
    count,
    page,
    noOfPages,
    sizePerPage,
    filter,
    sortBy,
    sortType,
  } = tableProps;
  const { onTableSort, onFilterChange, onExport } = tableHandler;

  const columns = storePromoTableColumn({
    onSort: onTableSort,
    page,
    sizePerPage,
  });

  function onReset() {
    const resetData = {
      fromDate: '',
      toDate: '',
      search: '',
      SearchType: undefined,
    };
    setFilter({
      ...filterValue,
      ...resetData,
    });
    onFilterChange(resetData);
  }

  const dateSelected = (e, defaultKey) => {
    e.preventDefault();
    defaultKey = defaultKey.key;
    setActiveDate(defaultKey);
    if (defaultKey) {
      if (defaultKey === 'today') {
        let data = {
          fromDate: dateFormatter(todayDate, 'YYYY-MM-DD'),
          toDate: dateFormatter(todayDate, 'YYYY-MM-DD'),
        };
        setFilter({
          ...filterValue,
          ...data,
        });
        onFilterChange(data);
      }
      if (defaultKey === 'yesterday') {
        let data = {
          fromDate: dateFormatter(moment().subtract(1, 'days'), 'YYYY-MM-DD'),
          toDate: dateFormatter(moment().subtract(1, 'days'), 'YYYY-MM-DD'),
        };
        setFilter({
          ...filterValue,
          ...data,
        });
        onFilterChange(data);
      }
      if (defaultKey === 'currentWeek') {
        let startOfWeek = moment().startOf('week');
        let endOfWeek = moment().endOf('week');
        let data = {
          fromDate: dateFormatter(startOfWeek, 'YYYY-MM-DD'),
          toDate: dateFormatter(endOfWeek, 'YYYY-MM-DD'),
        };
        setFilter({
          ...filterValue,
          ...data,
        });
        onFilterChange(data);
      }
      if (defaultKey === 'previousWeek') {
        let prev = moment().startOf('week').subtract(1, 'weeks');
        let end = moment().startOf('week').subtract(1, 'days');

        let data = {
          fromDate: dateFormatter(prev, 'YYYY-MM-DD'),
          toDate: dateFormatter(end, 'YYYY-MM-DD'),
        };
        setFilter({
          ...filterValue,
          ...data,
        });
        onFilterChange(data);
      }
      if (defaultKey === 'currentMonth') {
        let prev = moment().startOf('month');
        let end = moment().endOf('month');

        let data = {
          fromDate: dateFormatter(prev, 'YYYY-MM-DD'),
          toDate: dateFormatter(end, 'YYYY-MM-DD'),
        };
        setFilter({
          ...filterValue,
          ...data,
        });
        onFilterChange(data);
      }
      if (defaultKey === 'previousMonth') {
        let prev = moment().startOf('month').subtract(1, 'month');
        let end = moment().startOf('month').subtract(1, 'days');

        let data = {
          fromDate: dateFormatter(prev, 'YYYY-MM-DD'),
          toDate: dateFormatter(end, 'YYYY-MM-DD'),
        };
        setFilter({
          ...filterValue,
          ...data,
        });
        onFilterChange(data);
      }
      if (defaultKey === 'currentYear') {
        let prev = moment().startOf('year');
        let end = moment().endOf('year');

        let data = {
          fromDate: dateFormatter(prev, 'YYYY-MM-DD'),
          toDate: dateFormatter(end, 'YYYY-MM-DD'),
        };
        setFilter({
          ...filterValue,
          ...data,
        });
        onFilterChange(data);
      }
      if (defaultKey === 'previousYear') {
        let prev = moment().startOf('year').subtract(1, 'year');
        let end = moment().startOf('year').subtract(1, 'days');

        let data = {
          fromDate: dateFormatter(prev, 'YYYY-MM-DD'),
          toDate: dateFormatter(end, 'YYYY-MM-DD'),
        };
        setFilter({
          ...filterValue,
          ...data,
        });
        onFilterChange(data);
      }
    }
  };

  // For handle submit
  async function onSubmit(payload) {
    try {
      setFilter(payload);
      onFilterChange(payload);
    } catch (error) {
      logger(error);
    }
  }

  return (
    <>
      <div className="main-content promoPage">
        <div className="pageContent">
          <Container className="container-lg">
            <div className="d-flex justify-content-between align-items-center">
              <Breadcrumb
                className="flex-grow-1 me-2"
                title="Promo Code"
                breadcrumb={[]}
                type="store"
              />
              <CsvExport
                onClick={() => onExport('', columns)}
                extraClassName={`mb-3 btn-outline-info ${
                  data && data.length ? '' : 'disabled'
                }`}
              />
            </div>
            <div className="cardHeight bg-white br-10">
              <div className="commonTabs">
                <Nav className="nav-tabs">
                  {contentStore.map((date, index) => (
                    <Nav.Item key={index}>
                      <Nav.Link
                        onClick={(e) => {
                          dateSelected(e, date);
                        }}
                        className={activeDate === date?.key ? 'active' : ''}
                      >
                        {date?.name}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>

                <div className="promoPage_filter">
                  <StorePromoCode
                    onSubmit={onSubmit}
                    onReset={onReset}
                    loading={isLoading}
                    filterValue={filterValue}
                    setFilter={setFilter}
                  />
                </div>
                <div className="promoPage_table">
                  <TablePlus
                    hasLimit
                    noOfPage={noOfPages}
                    sizePerPage={sizePerPage}
                    page={page}
                    count={count}
                    tableData={data}
                    showAction={false}
                    resetAll={false}
                    tableColumns={columns}
                    tableLoader={isLoading}
                    showSorterTooltip={false}
                    isCard={false}
                    filter={filter}
                    sortBy={sortBy}
                    sortType={sortType}
                  />
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default PromoCodeStore;
