export const STORE_SIGNUP_ENDPOINT = {
  method: 'POST',
  url: '/v1/store/sign-up',
};
export const STORE_USER_ENDPOINT = {
  getProfile: (id) => ({
    url: `/v1/store/${id}`,
    method: 'GET',
  }),
  editProfile: (id) => ({
    url: `/v1/store/${id}`,
    method: 'PUT',
  }),
  editAddress: (id) => ({
    url: `/v1/store/address/${id}`,
    method: 'PUT',
  }),
  editBank: {
    url: '/v1/store/bank',
    method: 'POST',
  },
  addQuestion: {
    url: '/v1/store/question',
    method: 'POST',
  },
  addPromoCode: {
    url: '/v1/store/promo-code',
    method: 'POST',
  },
  getPromoCode: {
    url: '/v1/store/promo-code',
    method: 'GET',
  },
  updateProfile: {
    url: '/v1/store/image',
    method: 'PUT',
  },
  getDocumentAll: {
    url: '/v1/document',
    method: 'GET',
  },
  addDocument: {
    url: '/v1/admin/store/document',
    method: 'POST',
  },
  editDocument: (id) => ({
    url: `/v1/admin/store/document/${id}`,
    method: 'PUT',
  }),
};
