import React from 'react';
import {
  FormWrapper,
  Select,
  FormElementWrapper,
  CommonButton,
  TextInput,
  GlobalLoader,
  ImageElement,
} from '../../';
import { paymentInitialValues, paymentValidation } from './validation';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { expireYearList } from '../../../utils';
import { months } from '../../../services/Register';

function PaymentForm({
  onSubmit,
  cardList,
  onConfirmAlert,
  loadingCard,
  isAddNewCard,
  buttonText,
}) {
  const { t } = useTranslation();

  const yearData = expireYearList();
  const monthsData = months();
  return (
    <FormWrapper
      row={true}
      extraClassName="g-3 w-100"
      initialValues={paymentInitialValues()}
      onSubmit={(data, { setSubmitting, resetForm }) =>
        onSubmit(data, resetForm, paymentInitialValues, setSubmitting)
      }
      enableReinitialize={true}
      validation={() =>
        typeof isAddNewCard === 'undefined'
          ? paymentValidation()
          : isAddNewCard
          ? paymentValidation()
          : null
      }
      data={paymentInitialValues()}
    >
      {(props) => {
        return (
          <>
            {cardList?.map((item) => {
              return (
                <>
                  {loadingCard ? (
                    <GlobalLoader />
                  ) : (
                    <>
                      <div className="paymentPage_card form-group">
                        <div className="d-flex">
                          <div className="w-100">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="flex-grow-1 font-md">
                                {item?.name}
                                <span className="paymentPage_card_no d-block  font-md">
                                  **** **** **** {item?.lastFour}
                                </span>{' '}
                              </div>
                              <ImageElement
                                className="img-fluid paymentPage_card_logo"
                                previewSource={item?.cardIconUrl}
                                alt="logo"
                              />
                              <Link
                                to="#"
                                className="delete"
                                onClick={() => onConfirmAlert(item?.id)}
                              >
                                <em className="gg-delete" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              );
            })}
            <div className="ant-radio-group ant-radio-group-outline">
              <div className="paymentPage_card form-group">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="ant-form ant-form-horizontal">
                    <div className="paymentPage_card_form mt-0">
                      <div className="row">
                        <FormElementWrapper
                          md={12}
                          extraLabel={t('text.customerPayment.cardHolderName')}
                          // label={t('text.customerPayment.cardHolderName')}
                          mandatory={false}
                          element={() => (
                            <TextInput
                              name="name"
                              placeholder={t(
                                'text.customerPayment.cardHolderName'
                              )}
                              formik={props}
                            />
                          )}
                        />
                        <FormElementWrapper
                          md={12}
                          extraLabel={t('text.customerPayment.cardNumber')}
                          // label={t('text.customerPayment.cardNumber')}
                          mandatory={false}
                          element={() => (
                            <TextInput
                              name="cardNumber"
                              placeholder={t('text.customerPayment.cardNumber')}
                              formik={props}
                            />
                          )}
                        />
                        <FormElementWrapper
                          md={6}
                          extraLabel={t('text.customerPayment.expMonth')}
                          // label={t('text.customerPayment.expDate')}
                          mandatory={false}
                          element={() => (
                            <Select
                              name="expMonth"
                              formik={props}
                              options={monthsData}
                              placeholder="Month"
                              showSearch
                            />
                          )}
                        />
                        <FormElementWrapper
                          md={6}
                          extraLabel={t('text.customerPayment.expYear')}
                          mandatory={false}
                          element={() => (
                            <Select
                              name="expYear"
                              placeholder="Year"
                              formik={props}
                              options={yearData}
                              showSearch
                            />
                          )}
                        />
                        <FormElementWrapper
                          md={12}
                          extraLabel={t('text.customerPayment.cvv')}
                          mandatory={false}
                          element={() => (
                            <TextInput
                              name="cvv"
                              placeholder={t('text.customerPayment.cvv')}
                              formik={props}
                              inputType="password"
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <CommonButton
                  type="submit"
                  disabled={props.isSubmitting}
                  loading={props.isSubmitting}
                  className="font-sm ms-auto min-200 btn-lg"
                  variant="primary"
                >
                  {buttonText ? buttonText : t('text.common.addCard')}
                </CommonButton>
              </div>
            </div>
          </>
        );
      }}
    </FormWrapper>
  );
}

export default PaymentForm;
