import loadable from '@loadable/component';
import FullPageLoader from '../../components/UIElements/Loader/fullPageLoader';

export const Home = loadable(() => import('./Home'), {
  fallback: <FullPageLoader />,
});
export const MyBooking = loadable(() => import('./MyBooking'), {
  fallback: <FullPageLoader />,
});
export const ServiceDetails = loadable(() => import('./ServiceDetails'), {
  fallback: <FullPageLoader />,
});
export const TipsOfMonth = loadable(() => import('./TipsOfMonth'), {
  fallback: <FullPageLoader />,
});
export const FinanceOption = loadable(() => import('./FinancingOption'), {
  fallback: <FullPageLoader />,
});
export const ServiceProvider = loadable(() => import('./ServiceProvider'), {
  fallback: <FullPageLoader />,
});
export const ProviderProfile = loadable(() => import('./Profile/viewProfile'), {
  fallback: <FullPageLoader />,
});
export const EditProfile = loadable(() => import('./Profile/editProfile'), {
  fallback: <FullPageLoader />,
});
export const ChangePassword = loadable(() => import('./ChangePassword'), {
  fallback: <FullPageLoader />,
});
export const CustomerHome = loadable(() => import('./CustomerHome/index'), {
  fallback: <FullPageLoader />,
});
export const Payment = loadable(() => import('./Payment/index'), {
  fallback: <FullPageLoader />,
});
export const ServiceProviderDetails = loadable(
  () => import('./ServiceProviderDetails'),
  {
    fallback: <FullPageLoader />,
  }
);
export const ProviderContact = loadable(() => import('./ProviderContactUs'), {
  fallback: <FullPageLoader />,
});
export const ServiceTrack = loadable(() => import('./ServiceTrack'), {
  fallback: <FullPageLoader />,
});
export const RequestDetails = loadable(() => import('./RequestDetails'), {
  fallback: <FullPageLoader />,
});
export const RatingReview = loadable(() => import('./RatingReview'), {
  fallback: <FullPageLoader />,
});
export const Notification = loadable(() => import('./Notification'), {
  fallback: <FullPageLoader />,
});
export const PayOnlineGuest = loadable(() => import('./PayOnlineGuest'), {
  fallback: <FullPageLoader />,
});

export const MemberShipPayment = loadable(() =>
  import('./Payment/MembershipPayment')
);
