import i18next from 'i18next';
import { accessRoute } from '../../routes/CustomerApp/home';
export const customerSearchProviderBreadcrumb = () => {
  return [
    {
      path: accessRoute.HOME.path,
      name: i18next.t('text.common.home'),
    },
    {
      path: '#',
      name: i18next.t('text.customerService.searchServiceProvider'),
    },
  ];
};

export const customerProviderDetailsBreadcrumb = () => {
  return [
    {
      path: accessRoute.HOME.path,
      name: i18next.t('text.common.home'),
    },
    {
      path: '#',
      name: i18next.t('text.customerService.serviceProviderDetails'),
    },
  ];
};

export const customerProviderContactUsBreadcrumb = () => {
  return [
    {
      path: accessRoute.HOME.path,
      name: i18next.t('text.common.home'),
    },
    {
      path: '#',
      name: i18next.t('text.customerService.serviceProviderContactUs'),
    },
  ];
};

export const customerProviderListBreadcrumb = () => {
  return [
    {
      path: accessRoute.HOME.path,
      name: i18next.t('text.common.home'),
    },
    {
      path: '#',
      name: i18next.t('text.customerService.serviceProviderList'),
    },
  ];
};
