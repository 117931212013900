import i18next from 'i18next';
import * as yup from 'yup';
import { Regex } from '../../../helpers/regex.helper';
import { valueMapper } from '../../../helpers/schema.helper';

export const initValues = (data) => {
  const initVal = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    countryPhoneCode: '+1',
  };
  return valueMapper(initVal, data);
};

export const validation = () => {
  return yup.object().shape({
    firstName: yup
      .string()
      .trim()
      .max(30, i18next.t('validation.common.maxNameValid'))
      .min(2, i18next.t('validation.common.minFirstName'))
      .required(i18next.t('validation.common.firstName')),
    lastName: yup
      .string()
      .trim()
      .max(30, i18next.t('validation.common.maxNameValid'))
      .min(2, i18next.t('validation.common.minLastName'))
      .required(i18next.t('validation.common.lastName')),
    email: yup
      .string()
      .trim()
      .email(i18next.t('validation.common.validEmail'))
      .required(i18next.t('validation.common.email')),
    phoneNumber: yup
      .string()
      .required(i18next.t('validation.signup.phoneNumber'))
      .matches(Regex.onlyNumber, {
        message: i18next.t('validation.common.onlyNumber'),
        excludeEmptyString: false,
      })
      .matches(Regex.phoneNumber, {
        message: i18next.t('validation.signup.minPhoneNo'),
      }),
  });
};

export const CompanyInitValues = (data) => {
  const initVal = {
    name: '',
    address: '',
    logo: '',
    noOfStaff: '',
    noOfFieldTechnician: '',
    areaServed: '',
    role: '',
    isDefault: undefined,
    viewImageUrl: '',
    roleId: undefined,
    paymentMode: undefined,
  };

  return valueMapper(initVal, data);
};

export const CompanyValidation = (defaultRole) => {
  return yup.object().shape({
    name: yup
      .string()
      .trim()
      .typeError(i18next.t('validation.common.companyName'))
      .nullable()
      .required(i18next.t('validation.common.companyName')),
    address: yup
      .string()
      .trim()
      .typeError(i18next.t('validation.common.companyAddress'))
      .nullable()
      .required(i18next.t('validation.common.companyAddress')),

    noOfStaff: yup
      .number()
      .typeError(i18next.t('validation.common.onlyNumber'))
      .moreThan(-1, i18next.t('validation.common.numberNegative'))
      .lessThan(10001, i18next.t('validation.common.officeMoreThan'))
      .nullable()
      .required(i18next.t('validation.common.numberOfficeStaff')),
    noOfFieldTechnician: yup
      .number()
      .typeError(i18next.t('validation.common.onlyNumber'))
      .moreThan(-1, i18next.t('validation.common.numberNegative'))
      .lessThan(10001, i18next.t('validation.common.techniciansMoreThan'))
      .nullable()
      .required(i18next.t('validation.common.techniciansInstallers')),
    areaServed: yup
      .string()
      .trim()
      .typeError(i18next.t('validation.common.areaServed'))
      .nullable()
      .required(i18next.t('validation.common.areaServed')),
    isDefault: yup.string().nullable().required('Please select default key'),
    roleId: yup
      .string()
      .nullable()
      .required(i18next.t('validation.signup.role')),
    paymentMode: yup
      .string()
      .nullable()
      .required(i18next.t('validation.signup.duration')),
  });
};

export const initCardValues = (data) => {
  const initCardVal = {
    name: '',
    cardNumber: '',
    expMonth: data?.currentMonth,
    expYear: data?.currentYear,
    cvv: '',
  };
  return valueMapper(initCardVal, data);
};

export const cardValidation = () => {
  return yup.object().shape({
    name: yup
      .string()
      .trim()
      .nullable()
      .required(i18next.t('validation.common.cardHolder')),
    cardNumber: yup
      .string()
      .trim()
      .matches(Regex.cardNumber, {
        message: i18next.t('validation.signup.enterValidNumber'),
      })
      .nullable()
      .required(i18next.t('validation.signup.cardNumber')),
    expMonth: yup
      .string()
      .nullable()
      .required(i18next.t('validation.report.expMonth')),
    expYear: yup
      .string()
      .nullable()
      .required(i18next.t('validation.report.expYear')),
    cvv: yup
      .string()
      .trim()
      .matches(Regex.cvv, {
        message: i18next.t('validation.signup.enterValidNumber'),
      })
      .nullable()
      .required(i18next.t('validation.signup.cvv')),
  });
};

export const initPermissionValues = (data) => {
  const initCardVal = {
    term_of_use: '',
    privacy_policy: '',
    about_us: '',
    term_of_use_show: 0,
    privacy_policy_show: 0,
    about_us_show: 0,
  };
  return valueMapper(initCardVal, data);
};

export const permissionValidation = () => {
  return yup.object().shape({
    term_of_use: yup
      .string()
      .matches(Regex.urlRegex, {
        message: i18next.t('validation.nca.validUrl'),
        excludeEmptyString: false,
      })
      .nullable()
      .required(i18next.t('validation.nca.terms')),
    privacy_policy: yup
      .string()
      .matches(Regex.urlRegex, {
        message: i18next.t('validation.nca.validUrl'),
        excludeEmptyString: false,
      })
      .nullable()
      .required(i18next.t('validation.nca.privacyPolicy')),
    about_us: yup
      .string()
      .matches(Regex.urlRegex, {
        message: i18next.t('validation.nca.validUrl'),
        excludeEmptyString: false,
      })
      .nullable()
      .required(i18next.t('validation.nca.aboutUs')),
  });
};
