export const text = {
  title: 'Book Appointment',
  providerNotFound: 'Provider Not found',
  notAvailableMessage:
    ' Sorry no service provider is available, and the schedule is full for the remaining part of the day. Please schedule appointment for next open time slot.',
  bookNow: 'Book Now',
  bookNextTimeSlot: 'Book appointment for next open time slot',
  loaderMessageOne: 'Thank you for choosing us!',
  loaderMessageTwo: 'All techs have over 5 years of field experience',
  loaderMessageThree: 'Sometimes our  technicians are with customer',
  loaderMessageFour: 'Do not hit the X! We are almost confirmed',
  requestingDescription: `This is a live same day service request sent directly to our technicians. We will confirm your appointment shortly under 'My Bookings'.`,
  confirmationDescription:
    'This will cancel your booking request. We will have updated in less than a minute. Are you sure you want to cancel ?',
  errorBookingMassage: 'have been declined your request for job',
  WillCallList: 'Will Call List',
  acceptBookingMassage: 'have been accepted your request for job',
};
