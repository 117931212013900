export const validation = {
  image: 'Image is required',
  title: 'Title is required',
  status: 'Status is required',
  order: 'Order is required',
  providerTimeoutRequired: 'Provider timeout is required',
  providerTimeoutMinimum: 'Minimum provider timeout should be 40 seconds',
  minimumSplitAmountRequired: 'Minimum job amount is required',
  minimumSplitAmountAtLeast: 'Minimum split job amount should be 50',
  milesRadiusAroundUserRequired: 'Miles radius around user is required',
  milesRadiusAroundUserMinimum:
    'Minimum miles radius around user should be 100 miles',
  providerReachUserRequired:
    'Approx time for provider to reach user is required',
  providerReachUserMinimum:
    'Minimum approx time for provider to reach user should be 60 minutes',
  providerListLimitRequired: 'Online provider list limit is required',
  providerListLimitMinimum: 'Minimum online provider list limit should be 100',
};
