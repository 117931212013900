import { NCA_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';

export const getNCAService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...NCA_ENDPOINT?.getNCAList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const updateNCAStatus = async (bodyData) => {
  try {
    const payload = {
      ...NCA_ENDPOINT.NCAStatusUpdate,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const linkNCAService = async ({ bodyData, id }) => {
  try {
    const payload = {
      ...NCA_ENDPOINT.NCALink(id),
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getExistingCategoryService = async ({ bodyData }) => {
  try {
    const payload = {
      ...NCA_ENDPOINT.NCAExistingCategory,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getNCADocumentsService = async (queryParams) => {
  try {
    const payload = {
      ...NCA_ENDPOINT.NCADocument,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
// EDit NCA
export const editNCAProfileService = async ({ body, id }) => {
  try {
    const payload = {
      ...NCA_ENDPOINT.editNCAProfile(id),
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const editNCACompanyProfileService = async ({ body, id }) => {
  try {
    const payload = {
      ...NCA_ENDPOINT.editNCACompanyProfile(id),
      bodyData: body,
      isAdminToken: true,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const editNCACardService = async ({ body, id }) => {
  try {
    const payload = {
      ...NCA_ENDPOINT.editNCACard(id),
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const editNCABusinessService = async ({ bodyData, id }) => {
  try {
    const payload = {
      ...NCA_ENDPOINT.editNCABusiness(id),
      bodyData,
      isAdminToken: true,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const editNCABankingService = async ({ body, id }) => {
  try {
    const payload = {
      ...NCA_ENDPOINT.editNCABanking(id),
      bodyData: body,
      isAdminToken: true,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const sendEmailNCAProfileService = async ({ id }) => {
  try {
    const payload = {
      ...NCA_ENDPOINT.sendEmailNCAProfile(id),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const goToDashboardService = async ({ body }) => {
  try {
    const payload = {
      ...NCA_ENDPOINT.goToDashboard,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
// Get NCA
export const getNCAProfileService = async ({ queryParams, type, id }) => {
  try {
    const payload = {
      ...NCA_ENDPOINT?.getNCAProfile(id),
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getNCACompanyProfileService = async ({
  queryParams,
  type,
  id,
}) => {
  try {
    const payload = {
      ...NCA_ENDPOINT?.getNCACompanyProfile(id),
      queryParams,
      isAdminToken: true,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getNCACardService = async ({ queryParams, type, id }) => {
  try {
    const payload = {
      ...NCA_ENDPOINT?.getNCACard(id),
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getNCABusinessService = async ({ queryParams, type, id }) => {
  try {
    const payload = {
      ...NCA_ENDPOINT?.getNCABusiness(id),
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getNCABankingService = async ({ queryParams, type, id }) => {
  try {
    const payload = {
      ...NCA_ENDPOINT?.getNCABanking(id),
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const addNcaCardService = async ({ bodyData }) => {
  try {
    const payload = {
      ...NCA_ENDPOINT.ncaAddCard,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const getCardListService = async ({ queryParams }) => {
  try {
    const payload = {
      ...NCA_ENDPOINT.getCardList,
      queryParams,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const deleteCardService = async (id) => {
  try {
    const payload = {
      ...NCA_ENDPOINT.deleteCard(id),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const defaultCardService = async (id) => {
  try {
    const payload = {
      ...NCA_ENDPOINT.defaultCard(id),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const bankDetailsService = async () => {
  try {
    const payload = {
      ...NCA_ENDPOINT.getNcaBankDetails,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const businessDetailsService = async () => {
  try {
    const payload = {
      ...NCA_ENDPOINT.getNcaBusinessDetails,
      isAdminToken: true,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getAllPermissionService = async () => {
  try {
    const payload = {
      ...NCA_ENDPOINT.getAllPermission,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const putPageService = async ({ bodyData }) => {
  try {
    const payload = {
      ...NCA_ENDPOINT.putNcaPage,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const putNcaBUsinessDetailService = async ({ bodyData }) => {
  try {
    const payload = {
      ...NCA_ENDPOINT.putNcaBusinessDetails,
      bodyData,
      isAdminToken: true,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const putNcaBankDetailService = async ({ bodyData }) => {
  try {
    const payload = {
      ...NCA_ENDPOINT.putNcaBankDetails,
      bodyData,
      isAdminToken: true,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getPageService = async () => {
  try {
    const payload = {
      ...NCA_ENDPOINT.getNcaPage,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const putPermissionService = async ({ bodyData }) => {
  try {
    const payload = {
      ...NCA_ENDPOINT.putPermission,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const addCompanyProfileService = async ({ bodyData }) => {
  try {
    const payload = {
      ...NCA_ENDPOINT.companyProfilePut,
      bodyData,
      isAdminToken: true,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const getCompanyProfileService = async () => {
  try {
    const payload = {
      ...NCA_ENDPOINT.companyProfileGet,
      isAdminToken: true,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const defaultRoleAssignService = async () => {
  try {
    const payload = {
      ...NCA_ENDPOINT.defaultRole,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const cancelPlanService = async () => {
  try {
    const payload = {
      ...NCA_ENDPOINT.cancelPlan,
      isErrorData: true,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const listAdminNCACompanyService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...NCA_ENDPOINT?.getAdminNCACompanyProfile,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const postNCAPlanPaymentService = async (bodyData) => {
  try {
    const payload = {
      ...NCA_ENDPOINT?.postNCAPlanPayment,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const listAdminNCALogReportsService = async ({ queryParams }) => {
  let { id, ...extraQurey } = queryParams;
  try {
    const payload = {
      ...NCA_ENDPOINT?.getLogReports(id),
      queryParams: extraQurey,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const listNCALogReportsService = async ({ queryParams }) => {
  let { id, ...extraQurey } = queryParams;
  try {
    const payload = {
      ...NCA_ENDPOINT?.getNcaLogReports(id),
      queryParams: extraQurey,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const putNcaPaymentSettlement = async (id) => {
  try {
    const payload = {
      ...NCA_ENDPOINT?.putNcaPaymentSettlement(id),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
export const getNcaPaymentReports = async ({ queryParams }) => {
  const { userId, ...extraQuery } = queryParams;
  try {
    const payload = {
      ...NCA_ENDPOINT?.getNcaPaymentReport(userId),
      queryParams: { ...extraQuery },
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
export const getNcaPaymentReportTotal = async (query) => {
  const { id, ...restQuery } = query;

  try {
    const payload = {
      ...NCA_ENDPOINT?.ncaPaymentReportTotal(id),
      queryParams: { ...restQuery },
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
