import {
  getColumnSearchMultipleInputProps,
  getColumnSelectSearchProps,
  getInputSearchFilter,
} from '../../components';
import { getFromToDateFilter } from '../../components/Filter/Common/date.filter';
import {
  checkValidData,
  checkValidPrice,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { accessRoute } from '../../routes/Dashboard';
import { accessRoute as shoppingAccessRoutes } from '../../routes/ShoppingCart';
import i18next from 'i18next';
import { getOrderStatusFilter, getOrderTypeList } from '../index.service';
import { firstLetterCaps, getFullName } from '../../utils/text.util';
import { dateFormatter } from '../../utils';
import { DATE_MONTH_ORDER_YEAR, MONTH_DATE_YEAR } from '../../config';
export const orderManagementBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.common.orderManagement'),
    },
  ];
};

export const orderViewInvoiceBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: shoppingAccessRoutes.ORDER_MANAGEMENT_LIST.path,
      name: i18next.t('text.common.orderManagement'),
    },
    {
      path: '#',
      name: i18next.t('text.common.orderInvoice'),
    },
  ];
};

export const orderManagementTableColumn = ({
  Action,
  Toggle,
  onFilterChange,
  filter,
  page,
  sizePerPage,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.sno'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'orderNumber',
      title: i18next.t('text.shoppingCart.orderID'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'orderNumber',
        placeholder: 'order id',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'userName',
      title: i18next.t('text.shoppingCart.spName'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) =>
        getFullName(
          row?.ProviderDetails?.firstName,
          row?.ProviderDetails?.lastName
        ),
      ...getInputSearchFilter({
        dataIndex: 'userName',
        placeholder: 'SP name',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'email',
      title: i18next.t('text.common.email'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => checkValidData(row?.ProviderDetails?.email),
      ...getInputSearchFilter({
        dataIndex: 'email',
        placeholder: 'email',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'pickType',
      title: i18next.t('text.shoppingCart.orderType'),
      headerClasses: 'sorting',
      sorter: false,
      render: (cell, row) =>
        checkValidData(
          cell === 'self'
            ? i18next.t('text.shoppingCart.selfPickup')
            : i18next.t('text.shoppingCart.shipping')
        ),
      ...getColumnSelectSearchProps({
        dataIndex: 'pickType',
        name: 'pick type',
        list: getOrderTypeList(),
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'shippingAddress',
      title: i18next.t('text.shoppingCart.shippingAddress'),
      headerClasses: 'sorting',
      sorter: false,
      render: (cell, row) =>
        checkValidData(row?.ShippigAddressDetails?.shippingAddress),
      ...getInputSearchFilter({
        dataIndex: 'shippingAddress',
        placeholder: 'delivery address',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'total',
      title: i18next.t('text.shoppingCart.totalCost'),
      headerClasses: 'sorting',
      filterDataIndex: ['fromPrice', 'toPrice'],
      sorter: true,
      render: (cell, row) => checkValidPrice(cell),
      ...getColumnSearchMultipleInputProps({
        dataStartIndex: 'fromPrice',
        dataEndIndex: 'toPrice',
        placeholderStartPrice: 'From Price',
        placeholderEndPrice: 'To Price',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'orderDate',
      title: i18next.t('text.shoppingCart.orderDateTime'),
      headerClasses: 'sorting',
      filterDataIndex: ['fromDate', 'toDate'],
      sorter: true,
      render: (cell, row) => dateFormatter(cell, DATE_MONTH_ORDER_YEAR),
      ...getFromToDateFilter({
        dataStartIndex: 'fromDate',
        dataEndIndex: 'toDate',
        placeholderStartDate: 'From date',
        placeholderEndDate: 'To date',
        dateFormat: 'YYYY/MM/DD',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'status',
      title: 'Order Status',
      headerClasses: 'sorting',
      sorter: false,
      render: (cell, row) => {
        return <Toggle row={row} cell={cell} isDropDown />;
      },
      ...getColumnSelectSearchProps({
        dataIndex: 'status',
        placeholder: 'status',
        list: getOrderStatusFilter(),
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'action',
      title: 'Action',
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => <Action row={row} cell={cell} />,
    },
  ];
};
