import i18next from 'i18next';
import * as yup from 'yup';
import { Regex } from '../../../helpers/regex.helper';
import { valueMapper } from '../../../helpers/schema.helper';

export const initValues = (formData) => {
  let initValue = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phoneNumber: '',
    zipCode: '',
    address: '',
    countryPhoneCode: '+1',
    scheduledJobRadius: 5,
    emergencyJobRadius: 5,
    countryId: null,
    stateId: null,
    cityId: null,
    profilePicture: '',
    gender: '',
    commissionType: null,
    commissionFees: null,
    isSendMarketingMessage: '',
    googleAdAccountId: null,
    googleCampaignIds: [],
    ...formData,
  };

  return initValue;
};

export const validation = (commissionType, data, userRole) => {
  return yup.object().shape({
    firstName: yup
      .string()
      .trim()
      .max(30, i18next.t('validation.common.maxNameValid'))
      .min(2, i18next.t('validation.common.minFirstName'))
      .nullable()
      .required(i18next.t('validation.common.firstName')),
    lastName: yup
      .string()
      .trim()
      .max(30, i18next.t('validation.common.maxNameValid'))
      .min(2, i18next.t('validation.common.minLastName'))
      .nullable()
      .required(i18next.t('validation.common.lastName')),
    email: yup
      .string()
      .email(i18next.t('validation.common.validEmail'))
      .trim()
      .nullable()
      .required(i18next.t('validation.common.email')),
    password: yup
      .string()
      .trim()
      .when('email', {
        is: () => data,
        then: yup.string().trim(),
        otherwise: yup
          .string()
          .trim()
          .nullable()
          .required(i18next.t('validation.common.password')),
      })
      .min(6, i18next.t('validation.common.minValidPassword'))
      .matches(Regex.password, {
        message: i18next.t('validation.common.strongPassword'),
        excludeEmptyString: false,
      }),
    // .required(i18next.t('validation.common.password')),
    phoneNumber: yup
      .string()
      .nullable()
      .required(i18next.t('validation.common.phone'))
      // .min(10, i18next.t('validation.common.minPhone'))
      .matches(Regex.phoneNumber, {
        message: i18next.t('validation.common.phoneValid'),
        excludeEmptyString: false,
      }),
    zipCode: yup
      .string()
      .trim()
      .nullable()
      .matches(Regex.zipCodeReg, {
        message: i18next.t('validation.common.zipCodeValid'),
        excludeEmptyString: false,
      }),
    address: yup
      .string()
      .trim()
      .nullable()
      .required(i18next.t('validation.common.address')),
    countryId: yup
      .string()
      .typeError(i18next.t('validation.common.country'))
      .nullable()
      .required(i18next.t('validation.common.country')),
    stateId: yup
      .string()
      .typeError(i18next.t('validation.common.state'))
      .nullable()
      .required(i18next.t('validation.common.state')),
    cityId: yup
      .string()
      .typeError(i18next.t('validation.common.city'))
      .nullable()
      .required(i18next.t('validation.common.city')),
    scheduledJobRadius: yup
      .string()
      .typeError(i18next.t('validation.provider.scheduledWorkAreaRadius'))
      .nullable()
      .required(i18next.t('validation.provider.scheduledWorkAreaRadius')),
    scheduledJobRadius1: yup.string().when('scheduledJobRadius', {
      is: (val) => val === 'other',
      then: yup
        .string()
        .typeError(i18next.t('validation.provider.scheduledWorkAreaRadius'))
        .max(5, 'Radius should be less than 100000')
        .nullable()
        .required(i18next.t('validation.provider.scheduledWorkAreaRadius'))
        .matches(Regex.onlyIntNumber, {
          message: i18next.t('validation.common.onlyIntNumber'),
          excludeEmptyString: false,
        }),
      otherwise: yup.string().nullable(),
    }),
    emergencyJobRadius: yup
      .string()
      .typeError(i18next.t('validation.provider.emergencyWorkAreaRadius'))
      .nullable()
      .required(i18next.t('validation.provider.emergencyWorkAreaRadius')),

    emergencyJobRadius1: yup.string().when('emergencyJobRadius', {
      is: (val) => val === 'other',
      then: yup
        .string()
        .typeError(i18next.t('validation.provider.emergencyWorkAreaRadius'))
        .nullable()
        .required(i18next.t('validation.provider.emergencyWorkAreaRadius'))
        .max(5, 'Radius should be less than 5')
        .matches(Regex.onlyIntNumber, {
          message: i18next.t('validation.common.onlyIntNumber'),
          excludeEmptyString: false,
        }),
      otherwise: yup.string().nullable(),
    }),

    ...(commissionType === 'percentage' &&
      userRole !== 'nca' && {
        commissionFees: yup
          .number()
          // .required(i18next.t('validation.provider.adminCommissionFees'))
          .nullable()
          .typeError(i18next.t('validation.provider.adminCommissionFeesValid'))
          .moreThan(
            -1,
            i18next.t('validation.provider.adminCommissionFeesNegative')
          )
          .lessThan(
            101,
            i18next.t('validation.provider.adminCommissionFeesMoreThan')
          ),
      }),
    ...(commissionType === 'hourly' &&
      userRole !== 'nca' && {
        commissionFees: yup
          .number()
          .nullable()
          // .required(i18next.t('validation.provider.adminCommissionFees'))
          .typeError(i18next.t('validation.provider.adminCommissionFeesValid'))
          .moreThan(
            -1,
            i18next.t('validation.provider.adminCommissionFeesNegative')
          ),
      }),
  });
};

export const componyProfileInitValues = (formData) => {
  return {
    company: '',
    companyFrontId: null,
    companyBackId: null,
    employeeId: '',
    address: '',
    dateOfBirth: '',
    ...formData,
  };
};
export const validationCompanyProfile = () => {
  return yup.object().shape({
    company: yup
      .string()
      .trim()
      .max(50, i18next.t('validation.common.maxValid'))
      .nullable()
      .required(i18next.t('validation.provider.componyName')),
    dateOfBirth: yup
      .string()
      .nullable()
      .required(i18next.t('validation.provider.dateOfBirth')),
    address: yup
      .string()
      .trim()
      .max(50, i18next.t('validation.common.maxValid'))
      .nullable()
      .required(i18next.t('validation.provider.homeAddress')),
    employeeId: yup
      .number()
      .nullable()
      .required(i18next.t('validation.provider.employerIdNo'))
      .typeError(i18next.t('validation.common.onlyNumber'))
      .max(9)
      .min(9)
      .moreThan(-1, i18next.t('validation.provider.employerIdNoValidNegative'))
      .test(
        'maxDigits',
        i18next.t('validation.common.noMoreThanNineDigits'),
        (number) => String(number).length <= 9
      ),
    companyFrontId: yup
      .string()
      .nullable()
      .required(i18next.t('validation.provider.uploadDocument1')),
    companyBackId: yup
      .string()
      .nullable()
      .required(i18next.t('validation.provider.uploadDocument2')),
  });
};

export const businessDetailsInitValues = (formData) => {
  const yearAgo = new Date();
  yearAgo.setFullYear(yearAgo.getFullYear - 18);
  let initValue = {
    businessType: 'individual',
    firstName: '',
    lastName: '',
    email: '',
    jobTitle: '',
    dateOfBirth: '',
    addressLine1: '',
    addressLine2: '',
    state: '',
    city: '',
    zipCode: '',
    phoneNumber: '',
    employeeId: '',
    socialSecurityNumber: '',
    identityDocumentFront: '',
    identityDocumentBack: '',
    identityDocumentFrontThumbUrl: '',
    identityDocumentBackThumbUrl: '',
    company: '',
    doingBusinessAs: null,
    countryId: null,
    industryIds: undefined,
    businessWebsite: '',
    taxId: null,
    vatId: '',
    ownershipPercentage: '',
    ownershipType: '',
    ...formData,
  };
  return initValue;
};

export const validationBusinessDetails = (radioType) => {
  return yup.object().shape({
    ownershipPercentage:
      radioType === 'percent_ownership'
        ? yup
            .number()
            .nullable()
            .required(i18next.t('validation.provider.ownershipPercentage'))
            .typeError(i18next.t('validation.provider.ownershipPercentage'))
            .moreThan(
              -1,
              i18next.t('validation.provider.ownershipPercentageNegative')
            )
            .lessThan(
              101,
              i18next.t('validation.provider.ownershipPercentageMoreThan')
            )
        : null,

    businessType: yup
      .string()
      .trim()
      .typeError(i18next.t('validation.provider.businessType'))
      .nullable()
      .required(i18next.t('validation.provider.businessType')),
    firstName: yup.string().when('businessType', {
      is: (val) => val === 'individual',
      then: yup
        .string()
        .trim()
        .nullable()
        .required(i18next.t('validation.provider.firstName')),
      otherwise: yup.string().trim().nullable(),
    }),
    lastName: yup.string().when('businessType', {
      is: (val) => val === 'individual' || val === null,
      then: yup
        .string()
        .trim()
        .nullable()
        .required(i18next.t('validation.provider.lastName')),
      otherwise: yup.string().trim().nullable(),
    }),
    email: yup
      .string()
      .trim()
      .nullable()
      .when('businessType', {
        is: (val) => val === 'individual' || val === null,
        then: yup
          .string()
          .trim()
          .email()
          .nullable()
          .required(i18next.t('validation.provider.email')),
        otherwise: yup.string().trim().email().nullable(),
      }),
    jobTitle: yup.string().when('businessType', {
      is: (val) => val === 'individual' || val === null,
      then: yup
        .string()
        .trim()
        .nullable()
        .required(i18next.t('validation.provider.jobTitle')),
      otherwise: yup.string().nullable(),
    }),
    dateOfBirth: yup.string().when('businessType', {
      is: (val) => val === 'individual' || val === null,
      then: yup
        .string()
        .trim()
        .nullable()
        .required(i18next.t('validation.provider.dateOfBirth')),
      otherwise: yup.string().trim().nullable(),
    }),
    countryId: yup.string().when('businessType', {
      is: (val) => val === 'individual',
      then: yup
        .string()
        .trim()
        .nullable(true)
        .typeError(i18next.t('validation.provider.homeAddress'))
        .max(99, i18next.t('validation.common.maxValid'))
        .required(i18next.t('validation.provider.homeAddress')),
      otherwise: yup
        .string()
        .trim()
        .nullable(true)
        .typeError(i18next.t('validation.provider.businessAddress'))
        .max(99, i18next.t('validation.common.maxValid'))
        .required(i18next.t('validation.provider.businessAddress')),
    }),
    addressLine1: yup
      .string()
      .trim()
      .nullable()
      .max(99, i18next.t('validation.common.maxValid'))
      .required(i18next.t('validation.provider.address')),

    addressLine2: yup
      .string()
      .trim()
      .nullable()
      .max(99, i18next.t('validation.common.maxValid')),

    state: yup
      .string()
      .typeError(i18next.t('validation.provider.state'))
      .trim()
      .nullable()
      .max(99, i18next.t('validation.common.maxValid'))
      .required(i18next.t('validation.provider.state')),
    city: yup
      .string()
      .trim()
      .nullable()
      .typeError(i18next.t('validation.provider.city'))
      .max(99, i18next.t('validation.common.maxValid'))
      .required(i18next.t('validation.provider.city')),
    zipCode: yup
      .string()
      .trim()
      .nullable()
      .matches(Regex.zipCodeReg, {
        message: i18next.t('validation.common.zipCodeValid'),
        excludeEmptyString: false,
      }),
    // .max(50, i18next.t('validation.common.maxValid50'))
    // .required(i18next.t('validation.provider.zipCode')),
    phoneNumber: yup
      .string()
      .trim()
      .nullable()
      .matches(Regex.phoneNumber, {
        message: i18next.t('validation.common.phoneValid'),
        excludeEmptyString: false,
      })
      .required(i18next.t('validation.provider.phoneNumber')),
    socialSecurityNumber: yup.string().when('businessType', {
      is: (val) => val === 'individual' || val === null,
      then: yup
        .string()
        .trim()
        .nullable()
        .matches(Regex.onlyNumber, {
          message: i18next.t('validation.common.onlyNumber'),
          excludeEmptyString: false,
        })
        .max(4, 'Security number should be equal to 4 digit')
        .min(4, 'Security number should be equal to 4 digit')
        .required(i18next.t('validation.provider.socialNumber')),
      otherwise: yup.string().nullable(),
    }),
    identityDocumentFront: yup.string().when('businessType', {
      is: (val) => val === 'individual' || val === null,
      then: yup
        .string()
        .trim()
        .nullable()
        .required(i18next.t('validation.provider.documentFront')),
      otherwise: yup.string().trim().nullable(),
    }),
    identityDocumentBack: yup.string().when('businessType', {
      is: (val) => val === 'individual' || val === null,
      then: yup
        .string()
        .trim()
        .nullable()
        .required(i18next.t('validation.provider.documentBack')),
      otherwise: yup.string().trim().nullable(),
    }),
    company: yup.string().when('businessType', {
      is: (val) => val === 'individual',
      then: yup.string().trim().nullable(true),
      otherwise: yup
        .string()
        .trim()
        .nullable()
        .typeError(i18next.t('validation.provider.companyName'))
        .required(i18next.t('validation.provider.companyName')),
    }),
    doingBusinessAs: yup.string().when('businessType', {
      is: (val) => val === 'individual',
      then: yup.string().trim().nullable(true),
      otherwise: yup
        .string()
        .trim()
        .nullable()
        .typeError(i18next.t('validation.provider.business'))
        .required(i18next.t('validation.provider.business')),
    }),
    employeeId: yup.string().when('businessType', {
      is: (val) => val === 'individual',
      then: yup.string().trim().nullable(true),
      otherwise: yup
        .string()
        .typeError(i18next.t('validation.provider.employeeNumber'))
        .nullable()
        .required(i18next.t('validation.provider.employeeNumber'))
        .matches(Regex.onlyNumber, {
          message: i18next.t('validation.common.onlyNumber'),
          excludeEmptyString: false,
        })
        .max(9, i18next.t('validation.common.maxValidNine'))
        .min(9, i18next.t('validation.common.minValidNine')),
    }),
    industryIds: yup.array().when('businessType', {
      is: (val) => val !== 'individual',
      then: yup
        .array()
        .of(yup.string())
        .required(i18next.t('validation.provider.industry')),
    }),
    businessWebsite: yup.string().when('businessType', {
      is: (val) => val === 'individual',
      then: yup.string().trim().nullable(true),
      otherwise: yup
        .string()
        .trim()
        .nullable()
        .matches(Regex.urlRegex, {
          message: i18next.t('validation.common.websiteUrl'),
          excludeEmptyString: false,
        })
        .typeError(i18next.t('validation.provider.businessWebsite'))
        .required(i18next.t('validation.provider.businessWebsite')),
    }),
    taxId: yup.string().when('businessType', {
      is: (val) => val === 'individual',
      then: yup.string().trim().nullable(true),
      otherwise: yup
        .string()
        .trim()
        .nullable()
        .matches(Regex.onlyNumber, {
          message: i18next.t('validation.common.onlyNumber'),
          excludeEmptyString: false,
        })
        .max(9, i18next.t('validation.common.maxValidNine'))
        .min(9, i18next.t('validation.common.minValidNine'))
        .typeError(i18next.t('validation.provider.businessIdNumber'))
        .required(i18next.t('validation.provider.businessIdNumber')),
    }),
    vatId: yup.string().when('businessType', {
      is: (val) => val === 'individual',
      then: yup.string().trim().nullable(true),
      otherwise: yup
        .string()
        .trim()
        .nullable(true)
        .matches(Regex.onlyNumber, {
          message: i18next.t('validation.provider.vatValid'),
          excludeEmptyString: false,
        })
        .required(i18next.t('validation.provider.vatNumber')),
    }),
  });
};

export const bankingDetailsInitValues = (formData) => {
  return {
    bankName: '',
    bankAccountHolderName: '',
    accountNumber: '',
    bankAccountRoutingNumber: '',
    bankLocation: '',
    email: '',
    ...formData,
  };
};

export const validationBankingDetails = () => {
  return yup.object().shape({
    bankName: yup
      .string()
      .trim()
      .max(100, i18next.t('validation.common.maxValid'))
      .nullable()
      .required(i18next.t('validation.provider.bankName')),
    bankAccountHolderName: yup
      .string()
      .trim()
      .max(50, i18next.t('validation.common.maxValid50'))
      .nullable()
      .required(i18next.t('validation.provider.bankHolderName')),
    accountNumber: yup
      .string()
      .trim()
      .matches(Regex.accountNumberRegex, {
        message: i18next.t('validation.provider.accountNumberMatch'),
        excludeEmptyString: false,
      })
      .max(17, i18next.t('validation.provider.accountMaxValid'))
      .nullable()
      .required(i18next.t('validation.provider.accountNumber')),
    bankAccountRoutingNumber: yup
      .string()
      .trim()
      .min(9, i18next.t('validation.provider.routingMinValid'))
      .max(50, i18next.t('validation.common.maxValid50'))
      .nullable()
      .required(i18next.t('validation.provider.routingNumber')),
    bankLocation: yup
      .string()
      .trim()
      .nullable()
      .required(i18next.t('validation.provider.bankLocation')),
    email: yup
      .string()
      .email(i18next.t('validation.common.validEmail'))
      .nullable()
      .required(i18next.t('validation.provider.email')),
  });
};

export const validationDocument = (isRequiredExp) => {
  return yup.object().shape({
    documentImage: yup
      .string()
      .trim()
      .typeError(i18next.t('validation.provider.docImageRequired'))
      .nullable()
      .required(i18next.t('validation.provider.docImageRequired')),

    ...(isRequiredExp === 1 && {
      expireDate: yup
        .string()
        .trim()
        .typeError(i18next.t('validation.common.expireDateRequired'))
        .nullable()
        .required(i18next.t('validation.common.expireDateRequired')),
    }),
  });
};
// for edit Edit Availability Values
export const editAvailabilityValues = () => {
  return {
    serviceProviderManual: 2,
    timeSelectType: 1,
  };
};

// for edit Edit Unavailability Values
export const editUnavailabilityValues = (formData) => {
  return valueMapper(
    {
      isTimeOffRestrict: 0,
      isAutoApproveBookingRestrict: 0,
      isJobRadiusRestrict: 0,
      isWorkLocationRestrict: 0,
      autoApproveBooking: 0,
      locationType: 'any_location',
      scheduledJobRadius: 5,
      emergencyJobRadius: 5,
      workLocation: '',
      otherScheduleJob: '',
      otherEmergencyJob: '',
    },
    formData
  );
};
