import React, { useEffect, useState } from 'react';
import { Image } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { NoDataFound, PageContainerWrapper } from '../../../components';
import {
  Columns,
  getInstalledEquipmentImages,
} from '../../../services/index.service';
import { useTranslation } from 'react-i18next';
import { backToPage } from '../../../utils';
import { accessRoute } from '../../../routes/HomeInventory';

function ViewInventory() {
  const { t } = useTranslation();
  const [equipmentData, setEquipmentData] = useState([]);
  const [imageError, setImageError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const redirectUrl = accessRoute.HOME_INVENTORY.path;
  const breadcrumb = Columns.installedEquipmentImages();
  const navigate = useNavigate();
  const headerBtn = {
    extraButton: { name: 'Back' },
  };

  async function getEquipmentImages(id) {
    try {
      setLoading(true);
      const res = await getInstalledEquipmentImages(id);
      setEquipmentData(res?.data?.rows);
      setLoading(false);
      return res;
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
    setLoading(false);
  }
  function onError({ currentTarget }) {
    setImageError(true);
    try {
      currentTarget.onerror = null; // prevents looping
      currentTarget.src = '/assets/images/admin/img_default-placeholder.png';
    } catch (err) {
      return currentTarget;
    }
  }

  useEffect(() => {
    getEquipmentImages(id);
  }, []);

  return (
    <>
      <div className="wide-md mx-auto">
        <PageContainerWrapper
          btnArray={['extraButton']}
          btn={headerBtn}
          extraBtnText={t('text.common.back')}
          extraBtnClass="btn btn-outline-light bg-white"
          onExtraButtonHandleShow={() => backToPage(navigate, redirectUrl)}
          heading={t('text.homeInventory.installedEquipmentImage')}
          breadcrumb={breadcrumb}
          loading={loading}
          loadingText={false}
        >
          <div className="nk-block">
            <div className="card">
              <div className="card-inner">
                {loading ? null : equipmentData?.length > 0 ? (
                  <Image.PreviewGroup>
                    <h6 className="mb-3">
                      {equipmentData[0]?.HomeInventoryEquipment?.quoteType}{' '}
                    </h6>
                    <div className="row g-3 g-xl-4">
                      {equipmentData?.map((data) => {
                        return (
                          <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="googleMediaListImage">
                              <Image
                                src={
                                  imageError
                                    ? '/assets/images/admin/img_default-placeholder.png'
                                    : data?.imageThumbUrl
                                }
                                preview={{
                                  src: imageError
                                    ? '/assets/images/admin/img_default-placeholder.png'
                                    : data?.imageUrl,
                                }}
                                onError={onError}
                                loading="lazy"
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Image.PreviewGroup>
                ) : (
                  <NoDataFound
                    text={t('text.homeInventory.noImageFound')}
                    source="/no-booking.svg"
                    isCustomer={'/assets/images/admin/no-booking.svg'}
                  />
                )}
              </div>
            </div>
          </div>
        </PageContainerWrapper>
      </div>
    </>
  );
}

export default ViewInventory;
