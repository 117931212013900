import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  Link,
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  Breadcrumb,
  CommonButton,
  GlobalLoader,
  ImageElement,
  ModalComponent,
} from '../../../components';
import BookingDetailsForm from '../../../components/Forms/CustomerApp/bookingDetails.form';
import ChooseBookingForm from '../../../components/Forms/CustomerApp/chooseBooking.form';
import { checkValidData } from '../../../components/UIElements/Formatter';
import { accessRoute } from '../../../routes/CustomerApp/searchProvider';
import { accessRoute as bookingHomeRoute } from '../../../routes/CustomerApp/booking';
import { accessRoute as scheduleProviderRoutes } from '../../../routes/CustomerApp/scheduleServiceProvider';
import { getServiceProviderDetailService } from '../../../services/CustomerApp/index.service';
import { Columns } from '../../../services/index.service';
import { generateStarRating, logger } from '../../../utils';
import { firstLetterCaps } from '../../../utils/text.util';
import Favorite from '../../../components/Favorite';

function ServiceProviderDetails() {
  const { t } = useTranslation();
  const [providers, setProviders] = React.useState();
  const [chooseBooking, setChooseBooking] = useState(false);
  const [bookingDetail, setbookingDetail] = useState(false);
  const [showRequested, setRequested] = useState(false);
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const breadcrumb = Columns.customerProviderDetailsBreadcrumb();

  const chooseBookingClose = () => {
    setChooseBooking(false);
  };
  const chooseBookingShow = () => {
    setChooseBooking(true);
  };

  const bookingDetailClose = () => {
    setbookingDetail(false);
  };

  const bookingDetailShow = () => {
    chooseBookingClose();
    setbookingDetail(true);
  };

  const RequestedClose = () => {
    setRequested(false);
  };

  const RequestedShow = () => {
    bookingDetailClose();
    setRequested(true);
  };

  useEffect(() => {
    if (!id) {
      navigate(bookingHomeRoute.BOOKING.path);
    }
    if (id) getServiceProviderDetail(id);
  }, []);

  async function getServiceProviderDetail(id) {
    setLoading(true);
    try {
      const res = await getServiceProviderDetailService(
        id,
        'admin-provider-min-details'
      );
      if (res?.success) {
        setProviders(res?.data);
      }
    } catch (err) {
      logger(err);
    }
    setLoading(false);
  }
  return (
    <div className="main-content providerDetailPage">
      <div className="pageContent">
        <div className="pageContent_wide">
          <Container>
            <div className="d-flex justify-content-between">
              <Breadcrumb
                title="Service Provider Detail"
                breadcrumb={breadcrumb}
                type="customer"
              />
              <CommonButton
                onClick={() => navigate(-1)}
                className="btn btn-outline-info ms-3 mb-3 btn-sm"
              >
                {t('text.common.back')}
              </CommonButton>
            </div>

            <div className="detailsBox br-10 p-30 bg-white">
              {loading ? (
                <GlobalLoader />
              ) : (
                <>
                  <div className="detailsBox_user text-center position-relative">
                    <ImageElement
                      previewSource={providers?.profilePictureThumbUrl}
                      alt="user"
                    />
                    <div className="mt-md-3 mt-2">
                      <h3 className="font-md">
                        {checkValidData(
                          `${providers?.firstName}` &&
                            `${firstLetterCaps(
                              providers?.firstName ?? ''
                            )} ${firstLetterCaps(providers?.lastName ?? '')}`
                        )}
                      </h3>
                      <p className="ft-14 mb-0 text-750">
                        <ul className="list-unstyled d-flex starRating mb-0 justify-content-center">
                          {generateStarRating(providers?.rating ?? 0)}
                        </ul>
                      </p>
                    </div>
                    <div className="appointmentList_like">
                      <Favorite
                        userId={providers?.id}
                        isFavorite={providers?.isFavorite === 1 ? true : false}
                      />
                    </div>
                  </div>
                  <div className="detailsBox_desc">
                    {providers?.Provider?.aboutMe ? (
                      <p className="ft-14">{providers?.Provider?.aboutMe}</p>
                    ) : null}
                  </div>

                  {/* <div className="detailsBox_desc">
                    <p className="ft-14">
                      {providers?.aboutMe && providers?.aboutMe}
                    </p>
                  </div> */}
                  <div className="d-flex justify-content-end">
                    {state?.isBookingFlow ? (
                      <>
                        <Link
                          to={`${accessRoute.PROVIDERS.path}`}
                          className="btn btn-light me-3 min-200"
                        >
                          {t('text.common.cancel')}
                        </Link>
                        <CommonButton
                          variant="primary"
                          className="min-200"
                          onClick={() => chooseBookingShow()}
                        >
                          {t('text.customerService.bookNow')}
                        </CommonButton>
                      </>
                    ) : (
                      <Link
                        to={generatePath(
                          scheduleProviderRoutes.SERVICE_PROVIDER_CONTACT_US
                            .path,
                          {
                            id: id,
                          }
                        )}
                        state={{
                          providerId: id,
                        }}
                        className="btn btn-secondary float-right mr-auto"
                      >
                        {t('text.common.contactTech')}
                      </Link>
                    )}

                    <ModalComponent
                      show={chooseBooking}
                      onHandleCancel={chooseBookingClose}
                      closeButton
                      extraTitleClassName="align-items-start"
                      title={t('text.customerService.serviceDetail')}
                      size="xl"
                      modalExtraClass="chooseBookingmodal"
                      backdrop="static"
                    >
                      {/* <div className="chooseBookingmodal"> */}
                      <ChooseBookingForm
                        onClose={chooseBookingClose}
                        t={t}
                        show={bookingDetailShow}
                      />
                      {/* </div> */}
                    </ModalComponent>

                    <ModalComponent
                      show={bookingDetail}
                      onHandleCancel={bookingDetailClose}
                      closeButton
                      extraTitleClassName="align-items-start"
                      title={t('text.customerService.bookingDetails')}
                      size="lg"
                      modalExtraClass="bookingDetails"
                      backdrop="static"
                    >
                      <div className="chooseBookingmodal">
                        <Row className="align-items-center">
                          <Col lg={7} sm={12}>
                            <ul className="bookingDetails list-unstyled mb-0">
                              <li className="bookingDetails_list d-flex">
                                <label>
                                  {t('text.customerService.service')}
                                </label>
                                <span className="font-sm">
                                  FL - Heating System Tuneup
                                </span>
                              </li>
                              <li className="bookingDetails_list d-flex">
                                <label>
                                  {t('text.customerService.quantity')}
                                </label>
                                <span className="font-sm">1</span>
                              </li>
                              <li className="bookingDetails_list d-flex">
                                <label>
                                  {t('text.customerService.provider')}
                                </label>
                                <span className="font-sm">
                                  MARK SERVICE MAN
                                </span>
                              </li>
                              <li className="bookingDetails_list d-flex">
                                <label>
                                  {t('text.customerService.bookingDate')}
                                </label>
                                <span className="font-sm">Nov 04,2022</span>
                              </li>
                              <li className="bookingDetails_list d-flex">
                                <label>
                                  {t('text.customerService.bookingTime')}
                                </label>
                                <span className="font-sm">
                                  11:00 AM - 02:00 PM
                                </span>
                              </li>
                            </ul>
                          </Col>
                          <Col lg={5} sm={12}>
                            <ul className="serviceCost list-unstyled mt-lg-0 mt-3 mb-0">
                              <li className="serviceCost_list">
                                <label>
                                  {' '}
                                  {t('text.customerService.serviceCost')}
                                </label>
                                <span className="font-md">$128.98</span>
                              </li>
                              <li className="serviceCost_list">
                                <label>
                                  {t('text.customerService.promoCode')}
                                </label>
                                <span className="font-md">-$30.00</span>
                              </li>
                              <li className="serviceCost_list">
                                <label>
                                  {' '}
                                  {t('text.customerService.totalCost')}
                                </label>
                                <span className="font-sm">$98.98</span>
                              </li>
                            </ul>
                          </Col>
                        </Row>
                        <BookingDetailsForm
                          onClose={bookingDetailClose}
                          t={t}
                          show={RequestedShow}
                        />
                      </div>
                    </ModalComponent>

                    <ModalComponent
                      show={showRequested}
                      onHandleCancel={RequestedClose}
                      closeButton
                      extraTitleClassName="align-items-start"
                      title={t('text.customerService.bookingRequested')}
                      size="lg"
                      modalExtraClass="paidEmergencymodel"
                      backdrop="static"
                    >
                      <>
                        <div className="paidEmergency">
                          <p>
                            {t('text.customerService.yourProviderHasReceived')}
                          </p>
                        </div>
                        <div className="d-flex justify-content-flex-end">
                          <Link
                            to={bookingHomeRoute.BOOKING.path}
                            className="btn btn-primary ms-auto min-200"
                          >
                            {t('text.customerService.viewBooking')}
                          </Link>
                        </div>{' '}
                      </>
                    </ModalComponent>
                  </div>
                </>
              )}
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default ServiceProviderDetails;
