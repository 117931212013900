import i18next from 'i18next';
import {
  checkValidData,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { accessRoute } from '../../routes/Dashboard';
import { logoImageFormatter } from '../../utils';

export const bannerList = ({ Action, Toggle, onSort, page, sizePerPage }) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'bannerImageUrl',
      title: i18next.t('text.common.image'),
      render: (cell, row) => logoImageFormatter(row?.bannerImageUrl, 'Banner'),
    },
    {
      dataIndex: 'title',
      title: i18next.t('text.common.title'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell) => checkValidData(cell),
    },
    {
      dataIndex: 'displayOrder',
      title: i18next.t('text.common.order'),
      headerClasses: 'sorting',
      sort: true,
      onSort: (field, order) => onSort('displayOrder', order),
      render: (cell) => checkValidData(cell),
    },
    {
      dataIndex: 'status',
      title: i18next.t('text.common.status'),
      headerClasses: 'sorting',
      render: (cell, row) => <Toggle cell={cell} row={row} />,
      sorter: true,
    },
    {
      dataIndex: 'Action',
      title: i18next.t('text.common.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => <Action cell={cell} row={row} />,
    },
  ];
};

export const bannerBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.common.banner'),
    },
  ];
};
