export const CUSTOMER_APP_SETTING = {
  getNotificationTemplate: {
    url: '/v1/admin/notification-template',
    method: 'GET',
  },
  updateNotificationId:(id)=>({
    url: `/v1/admin/notification-template/${id}`,
    method: 'PUT'
  }),
  getNotificationById: (id) => ({
    url: `/v1/admin/notification-template/${id}`,
    method: 'GET'
  }),
  getBanner: {
    url: '/v1/banner',
    method: 'GET',
  },
  addBanner: {
    url: '/v1/admin/banner',
    method: 'POST',
  },
  deleteBanner:(id)=>({
    url: `/v1/admin/banner/${id}`,
    method: 'DELETE'
  }),
  getBannerById: (id) => ({
    url: `/v1/admin/banner/${id}`,
    method: 'GET'
  }),
  updateBannerStatusId:(id)=>({
    url: `/v1/admin/banner/change-status/${id}`,
    method: 'PUT'
  }),
  updateBannerId: (id) => ({
    url: `/v1/admin/banner/${id}`,
    method: 'PUT'
  })
};
