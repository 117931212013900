import React from 'react';
import { Skeleton } from 'antd';

function Shimmer({ type = 'button' }) {
  if (type === 'button') {
    return (
      <>
        <Skeleton.Button
          active={true}
          size={'large'}
          shape={'default'}
          block={false}
        />
      </>
    );
  }
  return <div></div>;
}

export default Shimmer;
