import { PROVIDER_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';

export const addProviderService = async ({ values }) => {
  try {
    const payload = {
      ...PROVIDER_ENDPOINT?.addProvider,
      bodyData: values,
    };
    return await APIRequest(payload);
  } catch (err) {
    logger(err);
  }
};

export const viewProviderService = async (id) => {
  try {
    const queryParams = {ignore:"deleted"}
    const payload = {
      ...PROVIDER_ENDPOINT?.viewProviderProfile(id),
      queryParams
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const editProviderService = async ({ id, values }) => {
  try {
    const payload = {
      ...PROVIDER_ENDPOINT?.editProviderProfile(id),
      bodyData: values,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
export const editCompanyDetailsService = async ({ id, values }) => {
  try {
    const payload = {
      ...PROVIDER_ENDPOINT?.editCompanyProfile(id),
      bodyData: values,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
export const getCompanyDetailsService = async (id) => {
  try {
    const payload = {
      ...PROVIDER_ENDPOINT?.getCompanyProfile(id),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const editBusinessDetailsService = async ({ id, bodyData }) => {
  try {
    const payload = {
      ...PROVIDER_ENDPOINT?.editBusinessDetails(id),
      bodyData: bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
export const getBusinessDetailsService = async (id) => {
  try {
    const payload = {
      ...PROVIDER_ENDPOINT?.getBusinessDetails(id),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const editBankingDetailsService = async ({ id, values }) => {
  try {
    const payload = {
      ...PROVIDER_ENDPOINT?.editBankingDetails(id),
      bodyData: values,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
export const getBankingDetailsService = async (id) => {
  try {
    const payload = {
      ...PROVIDER_ENDPOINT?.getBankingDetails(id),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
