let url = '/v1/admin/service-subcategory';
const customerSubCategoryUrl = '/v1/service-subcategory';

export const SERVICES_SUB_CATEGORY_ENDPOINT = {
  getSubServiceCategoryList: {
    url,
    method: 'GET',
  },
  statusSubServiceCategory: {
    url: `${url}/change-status`,
    method: 'PUT',
  },
  customerSubCategoryUrl: {
    url:customerSubCategoryUrl,
    method: 'GET',
  },
  deleteSubServiceCategory: {
    url,
    method: 'DELETE',
  },
  addSubServiceCategory: {
    url,
    method: 'POST',
  },
  editSubServiceCategory: (id) => ({
    url: `${url}/${id}`,
    method: 'PUT',
  }),
  getSubServiceCategory: (id) => ({
    url: `${url}/${id}`,
    method: 'GET',
  }),
};
