import React, { useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Link, generatePath, useLocation, useParams } from 'react-router-dom';
import {
  Breadcrumb,
  ImageElement,
  ModalComponent,
} from '../../../../components';
import {
  Columns,
  viewFooterSocialLinkService,
} from '../../../../services/index.service';
import { accessRoute } from '../../../../routes/CustomerApp/payment';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import {
  customerLink,
  getSocialMediaLinks,
} from '../../../../redux/index.redux';
import { useSelector, useDispatch } from 'react-redux';
import { useMemo } from 'react';

function SelectPaymentMethod({ payNow = false }) {
  const location = useLocation();
  const { id } = useParams();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const socialMediaLinks = useSelector(getSocialMediaLinks);
  const breadcrumb = Columns.customerPaymentBreadcrumb();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  const handleData = (type) => {
    socialMediaLinks?.rows?.find(
      (item) => item?.key === type && openInNewTab(item?.value)
    );
  };

  return (
    <>
      <div className="main-content paymentPage">
        {/* page content */}
        <div className="pageContent">
          <div className="pageContent_wide">
            <Container className="container-lg">
              <Breadcrumb
                type="customer"
                title={t('text.customerPayment.title')}
                breadcrumb={breadcrumb}
              />
              {/* Payment page content */}
              <div className="br-10 p-30 bg-white">
                <h2 className="text-850 font-sm paymentPage_head_title paymentPage_head_title-selectTitle">
                  {t('text.customerPayment.howYouLikeToPay')}
                </h2>
                <Row className="g-3">
                  <Col sm={6}>
                    <Link
                      onClick={() =>
                        localStorage.setItem(
                          'paymentState',
                          JSON.stringify(location?.state)
                        )
                      }
                      to={
                        payNow
                          ? generatePath(accessRoute.PAY_NOW_PAYMENT.path, {
                              id: location?.state?.bookingId || id,
                            })
                          : accessRoute.PAYMENT.path
                      }
                      state={location?.state}
                      className="paymentPage_item d-flex justify-content-center align-items-center flex-column"
                    >
                      <div className="icons d-flex justify-content-center align-items-center">
                        <em className="gg-credit-card" />
                      </div>
                      <p className="font-md">
                        {t('text.customerPayment.creditCard')}
                      </p>
                    </Link>
                  </Col>
                  <Col sm={6}>
                    <div
                      type="button"
                      onClick={handleShow}
                      className="paymentPage_item d-flex justify-content-center align-items-center flex-column"
                    >
                      <div className="icons d-flex justify-content-center align-items-center">
                        <em className="gg-cash-payment" />
                      </div>
                      <p className="font-md">
                        {t('text.customerPayment.cashPayment')}
                      </p>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div
                      onClick={handleShow}
                      type="button"
                      className="paymentPage_item d-flex justify-content-center align-items-center flex-column"
                    >
                      <div className="icons d-flex justify-content-center align-items-center">
                        <em className="gg-cheque" />
                      </div>
                      <p className="font-md">
                        {t('text.customerPayment.cheque')}
                      </p>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div
                      type="button"
                      onClick={handleShow}
                      className="paymentPage_item d-flex justify-content-center align-items-center flex-column"
                    >
                      <div className="icons d-flex justify-content-center align-items-center">
                        <em className="gg-financing" />
                      </div>
                      <p className="font-md">
                        {t('text.customerPayment.financing')}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </div>
      </div>
      <ModalComponent
        title={t('text.common.titlePaymentModal')}
        show={show}
        size="md"
        onHandleCancel={handleClose}
        onHide={handleClose}
        extraTitleClassName="text-center"
      >
        <div className="d-flex align-items-center justify-content-center">
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              handleData('android_app_website_footer');
            }}
            className="me-2"
          >
            <ImageElement
              source="google-play.svg"
              alt="Google-play"
              className="img-fluid"
            />
          </Link>
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              handleData('iphone_app_website_footer');
            }}
          >
            <ImageElement
              source="app-store.svg"
              alt="App-store"
              className="img-fluid"
            />
          </Link>
        </div>
      </ModalComponent>
    </>
  );
}

export default SelectPaymentMethod;
