import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EditHomeForm, PageContainerWrapper } from '../../../../../components';
import { formSubmit } from '../../../../../hooks/useFormSubmit.hook';
import { accessRoute } from '../../../../../routes/AdminSetting';
import { editHomePageBreadcrumb } from '../../../../../services/Columns';
import {
  editHomePageService,
  homePageService,
} from '../../../../../services/HomePage';

import { backToPage } from '../../../../../utils';
import { useTable } from '../../../../../hooks/index.hook';
import { useTranslation } from 'react-i18next';

function EditHomePage() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [updatedData, setUpdatedData] = useState([]);
  const navigate = useNavigate();
  const [, tableProps] = useTable(homePageService);
  const { isLoading, data } = tableProps;
  const breadcrumb = editHomePageBreadcrumb();
  const redirectUrl = accessRoute.LIST_HOMEPAGE.path;

  useEffect(() => {
    let thirdSectionObj =
      data?.length && data.find((e) => e?.key === 'third_sec_image');

    let filterData =
      data?.length && data.filter((e) => e?.key !== 'third_sec_image');

    filterData?.length &&
      filterData.splice(21, 0, {
        ...thirdSectionObj,
        name: 'Fourth Section Image',
      });
    setUpdatedData(filterData);
  }, [data]);

  return (
    <div className="wide-md mx-auto">
      <PageContainerWrapper
        breadcrumb={breadcrumb}
        btnArray={['extraButton']}
        extraBtnText={t('text.common.back')}
        extraBtnClass="btn btn-outline-light bg-white"
        onExtraButtonHandleShow={() => backToPage(navigate, redirectUrl)}
        heading={t('text.common.editHomeContent')}
        editHomeContent
      >
        <div className="nk-block">
          <div className="card">
            <div className="card-inner">
              <EditHomeForm
                formData={updatedData}
                onSubmit={(values) => {
                  delete values?.home_banner_left_image_url;
                  delete values?.right_banner_image_url;
                  delete values?.third_mid_image_one_url;
                  delete values?.third_mid_image_two_url;
                  delete values?.third_mid_image_three_url;
                  delete values?.mobile_app_bg_img_url;
                  delete values?.mobile_app_left_img_url;
                  formSubmit({
                    values,
                    service: editHomePageService,
                    navigate,
                    redirectUrl,
                    setIsLoading: setLoading,
                  });
                }}
                isLoading={loading}
                getApiLoader={isLoading}
                backToPage={() => backToPage(navigate, redirectUrl)}
              />
            </div>
          </div>
        </div>
      </PageContainerWrapper>
    </div>
  );
}

export default EditHomePage;
