import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  AccordionComponent,
  Breadcrumb,
  GlobalLoader,
  NoDataFound,
} from '../../../components';
import { getFAQs } from '../../../services/CustomerApp/index.service';
import { Columns } from '../../../services/index.service';
import { useLocation } from 'react-router-dom';

function FAQs() {
  const { t } = useTranslation();
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const breadcrumb = Columns.customerFaqsBreadcrumb(
    location?.pathname?.includes('store')
  );

  async function getFaqs() {
    setLoading(true);
    try {
      const res = await getFAQs({
        queryParams: {
          status: 'active',
          sortBy: 'displayOrder',
          sortType: 'asc',
        },
      });
      if (res?.success && res?.data?.rows) {
        setState(res?.data?.rows);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }

  useEffect(() => {
    getFaqs();
  }, []);

  return (
    <>
      <main className="aboutPage">
        <Container>
          <Breadcrumb
            title={t('text.common.faqs')}
            breadcrumb={breadcrumb}
            type="customer"
          />
          <div className="aboutPage_inner">
            {/* <h2 className="aboutPage_title lg mt-0 mb-0">FAQ's</h2> */}

            {loading ? (
              <GlobalLoader variant="primary" />
            ) : state && state?.length > 0 ? (
              <AccordionComponent AccordionContent={state} />
            ) : (
              <div className="noList m-auto text-center d-flex align-items-center justify-content-center">
                <div className="noList_inner">
                  <NoDataFound
                    source="/nodata-found.svg"
                    text={t('text.common.noDataFound')}
                    isCustomer={'/assets/images/admin/nodata-found.svg'}
                  />
                </div>
              </div>
            )}
          </div>
        </Container>
      </main>
    </>
  );
}

export default FAQs;
