import React from 'react';
import ImageElement from '../ImageElement';

function NoDataFound({
  source,
  text,
  extraClass = '',
  isCustomer = false,
  titleExtraClass,
}) {
  return (
    <>
      <div
        className={`${extraClass} emptySec text-center position-relative d-flex flex-column justify-content-center align-items-center`}
      >
        <ImageElement
          source={source}
          className="img-fluid svg"
          alt="no-messages"
          isCustomer={isCustomer}
        />
        <h4 className={`${titleExtraClass} text-400 emptySec_text mt-3`}>
          {text}
        </h4>
      </div>
    </>
  );
}

export default NoDataFound;
