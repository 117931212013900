import i18next from 'i18next';
import {
  getInputSearchFilter,
  getMultiSelectSearchFilter,
} from '../../components';
import { getStatus, getStatusData, getTupleStatusList } from '../index.service';
import { accessRoute } from '../../routes/Dashboard';
import {
  checkValidData,
  commasFormatter,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { readMoreTextShow } from '../../utils';
import { firstLetterCaps } from '../../utils/text.util';
export const variantManagementBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.shoppingCart.variantManagement'),
    },
  ];
};
export const variantManagementTableColumn = ({
  Action,
  Toggle,
  page,
  sizePerPage,
  onFilterChange,
  filter,
  onSort,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'variantName',
      filterDataIndex: ['variantName'],
      title: i18next.t('text.shoppingCart.variantName'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('variantName', order),
      render: (cell, row) => checkValidData(firstLetterCaps(cell)),
      ...getInputSearchFilter({
        dataIndex: 'variantName',
        placeholder: 'variant name',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'attributeNames',
      filterDataIndex: ['attributeNames'],
      title: i18next.t('text.shoppingCart.attributeName'),
      sorter: false,
      onSort: (field, order) => onSort('attributeNames', order),
      render: (cell, row) =>
        row?.productVariantAttributeDetail?.length > 0
          ? readMoreTextShow(
              commasFormatter(
                row?.productVariantAttributeDetail?.map(
                  (item) => item?.attributeNames
                )
              ),
              i18next.t('text.shoppingCart.attributeName')
            )
          : 'N/A',
      ...getInputSearchFilter({
        dataIndex: 'attributeNames',
        placeholder: 'attribute name',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'status',
      title: i18next.t('text.common.status'),
      headerClasses: 'sorting',
      render: (cell, row) => <Toggle cell={cell} row={row} />,
      sorter: true,
      onSort: (field, order) => onSort('status', order),
      ...getMultiSelectSearchFilter({
        dataIndex: 'status',
        onFilterChange,
        filter,
        name: 'status',
        listService: getStatusData,
        mode: false,
      }),
    },
    {
      dataIndex: 'Action',
      title: i18next.t('text.common.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => <Action cell={cell} row={row} />,
    },
  ];
};
