import { APIRequest } from '../../helpers/index.helper';
import { CHAT_ENDPOINT } from '../../apiEndpoints/index.endpoint';

import { logger } from '../../utils';

export const getChatRoomListService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...CHAT_ENDPOINT.getChatRoomList,
      queryParams,
    };

    const res = await APIRequest(payload);
    if (type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getChatRoomMessagesByRoomIdService = async (
  roomId,
  queryParams,
  signal
) => {
  try {
    const payload = {
      ...CHAT_ENDPOINT.getChatRoomMessagesByRoomId(roomId),
      queryParams,
      signal,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const postAddRoomService = async ({ body }) => {
  try {
    const payload = {
      ...CHAT_ENDPOINT.postAddChatRoom,
      bodyData: body,
      isErrorData: true,
      isErrorToast: false,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    return err.res;
  }
};

export const putEditRoomService = async ({roomId, body }) => {
  try {
    const payload = {
      ...CHAT_ENDPOINT.putEditChatRoom(roomId),
      bodyData: body,
      isErrorData: true,
      isErrorToast: false,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    return err.res;
  }
};

export const getChatRoomDetailsByIdService = async ({
  roomId,
  cancelFunction,
}) => {
  try {
    const payload = {
      ...CHAT_ENDPOINT.getChatRoomDetailsById(roomId),
      cancelFunction,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const postAddChatRoomMemberService = async ({ body }) => {
  try {
    const payload = {
      ...CHAT_ENDPOINT.postAddChatRoomMember,
      bodyData: body,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const deleteChatRoomMemberByIdService = async ({ memberId, roomId }) => {
  try {
    const payload = {
      ...CHAT_ENDPOINT.deleteChatRoomMemberById(memberId, roomId),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const deleteChatGroupByIdService = async ({ roomId }) => {
  try {
    const payload = {
      ...CHAT_ENDPOINT.deleteChatGroupById(roomId),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
export const postChatSendMediaByIdService = async (roomId, queryParams) => {
  try {
    const payload = {
      ...CHAT_ENDPOINT.postChatSendMediaById(roomId),
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const downloadChatRoomMediaByIdService = async (mediaId) => {
  try {
    const payload = {
      ...CHAT_ENDPOINT.downloadChatMediaById(mediaId),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
