import { Empty } from 'antd';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { PageContainerWrapper, TablePlus } from '../../../../components';
import useTable from '../../../../hooks/useTable.hook';
import { accessRoute } from '../../../../routes/GoogleConnect';
import { GoogleServiceList } from '../../../../services/GoogleConnect';
import { Columns } from '../../../../services/index.service';

function Leads() {
  const breadcrumb = Columns.googleServiceLeadBreadcrumb();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const googleServiceData = Columns.customerDataGMB();
  // For get google service  list
  const [tableHandler, tableProps] = useTable(googleServiceData);
  const { count, page, noOfPages, sizePerPage } = tableProps;
  const { onTableSort } = tableHandler;
  const columns = Columns.leadsColumns({ onSort: onTableSort });
  const redirectUrl = accessRoute.GOOGLE_SERVICE.path;
  const backToPage = (navigate, url) => {
    navigate(url);
  };
  const [loading, setLoading] = useState(false);
  const [leadData, setLeadData] = useState([]);
  async function getGoogleLeadData(value) {
    setLoading(true);
    try {
      let queryParams = {
        accountId: value,
      };
      const response = await GoogleServiceList.getGoogleLeadList({
        queryParams,
      });

      const { data, success } = response;
      if (success && data?.detailedLeadReports.length > 0) {
        setLeadData(data?.detailedLeadReports);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (id) {
      getGoogleLeadData(id);
    }
  }, [id]);

  return (
    <>
      {' '}
      <PageContainerWrapper
        heading={t('text.googleConnect.googleLocalServiceManagement')}
        breadcrumb={breadcrumb}
        btnArray={['extraButton']}
        extraBtnText={t('text.common.back')}
        extraBtnClass="btn btn-outline-light bg-white"
        onExtraButtonHandleShow={() => backToPage(navigate, redirectUrl)}
      >
        <TablePlus
          hasLimit
          tableLoader={loading}
          noOfPage={noOfPages}
          sizePerPage={sizePerPage}
          page={page}
          count={count}
          tableData={leadData}
          tableColumns={columns}
          selectRow={false}
          resetAll={false}
          pagination={false}
          locale={{
            emptyText: <Empty description={t('text.common.noDataFound')} />,
          }}
        />
      </PageContainerWrapper>
    </>
  );
}

export default Leads;
