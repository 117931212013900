import { APIRequest } from '../../helpers/index.helper';
import { CUSTOMER_APP_SETTING } from '../../apiEndpoints/CustomerAppSetting/index.endpoint';
import { logger } from '../../utils';

export const notificationTemplateService = async ({ queryParams }) => {
  try {
    const payload = {
      ...CUSTOMER_APP_SETTING.getNotificationTemplate,
      queryParams,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const updateNotification = async ({ resourceId, body }) => {
  try {
    const payload = {
      ...CUSTOMER_APP_SETTING.updateNotificationId(resourceId),
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err); 
    logger(err);
  }
}

export const getNotificationTemplateService = async ({ resourceId }) => {
  try {
    const payload = {
      ...CUSTOMER_APP_SETTING.getNotificationById(resourceId),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};