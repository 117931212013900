import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageContainerWrapper, EditSmsForm } from '../../../../components';
import { backToPage } from '../../../../utils';
import { useTranslation } from 'react-i18next';
import { editSmsMessageBreadcrumb } from '../../../../services/Columns';
import { accessRoute } from '../../../../routes/EmailSetting';
import useSubmit from '../../../../hooks/useSubmit.hook';
import {
  editSmsService,
  viewSmsService,
} from '../../../../services/EmailMessage/emailTemplate.service';

function EditSMS() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const breadcrumb = editSmsMessageBreadcrumb();
  const redirectUrl = accessRoute.SMS_TEMPLATES.path;

  const [onSubmit, data, isLoading] = useSubmit({
    service: editSmsService,
    detailsService: viewSmsService,
    id,
    afterSubmitActionType: 'redirect',
    redirectUrl,
  });
  return (
    <div className="wide-md mx-auto">
      <PageContainerWrapper
        breadcrumb={breadcrumb}
        btnArray={['extraButton']}
        extraBtnText={t('text.common.back')}
        extraBtnClass="btn btn-outline-light bg-white"
        onExtraButtonHandleShow={() => backToPage(navigate, redirectUrl)}
        heading={t('text.emailMessage.editSmsTemplates')}
      >
        <div className="nk-block">
          <div className="card">
            <div className="card-inner">
              <EditSmsForm
                formData={data}
                isLoading={isLoading}
                onSubmit={onSubmit}
                backToPage={() => backToPage(navigate, redirectUrl)}
              />
            </div>
          </div>
        </div>
      </PageContainerWrapper>
    </div>
  );
}

export default EditSMS;
