import { Outlet } from 'react-router-dom';
import {
  ListAdword,
  ListGoogleConnectService,
  ListGMB,
  ListLeadService,
  GMBPhotoAdd,
  GMBPostAdd,
} from '../../views/index.view';
import { getActions } from '../../services/Auth/index.service';
import GMBMedia from '../../views/GoogleConnect/GMB/Photos';
import ReviewList from '../../views/GoogleConnect/GMB/GMBReview/reviewlist';
import GMBPostNew from '../../views/GoogleConnect/GMB/Post/List/googlePostList';
import GoogleAuth from '../../views/GoogleConnect/GoogleAuth';

export default function route() {
  return [
    {
      private: true,
      name: 'Google Connect',
      submenu: true,
      key: 'googleConnect',
      belongsToSidebar: true,
      icon: (
        <span className="nk-menu-icon">
          <em className="icon ni ni-google" />
        </span>
      ),
      moduleKeys: [
        accessRoute.GOOGLE_GMB.moduleKey,
        accessRoute.GOOGLE_ADWORDS.moduleKey,
        accessRoute.GOOGLE_SERVICE.moduleKey,
      ],
      element: <Outlet />,
      children: [
        {
          private: true,
          path: accessRoute.GOOGLE_GMB.path,
          name: 'Google GMB',
          key: 'googleConnect.GMB',
          belongsToSidebar: true,
          moduleKey: accessRoute.GOOGLE_GMB.moduleKey,
          action: accessRoute.GOOGLE_GMB.action,
          element: <ListGMB />,
          ...accessRoute.GOOGLE_GMB,
        },
        {
          private: true,
          path: accessRoute.GOOGLE_GMB_REVIEW.path,
          name: 'Google GMB Review',
          key: 'googleConnect.GMB',
          belongsToSidebar: false,
          element: <ReviewList />,
          ...accessRoute.GOOGLE_GMB_REVIEW,
        },
        {
          private: true,
          path: accessRoute.GOOGLE_GMB_PHOTO.path,
          name: 'Google GMB Photo',
          key: 'googleConnect.GMB',
          belongsToSidebar: false,
          element: <GMBMedia />,
          ...accessRoute.GOOGLE_GMB_PHOTO,
        },
        {
          private: true,
          path: accessRoute.GOOGLE_GMB_POST.path,
          name: 'Google GMB Post',
          key: 'googleConnect.GMB',
          belongsToSidebar: false,
          element: <GMBPostNew />,
          ...accessRoute.GOOGLE_GMB_POST,
        },
        {
          private: true,
          path: accessRoute.GOOGLE_GMB_PHOTO_ADD.path,
          name: 'Google GMB Photo Add',
          key: 'googleConnect.GMB',
          belongsToSidebar: false,
          element: <GMBPhotoAdd />,
          ...accessRoute.GOOGLE_GMB_PHOTO_ADD,
        },
        {
          private: true,
          path: accessRoute.GOOGLE_GMB_POST_ADD.path,
          name: 'Google GMB Post Add',
          key: 'googleConnect.GMB',
          belongsToSidebar: false,
          element: <GMBPostAdd />,
          ...accessRoute.GOOGLE_GMB_POST_ADD,
        },
        {
          private: true,
          path: accessRoute.GOOGLE_ADWORDS.path,
          name: 'Google Adwords',
          key: 'googleConnect.Adwords',
          belongsToSidebar: true,
          moduleKey: accessRoute.GOOGLE_ADWORDS.moduleKey,
          actions: accessRoute.GOOGLE_ADWORDS.action,
          element: <ListAdword />,
          ...accessRoute.GOOGLE_ADWORDS,
        },
        {
          private: true,
          path: accessRoute.GOOGLE_SERVICE.path,
          // name: 'Google Service',
          name: 'Guaranteed',
          key: accessRoute.GOOGLE_SERVICE.key,
          belongsToSidebar: true,
          moduleKey: accessRoute.GOOGLE_SERVICE.moduleKey,
          action: accessRoute.GOOGLE_SERVICE.action,
          element: <ListGoogleConnectService />,
          ...accessRoute.GOOGLE_SERVICE,
        },
        {
          private: true,
          name: 'Google Service Lead',
          key: 'googleConnect.Service',
          belongsToSidebar: false,
          element: <ListLeadService />,
          ...accessRoute.GOOGLE_SERVICE_LEAD,
          path: `${accessRoute.GOOGLE_SERVICE_LEAD.path}/:id`,
        },
        {
          private: true,
          path: accessRoute.GOOGLE_AUTH.path,
          name: 'Google Auth',
          key: 'googleConnect.GMB',
          belongsToSidebar: false,
          moduleKey: accessRoute.GOOGLE_AUTH.moduleKey,
          action: accessRoute.GOOGLE_AUTH.action,
          element: <GoogleAuth />,
          ...accessRoute.GOOGLE_AUTH,
        },
      ],
    },
  ];
}

export const accessRoute = {
  GOOGLE_GMB: {
    path: '/admin/google-gmb',
    moduleKey: 'google-gmb',
    key: 'googleConnect.google-gmb',
    action: getActions('LIST').value,
    order: 57,
  },
  GOOGLE_GMB_REVIEW: {
    path: `/admin/google-gmb/review`,
    action: getActions('LIST').value,
    moduleKey: 'google-gmb',
  },
  GOOGLE_GMB_PHOTO: {
    path: '/admin/google-gmb/photo',
    action: getActions('LIST').value,
    moduleKey: 'google-gmb',
  },
  GOOGLE_GMB_POST: {
    path: '/admin/google-gmb/post',
    action: getActions('LIST').value,
    moduleKey: 'google-gmb',
  },
  GOOGLE_GMB_PHOTO_ADD: {
    path: '/admin/google-gmb/photo/add',
    action: getActions('CREATE').value,
    moduleKey: 'google-gmb',
  },
  GOOGLE_GMB_POST_ADD: {
    path: '/admin/google-gmb/post/add',
    action: getActions('LIST').value,
    moduleKey: 'google-gmb',
  },
  GOOGLE_ADWORDS: {
    path: '/admin/google-adwords',
    action: getActions('LIST').value,
    moduleKey: 'google-adwords',
    key: 'googleConnect.adwords',
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-grid-alt" />
    //   </span>
    // ),
    order: 58,
  },
  GOOGLE_SERVICE: {
    path: '/admin/google-service',
    action: getActions('LIST').value,
    moduleKey: 'google-service',
    key: 'googleConnect.google-service',
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-grid-alt" />
    //   </span>
    // ),
    order: 56,
  },
  GOOGLE_SERVICE_LEAD: {
    path: '/admin/google-service/lead',
    action: getActions('LIST').value,
    moduleKey: 'google-service',
    key: 'googleConnect.service',
  },
  GOOGLE_AUTH: {
    path: '/admin/auth-code',
    action: getActions('LIST').value,
    moduleKey: 'google-gmb',
    key: 'googleConnect.GMB',
  },
};

export const getChildrenRoutes = () => {
  return route()[0]?.children;
};
