import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import {
  FromDateToDateFilter,
  PageContainerWrapper,
  TablePlus,
} from '../../../components';
import useTable from '../../../hooks/useTable.hook';
import { Columns, homeInventoryService } from '../../../services/index.service';
import { decodeQueryData, logger } from '../../../utils';
import { useSelector } from 'react-redux';

function ListInventory() {
  const breadcrumb = Columns.homeInventoryBreadcrumb();
  const { t } = useTranslation();
  const location = useLocation();
  const params = location?.search && decodeQueryData(location?.search);
  const { customerId, customerName } = params;
  const extraQuery = { customerName: [customerId] };
  const [searchParams, setSearchParams] = useSearchParams();

  const [tableHandler, tableProps] = useTable(
    homeInventoryService?.getHomeInventoryService,
    customerId && extraQuery
  );

  const {
    data,
    isLoading,
    count,
    page,
    noOfPages,
    sizePerPage,
    filter,
    sortBy,
    sortType,
  } = tableProps;
  const { onFilterChange, onTableSort, onFilterResetChange } = tableHandler;

  const [filterValue, setFilter] = useState({
    fromDate: '',
    toDate: '',
    dateType: undefined,
  });
  const [globalFilter, setGlobalFilter] = useState(false);
  const userRole = useSelector((state) => state?.auth?.userData?.Role?.type);
  const columns = Columns.HomeInventoryTableColumn({
    onSort: onTableSort,
    onFilterChange: onFilterChange,
    filter: filter,
    page,
    sizePerPage,
    userRole,
    filterObj: { id: customerId, name: customerName },
  });

  const BackBtnFilter = () => {
    setGlobalFilter(false);
  };

  function onReset() {
    const resetData = {
      fromDate: '',
      toDate: '',
      dateType: undefined,
    };
    setFilter({
      ...resetData,
    });
    onFilterChange(resetData);
    setGlobalFilter(false);
  }

  const onSubmitFilter = (values) => {
    try {
      let val = {
        ...values,
      };
      setFilter((prevState) => ({
        ...prevState,
        ...val,
      }));
      onFilterChange(val);
      setGlobalFilter(false);
    } catch (error) {
      logger(error);
    }
  };

  const OpenBtnFilter = () => {
    setGlobalFilter(true);
  };

  function onTableResetFilter() {
    if (customerId || customerName) {
      searchParams.delete('customerId');
      searchParams.delete('customerName');
      setSearchParams(searchParams);
    }
    const resetData = {
      fromDate: '',
      toDate: '',
      dateType: undefined,
    };
    setFilter({
      ...resetData,
    });
    onFilterChange(resetData);
    onFilterResetChange();
  }
  const headerBtn = {
    add: {
      name: (
        <>
          <em className="icon ni ni-filter-fill me-1" />{' '}
          {t('text.common.filter')}
        </>
      ),
      handler: () => {
        OpenBtnFilter();
      },
    },
  };

  return (
    <PageContainerWrapper
      heading={t('text.homeInventory.title')}
      breadcrumb={breadcrumb}
      btn={headerBtn}
      extraClass="btn btn-primary d-block d-xl-none"
    >
      <div className="">
        <div className="globalFilters">
          <div className="card ">
            <div className="card-inner">
              <div
                className={`globalFilters_inner ${
                  globalFilter ? 'filterToggle' : ''
                }`}
              >
                <div className="mb-3 d-block d-xl-none">
                  <Link to="#" onClick={() => BackBtnFilter()}>
                    <em className="icon ni ni-arrow-long-left me-1"></em>
                    {t('text.common.back')}
                  </Link>
                </div>
                <FromDateToDateFilter
                  onSubmit={onSubmitFilter}
                  onReset={onReset}
                  loading={isLoading}
                  filterValue={filterValue}
                  setFilter={setFilter}
                  isEnabledFields={['dateType']}
                />
              </div>
              <div
                className={` ${globalFilter ? 'toggle-overlay' : ''} `}
                onClick={() => BackBtnFilter()}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="tableScrollHide">
        <TablePlus
          hasLimit
          noOfPage={noOfPages}
          sizePerPage={sizePerPage}
          page={page}
          count={count}
          tableData={data}
          tableColumns={columns}
          tableLoader={isLoading}
          selectRow
          onFilterResetChange={onTableResetFilter}
          showAction={false}
          scrollTopPosition
          filter={filter}
          sortBy={sortBy}
          sortType={sortType}
        />
      </div>
    </PageContainerWrapper>
  );
}

export default ListInventory;
