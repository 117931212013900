import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Localization } from '../../../services/Localization';
import {
  FormWrapper,
  FormElementWrapper,
  TextInput,
  CommonButton,
  Select,
  GlobalLoader,
} from '../../index';
import { initValues, validation } from './validation';

function NcaProfileForm({ onSubmit, formData, isLoading, setDefaultKey }) {
  const { t } = useTranslation();

  useEffect(() => {
    setDefaultKey('ncaProfile');
  }, []);
  return (
    <div className="card">
      <div className="card-inner">
        <FormWrapper
          row={true}
          onSubmit={onSubmit}
          enableReinitialize={true}
          initialValues={initValues({ ...formData })}
          data={formData}
          validation={validation}
          extraClassName="g-3"
        >
          {(props) => (
            <>
              {isLoading ? (
                <GlobalLoader animation="grow" size="lg" />
              ) : (
                <>
                  <FormElementWrapper
                    md={6}
                    mandatory={true}
                    label={t('text.common.fName')}
                    element={() => (
                      <TextInput
                        name="firstName"
                        placeholder={t('text.common.enterFirstName')}
                        formik={props}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={6}
                    mandatory={true}
                    label={t('text.common.lName')}
                    element={() => (
                      <TextInput
                        name="lastName"
                        placeholder={t('text.common.enterLastName')}
                        formik={props}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={6}
                    mandatory={true}
                    label={t('text.common.phoneNumber')}
                    element={() => (
                      <div className="inputAndSelect">
                        <div className="form-control-wrap ">
                          <div className="input-group flex-nowrap">
                            <div className="input-group-prepend w-25">
                              <Select
                                name="countryPhoneCode"
                                showSearch
                                formik={props}
                                scope="activeCountry"
                                listService={Localization.CountryService}
                                listKeys={{
                                  nameKey: 'phoneCode',
                                  nameId: 'phoneCode',
                                }}
                              />
                            </div>
                            <TextInput
                              name="phoneNumber"
                              // type="number"
                              placeholder={t('text.common.enterPhoneNumber')}
                              formik={props}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  />
                  <FormElementWrapper
                    md={6}
                    mandatory={true}
                    label={t('text.common.email')}
                    element={() => (
                      <TextInput
                        name="email"
                        placeholder={t('text.common.enterEmail')}
                        formik={props}
                      />
                    )}
                  />
                </>
              )}
              <FormElementWrapper
                md={12}
                className="text-end"
                element={() => (
                  <>
                    <CommonButton
                      type="submit"
                      className="btn btn-primary"
                      disabled={isLoading}
                    >
                      {isLoading && (
                        <GlobalLoader
                          animation="grow"
                          size="sm"
                          variant="white"
                        />
                      )}
                      {t('text.common.saveChanges')}
                    </CommonButton>
                  </>
                )}
              />
            </>
          )}
        </FormWrapper>
      </div>
    </div>
  );
}

export default NcaProfileForm;
