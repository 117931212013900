import React, { useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  PageContainerWrapper,
  ServiceCategoryForm,
} from '../../../../../components';
import { backToPage } from '../../../../../utils';
import { useTranslation } from 'react-i18next';
import { formSubmit } from '../../../../../hooks/useFormSubmit.hook';
import {
  breadcrumbServiceCategoryAdd,
  breadcrumbNCAServiceCategoryAdd,
} from '../../../../../services/Columns';
import { accessRoute } from '../../../../../routes/PriceManagement';
import { accessRoute as NCARoute } from '../../../../../routes/AdminSetting';
import { addServiceCategoryService } from '../../../../../services/ManageServices/serviceCategory.service';

function CreateServiceCategory({ userId = false, ncaEdit = false }) {
  const [isLoading, setIsLoading] = useState(false);
  const orderCount = useSelector((state) => state.common.orderCount);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const url =
    userId &&
    `${generatePath(NCARoute?.EDIT_NCA.path, {
      id: userId,
    })}`;
  const redirectUrl = !userId ? accessRoute.LIST_SERVICE_CATEGORY.path : url;
  const breadcrumb = userId
    ? breadcrumbNCAServiceCategoryAdd(url)
    : breadcrumbServiceCategoryAdd();

  async function onSubmit(values) {
    formSubmit({
      values: { ...(userId && { userId }), ...values },
      service: addServiceCategoryService,
      navigate,
      redirectUrl,
      setIsLoading,
    });
  }
  return (
    <div className="wide-md mx-auto">
      <PageContainerWrapper
        breadcrumb={breadcrumb}
        btnArray={['extraButton']}
        extraBtnText={t('text.common.back')}
        extraBtnClass="btn btn-outline-light bg-white"
        onExtraButtonHandleShow={() => backToPage(navigate, redirectUrl)}
        heading={t('text.manageServices.addServiceCategory')}
      >
        <ServiceCategoryForm
          onSubmit={onSubmit}
          isLoading={isLoading}
          backToPage={() => backToPage(navigate, redirectUrl)}
          submitText={t('text.common.add')}
          count={() => {
            return Array.from(Array((orderCount || 0) + 1)?.keys());
          }}
          ncaEdit={ncaEdit}
          userId={userId}
        />
      </PageContainerWrapper>
    </div>
  );
}

export default CreateServiceCategory;
