import i18next from 'i18next';
import {
  getInputSearchFilter,
  getMultiSelectSearchFilter,
} from '../../components';
import { accessRoute } from '../../routes/Dashboard';
import { accessRoute as priceManagementRoutes } from '../../routes/PriceManagement';
import { accessRoute as ncaAccessRoute } from '../../routes/AdminSetting';
import {
  checkValidData,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { getTupleStatusList } from '../index.service';
import { logoImageFormatter } from '../../utils';
import { firstLetterCaps } from '../../utils/text.util';

export const serviceCategoryTableColumn = ({
  Action,
  Toggle,
  onSort,
  onFilterChange,
  ViewButton,
  filter,
  page,
  sizePerPage,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'logoUrl',
      title: i18next.t('text.common.icon'),
      headerClasses: 'sorting',
      sorter: false,
      render: (cell, row) => logoImageFormatter(cell, 'logo'),
      export: false,
    },
    {
      dataIndex: 'name',
      filterDataIndex: ['category'],
      title: i18next.t('text.common.name'),
      headerClasses: 'sorting',
      sorter: true,
      exportName: i18next.t('text.common.category'),
      onSort: (field, order) => onSort('name', order),
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'category',
        placeholder: 'name',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'subCategoryCount',
      title: i18next.t('text.common.subCategories'),
      headerClasses: 'sorting',
      sorter: true,
      exportName: i18next.t('text.common.subCategoriesCount'),
      export: false,
      onSort: (field, order) => onSort('subCategoryCount', order),
      render: (cell, row) => {
        return <ViewButton row={row} cell={cell} count={cell} />;
      },
    },
    {
      dataIndex: 'displayOrder',
      title: i18next.t('text.manageServices.displayOrder'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('tagType', order),
      render: (cell, row) => checkValidData(cell),
    },
    {
      dataIndex: 'status',
      title: i18next.t('text.localization.status'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('status', order),
      render: (cell, row) => {
        return <Toggle row={row} cell={cell} />;
      },
      exportFormatter: (cell) => firstLetterCaps(cell),
      ...getMultiSelectSearchFilter({
        dataIndex: 'status',
        onFilterChange,
        name: 'status',
        list: [],
        mode: false,
        filter,
        listService: getTupleStatusList,
      }),
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.localization.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => {
        return <Action row={row} cell={cell} />;
      },
    },
  ];
};

export const serviceCategoryBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.manageServices.heading'),
    },
  ];
};

export const breadcrumbServiceCategoryAdd = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: priceManagementRoutes.LIST_SERVICE_CATEGORY.path,
      name: i18next.t('text.manageServices.heading'),
    },
    {
      path: '#',
      name: i18next.t('text.manageServices.addCategory'),
    },
  ];
};

export const breadcrumbServiceCategoryEdit = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: priceManagementRoutes.LIST_SERVICE_CATEGORY.path,
      name: i18next.t('text.manageServices.heading'),
    },
    {
      path: '#',
      name: i18next.t('text.manageServices.editCategory'),
    },
  ];
};

export const serviceTypeData = () => {
  return [
    {
      name: 'Scheduled Services',
      value: 'scheduled',
    },
    {
      name: 'Same Day Services',
      value: 'same_day',
    },
  ];
};

export const servicePriceData = () => {
  return [
    {
      name: 'Paid',
      value: 'paid',
    },
    {
      name: 'Free',
      value: 'free',
    },
  ];
};

export const serviceStatusData = () => {
  return [
    {
      name: 'ON',
      value: 1,
    },
    {
      name: 'OFF',
      value: 0,
    },
  ];
};

export const subcategoryData = () => {
  return [
    {
      name: 'Service Repair',
      value: 'serviceRepair',
    },
  ];
};

export const breadcrumbNCAServiceCategoryAdd = (url) => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: ncaAccessRoute.LIST_NCA.path,
      name: i18next.t('text.common.nca'),
    },
    {
      path: url,
      name: i18next.t('text.common.editNCA'),
    },
    {
      path: '#',
      name: i18next.t('text.manageServices.addCategory'),
    },
  ];
};

export const breadcrumbNCAServiceCategoryEdit = (url) => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: ncaAccessRoute.LIST_NCA.path,
      name: i18next.t('text.common.nca'),
    },
    {
      path: url,
      name: i18next.t('text.common.editNCA'),
    },
    {
      path: '#',
      name: i18next.t('text.manageServices.editCategory'),
    },
  ];
};
