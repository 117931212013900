export const text = {
  download: 'Download',
  search: 'Search by name',
  messageType: 'Type your message',
  chatWithSP: 'Chat With SP',
  chatWithGroup: 'Create Group',
  selectServiceProvider: 'Select Service Provider',
  sendMessage: 'Send Message',
  loadMore: 'Load More',
  createGroup: 'Create Group',
  groupName: 'Group Name',
  enterGroupName: 'Enter group name',
  addMemberInGroup: 'Add Member in Group',
  allMembers: 'All Members',
  addMembers: 'Add Members',
  admin: 'Admin',
  remove: 'Remove',
  noServiceProviderList: 'No Service Provider List',
  chatBoxNoContentHeading: 'No Chat Data Load',
  chatBoxNoContentPara: 'Please select the chat for start conversation',
  all: 'All',
  positive: 'Positive',
  help: 'Help',
  struggling: 'Struggling',
  medical: 'Medical',
  messages: 'Messages',
  end: 'End',
  removeMember: 'Remove Member',
  chatEdit:'Group Edit',
  chatDelete:'Remove Group',
  removeGroupMessage:"You Want To Delete This Group ? Deleting This Group Will Also Delete It's Data"
};
