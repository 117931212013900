let url = '/v1/admin/truck-stock-order';
export const TRUCK_ORDER_ENDPOINT = {
  getTruckOrderList: {
    url,
    method: 'GET',
  },
  deleteTruckOrder: {
    url,
    method: 'DELETE',
  },
  addTruckOrder: {
    url,
    method: 'POST',
  },
  orderTruckOrder: {
    url,
    method: 'PUT',
  },
  getTruckOrderAmount: {
    url: `${url}/count`,
    method: 'GET',
  },
  getSupplyHouseList: {
    url: '/v1/provider/truck-stock/order-location',
    method: 'GET',
  },
};
