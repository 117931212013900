import React, { forwardRef, useImperativeHandle } from 'react';
import { TablePlus } from '../../../../../components';
import { useTable } from '../../../../../hooks/index.hook';
import { Columns, getUserService } from '../../../../../services/index.service';
import { useEffect } from 'react';

const ReviewUserList = forwardRef(({ defaultKey }, ref) => {
  const extraCSVHandle = async (getData) => {
    let obj = {};
    let arr = [];
    getData &&
      getData?.rows?.length &&
      getData?.rows?.map((item) => {
        if (item?.toUser?.avgUserRating) {
          obj = {
            name: item?.toUser?.avgUserRating
              ? item?.toUser?.avgUserRating
              : '0.0',
          };
          arr.push(obj);
        }
      });
    return { averageRating: arr };
  };
  const [tableHandler, tableProps] = useTable(
    getUserService,
    false,
    false,
    false,
    false,
    extraCSVHandle
  );

  const {
    data,
    isLoading,
    count,
    page,
    noOfPages,
    sizePerPage,
    filter,
    sortBy,
    sortType,
  } = tableProps;
  const { onFilterChange, onExport, onFilterResetChange, onTableSort } =
    tableHandler;
  const columns = Columns.reviewUserColumn({
    onSort: onTableSort,
    onFilterChange: onFilterChange,
    filter,
    page,
    sizePerPage,
  });

  useEffect(() => {
    onFilterResetChange();
  }, [defaultKey]);

  async function fetchData() {
    try {
      let res = await onExport('', columns);
      return res;
    } catch (error) {
      console.error(error);
    }
  }

  useImperativeHandle(ref, () => ({
    fetchData,
  }));

  return (
    <>
      <TablePlus
        hasLimit
        noOfPage={noOfPages}
        sizePerPage={sizePerPage}
        page={page}
        count={count}
        tableData={data}
        tableColumns={columns}
        tableLoader={isLoading}
        onFilterResetChange={onFilterResetChange}
        showAction={false}
        selectRow="false"
        filter={filter}
        sortBy={sortBy}
        sortType={sortType}
      />
    </>
  );
});
export default ReviewUserList;
