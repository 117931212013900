import React from 'react';

import { ImageElement } from '../../components';

function ThankyouLoader({
  title = 'Thank you for choosing us!',
  handleTimerStop,
}) {
  return (
    <>
      <div className="thankyouloader">
        <div className="thankyouloader_content">
          <h2>{title}</h2>
          <div className="thankyouloader_sec d-flex justify-content-center align-items-center mx-auto">
            <ImageElement source="thank-you-loader.svg" />
          </div>
          <button
            onClick={() => handleTimerStop('confirmCancel')}
            className="thankyouloader_closebtn d-flex justify-content-center align-items-center border-0"
          >
            <em className="gg-close"></em>
          </button>
        </div>
      </div>
    </>
  );
}

export default ThankyouLoader;
