import loadable from '@loadable/component';
import FullPageLoader from '../../components/UIElements/Loader/fullPageLoader';

export { default as MembershipList } from './List';
export const AddMembership = loadable(() => import('./Create'), {
  fallback: <FullPageLoader />,
});
export const EditMembership = loadable(() => import('./Edit'), {
  fallback: <FullPageLoader />,
});
