import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { editAvailabilityValues } from './validation';
import { FormWrapper, TruckOrderForm } from '../../index';
import CommonButton from '../../UIElements/Button/Common/index.btn';
import Calender from '../../UIElements/Calender';
import { Checkbox as CheckBoxAnt } from 'antd';
import CheckBox from '../../UIElements/CheckBox/index.element';
import {
  dateFormatter,
  disabledPastDate,
  modalNotification,
} from '../../../utils';
import moment from 'moment';
import { getSystemDate, isValid } from '../../../utils/dateAndTime.util';
import { APP_DATE_INPUT_FORMAT } from '../../../config';
import { getApiData } from '../../../hooks/useGetApi.hook';
import {
  getProviderAvailabilityService,
  getProviderUnavailabilityBlockService,
} from '../../../services/TeamSetting/provider.service';
import GlobalLoader from '../../UIElements/GlobalLoader';

function UnavailabilityModalForm({
  onSubmit,
  isLoading,
  unblockSubmit,
  userId,
  // slots: slotData,
}) {
  const [selectedDate, setSelectedDate] = useState([]);
  const [loading, setLoading] = useState(null);
  const [getSlotData, setGetSlotData] = useState([]);
  const [selectedSProviderSlot, setSelectedProviderSlot] = useState({});
  const [slots, setSlot] = useState({});
  const [selectedDay, setSelectedDay] = useState({
    day: moment(new Date()).weekday(),
    date: new Date(),
  });
  const { t } = useTranslation();
  const { day } = selectedDay;
  let initialValues = editAvailabilityValues();

  function getActiveSlotOnly(e) {
    try {
      return e?.isProviderSlot === 1;
    } catch (err) {
      console.log('getActiveSlotOnly', err);
      return false;
    }
  }

  useEffect(() => {
    if (getSlotData) {
      const newSlot = {};
      getSlotData?.rows?.forEach((e) => {
        newSlot[e?.day] = e?.slots.filter(getActiveSlotOnly);
      });
      setSlot(newSlot);
    }
  }, [getSlotData]);

  const onSlotSelect = (checkedValues) => {
    try {
      const selectedSlots = {};
      selectedSlots[getSystemDate(selectedDay?.date, APP_DATE_INPUT_FORMAT)] =
        checkedValues;
      setSelectedProviderSlot(selectedSlots);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getProviderBlockedSlot();
  }, [userId]);

  async function getProviderBlockedSlot() {
    setLoading(true);
    try {
      const res = await getProviderUnavailabilityBlockService({
        queryParams: { id: userId },
      });
      if (res?.success) {
        const data = {};
        res?.data?.forEach((e) => {
          data[e?.date] = e?.slot?.map((el) => el?.providerSlotId);
        });
        setSelectedProviderSlot(data);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }

  useEffect(() => {
    getApiData({
      service: getProviderAvailabilityService,
      setData: setGetSlotData,
      id: { userId },
    });
  }, [userId]);

  // For handle entire day
  const handleBlockEntireDay = async () => {
    try {
      if (!selectedDay?.date || !isValid(selectedDay?.date)) {
        //***********Please select slot *****************/
        modalNotification({
          type: 'error',
          message: 'Please select at least one slot ',
        });
        return false;
      }
      const payload = {};
      payload.blockDate = getSystemDate(
        selectedDay?.date,
        APP_DATE_INPUT_FORMAT
      );
      payload.providerSlotIds = [];
      payload.isFullDayBlock = true;
      onSubmit(payload);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUnblock = async () => {
    try {
      if (!selectedDay?.date || !isValid(selectedDay?.date)) {
        //***********Please select slot *****************/
        modalNotification({
          type: 'error',
          message: 'Please select at least one slot ',
        });
        return false;
      }
      const payload = {};
      payload.blockDate = getSystemDate(
        selectedDay?.date,
        APP_DATE_INPUT_FORMAT
      );
      payload.providerSlotIds = [];
      payload.isFullDayBlock = true;
      unblockSubmit(payload, 'unblock');
    } catch (err) {
      console.log(err);
    }
  };

  // For handle submit
  const handleSubmit = () => {
    if (
      !selectedDay?.date ||
      !isValid(selectedDay?.date) ||
      !selectedSProviderSlot[
        getSystemDate(selectedDay?.date, APP_DATE_INPUT_FORMAT)
      ] ||
      selectedSProviderSlot[
        getSystemDate(selectedDay?.date, APP_DATE_INPUT_FORMAT)
      ]?.length === 0
    ) {
      //***********Please select slot *****************/
      modalNotification({
        type: 'error',
        message: 'Please select at least one slot ',
      });
      return false;
    }
    const payload = {};
    payload.blockDate = getSystemDate(selectedDay?.date, APP_DATE_INPUT_FORMAT);
    payload.providerSlotIds = selectedSProviderSlot[payload.blockDate];
    payload.isFullDayBlock = false;
    onSubmit(payload);
  };

  // For handle calender date with slot
  const calenderHandle = (value) => {
    const dayNum = moment(value).weekday();
    setSelectedDay({ day: dayNum, date: value });
  };

  // For create slot for default value
  // useEffect(() => {
  //   let slotsObj = {};
  //   let selectedSlotArray = [];
  //   let currentDate = moment(getDate).format('YYYY-MM-DD');
  //   if (selectedSlotData?.rows && selectedSlotData?.rows.length) {
  //     selectedSlotData?.rows.forEach((item) => {
  //       if (item.blockDate === currentDate) {
  //         slotsObj = {
  //           ...slotsObj,
  //           day: item?.day.toLocaleLowerCase(),
  //           slot: item?.slot,
  //           blockType: item?.blockType,
  //           blockDate: item?.blockDate,
  //         };
  //       }
  //       selectedSlotArray = [
  //         ...selectedSlotArray,
  //         {
  //           day: item?.day.toLocaleLowerCase(),
  //           slot: item?.slot,
  //           blockType: item?.blockType,
  //           blockDate: item?.blockDate,
  //         },
  //       ];
  //     });

  //     setSelectedCalenderData(selectedSlotArray);
  //     // for filter data for showing slots tonight changes
  //     let getCurrentSlot = selectedSlotArray
  //       .filter((e) => e.blockDate === currentDate)
  //       .map((item) => item.slot);
  //     if (getCurrentSlot && getCurrentSlot.length) {
  //       setUnblockBtn(true);
  //       setAvailabilitySlot(slotsObj);
  //       setSlotDataShow(getCurrentSlot);
  //     } else {
  //       setUnblockBtn(false);
  //       setAvailabilitySlot({});
  //       setSlotDataShow([]);
  //     }
  //   }
  // }, [selectedSlotData]);

  const dateCellRender = (value) => {
    const date = value.format('YYYY-MM-DD');
    const isSpecialDate = selectedDate.includes(date);

    return (
      <div
        className={`ant-picker-calendar-date ${isSpecialDate ? 'success' : ''}`}
      >
        {value.date()}
      </div>
    );
  };

  useEffect(() => {
    let getDate = selectedDate;
    let allSlot =
      Object.keys(slots)?.length &&
      Object.keys(slots).map((e) => slots[e])?.length &&
      Object.keys(slots)
        .map((e) => slots[e])
        .flat()?.length &&
      Object.keys(slots)
        .map((e) => slots[e])
        .flat()
        .filter((i) => i)?.length &&
      Object.keys(slots)
        .map((e) => slots[e])
        .flat()
        .filter((i) => i)
        .map((z) => z?.providerSlotId);

    Object.keys(selectedSProviderSlot)?.length &&
      Object.keys(selectedSProviderSlot).forEach((item) => {
        let data =
          selectedSProviderSlot[item]?.length &&
          selectedSProviderSlot[item].filter(
            (e) => allSlot?.length && allSlot.includes(e)
          );
        if (data && data?.length) {
          getDate = [...getDate, item];
        }
      });
    setSelectedDate(getDate);
  }, [slots, selectedSProviderSlot, getSlotData]);

  return (
    <>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner p-0">
            <FormWrapper
              row={true}
              extraClassName="g-3"
              initialValues={initialValues}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {() => {
                return (
                  <>
                    {loading ? (
                      <GlobalLoader
                        style={{
                          minHeight: 200,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      />
                    ) : (
                      <div className="text-center selectBlockDays">
                        <Calender
                          name="blockDate"
                          onSelect={calenderHandle}
                          fullscreen={false}
                          defaultPickerValue={selectedDay}
                          dateCellRender={dateCellRender}
                          disabledDate={disabledPastDate}
                        />
                        <div className="divider sm mt-0" />
                        {loading !== 'block' && (
                          <CheckBoxAnt.Group
                            style={{
                              width: '100%',
                            }}
                            onChange={(e) => onSlotSelect(e, selectedDay)}
                            value={
                              selectedSProviderSlot[
                                getSystemDate(
                                  selectedDay.date,
                                  APP_DATE_INPUT_FORMAT
                                )
                              ]
                            }
                          >
                            <ul className="custom-control-group justify-content-center">
                              {slots.hasOwnProperty(day) &&
                                slots[day].map((el) => (
                                  <li key={el?.id}>
                                    <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                      <CheckBox
                                        value={el?.providerSlotId}
                                        extraClassName="custom-control-input"
                                        id={el?.providerSlotId}
                                        label={true}
                                        group={true}
                                        labelText={el?.slotTime}
                                      />
                                    </div>
                                  </li>
                                ))}
                            </ul>
                          </CheckBoxAnt.Group>
                        )}
                        <div className="divider sm" />
                        <div className="text-center mb-3">
                          <CommonButton
                            className="btn btn-primary me-2"
                            type="submit"
                            disabled={isLoading}
                          >
                            {t('text.provider.blockTimeFrame')}
                          </CommonButton>
                          <CommonButton
                            className="btn btn-light me-2"
                            onClick={handleBlockEntireDay}
                            disabled={isLoading}
                          >
                            {t('text.provider.blockEntireDay')}
                          </CommonButton>

                          <CommonButton
                            className="btn btn-primary"
                            onClick={handleUnblock}
                          >
                            {t('text.provider.unblockEntireDay')}
                          </CommonButton>
                        </div>
                        <p className="form-note mb-3">
                          <b> {t('text.provider.NoteRepresenting')}</b>
                        </p>
                      </div>
                    )}
                  </>
                );
              }}
            </FormWrapper>
          </div>
        </div>
      </div>
    </>
  );
}
export default UnavailabilityModalForm;
