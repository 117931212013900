import i18next from 'i18next';
import { accessRoute } from '../../routes/Dashboard';
import { checkValidData, serialNumberFormatter } from '../../components/UIElements/Formatter';
import { capitalizeFirstLetter, logoImageFormatter } from '../../utils';
import { getColumnSelectSearchProps, getInputSearchFilter, getMultiSelectSearchFilter } from '../../components';
import { getAdsStatusData } from '../index.service';

export const advertisementBreadcrumb = () => {
    return [
        {
            path: accessRoute.DASHBOARD.path,
            name: i18next.t('text.common.dashboard'),
        },
        {
            path: '#',
            name: i18next.t('text.marketing.advertisements'),
        },
    ];
};

const userTypeValues = () => {
    return [
        {
            name: 'Provider',
            id: 'provider',
        },
        {
            name: 'Customer',
            id: 'customer',
        },
        {
            name: 'Both',
            id: 'both',
        },
    ];
};

export const interstitialAdsColumn = ({
    onFilterChange,
    filter,
    onSort,
    page,
    Toggle,
    Action,
    sizePerPage,
}) => {
    return [
        {
            dataIndex: 'id',
            title: i18next.t('text.common.serialNo'),
            headerClasses: 'w_70',
            render: (cell, row, index) =>
                serialNumberFormatter({ page, sizePerPage, index }),
            exportFormatter: (cell, row, index) =>
                serialNumberFormatter({ page, sizePerPage, index }),
        },
        {
            dataIndex: 'interstitialAdImage',
            title: i18next.t('text.common.image'),
            render: (cell, row) => logoImageFormatter(row?.interstitialAdImageThumbUrl),
        },
        {
            dataIndex: 'userType',
            filterDataIndex: ['userType'],
            title: i18next.t('text.marketing.userType'),
            sorter: true,
            headerClasses: 'sorting',
            render: (cell, row) => checkValidData(capitalizeFirstLetter(row?.userType)),
            ...getMultiSelectSearchFilter({
                dataIndex: 'userType',
                onFilterChange,
                filter,
                name: 'user type',
                list: [],
                mode: false,
                listService: userTypeValues,
                searchOrNot: true,
            }),
        }, ,
        {
            dataIndex: 'status',
            title: i18next.t('text.common.status'),
            headerClasses: 'sorting',
            render: (cell, row) => <Toggle cell={cell} row={row} />,
            sorter: true,
            ...getColumnSelectSearchProps({
                dataIndex: 'status',
                name: 'status',
                onFilterChange,
                filter,
                mode: false,
                list: getAdsStatusData(),
            }),
        },
        {
            dataIndex: 'Action',
            title: i18next.t('text.common.action'),
            className: 'nk-tb-col-tools text-end',
            render: (cell, row) => <Action cell={cell} row={row} />,
        },
    ];
};

export const texableAdsColumn = ({
    onFilterChange,
    filter = {},
    onSort,
    Toggle,
    Action,
    page,
    sizePerPage,
}) => {
    return [
        {
            dataIndex: 'id',
            title: i18next.t('text.common.serialNo'),
            headerClasses: 'w_70',
            render: (cell, row, index) =>
                serialNumberFormatter({ page, sizePerPage, index }),
            exportFormatter: (cell, row, index) =>
                serialNumberFormatter({ page, sizePerPage, index }),
        },
        {
            dataIndex: 'userType',
            filterDataIndex: ['userType'],
            title: i18next.t('text.marketing.userType'),
            sorter: true,
            headerClasses: 'sorting',
            render: (cell, row) => checkValidData(capitalizeFirstLetter(row?.userType)),
            ...getMultiSelectSearchFilter({
                dataIndex: 'userType',
                onFilterChange,
                filter,
                name: 'user type',
                list: [],
                mode: false,
                listService: userTypeValues,
                searchOrNot: true,
            }),
        }, ,
        {
            dataIndex: 'content',
            title: i18next.t('text.common.description'),
            headerClasses: 'sorting',
            // sorter: true,
            render: (cell) => checkValidData(cell),
            ...getInputSearchFilter({
                dataIndex: 'content',
                placeholder: 'description',
                onFilterChange,
                filter,
            }),
        },
        {
            dataIndex: 'displayOrder',
            title: i18next.t('text.common.order'),
            headerClasses: 'sorting',
            sort: true,
            onSort: (field, order) => onSort('displayOrder', order),
            render: (cell) => checkValidData(cell),
        },
        {
            dataIndex: 'status',
            title: i18next.t('text.common.status'),
            headerClasses: 'sorting',
            render: (cell, row) => <Toggle cell={cell} row={row} />,
            sorter: true,
            ...getColumnSelectSearchProps({
                dataIndex: 'status',
                name: 'status',
                onFilterChange,
                filter,
                mode: false,
                list: getAdsStatusData(),
            }),
        },
        {
            dataIndex: 'Action',
            title: i18next.t('text.common.action'),
            className: 'nk-tb-col-tools text-end',
            render: (cell, row) => <Action cell={cell} row={row} />,
        },
    ];
};