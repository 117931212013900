import { MANAGE_CATEGORY_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';

export const getCategoryService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...MANAGE_CATEGORY_ENDPOINT?.getCategoryList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const deleteCategoryService = async ({ bodyData }) => {
  const { id } = bodyData;
  try {
    const payload = {
      ...MANAGE_CATEGORY_ENDPOINT?.deleteCategory(id),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const categoryStatus = async ({ bodyData, queryParams }) => {
  try {
    const payload = {
      ...MANAGE_CATEGORY_ENDPOINT?.changeCategoryStatus(queryParams),
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const addCategoryService = async ({ bodyData }) => {
  try {
    const payload = {
      ...MANAGE_CATEGORY_ENDPOINT?.addCategory,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const editCategoryService = async ({ bodyData, id }) => {
  try {
    const payload = {
      ...MANAGE_CATEGORY_ENDPOINT?.editCategory(id),
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getCategoryDetailsService = async ({ id }) => {
  try {
    const payload = {
      ...MANAGE_CATEGORY_ENDPOINT?.getCategoryDetails(id),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
//Import File Service
export const importCategoryService = async ({ body }) => {
  try {
    const payload = {
      ...MANAGE_CATEGORY_ENDPOINT.importCategoryItems,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

// Import file
export const importFileCategoryListService = async (queryParams) => {
  try {
    const payload = {
      ...MANAGE_CATEGORY_ENDPOINT.getImportFileDetails,
      queryParams,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

// Save Imported File Changes
export const saveImportCategoryService = async ({ body }) => {
  try {
    const payload = {
      ...MANAGE_CATEGORY_ENDPOINT.saveImport,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

// Discard the all change of
export const discardImportCategoryService = async ({ body }) => {
  try {
    const payload = {
      ...MANAGE_CATEGORY_ENDPOINT.discardImport,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
