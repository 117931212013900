import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  CommonButton,
  NoDataFound,
  PageContainerWrapper,
} from '../../../../../components';
import {
  Columns,
  ManageServices,
  TeamSetting,
} from '../../../../../services/index.service';
import { accessRoute } from '../../../../../routes/TeamSetting';
import { backToPage, modalNotification } from '../../../../../utils';
import { Switch } from 'antd';
import { checkValidPrice } from '../../../../../components/UIElements/Formatter';

function Services({
  category,
  // id,
  updateSubCategoryState,
  originalList,
  subCategory,
}) {
  async function onToggleChange(e, z) {
    try {
      updateSubCategoryState({ [z]: e });
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <div className="nk-data data-list mt-2">
        <div className="data-head">
          <h6 className="overline-title">{category}</h6>
          {console.log(subCategory)}
        </div>
        {(subCategory || []).map((e) => {
          return (
            <div key={e?.id} className="data-item p-1">
              <div className="data-col">
                <span className="data-label">
                  {e?.name} (
                  {e?.servicePrice === 'free'
                    ? e?.servicePrice
                    : checkValidPrice(e?.price)}{' '}
                  - {e?.serviceType})
                </span>
                <span className="data-value">
                  <Switch
                    checked={originalList[e?.id] || false}
                    // loading={loading}
                    // disabled={loading || disabled}
                    onChange={(ele) => onToggleChange(ele, e?.id)}
                  />
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

function EditProviderService() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const breadcrumb = Columns.editProviderServiceBreadcrumb();
  const redirectUrl = accessRoute.PROVIDER.path;
  const [state, setState] = useState({
    data: [],
    loading: 'page',
    subCategory: {},
    subOriginalCategory: {},
  });
  const [originalList, setList] = useState({});
  const [completeSubCategoriesStatus, setCompleteSubCategoriesStatus] =
    useState(false);

  const { data, loading, subCategory } = state;

  useEffect(() => {
    getServiceById();
  }, []);

  function updateSubCategoryState(newState) {
    try {
      setList({ ...originalList, ...newState });
    } catch (err) {
      console.log(err);
    }
  }

  async function getServiceCategory() {
    try {
      const res = await ManageServices.ListServiceCategoryService({
        queryParams: { scope: 'activeServiceCategory', providerId: params?.id },
      });
      const newState = { ...state, loading: null };
      if (res?.success) {
        newState.data = res?.data?.rows;
        const subCat = {};
        res?.data?.rows?.forEach((category) => {
          (category?.ServiceSubcategory || []).forEach((subCategory) => {
            subCat[subCategory?.id] = subCategory?.status;
          });
        });
      }
      setState(newState);
    } catch (err) {
      setState({ loading: null });
      console.log(err);
    }
  }

  async function getServiceById() {
    try {
      const res = await TeamSetting.getProviderServiceById({
        queryParams: { userId: params?.id },
      });
      const prevList = {};
      if (res?.success) {
        (res?.data?.rows || []).forEach((e) => {
          prevList[e?.serviceCategoryId] =
            e?.status === 'active' ? true : false;
        });
        setList({ ...prevList });
        setCompleteSubCategoriesStatus(
          res?.data?.adminServiceRestriction === 1 ? true : false
        );
        await getServiceCategory();
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function onSubmit() {
    try {
      setState({ ...state, loading: 'submit' });
      let subCategoriesArr = [];
      data.forEach((e) => {
        const arr = e?.ServiceSubcategory?.map((ele) => {
          return {
            serviceId: ele?.id,
            status: originalList[ele?.id] ? 'active' : 'inactive',
          };
        });
        subCategoriesArr = [...subCategoriesArr, ...arr];
      });
      const payload = {
        adminServiceRestriction: Boolean(completeSubCategoriesStatus),
        services: subCategoriesArr,
      };
      const res = await TeamSetting.updateProviderStatusService({
        id: params?.id,
        body: payload,
      });
      if (res?.success) {
        modalNotification({
          type: 'success',
          message: 'Status updated successfully',
        });
        navigate(accessRoute.PROVIDER.path);
      }
      setState({ ...state, loading: null });
    } catch (err) {
      setState({ ...state, loading: null });
      console.log(err);
    }
  }

  function onGlobalToggleChange(e) {
    setCompleteSubCategoriesStatus(e);
  }

  const isDisableButton = () => {
    const details = { completeSubCategoriesStatus, ...originalList };
    if (Object.values(details).every((val) => !val)) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className="wide-md mx-auto">
        <PageContainerWrapper
          loading={loading === 'page'}
          btnArray={['extraButton']}
          extraBtnText={t('text.common.back')}
          extraBtnClass="btn btn-outline-light bg-white"
          onExtraButtonHandleShow={() => backToPage(navigate, redirectUrl)}
          heading={t('text.provider.editService')}
          breadcrumb={breadcrumb}
          loadingText={false}
        >
          <div className="card card-preview">
            {data ? (
              <div className="card-inner">
                <div className="nk-block">
                  <div className="nk-data data-list">
                    <div className="data-item px-0 py-2 pt-0">
                      <div className="data-col">
                        <span className="data-label w-25">
                          {t('text.provider.serviceRestriction')}
                        </span>
                        <span className="data-value w-75">
                          <Switch
                            checked={completeSubCategoriesStatus}
                            onChange={onGlobalToggleChange}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="serviceList">
                    <h6 className="mb-3">
                      {t('text.provider.serviceType')}
                      <span className="text-danger"> &nbsp;</span>
                    </h6>
                  </div>
                  {data.map((e) => {
                    return (
                      <Services
                        subCategory={e?.ServiceSubcategory}
                        originalList={originalList}
                        updateSubCategoryState={updateSubCategoryState}
                        key={e?.id}
                        id={e?.id}
                        existingList={subCategory}
                        category={e?.category}
                      />
                    );
                  })}
                  <div className="text-end">
                    <CommonButton
                      onClick={onSubmit}
                      disabled={isDisableButton() || loading === 'submit'}
                      loading={loading === 'submit'}
                      shouldPermissionApply={true}
                      className="btn btn-primary"
                    >
                      {t('text.common.edit')}
                    </CommonButton>
                  </div>
                </div>
              </div>
            ) : (
              <NoDataFound
                text="No Data Found"
                source="nodata-found.svg"
                isCustomer={'/assets/images/admin/nodata-found.svg'}
              />
            )}
          </div>
        </PageContainerWrapper>
      </div>
    </>
  );
}

export default EditProviderService;
