import { Outlet } from 'react-router-dom';
import { getActions } from '../../services/index.service';
import { ListProvider, ListAdmin } from '../../views/index.view';
import EditAdminProfile from '../../views/TeamSetting/Admin/Edit';
import DocumentListing from '../../views/TeamSetting/ManageDocument/Listing';
import ProfileTab from '../../views/TeamSetting/Providers/Create/index';
import DocumentList from '../../views/TeamSetting/Providers/Documents';
import EditProviderProfile from '../../views/TeamSetting/Providers/Edit';
import EditAvailability from '../../views/TeamSetting/Providers/Edit/Availability';
import EditProviderService from '../../views/TeamSetting/Providers/Edit/Edit Service';
import AssignModules from '../../views/TeamSetting/Roles/Assign';
import RoleListing from '../../views/TeamSetting/Roles/Listing';

export default function route() {
  return [
    {
      private: true,
      name: 'Team Settings',
      key: 'teamSetting',
      belongsToSidebar: true,
      // icon: accessRoute.PROVIDER.icon,
      icon: (
        <span className="nk-menu-icon">
          <em className="icon ni ni-setting-alt-fill" />
        </span>
      ),
      moduleKeys: [
        accessRoute.PROVIDER.moduleKey,
        accessRoute.MANAGE_DOCUMENT.moduleKey,
        accessRoute.ADMIN.moduleKey,
        accessRoute.ROLES.moduleKey,
      ],
      element: <Outlet />,
      submenu: true,
      children: [
        {
          private: true,
          name: accessRoute.PROVIDER.name,
          key: accessRoute.PROVIDER.key,
          path: accessRoute.PROVIDER.path,
          belongsToSidebar: true,
          element: <ListProvider />,
          ...accessRoute.PROVIDER,
        },
        {
          private: true,
          name: accessRoute.ADMIN.name,
          key: accessRoute.ADMIN.key,
          path: accessRoute.ADMIN.path,
          belongsToSidebar: true,
          element: <ListAdmin />,
          ...accessRoute.ADMIN,
        },
        {
          private: true,
          name: accessRoute.MANAGE_DOCUMENT.name,
          label: accessRoute.MANAGE_DOCUMENT.label,
          key: accessRoute.MANAGE_DOCUMENT.key,
          path: accessRoute.MANAGE_DOCUMENT.path,
          belongsToSidebar: true,
          element: <DocumentListing />,
          ...accessRoute.MANAGE_DOCUMENT,
        },

        {
          private: true,
          name: accessRoute.ROLES.name,
          key: accessRoute.ROLES.key,
          path: accessRoute.ROLES.path,
          belongsToSidebar: true,
          element: <RoleListing />,
          ...accessRoute.ROLES,
        },
        {
          private: true,
          name: accessRoute.ADD_PROVIDER.name,
          key: accessRoute.ADD_PROVIDER.key,
          path: accessRoute.ADD_PROVIDER.path,
          belongsToSidebar: false,
          element: <ProfileTab />,
          ...accessRoute.ADD_PROVIDER,
        },
        {
          private: true,
          name: accessRoute.EDIT_PROVIDER_SERVICE.name,
          key: accessRoute.PROVIDER.key,
          path: accessRoute.EDIT_PROVIDER_SERVICE.path,
          belongsToSidebar: false,
          element: <EditProviderService />,
          ...accessRoute.EDIT_PROVIDER_SERVICE,
        },

        {
          private: true,
          name: accessRoute.PROVIDER_DOCUMENT.name,
          key: accessRoute.PROVIDER.key,
          path: accessRoute.PROVIDER_DOCUMENT.path,
          belongsToSidebar: false,
          element: <DocumentList />,
          ...accessRoute.PROVIDER_DOCUMENT,
        },
        {
          private: true,
          name: accessRoute.EDIT_PROVIDER.name,
          key: accessRoute.PROVIDER.key,
          path: accessRoute.EDIT_PROVIDER.path,
          belongsToSidebar: false,
          element: <EditProviderProfile />,
          ...accessRoute.EDIT_PROVIDER,
        },
        {
          private: true,
          name: accessRoute.PROVIDER_EDIT_AVAILABILITY.name,
          key: accessRoute.PROVIDER.key,
          path: accessRoute.PROVIDER_EDIT_AVAILABILITY.path,
          belongsToSidebar: false,
          element: <EditAvailability />,
          ...accessRoute.PROVIDER_EDIT_AVAILABILITY,
        },
        {
          private: true,
          name: accessRoute.EDIT_ADMIN.name,
          key: accessRoute.PROVIDER.key,
          path: accessRoute.EDIT_ADMIN.path,
          belongsToSidebar: false,
          element: <EditAdminProfile />,
          ...accessRoute.EDIT_ADMIN,
        },
        {
          private: true,
          name: accessRoute.ASSIGN_MODULE.name,
          key: accessRoute.ASSIGN_MODULE.key,
          path: accessRoute.ASSIGN_MODULE.path,
          belongsToSidebar: false,
          element: <AssignModules />,
          ...accessRoute.ASSIGN_MODULE,
        },
      ],
    },
  ];
}

export const accessRoute = {
  PROVIDER: {
    path: '/admin/provider',
    name: 'Provider',
    key: 'teamSetting.provider',
    action: getActions('LIST').value,
    moduleKey: 'provider',
    for: ['admin', 'subadmin', 'nca'],
    order: 4,
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-setting-alt-fill" />
    //   </span>
    // ),
  },
  ADMIN: {
    path: '/admin/super-admin',
    name: 'Admin',
    action: getActions('LIST').value,
    moduleKey: 'admin',
    for: ['admin', 'subadmin'],
    key: 'teamSetting.admin',
    order: 3,
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-grid-alt" />
    //   </span>
    // )
  },
  EDIT_ADMIN: {
    path: '/admin/super-admin/edit/:id',
    name: 'Edit Admin',
    action: getActions('EDIT').value,
    moduleKey: 'admin',
    for: ['admin', 'subadmin'],
    key: 'teamSetting.admin',
  },
  MANAGE_DOCUMENT: {
    path: '/admin/manage-document',
    for: ['admin', 'subadmin', 'nca'],
    name: 'Manage Documents',
    label: 'Manage Document',
    key: 'teamSetting.manage-document',
    action: getActions('LIST').value,
    moduleKey: 'manage-documents',
    order: 35,
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-grid-alt" />
    //   </span>
    // ),
  },
  ASSIGN_MODULE: {
    path: '/admin/assign-modules/:id',
    name: 'Assign Modules',
    for: ['admin', 'subadmin'],
    key: 'teamSetting.roles',
    action: getActions('LIST').value,
    moduleKey: 'roles',
  },
  ROLES: {
    path: '/admin/roles',
    name: 'Roles',
    for: ['admin', 'subadmin'],
    key: 'teamSetting.roles',
    action: getActions('LIST').value,
    moduleKey: 'roles',
    order: 33,
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-grid-alt" />
    //   </span>
    // )
  },

  ADD_PROVIDER: {
    path: '/admin/provider/add',
    name: 'Add Provider',
    key: 'teamSetting.provider',
    for: ['admin', 'subadmin', 'nca'],
    action: getActions('CREATE').value,
    moduleKey: 'provider',
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-grid-alt" />
    //   </span>
    // )
  },
  EDIT_PROVIDER: {
    path: '/admin/provider/edit/:id',
    name: 'Edit Provider',
    key: 'teamSetting.provider',
    for: ['admin', 'subadmin'],
    action: getActions('EDIT').value,
    moduleKey: 'provider',
  },
  EDIT_PROVIDER_SERVICE: {
    path: '/admin/provider/service/:id',
    name: 'Provider Service',
    for: ['admin', 'subadmin', 'nca'],
    action: getActions('LIST').value,
    moduleKey: 'provider',
    key: 'teamSetting.provider',
  },
  PROVIDER_DOCUMENT: {
    path: '/admin/provider/document/:id',
    name: 'Provider Document',
    action: getActions('LIST').value,
    moduleKey: 'provider',
    for: ['admin', 'subadmin', 'nca'],
    key: 'teamSetting.provider',
  },
  PROVIDER_EDIT_AVAILABILITY: {
    path: '/admin/provider/availability/:userId',
    name: 'Provider Edit Availability',
    action: getActions('LIST').value,
    moduleKey: 'provider',
    for: ['admin', 'subadmin', 'nca'],
    key: 'teamSetting.provider',
  },
};

export const getChildrenRoutes = () => {
  return route()[0].children;
};
