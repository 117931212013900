import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ActionWrapper,
  ModalWrapper,
  PageContainerWrapper,
  TablePlus,
} from '../../../../components';
import { useTranslation } from 'react-i18next';
import {
  Columns,
  getActions,
  getNcaList,
  postNCAPlanPaymentService,
  putNcaPaymentSettlement,
} from '../../../../services/index.service';
import useTable from '../../../../hooks/useTable.hook';
import { Link, generatePath, useNavigate } from 'react-router-dom';
import { accessRoute } from '../../../../routes/Reports';
import PaymentModal from '../../../../features/modalComponent/payment';
import { useDispatch } from 'react-redux';
import { login } from '../../../../redux/index.redux';
import { modalNotification } from '../../../../utils';

const MODAL_TYPES = {
  PAY_NOW: {
    name: 'PAY_NOW',
    heading: 'text.customerPayment.payNow',
  },
};

function NCAListing() {
  const { t } = useTranslation();
  const breadcrumb = Columns.nclBillingBreadcrumb();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth?.userData);
  const userRole = user?.Role?.type;
  const navigate = useNavigate();
  const [state, setState] = useState({
    loader: null,
    modal: null,
    modalId: null,
    selectedRows: [],
    isOpenModal: false,
  });

  const [tableHandler, tableProps] = useTable(getNcaList);
  const {
    data,
    count,
    page,
    noOfPages,
    sizePerPage,
    filter,
    isLoading,
    sortBy,
    sortType,
  } = tableProps;
  const {
    onFilterChange,
    getData,
    onExport,
    onFilterResetChange,
    onTableSort,
    onRefresh,
  } = tableHandler;

  function onAction(e, handler) {
    e.preventDefault();
    handler();
  }

  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options?.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item?.handler)}
              key={key}
              className="action_list"
              action={item?.action}
            >
              <Link
                // to={`${accessRoute.NCA_BILLING_REPORT_DETAILS.path}/${item?.id}`}
                to={`/admin/nca-billing-report/view/${item?.id}`}
              >
                <em className={item?.icon} />
                {item?.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };

  const handleSubmitPayNow = async (values) => {
    const bodyData = {
      ...values,
      billingId: state?.billingId,
    };

    const response = await postNCAPlanPaymentService(bodyData);
    if (response?.success) {
      modalNotification({
        type: 'success',
        message: response?.message,
      });
      if (response?.data?.ncaBilling) {
        let dataRedux = { ...user, ncaBilling: response?.data?.ncaBilling };
        dispatch(login({ ...dataRedux }));
      }
      setState({
        ...state,
        isOpenModal: false,
      });
      onRefresh();
    }
  };

  const handleOpenPayNowModal = (billingId) => {
    setState((prevState) => ({
      ...prevState,
      billingId: billingId,
      isOpenModal: MODAL_TYPES.PAY_NOW.name,
    }));
  };

  const modalObj = {
    [MODAL_TYPES.PAY_NOW.name]: <PaymentModal onSubmit={handleSubmitPayNow} />,
  };

  const handleMarkAsSettled = async (id) => {
    setState({
      ...state,
      loader: id,
    });
    const response = await putNcaPaymentSettlement(id);
    if (response?.success) {
      onRefresh();
    }
    setState({
      ...state,
      loader: null,
    });
  };

  const columns = Columns.nclBillingColumn({
    onSort: onTableSort,
    onFilterChange: onFilterChange,
    filter: filter,
    page,
    sizePerPage,
    Action: (props) =>
      actionFormatter([
        {
          name: t('text.common.views'),
          icon: 'icon ni ni-eye',
          id: props?.row?.id,
          action: getActions('VIEW').value,
          handler: () => {
            const url = `${generatePath(
              accessRoute?.NCA_BILLING_REPORT_DETAILS?.path,
              {
                id: props?.row?.id,
              }
            )}`;
            navigate(url);
          },
        },
      ]),
    handleOpenPayNowModal,
    handleMarkAsSettled,
    isLoading: state.loader,
    role: userRole,
  });

  const headerBtn = {
    csvExport: { handler: () => onExport('', columns) },
  };
  function onRowSelect(e) {
    try {
      setState({ ...state, selectedRows: [...e] });
    } catch (err) {
      console.log('err', err);
    }
  }

  async function handleActionSelect(e) {
    try {
      if (e.target.value === 'delete') {
        getData();
      } else {
        getData();
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <PageContainerWrapper
        heading={t('text.report.billingReport')}
        breadcrumb={breadcrumb}
        btn={headerBtn}
      >
        <TablePlus
          hasLimit
          noOfPage={noOfPages}
          sizePerPage={sizePerPage}
          page={page}
          handleSelectedRows={onRowSelect}
          handleActionSelect={handleActionSelect}
          count={count}
          tableData={data}
          tableColumns={columns}
          onFilterResetChange={onFilterResetChange}
          selectRow
          showAction={false}
          tableLoader={isLoading}
          filter={filter}
          sortBy={sortBy}
          sortType={sortType}
        />
      </PageContainerWrapper>

      {state?.isOpenModal && (
        <ModalWrapper
          heading={t(MODAL_TYPES[state?.isOpenModal]?.heading)}
          show={state?.isOpenModal}
          content={modalObj[state?.isOpenModal]}
          onCancel={() => setState({ ...state, isOpenModal: '' })}
        ></ModalWrapper>
      )}
    </>
  );
}

export default NCAListing;
