import { MEMBERSHIP_ENDPOINT } from '../../apiEndpoints/index.endpoint';

import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';
export const getMembershipService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...MEMBERSHIP_ENDPOINT?.getMembership,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};
export const addMembershipService = async ({ body }) => {
  try {
    const payload = {
      ...MEMBERSHIP_ENDPOINT?.addMembership,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const editMemberShipService = async ({ body, id }) => {
  try {
    const payload = {
      ...MEMBERSHIP_ENDPOINT?.editMembership(id),
      bodyData: body,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const getMembershipDetailsService = async ({ id }) => {
  try {
    const payload = {
      ...MEMBERSHIP_ENDPOINT?.getMembershipDetails(id),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const cancelMembershipPlan = async (id) => {
  try {
    const payload = {
      ...MEMBERSHIP_ENDPOINT?.cancelMembershipPlan(id),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const updateMembershipStatus = async (bodyData) => {
  try {
    const payload = {
      ...MEMBERSHIP_ENDPOINT?.membershipStatusUpdate,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const deleteMembershipService = async ({ bodyData }) => {
  try {
    const payload = {
      ...MEMBERSHIP_ENDPOINT?.deleteMembership,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
