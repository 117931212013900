import { ServiceProvider } from '../../views/CustomerApp';
import ServiceProviderDetails from '../../views/CustomerApp/ServiceProviderDetails';

export default function route() {
  return [
    {
      path: accessRoute.PROVIDERS.path,
      private: true,
      name: accessRoute.PROVIDERS.name,
      key: accessRoute.PROVIDERS.key,
      belongsToSidebar: true,
      icon: accessRoute.PROVIDERS.icon,
      element: <ServiceProvider />,
    },
    {
      path: accessRoute.SERVICE_PROVIDER_DETAILS().path,
      private: true,
      name: accessRoute.SERVICE_PROVIDER_DETAILS().name,
      key: accessRoute.SERVICE_PROVIDER_DETAILS().key,
      belongsToSidebar: false,
      element: <ServiceProviderDetails />,
    },
  ];
}

export const accessRoute = {
  PROVIDERS: {
    path: '/customer/providers',
    name: 'Search Service Provider',
    key: 'search_service_provider',
    for: 'customer',
    icon: <em className="gg-service-provider"></em>,
  },
  SERVICE_PROVIDER_DETAILS: (id) => ({
    path: `/customer/service-provider-details/${!id ? ':id' : id}`,
    name: 'Service Provider Details',
    key: 'service-provider-details',
    for: 'customer',
  }),
};

export const getChildrenRoutes = () => {
  return route();
};
