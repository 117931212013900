import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { reviewReplyService } from '../../../services/GoogleConnect/googleGMB.service';
import { modalNotification } from '../../../utils';
import {
  FormWrapper,
  FormElementWrapper,
  CommonButton,
  TextInput,
  GlobalLoader,
} from '../../index';

function PostReplyForm({
  setIsAlertVisible,
  reply = false,
  name,
  comment,
  setReviewName,
  getReviewList,
}) {
  const { t } = useTranslation();
  const [editReply, setEditReply] = useState(false);
  const initValues = {
    replyText: '',
  };
  const [formData, setFormData] = React.useState(initValues);
  const [loading, setLoading] = useState(false);
  const [postReply, setPostReply] = useState();

  useEffect(() => {
    if (comment) {
      setFormData((formData) => ({
        ...formData,
        replyText: comment,
      }));
    }
  }, [comment]);

  const handleEditReplyChange = () => {
    setEditReply(true);
  };
  async function onSubmit(value) {
    setLoading(true);
    try {
      let bodyData = {
        name,
        replyText: value?.replyText,
      };
      const response = await reviewReplyService({ bodyData });
      const { success, message } = response;
      if (success) {
        setTimeout(() => {
          modalNotification({
            type: 'success',
            message,
          });
          setLoading(false);
          getReviewList();
        }, 20000);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }

  return (
    <>
      <FormWrapper
        onSubmit={postReply && onSubmit}
        initialValues={{ ...formData }}
        row={true}
        data={{ ...formData }}
        extraClassName="g-3"
        enableReinitialize={true}
      >
        {(props) => {
          if (props?.values?.replyText) {
            setPostReply(props?.values?.replyText);
          }
          return (
            <>
              {editReply && (
                <>
                  <FormElementWrapper
                    md={12}
                    label="Your Reply"
                    mandatory={true}
                    element={() => (
                      <TextInput
                        name="replyText"
                        className="form-control"
                        placeholder={t(
                          'text.googleConnect.replyTextPlaceHolder'
                        )}
                        formik={props}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={12}
                    element={() => (
                      <>
                        <CommonButton
                          className="btn btn-primary"
                          type="submit"
                          disabled={loading}
                          // onClick={() => handlePostReplyChange()}
                        >
                          {loading && (
                            <GlobalLoader
                              animation="grow"
                              size="sm"
                              variant="white"
                            />
                          )}
                          {t('text.googleConnect.postReply')}
                        </CommonButton>
                        <CommonButton
                          className="btn btn-light ms-2"
                          onClick={() => setEditReply(false)}
                        >
                          {t('text.common.cancel')}
                        </CommonButton>
                      </>
                    )}
                  />
                </>
              )}
              {!editReply && (
                <FormElementWrapper
                  md={12}
                  element={() => (
                    <>
                      <CommonButton
                        className="btn btn-primary"
                        type="submit"
                        htmlType="submit"
                        onClick={() => handleEditReplyChange()}
                      >
                        {reply
                          ? t('text.googleConnect.reply')
                          : t('text.common.edit')}
                      </CommonButton>
                      {!reply && (
                        <Link
                          to="#"
                          className="btn btn-light ms-2"
                          onClick={(e) => {
                            e.preventDefault();
                            setIsAlertVisible(true);
                            setReviewName(name);
                          }}
                        >
                          {t('text.common.delete')}
                        </Link>
                      )}
                    </>
                  )}
                />
              )}
            </>
          );
        }}
      </FormWrapper>
    </>
  );
}
export default PostReplyForm;
