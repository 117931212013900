import { useContext } from 'react';
import  Button  from 'react-bootstrap/Button';
import   Spinner  from 'react-bootstrap/Spinner';
import { useSelector } from 'react-redux';
import { PermissionContext } from '../../../../context/permission.context';
import { getActions } from '../../../../services/index.service';

function CommonButton({
  children,
  extraClassName = '',
  loading,
  shouldPermissionApply = false,
  variant = 'none',
  ...rest
}) {
  const action = useContext(PermissionContext);
  const userType = useSelector((state) => state?.auth?.userData?.Role?.type);
  const isUserCanEdit =
    (action && action?.arr?.includes(getActions('CREATE').value)) ||
    userType === 'admin';
  if (!isUserCanEdit && shouldPermissionApply) {
    return <></>;
  }
  return (
    <Button
      disabled={loading}
      className={`${extraClassName}`}
      variant={variant}
      {...rest}
    >
      {loading ? <Spinner size="sm" as="div" animation="border" /> : children}
    </Button>
  );
}
export default CommonButton;
