import { Dashboard } from '../../views/index.view';

export default function route() {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      private: true,
      name: accessRoute.DASHBOARD.name,
      key: accessRoute.DASHBOARD.key,
      belongsToSidebar: true,
      icon: accessRoute.DASHBOARD.icon,
      element: <Dashboard />,
      ...accessRoute.DASHBOARD,
    },
  ];
}

export const accessRoute = {
  DASHBOARD: {
    path: '/admin/dashboard',
    name: 'Dashboard',
    key: 'dashboard',
    moduleKey: 'dashboard',
    label: 'OneView Dashboard',
    ignorePermission: true,
    action: 'view',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt-fill" />
      </span>
    ),
    order: 1,
  },
};

export const getChildrenRoutes = () => {
  return route();
};
