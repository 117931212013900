import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { APP_NAME, ENVIRONMENT } from '../../config';
import {
  authSlice,
  commonSlice,
  permissionSlice,
  googleMap,
} from '../slices/index.slice';

const RootReducer = combineReducers({
  auth: authSlice,
  common: commonSlice,
  permission: permissionSlice,
  googleMap,
});

const encryptor = encryptTransform({
  secretKey: `${APP_NAME}-storage`,
  onError: () => {
    // Handle the error.
    // logger({ error });
  },
});

const persistConfig = {
  key: APP_NAME,
  storage,
  whitelist: ['auth', 'profile', 'permission', 'googleMap', 'common'],
  transforms: [encryptor],
};

const persistedReducer = persistReducer(persistConfig, RootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    // https://github.com/rt2zz/redux-persist/issues/988#issuecomment-552242978
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
  devTools: ENVIRONMENT !== 'production',
});

export default store;

export const Persistor = persistStore(store);
