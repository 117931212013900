import { ListTags } from '../../views/index.view';
import { getActions } from '../../services/Auth/index.service';

export default function route() {
  return [
    {
      path: accessRoute.TAGS.path,
      private: true,
      name: 'Tags',
      action: accessRoute.TAGS.action,
      key: accessRoute.TAGS.key,
      belongsToSidebar: true,
      submenu: false,
      icon: accessRoute.TAGS.icon,
      element: <ListTags />,
      ...accessRoute.TAGS,
    },
  ];
}

export const accessRoute = {
  TAGS: {
    path: '/admin/tags',
    action: getActions('LIST').value,
    key: 'tags',
    moduleKey: 'tags',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-opt-alt-fill" />
      </span>
    ),
    order: 48,
  },
};
export const getChildrenRoutes = () => {
  return route();
};
