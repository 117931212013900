import { createSlice } from '@reduxjs/toolkit';
import { logger } from '../../../utils';
import { viewFooterSocialLinkService } from '../../../services/index.service';
// import { currentTimeStamp } from '../../utilities/common';

export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    notification: {
      list: [],
      count: 0,
      recentlyFetchedAt: '',
      roles: [],
      setting: {},
      chatCount: 0,
    },
    orderCount: 0,
    importData: {},
    notificationCount: null,
    isEnableExport: false,
    socialMedia: {},
    importAPIData: {},
  },
  reducers: {
    addNotificationsAction: (state, action) => {
      return (state = {
        ...state,
        notification: {
          list: action.payload.list,
          count: action.payload.count,
          // recentlyFetchedAt: currentTimeStamp(),
        },
      });
    },
    updateNotificationsCountAction: (state, action) => {
      return (state = {
        ...state,
        notification: {
          ...state.notification,
          count: action.payload.count,
        },
      });
    },
    updateLocationAction: (state, action) => {
      return (state = {
        ...state,
        setting: {
          ...state.setting,
          location: action.payload.location,
        },
      });
    },
    deleteNotificationsCountAction: (state) => {
      return (state = {
        ...state,
        notification: {
          list: [],
          count: 0,
          recentlyFetchedAt: '',
        },
      });
    },
    addUserRolesAction: (state, action) => {
      return (state = {
        ...state,
        userRoles: action.payload,
      });
    },
    addRolesAction: (state, action) => {
      return (state = {
        ...state,
        roles: action.payload,
      });
    },
    addOrderCountAction: (state, action) => {
      return (state = {
        ...state,
        orderCount: action.payload,
      });
    },
    importFileDataAction: (state, action) => {
      return (state = {
        ...state,
        importData: action.payload,
      });
    },
    importFileAPIDataAction: (state, action) => {
      return (state = {
        ...state,
        importAPIData: action.payload,
      });
    },
    notificationCountAction: (state, action) => {
      return (state = {
        ...state,
        notificationCount: action.payload,
      });
    },
    updateIsEnableExportStatus: (state, action) => {
      return (state = {
        ...state,
        isEnableExport: action.payload?.length ? true : false,
      });
    },
    customerLinkAction: (state, action) => {
      return (state = {
        ...state,
        socialMedia: { ...action.payload },
      });
    },
    chatCountAction: (state, action) => {
      return (state = {
        ...state,
        notification: {
          chatCount: action.payload,
        },
      });
    },
    marketingCountAction: (state, action) => {
      return (state = {
        ...state,
        marketingNotification: {
          marketingCount: action.payload,
        },
      });
    },
  },
});

export const {
  addNotificationsAction,
  updateNotificationsCountAction,
  addRolesAction,
  addUserRolesAction,
  deleteNotificationsCountAction,
  addOrderCountAction,
  importFileDataAction,
  importFileAPIDataAction,
  notificationCountAction,
  updateIsEnableExportStatus,
  customerLinkAction,
  chatCountAction,
  marketingCountAction,
} = commonSlice.actions;

export const addOrderCount = (data) => async (dispatch) => {
  try {
    dispatch(addOrderCountAction(data));
  } catch (error) {
    logger(error);
  }
};

// For import data
export const importFileData = (data) => async (dispatch) => {
  try {
    dispatch(importFileDataAction(data));
  } catch (error) {
    logger(error);
  }
};

export const importFileAPIData = (data) => async (dispatch) => {
  try {
    dispatch(importFileAPIDataAction(data));
  } catch (error) {
    logger(error);
  }
};

export const customerNotificationCount = (data) => async (dispatch) => {
  try {
    dispatch(notificationCountAction(data));
  } catch (error) {
    logger(error);
  }
};
export const customerLink = () => async (dispatch) => {
  try {
    const res = await viewFooterSocialLinkService();
    if (res.success) {
      dispatch(customerLinkAction(res?.data));
    }
  } catch (error) {
    logger(error);
  }
};

export const chatCountNotification = (data) => async (dispatch) => {
  try {
    dispatch(chatCountAction(data));
  } catch (error) {
    logger(error);
  }
};

export const marketingCountNotification = (data) => async (dispatch) => {
  try {
    dispatch(marketingCountAction(data));
  } catch (error) {
    logger(error);
  }
};

export const selectNotifications = (state) => state.common.notifications;
export const selectRoles = (state) => state.common.roles;
export const selectUserRoles = (state) => state.common.userRoles;
export const selectOrderCount = (state) => state.common.orderCount;
export const selectImportData = (state) => state.common.importData;
export const getNotificationValue = (state) => state.common.notificationCount;
export const getSocialMediaLinks = (state) => state.common.socialMedia;
export const selectImportAPIData = (state) => state.common.importAPIData;
export const chatNotification = (state) => state.common.notification;
export const marketingNotification = (state) => state.common.marketingNotification;
export default commonSlice.reducer;

export const updateLocation =
  () =>
  // data
  async () => // dispatch
  {
    // try {
    //   const res = await getGeneralSetting();
    //   if(Array.isArray(res?.data?.rows)){
    //     const mapKey = res.data.rows.find(e=>e?.key === "google_server_key");
    //     if(mapKey?.value) {
    //       dispatch(updateLocationAction({location:mapKey?.value}))
    //     }
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };
