export const text = {
  couponCode: 'Coupon Code',
  enterCouponCode: 'Enter Coupon code',
  usageLimit: 'Usage limit',
  enterUsageLimit: 'Enter usage limit',
  discountType: 'Discount type',
  selectDiscountType: 'Select Discount type',
  discount: 'Discount',
  enterDiscountAmount: 'Enter discount amount',
  activationDate: 'Activation date',
  selectActivationDate: 'Select activation date',
  expiryDate: 'Expiry date',
  selectExpiryDate: 'Select expiry date',
  validity: 'Validity',
  selectValidity: 'Select Validity',
  categoryTitle: 'Category Management',
  addCategory: 'Add Category',
  CategoryImage: 'Category Image',
  categoryName: 'Category Name',
  areYouSureCategory: 'you want to delete this category',
  subCategoryManagement: 'Sub Category Management',
  subCategoryName: 'Sub Category Name',
  addSubCategory: 'Add Sub Category',
  downloadSample: 'Download Sample',
  Images1: 'Product Images 1',
  Images2: 'Product Images 2',
  Images3: 'Product Images 3',
  productTitle: 'Product Title',
  productCategory: 'Product Category',
  productVariant: 'Product Variant',
  productQuantity: 'Product Quantity',
  productSKU: 'Product SKU',
  productPrice: 'Product Price',
  productDescription: 'Product Description',
  variantManagement: 'Variants Management',
  variantName: 'Variant Name',
  addVariant: 'Add Variant',
  deleteThisOrder: 'you want to delete this Order',
  areYouSureDeleteProduct: 'Are You Sure Delete Product',
  attributeName: 'Attribute Name',
  variantOption: 'Variant Option',
  areYouSureVariant: 'you want to delete this variant',
  productName: 'Product Name',
  allProductImages: 'All Product Images',
  priceMustBePositive: 'Price must me be positive',
  areYouSureDeleteCouponCode: 'you want to delete this coupon',
  orderID: 'Order ID',
  orderDate: 'Order Date',
  orderType: 'Order Type',
  shippingAddress: 'Shipping Address',
  paymentMode: 'Payment Mode',
  image: 'Image',
  name: 'Name',
  variant: 'Variant',
  quantity: 'Quantity',
  price: 'Price',
  total: 'Total',
  couponDiscount: 'Coupon Discount',
  shippingCharges: 'Shipping Charges',
  spNotes: 'SP Notes',
  productsItems: 'Products Items',
  orderStatus: 'Order Status',
  spName: 'SP Name',
  totalCost: 'Total Cost',
  orderDateTime: 'Order Date Time',
  selfPickup: 'Self Pickup',
  delivery: 'Delivery',
  shipping: 'Shipping',
};
