import React, { useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import {
  Breadcrumb,
  GlobalLoader,
  ImageElement,
  ModalWrapper,
  NoDataFound,
} from '../../../components';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../../../../node_modules/sassy-datepicker/dist/styles.css';
import CustomerBooking from '../../../features/modalComponent/customerBooking';
import { getAddressService } from '../../../services/Home';
import { useEffect } from 'react';
import { accessRoute } from '../../../routes/CustomerApp/booking';
import { firstLetterCaps } from '../../../utils/text.util';
import { Columns } from '../../../services/index.service';
import Favorite from '../../../components/Favorite';
import { getProviderDetailsById } from '../../../services/CustomerApp/index.service';
import { generateStarRating } from '../../../utils';
import { accessRoute as scheduleProviderRoutes } from '../../../routes/CustomerApp/scheduleServiceProvider';
import { accessRoute as bookingRoutes } from '../../../routes/CustomerApp/booking';
import { useTranslation } from 'react-i18next';

function ServiceProviderDetailsMiles() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [state, setState] = useState({
    modal: '',
    modalTitle: '',
    selectedAddress: [],
    providerDetails: {},
    isLoading: true,
  });
  const { modal, modalTitle } = state;
  const breadcrumb = Columns.customerProviderDetailsBreadcrumb();

  const modalObj = {
    customerBooking: (
      <CustomerBooking
        submitText={'Booking Confirm'}
        setModal={setState}
        stateModal={state}
        isSuccess={true}
      />
    ),
    success: (
      <>
        <div className="paidEmergency">
          <p>{t('text.scheduleServiceProvider.successMessage')}</p>
        </div>
        <div className="d-flex justify-content-flex-end">
          <Link
            to={`${bookingRoutes.BOOKING.path}?tab=upcoming`}
            className="btn btn-primary ms-auto min-200"
          >
            {t('text.common.viewBooking')}
          </Link>
        </div>
      </>
    ),
  };

  const handleStateChange = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getPageDetails = async () => {
    handleStateChange('isLoading', true);
    await Promise.all([getAddressList(), getProviderDetails()]);
    handleStateChange('isLoading', false);
  };

  async function getAddressList() {
    try {
      const res = await getAddressService({
        scope: 'activeCustomerAddresses',
      });
      if (res?.success) {
        handleStateChange('selectedAddress', res?.data?.rows ?? []);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function getProviderDetails() {
    try {
      const res = await getProviderDetailsById(
        location?.state?.providerDetails?.Provider?.UserId
      );
      if (res?.success) {
        handleStateChange('providerDetails', res?.data);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (!location?.state) {
      navigate(accessRoute.BOOKING.path);
    }
    getPageDetails();
  }, []);

  return (
    <>
      <div className="main-content providerDetailPage">
        {/* page content */}
        <div className="pageContent">
          <div className="pageContent_wide">
            <Container className="container-lg">
              <div className="d-flex justify-content-between">
                <Breadcrumb
                  type="customer"
                  title={t('text.scheduleServiceProvider.providerDetailsTitle')}
                  breadcrumb={breadcrumb}
                />
                <Link
                  to={scheduleProviderRoutes.SCHEDULE_PROVIDER_LIST.path}
                  state={location?.state}
                  className="btn btn-outline-info ms-3 mb-3 btn-sm"
                >
                  {t('text.common.back')}
                </Link>
              </div>

              <div className="detailsBox br-10 p-30 bg-white">
                {state?.isLoading ? (
                  <div className="pt-5 pb-5">
                    <GlobalLoader />
                  </div>
                ) : (
                  <>
                    <div className="serviceAddress_left mb-3">
                      <h4 className="text-850 font-sm mb-1">Service Address</h4>
                      <p className="mb-0">
                        {state?.selectedAddress?.length
                          ? state?.selectedAddress.find(
                              (address) =>
                                address.id ===
                                Number(location?.state?.locationId)
                            )?.addressLine1
                          : null}
                      </p>
                    </div>

                    <div className="detailsBox_user text-center position-relative">
                      <ImageElement
                        previewSource={
                          location?.state?.providerDetails
                            ?.profilePictureThumbUrl
                        }
                        alt="user"
                      />
                      <div className="mt-md-3 mt-2">
                        <h3 className="font-md">
                          {firstLetterCaps(
                            location?.state?.providerDetails?.firstName
                          )}
                          &nbsp;
                          {firstLetterCaps(
                            location?.state?.providerDetails?.lastName
                          )}
                        </h3>
                        <div className="d-flex justify-content-center">
                          <ul className="list-unstyled d-flex starRating mb-0 justify-content-center">
                            {generateStarRating(
                              state?.providerDetails?.rating ?? 0
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="appointmentList_like">
                        <Favorite
                          userId={state?.providerDetails?.id}
                          isFavorite={
                            state?.providerDetails?.isFavorite === 1
                              ? true
                              : false
                          }
                        />
                      </div>
                    </div>
                    <div className="detailsBox_desc">
                      {state?.providerDetails?.Provider?.aboutMe ? (
                        <p className="ft-14">
                          {state?.providerDetails?.Provider?.aboutMe}
                        </p>
                      ) : null}
                    </div>
                    <div className="d-flex justify-content-end">
                      <Link
                        to={
                          scheduleProviderRoutes.SERVICE_PROVIDER_CONTACT_US
                            .path
                        }
                        state={{
                          providerId:
                            location?.state?.providerDetails?.Provider?.UserId,
                        }}
                        className={`btn btn-secondary min-200 ${
                          !location?.state?.isBooking && 'me-3'
                        }`}
                      >
                        {t('text.customerService.contactTech')}
                      </Link>
                      {!location?.state?.isBooking && (
                        <Button
                          variant="primary"
                          className="min-200"
                          onClick={() =>
                            setState({
                              ...state,
                              modal: 'customerBooking',
                              modalTitle: 'Booking Details',
                            })
                          }
                        >
                          {t('text.scheduleServiceProvider.continueBooking')}
                        </Button>
                      )}
                    </div>
                  </>
                )}
              </div>
            </Container>
          </div>
        </div>
      </div>
      {/* Booking detail */}
      <ModalWrapper
        onCancel={() => {
          if (state?.modal === 'success') {
            navigate(accessRoute.BOOKING.path);
          } else {
            handleStateChange('modal', '');
          }
        }}
        heading={modalTitle}
        show={modal}
        modalExtraClass="bookingDetails"
        extraClassName="modal-lg"
        extraTitleClassName="align-items-start"
        content={modalObj[modal]}
      ></ModalWrapper>
    </>
  );
}

export default ServiceProviderDetailsMiles;
