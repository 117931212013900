import { APIRequest } from '../../helpers/index.helper';
import { QUOTE_SYSTEM_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { logger } from '../../utils';

export const listQuoteSystemService = async ({ queryParams }) => {
  try {
    const payload = {
      ...QUOTE_SYSTEM_ENDPOINT.getQuoteSystemList,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const deleteQuoteSystemService = async ({ bodyData }) => {
  try {
    const payload = {
      ...QUOTE_SYSTEM_ENDPOINT.deleteQuoteSystem,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const updateStatusQuoteSystemService = async (bodyData) => {
  try {
    const payload = {
      ...QUOTE_SYSTEM_ENDPOINT.statusUpdateQuoteSystem,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const updateAvailabilityStatusQuoteSystemService = async ({
  bodyData,
  id,
}) => {
  try {
    const payload = {
      ...QUOTE_SYSTEM_ENDPOINT.availabilityStatusUpdateQuoteSystem(id),
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const addQuoteSystemService = async ({ body }) => {
  try {
    const payload = {
      ...QUOTE_SYSTEM_ENDPOINT.addQuoteSystem,
      bodyData: body,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const editQuoteSystemService = async ({ body, id }) => {
  try {
    const payload = {
      ...QUOTE_SYSTEM_ENDPOINT.editQuoteSystem(id),
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const viewQuoteSystemService = async ({ id }) => {
  try {
    const payload = {
      ...QUOTE_SYSTEM_ENDPOINT?.getQuoteSystemDetails(id),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const importQuoteSystemService = async ({ body }) => {
  try {
    const payload = {
      ...QUOTE_SYSTEM_ENDPOINT.quoteUpload,
      bodyData: body,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

// View Quote image
export const listViewQuoteImageService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...QUOTE_SYSTEM_ENDPOINT.getQuoteViewImageList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const deleteViewQuoteImageService = async ({ id }) => {
  try {
    const payload = {
      ...QUOTE_SYSTEM_ENDPOINT.deleteQuoteViewImage(id),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
