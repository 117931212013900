import i18next from 'i18next';
import {
  checkValidData,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';

import { getFullName } from '../../utils/text.util';
import { dateFormatter, phoneNumberFormate } from '../../utils';
import { accessRoute } from '../../routes/Dashboard';

export const onlineProviderTableColumn = ({ page, sizePerPage }) => {
  let columns = [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      render: (cell, row, index) =>
        serialNumberFormatter({ page, index, sizePerPage }),
    },
    {
      dataIndex: 'userName',
      title: i18next.t('text.provider.providerName'),
      headerClasses: 'sorting',

      render: (cell, row) => {
        return row?.firstName || row?.lastName
          ? getFullName(row?.firstName, row?.middleName, row?.lastName)
          : '-';
      },
    },
    {
      dataIndex: 'email',
      title: i18next.t('text.common.email'),
      headerClasses: 'sorting',

      render: (cell, row) => checkValidData(cell),
    },
    // {
    //   dataIndex: 'createdAt',
    //   filterDataIndex: ['fromDate', 'toDate'],
    //   title: i18next.t('text.common.signUpDate'),
    //   render: (cell, row) => dateFormatter(cell, 'Do MMMM, YYYY'),
    // },
    {
      dataIndex: 'phoneNumber',
      title: i18next.t('text.common.mobile'),
      render: (cell, row) => {
        const formatNumber = phoneNumberFormate(cell, row?.countryPhoneCode);
        return formatNumber ?? '-';
      },
    },
    {
      dataIndex: 'radius',
      title: i18next.t('text.dashboard.emergencyRadius'),
      headerClasses: 'sorting',

      render: (cell, row) =>
        `${
          row?.Provider?.emergencyJobRadius
            ? `${checkValidData(row?.Provider?.emergencyJobRadius)} Miles`
            : '-'
        }`,
    },
    // {
    //   dataIndex: 'providerNca',
    //   title: i18next.t('text.provider.providerNca'),
    //   headerClasses: 'sorting',
    //   render: (cell, row) =>
    //     checkValidData(
    //       row?.Provider?.CompanyNca?.name
    //         ? row?.Provider?.CompanyNca?.name
    //         : i18next.t('text.common.goodGuysAdmin')
    //     ),
    // },
  ];

  return columns;
};

export const onlineProviderBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.dashboard.onlineProvider'),
    },
  ];
};
