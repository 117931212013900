import React, { useEffect, useState } from 'react';

import { getApiData } from '../../../hooks/useGetApi.hook';
import { getMemberShipCustomerData } from '../../../services/Customers/customers.service';
import { GlobalLoader, NoDataFound } from '../../../components';
import {
  checkValidCount,
  checkValidData,
  checkValidDiscountType,
} from '../../../components/UIElements/Formatter';
import { useTranslation } from 'react-i18next';

export default function MemberShipPlan({
  id,
  memberShipUpgradeModalShow,
  isOpenModal,
  setMemberShipData,
  memberShipData,
  isAlertVisible,
}) {
  // const [memberShipData, setMemberShipData] = useState([]);
  const [getApiLoader, setGetApiLoader] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    getApiData({
      id,
      service: getMemberShipCustomerData,
      setData: setMemberShipData,
      setIsLoading: setGetApiLoader,
    });
  }, [id, isOpenModal, isAlertVisible]);

  return (
    <>
      {' '}
      <>
        <h5 className="mb-3">{t('text.common.memberPlan')}</h5>
        <div className="membershipPlan">
          {getApiLoader ? (
            <GlobalLoader />
          ) : memberShipData?.rows?.length > 0 ? (
            <ul>
              {memberShipData?.rows?.map((item) => (
                <li
                  className={`membershipPlan_box ${item?.isActivePlan === 1 ? 'active' : 'upgrade'
                    }`}
                  onClick={() => memberShipUpgradeModalShow(item)}
                >
                  <div className="d-flex flex-wrap align-items-center justify-content-between mb-1">
                    <h6 className="mb-0">{checkValidData(item?.name)}</h6>
                    {item?.CustomerActivePlan?.membershipPlanId ? (
                      <span className="badge badge-active">Active</span>
                    ) : (
                      <span
                        className={`badge badge-${item?.isActivePlan === 1 ? 'active' : 'upgrade'
                          }`}
                      >
                        {memberShipData?.rows.find(
                          (e) => e?.CustomerActivePlan?.membershipPlanId
                        )
                          ? 'Upgrade'
                          : 'Activate'}
                      </span>
                    )}
                  </div>
                  {item?.isMonthly ? (
                    <div className="d-flex align-items-center justify-content-between mb-1">
                      <p>{t('text.common.monthly')}</p>
                      <p>${checkValidCount(item?.monthlyPrice)}</p>
                    </div>
                  ) : null}
                  {item?.isYearly ? (
                    <div className="d-flex align-items-center justify-content-between">
                      <p>{t('text.common.yearly')}</p>
                      <p>${checkValidCount(item?.yearlyPrice)}</p>
                    </div>
                  ) : null}
                  {(item?.typeOfBenefit === 'combo') ?
                    <div className="">
                      <p>{t('text.common.save')}</p>
                      <div className='d-flex align-items-center justify-content-between ms-2'>
                        <p>{t('text.common.serviceItemDiscount')}</p>
                        <p>{(item?.savingType && item?.savingAmount) &&
                          checkValidDiscountType(
                            item?.savingType,
                            item?.savingAmount
                          )}
                        </p>
                      </div>
                      <div className='d-flex align-items-center justify-content-between ms-2'>
                        <p>{t('text.memberShip.quoteDiscount')}</p>
                        <p>{(item?.quoteSavingType && item?.quoteSavingAmount) &&
                          checkValidDiscountType(
                            item?.quoteSavingType,
                            item?.quoteSavingAmount
                          )}
                        </p>
                      </div>
                      <div className='d-flex align-items-center justify-content-between ms-2'>
                        <p>{t('text.common.lineItemDiscount')}</p>
                        <p>{(item?.lineItemSavingType && item?.lineItemSavingAmount) &&
                          checkValidDiscountType(
                            item?.lineItemSavingType,
                            item?.lineItemSavingAmount
                          )}
                        </p>
                      </div>
                    </div>
                    : ((item?.savingAmount && item?.savingType) ||
                      (item?.lineItemSavingType && item?.lineItemSavingAmount)) ? (
                      <div className="d-flex align-items-center justify-content-between">
                        <p>{t('text.common.save')}</p>
                        <p>
                          {
                            item?.savingType && item?.savingAmount
                              ? checkValidDiscountType(
                                item?.savingType,
                                item?.savingAmount
                              ) :
                              item?.lineItemSavingType && item?.lineItemSavingAmount &&
                              checkValidDiscountType(
                                item?.lineItemSavingType,
                                item?.lineItemSavingAmount
                              )
                          }
                        </p>
                      </div>
                    ) : null}
                </li>
              ))}
            </ul>
          ) : (
            <NoDataFound
              source={`/nodata-found.svg`}
              text={t('text.common.NoPlanFound')}
              extraClass="noDataFoundWrapper"
            />
          )}
        </div>
      </>
    </>
  );
}
