import i18next from 'i18next';
import {
  ButtonTextFormatter,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { accessRoute } from '../../routes/GoogleConnect';
import { logoFormatter, readMoreTextShow } from '../../utils';
import { adminLogoFormatter, customerLogoFormatter } from '../../utils';

const TextFormatter = (heading, para) => {
  const getBusinessAddress = (data) => {
    let businessAddress = '';
    if (data?.storefrontAddress) {
      let address = data?.storefrontAddress;
      businessAddress = `${address?.addressLines[0]} ${address?.locality} ${address?.administrativeArea} ${address?.postalCode}`;
      return businessAddress;
    }
    if (data?.serviceArea) {
      let address = data?.serviceArea?.places;
      businessAddress = `${address?.placeInfos[0]?.placeName} ${
        address?.placeInfos[1]?.placeName
      } ${address?.placeInfos?.length - 2} others areas`;
      return businessAddress;
    }
  };

  return (
    <>
      <h6 className="mb-0">{heading}</h6>
      <p>{para ? getBusinessAddress(para) : '-'}</p>
    </>
  );
};

export const googleGMBBreadcrumb = () => {
  return [
    {
      path: '/admin/dashboard',
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.googleConnect.titleGMB'),
    },
  ];
};

export const googleGMBPhotoBreadcrumb = () => {
  return [
    {
      path: '/admin/dashboard',
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: accessRoute.GOOGLE_GMB.path,
      name: i18next.t('text.googleConnect.titleGMB'),
    },
  ];
};
export const googleGMBReviewsBreadcrumb = () => {
  return [
    {
      path: '/admin/dashboard',
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: accessRoute.GOOGLE_GMB.path,
      name: i18next.t('text.googleConnect.titleGMB'),
    },
    {
      path: '#',
      name: i18next.t('text.googleConnect.googleMediaList'),
    },
  ];
};

export const GooglePostListBreadcrumb = () => {
  return [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/google-gmb',
      name: 'Google My Business',
    },
    {
      path: '#',
      name: 'Google Post List',
    },
  ];
};
export const AddGoogleMediaBreadcrumb = () => {
  return [
    {
      path: '/admin/dashboard',
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '/admin/google-gmb',
      name: i18next.t('text.googleConnect.titleGMB'),
    },
    {
      path: '/admin/google-gmb/photo',
      name: i18next.t('text.googleConnect.googleMediaList'),
    },
    {
      path: '#',
      name: i18next.t('text.googleConnect.mediaTitle'),
    },
  ];
};

export const createGooglePostBreadcrumb = () => {
  return [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/google-gmb',
      name: 'Google My Business',
    },
    {
      path: '/admin/google-gmb/post',
      name: 'Google Post List',
    },
    {
      path: '#',
      name: 'Create Google Post',
    },
  ];
};
export const googleGMBPostColumn = ({
  Action,
  readMoreModal,
  showMoreText,
  page,
  sizePerPage,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'image',
      title: 'Image',
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => logoFormatter(row?.image, 'Image'),
    },
    {
      dataIndex: 'post',
      title: 'Post',
      headerClasses: 'sorting',
      sorter: true,
      // render: (cell, row) => ModalOpenFormatter(readMoreModal, row?.post),
      render: (cell, row) => readMoreTextShow(cell, showMoreText),
    },
    {
      dataIndex: 'action',
      title: 'Action',
      className: 'nk-tb-col-tools text-end',
      render: () => <Action />,
      name: i18next.t('text.googleConnect.googleReviewList'),
    },
  ];
};

const StatusFormatter = (status) => {
  return (
    <>
      {status === 'COMPLETED' ? (
        <span className="badge badge-md badge-dim bg-success d-inline-flex align-items-center">
          <em className="ni ni-check-circle-fill me-1" />
          Verified
        </span>
      ) : (
        <span className="badge badge-md badge-dim bg-danger d-inline-flex align-items-center">
          <em className="ni ni-cross-circle-fill me-1" />
          UnVerified
        </span>
      )}
    </>
  );
};

export const googleGMBServiceTableColumn = ({
  Action,
  onSort,
  page,
  sizePerPage,
  account,
  navigate,
}) => {
  return [
    {
      dataIndex: 'business',
      title: i18next.t('text.googleConnect.business'),
      // headerClasses: 'sorting',
      // sorter: true,
      onSort: (field, order) => onSort('business', order),
      render: (cell, row) =>
        row?.business
          ? TextFormatter(row?.business?.title, row?.business)
          : '-',
    },
    {
      dataIndex: 'totalReviews',
      title: i18next.t('text.googleConnect.totalReview'),
      // headerClasses: 'sorting',
      // sorter: true,
      onSort: (field, order) => onSort('totalReviews', order),
      render: (cell, row) =>
        ButtonTextFormatter(navigate, row?.totalReviewCount, 'googleText', {
          name: row?.business?.name,
          accountId: account,
          businessName: row?.business?.title,
        }),
    },
    {
      dataIndex: 'googleRating',
      title: i18next.t('text.googleConnect.googleRating'),
      // headerClasses: 'sorting',
      // sorter: true,
      onSort: (field, order) => onSort('googleRating', order),
      render: (cell, row) =>
        row?.averageRating ? `${row?.averageRating} Stars` : '-',
    },
    {
      dataIndex: 'reviewsToRespondTo',
      title: i18next.t('text.googleConnect.reviewsToRespondTo'),
      // headerClasses: 'sorting',
      // sorter: true,
      onSort: (field, order) => onSort('reviewsToRespondTo', order),
      render: (cell, row) => 'NA',
    },
    {
      dataIndex: 'status',
      title: i18next.t('text.common.status'),
      // headerClasses: 'sorting',
      // sorter: true,
      onSort: (field, order) => onSort('status', order),
      // render: () => <Toggle />,
      render: (cell, row) => StatusFormatter(row?.status),
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.common.action'),
      render: (cell, row) => <Action row={row} />,
      sorter: false,
      className: 'nk-tb-col-tools text-end',
    },
  ];
};

export const reviewGMBColumns = ({ Action }) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'customerProfile',
      title: i18next.t('text.common.customer'),
      headerClasses: 'sorting',
      render: (cell, row) =>
        customerLogoFormatter(
          row?.customerProfile,
          'Louisa Johnson',
          'Aug 27, 2022'
        ),
    },
    {
      dataIndex: 'customerPost',
      title: i18next.t('text.googleConnect.customerPost'),
      headerClasses: 'sorting',
      // render: (cell, row) =>
      // ModalOpenFormatter(customerPostModal, row?.customerPost),
    },
    {
      dataIndex: 'AdminProfile',
      title: i18next.t('text.googleConnect.admin'),
      headerClasses: 'sorting',
      render: (cell, row) =>
        adminLogoFormatter(
          row?.AdminProfile,
          'Tékhnē Heating & Air Conditioning',
          'Oct 6, 2022'
        ),
    },
    {
      dataIndex: 'adminPost',
      title: i18next.t('text.googleConnect.AdminReply'),
      headerClasses: 'sorting',
      // render: (cell, row) => ModalOpenFormatter(AdminReplyModal, row?.adminPost),
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.common.action'),
      className: 'nk-tb-col-tools text-end',
      render: () => <Action />,
    },
  ];
};

export const GoogleConnectGMBServices = () => {
  return [
    {
      id: 1,
      business: 'Tékhnē Heating & Air Conditioning',
      totalReviews: 'View(11)',
      googleRating: '5 Stars',
      reviewsToRespondTo: 'NA',
    },
    {
      id: 2,
      business: 'Good Guys Heating & Air Conditioning, LLC',
      totalReviews: 'View(15)',
      googleRating: '4.9 Stars',
      reviewsToRespondTo: 'NA',
    },
    {
      id: 3,
      business: 'Good Guys Heating & Air Conditioning',
      totalReviews: 'View(20)',
      googleRating: '5 Stars',
      reviewsToRespondTo: 'NA',
    },
    {
      id: 4,
      business: 'Tékhnē Heating & Air Conditioning',
      totalReviews: 'View(113',
      googleRating: '5 Stars',
      reviewsToRespondTo: 'NA',
    },
  ];
};

export const customerDataGMB = () => {
  return [
    {
      id: 1,
      image: '/assets/images/admin/uploadImg.png',
      post: 'New post: HVAC SERVICES-Reasons to run your AC during winter - As a homeowner, you might want to run As a homeowner, you might want to run ',
    },
    {
      id: 2,
      image: '/assets/images/admin/uploadImg.png',
      post: 'New post: HVAC SERVICES-Reasons to run your AC during winter - As a homeowner, you might want to run As a homeowner, you might want to run ',
    },
    {
      id: 3,
      image: '/assets/images/admin/uploadImg.png',
      post: 'New post: HVAC SERVICES-Reasons to run your AC during winter - As a homeowner, you might want to run As a homeowner, you might want to run ',
    },
    {
      id: 4,
      image: '/assets/images/admin/uploadImg.png',
      post: 'New post: HVAC SERVICES-Reasons to run your AC during winter - As a homeowner, you might want to run As a homeowner, you might want to run ',
    },
  ];
};

export const reviewGMBServices = () => {
  return [
    {
      id: 1,
      customerProfile: '',
      customerPost: 'AC unit mid afternoon stopped working.',
      AdminProfile: '/assets/images/admin/small-logo.svg',
      adminPost: 'Glad we could get the air conditioner.',
    },
    {
      id: 2,
      customerProfile: '/assets/images/admin/uploadImg.png',
      customerPost: 'AC unit mid afternoon stopped working.',
      AdminProfile: '/assets/images/admin/small-logo.svg',
      adminPost: 'Glad we could get the air conditioner.',
    },
    {
      id: 3,
      customerProfile: '',
      customerPost: 'AC unit mid afternoon stopped working.',
      AdminProfile: '/assets/images/admin/small-logo.svg',
      adminPost: 'Glad we could get the air conditioner.',
    },
  ];
};
