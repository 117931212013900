let url = '/v1/admin/general-setting';
let urlNCA = '/v1/nca/general-setting';

export const COMPANY_SETTING_ENDPOINT = {
  getCompanySettingList: {
    url,
    method: 'GET',
  },
  getCompanyNCASettingList: {
    url: 'v1/nca/general-setting',
    method: 'GET',
  },
  editCompanySettingGeneral: {
    url: `${url}/general`,
    method: 'PUT',
  },
  editCompanySettingPayment: {
    url: `${url}/payment`,
    method: 'PUT',
  },
  editCompanySettingEmail: {
    url: `${url}/email`,
    method: 'PUT',
  },
  editCompanySettingAppearance: {
    url: `${url}/appearance`,
    method: 'PUT',
  },
  editCompanySettingSocialMedia: {
    url: `${url}/social-media`,
    method: 'PUT',
  },
  editCompanySettingAppSetting: {
    url: `${url}/app-setting`,
    method: 'PUT',
  },
  editCompanySettingInstallationSetting: {
    url: `${url}/installation-setting`,
    method: 'PUT',
  },
  editCompanyReportAnIssueSetting: {
    url: `${url}/report-issue`,
    method: 'PUT',
  },
  editCompanySettingEmailNCA: {
    url: `${urlNCA}/email`,
    method: 'PUT',
  },
  editCompanySettingAppearanceNCA: {
    url: `${urlNCA}/appearance`,
    method: 'PUT',
  },
  editCompanyReportAnIssueNCA: {
    url: `${urlNCA}/report-issue`,
    method: 'PUT',
  },
  editCompanyShoppingCart: {
    url: `${url}/shopping-cart`,
    method: 'PUT',
  },
  editCompanyVoiceAI: {
    url: `${url}/voice-ai`,
    method: 'PUT',
  },
};
