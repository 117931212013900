import * as yup from 'yup';
import i18next from 'i18next';
import { Regex } from '../../../../helpers/regex.helper';

export const initValues = () => {
  return {
    newPassword: '',
    confirmPassword: '',
  };
};

export const validation = () => {
  return yup.object().shape({
    newPassword: yup.string()
      .trim()
      .max(30, i18next.t('validation.common.maxNameValid'))
      .min(6, i18next.t('validation.common.minValidPassword'))
      .matches(Regex.password, i18next.t('validation.signup.strongPassword'))
      .nullable()
      .required(i18next.t('validation.common.newPasswordReq')),
    confirmPassword:  yup
      .string()
      .trim()
      .max(30, i18next.t('validation.common.maxNameValid'))
      .oneOf([yup.ref('newPassword'), null], i18next.t('validation.common.confirmPasswordSame'))
      .nullable()
      .required(i18next.t('validation.common.confirmPasswordReq')),
  });
};
