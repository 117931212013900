let url = '/v1/admin/equipment-location';


export const SUPPLY_HOUSE_ENDPOINT = {
  getSupplyHouseList: {
    url,
    method: 'GET',
  },
  deleteSupplyHouse: {
    url,
    method: 'DELETE',
  },
  statusUpdateSupplyHouse: {
    url: `${url}/change-status`,
    method: 'PUT',
  },
  addSupplyHouse: {
    url,
    method: 'POST',
  },
  editSupplyHouse: (id) => ({
    url: `${url}/${id}`,
    method: 'PUT',
  }),
  getSupplyHouseDetails: (id) => ({
    url: `${url}/${id}`,
    method: 'GET',
  }),
};
