import { APIRequest } from '../../helpers/index.helper';
import { DASHBOARD_ENDPOINT } from '../../apiEndpoints/Dashboard/index.endpoint';

import { logger } from '../../utils';

export const getUserCountListsService = async ({ queryParams }) => {
  try {
    const payload = {
      ...DASHBOARD_ENDPOINT.getUserCountListUrl,
      queryParams,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getProviderRatingListsService = async ({ queryParams }) => {
  try {
    const payload = {
      ...DASHBOARD_ENDPOINT.getProviderRatingListsUrl,
      queryParams,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getProviderCountListsService = async ({ queryParams }) => {
  try {
    const payload = {
      ...DASHBOARD_ENDPOINT.getProviderCountListUrl,
      queryParams,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getEmergencyResponseDetailsService = async ({ queryParams }) => {
  try {
    const payload = {
      ...DASHBOARD_ENDPOINT.getEmergencyServiceDetailsUrl,
      queryParams,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getRegisteredProviderListService = async ({ queryParams }) => {
  try {
    const payload = {
      ...DASHBOARD_ENDPOINT.getRegisteredProviderListUrl,
      queryParams,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getSalesDetailsService = async ({ queryParams }) => {
  try {
    const payload = {
      ...DASHBOARD_ENDPOINT.getSalesDetailsUrl,
      queryParams,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getServiceCategoryListsService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...DASHBOARD_ENDPOINT.getServiceCategoryListUrl,
      queryParams,
    };

    const res = await APIRequest(payload);
    if (res?.data && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getBookingEstimatesDetailsService = async ({ queryParams }) => {
  try {
    const payload = {
      ...DASHBOARD_ENDPOINT.getBookingEstimatesDetailsUrl,
      queryParams,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getAvailableProviderDetailsService = async ({ queryParams }) => {
  try {
    const payload = {
      ...DASHBOARD_ENDPOINT.getAvailableProviderDetailsUrl,
      queryParams,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getPromoCodeListService = async ({ queryParams }) => {
  try {
    const payload = {
      ...DASHBOARD_ENDPOINT.getPromoCodeListsUrl,
      queryParams,
    };

    const res = await APIRequest(payload);
    return res?.data?.rows;
  } catch (err) {
    logger(err);
  }
};

export const getProviderSalesDetailsService = async ({ queryParams }) => {
  const { isTable, ...rest } = queryParams;
  try {
    const payload = {
      ...DASHBOARD_ENDPOINT.getProviderSalesDetailsUrl,
      queryParams: rest,
    };

    const res = await APIRequest(payload);
    if (isTable) {
      return {
        data: {
          rows: res?.data?.providerBookings?.rows ?? [],
          count: res?.data?.providerBookings?.count ?? 0,
        },
        success: true,
      };
    } else {
      return res?.data;
    }
  } catch (err) {
    logger(err);
  }
};

export const getInvoiceTotalDetailsService = async ({ queryParams }) => {
  try {
    const payload = {
      ...DASHBOARD_ENDPOINT.getInvoiceTotalDetailsUrl,
      queryParams,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getSalesGraphDetailsService = async () => {
  try {
    const payload = {
      ...DASHBOARD_ENDPOINT.getSalesGraphDetailsUrl,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getHeatMapListService = async (queryParams) => {
  try {
    const payload = {
      ...DASHBOARD_ENDPOINT.getHeatMapListUrl,
      queryParams,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getServiceCategoryFilterListService = async ({
  queryParams,
  type,
}) => {
  try {
    const payload = {
      ...DASHBOARD_ENDPOINT.getServiceCategoryFilterListUrl,
      queryParams,
    };

    const res = await APIRequest(payload);
    if (res?.data && type === 'all') {
      return res?.data;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getBookingEstimatesListService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...DASHBOARD_ENDPOINT.getBookingEstimatesListUrl,
      queryParams,
    };

    const res = await APIRequest(payload);
    if (res?.data && type === 'all') {
      return res?.data;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};
export const onlineProviderListService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...DASHBOARD_ENDPOINT?.getOnlineProviderList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};
