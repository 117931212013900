export const ORDER_MANAGEMENT_ENDPOINT = {
  getOrderList: {
    url: 'v1/admin/order',
    method: 'GET',
  },
  statusOrderStatus: (id) => ({
    url: `v1/admin/order/${id}`,
    method: 'PUT',
  }),
  getOrderInvoice: (id) => ({
    url: `/v1/admin/order/${id}`,
    method: 'GET',
  }),
};
