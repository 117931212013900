import { UPLOAD_BULK_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';

export const downloadDemoFileService = async () => {
  try {
    const payload = {
      ...UPLOAD_BULK_ENDPOINT?.downloadSampleFile,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const uploadBulkFileService = async ({ bodyData }) => {
  try {
    const payload = {
      ...UPLOAD_BULK_ENDPOINT?.uploadBulkFile,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const uploadBulkImageService = async ({ bodyData }) => {
  try {
    const payload = {
      ...UPLOAD_BULK_ENDPOINT?.uploadBulkImages,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
