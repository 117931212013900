import React, { useState, createRef } from 'react';
import { Spin } from 'antd';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import '../../../../node_modules/slick-carousel/slick/slick.css';
import '../../../../node_modules/slick-carousel/slick/slick-theme.css';
import { ImageElement, NoDataFound } from '../../../components';
import { accessRoute } from '../../../routes/CustomerApp/scheduleServiceProvider';
import { firstLetterCaps } from '../../../utils/text.util';
import { useTranslation } from 'react-i18next';
import Favorite from '../../../components/Favorite';
import { generateStarRating } from '../../../utils';
import ProviderLocation from '../../../components/Map/providerLocation.map';
import { GOOGLE_MAPS_API_KEY, GOOGLE_MAP_URL } from '../../../config';
import { useSelector } from 'react-redux';

const ServiceProviderMap = ({ providersList, isLoading, state }) => {
  const { t } = useTranslation();
  const [providerId, setProviderId] = useState('');
  const googleMapApiKey = useSelector((state) => state?.googleMap?.apiKey);

  const refs = providersList?.reduce?.((acc, value) => {
    acc[value.id] = createRef();
    return acc;
  }, {});

  const listLength =
    providersList && providersList?.length && providersList?.length;
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: listLength > 3 ? 4 : listLength,
    slidesToScroll: 1,
    navs: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: listLength > 2 ? 3 : listLength,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: listLength > 1 ? 2 : listLength,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleSelectProvider = (id) => {
    setProviderId(id);
    refs?.[id]?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <>
      {isLoading ? (
        <div className="text-center mt-5">
          <Spin />
        </div>
      ) : providersList && providersList?.length ? (
        <div className="serviceAddress_map">
          <>
            <div className="serviceAddress_map_img">
              <ProviderLocation
                googleMapURL={GOOGLE_MAP_URL(
                  googleMapApiKey || GOOGLE_MAPS_API_KEY,
                  ['drawing']
                )}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `800px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                providersList={providersList}
                handleProviderId={(id) => handleSelectProvider(id)}
              />
            </div>
            <div className="serviceAddress_map_slide">
              <Slider {...settings}>
                {providersList?.map((provider, idx) => (
                  <div
                    ref={refs?.[provider?.id]}
                    key={idx}
                    className={`appointmentList position-relative bg-white ${
                      providerId === provider?.id ? 'active' : ''
                    }`}
                  >
                    <div className="appointmentList_like">
                      <Favorite
                        userId={provider?.Provider?.UserId}
                        isFavorite={provider?.isFavorite === 1 ? true : false}
                      />
                    </div>
                    <div className="appointmentList_top d-flex align-items-start">
                      <div className="appointmentList_top_images">
                        <ImageElement
                          previewSource={provider?.profilePictureThumbUrl}
                          source="img-1.png"
                        />
                      </div>
                      <div className="appointmentList_top_content">
                        <h2 className="font-md">
                          {firstLetterCaps(provider?.firstName ?? '')}&nbsp;
                          {firstLetterCaps(provider?.lastName ?? '')}
                        </h2>
                        <p className="">{provider?.companyName}</p>
                        <ul className="list-inline mb-0">
                          <li className="list-inline-item">
                            <div className="info">
                              <ul className="list-unstyled d-flex starRating mb-0">
                                {generateStarRating(provider?.avgRating ?? 0)}
                              </ul>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="appointmentList_bottom">
                      <Link
                        to={accessRoute.SERVICE_PROVIDER_DETAILS_MILES.path}
                        state={{ ...state, providerDetails: provider }}
                        className="btn btn-light w-100"
                      >
                        {t('text.scheduleServiceProvider.moreInfo')}
                      </Link>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </>
        </div>
      ) : (
        <NoDataFound
          source="/no-provider.svg"
          text={t('text.common.noProviderFound')}
          extraClass="noDataFoundWrapper w-100"
          isCustomer={'/assets/images/admin/no-provider.svg'}
        />
      )}
    </>
  );
};

export default ServiceProviderMap;
