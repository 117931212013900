import { Button, Space } from 'antd';
import Input from 'antd/lib/input/Input';
import React, { useEffect, useRef, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { FilterFilled } from '@ant-design/icons';
import { t } from 'i18next';

export default function InputFilterComponent({
  setSelectedKeys,
  selectedKeys,
  onFilterChange,
  confirm,
  clearFilters,
  dataIndex,
  filter,
  placeholder = false,
  defaultValue,
}) {
  // const { t } = useTranslation();
  const searchInput = useRef(null);
  const [state, setState] = useState(filter[dataIndex]);

  const handleSearch = async (_, confirm, dataIndex) => {
    try {
      await onFilterChange({ [dataIndex]: state });
      setSelectedKeys(state);
      confirm();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (
      Object.keys(filter).length === 0 &&
      clearFilters &&
      confirm &&
      (Array.isArray(selectedKeys) ? selectedKeys.length > 0 : !!selectedKeys)
    ) {
      setSelectedKeys(undefined);
      clearFilters();
      confirm();
      setState(null);
    }
  }, [filter]);

  const handleReset = async (clearFilters, confirm) => {
    try {
      await onFilterChange({ [dataIndex]: undefined });
      setSelectedKeys(undefined);
      setState('');
      clearFilters();
      confirm();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      style={{
        padding: 8,
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Input
        ref={searchInput}
        placeholder={`Search ${placeholder || dataIndex}`}
        className="ant-input form-control"
        value={state || defaultValue}
        onChange={(e) => setState(e?.target?.value)}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{
          marginBottom: 8,
          display: 'block',
        }}
      />
      <Space>
        <Button
          type="primary"
          className="btn btn-primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            width: 90,
          }}
        >
          {t('text.common.search').toUpperCase()}
        </Button>
        <Button
          onClick={() => clearFilters && handleReset(clearFilters, confirm)}
          className="btn btn-info"
          style={{
            width: 90,
          }}
        >
          {t('text.common.reset').toUpperCase()}
        </Button>
      </Space>
    </div>
  );
}

export function getInputSearchFilter({
  dataIndex,
  onFilterChange,
  filter,
  placeholder,
  defaultValue,
}) {
  return {
    filterDropdown: (props) => (
      <InputFilterComponent
        dataIndex={dataIndex}
        onFilterChange={onFilterChange}
        placeholder={placeholder}
        filter={filter || {}}
        defaultValue={defaultValue}
        {...props}
      />
    ),
    filterIcon: (filtered) =>
      filter && filter[dataIndex] ? (
        <span className="reset-filter">
          <FilterFilled
            type="search"
            style={{ color: filtered ? '#F47721' : undefined }}
          />
        </span>
      ) : (
        <FilterFilled
          type="search"
          style={{ color: filtered ? '#F47721' : undefined }}
        />
      ),
    onFilterDropdownVisibleChange: () => {},
  };
}
