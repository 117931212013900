import { AMOUNT_CALCULATION_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';

export const getAmountCalculationService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...AMOUNT_CALCULATION_ENDPOINT?.getAmountCalculationList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const deleteAmountCalculationService = async ({ bodyData }) => {
  try {
    const payload = {
      ...AMOUNT_CALCULATION_ENDPOINT.deleteAmountCalculation,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const updateAmountCalculationStatus = async ({ bodyData }) => {
  try {
    const payload = {
      ...AMOUNT_CALCULATION_ENDPOINT.statusUpdateAmountCalculation,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const addAmountCalculationService = async ({body}) => {
  try {
    const payload = {
      ...AMOUNT_CALCULATION_ENDPOINT.addAmountCalculation,
      bodyData:body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const editAmountCalculationService = async ({ body, id }) => {
  try {
    const payload = {
      ...AMOUNT_CALCULATION_ENDPOINT.editAmountCalculation(id),
      bodyData:body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getAmountCalculationDetailsService = async ({ id }) => {
  try {
    const payload = {
      ...AMOUNT_CALCULATION_ENDPOINT.getAmountCalculationDetails(id),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
