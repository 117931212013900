export const text = {
  home: 'Home',
  title: 'Payment',
  cardHolderName: 'Card Holder Name',
  cardNumber: 'Card Number',
  expDate: 'Exp. Date',
  expYear: 'Exp. Year',
  expMonth: 'Exp. Month',
  cvv: 'CVV',
  payNow: 'Pay Now',
  serviceCompleted: 'Service Completed',
  currentBookingSuccessfully: 'Current Booking is Successfully ',
  completed: 'Completed',
  LeaveReview: 'Leave a review on ',
  google: 'Google',
  facebook: 'Facebook',
  bookingDate: 'Booking Date :',
  discountApplied: 'Discount Applied :',
  percentOff: '15% Off',
  getReceipt: 'Get Receipt',
  paymentType: 'Payment Type :',
  request: 'Request',
  customerImage: 'Customer Image',
  lineItems: 'Line Items',
  waterHeaters: 'Water heaters',
  promoCodeDiscount: 'Promo Code Discount',
  subtotal: 'Subtotal',
  total: 'Total',
  invoiceMessage: 'Invoice Message',
  howWasYourService: 'How Was Your Service',
  rate: 'Rate',
  meetGGSMobileApp: 'Meet the new Good Guys Service Mobile app',
  paynow: 'Paynow',
  savedCard: 'Saved Card',
  selectYourCard: 'Select your card',
  paymentAmount: 'Payment amount',
  howYouLikeToPay: 'How would you like to pay ?',
  creditCard: 'Credit Card',
  cashPayment: 'Cash Payment',
  cheque: 'Cheque',
  financing: 'Financing',
  rating: 'Rating',
};
