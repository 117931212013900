import React, { useMemo } from 'react';
import { TablePlus, PageContainerWrapper } from '../../../../../components';
import { useNavigate, useParams } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import {
  viewReportDetailDate,
  viewReportBreadcrumb,
  viewReportColumns,
} from '../../../../../services/Columns/marketing.service';
import { accessRoute } from '../../../../../routes/Marketing';
import { getMessageReportListService } from '../../../../../services/Marketing/marketingTemplate.service';
import { useTable } from '../../../../../hooks/index.hook';
import { t } from 'i18next';

function ReviewTemplateReport(props) {
  const breadcrumb = viewReportBreadcrumb();
  const navigate = useNavigate();
  const backPage = () => {
    navigate(accessRoute.MARKETING_REVIEW_TEMPLATE.path);
  };

  const { id } = useParams();

  let extraQuery = {
    id,
  };
  const [tableHandler, tableProps] = useTable(
    getMessageReportListService,
    extraQuery
  );
  const {  data,
    isLoading,
    count,
    page,
    sizePerPage,
    filter,
    sortBy,
    sortType, } = tableProps;
  const { onFilterChange , onTableSort,onFilterResetChange} = tableHandler;

  const columns = viewReportColumns({ 
    data,
    page,
    sizePerPage,
    filter, 
    onFilterChange ,
    onSort:onTableSort});
  const dataReport = viewReportDetailDate({ data });

  const noOfPages = useMemo(() => {
    const pages = data?.MarketingRecipientList?.count
      ? Math.ceil(data?.MarketingRecipientList?.count / sizePerPage)
      : 0;
    return pages;
  }, [data]);

  return (
    <>
      <PageContainerWrapper
        heading={t('text.marketing.messageReport')}
        breadcrumb={breadcrumb}
        btnArray={['extraButton']}
        extraBtnText={t('text.common.back')}
        extraBtnClass="btn btn-outline-light bg-white"
        onExtraButtonHandleShow={backPage}
      >
        <div className="nk-block">
          <div className="card shadow-none">
            <div className="card-inner">
              <div className="table-responsive">
                <Table bordered size="md">
                  <tbody>
                    {dataReport?.map((item) => {
                      return (
                        <>
                          <tr>
                            <td>{item?.message}</td>
                            <td>{item?.date}</td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
        <TablePlus
          hasLimit
          noOfPage={noOfPages}
          sizePerPage={sizePerPage}
          page={page}
          count={data?.MarketingRecipientList?.count}
          tableData={data?.MarketingRecipientList?.rows}
          tableColumns={columns}
          tableLoader={isLoading}
          onFilterResetChange={onFilterResetChange}
          resetAll={false}
          setSizePerPage=""
          selectRow={false}
          isSearch={false}
          showAction={false}
          statusAction={false}
          isResetBtn={false}
          filter={filter}
          sortBy={sortBy}
          sortType={sortType}
        />
      </PageContainerWrapper>
    </>
  );
}

export default ReviewTemplateReport;
