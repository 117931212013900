import loadable from '@loadable/component';
import FullPageLoader from '../../components/UIElements/Loader/fullPageLoader';
export const ListNewEquipment = loadable(() => import('./List'), {
  fallback: <FullPageLoader />,
});
export * from './List';
export const BookingViewNewEquipment = loadable(() => import('./BookingView'), {
  fallback: <FullPageLoader />,
});
export const NewEquipmentViewQuote = loadable(
  () => import('./ViewQuoteDetails'),
  {
    fallback: <FullPageLoader />,
  }
);
