let url = '/v1/admin/rebate';

export const REBATE_ENDPOINT = {
  getRebateList: {
    url,
    method: 'GET',
  },
  deleteRebate: {
    url,
    method: 'DELETE',
  },
  statusUpdateRebate: {
    url: `${url}/change-status`,
    method: 'PUT',
  },
  addRebate: {
    url,
    method: 'POST',
  },
  editRebate: (id) => ({
    url: `${url}/${id}`,
    method: 'PUT',
  }),
  getRebateDetails: (id) => ({
    url: `${url}/${id}`,
    method: 'GET',
  }),
};
