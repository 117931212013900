import loadable from '@loadable/component';
import FullPageLoader from '../components/UIElements/Loader/fullPageLoader';

export const SidebarFeature = loadable(
  () => import('./sidebar/sidebar.feature'),
  {
    fallback: <FullPageLoader />,
  }
);
export const AdminHeader = loadable(() => import('./header/header.feature'), {
  fallback: <FullPageLoader />,
});
export const Notification = loadable(
  () => import('./notification/index.feature'),
  {
    fallback: <FullPageLoader />,
  }
);
export const HeaderProfileMenu = loadable(() => import('./headerProfileMenu'), {
  fallback: <FullPageLoader />,
});
export const ModuleSelectToggler = loadable(
  () => import('./customerLoginHeader/header.feature'),
  {
    fallback: <FullPageLoader />,
  }
);
export const DocumentUpdate = loadable(
  () => import('./documentUpdate/documentUpdate.feature'),
  {
    fallback: <FullPageLoader />,
  }
);
export const Invoice = loadable(() => import('./Invoice/index.feature'), {
  fallback: <FullPageLoader />,
});
export const BookingLogView = loadable(
  () => import('./BookingLog/index.feature'),
  {
    fallback: <FullPageLoader />,
  }
);
export const DocumentView = loadable(
  () => import('./DocumentView/documentView.feature'),
  {
    fallback: <FullPageLoader />,
  }
);
export const StoreLoginHeader = loadable(
  () => import('./storeLoginHeader/storeLoginHeader'),
  {
    fallback: <FullPageLoader />,
  }
);
export const CommonInvoicePage = loadable(() => import('./InvoicePage/index'), {
  fallback: <FullPageLoader />,
});

export const MemberShipModalInfo = loadable(
  () => import('./modalComponent/memberShipInfo/index'),
  { fallback: <FullPageLoader /> }
);
export const CommonOrderInvoice = loadable(
  () => import('./OrderInvoice/index.feature'),
  { fallback: <FullPageLoader /> }
);
export const CoachMarkFeature = loadable(
  () => import('./coachMark/coachMark.feature'),
  { fallback: <FullPageLoader /> }
);
