export const text = {
  title: 'Standard Operating Procedures',
  lessonTitle: 'Lesson Title',
  typeOfSop: 'Type Of SOP',
  manageService: 'Manage Services',
  adminModule: 'Admin Modules',
  numberOfQue: 'No. of Questions',
  required: 'Required',
  addLesson: 'Add Lesson',
  editLesson: 'Edit Lesson',
  areYouSureDeleteLesson: 'Are you sure you want to delete?',
  typeOfOperatingProcedure: 'Type of Operating Procedure',
  description: 'Lesson Description',
  requiredPassingPercentage: 'Required passing score %  ',
  addQuestion: 'Add Question',
  sendNotification: 'Send Notification',
  editQuestion: 'Edit Question',
  areYouSureNotification:
    'To send notification after add or update the questions ?',
  areYouSureDeleteQuestion: 'you want to delete this Question',
  questionType: 'Question Type',
  option: 'Option',
  questionTitle: 'Question Title',
  areYouSureNot: 'Are you sure',
  checkText:
    'Check this checkbox to send notification to related SP or Sub Admin after Update.',
  pleaseSendNotification: '(After making any update please send notification)',
  cautionMessage: 'Caution: Once a record is deleted it cannot be recovered',
  checkAnswer: 'Check Answer',
  tryAgainText: 'Please review the information and try again',
  testCompleted: 'Congratulation you have completed the test',
  markCompleted: 'Mark as Completed',
  tryAgain: 'Try Again',
  lessonList: 'Lesson list',
  reference: 'Reference',
  confirmUnderstanding: 'Confirm Understanding',
  serviceProvider: 'Service Provider',
  singleAnswer: 'Single Answer',
  multipleAnswer: 'Multiple Answer',
  statusChangeToolTip: 'Please activate lesson',
};
