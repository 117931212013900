import { Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { CommonButton } from '../../components';
import { postBookingEmergencyFree } from '../../services/CustomerApp/index.service';
import { providerSortOptions } from '../../services/index.service';
import { modalNotification } from '../../utils';

const LOADING_TYPE = {
  REQUEST_TO_ALL: 'REQUEST_TO_ALL',
};

function ServiceAddress({
  providerLIst = false,
  addressList,
  selectedAddress,
  emergencyButton = true,
  scheduleProviderLIst = false,
  handleChangeSortBy,
  sortBy,
  setModal,
  stateModal,
  providers,
}) {
  const { t } = useTranslation();
  const location = useLocation();
  const { state: locationState } = location;

  const handleRequestAll = async () => {
    let stateObj = {
      isLoading: false,
    };
    try {
      if (setModal) {
        setModal({ ...stateModal, isLoading: LOADING_TYPE.REQUEST_TO_ALL });
      }
      const bodyData = {
        bookingType: 'all',
        customerLocationId: locationState?.locationId,
        serviceCategoryId: locationState?.id,
        quantity: locationState?.quantity ? locationState?.quantity : 1,
        serviceTypeId: locationState?.serviceTypeDetails?.id,
        ...(sortBy === 'favorite' && {
          sortBy,
        }),
      };
      const res = await postBookingEmergencyFree(bodyData);
      if (res?.success) {
        modalNotification({
          type: 'success',
          message: res?.message,
        });
        stateObj = {
          isLoading: false,
          thankyouLoader: true,
          bookingId: res?.data?.id,
        };
      }
    } catch (error) {
      console.log(error);
    }
    if (setModal) {
      setModal({
        ...stateModal,
        ...stateObj,
      });
    }
  };

  return (
    <div
      className={`serviceAddress ${!scheduleProviderLIst && `bg-white`} ${
        providerLIst && !scheduleProviderLIst && 'p-30 br-10'
      } ${scheduleProviderLIst && `bg-BookAppointmentPage`}`}
    >
      <div className="d-flex align-items-lg-center align-items-start flex-lg-row flex-column">
        <div className="serviceAddress_left">
          <h2 className={`font-sm ${!providerLIst && 'h-20 mb-mb-2 mb-1'}`}>
            Service Address
          </h2>
          <p className="mb-0 font-md">
            {selectedAddress && addressList && addressList?.length
              ? addressList?.find(
                  (address) => address?.id === Number(selectedAddress)
                )?.addressLine1
              : null}
          </p>
        </div>
        {providerLIst && (
          <div className="serviceAddress_right ms-lg-auto">
            <div className="d-flex align-items-center">
              <div className="form-group d-flex flex-wrap align-items-sm-center mb-0">
                <div className="mb-0 font-md">
                  <div className="ant-row ant-form-item-row">
                    <div className="ant-col ant-form-item-label">
                      <label className="" title="Sort By">
                        Sort By
                      </label>
                    </div>
                    <Select
                      defaultValue="all"
                      value={sortBy}
                      onChange={handleChangeSortBy}
                      className="w-100 ant-select-lg"
                      options={providerSortOptions()}
                    />
                  </div>
                </div>
                {emergencyButton && (
                  <fieldset
                    disabled={providers && providers > 0 ? false : true}
                  >
                    <CommonButton
                      variant="primary"
                      className="font-md ms-sm-2"
                      onClick={handleRequestAll}
                      loading={
                        stateModal?.isLoading === LOADING_TYPE.REQUEST_TO_ALL
                      }
                    >
                      {t('text.common.emergencyRequestAll')}
                    </CommonButton>
                  </fieldset>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ServiceAddress;
