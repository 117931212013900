import React, { useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Columns,
  getActions,
  orderListService,
  updateOrderStatusService,
} from '../../../services/index.service';
import {
  ActionWrapper,
  CommonButton,
  PageContainerWrapper,
  TablePlus,
  Toggle,
} from '../../../components';
import useTable from '../../../hooks/useTable.hook';
import { accessRoute } from '../../../routes/ShoppingCart';

export default function OrderManagement() {
  const breadcrumb = Columns.orderManagementBreadcrumb();
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  function onAction(e, handler, item) {
    e.preventDefault();
    handler(item);
  }
  const navigate = useNavigate();

  const [tableHandler, tableProps] = useTable(orderListService);
  const {
    data,
    isLoading,
    count,
    page,
    noOfPages,
    sizePerPage,
    filter,
    sortBy,
    sortType,
  } = tableProps;
  const {
    onRefresh,
    onFilterChange,
    onFilterResetChange,
    onTableSort,
    onToggleChange,
    onConfirmDeleteAlert,
  } = tableHandler;

  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options?.map((item, key) => {
          return (
            <li
              action={item?.action}
              onClick={(e) => onAction(e, item?.handler, item)}
              key={key}
              className="action_list"
            >
              <CommonButton>
                <em className={item?.icon} />
                {item?.name}
              </CommonButton>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };

  const columns = Columns.orderManagementTableColumn({
    onSort: onTableSort,
    onFilterChange: onFilterChange,
    filter,
    page,
    sizePerPage,
    Action: (
      props
      // row
    ) =>
      actionFormatter([
        {
          name: t('text.common.views'),
          icon: 'icon ni ni-eye',
          id: props?.row?.id,
          action: getActions('VIEW').value,
          // handler: () => {
          //   navigate(accessRoute?.ORDER_MANAGEMENT_VIEW?.path);
          // },
          handler: () => {
            const url = `${generatePath(
              accessRoute?.ORDER_MANAGEMENT_VIEW?.path,
              {
                id: props?.row?.id,
              }
            )}`;
            navigate(url);
          },
        },
      ]),
    Toggle: (props) => (
      <Toggle
        isProductDropdown={true}
        isOrder={true}
        loading={loader === 'status-update'}
        defaultChecked={props.row.status}
        onChange={(e) =>
          onToggleChange(
            e,
            props?.row,
            '',
            updateOrderStatusService,
            'orderId',
            true
          )
        }
        onRefresh={onRefresh}
      />
    ),
  });

  return (
    <>
      <PageContainerWrapper
        heading={t('text.common.orderManagement')}
        breadcrumb={breadcrumb}
        extraClass="btn btn-primary d-block d-xl-none"
        extraMobileClass="p-xl-0"
      >
        <TablePlus
          hasLimit
          noOfPage={noOfPages}
          sizePerPage={sizePerPage}
          page={page}
          count={count}
          tableData={data}
          tableColumns={columns}
          selectRow
          showAction={false}
          statusAction={false}
          // isSearch={true}
          onFilterResetChange={onFilterResetChange}
          filter={filter}
          sortBy={sortBy}
          sortType={sortType}
          tableLoader={isLoading}
          setSizePerPage=""
          // searchPlaceholder="Order Id"
        />
      </PageContainerWrapper>
    </>
  );
}
