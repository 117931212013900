import { getActions } from '../../services/index.service';
import NcaProfile from '../../views/NcaProfile';

export default function route() {
  return [
    {
      path: accessRoute.NCA_PROFILE.path,
      private: true,
      name: 'Profile',
      action: accessRoute.NCA_PROFILE.action,
      key: accessRoute.NCA_PROFILE.key,
      belongsToSidebar: true,
      submenu: false,
      icon: accessRoute.NCA_PROFILE.icon,
      element: <NcaProfile />,
      ...accessRoute.NCA_PROFILE,
    },
    // {
    //   path: accessRoute.CANCEL_NCA.path,
    //   private: true,
    //   name: 'Cancel Nca',
    //   action: accessRoute.CANCEL_NCA.action,
    //   key: accessRoute.CANCEL_NCA.key,
    //   belongsToSidebar: false,
    //   submenu: false,
    //   element: <CancelNcaService />,
    //   ...accessRoute.CANCEL_NCA,
    // },
  ];
}

export const accessRoute = {
  NCA_PROFILE: {
    path: '/admin/nca-profile',
    action: getActions('LIST').value,
    moduleKey: 'nca',
    for: ['nca'],
    ignorePermission: true,
    shouldBePartOf: ['nca'],
    belongsToSidebar: true,
    key: 'nca',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-user-fill" />
      </span>
    ),
  },
  // CANCEL_NCA: {
  //   path: '/admin/cancel-plan-web-page',
  //   action: getActions('LIST').value,
  //   moduleKey: 'nca',
  //   key: 'nca',
  // },
};
export const getChildrenRoutes = () => {
  return route();
};
