import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TablePlus,
  ModalWrapper,
  PageContainerWrapper,
  TruckOrderForm,
  CommonButton,
  FromDateToDateFilter,
} from '../../../components';
import { modalNotification, FireAlert, logger } from '../../../utils';
import { getApiData, useTable } from '../../../hooks/index.hook';
import {
  truckListBreadcrumb,
  truckTableColumn,
} from '../../../services/Columns';
import {
  addTruckOrderService,
  getTruckOrderAmountService,
  getTruckOrderService,
  orderTruckService,
  removeTruckOrderService,
} from '../../../services/TruckOrder/truckOrder.service';
import { Table } from 'react-bootstrap';
import { checkValidPrice } from '../../../components/UIElements/Formatter';
import { Link } from 'react-router-dom';
import { getActions } from '../../../services/index.service';
import { PermissionContext } from '../../../context/permission.context';
import { useSelector } from 'react-redux';

function ListTruckOrdering() {
  const { t } = useTranslation();
  const [amount, setAmount] = useState([]);
  const [adminIdList, setAdminIdList] = useState([]);
  const [removeIdList, setRemoveIdList] = useState([]);

  // Permission
  const action = useContext(PermissionContext);
  const userType = useSelector((state) => state?.auth?.userData?.Role?.type);
  const isUserCanEdit =
    (action && action?.arr?.includes(getActions('CREATE').value)) ||
    userType === 'admin';

  // For loader, modal, selected row, and form text
  const [state, setState] = useState({
    loader: null,
    modal: null,
    submitText: 'add',
    partsAmount: false,
  });
  const { loader, modal, submitText } = state;

  // For get tags list
  const [tableHandler, tableProps] = useTable(getTruckOrderService);
  const {
    data,
    isLoading,
    count,
    page,
    noOfPages,
    sizePerPage,
    filter,
    sortBy,
    sortType,
  } = tableProps;
  const { onRefresh, onFilterChange, onFilterResetChange, getListParams } =
    tableHandler;
  const [globalFilter, setGlobalFilter] = useState(false);
  const [filterValue, setFilter] = useState({
    fromDate: '',
    toDate: '',
    dateType: undefined,
  });
  // For breadcrumb
  const breadcrumb = truckListBreadcrumb();

  let adminId = adminIdList;
  const onOrderCheck = ({ target }, id) => {
    let { checked } = target;
    if (checked) {
      adminId.push(id);
    } else {
      let index = adminId.indexOf(id);
      adminId.splice(index, 1);
    }
    setAdminIdList([...adminId]);
  };

  // For Order Parts Alert Section Start
  const onConfirmOrderAlert = async () => {
    FireAlert({
      title: t('text.common.youSure'),
      text: t('text.common.orderSelectedPart'),
      icon: t('text.common.warning'),
      confirmButtonText: t('text.common.yes'),
      cancelButtonText: t('text.common.no'),
      preConfirm: () => orderParts(),
    });
    return true;
  };

  // For Order Parts
  async function orderParts() {
    try {
      const { success, message } = await orderTruckService({
        bodyData: { truckStockOrderIds: adminIdList },
      });
      if (success && message) {
        modalNotification({
          type: 'success',
          message,
        });
        onRefresh();
        setAdminIdList([]);
        setRemoveIdList([]);
        getAmount(false);
      }
      return success ? true : false;
    } catch (err) {
      console.log(err);
    }
  }

  // For remove parts
  let removeId = removeIdList;
  const onRemoveCheck = async ({ target }, id) => {
    let { checked } = target;
    if (checked) {
      removeId.push(id);
    } else {
      let index = removeId.indexOf(id);
      removeId.splice(index, 1);
    }
    setRemoveIdList([...removeId]);
  };

  // For Remove Parts Alert Section Start
  const onConfirmRemoveAlert = async () => {
    FireAlert({
      title: t('text.common.youSure'),
      text: t('text.common.removeSelectedPart'),
      icon: t('text.common.warning'),
      confirmButtonText: t('text.common.yes'),
      cancelButtonText: t('text.common.no'),
      preConfirm: () => removeOrderParts(),
    });
    return true;
  };

  // For Remove Order Parts
  async function removeOrderParts() {
    try {
      const { success, message } = await removeTruckOrderService({
        bodyData: { truckStockOrderIds: removeIdList },
      });
      if (success && message) {
        modalNotification({
          type: 'success',
          message,
        });
        onRefresh();
        setRemoveIdList([]);
        setAdminIdList([]);
        getAmount(false);
      }
      return success ? true : false;
    } catch (err) {
      console.log(err);
    }
  }

  const columns = truckTableColumn({
    onFilterChange: onFilterChange,
    onOrderCheck: onOrderCheck,
    onRemoveCheck: onRemoveCheck,
    filter: filter,
    page,
    sizePerPage,
    isUserCanEdit,
  });

  function onCancel() {
    setState({ ...state, modal: null, modalId: null });
  }

  // For handle submit
  async function onSubmit(payload) {
    setState({ ...state, loader: 'add' });
    try {
      let response = await addTruckOrderService({
        bodyData: payload,
      });
      const { success, message } = response;
      if (success) {
        setState({ ...state, modal: null, loader: null, modalId: null });
        modalNotification({
          type: 'success',
          message,
        });
        onRefresh();
      } else {
        setState({ ...state, loader: null });
      }
    } catch (error) {
      console.log(error);
      setState({ ...state, loader: null });
    }
  }

  const formProps = {
    onCancel: onCancel,
    onSubmit: onSubmit,
  };

  const modalForm = {
    add: (
      <TruckOrderForm
        onCancel={onCancel}
        loading={loader === 'add'}
        submitText={submitText}
        {...formProps}
      />
    ),
  };
  const OpenBtnFilter = () => {
    setGlobalFilter(true);
  };

  // For header Button
  const headerBtn = {
    add: {
      name: (
        <>
          <em className="icon ni ni-filter-fill me-1" />{' '}
          {t('text.common.filter')}
        </>
      ),
      handler: () => {
        OpenBtnFilter();
      },
    },
    multipleButton: [
      {
        title: t('text.truckOrdering.addPart'),
        handler: () => {
          setState({
            ...state,
            submitText: t('text.common.add'),
            modal: 'add',
          });
        },
        // disabled: adminIdList && adminIdList.length ? false : true,
      },
      {
        title: t('text.common.orderParts'),
        handler: () => {
          onConfirmOrderAlert();
        },
        disabled: adminIdList && adminIdList.length ? false : true,
      },
      {
        title: t('text.common.removeParts'),
        handler: () => {
          onConfirmRemoveAlert();
        },
        disabled: removeIdList && removeIdList.length ? false : true,
      },
    ],
  };

  const getAmount = (status, filterData) => {
    let params = getListParams();
    setState({ ...state, partsAmount: status });
    const queryParams = {
      ...params,
      startDate: params?.fromDate || filterData?.fromDate || '',
      endDate: params?.toDate || filterData?.toDate || '',
    };
    delete queryParams.fromDate;
    delete queryParams.toDate;
    getApiData({
      service: getTruckOrderAmountService,
      setData: setAmount,
      queryParams,
    });
  };

  const BackBtnFilter = () => {
    setGlobalFilter(false);
  };

  function onReset() {
    const resetData = {
      fromDate: '',
      toDate: '',
      dateType: undefined,
    };

    setFilter({
      ...resetData,
    });
    setRemoveIdList([]);
    setAdminIdList([]);
    onFilterChange(resetData);
    setGlobalFilter(false);
  }
  const onSubmitFilter = (values) => {
    try {
      let val = {
        ...values,
      };
      setFilter((prevState) => ({
        ...prevState,
        ...val,
      }));
      getAmount(true, values);
      onFilterChange(values);
      setGlobalFilter(false);
    } catch (error) {
      logger(error);
    }
  };

  const handleResetFilter = () => {
    try {
      const resetData = {
        fromDate: '',
        toDate: '',
        dateType: undefined,
      };
      setFilter({
        ...resetData,
      });
      setRemoveIdList([]);
      setAdminIdList([]);
      onFilterChange(resetData);
      onFilterResetChange();
    } catch (error) {
      logger(error);
    }
  };

  return (
    <>
      <PageContainerWrapper
        heading={t('text.truckOrdering.title')}
        breadcrumb={breadcrumb}
        btn={headerBtn}
        multipleButtonClass={false}
        extraClass="btn btn-primary d-block d-xl-none"
      >
        <ModalWrapper
          heading={t('text.truckOrdering.addPart')}
          onCancel={onCancel}
          content={modalForm['add']}
          show={['add'].includes(modal)}
        />
        <div className="">
          <div className="globalFilters">
            <div className="card ">
              <div className="card-inner">
                <div
                  className={`globalFilters_inner ${
                    globalFilter ? 'filterToggle' : ''
                  }`}
                >
                  <div className="mb-3 d-block d-xl-none">
                    <Link to="#" onClick={() => BackBtnFilter()}>
                      <em className="icon ni ni-arrow-long-left me-1"></em>
                      {t('text.common.back')}
                    </Link>
                  </div>

                  <FromDateToDateFilter
                    onSubmit={onSubmitFilter}
                    onReset={onReset}
                    loading={isLoading}
                    filterValue={filterValue}
                    setFilter={setFilter}
                    isEnabledFields={['dateType']}
                  />
                </div>
                <div
                  className={` ${globalFilter ? 'toggle-overlay' : ''} `}
                  onClick={() => BackBtnFilter()}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <TablePlus
          hasLimit
          noOfPage={noOfPages}
          sizePerPage={sizePerPage}
          page={page}
          count={count}
          tableData={data}
          tableColumns={columns}
          selectRow
          tableLoader={isLoading}
          showAction={false}
          onFilterResetChange={handleResetFilter}
          filter={filter}
          sortBy={sortBy}
          sortType={sortType}
        />
        <div className="text-end my-4">
          <CommonButton
            className="btn btn-primary"
            onClick={() => getAmount(true)}
          >
            {t('text.common.totalPartsAmount')}
          </CommonButton>
        </div>
        {state?.partsAmount && (
          <div className="nk-block">
            <div className="card">
              <div className="card-inner">
                <h5 className="title mb-4">
                  {t('text.common.totalPartsAmount')}
                </h5>
                <div className="table-responsive">
                  <Table bordered size="md">
                    <thead>
                      <tr>
                        <th> {t('text.truckOrdering.TotalPartAmountYes')}</th>
                        <td>{checkValidPrice(amount?.sum?.totalOrdered)}</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th> {t('text.truckOrdering.TotalPartAmountNo')}</th>
                        <td> {checkValidPrice(amount?.sum?.totalUnOrdered)}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        )}
      </PageContainerWrapper>
    </>
  );
}

export default ListTruckOrdering;
