export const MANUAL_BOOKING_ENDPOINT = {
  getServiceTypeLists: {
    url: '/v1/admin/manual-booking/service-subcategory',
    method: 'GET',
  },
  getSlot: {
    url: '/v1/slot',
    method: 'GET',
  },
  postCreateManualBooking: {
    url: '/v1/admin/customer/manual-booking',
    method: 'POST',
  },
  getServiceProvidersList: {
    url: `/v1/customer/booking/service-providers`,
    method: 'GET',
  },
  getAddressListByMobileNumber: (mobileNumber) => ({
    url: `/v1/admin/address/${mobileNumber}`,
    method: 'GET',
  }),
  getProviderAvailabilityList: {
    url: `/v1/admin/booking/service-providers`,
    method: 'GET',
  },
};
