export const text = {
  home: 'Home',
  faq: "FAQ's",
  ModernPrestigious: 'Modern & Prestigious',
  homeServiceSolutions: ' Service Solutions',
  onDemand: 'HVAC, Plumber, Electrician & Other On Demand Service For You',
  yourFind: 'Find your ',
  experts: 'Experts',
  whateverYourNeeds:
    'With Tékhnē you will find the best Professionals in the area, whatever your needs.',
  beEfficient: 'Be Efficient',
  customersFast:
    'We get to customers fast and provide energy-efficient equipment.',
  rightTechnicians: 'The Right Technicians',
  yearsExperience:
    '5 years’ experience in the field. Technically strong and can work on various types of equipment. Enjoys the trade.',
  noGimmicks: 'No Gimmicks',
  isFree: `Show up and Do the right thing. Smart customers know nothing is "Free".`,
  knowAboutUs: 'Know more about us',
  activeCustomers: 'Active Customers',
  customersRatings: 'Customers Ratings',
  guysService: 'Good Guys Service Provider',
  neverBeenEasier: 'It has never been easier to hire a ',
  professional: 'Professional!',
  GetStarted:
    'Get started with Good Guys and get your job done in just 3 easy steps.',
  itNever: 'It has never been easier to hire a ',
  yourLocation: 'Select your location',
  yourService: 'Select your service',
  theJob: 'Hire a pro & finish the job',
  describeYourNeeds:
    'Describe your needs with just a few clicks on our form options.',
  listOfServices:
    'A list of services meeting your criteria will be presented to you.',
  youCanContact:
    'You can contact the professional that you liked the most, by phone or email.',
  youProfessional: 'Are you a ',
  andFocused: 'Experienced and customer focused!',
  joinTheTeam: 'JOIN THE TEAM',
  whatAboutUs: 'What they say About Us',
  ourWordForIT: "Don't take our word for it, here’s what others have to say.",
  overProfessionals:
    'Over 100,000 Clients and Professionals use Good Guys home services.',
  howItWorks: 'How It Works',
  dontTakeOurWord:
    "Don't take our word for it, here’s what others have to say.",
  whatTheySay: 'What they say',
  over: 'Over',
  clients: 'Clients',
  and: 'and',
  professionals: 'Professionals',
  useGoodGuys: 'use Good Guys home services.',
  users: 'users',
  by: 'by',
  ratedWith: 'GGHS was rated with',
};
