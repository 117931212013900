import { Outlet } from 'react-router-dom';
import { getActions } from '../../services/index.service';
import { CompanySetting } from '../../views/CompanySetting';

export default function route() {
  return [
    {
      private: true,
      name: 'Company Settings',
      key: 'companySettings',
      belongsToSidebar: true,
      icon: (
        <span className="nk-menu-icon">
          <em className="icon ni ni-setting-fill" />
        </span>
      ),
      moduleKeys: [accessRoute.GENERAL.moduleKey],
      element: <Outlet />,
      submenu: true,
      children: [
        {
          private: true,
          name: accessRoute.GENERAL.name,
          key: 'companySettings.general',
          path: accessRoute.GENERAL.path,
          belongsToSidebar: true,
          element: <CompanySetting />,
          ...accessRoute.GENERAL,
        },
      ],
    },
  ];
}

export const accessRoute = {
  GENERAL: {
    path: '/admin/general-setting',
    action: getActions('LIST').value,
    moduleKey: 'general',
    name: 'General',
    order: 16,
  },
};

export const getChildrenRoutes = () => {
  return route()[0]?.children;
};
