import React from 'react';
import {
  ActionWrapper,
  ModalWrapper,
  NoteFooter,
  PageContainerWrapper,
  TablePlus,
} from '../../../../components';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import {
  emailMessageBreadcrumb,
  emailMessageTableColumn,
} from '../../../../services/Columns';
import useTable from '../../../../hooks/useTable.hook';
import {
  listEmailService,
  viewShortCodeService,
} from '../../../../services/EmailMessage/emailTemplate.service';
import { accessRoute } from '../../../../routes/EmailSetting';
import { getActions } from '../../../../services/index.service';
import ShortCodeModal from '../../SMS/List/shortCodeModal';
import { useState } from 'react';
import { useEffect } from 'react';
import { getApiData } from '../../../../hooks/useGetApi.hook';

function ListEmail() {
  const { t } = useTranslation();
  const [shortCodeData, setShortCodeData] = useState([]);
  const [state, setState] = useState({
    modal: null,
  });
  const { modal } = state;
  const extraQuery = {
    emailType: 'default',
  };

  // Get table data by api
  const [tableHandler, tableProps] = useTable(listEmailService, extraQuery);
  const {
    data,
    isLoading,
    count,
    page,
    noOfPages,
    sizePerPage,
    filter,
    sortBy,
    sortType,
  } = tableProps;
  const { onFilterChange, onExport, onFilterResetChange } = tableHandler;

  // for breadcrumb
  const breadcrumbData = emailMessageBreadcrumb();

  // For action with column
  function onAction(e, handler, item) {
    e.preventDefault();
    if (handler) handler(item);
  }

  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options?.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item?.handler, item)}
              key={key}
              className="action_list"
              action={item?.action}
            >
              <Link
                to={generatePath(accessRoute.EDIT_EMAIL_TEMPLATES.path, {
                  id: item?.id,
                })}
              >
                <em className={item?.icon} /> {item?.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };

  // Columns
  const columns = emailMessageTableColumn({
    onFilterChange: onFilterChange,
    filter,
    page,
    sizePerPage,
    Action: (props) =>
      actionFormatter([
        {
          name: t('text.common.edit'),
          icon: 'icon ni ni-edit',
          id: props?.row?.id,
          action: getActions('EDIT').value,
        },
      ]),
  });

  const btn = {
    add: {
      name: t('text.emailMessage.shortCodes'),
      handler: () => {
        setState({ ...state, modal: 'short-code' });
      },
    },
    csvExport: { handler: () => onExport('', columns) },
  };
  const modalForm = {
    add: <ShortCodeModal onCancel={onCancel} data={shortCodeData} />,
  };
  function onCancel() {
    setState({ ...state, modal: null, modalId: null });
  }
  useEffect(() => {
    getApiData({
      service: viewShortCodeService,
      setData: setShortCodeData,
    });
  }, []);
  return (
    <>
      <PageContainerWrapper
        breadcrumb={breadcrumbData}
        btn={btn}
        heading={t('text.emailMessage.title')}
      >
        <ModalWrapper
          extraClassName="modal-xl"
          heading={t('text.emailMessage.emailShortCodes')}
          onCancel={onCancel}
          content={modalForm['add']}
          show={['short-code'].includes(modal)}
        />
        <TablePlus
          hasLimit
          noOfPage={noOfPages}
          sizePerPage={sizePerPage}
          page={page}
          count={count}
          tableData={data}
          tableColumns={columns}
          selectRow
          tableLoader={isLoading}
          showAction={false}
          onFilterResetChange={onFilterResetChange}
          filter={filter}
          sortBy={sortBy}
          sortType={sortType}
        />
        <NoteFooter title={t('text.common.notes')}>
          <li>{t('text.emailMessage.noteEmail')}</li>
          <li>{t('text.emailMessage.noteEdit')}</li>
          <li>{t('text.localization.noteThree')}</li>
        </NoteFooter>
      </PageContainerWrapper>
    </>
  );
}

export default ListEmail;
