import { APIRequest } from '../../helpers/index.helper';
import { ADDRESS_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { logger } from '../../utils';

export const userAddressListService = async ({ queryParams }) => {
  try {
    const payload = {
      ...ADDRESS_ENDPOINT?.getUserAddress,
      queryParams,
    };

    const res = await APIRequest(payload);

    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const addUserAddressService = async ({ body }) => {
  try {
    const payload = {
      ...ADDRESS_ENDPOINT?.addUserAddress,
      bodyData: body,
    };
    return await APIRequest(payload);
  } catch (err) {
    console.log(err);
  }
};

export const viewUserAddressService = async ({ resourceId }) => {
  try {
    const payload = {
      ...ADDRESS_ENDPOINT?.viewUserAddress(resourceId),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const editUserAddressService = async ({ body, id }) => {
  try {
    const payload = {
      ...ADDRESS_ENDPOINT?.editUserAddress(id),
      bodyData: body,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

// export const deleteUserAddressService = async ({ values }) => {
//   try {
//     const payload = {
//       ...ADDRESS_ENDPOINT,
//       bodyData: values,
//     };
//     const res = await APIRequest(payload);
//     return res;
//   } catch (err) {
//     console.log(err);
//     logger(err);
//   }
// };
