let url = '/v1/admin/promo-code';
export const PROMO_CODE_ENDPOINT = {
  getPromoCodeList: {
    url,
    method: 'GET',
  },
  deletePromoCode: {
    url,
    method: 'DELETE',
  },
  promoCodeStatusUpdate: {
    url: `${url}/change-status`,
    method: 'PUT',
  },
  addPromoCode: {
    url,
    method: 'POST',
  },
  editPromoCode: (id) => ({
    url: `${url}/${id}`,
    method: 'PUT',
  }),
  getPromoCodeDetails: (id) => ({
    url: `${url}/${id}`,
    method: 'GET',
  }),
  unSubscribeUser: (id) => ({
    url: `/v1/marketing/unsubscribe/${id}`,
    method: 'PUT',
  }),
};
