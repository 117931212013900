import i18next from 'i18next';
import React, { useState } from 'react';
import { ImagePreviewFormatter } from '../../../components/UIElements/Formatter';
import { firstLetterCaps } from '../../../utils/text.util';
import { CommonButton } from '../../../components';

const removeSymbolAndUpperCase = (str, split, join) => {
  if (str) {
    return str
      .split(split)
      .map((word) => word[0].toUpperCase() + word.substring(1))
      .join(join);
  }
  return '-';
};

export default function InstalledEquipment({ addModalHide, selectedRows }) {
  const [imageError, setImageError] = useState(false);
  return (
    <div>
      <div className="row g-3">
        <div className="col-6">
          <span className="sub-text">{i18next.t('text.common.brand')}</span>
          <span>
            {selectedRows?.brand ? firstLetterCaps(selectedRows?.brand) : '-'}
          </span>
        </div>
        <div className="col-6">
          <span className="sub-text">
            {i18next.t('text.equipmentInstall.model')}
          </span>
          <span className="text-break">{selectedRows?.model ?? '-'}</span>
        </div>
        <div className="col-6">
          <span className="sub-text">
            {i18next.t('text.equipmentInstall.serialHash')}
          </span>
          <span className="text-break">{selectedRows?.serialNo ?? '-'}</span>
        </div>
        <div className="col-6">
          <span className="sub-text">
            {i18next.t('text.equipmentInstall.installationDate')}
          </span>
          <span>{selectedRows?.installDate ?? '-'}</span>
        </div>
        <div className="col-6">
          <span className="sub-text">
            {i18next.t('text.equipmentInstall.applicationType')}
          </span>
          <span>
            {selectedRows?.applicationType
              ? removeSymbolAndUpperCase(
                  selectedRows?.applicationType,
                  '_',
                  '-'
                )
              : '-'}
          </span>
        </div>
        <div className="col-6">
          <span className="sub-text">
            {i18next.t('text.equipmentInstall.typeofPurchase')}
          </span>
          {/* firstLetterCaps(selectedRows.purchaseType) */}
          <span>
            {selectedRows?.purchaseType === 'new'
              ? firstLetterCaps(selectedRows?.purchaseType)
              : selectedRows?.purchaseType === 'add_on'
              ? 'Add-on'
              : selectedRows?.purchaseType === 'existing_equipment'
              ? 'Existing-Equipment'
              : selectedRows?.purchaseType === 'replacement'
              ? firstLetterCaps(selectedRows?.purchaseType)
              : '-'}
          </span>
        </div>
        <div className="col-6">
          <span className="sub-text">
            {i18next.t('text.equipmentInstall.systemCondition')}
          </span>
          <span>
            {selectedRows?.systemCondition
              ? removeSymbolAndUpperCase(
                  selectedRows?.systemCondition,
                  '_',
                  ' '
                )
              : '-'}
          </span>
        </div>
        <div className="col-6">
          <span className="sub-text">
            {i18next.t('text.equipmentInstall.registerWarranty')}
          </span>
          <span>
            {selectedRows?.warrantyStatus
              ? removeSymbolAndUpperCase(selectedRows?.warrantyStatus, '_', ' ')
              : '-'}
          </span>
        </div>
        <div className="col-12">
          <span className="sub-text">
            {i18next.t('text.equipmentInstall.systemNotes')}
          </span>
          <span>
            {selectedRows?.systemNotes ? selectedRows?.systemNotes : '-'}
          </span>
        </div>
        <div className="col-12">
          <span className="sub-text">
            {i18next.t('text.equipmentInstall.photos')}
          </span>
          <div className="d-flex flex-wrap">
            {selectedRows?.HomeInventoryEquipmentImages?.length > 0
              ? selectedRows?.HomeInventoryEquipmentImages?.map(
                  (item, itemIdx) => (
                    <div
                      key={itemIdx}
                      className="imgPreviewBox overflow-hidden m-1"
                    >
                      <ImagePreviewFormatter
                        path={item?.imageUrl}
                        text="Image"
                        setImageError={setImageError}
                        imageError={imageError}
                      />
                    </div>
                  )
                )
              : '-'}
          </div>
        </div>
      </div>
      <div className="text-end mt-2">
        <CommonButton className="btn btn-light" onClick={() => addModalHide()}>
          {i18next.t('text.common.close')}
        </CommonButton>
      </div>
    </div>
  );
}
