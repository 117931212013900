import i18next from 'i18next';
import { accessRoute } from '../../routes/Dashboard';
import {
  checkValidData,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import {
  getInputSearchFilter,
  getMultiSelectSearchFilter,
} from '../../components';
import { readMoreTextShow } from '../../utils';
import { booleanYesNoList } from '../index.service';

export const serviceTypeTableColumn = ({
  Action,
  onSort,
  onFilterChange,
  filter,
  page,
  sizePerPage,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'name',
      filterDataIndex: ['salesQuote'],
      title: i18next.t('text.manageServices.salesQuote'),
      sorter: true,
      exportName: 'Type',
      render: (cell, row) => checkValidData(cell),
      ...getMultiSelectSearchFilter({
        dataIndex: 'salesQuote',
        onFilterChange,
        name: 'sales quote',
        list: [],
        mode: false,
        listService: booleanYesNoList,
        filter,
        // addAll: true,
      }),
    },
    {
      dataIndex: 'displayOrder',
      title: i18next.t('text.manageServices.displayOrder'),
      sorter: true,
      render: (cell, row) => checkValidData(cell),
    },
    {
      dataIndex: 'description',
      title: i18next.t('text.common.description'),
      sorter: false,
      export: false,
      render: (cell, row) =>
        readMoreTextShow(
          checkValidData(cell),
          i18next.t('text.common.description')
        ),
      ...getInputSearchFilter({
        dataIndex: 'description',
        placeholder: 'description',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.localization.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => {
        return <Action row={row} cell={cell} />;
      },
    },
  ];
};

export const priceServiceTypeBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.manageServices.serviceType'),
    },
  ];
};
