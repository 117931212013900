import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
  ActionWrapper,
  ModalComponent,
  PageContainerWrapper,
  PaymentReportFilterForm,
  TablePlus,
} from '../../../../components';
import ModalWrapper from '../../../../components/Wrappers/ModalWrapper/index.wrapper';
import GraphService from '../../../../features/graph/graph';
import useTable from '../../../../hooks/useTable.hook';
import { accessRoute } from '../../../../routes/Reports';
import {
  Columns,
  getActions,
  getGraphScheduleBooking,
  getProviderPaymentReportService,
} from '../../../../services/index.service';
import { dateFormatter, logger } from '../../../../utils';
import { useEffect } from 'react';
import useGraphData from '../../../../hooks/graph.hook';
import SubLabourList from '../SubLabourList';

import { totalTimeCount } from '../../../../components/UIElements/Formatter';
import moment from 'moment';
import { selectDateData } from '../../../../services/Columns';

function ServiceProvidersReport() {
  // const [totalJobTiming, setJobTiming] = useState({});

  const { t } = useTranslation();
  const navigate = useNavigate();
  const breadcrumb = Columns.serviceProviderBreadcrumb();
  const [subLabourModal, setSubLabourModal] = useState(false);
  const subLabourModalShow = () => {
    setSubLabourModal(true);
  };
  const subLabourModalHide = () => {
    setSubLabourModal(false);
  };
  const [isSearch, setIsSearch] = useState(0);

  const [state, setState] = useState({
    loader: null,
    modal: null,
    modalId: null,
    selectedRows: [],
  });

  const [filterValue, setFilter] = useState({
    startDate: moment().format('YYYY-MM-DD') || '',
    endDate: moment().format('YYYY-MM-DD') || '',
    location: undefined,
    dateType: selectDateData[0]?.value || undefined,
  });

  const [values, setValues] = useState({
    startDate: moment().format('YYYY-MM-DD') || '',
    endDate: moment().format('YYYY-MM-DD') || '',
    dateType: selectDateData[0]?.value || undefined,
  });

  const [ShowHideGraph, setShowHideGraph] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(false);
  const [graphData, graphLoader] = useGraphData({
    apiHandler: getGraphScheduleBooking,
    type: 'paymentReport',
    filterValue,
    isDependency: isSearch,
    isGraph: ShowHideGraph,
  });
  const [rowData, setRowData] = useState('');

  // const extraCSVHandle = async (getData) => {
  //   let obj = {};
  //   let arr = [];
  //   getData?.rows?.map &&
  //     getData?.rows?.map((item) => {
  //       obj = {
  //         name: totalTimeCount(
  //           item?.BookingDetail?.totalJobTime > 0
  //             ? item?.BookingDetail?.totalJobTime
  //             : null
  //         ),
  //       };
  //       arr.push(obj);
  //     });
  //   return { 'Job Timing': arr };
  // };
  let extraQuery = {
    ...filterValue,
  };
  delete extraQuery.dateType;
  const [tableHandler, tableProps] = useTable(
    getProviderPaymentReportService,
    extraQuery,
    false,
    false,
    false,
    false
    // extraCSVHandle
  );
  const {
    onFilterChange,
    getData,
    onExport,
    onFilterResetChange,
    onTableSort,
  } = tableHandler;
  const {
    data,
    isLoading,
    count,
    page,
    noOfPages,
    sizePerPage,
    filter,
    sortBy,
    sortType,
  } = tableProps;
  const { modal } = state;
  const ShowHideGraphShow = () => {
    setShowHideGraph(!ShowHideGraph);
  };
  const OpenBtnFilter = () => {
    setGlobalFilter(true);
  };

  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options?.map((item, key) => {
          return (
            <li key={key} className="action_list" action={item?.action}>
              <Link
                to={`${accessRoute.PAYMENT_REPORT.path}?providerUserId=${
                  item?.providerUserId
                }${`&providerName=${item?.providerName}`}${
                  values?.startDate && isSearch
                    ? `&startDate=${values?.startDate}`
                    : ''
                }${
                  values?.endDate && isSearch
                    ? `&endDate=${values?.endDate}`
                    : ''
                }${
                  values?.location
                    ? `&providerLocation=${values?.location}`
                    : ''
                }`}
                target="_blank"
              >
                <em className={item?.icon} />
                {item?.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };

  // const getFilterDataList = async () => {
  //   try {
  //     let queryParams = {
  //       scope: 'all',
  //     };
  //     const response = await getProviderListService({
  //       queryParams,
  //       type: 'all',
  //     });
  //     if (response) {
  //       setProviderListData(response);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const TotalSubLaborPayDetails = (e, id) => {
    e.preventDefault();
    navigate(`${accessRoute.SUBLABOR_PAY_ADD.path}/${id}`);
  };
  const columns = Columns.serviceProviderReportColumn({
    onSort: onTableSort,
    onFilterChange: onFilterChange,
    filter: filter,
    page,
    sizePerPage,
    setRowData,
    subLabourModalShow,
    TotalSubLaborPayDetails: TotalSubLaborPayDetails,
    Action: (props) =>
      actionFormatter([
        {
          name: t('text.common.viewDetails'),
          icon: 'icon ni ni-eye',
          id: props?.row?.id,
          action: getActions('VIEW').value,
          providerUserId: props?.row?.providerUserId,
          providerName: `${props?.row?.Provider?.firstName} ${props?.row?.Provider?.lastName}`,
        },
      ]),
  });

  const chartOptionsData = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
      tooltip: {
        mode: 'index',
        position: 'average',
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  const jobData = {
    labels: graphData?.labels,
    datasets: [
      {
        label: t('text.common.collected'),
        data: graphData?.MonthlyCollectedAmount,
        backgroundColor: ['#1EE0AC'],
        borderWidth: 0,
        hoverOffset: 4,
      },
      {
        label: t('text.common.uncollected'),
        data: graphData?.remainingMonthlyCollectedAmount,
        backgroundColor: ['#E85347'],
        borderWidth: 0,
        hoverOffset: 4,
      },
    ],
  };

  const modalForm = {
    add: <GraphService jobData={jobData} chartOptionsData={chartOptionsData} />,
  };
  if (globalFilter) {
    document.querySelector('body').classList.add('toggle-shown');
  } else {
    document.querySelector('body').classList.remove('toggle-shown');
  }

  window.addEventListener('resize', () => {
    const { width } = window.screen;
    if (width === 1200) {
      setState({ ...state, shouldVisible: true });
    }
  });
  const headerBtn = {
    graph: {
      name: t('text.report.showHide'),
      handler: () => {
        setState({
          ...state,
          submitText: 'show graph',
        });
        ShowHideGraphShow();
      },
    },
    add: {
      name: (
        <>
          <em className="icon ni ni-filter-fill me-1" /> Filter
        </>
      ),
      handler: () => {
        OpenBtnFilter();
      },
    },
    // csvExport: { handler: () => onExport('', columns, totalJobTiming) },
    csvExport: { handler: () => onExport('', columns) },
  };
  function onCancel() {
    setState({ ...state, modal: null, modalId: null });
  }
  function onRowSelect(e) {
    try {
      setState({ ...state, selectedRows: [...e] });
    } catch (err) {
      console.log('err', err);
    }
  }

  async function handleActionSelect(e) {
    try {
      if (e.target.value === 'delete') {
        getData();
      } else {
        getData();
      }
    } catch (err) {
      console.log(err);
    }
  }

  const BackBtnFilter = () => {
    setGlobalFilter(false);
  };

  const onFilterSubmit = (values) => {
    try {
      let val = {
        startDate: values?.startDate || '',
        endDate: values?.endDate || '',
        location: values?.location || '',
      };
      setFilter((prevState) => ({
        ...prevState,
        ...val,
      }));
      delete val?.dateType;

      setIsSearch(isSearch + 1);

      onFilterChange(val);
      setGlobalFilter(false);
    } catch (error) {
      logger(error);
    }
  };

  function onReset() {
    if (
      !values?.startDate &&
      !values?.endDate &&
      !values?.fromDate &&
      !values?.toDate &&
      !values?.dateType &&
      !values?.location
    ) {
      return;
    }
    const resetData = {
      startDate: dateFormatter(
        moment(new Date()).subtract(1, 'year').toDate(),
        'YYYY-MM-DD'
      ),
      endDate: dateFormatter(new Date(), 'YYYY-MM-DD'),
      location: undefined,
      dateType: '',
    };
    setFilter({
      ...resetData,
    });
    setValues({
      ...resetData,
    });
    setIsSearch(isSearch + 1);
    onFilterChange(resetData);
    setGlobalFilter(false);
  }

  function onTableResetFilter() {
    const resetData = {
      startDate: '',
      endDate: '',
      location: undefined,
    };
    setFilter({
      ...resetData,
    });
    setIsSearch(isSearch + 1);
    onFilterChange(resetData);
    onFilterResetChange();
  }

  useEffect(() => {
    if (values) {
      setFilter({
        ...filterValue,
        startDate:
          values?.startDate ||
          (values?.dateType && moment().format('YYYY-MM-DD')),
        endDate:
          values.endDate || (values?.dateType && moment().format('YYYY-MM-DD')),
        dateType: values?.dateType,
      });
    }
  }, [values]);

  return (
    <>
      <PageContainerWrapper
        form={modalForm['filter']}
        heading={t('text.report.serviceProviderPaymentReport')}
        breadcrumb={breadcrumb}
        btn={headerBtn}
        extraClass="btn btn-primary d-block d-xl-none"
      >
        <div className="">
          <div className="globalFilters">
            <div className="card ">
              <div className="card-inner">
                <div
                  className={`globalFilters_inner ${
                    globalFilter ? 'filterToggle' : ''
                  }`}
                >
                  <div className="mb-3 d-block d-xl-none">
                    <Link to="#" onClick={() => BackBtnFilter()}>
                      <em className="icon ni ni-arrow-long-left me-1"></em>
                      {t('text.common.back')}
                    </Link>
                  </div>
                  <PaymentReportFilterForm
                    data={true}
                    onSubmit={onFilterSubmit}
                    onReset={onReset}
                    filterValue={filterValue}
                    setValues={setValues}
                    values={values}
                  />
                  {/* <ScheduleFilterForm
                    data={true}
                    onSubmit={onFilterSubmit}
                    onReset={onReset}
                    filterValue={filterValue}
                    setValues={setValues}
                    values={values}
                  /> */}
                </div>
                <div
                  className={` ${globalFilter ? 'toggle-overlay' : ''} `}
                  onClick={() => BackBtnFilter()}
                ></div>
              </div>
            </div>
          </div>
        </div>

        {ShowHideGraph && (
          <GraphService
            type="bar"
            data={jobData}
            options={chartOptionsData}
            totalData={graphData}
            loading={graphLoader}
          />
        )}
        <ModalWrapper
          heading={`${'Graph'}`}
          onCancel={onCancel}
          content={modalForm['add']}
          show={['show-graph'].includes(modal)}
        />
        <div className="tableScrollHide">
          <TablePlus
            hasLimit
            noOfPage={noOfPages}
            sizePerPage={sizePerPage}
            page={page}
            handleSelectedRows={onRowSelect}
            handleActionSelect={handleActionSelect}
            count={count}
            tableData={data}
            tableColumns={columns}
            selectRow={true}
            statusAction={false}
            showAction={false}
            tableLoader={isLoading}
            onFilterResetChange={onTableResetFilter}
            filter={filter}
            sortBy={sortBy}
            sortType={sortType}
            scrollTopPosition
          />
        </div>
        <ModalComponent
          backdrop
          show={subLabourModal}
          onHandleCancel={subLabourModalHide}
          extraClassName="modal-xxl"
          title={t('text.report.sublaborPayDetails')}
        >
          <SubLabourList
            subLabourModalHide={subLabourModalHide}
            rowData={rowData}
            filterValue={filterValue}
          />
        </ModalComponent>
      </PageContainerWrapper>
    </>
  );
}

export default ServiceProvidersReport;
