import { useEffect, useState } from 'react';
import { months } from '../utils';

const useGraphData = ({
  apiHandler,
  type,
  filterValue,
  isDependency,
  isGraph,
}) => {
  const { startDate, endDate, location, fromDate, toDate } = filterValue;
  const [graphData, setGraphData] = useState({});
  const [graphLoader, setGraphLoader] = useState(false);

  useEffect(() => {
    if (apiHandler && isGraph) {
      getGraphDetails(apiHandler);
    }
  }, [apiHandler, isDependency, isGraph]);

  const getData = (arr) => {
    try {
      const collections = new Array(12).fill(0);
      arr?.forEach((v) => {
        collections.splice(
          v?.month - 1,
          0,
          parseFloat(v?.monthlyCollectedAmount).toFixed(2)
        );
      });
      return collections;
    } catch (error) {
      console.log(error);
    }
  };

  const getGraphDetails = async (GraphAPI) => {
    try {
      setGraphLoader(true);
      const queryParams = {
        type: type,
        startDate: startDate || fromDate,
        endDate: endDate || toDate,
        location: location,
      };
      const res = await GraphAPI({ queryParams });
      if (res.success) {
        setGraphData({
          ...graphData,
          remainingMonthlyCollectedAmount: getData(
            res?.data?.remainingCollection
          ),
          MonthlyCollectedAmount: getData(res?.data?.collection),
          totalData: res?.data?.allCollection,
          labels: months.map(
            (v) =>
              `${v?.month} ${
                res?.data?.remainingCollection[0].year ||
                res?.data?.collection[0]?.year
              }`
          ),
        });
      }
    } catch (error) {
      console.log(error);
    }
    setGraphLoader(false);
  };
  return [graphData, graphLoader];
};
export default useGraphData;
