import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { PageContainerWrapper, TablePlus } from '../../../../components';
import useTable from '../../../../hooks/useTable.hook';
import { accessRoute } from '../../../../routes/Reports';
import {
  Columns,
  getProviderInvoiceReportDetailsService,
} from '../../../../services/index.service';
import { backToPage } from '../../../../utils';

function ProviderInvoiceUpdate() {
  const breadcrumb = Columns.providerInvoiceBreadcrumb();
  const redirectUrl = accessRoute.PAYMENT_REPORT.path;
  const navigate = useNavigate();
  const params = useParams();
  let extraQuery = {
    bookingId: params?.id,
  };
  const [tableHandler, tableProps] = useTable(
    getProviderInvoiceReportDetailsService,
    extraQuery
  );
  const {
    count,
    data,
    sizePerPage,
    page,
    noOfPages,
    isLoading,
    filter,
    sortBy,
    sortType,
  } = tableProps;
  const { onFilterChange, onTableSort } = tableHandler;

  const columns = Columns.providerInvoiceColumn({
    onSort: onTableSort,
    onFilterChange: onFilterChange,
    sizePerPage,
    page,
  });
  const { t } = useTranslation();

  return (
    <>
      <PageContainerWrapper
        heading={t('text.report.providerInvoice')}
        breadcrumb={breadcrumb}
        btnArray={['extraButton']}
        extraBtnText={t('text.common.back')}
        extraBtnClass="btn btn-outline-light bg-white"
        onExtraButtonHandleShow={() => backToPage(navigate, redirectUrl)}
      >
        <TablePlus
          hasLimit
          noOfPage={noOfPages}
          sizePerPage={sizePerPage}
          page={page}
          count={count}
          tableData={data}
          tableColumns={columns}
          // handleSelectedRows={onRowSelect}
          // handleActionSelect={handleActionSelect}
          selectRow
          showAction={false}
          searchField
          tableLoader={isLoading}
          filter={filter}
          sortBy={sortBy}
          sortType={sortType}
        />
      </PageContainerWrapper>
    </>
  );
}

export default ProviderInvoiceUpdate;
