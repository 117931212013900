import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditBankingDetailsForm } from '../../../../components';
import BankDetailCard from '../../../../features/bankDetailCard/bankDetailCard.feature';

function NCABankingDetails({ onSubmit, isLoading, formData }) {
  const { t } = useTranslation();

  return (
    <div className="card">
      <div className="card-inner">
        {!formData?.UserBank?.accountNumber && (
          <>
            <div className="alert alert-danger alert-icon">
              <em className="icon ni ni-alert-circle"></em>{' '}
              <b>{t('text.common.note')} :-</b> {t('text.common.oneTimeFile')}
            </div>
            <EditBankingDetailsForm
              isLoading={isLoading === 'SUBMIT'}
              onSubmit={onSubmit}
              ncaEdit={true}
            />
          </>
        )}
        {formData?.UserBank?.accountNumber && (
          <BankDetailCard data={formData?.UserBank} />
        )}
      </div>
    </div>
  );
}

export default NCABankingDetails;
