import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CommonButton,
  NoDataFound,
  PageContainerWrapper,
} from '../../../../components';
import { Columns, getModules } from '../../../../services/index.service';
import { accessRoute } from '../../../../routes/TeamSetting/teamSetting';
import {
  getPermission,
  getRolesList,
  updatePermissionBy,
} from '../../../../services/TeamSetting/role.service';
import { getPurePermissionObjectFromArray } from '../../../../utils/permission';
import { Form, Switch, Radio } from 'antd';
import { backToPage, modalNotification } from '../../../../utils';
import { useTranslation } from 'react-i18next';

function AssignModules() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();
  const { t } = useTranslation();
  const listOfValues = {
    view: { value: 'view', label: 'View' },
    edit: { value: 'edit', label: 'Edit' },
  };
  const breadcrumb = Columns.assignRoleBreadcrumb();
  const routes = getModules();
  const [state, setState] = useState({
    permission: {},
    listPermission: {},
    rolePermission: [],
    initialState: {},
  });
  const [rolePermission, setRolePermission] = useState({
    listData: null,
    type: null,
    mapData: {},
  });
  const { listPermission, initialState, permission } = state;
  const { listData, mapData, type } = rolePermission;
  const [loading, setLoading] = useState('getPermissionList');

  useEffect(() => {
    getRolePermission(id);
  }, []);

  useEffect(() => {
    if (Array.isArray(listData)) {
      getPermissionList(mapData);
    }
  }, [listData]);

  async function getRolePermission(id) {
    try {
      const res = await getRolesList({ id });
      if (res?.success) {
        const rolePermissionMap = {};
        (res?.data?.permission || []).forEach((e) => {
          const moduleKeys = e?.name?.split('.');
          e.moduleKey = moduleKeys?.shift();
          e.action = moduleKeys.pop();
          rolePermissionMap[e?.moduleKey] = e;
        });
        setRolePermission({
          ...rolePermission,
          type: res?.data?.type,
          listData: res?.data?.permission || [],
          mapData: rolePermissionMap,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  function permissionListInterceptor(e, permissionArrayFromObject) {
    try {
      if (type === 'nca' && e?.moduleKey === 'membership') {
        return false;
      }
      if (e?.for) {
        if (e?.for?.includes(type)) {
          return Object.keys(permissionArrayFromObject)?.includes(e?.moduleKey);
        }
        return false;
      }
      return Object.keys(permissionArrayFromObject).includes(e?.moduleKey);
    } catch (err) {
      console.log(err);
    }
  }

  async function getPermissionList(rolePermission) {
    try {
      setLoading('getPermissionList');
      const res = await getPermission({});
      if (res?.success) {
        const permissionArrayFromObject = getPurePermissionObjectFromArray(
          res?.data?.rows
        );
        const list = {
          permission: [
            ...routes
              .filter((e) =>
                permissionListInterceptor(e, permissionArrayFromObject)
              )
              .sort((a, b) => a?.order - b?.order)
              .map((e) => {
                return {
                  action: mapData[e?.moduleKey]?.action || undefined,
                  // isActive: !!rolePermission[e?.key],
                  isActive: !!rolePermission[e?.moduleKey],
                  key: e?.moduleKey,
                  name: e?.name,
                  permissionId:
                    permissionArrayFromObject[e?.moduleKey]?.permissionId,
                  moduleId: permissionArrayFromObject[e?.moduleKey]?.moduleId,
                };
              }),
          ],
        };

        setState({
          ...state,
          permission: permissionArrayFromObject,
          listPermission: list,
          initialState: { ...list },
        });
      }
      setLoading(null);
    } catch (err) {
      setLoading(null);
      console.log(err);
    }
  }

  async function updatePermissionListById(payload) {
    try {
      setLoading('submit');
      const res = await updatePermissionBy({ id: id, body: payload });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  async function onSubmit(payload) {
    try {
      const res = await updatePermissionListById(payload);
      if (res?.success) {
        modalNotification({
          type: 'success',
          message: t('text.customers.permissionUpdatedSuccessfully'),
        });
        backToPage(navigate, accessRoute.ROLES.path);
      }
      setLoading(null);

      return false;
    } catch (err) {
      setLoading(null);
      console.log(err);
    }
  }

  const onBack = () => {
    try {
      navigate(accessRoute.ROLES.path);
    } catch (err) {
      console.log(err);
    }
  };

  const headerBtn = {
    extraButton: { name: t('text.common.back'), handler: onBack },
  };

  function onSwitchChange(switchState, key) {
    try {
      let formValues = form.getFieldsValue()?.permission;
      if (formValues) {
        formValues = formValues.map((e, index) => {
          if (key === index && !switchState) {
            e.action = undefined;
          }
          if (key === index && switchState) {
            e.action =
              initialState?.permission[key]?.action ||
              listPermission?.permission[key]?.key === 'chat'
                ? 'edit'
                : 'view';
          }
          return e;
        });
        setState({ ...state, listPermission: { permission: formValues } });
      }
    } catch (err) {
      console.log('Error', err);
    }
  }

  function getSwitchShouldDisable(key) {
    try {
      if (
        Array.isArray(form?.getFieldsValue()?.permission) &&
        form?.getFieldsValue()?.permission[key]?.isActive
      ) {
        return !form?.getFieldsValue()?.permission[key]?.isActive;
      }
      return true;
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="wide-md mx-auto">
      <PageContainerWrapper
        breadcrumb={breadcrumb}
        heading={t('text.common.assignModules')}
        extraBtnClass="btn btn-outline-light bg-white"
        btnArray={['filter', 'create', 'csvExport']}
        btn={headerBtn}
        loading={loading === 'getPermissionList'}
        loadingText={false}
      >
        <div className="card card-preview">
          <div className="card-inner">
            <div className="nk-block">
              <div className="serviceList assignModuleList">
                <h6 className="mb-3">
                  {t('text.common.assignModules')}
                  <span className="text-danger">*</span>
                </h6>
                <div className="nk-data data-list mt-2">
                  <div className="data-head">
                    <h6 className="overline-title">
                      {' '}
                      {t('text.common.assignModules')}
                    </h6>
                  </div>

                  {loading === 'getPermissionList' ? null : (
                    <>
                      {Object.keys(listPermission).length > 0 ? (
                        <>
                          {listPermission?.permission && (
                            <Form
                              form={form}
                              onFinish={onSubmit}
                              name="dynamic_form_nest_item"
                            >
                              <Form.List
                                initialValue={[...listPermission?.permission]}
                                name="permission"
                              >
                                {(
                                  fields
                                  // { add, remove }
                                ) => (
                                  <>
                                    {fields.map(
                                      ({ key, name, ...restField }) => (
                                        <>
                                          <div className="data-item p-1">
                                            <div className="data-col">
                                              <span className="data-label">
                                                {' '}
                                                {
                                                  listPermission?.permission[
                                                    key
                                                  ]?.name
                                                }
                                              </span>
                                              <span className="data-value d-flex align-items-center">
                                                <div>
                                                  <Form.Item
                                                    valuePropName="checked"
                                                    {...restField}
                                                    name={[name, 'isActive']}
                                                  >
                                                    <Switch
                                                      disabled={loading}
                                                      onChange={(e) =>
                                                        onSwitchChange(e, key)
                                                      }
                                                    />
                                                  </Form.Item>
                                                </div>
                                                <Form.Item
                                                  valuePropName="value"
                                                  {...restField}
                                                  name={[name, 'action']}
                                                  className="ms-2"
                                                >
                                                  <Radio.Group
                                                    disabled={
                                                      getSwitchShouldDisable(
                                                        key
                                                      ) || loading
                                                    }
                                                    // defaultValue={field?.value || null}
                                                    // onChange={handleChangeSelect}
                                                  >
                                                    {(
                                                      permission[
                                                        listPermission
                                                          ?.permission[key]?.key
                                                      ]?.arr || []
                                                    ).map((e) => {
                                                      return (
                                                        <>
                                                          {(listPermission
                                                            ?.permission[key]
                                                            ?.key !== 'chat' ||
                                                            e !== 'view') && (
                                                            <Radio
                                                              key={
                                                                listOfValues[e]
                                                                  .value
                                                              }
                                                              value={
                                                                listOfValues[e]
                                                                  .value
                                                              }
                                                            >
                                                              {
                                                                listOfValues[e]
                                                                  .label
                                                              }
                                                            </Radio>
                                                          )}
                                                        </>
                                                      );
                                                    })}
                                                  </Radio.Group>
                                                </Form.Item>
                                              </span>
                                            </div>
                                          </div>
                                        </>
                                      )
                                    )}
                                  </>
                                )}
                              </Form.List>
                              <div className="text-end mt-3">
                                <CommonButton
                                  className="btn btn-primary"
                                  type="submit"
                                  loading={loading}
                                  disabled={loading}
                                >
                                  {t('text.common.assignModule')}
                                </CommonButton>
                              </div>
                            </Form>
                          )}
                        </>
                      ) : (
                        <NoDataFound
                          source="nodata-found.svg"
                          text={t('text.common.noDataFound')}
                          isCustomer={'/assets/images/admin/nodata-found.svg'}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageContainerWrapper>
    </div>
  );
}

export default AssignModules;
