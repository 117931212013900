import i18next from 'i18next';
import {
  getColumnSelectSearchProps,
  getInputSearchFilter,
  getPriceFilter,
} from '../../components';
import {
  ButtonFormatter,
  checkValidData,
  checkValidPrice,
  iconFormatter,
  serialNumberFormatter,
  ToFixedPrice,
} from '../../components/UIElements/Formatter';
import { accessRoute } from '../../routes/Dashboard';
import { accessRoute as marketingRoute } from '../../routes/Marketing';
import { formatPhoneNumber, phoneNumberFormate } from '../../utils';
import { getStatusData } from '../index.service';
import { generatePath } from 'react-router-dom';

const totalAmountFields = {
  fromOperatorField: 'totalAmountFromOperator',
  fromOperatorAmountField: 'totalAmountFrom',
  conditionalField: 'totalAmountCondition',
  toOperatorField: 'totalAmountToOperator',
  toOperatorAmountField: 'totalAmountTo',
};
const pendingAmountFields = {
  fromOperatorField: 'pendingAmountFromOperator',
  fromOperatorAmountField: 'pendingAmountFrom',
  conditionalField: 'pendingAmountCondition',
  toOperatorField: 'pendingAmountToOperator',
  toOperatorAmountField: 'pendingAmountTo',
};

const transferAmountFields = {
  fromOperatorField: 'transferAmountFromOperator',
  fromOperatorAmountField: 'transferAmountFrom',
  conditionalField: 'transferAmountCondition',
  toOperatorField: 'transferAmountToOperator',
  toOperatorAmountField: 'transferAmountTo',
};

export const storeTableColumn = ({
  Action,
  Toggle,
  onFilterChange,
  onSort,
  onDocument,
  filter,
  page,
  sizePerPage,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'firstName',
      title: i18next.t('text.marketing.name'),
      headerClasses: 'sorting',
      sorter: false,
      exportFormatter: (cell, row) => checkValidData(row?.firstName),
      render: (cell, row) => checkValidData(row?.firstName),
      ...getInputSearchFilter({
        dataIndex: 'firstName',
        placeholder: 'name',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'email',
      title: i18next.t('text.common.email'),
      sorter: false,
      exportFormatter: (cell, row) => checkValidData(row?.email),
      render: (cell, row) => checkValidData(row?.email),
      ...getInputSearchFilter({
        dataIndex: 'email',
        placeholder: 'email',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'phoneNumber',
      title: i18next.t('text.common.mobile'),
      sorter: false,
      exportFormatter: (cell, row) =>
        phoneNumberFormate(cell, row?.countryPhoneCode),
      render: (cell, row) => {
        const formatNumber = phoneNumberFormate(cell, row?.countryPhoneCode);
        return formatNumber ?? '-';
      },
      ...getInputSearchFilter({
        dataIndex: 'phoneNumber',
        placeholder: 'phone number',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'totalAmount',
      filterDataIndex: [
        'totalAmountCondition',
        'totalAmountFrom',
        'totalAmountFromOperator',
        'totalAmountTo',
        'totalAmountToOperator',
      ],
      title: i18next.t('text.marketing.totalAmount'),
      sorter: false,
      exportFormatter: (cell, row) => checkValidPrice(cell),
      render: (cell, row) =>
        parseFloat(cell) > 0 && row?.Store?.id ? (
          <>
            {checkValidPrice(cell)}
            {ButtonFormatter(
              generatePath(marketingRoute.PROMO_CODE_JOBS.path, {
                storeId: row?.id,
              }),
              'View Detail',
              '_blank'
            )}
          </>
        ) : (
          checkValidPrice(cell)
        ),
      ...getPriceFilter({
        dataIndex: 'totalAmount',
        onFilterChange,
        filter,
        fields: totalAmountFields,
      }),
    },
    {
      dataIndex: 'pendingAmount',
      filterDataIndex: [
        'pendingAmountCondition',
        'pendingAmountFrom',
        'pendingAmountFromOperator',
        'pendingAmountTo',
        'pendingAmountToOperator',
      ],

      title: i18next.t('text.marketing.pendingAmount'),
      sorter: false,
      exportFormatter: (cell, row) => checkValidPrice(cell),
      render: (cell, row) => checkValidPrice(cell),
      ...getPriceFilter({
        dataIndex: 'pendingAmount',
        onFilterChange,
        filter,
        fields: pendingAmountFields,
      }),
    },
    {
      dataIndex: 'transferAmount',
      filterDataIndex: [
        'transferAmountCondition',
        'transferAmountFrom',
        'transferAmountFromOperator',
        'transferAmountTo',
        'transferAmountToOperator',
      ],
      exportFormatter: (cell, row) => checkValidPrice(cell),

      title: i18next.t('text.marketing.transferAmount'),
      sorter: false,
      render: (cell, row) => checkValidPrice(cell),
      ...getPriceFilter({
        dataIndex: 'transferAmount',
        onFilterChange,
        filter,
        fields: transferAmountFields,
      }),
    },
    {
      dataIndex: 'document',
      title: i18next.t('text.marketing.document'),
      sort: false,
      render: (cell, row) =>
        iconFormatter('icon ni ni-file-doc', onDocument(row?.id)),
    },
    {
      dataIndex: 'status',
      title: i18next.t('text.common.status'),
      headerClasses: 'sorting',
      sort: false,
      onSort: (field, order) => onSort('status', order),
      render: (cell, row) => {
        return <Toggle row={row} cell={cell} />;
      },
      ...getColumnSelectSearchProps({
        dataIndex: 'status',
        name: 'status',
        onFilterChange,
        filter,
        mode: false,
        list: getStatusData(),
      }),
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.common.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => {
        return <Action row={row} cell={cell} />;
      },
    },
  ];
};

export const storeBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.marketing.storeHeading'),
    },
  ];
};
export const addStoreBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: marketingRoute.STORE.path,
      name: i18next.t('text.marketing.storeHeading'),
    },
    {
      path: '#',
      name: i18next.t('text.marketing.addStoreHeading'),
    },
  ];
};
export const editStoreBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: marketingRoute.STORE.path,
      name: i18next.t('text.marketing.storeHeading'),
    },
    {
      path: '#',
      name: i18next.t('text.marketing.editStoreHeading'),
    },
  ];
};
export const storeDocumentBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: marketingRoute.STORE.path,
      name: i18next.t('text.marketing.storeHeading'),
    },
    {
      path: '#',
      name: i18next.t('text.common.document'),
    },
  ];
};
