let url = '/v1/admin/lesson';

export const OPERATING_PROCEDURE_ENDPOINT = {
  getLessonList: {
    url: `${url}`,
    method: 'GET',
  },
  deleteLesson: {
    url: `${url}`,
    method: 'DELETE',
  },
  changeStatus: {
    url: `${url}/change-status`,
    method: 'PUT',
  },
  lessonDetails: (id) => ({
    url: `${url}/${id}`,
    method: 'GET',
  }),
  lessonUpdate: (id) => ({
    url: `${url}/${id}`,
    method: 'PUT',
  }),
  addLesson: {
    url: `${url}`,
    method: 'POST',
  },
  getQuestions: {
    url: `${url}/question`,
    method: 'GET',
  },
  deleteQuestions: (id) => ({
    url: `${url}/question/${id}`,
    method: 'DELETE',
  }),

  addQuestions: {
    url: `${url}/question`,
    method: 'POST',
  },

  questionDetails: (id) => ({
    url: `${url}/question/${id}`,
    method: 'GET',
  }),
  questionNotification: (id) => ({
    url: `${url}/question-notification/${id}`,
    method: 'GET',
  }),
  questionUpdate: (id) => ({
    url: `${url}/question/${id}`,
    method: 'PUT',
  }),
};
