import React from 'react';

function FullPageLoader({ isSideBar = false }) {
  return (
    <div className={`loaderBox ${isSideBar ? 'loaderBox_sidebar' : ''}`}>
      <div className="loader">
        <span></span>
      </div>
    </div>
  );
}

export default FullPageLoader;
