import { APIRequest } from '../../helpers/index.helper';
import { MANAGE_LOCATION_ENDPOINT } from '../../apiEndpoints/ManageLocation/index.endpoint';
import { logger, setLocalStorage } from '../../utils';

export const ListManageLocationService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...MANAGE_LOCATION_ENDPOINT.getGeoFenceLocationList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      if (queryParams?.addToArray) {
        return [{ id: 'all', name: 'All' }, ...res?.data?.rows];
      }
      setLocalStorage({ keyName: 'isScrollCount', data: res?.data?.count });
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};
export const addNCALocationService = async ({ values }) => {
  try {
    const payload = {
      ...MANAGE_LOCATION_ENDPOINT.addNCALocationList,
      bodyData: values,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
export const ManageLocationListWithoutTokenService = async ({
  queryParams,
  type,
}) => {
  try {
    const payload = {
      ...MANAGE_LOCATION_ENDPOINT.getGeoFenceLocation,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      if (queryParams?.addToArray) {
        return [{ id: 'all', name: 'All' }, ...res?.data?.rows];
      }
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};
export const deleteManageLocationService = async ({ bodyData }) => {
  try {
    const payload = {
      ...MANAGE_LOCATION_ENDPOINT.deleteGeoFenceLocation,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const statusManageLocationService = async (values) => {
  try {
    const payload = {
      ...MANAGE_LOCATION_ENDPOINT.statusGeoFenceLocation,
      bodyData: values,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const addManageLocationService = async ({ values }) => {
  try {
    const payload = {
      ...MANAGE_LOCATION_ENDPOINT.addGeoFenceLocation,
      bodyData: values,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
export const ViewManageLocationService = async (id) => {
  try {
    const payload = {
      ...MANAGE_LOCATION_ENDPOINT?.viewGeoFenceLocation(id),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
export const editManageLocationService = async ({ values, id }) => {
  try {
    const payload = {
      ...MANAGE_LOCATION_ENDPOINT.editGeoFenceLocation(id),
      bodyData: values,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
export const editNCALocationService = async ({ values, id }) => {
  try {
    const payload = {
      ...MANAGE_LOCATION_ENDPOINT.editNCAGeoFenceLocation(id),
      bodyData: values,
    };
    console.log('Values', payload);
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
