import i18next from 'i18next';
import {
  getInputSearchFilter,
  getMultiSelectSearchFilter,
} from '../../components';
import {
  numberToBoolean,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { firstLetterCaps } from '../../utils/text.util';
import { getTupleStatusList } from '../index.service';

export const roleList = ({
  Action,
  Toggle,
  ToggleHealth,
  onSort,
  onFilterChange,
  filter,
  page,
  sizePerPage,
  AlexaToggle,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'name',
      title: i18next.t('text.common.roles'),
      sorter: true,
      onSort: (field, order) => onSort('name', order),
      render: (cell, row) => firstLetterCaps(cell),
      ...getInputSearchFilter({
        dataIndex: 'name',
        onFilterChange,
        filter,
        placeholder: 'roles',
      }),
    },
    {
      dataIndex: 'type',
      title: i18next.t('text.common.roleAssigned'),
      sorter: true,
      render: (cell, row) => firstLetterCaps(cell),
      onSort: (field, order) => onSort('type', order),
      ...getInputSearchFilter({
        dataIndex: 'type',
        onFilterChange,
        filter,
        placeholder: 'roles assigned',
      }),
    },
    {
      dataIndex: 'isAllowMentalHealthModule',
      title: i18next.t('text.common.mentalHealthPermission'),
      sorter: true,
      export: false,
      exportFormatter: (cell, row) => numberToBoolean(cell),
      render: (cell, row) => {
        return <ToggleHealth row={row} cell={cell} />;
      },
    },
    {
      dataIndex: 'alexaToggle',
      title: i18next.t('text.role.alexaToggle'),
      sorter: false,
      render: (cell, row) => {
        return <AlexaToggle row={row} cell={cell} />;
      },
    },
    {
      dataIndex: 'status',
      title: i18next.t('text.common.status'),
      sorter: true,
      render: (cell, row) => {
        return <Toggle row={row} cell={cell} />;
      },
      ...getMultiSelectSearchFilter({
        dataIndex: 'status',
        onFilterChange,
        filter,
        name: 'status',
        list: [],
        mode: false,
        listService: getTupleStatusList,
      }),
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.common.action'),
      className: 'nk-tb-col-tools text-end',
      headerClasses: 'nk-tb-col-tools text-end',
      render: (cell, row) => <Action cell={cell} row={row} />,
    },
  ];
};

export const roleBreadcrumb = () => {
  return [
    {
      path: '/admin/dashboard',
      name: i18next.t('text.common.dashboard'),
    },
    {
      // path: "/admin/role",
      name: i18next.t('text.common.roles'),
    },
  ];
};

export const assignRoleBreadcrumb = () => {
  return [
    {
      path: '/admin/dashboard',
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.common.assignModules'),
    },
  ];
};
