import FullPageLoader from '../../../UIElements/Loader/fullPageLoader';
import loadable from '@loadable/component';

export const AddEditInterstitialForm = loadable(
    () => import('./interstitial.form'),
    {
        fallback: <FullPageLoader />,
    }
);

export const AddEditTexableForm = loadable(
    () => import('./texable.form'),
    {
        fallback: <FullPageLoader />,
    }
);