import loadable from '@loadable/component';
import FullPageLoader from '../../UIElements/Loader/fullPageLoader';

export const InputFilter = loadable(() => import('./Input.filter'), {
  fallback: <FullPageLoader />,
});
export const SelectFilter = loadable(() => import('./select.filter'), {
  fallback: <FullPageLoader />,
});
export const DateFilter = loadable(() => import('./date.filter'), {
  fallback: <FullPageLoader />,
});
export const AmountFilter = loadable(() => import('./amount.filter'), {
  fallback: <FullPageLoader />,
});
export const MultiSelectFilter = loadable(
  () => import('./select.multi.filter'),
  {
    fallback: <FullPageLoader />,
  }
);

export * from './Input.filter';
export * from './select.filter';
export * from './select.multi.filter';
export * from './input.select.filter';
export * from './price.input.filter';
