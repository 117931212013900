import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CommonButton,
  GlobalLoader,
  ImageElement,
  NoDataFound,
  PageContainerWrapper,
} from '../../../components';
import { getApiData } from '../../../hooks/useGetApi.hook';
import { backToPage, dateFormatter, handlePrint } from '../../../utils';
import { getViewQuoteDetailsService } from '../../../services/index.service';
import { accessRoute } from '../../../routes/ServiceManagement';
import { servicemanagementViewQuoteBreadcrumb } from '../../../services/Columns';
import {
  checkValidCount,
  checkValidData,
  checkValidPrice,
  textFormatter,
} from '../../../components/UIElements/Formatter';
import { useSelector } from 'react-redux';

function ServiceManagementViewQuote() {
  const userType = useSelector((state) => state?.auth?.userData?.Role?.type);
  const breadcrumb = servicemanagementViewQuoteBreadcrumb();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [getViewQuoteData, setGetQuoteData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const redirectUrl = accessRoute.LIST_SERVICE.path;
  const bookingId = params?.id;
  useEffect(() => {
    if (bookingId) {
      getApiData({
        service: getViewQuoteDetailsService,
        setData: setGetQuoteData,
        id: bookingId,
        setIsLoading: setIsLoading,
      });
    }
  }, [bookingId]);

  return (
    <>
      {' '}
      <PageContainerWrapper
        heading={t('text.serviceManagement.viewQuote')}
        breadcrumb={breadcrumb}
        btnArray={['extraButton']}
        extraBtnText={t('text.common.back')}
        extraBtnClass="btn btn-outline-light bg-white"
        onExtraButtonHandleShow={() => backToPage(navigate, redirectUrl)}
      >
        {isLoading ? (
          <GlobalLoader />
        ) : !!getViewQuoteData ? (
          <div className="nk-block" id="invoiceWrapper">
            <div className="card">
              <div className="card-inner">
                <div className="table-responsive">
                  <table style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <td style={{ 'padding-bottom': '10px' }}>
                          <span
                            style={{
                              color: '#364a63',
                              fontWeight: 'bold',
                              display: 'inline-block',
                            }}
                          >
                            {t('text.serviceManagement.yourJob')}
                          </span>{' '}
                          {dateFormatter(
                            getViewQuoteData?.Booking?.bookingScheduleDate,
                            'hh:mm A'
                          )}{' '}
                          {t('text.serviceManagement.on')}{' '}
                          {dateFormatter(
                            getViewQuoteData?.Booking?.bookingScheduleDate,
                            'D MMM  YYYY'
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ 'padding-bottom': '10px' }}>
                          <span
                            style={{
                              color: '#364a63',
                              fontWeight: 'bold',
                              display: 'inline-block',
                            }}
                          >
                            {t('text.common.address')}
                          </span>
                          {getViewQuoteData?.Booking?.jobLocationAddress}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: '100%',
                            background: '#f1f3f9',
                            textAlign: 'left',
                            padding: '10px',
                            'text-align': 'center',
                          }}
                        >
                          <span
                            style={{ color: '#364a63', fontWeight: 'bold' }}
                          >
                            {t('text.common.serviceType')}
                          </span>
                          <br />
                          <span>
                            {checkValidData(
                              getViewQuoteData?.Booking?.ServiceType?.name
                            )}
                            -
                            {checkValidData(
                              getViewQuoteData?.Booking?.ServiceCategory?.name
                            )}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table
                            style={{ width: '100%', 'padding-top': '10px' }}
                          >
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    width: '50%',
                                    'text-align': 'center',
                                    padding: '10px',
                                  }}
                                >
                                  <ImageElement
                                    previewSource={
                                      getViewQuoteData?.Booking?.Provider
                                        ?.profilePictureThumbUrl
                                    }
                                    className="img-fluid logo-img"
                                    alt="logo"
                                    style={{
                                      border: '1px solid #000',
                                      borderRadius: '50%',
                                      padding: '3px',
                                    }}
                                  />
                                  <span
                                    style={{
                                      color: '#364a63',
                                      fontWeight: 'bold',
                                      display: 'block',
                                    }}
                                  >
                                    {t('text.report.provider')}
                                  </span>
                                  <span
                                    style={{
                                      color: '#364a63',
                                      fontWeight: 'bold',
                                      display: 'block',
                                    }}
                                  >
                                    {checkValidData(
                                      `${getViewQuoteData?.Booking?.Provider?.firstName} ${getViewQuoteData?.Booking?.Provider?.lastName}`
                                    )}
                                  </span>
                                  <span>
                                    {checkValidData(
                                      getViewQuoteData?.Booking?.Provider?.email
                                    )}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    width: '50%',
                                    'text-align': 'center',
                                    padding: '10px',
                                  }}
                                >
                                  <ImageElement
                                    previewSource={
                                      getViewQuoteData?.Booking?.Customer
                                        ?.profilePictureThumbUrl
                                    }
                                    className="img-fluid logo-img"
                                    alt="logo"
                                    style={{
                                      border: '1px solid #000',
                                      borderRadius: '50%',
                                      padding: '3px',
                                    }}
                                  />
                                  <span
                                    style={{
                                      color: '#364a63',
                                      fontWeight: 'bold',
                                      display: 'block',
                                    }}
                                  >
                                    {t('text.report.user')}
                                  </span>
                                  <span
                                    style={{
                                      color: '#364a63',
                                      fontWeight: 'bold',
                                      display: 'block',
                                    }}
                                  >
                                    {checkValidData(
                                      `${getViewQuoteData?.Booking?.Customer?.firstName} ${getViewQuoteData?.Booking?.Customer?.lastName}`
                                    )}
                                  </span>
                                  <span>
                                    {checkValidData(
                                      getViewQuoteData?.Booking?.Customer?.email
                                    )}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 className="mt-3" style={{ color: '#364a63' }}>
                            {t('text.serviceManagement.amountDetails')} :{' '}
                            {checkValidData(
                              getViewQuoteData?.Booking?.bookingNo
                            )}
                          </h6>
                          <h6 className="mt-3" style={{ color: '#364a63' }}>
                            {t('text.report.lineItems')}
                          </h6>
                          {getViewQuoteData?.Booking?.BookingLineItems
                            ?.length ? (
                            <table className="w-100">
                              <thead>
                                <tr>
                                  <th
                                    width="850"
                                    style={{
                                      color: '#364a63',
                                      padding: '15px',
                                      background: '#f1f3f9',
                                      textAlign: 'left',
                                    }}
                                  >
                                    {t('text.common.name')}
                                  </th>
                                  <th
                                    style={{
                                      color: '#364a63',
                                      padding: '15px',
                                      background: '#f1f3f9',
                                    }}
                                  >
                                    {t('text.common.description')}
                                  </th>
                                  <th
                                    style={{
                                      color: '#364a63',
                                      padding: '15px',
                                      background: '#f1f3f9',
                                    }}
                                  >
                                    {t('text.dashboard.quantity')}
                                  </th>
                                  <th
                                    style={{
                                      color: '#364a63',
                                      padding: '15px',
                                      background: '#f1f3f9',
                                      textAlign: 'right',
                                    }}
                                  >
                                    {t('text.common.price')}
                                  </th>
                                  <th
                                    style={{
                                      color: '#364a63',
                                      padding: '15px',
                                      background: '#f1f3f9',
                                      textAlign: 'right',
                                    }}
                                  >
                                    {t('text.ratingReview.total')}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {getViewQuoteData?.Booking?.BookingLineItems?.map(
                                  (lineItems, index) => {
                                    return (
                                      <tr>
                                        <td
                                          className="pt-2"
                                          style={{ paddingLeft: '15px' }}
                                        >
                                          {checkValidData(lineItems?.name)}
                                        </td>
                                        <td
                                          className="pt-2"
                                          style={{
                                            paddingLeft: '15px',
                                            color: '#364a63',
                                            verticalAlign: 'top',
                                          }}
                                        >
                                          {checkValidData(
                                            lineItems?.description
                                          )}
                                        </td>
                                        <td
                                          className="pt-2"
                                          style={{
                                            paddingLeft: '15px',
                                            verticalAlign: 'top',
                                          }}
                                        >
                                          {checkValidCount(lineItems?.qty)}
                                        </td>
                                        <td
                                          className="pt-2"
                                          style={{
                                            textAlign: 'right',
                                            paddingLeft: '15px',
                                            verticalAlign: 'top',
                                          }}
                                        >
                                          {checkValidPrice(
                                            lineItems?.unitPrice
                                          )}
                                        </td>
                                        <td
                                          className="pt-2"
                                          style={{
                                            textAlign: 'right',
                                            paddingLeft: '15px',
                                            verticalAlign: 'top',
                                          }}
                                        >
                                          {checkValidPrice(lineItems?.total)}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          ) : (
                            <div>No Line Item Found</div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table className="w-100 mt-2">
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    padding: ' 0 0 10px',
                                    color: '#364a63',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {t('text.common.servicePrice')}
                                </td>
                                <td
                                  style={{
                                    textAlign: 'right',
                                    padding: '0 0 10px',
                                  }}
                                >
                                  {getViewQuoteData?.Booking?.isPaid === 0 &&
                                    !getViewQuoteData?.isSalesQuotes
                                    ? checkValidPrice(
                                      getViewQuoteData?.quoteAmount
                                    )
                                    : checkValidPrice(
                                      getViewQuoteData?.Booking?.serviceAmount
                                    )}
                                </td>
                              </tr>
                              {/* Block For Display Total Cost */}
                              <tr>
                                <td
                                  style={{
                                    padding: ' 0 0 10px',
                                    color: '#364a63',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {t('text.ratingReview.totalCost')}
                                </td>
                                <td
                                  style={{
                                    textAlign: 'right',
                                    padding: '0 0 10px',
                                  }}
                                >
                                  {checkValidPrice(
                                    getViewQuoteData?.Booking?.totalCost
                                  )}
                                </td>
                              </tr>
                              {/* Block For Display Promo Code */}
                              {!(getViewQuoteData?.isSalesQuotes) &&
                                <tr>
                                  <td
                                    style={{
                                      padding: ' 0 0 10px',
                                      color: '#364a63',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {t('text.ratingReview.promoCodeDiscount')}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: 'right',
                                      padding: '0 0 10px',
                                    }}
                                  >
                                    {checkValidPrice(
                                      getViewQuoteData?.Booking?.promoCodeDiscount
                                    )}
                                  </td>
                                </tr>
                              }
                              <tr>
                                <td
                                  style={{
                                    padding: ' 0 0 10px',
                                    color: '#364a63',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {t('text.serviceManagement.subtotal')}
                                </td>
                                <td
                                  style={{
                                    textAlign: 'right',
                                    padding: '0 0 10px',
                                  }}
                                >
                                  {checkValidPrice(getViewQuoteData?.subTotal)}
                                </td>
                              </tr>
                              {/* For diplay membership */}
                              {getViewQuoteData?.Booking &&
                                getViewQuoteData?.Booking
                                  ?.membershipBenefitAvailable && (
                                  <>
                                    <tr>
                                      <td
                                        style={{
                                          padding: ' 0 0 10px',
                                          color: '#364a63',
                                          fontWeight: 'bold',
                                          width: '100%',
                                        }}
                                      >
                                        {t('text.common.memberShipDiscount')}
                                      </td>
                                    </tr>
                                    {getViewQuoteData?.membershipType === 'combo' ?
                                      <>
                                        <tr>
                                          <td
                                            style={{
                                              padding: '0 0 10px 15px',
                                              color: '#364a63',
                                            }}
                                          >
                                            {t('text.common.lineItemDiscount')}
                                          </td>
                                          <td
                                            style={{
                                              textAlign: 'right',
                                              padding: '0 0 10px',
                                            }}
                                          >
                                            {checkValidPrice(
                                              parseFloat(
                                                getViewQuoteData?.Booking?.lineItemMembershipBenefit
                                              )
                                            )}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              padding: '0 0 10px 15px',
                                              color: '#364a63',
                                            }}
                                          >
                                            {getViewQuoteData?.isSalesQuotes === true ? t('text.memberShip.quoteDiscount') : t('text.common.serviceDiscount') }
                                          </td>
                                          <td
                                            style={{
                                              textAlign: 'right',
                                              padding: '0 0 10px',
                                            }}
                                          >
                                            {checkValidPrice(
                                              parseFloat(getViewQuoteData?.Booking?.membershipBenefit)
                                            )}
                                          </td>
                                        </tr>
                                      </>
                                      :
                                      ((getViewQuoteData?.membershipType === 'line_item' || getViewQuoteData?.membershipType === 'service_category') &&
                                        <>
                                          <>
                                            <tr>
                                              <td
                                                style={{
                                                  padding: '0 0 10px 15px',
                                                  color: '#364a63',
                                                }}
                                              >
                                                {getViewQuoteData?.membershipType === 'line_item' ? t('text.common.lineItemDiscount') : t('text.common.serviceDiscount')}
                                              </td>
                                              <td
                                                style={{
                                                  textAlign: 'right',
                                                  padding: '0 0 10px',
                                                }}
                                              >
                                                {getViewQuoteData?.membershipType === 'line_item' ?
                                                  checkValidPrice(
                                                    parseFloat(
                                                      getViewQuoteData?.Booking?.lineItemMembershipBenefit
                                                    )
                                                  ) :
                                                  checkValidPrice(
                                                    parseFloat(getViewQuoteData?.Booking?.membershipBenefit)
                                                  )
                                                }
                                              </td>
                                            </tr>
                                          </>
                                        </>
                                      )
                                    }
                                    {getViewQuoteData?.membershipType === 'quote' && <>
                                      <tr>
                                        <td
                                          style={{
                                            padding: '0 0 10px 15px',
                                            color: '#364a63',
                                          }}
                                        >
                                          {t('text.common.quoteMembershipDiscount')}
                                        </td>
                                        <td
                                          style={{
                                            textAlign: 'right',
                                            padding: '0 0 10px',
                                          }}
                                        >
                                          {checkValidPrice(
                                            parseFloat(getViewQuoteData?.Booking?.membershipBenefit)
                                          )}
                                        </td>
                                      </tr>
                                    </>
                                    }
                                  </>
                                )}
                              <tr>
                                <td
                                  style={{
                                    padding: ' 0 0 10px',
                                    color: '#364a63',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {getViewQuoteData?.isSalesQuotes === true ? t('text.serviceManagement.discountApplied'): t('text.serviceManagement.serviceProviderDiscount')}
                                </td>
                                <td
                                  style={{
                                    textAlign: 'right',
                                    padding: '0 0 10px',
                                  }}
                                >
                                  {checkValidPrice(getViewQuoteData?.discount)}
                                </td>
                              </tr>

                              <tr>
                                <td
                                  style={{
                                    padding: ' 0 0 10px',
                                    color: '#364a63',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {t('text.serviceManagement.commission')}
                                </td>
                                <td
                                  style={{
                                    textAlign: 'right',
                                    padding: '0 0 10px',
                                  }}
                                >
                                  {checkValidPrice(
                                    getViewQuoteData?.Booking?.storeCommission
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    padding: ' 0 0 10px',
                                    color: '#364a63',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {t('text.common.notes')}
                                </td>
                                <td
                                  style={{
                                    textAlign: 'right',
                                    padding: '0 0 10px',
                                  }}
                                >
                                  {checkValidData(
                                    getViewQuoteData?.Booking?.providerNote
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    padding: ' 0 0 10px',
                                    color: '#364a63',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {t('text.serviceManagement.totalFees')}
                                </td>
                                <td
                                  style={{
                                    textAlign: 'right',
                                    padding: '0 0 10px',
                                    fontWeight: 'bold',
                                    color: '#364a63',
                                  }}
                                >
                                  {checkValidPrice(getViewQuoteData?.total)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      {getViewQuoteData && (
                        <tr>
                          <td>
                            <table className="w-100 text-center mt-2">
                              <tbody>
                                <tr>
                                  <td>
                                    {getViewQuoteData?.customerStatus ===
                                      'pending' &&
                                      getViewQuoteData?.providerStatus ===
                                      'pending' ? (
                                      <CommonButton className="btn btn-primary">
                                        {textFormatter('Pending')}
                                      </CommonButton>
                                    ) : getViewQuoteData?.customerStatus ===
                                      'accepted' ||
                                      getViewQuoteData?.providerStatus ===
                                      'accepted' ? (
                                      <CommonButton className="btn btn-primary">
                                        {' '}
                                        {textFormatter('Accepted')}
                                      </CommonButton>
                                    ) : (
                                      <CommonButton className="btn btn-primary">
                                        Declined
                                      </CommonButton>
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="text-center mt-3 mt-lg-4">
              <CommonButton
                className="btn btn-secondary"
                onClick={() => {
                  handlePrint('invoiceWrapper', userType);
                }}
              >
                {t('text.report.print')}
              </CommonButton>
            </div>
          </div >
        ) : (
          <NoDataFound
            extraClass="p-4"
            source="/nodata-found.svg"
            text="Booking not found"
            isCustomer={'/assets/images/admin/nodata-found.svg'}
          />
        )}
      </PageContainerWrapper >
    </>
  );
}

export default ServiceManagementViewQuote;
