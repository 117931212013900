import { MODULE_LIST } from '../../apiEndpoints/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { routesList, getCompletePathList } from '../../routes/index.routes';
import { logger } from '../../utils';

export const getModuleListService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...MODULE_LIST.getModuleList,
      queryParams,
    };

    const res = await APIRequest(payload);
    if (type === 'all') {
      const data = res?.data?.rows.map(e => {
        const name = getCompletePathList().find(ele => {
          return ele?.moduleKey == e?.name;
        })?.name;
        if (name) {
          e.name = name;
        }
        return e;
      })
      return data;
      // return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};
