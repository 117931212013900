import React from 'react';
import { Col } from 'react-bootstrap';
import { Charts } from '../../UIElements/index.element';

export default function BarChart({
  col,
  title,
  type,
  JobsData,
  iconClass,
  options,
  ProviderLogData,
}) {
  return (
    <Col lg={col}>
      <div className="card card-full">
        <div className="card-inner-group">
          <div className="card-inner">
            <div className="card-title-group">
              <div className="card-title">
                <h6 className="title d-flex align-items-center">
                  {' '}
                  <span className="iconView me-2">
                    <em className={`icon bg-primary-dim ${iconClass}`}></em>
                  </span>{' '}
                  {title}
                </h6>
              </div>
            </div>
          </div>
          <div className="card-inner">
            {col === '6' || JobsData ? (
              <Charts type={type} data={JobsData} options={options} />
            ) : (
              ProviderLogData && (
                <div className="card-inner">
                  <div style={{ maxHeight: '450px' }}>
                    <Charts
                      type={type}
                      data={ProviderLogData}
                      options={options}
                    />
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </Col>
  );
}

