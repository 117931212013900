export const text = {
  title: 'Rating & Review',
  home: 'Home',
  ratingRequired: 'The Rating is required.',
  commentRequired: 'The Comment is requried',
  ratingSuccess: 'Current Job is successfully finished. Visit again.',
  invoiceMessage: 'Invoice Message',
  total: 'Total',
  subTotal: 'Subtotal',
  promoCodeDiscount: 'Promo Code Discount',
  bookingDate: 'Booking Date',
  discountApplied: 'Discount Applied',
  getReceipt: 'Get Receipt',
  paymentType: 'Payment Type',
  services: 'Services',
  customerImage: 'Customer Image',
  lineItems: 'Line Items',
  ratingText: 'How Was Your Service',
  rate: 'Rate',
  payNow: 'Pay Now',
  footerText: ' Meet the new Good Guys Service Mobile app',
  noItems: 'No Items',
  noRatingAndReview: 'No Rating & Review Found',
  cost:'Cost:',
  totalCost:'Cost',
  membershipDiscount:'Membership Discount',
  customerPaid:'Customer Paid',
  providerPayAmount:'Provider Pay Amount ',
  promoCodeInfoIconDetails:'Promo code discount only valid for service visits and not valid towards line items and replacement quotes.',
};
