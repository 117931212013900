import React, { useEffect, useState, useRef } from 'react';
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
} from 'react-google-maps';

const ProviderLocation = withScriptjs(
  withGoogleMap(({ providersList, handleProviderId }) => {
    const [location, setLocation] = useState({});
    const [providerLocation, setProviderLocation] = useState([]);
    const mapRef = useRef(null);

    useEffect(() => {
      if (providersList && providersList.length) {
        setProviderLocation(providersList);
        setLocation({
          lat: Number(providersList[0]?.Provider?.workLocationLatitude),
          lng: Number(providersList[0]?.Provider?.workLocationLongitude),
        });
      }
    }, [providersList]);

    const onMarkerClick = (id) => {
      handleProviderId(id);
    };

    return (
      <GoogleMap
        defaultZoom={15}
        defaultCenter={{ ...location }}
        center={location}
        ref={mapRef}
      >
        {providerLocation && providerLocation?.length
          ? providerLocation.map((item, index) => (
              <Marker
                key={index}
                onClick={() => onMarkerClick(item?.id)}
                title={item?.Provider?.workLocation}
                position={{
                  lat: Number(item?.Provider?.workLocationLatitude),
                  lng: Number(item?.Provider?.workLocationLongitude),
                }}
              />
            ))
          : null}
      </GoogleMap>
    );
  })
);

export default ProviderLocation;
