import { Checkbox, Form } from 'antd';
import { useField } from 'formik';

function CustomCheck({
  name,
  label,
  // icon,
  // setFieldValue,
  // onChange,
  onItemChange,
  // checked,
  defaultChecked,
  // isBoolean = true,
  ...rest
}) {
  const [field, meta, helpers] = useField(name);
  const config = { ...field, ...rest };

  if (meta && meta?.touched && meta?.error) {
    config.error = true;
    config.helperText = meta.error;
  }

  const handleChangeSelect = ({ target }) => {
    if (target?.checked) {
      helpers.setValue(1);
    } else {
      helpers.setValue(0);
    }
    if (onItemChange) onItemChange(target?.value);
  };

  return (
    <Form.Item
      name={name}
      help={meta?.error && meta?.touched ? meta.error : ''}
      validateStatus={config?.error ? 'error' : 'success'}
    >
      <Checkbox
        // checked={checked}
        onChange={handleChangeSelect}
        defaultChecked={defaultChecked}
        {...rest}
      />{' '}
      <label className="m-1">
        <span>{label}</span>
      </label>
    </Form.Item>
  );
}

export default CustomCheck;
