import { APIRequest } from '../../helpers/index.helper';
import { PAGE_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { logger } from '../../utils';

export const pageService = {
  getPageService: async ({ queryParams }) => {
    try {
      const payload = {
        ...PAGE_ENDPOINT.getPageList,
        queryParams,
      };
      const res = await APIRequest(payload);
      return res;
    } catch (err) {
      logger(err);
    }
  },
};

export const viewPageService = async (id) => {
  try {
    const payload = {
      ...PAGE_ENDPOINT?.viewPage(id),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const editPageService = async ({ values, id }) => {
  try {
    const payload = {
      ...PAGE_ENDPOINT.editPage(id),
      bodyData: values,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
