import i18next from 'i18next';
import { accessRoute } from '../../routes/Dashboard';

export const financingInformationBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.appText.financingInfoHeading'),
    },
  ];
};
