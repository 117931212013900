export function getPurePermissionObjectFromArray(arr){
    if(Array.isArray(arr)){
        const permissionBucket = {};
        arr.forEach(e=>{
            const {name} = e;
            const moduleArray = name.split(".");
            if(moduleArray.length > 0 && permissionBucket[moduleArray[0]] ){
                const moduleName = moduleArray.shift();
                permissionBucket[moduleName] = {
                    arr:[
                        ...permissionBucket[moduleName].arr, 
                        ...moduleArray
                    ],
                    moduleId:e?.moduleId, 
                    permissionId:e?.id 
                }
                return true;
            }else if(moduleArray.length > 0){
                const moduleName = moduleArray.shift();
                permissionBucket[moduleName] = {
                    arr:[ ...moduleArray], 
                    moduleId:e?.moduleId, 
                    permissionId:e?.id 
                }
            }
            return true;
        })
        return permissionBucket;
    }
    return arr;
}