import React, { useContext, useState } from 'react';
import {
  SweetAlert,
  ImageElement,
  CommonButton,
  ModalComponent,
  GlobalLoader,
  PageContainerWrapper,
} from '../../../../../components';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { decodeQueryData, modalNotification } from '../../../../../utils';
import { Dropdown } from 'react-bootstrap';
import { useEffect } from 'react';
import {
  deleteBusinessPost,
  getBusinessPostDataService,
} from '../../../../../services/GoogleConnect/googleGMB.service';
import { useTranslation } from 'react-i18next';
import GMBPostAdd from '../Create';
import { getActions } from '../../../../../services/index.service';
import { useSelector } from 'react-redux';
import { PermissionContext } from '../../../../../context/permission.context';

function GMBPostNew() {
  const { t } = useTranslation();
  const location = useLocation();
  const params = decodeQueryData(location?.search);
  const { accountId, name } = params;
  const action = useContext(PermissionContext);
  const [loading, setLoading] = useState(false);
  const [postData, setPostData] = useState([]);
  const [nextPageToken, setNextPageToken] = useState('');
  const [postName, setPostName] = useState('');
  const [postDeleteloading, setPostDeleteLoading] = useState(false);
  const userType = useSelector((state) => state?.auth?.userData?.Role?.type);

  const isUserCanEdit =
    (action && action?.arr?.includes(getActions('CREATE').value)) ||
    userType === 'admin';

  async function getBusinessPostData() {
    setLoading(true);
    try {
      let queryParams = {
        name,
        accountId,
        nextPageToken,
      };
      const response = await getBusinessPostDataService({ queryParams });
      const { data, success } = response;
      if (success) {
        setPostData(data);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }

  useEffect(() => {
    getBusinessPostData();
  }, [nextPageToken]);

  async function onBusinessPostDelete() {
    setPostDeleteLoading(true);
    try {
      let bodyData = {
        name: postName,
      };
      const response = await deleteBusinessPost({ bodyData });
      const { message, success } = response;
      if (success) {
        modalNotification({
          type: 'success',
          message,
        });
        getBusinessPostData();
      }
    } catch (err) {
      console.log(err);
    }
    setPostDeleteLoading(false);
  }

  const onConfirmAlert = () => {
    onBusinessPostDelete();
    return true;
  };

  const [createPostModal, setCreatePostModal] = useState(false);

  const createPostModalHide = () => {
    setCreatePostModal(false);
  };
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: t('text.common.dashboard'),
    },
    {
      path: '/admin/google-gmb',
      name: t('text.googleConnect.titleGMB'),
    },
    {
      path: '#',
      name: t('text.googleConnect.googlePostList'),
    },
  ];
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const headerBtn = {
    extraButton: {
      name: t('text.common.back'),
      extraBtnClass: 'btn btn-outline-light bg-white',
    },
    add: {
      name: t('text.googleConnect.createPost'),
      handler: () => {
        setCreatePostModal(true);
      },
    },
  };

  const navigate = useNavigate();
  const backPage = () => {
    navigate('/admin/google-gmb');
  };
  return (
    <div className="wide-md mx-auto reviewSec">
      <div className="nk-block-head nk-block-head-sm">
        {/* <div className="nk-block-between"> */}
        <PageContainerWrapper
          heading={t('text.googleConnect.googleMediaList')}
          breadcrumb={breadcrumb}
          btn={headerBtn}
          extraBtnClass="btn btn-outline-light bg-white"
          onExtraButtonHandleShow={backPage}
        ></PageContainerWrapper>
      </div>

      {loading ? (
        <GlobalLoader />
      ) : (
        <div className="nk-block">
          <div className="card">
            <div className="card-inner">
              <div className="row">
                <div className="col-lg-7 mx-auto">
                  {postData?.localPosts?.length > 0
                    ? postData?.localPosts?.map((item) => (
                        <div className=" card card-bordered card-preview reviewSec_list">
                          {item?.media?.[0]?.googleUrl && (
                            <ImageElement
                              previewSource={
                                item?.media ? item?.media[0]?.googleUrl : '-'
                              }
                              className="img-fluid"
                              alt="google-post-list-photo"
                            />
                          )}
                          <div className="card-inner p-3">
                            <p className="card-text">{item?.summary}</p>
                          </div>
                          {item?.callToAction?.url ? (
                            <div className="card-footer border-top d-flex justify-content-between">
                              <a
                                href={item?.callToAction?.url}
                                target="_blank"
                                className="btn btn-primary"
                                rel="noreferrer"
                              >
                                {t('text.googleConnect.learnMore')}
                              </a>
                              {isUserCanEdit && (
                                <Dropdown className="position-static">
                                  <Dropdown.Toggle
                                    as="a"
                                    className="btn btn-icon btn-trigger"
                                  >
                                    <em className="icon ni ni-more-v" />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu
                                    align="end"
                                    size="sm"
                                    className="wide-xs"
                                  >
                                    <ul className="link-list-plain">
                                      <li>
                                        <Link
                                          to="#"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setIsAlertVisible(true);
                                            setPostName(item?.name);
                                          }}
                                        >
                                          {t('text.common.delete')}
                                        </Link>
                                      </li>
                                    </ul>
                                  </Dropdown.Menu>
                                </Dropdown>
                              )}
                            </div>
                          ) : (
                            <div className="card-footer border-top d-flex justify-content-end">
                              <Dropdown className="position-static">
                                <Dropdown.Toggle
                                  as="a"
                                  className="btn btn-icon btn-trigger"
                                >
                                  <em className="icon ni ni-more-v" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  align="end"
                                  size="sm"
                                  className="wide-xs"
                                >
                                  <ul className="link-list-plain">
                                    <li>
                                      <Link
                                        to="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setIsAlertVisible(true);
                                          setPostName(item?.name);
                                        }}
                                      >
                                        {t('text.common.delete')}
                                      </Link>
                                    </li>
                                  </ul>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          )}
                        </div>
                      ))
                    : t('text.common.NoDataFound')}
                  {postData?.nextPageToken && (
                    <CommonButton
                      className="btn btn-primary float-end"
                      onClick={() => setNextPageToken(postData?.nextPageToken)}
                    >
                      {t('text.googleConnect.next')}
                    </CommonButton>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <SweetAlert
        title={t('text.common.areYouSure')}
        text={t('text.googleConnect.onPostDeleteConfirm')}
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText={t('text.common.yes')}
        cancelButtonText={t('text.common.no')}
        setIsAlertVisible={setIsAlertVisible}
        // showLoaderOnConfirm
        loading={postDeleteloading}
        onConfirmAlert={onConfirmAlert}
      />

      {/* CreatePost Modal */}
      <ModalComponent
        backdrop
        show={createPostModal}
        onHandleCancel={createPostModalHide}
        title={t('text.googleConnect.createPost')}
        extraClassName="modal-md"
      >
        <GMBPostAdd
          createPostModalHide={createPostModalHide}
          getBusinessPostData={getBusinessPostData}
        />
      </ModalComponent>
    </div>
  );
}

export default GMBPostNew;
