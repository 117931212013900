export const text = {
  serviceProviderDetails: 'Service Provider Details / Profile',
  time: 'Select Time',
  youHaveJust:
    ' You must be at least 18 years of age to use this Mobile Application. By using this Mobile Application and by agreeing to this Agreement you warrant and represent that you are at least 18 years of age. If you create an account in the Mobile Application, you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it. Providing false contact information of any kind may result in the termination of your account.',
  youHaveMust:
    'You must immediately notify us of any unauthorized uses of your account or any other breaches of security. We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions.',
  bookNow: 'Book Now',
  apply: 'APPLY',
  promoCode: 'Promo Code',
  addInstruction: 'Add Special Instruction For Provider Below',
  confirmBooking: 'Confirm Booking',
  continue: 'CONTINUE',
  serviceProviderDetail: 'Service Provider Detail',
  chooseBooking: 'Choose Booking Date & Time',
  bookingDetails: 'Booking Details',
  bookingRequested: 'Booking Requested',
  yourProviderHasReceived:
    " Your provider has received the booking request and will get back to you shortly. You can check out the status of your request on the 'Your Bookings' menu item.",
  viewBooking: 'VIEW BOOKING',
  searchServiceProvider: 'Search Service Provider',
  contactTech: 'Contact Tech',
  moreInfo: 'More Info',
  serviceDetail: 'Service Provider Detail',
  promoCodeApplied: 'Promo Code applied!',
  service: 'Service',
  quantity: 'Quantity',
  provider: 'Provider',
  bookingDate: 'Booking Date',
  bookingTime: 'Booking Time',
  serviceCost: 'Service Cost',
  totalCost: 'Total Cost',
  message: 'Message',
  subject: 'Subject',
  serviceProviderContactUs: 'Service Provider / Contact Us',
  serviceProviderList: 'Service Provider List',
  noOngoingBooking: 'No Ongoing Booking',
  paidSchedule: 'Paid Schedule',
  companyName: 'Company Name',
  bookingNow: 'Booking Now',
  searchByNamePhone: 'Search by name or phone number',
};
