import { getActions } from '../../services/index.service';
import CommonInvoicePage from '../../features/InvoicePage';

export default function routes() {
  return [
    {
      private: true,
      path: accessRoute.INVOICE.path,
      name: 'Invoice',
      key: 'report.list',
      belongsToSidebar: false,
      element: <CommonInvoicePage />,
      ...accessRoute.INVOICE,
    },
  ];
}

export const accessRoute = {
  INVOICE: {
    path: '/admin/:viewInvoice/:routePath/:id',
    action: getActions('LIST').value,
    moduleKey: 'payment-report',
    key: 'payment-report',
    name: 'Edit Line Items',
  },
};

export const getChildrenRoutes = () => {
  return routes();
};
