import i18next from 'i18next';
import * as yup from 'yup';
import { Regex } from '../../../helpers/regex.helper';
import { valueMapper } from '../../../helpers/schema.helper';

export const initValues = (userData) => {
  const initVal = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    countryPhoneCode: '+1',
    profilePicture: '',
    promoCode: '',
  };
  return valueMapper(initVal, userData);
};
export const validation = () =>
  // e, id
  {
    let sche = {
      firstName: yup
        .string()
        .trim()
        .max(30, i18next.t('validation.common.maxNameValid'))
        .min(2, i18next.t('validation.common.minFirstName'))
        .nullable()
        .required(i18next.t('validation.common.firstName')),
      lastName: yup
        .string()
        .trim()
        .max(30, i18next.t('validation.common.maxNameValid'))
        .min(2, i18next.t('validation.common.minLastName'))
        .nullable()
        .required(i18next.t('validation.common.lastName'))
        .typeError(i18next.t('validation.common.lastName')),
      email: yup
        .string()
        .email(i18next.t('validation.common.validEmail'))
        .trim()
        .max(49, i18next.t('validation.common.emailMax'))
        .nullable()
        .required(i18next.t('validation.common.email')),
      phoneNumber: yup
        .string()
        .nullable()
        .required(i18next.t('validation.common.phone'))
        .matches(Regex.phoneNumber, {
          message: i18next.t('validation.common.phoneValid'),
          excludeEmptyString: false,
        }),
    };

    return yup.object().shape(sche);
  };

export const userAddressInitValues = (data) => {
  return {
    userId: '',
    name: '',
    suiteNumber: '',
    cityId: null,
    stateId: null,
    countryId: null,
    address: '',
    landmark: '',
    zipCode: '',
    ...data,
  };
};

export const userAddressValidation = () => {
  return yup.object().shape({
    address: yup
      .string()
      .trim()
      .max(99, i18next.t('validation.common.maxAddress'))
      .nullable()
      .required(i18next.t('validation.common.address')),
    landmark: yup
      .string()
      .trim()
      .nullable()
      .max(99, i18next.t('validation.common.maxAddress')),
    suiteNumber: yup
      .string()
      .trim()
      .nullable()
      .max(49, i18next.t('validation.common.suiteNumber')),
    name: yup
      .string()
      .trim()
      .nullable()
      .max(99, i18next.t('validation.common.maxName')),
    countryId: yup
      .string()
      .typeError(i18next.t('validation.common.country'))
      .nullable()
      .required(i18next.t('validation.common.country')),
    stateId: yup
      .string()
      .typeError(i18next.t('validation.common.state'))
      .nullable()
      .required(i18next.t('validation.common.state')),
    cityId: yup
      .string()
      .typeError(i18next.t('validation.common.city'))
      .nullable()
      .required(i18next.t('validation.common.city')),
    zipCode: yup
      .string()
      .nullable()
      .matches(Regex.zipCodeReg, {
        message: i18next.t('validation.common.invalidZip'),
        excludeEmptyString: false,
      }),
  });
};

export const initChangePasswordValues = () => {
  return {
    currentPassword: '',
    confirmPassword: '',
    newPassword: '',
  };
};

export const changeValidation = () => {
  let sche = {
    currentPassword: yup
      .string()
      .trim()
      .max(30, i18next.t('validation.common.maxNameValid'))
      .min(6, i18next.t('validation.common.minValidPassword'))
      .nullable()
      .required(i18next.t('validation.common.oldPasswordReqV2')),
    newPassword: yup
      .string()
      .trim()
      .max(30, i18next.t('validation.common.maxNameValid'))
      .min(6, i18next.t('validation.common.minValidPassword'))
      .matches(Regex.password, i18next.t('validation.signup.strongPassword'))
      .nullable()
      .required(i18next.t('validation.common.newPasswordReq')),
    confirmPassword: yup
      .string()
      .trim()
      .max(30, i18next.t('validation.common.maxNameValid'))
      .oneOf(
        [yup.ref('newPassword'), null],
        i18next.t('validation.common.confirmPasswordSame')
      )
      .nullable()
      .required(i18next.t('validation.common.confirmPasswordReq')),
  };

  return yup.object().shape(sche);
};

export const paymentInitialValues = () => {
  return {
    name: '',
    cardNumber: '',
    expMonth: undefined,
    expYear: undefined,
    cvv: '',
  };
};

export const paymentValidation = () => {
  return yup.object().shape({
    name: yup
      .string()
      .trim()
      .nullable()
      .required(i18next.t('validation.common.cardHolder')),
    cardNumber: yup
      .string()
      .trim()
      .matches(Regex.cardNumber, {
        message: i18next.t('validation.signup.enterValidNumber'),
      })
      .nullable()
      .required(i18next.t('validation.signup.cardNumber')),
    expMonth: yup
      .string()
      .nullable()
      .required(i18next.t('validation.report.expMonth')),
    expYear: yup
      .string()
      .nullable()
      .required(i18next.t('validation.report.expYear')),
    cvv: yup
      .string()
      .trim()
      .matches(Regex.cvv, {
        message: i18next.t('validation.signup.enterValidNumber'),
      })
      .nullable()
      .required(i18next.t('validation.signup.cvv')),
  });
};

// export const paymentValidation = () => {
//   return yup.object().shape({
//     cardHolderName: yup.string().when('selectedCard', {
//       is: (val) => val === 'third_card',
//       then: yup.string().required(i18next.t('validation.report.cardHolder')),
//       otherwise: yup.string().nullable(),
//     }),
//     cardNumber: yup.string().when('selectedCard', {
//       is: (val) => val === 'third_card',
//       then: yup.string().required(i18next.t('validation.report.cardNumber')),
//       otherwise: yup.string().nullable(),
//     }),
//     expiryDate: yup.date().when('selectedCard', {
//       is: (val) => val === 'third_card',
//       then: yup
//         .date()
//         .typeError(i18next.t('Please enter date is required'))
//         .required(i18next.t('validation.register.accountNumber')),
//       otherwise: yup.date().nullable(),
//     }),
//     cvvNumber: yup.string().when('selectedCard', {
//       is: (val) => val === 'third_card',
//       then: yup.string().required(i18next.t('validation.report.cvv')),
//       otherwise: yup.string().nullable(),
//     }),
//   });
// };

export const contactValidation = () => {
  return yup.object().shape({
    subject: yup
      .string()
      .trim()
      .max(30, i18next.t('validation.contactUs.subjectMax'))
      .nullable()
      .required(i18next.t('validation.contactUs.subjectRequired')),
    message: yup
      .string()
      .trim()
      .max(500, i18next.t('validation.contactUs.messageMax'))
      .nullable()
      .required(i18next.t('validation.contactUs.messageRequired')),
  });
};
