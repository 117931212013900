export const text = {
  title: 'Service Management',
  booking: 'Booking No',
  location: 'Location',
  jobDate: 'Job Date',
  jobAmount: 'Job Amount',
  customerTag: 'Customer Tag',
  bookingLog: 'Booking Log',
  jobTag: 'Job Tag',
  type: 'Type',
  quote: 'Quote Details',
  jobStatus: 'Job Status',
  sendQuote: 'Send Quote',
  jobComplaint: 'Job Completion Time',
  viewInvoice: 'View Invoice',
  viewQuote: ' View Quote',
  totalSpent: 'Customer Total Spent',
  collected: 'COLLECTED',
  uncollected: 'UNCOLLECTED',
  yourJob: 'Your Job',
  on: 'on',
  amountDetails: 'Amount Details For Job No',
  subtotal: 'Subtotal',
  discountByProvider: ' Discount(By Provider)',
  commission: 'Commission',
  totalFees: 'Total Fees',
  returnedAmount: 'Returned Amount',
  sublaborFee: ' Sublabor Fee',
  purchaseOrder: ' Purchase Order',
  discountApplied: 'Discount applied',
  customerTotalSpent: 'Customer Total Spent',
  customerPaid: 'Customer Paid',
  serviceProviderDiscount:'Service Provider Discount',
};
