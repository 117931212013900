export const validation = {
  categoryName: 'Please enter category name',
  maxValid: 'Please enter less than 100 characters.',
  categoryValidName: 'Please enter valid category name',
  subCategoryValidName: 'Please enter valid sub category name',
  subCategory: 'Please enter sub category name',
  validity: 'Validity is required',
  image1: 'Product image 1 is required',
  image2: 'Product image 2 is required',
  image3: 'Product image 3 is required',
  productNameLimit: 'Product name length should be between 3 to 200',
  validProductName: 'Please enter valid Product name',
  productName: 'Product name is required',
  category: 'Product category is required',
  quantity: 'Quantity is required',
  validNumber: 'Please enter the valid number',
  price: 'Regular Price is required',
  priceGreater: 'Regular Price must be greater than 0',
  productSKU: 'Product SKU is required',
  description: 'Product description is required',
  productVariant: 'Product Variant is required',
  sellingPrice: 'Selling Price is required',
  variantName: 'variant name is required',
  validName: 'Please enter valid name',
  variantOption: 'variant option is required',
  regularPriceMustBeNumber: 'Regular Price must be number',
  sellingPriceMustBeNumber: 'Sale Price must be number',
  quantityNo: 'Quantity must be number',
  couponCodeRe: 'Coupon Code is required',
  priceGreaterSelling: 'Sale price is greater then zero',
  sellingPriceLessRegular: 'Sale price must be less then regular price',
  descriptionMinLength: 'Description must be greater than three characters ',
  descriptionMaxLength:
    " Description shouldn't be greater than 500 characters.",
  priceLessThan500000: 'Regular Price must be less than 100000',
  productQuantity: 'Product Quantity length should be between 1 to 100',
  priceMustBePositive: 'Price must me be positive',
  selectedCouponCode: 'Coupon code name is required',
  selectOneCouponCode: 'Please select at least one coupon',
  maxFlatDiscountValid: 'Discount should not be greater than 10000',
  atLeastOneCategory:"Please select at least one category"
};
