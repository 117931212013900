export const text = {
  title: 'Voice AI Records',
  enterNote: 'Enter note',
  addNote: 'Add Note',
  updateNote: 'Update Note',
  editNote: 'Edit Note',
  requestChannel: 'Request Channel',
  newCustomer: 'New Customer',
  streetNo: 'Street No.',
  streetName: 'Street Name',
  serviceSubCategory: 'Service Sub Category',
  serviceDate: 'Service Date',
  adminNote: 'Admin Note',
  adminStatus: 'Admin Status',
  requestDate: 'Request Date',
  note: 'Note',
  userName: 'user name',
  suiteNumber: 'Suite No.',
  transcript: 'Transcript',
  download: 'Download',
  view: 'View',
};
