import TermConditions from '../../views/CustomerApp/TermConditions';
import AboutUs from '../../views/CustomerApp/AboutUs';
import HowItWorks from '../../views/CustomerApp/HowItWorks';
import PrivacyPolicy from '../../views/CustomerApp/PrivacyPolicy';
import FAQs from '../../views/CustomerApp/FAQs';
import ContactUs from '../../views/CustomerApp/ContactUs';
import { CustomerHome, PayOnlineGuest } from '../../views/CustomerApp';
import GuestNewQuote from '../../views/GuestNewQuote';

export default function route() {
  return [
    {
      path: accessRoute.TERMS.path,
      private: false,
      name: accessRoute.TERMS.name,
      key: accessRoute.TERMS.key,
      belongsToSidebar: false,
      icon: accessRoute.TERMS.icon,
      element: <TermConditions />,
    },
    {
      path: accessRoute.HOW_IT_WORK.path,
      private: false,
      name: accessRoute.HOW_IT_WORK.name,
      key: accessRoute.HOW_IT_WORK.key,
      belongsToSidebar: false,
      icon: accessRoute.HOW_IT_WORK.icon,
      element: <HowItWorks />,
    },
    {
      path: accessRoute.PRIVACY.path,
      private: false,
      name: accessRoute.PRIVACY.name,
      key: accessRoute.PRIVACY.key,
      belongsToSidebar: false,
      icon: accessRoute.PRIVACY.icon,
      element: <PrivacyPolicy />,
    },
    {
      path: accessRoute.ABOUT_US.path,
      private: false,
      name: accessRoute.ABOUT_US.name,
      key: accessRoute.ABOUT_US.key,
      belongsToSidebar: false,
      icon: accessRoute.ABOUT_US.icon,
      element: <AboutUs />,
    },
    {
      path: accessRoute.FAQS.path,
      private: false,
      name: accessRoute.FAQS.name,
      key: accessRoute.FAQS.key,
      belongsToSidebar: false,
      icon: accessRoute.FAQS.icon,
      element: <FAQs />,
    },
    {
      path: accessRoute.CONTACT_US.path,
      private: false,
      name: accessRoute.CONTACT_US.name,
      key: accessRoute.CONTACT_US.key,
      belongsToSidebar: false,
      icon: accessRoute.CONTACT_US.icon,
      element: <ContactUs />,
    },
    {
      path: accessRoute.HOME.path,
      private: false,
      name: accessRoute.HOME.name,
      key: accessRoute.HOME.key,
      belongsToSidebar: false,
      icon: accessRoute.HOME.icon,
      element: <CustomerHome />,
    },
    {
      path: accessRoute.PAY_ONLINE_GUEST.path,
      private: true,
      name: accessRoute.PAY_ONLINE_GUEST.name,
      key: accessRoute.PAY_ONLINE_GUEST.key,
      belongsToSidebar: false,

      element: <PayOnlineGuest />,
      ...accessRoute.PAY_ONLINE_GUEST,
    },

    {
      path: accessRoute.GUEST_NEW_QUOTE.path,
      private: false,
      name: 'Guest New Quote',
      key: accessRoute.GUEST_NEW_QUOTE.key,
      belongsToSidebar: false,
      icon: accessRoute.GUEST_NEW_QUOTE.icon,
      element: <GuestNewQuote />,
    },
  ];
}

export const accessRoute = {
  TERMS: {
    path: '/terms-conditions',
    name: 'Terms and Conditions',
    key: 'terms',
    for: 'customer',
    icon: <em className="gg-financing"></em>,
  },
  HOW_IT_WORK: {
    path: 'how-it-works',
    name: 'How it Works',
    key: 'terms',
    for: 'customer',
    icon: <em className="gg-financing"></em>,
  },
  PRIVACY: {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    key: 'terms',
    for: 'customer',
    icon: <em className="gg-financing"></em>,
  },
  ABOUT_US: {
    path: '/about-us',
    name: 'About Us',
    key: 'terms',
    for: 'customer',
    icon: <em className="gg-financing"></em>,
  },
  FAQS: {
    path: '/faq',
    name: 'FAQs',
    key: 'faqs',
    for: 'customer',
    icon: <em className="gg-financing"></em>,
  },
  CONTACT_US: {
    path: '/contact-us',
    name: 'Contact-Us',
    key: 'terms',
    for: 'customer',
    icon: <em className="gg-financing"></em>,
  },
  HOME: {
    path: '/',
    name: 'home',
    key: 'terms',
    for: 'customer',
    icon: <em className="gg-financing"></em>,
  },

  PAY_ONLINE_GUEST: {
    path: '/customer/pay-online-guest/:trip',
    name: 'Pay online guest',
    key: 'payOnlineGuest',
    // action: getActions('LIST').value,
  },

  GUEST_NEW_QUOTE: {
    path: '/customer/guest-new-quote/:bookingId',
    name: 'Guest new quote',
    key: 'terms',
    for: 'customer',
    icon: <em className="gg-financing"></em>,
  },
};

export const getChildrenRoutes = () => {
  return route();
};
