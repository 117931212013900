import i18next from 'i18next';
import { accessRoute } from '../../routes/Dashboard';
import {
  getInputSearchFilter,
  getMultiSelectSearchFilter,
  // InputFilter,
  // SelectFilter,
} from '../../components';
// import { FilterFilled } from '@ant-design/icons';
import { getTupleStatusList } from '../../services/index.service';
import {
  checkValidData,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';

// const getColumnSearchProps = (dataIndex) => ({
//   filterDropdown: (props) => <InputFilter dataIndex={dataIndex} {...props} />,
//   filterIcon: (filtered) => (
//     <FilterFilled
//       type="search"
//       style={{ color: filtered ? '#1890ff' : undefined }}
//     />
//   ),
//   onFilterDropdownVisibleChange: (visible) => {
//     // if (visible) {
//     //   setTimeout(() => this.searchInput.select());
//     // }
//   },
// });

// const getColumnSelectSearchProps = (dataIndex, list) => ({
//   filterDropdown: (props) => (
//     <SelectFilter list={list} dataIndex={dataIndex} {...props} />
//   ),
//   filterIcon: (filtered) => (
//     <FilterFilled
//       type="search"
//       style={{ color: filtered ? '#1890ff' : undefined }}
//     />
//   ),
//   onFilterDropdownVisibleChange: (visible) => {
//     // if (visible) {
//     //   setTimeout(() => this.searchInput.select());
//     // }
//   },
// });

export const cityTableColumn = ({
  onFilterChange,
  filter,
  Action,
  Toggle,
  onSort,
  page,
  sizePerPage,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'countryName',
      title: i18next.t('text.localization.country'),
      headerClasses: 'sorting',
      render: (cell, row) => checkValidData(row?.Country?.name),
      exportKey: 'Country',
      exportFormatter: (col, row) => checkValidData(row?.Country?.name),

      sorter: true,
      ...getInputSearchFilter({
        dataIndex: 'countryName',
        placeholder: 'country name',
        onFilterChange,
        filter,
      }),
      onSort: (field, order) => onSort('countryName', order),
    },
    {
      dataIndex: 'stateName',
      title: i18next.t('text.localization.state'),
      headerClasses: 'sorting',
      sorter: true,
      ...getInputSearchFilter({
        dataIndex: 'stateName',
        placeholder: 'state name',
        onFilterChange,
        filter,
      }),
      render: (cell, row) => checkValidData(row?.State?.name),
      exportKey: 'State',
      exportFormatter: (col, row) => checkValidData(row?.State?.name),

      onSort: (field, order) => onSort('stateName', order),
    },
    {
      dataIndex: 'name',
      filterDataIndex: ['cityName'],
      title: i18next.t('text.localization.city'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => checkValidData(row?.name),
      onSort: (field, order) => onSort('name', order),
      ...getInputSearchFilter({
        dataIndex: 'cityName',
        placeholder: 'city name',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'status',
      title: 'Status',
      headerClasses: 'sorting',
      render: (cell, row) => <Toggle row={row} cell={cell} />,
      sorter: true,
      ...getMultiSelectSearchFilter({
        dataIndex: 'status',
        onFilterChange,
        filter,
        name: 'status',
        listService: getTupleStatusList,
        mode: false,
      }),
      onSort: (field, order) => onSort('status', order),
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.localization.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => {
        return <Action row={row} cell={cell} />;
      },
    },
  ];
};

export const cityBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.localization.city'),
    },
  ];
};
