export const validation = {
  titleRequired: 'Title is required',
  costRequired: 'Cost is required',
  displayOrder: 'Display order is required',
  description: 'Description order is required',

  bankRequired: 'Bank name is required',
  bankUrlRequired: 'Bank url is required',

  financeTitleReq: 'Finance title is required',
  termInMonthReq: 'Terms in month is required',
  minAmountReq: 'Minimum amount is required',
  paymentFactorReq: 'Payment factor is required',
  dealerFeeReq: 'Dealer fee is required',

  discountTitle: 'Discount title is required',
  discountType: 'Discount type is required',
  minJobAmountRequired: 'Min job amount is required',
  quoteOrderRequired: 'Quote order is required',
  quoteTypeRequired: 'Quote type is required',
  costValid: 'Cost must be number',
  providerQuoteOrderRequired: 'Provider quote order is required',
  laborCostRequired: 'Labor cost is required',
  salesProfitRequired: 'Sales profit is required',
  profitRequired: 'Profit type is required',
  adjustmentStatusRequired: 'Adjustment is required',
  quoteType: 'Quote type is required',

  locationNameReq: 'Location name is required',
  stateTaxReq: 'State tax is required',
  salesTaxReq: 'Sales tax is required',
  locationProfitReq: 'Location profit is required',

  rebateTitleReq: 'Rebate title is required',
  minCertifiedReq: 'Min certified rating is required',
  maxCertifiedReq: 'Max certified rating is required',
  desc500: 'Description should not greater than 500',

  rebateTypeReq: 'Rebate type is required',
  basePath: 'Upload bulk data is required',
  bulkUploadImage: 'Upload bulk images is required',
  costReq: 'Cost is required',

  equipmentNameReq: 'Equipment name is required',
  equipmentLocationReq: 'Equipment Location is required',
  equipmentEmailReq: 'Equipment Email is required',
  geoLocation: 'Geo Fence Location Served is required',
  officeNumber: 'Office Number is required',
  smsTextNumber: 'SMS Text Number is required',
  emailContact: 'Email Contact is required',
  showRolodex: 'Show on Rolodex',
  showEquip: 'Show In Equipment Order Location And Truck Stock Ordering',
  notes: 'Equipment Notes is required',
  sameNumberCheckbox: 'Use same number for SMS number also',
  showEquipmentLocation:
    'Show in Equipment Order Location And Truck Stock Ordering',
  quoteTypeReq: 'Quote type is required',
  maxQuoteType: 'Quote type should not be more than 14',
  negativeLocationProfit: 'Location Profit should not be negative',
  maxLocationProfit: 'Location profit should not be greater than 100 %',
  minCertIsGreaterThanMaxCert:
    'Min certified rating must be less than or equal to max certified rating',
  memberShipType: 'Member ship type is required',
};
