import { getActions } from '../../services/Auth/index.service';
import { ListTruckOrdering } from '../../views/TruckOrdering';

export default function route() {
  return [
    {
      path: accessRoute.TRUCK_ORDERING.path,
      private: true,
      action: accessRoute.TRUCK_ORDERING.action,
      key: accessRoute.TRUCK_ORDERING.key,
      belongsToSidebar: true,
      submenu: false,
      icon: accessRoute.TRUCK_ORDERING.icon,
      element: <ListTruckOrdering />,
      ...accessRoute.TRUCK_ORDERING,
    },
  ];
}

export const accessRoute = {
  TRUCK_ORDERING: {
    path: '/admin/truck-stock-ordering',
    action: getActions('LIST').value,
    moduleKey: 'truck-stock-ordering',
    key: 'truck-stock-ordering',
    name: 'PO/Truck Stock',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-truck" />
      </span>
    ),
    order: 51,
  },
};

export const getChildrenRoutes = () => {
  return route();
};
