import { APIRequest } from '../../helpers/index.helper';
import { NEW_EQUIPMENT_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { logger } from '../../utils';

export const NewEquipmentListService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...NEW_EQUIPMENT_ENDPOINT?.getNewEquipmentList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const putUpdateEquipmentStatusService = async ({ bodyData, id }) => {
  try {
    const payload = {
      ...NEW_EQUIPMENT_ENDPOINT?.putUpdateEquipmentStatus(id),
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
