import React, { useContext, useEffect, useState } from 'react';
import { CommonButton, CustomTable } from '../../../../../components';
import useTable from '../../../../../hooks/useTable.hook';
import {
  Columns,
  deleteSubLaborService,
  getActions,
  getCreateSubLabour,
  getDataOfSubLabour,
} from '../../../../../services/index.service';
import { Form } from 'antd';
import { logger, modalNotification } from '../../../../../utils';
import { t } from 'i18next';
import { PermissionContext } from '../../../../../context/permission.context';
import { useSelector } from 'react-redux';

function PurchaseOrder({
  providerDiscountData,
  setIsOpenModal,
  getReportData,
}) {
  const action = useContext(PermissionContext);
  const userType = useSelector((state) => state?.auth?.userData?.Role?.type);
  const isUserCanEdit =
    (action && action?.arr?.includes(getActions('CREATE').value)) ||
    userType === 'admin';

  const [form] = Form.useForm();
  const [state, setState] = useState({
    loader: null,
    modal: null,
    modalId: null,
    selectedRows: [],
  });
  const [checkBoxID, setCheckBoxId] = useState([]);
  const [checkBoxIDs, setCheckBoxIds] = useState([]);
  const [bookingId, setBookingId] = useState();
  const [value, setValue] = useState([]);
  const [valueTotal, setValueTotal] = useState();
  let extraQuery = {
    laborType: 'purchase',
    bookingId: providerDiscountData?.BookingDetail?.bookingId,
  };
  const [searchData, setSearchData] = useState('');
  const [tableHandler, tableProps] = useTable(getDataOfSubLabour, extraQuery);
  const { data, isLoading, count } = tableProps;
  const [reportData, setReportData] = useState([]);
  const { onFilterChange, onTableSort, onSearch } = tableHandler;
  const [tableLoading, setTableLoading] = useState(false);
  const { selectedRows } = state;
  const [loading, setLoading] = useState(false);
  const [addNewData, setAddNewData] = useState([]);
  const [firstTimeFetch, setFirstTimeFetch] = useState(false);
  const [page, setPage] = useState({
    currentPage: 1,
    sizePerPage: 10,
  });
  const [newAddRowIds, setNewAddRowIds] = useState([]);

  const defaultData = [
    {
      rowID: 1,
      id: 1,
      Booking: {},
      bookingId: bookingId,
      // labor: null,
      name: '',
      providerUserId: null,
      totalAmount: '',
      type: 'purchase',
    },
  ];
  const columns = Columns.purchaseOrderColumn({
    onSort: onTableSort,
    onFilterChange: onFilterChange,
    selectedRows: checkBoxID,
    page: page.currentPage,
    sizePerPage: page.sizePerPage,
    setValue,
    value,
    setValueTotal,
    valueTotal,
    form,
    selectRow: state?.selectedRows,
    providerDiscountData,
    checkBoxIDs,
  });
  function onRowSelect(e) {
    try {
      setState({ ...state, selectedRows: e?.sort((a, b) => a - b) });
    } catch (err) {
      console.log('err', err);
    }
  }
  let checkSelectValue = true;
  async function handleActionSelect(e) {
    setCheckBoxId(selectedRows);
    if (e.target.value === 'edit') {
      setCheckBoxIds(selectedRows);
    }
    try {
      addNewData &&
        addNewData?.filter((item) => {
          if (state?.selectedRows.includes(item?.id)) {
            checkSelectValue = false;
          } else {
            checkSelectValue = true;
          }
        });
      data?.filter((item) => {
        if (state?.selectedRows.includes(item?.id)) {
          checkSelectValue = true;
        }
      });

      if (
        newAddRowIds.some((newAddRowId) =>
          state.selectedRows.includes(newAddRowId)
        ) &&
        e.target.value === 'delete'
      ) {
        setNewAddRowIds(
          newAddRowIds.filter(
            (newAddRowId) => !state.selectedRows.includes(newAddRowId)
          )
        );
      }

      if (e.target.value === 'delete') {
        if (window.confirm(t('text.common.deleteSelectedRow'))) {
          if (checkSelectValue && data?.length > 0) {
            setTableLoading(true);
            let bodyData = {
              id: state?.selectedRows,
            };
            const response = await deleteSubLaborService({ bodyData });
            if (response?.success) {
              setTableLoading(false);
              setIsOpenModal(false);
              getReportData();
            }
          } else {
            const array = reportData.filter(
              (val) => !state?.selectedRows.includes(val?.id)
            );
            const array1 = addNewData.filter(
              (val) => !state?.selectedRows.includes(val?.id)
            );
            const array2 = state?.selectedRows.filter(
              (val) => !state?.selectedRows.includes(val)
            );

            setState({
              ...state,
              selectedRows: array2,
            });

            setReportData(array);
            setAddNewData(array1);
            setPage({
              currentPage: Math.ceil(array.length / page.sizePerPage),
              sizePerPage: 10,
            });
          }
        }
      }
    } catch (err) {
      console.log(err);
      setTableLoading(false);
    }
  }

  async function AddReportData(addRow) {
    if (!reportData.length) {
      setPage({
        ...page,
        currentPage: 1,
      });
    }
    setNewAddRowIds([
      ...new Set([
        ...newAddRowIds,
        (reportData?.[reportData?.length - 1]?.id
          ? reportData?.[reportData?.length - 1]?.id
          : 0) + 1,
      ]),
    ]);
    let newData = {
      rowID: reportData?.length + 1,
      id:
        (reportData?.[reportData?.length - 1]?.id
          ? reportData?.[reportData?.length - 1]?.id
          : 0) + 1,
      // id: Math.floor(Math.random() * 10) * 1,
      Booking: addRow[0]?.Booking,
      jobNumber: addRow[0]?.Booking.bookingNo,
      name: '',
      totalAmount: '',
      labor: null,
      // labor: {
      //   firstName: addRow[addRow.length - 1].labor
      //     ? addRow[addRow.length - 1].labor.firstName
      //     : '',
      //   lastName: addRow[addRow.length - 1].labor
      //     ? addRow[addRow.length - 1].labor.lastName
      //     : '',
      // },
      // providerUserId: addRow[0]?.Booking?.providerUserId,
      type: 'purchase',
    };
    setReportData([...reportData, newData]);
    setAddNewData([...addNewData, newData]);
  }

  let rowID = '1';
  let checkSelected = true;
  let newRowData = true;
  const onFinish = async (value) => {
    addNewData &&
      addNewData?.forEach((item) => {
        checkSelected = state?.selectedRows.includes(item?.id);
        if (checkSelected) {
          return false;
        }
      });

    if (data?.length === 0) {
      if (reportData?.length !== state?.selectedRows?.length) {
        newRowData = false;
      }
    }

    let editRowID = [];
    let finalArray = [];
    let selectedIndex = 0;
    let prevDataIds = data?.map((e) => e.id);

    for (let index = 0; index < Object.values(value).length; index++) {
      if (index % 3 === 0) {
        editRowID.push(Object.values(value)[index]);

        if (prevDataIds.includes(Object.values(value)[index])) {
          finalArray.push({
            id: Object.values(value)[index],
            name: Object.values(value)[index + 1],
            amount: Object.values(value)[index + 2],
          });
        } else {
          finalArray.push({
            name: Object.values(value)[index + 1],
            amount: Object.values(value)[index + 2],
          });
        }
        selectedIndex = selectedIndex + 1;
      }
    }

    const isSelectButNotEdit = selectedRows
      .map((sRId) =>
        selectedRows.length &&
        !editRowID.includes(sRId) &&
        !prevDataIds.includes(sRId)
          ? {
              index: prevDataIds.includes(sRId)
                ? prevDataIds.findIndex((prevDId) => prevDId === sRId) + 1
                : newAddRowIds.findIndex((newARId) => newARId === sRId) +
                  1 +
                  (prevDataIds.length > 0 ? prevDataIds.length : 1),
            }
          : undefined
      )
      .filter((d) => d);

    const isEditButNotSelect = editRowID
      .map((eRId) =>
        editRowID.length && !selectedRows.includes(eRId)
          ? {
              index: prevDataIds.includes(eRId)
                ? prevDataIds.findIndex((prevDId) => prevDId === eRId) + 1
                : newAddRowIds.findIndex((newARId) => newARId === eRId) +
                  1 +
                  (prevDataIds.length > 0 ? prevDataIds.length : 1),
            }
          : undefined
      )
      .filter((d) => d);

    const isNewRowAddButPrevNotSelect =
      !selectedRows.length && !editRowID.length && newAddRowIds.length > 0;

    const isNewAddButNotEditAndSelect =
      newAddRowIds.length &&
      newAddRowIds.filter(
        (newAddRId) =>
          !editRowID.includes(newAddRId) && !selectedRows.includes(newAddRId)
      );

    if (isSelectButNotEdit.length > 0) {
      modalNotification({
        type: 'error',
        message: `Please enter/select name in row ${isSelectButNotEdit[0].index}`,
      });
      return;
    }
    if (isEditButNotSelect.length > 0) {
      modalNotification({
        type: 'error',
        message: `Please enter/select name in row ${isEditButNotSelect[0].index}`,
      });
      return;
    }

    if (isNewRowAddButPrevNotSelect) {
      modalNotification({
        type: 'error',
        message: `Please enter/select name in row ${prevDataIds.length + 1}`,
      });
      return;
    }

    if (prevDataIds.length && !newAddRowIds.length && !selectedRows.length) {
      modalNotification({
        type: 'error',
        message: `Please enter/select name in row 1`,
      });
      return;
    }

    if (isNewAddButNotEditAndSelect?.length > 0) {
      modalNotification({
        type: 'error',
        message: `Please enter/select name in row ${
          prevDataIds.length +
          newAddRowIds.findIndex(
            (findItem) => findItem === isNewAddButNotEditAndSelect[0]
          ) +
          1
        }`,
      });
      return;
    }
    if (finalArray.length > 0) {
      setLoading(true);
      try {
        let bodyData = {
          bookingId: bookingId,
          laborType: 'purchase',
          laborData: finalArray,
        };
        const response = await getCreateSubLabour({ bodyData });
        const { success } = response;
        if (success) {
          setIsOpenModal(false);
          getReportData();
        }
      } catch (err) {
        logger(err);
      }
      setLoading(false);
    } else {
      modalNotification({
        type: 'error',
        message: `Please enter/select name in row ${rowID}`,
      });
    }
  };

  useEffect(() => {
    if (searchData) {
      setReportData(data);
    } else {
      setReportData(
        data?.length > 0
          ? data.map((dItem, idx) => ({ ...dItem, rowID: idx + 1 }))
          : defaultData
      );
    }
    setBookingId(providerDiscountData?.BookingDetail?.bookingId);
  }, [data]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (
        (searchData.length >= 1 || searchData.length === 0) &&
        firstTimeFetch
      ) {
        onSearch(searchData);
      }
    }, 700);

    return () => {
      clearTimeout(handler);
    };
  }, [searchData]);

  useEffect(() => {
    setFirstTimeFetch(true);
  }, []);

  return (
    <>
      <Form onFinish={onFinish}>
        <CustomTable
          tableData={reportData}
          tableColumns={columns}
          count={reportData?.length}
          setSearchData={setSearchData}
          setPage={setPage}
          // searchDisabled={data && data?.length > 0}
          searchDisabled={reportData?.length > 1 || searchData?.length}
          sizePerPage={page.sizePerPage}
          currentPage={page.currentPage}
          tableLoader={isLoading || tableLoading}
          actions={[
            { label: 'Edit', id: 'edit' },
            { label: ' Delete', id: 'delete' },
          ]}
          searchPlaceholder={t('text.common.nameAndTotalFees')}
          isModalPadding={true}
          hasLimit
          handleActionSelect={handleActionSelect}
          handleSelectedRows={onRowSelect}
          selectRow={
            providerDiscountData?.BookingDetail?.providerPaymentStatus !==
              'settelled' || !isUserCanEdit
          }
          modalParams={false}
          showPageSizeAction={false}
          page={page}
        />

        {(userType === 'admin' || isUserCanEdit) &&
        providerDiscountData?.BookingDetail?.providerPaymentStatus !==
          'settelled' ? (
          <div className="text-end">
            <CommonButton
              className="btn btn-primary"
              onClick={() =>
                AddReportData(data?.length > 0 ? data : defaultData)
              }
            >
              {t('text.common.add')}{' '}
            </CommonButton>
            <CommonButton
              className="btn btn-success ms-2"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              {t('text.common.submit')}
            </CommonButton>
          </div>
        ) : (
          <></>
        )}
      </Form>
    </>
  );
}

export default PurchaseOrder;
