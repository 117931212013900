import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb, CommonButton } from '../../../components';
import ProviderContactUsForm from '../../../components/Forms/CustomerApp/providerContact.form';
import { addContactForm } from '../../../services/CustomerApp/index.service';
import { modalNotification } from '../../../utils';
import { accessRoute } from '../../../routes/CustomerApp/searchProvider';
import { Columns } from '../../../services/index.service';

function ProviderContact() {
  const { id } = useParams();
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const breadcrumb = Columns.customerProviderContactUsBreadcrumb();

  const onSubmit = async (values) => {
    let bodyData = { ...values };
    bodyData.userId = state?.providerId || id;
    const res = await addContactForm({ bodyData });
    if (res?.success && res?.message) {
      modalNotification({
        type: 'success',
        message: res?.message,
      });
      navigate(accessRoute.PROVIDERS.path);
    }
  };

  return (
    <div className="main-content providerDetailPage">
      {/* page content */}
      <div className="pageContent">
        <div className="pageContent_wide">
          <Container className="container-lg">
            <div className="d-flex justify-content-between">
              <Breadcrumb
                title="Service Provider Contact Us"
                breadcrumb={breadcrumb}
                type="customer"
              />
              <CommonButton
                onClick={() => navigate(-1)}
                className="btn btn-outline-info ms-3 mb-3 btn-sm"
              >
                {t('text.common.back')}
              </CommonButton>
            </div>
            <div className="detailsBox br-10 p-30 bg-white">
              <ProviderContactUsForm t={t} onSubmit={onSubmit} />
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default ProviderContact;
