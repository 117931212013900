import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Spin } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { accessRoute } from '../../../routes/CustomerApp/scheduleServiceProvider';
import { ImageElement, NoDataFound } from '../../../components';
import { firstLetterCaps } from '../../../utils/text.util';
import { useTranslation } from 'react-i18next';
import Favorite from '../../../components/Favorite';
import { generateStarRating } from '../../../utils';

const ServiceProviderList = ({ providersList, isLoading, loadMore }) => {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <>
      <div className="appointmentSection bg-white p-30 br-10">
        <Row className="g-3">
          {isLoading && !providersList?.length ? (
            <Spin />
          ) : !isLoading && providersList && providersList?.length ? (
            <>
              {providersList?.map((provider, idx) => (
                <Col key={idx} sm={6} lg={4} xxl={3}>
                  <div className="appointmentList position-relative">
                    <div className="appointmentList_like">
                      <Favorite
                        userId={provider?.Provider?.UserId}
                        isFavorite={provider?.isFavorite === 1 ? true : false}
                      />
                    </div>
                    <div className="appointmentList_top d-flex align-items-start">
                      <div className="appointmentList_top_images">
                        <ImageElement
                          previewSource={provider?.profilePictureThumbUrl}
                        />
                      </div>
                      <div className="appointmentList_top_content">
                        <h2 className="font-md">
                          {firstLetterCaps(provider?.firstName ?? '')}&nbsp;
                          {firstLetterCaps(provider?.lastName ?? '')}
                        </h2>
                        <ul className="list-inline mb-0">
                          <li className="list-inline-item">
                            <div className="info">
                              <ul className="list-unstyled d-flex starRating mb-0">
                                {generateStarRating(provider?.avgRating ?? 0)}
                              </ul>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="appointmentList_bottom d-flex">
                      <Link
                        to={accessRoute.SERVICE_PROVIDER_DETAILS_MILES.path}
                        state={{
                          ...location?.state,
                          providerDetails: provider,
                        }}
                        className="btn btn-light w-100"
                      >
                        {t('text.scheduleServiceProvider.moreInfo')}
                      </Link>
                    </div>
                  </div>
                </Col>
              ))}
              {loadMore}
            </>
          ) : (
            <NoDataFound
              source="/no-provider.svg"
              text={t('text.common.noProviderFound')}
              extraClass="noDataFoundWrapper w-100"
              isCustomer={'/assets/images/admin/no-provider.svg'}
            />
          )}
        </Row>
      </div>
    </>
  );
};

export default ServiceProviderList;
