let url = '/v1/admin/will-call-list';
export const WILL_CALL_LIST_ENDPOINT = {
  getWillCallList: {
    url,
    method: 'GET',
  },
  willCallListStatusUpdate: (id) => ({
    url: `${url}/change-status/${id}`,
    method: 'PUT',
  }),
};
