export const LOCALIZATION_ENDPOINT = {
  getCountryApi: {
    url: '/v1/admin/country',
    method: 'GET',
  },
  getStateApi: {
    url: '/v1/admin/state',
    method: 'GET',
  },
  getCityApi: {
    url: '/v1/admin/city',
    method: 'GET',
  },
  addCountry: {
    url: '/v1/admin/country',
    method: 'POST',
  },
  addState: {
    url: '/v1/admin/state',
    method: 'POST',
  },
  addCity: {
    url: '/v1/admin/city',
    method: 'POST',
  },
  editCity: (id) => ({
    url: `/v1/admin/city/${id}`,
    method: 'PUT',
  }),
  deleteCountry: {
    url: `/v1/admin/country`,
    method: 'DELETE',
  },
  editCountry: (id) => ({
    url: `/v1/admin/country/${id}`,
    method: 'PUT',
  }),
  deleteState: {
    url: `/v1/admin/state`,
    method: 'DELETE',
  },
  deleteCity: {
    url: `/v1/admin/city`,
    method: 'DELETE',
  },
  getCountryById: (id) => ({
    url: `/v1/admin/country/${id}`,
    method: 'GET',
  }),
  updateCountryStatusId: {
    url: `/v1/admin/country/change-status`,
    method: 'PUT',
  },
  getStateById: (id) => ({
    url: `/v1/admin/state/${id}`,
    method: 'GET',
  }),
  updateStateStatusId: {
    url: `/v1/admin/state/change-status`,
    method: 'PUT',
  },
  getCityById: (id) => ({
    url: `/v1/admin/city/${id}`,
    method: 'GET',
  }),
  updateCityStatus: {
    url: `/v1/admin/city/change-status`,
    method: 'PUT',
  },
  editState: (id) => ({
    url: `/v1/admin/state/${id}`,
    method: 'PUT',
  }),
};
