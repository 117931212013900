import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Tabs, PageContainerWrapper } from '../../../../components';
import { getApiData } from '../../../../hooks/useGetApi.hook';
import useSubmit from '../../../../hooks/useSubmit.hook';
import { accessRoute } from '../../../../routes/AdminSetting';
import {
  editTabContent,
  ncaEditApiList,
  ncaEditBreadcrumb,
  ncaGetApiList,
} from '../../../../services/Columns';
import {
  backToPage,
  getLocalStorage,
  openInNewTab,
  setLocalStorage,
  setLocalStorageToken,
} from '../../../../utils';
import { goToDashboardService } from '../../../../services/index.service';
import { loadPermission, login } from '../../../../redux/index.redux';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

function EditNCA() {
  let { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [formData, setFormData] = useState([]);
  const [ncaProfileLoading, setNcaProfileLoading] = useState(false);
  const [defaultKey, setDefaultKey] = useState('ncaProfile');
  const isNcaUser = useSelector(
    (state) => state?.auth?.userData?.Role?.type === 'admin'
  );
  let breadcrumb = ncaEditBreadcrumb(isNcaUser);
  const redirectUrl = accessRoute.LIST_NCA.path;
  let editApiObj = ncaEditApiList();
  let getApiObj = ncaGetApiList();

  // For form submit
  const [onSubmit, data, isLoading] = useSubmit({
    service: editApiObj[defaultKey],
    id,
  });
  const [handleGoToNCADashboard, ncaUserData, isLoadingGoToDashboard] =
    useSubmit({
      service: goToDashboardService,
    });

  // For tab content
  let tabContentData = editTabContent({
    formData: formData,
    onSubmit,
    isLoading,
    defaultKey,
    id,
    setDefaultKey,
    ncaProfileLoading,
  });

  const handleGoToLogReportPage = () => {
    openInNewTab(
      generatePath(accessRoute.LOG_REPORTS.path, {
        id,
      })
    );
  };

  // For get form details by api
  useEffect(() => {
    if (defaultKey !== 'geoFenceLocation' && defaultKey !== 'serviceCategory') {
      getApiData({
        service: getApiObj['ncaProfile'],
        setData: setFormData,
        queryParams: { id },
        setIsLoading: setNcaProfileLoading,
      });
    }
  }, [defaultKey, data]);

  // set default tabs
  useEffect(() => {
    let getTabsKey = getLocalStorage({ keyName: 'tabsKey' });
    if (getTabsKey) {
      setDefaultKey(getTabsKey);
    }
  }, []);

  useEffect(() => {
    if (defaultKey) {
      setLocalStorage({ keyName: 'tabsKey', data: defaultKey });
    }
  }, [defaultKey]);

  useEffect(() => {
    if (ncaUserData && ncaUserData?.token) {
      setLocalStorageToken(ncaUserData?.token);
      dispatch(loadPermission());
      dispatch(login({ ...ncaUserData, ncaSwitch: true }));
    }
  }, [ncaUserData]);

  // For header Button
  const btn = {
    multipleButton: [
      {
        title: t('text.nca.goLogReport'),
        handler: () => handleGoToLogReportPage(),
      },
      ...(formData?.CompanyNca?.isPlanCancelled === 0 &&
      formData?.status === 'active'
        ? [
            {
              title: t('text.nca.goToNcaDashboard'),
              handler: () => handleGoToNCADashboard({ userId: id }),
              disabled: isLoadingGoToDashboard,
              loading: isLoadingGoToDashboard,
            },
          ]
        : []),
    ],
    extraButton: {},
  };

  return (
    <>
      <PageContainerWrapper
        breadcrumb={breadcrumb}
        heading={t('text.common.editNCA')}
        btn={btn}
        extraBtnText={t('text.common.back')}
        extraBtnClass="btn btn-outline-light bg-white"
        onExtraButtonHandleShow={() => backToPage(navigate, redirectUrl)}
      >
        <Tabs
          tabContent={tabContentData}
          tabsFor="table"
          activeKey={defaultKey}
          setActiveKey={setDefaultKey}
        />
      </PageContainerWrapper>
    </>
  );
}

export default EditNCA;
