export const text = {
  noteInfo: `Click on Action "Edit" icon to change the content for home page`,
  find_your_experts_image:
    '[Note: For Better Resolution Upload image size of 800px * 550px.]',
  right_banner_image:
    '[Note: For Better Resolution Upload image size of 170px * 680px.]',

  testimonial_one_image:
    '[Note: For Better Resolution Upload image size of 200px * 200px.]',
  testimonial_two_image:
    '[Note: For Better Resolution Upload image size of 200px * 200px.]',
  testimonial_three_image:
    '[Note: For Better Resolution Upload image size of 200px * 200px.]',
  testimonial_four_image:
    '[Note: For Better Resolution Upload image size of 200px * 200px.]',
  third_sec_image:
    '[Note: For Better Resolution Upload image size of 1925px * 460px.]',
};
