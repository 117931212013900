import i18next from 'i18next';
import { getInputSearchFilter } from '../../components';
import { serialNumberFormatter } from '../../components/UIElements/Formatter';
import { accessRoute } from '../../routes/Dashboard';
import { readMoreTextShow } from '../../utils';

export const notificationTemplateList = ({
  onFilterChange,
  filter,
  Action,
  onSort,
  t,
  page,
  sizePerPage,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'purpose',
      title: t('text.common.notificationTitle'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('purpose', order),
      ...getInputSearchFilter({
        dataIndex: 'purpose',
        onFilterChange,
        filter,
        name: 'purpose',
        placeholder: 'notification purpose',
      }),
    },
    {
      dataIndex: 'body',
      title: t('text.common.notificationMessage'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) =>
        readMoreTextShow(cell, t('text.common.notificationMessage')),
      onSort: (field, order) => onSort('body', order),
      ...getInputSearchFilter({
        dataIndex: 'body',
        onFilterChange,
        filter,
        name: 'notification message',
        placeholder: 'notification message',
      }),
    },
    {
      dataIndex: 'edit',
      title: t('text.common.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => <Action cell={cell} row={row} />,
    },
  ];
};

export const notificationTemplateBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.common.notificationTemplates'),
    },
  ];
};
