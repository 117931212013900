const newUrl = `/v1/sub-admin/lesson`;

export const lessonList = {
  getLessonList: {
    url: `${newUrl}/required`,
    method: 'GET',
  },
  getReferenceList: {
    url: `${newUrl}/reference`,
    method: 'GET',
  },
  getLessonInfo: (id) => ({
    url: `${newUrl}/${id}`,
    method: 'GET',
  }),
  putQuestionAnswer: {
    url: `${newUrl}/lesson-test`,
    method: 'POST',
  },
  completeList: {
    url: `${newUrl}/completed`,
    method: 'POST',
  },
};
