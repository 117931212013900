export const text = {
  title: 'My Bookings',
  cancelBookingTitle: 'CANCEL BOOKING',
  status: 'Status',
  bookingID: 'Booking ID',
  quantity: 'Qty',
  serviceAddress: 'Service Address',
  paymentStatus: 'Payment Status',
  viewDetails: 'View Details',
  cancelBooking: ' Cancel Booking',
  continue: 'Continue',
  onGoingServices: 'On Going Services',
  upcoming: 'Upcoming',
  past: 'Past',
  request: 'Request',
};
