import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { firstLetterCaps } from '../../utils/text.util';

function BankDetailCard({ data }) {
  const { t } = useTranslation();
  return (
    <div className="ncaBusinessDetails">
      <Row>
        <Col md={6}>
          <ul className="list-unstyled">
            <li>
              <label className="sub-text">{t('text.provider.bankName')}</label>
              {firstLetterCaps(data?.bankName)}
            </li>
            <li>
              <label className="sub-text">
                {t('text.provider.bankHolderName')}
              </label>
              {data?.bankAccountHolderName || '-'}
            </li>
            <li>
              <label className="sub-text">
                {t('text.provider.accountNumber')}
              </label>
              {data?.accountNumber || '-'}
            </li>
          </ul>
        </Col>
        <Col md={6}>
          <ul className="list-unstyled">
            <li>
              <label className="sub-text">
                {t('text.provider.routingNumber')}
              </label>{' '}
              {data?.bankAccountRoutingNumber || '-'}
            </li>
            <li>
              <label className="sub-text">
                {t('text.provider.bankLocation')}
              </label>{' '}
              {data?.bankLocation || '-'}
            </li>
            <li>
              <label className="sub-text">{t('text.common.email')}</label>{' '}
              {data?.email || '-'}
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
}
export default BankDetailCard;
