import React from 'react';
import { CommonButton, TextInput } from '../../../UIElements/index.element';
import Select from '../../../UIElements/Select';
import { FormElementWrapper } from '../../../Wrappers';
import FormWrapper from '../../../Wrappers/Form/index.wrapper';
import { creditCardInitValues, creditCardValidation } from './validation';
import { useEffect } from 'react';

export default function CreditCardForm({
  onSubmit,
  monthsData,
  yearsData,
  rowData,
  loading,
  authorizedUser,
  paymentDetails,
}) {
  let initialValues = creditCardInitValues();
  const [formData, setFormData] = React.useState(initialValues);

  useEffect(() => {
    if (rowData) {
      setFormData((formData) => ({
        ...formData,
        paidAmount: rowData?.adminAttentionAmount,
        // paidAmount:
        //   authorizedUser === 'adminAttention'
        //     ? paymentDetails?.remainingAmount
        //     : rowData?.remainingAmount,

        expiryMonth: '1',
        expiryYear: `${new Date().getFullYear()}`,
      }));
    }
  }, [rowData]);

  return (
    <>
      {' '}
      <FormWrapper
        row={true}
        extraClassName="g-3"
        initialValues={{ ...formData }}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validation={creditCardValidation}
        data={{ ...formData }}
      >
        {(props) => {
          return (
            <>
              <FormElementWrapper
                md={4}
                label="Amount To Pay"
                element={() => (
                  <TextInput
                    name="paidAmount"
                    placeholder="Enter amount to pay"
                    formik={props}
                    disabled={true}
                  />
                )}
              />
              <FormElementWrapper
                md={4}
                label="Card Holder"
                element={() => (
                  <TextInput
                    name="cardHolderName"
                    placeholder="Enter card holder"
                    formik={props}
                    type="text"
                  />
                )}
              />
              <FormElementWrapper
                md={4}
                label=" Your Card Number"
                element={() => (
                  <TextInput
                    name="cardNumber"
                    placeholder="Enter your card number"
                    formik={props}
                    type="text"
                  />
                )}
              />
              <FormElementWrapper
                md={4}
                label="Exp. Month"
                element={() => (
                  <Select
                    name="expiryMonth"
                    placeholder="Select exp. month"
                    options={monthsData}
                    showSearch
                    formik={props}
                  />
                )}
              />
              <FormElementWrapper
                md={4}
                label="Exp. Year"
                element={() => (
                  <Select
                    name="expiryYear"
                    placeholder="Select exp. year"
                    options={yearsData}
                    showSearch
                    formik={props}
                  />
                )}
              />
              <FormElementWrapper
                md={4}
                label="CVV"
                element={() => (
                  <TextInput
                    name="cvc"
                    placeholder="Enter cvv"
                    formik={props}
                    type="text"
                  />
                )}
              />
              <div className="mt-4 text-end">
                <CommonButton
                  type="submit"
                  className="btn btn-primary"
                  loading={loading}
                >
                  Submit
                </CommonButton>
              </div>
            </>
          );
        }}
      </FormWrapper>
    </>
  );
}
