import { APIRequest } from '../../helpers/index.helper';
import { LOCALIZATION_ENDPOINT } from '../../apiEndpoints/Localization/index.endpoint';
import { logger } from '../../utils';
import { COMMON_ENDPOINT } from '../../apiEndpoints/index.endpoint';

export const CountryListService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...LOCALIZATION_ENDPOINT.getCountryApi,
      queryParams,
      // isAdminToken: true,
    };

    const res = await APIRequest(payload);
    if (type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const CountryService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...COMMON_ENDPOINT.getCountry,
      queryParams,
    };

    const res = await APIRequest(payload);
    if (type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const addCountryService = async ({ body }) => {
  try {
    const payload = {
      ...LOCALIZATION_ENDPOINT.addCountry,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const editCountryService = async ({ id, body }) => {
  try {
    const payload = {
      ...LOCALIZATION_ENDPOINT.editCountry(id),
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const deleteCountryService = async ({ bodyData }) => {
  try {
    const payload = {
      ...LOCALIZATION_ENDPOINT.deleteCountry,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const getCountryByIdService = async ({ resourceId }) => {
  try {
    const payload = {
      ...LOCALIZATION_ENDPOINT.getCountryById(resourceId),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const updateCountryStatus = async (body) => {
  try {
    const payload = {
      ...LOCALIZATION_ENDPOINT.updateCountryStatusId,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
