import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ImageElement from '../UIElements/ImageElement';
import { ModalWrapper } from '../Wrappers';

function ServiceCategoryCard(props) {
  const [showModal, setShowModal] = useState();
  const { name, logoSrc, onNextURL, description } = props;
  const modalHandler = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="col">
        <div className="type">
          {/* <input type="radio" name="serviceType" /> */}
          <div className="type_wrap bg-white rounded d-flex align-items-center justify-content-center flex-column">
            <ImageElement previewSource={logoSrc} />
            <h3 className="font-md mb-0 text-center">
              {name || ''}
              <Link to="#" onClick={() => modalHandler()}>
                <em className="gg-info" />
              </Link>
            </h3>
          </div>
          <Link className="type_link" to={onNextURL}></Link>
        </div>
      </div>
      <ModalWrapper
        onCancel={handleClose}
        heading={name}
        show={showModal}
        extraClassName="modal-lg"
        extraTitleClassName="justify-content-center"
        content={<p>{description}</p>}
      ></ModalWrapper>
    </>
  );
}

export default ServiceCategoryCard;
