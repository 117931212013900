export const APP_TEXT_ENDPOINT = {
  importantInformation: {
    url: '/v1/admin/important-information',
    method: 'PUT',
  },
  tipInformation: {
    url: '/v1/admin/tip',
    method: 'PUT',
  },
  financingInformation: {
    url: '/v1/admin/financing-information',
    method: 'PUT',
  }, 
  quoteInformation: {
    url: '/v1/admin/quote-information',
    method: 'PUT',
  },
  getImportantInformation: {
    url: '/v1/admin/important-information',
    method: 'GET',
  },
  getTipInformation: {
    url: '/v1/admin/tip',
    method: 'GET',
  },
  getFinancingInformation: {
    url: '/v1/admin/financing-information',
    method: 'GET',
  },
  getQuoteInformation: {
    url: '/v1/admin/quote-information',
    method: 'GET',
  },
};
