import { getActions } from '../../services/index.service';
import { LogReports } from '../../views/index.view';

export default function route() {
  return [
    {
      path: accessRoute.NCA_LOG_REPORT.path,
      private: true,
      name: 'Nca Log Report',
      action: accessRoute.NCA_LOG_REPORT.action,
      key: accessRoute.NCA_LOG_REPORT.key,
      belongsToSidebar: accessRoute.NCA_LOG_REPORT.belongsToSidebar,
      submenu: false,
      icon: accessRoute.NCA_LOG_REPORT.icon,
      element: <LogReports />,
      ...accessRoute.NCA_LOG_REPORT,
    },
  ];
}

export const accessRoute = {
  NCA_LOG_REPORT: {
    path: '/admin/nca/log-report/:id',
    action: getActions('LIST').value,
    moduleKey: 'nca',
    for: ['nca'],
    ignorePermission: true,
    shouldBePartOf: ['nca'],
    belongsToSidebar: false,
    key: 'nca',
  },
};
export const getChildrenRoutes = () => {
  return route();
};
