import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { editUnavailabilityValues } from './validation';
import { FormWrapper } from '../../index';
import CommonButton from '../../UIElements/Button/Common/index.btn';
import GlobalLoader from '../../UIElements/GlobalLoader';
import FormElementWrapper from '../../Wrappers/FormElement/index.wrapper';
import Select from '../../UIElements/Select';
import TextInput from '../../UIElements/TextInput/index.element';
import {
  autoApproveBookingList,
  scheduleJobData,
  spWorkLocationList,
} from '../../../services/Columns';
import Switch from '../../UIElements/Switch';
import { getProviderUnAvailabilityFormService } from '../../../services/TeamSetting/provider.service';
import AutoComplete from '../../../components/UIElements/AutoComplete/index';
import useLocation from '../../../hooks/useLocation.hook';
import ModalComponent from '../../UIElements/Modal';

function EditUnavailabilityForm({ onSubmit, isLoading, setModalShow, userId }) {
  const [unavailabilityData, setUnavailabilityData] = useState({});
  const [formData, setFormData] = useState([]);
  const [showDescription, setShowDescription] = useState(false);
  const { t } = useTranslation();
  const [data, method] = useLocation();
  const { isPlacePredictionsLoading, lat, lng } = data;
  const initialValues = editUnavailabilityValues({ ...formData });
  const autoApproveBookingData = autoApproveBookingList();
  const spWorkLocationData = spWorkLocationList();
  const scheduleJobList = scheduleJobData();

  // For get unavailability data
  useEffect(() => {
    if (userId) {
      getProviderUnAvailability(userId);
    }
  }, [userId]);

  async function getProviderUnAvailability(id) {
    try {
      const res = await getProviderUnAvailabilityFormService({ id });
      if (res?.success) {
        setUnavailabilityData(res?.data?.Provider);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    setFormData(unavailabilityData);
  }, [unavailabilityData]);

  function onFinish(payload) {
    try {
      if (payload.locationType === 'specified_location' && lat && lng) {
        payload.workLocationLatitude = String(lat);
        payload.workLocationLongitude = String(lng);
      }
      if (payload.locationType === 'any_location' && lat && lng) {
        delete payload.workLocationLatitude;
        delete payload.workLocationLongitude;
        delete payload.workLocation;
      }
      onSubmit(payload, 'form');
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <div className="nk-block">
        <div className="card unavailabilitySec">
          <div className="card-inner">
            <FormWrapper
              initialValues={initialValues}
              onSubmit={onFinish}
              enableReinitialize={true}
              data={formData}
            >
              {(props) => {
                return (
                  <>
                    <div className="card-bordered">
                      <div className="card-inner-group">
                        <div className="card-inner">
                          <div className="between-center flex-wrap flex-md-nowrap g-3">
                            <div className="nk-block-text w-75">
                              <h6>{t('text.provider.timeOff')}</h6>
                              <p className="mb-0">
                                <b>{t('text.provider.setDayInfo')}</b>
                              </p>
                              <p className="mb-2">
                                {t('text.provider.setDayDescription')}
                              </p>
                              <CommonButton
                                variant="primary"
                                shouldPermissionApply={true}
                                onClick={() => setModalShow(true)}
                              >
                                {t('text.common.selectDate')}
                              </CommonButton>
                              <p className="mb-0 mt-2">
                                {t('text.provider.setDayDescriptionTwo')}
                              </p>
                            </div>
                            <div className="nk-block-actions text-sm-center w-25">
                              <div>
                                <FormElementWrapper
                                  element={() => (
                                    <Switch
                                      name="isTimeOffRestrict"
                                      formik={props}
                                    />
                                  )}
                                />
                              </div>
                              <span>
                                ({t('text.provider.restrictForServiceProvider')}
                                )
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="card-inner">
                          <div className="between-center flex-wrap flex-md-nowrap g-3">
                            <div className="nk-block-text w-75">
                              <h6>
                                <span className="linkButton">
                                  <em
                                    onClick={() => setShowDescription(true)}
                                    className="icon ni ni-info me-1"
                                  />
                                </span>
                                {t('text.provider.setAutoApproveBooking')}
                              </h6>
                              <div className="form-group">
                                <FormElementWrapper
                                  label={t(
                                    'text.provider.selectApproveAllBookingAutomatically'
                                  )}
                                  element={() => (
                                    <Select
                                      name="autoApproveBooking"
                                      placeholder={t(
                                        'text.provider.selectApproveAllBookingAutomatically'
                                      )}
                                      variant="standard"
                                      size="medium"
                                      options={autoApproveBookingData}
                                      showSearch
                                      formik={props}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                            <div className="nk-block-actions text-sm-center w-25">
                              <div>
                                <FormElementWrapper
                                  element={() => (
                                    <Switch
                                      name="isAutoApproveBookingRestrict"
                                      formik={props}
                                    />
                                  )}
                                />
                              </div>
                              <span>
                                ({t('text.provider.restrictForServiceProvider')}
                                )
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="card-inner">
                          <div className="between-center flex-wrap flex-md-nowrap g-3">
                            <div className="nk-block-text w-75">
                              <h6>{t('text.provider.spWorkLocation')}</h6>
                              <FormElementWrapper
                                label={t(
                                  'text.provider.workLocationIsTheServiceAreaSPWillProvideService'
                                )}
                                element={() => (
                                  <Select
                                    name="locationType"
                                    placeholder={t(
                                      'text.provider.SelectWorkLocation'
                                    )}
                                    variant="standard"
                                    size="medium"
                                    options={spWorkLocationData}
                                    formik={props}
                                  />
                                )}
                              />
                              {props?.values?.locationType ===
                                'specified_location' && (
                                <div className="mt-3">
                                  <FormElementWrapper
                                    element={() => (
                                      <AutoComplete
                                        name="workLocation"
                                        onKeywordChange={
                                          method?.onPlacePredictions
                                        }
                                        placeholder={t(
                                          'text.common.enterYourLocation'
                                        )}
                                        formik={props}
                                        onSelect={method?.onPlaceSelect}
                                        options={method?.getPredictionLocation()}
                                        loading={isPlacePredictionsLoading}
                                      />
                                    )}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="nk-block-actions text-sm-center w-25">
                              <div>
                                <FormElementWrapper
                                  element={() => (
                                    <Switch
                                      name="isWorkLocationRestrict"
                                      formik={props}
                                    />
                                  )}
                                />
                              </div>
                              <span>
                                ({t('text.provider.restrictForServiceProvider')}
                                )
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="card-inner border-bottom-0">
                          <div className="between-center flex-wrap flex-md-nowrap g-3">
                            <div className="nk-block-text w-75">
                              <h6>{t('text.provider.spJobRadius')}</h6>
                              <div className="mb-3">
                                <FormElementWrapper
                                  label={t('text.provider.scheduleJobRadius')}
                                  element={() => (
                                    <Select
                                      name="scheduledJobRadius"
                                      placeholder={t(
                                        'text.provider.selectJobRadius'
                                      )}
                                      variant="standard"
                                      size="medium"
                                      options={scheduleJobList}
                                      showSearch
                                      formik={props}
                                    />
                                  )}
                                />
                                {props?.values?.scheduledJobRadius ===
                                  'other' && (
                                  <div className="mt-3">
                                    <FormElementWrapper
                                      label="Other Miles Radius (For Schedule Job)"
                                      element={() => (
                                        <TextInput
                                          type="number"
                                          name="otherScheduleJob"
                                          placeholder={t(
                                            'text.common.otherMilesRadius'
                                          )}
                                          formik={props}
                                        />
                                      )}
                                    />
                                  </div>
                                )}
                              </div>
                              <FormElementWrapper
                                label={t('text.provider.emergencyJobRadius')}
                                element={() => (
                                  <Select
                                    name="emergencyJobRadius"
                                    placeholder={t(
                                      'text.provider.selectEmergencyJobRadius'
                                    )}
                                    variant="standard"
                                    size="medium"
                                    options={scheduleJobList}
                                    showSearch
                                    formik={props}
                                  />
                                )}
                              />
                              {props?.values?.emergencyJobRadius ===
                                'other' && (
                                <div className="mt-3">
                                  <FormElementWrapper
                                    label="Other Miles Radius (For Emergency Job)"
                                    element={() => (
                                      <TextInput
                                        name="otherEmergencyJob"
                                        placeholder={t(
                                          'text.common.otherMilesRadius'
                                        )}
                                        type="number"
                                        formik={props}
                                      />
                                    )}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="nk-block-actions text-sm-center w-25">
                              <div>
                                <FormElementWrapper
                                  element={() => (
                                    <Switch
                                      name="isJobRadiusRestrict"
                                      formik={props}
                                    />
                                  )}
                                />
                              </div>
                              <span>
                                ({t('text.provider.restrictForServiceProvider')}
                                )
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-end mt-4">
                      <CommonButton
                        type="submit"
                        shouldPermissionApply={true}
                        className="btn btn-primary"
                        disabled={isLoading}
                      >
                        {isLoading && (
                          <GlobalLoader
                            animation="grow"
                            size="sm"
                            variant="white"
                          />
                        )}
                        {t('text.common.submit')}
                      </CommonButton>
                    </div>
                  </>
                );
              }}
            </FormWrapper>
          </div>
        </div>
      </div>
      <ModalComponent
        closeButton={true}
        extraTitleClassName="justify-content-center"
        onHandleCancel={() => {
          setShowDescription(false);
        }}
        backdrop
        show={showDescription}
        title={t('text.provider.setAutoApproveBookingTitle')}
      >
        <p> {t('text.provider.CustomersInfo')}</p>
      </ModalComponent>
    </>
  );
}
export default EditUnavailabilityForm;
