import React, { useState } from 'react';
import { Upload } from 'antd';
import {
  getLocalStorageToken,
  modalNotification,
  removeLocalStorageToken,
  removeSessionStorageToken,
} from '../../../../utils';
import { Navigate, Link } from 'react-router-dom';
import { uploadFile } from '../../../../services/index.service';
import useSubmit from '../../../../hooks/useSubmit.hook';
import { useEffect } from 'react';
import { ModalWrapper } from '../../../Wrappers';
import { CommonButton } from '..';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const CsvImport = ({
  basePath = 'basePath',
  service,
  mediaFor = 'csv',
  mediaType = 'doc',
  showUploadList = false,
  allowedFormat = ['doc'],
  handleAddImport,
}) => {
  const [state, setState] = useState({
    modal: false,
    recordData: {},
  });
  const { modal, recordData } = state;

  const { t } = useTranslation();
  // const [loading, setLoading] = useState(false);
  const apiToken = getLocalStorageToken();
  const actionUrl = uploadFile(mediaFor, mediaType)?.url;
  const accept = uploadFile(mediaFor, mediaType)?.accept;

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const types = {
    'application/vnd.ms-excel': 'doc',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'doc',
    'image/jpeg': 'image',
    'image/jpg': 'image',
    'image/png': 'image',
    'image/*': 'image',
  };

  const beforeUpload = async (file) => {
    try {
      if (mediaFor === 'csv') {
        if (types[file?.type] && allowedFormat?.includes(types[file?.type])) {
          return true;
        } else {
          modalNotification({ type: 'error', message: 'Invalid File Type' });
          return false;
        }
      }
      return true;
    } catch (err) {
      console.log(err);
    }
  };

  const [onSubmit, data, isLoading] = useSubmit({
    service,
    // refresh: () => tableRefresh(),
  });

  useEffect(() => {
    if (data) {
      setState({ ...state, modal: true, recordData: data });
    }
  }, [data]);

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      // setLoading(true);
      return;
    }
    const { status, response } = info.file;
    if (status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, () => {
        // setLoading(false);
      });
      let docObj = {
        [basePath]: response?.data.basePath,
      };
      onSubmit(docObj);
    } else if (status === 'error') {
      // setLoading(false);
      if (response?.error?.status === 401) {
        removeSessionStorageToken();
        removeLocalStorageToken();
        Navigate('/login');
      }
      modalNotification({
        type: 'error',
        message: info?.fileList[0]?.response?.message,
        description: `${info.file.name} file upload failed. ${info.file.response.detail}`,
      });
      if (response.detail.search('authenticated') !== -1) {
        removeSessionStorageToken();
      }
    }
  };

  const uploadButton = (
    <Link
      onClick={(e) => e.preventDefault()}
      className="btn btn-white btn-outline-light"
    >
      {isLoading && <Spin indicator={<LoadingOutlined spin />} />}
      <em className="ms-1 icon ni ni-upload-cloud" />
      <span>Import</span>
    </Link>
  );

  const uploadProps = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${apiToken}`,
    },
    action: actionUrl,
    method: 'POST',
    multiple: false,
    accept: accept,
    onChange: handleChange,
    showUploadList,
    beforeUpload,
  };

  return (
    <>
      <Upload name="file" className="avatar-uploader" {...uploadProps}>
        {uploadButton}
      </Upload>
      <ModalWrapper
        heading={t('text.common.importRecord')}
        onCancel={() => setState({ ...state, modal: false })}
        content={
          <>
            <ul className="list list-sm list-checked">
              <li>
                {t('text.common.totalRecords')} :{' '}
                <b>{recordData?.totalRecord}</b>
              </li>
              <li>
                {t('text.common.recordInserted')} :{' '}
                <b>{recordData?.insertData}</b>
              </li>
              <li>
                {t('text.common.recordUpdated')} :{' '}
                <b>{recordData?.updateRecord}</b>
              </li>
            </ul>
            <div className="text-end">
              <CommonButton
                className="btn btn-primary"
                onClick={() => {
                  handleAddImport(recordData);
                  setState({ ...state, modal: false });
                }}
              >
                {t('text.common.ok')}
              </CommonButton>
            </div>
          </>
        }
        show={modal}
      />
    </>
  );
};
export default CsvImport;
