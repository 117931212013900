import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PageContainerWrapper, TablePlus, Tabs } from '../../../components';
import { operatingProcedureBreadcrumb } from '../../../services/Columns';
import { accessRoute as lessonRoute } from '../../../routes/LessonList';
import {
  linkFormatter,
  serialNumberFormatter,
} from '../../../components/UIElements/Formatter';
import {
  getLessonService,
  getReferenceService,
} from '../../../services/LessonList/lessonList.service';
import { useEffect } from 'react';
import useTable from '../../../hooks/useTable.hook';

function LessonList() {
  const { t } = useTranslation();
  const [defaultKey, setDefaultKey] = useState('required');
  const breadcrumb = operatingProcedureBreadcrumb();

  const [tableHandler, tableProps] = useTable(
    defaultKey === 'required' ? getLessonService : getReferenceService,
    {
      type: 'admin',
      status: 'active',
    }
  );
  const { data, isLoading, count, page, noOfPages, sizePerPage } = tableProps;
  const { onRefresh } = tableHandler;

  useEffect(() => {
    onRefresh();
  }, [defaultKey]);

  const navigate = useNavigate();

  const columns = [
    {
      dataIndex: 'id',
      title: t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, index, sizePerPage }),
    },
    {
      dataIndex: 'title',
      title: t('text.operating.lessonList'),
      render: (cell, row) =>
        linkFormatter(() => {
          navigate(`${lessonRoute.LESSON_INFO.path}/${row?.id}`);
        }, row?.title),
    },
  ];

  const refColumns = [
    {
      dataIndex: 'id',
      title: t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, index, sizePerPage }),
    },
    {
      dataIndex: 'title',
      title: t('text.operating.lessonList'),
      render: (cell, row) =>
        linkFormatter(() => {
          navigate(`${lessonRoute.LESSON_REF.path}/${row?.id}`);
        }, row?.title),
    },
  ];

  const tabContent = [
    {
      name: t('text.operating.required'),
      key: 'required',
      content: (
        <>
          <div className="card">
            <div className="">
              <TablePlus
                hasLimit
                noOfPage={noOfPages}
                sizePerPage={sizePerPage}
                page={page}
                count={count}
                tableData={data}
                tableColumns={columns}
                showAction={false}
                statusAction={false}
                tableLoader={isLoading}
                searchField
                selectRow={false}
              />
            </div>
          </div>
        </>
      ),
    },
    {
      name: t('text.operating.reference'),
      key: 'reference',
      content: (
        <>
          <div className="card">
            <div className="">
              <TablePlus
                hasLimit
                noOfPage={noOfPages}
                sizePerPage={sizePerPage}
                page={page}
                count={count}
                tableData={data}
                tableColumns={refColumns}
                showAction={false}
                statusAction={false}
                tableLoader={isLoading}
                searchField
                selectRow={false}
              />
            </div>
          </div>
        </>
      ),
    },
  ];

  return (
    <div className="wide-xl mx-auto">
      <div className="nk-block-head nk-block-head-sm">
        <PageContainerWrapper
          heading={t('text.operating.title')}
          breadcrumb={breadcrumb}
        >
          <div className="nk-block">
            <Tabs
              tabContent={tabContent}
              tabsFor="table"
              activeKey={defaultKey}
              setActiveKey={setDefaultKey}
              // onTabChange={onTabChange}
            />
          </div>
        </PageContainerWrapper>
      </div>
    </div>
  );
}

export default LessonList;
