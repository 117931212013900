import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { PageContainerWrapper } from '../../../components';
import { lessonListInfoBreadcrumbs } from '../../../services/Columns';
import { accessRoute as lessonRoute } from '../../../routes/LessonList';
import { logger } from '../../../utils';
import { getInfoListService } from '../../../services/LessonList/lessonList.service';
import { useEffect } from 'react';
import GlobalLoader from '../../../components/UIElements/GlobalLoader';

function LessonReference(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [lessonData, setLessonData] = useState({});
  const [loading, setLoading] = useState(false);

  const infoList = async (id) => {
    setLoading(true);
    try {
      const { success, data } = await getInfoListService(id);
      if (success) {
        setLessonData(data);
      }
    } catch (error) {
      logger(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      infoList(id);
    }
  }, [id]);

  const backPage = () => {
    navigate('/admin/lesson-list');
  };

  const breadcrumb = lessonListInfoBreadcrumbs();
  return (
    <>
      <div className="wide-xl mx-auto">
        <PageContainerWrapper
          heading={t('text.operating.title')}
          breadcrumb={breadcrumb}
          btnArray={['extraButton']}
          extraBtnText={t('text.common.back')}
          extraBtnClass="btn btn-outline-light bg-white"
          onExtraButtonHandleShow={backPage}
        >
          {/* </div> */}
          <div className="nk-block">
            {loading ? (
              <GlobalLoader />
            ) : (
              <div className="card">
                <div className="card-inner">
                  <div className="bq-note">
                    <div className="bq-note-text">
                      <div className="nk-block-head">
                        <h5 className="title">{lessonData?.title}</h5>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: lessonData?.description,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </PageContainerWrapper>
      </div>
    </>
  );
}

export default LessonReference;
