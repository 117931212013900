import i18next from 'i18next';
import * as yup from 'yup';

export const validation = () => {
  return yup.object().shape({
    basePath: yup.string().required(i18next.t('validation.quote.basePath')),
  });
};

export const bulkValidation = () => {
  return yup.object().shape({
    bulkUploadImage: yup
      .array()
      .nullable()
      .typeError(i18next.t('validation.common.atLeastOneEmail'))
      .required(i18next.t('validation.common.bulkUploadImage')),
  });
};
