let url = '/v1/admin/service-type';
export const SERVICE_TYPE_ENDPOINT = {
  getServiceTypeList: {
    url,
    method: 'GET',
  },
  deleteServiceType: {
    url,
    method: 'DELETE',
  },
  addServiceType: {
    url,
    method: 'POST',
  },
  editServiceType: (id) => ({
    url: `${url}/${id}`,
    method: 'PUT',
  }),
  getServiceTypeDetails: (id) => ({
    url: `${url}/${id}`,
    method: 'GET',
  }),
  getServiceTypeCustomerList: {
    url: '/v1/customer/booking/service-type',
    method: 'GET',
  },
};
