import { Checkbox, Radio, Space } from 'antd';
import React from 'react';

function QuestionAnswerForm({ item, key, handleChangeSelect }) {
  return (
    <div>
      {item?.type === 'single' ? (
        <Radio.Group
          index={key}
          onChange={(e) => {
            handleChangeSelect(e.target.value, item?.id, item?.type);
          }}
        >
          <Space direction="vertical">
            {item?.LessonQuestionOptions?.length > 0 &&
              item?.LessonQuestionOptions.map((e) => {
                return (
                  <Radio key={e?.id} value={e?.id}>
                    {e?.option}
                  </Radio>
                );
              })}
          </Space>
        </Radio.Group>
      ) : (
        <Checkbox.Group
          index={key}
          onChange={(e) => {
            handleChangeSelect(e, item?.id, item?.type);
          }}
        >
          <Space direction="vertical">
            {item?.LessonQuestionOptions?.length > 0 &&
              item?.LessonQuestionOptions.map((e) => {
                return (
                  <Checkbox key={e?.id} value={e?.id}>
                    {e?.option}
                  </Checkbox>
                );
              })}
          </Space>
        </Checkbox.Group>
      )}
    </div>
  );
}

export default QuestionAnswerForm;
