import { Outlet } from 'react-router-dom';
import { getActions } from '../../services/index.service';
import {
  AddStore,
  DocumentsView,
  EditStore,
  StoreListing,
  AddPromoCode,
  EditPromoCode,
  PromoCodeListing,
  MarketingEmailTemplate,
  EditMarketingEmailTemplate,
  AddMarketingEmailTemplate,
  ViewMarketingSMSTemplate,
  EditMarketingSmsTemplate,
  AddMarketingSmsTemplate,
  ViewMarketingReviewTemplate,
  MarketingMessageSend,
  AdvertisementListing
} from '../../views/Marketing';
import PromoCodeJobs from '../../views/Marketing/PromoCodeJobs';
import UnsubscribeUserList from '../../views/Marketing/MarketingTemplate/MarketingReviewTemplate/UnsubscribeUserList';
import ReviewTemplateReport from '../../views/Marketing/MarketingTemplate/MarketingReviewTemplate/ViewReport';

export default function route() {
  return [
    {
      private: true,
      name: 'Marketing',
      key: 'marketing',
      belongsToSidebar: true,
      icon: (
        <span className="nk-menu-icon">
          <em className="icon ni ni-offer-fill" />
        </span>
      ),
      moduleKeys: [
        accessRoute.PROMO_CODE.moduleKey,
        accessRoute.STORE.moduleKey,
        accessRoute.MARKETING_EMAIL_TEMPLATE.moduleKey,
        accessRoute.MARKETING_SMS_TEMPLATE.moduleKey,
        accessRoute.MARKETING_REVIEW_TEMPLATE.moduleKey,
        accessRoute.MARKETING_MESSAGE_SEND.moduleKey,
      ],
      element: <Outlet />,
      submenu: true,
      children: [
        {
          private: true,
          name: accessRoute.PROMO_CODE.name,
          key: 'marketing.promoCode',
          path: accessRoute.PROMO_CODE.path,
          moduleKeys: accessRoute.PROMO_CODE.moduleKey,
          belongsToSidebar: true,
          element: <PromoCodeListing />,
          ...accessRoute.PROMO_CODE,
        },
        {
          private: true,
          name: accessRoute.ADD_PROMO_CODE.name,
          key: 'marketing.promoCode',
          path: accessRoute.ADD_PROMO_CODE.path,
          belongsToSidebar: false,
          element: <AddPromoCode />,
          ...accessRoute.ADD_PROMO_CODE,
        },
        {
          private: true,
          name: accessRoute.EDIT_PROMO_CODE.name,
          key: 'marketing.promoCode',
          path: accessRoute.EDIT_PROMO_CODE.path,
          belongsToSidebar: false,
          element: <EditPromoCode />,
          ...accessRoute.EDIT_PROMO_CODE,
        },
        {
          private: true,
          name: accessRoute.STORE.name,
          key: 'marketing.store',
          path: accessRoute.STORE.path,
          belongsToSidebar: true,
          element: <StoreListing />,
          ...accessRoute.STORE,
        },
        {
          private: true,
          name: accessRoute.ADVERTISEMENT.name,
          key: 'marketing.advertisement',
          path: accessRoute.ADVERTISEMENT.path,
          belongsToSidebar: true,
          element: <AdvertisementListing />,
          ...accessRoute.ADVERTISEMENT,
        },
        {
          private: true,
          name: accessRoute.ADD_STORE.name,
          key: 'marketing.store',
          path: accessRoute.ADD_STORE.path,
          belongsToSidebar: false,
          element: <AddStore />,
          ...accessRoute.ADD_STORE,
        },
        {
          private: true,
          name: accessRoute.EDIT_STORE.name,
          key: 'marketing.store',
          path: accessRoute.EDIT_STORE.path,
          belongsToSidebar: false,
          element: <EditStore />,
          ...accessRoute.EDIT_STORE,
        },
        {
          private: true,
          name: accessRoute.VIEW_DOCUMENTS.name,
          key: 'marketing.store',
          path: accessRoute.VIEW_DOCUMENTS.path,
          belongsToSidebar: false,
          element: <DocumentsView />,
          ...accessRoute.VIEW_DOCUMENTS,
        },
        {
          private: true,
          name: accessRoute.PROMO_CODE_JOBS.name,
          key: 'marketing.store',
          path: accessRoute.PROMO_CODE_JOBS.path,
          belongsToSidebar: false,
          element: <PromoCodeJobs />,
          ...accessRoute.PROMO_CODE_JOBS,
        },
        // Marketing template start
        {
          private: true,
          name: 'Marketing Template',
          key: 'marketing.marketingTemplate',
          belongsToSidebar: true,
          element: <Outlet />,
          moduleKeys: [
            accessRoute.MARKETING_EMAIL_TEMPLATE.moduleKey,
            accessRoute.MARKETING_SMS_TEMPLATE.moduleKey,
            accessRoute.MARKETING_REVIEW_TEMPLATE.moduleKey,
            accessRoute.MARKETING_MESSAGE_SEND.moduleKey,
          ],
          submenu: true,
          children: [
            {
              private: true,
              name: accessRoute.MARKETING_EMAIL_TEMPLATE.name,
              path: accessRoute.MARKETING_EMAIL_TEMPLATE.path,
              key: 'marketing.marketingTemplate.marketing-email-templates',
              belongsToSidebar: true,
              element: <MarketingEmailTemplate />,
              ...accessRoute.MARKETING_EMAIL_TEMPLATE,
            },
            {
              private: true,
              name: accessRoute.ADD_MARKETING_EMAIL_TEMPLATE.name,
              key: 'marketing.marketingTemplate.marketing-email-templates',
              path: accessRoute.ADD_MARKETING_EMAIL_TEMPLATE.path,
              belongsToSidebar: false,
              element: <AddMarketingEmailTemplate />,
              ...accessRoute.ADD_MARKETING_EMAIL_TEMPLATE,
            },
            {
              private: true,
              name: accessRoute.EDIT_MARKETING_EMAIL_TEMPLATE.name,
              key: 'marketing.marketingTemplate.marketing-email-templates',
              path: accessRoute.EDIT_MARKETING_EMAIL_TEMPLATE.path,
              belongsToSidebar: false,
              element: <EditMarketingEmailTemplate />,
              ...accessRoute.EDIT_MARKETING_EMAIL_TEMPLATE,
            },
            {
              private: true,
              name: accessRoute.MARKETING_SMS_TEMPLATE.name,
              key: 'marketing.marketingTemplate.marketing-sms-templates',
              path: accessRoute.MARKETING_SMS_TEMPLATE.path,
              belongsToSidebar: true,
              element: <ViewMarketingSMSTemplate />,
              ...accessRoute.MARKETING_SMS_TEMPLATE,
            },
            {
              private: true,
              name: accessRoute.MARKETING_SMS_EDIT_TEMPLATE.name,
              key: 'marketing.marketingTemplate.marketing-sms-templates',
              belongsToSidebar: false,
              element: <EditMarketingSmsTemplate />,
              ...accessRoute.MARKETING_SMS_EDIT_TEMPLATE,
              path: `${accessRoute.MARKETING_SMS_EDIT_TEMPLATE.path}/:id`,
            },
            {
              private: true,
              name: accessRoute.MARKETING_SMS_ADD_TEMPLATE.name,
              key: 'marketing.marketingTemplate.marketing-sms-templates',
              path: accessRoute.MARKETING_SMS_ADD_TEMPLATE.path,
              belongsToSidebar: false,
              element: <AddMarketingSmsTemplate />,
              ...accessRoute.MARKETING_SMS_ADD_TEMPLATE,
            },
            {
              private: true,
              name: accessRoute.MARKETING_REVIEW_TEMPLATE.name,
              key: 'marketing.marketingTemplate.marketing-review-message',
              path: accessRoute.MARKETING_REVIEW_TEMPLATE.path,
              belongsToSidebar: true,
              element: <ViewMarketingReviewTemplate />,
              ...accessRoute.MARKETING_REVIEW_TEMPLATE,
            },
            {
              private: true,
              name: accessRoute.MARKETING_REVIEW_TEMPLATE_UNSUBSCRIBE_USER.name,
              key: 'marketing.marketingTemplate.marketing-review-message',
              path: accessRoute.MARKETING_REVIEW_TEMPLATE_UNSUBSCRIBE_USER.path,
              belongsToSidebar: false,
              element: <UnsubscribeUserList />,
              ...accessRoute.MARKETING_REVIEW_TEMPLATE_UNSUBSCRIBE_USER,
            },
            {
              private: true,
              name: accessRoute.MARKETING_REVIEW_TEMPLATE_REPORT.name,
              key: 'marketing.marketingTemplate.marketing-review-message',

              belongsToSidebar: false,
              element: <ReviewTemplateReport />,
              ...accessRoute.MARKETING_REVIEW_TEMPLATE_REPORT,
              path: `${accessRoute.MARKETING_REVIEW_TEMPLATE_REPORT.path}/:id`,
            },
            {
              private: true,
              name: accessRoute.MARKETING_MESSAGE_SEND.name,
              key: 'marketing.marketingTemplate.marketing-send-message',
              path: accessRoute.MARKETING_MESSAGE_SEND.path,
              belongsToSidebar: true,
              element: <MarketingMessageSend />,
              ...accessRoute.MARKETING_MESSAGE_SEND,
            },
          ],
        },
      ],
    },
  ];
}

export const accessRoute = {
  PROMO_CODE: {
    path: '/admin/promo-code',
    name: 'Promo Code',
    key: 'marketing.promoCode',
    action: getActions('LIST').value,
    moduleKey: 'promo-code',
    order: 11,
  },
  ADVERTISEMENT: {
    path: '/admin/advertisement',
    name: 'Advertisement',
    key: 'marketing.advertisement',
    action: getActions('LIST').value,
    moduleKey: 'advertisement',
    for: ['admin', 'subadmin'],
    order: 18,
  },
  ADD_PROMO_CODE: {
    path: '/admin/promo-code/add',
    name: 'Add Promo Code',
    action: getActions('CREATE').value,
    moduleKey: 'promo-code',
  },
  EDIT_PROMO_CODE: {
    path: '/admin/promo-code/edit/:id',
    name: 'Edit Promo Code',
    action: getActions('EDIT').value,
    moduleKey: 'promo-code',
  },
  STORE: {
    path: '/admin/store',
    name: 'Store',
    action: getActions('LIST').value,
    moduleKey: 'store',
    for: ['admin', 'subadmin'],
    order: 34,
  },
  ADD_STORE: {
    path: '/admin/store/add',
    name: 'Add Store',
    action: getActions('CREATE').value,
    moduleKey: 'store',
  },
  EDIT_STORE: {
    path: '/admin/store/edit/:id',
    name: 'Edit Store',
    action: getActions('EDIT').value,
    moduleKey: 'store',
  },
  VIEW_DOCUMENTS: {
    path: '/admin/store/documents',
    name: 'View Documents',
    action: getActions('LIST').value,
    moduleKey: 'store',
  },
  PROMO_CODE_JOBS: {
    path: '/admin/store/view-jobs-promo/:storeId',
    name: 'Store',
    action: getActions('LIST').value,
    moduleKey: 'store',
  },
  MARKETING_EMAIL_TEMPLATE: {
    path: '/admin/marketing-email-template',
    name: 'Marketing Email Template',
    key: 'marketing.marketingTemplate.marketing-email-templates',
    action: getActions('LIST').value,
    moduleKey: 'marketing-email-templates',
    // order: 62,
  },
  ADD_MARKETING_EMAIL_TEMPLATE: {
    path: '/admin/marketing-email-template/add',
    name: 'Marketing Email Template',
    key: 'marketing.marketingTemplate.marketing-email-templates',
    action: getActions('EDIT').value,
    moduleKey: 'marketing-email-templates',
  },
  EDIT_MARKETING_EMAIL_TEMPLATE: {
    path: '/admin/marketing-email-template/edit/:id',
    name: 'Marketing Email Template',
    key: 'marketing.marketingTemplate.marketing-email-templates',
    action: getActions('EDIT').value,
    moduleKey: 'marketing-email-templates',
  },
  MARKETING_SMS_TEMPLATE: {
    path: '/admin/marketing-sms-template',
    name: 'Marketing SMS Template',
    key: 'marketing.marketingTemplate.marketing-sms-templates',
    action: getActions('LIST').value,
    moduleKey: 'marketing-sms-templates',
  },
  MARKETING_SMS_EDIT_TEMPLATE: {
    path: '/admin/marketing-template/sms/edit',
    name: 'Edit Marketing SMS Templates',
    key: 'marketing.marketingTemplate.marketing-sms-templates',
    moduleKey: 'marketing-sms-templates',
    action: getActions('EDIT').value,
  },
  MARKETING_SMS_ADD_TEMPLATE: {
    path: '/admin/marketing-template/sms/add',
    name: 'Add Marketing SMS Templates',
    key: 'marketing.marketingTemplate.marketing-sms-templates',
    moduleKey: 'marketing-sms-templates',
    action: getActions('EDIT').value,
  },
  MARKETING_REVIEW_TEMPLATE: {
    path: '/admin/marketing-review-message',
    name: 'Marketing Review Message',
    key: 'marketing.marketingTemplate.marketing-review-message',
    action: getActions('LIST').value,
    moduleKey: 'marketing-review-message',
  },
  MARKETING_REVIEW_TEMPLATE_UNSUBSCRIBE_USER: {
    path: '/admin/marketing-template/marketing-review/unsubscribe-user',
    name: 'Marketing Review Template Unsubscribe User',
    action: getActions('LIST').value,
    moduleKey: 'marketing-review-message',
    key: 'marketing.marketingTemplate.marketing-review-message',
  },
  MARKETING_REVIEW_TEMPLATE_REPORT: {
    path: '/admin/marketing-template/marketing-review/report',
    name: 'Marketing Review Template Report',
    action: getActions('LIST').value,
    moduleKey: 'marketing-review-message',
    key: 'marketing.marketingTemplate.marketing-review-message',
  },
  MARKETING_MESSAGE_SEND: {
    path: '/admin/marketing-message-send',
    name: 'Marketing Message Send',
    key: 'marketing.marketingTemplate.marketing-send-message',
    action: getActions('LIST').value,
    moduleKey: 'marketing-send-message',
  },
};
export const getChildrenRoutes = () => {
  return route()[0]?.children;
};
