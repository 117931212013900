import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  CommonButton,
  GlobalLoader,
  ModalWrapper,
  NoDataFound,
  PageContainerWrapper,
  QuestionForm,
} from '../../../components';
import { accessRoute } from '../../../routes/OperatingProcedure';
import { addQuestions } from '../../../services/Columns';
import {
  addQuestionService,
  deleteQuestionService,
  getQuestionNotificationService,
  questionDetailsService,
  updateLessonStatus,
  updateQuestionsService,
} from '../../../services/index.service';
import {
  backToPage,
  decodeQueryData,
  FireAlert,
  logger,
  modalNotification,
} from '../../../utils';
import { getQuestionService } from '../../../services/index.service';
function AddQuestion() {
  const location = useLocation();
  const params = location?.search && decodeQueryData(location?.search);
  const [state, setState] = useState({
    loader: null,
    modal: null,
    modalId: null,
    selectedRows: [],
    submitText: 'add',
    editLoader: false,
    deleteLoader: false,
  });
  const [questions, setQuestions] = useState([]);
  const { t } = useTranslation();
  const breadcrumb = addQuestions();
  const navigate = useNavigate();
  const redirectUrl = accessRoute.OPERATING_PROCEDURE.path;
  const { loader, modal, modalId, submitText, editLoader, deleteLoader } =
    state;
  const { id, isActive, title } = params;
  const [formData, setFormData] = useState({});
  const [newId, setId] = useState(null);
  const [isCheckError, setIsCheckError] = useState(false);
  const [type, setType] = useState('');
  const [lenError, setLenError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  async function getQuestionsList() {
    setLoading(true);
    try {
      let queryParams = {
        lessonId: id,
      };
      const { data, success } = await getQuestionService({ queryParams });
      if (success) {
        setQuestions(data?.rows);
      }
      setLoading(false);
    } catch (err) {
      logger(err);
      setLoading(false);
    }
  }

  async function getDetails(id) {
    setState({ ...state, editLoader: true });
    try {
      const { data, success } = await questionDetailsService(id);
      if (success) {
        let resData = { ...data };
        resData.options = {};
        resData.answerKey = [];
        resData?.LessonQuestionOptions?.forEach((item, key) => {
          resData.options[key] = {
            option: item?.option,
            isCorrect: item?.isCorrect,
          };
        });
        setFormData(resData);
        setState({
          ...state,
          modal: 'edit',
          modalId: modalId,
          editLoader: false,
        });
      } else {
        setState({ ...state, editLoader: false });
      }
    } catch (err) {
      logger(err);
      setState({ ...state, editLoader: false });
    }
  }

  async function handleStatus(status) {
    try {
      const payload = {
        lessonIds: [searchParams.get('id')],
        status: status ? 'active' : 'inactive',
      };
      const { success, message } = await updateLessonStatus(payload);
      if (success) {
        modalNotification({
          type: 'success',
          message,
        });
        setSearchParams({
          ...params,
          isActive: isActive === 'active' ? 'inactive' : 'active',
        });
      }
      return success ? true : false;
    } catch (err) {
      logger(err);
      return false;
    }
  }

  useEffect(() => {
    if (modalId) {
      getDetails(modalId);
    }
  }, [modalId]);

  const onConfirmAlert = async (deleteId) => {
    FireAlert({
      title: t('text.common.areYouSure'),
      text: t('text.operating.areYouSureDeleteQuestion'),
      icon: t('text.common.warning'),
      confirmButtonText: t('text.common.yes'),
      cancelButtonText: t('text.common.no'),
      preConfirm: () => onDelete(deleteId),
    });

    return true;
  };

  const onNotificationAlert = async () => {
    FireAlert({
      title: t('text.operating.areYouSureNot'),
      text: t('text.operating.areYouSureNotification'),
      icon: t('text.common.warning'),
      confirmButtonText: t('text.common.yes'),
      cancelButtonText: t('text.common.no'),
      preConfirm: () => onNotificationSend(),
    });
    return true;
  };

  const headerBtn = {
    add: {
      name: t('text.operating.addQuestion'),
      handler: () => {
        setState({ ...state, submitText: 'Add', modal: 'add' });
      },
    },
    create: {},
    extraButton: {},
    toggle: {
      status: isActive,
      toggleLoader: loading,
      handler: handleStatus.bind(this),
    },
  };
  function onCancel() {
    setId(null);
    setState({ ...state, modal: null, modalId: null });
    setFormData({});
  }

  // For Add Questions
  async function onSubmit(values) {
    setLoading(true);
    try {
      let bodyData = { ...values };

      delete bodyData.isCorrect;
      bodyData.lessonId = id;

      bodyData.options = Object?.values(values?.options)?.map((e) => {
        e.isCorrect = Number(e.isCorrect);
        return e;
      });
      setType(values.questionType);
      let check = Object.values(values?.options)?.some(
        (e) => Number(e.isCorrect) === 1
      );

      let new1 = Object.values(values?.options)?.filter(
        (e) => Number(e.isCorrect) === 1
      );
      let error = false;
      if (new1?.length > 1 && values?.questionType === 'single_answer') {
        setLenError(true);
        error = true;
      } else {
        setLenError(false);
        error = false;
      }
      if (check === true && !error) {
        setIsCheckError(false);

        const { success, message } = newId
          ? await updateQuestionsService({ id: newId, bodyData })
          : await addQuestionService({ bodyData });

        if (success && message) {
          setId(null);
          modalNotification({
            type: 'success',
            message,
          });
          onCancel();
          getQuestionsList();
        }
      } else {
        setIsCheckError(true);
      }
    } catch (err) {
      logger(err);
    }
    setLoading(false);
  }

  const modalForm = {
    add: (
      <QuestionForm
        onSubmit={onSubmit}
        onCancel={onCancel}
        loading={loading}
        modalId={modalId}
        submitText={submitText}
        state={state}
        setState={setState}
        setFormData={setFormData}
        formData={formData}
        isCheckError={isCheckError}
        setIsCheckError={setIsCheckError}
        lenError={lenError}
        type={type}
        setLenError={setLenError}
      />
    ),
  };
  // For delete
  async function onDelete(deleteId) {
    try {
      const { success, message } = await deleteQuestionService(deleteId);
      if (success && message) {
        modalNotification({
          type: 'success',
          message,
        });
        getQuestionsList();
      }
    } catch (err) {
      logger(err);
    }
  }
  //For notification
  async function onNotificationSend() {
    try {
      // let queryParams = {
      //   id: id,
      // };
      const { success, message } = await getQuestionNotificationService({
        id,
      });
      if (success && message) {
        modalNotification({
          type: 'success',
          message,
        });
        getQuestionsList();
      }
    } catch (err) {
      logger(err);
    }
  }

  useEffect(() => {
    getQuestionsList();
  }, []);

  return (
    <div className="wide-xl mx-auto">
      <PageContainerWrapper
        breadcrumb={breadcrumb}
        heading={t('text.operating.addQuestion')}
        btnArray={['extraButton']}
        btn={headerBtn}
        extraBtnText={t('text.common.back')}
        extraBtnClass="btn btn-outline-light bg-white"
        onExtraButtonHandleShow={() => backToPage(navigate, redirectUrl)}
        addBtnText={t('text.operating.sendNotification')}
        handleFilterShow={() => {
          onNotificationAlert();
        }}
        disabled={
          (questions?.length > 0 ? false : true) ||
          (isActive === 'active' ? false : true)
        }
        extraClass="btn btn-primary"
        multipleButtonClass={false}
        tooltip={true}
        toolTipText={
          isActive === 'active' ? null : t('text.operating.statusChangeToolTip')
        }
      >
        <ModalWrapper
          heading={`${
            submitText === 'Add'
              ? t('text.operating.addQuestion')
              : t('text.operating.editQuestion')
          }`}
          onCancel={onCancel}
          content={modalForm['add']}
          show={['add', 'edit'].includes(modal)}
        />

        <h4 className="title" style={{ wordBreak: 'break-all' }}>
          {t('text.operating.lessonTitle')} : {title}
        </h4>

        <div className="nk-block">
          <div className="card">
            <div className="card-inner">
              {loading ? (
                <GlobalLoader />
              ) : questions?.length > 0 ? (
                <>
                  <p className="mb-0">
                    {t('text.operating.pleaseSendNotification')}
                  </p>
                  {questions.map((item, itemIdx) => {
                    return (
                      <div key={itemIdx} className="bq-note mt-3">
                        <div className="bq-note-text">
                          <div className="nk-block-head nk-block-head-sm nk-block-between g-1">
                            <h5 className="title">{item.question}</h5>
                            <div className="d-flex">
                              <CommonButton
                                onClick={() => {
                                  setState({
                                    ...state,
                                    submitText: 'Update',
                                    modal: null,
                                    modalId: item?.id,
                                  });
                                  setId(item?.id);
                                }}
                                className="btn btn-primary btn-sm"
                                loading={editLoader && modalId === item?.id}
                              >
                                <em className="icon ni ni-edit me-1"></em>
                                {t('text.common.edit')}
                              </CommonButton>
                              {questions?.length > 1 && (
                                <CommonButton
                                  onClick={() => {
                                    onConfirmAlert(item?.id);
                                    document.body.click();
                                  }}
                                  className="btn btn-danger btn-sm ms-2"
                                >
                                  <em className="icon ni ni-trash me-1"></em>
                                  {t('text.common.delete')}
                                </CommonButton>
                              )}
                            </div>
                          </div>
                          <>
                            {item?.LessonQuestionOptions?.length > 0 &&
                              item?.LessonQuestionOptions?.map((data, idx) => {
                                return (
                                  <ul
                                    key={idx}
                                    className={`list list-sm ${
                                      data.isCorrect === 1 ? 'list-checked' : ''
                                    }`}
                                  >
                                    <li>{data.option}</li>
                                  </ul>
                                );
                              })}
                          </>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <NoDataFound
                  source={`/no-data-found-img.svg`}
                  text={t('text.common.noQuestionFound')}
                  isCustomer={'/assets/images/admin/no-data-found-img.svg'}
                />
              )}
            </div>
          </div>
        </div>
      </PageContainerWrapper>
    </div>
  );
}
export default AddQuestion;
