export const ADDRESS_ENDPOINT = {
  getUserAddress: {
    url: '/v1/admin/address',
    method: 'GET',
  },
  addUserAddress: {
    url: '/v1/admin/address',
    method: 'POST',
  },
  viewUserAddress: (id) => ({
    url: `/v1/address/${id}`,
    method: 'GET',
  }),
  editUserAddress: (id) => ({
    url: `/v1/admin/address/${id}`,
    method: 'PUT',
  }),
};
