import React, { useState } from 'react';
import {
  CommonButton,
  FormElementWrapper,
  FormWrapper,
  ModalComponent,
  ImageElement,
  PageContainerWrapper,
  BulkUploadInput,
  MultiUpload,
  GlobalLoader,
} from '../../../../components';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import { bulkUploadBreadcrumb } from '../../../../services/Columns';

import {
  downloadDemoFileService,
  uploadBulkFileService,
  uploadBulkImageService,
} from '../../../../services/QuoteManagement/uploadBulk.service';
import { modalNotification } from '../../../../utils';
import { Media } from '../../../../apiEndpoints/index.endpoint';
import { bulkValidation, validation } from './validation';

function BulkUpload() {
  const [state, setState] = useState({
    isLoading: false,
  });
  const { isLoading } = state;
  const [uploadFilter, setUploadFilter] = useState(false);
  const breadcrumb = bulkUploadBreadcrumb();
  const [imageArray, setImageArray] = useState([]);
  const [basePathArray, setBasePathArray] = useState([]);

  const initialValues = {
    basePath: '',
    bulkUploadImage: '',
  };

  const uploadFilterShow = () => {
    setUploadFilter(true);
  };
  const uploadFilterHide = () => {
    setUploadFilter(false);
    setImageArray([]);
  };

  async function downloadDemoFile() {
    try {
      const response = await downloadDemoFileService();
      window.open(response?.data?.quoteSampleFileUrl);
    } catch (err) {
      console.log(err);
    }
  }

  const headerBtn = {
    csvExport: { handler: () => downloadDemoFile() },
    add: {
      name: t('text.quote.uploadBtnText'),
      handler: () => uploadFilterShow(),
    },
  };

  async function uploadBulkFile(value) {
    setState({ isLoading: true });
    try {
      let bodyData = {
        basePath: value?.basePath,
      };
      const response = await uploadBulkFileService({ bodyData });
      if (response?.success) {
        modalNotification({
          type: 'success',
          message: response?.message,
        });
      }
    } catch (err) {
      console.log(err);
    }
    setState({ isLoading: false });
  }

  const mediaUrl = (data) => {
    let tempArray = imageArray;
    let Arr = basePathArray;
    const { basePath } = data;
    Arr.push({ basePath });
    setBasePathArray([...Arr]);

    tempArray.push({ data });
    setImageArray([...tempArray]);
  };

  const removeBulkImage = (index) => {
    let tempArray = imageArray;
    let pathArray = basePathArray;
    tempArray.splice(index, 1);
    pathArray.splice(index, 1);
    setImageArray([...tempArray]);
    setBasePathArray([...pathArray]);
  };

  async function uploadBulkImage() {
    try {
      let bodyData = {
        quoteImages: basePathArray?.map((item) => {
          return item?.basePath;
        }),
      };

      const response = await uploadBulkImageService({ bodyData });
      if (response?.success) {
        modalNotification({
          type: 'success',
          message: response?.message,
        });
        setBasePathArray([]);
        uploadFilterHide();
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <div className="wide-md mx-auto">
        <PageContainerWrapper
          heading="Upload Bulk Data"
          breadcrumb={breadcrumb}
          btn={headerBtn}
          extraBtnClass="btn btn-outline-light bg-white"
        />
        <div className="nk-block">
          <div className="card">
            <div className="card-inner">
              <FormWrapper
                row={true}
                extraClassName="g-3"
                initialValues={{ ...initialValues }}
                onSubmit={uploadBulkFile}
                validation={validation}
              >
                {(props) => (
                  <>
                    <FormElementWrapper
                      md={12}
                      label={t('text.quote.uploadLabel')}
                      element={() => (
                        <>
                          <BulkUploadInput
                            imageUrlList={props?.values?.basePath}
                            formik={props}
                            name="basePath"
                            mediaFor="csv"
                            mediaType="doc"
                            allowedFormat="doc"
                            isImagePreview={false}
                            isRemovePreview={false}
                          />
                        </>
                      )}
                    />
                    <FormElementWrapper
                      md={12}
                      className="text-end"
                      element={() => (
                        <>
                          <CommonButton
                            type="submit"
                            shouldPermissionApply={true}
                            className="btn btn-primary me-2"
                            loading={isLoading}
                          >
                            {t('text.quote.uploadBtn')}
                          </CommonButton>
                          <CommonButton
                            className="btn btn-light"
                            type="reset"
                            onClick={() => {
                              props.resetForm({ ...initialValues });
                            }}
                          >
                            {t('text.common.reset')}
                          </CommonButton>
                        </>
                      )}
                    />
                  </>
                )}
              </FormWrapper>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent
        backdrop
        show={uploadFilter}
        onHandleCancel={uploadFilterHide}
        title={t('text.quote.uploadBtnText')}
      >
        <FormWrapper
          row={true}
          initialValues={{ ...initialValues }}
          onSubmit={uploadBulkImage}
          validation={bulkValidation}
        >
          {() => (
            <>
              <div className="col-md-12">
                <FormElementWrapper
                  md={12}
                  element={() => (
                    <MultiUpload
                      name="bulkUploadImage"
                      apiEndPoints={Media.bulkUploadImage}
                      type="image"
                      id="bulkUploadImage"
                      mediaUrl={mediaUrl}
                      applyImageCropper={true}
                      validateFileType={[
                        'image/jpeg',
                        'image/png',
                        'image/jpg',
                      ]}
                    />
                  )}
                />
              </div>
              <div className="col-md-12">
                <div className="nk-upload-list mt-2">
                  {imageArray?.map((item, index) => (
                    <div key={index} className="nk-upload-item">
                      <div className="nk-upload-icon">
                        <ImageElement
                          previewSource={item?.data?.basePathThumbUrl}
                          alt="img"
                        />
                      </div>
                      <div className="nk-upload-info">
                        <div className="nk-upload-title">
                          <span className="title">{item?.data?.name}</span>
                        </div>
                      </div>
                      <div className="nk-upload-action">
                        <Link
                          to="#"
                          onClick={() => removeBulkImage(index)}
                          className="btn btn-icon btn-trigger"
                        >
                          <em className="icon ni ni-trash"></em>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-md-12 text-end mt-2">
                <CommonButton
                  className="btn btn-primary me-2"
                  disabled={isLoading}
                  type="submit"
                >
                  {t('text.common.upload')}
                  {isLoading && (
                    <GlobalLoader animation="grow" size="sm" variant="white" />
                  )}
                </CommonButton>
                <CommonButton
                  onClick={() => uploadFilterHide()}
                  className="btn btn-secondary"
                >
                  {t('text.common.cancel')}
                </CommonButton>
              </div>
            </>
          )}
        </FormWrapper>
      </ModalComponent>
    </>
  );
}

export default BulkUpload;
