import React, { useEffect, useState } from 'react';
import { CommonButton, GlobalLoader, TablePlus } from '../../components';
import { useTranslation } from 'react-i18next';
import { logger } from '../../utils';
import { checkValidData } from '../../components/UIElements/Formatter';

function ImportRecordTable({
  handleDiscard,
  tableProps,
  tableColumns,
  recordData,
  handleSave,
  onSaveLoader,
  onDiscardLoader,
  loading = false,
}) {
  const [state, setState] = useState({
    column: [],
  });
  const { column } = state;
  const { t } = useTranslation();
  const { data, rows, isLoading, count, page, noOfPages, sizePerPage } =
    tableProps;

  const columnUpdate = (data) => {
    try {
      let arr = [
        {
          title: 'Id',
          dataIndex: 'id',
        },
      ];

  
      data.forEach((item) => {
        let { sorter, onSort, filterDropdown, ...rest } = item;
        logger(sorter, onSort, filterDropdown);
        if (
          item?.title !== t('text.common.serialNo') &&
          item?.title !== t('text.common.action')
        ) {
          arr.push({
            ...rest,
            ...(rest?.title === 'Customer Tag' && {
              dataIndex: 'customerTags',
              render: (cell, row) => checkValidData(cell),
            }),
            ...(rest?.title === 'Job Tag' && {
              dataIndex: 'jobTags',
              render: (cell, row) => checkValidData(cell),
            }),
          });
        }
      });
       arr = arr.filter(column => column.dataIndex !== "status");
      setState({ ...state, column: arr });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (tableColumns && tableColumns.length) {
      columnUpdate(tableColumns);
    }
  }, [tableColumns]);

  return (
    <>
      <div className="card">
        <div className="card-inner">
          <div className="row align-items-center">
            <div className="col-xxl-7">
              <ul className="d-md-flex justify-content-between">
                <li className="color-upload me-3 me-lg-4 me-xxl-5">
                  {t('text.lineItems.totalRecordUpload')}:{' '}
                  <b>{recordData?.totalRecord}</b>
                </li>
                <li className="color-update me-3 me-lg-4 me-xxl-5">
                  {t('text.lineItems.recordToUpdate')} :{' '}
                  <b>{recordData?.updateRecord}</b>
                </li>
                <li className="color-insert me-3 me-lg-4 me-xxl-5">
                  {t('text.lineItems.recordToInsert')}:{' '}
                  <b>{recordData?.insertData}</b>
                </li>
              </ul>
            </div>
            <div className="col-xxl-5 text-xxl-end mt-2 mt-lg-3 mt-xxl-0">
              <CommonButton
                className="btn btn-primary me-2 mt-1 mt-sm-0"
                disabled={onSaveLoader || onDiscardLoader}
                onClick={handleSave}
              >
                {onSaveLoader && (
                  <GlobalLoader animation="grow" size="sm" variant="white" />
                )}
                {t('text.common.saveUploadedData')}
              </CommonButton>
              <CommonButton
                className="btn btn-secondary mt-1 mt-sm-0"
                onClick={handleDiscard}
                disabled={onDiscardLoader || onSaveLoader}
              >
                {onDiscardLoader && (
                  <GlobalLoader animation="grow" size="sm" variant="white" />
                )}
                {t('text.common.discardEndBack')}
              </CommonButton>
            </div>
          </div>
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage={noOfPages || 0}
        sizePerPage={sizePerPage || 10}
        page={page || 1}
        count={count}
        tableData={data || rows}
        tableColumns={column}
        tableLoader={loading || isLoading}
        resetAll={false}
        pagination={false}
      />
    </>
  );
}

export default ImportRecordTable;
