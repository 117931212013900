import { APIRequest } from '../../helpers/index.helper';
import { LINE_ITEMS_ENDPOINT } from '../../apiEndpoints/LineItems/index.endpoint';
import { logger } from '../../utils';

export const LineItemsListService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...LINE_ITEMS_ENDPOINT.getLineItemsList,
      queryParams,
    };

    const res = await APIRequest(payload);
    if (type === 'poGenerated') {
      return [{ partNumber: 'PO Generated' }, ...res?.data?.rows];
    }
    if (type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const addLineItemsService = async ({ body }) => {
  try {
    const payload = {
      ...LINE_ITEMS_ENDPOINT.addLineItems,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const editLineItemsService = async ({ id, body }) => {
  try {
    const payload = {
      ...LINE_ITEMS_ENDPOINT.editLineItems(id),
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const deleteLineItemsService = async ({ bodyData }) => {
  try {
    const payload = {
      ...LINE_ITEMS_ENDPOINT.deleteLineItems(bodyData?.id),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getLineItemsByIdService = async (resourceId) => {
  try {
    const payload = {
      ...LINE_ITEMS_ENDPOINT.getLineItemsById(resourceId),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const importLineItemsService = async ({ body }) => {
  try {
    const payload = {
      ...LINE_ITEMS_ENDPOINT.importLineItems,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const lineItemsPartService = async () => {
  try {
    const payload = {
      ...LINE_ITEMS_ENDPOINT.lineItemPart,
    };
    const res = await APIRequest(payload);
    const { success } = res;
    if (success) {
      return res?.data?.rows;
    }
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
// Part listing
export const partListService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...LINE_ITEMS_ENDPOINT.getPartList,
      queryParams,
    };

    const res = await APIRequest(payload);
    if (type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

// Import file
export const LineImportListService = async (queryParams) => {
  try {
    const payload = {
      ...LINE_ITEMS_ENDPOINT.getImportList,
      queryParams,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const saveImportService = async ({ body }) => {
  try {
    const payload = {
      ...LINE_ITEMS_ENDPOINT.saveImport,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const discardImportService = async ({ body }) => {
  try {
    const payload = {
      ...LINE_ITEMS_ENDPOINT.discardImport,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getPartTitleItems = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...LINE_ITEMS_ENDPOINT.getPartTitleItems,
      queryParams,
    };

    const res = await APIRequest(payload);
    if (type === 'poGenerated') {
      return [{ name: 'PO Generated' }, ...res?.data?.rows];
    }
    if (type === 'all') {
      return res?.data?.rows;
    }

    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
