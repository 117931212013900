import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb as BreadcrumbItem } from 'react-bootstrap';

export default function Breadcrumb({ breadcrumb, title, type }) {
  if (type === 'customer' || type === 'store') {
    return (
      <div className="breadSec">
        <h1 className="breadSec_title mb-0">{title}</h1>
        <BreadcrumbItem>
          {breadcrumb.map((item, key) => {
            return item.path !== '#' ? (
              <BreadcrumbItem.Item href={item.path} key={key}>
                {item.name}
              </BreadcrumbItem.Item>
            ) : (
              <BreadcrumbItem.Item active key={key}>
                {item.name}
              </BreadcrumbItem.Item>
            );
          })}
        </BreadcrumbItem>
      </div>
    );
  }
  return (
    <>
      {breadcrumb && (
        <nav>
          <ul className="breadcrumb breadcrumb-arrow">
            {breadcrumb.map((item, key) => {
              return item.path !== '#' ? (
                <li className="breadcrumb-item" key={key}>
                  <Link to={item.path}>{item.name}</Link>
                </li>
              ) : (
                <li className="breadcrumb-item active" key={key}>
                  {item.name}
                </li>
              );
            })}
          </ul>
        </nav>
      )}
    </>
  );
}
