import React, { useState } from 'react';
import { Link, generatePath, useNavigate } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Button } from 'antd';
import { CancelBookingForm } from '../../Forms/index.form';
import { ImageElement, ModalWrapper } from '../../../components';
import { firstLetterCaps } from '../../../utils/text.util';
import { logger, modalNotification } from '../../../utils';
import GlobalLoader from '../../../components/UIElements/GlobalLoader';

import { putCustomerBookingCancel } from '../../../services/CustomerApp/index.service';
import { accessRoute } from '../../../routes/CustomerApp/booking';
import { accessRoute as customerRoute } from '../../../routes/CustomerApp/payment';

import NoDataFound from '../../UIElements/NoDataFound';

function MyBookingCards({ cardKey, myBookings, getMyBookingLists, isLoading }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [state, setState] = useState({
    isOpenCancelBookingModal: false,
    isLoading: false,
    selectedCancelBookingId: '',
  });

  const handleClose = () => {
    setState({
      ...state,
      isOpenCancelBookingModal: false,
      isLoading: false,
    });
  };

  const handleShow = (bookingId) => {
    setState({
      ...state,
      selectedCancelBookingId: bookingId,
      isOpenCancelBookingModal: true,
    });
  };

  const handleCancelBooking = async (e) => {
    e.defaultPrevented = true;
    try {
      setState({ ...state, isLoading: true });
      const response = await putCustomerBookingCancel({
        bookingId: state?.selectedCancelBookingId,
        declineReason: e?.reason,
      });
      if (response.success) {
        modalNotification({
          type: 'success',
          message: 'Your booking is successfully cancelled.',
        });
        setState({
          ...state,
          isLoading: false,
          isOpenCancelBookingModal: false,
        });
        let page = 1;
        getMyBookingLists(page);
      } else {
        setState({ ...state, isLoading: false });
      }
    } catch (error) {
      logger(error);
      setState({ ...state, isLoading: false });
    }
  };

  let textObj = {
    begin: 'Active',
    arrived: 'Arrived',
    start: 'On Going',
  };

  return (
    <div>
      <Row className="g-3">
        {!isLoading && myBookings?.length > 0 ? (
          myBookings?.map((item, itemIdx) => {
            return (
              <Col
                onClick={() => {
                  if (
                    cardKey === 'past' &&
                    item?.bookingStatus === 'completed'
                  ) {
                    navigate(
                      generatePath(customerRoute.CUSTOMER_PAYMENT.path, {
                        id: item?.id,
                      }),
                      {
                        state: item?.id,
                      }
                    );
                  }
                }}
                style={
                  cardKey === 'past' && item?.bookingStatus === 'Completed'
                    ? { cursor: 'pointer' }
                    : null
                }
                key={itemIdx}
                xl={4}
                md={6}
              >
                <div className="bookingBox">
                  <Card>
                    <Card.Header>
                      <ul className="list-unstyled mb-0 d-sm-flex flex-wrap">
                        <li>
                          {t('text.customerMyBooking.bookingID')} :
                          <span>{item?.bookingNo} </span>
                        </li>
                        {item?.quantity && (
                          <li>
                            {t('text.customerMyBooking.quantity')} :
                            <span>{item?.quantity}</span>
                          </li>
                        )}

                        <li>
                          {t('text.customerMyBooking.status')} :
                          <span className="text-500">
                            {cardKey === 'ongoing'
                              ? textObj[item?.BookingDetail?.serviceStatus]
                              : firstLetterCaps(item?.bookingStatus)}
                          </span>
                        </li>
                      </ul>
                    </Card.Header>
                    <Card.Body>
                      {item?.ServiceCategory?.name && cardKey !== 'ongoing' && (
                        <Card.Title>
                          {item?.ServiceCategory?.callingServiceName &&
                            `${item?.ServiceCategory?.callingServiceName} - &nbsp`}
                          {firstLetterCaps(
                            item?.ServiceCategory?.ServiceCategoryDetails?.name
                          )}{' '}
                          ({firstLetterCaps(item?.ServiceCategory?.name)})
                        </Card.Title>
                      )}

                      {cardKey === 'past' && (
                        <div className="bookingBox_dateTime mb-1">
                          <span>
                            <em className="gg-calendar me-2" />
                            {moment(item?.bookingScheduleDate).format('ddd LL')}
                            &nbsp;
                            {item?.rescheduleNote
                              ? moment(item?.bookingScheduleDate).format(
                                  'hh:mm A'
                                )
                              : item?.bookingType === 'schedule' &&
                                item?.slotEndDate &&
                                item?.slotStartDate
                              ? `at ${moment(item?.slotStartDate).format(
                                  'hh:mm A'
                                )} - ${moment(item?.slotEndDate).format(
                                  'hh:mm A'
                                )}`
                              : moment(item?.bookingScheduleDate).format(
                                  'hh:mm A'
                                )}
                          </span>
                        </div>
                      )}
                      <div
                        className={` d-flex align-items-md-center flex-grow-1 ${
                          cardKey === 'ongoing' || cardKey === 'request'
                            ? 'bookingBox_cnt'
                            : 'mb-2'
                        }`}
                      >
                        {cardKey === 'ongoing' && (
                          <div className="bookingBox_img flex-shrink-0">
                            <ImageElement
                              previewSource={
                                item?.Provider?.profilePictureThumbUrl
                              }
                              className="img-fluid"
                            />
                          </div>
                        )}
                        <div className="bookingBox_serviceAdderss text-break flex-grow-1">
                          <div className="bookingBox_location">
                            <em className="gg-marker me-2" />
                            {t('text.customerMyBooking.serviceAddress')}
                          </div>
                          <p className="bookingBox_adderss mb-0">
                            {item?.Address?.addressLine1
                              ? item?.Address?.addressLine1
                              : 'No Address'}
                          </p>
                        </div>
                      </div>
                      {cardKey === 'upcoming' && (
                        <div className="bookingBox_dateTime mb-1">
                          <span>
                            <em className="gg-calendar me-2" />
                            {moment(item?.bookingScheduleDate).format('ddd LL')}
                            &nbsp;
                            {item?.rescheduleNote
                              ? moment(item?.bookingScheduleDate).format(
                                  'hh:mm A'
                                )
                              : item?.bookingType === 'schedule' &&
                                item?.slotEndDate &&
                                item?.slotStartDate
                              ? `at ${moment(item?.slotStartDate).format(
                                  'hh:mm A'
                                )} - ${moment(item?.slotEndDate).format(
                                  'hh:mm A'
                                )}`
                              : moment(item?.bookingScheduleDate).format(
                                  'hh:mm A'
                                )}
                          </span>
                        </div>
                      )}

                      {cardKey === 'past' && (
                        <div className="bookingBox_dateTime mb-1">
                          <span>
                            <em className="gg-payment-status me-2" />
                            {t('text.customerMyBooking.paymentStatus')} :
                            <span className="status text-500">
                              {item?.BookingDetail?.paymentStatus
                                ? firstLetterCaps(
                                    item?.BookingDetail?.paymentStatus
                                  )
                                : 'Pending'}
                            </span>
                          </span>
                        </div>
                      )}

                      {!item?.paymentStatus && item?.dateAndTime && (
                        <div className="bookingBox_dateTime mb-1">
                          <span>
                            <em className="gg-calendar me-2" />
                            {item?.dateAndTime}
                          </span>
                        </div>
                      )}
                      {cardKey === 'ongoing' && (
                        <Link
                          // to={accessRoute.SERVICE_TRACK.path}
                          to={generatePath(accessRoute.SERVICE_TRACK.path, {
                            bookingId: item?.id,
                          })}
                          state={{ bookingId: item?.id }}
                          className="btn btn-outline-primary w-100"
                        >
                          {t('text.customerMyBooking.viewDetails')}
                        </Link>
                      )}
                      {cardKey === 'upcoming' ? (
                        item?.status === 'Cancelled' ? (
                          ''
                        ) : (
                          <Button
                            className="btn btn-outline-primary w-100"
                            onClick={() => {
                              handleShow(item?.id);
                            }}
                          >
                            {t('text.customerMyBooking.cancelBooking')}
                          </Button>
                        )
                      ) : (
                        ''
                      )}
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            );
          })
        ) : !isLoading && !myBookings?.length ? (
          <NoDataFound
            source="/no-booking.svg"
            text="No Booking Found"
            extraClass="noDataFoundWrapper"
            isCustomer={'/assets/images/admin/no-booking.svg'}
          />
        ) : (
          <GlobalLoader />
        )}
      </Row>
      {state?.isOpenCancelBookingModal && (
        <ModalWrapper
          title={t('text.customerMyBooking.cancelBookingTitle')}
          show={state?.isOpenCancelBookingModal}
          size="lg"
          onCancel={handleClose}
          content={
            <CancelBookingForm
              onCancel={handleClose}
              onSubmit={handleCancelBooking}
              isLoading={state?.isLoading}
            />
          }
        ></ModalWrapper>
      )}
    </div>
  );
}
export default MyBookingCards;
