let url = '/admin/category';
export const MANAGE_CATEGORY_ENDPOINT = {
  getCategoryList: {
    url: 'v1/admin/all-category',
    method: 'GET',
  },
  addCategory: {
    url: 'v1/category',
    method: 'POST',
  },
  changeCategoryStatus: (id) => ({
    url: `v1/admin/status-category/${id}`,
    method: 'PUT',
  }),
  deleteCategory: (id) => ({
    url: `v1/admin/category/${id}`,
    method: 'DELETE',
  }),
  editCategory: (id) => ({
    url: `v1/admin/categorys/${id}`,
    method: 'PUT',
  }),
  getCategoryDetails: (id) => ({
    url: `v1/admin/categoryDetail/${id}`,
    method: 'GET',
  }),
  getSubCategoryList: {
    url,
    method: 'GET',
  },
  addSubCategory: {
    url,
    method: 'POST',
  },
  changeSubCategoryStatus: {
    url: `${url}/change-status`,
    method: 'PUT',
  },
  deleteSubCategory: {
    url,
    method: 'DELETE',
  },
  editSubCategory: (id) => ({
    url: `${url}/${id}`,
    method: 'PUT',
  }),
  getSubCategoryDetails: (id) => ({
    url: `${url}/${id}`,
    method: 'GET',
  }),
  importCategoryItems: {
    url: `v1/admin/category/file`,
    method: 'POST',
  },
  getImportFileDetails: {
    url: `v1/admin/category/bulk/details`,
    method: 'GET',
  },
  saveImport: {
    url: `v1/admin/category/bulk/save`,
    method: 'POST',
  },
  discardImport: {
    url: `v1/admin/category/bulk/discard`,
    method: 'POST',
  },
};
