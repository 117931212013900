import i18next from 'i18next';
import { accessRoute } from '../../routes/Dashboard';
import {
  checkValidData,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import {
  getInputSearchFilter,
  getMultiSelectSearchFilter,
} from '../../components';
import { getTupleStatusAllList } from '../index.service';
import { logoImageFormatter, readMoreTextShow } from '../../utils';

export const willCallListTableColumn = ({
  Toggle,
  onSort,
  onFilterChange,
  filter,
  page,
  sizePerPage,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'logoThumbUrl',
      title: i18next.t('text.willCallList.icon'),
      headerClasses: 'sorting',
      render: (cell, row) =>
        logoImageFormatter(row?.ServiceCategoryDetails?.logoThumbUrl, ''),
    },
    {
      dataIndex: 'name',
      title: i18next.t('text.willCallList.categoryName'),
      headerClasses: 'sorting',
      sorter: false,
      render: (cell, row) => checkValidData(row?.ServiceCategoryDetails?.name),
    },
    {
      dataIndex: 'name',
      filterDataIndex: ['subCategory'],
      title: i18next.t('text.willCallList.subCategoryName'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'subCategory',
        placeholder: i18next.t('text.willCallList.subCategoryName'),
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'ServiceCategoryDetails',
      title: i18next.t('text.willCallList.location'),
      headerClasses: 'sorting',
      onSort: (field, order) => onSort('tagType', order),
      render: (cell, row) => {
        const data =
          cell?.ServiceCategoryLocations?.length > 0 &&
          cell?.ServiceCategoryLocations?.filter((item) => item?.Location)
            .map((e) => e?.Location)
            .map((loc) => loc?.name)
            .join(', ');
        return readMoreTextShow(
          checkValidData(data),
          i18next.t('text.common.location'),
          30
        );
      },
    },
    {
      dataIndex: 'isWillCallList',
      title: i18next.t('text.willCallList.title'),
      headerClasses: 'sorting',
      sort: true,
      onSort: (field, order) => onSort('status', order),
      render: (cell, row) => {
        return <Toggle row={row} cell={cell} />;
      },
      ...getMultiSelectSearchFilter({
        dataIndex: 'isWillCallList',
        onFilterChange,
        name: 'status',
        list: [],
        mode: false,
        listService: getTupleStatusAllList,
        filter,
      }),
    },
  ];
};

export const willCallListBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.willCallList.title'),
    },
  ];
};
