import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  AcountSidebar,
  AdminProfileForm,
  // Input,
  ModalComponent,
  PageHeader,
  // TextInput as Input,
} from '../../../components';
import { checkValidData } from '../../../components/UIElements/Formatter';
import { updateUserData } from '../../../redux/index.redux';
import { updateProfileService } from '../../../services/index.service';
import { logger, modalNotification } from '../../../utils';
import { getFullName } from '../../../utils/text.util';

export default function AdminProfile() {
  const user = useSelector((state) => state?.auth?.userData);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [asideView, setAsideView] = useState(false);

  const asideToggle = () => {
    setAsideView(!asideView);
  };
  if (asideView) {
    document.querySelector('body').classList.add('toggle-shown');
  } else {
    document.querySelector('body').classList.remove('toggle-shown');
  }
  const [showEdit, setShowEdit] = useState(false);

  const onCancel = () => {
    setShowEdit(false);
  };

  const onShow = () => {
    setShowEdit(true);
  };
  const onSubmit = async (values) => {
    try {
      let bodyData = { ...values };
      const res = await updateProfileService(bodyData);
      const { success, message, data } = res;
      if (success) {
        modalNotification({
          type: 'success',
          message,
        });
        onCancel();
      }
      dispatch(updateUserData(data));
    } catch (error) {
      logger(error);
    }
  };

  return (
    <>
      <div className="nk-block">
        <div className="card">
          <div className="card-aside-wrap">
            <div className="card-inner card-inner-lg">
              <div className="nk-block-head nk-block-head-lg">
                <div className="nk-block-between">
                  <PageHeader heading="Personal Information">
                    <p>{t('text.adminProfile.basicInfoLike')}</p>
                  </PageHeader>
                  <div className="nk-block-head-content align-self-start d-lg-none">
                    <Link
                      to="#"
                      onClick={() => asideToggle()}
                      className={`toggle btn btn-icon btn-trigger mt-n1 ${
                        asideView ? 'active' : ''
                      }`}
                    >
                      <em className="icon ni ni-menu-alt-r" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="nk-block">
                <div className="nk-data data-list">
                  <div className="data-head">
                    <h6 className="overline-title">
                      {t('text.adminProfile.basic')}
                    </h6>
                  </div>
                  <div className="data-item">
                    <div className="data-col">
                      <span className="data-label">
                        {' '}
                        {t('text.adminProfile.fullName')}
                      </span>
                      <span className="data-value">
                        {' '}
                        {getFullName(user?.firstName, null, user?.lastName)}
                      </span>
                    </div>
                    <div className="data-col data-col-end">
                      <span className="data-more disable">
                        <em className="icon ni ni-user-alt" />
                      </span>
                    </div>
                  </div>
                  <div className="data-item">
                    <div className="data-col">
                      <span className="data-label">
                        {' '}
                        {t('text.adminProfile.displayName')}
                      </span>
                      <span className="data-value">
                        {getFullName(user?.firstName)}
                      </span>
                    </div>
                    <div className="data-col data-col-end">
                      <span className="data-more disable">
                        <em className="icon ni ni-user-alt" />
                      </span>
                    </div>
                  </div>
                  <div className="data-item">
                    <div className="data-col">
                      <span className="data-label">
                        {t('text.adminProfile.email')}
                      </span>
                      <span className="data-value">
                        {checkValidData(user?.email)}
                      </span>
                    </div>
                    <div className="data-col data-col-end">
                      <span className="data-more disable">
                        <em className="icon ni ni-lock-alt" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AcountSidebar
              asideView={asideView}
              asideToggle={asideToggle}
              editProfileModal={onShow}
              t={t}
            />
          </div>
        </div>
      </div>
      <ModalComponent
        closeButton={false}
        extraTitleClassName="justify-content-center"
        onHandleCancel={() => {
          setShowEdit(false);
        }}
        backdrop
        show={showEdit}
        title={t('text.adminProfile.updateProfile')}
      >
        <AdminProfileForm
          onSubmit={onSubmit}
          t={t}
          onCancel={onCancel}
          user={user}
        />
      </ModalComponent>
    </>
  );
}
