/* eslint-disable no-empty-pattern */
import React from 'react';
import {
  ActionWrapper,
  CommonButton,
  GeoLocationFilterForm,
  PageContainerWrapper,
  TablePlus,
  Toggle,
} from '../../../../components';
import {
  deleteManageLocationService,
  getActions,
  ListManageLocationService,
  statusManageLocationService,
} from '../../../../services/index.service';

import useTable from '../../../../hooks/useTable.hook';
import { accessRoute } from '../../../../routes/ManageLocation';
import { accessRoute as NCARoute } from '../../../../routes/AdminSetting';
import {
  breadcrumb,
  geoLocationTableColumn,
} from '../../../../services/Columns';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

function ListGeoLocation({ editNca = true, userId = null }) {
  const { t } = useTranslation();
  const [state, setState] = React.useState({
    loader: null,
    modal: null,
    modalId: null,
    selectedRows: [],
    submitText: 'add',
  });

  const { selectedRows } = state;
  const { loader } = state;
  const userType = useSelector((state) => state?.auth?.userData?.Role?.type);
  const url =
    !editNca &&
    `${generatePath(NCARoute?.NCA_ADD_GEO_FENCE.path, {
      id: userId,
    })}`;

  const btn = {
    // filter: { handler: () => {}, name: 'Filter' },
    link: {
      name: t('text.manageLocation.addLocation'),
      url: editNca ? accessRoute.ADD_LOCATION.path : url,
    },
  };
  const breadcrumbData = breadcrumb();
  // Get table data by api
  let extraQuery = {
    userId,
  };
  const [tableHandler, tableProps] = useTable(
    ListManageLocationService,
    extraQuery
  );

  const {
    data,
    isLoading,
    count,
    page,
    noOfPages,
    sizePerPage,
    filter,
    sortBy,
    sortType,
  } = tableProps;
  const {
    onRefresh,
    onFilterChange,
    getData,
    onTableSort,
    onFilterResetChange,
    onToggleChange,
    onConfirmDeleteAlert,
  } = tableHandler;

  // Action for stats and delete
  function onAction(e, handler, item) {
    e.preventDefault();
    if (handler) handler(item);
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options?.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler, item)}
              key={key}
              className="action_list"
              action={item?.action}
            >
              {item?.name === 'Edit' ? (
                <Link to={`${accessRoute.EDIT_LOCATION.path}/${item?.id}`}>
                  <em className={item?.icon} /> {item?.name}
                </Link>
              ) : (
                <CommonButton>
                  <em className={item?.icon} /> {item?.name}
                </CommonButton>
              )}
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  // Columns
  const Columns = geoLocationTableColumn({
    onSort: onTableSort,
    onFilterChange: onFilterChange,
    filter: filter,
    page,
    sizePerPage,
    Action: (props) =>
      actionFormatter([
        {
          name: t('text.common.edit'),
          icon: 'icon ni ni-edit',
          id: props?.row?.id,
          action: getActions('EDIT').value,
        },
        userType === 'nca' && props?.row?.createdByUser?.Role?.type === 'admin'
          ? {}
          : {
              name: t('text.common.delete'),
              icon: 'icon ni ni-trash',
              action: getActions('DELETE').value,
              handler: () => {
                onConfirmDeleteAlert({
                  id: props?.row?.id,
                  text: t('text.common.deleteThisLocations'),
                  service: deleteManageLocationService,
                  key: 'geoFenceLocationIds',
                  userId,
                });
                document.body.click();
              },
            },
      ]),
    Toggle: (props) => {
      return (
        <Toggle
          isDropDown={true}
          defaultChecked={props?.row?.status}
          loading={loader === 'status-update'}
          onChange={(e) =>
            onToggleChange(
              e,
              props?.row,
              '',
              statusManageLocationService,
              'geoFenceLocationIds',
              true
            )
          }
        />
      );
    },
  });

  function onRowSelect(e) {
    try {
      setState({ ...state, selectedRows: [...e] });
    } catch (err) {
      console.log('err', err);
    }
  }

  async function handleActionSelect(e) {
    try {
      if (e?.target?.value === 'delete') {
        await onConfirmDeleteAlert({
          id: selectedRows,
          text: t('text.common.deleteThisLocations'),
          service: deleteManageLocationService,
          key: 'geoFenceLocationIds',
          userId,
        });
        getData();
      } else {
        await onToggleChange(
          e?.target?.value,
          selectedRows,
          'action',
          statusManageLocationService,
          'geoFenceLocationIds'
        );
        getData();
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <PageContainerWrapper
      breadcrumb={editNca && breadcrumbData}
      btn={btn}
      form={
        editNca && (
          <GeoLocationFilterForm
            onSubmit={onFilterChange}
            onRefresh={onRefresh}
          />
        )
      }
      heading={editNca ? t('text.manageLocation.heading') : ''}
    >
      <TablePlus
        hasLimit
        noOfPage={noOfPages}
        sizePerPage={sizePerPage}
        page={page}
        count={count}
        tableData={data}
        tableColumns={Columns}
        handleSelectedRows={onRowSelect}
        handleActionSelect={handleActionSelect}
        selectRow
        tableLoader={isLoading}
        onFilterResetChange={onFilterResetChange}
        filter={filter}
        sortBy={sortBy}
        sortType={sortType}
      />
    </PageContainerWrapper>
  );
}

export default ListGeoLocation;
