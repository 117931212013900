import { APIRequest } from '../../helpers/index.helper';
import {
  PROMO_CODE_ENDPOINT,
  STORE_ENDPOINT,
} from '../../apiEndpoints/index.endpoint';
import { logger, setLocalStorage } from '../../utils';

export const StoreListService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...STORE_ENDPOINT?.getStoreList,
      queryParams,
    };

    const res = await APIRequest(payload);

    if (res?.data?.rows && type === 'all') {
      setLocalStorage({ keyName: 'isScrollCount', data: res?.data?.count });
      return res?.data?.rows;
    }

    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const addStoreService = async ({ values }) => {
  try {
    const payload = {
      ...STORE_ENDPOINT?.addStore,
      bodyData: values,
    };
    return await APIRequest(payload);
  } catch (err) {
    console.log(err);
  }
};

export const deleteStoreService = async ({ bodyData }) => {
  try {
    const payload = {
      ...STORE_ENDPOINT?.deleteStore,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const storeStatusService = async (bodyData) => {
  try {
    const payload = {
      ...STORE_ENDPOINT?.storeStatusUpdate,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const getStoreServiceById = async (id) => {
  try {
    const payload = {
      ...STORE_ENDPOINT?.getStoreById(id),
    };
    const res = await APIRequest(payload);

    return res;
  } catch (err) {
    logger(err);
  }
};
export const editStoreService = async ({ id, values }) => {
  try {
    const payload = {
      ...STORE_ENDPOINT?.editStore(id),
      bodyData: values,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const getStoreDocumentService = async (id) => {
  try {
    const payload = {
      ...STORE_ENDPOINT?.getStoreDocument,
      queryParams: id,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getStorePaymentReportListService = async ({ queryParams }) => {
  try {
    const payload = {
      ...STORE_ENDPOINT?.getPaymentStoreList,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getStorePaymentReportSumService = async ({ queryParams }) => {
  try {
    const payload = {
      ...STORE_ENDPOINT?.getStorePaymentReportSum,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const marketingUnsubscribeService = async ({ body, userId }) => {
  try {
    const payload = {
      ...PROMO_CODE_ENDPOINT?.unSubscribeUser(userId),
      bodyData: body,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getRecipientCountService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...STORE_ENDPOINT?.getRecipientCount,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const addSendMessageTemplateService = async ({ values }) => {
  try {
    const payload = {
      ...STORE_ENDPOINT?.addSendMessageTemplate,
      bodyData: values,
    };
    return await APIRequest(payload);
  } catch (err) {
    console.log(err);
  }
};

export const getMarketingTagListService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...STORE_ENDPOINT?.getMarketingTags,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
