export const text = {
  alreadyAccount: 'Already Account?',
  checkOutAsGuest: 'Check Out As Guest',
  signUpNote:
    'Signing up allows you to schedule appointments, cancel appointments, view past receipts, pay online (contactless payments), view/approve quotes, track your technician on the way to your home, take advantage of any specials, and more.',
  alternativeEmail: 'Alternative Email Address',
  payNow: 'Pay Now',
  enterCvv: 'Enter Cvv',
  enterEmailAddress: 'Enter Email Address',
  remainingAmount: 'Remaining Amount',
  enterRemainingAmount: 'Enter Remaining Amount',
  totalAmount: 'Total Amount',
  enterTotalAmount: 'Enter Total Amount',
  enterServiceAddress: 'Entre Service Address',
  jobNumber: 'Job Number',
  enterJobNumber: 'Enter Job Number',
  OR: 'OR',
  signIn: 'Sign In',
  noPaymentRequired:'No Payment required',
};
