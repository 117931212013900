import React from 'react';
import { IMAGE_URL } from '../../../config';

function ImageElement({
  previewSource = '',
  source,
  alt = 'image',
  local = false,
  isImagePreview = true,
  isProfile = false,
  defaultImage = '/assets/images/admin/img_default-placeholder.png',
  isCustomer = false,
  ...rest
}) {
  if (local) {
    return <img src={previewSource} alt={alt} {...rest} />;
  }
  function onError({ currentTarget }) {
    try {
      currentTarget.onerror = null; // prevents looping
      currentTarget.src = isImagePreview
        ? isProfile
          ? '/assets/images/admin/profile-img.jpg'
          : isCustomer || defaultImage
        : '/assets/images/admin/file-excel-upload.svg';
    } catch (err) {
      return currentTarget;
    }
  }
  return (
    <>
      {previewSource ? (
        <img src={previewSource} alt={alt} {...rest} onError={onError} />
      ) : (
        <img
          src={`${IMAGE_URL}/${source}`}
          alt={alt}
          {...rest}
          onError={onError}
        />
      )}
    </>
  );
}

export default ImageElement;
