export const text = {
  title: 'Will Call List',
  icon: 'Icon',
  addTag: 'Add Tag',
  categoryName: 'Category Name',
  subCategoryName: 'Sub Category Name',
  location: 'Locations',
  noteOne:
    'Will Call List module will list all Same-day Emergency Services of all Main Category on this page.',
  noteTwo: 'Administrator can Activate / Deactivate Service.',
};
