import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';

// import ScrollToFieldError from '../../../utils/ScrollToFieldError';
import { Row, Col, Table } from 'react-bootstrap';
import {
  FormElementWrapper,
  FormWrapper,
  TextInput,
  CommonButton,
  ImageElement,
  Select,
  DatePicker,
  AntTextArea,
  ModalWrapper,
  NoDataFound,
} from '../../../components';
import Map from '../ManualBookingMap';
import AutoComplete from '../../../components/UIElements/AutoComplete/index';

import { GOOGLE_MAPS_API_KEY, GOOGLE_MAP_URL } from '../../../config';
import useLocationHook from '../../../hooks/useLocation.hook';
import {
  initValues,
  validation,
} from '../../../components/Forms/ManualBooking/validation';
import {
  getServiceTypeListsService,
  // getTimeSlotListsService,
  postCreateManualBookingService,
  getServiceProvidersListService,
  getAddressListByMobileNumberService,
  getProviderAvailabilityListService,
  providerAvailabilityOptions,
  zoomLevel,
  MODAL_TYPE,
  jobTypeOptions,
} from '../../../services/ManualBooking/manualBooking.service';
import { accessRoute } from '../../../routes/ScheduledBooking/scheduledBooking';
import { accessRoute as serviceManagementRoute } from '../../../routes/ServiceManagement/serviceManagement';
import { accessRoute as dashboardRoute } from '../../../routes/Dashboard/dashboard';
import { firstLetterCaps } from '../../../utils/text.util';
import {
  capitalizeFirstLetter,
  convertTo12HourFormat,
  // formatPhoneNumber,
  getAddressByGeoCode,
  logger,
  modalNotification,
} from '../../../utils';
import { Spin, notification } from 'antd';
import useOutsideClick from '../../../hooks/useOutSideClick.hook';
import { Regex } from '../../../helpers/regex.helper';
import { checkValidData } from '../../../components/UIElements/Formatter';
import { getSlotService } from '../../../services/index.service';

const LOADING_TYPE = {
  GET_SERVICE_TYPE: 'GET_SERVICE_TYPE',
  GET_SLOT_LIST: 'GET_SLOT_LIST',
  SUBMIT: 'SUBMIT',
  GET_SERVICE_PROVIDER: 'GET_SERVICE_PROVIDER',
  GET_ADDRESS: 'GET_ADDRESS',
  GET_PROVIDER_AVAIL_LIST: 'GET_PROVIDER_AVAIL_LIST',
};

const debounce = (func, wait) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, wait);
  };
};

const getDayNumberFromDate = (date) => {
  var d = new Date(`${date} 00:00:00`);
  var dayNumber = d.getDay();
  return dayNumber;
};

const initialState = {
  isLoading: '',
  addressLine1: '',
  serviceTypeLists: [],
  timeSlots: [],
  prevProviderList: [],
  providerList: [],
  selectedSlotId: null,
  selectedProvider: null,
  modal: '',
  jobType: true,
  addressLists: [],
  zoomLevel: null,
  providerAvailability: 'all',
  providerAvailabilityList: [],
  isMarkerDraggable: false,
  showMap: false,
  errors: {},
  serviceType: '',
  isOpenServiceProviderModal: false,
  bookingDate: null,
  defaultCountryId: '',
  isOpenNotPermissionModal: false,
  notPermissionService: '',
  count: 0,
  currentProviderListPage: 1,
};

const initialIsSelectLocationState = {
  select: false,
  isBlur: false,
  error: false,
};

let intervalId;

const PER_PAGE_DATA = 10;

function CreateManualBooking() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, method] = useLocationHook();
  const [planName, setPlanName] = useState('');
  const [isSelectLocationState, setIsSelectLocationState] = useState(
    initialIsSelectLocationState
  );

  const [mapLoader, setMapLoader] = useState(false);
  const [, setServiceBlockDay] = useState(0);
  const [isValidAddress, setIsValidAddress] = React.useState(false);
  const [api, contextHolder] = notification.useNotification();
  const notificationRef = useOutsideClick(() => notification.destroy());
  const [serviceId, setServiceId] = useState('');
  const [isLoadMoreLoading, setIsMoreLoading] = useState(false);
  const providerIdRef = useRef();
  const googleMapApiKey = useSelector((state) => state?.googleMap?.apiKey);
  const isNCAUser = useSelector(
    (state) => state?.auth?.userData?.Role?.type === 'nca'
  );
  const permissions = useSelector(
    (state) => state?.permission?.permission ?? {}
  );
  const { isPlacePredictionsLoading } = data;
  let isBookingDetails = localStorage.getItem('bookingDetails')
    ? JSON.parse(localStorage.getItem('bookingDetails'))
    : location?.state;
  if (isBookingDetails?.type) {
    isBookingDetails.phoneNumber = isBookingDetails?.Customer?.phoneNumber;
    isBookingDetails.firstName = isBookingDetails?.Customer?.firstName;
    isBookingDetails.lastName = isBookingDetails?.Customer?.lastName;
    isBookingDetails.email = isBookingDetails?.Customer?.email;
    isBookingDetails.serviceCategoryId = isBookingDetails?.ServiceCategory?.id;
    isBookingDetails.bookingDate = moment(
      isBookingDetails?.bookingScheduleDate
    ).format('YYYY-MM-DD');
    if (isBookingDetails?.type && isBookingDetails?.address) {
      const address = JSON?.parse?.(isBookingDetails?.address);
      isBookingDetails.addressLine1 = address?.addressLine1;
      isBookingDetails.lat = address?.latitude;
      isBookingDetails.lng = address?.longitude;
    }
  }

  // const breadcrumb = [
  //   {
  //     path: '/admin/dashboard',
  //     name: t(`text.common.dashboard`),
  //   },
  //   {
  //     path: '#',
  //     name: t(`text.common.manualBooking`),
  //   },
  // ];

  const [state, setState] = useState(initialState);
  const [loadingState, setLoadingState] = useState(false);
  const handleJobTypeChange = (value) => {
    setState({
      ...state,
      jobType: parseInt(value) === 0 ? true : false,
    });
  };

  // const headerBtn = {
  //   add: {
  //     name: 'How it works?',
  //     handler: () => handleModalOpen(MODAL_TYPE.howItsWorks.name),
  //   },
  // };

  const howItsWorksContent = () => {
    return (
      <ul className="list list-sm list-checked">
        <li>{t(`text.manualBooking.workOne`)}</li>
        <li>{t(`text.manualBooking.workTwo`)}</li>
        <li>{t(`text.manualBooking.workThree`)}</li>
        <li>{t(`text.manualBooking.workFour`)}</li>
        <li>{t(`text.manualBooking.workFive`)}</li>
        <li>{t(`text.manualBooking.workSix`)}</li>
        <li>{t(`text.manualBooking.workSeven`)}</li>
        <li>{t(`text.manualBooking.workEight`)}</li>
        <li>{t(`text.manualBooking.workNine`)}</li>
        <li>{t(`text.manualBooking.workTen`)}</li>
      </ul>
    );
  };

  const handleStateChange = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      ...(typeof name === 'string' ? { [name]: value } : name),
    }));
  };

  const handleModalClose = () => {
    handleStateChange('modal', '');
  };

  const handleModalOpen = (modalType) => {
    handleStateChange('modal', modalType);
  };

  const getServiceTypesOptions = useMemo(() => {
    const options = [];

    if (state?.serviceTypeLists?.length > 0) {
      state?.serviceTypeLists?.forEach((serviceTypeItem) => {
        options.push({
          name: `${
            serviceTypeItem?.ServiceCategoryDetails?.name
              ? `${serviceTypeItem?.ServiceCategoryDetails?.name} -`
              : ''
          } ${serviceTypeItem?.name ? serviceTypeItem?.name : ''} (${
            serviceTypeItem?.servicePrice === 'paid'
              ? `$${serviceTypeItem?.price}`
              : 'free'
          } - ${
            serviceTypeItem?.serviceType === 'schedule' ||
            serviceTypeItem?.serviceType === 'scheduled'
              ? 'Scheduled Later'
              : 'Emergency'
          })`,
          value: serviceTypeItem?.id,
          chip: serviceTypeItem?.servicePrice === 'free',
          chipValue: serviceTypeItem?.isSalesQuote === 1 ? 'Pro' : 'Basic',
        });
      });
    }

    return options;
  }, [state?.serviceTypeLists]);

  const getCountryList = useMemo(() => {
    let countyList =
      data?.countryList?.length > 0
        ? data?.countryList?.map((country) => ({
            name: country?.name,
            value: country?.id,
          }))
        : [];
    return countyList;
  }, [data?.countryList]);

  useEffect(() => {
    let defaultCountryId =
      data?.countryList?.length &&
      data?.countryList?.find(
        (e) =>
          e?.iso3 === 'USA' ||
          e?.name === 'USA' ||
          e?.name === 'United States' ||
          e?.countryCode === 'US'
      ) &&
      data?.countryList?.find(
        (e) =>
          e?.iso3 === 'USA' ||
          e?.name === 'USA' ||
          e?.name === 'United States' ||
          e?.countryCode === 'US'
      )?.id;

    setState((prevState) => ({
      ...prevState,
      defaultCountryId: defaultCountryId,
    }));
  }, [data?.countryList]);

  const isShowRangePicker = (serviceId) => {
    const service = state?.serviceTypeLists?.find((service) =>
      service.id === parseInt(serviceId) ? serviceId : state?.serviceId
    );

    if (service && service?.serviceType === 'same_day') {
      return true;
    }

    return false;
  };

  const handleReset = (setValues, locationId) => {
    setValues((prevValues) => {
      return {
        ...prevValues,
        addressLine1: '',
        addressLine2: '',
        suiteNumber: '',
        serviceCategoryId: '',
        bookingDate: '',
        note: '',
        locationId,
      };
    });
    handleStateChange({
      // addressLists: [],
      selectedSlotId: null,
      selectedProvider: null,
      providerList: [],
      prevProviderList: [],
    });
  };

  const getMapLocations = () => {
    return {
      lat: data?.lat
        ? parseFloat(data?.lat)
        : state?.lat
        ? parseFloat(state?.lat)
        : isBookingDetails?.lat
        ? parseFloat(isBookingDetails?.lat)
        : 0,
      lng: data?.lng
        ? parseFloat(data?.lng)
        : state?.lng
        ? parseFloat(state?.lng)
        : isBookingDetails?.lng
        ? parseFloat(isBookingDetails?.lng)
        : 0,
    };
  };

  const getProviderLocations = () => {
    return state?.providerAvailabilityList?.length > 0
      ? state?.providerAvailabilityList?.map((provider) => ({
          email: provider?.email,
          image: provider?.profilePictureThumbUrl,
          mobile: provider?.phoneNumber,
          location: {
            lat: parseFloat(provider?.Provider?.workLocationLatitude),
            lng: parseFloat(provider?.Provider?.workLocationLongitude),
          },
          status: provider?.providerCurrentStatus || 'all',
        }))
      : [];
  };

  const handleSetNewAddress = async (location, setFieldValue) => {
    try {
      const addressLists = await getAddressByGeoCode({
        lat: location?.lat,
        lng: location?.lng,
        apiKey: googleMapApiKey,
      });
      const address = addressLists?.results?.[0];
      method.setUseLocationState({
        lat: address?.geometry?.location?.lat,
        lng: address?.geometry?.location?.lng,
      });
      handleStateChange('addressLine1', address?.formatted_address);
      if (setFieldValue) {
        setFieldValue('addressLine1', address?.formatted_address);
      }
    } catch (error) {
      logger(error);
    }
  };

  const handleSubmit = async (details, props) => {
    const isRangePicker = isShowRangePicker(details?.serviceCategoryId);
    if (!details?.bookingDate || !state?.selectedSlotId) {
      let errorObj = {};

      if (!details?.bookingDate) {
        modalNotification({
          type: 'error',
          message: t('validation.manualBooking.bookingDate'),
        });
        errorObj.bookingDate = true;
        props?.setErrors({
          bookingDate: t('validation.manualBooking.bookingDate'),
        });
        return;
      }

      if (!isRangePicker && !state?.selectedSlotId) {
        modalNotification({
          type: 'error',
          message: t('validation.manualBooking.slot'),
        });
        errorObj.slot = true;
        return;
      }

      handleStateChange('errors', errorObj);
    }
    if (
      details?.isJobTypeNac !== 'isWillCall' &&
      details?.isJobTypeNac !== 'isNAC' &&
      !state.selectedProvider
    ) {
      modalNotification({
        type: 'error',
        message: t('validation.manualBooking.selectProvider'),
      });
      return;
    }

    try {
      handleStateChange('isLoading', LOADING_TYPE.SUBMIT);

      let address;
      if (details?.locationId) {
        address = state?.addressLists.find(
          (addressItem) => addressItem?.id === details?.locationId
        );
      }
      const bodyData = {
        ...details,
        zipCode: data?.postal_code,
        city: data?.locality,
        state: data?.administrative_area_level_1,
        isJobTypeNac: details?.isJobTypeNac === 'isNAC' ? 1 : 0,
        latitude: address?.latitude
          ? address?.latitude?.toString()
          : data?.lat
          ? data?.lat?.toString()
          : isBookingDetails?.lat ?? '',
        longitude: address?.latitude
          ? address?.longitude?.toString()
          : data?.lng
          ? data?.lng?.toString()
          : isBookingDetails?.lng ?? '',
        ...(state?.selectedProvider &&
        details?.isJobTypeNac !== 'isNAC' &&
        details?.isJobTypeNac !== 'isWillCall'
          ? { providerUserId: state?.selectedProvider?.id }
          : {}),
        slotId: state?.selectedSlotId,
        bookingDate: isRangePicker
          ? moment(new Date()).format('YYYY-MM-DD HH:mm')
          : details?.bookingDate,
        countryId: details?.countryId
          ? details?.countryId
          : state?.defaultCountryId,
        // countryPhoneCode: details?.countryPhoneCode
        //   ? details?.countryPhoneCode
        //   : '1',
        countryPhoneCode : details?.countryId
        ? data?.countryList?.find(
            (countryItem) =>
              countryItem?.id === details?.countryId
          )?.phoneCode ?? '+1'
        : '+1',
        isWillCallList:
          details?.isJobTypeNac === 'isWillCall'
            ? 1
            : state?.selectedProvider?.isWillCallList === 1
            ? 1
            : state?.isWillCallList === 1
            ? 1
            : 0,
      };
      if (isBookingDetails?.type) {
        bodyData.type = 'assign';
        bodyData.bookingId = isBookingDetails?.id;
      }
      const response = await postCreateManualBookingService(bodyData);
      if (response?.success) {
        modalNotification({
          type: 'success',
          message: response?.message,
        });
        const bookingType = response?.data?.bookingType;
        if (isNCAUser) {
          if (
            ((bookingType === 'schedule' || bookingType === 'scheduled') &&
              !Object.keys(permissions).some(
                (key) => key === 'scheduled-bookings'
              )) ||
            (bookingType === 'emergency' &&
              !Object.keys(permissions).some(
                (key) => key === 'service-management'
              ))
          ) {
            setState((prevState) => ({
              ...prevState,
              notPermissionService: bookingType,
              isOpenNotPermissionModal: true,
            }));
            handleStateChange('isOpenNotPermissionModal', true);
            return;
          }
        }
        navigate(
          bookingType === 'schedule' || bookingType === 'scheduled'
            ? accessRoute.SCHEDULE_MANAGEMENT.path
            : serviceManagementRoute.LIST_SERVICE.path
        );
      } else {
        handleStateChange('isLoading', '');
      }
    } catch (error) {
      handleStateChange('isLoading', '');
    }
  };

  const handleStateChangePhoneNumber = async (mobileNumber, formProps) => {
    let phoneNumber = mobileNumber?.trim();
    formProps.setValues((prevValues) => ({
      ...prevValues,
      phoneNumber: phoneNumber,
    }));
    if (mobileNumber?.length < 6) {
      return;
    } else if (!Regex?.phoneNumber?.test(phoneNumber)) {
      modalNotification({
        type: 'error',
        message: 'Please enter valid phone number',
      });
      return;
    }

    try {
      phoneNumber && handleStateChange('isLoading', LOADING_TYPE.GET_ADDRESS);
      let response;
      if (phoneNumber) {
        response = await getAddressListByMobileNumberService(phoneNumber);
      } else {
        setState((prevState) => ({
          ...prevState,
          addressLists: [],
        }));
      }

      if (formProps) {
        formProps.setValues((prevValues) => ({
          ...prevValues,
          locationId: null,
          firstName: response?.data?.userResult?.firstName ?? '',
          lastName: response?.data?.userResult?.lastName ?? '',
          email: response?.data?.userResult?.email ?? '',
          phoneNumber: phoneNumber,
        }));
      }
      if (response?.success) {
        setState((prevState) => ({
          ...prevState,
          addressLists: response?.data?.rows ?? [],
          isMarkerDraggable: true,
          isLoading: '',
        }));
        setPlanName(
          response?.data?.userResult?.CustomerMembership?.MembershipPlan?.name
        );
      } else {
        phoneNumber && handleStateChange('isLoading', '');
      }
    } catch (error) {
      handleStateChange('isLoading', '');
    }
  };

  const getProviderList = (value) => {
    let providers = state?.prevProviderList;

    if (providers?.length > 0 && typeof value === 'string') {
      providers = providers?.filter((item) => {
        const fullName = `${item?.firstName ?? ''}${
          item?.lastName ?? ''
        }`.toLowerCase();
        const reversedFullName = `${item?.lastName ?? ''}${
          item?.firstName ?? ''
        }`?.toLowerCase?.();
        const trimmedSearchValue = value?.replace(/\s+/g, '')?.toLowerCase?.();
        return (
          fullName?.includes?.(trimmedSearchValue) ||
          reversedFullName?.includes?.(trimmedSearchValue)
        );
      });
    }

    return providers;
  };

  const handleDebounceChangedPhNumber = useCallback(
    debounce(handleStateChangePhoneNumber, 1500),
    []
  );

  const getServiceTypeLists = async (addressId, setValues) => {
    if (setValues) {
      handleReset(setValues, addressId);
      clearInterval(intervalId);
      method?.handleResetLocation();
    }
    setIsValidAddress(true);

    if (addressId === 'select') {
      setValues((prevValues) => ({
        ...prevValues,
        locationId: null,
      }));
      return;
    }
    let address;
    if (addressId) {
      address = state?.addressLists?.find(
        (addressItem) => addressItem?.id === addressId
      );
    }

    try {
      handleStateChange('isLoading', LOADING_TYPE.GET_SERVICE_TYPE);
      setLoadingState(true);
      const queryParams = {
        scope: 'activeServiceCategory',
        latitudeCoordinate: address
          ? address?.latitude
          : data?.lat
          ? data?.lat
          : isBookingDetails?.lat ?? '',
        longitudeCoordinate: address
          ? address?.longitude
          : data?.lng
          ? data?.lng
          : isBookingDetails?.lng ?? '',
        serviceTypeVisibility: 'visible',
      };
      const response = await getServiceTypeListsService({
        queryParams,
        type: 'all',
      });
      if (response?.success) {
        setState((prevState) => ({
          ...prevState,
          serviceTypeLists: response?.data?.rows,
          selectedSlotId: null,
          isLoading: '',
          ...(address
            ? { lat: address?.latitude, lng: address?.longitude }
            : {}),
        }));
        // if (!response?.data?.rows?.length) {
        //   setValues((prevValues) => ({
        //     ...prevValues,
        //     serviceCategoryId: '',
        //   }));
        // }
      } else {
        handleStateChange('isLoading', '');
      }
    } catch (error) {
      handleStateChange('isLoading', '');
    }
    setLoadingState(false);
  };

  const getProviderAvailabilityList = async (value, formValues) => {
    setMapLoader(true);
    try {
      setState((prevState) => ({
        ...prevState,
        providerAvailability: value,
      }));
      let address;
      if (formValues?.locationId) {
        address = state?.addressLists?.find(
          (addressItem) => addressItem?.id === formValues?.locationId
        );
      }
      const queryParams = {
        offset: '',
        limit: '',
        serviceSubcategoryId: formValues?.serviceCategoryId ?? '',
        latitudeCoordinate: address?.latitude
          ? address?.latitude.toString()
          : data?.lat
          ? data.lat?.toString()
          : isBookingDetails?.lat ?? '',
        longitudeCoordinate: address?.latitude
          ? address?.longitude.toString()
          : data?.lng
          ? data.lng?.toString()
          : isBookingDetails?.lng ?? '',
        radius: state?.zoomLevel ? parseInt(state?.zoomLevel) : '',
        bookingStatus: value,
      };

      if (
        (state?.serviceType === 'schedule' ||
          state?.serviceType === 'scheduled') &&
        formValues?.bookingDate &&
        state?.selectedSlotId
      ) {
        queryParams.bookingDate = formValues.bookingDate;
        queryParams.slotId = state.selectedSlotId;
      }
      const response = await getProviderAvailabilityListService(queryParams);

      setState((prevState) => ({
        ...prevState,
        isWillCallList: response?.data?.isWillCallList,
        providerAvailabilityList: response?.data?.rows ?? [],
      }));
    } catch (error) {
      logger(error);
    }
    setMapLoader(false);
  };

  const getAddressListOptions = useMemo(() => {
    let options = [];
    if (state?.addressLists?.length) {
      options?.push({ name: t('text.common.enterAddress'), value: 'select' });
      state?.addressLists?.forEach((addressItem) => {
        options.push({
          name: addressItem?.addressLine1,
          value: addressItem?.id,
        });
      });
    }

    return options;
  }, [state?.addressLists]);

  const geTimeSlotLists = async (bookingDate, isOpen) => {
    const prevBookingDate = state?.prevBookingDate;

    if (bookingDate !== prevBookingDate && !isOpen) {
      try {
        handleStateChange('isLoading', LOADING_TYPE.GET_SLOT_LIST);

        let currentDate = moment(bookingDate).format('YYYY-MM-DD');
        let getDayNumber = getDayNumberFromDate(bookingDate);

        let query = {
          current: getDayNumber,
          fromDate: currentDate,
        };
        const response = await getSlotService(query);
        if (response?.success) {
          setState((prevState) => ({
            ...prevState,
            timeSlots: response?.data?.rows?.[0]?.slots ?? [],
            prevBookingDate: bookingDate,
            selectedSlotId:
              response?.data?.rows?.[0]?.slots?.length > 0 &&
              response?.data?.rows?.[0]?.slots?.some?.(
                (item) => item.id === isBookingDetails?.slotId
              )
                ? isBookingDetails?.slotId
                : null,
            isLoading: '',
          }));
        } else {
          handleStateChange('isLoading', '');
        }
      } catch (error) {
        handleStateChange('isLoading', '');
      }
    }
  };

  const handleSelectTime = (date, setFieldValue) => {
    var now = moment(new Date());
    var end = moment(date);
    var duration = moment.duration(end.diff(now));
    var minutes = duration.asMinutes();
    if (minutes <= 60) {
      setFieldValue?.('bookingDate', '');
      modalNotification({
        type: 'error',
        message: t('validation.manualBooking.bookingTime'),
      });
    }
  };

  const getServiceProviderLists = async ({
    categoryId,
    slotId,
    setValues,
    isFetch,
    newPage,
  } = {}) => {
    if (newPage && !setValues) setIsMoreLoading(true);

    if (setValues) {
      setValues((prevValue) => ({
        ...prevValue,
        bookingDate: '',
        serviceCategoryId: categoryId,
      }));
    }
    const service =
      state?.serviceTypeLists?.find?.(
        (service) => service?.id === categoryId
      ) ?? {};

    if (!slotId && isBookingDetails?.type) {
      if (isBookingDetails?.slotId) slotId = isBookingDetails?.slotId;
      categoryId = isBookingDetails?.ServiceCategory?.id;
      service.serviceType = isBookingDetails?.bookingType;
    }

    if (
      ((service?.serviceType === 'schedule' ||
        service?.serviceType === 'scheduled') &&
        !state?.selectedSlotId &&
        !slotId) ||
      (setValues &&
        (service?.serviceType === 'schedule' ||
          service?.serviceType === 'scheduled'))
    ) {
      setState((prevState) => ({
        ...prevState,
        prevProviderList: [],
        providerList: [],
        serviceType: service?.serviceType,
        selectedSlotId: null,
      }));
      setServiceBlockDay(service?.serviceBlockDay);
      setServiceId('');
      return;
    }
    setServiceBlockDay(service?.serviceBlockDay);
    try {
      setState((prevState) => ({
        ...prevState,
        providerList:
          (categoryId && setValues) || (!isFetch && !newPage)
            ? []
            : prevState?.providerList,
        prevProviderList:
          (categoryId && setValues) || (!isFetch && !newPage)
            ? []
            : prevState?.prevProviderList,
        isLoading:
          (categoryId && setValues) ||
          slotId ||
          (!isFetch && !newPage && setValues)
            ? LOADING_TYPE.GET_SERVICE_PROVIDER
            : '',
      }));
      setServiceId(categoryId);
      const queryParams = {
        serviceSubcategoryId: categoryId,
        latitudeCoordinate: data?.lat
          ? data.lat
          : state.lat
          ? state.lat
          : isBookingDetails?.lat ?? '',
        longitudeCoordinate: data?.lng
          ? data.lng
          : state.lng
          ? state.lng
          : isBookingDetails?.lng ?? '',
        sortBy: 'all',
        ...((state?.prevBookingDate || isBookingDetails?.bookingScheduleDate) &&
        (service?.serviceType === 'schedule' ||
          service?.serviceType === 'scheduled')
          ? {
              bookingDate: !state?.prevBookingDate
                ? moment(isBookingDetails?.bookingScheduleDate).format(
                    'YYYY-MM-DD'
                  )
                : state?.prevBookingDate,
            }
          : {}),
        ...((state?.selectedSlotId || slotId) &&
        service?.serviceType !== 'same_day'
          ? {
              slotId: slotId
                ? slotId
                : !!(categoryId && setValues)
                ? null
                : state?.selectedSlotId,
            }
          : {}),
        type: 'manual',
        offset:
          ((slotId
            ? 1
            : newPage
            ? newPage
            : isFetch
            ? 1
            : state?.currentProviderListPage) -
            1) *
          PER_PAGE_DATA,
        limit: PER_PAGE_DATA,
      };

      const response = await getServiceProvidersListService(queryParams);

      if (response?.success) {
        if (
          isBookingDetails?.type &&
          isBookingDetails?.slotId &&
          !state.timeSlots.some?.(
            (item) => item.id === isBookingDetails?.slotId
          )
        ) {
          slotId = null;
        }
        setState((prevState) => ({
          ...prevState,
          jobType: true,
          prevProviderList:
            newPage && !setValues
              ? [...state?.prevProviderList, ...(response?.data?.rows ?? [])]
              : response?.data?.rows,
          providerList:
            newPage && !setValues
              ? [...state.providerList, ...(response?.data?.rows ?? [])]
              : response?.data?.rows,
          serviceType: service?.serviceType,
          selectedSlotId: slotId
            ? slotId
            : !!(categoryId && setValues)
            ? null
            : state.selectedSlotId,
          isWillCallList: response?.data?.isWillCallList,
          isLoading: '',
          count: response?.data?.count ?? 0,
          currentProviderListPage: slotId
            ? 1
            : newPage || !setValues
            ? newPage
              ? newPage
              : 1
            : state?.currentProviderListPage,
          ...(!response?.data?.rows?.some?.(
            (item) => item?.Provider?.id === providerIdRef.current
          )
            ? { selectedProvider: null }
            : {}),
        }));
        if (newPage) {
          setIsMoreLoading(false);
        }

        if (service?.serviceType === 'same_day') {
          setValues((prevValue) => ({
            ...prevValue,
            bookingDate: moment(new Date()).format('YYYY-MM-DD HH:mm'),
            serviceCategoryId: categoryId,
          }));
        }
      } else {
        handleStateChange('isLoading', '');
      }
    } catch (error) {
      handleStateChange('isLoading', '');
    }
  };

  async function onSelectValue(e, value, keys, setValues) {
    setIsSelectLocationState({
      ...isSelectLocationState,
      select: true,
      error: false,
    });
    setIsValidAddress(true);

    setLoadingState(true);
    await method?.onPlaceSelect(e);
    // handleStateChange('isMarkerDraggable', true);
    setLoadingState(false);
    // Add changes 12 Oct
    setValues((prevValues) => ({
      ...prevValues,
      serviceCategoryId: '',
      bookingDate: '',
    }));
    handleStateChange({
      isMarkerDraggable: true,
      providerList: [],
      selectedProvider: null,
      prevProviderList: [],
    });
  }

  function onItemChange(e, setFieldValue, fieldName) {
    setIsSelectLocationState(initialIsSelectLocationState);
    if (fieldName === 'addressLine1') {
      setIsValidAddress(false);
    }
    handleStateChange('addressLine1', e);
    setFieldValue('addressLine1', e);
    method?.onPlacePredictions(e);
  }

  const range = (start) => {
    const result = [];
    for (let i = 0; i < start + 1; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledDateTime = () => {
    const d = new Date();

    return {
      disabledHours: () => range(d.getHours()),
      disabledMinutes: () => [],
    };
  };

  const handleOpenProviderDetails = (provider) => {
    providerIdRef.current = provider?.Provider?.id;
    notification.destroy();
    api.open({
      closeIcon: <></>,
      description: (
        <div ref={notificationRef}>
          <div style={{ fontWeight: '600' }}>Provider Details</div>
          <div className="row g-3 mt-0">
            <div className="col-6">
              <span className="sub-text">Service Provider</span>
              <span>
                {capitalizeFirstLetter(provider?.firstName ?? '')}
                &nbsp;
                {capitalizeFirstLetter(provider?.lastName ?? '')}
              </span>
            </div>
            <div className="col-6">
              <span className="sub-text">Service Provider Email</span>
              <span>{provider?.email ?? ''}</span>
            </div>
            <div className="col-6">
              <span className="sub-text">Phone No</span>
              <span>
                {/* formatPhoneNumber(provider?.phoneNumber?.replace(/-/g, '')) */}
                {provider?.phoneNumber
                  ? checkValidData(provider?.phoneNumber?.replace(/-/g, ''))
                  : ''}
              </span>
            </div>
          </div>
        </div>
      ),
      duration: 0,
      style: {
        marginTop: '50px',
      },
    });
  };

  const isScheduleBookingSlot = (slotItem, date) => {
    const scheduleDate = moment(
      isBookingDetails?.bookingScheduleDate,
      'YYYY-MM-DD'
    );

    return (
      !state.selectedSlotId &&
      isBookingDetails?.slotId === slotItem?.id &&
      scheduleDate.isSame(date)
    );
  };

  const getProviderAvailStatus = (provider) => {
    const providerStatus =
      provider?.ProviderBooking?.[0]?.BookingDetail?.serviceStatus;
    if (provider?.isOnline) {
      return 'available';
    } else if (!providerStatus && provider?.isOnline === 0) {
      return 'offline';
    } else if (providerStatus === 'start') {
      return 'journey-started';
    } else if (providerStatus === 'arrived') {
      return 'reached';
    } else {
      return 'enroute';
    }
  };

  const getProviderByInterval = (categoryId, slotId) => {
    if (intervalId) clearInterval(intervalId);
    intervalId = setInterval(() => {
      getServiceProviderLists({
        categoryId: serviceId ? serviceId : categoryId,
        slotId: slotId,
        isFetch: true,
      });
    }, 90000);
  };

  const loadMore =
    !state?.isLoading &&
    state?.count !== state?.providerList.length &&
    !state?.isProviderSearch ? (
      <div className="d-flex justify-content-center pb-2">
        <CommonButton
          variant="primary"
          className="min-100"
          size="sm"
          onClick={() => {
            getServiceProviderLists({
              categoryId: serviceId,
              newPage: state?.currentProviderListPage + 1,
            });
          }}
          loading={isLoadMoreLoading}
          disabled={
            isLoadMoreLoading || state?.count === state?.providerList.length
          }
        >
          Load more
        </CommonButton>
      </div>
    ) : null;

  useEffect(() => {
    if ((data?.lat && data?.lng) || isBookingDetails?.lat) {
      getServiceTypeLists();
    }
  }, [data?.lat, data?.lng]);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleStateChange('showMap', true);
    }, 1000);

    if (isBookingDetails && isBookingDetails?.phoneNumber) {
      handleStateChangePhoneNumber(isBookingDetails?.phoneNumber);
    }

    if (isBookingDetails?.type && isBookingDetails?.address) {
      handleStateChange({
        addressLine1: isBookingDetails.addressLine1,
        serviceType: isBookingDetails?.bookingType,
      });
      geTimeSlotLists(isBookingDetails.bookingDate, false);
      getServiceProviderLists();
      setIsSelectLocationState({
        ...isSelectLocationState,
        select: true,
      });
    }

    getProviderAvailabilityList(state?.providerAvailability);

    window.scrollTo(0, 0);

    return () => {
      if (isBookingDetails) localStorage.removeItem('bookingDetails');
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (serviceId) {
      getProviderByInterval();
      return () => clearInterval(intervalId);
    }
  }, [serviceId]);

  return (
    <>
      {/* <PageContainerWrapper
        heading="Manual Booking"
        breadcrumb={breadcrumb}
        btn={headerBtn}
      ></PageContainerWrapper> */}
      <div className="nk-block">
        <div className="card">
          <div className="card-inner">
            <FormWrapper
              onSubmit={(data, formProps) => {
                if (isValidAddress) {
                  handleSubmit(data, formProps);
                } else {
                  modalNotification({
                    type: 'error',
                    message:
                      'This address is invalid. Please select valid address',
                  });
                }
              }}
              row={true}
              extraClassName="g-3"
              initialValues={{
                ...initValues(isBookingDetails ? isBookingDetails : {}),
                countryId: state?.defaultCountryId,
              }}
              validate={validation}
              enableReinitialize={true}
              data={{
                ...initValues(),
                countryId: state?.defaultCountryId,
              }}
            >
              {(props) => (
                <>
                
                  <FormElementWrapper
                    md={3}
                    label={t(`text.common.country`)}
                    element={() => (
                      <Select
                        id="countryId"
                        name="countryId"
                        placeholder={t(`text.common.selectCountry`)}
                        options={getCountryList}
                        formik={props}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={3}
                    label={t(`text.common.phoneNumber`)}
                    infoLabel={planName && planName}
                    isReadMore={true}
                    element={() => (
                      <TextInput
                        addonBefore={
                          
                          <>
                            {props.values?.countryId
                              ? data?.countryList?.find(
                                  (countryItem) =>
                                    countryItem?.id === props?.values?.countryId
                                )?.phoneCode ?? '+1'
                              : '+1'}
                          </>
                        }
                        id="phoneNumber"
                        name="phoneNumber"
                        className="form-control"
                        placeholder={t(`text.common.enterPhoneNumber`)}
                        variant="standard"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event?.key)) {
                            event.preventDefault();
                          }
                        }}
                        onInput={(e) =>
                          handleDebounceChangedPhNumber(e.target.value, props)
                        }
                        formik={props}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={3}
                    label={t(`text.common.fName`)}
                    element={() => (
                      <TextInput
                        id="firstName"
                        name="firstName"
                        placeholder={t(`text.common.enterFirstName`)}
                        formik={props}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={3}
                    label={t(`text.common.lName`)}
                    element={() => (
                      <TextInput
                        id="lastName"
                        name="lastName"
                        placeholder={t(`text.common.enterLastName`)}
                        formik={props}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={3}
                    label={t(`text.common.email`)}
                    element={() => (
                      <TextInput
                        id="email"
                        name="email"
                        type="email"
                        placeholder={t(`text.common.enterEmail`)}
                        formik={props}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={3}
                    label={t(`text.common.address`)}
                    element={() => (
                      <Select
                        id="locationId"
                        extraLoading={
                          state.isLoading === LOADING_TYPE.GET_ADDRESS
                        }
                        name="locationId"
                        placeholder={t(`text.common.enterAddress`)}
                        options={getAddressListOptions}
                        onItemChange={(addressId) =>
                          getServiceTypeLists(addressId, props.setValues)
                        }
                        formik={props}
                      />
                    )}
                  />
                  <div className="col-12">
                    <Row className="mt-2 mt-lg-0 g-0 gx-lg-3">
                      <Col lg={6}>
                        <div className="border p-2 mt-lg-3">
                          <div className="row g-3">
                            {!props?.values?.locationId && (
                              <>
                                <FormElementWrapper
                                  md={12}
                                  label={t(
                                    `text.common.EnterlocationRequested`
                                  )}
                                  mandatory={true}
                                  element={() => (
                                    <AutoComplete
                                      name="addressLine1"
                                      value={state?.addressLine1}
                                      onKeywordChange={(e) =>
                                        onItemChange(
                                          e,
                                          props?.setFieldValue,
                                          'addressLine1'
                                        )
                                      }
                                      placeholder={t(
                                        `text.manualBooking.enterjobLocation`
                                      )}
                                      formik={props}
                                      onLocationSelect={(e, value, keys) =>
                                        onSelectValue(
                                          e,
                                          value,
                                          keys,
                                          props.setValues
                                        )
                                      }
                                      onBlur={(e) => {
                                        if (
                                          props.values?.addressLine1 &&
                                          !isSelectLocationState.select
                                        ) {
                                          setIsSelectLocationState({
                                            ...isSelectLocationState,
                                            error: true,
                                          });
                                        }
                                      }}
                                      options={method?.getPredictionLocation()}
                                      loading={isPlacePredictionsLoading}
                                    />
                                  )}
                                />
                                {!isSelectLocationState.select &&
                                  isSelectLocationState.error && (
                                    <span className="text-danger mt-1">
                                      Please select location.
                                    </span>
                                  )}
                              </>
                            )}
                            {!props?.values?.locationId && (
                              <FormElementWrapper
                                md={12}
                                label={t(`text.common.addressLine2`)}
                                element={() => (
                                  <TextInput
                                    id="addressLine2"
                                    name="addressLine2"
                                    placeholder={t(
                                      `text.common.enterEnterAddress`
                                    )}
                                    formik={props}
                                  />
                                )}
                              />
                            )}

                            {!props?.values?.locationId && (
                              <FormElementWrapper
                                md={12}
                                label={t(`text.common.suiteNumber`)}
                                element={() => (
                                  <TextInput
                                    id="suiteNumber"
                                    name="suiteNumber"
                                    placeholder={t(
                                      `text.common.EnterSuiteNumber`
                                    )}
                                    formik={props}
                                    extraClassName="mb-4 mb-xl-5"
                                  />
                                )}
                              />
                            )}
                            <FormElementWrapper
                              md={12}
                              actionHandler={() =>
                                handleModalOpen(MODAL_TYPE.serviceTypeInfo.name)
                              }
                              link="Info"
                              label={t(`text.manualBooking.typeService`)}
                              mandatory={true}
                              element={() => (
                                <Select
                                  id="serviceCategoryId"
                                  name="serviceCategoryId"
                                  placeholder={t(
                                    `text.manualBooking.selectServices`
                                  )}
                                  extraLoading={loadingState}
                                  disabled={
                                    !props?.values?.addressLine1 &&
                                    !props?.values?.locationId
                                  }
                                  options={getServiceTypesOptions}
                                  onItemChange={(categoryId) => {
                                    handleStateChange('selectedProvider', null);
                                    if (isBookingDetails?.type) {
                                      isBookingDetails.type = null;
                                    }
                                    getServiceProviderLists({
                                      categoryId,
                                      setValues: props?.setValues,
                                      newPage: 1,
                                    });
                                  }}
                                  formik={props}
                                />
                              )}
                            />
                            <>
                              {state?.serviceType !== 'same_day' && (
                                <FormElementWrapper
                                  actionHandler={() =>
                                    handleModalOpen(
                                      MODAL_TYPE.bookingDateInfo.name
                                    )
                                  }
                                  link="Info"
                                  md={12}
                                  mandatory={true}
                                  label={t(`text.manualBooking.setAppointment`)}
                                  element={() => (
                                    <DatePicker
                                      id="bookingDate"
                                      name="bookingDate"
                                      className="form-control date-picker shadow-none"
                                      placeholder="MM/DD/YY"
                                      onOpenChange={(open) => {
                                        if (
                                          state?.serviceType &&
                                          state?.serviceType !== 'same_day'
                                        ) {
                                          if (isBookingDetails?.type) {
                                            isBookingDetails.slotId = null;
                                          }
                                          geTimeSlotLists(
                                            props?.values?.bookingDate,
                                            open
                                          );
                                          handleStateChange('slotId', null);
                                        }
                                      }}
                                      value={props?.values?.bookingDate}
                                      defaultValue={props?.values?.bookingDate}
                                      disabledDate={(current) => {
                                        let customDate =
                                          moment().format('YYYY-MM-DD');
                                        return state?.serviceType === 'same_day'
                                          ? (current &&
                                              current <
                                                moment(
                                                  customDate,
                                                  'YYYY-MM-DD'
                                                )) ||
                                              current >=
                                                moment(
                                                  customDate,
                                                  'YYYY-MM-DD'
                                                ).add(1, 'days')
                                          : current &&
                                              current <
                                                // moment(
                                                //   customDate,
                                                //   'YYYY-MM-DD'
                                                // ).add(
                                                //   serviceBlockDay === 0
                                                //     ? 1
                                                //     : serviceBlockDay + 1,
                                                //   'days'
                                                // );

                                                moment(
                                                  customDate,
                                                  'YYYY-MM-DD'
                                                );
                                      }}
                                      formik={props}
                                      status={
                                        state?.errors?.bookingDate
                                          ? 'error'
                                          : ''
                                      }
                                      onOk={(e) =>
                                        state?.serviceType === 'same_day' &&
                                        handleSelectTime(
                                          e,
                                          props?.setFieldValue
                                        )
                                      }
                                      showTimeFormat="HH:mm"
                                      disabledTime={disabledDateTime}
                                      showTime={
                                        state?.serviceType === 'same_day'
                                      }
                                      format={'MM/DD/YY'}
                                      showNow={false}
                                      showToday={false}
                                    />
                                  )}
                                />
                              )}
                              {state?.serviceType &&
                                state?.serviceType !== 'same_day' &&
                                props?.values?.bookingDate && (
                                  <FormElementWrapper
                                    actionHandler={() =>
                                      handleModalOpen(
                                        MODAL_TYPE.bookingDateInfo.name
                                      )
                                    }
                                    link="Info"
                                    md={12}
                                    mandatory={true}
                                    label={t(
                                      `text.manualBooking.timeFrameWork`
                                    )}
                                    extraClassName="slotTimes"
                                    element={() => (
                                      <>
                                        {state?.isLoading ===
                                        'GET_SLOT_LIST' ? (
                                          <div className="text-center">
                                            <Spin />
                                          </div>
                                        ) : state?.timeSlots?.length ? (
                                          <ul className="custom-control-group">
                                            {state?.timeSlots?.map(
                                              (slotItem, idx) => (
                                                <li key={idx}>
                                                  <div
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      handleStateChange(
                                                        'selectedSlotId',
                                                        slotItem.id
                                                      );
                                                      if (
                                                        isBookingDetails?.type
                                                      ) {
                                                        isBookingDetails.slotId =
                                                          null;
                                                      }
                                                      if (
                                                        props?.values
                                                          ?.serviceCategoryId
                                                      ) {
                                                        getServiceProviderLists(
                                                          {
                                                            categoryId:
                                                              props?.values
                                                                ?.serviceCategoryId,
                                                            slotId: slotItem.id,
                                                          }
                                                        );
                                                      }
                                                      getProviderByInterval(
                                                        props?.values
                                                          ?.serviceCategoryId,
                                                        slotItem.id
                                                      );
                                                    }}
                                                    className={`custom-control custom-checkbox custom-control-pro no-control ${
                                                      isScheduleBookingSlot(
                                                        slotItem,
                                                        props.values
                                                          ?.bookingDate
                                                      )
                                                        ? 'slotchecked'
                                                        : ''
                                                    }`}
                                                  >
                                                    <input
                                                      type="radio"
                                                      className="custom-control-input"
                                                      name={`btnCheck ${
                                                        idx + 1
                                                      }`}
                                                      value={slotItem?.id}
                                                      id={idx}
                                                      checked={
                                                        slotItem?.id ===
                                                        state?.selectedSlotId
                                                      }
                                                      readOnly
                                                    />

                                                    <label
                                                      className="custom-control-label"
                                                      htmlFor={idx}
                                                    >
                                                      {convertTo12HourFormat(
                                                        slotItem?.slotTime
                                                      )}
                                                    </label>
                                                  </div>
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        ) : (
                                          <NoDataFound
                                            source={`/no-time.png`}
                                            text={t('text.common.noSlotFound')}
                                            isCustomer={
                                              '/assets/images/admin/no-time.png'
                                            }
                                          />
                                        )}
                                      </>
                                    )}
                                  />
                                )}
                            </>

                            <FormElementWrapper
                              md={12}
                              label={t(
                                `text.manualBooking.specialInstructions`
                              )}
                              actionHandler={() =>
                                handleModalOpen(MODAL_TYPE.specialInstInfo.name)
                              }
                              link="Info"
                              element={() => (
                                <AntTextArea
                                  id="note"
                                  name="note"
                                  placeholder={t(
                                    `text.manualBooking.enterSpecialInstructions`
                                  )}
                                  className="form-control"
                                  formik={props}
                                />
                              )}
                            />
                            <FormElementWrapper
                              md={12}
                              label={t(`text.manualBooking.jobType`)}
                              actionHandler={() =>
                                handleModalOpen(MODAL_TYPE.jobTypeInfo.name)
                              }
                              link="Info"
                              element={(formik) => (
                                <Select
                                  id="isJobTypeNac"
                                  name="isJobTypeNac"
                                  placeholder={t(
                                    `text.manualBooking.selectjobType`
                                  )}
                                  options={jobTypeOptions(
                                    state?.isWillCallList
                                  )}
                                  onSelect={handleJobTypeChange}
                                  formik={formik}
                                  defaultValue="Select Service Provider"
                                />
                              )}
                            />
                            {state?.jobType && state?.selectedProvider && (
                              <div className="d-flex bg-black text-white align-items-center p-1">
                                <p className="mb-0">
                                  Assigned Service Provider:
                                </p>
                                &nbsp;
                                <span>
                                  {capitalizeFirstLetter(
                                    state?.selectedProvider?.firstName ?? ''
                                  )}
                                  &nbsp;
                                  {capitalizeFirstLetter(
                                    state?.selectedProvider?.lastName ?? ''
                                  )}
                                  &nbsp;
                                  {state?.selectedProvider?.phoneNumber}
                                </span>
                              </div>
                            )}

                            {state?.jobType && (
                              <>
                                <FormElementWrapper
                                  md={12}
                                  label={t(
                                    `text.manualBooking.specificTechnician`
                                  )}
                                  element={(formik) => (
                                    <TextInput
                                      name="address"
                                      placeholder={t(
                                        `text.manualBooking.selectSpecificTechnician`
                                      )}
                                      onItemChange={(e) => {
                                        setState((prevState) => ({
                                          ...prevState,
                                          isProviderSearch: !!e?.target?.value,
                                          providerList: getProviderList(
                                            e?.target?.value
                                          ),
                                        }));
                                      }}
                                      formik={formik}
                                    />
                                  )}
                                />
                                <div className="mt-4 col-md-12">
                                  {state?.isLoading !==
                                    LOADING_TYPE.GET_SERVICE_PROVIDER &&
                                  state?.providerList?.length > 0 ? (
                                    <div className="table-responsive border manualBookingProviderList">
                                      <Table size="lg">
                                        <thead>
                                          <tr>
                                            <th className="w-30">
                                              {t(`text.common.provider`)}
                                            </th>
                                            <th className="w-30">
                                              {t(`text.manualBooking.number`)}
                                            </th>
                                            <th className="w-40">
                                              {t(`text.common.action`)}
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {state?.providerList?.map(
                                            (providerItem, idx) => (
                                              <tr key={idx}>
                                                <td>
                                                  <div className="user-card">
                                                    <div className="user-avatar bg-warning">
                                                      <ImageElement
                                                        previewSource={
                                                          providerItem?.profilePictureThumbUrl
                                                        }
                                                        alt="user-img"
                                                      />
                                                      <span
                                                        className={`status dot dot-xxl dot-${getProviderAvailStatus(
                                                          providerItem
                                                        )}`}
                                                      ></span>
                                                    </div>
                                                    <div className="user-name">
                                                      <span className="tb-lead">
                                                        {firstLetterCaps(
                                                          providerItem?.firstName
                                                        )}
                                                        &nbsp;
                                                        {firstLetterCaps(
                                                          providerItem?.lastName
                                                        )}
                                                      </span>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td>
                                                  {providerItem?.phoneNumber}
                                                </td>
                                                <td>
                                                  <CommonButton
                                                    onClick={() => {
                                                      handleStateChange(
                                                        'selectedProvider',
                                                        providerItem
                                                      );
                                                      handleOpenProviderDetails(
                                                        providerItem
                                                      );
                                                    }}
                                                    className={`btn btn-none ${
                                                      state.selectedProvider
                                                        ?.id ===
                                                      providerItem?.id
                                                        ? 'btn-primary'
                                                        : providerItem?.isWillCallList ===
                                                          1
                                                        ? 'btn-secondary'
                                                        : 'btn-success'
                                                    }`}
                                                  >
                                                    {providerItem?.isWillCallList ===
                                                    0
                                                      ? 'Assign Provider'
                                                      : 'Will Call List'}
                                                  </CommonButton>
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </Table>
                                      {loadMore}
                                    </div>
                                  ) : state?.isLoading ===
                                      LOADING_TYPE.GET_SERVICE_PROVIDER &&
                                    !state?.providerList?.length ? (
                                    <div className="text-center">
                                      <Spin />
                                    </div>
                                  ) : (
                                    <NoDataFound
                                      source="/no-provider.svg"
                                      text="No Provider Data"
                                      isCustomer={
                                        '/assets/images/admin/no-provider.svg'
                                      }
                                    />
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col lg={6} className="mt-3 mt-lg-0">
                        <div className="border p-2 mt-lg-3">
                          <Select
                            value={state?.providerAvailability}
                            name="state"
                            placeholder="Select provider availability"
                            onItemChange={(value) => {
                              getProviderAvailabilityList(value, props.values);
                            }}
                            options={providerAvailabilityOptions()}
                            isCustomIcon={true}
                            isFormik={false}
                          />
                          <div className="mt-2">
                            <Select
                              value={state?.zoomLevel}
                              name="zoomLevel"
                              placeholder="Select map zoom level"
                              options={zoomLevel}
                              showSearch
                              onSelect={(value) =>
                                handleStateChange('zoomLevel', value)
                              }
                              disabled={
                                !props?.values?.addressLine1 &&
                                !props?.values?.locationId
                              }
                              isFormik={false}
                            />
                          </div>
                          {state?.showMap ? (
                            <div className="card card-bordered w-100 mt-4">
                              <div style={{ height: 450, width: '100%' }}>
                                <Map
                                  googleMapURL={GOOGLE_MAP_URL(
                                    googleMapApiKey || GOOGLE_MAPS_API_KEY,
                                    ['drawing']
                                  )}
                                  loadingElement={
                                    <div style={{ height: `100%` }} />
                                  }
                                  containerElement={
                                    <div style={{ height: `100%` }} />
                                  }
                                  mapElement={
                                    <div style={{ height: `100%` }} />
                                  }
                                  location={getMapLocations()}
                                  zoomLevel={state?.zoomLevel}
                                  handleChangeMarker={(location) => {
                                    handleSetNewAddress(
                                      location,
                                      props?.setFieldValue
                                    );
                                  }}
                                  isMarkerDraggable={state?.isMarkerDraggable}
                                  providerLocations={getProviderLocations()}
                                  providerAvailable={
                                    state?.providerAvailability
                                  }
                                />
                                {mapLoader && (
                                  <div
                                    className="text-center"
                                    style={{
                                      position: 'absolute',
                                      left: 0,
                                      width: '100%',
                                      height: '100%',
                                      top: 0,
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      background: 'rgb(0 0 0 / 71%)',
                                    }}
                                  >
                                    <Spin className="white-color" />
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className="text-center mt-2">
                              <Spin />
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <FormElementWrapper
                    className="mt-4 text-end"
                    element={() => (
                      <>
                        <CommonButton
                          type="submit"
                          loading={state.isLoading === LOADING_TYPE.SUBMIT}
                          disabled={state.isLoading === LOADING_TYPE.SUBMIT}
                          className="btn btn-primary me-2"
                        >
                          {t(`text.common.bookNow`)}
                        </CommonButton>

                        <CommonButton
                          type="reset"
                          onClick={() => {
                            props.resetForm();
                            setState({
                              ...{
                                ...initialState,
                                defaultCountryId: state?.defaultCountryId,
                              },
                              showMap: true,
                            });
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                          }}
                          className="btn btn-light"
                        >
                          {t(`text.common.reset`)}
                        </CommonButton>
                      </>
                    )}
                  />
                </>
              )}
            </FormWrapper>
          </div>
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-inner">
          <div className="bq-note">
            <div className="bq-note-text">
              <div className="nk-block-head nk-block-head-sm nk-block-between">
                <h5 className="title">{t(`text.common.notes`)}:</h5>
              </div>
              <ul className="list list-sm list-checked">
                <li>{t(`text.manualBooking.noteOne`)}</li>
                <li>{t(`text.manualBooking.noteTwo`)}</li>
                <li>{t(`text.manualBooking.noteThree`)}</li>
                <li>{t(`text.manualBooking.noteFour`)}</li>
                <li>{t(`text.manualBooking.noteSix`)}</li>
                <li>{t(`text.manualBooking.noteSeven`)}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {state.modal && (
        <ModalWrapper
          onCancel={handleModalClose}
          show={state?.modal}
          heading={
            MODAL_TYPE[state?.modal] ? t(MODAL_TYPE[state?.modal].heading) : ''
          }
          content={
            <>
              <p>{t(MODAL_TYPE[state?.modal].para)}</p>
              {state.modal === MODAL_TYPE.howItsWorks.name &&
                howItsWorksContent()}
              <CommonButton
                type="submit"
                className="btn btn-secondary float-end"
                onClick={handleModalClose}
              >
                {t(`text.common.close`)}
              </CommonButton>
            </>
          }
        ></ModalWrapper>
      )}
      {state?.isOpenNotPermissionModal && (
        <ModalWrapper
          onCancel={() => handleStateChange('isOpenNotPermissionModal', false)}
          show={state?.isOpenNotPermissionModal}
          heading={t('text.manualBooking.dontHavePermission')}
          content={
            <>
              <p>
                You have not permission to access&nbsp;
                <b>
                  {state?.notPermissionService === 'schedule' ||
                  state?.notPermissionService === 'scheduled'
                    ? 'Scheduled Booking'
                    : 'Service Management'}
                </b>
                &nbsp;page
              </p>
              <Link to={dashboardRoute.DASHBOARD.path}>
                <CommonButton className="btn btn-primary mt-1 float-end">
                  {t('text.common.goToDashboard')}
                </CommonButton>
              </Link>
            </>
          }
        ></ModalWrapper>
      )}
      {contextHolder}
    </>
  );
}

export default CreateManualBooking;
