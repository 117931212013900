import { Link } from 'react-router-dom';
import { ActionWrapper, CommonButton, ImageElement } from '../components';
import { Rate } from 'antd';
import moment from 'moment';

// export const logoFormatter = (path, text) => {
//   return (
//     <>
//       <div className="table-img">
//         <img src={path || '/assets/images/admin/default_logo.png'} alt={text} />
//       </div>
//     </>
//   );
// };

export const actionFormatter = ({
  actionEditDelete: options,
  id,
  link = false,
  url = false,
  btnArray = ['Edit', 'Delete'],
  editId = true,
  action,
}) => {
  return (
    <ActionWrapper>
      {options &&
        options.map((item, key) => {
          return btnArray.includes(item?.name) ? (
            <li
              onClick={() => {
                item?.handler(item?.name, id);
                document.body.click();
              }}
              key={key}
              className="action_list"
              action={action}
            >
              {item?.name === 'Edit' && link ? (
                <Link to={`${editId ? `${url}/${id}` : url}`}>
                  <em className={item?.icon} /> {item?.name}
                </Link>
              ) : (
                <CommonButton>
                  <em className={item?.icon} /> {item?.name}
                </CommonButton>
              )}
            </li>
          ) : null;
        })}
    </ActionWrapper>
  );
};

export const TableImageFormatter = ({ url }) => {
  return (
    <div className="table-img">
      <ImageElement
        src={url || '/assets/images/admin/img_default-placeholder.png'}
        alt="url"
      />
    </div>
  );
};

export const ModalOpenFormatter = (url, text) => {
  return (
    <>
      <div className="postDescription">
        {text}{' '}
        <Link to="#" onClick={url} className="text-primary">
          Read More
        </Link>
      </div>
    </>
  );
};
export const adminLogoFormatter = (path, name, date) => {
  return (
    <>
      <div className="user-card">
        <div className="user-avatar md bg-light">
          <img src={path} alt="img" />
        </div>
        <div className="user-info">
          <span className="tb-lead">{name}</span>
          <span className="d-block">{date}</span>
        </div>
      </div>
    </>
  );
};

export const logoFormatter = (path, text) => {
  return (
    <>
      {path ? (
        <div className="table-img">
          <img src={path} alt="img" />
        </div>
      ) : (
        text
      )}
    </>
  );
};

export const logoImageFormatter = (path, text) => {
  return (
    <>
      <div className="table-img">
        <ImageElement previewSource={path} className="img-fluid" />
      </div>
    </>
  );
};
export const customerLogoFormatter = (path, name, date) => {
  return (
    <>
      <div className="user-card">
        <div className="user-avatar md bg-light">
          {path ? <img src={path} alt="img" /> : <span>AB</span>}
        </div>
        <div className="user-info">
          <span className="tb-lead">{name}</span>
          <span className="d-block">
            <Rate disabled defaultValue={5} />
          </span>
          <span className="d-block">{date}</span>
        </div>
      </div>
    </>
  );
};

export const currencyFormatter = (dollar, type) => {
  return (
    <>
      {dollar
        ? Number(dollar)?.toLocaleString(type === 'INR' ? `en-IN` : `en-US`, {
            style: 'currency',
            currency: `${type}`,
          })
        : '$0.00'}
    </>
  );
};

export function minutes_to_hhmm(numberOfMinutes) {
  //create duration object from moment.duration
  var duration = moment.duration(numberOfMinutes, 'minutes');

  //calculate hours
  var hh =
    duration.years() * (365 * 24) +
    duration.months() * (30 * 24) +
    duration.days() * 24 +
    duration.hours();

  //get minutes

  //return total time in hh:mm format
  return hh;
}
