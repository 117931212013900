import { siteStatisticsEndpoints } from '../../apiEndpoints/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';

export const getStatisticsService = async () => {
  try {
    const payload = {
      ...siteStatisticsEndpoints?.getSiteStatistics,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows) {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

