import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  generatePath,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {
  CommonButton,
  PageContainerWrapper,
  TablePlus,
  ModalComponent,
  CompletePaymentDetails,
  PaymentReportFilterForm,
} from '../../../../components';
import Graph from '../../../../features/graph/graph.feature';
import useTable from '../../../../hooks/useTable.hook';
import { accessRoute } from '../../../../routes/Reports';

import { accessRoute as commonAccessRoutes } from '../../../../routes/CommonRoutes';
import {
  Columns,
  getActions,
  getCustomerListService,
  getGraphScheduleBooking,
  getJobAmountDetailsService,
  getPaymentReport,
  getPaymentReportExcelService,
  getProviderListService,
  updateMarkAsSettledService,
} from '../../../../services/index.service';

import SplitPaymentDetails from '../SplitPaymentDetails';
import JobProviderDiscount from '../JobProviderDiscount/List';
import PurchaseOrder from '../PurchaseOrder/List';
import SubLaborPay from '../SubLaborPay/List';
import ReturnAmount from '../ReturnAmount/List';
import PaymentMethods from '../PaymentMethods/Create';
import {
  dateFormatter,
  decodeQueryData,
  logger,
  modalNotification,
} from '../../../../utils';
import moment from 'moment';
import { Checkbox, Spin, Table } from 'antd';
import { useSelector } from 'react-redux';
import { PermissionContext } from '../../../../context/permission.context';
import { useContext } from 'react';
import { getApiData } from '../../../../hooks/useGetApi.hook';
import useGraphData from '../../../../hooks/graph.hook';
import GraphService from '../../../../features/graph/graph';
import {
  checkValidPrice,
  totalTimeCount,
} from '../../../../components/UIElements/Formatter';
import { selectDateData } from '../../../../services/Columns';

const paymentReportModals = {
  jobProviderDiscountModal: 'jobProviderDiscountModal',
  purchaseOrderTotalModal: 'purchaseOrderTotalModal',
  splitPaymentDetailsModal: 'splitPaymentDetailsModal',
  sublaborTotalFeesModal: 'sublaborTotalFeesModal',
  returnedPoModal: 'returnedPoModal',
  paymentMethodModal: 'paymentMethodModal',
};

function PaymentReport() {
  const location = useLocation();

  const params = location?.search
    ? decodeQueryData(location && location?.search)
    : '';
  const action = useContext(PermissionContext);
  // const [providerListData, setProviderListData] = useState([]);
  // const [customerListData, setCustomerListData] = useState([]);
  const [settledLoading, setSettledLoading] = useState(false);
  const [checkboxId, setCheckBoxId] = useState([]);
  const [settledCheck, setSettledCheck] = useState(false);

  const {
    providerUserId,
    startDate,
    endDate,
    providerName,
    customerUserId,
    customerName,
    providerLocation,
  } = params;
  const [filterValue, setFilter] = useState({
    startDate:
      (!providerUserId && !customerUserId && moment().format('YYYY-MM-DD')) ||
      '',
    endDate:
      (!providerUserId && !customerUserId && moment().format('YYYY-MM-DD')) ||
      '',
    location: providerLocation ? parseInt(providerLocation) : undefined,
    providerUserId: params?.providerUserId && params?.providerUserId,
    customerUserId: params?.customerUserId && params?.customerUserId,
    dateType:
      (!providerUserId && !customerUserId && selectDateData[0]?.value) ||
      undefined,
  });

  const [values, setValues] = useState({
    startDate:
      (!providerUserId && !customerUserId && moment().format('YYYY-MM-DD')) ||
      '',
    endDate:
      (!providerUserId && !customerUserId && moment().format('YYYY-MM-DD')) ||
      '',
    dateType:
      (!providerUserId && !customerUserId && selectDateData[0]?.value) ||
      undefined,
  });
  const [totalJobTiming, setJobTiming] = useState({});
  const [isSearch, setIsSearch] = useState(0);
  const breadcrumb = Columns.paymentReportBreadcrumb();

  let extraQuery = {
    ...(providerUserId
      ? {
          ...filterValue,
          providerUserId: [providerUserId],
          startDate: startDate,
          endDate: endDate,
        }
      : customerUserId
      ? {
          customerUserId: customerUserId,
          ...filterValue,
        }
      : { ...filterValue }),
  };

  delete extraQuery.dateType;
  // const [tableHandler, tableProps] = useTable(getPaymentReport, extraQuery);

  const extraCSVHandle = async (getData) => {
    let obj = {};
    let arr = [];

    getData?.rows?.map &&
      getData?.rows?.map((item) => {
        obj = {
          name: totalTimeCount(item?.jobTime > 0 ? item?.jobTime : null),
        };
        arr.push(obj);
      });
    setJobTiming({ 'Job Timing': arr });
  };

  // console.log(extraQuery);

  const [tableHandler, tableProps] = useTable(
    getPaymentReport,
    extraQuery,
    false,
    false,
    false,
    extraCSVHandle,
    false,
    false,
    { sortBy: 'bookingScheduleDate', sortType: 'DESC' }
  );

  const graphType = 'paymentReport';
  const [ShowHideGraph, setShowHideGraph] = useState(false);

  const [graphData, graphLoader] = useGraphData({
    apiHandler: getGraphScheduleBooking,
    type: graphType,
    filterValue,
    isDependency: isSearch,
    isGraph: ShowHideGraph,
  });

  const {
    data,
    isLoading,
    count,
    page,
    noOfPages,
    sizePerPage,
    filter,
    sortBy,
    sortType,
  } = tableProps;

  const {
    onFilterChange,
    getData,
    // onExport,
    onRefresh,
    onFilterResetChange,
    onTableSort,
  } = tableHandler;

  const navigate = useNavigate();
  const userType = useSelector((state) => state?.auth?.userData?.Role?.type);

  const [state, setState] = useState({
    loader: null,
    modal: null,
    modalId: null,
    selectedRows: [],
    isShowTotalAmount: false,
  });
  const [globalFilter, setGlobalFilter] = useState(false);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [rowData, setRowData] = useState('');
  const [jobAmountDetails, setJobAmountDetails] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const queryData = Object.fromEntries([...searchParams]);

  const [isFirstTimeTotalAmount, setIsFirstTimeTotalAmount] = useState(true);
  const isUserCanEdit =
    (action && action?.arr?.includes(getActions('CREATE').value)) ||
    userType === 'admin';
  const isNCAUser = useSelector(
    (state) => state?.auth?.userData?.Role?.type === 'nca'
  );

  const [showMarkAsButton, setShowMarkAsButton] = useState(true);
  const [arrayValue, setArrayValue] = useState([]);
  const PaymentMethodPage = (e) => {
    e.preventDefault();
    navigate(accessRoute.PAYMENT_METHOD.path);
  };
  const InvoicePage = (e) => {
    navigate(
      `${generatePath(commonAccessRoutes.INVOICE.path, {
        viewInvoice: 'invoice',
        routePath: 'payment-report',
        id: e,
      })}`
    );
  };
  const JobProviderDiscountAdd = (e) => {
    e.preventDefault();
    navigate(accessRoute.JOB_PROVIDER_DISCOUNT.path);
  };
  const PurchaseOrderTotal = (e) => {
    e.preventDefault();
    navigate(accessRoute.PURCHASE_ORDER.path);
  };
  const PurchaseOrderTotalAdd = (e) => {
    e.preventDefault();
    navigate(accessRoute.PURCHASE_ORDER.path);
  };
  const SubLaborPayDetails = (e) => {
    e.preventDefault();
    navigate(accessRoute.SUBLABOR_PAY_ADD.path);
  };
  const SubLaborPayDetailsAdd = (e) => {
    e.preventDefault();
    navigate(accessRoute.SUBLABOR_PAY_ADD.path);
  };

  const ReturnAmountDetails = (e) => {
    e.preventDefault();
    navigate(accessRoute.RETURN_AMOUNT_ADD.path);
  };
  const ReturnAmountDetailsAdd = (e) => {
    e.preventDefault();
    navigate(accessRoute.RETURN_AMOUNT_ADD.path);
  };
  const ProviderInvoiceUpdateDetails = (e) => {
    e.preventDefault();
    navigate(accessRoute.PROVIDER_INVOICE_UPDATE.path);
  };
  if (globalFilter) {
    document.querySelector('body').classList.add('toggle-shown');
  } else {
    document.querySelector('body').classList.remove('toggle-shown');
  }

  window.addEventListener('resize', () => {
    // console.log("Size", window.screen.width);
    const { width } = window.screen;
    if (width === 1200) {
      setState({ ...state, shouldVisible: true });
    }
  });

  const onCheckAllChange = (e) => {
    arrayValue.push(e?.target?.value);
    let finalArray = [...new Set(arrayValue)];
    if (e?.target?.checked) {
      if (e?.target?.value === 'all') {
        let bookingId = data?.map((item) => {
          if (
            item?.BookingDetail?.providerPaymentStatus === 'unsettelled' &&
            item?.BookingDetail?.paymentStatus === 'complete'
          ) {
            return item?.bookingNo;
          }
        });
        let arr = bookingId.filter((item) => item !== undefined);
        arr.push(e?.target?.value);
        setCheckBoxId(arr);
      } else {
        setCheckBoxId(finalArray);
      }
    } else {
      if (e?.target?.value === 'all') {
        setCheckBoxId([]);
        setArrayValue([]);
      } else {
        let arr = checkboxId?.filter(
          (item) => !['all', e?.target?.value].includes(item)
        );
        setArrayValue(arr);
        setCheckBoxId(arr);
      }
    }
    if (checkboxId.includes('all')) {
      if (e?.target?.value === 'all') {
        setCheckBoxId([]);
        setArrayValue([]);
      } else {
        let arr = checkboxId?.filter(
          (item) => !['all', e?.target?.value].includes(item)
        );
        setCheckBoxId(arr);
        setArrayValue(arr);
      }
    }
    let bookingId = data?.map((item) => {
      if (item?.BookingDetail?.providerPaymentStatus !== 'settelled') {
        return item?.bookingNo;
      }
    });
    let arr = bookingId.filter((item) => item !== undefined);
    if (arr.length === finalArray.length) {
      if (!arr.includes('all')) {
        if (e.target.value === 'all') {
          arr?.push(e.target.value);
        } else {
          arr?.push('all');
        }
        setArrayValue([...new Set(arr)]);
        setCheckBoxId([...new Set(arr)]);
      }
    }
  };

  useEffect(() => {
    if (data && data?.length) {
      let isCheckSettled =
        data?.length &&
        data.map((e) => e?.BookingDetail)?.length &&
        data
          .map((e) => e?.BookingDetail)
          .filter(
            (z) =>
              z?.providerPaymentStatus === 'unsettelled' &&
              z?.paymentStatus === 'complete'
          ) &&
        data
          .map((e) => e?.BookingDetail)
          .filter(
            (z) =>
              z?.providerPaymentStatus === 'unsettelled' &&
              z?.paymentStatus === 'complete'
          )?.length;
      setSettledCheck(isCheckSettled > 0);
    }
  }, [data]);

  const columns = Columns.PaymentReportTableColumn({
    onSort: onTableSort,
    onFilterChange: onFilterChange,
    JobProviderDiscountAdd,
    PurchaseOrderTotalAdd,
    PurchaseOrderTotal,
    SubLaborPayDetailsAdd,
    SubLaborPayDetails,
    ReturnAmountDetailsAdd,
    ReturnAmountDetails,
    PaymentMethodPage,
    SplitPaymentDetails,
    ProviderInvoiceUpdateDetails,
    InvoicePage,
    filter,
    paymentReportModals,
    setIsOpenModal,
    page,
    sizePerPage,
    // providerListData,
    // customerListData,
    setRowData,
    isUserCanEdit,
    isNCAUser,
    onCheckAllChange,
    defaultFilter: providerName,
    filterObj: {
      id: providerUserId ?? customerUserId,
      name: providerName ?? customerName,
    },
    settledCheck: settledCheck,
  });

  const { t } = useTranslation();
  const ShowHideGraphShow = () => {
    setShowHideGraph(!ShowHideGraph);
  };
  const OpenBtnFilter = () => {
    setGlobalFilter(true);
  };

  const getTotalJobsAmountDetailsData = () => {
    const totalAmountColumns = [
      {
        id: '1.',
        amount: 0,
        service: 'Earning from Customer',
      },
      {
        id: '2.',
        amount: 0,
        service: 'Total Platform fees collected from service provider',
      },
      {
        id: '3.',
        amount: 0,
        service: 'Total Promo Discount',
      },
      {
        id: '4.',
        amount: 0,
        service: 'Total Provider Discount Amount (included)',
      },
      {
        id: '5.',
        amount: 0,
        service: 'Total Provider Payment',
      },
    ];
    if (jobAmountDetails?.earningFromCustomer) {
      totalAmountColumns[0].amount = parseFloat(
        jobAmountDetails?.earningFromCustomer
      ).toFixed(2);
    }
    if (jobAmountDetails?.TotalPlatFormFees) {
      totalAmountColumns[1].amount = parseFloat(
        jobAmountDetails?.TotalPlatFormFees
      ).toFixed(2);
    }
    if (jobAmountDetails?.totalPromoCodeDiscount) {
      totalAmountColumns[2].amount = parseFloat(
        jobAmountDetails?.totalPromoCodeDiscount
      ).toFixed(2);
    }
    if (jobAmountDetails?.totalProviderDiscount) {
      totalAmountColumns[3].amount = parseFloat(
        jobAmountDetails?.totalProviderDiscount
      ).toFixed(2);
    }

    if (jobAmountDetails?.providerPayAmount) {
      totalAmountColumns[4].amount = parseFloat(
        jobAmountDetails?.providerPayAmount
      ).toFixed(2);
    }
    return totalAmountColumns.map((col) => ({
      ...col,
      amount: `${col.amount}`,
    }));
  };

  const getCsvRowColumns = () => {
    let rowColumns = [{}];

    const rowColumnsKeys = [
      'Provider Payment Status',
      'Payment Transfer Status',
    ];

    getTotalJobsAmountDetailsData()?.forEach?.((item) => {
      rowColumns.push({
        [rowColumnsKeys[0]]: item?.service,
        [rowColumnsKeys[1]]: checkValidPrice(item?.amount),
      });
    });

    return rowColumns;
  };

  const handleExportCsvData = async () => {
    try {
      const { startDate, endDate, location, providerUserId, customerUserId } =
        extraQuery;
      const queryParams = {
        sortBy: 'bookingScheduleDate',
        sortType: 'DESC',
        startDate,
        endDate,
        location,
        providerUserId,
        customerUserId,
        ...filter,
      };
      const response = await getPaymentReportExcelService({ queryParams });
      const { success, message } = response;
      if (success) {
        modalNotification({
          type: 'success',
          message,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const headerBtn = {
    graph: {
      name: t('text.report.showHide'),
      handler: () => {
        setState({
          ...state,
          submitText: 'show graph',
        });
        ShowHideGraphShow();
      },
    },
    add: {
      name: (
        <>
          <em className="icon ni ni-filter-fill me-1" /> Filter
        </>
      ),
      handler: () => {
        OpenBtnFilter();
      },
    },
    csvExport: {
      handler: () => {
        handleExportCsvData();
      },
    },
  };

  const jobData = {
    labels: graphData?.labels,
    datasets: [
      {
        label: 'COLLECTED',
        data: graphData?.MonthlyCollectedAmount,
        backgroundColor: ['#1EE0AC'],
        borderWidth: 0,
        hoverOffset: 4,
      },
      {
        label: 'UNCOLLECTED',
        data: graphData?.remainingMonthlyCollectedAmount,
        backgroundColor: ['#E85347'],
        borderWidth: 0,
        hoverOffset: 4,
      },
    ],
  };

  const modalForm = {
    add: <Graph jobData={jobData} />,
  };

  function onRowSelect(e) {
    try {
      setState({ ...state, selectedRows: [...e] });
    } catch (err) {
      console.log('err', err);
    }
  }

  async function handleActionSelect(e) {
    try {
      if (e.target.value === 'delete') {
        getData();
      } else {
        getData();
      }
    } catch (err) {
      console.log(err);
    }
  }

  const totalJobsAmountDetailsColumn = [
    { dataIndex: 'service', title: t('text.common.services') },
    {
      dataIndex: 'amount',
      title: t('text.common.amount'),
      render: (cell, row) => checkValidPrice(cell),
    },
  ];

  const getJobAmountDetails = async (filterData, isClick = false) => {
    if (isClick && isFirstTimeTotalAmount) {
      setState({
        ...state,
        isShowTotalAmount: true,
      });
      setIsFirstTimeTotalAmount(false);
      return;
    }
    setState({
      ...state,
      loader: true,
    });
    let filterValues = {
      startDate: filterValue?.startDate || '',
      endDate: filterValue?.endDate || '',
      location: filterValue?.location || '',
      limit: sizePerPage,
      offset: sizePerPage * (page - 1),
    };
    const queryParams = { ...filterValues, ...(filterData ? filterData : {}) };

    await getApiData({
      service: getJobAmountDetailsService,
      setData: (data) => {
        setJobAmountDetails(data ? data : {});
      },
      queryParams,
    });
    setState({
      ...state,
      loader: false,
    });
  };

  const chartOptionsData = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
      tooltip: {
        mode: 'index',
        position: 'average',
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  const BackBtnFilter = () => {
    setGlobalFilter(false);
  };

  // const getFilterDataList = async () => {
  //   try {
  //     let queryParams = {
  //       scope: 'all',
  //     };
  //     // const response = await getProviderListService({
  //     //   queryParams,
  //     //   type: 'all',
  //     // });
  //     // const res = await getCustomerListService({
  //     //   queryParams,
  //     //   type: 'all',
  //     // });
  //     // if (response) {
  //     //   setProviderListData(response);
  //     // }
  //     // if (res) {
  //     //   setCustomerListData(res);
  //     // }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const onFilterSubmit = (values) => {
    try {
      if (!values?.startDate && !values?.endDate && !values?.location) {
        modalNotification({
          type: 'error',
          message: t('text.common.selectDateLocation'),
        });
        return;
      }
      let val = {
        startDate: values?.startDate || '',
        endDate: values?.endDate || '',
        location: values?.location || '',
      };
      setFilter((prevState) => ({
        ...prevState,
        ...val,
      }));
      setIsSearch(isSearch + 1);
      onFilterChange(val);
      getJobAmountDetails();
      setGlobalFilter(false);
    } catch (error) {
      logger(error);
    }
  };
  function onReset() {
    setCheckBoxId([]);
    setArrayValue([]);
    if (
      !values?.startDate &&
      !values?.endDate &&
      !values?.location &&
      !values?.dateType &&
      !filterValue?.startDate &&
      !filterValue?.endDate
    ) {
      return;
    }
    if (
      queryData?.providerUserId ||
      queryData?.startDate ||
      queryData?.endDate ||
      queryData?.customerUserId ||
      queryData?.customerName
    ) {
      // temporally
      searchParams.delete('startDate');
      searchParams.delete('endDate');
      setSearchParams(searchParams);
    }

    const resetData = {
      startDate: dateFormatter(
        moment(new Date()).subtract(1, 'year').toDate(),
        'YYYY-MM-DD'
      ),
      endDate: dateFormatter(new Date(), 'YYYY-MM-DD'),
      location: undefined,
      dateType: '',
    };
    setValues({
      ...resetData,
    });
    setFilter({
      ...resetData,
    });
    setIsSearch(isSearch + 1);
    onFilterChange(resetData);
    setGlobalFilter(false);
  }
  function onTableResetFilter() {
    setCheckBoxId([]);
    setArrayValue([]);
    if (
      queryData?.providerUserId ||
      queryData?.startDate ||
      queryData?.endDate ||
      queryData?.customerUserId ||
      queryData?.customerName ||
      queryData?.providerName ||
      queryData?.providerLocation
    ) {
      searchParams.delete('providerUserId');
      searchParams.delete('customerUserId');
      searchParams.delete('customerName');
      searchParams.delete('startDate');
      searchParams.delete('endDate');
      searchParams.delete('providerName');
      searchParams.delete('providerLocation');
      setSearchParams(searchParams);
    }
    // temporally
    const resetData = {
      startDate: moment().format('YYYY-MM-DD') || '',
      endDate: moment().format('YYYY-MM-DD') || '',
      location: undefined,
    };
    setValues({
      startDate: moment().format('YYYY-MM-DD') || '',
      endDate: moment().format('YYYY-MM-DD') || '',
      dateType: selectDateData[0]?.value,
      // dateType: 'today',
    });
    setFilter({
      ...resetData,
    });
    setIsSearch(isSearch + 1);
    onFilterChange(resetData);
    onFilterResetChange();
  }
  const onSettled = async () => {
    setSettledLoading(true);
    try {
      let arr = checkboxId.filter((item) => item !== 'all');
      let newArr = data?.map((item) => {
        if (arr?.includes(item?.bookingNo)) {
          return {
            bookingId: item?.BookingDetail?.bookingId,
            providerPayAmount: item?.BookingDetail?.providerPayAmount || '0',
          };
        }
      });
      let bodyDataArr = newArr.filter((item) => item !== undefined);
      if (bodyDataArr?.length > 0) {
        let bodyData = {
          payload: bodyDataArr,
        };
        const response = await updateMarkAsSettledService(bodyData);
        if (response?.success) {
          setCheckBoxId([]);
          setArrayValue([]);
          modalNotification({
            type: 'success',
            message: response?.message,
          });
          onRefresh();
        }
      } else {
        modalNotification({
          type: 'error',
          message: t('text.report.errorMarkAsMessage'),
        });
      }
    } catch (error) {
      console.log(error);
    }
    setSettledLoading(false);
  };

  useEffect(() => {
    if (queryData?.page || queryData?.pageSize) {
      setCheckBoxId([]);
      setArrayValue([]);
    }
  }, [queryData?.page, queryData?.pageSize]);

  useEffect(() => {
    if (data?.length > 0) {
      let filterData = data?.filter(
        (item) => item?.BookingDetail?.providerPaymentStatus !== 'settelled'
      );
      if (filterData?.length === 0) {
        setShowMarkAsButton(false);
      } else {
        setShowMarkAsButton(true);
      }
    } else {
      setShowMarkAsButton(false);
    }
  }, [data]);

  useEffect(() => {
    setFilter({
      ...filterValue,
      startDate:
        values?.startDate ||
        (values?.dateType && moment().format('YYYY-MM-DD')),
      endDate:
        values.endDate || (values?.dateType && moment().format('YYYY-MM-DD')),
      dateType: values?.dateType,
    });
  }, [values]);

  useEffect(() => {
    if (providerUserId || startDate || endDate || customerUserId) {
      setFilter({
        ...filterValue,
        startDate: startDate,
        endDate: endDate,
        dateType: undefined,
      });
    }
  }, [providerUserId, customerUserId]);

  useEffect(() => {
    if (filter) {
      getJobAmountDetails(filter);
    }
  }, [filter]);

  return (
    <>
      <PageContainerWrapper
        form={modalForm['filter']}
        heading={t('text.report.heading')}
        breadcrumb={breadcrumb}
        btn={headerBtn}
        extraClass="btn btn-primary d-block d-xl-none"
      >
        <div className="">
          <div className="globalFilters">
            <div className="card ">
              <div className="card-inner">
                <div
                  className={`globalFilters_inner ${
                    globalFilter ? 'filterToggle' : ''
                  }`}
                >
                  <div className="mb-3 d-block d-xl-none">
                    <Link to="#" onClick={() => BackBtnFilter()}>
                      <em className="icon ni ni-arrow-long-left me-1"></em>
                      {t('text.common.back')}
                    </Link>
                  </div>
                  <PaymentReportFilterForm
                    data={true}
                    onSubmit={onFilterSubmit}
                    onReset={onReset}
                    filterValue={filterValue}
                    setValues={setValues}
                    values={values}
                  />
                </div>
                <div
                  className={` ${globalFilter ? 'toggle-overlay' : ''} `}
                  onClick={() => BackBtnFilter()}
                ></div>
              </div>
            </div>
          </div>
        </div>

        {ShowHideGraph && (
          <GraphService
            type="bar"
            data={jobData}
            options={chartOptionsData}
            totalData={graphData}
            loading={graphLoader}
          />
        )}

        <Checkbox.Group
          style={{
            width: '100%',
          }}
          value={checkboxId}
        >
          <div className="tableScrollHide">
            <TablePlus
              hasLimit
              noOfPage={noOfPages}
              sizePerPage={sizePerPage}
              page={page}
              handleSelectedRows={onRowSelect}
              handleActionSelect={handleActionSelect}
              count={count}
              tableData={data}
              tableColumns={columns}
              onFilterResetChange={onTableResetFilter}
              selectRow={true}
              tableLoader={isLoading}
              rowClassName={(row) =>
                row?.BookingDetail?.providerPaymentStatus === 'settelled' &&
                'setteled-class'
              }
              filter={filter}
              sortBy={sortBy}
              sortType={sortType}
              showAction={false}
              isHeaderFix={{ y: 700 }}
              scrollTopPosition
            />
          </div>
        </Checkbox.Group>
        <div className="text-end my-4 d-flex align-items-start flex-wrap justify-content-end">
          {data?.length > 0 && (
            <CommonButton
              className="btn btn-primary mb-2 mb-sm-0"
              onClick={() => {
                getJobAmountDetails(filter, true);
              }}
              disabled={state?.isLoading}
            >
              {t('text.report.totalJobAmountDetail')}
            </CommonButton>
          )}
          {showMarkAsButton && settledCheck && (
            <CommonButton
              loading={settledLoading}
              className="btn btn-secondary ms-2"
              onClick={onSettled}
            >
              {t('text.report.markAsSettled')}
            </CommonButton>
          )}
        </div>

        {state.isShowTotalAmount ? (
          <div className="nk-block">
            <div className="card">
              <div className="card-inner">
                {state?.loader === true ? (
                  <div
                    className="text-center"
                    style={{
                      minHeight: '200px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Spin />
                  </div>
                ) : (
                  <>
                    <h5 className="title">Total Jobs Amount Details</h5>
                    {/* <TablePlus
                    hasLimit
                    tableData={
                      Object.values(jobAmountDetails)?.length > 0
                        ? getTotalJobsAmountDetailsData()
                        : []
                    }
                    tableColumns={totalJobsAmountDetailsColumn}
                    pagination={false}
                    resetAll={false}
                    isModalShadow={true}
                    isModalPadding={true}
                  /> */}
                    <div className="common-table">
                      <div className="dataTables_wrapper dt-bootstrap4 no-footer">
                        <Table
                          pagination={false}
                          rowKey="id"
                          columns={totalJobsAmountDetailsColumn}
                          dataSource={
                            Object.values(jobAmountDetails)?.length > 0
                              ? getTotalJobsAmountDetailsData()
                              : []
                          }
                        />{' '}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </PageContainerWrapper>{' '}
      {isOpenModal === paymentReportModals.splitPaymentDetailsModal && (
        <ModalComponent
          backdrop
          show={true}
          onHandleCancel={() => setIsOpenModal('')}
          title={t('text.report.splitPayment')}
          extraClassName="modal-xxl"
        >
          <SplitPaymentDetails providerDiscountData={rowData} />
        </ModalComponent>
      )}
      {isOpenModal === paymentReportModals.jobProviderDiscountModal && (
        <ModalComponent
          backdrop
          show={true}
          onHandleCancel={() => setIsOpenModal('')}
          title={t('text.report.providerDiscountHeading')}
          extraClassName="modal-xxl"
        >
          <JobProviderDiscount
            providerDiscountData={rowData}
            setIsOpenModal={setIsOpenModal}
            getData={getData}
          />
        </ModalComponent>
      )}
      {isOpenModal === paymentReportModals.purchaseOrderTotalModal && (
        <ModalComponent
          backdrop
          show={true}
          onHandleCancel={() => setIsOpenModal('')}
          title={t('text.report.purchaseOrderAdd')}
          extraClassName="modal-xxl"
        >
          <PurchaseOrder
            providerDiscountData={rowData}
            setIsOpenModal={setIsOpenModal}
            getReportData={getData}
          />
        </ModalComponent>
      )}
      {isOpenModal === paymentReportModals.sublaborTotalFeesModal && (
        <ModalComponent
          backdrop
          show={true}
          onHandleCancel={() => setIsOpenModal('')}
          title={t('text.report.SubLaborPayAdd')}
          extraClassName="modal-xxl"
        >
          <SubLaborPay
            providerDiscountData={rowData}
            setIsOpenModal={setIsOpenModal}
            getReportData={getData}
          />
        </ModalComponent>
      )}
      {isOpenModal === paymentReportModals.returnedPoModal && (
        <ModalComponent
          backdrop
          show={true}
          onHandleCancel={() => setIsOpenModal('')}
          title={t('text.report.returnAmount')}
          extraClassName="modal-xxl"
        >
          <ReturnAmount
            providerDiscountData={rowData}
            setIsOpenModal={setIsOpenModal}
            getReportData={getData}
          />
        </ModalComponent>
      )}
      {/* Admin Payment Modal Start */}
      <ModalComponent
        backdrop
        show={isOpenModal?.adminModal === 'adminRequest'}
        extraClassName={
          !isOpenModal?.adminPaymentMode ||
          (isOpenModal?.adminPaymentMode === 'admin' &&
            isOpenModal?.authorizedUser === 'adminAttention')
            ? 'modal-xxl'
            : ''
        }
        onHandleCancel={() => setIsOpenModal(false)}
        title={
          isOpenModal?.authorizedUser !== 'provider'
            ? isOpenModal?.authorizedUser === 'adminAttention'
              ? isOpenModal?.adminPaymentMode === 'pending'
                ? 'Warranty/No Charge Reason'
                : 'Admin Attention Required Reason'
              : t('text.report.paymentMethodHeader')
            : isOpenModal?.adminPaymentMode === 'check'
            ? 'Check Details'
            : isOpenModal?.adminPaymentMode === 'finance'
            ? 'Financing Details'
            : ''
        }
      >
        {!isOpenModal?.adminPaymentMode ||
        (isOpenModal?.adminPaymentMode === 'admin' &&
          isOpenModal?.authorizedUser === 'adminAttention') ? (
          <PaymentMethods
            rowData={rowData}
            getData={getData}
            setIsOpenModal={setIsOpenModal}
            authorizedUser={isOpenModal?.authorizedUser}
            paymentDetails={isOpenModal?.adminPaymentDetails}
          />
        ) : (
          <CompletePaymentDetails
            rowData={rowData}
            bookingLatestPayments={isOpenModal?.bookingLatestPayments}
            paymentMode={isOpenModal?.adminPaymentMode}
            paymentDetails={isOpenModal?.adminPaymentDetails}
            authorizedUser={isOpenModal?.authorizedUser}
          />
        )}
      </ModalComponent>
    </>
  );
}

export default PaymentReport;
