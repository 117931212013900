export const ROLE_LIST = {
  method: `GET`,
  url: `/v1/admin/role`,
};

export const ROLE_ADD = {
  method: `POST`,
  url: `/v1/admin/role`,
};

export const ROLE_DELETE = {
  method: `DELETE`,
  url: `/v1/admin/role`,
};

export const GET_ROLE_BY_ID = (id) => ({
  method: `GET`,
  url: `/v1/admin/role/${id}`,
});

export const ROLE_UPDATE = (id) => ({
  method: `PUT`,
  url: `/v1/admin/role/${id}`,
});

export const ROLE_STATUS_UPDATE = {
  method: `PUT`,
  url: `/v1/admin/role/change-status`,
};

export const GET_ROLE_PERMISSION = {
  method: 'GET',
  url: '/v1/admin/get-permissions',
};

export const GET_USER_ROLE_PERMISSION = {
  method: 'GET',
  url: '/v1/admin/get-users-permission',
};

export const GET_ROLE_PERMISSION_BY_ID = (id) => ({
  method: 'GET',
  url: `/v1/admin/get-permissions/${id}`,
});

export const UPDATE_ROLE_PERMISSION_BY_ID = (id) => ({
  method: 'PUT',
  url: `/v1/admin/permission/${id}`,
});

export const ROLE_NCA_LIST = {
  method: `GET`,
  url: `/v1/nca/nca-role`,
};

export const GET_ROLE_PERMISSION_LIST = (id) => ({
  method: 'GET',
  url: `/v1/admin/permission/${id}`,
});

export const MENTAL_HEALTH_STATUS_UPDATE = (id) => ({
  method: `PUT`,
  url: `/v1/admin/role/mental-health-permission/${id}`,
});

export const ALEXA_TOGGLE_STATUS_UPDATE = (id) => ({
  method: `PUT`,
  url: `/v1/admin/role/amazon-lex/${id}`,
});
