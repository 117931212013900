import i18next from 'i18next';
import {
  getInputSearchFilter,
  getMultiSelectSearchFilter,
} from '../../components';
import { serialNumberFormatter } from '../../components/UIElements/Formatter';
import { accessRoute } from '../../routes/Dashboard';
import { getTupleStatusList } from '../index.service';
import { readMoreTextShowArray } from '../../utils';

export const faqTableColumn = ({
  Action,
  Toggle,
  onSort,
  onFilterChange,
  filter,
  page,
  sizePerPage,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'question',
      title: i18next.t('text.common.title'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('question', order),
      ...getInputSearchFilter({
        dataIndex: 'question',
        placeholder: i18next.t('text.common.title'),
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'displayOrder',
      title: i18next.t('text.common.order'),
      sorter: true,
      headerClasses: 'sorting',
      onSort: (field, order) => onSort('displayOrder', order),
    },
    {
      dataIndex: 'status',
      title: i18next.t('text.common.status'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('status', order),
      render: (cell, row) => {
        return <Toggle row={row} cell={cell} />;
      },
      ...getMultiSelectSearchFilter({
        dataIndex: 'status',
        onFilterChange,
        filter,
        name: 'status',
        listService: getTupleStatusList,
        mode: false,
      }),
    },  
    {
      dataIndex: 'action',
      title: i18next.t('text.common.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => {
        return <Action row={row} cell={cell} />;
      },
    },
  ];
};
export const faqBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.faq.faq'),
    },
  ];
};
