import i18next from 'i18next';
import { getFromToDateFilter } from '../../components/Filter/Common/date.filter';
import { getInputSearchFilter, getMultiSelectSearchFilter } from '../../components';
import { accessRoute } from '../../routes/Dashboard';
import {
  checkValidData,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import {
  capitalizeFirstLetter,
  dateFormatter,
  readMoreTextShow,
  showDateInBrowser,
} from '../../utils';
import { DATE_TH_MONTH_YEAR } from '../../config';
import { CommonButton } from '../../components';

export const chatbotQuestionAnswerRecordsBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.chatbotQuestionAnswerRecords.title'),
    },
  ];
};

const RequestedUser = () => {
  return [
    {
      value: 'customer',
      name: 'Customer',
    },
    {
      value: 'provider',
      name: 'Provider',
    },
  ];
};

export const ChatbotQuestionnaireRecordsTable = ({
  options,
  handleChangeOption,
  page,
  sizePerPage,
  onFilterChange,
  filter,
  cancelUpdates,
  saveUpdates,
  adminNoteModal,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      exportName: 'S no.',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'question',
      title: i18next.t('text.chatbotQuestionAnswerRecords.question'),
      sorter: false,
      exportName: 'Question',
      exportFormatter: (cell, row) => checkValidData(row?.question),
      render: (cell, row) =>
        readMoreTextShow(
          checkValidData(cell),
          i18next.t('text.chatbotQuestionAnswerRecords.question'),
          40
        ),
      ...getInputSearchFilter({
        dataIndex: 'question',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'answer',
      title: i18next.t('text.chatbotQuestionAnswerRecords.answer'),
      sorter: false,
      exportName: 'Answer',
      exportFormatter: (cell, row) => checkValidData(row?.answer),
      render: (cell, row) =>
        readMoreTextShow(
          checkValidData(cell),
          i18next.t('text.chatbotQuestionAnswerRecords.answer'),
          40
        ),
      ...getInputSearchFilter({
        dataIndex: 'answer',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'userType',
      filterDataIndex: ['userType'],
      title: i18next.t('text.chatbotQuestionAnswerRecords.userType'),
      sorter: false,
      exportName: 'User Type',
      exportFormatter: (cell, row) => checkValidData(row?.userType),
      render: (cell, row) => checkValidData(capitalizeFirstLetter(cell)),
      ...getMultiSelectSearchFilter({
        dataIndex: 'userType',
        onFilterChange,
        name: 'userType',
        listKeys: { nameKey: 'name', nameId: 'value' },
        mode: false,
        listService: RequestedUser,
        filter,
      }),
    },
    {
      dataIndex: 'createdAt',
      filterDataIndex: ['fromDate', 'toDate'],
      title: i18next.t('text.chatbotQuestionAnswerRecords.date'),
      sorter: false,
      exportName: 'Date',
      exportFormatter: (cell, row) =>
        dateFormatter(cell, 'ddd, MMMM, DD, YYYY'),
      render: (cell, row) => {
        if (options?.[row?.id]) {
          return (
            <>
              <CommonButton
                onClick={() => saveUpdates(row?.id)}
                className="btn btn-primary btn-sm"
              >
                {i18next.t('text.common.save')}
              </CommonButton>
              <CommonButton
                onClick={() => cancelUpdates(row?.id)}
                className="btn btn-light btn-sm ms-1"
              >
                {i18next.t('text.common.cancel')}
              </CommonButton>
            </>
          );
        } else {
          return (
            <>{cell ? showDateInBrowser(cell, DATE_TH_MONTH_YEAR) : '-'} </>
          );
        }
      },
      ...getFromToDateFilter({
        dataStartIndex: 'fromDate',
        dataEndIndex: 'toDate',
        placeholderStartDate: 'From date',
        placeholderEndDate: 'To date',
        dateFormat: 'YYYY-MM-DD',
        onFilterChange,
        filter,
      }),
    },
  ];
};
