import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AddOrEditSupplyHouseForm,
  PageContainerWrapper,
} from '../../../../../components';
import { accessRoute } from '../../../../../routes/PriceManagement';
import { backToPage } from '../../../../../utils';
import { Columns } from '../../../../../services/index.service';
import {
  editSupplyHouseService,
  getSupplyHouseDetailsService,
} from '../../../../../services/QuoteManagement/supply.service';
import useSubmit from '../../../../../hooks/useSubmit.hook';

function SupplyHouseLocation() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [onSubmit, values, isLoading] = useSubmit({
    service: editSupplyHouseService,
    id: id,
    detailsService: getSupplyHouseDetailsService,
    afterSubmitActionType: 'redirect',
    redirectUrl: accessRoute.LIST_HOUSE_LOCATION.path,
  });

  const redirectUrl = accessRoute.LIST_HOUSE_LOCATION.path;
  const breadcrumb = Columns.editSupplyHouseBreadcrumb();

  return (
    <div className="wide-md mx-auto">
      <PageContainerWrapper
        breadcrumb={breadcrumb}
        btnArray={['extraButton']}
        extraBtnText={t('text.common.back')}
        extraBtnClass="btn btn-outline-light bg-white"
        onExtraButtonHandleShow={() => backToPage(navigate, redirectUrl)}
        heading={t('text.quote.updateSupplyHouseLocation')}
      >
        <div className="nk-block">
          <div className="card">
            <div className="card-inner">
              <AddOrEditSupplyHouseForm
                onSubmit={onSubmit}
                loading={isLoading}
                formData={values}
                formId={id}
                backToPage={() => backToPage(navigate, redirectUrl)}
              />
            </div>
          </div>
        </div>
      </PageContainerWrapper>
    </div>
  );
}

export default SupplyHouseLocation;
