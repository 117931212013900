import * as yup from 'yup';
import i18next from 'i18next';

export const CommonSchema = {
  email: yup
    .string()
    .required(i18next.t('validation.login.email'))
    .email(i18next.t('validation.login.validEmail')),
  password: yup
    .string()
    .required(i18next.t('validation.login.password'))
    .min(6, i18next.t('validation.login.minValidPassword'))
    .max(16, i18next.t('validation.login.maxValidPassword')),
  currentPassword: yup
    .string()
    .required(i18next.t('validation.login.password'))
    .min(6, i18next.t('validation.login.minValidPassword'))
    .max(16, i18next.t('validation.login.maxValidPassword')),
  newPassword: yup
    .string()
    .required(i18next.t('validation.changePassword.newPassword'))
    .min(6, i18next.t('validation.login.minValidPassword'))
    .max(16, i18next.t('validation.login.maxValidPassword')),
  confirmPassword: yup
    .string()
    .required(i18next.t('validation.changePassword.confirmPassword'))
    .oneOf(
      [yup.ref('newPassword')],
      i18next.t('validation.changePassword.matchPassword')
    ),
  firstName: yup
    .string()
    .required(i18next.t('validation.signup.firstName'))
    .min(3, i18next.t('validation.signup.minFirstName'))
    .max(16, i18next.t('validation.signup.maxFirstName'))
    .matches(/^[aA-zZ\s]+$/, i18next.t('validation.signup.firstNameValid')),
  lastName: yup
    .string()
    .required(i18next.t('validation.signup.lastName'))
    .min(3, i18next.t('validation.signup.minLastName'))
    .max(16, i18next.t('validation.signup.maxLastName'))
    .matches(/^[aA-zZ\s]+$/, i18next.t('validation.signup.lastNameValid')),
  phoneNumber: yup
    .number()
    .required(i18next.t('validation.signup.mobileNumber'))
    .min(10, i18next.t('validation.signup.minPhoneNo')),
  address: yup.string().required(i18next.t('validation.common.address')),
  gender: yup.string().required(i18next.t('validation.common.gender')),
  profileImage: yup
    .string()
    .required(i18next.t('validation.common.profileImage')),
  country: yup.string().required(i18next.t('validation.common.country')),
  field: yup.string().required(i18next.t('validation.common.field')),
  notes: yup.string().required(i18next.t('validation.common.notes')),
  suiteNumber: yup
    .string()
    .required(i18next.t('validation.common.suiteNumber')),
  state: yup.string().required(i18next.t('validation.common.state')),
  city: yup.string().required(i18next.t('validation.common.city')),
  zipCode: yup.string().required(i18next.t('validation.common.zipCode')),
  name: yup.string().required(i18next.t('validation.common.name')),
  bankName: yup.string().required(i18next.t('validation.common.bankName')),
  checkNumber: yup
    .string()
    .required(i18next.t('validation.common.checkNumber')),
  cardHolder: yup.string().required(i18next.t('validation.common.cardHolder')),
  cardNumber: yup.string().required(i18next.t('validation.common.cardNumber')),
  cvv: yup.string().required(i18next.t('validation.common.cvv')),
  expireYear: yup.string().required(i18next.t('validation.common.expireYear')),
  expireMonth: yup
    .string()
    .required(i18next.t('validation.common.expireMonth')),
  dateOfBirth: yup
    .string()
    .required(i18next.t('validation.common.dateOfBirth')),
  companyName: yup
    .string()
    .required(i18next.t('validation.common.companyName')),
  homeAddress: yup
    .string()
    .required(i18next.t('validation.common.homeAddress')),
  employerId: yup.string().required(i18next.t('validation.common.employerId')),
  uploadDocument: yup
    .string()
    .required(i18next.t('validation.common.uploadDocument')),
  userRole: yup.string().required(i18next.t('validation.common.userRole')),
  location: yup.string().required(i18next.t('validation.common.location')),
  documentName: yup
    .string()
    .required(i18next.t('validation.common.documentName')),
  roleName: yup.string().required(i18next.t('validation.common.roleName')),
  roleAssign: yup.string().required(i18next.t('validation.common.roleAssign')),
  description: yup
    .string()
    .required(i18next.t('validation.common.description')),
  status: yup.string().required(i18next.t('validation.common.status')),
};
