export const text = {
  forgetPassword: 'Forgot Password',
  message: 'Message',
  forgetPasswordDetails:
    'Submit Email or Mobile Number to reset your password.',
  forgetPasswordPlaceholder: 'Mobile Number/Email',
  createAccount: 'Create an Account',
  affiliateInformation: 'Promo Code Good Guys Affiliate Information',
  name: 'Your Name or Company Name',
  location: ' Good Guys Location To Support',
  search: 'Search',
  email: 'Email Id',
  captch: 'Enter Captcha',
  signUp: 'SIGN UP',
  viewPromoCode: 'View Promo Code',
  addPromoCode: 'Add Promo Code',
  customerUsage: 'Customer Usage',
  pendingAmount: 'Pending Amount',
  transferredAmount: 'Transferred Amount',
  uploadPhoto: 'Upload Photo',
  haveQuestion: 'Have a Question?',
  bankDetail: 'Bank Detail',
  editPassword: 'Edit Password',
  editAddress: 'Edit Address',
  profilePicture: 'Profile Picture',
  currentPassword: 'Current Password',
  newPassword: 'New Password',
  confirmPassword: 'Confirm New Password',
  enterEmail: 'Enter Your Email Id',
  requiredDocument: 'Required Documents',
  documentInfo:
    '  Payments Over $400 Will Usually Require Us To Verify Your Identity For Our Banking Partner. If Less Than $400 The Bank Usually Does Not Require This. We Will Be Required To Collect Your ID For Anything Above $400.',
  addDocument: 'Add Document',
  bankInfo: ' This is where your monthly direct deposit will go.',
  thankYou:
    'Thank you for your interest in becoming a Super Fan or Referral Partner.',
  howWork: 'How it works is simple!',
  signUpwith:
    'Sign up with some basic information (We confirm your account in less than 24 hours)',
  submita: 'Submit a 5-8 digit Promo Code',
  providerThis: ' Provide this promo code to anyone who could use our service.',
  haveThem:
    'Have them download our app or create an account online and enter your promo code.',
  added:
    ' Added bonus! That promo code is saved in their profile forever! The customer will receive the savings and you will be compensated every time it is used! You earn money! Customer with your codes save money and receives the highest level of 5 star service! Get started today and tell us a bit about yourself.',
  theCustomer:
    ' The customer will save money for using the discount associated with the promo code and you will earn money every time they use our service.',
  goodGuys:
    'Good Guys works hard everyday to provide the best service and value to our customers. To help reduce our cost, we created our Promo Code program. The Promo Code program has already saved customers money  and provided returns for our super fans and referral partners!',
};
