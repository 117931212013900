import { WillCallList } from '../../views/index.view';
import { getActions } from '../../services/Auth/index.service';

export default function route() {
  return [
    {
      path: accessRoute.WILL_CALL_LIST.path,
      private: true,
      name: 'Will Call List',
      action: accessRoute.WILL_CALL_LIST.action,
      key: accessRoute.WILL_CALL_LIST.key,
      belongsToSidebar: true,
      submenu: false,
      icon: accessRoute.WILL_CALL_LIST.icon,
      element: <WillCallList />,
      ...accessRoute.WILL_CALL_LIST,
    },
  ];
}

export const accessRoute = {
  WILL_CALL_LIST: {
    path: '/admin/will-call-list',
    action: getActions('LIST').value,
    moduleKey: 'will-call-list',
    key: 'will-call-list',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-money" />
      </span>
    ),
    order: 59,
  },
};
export const getChildrenRoutes = () => {
  return route();
};
