import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';
import { COMPANY_SETTING_ENDPOINT } from '../../apiEndpoints/index.endpoint';

export const viewCompanySettingService = async (queryParams) => {
  try {
    const payload = {
      ...COMPANY_SETTING_ENDPOINT?.getCompanySettingList,
      queryParams,
      isAdminToken: true,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
export const viewCompanyNCASettingService = async (queryParams) => {
  try {
    const payload = {
      ...COMPANY_SETTING_ENDPOINT?.getCompanyNCASettingList,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const editCompanySettingGeneralService = async ({ body }) => {
  try {
    const payload = {
      ...COMPANY_SETTING_ENDPOINT.editCompanySettingGeneral,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const editCompanySettingPaymentService = async ({ body }) => {
  try {
    const payload = {
      ...COMPANY_SETTING_ENDPOINT.editCompanySettingPayment,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const editCompanySettingEmailService = async ({ body }) => {
  try {
    const payload = {
      ...COMPANY_SETTING_ENDPOINT.editCompanySettingEmail,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const editCompanySettingAppearanceService = async ({ body }) => {
  try {
    const payload = {
      ...COMPANY_SETTING_ENDPOINT.editCompanySettingAppearance,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const editCompanySettingSocialMediaService = async ({ body }) => {
  try {
    const payload = {
      ...COMPANY_SETTING_ENDPOINT.editCompanySettingSocialMedia,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const editCompanySettingAppSettingService = async ({ body }) => {
  try {
    const payload = {
      ...COMPANY_SETTING_ENDPOINT.editCompanySettingAppSetting,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const editCompanySettingAppInstallationSettingService = async ({
  body,
}) => {
  try {
    const payload = {
      ...COMPANY_SETTING_ENDPOINT.editCompanySettingInstallationSetting,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const editCompanySettingEmailNCAService = async ({ body }) => {
  try {
    const payload = {
      ...COMPANY_SETTING_ENDPOINT.editCompanySettingEmailNCA,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const editCompanySettingAppearanceNCAService = async ({ body }) => {
  try {
    const payload = {
      ...COMPANY_SETTING_ENDPOINT.editCompanySettingAppearanceNCA,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const editCompanyReportAnIssueSettingService = async ({ body }) => {
  try {
    const payload = {
      ...COMPANY_SETTING_ENDPOINT.editCompanyReportAnIssueSetting,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const editCompanyReportAnIssueNCAService = async ({ body }) => {
  try {
    const payload = {
      ...COMPANY_SETTING_ENDPOINT.editCompanyReportAnIssueNCA,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const editCompanyShoppingCartService = async ({ body }) => {
  try {
    const payload = {
      ...COMPANY_SETTING_ENDPOINT.editCompanyShoppingCart,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const editCompanyVoiceAIService = async ({ body }) => {
  try {
    const payload = {
      ...COMPANY_SETTING_ENDPOINT.editCompanyVoiceAI,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
