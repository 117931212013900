import React from 'react';
import CommonButton from '../../../components/UIElements/Button/Common/index.btn';

function BookAppointmentNow({
  buttonText = 'book appointment now',
  title = ' Or you can pick a specific technician below',
  handleModal,
  handleMapView,
  isMapView,
  disabled,
}) {
  return (
    <div className="bookAppointment bg-white p-30 br-10 mb-3">
      <div className="d-sm-flex align-items-center flex-wrap justify-content-between">
        <div className="bookAppointment_left">
          <CommonButton
            variant="primary"
            onClick={handleModal}
            className="btnAppointment"
            disabled={disabled}
          >
            {buttonText}
          </CommonButton>
          <span className="font-md mt-1 d-inline-block">{title}</span>
        </div>
        <div className="d-flex btns-group">
          <CommonButton
            variant="primary"
            className={`btn text-capitaliz ${
              !isMapView ? 'btn-light' : ' btn-info'
            }`}
            onClick={() => handleMapView(true)}
          >
            <em className="gg-marker me-2" />
            Map
          </CommonButton>
          <CommonButton
            variant="primary"
            className={`btn text-capitaliz ${
              isMapView ? 'btn-light' : ' btn-info'
            }`}
            onClick={() => handleMapView(false)}
          >
            <em className="gg-list me-2" />
            List
          </CommonButton>
        </div>
      </div>
    </div>
  );
}

export default BookAppointmentNow;
