import React from 'react';
import { TablePlus, PageContainerWrapper } from '../../../../../components';
import { useNavigate } from 'react-router-dom';
import {
  subscribeColumns,
  subscribeData,
  unSubscribeBreadcrumb,
} from '../../../../../services/Columns/marketing.service';
import { accessRoute } from '../../../../../routes/Marketing';
import useTable from '../../../../../hooks/useTable.hook';
import {
  deleteUnsubscribeUserService,
  getUnsubscribeUserListService,
} from '../../../../../services/Marketing/marketingTemplate.service';
import { modalNotification } from '../../../../../utils';
import { useTranslation } from 'react-i18next';

function UnsubscribeUserList(props) {
  const {t} =useTranslation()
  const [tableHandler, tableProps] = useTable(getUnsubscribeUserListService);
  const {
    data,
    isLoading,
    count,
    page,
    noOfPages,
    sizePerPage,
    filter,
    sortBy,
    sortType,
  } = tableProps;
  const { onRefresh, onFilterChange } = tableHandler;

  const breadcrumb = unSubscribeBreadcrumb();
  const deleteUnsubscribeData = async (id) => {
    try {
      let bodyData = {
        id: id,
      };
      const response = await deleteUnsubscribeUserService({ bodyData });
      const { success, message } = response;
      if (response && success) {
        modalNotification({
          type: 'success',
          message,
        });
        onRefresh();
      }
    } catch (error) {
      console.log('Error', error);
    }
  };
  const columns = subscribeColumns({
    filter,
    onFilterChange,
    deleteUnsubscribeData,
    page,
    sizePerPage,
  });

  const navigate = useNavigate();
  const backPage = () => {
    navigate(accessRoute.MARKETING_REVIEW_TEMPLATE.path);
  };

  return (
    <>
      <PageContainerWrapper
        heading="Unsubscribe user list"
        breadcrumb={breadcrumb}
        btnArray={['extraButton']}
        extraBtnText={t('text.common.back')}
        extraBtnClass="btn btn-outline-light bg-white"
        onExtraButtonHandleShow={backPage}
      >
        <TablePlus
          hasLimit
          noOfPage={noOfPages}
          sizePerPage={sizePerPage}
          page={page}
          count={count}
          tableData={data}
          tableColumns={columns}
          selectRow={false}
          tableLoader={isLoading}
          filter={filter}
          sortBy={sortBy}
          sortType={sortType}
          isSearch={false}
          showAction={false}
          statusAction={false}
          isResetBtn={false}
          resetAll={false}
        />
      </PageContainerWrapper>
    </>
  );
}

export default UnsubscribeUserList;
