import React from 'react';
import { Charts } from '../../components';

export default function Graph({ jobData }) {
  return (
    <div>
      {' '}
      <div style={{ maxWidth: '500px', width: '100%', margin: '0 auto' }}>
        <Charts type="bar" data={jobData} />
      </div>
      {/* <ul className="nk-store-statistics mt-4">
        <li className="item pt-0">
          <div className="info">
            <div className="title">COLLECTED</div>
            <div className="count">
              $20115773.28
              <span className="change down">
                <em className="ni ni-arrow-long-down"></em>2696 jobs
              </span>
            </div>
          </div>
          <em className="icon bg-info-dim ni ni-activity-round"></em>
        </li>
        <li className="item pt-0">
          <div className="info">
            <div className="title">UNCOLLECTED</div>
            <div className="count">
              $751732.75
              <span className="change up">
                <em className="ni ni-arrow-long-up"></em>534 jobs
              </span>
            </div>
          </div>
          <em className="icon bg-purple-dim ni ni-activity-round"></em>
        </li>
      </ul> */}
    </div>
  );
}
