let url = '/v1/admin/nca';
// let editUrl = '/v1/nca';
export const NCA_ENDPOINT = {
  getNCAList: {
    url,
    method: 'GET',
  },
  NCAStatusUpdate: {
    url: `${url}/change-status`,
    method: 'PUT',
  },
  NCALink: (id) => ({
    url: `${url}/nca-link-status-update/${id}`,
    method: 'PUT',
  }),
  NCAExistingCategory: {
    url: `${url}/service-category/existing`,
    method: 'POST',
  },
  NCADocument: {
    url: `${url}/documents`,
    method: 'GET',
  },
  sendEmailNCAProfile: (id) => ({
    url: `${url}/${id}`,
    method: 'POST',
  }),
  // Edit NCa
  editNCAProfile: (id) => ({
    url: `${url}/nca-profile/${id}`,
    method: 'PUT',
  }),
  editNCACompanyProfile: (id) => ({
    url: `${url}/nca-company-profile/${id}`,
    method: 'PUT',
  }),
  editNCACard: (id) => ({
    url: `${url}/nca-card/${id}`,
    method: 'PUT',
  }),
  editNCABusiness: (id) => ({
    url: `${url}/nca-business-detail/${id}`,
    method: 'PUT',
  }),
  editNCABanking: (id) => ({
    url: `${url}/nca-banking-detail/${id}`,
    method: 'PUT',
  }),
  goToDashboard: {
    url: `${url}/login/go-to-dashBoard`,
    method: 'POST',
  },
  // get nca list
  getNCAProfile: (id) => ({
    url: `${url}/nca-profile/${id}`,
    method: 'GET',
  }),
  getNCACompanyProfile: (id) => ({
    url: `${url}/nca-company-profile/${id}`,
    method: 'GET',
  }),
  getNCACard: (id) => ({
    url: `${url}/nca-card/${id}`,
    method: 'GET',
  }),
  getNCABusiness: (id) => ({
    url: `${url}/nca-business-detail/${id}`,
    method: 'GET',
  }),
  getNCABanking: (id) => ({
    url: `${url}/nca-banking-detail/${id}`,
    method: 'GET',
  }),
  ncaAddCard: {
    url: `/v1/nca/card`,
    method: 'POST',
  },
  getCardList: {
    url: `/v1/nca/card`,
    method: 'GET',
  },
  deleteCard: (id) => ({
    url: `/v1/nca/card/${id}`,
    method: 'DELETE',
  }),
  defaultCard: (id) => ({
    url: `/v1/nca/default-card/${id}`,
    method: 'GET',
  }),
  getNcaBankDetails: {
    url: `/v1/nca/nca-banking-detail`,
    method: 'GET',
  },
  putNcaBankDetails: {
    url: `/v1/nca/nca-banking-detail`,
    method: 'PUT',
  },
  getNcaBusinessDetails: {
    url: `/v1/nca/nca-business-detail`,
    method: 'GET',
  },
  putNcaBusinessDetails: {
    url: `/v1/nca/nca-business-detail`,
    method: 'PUT',
  },
  getAllPermission: {
    url: `/v1/nca/page-permission`,
    method: 'GET',
  },
  putPermission: {
    url: `/v1/nca/page-permission`,
    method: 'PUT',
  },
  putNcaPage: {
    url: `/v1/nca/page`,
    method: 'PUT',
  },
  getNcaPage: {
    url: `/v1/nca/page`,
    method: 'GEt',
  },
  companyProfilePut: {
    url: `/v1/nca/nca-company-profile`,
    method: 'PUT',
  },
  companyProfileGet: {
    url: `/v1/nca/nca-company-profile`,
    method: 'GET',
  },
  defaultRole: {
    url: `/v1/nca/default-role/assign`,
    method: 'PUT',
  },
  cancelPlan: {
    url: `/v1/nca/plan/cancelled`,
    method: 'PUT',
  },
  getAdminNCACompanyProfile: {
    url: `${url}/company-profile`,
    method: 'GET',
  },
  postNCAPlanPayment: {
    url: `/v1/nca/plan/payment`,
    method: 'POST',
  },
  getLogReports: (id) => ({
    url: `/v1/admin/booking-log/${id}`, // need to change end point
    method: 'GET',
  }),
  getNcaLogReports: (id) => ({
    url: `/v1/nca/log/${id}`, // need to change end point
    method: 'GET',
  }),
  putNcaPaymentSettlement: (id) => ({
    url: `/v1/nca/settle-payment/${id}`,
    method: 'PUT',
  }),
  getNcaPaymentReport: (id) => ({
    url: `/v1/nca-provider/report/${id}`,
    method: 'GET',
  }),
  ncaPaymentReportTotal: (id) => ({
    url: `/v1/nca-provider/report-total/${id}`,
    method: 'GET',
  }),
};
