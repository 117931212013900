import React, { useState } from 'react';
import { useRef } from 'react';

import {
  GoogleMap,
  withGoogleMap,
  withScriptjs,
  Marker,
  InfoWindow,
} from 'react-google-maps';
import { ImageElement } from '../../components';

const defaultLocation = {
  lat: 38.0,
  lng: -97.0,
};
const iconColorWise = {
  all: '/assets/images/admin/men.svg',
  online: '/assets/images/admin/available.png',
  begin: '/assets/images/admin/enroute.png',
  arrived: '/assets/images/admin/jobs.png',
  start: '/assets/images/admin/yellow.png',
  offline: '/assets/images/admin/offline.png',
};

const ManualBookingMap = withScriptjs(
  withGoogleMap(
    ({
      location,
      providerLocations,
      handleChangeMarker,
      zoomLevel,
      isMarkerDraggable,
      providerAvailable,
    }) => {
      const [popupInfo, setPopupInfo] = useState(null);
      const mapRef = useRef();
      return (
        <GoogleMap
          defaultZoom={zoomLevel ? parseInt(zoomLevel) : 4}
          zoom={zoomLevel ? parseInt(zoomLevel) : 4}
          defaultCenter={location?.lng ? location : defaultLocation}
          center={location?.lng ? location : defaultLocation}
          ref={mapRef}
        >
          {providerLocations?.length > 0 &&
            providerLocations?.map((providerLocation, idx) => (
              <Marker
                key={idx}
                position={{ ...providerLocation?.location }}
                onClick={() => setPopupInfo(providerLocation)}
                icon={{
                  // url: `${window.location.origin}/assets/images/admin/men.svg`,
                  url:
                    providerAvailable === 'all'
                      ? `${window.location.origin}${
                          iconColorWise[providerLocation?.status]
                        }`
                      : `${window.location.origin}${iconColorWise[providerAvailable]}`,
                  fillColor: '#FFF',
                  strokeColor: '#FFF',
                  scaledSize: { width: 25, height: 32 },
                }}
              />
            ))}
          {isMarkerDraggable && (
            <Marker
              draggable={true}
              onDragEnd={(e) =>
                handleChangeMarker({
                  lat: e?.latLng?.lat(),
                  lng: e?.latLng?.lng(),
                })
              }
              position={location ? location : defaultLocation}
            />
          )}
          {popupInfo && (
            <InfoWindow
              position={popupInfo?.location}
              onCloseClick={() => setPopupInfo(null)}
              className="mapInfo"
            >
              <div
                className="mapUserInfo"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div className="mapUserInfo_img">
                  <ImageElement
                    previewSource={popupInfo?.image}
                    alt="profile"
                  />
                </div>
                <div>
                  <p className="mb-1">
                    Email: <strong>{popupInfo?.email}</strong>
                  </p>
                  <p>
                    Mobile: <strong>{popupInfo?.mobile}</strong>
                  </p>
                </div>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      );
    }
  )
);

export default ManualBookingMap;
