import {
  AddUser,
  EditUser,
  ListUser,
  ListUserAddress,
} from '../../views/index.view';
import { getActions } from '../../services/Auth/index.service';

export default function route() {
  return [
    {
      private: true,
      path: accessRoute.CUSTOMERS.path,
      name: 'Customers',
      key: accessRoute.CUSTOMERS.key,
      belongsToSidebar: true,
      icon: accessRoute.CUSTOMERS.icon,
      element: <ListUser />,
      ...accessRoute.CUSTOMERS,
    },
    {
      private: true,
      path: accessRoute.CUSTOMERS_ADD.path,
      name: 'Customers',
      key: accessRoute.CUSTOMERS_ADD.key,
      belongsToSidebar: false,
      icon: accessRoute.CUSTOMERS_ADD.icon,
      element: <AddUser />,
      ...accessRoute.CUSTOMERS_ADD,
    },
    {
      private: true,
      name: 'Customers',
      key: accessRoute.CUSTOMERS_EDIT.key,
      belongsToSidebar: false,
      icon: accessRoute.CUSTOMERS_EDIT.icon,
      element: <EditUser />,
      ...accessRoute.CUSTOMERS_EDIT,
      path: `${accessRoute.CUSTOMERS_EDIT.path}`,
    },
    {
      private: true,
      name: 'Customers',
      key: accessRoute.CUSTOMERS_VIEW.key,
      belongsToSidebar: false,
      icon: accessRoute.CUSTOMERS_VIEW.icon,
      element: <ListUserAddress />,
      ...accessRoute.CUSTOMERS_VIEW,
      path: `${accessRoute.CUSTOMERS_VIEW.path}/:id`,
    },
  ];
}

export const accessRoute = {
  CUSTOMERS: {
    path: '/admin/customers',
    action: getActions('LIST').value,
    key: 'customers',
    moduleKey: 'customers',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-users-fill" />
      </span>
    ),
    order: 7,
  },
  CUSTOMERS_EDIT: {
    path: '/admin/customers/edit/:id/:isBookNow',
    action: getActions('EDIT').value,
    moduleKey: 'customers',
    key: 'customers',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
  },
  CUSTOMERS_VIEW: {
    path: '/admin/customers/view',
    action: getActions('VIEW').value,
    moduleKey: 'customers',
    key: 'customers',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
  },
  CUSTOMERS_ADD: {
    path: '/admin/customers/add',
    action: getActions('CREATE').value,
    moduleKey: 'customers',
    key: 'customers',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
  },
};
export const getChildrenRoutes = () => {
  return route();
};
