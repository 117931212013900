import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChatContext } from '../../../context/chat.context';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import ImageElement from '../../../components/UIElements/ImageElement';
import {
  ModalComponent,
  CheckBox,
  CommonButton,
  NoDataFound,
  SweetAlert,
} from '../../../components';
import { Spin } from 'antd';
import ChatSearch from './ChatSearch';

import toast from '../../../utils/notifications';
import {
  deleteChatRoomMemberByIdService,
  postAddChatRoomMemberService,
  deleteChatGroupByIdService,
} from '../../../services/Chat/index.service';
import { capitalizeFirstLetter } from '../../../utils';

const ChatProfile = (props) => {
  const {
    isOpenChatProfileDrawer,
    handleOpenChatProfileDrawer,
    getServiceProviderData,
    providers,
    setProviders,
    handleSearchProviders,
    handleIncrementPerPage,
    isDisabledLoadMore,
    handleResetProviderDetails,
    isProviderLoading,
    isLoadMoreLoading,
    userDetails,
    handleOpenGroupModal,
    isLoadUpdate, setIsLoadUpdate,
    setIsLoadingChat,
  } = useContext(ChatContext);
  const { getProfileDetails, selectUserDetails, setRoomMessages, setSelectUserDetails, socket, handleAddNewMessage } =
    props;
  const { t } = useTranslation();
  const [selectGroupMemberId, setSelectGroupMemberId] = useState([]);
  const [isOpenConfirmRemoveMemberModal, setIsOpenConfirmRemoveMemberModal] =
    useState(false);
  const [selectRemoveMemberDetails, setSelectRemoveMemberDetails] = useState();
  const [isOpenAddMemberInGroupModal, setIsOpenAddMemberInGroupModal] =
    useState(false);
  const [searchName, setSearchName] = useState('');
  const [isLoading, setIsLoading] = useState('');

  const handleReset = () => {
    setSelectGroupMemberId([]);
    setSearchName('');
    handleResetProviderDetails();
  };

  const getGroupMemberLists = async () => {
    await getServiceProviderData();
  };

  const getGroupNames = () => {
    let userNames = [];

    selectUserDetails?.members?.forEach((member) => {
      userNames?.push(
        `${capitalizeFirstLetter(
          member?.User?.firstName ?? ''
        )} ${capitalizeFirstLetter(member?.User?.lastName ?? '')}`
      );
    });

    return userNames?.join(', ');
  };

  const handleSubmitAddGroupMember = async () => {
    setIsLoading(true);
    const body = {
      userIds: [...new Set(selectGroupMemberId)],
      roomId: selectUserDetails?.chatRoom?.id,
    };

    const response = await postAddChatRoomMemberService({ body });
    if (response?.success) {
      handleAddNewMessage({
        message: `${selectGroupMemberId?.length} member added in group`,
        createdAt: new Date().toISOString(),
        fromId: userDetails?.id,
      });
      getProfileDetails();
      setIsOpenAddMemberInGroupModal(false);
      toast({
        type: 'success',
        message: t('validation.chat.addGroupMemberSuccess'),
      });
      setSelectGroupMemberId([]);
    } else {
      toast({
        type: 'error',
        message: t('validation.chat.addGroupMemberFailed'),
      });
    }
    setIsLoading(false);
  };

  const handleSelectGroupMember = (isChecked, memberId) => {
    setSelectGroupMemberId(
      !selectGroupMemberId?.includes(memberId)
        ? [...selectGroupMemberId, memberId]
        : !isChecked
          ? selectGroupMemberId?.filter((memberItem) => memberItem !== memberId)
          : selectGroupMemberId
    );
  };

  const handleRemoveGroup = async () => {
    setIsLoading(true);
    const response = await deleteChatGroupByIdService({
      roomId: selectRemoveMemberDetails?.chatGroup?.id,
    });
    if (response?.success) {
      toast({
        type: 'success',
        message: t('validation.chat.removeGroupSuccess'),
      });
      setIsOpenConfirmRemoveMemberModal(false);
      setIsLoadingChat(true)
      setRoomMessages([])
      setSelectUserDetails({})
    } else {
      toast({
        type: 'error',
        message: t('validation.chat.removeGroupFailed'),
      });
    }
    setIsLoading(false);
  }

  const handleRemoveGroupMember = async () => {
    setIsLoading(true);
    const response = await deleteChatRoomMemberByIdService({
      memberId: selectRemoveMemberDetails?.memberItem?.memberUserId,
      roomId: selectUserDetails?.chatRoom?.id,
    });
    if (response?.success) {
      toast({
        type: 'success',
        message: t('validation.chat.removeGroupMemberSuccess'),
      });
      socket.emit('chat-member-remove', {
        roomId: selectUserDetails?.chatRoom?.id,
        userId: selectRemoveMemberDetails?.memberItem?.memberUserId,
      });
      setIsOpenConfirmRemoveMemberModal(false);
      getProfileDetails();
    } else {
      toast({
        type: 'error',
        message: t('validation.chat.removeGroupMemberFailed'),
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isOpenAddMemberInGroupModal) {
      getGroupMemberLists();
    }
    if (isLoadUpdate) {
      getProfileDetails();
      setIsLoadUpdate(false)
    }
  }, [isLoadUpdate]);

  useEffect(() => {
    if (isOpenAddMemberInGroupModal) {
      getGroupMemberLists();
    }
  }, [isOpenAddMemberInGroupModal]);

  // useEffect(() => {
  // }, [selectUserDetails])

  const onConfirmAlert1 = () => {
    selectRemoveMemberDetails?.action === 'group' ? handleRemoveGroup() : handleRemoveGroupMember();
    setIsOpenConfirmRemoveMemberModal(false)
    handleReset();
    return true;
  }

  if (!isOpenChatProfileDrawer) return <></>;

  return (
    <>
      <div
        className={`nk-chat-profile d-flex flex-column h-100 ${isOpenChatProfileDrawer ? 'visible' : ''
          }`}
      >
        <>
          <div className="user-card user-card-s2 my-4">
            <div className="user-avatar md bg-purple">
              <ImageElement
                previewSource={
                  selectUserDetails?.chatRoom?.imageUrl
                    ? selectUserDetails?.chatRoom?.imageUrl?.includes(
                      'defaultImage'
                    )
                      ? '/assets/images/admin/groups.png'
                      : selectUserDetails?.chatRoom?.imageUrl
                    : ''
                }
                alt="img"
              />
            </div>
            <div className="user-info">
              <h5>
                {selectUserDetails?.chatRoom?.chatType === 'group'
                  ? capitalizeFirstLetter(
                    selectUserDetails?.chatRoom?.roomName ?? ''
                  )
                  : selectUserDetails?.chatRoom?.ChatRoomMembers?.map(
                    (chatMember) =>
                      chatMember?.memberUserId !==
                        selectUserDetails?.createdBy
                        ? chatMember
                        : []
                  )[0].User?.firstName}
              </h5>
              {selectUserDetails?.chatRoom?.chatType === 'single' ? (
                selectUserDetails?.members?.find(
                  (member) =>
                    member?.memberUserId ===
                    selectUserDetails?.chatRoom?.toUserId
                ).User?.lastOnlineAt ? (
                  <div className="sub-text">
                    <span className="d-none d-sm-inline me-1">
                      {t(`text.common.active`)}
                    </span>
                    &nbsp;
                    {moment(
                      selectUserDetails?.members?.find(
                        (member) =>
                          member?.memberUserId ===
                          selectUserDetails?.chatRoom?.toUserId
                      ).User?.lastOnlineAt
                    )
                      .startOf('hour')
                      .fromNow()}
                  </div>
                ) : null
              ) : (
                <div
                  style={{
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                  }}
                  className="sub-text"
                >
                  {getGroupNames()}
                </div>
              )}
            </div>
          </div>
          {selectUserDetails?.chatRoom?.chatType === 'group' ? (
            <div className="chat-profile-group py-3 px-3 mx-5">
              <div className="row justify-content-between align-items-center">
                <div className='col'>
                  <Link
                    to="#"
                    onClick={() => handleOpenGroupModal({ chatGroup: selectUserDetails?.chatRoom, action: 'chatEdit' })}
                    className="text-primary"
                  >
                    {t('text.chat.chatEdit')}
                  </Link>
                </div>
                <div className='col'>
                  {selectUserDetails?.chatRoom?.chatType === 'group' ?
                    <Link
                      to="#"
                      onClick={() => {
                        setSelectRemoveMemberDetails({ chatGroup: selectUserDetails?.chatRoom, action: 'group' });
                        setIsOpenConfirmRemoveMemberModal(true);
                      }}
                      className="text-primary"
                    >
                      {t('text.chat.chatDelete')}
                    </Link>
                    : null
                  }
                </div>
              </div>
            </div>
          ) : null}
          <div className="chat-profile-group py-3 px-4">
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="title overline-title mb-0">
                {t('text.chat.allMembers')} (
                {selectUserDetails?.members?.length})
              </h6>
              <Link
                to="#"
                onClick={() => setIsOpenAddMemberInGroupModal(true)}
                className="text-primary"
              >
                {t('text.chat.addMembers')}
              </Link>
            </div>
          </div>
          <div className="chat-profile" style={{ overflowY: 'auto' }}>
            <div className="chat-profile-group px-3 border-top-0">
              {selectUserDetails?.members?.length > 0 && (
                <ul className="chat-list">
                  {selectUserDetails?.members?.map((memberItem, idx) => (
                    <li key={idx} className="chat-item is-unread">
                      <div className="chat-link">
                        <div className="chat-media user-avatar">
                          <ImageElement
                            previewSource={
                              memberItem?.User?.profilePictureThumbUrl
                            }
                            alt="img"
                            loading="lazy"
                          />
                        </div>
                        <div className="chat-info  d-flex justify-content-between">
                          <div className="chat-from">
                            <div className="name">
                              {capitalizeFirstLetter(
                                memberItem?.User?.firstName
                              )}
                              &nbsp;
                              {capitalizeFirstLetter(
                                memberItem?.User?.lastName
                              )}
                            </div>
                          </div>
                          {selectUserDetails?.chatRoom?.createdBy !==
                            memberItem?.memberUserId ? (
                            <Link
                              to="#"
                              onClick={() => {
                                setSelectRemoveMemberDetails({ memberItem: memberItem, action: 'member' });
                                setIsOpenConfirmRemoveMemberModal(true);
                              }}
                              className="text-danger"
                            >
                              {t('text.chat.remove')}
                            </Link>
                          ) : (
                            <div>{t('text.chat.admin')}</div>
                          )}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </>
      </div>
      <div
        className={`${isOpenChatProfileDrawer ? 'nk-chat-profile-overlay' : ''
          }`}
        onClick={handleOpenChatProfileDrawer}
      ></div>

      {isOpenAddMemberInGroupModal && (
        <ModalComponent
          backdrop
          show={isOpenAddMemberInGroupModal}
          onHandleCancel={() => {
            setIsOpenAddMemberInGroupModal(false);
            handleReset();
          }}
          title={t('text.chat.addMemberInGroup')}
        >
          <>
            <div className="selectServiceProviderModal">
              <div className="nk-chat-aside-search p-0 mb-3">
                <ChatSearch
                  search={searchName}
                  handleSearch={(e) => setSearchName(e.target.value)}
                  setEmptyList={() => setProviders([])}
                  getSearchList={() => handleSearchProviders(searchName)}
                />
              </div>
              {isProviderLoading && !providers?.length ? (
                <div className="p-2 mb-2">
                  <Spin
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      margin: 'auto',
                    }}
                  />
                </div>
              ) : !isProviderLoading && providers?.length > 0 ? (
                <ul className="chat-list">
                  {providers?.map((groupMember, idx) => (
                    <li key={idx} className="chat-item mb-2">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center pe-1 pe-sm-2 mb-1 mb-sm-0 flex-shrink-0">
                          <div className="chat-media user-avatar bg-purple">
                            <ImageElement
                              previewSource={
                                groupMember?.profilePictureThumbUrl
                              }
                              alt="img"
                              isProfile={true}
                            />
                          </div>
                          <div className="chat-from ms-2">
                            <div className="name">
                              {capitalizeFirstLetter(
                                groupMember?.firstName ?? ''
                              )}
                              &nbsp;
                              {capitalizeFirstLetter(
                                groupMember?.lastName ?? ''
                              )}
                            </div>
                          </div>
                        </div>
                        {!selectUserDetails?.members?.some(
                          (memberInfo) =>
                            memberInfo?.memberUserId === groupMember?.id
                        ) && (
                            <div className="text-end">
                              <CheckBox
                                defaultChecked={
                                  providers?.length > 0 &&
                                  providers?.includes(groupMember?.id)
                                }
                                onChange={(e) =>
                                  handleSelectGroupMember(
                                    e?.target?.checked,
                                    groupMember?.id
                                  )
                                }
                              />
                            </div>
                          )}
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <NoDataFound
                  source="/no-provider.svg"
                  text={t('text.chat.noServiceProviderList')}
                  extraClass="noDataFoundWrapper"
                  isCustomer={'/assets/images/admin/no-provider.svg'}
                />
              )}

              <div className="text-center mt-1 pb-2">
                <CommonButton
                  disabled={
                    isDisabledLoadMore || isProviderLoading || isLoadMoreLoading
                  }
                  loading={isLoadMoreLoading}
                  onClick={handleIncrementPerPage}
                  className="btn btn-primary"
                >
                  {t('text.chat.loadMore')}
                </CommonButton>
              </div>
              <div className="border-top text-end pt-2">
                <CommonButton
                  disabled={!selectGroupMemberId?.length || isLoading}
                  loading={isLoading}
                  onClick={handleSubmitAddGroupMember}
                  className="btn btn-primary me-2"
                >
                  {t('text.common.add')}
                </CommonButton>
                <CommonButton
                  className="btn btn-secondary"
                  onClick={() => {
                    setIsOpenAddMemberInGroupModal(false);
                    handleReset();
                  }}
                >
                  {t('text.common.close')}
                </CommonButton>
              </div>
            </div>
          </>
        </ModalComponent>
      )}
      <SweetAlert
        title="Are you sure"
        text={selectRemoveMemberDetails?.action === 'group' ? t('text.chat.removeGroupMessage') : `Remove "${selectRemoveMemberDetails?.memberItem?.User?.firstName} ${selectRemoveMemberDetails?.memberItem?.User?.lastName}" From "${selectUserDetails?.chatRoom?.roomName}" group`}
        show={isOpenConfirmRemoveMemberModal}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsOpenConfirmRemoveMemberModal}
        showLoaderOnConfirm
        loading={isLoading}
        onConfirmAlert={onConfirmAlert1}
      />
    </>
  );
};

export default ChatProfile;
