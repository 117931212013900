import { GOOGLE_CONNECT_ENDPOINT } from '../../apiEndpoints/GoogleConnect/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';

export const getGoogleAdwordsAccountListService = async ({
  type,
  isErrorToast = true,
}) => {
  try {
    const payload = {
      ...GOOGLE_CONNECT_ENDPOINT?.getGoogleAdwordsAccount,
      isErrorToast,
    };

    const res = await APIRequest(payload);
    if (res?.data && type === 'all') {
      return res?.data;
    }
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const getGoogleAdwordsListService = async ({
  queryParams,
  isErrorToast = true,
}) => {
  try {
    const payload = {
      ...GOOGLE_CONNECT_ENDPOINT?.getGoogleAdwordsList,
      queryParams,
      isErrorToast,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const updateGoogleAdwordsBudgetService = async ({ bodyData }) => {
  try {
    const payload = {
      ...GOOGLE_CONNECT_ENDPOINT?.updateGoogleAdwordsBudget,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
