import { Button, Select, Space } from 'antd';
import Input from 'antd/lib/input/Input';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterFilled } from '@ant-design/icons';

function SearchSelectFilter({
  setSelectedKeys,
  onFilterChange,
  selectedKeys,
  filter,
  list,
  confirm,
  clearFilters,
  dataIndex,
  placeholder,
}) {
  const { t } = useTranslation();
  const searchInput = useRef(null);

  const [keys, setKeys] = useState({
    [placeholder['selectIndex']]: null,
    [placeholder['inputIndex']]: null,
  });

  const handleSearch = async (selectedKeys, confirm) => {
    await onFilterChange({ ...keys });
    setSelectedKeys([{ ...keys }]);
    confirm();
  };

  function onChange(e, g) {
    let newKeys = {};
    if (e?.target) {
      newKeys = { ...keys, [e.target.name]: e.target.value };
    } else {
      newKeys = { ...keys, [g]: e };
    }
    setKeys(newKeys);
    setSelectedKeys(newKeys);
  }

  const handleReset = async (clearFilters, confirm) => {
    await onFilterChange({
      [placeholder['selectIndex']]: null,
      [placeholder['inputIndex']]: null,
    });
    setKeys({});
    setSelectedKeys({});
    clearFilters();
    confirm();
  };
  return (
    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
      <Select
        size="medium"
        variant="standard"
        value={keys ? keys[placeholder['selectIndex']] : ''}
        onChange={(e) => onChange(e, placeholder['selectIndex'])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        placeholder={placeholder?.select || ''}
        options={list}
        style={{
          marginBottom: 8,
          display: 'block',
        }}
      />
      <Input
        ref={searchInput}
        placeholder={placeholder?.input || ''}
        className="ant-input form-control"
        value={keys ? keys[placeholder['inputIndex']] : ''}
        name={placeholder['inputIndex']}
        onChange={onChange}
        onPressEnter={() => handleSearch(keys?.input, confirm, dataIndex)}
        style={{
          marginBottom: 8,
          display: 'block',
        }}
      />

      <Space>
        <Button
          type="primary"
          className="btn btn-primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            width: 90,
          }}
        >
          {t('text.common.search')}
        </Button>
        <Button
          onClick={() => clearFilters && handleReset(clearFilters, confirm)}
          className="btn btn-info"
          style={{
            width: 90,
          }}
        >
          {t('text.common.reset')}
        </Button>
      </Space>
    </div>
  );
}

export default SearchSelectFilter;

export const getColumnSearchSelectProps = ({
  dataIndex,
  list,
  filter,
  ...rest
}) => {
  return {
    filterDropdown: (props) => (
      <SearchSelectFilter
        filter={filter}
        list={list}
        dataIndex={dataIndex}
        {...props}
        {...rest}
      />
    ),
    filterIcon: (filtered) => (
      <FilterFilled
        type="search"
        style={{ color: filtered ? '#F47721' : undefined }}
      />
    ),
    onFilterDropdownVisibleChange: () => {},
  };
};
