import { Form, Input as FormInput } from 'antd';
import { useField } from 'formik';

function Input({
  name,
  // icon,
  // setFieldValue,
  formik,
  inputType,
  onItemChange,
  extraClassName = '',
  ...rest
}) {
  const [field, meta] = useField(name);
  const config = { ...field, ...rest };

  if (meta && meta?.touched && meta?.error) {
    config.error = true;
    config.helperText = meta.error;
  }

  function onChange(e) {
    // e.stopPropagation();
    if (onItemChange) onItemChange(e, formik);
    formik?.handleChange(e);
  }

  return (
    <Form.Item
      label={rest?.label}
      help={meta?.error && meta?.touched ? meta.error : ''}
      validateStatus={config?.error ? 'error' : 'success'}
    >
      <FormInput
        onChange={onChange}
        // onChange={formik.handleChange}
        type={inputType}
        {...rest}
        value={field?.value}
        name={name}
        className={`form-control ${extraClassName && extraClassName}`}
      />
    </Form.Item>
  );
}

export default Input;
