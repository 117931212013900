let url = '/v1';

export const CUSTOMER_APP_ENDPOINT = {
  getCustomerApp: {
    url: `${url}/app-text`,
    method: 'GET',
  },
  getCustomerFinanceOptionApp: {
    url: `${url}/financing-option`,
    method: 'GET',
  },
  getCustomerServiceProvider: {
    url: `${url}/admin/financing-option`,
    method: 'GET',
  },
  myAccount: {
    url: `${url}/account/me`,
    method: 'GET',
  },
  changePassword: {
    url: `${url}/change-password`,
    method: 'POST',
  },
  forgetPassword: {
    url: `${url}/forgot-password`,
    method: 'POST',
  },
  resetPassword: {
    url: `${url}/reset-password`,
    method: 'POST',
  },
  updateCustomer: {
    url: `/v1/customer`,
    method: 'PUT',
  },
  getPage: {
    url: `/v1/page`,
    method: 'GET',
  },
  getFaqs: {
    url: `/v1/faq`,
    method: 'GET',
  },
  contactUs: {
    url: `/v1/contact-us`,
    method: 'POST',
  },
  city: {
    url: `/v1/city`,
    method: 'GET',
  },
  country: {
    url: `/v1/country`,
    method: 'GET',
  },
  state: {
    url: `/v1/state`,
    method: 'GET',
  },
  getServiceProvidersList: {
    // url: `/v1/provider`,
    url: `/v1/customer/booking/service-providers`,
    method: 'GET',
  },
  getProviderDetails: (userId) => ({
    url: `/v1/provider/profile/${userId}`,
    method: 'GET',
  }),
  providerFavorite: {
    url: `/v1/customer/favorite-provider`,
    method: 'POST',
  },
  providerContactForm: {
    url: '/v1/contact-form',
    method: 'POST',
  },
  getOnGoingService: {
    url: `/v1/onGoingService`,
    method: 'GET',
  },
  getUpcomingCard: {
    url: `/v1/upcoming`,
    method: 'GET',
  },
  getPastCard: {
    url: `/v1/past`,
    method: 'GET',
  },
  getRequestCard: {
    url: `/v1/request`,
    method: 'GET',
  },
  viewOnGoingService: (id) => ({
    url: `/v1/${id}`,
    method: 'GET',
  }),
  viewRequest: (id) => ({
    url: `/v1/${id}`,
    method: 'GET',
  }),
  newCardRequest: {
    url: `/v1/customer/card`,
    method: 'POST',
  },
  getCardDetails: {
    url: `/v1/customer/card`,
    method: 'GET',
  },
  deleteCard: (id) => ({
    url: `/v1/customer/card/${id}`,
    method: 'DELETE',
  }),
  updatePromoCode: (promoCode) => ({
    url: `/v1/customer/promo-code/${promoCode}`,
    method: 'GET',
  }),
  getSlot: {
    url: '/v1/slot',
    method: 'GET',
  },
  bookingEmergencyFree: {
    url: '/v1/customer/emergency-free/booking',
    method: 'POST',
  },
  getBookingLists: {
    url: '/v1/customer/booking',
    method: 'GET',
  },
  putBookingCancel: (bookingId) => ({
    url: `/v1/customer/bookings/decline/${bookingId}`,
    method: 'PUT',
  }),
  getBookingDetailsById: (bookingId) => ({
    url: `/v1/customer/booking/${bookingId}`,
    method: 'GET',
  }),
  putApproveBooking: (bookingId) => ({
    url: `/v1/customer/booking/quote/accept/${bookingId}`,
    method: 'PUT',
  }),
  getServicerProviderList: {
    url: '/v1/provider',
    method: 'GET',
  },
  getProviderDetailsById: (id) => ({
    url: `/v1/provider/profile/${id}`,
    method: 'GET',
  }),
  getBookingReviewById: (id) => ({
    url: `/v1/booking-review/${id}`,
    method: 'GET',
  }),
  getBookingReviewLists: {
    url: '/v1/booking-review',
    method: 'GET',
  },
  postCustomerPayNow: {
    url: '/v1/customer/booking/payment-credit',
    method: 'POST',
  },
  postBookingReview: {
    url: '/v1/customer/booking-review',
    method: 'POST',
  },
  getQuoteOptionList: (bookingId) => ({
    url: `/v1/customer/option/${bookingId}`,
    method: 'GET',
  }),
  addQuote: (bookingId) => ({
    url: `/v1/customer/option/${bookingId}`,
    method: 'PUT',
  }),
  getRetingReview: (bookingId) => ({
    url: `/v1/booking/booking-review/${bookingId}`,
    method: 'GET',
  }),
  getReceipt: (bookingId) => ({
    url: `/v1/customer/bookings/receipt/${bookingId}`,
    method: 'GET',
  }),
  getNotification: {
    url: '/v1/notification',
    method: 'GET',
  },
  getNotificationCount: {
    url: '/v1/notification/count',
    method: 'GET',
  },
  getBookingStatus: (bookingStatusId) => ({
    url: `/v1/customer/booking/${bookingStatusId}`,
    method: 'GET',
  }),
  getBookingRemainingAmount: (bookingId) => ({
    url: `/v1/customer/booking/remaining-amount/${bookingId}`,
    method: 'GET',
  }),
  customerBookingReview: {
    url: '/v1/customer/booking-review',
    method: 'GET',
  },
  getNCACustomerBeforeLoginPermission: (code) => ({
    url: `/v1/customer/nca/page/${code}`,
    method: 'GET',
  }),
  getNCACustomerAfterLoginPermission: {
    url: '/v1/customer/nca/page-permission',
    method: 'GET',
  },
  getBookingLoader: {
    url: '/v1/general-setting',
    method: 'GET',
  },
  willCallList: {
    url: '/v1/customer/will-call-list/booking',
    method: 'POST',
  },
  getHomeContent: {
    url: 'v1/home-content',
    method: 'GET',
  },
  bookingDecline: (bookingId) => ({
    url: `/v1/customer/booking-quote/decline/${bookingId}`,
    method: 'PUT',
  }),
  // for guest
  getGuestBookingDetailsById: (bookingId) => ({
    url: `/v1/customer/public/booking/${bookingId}`,
    method: 'GET',
  }),
  getGuestQuoteOptionList: (bookingId) => ({
    url: `/v1/customer/public/option/${bookingId}`,
    method: 'GET',
  }),
  //Guest new QUote
  addGuestQuote: (bookingId) => ({
    url: `/v1/public/customer/option/${bookingId}`,
    method: 'PUT',
  }),
  bookingGuestDecline: (bookingId) => ({
    url: `/v1/public/customer/booking-quote/decline/${bookingId}`,
    method: 'PUT',
  }),
  putGuestApproveBooking: (bookingId) => ({
    url: `/v1/public/customer/booking/quote/accept/${bookingId}`,
    method: 'PUT',
  }),
};
