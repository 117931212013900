import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  GlobalLoader,
  ImageElement,
  NoDataFound,
  PageContainerWrapper,
} from '../../../../components';
import { accessRoute } from '../../../../routes/Reports';
import { Columns, getNcaUser } from '../../../../services/index.service';
import {
  backToPage,
  capitalizeFirstLetter,
  dateFormatter,
} from '../../../../utils';
import { useSelector } from 'react-redux';
import { checkValidPrice } from '../../../../components/UIElements/Formatter';

function NCABillingDetails() {
  const { id } = useParams();
  const userRole = useSelector((state) => state?.auth?.userData?.Role?.type);
  const [ncaDeatils, setNcaDetails] = useState({});
  const [loading, setLoading] = useState();
  const { t } = useTranslation();
  const breadcrumb = Columns.nclBillingDetailsBreadcrumb();
  const navigate = useNavigate();
  const redirectUrl = accessRoute.NCA_BILLING_REPORT.path;

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (id) {
        const res = await getNcaUser({ id });
        if (res?.success && res?.data) {
          setNcaDetails(res?.data);
          setLoading(false);
        }
      }
      setLoading(false);
    })();
  }, [id]);

  const COST_PAID =
    ncaDeatils?.creditCardTransaction > 0
      ? (ncaDeatils?.creditCardTransaction *
          Number(ncaDeatils?.creditCardPercentage)) /
          100 +
        Number(ncaDeatils?.creditCardPerTransaction) *
          Number(ncaDeatils?.creditCardUsedCount)
      : 0;
  const onYearlyHideColumn = ncaDeatils?.billType;
  return (
    <>
      {' '}
      <PageContainerWrapper
        heading={t('text.report.billingReportDetails')}
        breadcrumb={breadcrumb}
        btnArray={['extraButton']}
        extraBtnText={t('text.common.back')}
        extraBtnClass="btn btn-outline-light bg-white"
        onExtraButtonHandleShow={() => backToPage(navigate, redirectUrl)}
      >
        <div className="nk-block">
          <div className="invoice">
            <div className="invoice-wrap p-2 p-lg-3 p-xl-4">
              {loading ? (
                <GlobalLoader />
              ) : ncaDeatils && Object.keys(ncaDeatils)?.length > 0 ? (
                <div>
                  <div className="invoice-brand text-center pb-1">
                    <ImageElement source="logo.svg" />
                  </div>
                  <div className="invoice-head">
                    <div className="invoice-contact">
                      <span className="overline-title">
                        {t('text.report.billedTo')}
                      </span>
                      <div className="invoice-contact-info">
                        <h5 className="title mb-0">
                          {ncaDeatils?.User?.firstName}{' '}
                          {ncaDeatils?.User?.lastName}
                        </h5>
                        <p className="mb-0">
                          {ncaDeatils?.User?.CompanyNca?.name}
                        </p>
                        <p>{ncaDeatils?.User?.CompanyNca?.address}</p>
                      </div>
                      <ul className="list-plain mt-2">
                        <li>
                          <span className="overline-title">
                            {t('text.report.payMethod')}
                          </span>
                          <p>
                            {t('text.report.visaEnd')}{' '}
                            {ncaDeatils?.cardNumberLast4}{' '}
                          </p>
                        </li>
                        <li>
                          <span className="overline-title">
                            {t('text.report.paymentStatus')}
                          </span>
                          <br />
                          <span
                            className={
                              ncaDeatils?.paymentStatus === 'success'
                                ? 'badge badge-sm mt-1 rounded-pill badge-dim bg-outline-success'
                                : 'badge badge-sm mt-1 rounded-pill badge-dim bg-outline-danger'
                            }
                          >
                            {ncaDeatils?.paymentStatus}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="invoice-desc">
                      <ul className="list-plain">
                        <li className="invoice-date">
                          <span> {t('text.report.receiptDate')} </span>:
                          <span>
                            {' '}
                            {dateFormatter(ncaDeatils?.createdAt, 'MM-DD-YYYY')}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="invoice-bills">
                    <h6 className="title"> {t('text.report.yourPlan')}</h6>
                    <div className="table-responsive">
                      <table className="table border">
                        <thead>
                          <tr>
                            <th>{t('text.report.ncaRoleName')}</th>
                            <th>{t('text.report.billed')}</th>
                            {onYearlyHideColumn === 'monthly' ? (
                              <>
                                <th>
                                  {t('text.report.activeServiceProvider')} (
                                  {checkValidPrice(
                                    ncaDeatils?.activeProviderRate
                                  )}
                                  )
                                </th>
                                <th>{t('text.report.qty')}</th>
                              </>
                            ) : (
                              ''
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{ncaDeatils?.User?.Role?.name}</td>
                            <td>
                              {checkValidPrice(ncaDeatils?.planAmountPaid)}{' '}
                              {ncaDeatils?.billType}{' '}
                            </td>
                            {onYearlyHideColumn === 'monthly' ? (
                              <>
                                <td>
                                  {checkValidPrice(
                                    ncaDeatils?.activeProviderRate *
                                      ncaDeatils?.totalProvider
                                  )}
                                </td>
                                <td>{ncaDeatils?.totalProvider}</td>
                              </>
                            ) : (
                              ''
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <h6 className="title mt-3">
                      {t('text.report.transactionProcessingFee')}
                    </h6>
                    <div className="table-responsive">
                      <table className="table border">
                        <thead>
                          <tr>
                            <th>
                              {t('text.report.costPaid')} (
                              {t('text.report.creditCardTransaction')} (
                              {ncaDeatils?.creditCardPercentage}%+$
                              {ncaDeatils?.creditCardPerTransaction} per)){' '}
                            </th>
                            <th>{t('text.report.transactionCount')}</th>
                            <th>{t('text.report.transactionAmount')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{checkValidPrice(COST_PAID)}</td>
                            <td>{ncaDeatils?.creditCardUsedCount}</td>
                            <td>
                              {checkValidPrice(
                                ncaDeatils?.creditCardTransaction
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <h6 className="title mt-3">
                      {' '}
                      {t('text.report.planBilled')}
                    </h6>
                    <div className="table-responsive">
                      <table className="table border">
                        <thead>
                          <tr>
                            <th className="text-start">
                              {t('text.report.planAmount')}{' '}
                              {`(${capitalizeFirstLetter(
                                ncaDeatils?.billType
                              )})`}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-start">
                              {checkValidPrice(ncaDeatils?.totalAmountPaid)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="nk-notes ff-italic fs-12px text-soft">
                        {userRole === 'admin' ? (
                          <>
                            {ncaDeatils?.paymentStatus == 'success' ? (
                              <>
                                {ncaDeatils?.User?.CompanyNca?.name}{' '}
                                subscription will automatically renew on this
                                date{' '}
                                {dateFormatter(
                                  ncaDeatils?.User?.CompanyNca?.nextInvoiceDate,
                                  'MM-DD-YYYY'
                                )}
                              </>
                            ) : (
                              ''
                            )}
                          </>
                        ) : (
                          <>
                            Your subscription will automatically renew on this
                            date{' '}
                            {dateFormatter(
                              ncaDeatils?.User?.CompanyNca?.nextInvoiceDate
                            )}
                            . By subscribing to tekhne,you agree to our{' '}
                            <>
                              <Link to="/terms-conditions">
                                {t('text.common.termsAndConditions')}
                              </Link>{' '}
                              and{' '}
                              <Link to="/privacy-policy">
                                {t('text.common.privacyPolicy')}
                              </Link>
                            </>
                            .
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {userRole !== 'admin' ? (
                    <div>
                      <a
                        href="https://tekhne.app/contact-us/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t('text.common.contactUs')}
                      </a>
                    </div>
                  ) : null}
                  <div className="invoice-contact  mt-2">
                    <span className="overline-title">Powerd By</span>
                    <div className="invoice-contact-info mt-1">
                      <ImageElement source="logo.svg" />
                      <h6 className="title mb-0 mt-1">
                        {t('text.common.goodGuysLLC')}
                      </h6>
                    </div>
                  </div>
                </div>
              ) : (
                <NoDataFound
                  source="/nodata-found.svg"
                  text={t('text.common.noDataFound')}
                />
              )}
            </div>
          </div>
        </div>
      </PageContainerWrapper>
    </>
  );
}

export default NCABillingDetails;
