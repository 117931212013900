export const text = {
  heading: 'Providers',
  areYouSureDeleteProvider: 'you want to delete this Providers',
  providerNoteOne: 'Provider module will list all Provider on this page.',
  providerNoteTwo:
    'Administrator can Activate / Deactivate / Delete any Provider .',
  providerNoteThree: 'Administrator can export data in XLS format.',
  addProvider: 'Add Provider',
  providerName: 'Provider Name',
  providerNca: 'Provider NCA ',
  availability: 'Availability/Unavailability',
  manageService: 'Manage Services',
  documents: 'Document',
  editService: 'Edit Service',
  serviceRestriction: 'Service Restriction',
  serviceType: 'Service Type',
  providerProfile: 'Provider Details',
  scheduleJobRadius: 'Schedule Job Radius',
  selectScheduleJobRadius: 'Select schedule job radius',
  emergencyJobRadius: 'Emergency Job Radius',
  selectEmergencyJobRadius: 'Select emergency job radius',
  serviceProviderFees: 'Service Provider fee per Minute (In USD)',
  commissionPlaceholder: 'Admin Commission (Per Minute In USD) ',
  commissionPlaceholderPercent: 'Admin Commission (In percentage) ',
  googleAdAccount: 'Google Ad Account',
  selectGoogleAdAccount: 'Select google ad account',
  googleCampaign: 'Google Campaign',
  selectGoogleCampaign: 'Select google campaign',
  companyName: 'Company Name',
  companyNamePlaceHolder: 'Enter company name',
  componyName: 'Company Name',
  componyNamePlaceHolder: 'Enter company name',
  dateOfBirth: 'Date Of Birth',
  datePlaceholder: 'Enter Date',
  homeAddress: 'Home Address',
  businessAddress: 'Register business address',
  placeholderBusinessAddress: 'Enter registered business address',
  homePlaceholder: 'Select home address',
  employerIdNo: 'Employer ID number',
  employerIdNoPlaceholder: 'Enter employer ID number',
  uploadDocument1: 'Upload Document 1',
  uploadDocument2: 'Upload Document 2',
  businessType: 'Business Type',
  businessTypePlaceholder: 'Select business type',
  firstName: 'First Name',
  firstNamePlaceHolder: 'Enter first name',
  lastName: 'Last Name',
  lastNamePlaceHolder: 'Enter last name',
  emailPlaceholder: 'Enter email',
  jobTitle: 'Job Title',
  jobTitlePlaceHolder: 'Enter job title',
  addressPlace: 'Enter address',
  address1: 'Address Line 1',
  address1Placeholder: 'Enter address line 1',
  address2: 'Address Line 2',
  address2Placeholder: 'Enter address line 2',
  state: 'State',
  statePlaceholder: 'Enter state',
  city: 'City',
  cityPlaceholder: 'Enter city',
  zipCodePlaceholder: 'Enter zip code',
  socialNumber: 'Last 4 Digit Of Social Security Number',
  socialNumberPlaceholder: 'Enter last 4 digit of social security number',
  documentFront: 'Upload Identity Document Front',
  documentBack: 'Upload Identity Document Back',
  dateOfBirthPlaceHolder: 'Enter date',
  bankName: 'Bank Name',
  bankNamePlaceholder: 'Enter bank name',
  bankHolderName: 'Bank Account Holder Name',
  bankHolderNamePlaceholder: 'Enter bank account holder name',
  accountNumber: 'Account Number',
  accountNumberPlaceholder: 'Enter account number',
  routingNumber: 'Bank Account Routing Number',
  routingNumberPlaceholder: 'Enter bank account routing number ',
  bankLocation: 'Bank Location',
  bankLocationPlaceholder: 'Enter bank location',
  adminCommission: 'Admin Commission fee (In percentage)',
  editAvailability: 'Edit Availability',
  serviceProviderManualWorkingTime: 'Service Provider Manual Working Time ',
  pleaseSelectTimeSelectionType: ' Please select time selection type ',
  setServiceProviderWorkingTime: 'Set Service Provider Working Time',
  timeOff: 'Time Off',
  setDayInfo: 'Set Day(s) or Time Frame(s) you are not available to work.',
  setDayDescription:
    'To block off day(s) or time frame(s) hit the Select Date button below. Choose Block Entire Day or select the time frame(s) you need to block off for that date.',
  setDayDescriptionTwo:
    ' When you are set to Online you will be sent emergency service request 24|7. This should only be on if you available to run the service request.',
  restrictForServiceProvider: 'Restrict for service provider',
  setAutoApproveBooking: 'Set Auto Approve Booking ',
  selectApproveAllBookingAutomatically:
    'Select Approve All Booking Automatically',
  spWorkLocation: 'SP Work Location',
  workLocationIsTheServiceAreaSPWillProvideService:
    'Work Location is the service area SP will provide service.',
  SelectWorkLocation: 'Select work location',
  spJobRadius: 'SP Job Radius',
  selectJobRadius: 'Select Job Radius',
  selectBlockDays: 'Select Block Days',
  blockTimeFrame: 'Block Time Frame',
  blockEntireDay: ' Block Entire Day',
  unblockEntireDay: ' Unblock Entire Day',
  NoteRepresenting: 'Note: (_) Is representing day/slot is blocked.',
  profile: 'Profile',
  companyProfile: 'Company Profile',
  businessProfile: 'Business Details',
  bankingProfile: 'Banking Details ',
  lessonProfile: 'Procedure Lessons Details',
  company: 'Company',
  companyPlaceHolder: 'Enter company name',
  doingBusiness: 'Doing Business As',
  doingBusinessPlaceHolder: 'Enter doing business as',
  identityFictionsNumber: 'Employer identification number',
  identityFictionsNumberPlaceHolder: 'Enter employer identification number',
  industry: 'Industry',
  industryPlaceHolder: 'Enter industry',
  registerBusinessAddress: 'Registered Business Address',
  registerBusinessAddressPlaceHolder: 'Enter registered business address',
  businessWebSite: 'Business Website',
  businessWebSitePlaceholder: 'Enter business website',
  businessIdNumber: 'Business ID Number (Tax Id)',
  businessIdNumberPlaceholder: 'Enter business ID number',
  vatNumber: 'Vat Number',
  vatNumberPlaceholder: 'Enter vat number',
  emergencyJobRadiusOther: 'Other Miles Radius (For Emergency Job)',
  radiusOtherPlaceholder: 'Enter other miles radius ',
  scheduleJobRadiusOther: 'Other Miles Radius (For Schedule Job) ',
  enterCustomerTag: 'Select customer tags',
  enterJobTag: 'Select job tags',
  enterCustomerNotes: 'Enter note',
  ownershipPercentage: 'Percentage Ownership of Business',
  primary_representative:
    ' This person is authorized as the primary representative of the account.',
  percent_ownership: ' This person owns 25% or more of the company.',
  governing_board:
    'This person is a member of the governing board of the company.',
  senior_manager_executive:
    '  This person is an executive or senior manager with significant management responsibility.',
  saveAndContinue: 'Save and continue',
  expireDate: 'Enter document expire date',
  addPerson: 'Add Person',
  businessPhoneNumber: 'Business Phone Number',
  setAutoApproveBookingTitle: 'Set Auto Approve Booking',
  CustomersInfo:
    'Customers will be able to schedule you by selecting “Book Later” appointments through the App, on the day and time frames you have made available and do not have a appointment already scheduled. It is best to set “Approve All Bookings Automatically” to increase your chance of filling your work schedule. At times, you may want to not allow next day bookings. You are provided an option to set the following: Approve All Bookings Automatically,2 Days Out, 3 Days Out or 7 Days Out. These are calendar days, not business days. Any appointment beyond 7 days you would be required to accept the booking automatically. If a customer books an appointment earlier than what you have as “Auto Approved” you will have one hour to confirm the appointment, before it is sent to the next available service provider.',
  areYouSureAdmin: 'you want to delete this Admin',
  addProvider: 'Add',
  addProviderTitle: 'Add Provider',
  providerDetails: 'Provider Details',
  invoiceMessagePermission: 'Invoice Message Permission',
};
