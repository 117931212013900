import React, { useState } from 'react';
import {
  ActionWrapper,
  CommonButton,
  NoteFooter,
  PageContainerWrapper,
  TablePlus,
  Toggle,
} from '../../../../../../components';
import { accessRoute } from '../../../../../../routes/PriceManagement';
import { accessRoute as NCARoute } from '../../../../../../routes/AdminSetting';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useNavigate, useParams } from 'react-router-dom';

import {
  breadcrumbServiceSubcategory,
  breadcrumbServiceSubcategoryNCA,
} from '../../../../../../services/Columns';

import { backToPage, modalNotification } from '../../../../../../utils';
import { useSelector } from 'react-redux';
import useTable from '../../../../../../hooks/useTable.hook';
import { serviceSubcategoryTableColumn } from '../../../../../../services/Columns/serviceSubcategory.service';
import {
  DeleteSubServiceCategoryService,
  ListSubServiceCategoryService,
  StatusSubServiceCategoryService,
} from '../../../../../../services/ManageServices/serviceSubcategory.service';
import { getActions } from '../../../../../../services/index.service';
import { ButtonFormatterWithZero } from '../../../../../../components/UIElements/Formatter';
import { accessRoute as ServiceType } from '../../../../../../routes/PriceManagement/priceManagement';

function ListSubServiceCategory({ ncaEdit = false, userId }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const permissionList =
    useSelector((state) => state?.permission?.permission) || {};

  const user = useSelector((state) => state?.auth?.userData);

  const isUserCanEdit =
    permissionList[ServiceType?.LIST_SERVICE?.moduleKey]?.arr?.length &&
    permissionList[ServiceType?.LIST_SERVICE?.moduleKey]?.arr?.join();

  const url =
    ncaEdit &&
    `${generatePath(NCARoute?.EDIT_NCA.path, {
      id: userId,
    })}`;

  const redirectUrl = ncaEdit ? url : accessRoute.LIST_SERVICE_CATEGORY.path;
  // For loader, modal, selected row, and form text
  const [state, setState] = useState({
    loader: null,
    modal: null,
    modalId: null,
    selectedRows: [],
    submitText: 'add',
  });
  const { loader, selectedRows } = state;

  // Get table data by api
  let extraQuery = {
    serviceCategoryId: id,
    ...(ncaEdit && { userId }),
  };
  const [tableHandler, tableProps] = useTable(
    ListSubServiceCategoryService,
    extraQuery,
    'category'
  );
  const {
    data,
    isLoading,
    count,
    page,
    noOfPages,
    sizePerPage,
    extraData,
    filter,
    sortBy,
    sortType,
  } = tableProps;

  const {
    onFilterChange,
    onTableSort,
    onExport,
    getData,
    onFilterResetChange,
    onConfirmDeleteAlert,
  } = tableHandler;

  // for breadcrumb
  const breadcrumbData = ncaEdit
    ? breadcrumbServiceSubcategoryNCA(url)
    : breadcrumbServiceSubcategory();

  // For action with column
  function onAction(e, handler, item) {
    e.preventDefault();
    if (handler) handler(item);
  }

  const actionFormatter = (status, options) => {
    return (
      <>
        {status ? (
          <ActionWrapper>
            {options?.map((item, key) => {
              return (
                <li
                  onClick={(e) => onAction(e, item?.handler, item)}
                  key={key}
                  className="action_list"
                  action={item?.action}
                >
                  {item?.name === 'Edit' ? (
                    <Link
                      to={generatePath(
                        `${
                          ncaEdit
                            ? NCARoute?.NCA_EDIT_SUB_CATEGORY.path
                            : accessRoute.EDIT_SUB_SERVICE_CATEGORY.path
                        }`,
                        {
                          subcategoryId: id,
                          id: item?.id,
                          userId,
                        }
                      )}
                    >
                      <em className={item?.icon} /> {item?.name}
                    </Link>
                  ) : (
                    <CommonButton>
                      <em className={item?.icon} /> {item?.name}
                    </CommonButton>
                  )}
                </li>
              );
            })}
          </ActionWrapper>
        ) : (
          '-'
        )}
      </>
    );
  };

  // Columns
  const columns = serviceSubcategoryTableColumn({
    onSort: onTableSort,
    onFilterChange: onFilterChange,
    filter,
    page,
    sizePerPage,
    Action: (props) =>
      actionFormatter(
        user?.Role?.type !== 'nca' ||
          props?.row?.createdByUser?.Role?.type === 'nca'
          ? true
          : false,
        [
          {
            name: 'Edit',
            icon: 'icon ni ni-edit',
            id: props?.row?.id,
            action: getActions('EDIT').value,
          },
          {
            name: 'Delete',
            icon: 'icon ni ni-trash',
            action: getActions('DELETE').value,
            handler: () => {
              onConfirmDeleteAlert({
                id: props?.row?.id,
                text: t(
                  'text.manageServices.areYouSureDeleteSubServiceCategory'
                ),
                service: DeleteSubServiceCategoryService,
                key: 'serviceSubcategoryIds',
              });
              document.body.click();
            },
          },
        ]
      ),
    Toggle: (props) => (
      <>
        <Toggle
          loading={loader === 'status-update'}
          defaultChecked={props?.row?.status === 'active' ? true : false}
          onChange={(e) => onToggleChange(e, props?.row)}
          disabled={
            user?.Role?.type === 'nca' &&
            props?.row?.createdByUser?.Role?.type !== 'nca'
          }
        />
      </>
    ),
    ViewButton: (props) =>
      ButtonFormatterWithZero(
        `${generatePath(
          ncaEdit
            ? NCARoute?.NCA_SERVICE_TYPE.path
            : accessRoute.LIST_SERVICE.path,
          {
            ...(ncaEdit && { userId }),
          }
        )}?id=${props?.row?.id}`,

        'View',
        props?.row?.subTypeCount,
        '',
        '_blank',
        isUserCanEdit === 'view' || isUserCanEdit === 'edit'
          ? false
          : user?.Role?.type === 'nca' && !isUserCanEdit
      ),
  });
  let columnExport = serviceSubcategoryTableColumn({
    onSort: onTableSort,
    onFilterChange: onFilterChange,
    filter,
    page,
    sizePerPage,
    isCategory: extraData,
  });

  // For Status change toggle function Start
  async function onToggleChange(e, row, type) {
    try {
      let status = e ? 'active' : 'inactive';
      if (type && type === 'action') {
        status = e;
      }
      const listOfIds = row?.id ? [row?.id] : [...row];
      // Payload id array change like userIds
      const payload = {
        serviceSubcategoryIds: listOfIds,
        status: status,
      };
      const { success, message } = await StatusSubServiceCategoryService({
        bodyData: payload,
      });
      if (success) {
        modalNotification({
          type: 'success',
          message,
        });
      }
      return success ? true : false;
    } catch (err) {
      return false;
    }
  }

  // For bulk action
  function onRowSelect(e) {
    try {
      setState({ ...state, selectedRows: [...e] });
    } catch (err) {
      console.log('err', err);
    }
  }
  // For bulk action
  async function handleActionSelect(e) {
    try {
      if (e?.target?.value === 'delete') {
        await onConfirmDeleteAlert({
          id: selectedRows,
          text: t('text.manageServices.areYouSureDeleteSubServiceCategory'),
          service: DeleteSubServiceCategoryService,
          key: 'serviceSubcategoryIds',
        });
        getData();
      } else {
        await onToggleChange(e.target.value, selectedRows, 'action');
        getData();
      }
    } catch (err) {
      console.log(err);
    }
  }
  // For header button

  const AddNCAurl =
    ncaEdit &&
    `${generatePath(NCARoute?.NCA_ADD_SUB_CATEGORY.path, {
      id,
      userId,
    })}`;

  const btn = {
    link: {
      name: t('text.manageServices.addSubServiceCategory'),
      url: ncaEdit
        ? AddNCAurl
        : generatePath(accessRoute.ADD_SUB_SERVICE_CATEGORY.path, {
            id,
          }),
    },
    csvExport: { handler: () => onExport('', columnExport) },
    extraButton: {},
  };

  return (
    <>
      <PageContainerWrapper
        breadcrumb={breadcrumbData}
        btnArray={['extraButton']}
        btn={btn}
        heading={`${t('text.manageServices.subServiceCategory')} ${
          extraData ? `( ${extraData} )` : ''
        } `}
        extraBtnText={t('text.common.back')}
        extraBtnClass="btn btn-outline-light bg-white"
        onExtraButtonHandleShow={() => backToPage(navigate, redirectUrl)}
      >
        <TablePlus
          hasLimit
          noOfPage={noOfPages}
          sizePerPage={sizePerPage}
          page={page}
          handleSelectedRows={onRowSelect}
          handleActionSelect={handleActionSelect}
          count={count}
          tableData={data}
          tableColumns={columns}
          selectRow
          tableLoader={isLoading}
          onFilterResetChange={onFilterResetChange}
          filter={filter}
          sortBy={sortBy}
          sortType={sortType}
        />
        <NoteFooter title={t('text.common.notes')}>
          <li>{t('text.manageServices.subCategoryNoteOne')}</li>
          <li>{t('text.manageServices.subCategoryNoteTwo')}</li>
          <li>{t('text.localization.noteThree')}</li>
        </NoteFooter>
      </PageContainerWrapper>
    </>
  );
}

export default ListSubServiceCategory;
