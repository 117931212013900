import { APIRequest } from '../../../helpers/index.helper';
import { logger } from '../../../utils';
import { NCA_SERVICE_CATEGORY_ENDPOINT } from '../../../apiEndpoints/NCA/serviceCategory.endpoint';

export const ListNCAExistingServiceCategoryService = async ({
  queryParams,
  type,
}) => {
  try {
    const payload = {
      ...NCA_SERVICE_CATEGORY_ENDPOINT.getServiceCategoryList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const ListNCAServiceCategoryService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...NCA_SERVICE_CATEGORY_ENDPOINT.getNCAServiceCategoryList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const addNCASubCategoryService = async ({ values }) => {
  try {
    const payload = {
      ...NCA_SERVICE_CATEGORY_ENDPOINT.addNCASubServiceCategory,
      bodyData: values,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
