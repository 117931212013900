import { StoreForgetPassword, StoreResetPassword } from '../../views/Store';
import HowItWorks from '../../views/CustomerApp/HowItWorks';
import TermConditions from '../../views/CustomerApp/TermConditions';
import PrivacyPolicy from '../../views/CustomerApp/PrivacyPolicy';
import ContactUs from '../../views/CustomerApp/ContactUs';
import StoreLogin from '../../views/Store/Auth/Login';
import StoreSignUp from '../../views/Store/Auth/Register';
import AboutUs from '../../views/CustomerApp/AboutUs';
import FAQs from '../../views/CustomerApp/FAQs';

export default function route() {
  return [
    {
      path: accessRoute.STORE_LOGIN.path,
      private: false,
      label: 'Login',
      name: 'login',
      key: accessRoute.STORE_LOGIN.path,
      belongsToHeader: false,
      icon: accessRoute.STORE_LOGIN.icon,
      element: <StoreLogin />,
    },
    {
      path: accessRoute.STORE_SIGNUP.path,
      private: false,
      name: 'Sign Up',
      key: accessRoute.STORE_SIGNUP.path,
      belongsToHeader: false,
      icon: accessRoute.STORE_SIGNUP.icon,
      element: <StoreSignUp />,
    },
    {
      path: accessRoute.STORE_FORGETPASSWORD.path,
      private: false,
      name: 'Forgot Password',
      key: accessRoute.STORE_FORGETPASSWORD.path,
      belongsToHeader: false,
      icon: accessRoute.STORE_FORGETPASSWORD.icon,
      element: <StoreForgetPassword />,
    },
    {
      path: `${accessRoute.STORE_RESETPASSWORD.path}/:token`,
      private: false,
      name: 'Reset Password',
      key: `${accessRoute.STORE_RESETPASSWORD.path}/:token`,
      belongsToHeader: false,
      icon: accessRoute.STORE_RESETPASSWORD.icon,
      element: <StoreResetPassword />,
    },
    {
      path: accessRoute.ABOUT_US.path,
      private: false,
      name: 'About US',
      key: accessRoute.ABOUT_US.path,
      belongsToHeader: false,
      icon: accessRoute.STORE_RESETPASSWORD.icon,
      element: <AboutUs />,
    },
    {
      path: accessRoute.HOW_IT_WORKS.path,
      private: false,
      name: 'How It Works',
      key: accessRoute.HOW_IT_WORKS.path,
      belongsToHeader: false,
      icon: accessRoute.STORE_RESETPASSWORD.icon,
      element: <HowItWorks />,
    },
    {
      path: accessRoute.FAQ.path,
      private: false,
      name: 'Faq',
      key: accessRoute.FAQ.path,
      belongsToHeader: false,
      icon: accessRoute.STORE_RESETPASSWORD.icon,
      element: <FAQs />,
    },
    {
      path: accessRoute.CONTACT_US.path,
      private: false,
      name: 'Contact Us',
      key: accessRoute.CONTACT_US.path,
      belongsToHeader: false,
      icon: accessRoute.STORE_RESETPASSWORD.icon,
      element: <ContactUs />,
    },
    {
      path: accessRoute.TERMS.path,
      private: false,
      name: accessRoute.TERMS.path,
      key: accessRoute.TERMS.path,
      icon: accessRoute.STORE_RESETPASSWORD.icon,
      element: <TermConditions />,
    },
    {
      path: accessRoute.PRIVACY.path,
      private: false,
      name: accessRoute.PRIVACY.path,
      key: accessRoute.PRIVACY.path,
      icon: accessRoute.STORE_RESETPASSWORD.icon,
      element: <PrivacyPolicy />,
    },
  ];
}

export const accessRoute = {
  STORE_LOGIN: {
    path: '/store',
  },
  STORE_SIGNUP: {
    path: '/store/register',
  },
  STORE_FORGETPASSWORD: {
    path: '/store/forgot-password',
  },
  STORE_RESETPASSWORD: {
    path: '/store/reset-password',
  },
  ABOUT_US: {
    path: '/store/about-us',
  },
  HOW_IT_WORKS: {
    path: '/store/how-it-works',
  },
  FAQ: {
    path: '/store/faq',
  },
  CONTACT_US: {
    path: '/store/contact-us',
  },
  PRIVACY: {
    path: '/store/privacy-policy',
  },
  TERMS: {
    path: '/store/terms-conditions',
  },
};
