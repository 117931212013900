import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  TablePlus,
  NoteFooter,
  PageContainerWrapper,
} from '../../../../../components';
import {
  Columns,
  // homePageService,
} from '../../../../../services/index.service';
import { accessRoute } from '../../../../../routes/AdminSetting';
// import useTable from '../../../../../hooks/useTable.hook';

function ListHomePage() {
  // const [tableData, setTableData] = useState([]);
  const { t } = useTranslation();
  // const [, tableProps] = useTable(homePageService);
  // const { data } = tableProps;

  // useEffect(() => {
  //   if (data) {
  //     let editHomeData = data.filter((e) => e.key === 'home_page_title');
  //     setTableData(editHomeData);
  //   }
  // }, [data]);
  const tableData = [
    {
      id: 1,
      value: 'Home Page',
    },
  ];
  const breadcrumb = Columns.homePageBreadcrumb();
  const columns = Columns.editHomepageTableColumn({
    url: accessRoute.EDIT_HOMEPAGE.path,
  });

  return (
    <>
      <PageContainerWrapper
        breadcrumb={breadcrumb}
        heading={t('text.common.homePageContent')}
        listingHeader={false}
      />
      <TablePlus
        searchField={false}
        hasLimit
        showAction={false}
        tableData={tableData}
        tableColumns={columns}
        noOfPage="1"
        sizePerPage="1"
        page="1"
        count="1"
      />
      <NoteFooter title={`${t('text.common.notes')} :`}>
        <li>{t('text.editHomePage.noteInfo')} </li>
      </NoteFooter>
    </>
  );
}

export default ListHomePage;
