export const text = {
  storeHeading: 'Promo Code Good Guys Affiliate',
  addStore: 'Add Store',
  storeNoteOne:
    'Promo Code Good Guys Affiliate module will list all stores on this page.',
  storeNoteTwo:
    'Admin can Activate / Deactivate / Delete any store. Default Store cannot be Activated / Deactivated / Deleted.',
  storeNoteThree: 'Admin can export data in XLS or PDF format.',
  name: 'Name or Company Name',
  totalAmount: 'Total Amount',
  pendingAmount: 'Pending Amount',
  transferAmount: 'Transfer Amount',
  document: 'View/Edit Documents',
  areYouSureDeleteStore: 'you want to delete this Store',
  addStoreHeading: 'Add Promo Code Good Guys Affiliate',
  editStoreHeading: 'Edit Promo Code Good Guys Affiliate',
  nameOrCompanyName: 'Name or Company Name',
  commissionFee: 'Commission Fee (In Dollar)',
  paymentEmail: 'Payment Email',
  accountHolderName: 'Account Holder Name',
  accountNumber: 'Account Number',
  nameOfBank: 'Name of Bank',
  bankLocation: 'Bank Location',
  bicSwiftCode: 'BIC/SWIFT Code',
  companyNamePlaceholder: 'Name or Company Name',
  commissionFeePlaceholder: 'Enter commission fee (in Dollar)',
  paymentEmailPlaceholder: 'Enter payment email',
  accountHolderNamePlaceholder: 'Enter account holder name',
  accountNumberPlaceholder: 'Enter account number',
  nameOfBankPlaceholder: 'Enter name of bank',
  bankLocationPlaceholder: 'Enter bank location',
  bicSwiftCodePlaceholder: 'Enter BIC/SWIFT code',
  DocumentsOfCompany: 'Documents of ',
  promoCodeJobsHeading: 'Promo Code Good Guys Affiliate Jobs',
  aCommissonAmount: 'A=Commission Amount',
  bTransferAmount: 'B=Transfer Amount',
  cABPendingAmount: 'C = A-B Pending Amount',
  earnFromCustomer: 'Earning from Customer',
  totalStoreAmount: 'Total Store Amount',
  totalTransferAmount: 'Total Transfer Amount',
  totalPendingAmount: 'Total Pending Amount',
  marketingEmailTemplate: 'Marketing Email Template',
  editEmailMarketingTemplate: 'Edit Marketing Email Template',
  addEmailMarketingTemplate: 'Add Marketing Email Template',
  noteEmail:
    'Marketing Email Templates module will list all emails on this page.',
  noteEdit: 'Administrator can add/edit any email template.',
  marketingSmsTemplate: 'Marketing SMS Template',
  addMarketingSmsTemplate: 'Add Marketing SMS Template',
  editMarketingSmsTemplate: 'Edit Marketing SMS Template',
  marketingModuleText:
    'Marketing SMS Templates module will list all sms on this page.',
  administratorSmsTemplate: 'Administrator can add/edit any sms template.',
  smsPurpose: 'SMS Purpose',
  smsBody: 'SMS Body',
  smsRequired: 'SMS purpose is required.',
  addTemplate: 'Add Template',
  shortCodes: 'Short Codes',
  enterSMSPurpose: 'Enter SMS Purpose',
  sms: 'SMS',
  smsPurposeIsRequired: 'SMS purpose is required.',
  dateOfSubmission: 'Date of submission',
  senderName: 'Sender Name',
  typeofMessageSent: 'Type of Message Sent',
  audience: 'Audience',
  moduleServicesTags: 'Module/Services/Tags',
  message: 'Message',
  approve: 'Approve',
  decline: 'Decline',
  viewReport: 'View Report',
  approvedDeclinedDate: 'Approved/Declined Date',
  typeOfAudience: 'Type of audience',
  unSubscribe: 'Unsubscribe user list',
  messageReport: 'Message Report',
  viewModules: '  View Modules',
  viewServices: 'View Services',
  viewTags: 'View Tags',
  customerTags: 'Customer Tags',
  jobTags: 'Job Tags',
  providerTags: 'Provider Name',
  headingReview: 'Marketing Review Message',
  marketingReview:
    '  Marketing Review Templates module will list all templates on this page.',
  modules: 'Modules',
  services: 'Services',
  subject: 'Subject',
  youWantApprove: 'You Want To Approve This Message?',
  youWantApproveMore: 'You are sending to 300+ Recipient, this will slow down the Apps. It is recommended to do this during Non-working business hours. ',
  youWantDecline: 'you want to decline this message?',
  messageType: 'Message type is required.',
  messageTemplate: 'Message template is required',
  audienceTyp: 'Audience Type is required',
  serviceProvider: 'Service Provider is required.',
  bookingMode: 'Booking Mode is required',
  service: 'Service is required',
  module: 'Module is required',
  notificationRequired: 'Notification template is required',
  emailTemplateRequired: 'Email template is required',
  messageTypeSend: 'Marketing Message Send',
  selectMessageType: 'Select Message Type',
  messageTypes: 'Message Type',
  selectAudience: 'Select Audience Type',
  selectService: 'Select Service Provider',
  selectBooking: 'Select Booking Mode',
  selectCustomer: 'Select Customer Tags',
  selectJob: 'Select Job Tags ',
  selectTemplate: 'Select Template',
  selectServices: 'Select Services',
  selectModule: 'Select Module',
  totalRecipient: 'Total Recipient',
  note: `Note : Please Don't Replace Variable Between # Sign.`,
  noTagsFound: 'No Tags Found',
  noModuleFound: 'No Module Found',
  noServiceFound: 'No Service Found',
  noSubjectFound: 'No Subject Found',
  messageSentDate: 'Message Sent Date',
  approvedDate: 'Approved Date',
  audienceCount: 'Audience Count',
  audienceName: 'Audience Name',
  pending: 'Pending',
  opened: 'Opened',
  bounced: 'Bounced',
  unsubscribed: 'Unsubscribed',
  complained: 'Complained',
  clickedOnLink: 'Clicked on Link	',
  messageStatus: 'Message status	',
  clickPerformance: 'Click Performance',
  enterSMS: 'Enter SMS',
  userType: 'User Type',
  serialNo : 'S No.',
  imageThumbnail:'Thumbnail',
  status:'Status',
  textual:'Textual Ads ',
  addTextual:'Add Textual Ads ',
  interstitial:'Interstitial Ads',
  addInterstitial:'Add Interstitial Ads',
  advertisements:'Advertisements',
  userType:'User Type',
  selectUserType:'Select User Type',
  enterDescription:'Enter Ads Description',
  selectUserType:'Select User Type',
  interstitialImageUploadNote:'[Note: Recommended dimension for image is 640 * 1136.]',
  areYouSureBanner: 'you want to delete this Ad',
};
