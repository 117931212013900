export const NCA_REPORT_ENDPOINT = {
  getNcaList: {
    url: 'v1/admin/nca/billings',
    method: 'GET',
  },
  getNcaUser: (id) => ({
    url: `v1/admin/nca/billing/detail/${id}`,
    method: 'GET',
  }),
  getPaymentReportList: {
    url: 'v1/admin/payment-report',
    method: 'GET',
  },
  getProviderList: {
    url: 'v1/admin/provider',
    method: 'GET',
  },
  getCustomerList: {
    url: 'v1/admin/customer',
    method: 'GET',
  },
  getInvoice: (bookingId) => ({
    url: `v1/admin/booking/invoice/${bookingId}`,
    method: 'GET',
  }),
  updateProviderDiscount: (bookingId) => ({
    url: `v1/admin/payment-report/discount/${bookingId}`,
    method: 'PUT',
  }),
  updateMarkAsSettled: {
    url: 'v1/admin/booking/mark-settle-payment',
    method: 'PUT',
  },
  getSplitPayment: (bookingId) => ({
    url: `v1/admin/split-payment/${bookingId}`,
    method: 'GET',
  }),
  getJobAmountDetails: {
    url: 'v1/admin/payment-report-sum',
    method: 'GET',
  },
  checkPayment: {
    url: 'v1/admin/booking/payment-check',
    method: 'POST',
  },
  financingPayment: {
    url: 'v1/admin/booking/payment-financing',
    method: 'POST',
  },
  creditCardPayment: {
    url: 'v1/admin/booking/payment-credit',
    method: 'POST',
  },
  cashPayment: {
    url: 'v1/admin/booking/payment-cash',
    method: 'POST',
  },
  warrantyPayment: {
    url: 'v1/admin/booking/payment-warranty',
    method: 'POST',
  },
  waivePayment: {
    url: 'v1/admin/booking/payment-admin-waive',
    method: 'POST',
  },
};
