let url = '/v1/admin/amount-calculation-formula';


export const AMOUNT_CALCULATION_ENDPOINT = {
  getAmountCalculationList: {
    url,
    method: 'GET',
  },
  deleteAmountCalculation: {
    url,
    method: 'DELETE',
  },
  statusUpdateAmountCalculation: {
    url: `${url}/change-status`,
    method: 'PUT',
  },
  addAmountCalculation: {
    url,
    method: 'POST',
  },
  editAmountCalculation: (id) => ({
    url: `${url}/${id}`,
    method: 'PUT',
  }),
  getAmountCalculationDetails: (id) => ({
    url: `${url}/${id}`,
    method: 'GET',
  }),
};
