import React from 'react';
import {
  FormWrapper,
  FormElementWrapper,
  TextInput,
  CommonButton,
} from '../../../../index';
import { initValues, validation } from './validation';

function AdminBankDetails({ onSubmit }) {
  return (
    <FormWrapper
      onSubmit={onSubmit}
      validation={validation}
      initialValues={initValues()}
      row={true}
    >
      <FormElementWrapper
        md={6}
        label="Bank Name  *"
        element={(formik) => (
          <TextInput name="bankName" placeholder="Bank Name " formik={formik} />
        )}
      />
      <FormElementWrapper
        md={6}
        label="Bank Account Holder Name *"
        element={(formik) => (
          <TextInput
            name="accountHolder"
            placeholder="Bank Account Holder Name"
            formik={formik}
          />
        )}
      />
      <FormElementWrapper
        md={6}
        label="Account Number *"
        element={(formik) => (
          <TextInput
            inputType="number"
            name="accountNumber"
            placeholder="Account Number"
            formik={formik}
          />
        )}
      />
      <FormElementWrapper
        md={6}
        label="Bank Account Routing Number *"
        element={(formik) => (
          <TextInput
            name="routingNumber"
            placeholder="Bank Account Routing Number"
            formik={formik}
          />
        )}
      />
      <FormElementWrapper
        md={6}
        label="Bank Location *"
        element={(formik) => (
          <TextInput
            name="bankLocation"
            placeholder="Bank Location"
            formik={formik}
          />
        )}
      />
      <FormElementWrapper
        md={6}
        label="Email  *"
        element={(formik) => (
          <TextInput
            name="email"
            inputType="email"
            placeholder="Email"
            formik={formik}
          />
        )}
      />
      <div className="form-group">
        <CommonButton
          type="submit"
          className="btn btn-lg btn-primary btn-block"
        >
          Save & Next
        </CommonButton>
      </div>
    </FormWrapper>
  );
}

export default AdminBankDetails;
