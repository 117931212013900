import React from 'react';
import { Link } from 'react-router-dom';
import CommonButton from '../../../components/UIElements/Button/Common/index.btn';

function ServiceType({
  list,
  path = '#',
  description = '',
  buttonText = 'Continue',
  cancelButton = false,
  confirmButton = false,
  handleCancel,
  handleCancelBooking,
  isLoader,
}) {
  return (
    <div>
      {list && list?.length
        ? list?.map((e) => <p className="mb-1">{e?.name}</p>)
        : null}
      {description && <p>{description}</p>}
      <div className="d-flex justify-content-end">
        {cancelButton && (
          <CommonButton
            onClick={() => handleCancel()}
            className="min-200 me-3 btn btn-light"
          >
            Cancel
          </CommonButton>
        )}
        {path && (
          <Link to={path} className="btn btn-primary min-200">
            {buttonText}
          </Link>
        )}
        {confirmButton && (
          <CommonButton
            onClick={() => handleCancelBooking()}
            className="btn btn-primary min-200"
            loading={isLoader}
          >
            {buttonText}
          </CommonButton>
        )}
      </div>
    </div>
  );
}

export default ServiceType;
