import { getActions } from '../../services/index.service';
import CreateManualBooking from '../../views/ManualBooking/Create';

export default function route() {
  return [
    {
      path: accessRoute.ADD_BOOKING.path,
      private: true,
      name: accessRoute.ADD_BOOKING.name,
      key: accessRoute.ADD_BOOKING.key,
      belongsToSidebar: true,
      icon: accessRoute.ADD_BOOKING.icon,
      action: accessRoute.ADD_BOOKING.action,
      submenu: false,
      element: <CreateManualBooking />,
      ...accessRoute.ADD_BOOKING,
    },
  ];
}

export const accessRoute = {
  ADD_BOOKING: {
    path: '/admin/add-booking',
    action: getActions('LIST').value,
    moduleKey: 'manual-bookings',
    label: 'Manual Booking',
    name: 'Manual Bookings',
    key: 'manual-bookings',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-calendar-booking-fill" />
      </span>
    ),
    order: 8,
  },
};

export const getChildrenRoutes = () => {
  return route();
};
