export const CUSTOMERS_ENDPOINT = {
  getCustomersApi: {
    url: '/v1/admin/customer',
    method: 'GET',
  },
  statusCustomer: {
    url: '/v1/admin/customer/change-status',
    method: 'PUT',
  },
  deleteCustomer: {
    url: '/v1/admin/customer',
    method: 'DELETE',
  },
  addCustomer: {
    url: '/v1/admin/customer',
    method: 'POST',
  },
  viewCustomer: (id) => ({
    url: `/v1/admin/customer/${id}`,
    method: 'GET',
  }),
  editCustomer: (id) => ({
    url: `/v1/admin/customer/${id}`,
    method: 'PUT',
  }),
  addCustomerNote: {
    url: '/v1/admin/customer/notes',
    method: 'POST',
  },
  getCustomerNote: (id) => ({
    url: `/v1/admin/customer/notes/${id}`,
    method: 'GET',
  }),

  getCustomerMemberShip: {
    url: '/v1/membership',
    method: 'GET',
  },
  memberShipPurchase: {
    url: 'v1/memberShip/purchase',
    method: 'POST',
  },
};
