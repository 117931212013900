import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, ContactUsForm } from '../../../components';
import { ContactUsService } from '../../../services/CustomerApp/index.service';
import { Columns } from '../../../services/index.service';
import { logger, modalNotification } from '../../../utils';
import { useLocation } from 'react-router-dom';

function ContactUs() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [captchaError, setCaptchaError] = useState(false);
  const [validCaptcha, setValidCaptcha] = useState(false);
  const location = useLocation();

  const breadcrumb = Columns.customerContactUsBreadcrumb(
    location?.pathname?.includes('store')
  );
  async function onSubmit(value, resetForm) {
    setLoading(true);
    try {
      let body = { ...value };
      const response = await ContactUsService({ body });
      if (response?.success) {
        setSuccess(true);
        resetForm({});
        modalNotification({
          type: 'success',
          message: response?.message,
        });
        setCaptchaError(false);
        setValidCaptcha(false);
      } else {
        setCaptchaError(true);
      }
    } catch (err) {
      logger(err);
    }
    setLoading(false);
  }

  return (
    <>
      <main className="aboutPage">
        <Container>
          <Breadcrumb
            title={t('text.common.contactUs')}
            breadcrumb={breadcrumb}
            type="customer"
          />
          <div className="aboutPage_inner">
            <div>
              <h2 className="aboutPage_title mt-0 mb-0 text-center">
                {t('text.common.contactUsHeading')}
              </h2>
              <div className="aboutPage_form mx-auto w-100">
                <ContactUsForm
                  onSubmit={onSubmit}
                  loading={loading}
                  success={success}
                  setCaptchaError={setCaptchaError}
                  setValidCaptcha={setValidCaptcha}
                  captchaError={captchaError}
                  validCaptcha={validCaptcha}
                />
              </div>
            </div>
          </div>
        </Container>
      </main>
    </>
  );
}

export default ContactUs;
