import React from 'react';

function NoteFooter({ title, children, card = true, customIcon = false }) {
  if (customIcon) {
    return <>
      <div className="bq-note mt-3 mt-lg-4 mt-xl-5">
        <div className="bq-note-text">
          <div className="nk-block-head nk-block-head-sm nk-block-between">
            <h5 className="title">Notes:</h5>
          </div>
          <ul className="list list-svg">
            {children}
          </ul>
        </div>
      </div>
    </>
  }
  return (
    <div className={`${card ? 'card' : ''} mt-3`}>
      <div className={card ? 'card-inner' : ''}>
        <div className="bq-note">
          <div className="bq-note-text">
            <div className="nk-block-head nk-block-head-sm nk-block-between">
              <h5 className="title">{title}</h5>
            </div>
            <ul className="list list-sm list-checked">{children}</ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoteFooter;
