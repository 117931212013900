import React from 'react';
import { CheckAndFinanceForm } from '../../../../../../components';
import { checkPaymentService } from '../../../../../../services/index.service';
import { modalNotification } from '../../../../../../utils';
import { useState } from 'react';
import { purchaseMemberShipPlan } from '../../../../../../services/Customers/customers.service';

export default function CheckComponent({
  defaultKey,
  rowData,
  getData,
  setIsOpenModal,
  authorizedUser,
  paymentDetails,
  memberShip = false,
  isAlreadyActivate,
  setMemberShipAPIData,
}) {
  const [loading, setLoading] = useState(false);
  const onSubmit = async (value) => {
    setLoading(true);
    try {
      let bodyData = {
        bookingId: rowData?.BookingDetail?.bookingId,
        paidAmount: value?.amountToPay,
        bankName: value?.bankName,
        checkNumber: value?.checkNumber,
        reason: paymentDetails?.reason,
      };

      let memberShipBodyData = {
        ...(isAlreadyActivate ? { isUpgradePlan: 1 } : ''),
        customerId: rowData?.customerId,
        membershipPlanId: rowData?.memberShipPlanId,
        durationType: rowData?.planType,
        bankName: value?.bankName,
        checkNumber: value?.checkNumber,
        paymentMethod: 'check',
      };
      const response = memberShip
        ? await purchaseMemberShipPlan({ memberShipBodyData })
        : await checkPaymentService({ bodyData });
      if (response?.success) {
        modalNotification({
          type: 'success',
          message: response?.message,
        });
        setIsOpenModal(false);
        setMemberShipAPIData([]);
        getData();
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  return (
    <CheckAndFinanceForm
      onSubmit={onSubmit}
      defaultKey={defaultKey}
      rowData={rowData}
      loading={loading}
      authorizedUser={authorizedUser}
      paymentDetails={paymentDetails}
    />
  );
}
