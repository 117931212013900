import * as RoleService from './role.service';
import * as AdminService from './admin.service';
import * as ProviderService from './provider.service';
import * as ManageDocumentService from './document.service';

export const TeamSetting = {
  ...RoleService,
  ...AdminService,
  ...ProviderService,
  ...ManageDocumentService,
};
