import React, { useEffect, useState } from 'react';
import {
  ActionWrapper,
  TablePlus,
  Toggle,
  ModalWrapper,
  PageContainerWrapper,
  AddEditVariantForm,
} from '../../../../components';
import { Link } from 'react-router-dom';
import { modalNotification } from '../../../../utils';
import {
  Columns,
  ShoppingCartServices,
  getActions,
} from '../../../../services/index.service';
import { useTranslation } from 'react-i18next';
import { useTable } from '../../../../hooks/index.hook';
import { variantManagementBreadcrumb } from '../../../../services/Columns';
import {
  deleteVariantService,
  getVariantDetailsService,
} from '../../../../services/ShoppingCart/variantManagement.service';
import { firstLetterCaps } from '../../../../utils/text.util';

function VariantManagementList(props) {
  const breadcrumb = variantManagementBreadcrumb();
  const { t } = useTranslation();
  const extraQuery = {
    scope: 'all',
  };
  const [tableHandler, tableProps] = useTable(
    ShoppingCartServices?.getVariantService,
    extraQuery
  );
  const [lenError, setLenError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [isCheckError, setIsCheckError] = useState(false);

  const {
    data,
    isLoading,
    count,
    page,
    noOfPages,
    sizePerPage,
    sortBy,
    sortType,
    filter,
  } = tableProps;
  const {
    tableReset,
    // onRefresh,
    getData,
    onTableSort,
    onFilterChange,
    onConfirmDeleteAlert,
    onRefresh,
    onExport,
    onFilterResetChange,
  } = tableHandler;
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [state, setState] = useState({
    loader: null,
    modal: null,
    modalId: null,
    selectedRows: [],
    submitText: 'add',
  });
  const { loader, modal, modalId, selectedRows, submitText } = state;
  const onConfirmAlert = () => {
    setIsAlertVisible(false);
    modalNotification({
      type: 'success',
      message: 'Variant Deleted Successfully',
    });
    return true;
  };
  function onAction(e, handler) {
    e.preventDefault();
    handler();
  }

  const statusActionOption = [
    {
      id: 1,
      name: 'Select Action',
    },
    {
      id: 2,
      name: 'Make Active',
    },
    {
      id: 3,
      name: 'Make Inactive',
    },
    {
      id: 4,
      name: 'Make Delete',
    },
  ];

  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler)}
              key={key}
              action={item?.action}
              className="action_list"
            >
              <Link to="#" onClick={(e) => e.preventDefault()}>
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };

  const multipleButton = [
    {
      title: 'Download sample',
      icons: 'icon ni ni-download',
      buttonClass: 'btn btn-outline-light',
    },
    {
      title: 'Add Variant',
      handler: () => {},
    },
  ];
  async function onToggleChange(e, row, type) {
    try {
      let status =
        e === 'active' ? 'active' : e === 'deleted' ? 'deleted' : 'inactive';
      if (type && type === 'action') {
        status = e;
      }
      const listOfIds = row?.id && row?.id;
      const payload = {
        status: status,
      };
      const id = listOfIds;
      const { success, message } = await ShoppingCartServices?.variantStatus({
        bodyData: payload,
        queryParams: id,
      });
      if (success) {
        modalNotification({
          type: 'success',
          message,
        });
        onRefresh();
      }
      return success ? true : false;
    } catch (err) {
      return false;
    }
  }
  const columns = Columns.variantManagementTableColumn({
    onSort: onTableSort,
    page,
    sizePerPage,
    onFilterChange: onFilterChange,
    filter,
    Action: (
      props
      // row
    ) =>
      actionFormatter([
        {
          name: t('text.common.edit'),
          icon: 'icon ni ni-edit',
          action: getActions('EDIT').value,
          handler: () => {
            setState({
              ...state,
              modal: 'edit-variant',
              submitText: 'Update',
              modalId: props?.row?.id,
            });
          },
        },
        {
          name: t('text.common.delete'),
          icon: 'icon ni ni-trash',
          action: getActions('DELETE').value,
          handler: () =>
            // item
            {
              onConfirmDeleteAlert({
                id: props?.row?.id,
                text: t('text.shoppingCart.areYouSureVariant'),
                service: deleteVariantService,
                key: 'id',
              });
              // document.body.click();
            },
        },
      ]),
    Toggle: (props) => (
      <Toggle
        isDropDown={true}
        shouldDeleteInclude={true}
        loading={loader === 'status-update'}
        defaultChecked={firstLetterCaps(props?.row?.status)}
        onChange={(e) => onToggleChange(e, props?.row)}
        onRefresh={onRefresh}
      />
    ),
  });
  async function getVariantDetails(id) {
    try {
      const res = await getVariantDetailsService({ id });
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    if (modalId) {
      getVariantDetails(modalId);
    }
  }, [modalId]);
  const CategoryManagementService = () => {
    return [
      {
        id: 1,
        variantName: 'Size',
      },
      {
        id: 2,
        variantName: 'Color',
      },
      {
        id: 3,
        variantName: 'Company Name',
      },
    ];
  };
  function onRowSelect(e) {
    try {
      setState({ ...state, selectedRows: [...e] });
    } catch (err) {
      console.log('err', err);
    }
  }

  async function handleActionSelect(e) {
    try {
      if (e.target.value === 'delete') {
        await onConfirmDeleteAlert({
          id: selectedRows,
          text: t('text.common.areYouSureBanner'),
          service: '', //
          key: 'id',
        });
        getData();
      } else {
        await onToggleChange(
          e,
          props?.row,
          'action',
          // Banner.bannerService.updateBannerStatus,
          'resourceId'
        );
        getData();
      }
    } catch (err) {
      console.log(err);
    }
  }
  function onCancel() {
    setState({ ...state, modal: null, modalId: null });
  }
  async function onSubmit(payload) {
    setLoading(true);
    setState({ ...state, loader: 'add' });
    try {
      let response = '';
      if (modalId)
        response = await ShoppingCartServices?.editVariantService({
          bodyData: payload,
          id: modalId,
        });
      else
        response = await ShoppingCartServices?.addVariantService({
          bodyData: payload,
        });
      const { success, message } = response;

      if (success) {
        setState({ ...state, modal: null, loader: null, modalId: null });
        modalNotification({
          type: 'success',
          message,
        });
        onRefresh();
      } else {
        setState({ ...state, loader: null });
      }
      setFormData({});
    } catch (error) {
      console.log(error);
      setState({ ...state, loader: null });
    }
    setLoading(false);
  }

  const modalForm = {
    add: (
      <AddEditVariantForm
        onEdit={getVariantDetails}
        onSubmit={onSubmit}
        onCancel={onCancel}
        loading={loading}
        modalId={modalId}
        submitText={submitText}
        state={state}
        setState={setState}
        isCheckError={isCheckError}
        setIsCheckError={setIsCheckError}
        lenError={lenError}
        setLenError={setLenError}
      />
    ),
  };

  const handleAddImport = (recordData) => {
    try {
      if (recordData?.path) {
        // getApiData({
        //   service: LineImportListService,
        //   setData: setCompareFileData,
        //   queryParams: {
        //     file: recordData?.path,
        //   },
        //   dispatch,
        //   setRedux: importFileAPIData,
        // });
        // dispatch(importFileData({ ...recordData }));
        // setImportData(recordData);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const headerBtn = {
    add: {
      name: t('text.shoppingCart.addVariant'),
      handler: () => {
        setState({ ...state, submitText: 'Add', modal: 'add-variant' });
      },
    },
  };

  return (
    <>
      <PageContainerWrapper
        heading={t('text.shoppingCart.variantManagement')}
        breadcrumb={breadcrumb}
        btn={headerBtn}
      >
        <TablePlus
          noOfPage={noOfPages}
          sizePerPage={sizePerPage}
          page={page}
          count={count}
          selectRow={true}
          filter={filter}
          onFilterResetChange={onFilterResetChange}
          hasLimit
          tableData={data}
          tableColumns={columns}
          showAction={false}
          tableLoader={isLoading}
          sortBy={sortBy}
          sortType={sortType}
          // searchPlaceholder={'Variant Name'}
          // isSearch={true}
          // searchField
        />
        <ModalWrapper
          heading={`${
            submitText === 'Add' ? t('text.common.add') : t('text.common.edit')
          } Variant`}
          onCancel={onCancel}
          content={modalForm['add']}
          show={['add-variant', 'edit-variant'].includes(modal)}
        />
      </PageContainerWrapper>
    </>
  );
}

export default VariantManagementList;
