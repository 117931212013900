let url = '/v1/admin/quote-order-admin-profit';
export const QUOTE_ORDER_ENDPOINT = {
  getQuoteOrderList: {
    url,
    method: 'GET',
  },
  deleteQuoteOrder: {
    url,
    method: 'DELETE',
  },
  addQuoteOrder: {
    url,
    method: 'POST',
  },
  editQuoteOrder: (id) => ({
    url: `${url}/${id}`,
    method: 'PUT',
  }),
  getQuoteOrderDetails: (id) => ({
    url: `${url}/${id}`,
    method: 'GET',
  }),
};
