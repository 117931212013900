import { Form, AutoComplete } from 'antd';
import { useField } from 'formik';
import { memo } from 'react';

function Input({
  name,
  // icon,
  // setFieldValue,
  formik,
  inputType,
  cityKey,
  stateKey,
  zipKey,
  countryKey,
  onKeywordChange,
  placeholder,
  defaultOptions,
  onLocationSelect,
  options,
  extraClassName = '',
  size = '',
  countryPhoneCodeKey,
  isFormik = true,
  value,
  defaultValue,
  ...rest
}) {
  const [field, meta, helper] = useField(name);
  const config = { ...field, ...rest };
  if (meta && meta?.touched && meta?.error) {
    config.error = true;
    config.helperText = meta.error;
  }

  function onChange(e) {
    try {
      if (isFormik) {
        helper.setValue(e);
        formik?.handleChange(e);
      }
      onKeywordChange(e);
    } catch (err) {
      console.log(err);
    }
  }

  function onSelectItem(e) {
    if (isFormik) {
      helper.setValue(e);
    }
    const keys = { cityKey, countryKey, countryPhoneCodeKey, stateKey, zipKey };
    onLocationSelect(e, formik?.setFieldValue, keys, defaultOptions);
  }

  return (
    <Form.Item
      label={rest?.label}
      help={meta?.error && meta?.touched ? meta.error : ''}
      validateStatus={config?.error ? 'error' : 'success'}
      // rules={[{required:true,message:"Address is required"}]}
    >
      <AutoComplete
        id={config?.error ? 'error' : 'success'}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        // defaultValue={options.find(e=>e?.description===field?.value)}
        value={defaultValue ? defaultValue : field?.value}
        options={options.map((e) => ({
          label: e?.description,
          value: e?.description,
        }))}
        type={inputType}
        onSelect={onSelectItem}
        {...rest}
        size={size}
        className={`${extraClassName}`}
        // filterOption={true}
      />
    </Form.Item>
  );
}

export default memo(Input);
