export const validation = {
  description: 'Description is required',
  finDescription: 'Financing description is required',
  descriptionInfo: 'Information description is required',
  infoUrl: 'Information url is required',
  finBankName: 'Bank name is required',
  finBankUrl: 'Bank url is required',
  tipOneUrl: 'Tip1 url is required',
  tipOneTitle: 'Tip1 title is required',
  tipTwoTitle: 'Tip2 title is required',
  tipTwoUrl: 'Tip2 url is required',
  tipThreeTitle: 'Tip3 title is required',
  tipThreeUrl: 'Tip3 url is required',
  tipOneDescription: 'Tip1 description is required',
  tipTwoDescription: 'Tip2 description is required',
  tipThreeDescription: 'Tip3 description is required',
  infoUrlValid: 'Please enter valid information url',
  quoteInformation: 'Quote information is required',
  bankUrlValid: 'Please enter valid financial bank url',
  tipUrlValid: 'Please enter valid tip url',
  finBank1Name: 'Financing bank1 is required',
  finBank1Url: 'Financing bank1 url is required',
  finBank2Name: 'Financing bank2 is required',
  finBank2Url: 'Financing bank2 url is required',
};
