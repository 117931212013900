import { TRUCK_ORDER_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';

export const getTruckOrderService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...TRUCK_ORDER_ENDPOINT?.getTruckOrderList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const removeTruckOrderService = async ({ bodyData }) => {
  try {
    const payload = {
      ...TRUCK_ORDER_ENDPOINT.deleteTruckOrder,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const addTruckOrderService = async ({ bodyData }) => {
  try {
    const payload = {
      ...TRUCK_ORDER_ENDPOINT.addTruckOrder,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const orderTruckService = async ({ bodyData }) => {
  try {
    const payload = {
      ...TRUCK_ORDER_ENDPOINT.orderTruckOrder,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getTruckOrderAmountService = async (queryParams) => {
  try {
    const payload = {
      ...TRUCK_ORDER_ENDPOINT?.getTruckOrderAmount,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getTruckSupplyHouseService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...TRUCK_ORDER_ENDPOINT?.getSupplyHouseList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
  }
};
