import {
  CommonButton,
  getInputSearchFilter,
  getMultiSelectSearchFilter,
} from '../../components';
import i18next from 'i18next';
import {
  checkValidData,
  checkValidPrice,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';

import { getFromToDateFilter } from '../../components/Filter/Common/date.filter';
import { dateFormatter, capitalizeFirstLetter } from '../../utils';
import { APP_DATE_INPUT_FORMAT } from '../../config';

const getPlanFailedData = () => {
  return [
    {
      name: 'Daily Cycle',
      id: 'cron',
    },
    {
      name: 'Cancel Plan',
      id: 'cancel',
    },
    {
      name: 'Update Plan',
      id: 'update',
    },
  ];
};

export const nclBillingBreadcrumb = () => {
  return [
    {
      path: '/admin/dashboard',
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.report.billingReport'),
    },
  ];
};

export const nclBillingDetailsBreadcrumb = () => {
  return [
    {
      path: '/admin/dashboard',
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '/admin/nca-billing-report',
      name: i18next.t('text.report.billingReport'),
    },
    {
      path: '#',
      name: i18next.t('text.report.billingReportDetails'),
    },
  ];
};

export const nclBillingColumn = ({
  onSort,
  Action,
  onFilterChange,
  filter = {},
  page,
  sizePerPage,
  handleOpenPayNowModal,
  handleMarkAsSettled,
  isLoading,
  role,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'ncaName',
      title: i18next.t('text.report.nca'),
      sorter: true,
      headerClasses: 'sorting',
      exportKey: 'User',
      exportName: 'NCA',
      exportFormatter: (cell, row) =>
        checkValidData(row?.User?.CompanyNca?.name),
      render: (cell, row) => checkValidData(row?.User?.CompanyNca?.name),
      ...getInputSearchFilter({
        dataIndex: 'ncaName',
        placeholder: 'nca',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'ncaPlanName',
      title: i18next.t('text.report.role'),
      // title: i18next.t('text.report.lastFourDigitsOfCard'),
      sorter: true,
      headerClasses: 'sorting',
      render: (cell, row) => checkValidData(row?.ncaPlanName),
      ...getInputSearchFilter({
        dataIndex: 'ncaPlanName',
        placeholder: 'role',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'cardNumberLast4',
      title: i18next.t('text.report.lastFourDigitsOfCard'),
      // sorter: true,
      headerClasses: 'sorting',
      render: (cell, row) => checkValidData(cell),
    },
    {
      dataIndex: 'billType',
      title: i18next.t('text.report.billType'),
      // sorter: true,
      headerClasses: 'sorting',
      render: (_, row) =>
        row?.billType ? capitalizeFirstLetter(row?.billType) : '-',
    },
    {
      dataIndex: 'planAmountPaid',
      title: i18next.t('text.report.planAmount'),
      // sorter: true,
      render: (cell, row) => checkValidPrice(row?.planAmountPaid),
      headerClasses: 'sorting',
    },
    {
      dataIndex: 'totalAmountPaid',
      title: i18next.t('text.report.totalAmount'),
      // sorter: true,
      exportName: 'Amount Paid',
      exportFormatter: (cell, row) =>
        checkValidData(row?.User?.CompanyNca?.name),
      render: (cell, row) => checkValidPrice(row?.totalAmountPaid),
      headerClasses: 'sorting',
    },
    {
      dataIndex: 'paymentStatus',
      title: i18next.t('text.report.paymentStatusSmall'),
      // sorter: true,
      headerClasses: 'sorting',
      render: (cell, row) => {
        if (
          row?.paymentStatus === 'failed' &&
          row?.paymentStatusFrom === 'cron'
        ) {
          return (
            <div>
              {row?.paymentStatus ? (
                <div>{capitalizeFirstLetter(row?.paymentStatus)}</div>
              ) : (
                '-'
              )}
              {role === 'nca' && (
                <CommonButton
                  className="btn btn-sm btn-primary"
                  onClick={() => handleOpenPayNowModal(row?.id)}
                >
                  {i18next.t('text.customerPayment.payNow')}
                </CommonButton>
              )}
            </div>
          );
        } else if (row?.paymentStatus === 'pending') {
          return (
            <div>
              <div>{capitalizeFirstLetter(row?.paymentStatus)}</div>
              {role !== 'nca' && (
                <CommonButton
                  loading={isLoading === row?.id}
                  disabled={isLoading}
                  className="btn btn-sm btn-primary"
                  onClick={() => handleMarkAsSettled(row?.id)}
                >
                  {i18next.t('text.nca.markAsSettled')}
                </CommonButton>
              )}
            </div>
          );
        } else {
          return row?.paymentStatus
            ? capitalizeFirstLetter(row?.paymentStatus)
            : '-';
        }
      },
      ...getInputSearchFilter({
        dataIndex: 'paymentStatus',
        placeholder: 'payment status',
        onFilterChange,
        filter,
      }),
      // ...getColumnSearchProps('payment status'),
    },
    {
      dataIndex: 'paymentStatusFrom',
      title: i18next.t('text.report.planFailedType'),
      // sorter: true,
      exportName: 'Failed Reason',
      headerClasses: 'sorting',
      render: (cell, row) => {
        if (row?.paymentStatus === 'success') {
          return '-';
        } else if (row?.paymentStatus === 'failed') {
          if ('cron' === row?.paymentStatusFrom) {
            return 'Billing Cycle';
          }
          if ('cancel' === row?.paymentStatusFrom) {
            return '	Plan Cancelled';
          }
          if ('update' === row?.paymentStatusFrom) {
            return 'Plan Updated ';
          } else {
            return row?.paymentStatusFrom
              ? capitalizeFirstLetter(row?.paymentStatusFrom)
              : '-';
          }
        }
      },
      ...getMultiSelectSearchFilter({
        dataIndex: 'paymentStatusFrom',
        onFilterChange,
        name: 'Plan Failed Type',
        list: [],
        mode: false,
        listService: getPlanFailedData,
        filter,
      }),
    },
    {
      dataIndex: 'createdAt',
      filterDataIndex: ['fromDate', 'toDate'],
      title: i18next.t('text.report.date'),
      sorter: true,
      headerClasses: 'sorting',
      exportFormatter: (cell, row, index) =>
        dateFormatter(row?.createdAt, 'YYYY-MM-DD'),
      render: (cell, row) =>
        row?.createdAt
          ? dateFormatter(row?.createdAt, 'D MMMM, YYYY, hh:mm A')
          : '-',
      // ...getColumnSelectDateProps('date'),
      ...getFromToDateFilter({
        dataStartIndex: 'fromDate',
        dataEndIndex: 'toDate',
        placeholderStartDate: 'From date',
        placeholderEndDate: 'To date',
        dateFormat: 'YYYY/MM/DD',
        onFilterChange,
        filter,
      }),
      // ...getFromToDateFilter({
      //   dataIndex: 'toDate',
      //   placeholderStartDate: 'Select date',
      //   placeholderEndDate: 'Select date',
      //   onFilterChange,
      //   filter,
      // }),
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.common.action'),
      // sorter: true,
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => {
        return <Action row={row} cell={cell} />;
      },
    },
  ];
};

export const nclBillingData = () => {
  return [
    {
      id: 1,
      nca: 'Rekha Test LTD',
      role: 'India make One',
      fourdigitsofcard: '4242',
      billType: 'Monthly',
      planAmount: '$ 200.00',
      totalAmount: '$ 200.00',
      paymentStatus: 'Success  ',
      planFailedType: '-',
      date: '20th November, 2022, 11:00 PM	',
    },
    {
      id: 2,
      nca: 'JAY pvt lmt',
      role: 'India make One',
      fourdigitsofcard: '4242',
      billType: 'Monthly',
      planAmount: '$ 200.00',
      totalAmount: '$ 200.00',
      paymentStatus: 'Success  ',
      planFailedType: '-',
      date: '20th November, 2022, 11:00 PM	',
    },
    {
      id: 3,
      nca: 'Teena Company',
      role: 'India make One',
      fourdigitsofcard: '4242',
      billType: 'Monthly',
      planAmount: '$ 200.00',
      totalAmount: '$ 200.00',
      paymentStatus: 'Success  ',
      planFailedType: '-',
      date: '20th November, 2022, 11:00 PM	',
    },
  ];
};
