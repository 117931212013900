import { login, changePassword, signup } from './Auth';
import { appLanguage as common } from './Common/index.i18n';
import { appLanguage as localization } from './Localization/index.i18n';
import { appLanguage as appText } from './AppText/index.i18n';
import { appLanguage as manageLocation } from './ManageLocation/index.i18n';
import { appLanguage as industry } from './Industry/index.i18n';
import { appLanguage as customers } from './Customers/index.i18n';
import { appLanguage as faq } from './Faq/index.i18n';
import { appLanguage as page } from './Page/index.i18n';
import { appLanguage as editHomePage } from './EditHomePage/index.i18n';
import { appLanguage as role } from './TeamSetting/index.i18n';
import { appLanguage as provider } from './Provider/index.i18n';
import { appLanguage as manageServices } from './ManageServices/index.i18n';
import { appLanguage as tags } from './Tags/index.i18n';
import { appLanguage as lineItems } from './LineItems/index.i18n';
import { appLanguage as report } from './Report/index.i18n';
import { appLanguage as notification } from './Notification/index.i18n';
import { appLanguage as quoteItems } from './QuoteManagement/index.i18n';
import { appLanguage as emailMessage } from './EmailMessage/index.i18n';
import { appLanguage as companySetting } from './CompanySetting/index.i18n';
import { appLanguage as request } from './Request/index.i18n';
import { appLanguage as serviceManagement } from './ServiceManagement/index.i18n';
import { appLanguage as equipmentInstall } from './NewEquipment/index.i18n';
import { appLanguage as equipmentOrder } from './EquipmentOrder/index.i18n';
import { appLanguage as homeInventory } from './HomeInventory/index.i18n';
import { appLanguage as scheduleBooking } from './ScheduleBooking/index.i18n';
import { appLanguage as customerApp } from './CustomerAppSetting/index.i18n';
import { appLanguage as operating } from './OperatingProcedure/index.i18n';
import { appLanguage as googleConnect } from './GoogleConnect/index.i18n';
import { appLanguage as promoCode } from './PromoCode/index.i18n';
import { appLanguage as documentManagement } from './DocumentManagement/index.i18n';
import { appLanguage as marketing } from './Marketing/index.i18n';
import { appLanguage as NCA } from './NCA/index.i18n';
import { appLanguage as truckOrdering } from './TruckOrdering/index.i18n';
import { appLanguage as ratingReview } from './CustomerApp/RatingReview/index.i18n';
import { appLanguage as willCallList } from './WillCallList/index.i18n';
import { appLanguage as manageSlot } from './ManageSlot/index.i18n';
import { appLanguage as store } from './Store/index.i18n';
import { appLanguage as contactUs } from './ContactUs/index.i18n';
import { appLanguage as siteStatistics } from './SiteStatistics/index.i18n';
import { appLanguage as customerHome } from './CustomerApp/CustomerHome/index.i18n';
import { appLanguage as adminProfile } from './AdminProfile/index.i18n';
import { appLanguage as customerPayment } from './CustomerApp/CustomerPayment/index.i18n';
import { appLanguage as customerService } from './CustomerApp/CustomerServiceProvider/index.i18n';
import { appLanguage as pushNotification } from './PushNotification/index.i18n';
import { appLanguage as customerMyBooking } from './CustomerApp/MyBooking/index.i18n';
import { appLanguage as requestViewDetails } from './CustomerApp/RequestViewDetails/index.i18n';
import { appLanguage as customerAppProfile } from './CustomerApp/CustomerProfile/index.i18n';
import { appLanguage as chat } from './Chat/index.i18n';
import { appLanguage as ManualBooking } from './ManualBooking/index.i18n';
import { appLanguage as dashboard } from './Dashboard/index.i18n';
import { appLanguage as customerBookAppointment } from './CustomerApp/BookAppointment/index.i18n';
import { appLanguage as customerFinancing } from './CustomerApp/CustomerFinancing/index.i18n';
import { appLanguage as customerTipOfTheMonth } from './CustomerApp/TipOfTheMonth/index.i18n';
import { appLanguage as scheduleServiceProvider } from './CustomerApp/ScheduleServiceProvider/index.i18n';
import { appLanguage as customerRatingReview } from './CustomerApp/RatingReview/index.i18n';
import { appLanguage as payOnlineGuest } from './PayOnlineGuest/index.i18n';
import { appLanguage as forgotPassword } from './Auth/ForgotPassword/index.i18n';
import { appLanguage as notificationTemplate } from './NotificationTemplate/index.i18n';
import { appLanguage as memberShip } from './Membership/index.i18n';
import { appLanguage as shoppingCart } from './ShoppingCart/index.i18n';
import { appLanguage as voiceRecords } from './VoiceRecords/index.i18n';
import { appLanguage as chatbotQuestionAnswerRecords } from './ChatbotQuestionAnswerRecord/index.i18n';

export default function lang() {
  return {
    text: {
      common: common.text,
      login: login.text,
      changePassword: changePassword.text,
      signup: signup.text,
      localization: localization.text,
      appText: appText.text,
      store: store.text,
      manageLocation: manageLocation.text,
      industry: industry.text,
      promoCode: promoCode.text,
      customers: customers.text,
      ratingReview: ratingReview.text,
      faq: faq.text,
      page: page.text,
      editHomePage: editHomePage.text,
      role: role.text,
      provider: provider.text,
      manageServices: manageServices.text,
      tags: tags.text,
      lineItems: lineItems.text,
      report: report.text,
      notification: notification.text,
      quote: quoteItems.text,
      emailMessage: emailMessage.text,
      companySetting: companySetting.text,
      request: request.text,
      serviceManagement: serviceManagement.text,
      equipmentInstall: equipmentInstall.text,
      equipmentOrder: equipmentOrder.text,
      homeInventory: homeInventory.text,
      scheduleBooking: scheduleBooking.text,
      customerApp: customerApp.text,
      googleConnect: googleConnect.text,
      nca: NCA.text,
      truckOrdering: truckOrdering.text,
      operating: operating.text,
      documentManagement: documentManagement.text,
      marketing: marketing.text,
      willCallList: willCallList.text,
      manageSlot: manageSlot.text,
      contactUs: contactUs.text,
      siteStatistics: siteStatistics.text,
      customerHome: customerHome.text,
      adminProfile: adminProfile.text,
      customerPayment: customerPayment.text,
      customerService: customerService.text,
      customerMyBooking: customerMyBooking.text,
      requestViewDetails: requestViewDetails.text,
      pushNotification: pushNotification.text,
      chat: chat.text,
      customerAppProfile: customerAppProfile.text,
      customerFinancing: customerFinancing.text,
      dashboard: dashboard.text,
      customerBookAppointment: customerBookAppointment.text,
      customerTipOfTheMonth: customerTipOfTheMonth.text,
      scheduleServiceProvider: scheduleServiceProvider.text,
      manualBooking: ManualBooking.text,
      customerRatingReview: customerRatingReview.text,
      payOnlineGuest: payOnlineGuest.text,
      forgotPassword: forgotPassword.text,
      notificationTemplate: notificationTemplate.text,
      memberShip: memberShip.text,
      shoppingCart: shoppingCart.text,
      voiceRecords: voiceRecords.text,
      chatbotQuestionAnswerRecords: chatbotQuestionAnswerRecords.text,
    },
    validation: {
      common: common.validation,
      login: login.validation,
      changePassword: changePassword.validation,
      signup: signup.validation,
      localization: localization.validation,
      appText: appText.validation,
      manageLocation: manageLocation.validation,
      industry: industry.validation,
      store: store.validation,
      promoCode: promoCode.validation,
      faq: faq.validation,
      page: page.validation,
      editHomePage: editHomePage.validation,
      customers: customers.validation,
      role: role.validation,
      provider: provider.validation,
      manageServices: manageServices.validation,
      tags: tags.validation,
      lineItems: lineItems.validation,
      report: report.validation,
      quote: quoteItems.validation,
      emailMessage: emailMessage.validation,
      companySetting: companySetting.validation,
      serviceManagement: serviceManagement.validation,
      homeInventory: homeInventory.validation,
      scheduleBooking: scheduleBooking.validation,
      customerApp: customerApp.validation,
      googleConnect: googleConnect.validation,
      nca: NCA.validation,
      truckOrdering: truckOrdering.validation,
      operating: operating.validation,
      documentManagement: documentManagement.validation,
      contactUs: contactUs.validation,
      marketing: marketing.validation,
      adminProfile: adminProfile.validation,
      customerPayment: customerPayment.validation,
      customerService: customerService.validation,
      pushNotification: pushNotification.validation,
      chat: chat.validation,
      customerAppProfile: customerAppProfile.validation,
      manualBooking: ManualBooking.validation,
      dashboard: dashboard.validation,
      customerBooking: customerMyBooking.validation,
      payOnlineGuest: payOnlineGuest.validation,
      memberShip: memberShip.validation,
      shoppingCart: shoppingCart.validation,
      voiceRecords: voiceRecords.validation,
      chatbotQuestionAnswerRecords: chatbotQuestionAnswerRecords.validation,
    },
  };
}
