import i18next from 'i18next';
import { accessRoute } from '../../routes/Dashboard';
import {
  getInputSearchFilter,
  getPriceFilter,
  getColumnSelectSearchProps,
} from '../../components';
import {
  checkValidData,
  checkValidDiscountType,
  checkValidPrice,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { accessRoute as membershipRoute } from '../../routes/Membership';
import { getStatusData, typesOfBenefit } from '../index.service';
import { readMoreTextShow } from '../../utils';

export const memberShipBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.memberShip.heading'),
    },
  ];
};
export const addMembershipPlanBreadcrumb = ({ text }) => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: membershipRoute.MEMBERSHIP.path,
      name: i18next.t('text.memberShip.heading'),
    },
    {
      path: '#',
      name:
        text === 'add'
          ? i18next.t('text.memberShip.addMembershipPlan')
          : i18next.t('text.memberShip.editMembershipPlan'),
    },
  ];
};
// use temporary keys
const monthlyCostFields = {
  fromOperatorField: 'monthlyCostFirstOperator',
  fromOperatorAmountField: 'monthlyCostFirstAmount',
  conditionalField: 'monthlyCostConditional',
  toOperatorField: 'monthlyCostSecondOperator',
  toOperatorAmountField: 'monthlyCostSecondAmount',
};
const yearlyCostFields = {
  fromOperatorField: 'yearlyCostFirstOperator',
  fromOperatorAmountField: 'yearlyCostFirstAmount',
  conditionalField: 'yearlyCostConditional',
  toOperatorField: 'yearlyCostSecondOperator',
  toOperatorAmountField: 'yearlyCostSecondAmount',
};
export const memberShipColumn = ({
  onSort,
  page,
  onFilterChange,
  filter,
  sizePerPage,
  Action,
  Toggle,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'title',
      title: i18next.t('text.common.title'),
      filterDataIndex: ['title'],
      sorter: true,
      onSort: (field, order) => onSort('name', order),
      render: (cell, row) => checkValidData(row?.name),
      ...getInputSearchFilter({
        dataIndex: 'title',
        placeholder: i18next.t('text.common.titlePlaceholder'),
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'monthlyCost',
      filterDataIndex: ['monthlyCostConditional'],
      title: i18next.t('text.memberShip.monthlyCost'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('monthlyPrice', order),
      render: (cell, row) => checkValidPrice(row?.monthlyPrice),
      ...getPriceFilter({
        dataIndex: 'price',
        onFilterChange,
        fields: monthlyCostFields,
        filter,
      }),
      // ...getColumnSelectAmountProps('monthly cost'),
    },
    {
      dataIndex: 'yearlyCost',
      filterDataIndex: ['yearlyCostConditional'],
      title: i18next.t('text.memberShip.yearlyOneTime'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('yearlyPrice', order),
      render: (cell, row) => checkValidPrice(row?.yearlyPrice),
      ...getPriceFilter({
        dataIndex: 'price',
        onFilterChange,
        fields: yearlyCostFields,
        filter,
      }),
      // ...getColumnSelectAmountProps('yearly one time cost'),
    },
    {
      dataIndex: 'planType',
      filterDataIndex: ['planType'],
      title: i18next.t('text.memberShip.planType'),
      // headerClasses: 'sorting',
      // sorter: true,
      // onSort: (field, order) => onSort('typeOfBenefit', order),
      render: (cell, row) =>
        row?.typeOfBenefit === "combo" ? i18next.t('text.memberShip.comboPlankey') :
          row?.typeOfBenefit === "service_category" ? i18next.t('text.memberShip.serviceCategoryPlankey') :
            row?.typeOfBenefit === "line_item" ? i18next.t('text.memberShip.lineItemPlankey') : 
              row?.typeOfBenefit === "quote" ? i18next.t('text.memberShip.quotePlankey') :
                i18next.t('text.common.noItem'),
      // ...getColumnSelectSearchProps({
      //   dataIndex: 'planType',
      //   onFilterChange,
      //   filter,
      //   mode: false,
      //   list: typesOfBenefit(),
      // }),
      // ...getColumnSearchProps('additional discount'),
    },
    {
      dataIndex: 'subCategoryDiscount',
      filterDataIndex: ['subCategoryDiscount'],
      title: i18next.t('text.memberShip.subCategoryDiscount'),
      headerClasses: 'sorting',
      render: (cell, row) =>
        row?.typeOfBenefit !== "quote" && row?.savingType && row?.savingAmount ?
          checkValidDiscountType(
            row?.savingType, row?.savingAmount) : i18next.t('text.common.noItem') ,
      // ...getInputSearchFilter({
      //   dataIndex: 'additionalDiscount',
      //   placeholder: i18next.t('text.memberShip.discountPlaceholder'),
      //   onFilterChange,
      //   filter,
      // }),
      // ...getColumnSearchProps('additional discount'),
    },
    {
      dataIndex: 'quoteDiscount',
      filterDataIndex: ['quoteDiscount'],
      title: i18next.t('text.memberShip.quoteDiscount'),
      headerClasses: 'sorting',
      // sorter: true,
      render: (cell, row) =>
        row.typeOfBenefit === 'quote' ?
          (row?.savingType && row?.savingAmount) ?
            checkValidDiscountType(
              row?.savingType, row?.savingAmount) : i18next.t('text.common.noItem')
          : (row?.quoteSavingType && row?.quoteSavingAmount) ?
              checkValidDiscountType(
                row?.quoteSavingType, row?.quoteSavingAmount) : i18next.t('text.common.noItem')
      // ...getInputSearchFilter({
      //   dataIndex: 'additionalDiscount',
      //   placeholder: i18next.t('text.memberShip.discountPlaceholder'),
      //   onFilterChange,
      //   filter,
      // }),
      // ...getColumnSearchProps('additional discount'),
    },
    {
      dataIndex: 'lineItemDiscount',
      filterDataIndex: ['lineItemDiscount'],
      title: i18next.t('text.memberShip.lineItemDiscount'),
      headerClasses: 'sorting',
      // sorter: true,
      render: (cell, row) =>
        row?.lineItemSavingType && row?.lineItemSavingAmount
          ? checkValidDiscountType(
            row?.lineItemSavingType,
            row?.lineItemSavingAmount
          )
          : i18next.t('text.common.noItem'),
      // ...getInputSearchFilter({
      //   dataIndex: 'additionalDiscount',
      //   placeholder: i18next.t('text.memberShip.discountPlaceholder'),
      //   onFilterChange,
      //   filter,
      // }),
      // ...getColumnSearchProps('additional discount'),
    },
    // {
    //   dataIndex: 'lengthOfMembership',
    //   title: i18next.t('text.memberShip.lengthOfMembership'),
    //   headerClasses: 'sorting',
    //   sorter: true,
    //   render: (cell, row) => checkValidData(cell),
    //   ...getInputSearchFilter({
    //     dataIndex: 'search',
    //     placeholder: i18next.t('text.memberShip.lengthOfMembershipPlaceholder'),
    //   }),
    //   // ...getColumnSearchProps('length of membership'),
    // },
    {
      dataIndex: 'displayOrder',
      title: i18next.t('text.memberShip.displayOrder'),
      render: (cell, row) => checkValidData(cell),
    },
    {
      dataIndex: 'description',
      title: i18next.t('text.common.description'),
      render: (cell, row) =>
        readMoreTextShow(
          checkValidData(cell),
          i18next.t('text.common.description'),
          20
        ),
    },
    {
      dataIndex: 'status',
      title: i18next.t('text.common.status'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => {
        return <Toggle row={row} cell={cell} />;
      },
      ...getColumnSelectSearchProps({
        dataIndex: 'status',
        name: 'status',
        onFilterChange,
        filter,
        mode: false,
        list: getStatusData(),
      }),
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.common.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => <Action row={row} cell={cell} />,
    },
  ];
};
