export const CHAT_ENDPOINT = {
  getChatRoomList: {
    url: '/v1/chat-room',
    method: 'GET',
  },
  getChatRoomDetailsById: (id) => ({
    url: `/v1/chat-room/${id}`,
    method: 'GET',
  }),
  getChatRoomMessagesByRoomId: (id) => ({
    url: `/v1/chat-messages/${id}`,
    method: 'GET',
  }),
  postAddChatRoom: {
    url: '/v1/chat-room',
    method: 'POST',
  },
  putEditChatRoom:(roomId)=> ({
    url: `/v1/chat-room/${roomId}`,
    method: 'PUT',
  }),
  postSendMessageInChatRoom: {
    url: '/v1/save-message',
    method: 'POST',
  },
  postAddChatRoomMember: {
    url: '/v1/chat-room/add-member',
    method: 'POST',
  },
  deleteChatRoomMemberById: (memberId, roomId) => ({
    url: `/v1/chat-room/remove-member/${memberId}/${roomId}`,
    method: 'DELETE',
  }),
  deleteChatGroupById: (roomId) => ({
    url: `/v1/chat-room/${roomId}`,
    method: 'DELETE',
  }),
  postChatSendMediaById: (roomId) => ({
    url: `/v1/chat-room/send-media/${roomId}`,
    method: 'POST',
  }),
  downloadChatMediaById: (mediaId) => ({
    url: `/v1/chat-room/download-media/${mediaId}`,
    method: 'GET',
  }),
};
