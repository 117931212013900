import { lessonList } from '../../apiEndpoints/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';

export const getLessonService = async ({ queryParams }) => {
  try {
    let payload = {
      ...lessonList.getLessonList,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (error) {
    logger(error);
  }
};
export const getReferenceService = async ({ queryParams }) => {
  try {
    let payload = {
      ...lessonList.getReferenceList,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (error) {
    logger(error);
  }
};

export const getInfoListService = async (id) => {
  try {
    let payload = {
      ...lessonList.getLessonInfo(id),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (error) {
    logger(error);
  }
};

export const lessonTestService = async ({ bodyData }) => {
  try {
    let payload = {
      ...lessonList.putQuestionAnswer,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (error) {
    logger(error);
  }
};

export const completeTestService = async ({ bodyData }) => {
  try {
    let payload = {
      ...lessonList.completeList,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (error) {
    logger(error);
  }
};
