import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { PageContainerWrapper, Tabs } from '../../../../components';
import useSubmit from '../../../../hooks/useSubmit.hook';
import { accessRoute } from '../../../../routes/TeamSetting';
import {
  adminEditBreadcrumbs,
  adminTabContent,
} from '../../../../services/Columns';
import { TeamSetting } from '../../../../services/TeamSetting';
import { backToPage } from '../../../../utils';

function EditAdminProfile() {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [defaultKey, setDefaultKey] = useState('profile');
  const redirectUrl = accessRoute.ADMIN.path;
  const breadcrumb = adminEditBreadcrumbs();

  const [onSubmit, values, isLoading] = useSubmit({
    service: TeamSetting.editAdminService,
    detailsService: TeamSetting.getAdminByIdService,
    id,
    afterSubmitActionType: 'redirect',
    redirectUrl,
  });

  let tabContent = adminTabContent({
    formData: values,
    onSubmit,
    isLoading,
    id,
    redirectUrl,
  });
  return (
    <div className="wide-md mx-auto">
      <PageContainerWrapper
        // heading={`Edit Provider ${profileData?.firstName}`}
        breadcrumb={breadcrumb}
        btnArray={['extraButton']}
        extraBtnText={t('text.common.back')}
        extraBtnClass="btn btn-outline-light bg-white"
        onExtraButtonHandleShow={() => backToPage(navigate, redirectUrl)}
        heading={`${t('text.role.editSubAdmin')} ${
          values?.firstName ? values?.firstName : ''
        }`}
      >
        <Tabs
          tabContent={tabContent}
          tabsFor="table"
          activeKey={defaultKey}
          setActiveKey={setDefaultKey}
        />
      </PageContainerWrapper>
    </div>
  );
}

export default EditAdminProfile;
