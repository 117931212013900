import { createSlice } from '@reduxjs/toolkit';

export const app = createSlice({
  name: 'app',
  initialState: {
    searchKeyword: '',
  },
  reducers: {
    setKeywordAction: (state, action) => {
      return {
        ...state,
        searchKeyword: action.payload,
      };
    },
    clearSearchAction: () => {
      return {
        searchKeyword: '',
      };
    },
  },
});

export const { setKeywordAction, clearSearchAction } = app.actions;

export const selectSearchKeyword = (state) => state.app.searchKeyword;

export default app.reducer;
