import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  // generatePath,
  Link,
  useLocation,
  useNavigate,
  // useParams,
  useSearchParams,
} from 'react-router-dom';
import {
  PageContainerWrapper,
  TablePlus,
  ModalWrapper,
  ScheduleFilterForm,
} from '../../../components';
// import CommonDateFilter from '../../../components/Filter/Common/commonDateFilter';
import Graph from '../../../features/graph/graph.feature';
import useTable from '../../../hooks/useTable.hook';
// import { accessRoute } from '../../../routes/ServiceManagement';
import {
  Columns,
  deleteBookingService,
  geServiceManagementExcelService,
  getActions,
  getGraphScheduleBooking,
  getSendQuoteService,
  getServiceManagementService,
  getTagService,
} from '../../../services/index.service';
import { decodeQueryData, logger, modalNotification } from '../../../utils';
import { useContext } from 'react';
import { PermissionContext } from '../../../context/permission.context';
import { useSelector } from 'react-redux';
import GraphService from '../../../features/graph/graph';
import useGraphData from '../../../hooks/graph.hook';
import { checkValidPrice } from '../../../components/UIElements/Formatter';
import moment from 'moment';
import { selectDateData } from '../../../services/Columns';

function ListServiceManagement() {
  // const Data = Columns.serviceManagementData();
  const location = useLocation();
  const params = location?.search && decodeQueryData(location?.search);

  const { id, customerId, customerName } = params;

  const [tags, setTags] = useState({});
  const breadcrumb = Columns.serviceManagementBreadcrumb();
  useEffect(() => {
    getTags();
  }, []);

  async function getTags() {
    try {
      const res = await getTagService({ queryParams: { scope: 'activeTag' } });
      if (res?.success) {
        const job = res?.data?.rows?.filter((e) => e?.tagType === 'job');
        const customer = res?.data?.rows?.filter(
          (e) => e?.tagType === 'customer'
        );
        setTags({
          'Available Customer Tags': customer,
          'Available Job Tags': job,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  const { t } = useTranslation();
  const userType = useSelector((state) => state?.auth?.userData?.Role?.type);
  const permissionContext = useContext(PermissionContext);
  const [state, setState] = useState({
    loader: null,
    modal: null,
    modalId: null,
    selectedRows: [],
    submitText: null,
    reasonTitle: '',
    sendQuoteBookingId: null,
  });
  const [isSearch, setIsSearch] = useState(0);

  const [filterValue, setFilter] = useState({
    startDate: (!id && !customerId && moment().format('YYYY-MM-DD')) || '',
    endDate: (!id && !customerId && moment().format('YYYY-MM-DD')) || '',
    dateType: (!id && !customerId && selectDateData[0]?.value) || undefined,
    location: undefined,
  });

  const [values, setValues] = useState({
    startDate: (!id && !customerId && moment().format('YYYY-MM-DD')) || '',
    endDate: (!id && !customerId && moment().format('YYYY-MM-DD')) || '',
    dateType: (!id && !customerId && selectDateData[0]?.value) || undefined,
  });
  const [ShowHideGraph, setShowHideGraph] = useState(false);

  const graphType = 'serviceManagement';
  const [graphData, graphLoader] = useGraphData({
    apiHandler: getGraphScheduleBooking,
    type: graphType,
    filterValue,
    isDependency: isSearch,
    isGraph: ShowHideGraph,
  });

  const extraQuery = id
    ? {
        ...(id ? { bookingNo: [id], ...filterValue } : { ...filterValue }),
      }
    : customerId
    ? {
        ...(customerId
          ? { customerUserId: [customerId], ...filterValue }
          : { ...filterValue }),
      }
    : { ...filterValue };

  if (!id) {
    delete extraQuery.bookingNo;
    delete extraQuery.dateType;
  }

  const extraDataKey = 'totalCustomerAmount';
  const [tableHandler, tableProps] = useTable(
    getServiceManagementService,
    extraQuery,
    extraDataKey,
    false,
    false,
    false,
    false,
    false,
    { sortBy: 'jobDate' }
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [globalFilter, setGlobalFilter] = useState(false);
  const {
    data,
    isLoading,
    count,
    page,
    noOfPages,
    sizePerPage,
    filter,
    sortBy,
    sortType,
    extraData,
  } = tableProps;

  const actions = [{ label: t('text.common.deleteSelectJob'), id: 'delete' }];
  const action = permissionContext;
  // const navigate = useNavigate();
  const {
    onFilterChange,
    onExport,
    onTableSort,
    onFilterResetChange,
    getData,
    onConfirmDeleteAlert,
  } = tableHandler;
  const { modal, submitText, selectedRows } = state;

  // const InvoicePage = (row, e) => {
  //   e.preventDefault();
  //   navigate(
  //     `${generatePath(accessRoute.SERVICE_MANAGEMENT_INVOICE.path, {
  //       id: row?.id,
  //     })}`
  //   );
  //   // navigate(`${accessRoute.SERVICE_MANAGEMENT_INVOICE.path}/${row?.id}`);
  // };
  const cancelPop = (row, e, reason) => {
    setState({
      ...state,
      submitText: `Job ${
        row?.bookingStatus === 'canceled' ? 'Cancel' : 'Decline'
      } Reason`,
      reasonTitle: row?.bookingStatus,
      modal: 'cancel-invoice',
      reason: row?.cancelReason || reason,
    });
  };

  const isUserCanEdit =
    (action && action?.arr?.includes(getActions('CREATE').value)) ||
    userType === 'admin';

  const columns = Columns.serviceManagementColumns({
    onSort: onTableSort,
    onFilterChange: onFilterChange,
    customerTags: tags['Available Customer Tags'],
    jobTags: tags['Available Job Tags'],
    // InvoicePage,
    cancelPop,
    page,
    sizePerPage,
    filter: filter,
    isUserCanEdit,
    handleSendQuote,
    sendQuoteBookingId: state.sendQuoteBookingId,
    filterObj: { id: customerId, name: customerName },
    userType,
  });

  const OpenBtnFilter = () => {
    setGlobalFilter(true);
  };

  if (globalFilter) {
    document.querySelector('body').classList.add('toggle-shown');
  } else {
    document.querySelector('body').classList.remove('toggle-shown');
  }

  const handleExportCsvData = async () => {
    try {
      const { startDate, endDate, location, providerUserId, customerUserId } =
        extraQuery;
      const queryParams = {
        sortBy: 'bookingScheduleDate',
        sortType: 'DESC',
        startDate,
        endDate,
        location,
        providerUserId,
        customerUserId,
        ...filter,
      };
      const response = await geServiceManagementExcelService({ queryParams });
      const { success, message } = response;
      if (success) {
        modalNotification({
          type: 'success',
          message,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const headerBtn = {
    add: {
      name: (
        <>
          <em className="icon ni ni-filter-fill me-1" />{' '}
          {t('text.common.filter')}
        </>
      ),
      handler: () => {
        OpenBtnFilter();
      },
    },
    multipleButton: [
      {
        title: t('text.report.showHide'),
        handler: () => {
          // setState({ ...state, submitText: 'show graph', modal: 'show-graph' });
          setShowHideGraph(!ShowHideGraph);
        },
      },
    ],
    csvExport: {
      handler: () => {
        handleExportCsvData();
      },
    },
    // csvExport: { handler: () => onExport('', columns) },
  };

  const BackBtnFilter = () => {
    setGlobalFilter(false);
  };

  const chartOptionsData = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
      tooltip: {
        mode: 'index',
        position: 'average',
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const jobData = {
    labels: graphData?.labels,
    datasets: [
      {
        label: t('text.serviceManagement.collected'),
        data: graphData?.MonthlyCollectedAmount,
        backgroundColor: ['#1EE0AC'],
        borderWidth: 0,
        hoverOffset: 4,
      },
      {
        label: t('text.serviceManagement.uncollected'),
        data: graphData?.remainingMonthlyCollectedAmount,
        backgroundColor: ['#E85347'],
        borderWidth: 0,
        hoverOffset: 4,
      },
    ],
  };
  const modalForm = {
    add: <Graph jobData={jobData} />,
    details: (
      <ul className="list list-sm list-checked">
        <li className="text-break">
          <b>
            {state?.reasonTitle === 'canceled'
              ? i18next.t('text.scheduleBooking.cancelReason')
              : i18next.t('text.scheduleBooking.declineReason')}
            :-
          </b>{' '}
          {state?.reason}
        </li>
      </ul>
    ),
  };
  function onCancel() {
    setState({ ...state, modal: null, modalId: null });
  }

  const onSubmitFilter = (value) => {
    try {
      let val = {
        startDate: value?.startDate || '',
        endDate: value?.endDate || '',
        location: value?.location || undefined,
      };
      setFilter((prevState) => ({
        ...prevState,
        ...val,
      }));
      setIsSearch(isSearch + 1);
      onFilterChange(val);
      setGlobalFilter(false);
    } catch (error) {
      logger(error);
    }
  };
  function onReset() {
    if (
      !values?.startDate &&
      !values?.endDate &&
      !values?.location &&
      !values?.toDate &&
      !values?.fromDate &&
      !values?.dateType
    ) {
      return;
    }
    const resetData = {
      startDate: '',
      endDate: '',
      location: undefined,
      dateType: '',
    };
    setValues({
      ...resetData,
    });
    setFilter({
      ...resetData,
    });
    setIsSearch(isSearch + 1);
    onFilterChange(resetData);
    setGlobalFilter(false);
  }
  function onTableResetFilter() {
    if (customerId || id) {
      searchParams.delete('customerId');
      searchParams.delete('id');
      setSearchParams(searchParams);
    }
    const resetData = {
      startDate: moment().format('YYYY-MM-DD') || '',
      endDate: moment().format('YYYY-MM-DD') || '',
      location: undefined,
    };
    setValues({
      startDate: values?.fromDate || moment().format('YYYY-MM-DD'),
      endDate: values?.toDate || moment().format('YYYY-MM-DD'),
      dateType: selectDateData[0]?.value,
      // dateType: 'today',
    });
    setIsSearch(isSearch + 1);
    setFilter({
      ...resetData,
    });
    onFilterChange(resetData);
    onFilterResetChange();
  }

  // For bulk action
  function onRowSelect(e) {
    try {
      setState({ ...state, selectedRows: [...e] });
    } catch (err) {
      console.log('err', err);
    }
  }

  async function handleSendQuote(bookingId) {
    setState({
      ...state,
      sendQuoteBookingId: bookingId,
    });
    const response = await getSendQuoteService(bookingId);
    if (response?.success) {
      modalNotification({
        type: 'success',
        message: response?.message,
      });
    }
    setState({
      ...state,
      sendQuoteBookingId: null,
    });
  }

  // For bulk action
  async function handleActionSelect(e) {
    try {
      if (e.target.value === 'delete') {
        await onConfirmDeleteAlert({
          title: t('text.common.deleteRecordTitle'),
          id: selectedRows,
          text: t('text.operating.areYouSureDeleteLesson'),
          service: deleteBookingService,
          key: 'id',
          html: `${t(
            'text.operating.areYouSureDeleteLesson'
          )}<div class="mt-2 text-danger"><small> ${t(
            'text.operating.cautionMessage'
          )}</small> </div>`,
        });
        getData();
      }
    } catch (err) {
      console.log(err);
    }
  }

  // useEffect(() => {
  //   setFilter({
  //     ...filterValue,
  //     startDate:
  //       !id &&
  //       !customerId &&
  //       (values.fromDate || moment().format('YYYY-MM-DD')),
  //     endDate:
  //       !id && !customerId && (values.toDate || moment().format('YYYY-MM-DD')),
  //     dateType: !id && !customerId && values.dateType,
  //   });
  // }, [values]);

  useEffect(() => {
    setFilter({
      ...filterValue,
      startDate:
        values?.fromDate || (values?.dateType && moment().format('YYYY-MM-DD')),
      endDate:
        values?.toDate || (values?.dateType && moment().format('YYYY-MM-DD')),
      dateType: values?.dateType,
    });
  }, [values]);

  useEffect(() => {
    if (customerId || id) {
      setFilter({
        ...filterValue,
        startDate: '',
        endDate: '',
        dateType: undefined,
      });
    }
  }, [customerId, id]);
  return (
    <>
      <PageContainerWrapper
        form={modalForm['filter']}
        heading={t('text.serviceManagement.title')}
        breadcrumb={breadcrumb}
        btn={headerBtn}
        multipleButtonClass={false}
        extraClass="btn btn-primary d-block d-xl-none"
        extraMobileClass="p-xl-0"
      >
        <div className="">
          <div className="globalFilters">
            <div className="card ">
              <div className="card-inner">
                <div
                  className={`globalFilters_inner ${
                    globalFilter ? 'filterToggle' : ''
                  }`}
                >
                  <div className="mb-3 d-block d-xl-none">
                    <Link to="#" onClick={() => BackBtnFilter()}>
                      <em className="icon ni ni-arrow-long-left me-1"></em>
                      {t('text.common.back')}
                    </Link>
                  </div>
                  {/* <CommonDateFilter
                    onSubmit={onSubmitFilter}
                    isLocation={true}
                    onReset={onReset}
                    loading={isLoading}
                    filterValue={filterValue}
                    setFilter={setFilter}
                  /> */}
                  <ScheduleFilterForm
                    data={true}
                    onSubmit={onSubmitFilter}
                    onReset={onReset}
                    filterValue={filterValue}
                    setValues={setValues}
                    values={values}
                  />
                </div>
                <div
                  className={` ${globalFilter ? 'toggle-overlay' : ''} `}
                  onClick={() => BackBtnFilter()}
                ></div>
              </div>
            </div>
          </div>
        </div>

        {ShowHideGraph && (
          <GraphService
            type="bar"
            data={jobData}
            options={chartOptionsData}
            totalData={graphData}
            loading={graphLoader}
          />
        )}
        <TablePlus
          hasLimit
          noOfPage={noOfPages}
          sizePerPage={sizePerPage}
          page={page}
          count={count}
          tableData={data}
          tableColumns={columns}
          selectRow={true}
          handleSelectedRows={onRowSelect}
          handleActionSelect={handleActionSelect}
          // param={param}
          // defaultSort={defaultSort}
          useEffect={true}
          tableLoader={isLoading}
          actions={actions}
          // tableReset={tableReset}
          // getSearchValue={getSearchValue}
          // searchPlaceholder={t("text.search.manageCustomers")}
          onFilterResetChange={onTableResetFilter}
          filter={filter}
          sortBy={sortBy}
          sortType={sortType}
        />

        {filter?.customerUserId?.length === 1 && (
          <div className="nk-block">
            <div className="card">
              <div className="card-inner">
                <h5 className="title mb-4">
                  {t('text.serviceManagement.totalSpent')}
                </h5>
                <div className="table-responsive">
                  <Table bordered size="md">
                    <tbody>
                      <tr>
                        <th>
                          {t('text.serviceManagement.customerTotalSpent')}
                        </th>
                        <th>{checkValidPrice(extraData)}</th>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        )}
        <ModalWrapper
          heading={t('text.common.graph')}
          onCancel={onCancel}
          content={modalForm['add']}
          show={['show-graph'].includes(modal)}
        />
        <ModalWrapper
          heading={submitText}
          content={modalForm['details']}
          show={['cancel-invoice'].includes(modal)}
          onCancel={onCancel}
        />
      </PageContainerWrapper>
    </>
  );
}

export default ListServiceManagement;
