import { APIRequest } from '../../helpers/index.helper';
import { CUSTOMER_APP_SETTING } from '../../apiEndpoints/CustomerAppSetting/index.endpoint';
import { logger } from '../../utils';

export const bannerService = {
  getBannerService: async ({ queryParams }) => {
    try {
      const payload = {
        ...CUSTOMER_APP_SETTING.getBanner,
        queryParams,
      };
      const res = await APIRequest(payload);
      return res;
    } catch (err) {
      logger(err);
    }
  },
  addBannerService: async ({ body }) => {
    try {
      const payload = {
        ...CUSTOMER_APP_SETTING.addBanner,
        bodyData: body,
      };
      const res = await APIRequest(payload);
      return res;
    } catch (err) {
      logger(err);
    }
  },
  deleteBannerService: async ({ bodyData }) => {
    try {
      const payload = {
        ...CUSTOMER_APP_SETTING.deleteBanner(bodyData?.id),
      };

      const res = await APIRequest(payload);
      return res;
    } catch (err) {
      console.log(err);
      logger(err);
    }
  },
  getBannerByIdService: async ({ resourceId }) => {
    try {
      const payload = {
        ...CUSTOMER_APP_SETTING.getBannerById(resourceId),
      };

      const res = await APIRequest(payload);
      return res;
    } catch (err) {
      console.log(err);
      logger(err);
    }
  },

  updateBannerStatus: async ({ resourceId, status }) => {
    try {
      const payload = {
        ...CUSTOMER_APP_SETTING.updateBannerStatusId(resourceId),
        bodyData: { status },
      };

      const res = await APIRequest(payload);
      return res;
    } catch (err) {
      console.log(err);
      logger(err);
    }
  },
  updateBanner: async ({ resourceId, body }) => {
    try {
      const payload = {
        ...CUSTOMER_APP_SETTING.updateBannerId(resourceId),
        bodyData: body,
      };

      const res = await APIRequest(payload);
      return res;
    } catch (err) {
      console.log(err);
      logger(err);
    }
  },
};
