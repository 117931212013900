import { CommonSchema } from '../schemas/index.schema';

export default function SchemaHandler(key) {
  return CommonSchema[key];
}

/********Return only initial keys value from data object******* */
export function valueMapper(initialValues, data) {
  try {
    let formValue = {};
    Object.keys(initialValues).forEach((e) => {
      if (data?.hasOwnProperty(e)) {
        formValue[e] = data[e];
        return true;
      }
      formValue[e] = initialValues[e];
    });
    return formValue;
  } catch (err) {
    console.log(err);
    return initialValues;
  }
}
