let url = '/v1/admin/home-inventory';
export const HOME_INVENTORY_ENDPOINT = {
  getHomeInventoryApi: {
    url: `${url}`,
    method: 'GET',
  },
  getInstalledEquipmentImages: (id) => ({
    url: `${url}/${id}`,
    method: 'GET',
  }),
  getBrandApi: {
    url: `${url}/brand`,
    method: 'GET',
  },
  getQuoteTypeApi: {
    url: `${url}/quote-type`,
    method: 'GET',
  },
  getSerialNoApi: {
    url: `${url}/serial-no`,
    method: 'GET',
  },
  getModelApi: {
    url: `${url}/model`,
    method: 'GET',
  },
};
