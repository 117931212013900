import { getActions } from '../../services/Auth/index.service';
import ListNotification from '../../views/Notification/List';

export default function route() {
  return [
    {
      path: accessRoute.NOTIFICATION.path,
      private: true,
      name: 'Notifications',
      action: accessRoute.NOTIFICATION.action,
      key: accessRoute.NOTIFICATION.key,
      belongsToSidebar: true,
      submenu: false,
      icon: accessRoute.NOTIFICATION.icon,
      element: <ListNotification />,
      ...accessRoute.NOTIFICATION,
    },
  ];
}

export const accessRoute = {
  NOTIFICATION: {
    path: '/admin/notifications',
    action: getActions('LIST').value,
    key: 'notifications',
    moduleKey: 'notifications',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-bell-fill" />
      </span>
    ),
    order: 30,
  },
};

export const getChildrenRoutes = () => {
  return route();
};
