import { Checkbox } from 'antd';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getAllPermissionService,
  putPermissionService,
} from '../../../services/index.service';
import { logger, modalNotification } from '../../../utils';
import CustomCheck from '../../UIElements/CustomCheck';
import GlobalLoader from '../../UIElements/GlobalLoader';
import { CommonButton, TextInput } from '../../UIElements/index.element';
import { FormElementWrapper, FormWrapper } from '../../Wrappers';
import { initPermissionValues, permissionValidation } from './validation';

function SetPermissionForm({ onSubmit, pageData, getProfile }) {
  const { t } = useTranslation();
  const [formData, setFormData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllPermission = async () => {
    setLoading(true);
    try {
      const { data } = await getAllPermissionService();
      setFormData(data?.rows);
    } catch (error) {
      logger(error);
    }
    setLoading(false);
    getProfile();
  };

  useEffect(() => {
    getAllPermission();
  }, []);

  const onCheckChange = async (e, item) => {
    try {
      let bodyData = { [item.NcaPage.code]: e === true ? 1 : 0 };
      const { success, message } = await putPermissionService({ bodyData });
      if (success) {
        modalNotification({
          type: 'success',
          message,
        });
      }
    } catch (error) {
      logger(error);
    }
    getAllPermission();
  };

  return (
    <>
      <div className="card">
        <div className="card-inner">
          <FormWrapper
            onSubmit={onSubmit}
            initialValues={initPermissionValues({ ...pageData })}
            data={pageData}
            // validation={permissionValidation}
            extraClassName="g-3"
            enableReinitialize={true}
            row={true}
          >
            {(props) => {
              return (
                <>
                  <FormElementWrapper
                    md={6}
                    lg={4}
                    label={t('text.nca.termsOfUse')}
                    mandatory={false}
                    element={() => (
                      <>
                        <TextInput
                          name="term_of_use"
                          placeholder="Enter term of use"
                          formik={props}
                        />
                        <CustomCheck
                          name="term_of_use_show"
                          defaultChecked={props.values.term_of_use_show}
                          checked={props.values.term_of_use_show}
                          label={t('text.nca.termsOfUseUrl')}
                        />
                      </>
                    )}
                  />
                  <FormElementWrapper
                    md={6}
                    lg={4}
                    label={t('text.nca.privacyPolicy')}
                    mandatory={false}
                    element={() => (
                      <>
                        <TextInput
                          name="privacy_policy"
                          placeholder="Enter privacy policy"
                          formik={props}
                        />
                        <CustomCheck
                          name="privacy_policy_show"
                          defaultChecked={props.values.privacy_policy_show}
                          checked={props.values.privacy_policy_show}
                          label={t('text.nca.privacyUrl')}
                        />
                      </>
                    )}
                  />
                  <FormElementWrapper
                    md={6}
                    lg={4}
                    label={t('text.nca.aboutUs')}
                    mandatory={false}
                    element={() => (
                      <>
                        <TextInput
                          name="about_us"
                          placeholder="Enter about us"
                          formik={props}
                        />
                        <CustomCheck
                          name="about_us_show"
                          defaultChecked={props.values.about_us_show}
                          checked={props.values.about_us_show}
                          label={t('text.nca.aboutUsUrl')}
                        />
                      </>
                    )}
                  />
                  <FormElementWrapper
                    md={12}
                    className="text-end"
                    element={() => (
                      <>
                        <CommonButton
                          loading={props.isSubmitting}
                          disabled={props.isSubmitting}
                          type="submit"
                          className="btn btn-primary"
                        >
                          Save Changes
                        </CommonButton>
                      </>
                    )}
                  />
                </>
              );
            }}
          </FormWrapper>
          <div className="divider divider-sm"></div>
          <h6 className="mb-3">{t('text.nca.pagePermission')}</h6>
          {loading ? (
            <GlobalLoader />
          ) : (
            <FormWrapper row={true} extraClassName="g-3">
              {formData?.length > 0 &&
                formData?.map((item, key) => {
                  return (
                    <FormElementWrapper
                      key={key}
                      md={6}
                      lg={3}
                      element={() => (
                        <Checkbox
                          name={item?.NcaPage?.name}
                          defaultChecked={item?.isAllowed}
                          onChange={(e) =>
                            onCheckChange(e.target.checked, item)
                          }
                        >
                          {item?.NcaPage?.name}
                        </Checkbox>
                      )}
                    />
                  );
                })}
            </FormWrapper>
          )}
        </div>
      </div>
    </>
  );
}

export default SetPermissionForm;
