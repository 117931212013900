import loadable from '@loadable/component';
import { Skeleton } from 'antd';
import ShimmerComponent from './Shimmer/index.element';
import FullPageLoader from './Loader/fullPageLoader';

export * from './Button/index';
export const ImageElement = loadable(() => import('./ImageElement/index'), {
  fallback: <FullPageLoader />,
});
export const Breadcrumb = loadable(() => import('./Breadcrumb/index'), {
  fallback: <FullPageLoader />,
});
export const SweetAlert = loadable(() => import('./SweetAlert/index'), {
  fallback: <FullPageLoader />,
});
export const GlobalLoader = loadable(() => import('./GlobalLoader/index'), {
  fallback: <FullPageLoader />,
});
export const Tabs = loadable(() => import('./Tabs/index'), {
  fallback: <FullPageLoader />,
});
export const TextInput = loadable(() => import('./TextInput/index.element'), {
  fallback: <FullPageLoader />,
});
export const DatePicker = loadable(() => import('./DatePicker/index'), {
  fallback: <FullPageLoader />,
});
export const AntSelect = loadable(() => import('./Select/index'), {
  fallback: <ShimmerComponent />,
});
export const Charts = loadable(() => import('./Chart/index'), {
  fallback: <FullPageLoader />,
});

export const Password = loadable(() => import('./Password/index'), {
  fallback: <FullPageLoader />,
});
export const AcountSidebar = loadable(() => import('./AcountSidebar/index'), {
  fallback: <FullPageLoader />,
});
export const ModalComponent = loadable(() => import('./Modal/index'), {
  fallback: <FullPageLoader />,
});
export const Pagination = loadable(() => import('./Pagination/index'), {
  fallback: <FullPageLoader />,
});
export const AntTooltip = loadable(() => import('./Tooltip/index'), {
  fallback: <FullPageLoader />,
});
export const InputType = loadable(() => import('./Input/index'), {
  fallback: <ShimmerComponent />,
});

export const AntTextArea = loadable(() => import('./TextArea/index'), {
  fallback: <FullPageLoader />,
});
export const Toggle = loadable(() => import('./Toggle/index'), {
  fallback: <FullPageLoader />,
});
export const TextEditor = loadable(() => import('./TextEditor/index'), {
  fallback: <FullPageLoader />,
});
export const CheckBox = loadable(() => import('./CheckBox/index.element'), {
  fallback: <FullPageLoader />,
});
export const Upload = loadable(() => import('./Upload/index'), {
  fallback: <FullPageLoader />,
});
export const Select = loadable(() => import('./Select/index'), {
  fallback: <ShimmerComponent />,
});
export const RadioComponent = loadable(() => import('./Radio/index.element'), {
  fallback: <FullPageLoader />,
});

export const NumberPrefix = loadable(
  () => import('./NumberPrefix/index.element'),
  {
    fallback: <FullPageLoader />,
  }
);
export const NoDataFound = loadable(() => import('./NoDataFound/index'), {
  fallback: (
    <div className="skeletonTable">
      <Skeleton.Input active size="large" />
    </div>
  ),
});
export const TablePlus = loadable(() => import('./TablePlus/index'));

export { default as Shimmer } from './Shimmer/index.element';
export const UploadInput = loadable(() => import('./UploadInput/index'), {
  fallback: <FullPageLoader />,
});
export const Formatter = loadable(() => import('./Formatter/index'), {
  fallback: <FullPageLoader />,
});
export const TimePicker = loadable(() => import('./TimePicker/index'), {
  fallback: <FullPageLoader />,
});
export const Calender = loadable(() => import('./Calender/index'), {
  fallback: <FullPageLoader />,
});
export const Switch = loadable(() => import('./Switch/index'), {
  fallback: <FullPageLoader />,
});

export const AutoComplete = loadable(() => import('./AutoComplete/index'), {
  fallback: <FullPageLoader />,
});
export const BulkUploadInput = loadable(() => import('./BulkUpload/index'), {
  fallback: <FullPageLoader />,
});
export const MultiUpload = loadable(() => import('./MultiUpload/index.ant'), {
  fallback: <FullPageLoader />,
});
export const SingleCheckBox = loadable(
  () => import('./SingleCheckbox/index.element'),
  {
    fallback: <FullPageLoader />,
  }
);
export const ArrayListWithCheck = loadable(
  () => import('./ArrayListWithCheck/index'),
  {
    fallback: <FullPageLoader />,
  }
);
export const StoreBreadcrumb = loadable(
  () => import('./Breadcrumb/storeBreadcrumb'),
  {
    fallback: <FullPageLoader />,
  }
);
export const AccordionComponent = loadable(() => import('./Accordion/index'), {
  fallback: <FullPageLoader />,
});
export const CustomSwitch = loadable(() => import('./StatusToggle/index'), {
  fallback: <FullPageLoader />,
});
export const CardComponent = loadable(() => import('./CardComponent/index'), {
  fallback: <FullPageLoader />,
});
export const CustomTable = loadable(() => import('./CustomTable/customTable'), {
  fallback: <FullPageLoader />,
});
