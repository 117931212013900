import { VARIANT_MANAGEMENT_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';

export const getVariantService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...VARIANT_MANAGEMENT_ENDPOINT?.getVariantList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const deleteVariantService = async ({ bodyData }) => {
  const { id } = bodyData;
  try {
    const payload = {
      ...VARIANT_MANAGEMENT_ENDPOINT?.deleteVariant(id),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const variantStatus = async ({ bodyData, queryParams }) => {
  try {
    const payload = {
      ...VARIANT_MANAGEMENT_ENDPOINT?.changeVariantStatus(queryParams),
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const addVariantService = async ({ bodyData }) => {
  try {
    const payload = {
      ...VARIANT_MANAGEMENT_ENDPOINT?.addVariant,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const editVariantService = async ({ bodyData, id }) => {
  try {
    const payload = {
      ...VARIANT_MANAGEMENT_ENDPOINT?.editVariant(id),
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getVariantDetailsService = async ({ id }) => {
  try {
    const payload = {
      ...VARIANT_MANAGEMENT_ENDPOINT?.getVariantDetails(id),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

