import { Outlet } from 'react-router-dom';
import { getActions } from '../../services/index.service';
import ViewChat from '../../views/Chat/View';

export default function route() {
  return [
    {
      path: accessRoute.CHAT.path,
      private: true,
      key: 'chat',
      name: 'Chat',
      belongsToSidebar: true,
      submenu: false,
      moduleKey: 'chat',
      action: getActions('EDIT').value,
      icon: accessRoute.CHAT.icon,
      moduleKeys: [accessRoute.CHAT.moduleKey],
      element: <Outlet />,
      children: [
        {
          private: true,
          path: accessRoute.CHAT.path,
          name: 'Chat',
          key: accessRoute.CHAT.key,
          belongsToSidebar: true,
          icon: accessRoute.CHAT.icon,
          element: <ViewChat />,
          ...accessRoute.CHAT,
        },
      ],
    },
  ];
}

export const accessRoute = {
  CHAT: {
    path: '/admin/chat',
    key: 'chat',
    moduleKey: 'chat',
    action: getActions('EDIT').value,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-chat-circle-fill" />
      </span>
    ),
    order: 61,
  },
};

export const getChildrenRoutes = () => {
  return route()[0]?.children;
};
