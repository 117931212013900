import React, { useState } from 'react';
import Tabs from '../../../../../components/UIElements/Tabs';
import AdminWaiveCost from './adminWaiveCost';
import Case from './Cash';
import CheckComponent from './Check';
import CreditCard from './CreditCard';
import FinancingComponent from './Financing';
import WarrantyNoCharge from './WarrantyNoCharge';
import TextArea from 'antd/lib/input/TextArea';
import i18next from 'i18next';
import { useEffect } from 'react';

export default function PaymentMethods({
  rowData,
  getData,
  setIsOpenModal,
  authorizedUser,
  paymentDetails,
}) {
  const [defaultKey, setDefaultKey] = useState('check');

  const tabContent = [
    {
      name: i18next.t('text.report.check'),
      key: 'check',
      content: (
        <CheckComponent
          defaultKey={defaultKey}
          rowData={rowData}
          getData={getData}
          setIsOpenModal={setIsOpenModal}
          authorizedUser={authorizedUser}
          paymentDetails={paymentDetails}
        />
      ),
      disabled:
        paymentDetails?.remainingAmount === 0 &&
        paymentDetails?.paymentMode === 'warranty',
    },
    {
      name: i18next.t('text.report.financing'),
      key: 'financing',
      content: (
        <FinancingComponent
          defaultKey={defaultKey}
          rowData={rowData}
          getData={getData}
          setIsOpenModal={setIsOpenModal}
          authorizedUser={authorizedUser}
          paymentDetails={paymentDetails}
        />
      ),
      disabled:
        paymentDetails?.remainingAmount === 0 &&
        paymentDetails?.paymentMode === 'warranty',
    },
    {
      name: i18next.t('text.report.creditCard'),
      key: 'creditCard',
      content: (
        <CreditCard
          rowData={rowData}
          getData={getData}
          setIsOpenModal={setIsOpenModal}
          authorizedUser={authorizedUser}
          paymentDetails={paymentDetails}
        />
      ),
      disabled:
        paymentDetails?.remainingAmount === 0 &&
        paymentDetails?.paymentMode === 'warranty',
    },
    {
      name: i18next.t('text.report.cash'),
      key: 'cash',
      content: (
        <Case
          rowData={rowData}
          getData={getData}
          setIsOpenModal={setIsOpenModal}
          authorizedUser={authorizedUser}
          paymentDetails={paymentDetails}
        />
      ),
      disabled:
        paymentDetails?.remainingAmount === 0 &&
        paymentDetails?.paymentMode === 'warranty',
    },
    {
      name: i18next.t('text.report.warrantyCharge'),
      key: 'warrantyNoCharge',
      content: (
        <WarrantyNoCharge
          rowData={rowData}
          getData={getData}
          setIsOpenModal={setIsOpenModal}
          paymentDetails={paymentDetails}
        />
      ),
    },
    {
      name: i18next.t('text.report.adminWaiveCost'),
      key: 'adminWaiveCost',
      content: (
        <AdminWaiveCost
          rowData={rowData}
          getData={getData}
          setIsOpenModal={setIsOpenModal}
          authorizedUser={authorizedUser}
          paymentDetails={paymentDetails}
        />
      ),
    },
  ];

  useEffect(() => {
    if (
      paymentDetails?.remainingAmount === 0 ||
      rowData?.remainingAmount === 0
    ) {
      setDefaultKey('warrantyNoCharge');
    }
  }, [paymentDetails?.remainingAmount, rowData?.remainingAmount]);

  return (
    <>
      {authorizedUser === 'adminAttention' && (
        <div className="pb-2">
          <TextArea disabled value={paymentDetails?.reason} />
        </div>
      )}
      <div>
        <Tabs
          tabContent={tabContent}
          tabsFor="table"
          activeKey={defaultKey}
          setActiveKey={setDefaultKey}
          tabsOnModal={true}
          setParams={false}
        />
      </div>
    </>
  );
}
