import {
  Home,
  ServiceDetails,
  ProviderProfile,
  EditProfile,
  ChangePassword,
  Notification,
} from '../../views/CustomerApp';
import { AddressLayout } from '../../layouts/customer';
import ServiceType from '../../views/CustomerApp/ServiceType';

export default function route() {
  return [
    {
      element: <AddressLayout />,
      children: [
        {
          path: accessRoute.HOME.path,
          private: true,
          name: accessRoute.HOME.name,
          key: accessRoute.HOME.key,
          belongsToSidebar: accessRoute.HOME.isSidebar,
          icon: accessRoute.HOME.icon,
          element: <Home />,
        },
        {
          path: accessRoute.SERVICE_DETAILS.path,
          private: true,
          name: accessRoute.SERVICE_DETAILS.name,
          key: accessRoute.SERVICE_DETAILS.key,
          belongsToSidebar: accessRoute.SERVICE_DETAILS.isSidebar,
          icon: accessRoute.SERVICE_DETAILS.icon,
          element: <ServiceDetails />,
        },
        {
          path: accessRoute.SERVICE_TYPE.path,
          private: true,
          name: accessRoute.SERVICE_TYPE.name,
          key: accessRoute.SERVICE_TYPE.key,
          belongsToSidebar: accessRoute.SERVICE_TYPE.isSidebar,
          icon: accessRoute.SERVICE_TYPE.icon,
          element: <ServiceType />,
        },
      ],
    },
    {
      path: accessRoute.PROFILE.path,
      private: true,
      name: accessRoute.PROFILE.name,
      key: accessRoute.PROFILE.key,
      belongsToSidebar: accessRoute.PROFILE.isSidebar,
      icon: accessRoute.PROFILE.icon,
      element: <ProviderProfile />,
    },
    {
      path: accessRoute.EDIT_PROFILE.path,
      private: true,
      name: accessRoute.EDIT_PROFILE.name,
      key: accessRoute.EDIT_PROFILE.key,
      belongsToSidebar: accessRoute.EDIT_PROFILE.isSidebar,
      icon: accessRoute.EDIT_PROFILE.icon,
      element: <EditProfile />,
    },
    {
      path: accessRoute.CHANGE_PASSWORD.path,
      private: true,
      name: accessRoute.CHANGE_PASSWORD.name,
      key: accessRoute.CHANGE_PASSWORD.key,
      belongsToSidebar: accessRoute.CHANGE_PASSWORD.isSidebar,
      icon: accessRoute.CHANGE_PASSWORD.icon,
      element: <ChangePassword />,
    },
    {
      path: accessRoute.NOTIFICATION.path,
      private: true,
      name: accessRoute.NOTIFICATION.name,
      key: accessRoute.NOTIFICATION.key,
      belongsToSidebar: accessRoute.NOTIFICATION.isSidebar,
      icon: accessRoute.NOTIFICATION.icon,
      element: <Notification />,
    },
  ];
}

export const accessRoute = {
  HOME: {
    path: '/customer/home',
    name: 'Home',
    key: 'home',
    isSidebar: true,
    for: 'customer',
    icon: <em className="gg-home"></em>,
  },
  SERVICE_DETAILS: {
    path: '/customer/service-details/:id/:locationId',
    name: 'Service Details',
    key: 'home',
    isSidebar: false,
    for: 'customer',
    icon: <em className="gg-home"></em>,
  },
  SERVICE_TYPE: {
    path: '/customer/service-type',
    name: 'Service Type',
    key: 'home',
    isSidebar: false,
    for: 'customer',
    icon: <em className="gg-home"></em>,
  },

  PROFILE: {
    path: '/customer/profile',
    name: 'Profile',
    key: 'profile',
    isSidebar: false,
    for: 'customer',
    icon: <em className="gg-home"></em>,
  },
  EDIT_PROFILE: {
    path: '/customer/edit-profile',
    name: 'Edit Profile',
    key: 'edit-profile',
    isSidebar: false,
    for: 'customer',
    icon: <em className="gg-home"></em>,
  },
  CHANGE_PASSWORD: {
    path: '/customer/change-password',
    name: 'Change Password',
    key: 'change-password',
    isSidebar: false,
    for: 'customer',
    icon: <em className="gg-home"></em>,
  },
  NOTIFICATION: {
    path: '/customer/notification',
    name: 'notification',
    key: 'notification',
    isSidebar: false,
    for: 'customer',
    icon: <em className="gg-home"></em>,
  },
};

export const getChildrenRoutes = () => {
  return route();
};
