import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { accessRoute } from '../../routes/Dashboard/dashboard';
import './index.css';
import { accessRoute as storeRoute } from '../../routes/StoreApp/store.home';
import { accessRoute as customerRoute } from '../../routes/CustomerApp/home';
import { accessRoute as customerAuthRoute } from '../../routes/Auth/customer';
import { accessRoute as storeAuthRoute } from '../../routes/Auth/store';
import { accessRoute as adminAuthRoute } from '../../routes/Auth/login';

import { getLocalStorageToken } from '../../utils';

function PageNotFound() {
  const { t } = useTranslation();
  const location = useLocation();
  const apiToken = getLocalStorageToken();

  let routObj = {
    store: apiToken
      ? storeRoute.MY_PROFILE.path
      : storeAuthRoute.STORE_LOGIN.path,
    customer: apiToken
      ? customerRoute.HOME.path
      : customerAuthRoute.CUSTOMER_LOGIN.path,
    admin: apiToken ? accessRoute.DASHBOARD.path : adminAuthRoute.LOGIN.path,
  };

  let getPath = location.pathname.split('/')?.[1].substring(0, 5);

  let path =
    getPath === 'store'
      ? 'store'
      : getPath.match('custo')?.[0] === 'custo'
      ? 'customer'
      : getPath === 'admin'
      ? 'admin'
      : 'admin';

  useEffect(() => {
    const body = document.getElementsByTagName('body');
    body[0].style = '';
  }, []);

  return (
    <>
      <div className="pageNotFound">
        <div className="pageNotFound_inner d-flex flex-column align-items-center justify-content-center h-100">
          <h1>404 </h1>
          <h2>{t('text.common.pageNotFound')}</h2>
          <p className="text-center mb-3 mb-lg-4">
            {t('text.common.WeAreSorry')}
            <br className="d-none d-lg-block" /> {t('text.common.goBack')}
          </p>
          <Link to={routObj[path]} className="btn btn-primary">
            {t('text.common.backToHome')}
          </Link>
        </div>
      </div>
    </>
  );
}

export default PageNotFound;
