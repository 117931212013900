import { Empty } from 'antd';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import {
  GoogleAdwordsFilter,
  GoogleBudget,
  ModalComponent,
  PageContainerWrapper,
  TablePlus,
  Toggle,
} from '../../../../components';
import { numberFormatter } from '../../../../services/Columns';
import {
  getGoogleAdwordsAccountListService,
  getGoogleAdwordsListService,
  updateGoogleAdwordsBudgetService,
} from '../../../../services/GoogleConnect/GoogleAdwords';
import {
  getGoogleTokenService,
  statusGoogleServiceList,
  updateSPControlStatus,
} from '../../../../services/GoogleConnect/googleGMB.service';
import { Columns, getActions } from '../../../../services/index.service';
import { decodeQueryData, modalNotification } from '../../../../utils';
import { useSelector } from 'react-redux';
import { PermissionContext } from '../../../../context/permission.context';
import { useContext } from 'react';

function AdwordListing() {
  const location = useLocation();
  const params = location?.search && decodeQueryData(location?.search);
  const { sortBy, sortType } = params;
  const breadcrumb = Columns.googleAdwordsBreadcrumb();
  const { t } = useTranslation();
  const action = useContext(PermissionContext);
  const [globalFilter, setGlobalFilter] = useState(false);
  const [account, setAccount] = useState();
  const [accountListLoading, setAccountListLoading] = useState(false);
  const [accountListData, setAccountListData] = useState([]);
  const [googleAdwordsListLoading, setGoogleAdwordsListLoading] =
    useState(false);
  const [googleAdwordsListData, setGoogleAdwordsListData] = useState([]);
  const [rowData, setRowData] = useState('');
  const [filterData, setFilterData] = useState();
  const [statusLoading, setStatusLoading] = useState(false);
  const [filterAccount, setFilterAccount] = useState();
  const [budgetLoading, setBudgetLoading] = useState(false);
  const [healthToggleLoading, setToggleHealthLoading] = useState(false);
  const userType = useSelector((state) => state?.auth?.userData?.Role?.type);

  const isUserCanEdit =
    (action && action?.arr?.includes(getActions('CREATE').value)) ||
    userType === 'admin';

  async function getAccountList() {
    setAccountListLoading(true);
    try {
      const response = await getGoogleAdwordsAccountListService({ type: null });
      const { data, success } = response;
      if (success) {
        setAccount(data[0]?.accountId);
        setAccountListData(data);
        getGoogleAdwordsData(data[0]?.accountId);
      }
    } catch (err) {
      console.log(err);
    }
    setAccountListLoading(false);
  }

  const checkGoogleRefreshToken = async () => {
    try {
      let queryParams = {};
      const response = await getGoogleTokenService({ queryParams });
      const { data } = response;
      if (data === true) {
        localStorage.removeItem('googleAuth');
        getAccountList();
      } else {
        localStorage.setItem('googleAuth', location?.pathname);
        window.open(data?.url, '_self');
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    checkGoogleRefreshToken();
  }, []);

  const BackBtnFilter = () => {
    setGlobalFilter(false);
  };
  const OpenBtnFilter = () => {
    setGlobalFilter(true);
  };
  if (globalFilter) {
    document.querySelector('body').classList.add('toggle-shown');
  } else {
    document.querySelector('body').classList.remove('toggle-shown');
  }
  function budgetViewModal(data) {
    setRowData(data);
    setAddModal(true);
  }
  const onToggleChange = async (value, data) => {
    setStatusLoading(true);
    try {
      let bodyData = {
        accountId: account,
        resourceName: data?.list?.campaign?.resourceName,
        status: value === 'active' ? 'ENABLED' : 'PAUSED',
      };
      const response = await statusGoogleServiceList({ bodyData });
      const { success, message } = response;
      if (success) {
        modalNotification({
          type: 'success',
          message,
        });
        getGoogleAdwordsData(account);
      }
    } catch (error) {
      console.log(error);
    }
    setStatusLoading(false);
  };

  async function onToggleMentalChange(value) {
    setToggleHealthLoading(true);
    try {
      const bodyData = {
        accountId: account,
        campaignId: value?.list?.campaign?.id,
        status: value?.spControlStatus === true ? false : true,
      };
      const response = await updateSPControlStatus({
        bodyData,
      });
      const { success, message } = response;
      if (success) {
        modalNotification({
          type: 'success',
          message,
        });
        getGoogleAdwordsData(account);
      }
    } catch (err) {
      return false;
    }
    setToggleHealthLoading(false);
  }

  const columns = Columns.googleAdwordsServiceTableColumn({
    budgetViewModal: (props) => budgetViewModal(props),
    Toggle: (props) => (
      <Toggle
        isDropDown={true}
        defaultChecked={
          props?.row?.list?.campaign?.status === 'PAUSED'
            ? 'inactive'
            : 'active'
        }
        loading={statusLoading}
        onChange={(e) => onToggleChange(e, props?.row)}
      />
    ),
    ToggleHealth: (props) => (
      <Toggle
        loading={healthToggleLoading}
        defaultChecked={props?.row?.spControlStatus}
        onChange={(e) => onToggleMentalChange(props?.row)}
      />
    ),
    isUserCanEdit,
  });
  const [addModal, setAddModal] = useState(false);
  const addModalHide = () => {
    setAddModal(false);
  };

  let exportData = googleAdwordsListData.map((item) => {
    return {
      Name: item?.list?.campaign?.name,
      Clicks: item?.list?.metrics?.clicks,
      'Average Cost': numberFormatter(item?.list?.metrics?.conversionsValue),
      Cost: numberFormatter(item?.list?.metrics?.costMicros),
      impressions: item?.list?.metrics?.impressions,
      Budget: numberFormatter(item?.list?.campaignBudget?.amountMicros),
      'Conversion Value': item?.list?.metrics?.conversionsValue,
    };
  });

  const btn = {
    add: {
      name: (
        <>
          <em className="icon ni ni-filter-fill me-1" />
          {t('text.common.filter')}
        </>
      ),
      handler: () => {
        OpenBtnFilter();
      },
    },
    csvExport: { handler: () => exportData },
  };
  const onFilterSubmit = (value) => {
    setFilterData(value);
    setFilterAccount(value?.accountId);
    setGlobalFilter(false);
  };

  async function getGoogleAdwordsData(value) {
    setGoogleAdwordsListLoading(true);
    try {
      let queryParams = {
        accountId: value,
        orderBy: sortBy ? (sortType === 'ascend' ? 'ASC' : 'DESC') : '',
        sortBy: sortBy,
        ...filterData,
      };
      setGoogleAdwordsListData([]);
      const response = await getGoogleAdwordsListService({ queryParams });
      const { data, success } = response;
      if (success) {
        setGoogleAdwordsListData(data?.results);
      }
    } catch (err) {
      console.log(err);
    }
    setGoogleAdwordsListLoading(false);
  }

  useEffect(() => {
    if (filterData) {
      getGoogleAdwordsData();
    }
    if (sortType || sortBy || sortType === undefined || sortBy === undefined) {
      getGoogleAdwordsData(account);
    }
  }, [filterData, sortType, sortBy]);

  const onSubmit = async (value) => {
    setBudgetLoading(true);
    try {
      let bodyData = {
        accountId: account,
        resourceName: rowData?.campaign?.campaignBudget,
        price: value?.budget,
      };
      const response = await updateGoogleAdwordsBudgetService({ bodyData });
      const { message, success } = response;
      if (success) {
        modalNotification({
          type: 'success',
          message,
        });
        addModalHide();
        getGoogleAdwordsData(account);
      }
    } catch (err) {
      console.log(err);
    }
    setBudgetLoading(false);
  };
  return (
    <>
      <PageContainerWrapper
        heading={t('text.googleConnect.googleAdwordsList')}
        breadcrumb={breadcrumb}
        btnArray={['create', 'csvExport']}
        btn={btn}
        extraClass="btn btn-primary d-block d-xl-none"
      />

      <div className="">
        <div className="globalFilters">
          <div className="card ">
            <div className="card-inner">
              <div
                className={`globalFilters_inner ${
                  globalFilter ? 'filterToggle' : ''
                }`}
              >
                <div className="mb-3 d-block d-xl-none">
                  <Link to="#" onClick={() => BackBtnFilter()}>
                    <em className="icon ni ni-arrow-long-left me-1"></em>{' '}
                    {t('text.common.back')}
                  </Link>
                </div>
                <>
                  <GoogleAdwordsFilter
                    accountListData={accountListData}
                    loading={accountListLoading}
                    account={account}
                    onFilterSubmit={onFilterSubmit}
                    setAccount={setAccount}
                    setFilterData={setFilterData}
                    filterData={filterData}
                    setFilterAccount={setFilterAccount}
                    setGlobalFilter={setGlobalFilter}
                    filterAccount={filterAccount}
                  />
                </>
              </div>
              <div
                className={` ${globalFilter ? 'toggle-overlay' : ''} `}
                onClick={() => BackBtnFilter()}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <TablePlus
        useTableHook={false}
        hasLimit
        tableData={googleAdwordsListData}
        tableColumns={columns}
        setSizePerPage=""
        pagination={false}
        tableLoader={googleAdwordsListLoading}
        header={false}
        locale={{
          emptyText: <Empty description={t('text.common.noDataFound')} />,
        }}
        sortBy={sortBy}
        sortType={sortType}
      />
      <ModalComponent
        backdrop
        show={addModal}
        onHandleCancel={addModalHide}
        title={t('text.googleConnect.editBudget')}
      >
        <GoogleBudget
          addModalHide={addModalHide}
          onSubmit={onSubmit}
          rowData={rowData}
          budgetLoading={budgetLoading}
        />
      </ModalComponent>
    </>
  );
}

export default AdwordListing;
