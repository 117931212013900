import { Outlet } from 'react-router-dom';
import ListGeoLocation from '../../views/ManageLocation/GeoLocation/List';
import EditGeoLocation from '../../views/ManageLocation/GeoLocation/Edit';
import AddGeoLocation from '../../views/ManageLocation/GeoLocation/Create';
import { getActions } from '../../services/index.service';

export default function route() {
  return [
    {
      // path: accessRoute.DASHBOARD.path,
      private: true,
      name: 'Manage Locations',
      key: 'location',
      belongsToSidebar: true,
      submenu: true,
      icon: (
        <span className="nk-menu-icon">
          <em className="icon ni ni-map-pin-fill" />
        </span>
      ),
      moduleKeys: [accessRoute.MANAGE_LOCATION.moduleKey],
      element: <Outlet />,
      children: [
        {
          private: true,
          path: accessRoute.MANAGE_LOCATION.path,
          name: accessRoute.MANAGE_LOCATION.name,
          key: accessRoute.MANAGE_LOCATION.key,
          belongsToSidebar: true,
          // icon: accessRoute.MANAGE_LOCATION.icon,
          element: <ListGeoLocation />,
          ...accessRoute.MANAGE_LOCATION,
        },
        {
          private: true,
          path: accessRoute.ADD_LOCATION.path,
          name: 'Add Location',
          key: accessRoute.ADD_LOCATION.key,
          belongsToSidebar: false,
          // icon: accessRoute.MANAGE_LOCATION.icon,
          element: <AddGeoLocation />,
          ...accessRoute.ADD_LOCATION,
        },
        {
          private: true,
          name: 'Edit Location',
          key: accessRoute.EDIT_LOCATION.key,
          belongsToSidebar: false,
          element: <EditGeoLocation />,
          ...accessRoute.EDIT_LOCATION,
          path: `${accessRoute.EDIT_LOCATION.path}/:id`,
        },
      ],
    },
  ];
}

export const accessRoute = {
  MANAGE_LOCATION: {
    path: '/admin/manage-location',
    isSecure: true,
    action: getActions('LIST').value,
    moduleKey: 'geo-fence-location',
    key: 'location.manage-location',
    belongsToSidebar: true,
    name: 'Geo Fence Location',
    order: 15,
  },
  ADD_LOCATION: {
    path: '/admin/manage-location/add',
    isSecure: true,
    belongsToSidebar: false,
    action: getActions('CREATE').value,
    moduleKey: 'geo-fence-location',
    key: 'location.manage-location',
    name: 'Add Location',
  },
  EDIT_LOCATION: {
    path: '/admin/manage-location/edit',
    isSecure: true,
    action: getActions('EDIT').value,
    moduleKey: 'geo-fence-location',
    key: 'location.manage-location',
    belongsToSidebar: false,
    name: 'Edit Location',
  },
};

export const getChildrenRoutes = () => {
  return route()[0]?.children;
};
