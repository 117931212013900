import i18next from 'i18next';
import * as yup from 'yup';
import { Regex } from '../../../helpers/regex.helper';
import { valueMapper } from '../../../helpers/schema.helper';

export const initValues = (data) => {
    const initVal = {
    firstName: '',
    locationId: null,
    lastName: '',
    email: '',
    phoneNumber: '',
    countryPhoneCode: data?.countryPhoneCode || "1",
    addressLine1: '',
    suiteNumber: '',
    addressLine2: '',
    bookingDate: '',
    serviceCategoryId: '',
    note: '',
    countryId: '',
  };

  return valueMapper(initVal, data);
};

export const validation = () => {
  return yup.object().shape({
    firstName: yup
      .string()
      .typeError(i18next.t('validation.adminProfile.titleError'))
      .nullable()
      .required(i18next.t('validation.adminProfile.fullName'))
      .max(29, i18next.t('validation.adminProfile.maxFullName')),
    lastName: yup
      .string()
      .typeError(i18next.t('validation.adminProfile.displayError'))
      .nullable()
      .required(i18next.t('validation.adminProfile.lastName'))
      .max(29, i18next.t('validation.adminProfile.maxLastName')),
    email: yup
      .string()
      .nullable()
      .required(i18next.t('validation.login.email'))
      .email(i18next.t('validation.login.validEmail')),
    phoneNumber: yup
      .string()
      .trim()
      .nullable()
      .required(i18next.t('validation.common.phone'))
      .matches(Regex.phoneNumber, {
        message: i18next.t('validation.common.validNumber'),
      }),
    addressLine1: yup.string().when('locationId', {
      is: (locationId) => (!locationId ? true : false),
      then: yup.string().required(i18next.t('validation.common.address')),
      otherwise: yup.string(),
    }),
    locationId: yup
      .number()
      .nullable(true)
      .transform((_, val) => (val === Number(val) ? val : null)),
    countryId: yup.number().required(i18next.t('validation.common.country')),
    bookingDate: yup
      .date()
      .required(i18next.t('validation.manualBooking.bookingDate')),
    serviceCategoryId: yup.string().when(['addressLine1', 'locationId'], {
      is: (addressLine1, locationId) =>
        addressLine1 || locationId ? true : false,
      then: yup
        .string()
        .required(i18next.t('validation.manualBooking.serviceType')),
    }),
    // serviceCategoryId: yup
    //   .string()
    //   .required(i18next.t('validation.manualBooking.serviceType')),
  });
};
