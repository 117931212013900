let url = '/v1/admin/email-template';
export const EMAIL_TEMPLATE_ENDPOINT = {
  getEmailTemplateList: {
    url,
    method: 'GET',
  },
  editEmailTemplate: (id) => ({
    url: `${url}/${id}`,
    method: 'PUT',
  }),
  getEmailTemplateDetails: (id) => ({
    url: `${url}/${id}`,
    method: 'GET',
  }),
};
