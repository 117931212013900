let url = '/v1/admin';
export const SHOPPING_CART_ENDPOINT = {
  getAllVariantList: {
    url: `${url}/all-variant`,
    method: 'GET',
  },
  getCategoryList: {
    url: `${url}/all-category`,
    method: 'GET',
  },
  addProduct: {
    url: `/v1/product`,
    method: 'POST',
  },
  getVariantAttributeDetail: (id) => ({
    url: `v1/admin/variant/${id}`,
    method: 'GET',
  }),
  getProductList: {
    url: `${url}/shop/product-list`,
    method: 'GET',
  },
  deleteProduct: (id) => ({
    url: `${url}/product/${id}`,
    method: 'DELETE',
  }),
  statusProduct: (id) => ({
    url: `${url}/product-status-change/${id}`,
    method: 'PUT',
  }),
  getProductDetails: (id) => ({
    url: `/v1/admin/shop/product-detail/${id}`,
    method: 'GET',
  }),
  editProduct: (id) => ({
    url: `/v1/admin/shop/product/${id}`,
    method: 'PUT',
  }),
  downloadSampleFile: {
    url:'/v1/admin/product/sample-file',
    method: 'GET',
  },
  uploadBulkProductFile: {
    url: '/v1/admin/product/file-upload',
    method: 'POST',
  },
  uploadBulkProductImages: {
    url: '/v1/admin/product/media-file-used',
    method: 'POST',
  },
  getProductViewImageList: {
    url: `${url}/product-list/images`,
    method: 'GET',
  },
  deleteProductViewImage: (id) => ({
    url: `${url}/product-list/images/${id}`,
    method: 'DELETE',
  }),
  saveImport: {
    url: `v1/admin/product/bulk/save`,
    method: 'POST',
  },
  importProductItems: {
    url: `v1/admin/product/file-upload`,
    method: 'POST',
  },
  getImportFileDetails: {
    url: `v1/admin/product/bulk/details`,
    method: 'GET',
  },
  discardImport: {
    url: `v1/admin/product/bulk/discard`,
    method: 'POST',
  },
};
