import { RatingReview } from '../../views/CustomerApp';

export default function route() {
  return [
    {
      path: accessRoute.RATING_REVIEW.path,
      private: true,
      name: accessRoute.RATING_REVIEW.name,
      key: accessRoute.RATING_REVIEW.key,
      belongsToSidebar: true,
      icon: accessRoute.RATING_REVIEW.icon,
      element: <RatingReview />,
    },
  ];
}

export const accessRoute = {
  RATING_REVIEW: {
    path: '/customer/rating-review',
    name: 'Rating & Review',
    key: 'rating-review',
    for: 'customer',
    icon: <em className="gg-rating"></em>,
  },
};

export const getChildrenRoutes = () => {
  return route();
};
