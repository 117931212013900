import React, { useState, useEffect, useRef } from 'react';
import { Button, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { FilterFilled } from '@ant-design/icons';

const { Option } = Select;

function SelectFilter(props) {
  const { t } = useTranslation();
  const searchInput = useRef(null);
  const {
    setSelectedKeys,
    onFilterChange,
    extraQuary,
    selectedKeys,
    confirm,
    clearFilters,
    listService,
    dataIndex,
    list,
    scope,
    listKeys = { nameKey: 'name', nameId: 'id', useKey: 'phoneCode' },
    name = '',
  } = props;
  const [state, setState] = useState({ data: [...list], loading: false });
  const { data } = state;

  useEffect(() => {
    if (listService) {
      getList(listService);
    } else {
      setState({ ...state, data: null });
    }
  }, []);

  const handleSearch = async (selectedKeys, confirm, dataIndex) => {
    try {
      await onFilterChange({ [dataIndex]: selectedKeys });
      confirm();
    } catch (err) {
      console.log(err);
    }
  };

  const handleReset = async (clearFilters, confirm) => {
    try {
      await onFilterChange({ [dataIndex]: null });
      clearFilters();
      confirm();
    } catch (err) {
      console.log(err);
    }
  };

  async function getList(listService) {
    try {
      setState({ ...state, loading: true });
      const res = await listService({
        type: 'all',
        queryParams: { ...extraQuary, scope: scope || 'all' },
      });
      let list = [];
      if (Array.isArray(res)) {
        list = [
          ...res.map((e) => ({
            name: e[listKeys?.nameKey],
            id: e[listKeys?.nameId],
            useKey: e[listKeys?.useKey],
          })),
        ];
      }
      setState({ ...state, data: [...list], loading: false });
    } catch (err) {
      setState({ ...state, loading: false });
      console.log(err);
    }
  }

  return (
    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
      <Select
        ref={searchInput}
        filterOption={(input, option) => {
          return (option?.children ?? '')
            .toLowerCase()
            .includes(input.toLowerCase());
        }}
        placeholder={`Select ${name || dataIndex}`}
        value={selectedKeys ? selectedKeys : ''}
        onChange={(e) => setSelectedKeys(e ? e : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{
          marginBottom: 8,
          display: 'block',
        }}
      >
        {(data || list).map(
          (item, key) =>
            item.name && (
              <Option
                key={key}
                value={item.value || item.id}
                disabled={item?.disabled || false}
              >
                {item.name}
              </Option>
            )
        )}
      </Select>
      <Space>
        <Button
          type="primary"
          className="btn btn-primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            width: 90,
          }}
        >
          {t('text.common.search')}
        </Button>
        <Button
          onClick={() => clearFilters && handleReset(clearFilters, confirm)}
          className="btn btn-info"
          style={{
            width: 90,
          }}
        >
          {t('text.common.reset')}
        </Button>
      </Space>
    </div>
  );
}

export default SelectFilter;

export const getColumnSelectSearchProps = ({
  dataIndex,
  list,
  filter,
  ...rest
}) => ({
  filterDropdown: (props) => (
    <SelectFilter
      filter={filter}
      list={list}
      dataIndex={dataIndex}
      {...props}
      {...rest}
    />
  ),
  filterIcon: (filtered) => (
    <FilterFilled
      type="search"
      style={{ color: filtered ? '#F47721' : undefined }}
    />
  ),
  onFilterDropdownVisibleChange: (visible) => {},
});
