import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-bootstrap';
import { GlobalLoader, PageContainerWrapper } from '../../../components';
import { Columns, getStatisticsService } from '../../../services/index.service';
import { PieChart, BarChart } from '../../../components/Graph';
import i18next from 'i18next';

function ViewSiteStatistics() {
  const { t } = useTranslation();
  const breadcrumb = Columns.siteStatisticsBreadcrumb();
  const [loading, setLoading] = useState(false);
  const [graphData, setGraphData] = useState();
  useEffect(() => {
    getSiteStatisticsData();
  }, []);

  async function getSiteStatisticsData() {
    setLoading(true);
    try {
      const res = await getStatisticsService();
      if (res?.success) {
        setGraphData(res?.data);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  const JobsData = Columns.JobsData(graphData);
  const UsersData = Columns.UsersData(graphData);
  const providerCountData = Columns.ProvidersCountData(graphData);
  const providerCountDetails = Columns.providerDetails(graphData);
  const JobsCountData = Columns.JobsCountData(graphData);
  const jobsDetails = Columns.jobDetails(graphData);
  const chartOptionsData = Columns.chartOptionsData(graphData);
  const ProviderLogData = Columns.ProviderLogData(graphData);

  return (
    <>
      <PageContainerWrapper
        heading={t('text.common.siteStatics')}
        breadcrumb={breadcrumb}
        listingHeader={false}
      >
        {loading ? (
          <GlobalLoader />
        ) : (
          <div className="nk-block">
            <Row className="g-gs">
              <BarChart
                col={6}
                title={t('text.siteStatistics.jobForLast')}
                type="bar"
                iconClass="ni ni-briefcase"
                JobsData={JobsData}
                // options={Data}
              />
              <BarChart
                col={6}
                title={t('text.siteStatistics.registeredUser')}
                type="bar"
                iconClass="ni ni-user-check"
                JobsData={UsersData}
              />
              <PieChart
                title={t('text.siteStatistics.jobs')}
                type="doughnut"
                iconClass="ni ni-briefcase"
                JobsCountData={JobsCountData}
                chartOptionsData={chartOptionsData}
                footer={t('text.siteStatistics.footer')}
                JobsDetails={jobsDetails}
              />
              <PieChart
                title={t('text.siteStatistics.providers')}
                type="doughnut"
                iconClass="ni ni-user"
                JobsCountData={providerCountData}
                chartOptionsData={chartOptionsData}
                JobsDetails={providerCountDetails}
              />
              <BarChart
                col={12}
                title={t('text.siteStatistics.providerLogReport')}
                type="bar"
                iconClass="ni ni-file-text"
                ProviderLogData={ProviderLogData}
                options={{ maintainAspectRatio: false }}
              />
            </Row>
          </div>
        )}
      </PageContainerWrapper>
    </>
  );
}

export default ViewSiteStatistics;
