import moment from 'moment';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';
import { firstLetterCaps } from '../../../utils/text.util';
import GlobalLoader from '../../UIElements/GlobalLoader';
// import { accessRoute } from '../../../routes/CustomerApp/requestViewDetails';
import ImageElement from '../../UIElements/ImageElement';
import NoDataFound from '../../UIElements/NoDataFound';

import { accessRoute } from '../../../routes/CustomerApp/booking';
import { generateStarRating } from '../../../utils';
import { checkValidPrice } from '../../UIElements/Formatter';

function RequestCard({ data, isLoading }) {
  const { t } = useTranslation();

  return (
    <>
      <Row className="g-3">
        {!isLoading && data?.length > 0 ? (
          data?.map((data, idx) => {
            return (
              <Col key={idx} xl={4} md={6}>
                <div className="bookingBox">
                  <Card>
                    <Card.Body>
                      <div className="bookingBox_cnt d-flex align-items-md-center flex-grow-1">
                        <div className="bookingBox_img flex-shrink-0">
                          <ImageElement
                            previewSource={
                              data?.Provider?.profilePictureThumbUrl
                            }
                            className="img-fluid"
                          />
                        </div>
                        <div className="bookingBox_detail flex-grow-1">
                          <div className="d-md-flex align-items-lg-center">
                            <div className="flex-grow-1">
                              <h5>
                                {firstLetterCaps(data?.Provider?.firstName)}{' '}
                                &nbsp;
                                {firstLetterCaps(data?.Provider?.lastName)}
                              </h5>

                              <ul className="list-unstyled d-flex starRating mb-0">
                                {generateStarRating(
                                  data?.Provider?.avgRating ?? 0
                                )}
                              </ul>
                            </div>
                            <div className="flex-shrink-1">
                              <p className="mb-0">
                                {checkValidPrice(data?.total)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bookingBox_dateTime mb-1">
                        <span>
                          <em className="gg-calendar me-2" />
                          {moment(data?.bookingScheduleDate).format('LLLL')}
                        </span>
                      </div>
                      <div className="bookingBox_serviceAdderss text-break">
                        <p className="bookingBox_adderss">
                          <em className="gg-marker me-2" />
                          {data?.Address?.addressLine1
                            ? data?.Address?.addressLine1
                            : 'No Address'}
                        </p>
                      </div>

                      <Link
                        onContextMenu={(e) => e?.preventDefault()}
                        to={generatePath(
                          data?.BookingQuote?.providerStatus === 'accepted'
                            ? accessRoute.REQUEST_APPROVE_VIEW_DETAILS.path
                            : accessRoute.REQUEST_VIEW_DETAILS.path,
                          {
                            bookingId: data?.id,
                          }
                        )}
                        state={{ bookingId: data?.id }}
                        className="btn btn-outline-primary w-100"
                      >
                        {t('text.customerMyBooking.viewDetails')}
                      </Link>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            );
          })
        ) : !isLoading && !data?.length ? (
          <NoDataFound
            source="/no-booking.svg"
            text="No Request Booking Found"
            extraClass="noDataFoundWrapper"
            isCustomer={'/assets/images/admin/no-booking.svg'}
          />
        ) : (
          <GlobalLoader />
        )}
      </Row>
    </>
  );
}
export default RequestCard;
