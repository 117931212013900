import React from 'react';
import { useNavigate } from 'react-router-dom';
import StorePageContainer from '../../../../components/Wrappers/StorePageContainer.js';
import { StoreLoginForm } from '../../../../components/index.js';
import {
  setLocalStorage,
  setLocalStorageToken,
} from '../../../../utils/common.util.js';
import { getDefaultAuthRoute } from '../../../../routes/index.routes.js';
import { loginStoreAction, storeLogin } from '../../../../redux/index.redux.js';
import { LoginService } from '../../../../services/index.service.js';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';

function StoreLogin() {
  const loginBreadcrumb = () => {
    return [
      // {
      //   path: '/store',
      //   name: 'Home',
      // },

      {
        path: '#',
        name: 'Log In',
      },
    ];
  };

  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function onSubmit(e) {
    try {
      setLoading(true);
      const res = await LoginService({
        ...e,
        userRole: 'store',
        deviceType: 'web',
        appVersion: 'v1',
        firebaseToken: 'none',
      });
      const { data, success } = res;
      if (success) {
        navigate(getDefaultAuthRoute('store', res?.data?.token));
        dispatch(storeLogin({ ...data }));
        setLocalStorageToken(res?.data?.token, 'store');
        // setLocalStorageToken(res?.data?.token);
        setLocalStorage({ keyName: 'storeRole', data: 'store' });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }

  return (
    <StorePageContainer breadcrumb={[]}>
      <div className="becomePage_signup text-center">
        <div className="becomePage_head mb-0">
          <h2 className="mb-0 font-sm">
            {i18next.t('text.common.storeLogin')}
          </h2>
        </div>
      </div>
      <div className="becomePage_auth mx-auto">
        <StoreLoginForm onSubmit={onSubmit} loading={loading} />
      </div>
    </StorePageContainer>
  );
}

export default StoreLogin;
