import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Upload, Form, Progress, Spin } from 'antd';
import ImgCrop from 'antd-img-crop';
import { useField } from 'formik';
// import { useTranslation } from 'react-i18next';
import {
  getLocalStorageToken,
  removeLocalStorageToken,
  removeSessionStorageToken,
} from '../../../utils';
import modalNotification from '../../../utils/notifications';
import { firstLetterCaps } from '../../../utils/text.util';
// import { IMAGE_URL } from '../../../config';
import { ImageElement } from '../index.element';
import {
  uploadFile,
  uploadPublicFile,
  uploadUsedFile,
} from '../../../services/index.service';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { InboxOutlined } from '@ant-design/icons';
import GlobalLoader from '../GlobalLoader';

function UploadInput(
  {
    // applyImageCropper = false,
    label = '',
    // apiEndPoints,
    name,
    contentType,
    defaultImageUrl = '/assets/images/admin/profile-img.jpg',
    imageUrlList = [],
    mediaFor = 'banner',
    mediaType = 'image',
    // aspect,
    allowedFormat,
    isDrag = false,
    limit = 1,
    // formik,
    children,
    imageDimension = false,
    imageDimensionValid = false,
    imageDimensionMessage = '',
    publicUpload = false,
    isUploadLogo = false,
    setImageUrl,
    imageUrl,
    extraClassName = '',
    removePreview = false,
    isCrop = true,
    successMsg,
    isAllDoc = false,
    googleConnect = false,
    isDragText = false,
    ...rest
  },
  ref
) {
  // const { t } = useTranslation();
  const navigate = useNavigate();
  // const userData = useSelector(selectUserData);
  const [loading, setLoading] = useState(false);
  // const [imageUrl, setImageUrl] = useState();
  const [uploadedList, setUploadedList] = useState([]);
  const [state] = useState({ file: null });
  const [field, meta, helper] = useField(name);
  const config = { ...field, ...rest };
  const apiToken = getLocalStorageToken();
  // let actionUrl = uploadFile(mediaFor, mediaType)?.url;

  let accept = '';
  if (publicUpload) {
    accept = uploadPublicFile(mediaFor, mediaType)?.accept;
  } else {
    accept = uploadFile(mediaFor, mediaType)?.accept;
  }

  // const accept = uploadFile(mediaFor, mediaType)?.accept;

  const types = {
    'application/pdf': 'doc',
    'image/jpeg': 'image',
    'image/jpg': 'image',
    'image/png': 'image',
    'image/*': 'image',
  };

  React.useEffect(() => {
    if (Array.isArray(imageUrlList) && imageUrlList.length > 0) {
      const list = imageUrlList.map((e) => {
        return {
          status: 'done',
          name: e?.split('/')?.pop(),
          url: e,
        };
      });
      setUploadedList(list);
    } else if (imageUrlList === '') {
      setUploadedList([]);
    }
  }, [imageUrlList]);

  useImperativeHandle(ref, () => ({
    handleReset: () => setUploadedList([]),
  }));
  // async function uploadMediaFile(e) {
  //   try {
  //     const formData = new FormData();
  //     formData.append('file', e.target.files[0]);
  //     const res = await uploadFile(mediaFor, mediaType, formData);
  //     if (res?.success) {
  //       setState({ ...state, file: { ...res?.data } });
  //       helper.setValue(res.data.basePath);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  if (meta && meta.touched && meta.error) {
    config.error = true;
    config.helperText = meta.error;
  }

  // useEffect(() => {
  //   if (defaultImageUrl) {
  //     // setImageUrl(defaultImageUrl);
  //   } else {
  //     // setImageUrl(`${IMAGE_URL}/img_default-placeholder.png`);
  //   }
  // }, [defaultImageUrl]);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  // const handleDimension = (file) => {};

  const checkImageDimension = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      let image = document.createElement('img');
      reader.addEventListener('load', (event) => {
        const _loadedImageUrl = event.target.result;
        image.src = _loadedImageUrl;
        image.addEventListener('load', () => {
          const { width, height } = image;
          if (
            width !== imageDimension?.width &&
            height !== imageDimension?.height
          ) {
            modalNotification({
              type: 'error',
              message: imageDimensionMessage,
            });
            resolve(false);
          }
          resolve(true);
        });
      });
    });
  };

  const beforeUpload = async (file) => {
    try {
      if (mediaFor === 'providerDocument') {
        if (types[file?.type] && allowedFormat?.includes(types[file?.type])) {
          return true;
        } else {
          modalNotification({ type: 'error', message: 'Invalid File Type' });
          return false;
        }
      }
      // For dimension validation
      if (imageDimensionValid) {
        return await checkImageDimension(file);
      }
      return true;
    } catch (err) {
      console.log(err);
    }
  };

  // const beforeUpload = (file) => {
  //   const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  //   if (!isJpgOrPng) {
  //     modalNotification({
  //       type: 'error',
  //       message: t('text.common.imageOnlyMessgae'),
  //     });
  //   }

  //   const isLt2M = file.size / 1024 / 1024 < 5;

  //   if (!isLt2M) {
  //     modalNotification({
  //       type: 'error',
  //       message: t('text.common.imageSizeExceedingMessage'),
  //     });
  //   }

  //   return isJpgOrPng && isLt2M;
  // };

  const handleChange = (info) => {
    if (info?.fileList?.length > limit) {
      const list = info.fileList;
      list.shift();
      setUploadedList([...list]);
    } else {
      setUploadedList([...info.fileList]);
    }
    if (info.file.status === 'uploading') {
      setLoading(true);
    } else {
      const { status, response } = info.file;
      if (status === 'done') {
        getBase64(info.file.originFileObj, () => {
          // setLoading(false);
          if (setImageUrl) setImageUrl(info.file.response.data);
        });
        modalNotification({
          type: 'success',
          message: successMsg || 'Image uploaded successfully',
          // description: `${info.file.name} file uploaded successfully.`,
        });
        // onFileUploaded(response.data.basePath)
        helper.setValue(
          googleConnect ? response.data.baseUrl : response.data.basePath
        );
      } else if (status === 'error') {
        // setLoading(false);
        if (response?.error?.status === 401) {
          removeSessionStorageToken();
          removeLocalStorageToken();
          navigate('/login');
        }
        modalNotification({
          type: 'error',
          message: info?.fileList[0]?.response?.message,
          description: `${info.file.name} file upload failed. ${info.file.response.detail}`,
        });
        if (response.detail.search('authenticated') !== -1) {
          removeSessionStorageToken();
        }
      }
      setLoading(false);
    }
  };

  function onRemoveFile(e) {
    try {
      const list = uploadedList.filter((ele) => {
        if (e?.url === ele?.url) {
          return false;
        }
        return true;
      });
      helper.setValue('');
      setUploadedList([...list]);
    } catch (err) {
      console.log(err);
    }
  }

  const uploadProps = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${apiToken}`,
    },
    action: (file) => {
      if (publicUpload) {
        return uploadPublicFile(mediaFor, types[file?.type])?.url;
      } else {
        if (isAllDoc) {
          return uploadFile(mediaFor, isAllDoc && 'doc')?.url;
        } else if (googleConnect) {
          return uploadUsedFile(mediaFor, mediaType)?.url;
        }

        return uploadFile(mediaFor, types[file?.type])?.url;
      }
    },
    method: 'POST',
    beforeUpload,
    listType: 'picture',
    multiple: true,
    fileList: uploadedList,
    accept: accept,
    onChange: handleChange,
    onRemove: onRemoveFile,
    itemRender: (item, file, list, actions) => {
      if (file?.status === 'error') {
        return <></>;
      }
      return (
        <div>
          {file?.status !== 'done' ? (
            file?.status && (
              <Progress
                percent={
                  (file?.percent && parseFloat(file?.percent.toFixed(2))) || 0
                }
                size="small"
              />
            )
          ) : !isDrag ? (
            <>
              {(file?.thumbUrl || file?.url) && (
                <div className="imgPreviewBox">
                  <ImageElement
                    previewSource={file?.thumbUrl || file?.url}
                    alt="img"
                  />
                  <div onClick={actions?.remove} className="imgPreviewBox_icon">
                    <Link
                      onClick={(e) => e.preventDefault()}
                      href="#"
                      className="btn btn-primary"
                    >
                      <em className="icon ni ni-cross"></em>
                    </Link>
                  </div>
                </div>
              )}
            </>
          ) : isUploadLogo ? (
            <div className="profileImageUpload position-relative text-center">
              <div className="img-box rounded-circle overflow-hidden profileImageUpload_img mx-auto">
                <ImageElement
                  previewSource={file?.response?.data?.baseUrl}
                  alt="img"
                />
              </div>
              {file.response.data.baseUrl}
              <Upload
                {...uploadProps}
                name={name}
                listType="picture-card"
                className="avatar-uploader profileImageUpload_upload"
                showUploadList={false}
                accept=".png, .jpg, .jpeg"
              >
                <ImageElement
                  previewSource={file?.response?.data?.baseUrl || file?.url}
                  alt="img"
                />
              </Upload>
            </div>
          ) : (
            <>
              {(file?.thumbUrl || file?.url || file?.name) && (
                <div className="nk-upload-list mt-2">
                  <div className="nk-upload-item">
                    <div className="nk-upload-icon">
                      <ImageElement
                        previewSource={file?.thumbUrl || file?.url}
                        alt="img"
                      />
                    </div>
                    <div className="nk-upload-info">
                      <div className="nk-upload-title">
                        <span className="title">
                          {file?.name.split(/[?#]/)[0] || contentType}
                        </span>
                        {removePreview && (
                          <div
                            onClick={actions?.remove}
                            className="nk-upload-action"
                          >
                            <Link
                              to="#"
                              onClick={(e) => e.preventDefault()}
                              className="btn btn-icon btn-trigger"
                            >
                              <em className="icon ni ni-trash"></em>
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      );
    },
  };

  return isUploadLogo ? (
    <div className="profileImageUpload position-relative text-center">
      <div className="img-box user-avatar user-avatar rounded-circle overflow-hidden profileImageUpload_img mx-auto">
        {loading ? (
          <Spin
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: 'auto',
            }}
          />
        ) : (
          <ImageElement
            previewSource={imageUrl ? imageUrl : defaultImageUrl}
            alt="img"
          />
        )}
      </div>
      <Upload
        {...uploadProps}
        name={name}
        listType="picture-card"
        className="avatar-uploader profileImageUpload_upload"
        showUploadList={false}
        accept=".png, .jpg, .jpeg"
      >
        {children}
      </Upload>
    </div>
  ) : !isDrag ? (
    <Form.Item
      label={label}
      className="flex-col"
      name={name}
      onClick={(e) => e.stopPropagation()}
      help={meta.error && meta?.error && meta?.touched ? meta.error : ''}
      validateStatus={config.error ? 'error' : 'success'}
    >
      {isCrop ? (
        <ImgCrop rotate>
          <Upload {...uploadProps} className="d-block">
            <div className="form-file">
              <label
                className={`form-file-label ${extraClassName}`}
                htmlFor="customFile"
              >
                {state && state?.file?.name ? state?.file?.name : 'Choose file'}
              </label>
            </div>
          </Upload>
        </ImgCrop>
      ) : (
        <Upload {...uploadProps} className="d-block">
          <div className="form-file">
            <label
              className={`form-file-label ${extraClassName}`}
              htmlFor="customFile"
            >
              {state && state?.file?.name ? state?.file?.name : 'Choose file'}
            </label>
          </div>
        </Upload>
      )}
    </Form.Item>
  ) : (
    <Form.Item
      label={label}
      className="flex-col"
      name={name}
      onClick={(e) => e.stopPropagation()}
      help={meta.error && meta?.error && meta?.touched ? meta.error : ''}
      validateStatus={config.error ? 'error' : 'success'}
    >
      <Upload.Dragger {...uploadProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        {isDragText ? (
          isDragText
        ) : (
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        )}
        {contentType && (
          <p className="ant-upload-hint">{firstLetterCaps(contentType)}</p>
        )}
      </Upload.Dragger>
    </Form.Item>
  );
}

export default forwardRef(UploadInput);
