import { GOOGLE_CONNECT_ENDPOINT } from '../../apiEndpoints/GoogleConnect/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';

export const getAccountListService = async () => {
  try {
    const payload = {
      ...GOOGLE_CONNECT_ENDPOINT?.getAccountList,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const getBusinessListService = async ({ queryParams }) => {
  try {
    const payload = {
      ...GOOGLE_CONNECT_ENDPOINT?.getBusinessList,
      queryParams,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const getReviewDataService = async ({ queryParams }) => {
  try {
    const payload = {
      ...GOOGLE_CONNECT_ENDPOINT?.getReviewData,
      queryParams,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const reviewReplyService = async ({ bodyData }) => {
  try {
    const payload = {
      ...GOOGLE_CONNECT_ENDPOINT?.reviewReply,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const getBusinessDataService = async ({ queryParams }) => {
  try {
    const payload = {
      ...GOOGLE_CONNECT_ENDPOINT?.getBusinessMedia,
      queryParams,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const getBusinessPostDataService = async ({ queryParams }) => {
  try {
    const payload = {
      ...GOOGLE_CONNECT_ENDPOINT?.getBusinessPost,
      queryParams,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const deleteReplyService = async ({ bodyData }) => {
  try {
    const payload = {
      ...GOOGLE_CONNECT_ENDPOINT?.deleteReply,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const addPostService = async ({ bodyData }) => {
  try {
    const payload = {
      ...GOOGLE_CONNECT_ENDPOINT?.addPost,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const addMediaService = async ({ bodyData }) => {
  try {
    const payload = {
      ...GOOGLE_CONNECT_ENDPOINT?.addMedia,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const deleteBusinessPost = async ({ bodyData }) => {
  try {
    const payload = {
      ...GOOGLE_CONNECT_ENDPOINT?.deletePost,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getGoogleServiceList = async ({ queryParams }) => {
  try {
    const payload = {
      ...GOOGLE_CONNECT_ENDPOINT?.getGoogleService,
      queryParams,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getGoogleLeadList = async ({ queryParams }) => {
  try {
    const payload = {
      ...GOOGLE_CONNECT_ENDPOINT?.getGoogleLead,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const statusGoogleServiceList = async ({ bodyData }) => {
  try {
    const payload = {
      ...GOOGLE_CONNECT_ENDPOINT.statusGoogleService,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
export const updateSPControlStatus = async ({ bodyData }) => {
  try {
    const payload = {
      ...GOOGLE_CONNECT_ENDPOINT.updateGoogleSPControlStatus,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getGoogleTokenService = async ({ queryParams }) => {
  try {
    const payload = {
      ...GOOGLE_CONNECT_ENDPOINT?.getGoogleToken,
      queryParams,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
