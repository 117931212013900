import loadable from '@loadable/component';

export const CreateButton = loadable(() => import('./CreateButton/index'));
export const ActionButton = loadable(() => import('./ActionButton/index'));
export const FilterButton = loadable(() => import('./FilterButton'), {
  fallback: <div>Loading...</div>,
});
export const CsvExport = loadable(() => import('./CsvExport/index'));
export const CommonButton = loadable(() => import('./Common/index.btn'));
export const GradientButton = loadable(() =>
  import('./GradientButton/index.btn')
);
export const CancelButton = loadable(() => import('./CancelButton/index.btn'));
export { default as CsvImport } from './CsvImport/index';
