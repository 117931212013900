import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import {
  ModalWrapper,
  NoDataFound,
  PageContainerWrapper,
  ProviderDocumentForm,
} from '../../../../components';
import { Columns, TeamSetting } from '../../../../services/index.service';
import { backToPage, modalNotification } from '../../../../utils';
import { accessRoute } from '../../../../routes/TeamSetting';
import { DocumentUpdate } from '../../../../features/index.feature';
import { getApiData } from '../../../../hooks/useGetApi.hook';
import { getProviderDocumentService } from '../../../../services/TeamSetting/document.service';
import { Row } from 'react-bootstrap';

function DocumentList() {
  const [listData, setListData] = useState([]);
  const { id } = useParams();

  const [state, setState] = useState({
    modal: null,
    modalId: null,
    modalUrl: null,
    loading: 'page',
    basePath: null,
    options: [],
    activeOption: {},
    editContentType: '',
    expireDate: null,
    isRequiredExp: 0,
  });

  const [activeOption, setActiveOption] = useState(null);
  const navigate = useNavigate();
  const breadcrumb = Columns.documentBreadcrumb();

  const redirectUrl = accessRoute.PROVIDER.path;
  const { t } = useTranslation();
  const {
    modal,
    modalUrl,
    modalId,
    loading,
    basePath,
    editContentType,
    expireDate,
    isRequiredExp,
  } = state;

  useEffect(() => {
    getApiData({
      service: getProviderDocumentService,
      setData: setListData,
      queryParams: {
        // countryId,
        documentFor: 'provider',
        userId: id,
        scope: 'activeDocument',
      },
    });
  }, [modalUrl]);

  const actions = (data) => {
    setState({
      ...state,
      modal: 'upload-file',
      expireDate: data?.UserDocument?.expireDate,
      modalId: data?.id,
      editContentType: data?.name,
      modalUrl: data?.UserDocument?.documentThumbUrl,
      basePath: data?.UserDocument?.documentImage,
      isRequiredExp: data?.isDateRequired,
    });
  };

  function onCancel() {
    setState({
      ...state,
      modal: null,
      modalUrl: null,
      activeOption: null,
      expireDate: null,
      isRequiredExp: 0,
    });
    setActiveOption(null);
  }

  async function onSave(data, documentId, modalId) {
    try {
      setState({ ...state, loading: 'submit' });
      const queryParams = {};
      if (modalId) {
        queryParams.id = modalId;
      }
      const bodyData = {
        documentImage: data.documentImage,
        expireDate: data.expireDate,
      };

      let res;
      res = await TeamSetting.createProviderDocument({
        bodyData: { ...bodyData, userId: id, documentId: modalId },
      });

      const newState = { ...state, loading: null };
      if (res?.success) {
        newState.modal = null;
        newState.modalId = null;
        newState.modalUrl = null;

        modalNotification({
          type: 'success',
          message: modalId
            ? 'Document updated successfully'
            : 'Document created successfully',
        });
      }
      setState({ ...newState });
    } catch (err) {
      setState({ ...state, loading: null });
      console.log(err);
    }
  }
  const headerBtn = {
    extraButton: { name: 'Back' },
  };

  return (
    <>
      <PageContainerWrapper
        breadcrumb={breadcrumb}
        btnArray={['extraButton']}
        btn={headerBtn}
        extraBtnText={t('text.common.back')}
        extraBtnClass="btn btn-outline-light bg-white"
        onExtraButtonHandleShow={() => backToPage(navigate, redirectUrl)}
        heading={t('text.common.document')}
      >
        <ModalWrapper
          heading={t('text.common.uploadFile')}
          content={
            <ProviderDocumentForm
              isLoading={loading === 'submit'}
              onSubmit={onSave}
              basePath={basePath}
              expireDate={expireDate}
              modalId={modalId}
              documentId={activeOption?.id}
              uploadType={activeOption?.name || editContentType}
              imgUrl={modalUrl}
              isRequiredExp={isRequiredExp}
              onCancel={onCancel}
            />
          }
          show={['upload-file'].includes(modal)}
          onCancel={onCancel}
        />
        <div className="nk-block documentUploadSec">
          <Row className="g-gs">
            {listData?.rows && listData?.rows?.length
              ? listData?.rows?.map((item) => {
                  return (
                    <DocumentUpdate
                      key={item?.id}
                      item={item}
                      actions={actions}
                      fileName={item?.Document?.documentFor}
                      imageUrl={item?.documentImageUrl}
                    />
                  );
                })
              : null}

            {listData && listData?.rows && listData?.rows?.length < 1 ? (
              <NoDataFound
                source="/no-data-found-img.svg"
                text="No Data Found"
                extraClass="noDataFoundWrapper"
                isCustomer={'/assets/images/admin/no-data-found-img.svg'}
              />
            ) : null}
          </Row>
        </div>
      </PageContainerWrapper>
    </>
  );
}

export default DocumentList;
