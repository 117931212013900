import React, { useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import { CommonButton } from '../../../components';

import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

const DeclineModal = ({ handleClose, handleSubmit, isLoading }) => {
  const { t } = useTranslation();
  const { TextArea } = Input;
  const [reason, setReason] = useState('');

  const handleContinue = () => {
    handleSubmit(reason);
  };

  return (
    <Form className="cancelBooking_textarea">
      <FormGroup className="form-group">
        <label className="ant-label">{t('text.common.reason')}</label>
        <TextArea
          rows={8}
          placeholder=""
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        ></TextArea>
      </FormGroup>
      <div className="d-flex align-items justify-content-end flex-wrap mt-lg-4 mt-2">
        <CommonButton
          variant="light"
          className="min-200 me-3"
          onClick={handleClose}
        >
          {t('text.common.cancel')}
        </CommonButton>
        <CommonButton
          disabled={isLoading}
          loading={isLoading}
          variant="primary"
          className="min-200"
          onClick={handleContinue}
        >
          {t('text.common.continue')}
        </CommonButton>
      </div>
    </Form>
  );
};

export default DeclineModal;
