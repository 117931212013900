import i18next from 'i18next';
import {
  getColumnSearchSelectProps,
  getInputSearchFilter,
  getMultiSelectSearchFilter,
} from '../../components';
import { getFromToDateFilter } from '../../components/Filter/Common/date.filter';
import {
  checkValidData,
  checkValidPrice,
  costWithPercentFormatter,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { accessRoute } from '../../routes/Dashboard';
import { dateFormatter, readMoreTextShow } from '../../utils';
import {
  getQuoteOrderService,
  discountType,
  getTupleStatusList,
} from '../index.service';
import { DATE_MONTH__DATE_TH_YEAR } from '../../config';

export const quoteDiscountTableColumn = ({
  Action,
  onFilterChange,
  Toggle,
  filter,
  page,
  sizePerPage,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'quoteOrderId',
      title: i18next.t('text.quote.quoteOrder'),
      sorter: true,
      render: (cell, row) =>
        readMoreTextShow(
          checkValidData(row?.quoteOrderAdminProfitName),
          i18next.t('text.quote.quoteOrder'),
          20
        ),

      // readMoreTextShow(
      //   (row?.DiscountQuoteOrderTypes || [])
      //     ?.filter((e) => e?.QuoteOrderAdminProfit?.providerQuoteOrder)
      //     ?.map((e) => e?.QuoteOrderAdminProfit?.providerQuoteOrder)
      //     .join(', '),
      //   i18next.t('text.quote.quoteOrder')
      // )
      ...getMultiSelectSearchFilter({
        dataIndex: 'quoteOrderId',
        onFilterChange,
        name: 'quote order',
        list: [],
        mode: false,
        filter,
        searchOrNot:true,
        listService: getQuoteOrderService,
        listKeys: {
          nameKey: 'providerQuoteOrder',
          nameId: 'providerQuoteOrder',
        },
        addAll: true,
        scope:'all'
      }),
    },
    {
      dataIndex: 'title',
      title: i18next.t('text.common.title'),
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'title',
        placeholder: 'title',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'cost',
      title: i18next.t('text.common.cost'),
      sorter: true,
      render: (cell, row) => costWithPercentFormatter({ cell, row }),
      ...getColumnSearchSelectProps({
        filter,
        dataIndex: 'cost',
        list: discountType(),
        onFilterChange,
        selectIndex: 'cost',
        placeholder: {
          select: 'Select',
          selectIndex: 'type',
          input: 'Enter Cost',
          inputName: 'inputType',
          inputIndex: 'cost',
        },
      }),
    },
    {
      dataIndex: 'minJobAmount',
      title: i18next.t('text.quote.minJobAmount'),
      sorter: true,
      render: (cell, row) => checkValidPrice(cell),
      ...getInputSearchFilter({
        dataIndex: 'minJobAmount',
        placeholder: 'min job amount',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'startDate',
      filterDataIndex: ['fromStartDate', 'toStartDate'],
      title: i18next.t('text.common.startDate'),
      sorter: true,
      render: (cell, row) => dateFormatter(cell, DATE_MONTH__DATE_TH_YEAR),
      ...getFromToDateFilter({
        dataIndex: 'startDate',
        dataStartIndex: 'fromStartDate',
        dataEndIndex: 'toStartDate',
        placeholderStartDate: 'From date',
        placeholderEndDate: 'To date',
        dateFormat: 'YYYY/MM/DD',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'endDate',
      filterDataIndex: ['fromEndDate', 'toEndDate'],
      title: i18next.t('text.common.endDate'),
      sorter: true,
      render: (cell, row) => dateFormatter(cell, DATE_MONTH__DATE_TH_YEAR),
      ...getFromToDateFilter({
        dataStartIndex: 'fromEndDate',
        dataEndIndex: 'toEndDate',
        placeholderStartDate: 'From date',
        placeholderEndDate: 'To date',
        dateFormat: 'YYYY/MM/DD',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'description',
      title: i18next.t('text.common.description'),
      sorter: true,
      render: (cell, row) =>
        readMoreTextShow(
          checkValidData(cell),
          i18next.t('text.quote.description'),
          20
        ),
      ...getInputSearchFilter({
        dataIndex: 'description',
        placeholder: 'description',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'status',
      title: i18next.t('text.localization.status'),
      headerClasses: 'sorting',
      sort: true,
      render: (cell, row) => {
        return <Toggle row={row} cell={cell} />;
      },
      ...getMultiSelectSearchFilter({
        dataIndex: 'status',
        onFilterChange,
        name: 'status',
        list: [],
        mode: false,
        listService: getTupleStatusList,
        filter,
      }),
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.localization.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => {
        return <Action row={row} cell={cell} />;
      },
    },
  ];
};

export const quoteDiscountBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.quote.discount'),
    },
  ];
};
