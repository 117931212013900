import { APIRequest } from '../../helpers/index.helper';
import { PUSH_NOTIFICATION_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { setLocalStorage } from '../../utils';

export const postPushNotification = async (body) => {
  try {
    const payload = {
      ...PUSH_NOTIFICATION_ENDPOINT?.pushNotification,
      bodyData: body?.values,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
  }
};
export const getUserListService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...PUSH_NOTIFICATION_ENDPOINT?.getUserList,
      queryParams,
    };
    const res = await APIRequest(payload);
    setLocalStorage({ keyName: 'isScrollCount', data: res?.data?.count });
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
  }
};
