import { modalNotification } from '../utils';

// For basic form submit
export const formSubmit = async ({
  values,
  navigate = false,
  redirectUrl,
  setIsLoading,
  service,
  id = '',
}) => {
  try {
    if (setIsLoading) setIsLoading(true);
    const { success, message } = await service({ values, id });
    if (success) {
      modalNotification({
        type: 'success',
        message,
      });
      if (navigate) navigate(redirectUrl);
      if (setIsLoading) setIsLoading(false);
      return success;
    }
  } catch (error) {
    console.log(error);
  }
  if (setIsLoading) setIsLoading(false);
};
