export const text = {
  enterjobLocation: 'Enter job location',
  typeService: 'What type of service are you looking for?',
  selectServices: 'Select service Type',
  setAppointment: 'When would you like us to set your appointment for?',
  timeFrameWork: 'Which time frame would work best for you?',
  enterSpecialInstructions: 'Enter special instructions*',
  specialInstructions: 'Special Instructions',
  jobType: 'Select job type should change to',
  selectjobType: 'Select job Type',
  specificTechnician:
    'Select a specific technician or first available technician',
  selectSpecificTechnician: 'Type provider name to search from below list',
  number: 'Number',
  noteOne: 'Administrator can Add / Edit Job later booking on this page.',
  noteTwo:
    'Provider current availability is not connected with booking being made. Please confirm future availability of Provider before doing booking.',
  noteThree:
    'Adding booking from here will not send request to Provider immediately.',
  noteFour: `In case of "Auto Assign Provider" option selected, Provider(s) get automatic request before 8-12 minutes of actual booking time.`,
  noteFive: `In case of "Auto Assign Provider" option not selected, Provider(s) get booking confirmation sms as well as reminder sms before 30 minutes of actual booking. Provider has to start the scheduled Job by going to "Your Job" Upcoming section from Provider App.`,
  noteSix: `In case of "Auto Assign Provider" option not selected, Provider(s) get booking confirmation sms as well as reminder sms before 30 minutes of actual booking. Provider has to start the scheduled Job by going to "Your Job" >> Upcoming section from Provider App.`,
  noteSeven: `In case of "Auto Assign Provider", the competitive algorithm will be followed instead of one you have selected in settings.`,
  howItWorks: 'How It Works?',
  howWorkHead: `Flow : Through "Manual Booking" Feature, you can book providers for
    users who ordered for a Service by calling you. There will be users
    who may not have iPhone or Android Phone or may not have app installed
    on their phone. In this case, they will call Company (your company)
    and order service which may be needed immediately or after some time
    later.`,
  workOne: `Here, you will fill their info in the form and dispatch a service provider for them.`,
  workTwo: `If the user is already registered with us, just enter his phone number and his info will be fetched from the database when "Get Details" button is clicked. Else fill the form.`,
  workThree: ` Once the Job detail is added, estimate will be calculated based on Service or Service provider selected.`,
  workFour:
    'Admin will need to communicate & confirm with provider and then select him as provider so the Job can be allotted to him.',
  workFive: `Clicking on "Book Now" Button, the Booking detail will be saved and will take Administrator to the "Scheduled Booking" Section. This page will show all such bookings.`,
  workSix: `Both Provider and User will receive the booking details through
    Email and SMS as soon as the form is submitted. Based on this
    booking details, Provider will go to user's location at the
    scheduled time.`,
  workSeven: `They both will get the reminder SMS and Email as well before 30
    minutes of actual job`,
  workEight: `The assigned provider can see the upcoming Bookings from his App
    under "My Jobs" section.`,
  workNine: `Provider will have option to "Start Job" when he reaches the Job
    Location at scheduled time or "Cancel Job" if he cannot take the job
    for some reason. If the provider clicks on "Cancel Job", a
    notification will be sent to Administrator so he can make alternate
    arrangements.`,
  workTen: `Upon clicking on "Start Job", the service will start in provider's
    App in regular way.`,
  serviceTypeInfo: `Service Type Info`,
  serviceTypeDetails: `Cost is associated next to each service types.No discounts apply if
    bookings via phone.For customer to get special promo pricing they
    should book online or use the Good Guys Home Services App Scheduled
    Bookings will provide an option to set a date and time. Emergency
    Same-day appointments will show you if any service techs are
    available.If technicians are available let the customer Know.We will
    add you to our will call list for today and call you 20-30 minutes
    before we arrive. If no techs are available recommend scheduling for
    the next day and time.`,
  bookingDateInfo: 'Booking Date Info',
  bookingDateDetails: `After selecting a date.The system will show you any available time
    frames for that day.`,
  specialInstructionInfo: 'Special Instruction Info',
  specialInstructionDetails: ` Please include any information that should be communicated directly to
    the tech from the customer. Example: Gate codes, additional contact
    person or phone number,warranties,etc.`,
  jobInfo: `Job Type Info`,
  jobDetials: `At the time some customers may request a specific technician.If it
    shows that technician as available,then assign the job to that
    person.If is doesn't show that technician available try a different
    date or time frame.Or confirm with the customer it is OK to set it up
    or the first available technician. If customer wants a specific
    technician and matching up times doesn't work.Recommend customer
    contacting technician directly and provide the customer with
    technicians phone number. First Available will send the job to all
    technicians open for that time frame. The first available tech will
    contact customer ASAP.`,
  bookingInfo: 'Booking Time Info',
  bookingDetials: `We provide 3 hour time windows but we will call or text 20-30 minutes
    before arrival.Pick the window that is closet to availability and type
    in time issues in the special instructions.`,
  dontHavePermission: "Don't have permission ",
};
