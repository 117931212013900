import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';
import { HOME_ENDPOINT } from '../../apiEndpoints/Home/index.endpoint';

export const addAddressService = async ({ body }) => {
  try {
    const payload = {
      ...HOME_ENDPOINT?.createAddress,
      bodyData: body,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getAddressService = async (queryParams) => {
  try {
    const payload = {
      ...HOME_ENDPOINT?.getCustomerAddress,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
