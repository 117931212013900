import { getActions } from '../../services/index.service';
import { PayOnlineGuest } from '../../views/CustomerApp';

export default function route() {
  return [
    {
      path: accessRoute.PAY_ONLINE_GUEST.path,
      private: true,
      name: accessRoute.PAY_ONLINE_GUEST.name,
      key: accessRoute.PAY_ONLINE_GUEST.key,
      belongsToSidebar: false,

      element: <PayOnlineGuest />,
      ...accessRoute.PAY_ONLINE_GUEST,
    },
  ];
}

export const accessRoute = {
  PAY_ONLINE_GUEST: {
    path: '/customer/pay-online-guest/:trip',
    name: 'Pay online guest',
    key: 'payOnlineGuest',
    action: getActions('LIST').value,
  },
};

export const getChildrenRoutes = () => {
  return route();
};
