import React from 'react';
import { CommonButton } from '../../../../../../components';
import { useState } from 'react';
import { modalNotification } from '../../../../../../utils';
import { warrantyPaymentService } from '../../../../../../services/index.service';

export default function WarrantyNoCharge({
  rowData,
  setIsOpenModal,
  getData,
  paymentDetails,
}) {
  const [loading, setLoading] = useState(false);
  const onSubmit = async () => {
    setLoading(true);
    try {
      let bodyData = {
        bookingId: rowData?.BookingDetail?.bookingId,
        paidAmount: rowData?.adminAttentionAmount,
        reason: paymentDetails?.reason,
      };
      const response = await warrantyPaymentService({ bodyData });
      if (response?.success) {
        modalNotification({
          type: 'success',
          message: response?.message,
        });
        setIsOpenModal(false);
        getData();
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <>
      <p>
        This would allow admin to close out the job if the job has a zero for
        total.
      </p>
      <h6 className="title text-primary mb-0">Warranty/No Charge</h6>
      <div className="mt-4 text-end">
        <CommonButton
          type="submit"
          className="btn btn-primary"
          onClick={onSubmit}
          loading={loading}
        >
          Submit
        </CommonButton>
      </div>
    </>
  );
}
