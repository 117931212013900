import loadable from '@loadable/component';
import FullPageLoader from '../../components/UIElements/Loader/fullPageLoader';

export const AddUser = loadable(() => import('./Create'), {
  fallback: <FullPageLoader />,
});
export const EditUser = loadable(() => import('./Edit'), {
  fallback: <FullPageLoader />,
});
export const ListUser = loadable(() => import('./List'), {
  fallback: <FullPageLoader />,
});
export const ListUserAddress = loadable(() => import('./ListUserAddress'), {
  fallback: <FullPageLoader />,
});
export * from './ViewUserProfile';

export const MemberShipPlan = loadable(() => import('./MemberShipPlan/index'), {
  fallback: <FullPageLoader />,
});
export const CustomerDetails = loadable(
  () => import('./CustomerDetails/index'),
  {
    fallback: <FullPageLoader />,
  }
);
