import i18next from 'i18next';
import {
  checkValidData,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { accessRoute } from '../../routes/Dashboard';
import { getInputSearchFilter } from '../../components';
import { accessRoute as PriceManagementRoute } from '../../routes/PriceManagement';
import { readMoreTextShow } from '../../utils';

export const supplyHouseBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.quote.supplyHouseLocation'),
    },
  ];
};

export const supplyHouseTableColumn = ({
  Action,
  filter,
  onSort,
  onFilterChange,
  page,
  sizePerPage,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'name',
      filterDataIndex: ['equipmentName'],
      title: i18next.t('text.quote.supplyHouseName'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('name', order),
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        placeholder: 'equipment name',
        dataIndex: 'equipmentName',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'address',
      filterDataIndex: ['equipmentLocation'],
      title: i18next.t('text.quote.supplyHouseLocation'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('address', order),
      render: (cell, row) =>
        readMoreTextShow(
          cell?.replaceAll(',', ', '),
          i18next.t('text.quote.supplyHouseLocation'),
          50
        ),
      ...getInputSearchFilter({
        dataIndex: 'equipmentLocation',
        placeholder: 'supply house location',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'emails',
      filterDataIndex: ['email'],
      title: i18next.t('text.common.email'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('emailContact', order),
      render: (cell, row) =>
        row?.emails
          ? readMoreTextShow(
              row?.emails?.replaceAll(',', ', '),
              i18next.t('text.common.email'),
              50
            )
          : '-',
      ...getInputSearchFilter({
        dataIndex: 'email',
        placeholder: 'email',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.common.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => <Action row={row} cell={cell} />,
    },
  ];
};
export const addSupplyHouseBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: PriceManagementRoute.LIST_HOUSE_LOCATION.path,
      name: i18next.t('text.equipmentOrder.supplyHouseLocation'),
    },
    {
      path: '#',
      name: i18next.t('text.quote.addEquipmentLocation'),
    },
  ];
};
export const editSupplyHouseBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: PriceManagementRoute.LIST_HOUSE_LOCATION.path,
      name: i18next.t('text.equipmentOrder.supplyHouseLocation'),
    },
    {
      path: '#',
      name: i18next.t('text.quote.updateSupplyHouseLocation'),
    },
  ];
};
