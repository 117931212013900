export const ADMIN_ENDPOINT = {
  getAdminApi: {
    url: '/v1/admin/sub-admin',
    method: 'GET',
  },
  addAdmin: {
    url: '/v1/admin/sub-admin',
    method: 'POST',
  },
  deleteAdmin: {
    url: `/v1/admin/sub-admin`,
    method: 'DELETE',
  },
  getAdminById: (id) => ({
    url: `/v1/admin/sub-admin/${id}`,
    method: 'GET',
  }),
  updateAdminById: (id) => ({
    url: `/v1/admin/sub-admin/${id}`,
    method: 'PUT',
  }),
  updateAdminStatusId: {
    url: `/v1/admin/sub-admin/change-status`,
    method: 'PUT',
  },
};
