import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import moment from 'moment';
import { ChatContext } from '../../../context/chat.context';
import { useTranslation } from 'react-i18next';

import ChatMessageHeader from './ChatMessageHeader';
import ChatMessageList from './ChatMessageList';
import ChatMessageFooter from './ChatMessageFooter';
import ChatProfile from './ChatProfile';
import ImageElement from '../../../components/UIElements/ImageElement';
import { Modal, Spin } from 'antd';

import {
  getChatRoomDetailsByIdService,
  getChatRoomMessagesByRoomIdService,
  postChatSendMediaByIdService,
  downloadChatRoomMediaByIdService,
} from '../../../services/Chat/index.service';
import { acceptChatMediaFiles, modalNotification } from '../../../utils';
import { getMentalHealthCount } from '../../../services/index.service';
import { chatCountNotification } from '../../../redux/index.redux';
import { useDispatch } from 'react-redux';

const PER_PAGE_MESSAGE = 15;

const ChatMessageBox = (props) => {
  const { t } = useTranslation();
  const {
    isMobileViewOpen,
    selectUser,
    fileList,
    setFileList,
    setIsUploadItems,
    hasMoreFetch,
    setHasMoreFetch,
    messageCount,
    setMessageCount,
    currentMessagePage,
    setCurrentMessagePage,
    isOpenChatProfileDrawer,
    setIsChatLoading,
    userDetails,
    handleUpdateChatList,
  } = useContext(ChatContext);
  const { newMessages, socket } = props;
  const [selectUserDetails, setSelectUserDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isMessageListLoading, setMessageListLoading] = useState(false);
  const [roomMessages, setRoomMessages] = useState([]);
  const [message, setMessage] = useState('');
  const timelineRef = useRef();
  const messagesEndRef = useRef(null);
  const [isOpenViewMediaModal, setIsOpenViewMediaModal] = useState(false);
  const [isOpenImageMediaModal, setIsOpenImageMediaModal] = useState(false);
  const [selectedViewMedia, setSelectedViewMedia] = useState(null);
  const [isFetchMoreLoading, setIsFetchMoreLoading] = useState(false);
  const dispatch = useDispatch();

  const resetSelectedViewMedia = () => setSelectedViewMedia(null);

  const getChatRoomDetails = async () => {
    setIsLoading(true);
    await Promise.all([getProfileDetails(), getRoomMessagesList()]);
    setIsLoading(false);
  };

  const getProfileDetails = async () => {
    const response = await getChatRoomDetailsByIdService({
      roomId: selectUser?.chatRoom?.id,
    });
    if (response?.success) {
      setSelectUserDetails(response?.data[0]);
      setMessageCount(response?.data[0]?.messages?.length);
      let roomData = [];
      roomData = response?.data[0]?.members?.map((member) => ({
        userId: member?.memberUserId,
        roomId: member?.roomId,
      }));
      socket.emit('join_chat_room', roomData);
    }
  };

  const getRoomMessagesList = useCallback(async () => {
    if (roomMessages?.length) {
      setRoomMessages([]);
    }
    let queryParams = {
      id: selectUser?.chatRoom.id,
      offset: currentMessagePage * PER_PAGE_MESSAGE,
      limit: PER_PAGE_MESSAGE,
    };
    const { success, data } = await getChatRoomMessagesByRoomIdService(
      selectUser?.chatRoom?.id,
      queryParams
    );
    if (success) {
      setMessageCount(data?.count);
      const messageData = generateItems(
        data?.rows?.sort(
          (a, b) => new Date(a?.createdAt) - new Date(b?.createdAt)
        )
      );
      setRoomMessages(messageData);
      setHasMoreFetch(data?.count < PER_PAGE_MESSAGE ? false : true);
      setCurrentMessagePage(currentMessagePage + 1);
    } else {
      modalNotification({ type: 'error', message: 'Something is wrong' });
    }
  }, [selectUser]);

  const fetchMoreRoomMessageList = async () => {
    setIsFetchMoreLoading(true);
    let queryParams = {
      id: selectUser?.chatRoom.id,
      offset: currentMessagePage * PER_PAGE_MESSAGE,
      limit: PER_PAGE_MESSAGE,
    };
    const { success, data } = await getChatRoomMessagesByRoomIdService(
      selectUser?.chatRoom?.id,
      queryParams
    );
    if (success) {
      let newMessages = [
        ...data?.rows,
        ...roomMessages?.filter((roomMessage) => !roomMessage?.type),
      ];
      setRoomMessages(generateItems(newMessages));
      setCurrentMessagePage(currentMessagePage + 1);
      if (newMessages?.length === messageCount) {
        setHasMoreFetch(false);
      }
    } else {
      modalNotification({ type: 'error', message: 'Something is wrong' });
    }
    setIsFetchMoreLoading(false);
  };

  const groupedDays = (messages) => {
    return messages?.reduce((acc, el) => {
      const messageDay = moment(el?.createdAt).format('YYYY-MM-DD');
      if (acc[messageDay]) {
        return { ...acc, [messageDay]: acc[messageDay].concat([el]) };
      }
      return { ...acc, [messageDay]: [el] };
    }, {});
  };

  const generateItems = (messages) => {
    const days = groupedDays(messages);
    const sortedDays = Object.keys(days).sort(
      (x, y) => moment(y, 'YYYY-MM-DD').unix() - moment(x, 'YYYY-MM-DD').unix()
    );
    const items = sortedDays?.reduce((acc, date) => {
      const sortedMessages = days[date].sort(
        (x, y) => new Date(y.createdAt) - new Date(x.createdAt)
      );
      return acc.concat([...sortedMessages, { type: 'day', date, id: date }]);
    }, []);

    return items;
  };

  const handleSubmitMediaFiles = async (queryParams) => {
    const response = await postChatSendMediaByIdService(
      selectUser?.chatRoom?.id,
      queryParams
    );
    if (response?.success) {
      return response?.data;
    } else {
      return response;
    }
  };

  // const updateSidebarMsgDetails = (roomId, message) => {
  //   const lastMessageEl = document.getElementById(`last-message-${roomId}`);
  //   const lastMessageTimeEl = document.getElementById(
  //     `last-message-time-${roomId}`
  //   );
  //   if (lastMessageEl && lastMessageTimeEl) {
  //     lastMessageEl.textContent = message;
  //     lastMessageTimeEl.textContent = moment(
  //       new Date().toISOString()
  //     ).fromNow();
  //   }
  // };

  const onSubmitMessage = async () => {
    if (
      message?.trim()?.length ||
      (fileList?.length > 0 &&
        fileList?.some?.((fileItem) => fileItem?.status === 'done'))
    ) {
      let data = roomMessages?.filter?.((message) => !message?.type);
      if (fileList?.length) {
        const isValidFile = acceptChatMediaFiles?.includes(
          fileList[0]?.name?.split('.')?.pop()
        );
        if (!isValidFile) {
          modalNotification({
            type: 'error',
            message: t('validation.chat.invalidFileError'),
          });
          return;
        }
      }
      setMessageListLoading(true);
      let messageBody = {
        roomId: selectUser?.chatRoom?.id,
        fromId: userDetails?.id,
        message,
        type: '',
      };
      if (message) {
        messageBody.type = 'text';
        socket.emit('send_message', messageBody);
        data = [
          ...data,
          {
            createdAt: new Date().toISOString(),
            message,
            fromId: userDetails?.id,
          },
        ];
        handleUpdateChatList(messageBody?.roomId, messageBody?.message);
        setRoomMessages(generateItems(data));
        handleScrollToBottom();
        setMessage('');
      }
      if (fileList?.length > 0) {
        let promise = [];
        fileList?.forEach((file) => {
          let queryParams = {
            id: selectUser?.chatRoom?.id,
            mediaPath: file?.response?.data?.basePath,
            mediaType: file?.response?.data?.mediaType,
            message: file?.response?.data?.name,
          };
          promise.push(handleSubmitMediaFiles(queryParams));
        });
        const promiseResult = await Promise.all(promise);
        if (promiseResult) {
          promiseResult.forEach((promiseItem) => {
            messageBody.type = 'media';
            messageBody.mediaType = promiseItem?.mediaType;
            messageBody.mediaImageUrl = promiseItem?.mediaImageUrl;
            messageBody.message = promiseItem?.message;
            socket.emit('send_message', messageBody);
            handleUpdateChatList(messageBody?.roomId, 'Media');
          });
          setRoomMessages(generateItems([...data, ...promiseResult]));
          setFileList([]);
          setIsUploadItems(false);
          handleScrollToBottom();
        }
      }
      setMessageListLoading(false);
    }
  };

  const handleDownloadMedia = async (mediaId, fileName) => {
    setIsChatLoading(true);
    try {
      const res = await downloadChatRoomMediaByIdService(mediaId);
      if (res && res?.success) {
        setSelectedViewMedia(res?.data);
        if (fileName?.split('.')[1] === 'mp4') {
          setIsOpenViewMediaModal(true);
        } else {
          setIsOpenImageMediaModal(true);
        }
      }
    } catch (error) {
      modalNotification({ type: 'error', message: error.message });
    }
    setIsChatLoading(false);
  };

  const handleOpenVideoModal = (videoUrl) => {
    setSelectedViewMedia(videoUrl);
    setIsOpenViewMediaModal(true);
  };

  const handleScrollToBottom = useCallback(() => {
    if (messagesEndRef && messagesEndRef?.current) {
      const element = messagesEndRef?.current;
      element?.scroll(0, 0);
    }
  }, []);

  const handleChangeEmojiSelector = (e, cursorPosition) => {
    const emoji = e?.native;
    setMessage(
      [
        message?.slice(0, cursorPosition),
        emoji,
        message?.slice(cursorPosition),
      ].join('')
    );
  };

  const handleReceiveMessage = () => {
    if (selectUser && selectUser?.chatRoom?.id === newMessages?.roomId) {
      const msgObj = {
        ...newMessages,
        User: selectUser?.chatRoom?.ChatRoomMembers?.find?.(
          (member) => member?.memberUserId === newMessages?.fromId
        )?.User,
        messageType: newMessages?.type,
        createdAt: new Date().toISOString(),
      };
      if (newMessages?.type) {
        delete msgObj?.type;
      }
      let data = [...roomMessages?.filter((message) => !message?.type), msgObj];
      setRoomMessages(generateItems(data));
    }
  };

  const handleAddNewMessage = (message) => {
    setRoomMessages([message, ...roomMessages]);
  };
  const mentalHealthCount = async (query) => {
    try {
      let queryParams = {
        helpProviders: query?.join(','),
      };
      let res = await getMentalHealthCount({ queryParams });
      if (res?.success === true) {
        dispatch(chatCountNotification(res?.data?.unreadMessage));
        return res?.data?.help_provider?.length
          ? res?.data?.help_provider
          : [0];
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let query = await mentalHealthCount();

        if (selectUser) {
          if (message) {
            setMessage('');
          }

          await getChatRoomDetails();
          handleScrollToBottom();
          await mentalHealthCount(query);
        }
      } catch (error) {
        // Handle errors if necessary
        console.error('Error in useEffect:', error);
      }
    };

    fetchData();
  }, [selectUser]);

  useEffect(() => {
    if (Object.keys(newMessages)?.length) {
      handleReceiveMessage();
    }
  }, [newMessages]);

  // if (!selectUser) {
  //   return (
  //     <div className="nk-chat-body">
  //       <div className="noChatData d-flex flex-column align-items-center justify-content-center w-100 h-100">
  //         <ImageElement source="chat-img.svg" alt="chat" />
  //         <h4>{t('text.chat.chatBoxNoContentHeading')}</h4>
  //         <p>{t('text.chat.chatBoxNoContentPara')}</p>
  //       </div>
  //     </div>
  //   );
  // }
  return (
    <>
      <div
        className={`nk-chat-body ${isMobileViewOpen ? 'show-chat' : ''} ${
          isOpenChatProfileDrawer ? 'profile-shown' : ''
        }`}
      >
        {isLoading ? (
          <div className="noChatData d-flex flex-column align-items-center justify-content-center w-100 h-100">
            <Spin />
          </div>
        ) : !Object.keys(selectUserDetails)?.length && !roomMessages?.length ? (
          <div className="nk-chat-body">
            <div className="noChatData d-flex flex-column align-items-center justify-content-center w-100 h-100">
              <ImageElement source="chat-img.svg" alt="chat" />
              <h4>{t('text.chat.chatBoxNoContentHeading')}</h4>
              <p>{t('text.chat.chatBoxNoContentPara')}</p>
            </div>
          </div>
        ) : (
          <>
            <ChatMessageHeader selectUserDetails={selectUserDetails} />
            <ChatMessageList
              messagesLength={currentMessagePage * PER_PAGE_MESSAGE}
              fetchMoreRoomMessageList={fetchMoreRoomMessageList}
              innerRef={timelineRef}
              hasMoreFetch={hasMoreFetch}
              handleDownloadMedia={handleDownloadMedia}
              ref={messagesEndRef}
              messages={roomMessages}
              handleScrollToBottom={handleScrollToBottom}
              isMessageListLoading={isMessageListLoading}
              isOpenViewMediaModal={isOpenImageMediaModal}
              setIsOpenViewMediaModal={setIsOpenImageMediaModal}
              selectedViewMedia={selectedViewMedia}
              resetSelectedViewMedia={resetSelectedViewMedia}
              handleOpenVideoModal={handleOpenVideoModal}
              isFetchMoreLoading={isFetchMoreLoading}
              selectUserDetails={selectUserDetails}
              newMessages={newMessages}
              socket={socket}
            />
            <ChatMessageFooter
              message={message}
              setMessage={setMessage}
              onSubmitMessage={onSubmitMessage}
              handleChangeEmojiSelector={handleChangeEmojiSelector}
              isDisableTextInput={fileList?.length}
            />
            <ChatProfile
              selectUserDetails={selectUserDetails}
              getProfileDetails={getProfileDetails}
              setRoomMessages={setRoomMessages}
              setSelectUserDetails={setSelectUserDetails}
              socket={socket}
              handleAddNewMessage={handleAddNewMessage}
            />
          </>
        )}
      </div>
      {isOpenViewMediaModal && (
        <Modal
          bodyStyle={{ padding: 0 }}
          open={isOpenViewMediaModal}
          footer={null}
          afterClose={() => setIsOpenViewMediaModal(false)}
          closable={true}
          keyboard={true}
          destroyOnClose={true}
          onCancel={() => setIsOpenViewMediaModal(false)}
          className="videoModal modal-lg"
        >
          <video
            src={selectedViewMedia}
            width="100%"
            height="100%"
            autoPlay
            controls
          ></video>
        </Modal>
      )}
    </>
  );
};

export default ChatMessageBox;
