import React from 'react';

import { BookingLogView } from '../../../features/index.feature';

import { accessRoute } from '../../../routes/ServiceManagement';
import { accessRoute as commonAccessRoutes } from '../../../routes/CommonRoutes';
import { servicemanagementBookingLogBreadcrumb } from '../../../services/Columns';
import { getBookingLogDetailsService } from '../../../services/index.service';
import { generatePath, useParams } from 'react-router-dom';

function BookingView() {
  const breadcrumb = servicemanagementBookingLogBreadcrumb();
  const { id, viewQuote } = useParams();

  const invoiceUrl = generatePath(commonAccessRoutes.INVOICE.path, {
    viewInvoice: 'quote',
    routePath: 'service-management',
    id: id,
  });

  return (
    <>
      <BookingLogView
        apiService={getBookingLogDetailsService}
        bookingNumberKey={'BookingNumber'}
        backPageUrl={accessRoute.LIST_SERVICE.path}
        breadcrumb={breadcrumb}
        viewQuoteButton={viewQuote === 'isViewQuote'}
        inVoiceUrl={invoiceUrl}
      />
    </>
  );
}

export default BookingView;
