import i18next from 'i18next';
import {
  getInputSearchFilter,
  getMultiSelectSearchFilter,
} from '../../components';
import {
  checkValidData,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { accessRoute } from '../../routes/Dashboard';
import { getTupleStatusList } from '../index.service';

export const stateTableColumn = ({
  onFilterChange,
  filter,
  Action,
  Toggle,
  onSort,
  page,
  sizePerPage,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'name',
      filterDataIndex: ['stateName'],
      title: i18next.t('text.localization.state'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('name', order),
      exportFormatter: (col, row) => checkValidData(row?.name),
      render: (col, row) => checkValidData(row?.name),
      ...getInputSearchFilter({
        dataIndex: 'stateName',
        placeholder: 'state name',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'countryName',
      title: i18next.t('text.localization.country'),
      headerClasses: 'sorting',
      sorter: true,
      exportKey: 'Country',
      exportFormatter: (col, row) => checkValidData(row?.Country?.name),
      render: (col, row) => checkValidData(row?.Country?.name),
      onSort: (field, order) => onSort('countryName', order),
      ...getInputSearchFilter({
        dataIndex: 'countryName',
        placeholder: 'country name',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'stateCode',
      title: i18next.t('text.localization.stateCode'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('stateCode', order),
      render: (col, row) => checkValidData(row?.stateCode),
      ...getInputSearchFilter({
        dataIndex: 'stateCode',
        placeholder: 'state code',
        onFilterChange,
        filter,
      }),
    },

    {
      dataIndex: 'status',
      title: i18next.t('text.localization.status'),
      headerClasses: 'sorting',
      render: (cell, row) => <Toggle row={row} cell={cell} />,
      sorter: true,
      onSort: (field, order) => onSort('status', order),
      ...getMultiSelectSearchFilter({
        dataIndex: 'status',
        onFilterChange,
        filter,
        name: 'status',
        listService: getTupleStatusList,
        mode: false,
      }),
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.localization.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => {
        return <Action row={row} cell={cell} />;
      },
    },
  ];
};

export const stateBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.localization.state'),
    },
  ];
};
