import { useEffect, useState } from 'react';
import {
  useLocation,
  generatePath,
  useParams,
  useNavigate,
} from 'react-router-dom';
import {
  getCompletePathList,
  getDefaultAuthRoute,
  getDefaultPublicRoute,
} from '../../routes/index.routes';
import {
  FireAlert,
  AuthDriver as authDriver,
  getLocalStorageToken,
  modalNotification,
  userRoleCheck,
} from '../../utils';
import { useSelector } from 'react-redux';
import { PermissionContext } from '../../context/permission.context';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/slices/auth/authSlice';
import FullPageLoader from '../../components/UIElements/Loader/fullPageLoader';
import { t } from 'i18next';
import { accessRoute } from '../../routes/LessonList';
import { skipLesson } from '../../services/index.service';
import { loadPermission } from '../../redux/index.redux';

function AppLayout({ children, isLoaded }) {
  // true for skip
  // const [lessonStatus, setLessonStatus] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  // Remaining
  const user = useSelector((state) => state?.auth?.userData);
  const adminToken = getLocalStorageToken();

  const userPermission =
    useSelector((state) => state?.permission.permission) || {};
  const testPermission = useSelector((state) => state?.permission);

  const activeRoute = getCompletePathList().find((e) => {
    const routePath = getGeneratedPath(e.path);
    const browserPath = window.location.pathname;
    return routePath === browserPath;
  });

  function getGeneratedPath(path) {
    try {
      return generatePath(path, params);
    } catch (error) {
      return path;
    }
  }
  // for sub admin lesson start
  const onConfirmOrderAlert = async () => {
    const result = await FireAlert({
      title: t('text.common.requiredLessonInfo'),
      text: t('text.common.proceedTest'),
      icon: t('text.common.warning'),
      confirmButtonText: t('text.common.yes'),
      cancelButtonText: t('text.common.skip'),
      showCancelButton: !testPermission?.blockStatus,
      allowOutsideClick: false,
      preConfirm: () => navigate(accessRoute.LESSON_LIST_VIEW.path),
    });
    // return true;
    if (!result?.isConfirmed) {
      const response = await skipLesson();
      const { success } = response;
      if (success) {
        dispatch(loadPermission());
      }
    }
  };
  useEffect(() => {
    if (
      user?.Role?.type === 'subadmin' &&
      location.pathname !== accessRoute.LESSON_LIST_VIEW.path &&
      location.pathname.slice(0, 30) !== accessRoute.LESSON_INFO.path &&
      location.pathname.slice(0, 29) !== accessRoute.LESSON_REF.path &&
      testPermission?.isTestCountShow
    ) {
      onConfirmOrderAlert();
    }
  }, [location?.pathname]);
  // for sub admin lesson end

  // const activeRoute = getCompletePathList().find(e => e.key === location.pathname);
  // const activeRoute = getCompletePathList().find(e => e.key.search(location.pathname.replace(/[0-9]/g, '')) >=0)
  const isPrivate = activeRoute?.private;
  // console.log("user Permission", userPermission);
  const havePermission = userPermission.hasOwnProperty(activeRoute?.moduleKey)
    ? userPermission[activeRoute?.moduleKey]
    : {};

  let canAccess;
  if (activeRoute?.action === 'view') {
    canAccess =
      havePermission?.arr?.includes('view') ||
      havePermission?.arr?.includes('edit');
  } else {
    canAccess = havePermission?.arr?.includes(activeRoute?.action);
  }
  if (activeRoute?.ignorePermission) {
    canAccess = true;
  }
  const isValid = authDriver(
    activeRoute,
    userRoleCheck(user?.Role),
    location.pathname.split('/')?.[1],
    true
  );

  if (!isLoaded && isPrivate === true) {
    if (!isValid) {
      if (isPrivate === true) {
        /********* If route type is private but not*****************
         * ******* able to prove their identity as valid entity*****
         * ********* so redirect it to public route******** */
        window.location = getDefaultPublicRoute('admin');
      } else if (isPrivate === false) {
        /********* If route type is public but not*****************
         * ******* able to prove their identity as valid entity*****
         * ********* so redirect it to private route******** */
        window.location = getDefaultAuthRoute('admin');
      }
    } else if (
      user?.Role?.type &&
      user?.Role?.type !== 'admin' &&
      user?.Role?.type !== 'subadmin' &&
      user?.Role?.type !== 'nca' &&
      isPrivate
    ) {
      // console.log('Customer-------', user?.Role?.type, user);

      window.location = getDefaultAuthRoute(
        userRoleCheck(user?.Role) || 'customer',
        // user?.token
        adminToken
      );
    } else {
      // console.log('Not Admin-------', user?.Role?.type);
      if (canAccess && user?.Role?.type !== 'admin') {
        if (
          window.location.pathname === '/admin/nca' &&
          user?.Role?.type === 'nca'
        ) {
          window.location = '/admin/dashboard';
          return;
        } else {
          return (
            <PermissionContext.Provider value={havePermission}>
              {children}
            </PermissionContext.Provider>
          );
        }
      } else {
        if (!user?.Role?.type) {
          /*****Initiate logout**** */
          dispatch(logout());
          modalNotification({
            type: 'success',
            message: 'User Role is Invalid',
          });
          window.location = getDefaultPublicRoute('admin');
          return false;
        }
      }
      if (user?.Role?.type === 'admin') {
        return children;
      }
      if (user?.ncaSwitch) {
        // delete user?.ncaSwitch;
        // dispatch(login({...user}))
        window.location = '/admin/dashboard';
        return true;
      }
      window.location = '/page-not-found';
    }
  }
  return <FullPageLoader isSideBar={true} />;
}

export default AppLayout;
