/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Pagination from '../Pagination';
import { Table } from 'antd';
import CommonButton from '../Button/Common/index.btn';
import AntTooltip from '../Tooltip';
import { getActions } from '../../../services/index.service';
import { useSelector } from 'react-redux';
import { PermissionContext } from '../../../context/permission.context';

function TablePlus(props) {
  const {
    pagination = true,
    hasLimit,
    handleLimitChange,
    noOfPage,
    sizePerPage,
    page,
    count,
    tableData,
    tableColumns,
    param,
    handleActionSelect,
    handleSelectedRows,
    resetAll = true,
    header = true,
    showAction = true,
    tableLoader = false,
    disabledAction = [],
    isCard = true,
    selectRow = false,
    statusOptDisabled = true,
    showPageSizeAction = true,
    // setFilteredValue,
    // filteredValue,
    onFilterResetChange,
    statusAction,
    statusActionOption,
    scrollTopPosition = false,
    searchPlaceholder = '',
    searchField = false,
    isModalPadding = false,
    isModalShadow = false,
    showSorterTooltip = true,
    locale = {},
    actions = [
      { label: 'Make Active', id: 'active' },
      { label: 'Make Inactive', id: 'inactive' },
      { label: 'Make Delete', id: 'delete' },
    ],
    tableTitle = false,
    rowClassName = '',
    disableSelectRowCheckboxKeys,
    sortBy,
    sortType,
    filter,
    useTableHook = true,
    isHeaderFix = false,
    defaultKey
  } = props;

  const query = new URLSearchParams(useLocation()?.search);
  const displayPerPage = useSelector((state) => state?.auth?.displayPerPage);
  const [limitOption, setLimitOption] = useState([
    10,
    25,
    50,
    100,
    parseFloat(displayPerPage),
  ]);

  const [search, setSearch] = useState(query.get('search') || '');
  const [firstTimeFetch, setFirstTimeFetch] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selected, setSelected] = useState([]);
  const [actionValue, setActionValue] = useState('');
  const action = useContext(PermissionContext);
  const userType = useSelector((state) => state?.auth?.userData?.Role?.type);
  const isUserCanEdit =
    (action && action?.arr?.includes(getActions('CREATE').value)) ||
    userType === 'admin';

  // const actions = [
  //   { label: 'Make Active', id: 'active' },
  //   { label: 'Make Inactive', id: 'inactive' },
  //   { label: 'Make Delete', id: 'delete' },
  // ];

  const SORTER = {
    desc: 'descend',
    asc: 'ascend',
  };

  // let divScroll = document.getElementById('scrollView');
  // let table = document.querySelector('.ant-table table');
  // let scrollWraper = document.getElementById('scrollWraper');
  // let tableWraper = document.querySelector('.ant-table-content');

  // setTimeout(() => {
  //   if (divScroll) {
  //     divScroll.style.width = table.scrollWidth + 'px';
  //   }
  // }, 500);

  // if (scrollWraper && tableWraper) {
  //   scrollWraper.onscroll = function () {
  //     tableWraper.scrollLeft = scrollWraper.scrollLeft;
  //   };
  //   tableWraper.onscroll = function () {
  //     scrollWraper.scrollLeft = tableWraper.scrollLeft;
  //   };
  // }
  let divScroll = document.getElementById('scrollView');
  let table = isHeaderFix
    ? document.querySelector('.ant-table .ant-table-body table')
    : document.querySelector('.ant-table table');

  let scrollWraper = document.getElementById('scrollWraper');
  let tableWraper = isHeaderFix
    ? document.querySelector('.ant-table-body')
    : document.querySelector('.ant-table-content');
  const isFirefox = typeof InstallTrigger !== 'undefined';
  let extraSpace = isFirefox ? 20 : 6;

  if (divScroll && table?.scrollWidth) {
    setTimeout(() => {
      if (divScroll) {
        divScroll.style.width = isHeaderFix
          ? table?.scrollWidth + extraSpace + 'px'
          : table.scrollWidth + 'px';
      }
    }, 500);
  }
  if (scrollWraper && tableWraper) {
    scrollWraper.onscroll = function () {
      tableWraper.scrollLeft = scrollWraper.scrollLeft;
    };
    tableWraper.onscroll = function () {
      scrollWraper.scrollLeft = tableWraper.scrollLeft;
    };
  }

  useEffect(() => {
    const param = searchParams.get('page');
  

    if (param ) {
      searchParams.delete('page');     
      setSearchParams(searchParams);
    }    
    
  }, [search]);

  useEffect(()=>{
    const sortByParam = searchParams.get('sortyBy'); 
    const sortTypeParam = searchParams.get('sortType'); 

    if (sortByParam ) {
      searchParams.delete('sortBy');     
      setSearchParams(searchParams);
    }
    if (sortTypeParam) {
      searchParams.delete('sortType');     
      setSearchParams(searchParams);
    }
  },[defaultKey])

  const goToPage = (pageNo) => {
    const newParams = { ...param };
    if (pageNo) {
      newParams.page = pageNo;
      searchParams.set('page', pageNo);
    }
    setSearchParams(searchParams);
  };

  const handleSelect = (e) => {
    searchParams.set('page', 1);
    searchParams.set('pageSize', e.target.value);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      if ((search.length >= 3 || search.length === 0) && firstTimeFetch) {
        searchParams.set('search', search);
        setSearchParams(searchParams);
      }
    }, 700);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    setFirstTimeFetch(true);
  }, []);

  const handleSearchValue = (val) => {
    setSearch(val);
  };

  function onTableCheckboxChange(e) {
    // console.log("E select 1")
    try {
      handleSelectedRows(e);
      setActionValue('-');
    } catch (err) {
      console.log(err);
    }
  }

  async function onTableActionChange(e) {
    // console.log("E select 2")
    try {
      e.preventDefault();
      if (e?.target?.value === '') {
        return true;
      }
      await handleActionSelect(e);
      setActionValue('-');
      setSelected([]);
    } catch (err) {
      console.log(err);
    }
  }

  const onTableSelectChange = (rows) => {
    // console.log("E select 3")
    try {
      setSelected([...rows]);
      onTableCheckboxChange(rows);
    } catch (err) {
      console.log(err);
    }
  };

  const disableRowCheckboxByCondition = (record) => {
    let isDisable = false;
    typeof disableSelectRowCheckboxKeys === 'object' &&
      Object.keys(disableSelectRowCheckboxKeys).forEach((key) => {
        if (record?.[key] === disableSelectRowCheckboxKeys?.[key]) {
          isDisable = true;
        }
      });
    return isDisable;
  };

  const rowSelection = {
    mode: 'checkbox',
    clickToSelect: false,
    onChange: onTableSelectChange,
    selectedRowKeys: selected,
    getCheckboxProps: (record) => {
      return {
        disabled:
          record.isAssigned === 1
            ? true
            : !isUserCanEdit
            ? true
            : disableSelectRowCheckboxKeys
            ? disableRowCheckboxByCondition(record)
            : false,
      };
    },
    // onSelectAll: handleOnSelectAll,
  };

  const handleChange = (pagination, filters, sorter) => {
    if (sorter.hasOwnProperty('column')) {
      if (filters && Object.keys(filters)) {
        goToPage(1);
      }
      if (sorter?.order) {
        searchParams.set('sortBy', sorter?.column?.sortType || sorter?.field);
        searchParams.set('sortType', sorter?.order);

        setSearchParams(searchParams);
        return true;
      }
      searchParams.delete('sortBy', sorter?.field);
      searchParams.delete('sortType', sorter?.order);
      setSearchParams(searchParams);
    }
  };

  const handleInput = () => {
    // console.log("E select")
    searchParams.delete('page');
    searchParams.delete('sortBy');
    searchParams.delete('sortType');
    searchParams.delete('search');
    searchParams.delete('pageSize');
    setSearchParams(searchParams);
  };

  function onResetAll() {
    handleInput();
    onFilterResetChange();
    setSelected([]);
    if (handleSelectedRows) handleSelectedRows([]);
  }
  // for table filter and sorting
  function handleColumData(column) {
    try {
      if (filter?.filterColumn) {
        filter.multiFilterIndex = new Set([
          ...(filter?.multiFilterIndex || []),
          filter.filterColumn,
        ]);
      }
      let customColumn = column.map((e) => ({
        ...e,
        ...(e.hasOwnProperty('sorter') &&
          e?.sorter === true && {
            sortOrder:
              sortBy === (e?.sortType ? e?.sortType : e?.dataIndex)
                ? SORTER[sortType]
                  ? SORTER[sortType]
                  : Object.keys(SORTER).find((key) => SORTER[key] === sortType)
                : null,
          }),
        ...(e.hasOwnProperty('filterIcon') && {
          filteredValue:
            filter?.multiFilterIndex && e?.filterMultiple
              ? [...(filter?.multiFilterIndex || [])]?.includes(
                  e?.filterDataIndex?.[0]
                )
                ? [...filter?.multiFilterIndex]
                : null
              : filter &&
                Object.keys(filter).length > 0 &&
                e?.filterDataIndex &&
                filter.hasOwnProperty(...e?.filterDataIndex)
              ? Array.isArray(e?.filterDataIndex)
                ? e?.filterDataIndex?.map((value) =>
                    filter[value] !== null && filter[value] !== ''
                      ? filter?.[value]
                      : null
                  )?.[0]
                : null
              : typeof filter?.[e?.dataIndex] === 'number'
              ? filter?.[e?.dataIndex]?.toString()
              : filter?.[e?.dataIndex] || null,
        }),
      }));
      return customColumn;
    } catch (error) {
      console.log({ error });
    }
  }

  useEffect(() => {
    if (!pagination) {
      searchParams.set('pageSize', count || 100000);
      searchParams.set('page', 1);
      setSearchParams(searchParams);
    }
  }, [search]);

  return (
    <div className="nk-block">
      <div
        className={`${isCard ? 'card' : ''} position-static ${
          isModalShadow ? 'shadow-none' : ''
        }`}
      >
        <div className={`card-inner ${isModalPadding ? 'p-0' : ''}`}>
          {tableTitle && <h5 className="title mb-4">{tableTitle}</h5>}
          <div className="common-table">
            <div className="dataTables_wrapper dt-bootstrap4 no-footer">
              {header && (
                <Row className="justify-between g-2 mb-3">
                  <Col sm="4" md={6} className="text-start">
                    <div
                      id="DataTables_Table_0_filter"
                      className="dataTables_filter"
                    >
                      {searchPlaceholder ? (
                        <AntTooltip
                          placement="topLeft"
                          promptText={`Search by ${searchPlaceholder}`}
                        >
                          <label>
                            <input
                              type="search"
                              className="form-control form-control-md"
                              placeholder="Type 3 or more characters"
                              aria-controls="DataTables_Table_0"
                              onChange={(e) =>
                                handleSearchValue(e.target.value)
                              }
                              value={search}
                            />
                          </label>
                        </AntTooltip>
                      ) : (
                        searchField && (
                          <label>
                            <input
                              type="search"
                              className="form-control form-control-md"
                              placeholder="Search..."
                              aria-controls="DataTables_Table_0"
                              onChange={(e) =>
                                handleSearchValue(e.target.value)
                              }
                              value={search}
                            />
                          </label>
                        )
                      )}
                    </div>
                  </Col>

                  <Col sm="8" md={6} className="text-end">
                    <div className="d-flex justify-content-sm-end filterButtons">
                      {resetAll && (
                        <CommonButton
                          className="btn btn-outline-light bg-white"
                          onClick={onResetAll}
                        >
                          <span>Reset All</span>
                        </CommonButton>
                      )}
                      {selectRow && (
                        <>
                          {showAction && (
                            <div className="datatable-filter">
                              <div
                                className="dataTables_length"
                                id="DataTables_Table_0_length"
                              >
                                <div className="form-control-select">
                                  <select
                                    value={actionValue}
                                    className="custom-select custom-select-sm form-control form-control-sm"
                                    onChange={onTableActionChange}
                                    style={
                                      Array.isArray(selected) &&
                                      selected.length > 0
                                        ? {}
                                        : { pointerEvents: 'none' }
                                    }
                                  >
                                    <option value="">Select Action</option>
                                    {statusOptDisabled && (
                                      <>
                                        {actions
                                          .filter(
                                            (e) =>
                                              !disabledAction.includes(e.id)
                                          )
                                          .map((e) => {
                                            return (
                                              <option key={e.id} value={e.id}>
                                                {e.label}
                                              </option>
                                            );
                                          })}
                                      </>
                                    )}
                                  </select>
                                </div>
                              </div>
                            </div>
                          )}
                          {statusAction && (
                            <div className="datatable-filter status">
                              <div
                                className="dataTables_length"
                                id="DataTables_Table_0_length"
                              >
                                <div className="form-control-select">
                                  <select
                                    className="custom-select custom-select-sm form-control form-control-sm"
                                    onChange={handleSelect}
                                  >
                                    {statusActionOption.map(
                                      (option, optionIdx) => (
                                        <option
                                          key={optionIdx}
                                          value={option.name}
                                        >
                                          {option.name}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                              </div>
                            </div>
                          )}
                          {showPageSizeAction && (
                            <div className="datatable-filter">
                              <div
                                className="dataTables_length"
                                id="DataTables_Table_0_length"
                              >
                                <div className="form-control-select">
                                  <select
                                    value={
                                      query.get('pageSize') ||
                                      displayPerPage ||
                                      '10'
                                    }
                                    name="DataTables_Table_0_length"
                                    aria-controls="DataTables_Table_0"
                                    className="custom-select custom-select-sm form-control form-control-sm"
                                    onChange={handleSelect}
                                  >
                                    {limitOption?.length &&
                                      [...new Set(limitOption)]
                                        .sort(function (a, b) {
                                          return a - b;
                                        })
                                        .map((e) => (
                                          <option value={e}>{e}</option>
                                        ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              )}
              {scrollTopPosition && (
                <div id="scrollWraper" className="topScroll">
                  <div id="scrollView" style={{ height: '10px' }}></div>
                </div>
              )}

              <Table
                rowSelection={
                  selectRow ? showAction && rowSelection : selectRow
                }
                loading={tableLoader}
                pagination={false}
                rowKey="id"
                onChange={handleChange}
                //  pagination={false}
                columns={
                  useTableHook
                    ? handleColumData(tableColumns.filter((e) => !e?.isHidden))
                    : tableColumns.filter((e) => !e?.isHidden)
                }
                // columns={tableColumns.filter((e) => !e?.isHidden)}
                // onCell={e => validateValue(e)}
                dataSource={tableData}
                showSorterTooltip={showSorterTooltip}
                locale={locale}
                rowClassName={rowClassName}
                scroll={isHeaderFix}
              />
              {pagination && (
                <Pagination
                  count={count}
                  page={parseInt(page)}
                  sizePerPage={sizePerPage}
                  noOfPage={noOfPage}
                  goToPage={goToPage}
                  handleLimitChange={handleLimitChange}
                  hasLimit={hasLimit}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TablePlus;
