import i18next from 'i18next';
import { accessRoute } from '../../routes/CustomerApp/home';
import { MyBookingCards, RequestCard } from '../../components/Cards';

export const customerMyBookingBreadcrumb = () => {
  return [
    {
      path: accessRoute.HOME.path,
      name: i18next.t('text.common.home'),
    },
    {
      path: '#',
      name: i18next.t('text.customerMyBooking.title'),
    },
  ];
};

export const onGoingServiceCardData = [
  {
    bookingId: '1458265',
    status: 'Accepted',
    serviceAddress:
      'Vijay Nagar Square, Vijay Nagar, Indore, Madhya Pradesh, India',
  },
];

export const upcomingCardData = [
  {
    bookingId: '1458265',
    status: 'Cancelled',
    title: ' Schedule A Replacement Quote (Heating and Cooling Equipment)',
    Qty: '1d',
    serviceAddress:
      'Vijay Nagar Square, Vijay Nagar, Indore, Madhya Pradesh, India',
    dateAndTime: 'Thu, March 3, 2019 at 8:00 AM– 11:00 AM',
  },
  {
    bookingId: '1458265',
    status: 'Accepted',
    title: ' Schedule A Replacement Quote (Heating and Cooling Equipment)',
    Qty: '1d',
    serviceAddress:
      'Vijay Nagar Square, Vijay Nagar, Indore, Madhya Pradesh, India',
    dateAndTime: 'Thu, March 3, 2019 at 8:00 AM– 11:00 AM',
  },
  {
    bookingId: '1458265',
    status: 'Accepted',
    title: ' Schedule A Replacement Quote (Heating and Cooling Equipment)',
    Qty: '1d',
    serviceAddress:
      'Vijay Nagar Square, Vijay Nagar, Indore, Madhya Pradesh, India',
    dateAndTime: 'Thu, March 3, 2019 at 8:00 AM– 11:00 AM',
  },
  {
    bookingId: '1458265',
    status: 'Accepted',
    title: ' Schedule A Replacement Quote (Heating and Cooling Equipment)',
    Qty: '1d',
    serviceAddress:
      'Vijay Nagar Square, Vijay Nagar, Indore, Madhya Pradesh, India',
    dateAndTime: 'Thu, March 3, 2019 at 8:00 AM– 11:00 AM',
  },
  {
    bookingId: '1458265',
    status: 'Accepted',
    title: ' Schedule A Replacement Quote (Heating and Cooling Equipment)',
    Qty: '1d',
    serviceAddress:
      'Vijay Nagar Square, Vijay Nagar, Indore, Madhya Pradesh, India',
    dateAndTime: 'Thu, March 3, 2019 at 8:00 AM– 11:00 AM',
  },
  {
    bookingId: '1458265',
    status: 'Accepted',
    title: ' Schedule A Replacement Quote (Heating and Cooling Equipment)',
    Qty: '1d',
    serviceAddress:
      'Vijay Nagar Square, Vijay Nagar, Indore, Madhya Pradesh, India',
    dateAndTime: 'Thu, March 3, 2019 at 8:00 AM– 11:00 AM',
  },
];

export const pastCardData = [
  {
    bookingId: '1458265',
    Qty: '1d',
    status: 'Finished',
    title: ' Schedule A Replacement Quote (Heating and Cooling Equipment)',
    serviceAddress:
      'Vijay Nagar Square, Vijay Nagar, Indore, Madhya Pradesh, India',
    dateAndTime: 'Thu, March 3, 2019 at 8:00 AM– 11:00 AM',
    paymentStatus: 'pending',
  },
  {
    bookingId: '1458265',
    Qty: '1d',
    status: 'Finished',
    title: ' Schedule A Replacement Quote (Heating and Cooling Equipment)',
    serviceAddress:
      'Vijay Nagar Square, Vijay Nagar, Indore, Madhya Pradesh, India',
    dateAndTime: 'Thu, March 3, 2019 at 8:00 AM– 11:00 AM',
    paymentStatus: 'Complete',
  },
  {
    bookingId: '1458265',
    Qty: '1d',
    status: 'Finished',
    title: ' Schedule A Replacement Quote (Heating and Cooling Equipment)',
    serviceAddress:
      'Vijay Nagar Square, Vijay Nagar, Indore, Madhya Pradesh, India',
    dateAndTime: 'Thu, March 3, 2019 at 8:00 AM– 11:00 AM',
    paymentStatus: 'Complete',
  },
  {
    bookingId: '1458265',
    Qty: '1d',
    status: 'Finished',
    title: ' Schedule A Replacement Quote (Heating and Cooling Equipment)',
    serviceAddress:
      'Vijay Nagar Square, Vijay Nagar, Indore, Madhya Pradesh, India',
    dateAndTime: 'Thu, March 3, 2019 at 8:00 AM– 11:00 AM',
    paymentStatus: 'Complete',
  },
  {
    bookingId: '1458265',
    Qty: '1d',
    status: 'Finished',
    title: ' Schedule A Replacement Quote (Heating and Cooling Equipment)',
    serviceAddress:
      'Vijay Nagar Square, Vijay Nagar, Indore, Madhya Pradesh, India',
    dateAndTime: 'Thu, March 3, 2019 at 8:00 AM– 11:00 AM',
    paymentStatus: 'Complete',
  },
  {
    bookingId: '1458265',
    Qty: '1d',
    status: 'Finished',
    title: ' Schedule A Replacement Quote (Heating and Cooling Equipment)',
    serviceAddress:
      'Vijay Nagar Square, Vijay Nagar, Indore, Madhya Pradesh, India',
    dateAndTime: 'Thu, March 3, 2019 at 8:00 AM– 11:00 AM',
    paymentStatus: 'Complete',
  },
  {
    bookingId: '1458265',
    Qty: '1d',
    status: 'Finished',
    title: ' Schedule A Replacement Quote (Heating and Cooling Equipment)',
    serviceAddress:
      'Vijay Nagar Square, Vijay Nagar, Indore, Madhya Pradesh, India',
    dateAndTime: 'Thu, March 3, 2019 at 8:00 AM– 11:00 AM',
    paymentStatus: 'Complete',
  },
  {
    bookingId: '1458265',
    Qty: '1d',
    status: 'Finished',
    title: ' Schedule A Replacement Quote (Heating and Cooling Equipment)',
    serviceAddress:
      'Vijay Nagar Square, Vijay Nagar, Indore, Madhya Pradesh, India',
    dateAndTime: 'Thu, March 3, 2019 at 8:00 AM– 11:00 AM',
    paymentStatus: 'Complete',
  },
  {
    bookingId: '1458265',
    Qty: '1d',
    status: 'Finished',
    title: ' Schedule A Replacement Quote (Heating and Cooling Equipment)',
    serviceAddress:
      'Vijay Nagar Square, Vijay Nagar, Indore, Madhya Pradesh, India',
    dateAndTime: 'Thu, March 3, 2019 at 8:00 AM– 11:00 AM',
    paymentStatus: 'Complete',
  },
];
export const requestData = [
  {
    name: 'Steven S. Hurd',
    rating: '4.2',
    amount: '$100000.00',
    dateAndTime: 'Thu, March 3, 2019 at 8:00 AM– 11:00 AM',
    serviceAddress: '3050 Crestview Terrace Marble Falls, TX 78654',
  },
  {
    name: 'Steven S. Hurd',
    rating: '4.2',
    amount: '$100.00',
    dateAndTime: 'Thu, March 3, 2019 at 8:00 AM– 11:00 AM',
    serviceAddress: '3050 Crestview Terrace Marble Falls, TX 78654',
  },
  {
    name: 'Steven S. Hurd',
    rating: '4.2',
    amount: '$100.00',
    dateAndTime: 'Thu, March 3, 2019 at 8:00 AM– 11:00 AM',
    serviceAddress: '3050 Crestview Terrace Marble Falls, TX 78654',
  },
  {
    name: 'Steven S. Hurd',
    rating: '4.2',
    amount: '$100.00',
    dateAndTime: 'Thu, March 3, 2019 at 8:00 AM– 11:00 AM',
    serviceAddress: '3050 Crestview Terrace Marble Falls, TX 78654',
  },
  {
    name: 'Steven S. Hurd',
    rating: '4.2',
    amount: '$100.00',
    dateAndTime: 'Thu, March 3, 2019 at 8:00 AM– 11:00 AM',
    serviceAddress: '3050 Crestview Terrace Marble Falls, TX 78654',
  },
  {
    name: 'Steven S. Hurd',
    rating: '4.2',
    amount: '$100.00',
    dateAndTime: 'Thu, March 3, 2019 at 8:00 AM– 11:00 AM',
    serviceAddress: '3050 Crestview Terrace Marble Falls, TX 78654',
  },
  {
    name: 'Steven S. Hurd',
    rating: '4.2',
    amount: '$100.00',
    dateAndTime: 'Thu, March 3, 2019 at 8:00 AM– 11:00 AM',
    serviceAddress: '3050 Crestview Terrace Marble Falls, TX 78654',
  },
  {
    name: 'Steven S. Hurd',
    rating: '4.2',
    amount: '$100.00',
    dateAndTime: 'Thu, March 3, 2019 at 8:00 AM– 11:00 AM',
    serviceAddress: '3050 Crestview Terrace Marble Falls, TX 78654',
  },
  {
    name: 'Steven S. Hurd',
    rating: '4.2',
    amount: '$100.00',
    dateAndTime: 'Thu, March 3, 2019 at 8:00 AM– 11:00 AM',
    serviceAddress: '3050 Crestview Terrace Marble Falls, TX 78654',
  },
];

export const myBookingTabContent = ({
  defaultKey,
  myBookings,
  isLoading,
  getMyBookingLists,
  loadMore,
}) => {
  return [
    {
      name: i18next.t('text.customerMyBooking.onGoingServices'),
      key: 'ongoing',
      content: (
        <>
          <MyBookingCards
            cardKey={defaultKey}
            myBookings={myBookings}
            isLoading={isLoading}
            loadMore={loadMore}
          />
        </>
      ),
    },
    {
      name: i18next.t('text.customerMyBooking.upcoming'),
      key: 'upcoming',
      content: (
        <>
          <MyBookingCards
            cardKey={defaultKey}
            myBookings={myBookings}
            getMyBookingLists={getMyBookingLists}
            isLoading={isLoading}
          />
        </>
      ),
    },
    {
      name: i18next.t('text.customerMyBooking.past'),
      key: 'past',
      content: (
        <>
          <MyBookingCards
            cardKey={defaultKey}
            myBookings={myBookings}
            isLoading={isLoading}
          />
        </>
      ),
    },
    {
      name: i18next.t('text.customerMyBooking.request'),
      key: 'request',
      content: (
        <>
          <RequestCard data={myBookings} isLoading={isLoading} />
        </>
      ),
    },
  ];
};
