import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
  CancelBookingForm,
  // CommonButton,
  ModalWrapper,
  NoteFooter,
  PageContainerWrapper,
  ScheduleFilterForm,
  // ScheduleFilterForm,
  TablePlus,
} from '../../../components';
import Graph from '../../../features/graph/graph.feature';
import useTable from '../../../hooks/useTable.hook';
import useGraphData from '../../../hooks/graph.hook';
import { Columns, getActions } from '../../../services/index.service';
import { getScheduleListService } from '../../../services/index.service';
import { logger, modalNotification } from '../../../utils';
import {
  getGraphScheduleBooking,
  getSendQuoteService,
  putAdminBookingCancel,
} from '../../../services/ScheduleBooking/scheduleBooking.service';
import GraphService from '../../../features/graph/graph';
// import { totalTimeCount } from '../../../components/UIElements/Formatter';
import moment from 'moment';
import { selectDateData } from '../../../services/Columns';
import { firstLetterCaps } from '../../../utils/text.util';
import { PermissionContext } from '../../../context/permission.context';
import { useSelector } from 'react-redux';

function ListScheduledBooking() {
  const breadcrumb = Columns.scheduleBreadcrumb();
  const [globalFilter, setGlobalFilter] = useState(false);
  const { t } = useTranslation();
  const [isSearch, setIsSearch] = useState(0);

  const [ShowHideGraph, setShowHideGraph] = useState(false);
  // const [totalJobTiming, setJobTiming] = useState({});
  const ShowHideGraphShow = () => {
    setShowHideGraph(!ShowHideGraph);
  };
  const action = useContext(PermissionContext);
  const userType = useSelector((state) => state?.auth?.userData?.Role?.type);

  const isUserCanEdit =
    (action && action?.arr?.includes(getActions('CREATE').value)) ||
    userType === 'admin';

  const [state, setState] = useState({
    loader: null,
    modal: null,
    modalId: null,
    selectedRows: [],
    shouldVisible: false,
    sendQuoteBookingId: null,
    reason: '',
    cancelUser: '',
    isOpenCancelBookingModal: false,
    isLoading: false,
    selectedCancelBookingId: '',
  });

  const [filterValue, setFilter] = useState({
    fromDate: moment().format('YYYY-MM-DD') || '',
    toDate: moment().format('YYYY-MM-DD') || '',
    dateType: selectDateData[0]?.value,
    location: undefined,
  });

  const [values, setValues] = useState({
    startDate: moment().format('YYYY-MM-DD') || '',
    endDate: moment().format('YYYY-MM-DD') || '',
    dateType: selectDateData[0]?.value,
  });

  let extraQuery = {
    ...filterValue,
  };
  delete extraQuery?.dateType;
  delete extraQuery?.startDate;
  delete extraQuery?.endDate;

  // const extraCSVHandle = async (getData) => {
  //   let obj = {};
  //   let arr = [];

  //   getData?.rows?.length &&
  //     getData?.rows?.map((item) => {
  //       obj = {
  //         name: totalTimeCount(item?.jobTime),
  //       };
  //       arr.push(obj);
  //     });

  //   return { 'Job Timing': arr };
  // };

  const [tableHandler, tableProps] = useTable(
    getScheduleListService,
    extraQuery,
    false,
    false,
    false,
    false,
    false,
    false,
    { sortBy: 'bookingScheduleDate' }

    // extraCSVHandle
  );
  const graphType = 'scheduleBooking';
  const [graphData, graphLoader] = useGraphData({
    apiHandler: getGraphScheduleBooking,
    type: graphType,
    filterValue,
    isDependency: isSearch,
    isGraph: ShowHideGraph,
  });
  const {
    data,
    isLoading,
    count,
    page,
    noOfPages,
    sizePerPage,
    filter,
    sortBy,
    sortType,
  } = tableProps;
  const {
    onFilterChange,
    onExport,
    onTableSort,
    onFilterResetChange,
    onRefresh,
  } = tableHandler;
  const { modal, cancelUser, reason } = state;
  const handleShow = (bookingId) => {
    setState({
      ...state,
      selectedCancelBookingId: bookingId,
      isOpenCancelBookingModal: true,
    });
  };
  const columns = Columns.scheduledBookingsTableColumn({
    onSort: onTableSort,
    onFilterChange: onFilterChange,
    viewInVoicePage,
    BookingCancelModal,
    handleShow,
    filter,
    page,
    sizePerPage,
    handleSendQuote,
    sendQuoteBookingId: state.sendQuoteBookingId,
    isUserCanEdit,
  });
  const navigate = useNavigate();
  function viewInVoicePage() {
    navigate('/admin/payment-report/invoice');
  }

  function BookingCancelModal(cancelUser, reason) {
    setState({
      ...state,
      submitText: 'reason',
      modal: 'reason',
      cancelUser,
      reason,
    });
  }

  const bookingCancelHide = () => {
    setState({
      ...state,
      modal: null,
      modalId: null,
    });
  };
  const handleClose = () => {
    setState({
      ...state,
      isOpenCancelBookingModal: false,
      isLoading: false,
    });
  };
  // const BackBtnFilterToggle = () => {
  //   setGlobalFilter(false);
  // };

  const OpenBtnFilter = () => {
    setGlobalFilter(true);
  };

  const headerBtn = {
    graph: {
      name: t('text.report.showHide'),
      handler: () => {
        setState({
          ...state,
          submitText: 'show graph',
        });
        ShowHideGraphShow();
      },
    },
    add: {
      name: (
        <>
          <em className="icon ni ni-filter-fill me-1" /> Filter
        </>
      ),
      handler: () => {
        OpenBtnFilter();
      },
    },
    csvExport: { handler: () => onExport('', columns) },
  };
  if (globalFilter) {
    document.querySelector('body').classList.add('toggle-shown');
  } else {
    document.querySelector('body').classList.remove('toggle-shown');
  }

  window.addEventListener('resize', () => {
    // console.log("Size", window.screen.width);
    const { width } = window.screen;
    if (width === 1200) {
      setState({ ...state, shouldVisible: true });
    }
  });

  const TimeSlotData = [
    {
      name: '05 AM-08 AM',
      value: '05 AM-08 AM',
    },
    {
      name: '08 AM-11 AM',
      value: '08 AM-11 AM',
    },
    {
      name: '11 AM-02 PM',
      value: '11 AM-02 PM',
    },
  ];

  const graphValue = {
    labels: graphData?.labels,
    datasets: [
      {
        label: t('text.common.collected'),
        data: graphData?.MonthlyCollectedAmount,
        backgroundColor: ['#1EE0AC'],
        borderWidth: 0,
        hoverOffset: 4,
      },
      {
        label: t('text.common.uncollected'),
        data: graphData?.remainingMonthlyCollectedAmount,
        backgroundColor: ['#E85347'],
        borderWidth: 0,
        hoverOffset: 4,
      },
    ],
  };

  const reasonText = () => {
    return (
      <>
        {' '}
        <ul className="list list-sm list-checked">
          {cancelUser !== 'declinedRemove' && (
            <li>
              <b>{t('text.scheduleBooking.cancelBy')}:</b>{' '}
              {cancelUser
                ? firstLetterCaps(cancelUser)
                : 'No Cancel User Found'}
            </li>
          )}
          <li>
            <b>{t('text.scheduleBooking.cancelReason')}:</b>{' '}
            {reason ? firstLetterCaps(reason) : 'No Reason Found'}
          </li>
        </ul>
        {/* <div className="text-end">
          <CommonButton
            onClick={() => bookingCancelHide()}
            className="btn btn-secondary"
          >
            {t('text.common.cancel')}
          </CommonButton>
        </div> */}
      </>
    );
  };
  const modalForm = {
    add: <Graph jobData={graphValue} />,
    reason: reasonText(),
  };
  function onCancel() {
    setState({ ...state, modal: null, modalId: null });
  }
  const chartOptionsData = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
      tooltip: {
        mode: 'index',
        position: 'average',
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  const BackBtnFilter = () => {
    setGlobalFilter(false);
  };
  const onSubmitFilter = (value) => {
    try {
      let val = {
        fromDate: value?.startDate || '',
        toDate: value?.endDate || '',
        location: value?.location || undefined,
        slotId: value?.slots,
      };
      delete val?.dateType;
      delete val?.startDate;
      delete val?.endDate;

      setFilter((prevState) => {
        return {
          ...prevState,
          ...val,
        };
      });
      setIsSearch(isSearch + 1);
      onFilterChange(val);
      setGlobalFilter(false);
    } catch (error) {
      logger(error);
    }
  };
  function onReset() {
    if (
      !values?.startDate &&
      !values?.endDate &&
      !values?.location &&
      !values?.toDate &&
      !values?.fromDate &&
      !values?.dateType
    ) {
      return;
    }
    const resetData = {
      fromDate: '',
      toDate: '',
      dateType: '',
      location: undefined,
      slotId: undefined,
    };
    setValues({ ...resetData });
    setFilter({
      ...resetData,
    });
    setIsSearch(isSearch + 1);
    onFilterChange(resetData);
    setGlobalFilter(false);
  }

  async function handleSendQuote(bookingId) {
    setState({
      ...state,
      sendQuoteBookingId: bookingId,
    });
    const response = await getSendQuoteService(bookingId);
    if (response?.success) {
      modalNotification({
        type: 'success',
        message: response?.message,
      });
    }
    setState({
      ...state,
      sendQuoteBookingId: null,
    });
  }

  function onTableResetFilter() {
    const resetData = {
      startDate: moment().format('YYYY-MM-DD') || '',
      endDate: moment().format('YYYY-MM-DD') || '',
      location: undefined,
    };
    setValues({
      ...resetData,
      dateType: 'today',
    });
    setFilter({
      ...filterValue,
      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
    });
    setIsSearch(isSearch + 1);
    onFilterChange(resetData);
    onFilterResetChange();
  }
  useEffect(() => {
    setFilter({
      ...filterValue,
      startDate:
        values?.fromDate || (values?.dateType && moment().format('YYYY-MM-DD')),
      endDate:
        values?.toDate || (values?.dateType && moment().format('YYYY-MM-DD')),
      dateType: values?.dateType,
      slotId: values?.slotId,
      location: values?.location,
    });
  }, [values]);
  // useEffect(() => {
  //   fetchExportData();
  // }, []);

  const handleCancelBooking = async (e) => {
    e.defaultPrevented = true;
    try {
      setState({ ...state, isLoading: true });
      const response = await putAdminBookingCancel({
        bookingId: state?.selectedCancelBookingId,
        declineReason: e?.reason,
      });
      if (response.success) {
        modalNotification({
          type: 'success',
          message: 'Your booking is successfully cancelled.',
        });
        setState({
          ...state,
          isLoading: false,
          isOpenCancelBookingModal: false,
        });
      } else {
        setState({ ...state, isLoading: false });
      }
      onRefresh();
    } catch (error) {
      logger(error);
      setState({ ...state, isLoading: false });
    }
  };

  return (
    <>
      <PageContainerWrapper
        form={modalForm['filter']}
        heading={t('text.scheduleBooking.scheduledBookings')}
        breadcrumb={breadcrumb}
        btn={headerBtn}
        extraClass="btn btn-primary d-block d-xl-none"
      >
        <div className="">
          <div className="globalFilters">
            <div className="card ">
              <div className="card-inner">
                <div
                  className={`globalFilters_inner ${
                    globalFilter ? 'filterToggle' : ''
                  }`}
                >
                  <div className="mb-3 d-block d-xl-none">
                    <Link to="#" onClick={() => BackBtnFilter()}>
                      <em className="icon ni ni-arrow-long-left me-1"></em>
                      {t('text.common.back')}
                    </Link>
                  </div>
                  <ScheduleFilterForm
                    data={true}
                    onSubmit={onSubmitFilter}
                    onReset={onReset}
                    filterValue={filterValue}
                    setValues={setValues}
                    values={values}
                    showSlots={true}
                  />
                  {/* <DashboardFilterForm
                    onSubmit={onSubmitFilter}
                    onReset={onReset}
                    loading={isLoading}
                    filterValue={filterValue}
                    setFilter={setFilter}
                    TimeSlot={TimeSlotData}
                  /> */}
                  {/* <ScheduleFilterForm  data={true}  onSubmit={onSubmit} /> */}
                </div>
                <div
                  className={` ${globalFilter ? 'toggle-overlay' : ''} `}
                  onClick={() => BackBtnFilter()}
                ></div>
              </div>
              {/* <div className="card-inner">
                <div
                // className={`globalFilters_inner ${
                //   // globalFilter ? 'filterToggle' : ''
                // }`}
                >
                  <div className="mb-3 d-block d-xl-none">
                    <em className="icon ni ni-arrow-long-left me-1"></em> Back
                  </div>
                  <ScheduleFilterForm />
                </div>
              </div> */}
            </div>
          </div>
        </div>

        {ShowHideGraph && (
          <GraphService
            type="bar"
            data={graphValue}
            options={chartOptionsData}
            totalData={graphData}
            loading={graphLoader}
          />
        )}
        <TablePlus
          hasLimit
          isSearch={false}
          showAction={false}
          noOfPage={noOfPages}
          sizePerPage={sizePerPage}
          page={page}
          count={count}
          s
          tableData={data}
          tableColumns={columns}
          selectRow
          tableLoader={isLoading}
          onFilterResetChange={onTableResetFilter}
          filter={filter}
          sortBy={sortBy}
          sortType={sortType}
        />
        <NoteFooter title={t('text.common.notes')}>
          <li>{t('text.scheduleBooking.noteBookings')}</li>
          <li>{t('text.scheduleBooking.noteAdministrator')}</li>
          <li>{t('text.scheduleBooking.noteExport')}</li>
        </NoteFooter>

        <ModalWrapper
          heading={t('text.common.graph')}
          onCancel={onCancel}
          content={modalForm['add']}
          // show={['show-graph'].includes(modal)}
        />
        <ModalWrapper
          heading={t('text.scheduleBooking.bookingCancelReason')}
          onCancel={bookingCancelHide}
          content={modalForm['reason']}
          show={['reason'].includes(modal)}
        />
        {state?.isOpenCancelBookingModal && (
          <ModalWrapper
            title={t('text.common.cancelBooking')}
            show={state?.isOpenCancelBookingModal}
            size="lg"
            onCancel={handleClose}
            content={
              <CancelBookingForm
                onCancel={handleClose}
                onSubmit={handleCancelBooking}
                isLoading={state?.isLoading}
              />
            }
          ></ModalWrapper>
        )}
      </PageContainerWrapper>
    </>
  );
}

export default ListScheduledBooking;
