import React from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GlobalLoader } from '../../../components';
import { getGoogleTokenService } from '../../../services/GoogleConnect/googleGMB.service';
import { decodeQueryData } from '../../../utils';

export default function GoogleAuth() {
  const location = useLocation();
  const navigate = useNavigate();
  const params = location?.search && decodeQueryData(location?.search);
  const googleKey = localStorage.getItem('googleAuth');

  const checkGoogleRefreshToken = async () => {
    try {
      let queryParams = params;
      const response = await getGoogleTokenService({ queryParams });
      const { success } = response;
      if (success) {
        navigate(googleKey);
        // window.close();
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    checkGoogleRefreshToken();
  }, [params]);
  return <GlobalLoader />;
}

