import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getCustomerProviderListService } from '../../../services/CustomerApp/index.service';
import { getLocalStorage } from '../../../utils';
import BookAppointmentNow from '../../../features/customerApp/BookAppointmentNow/index.feature';
import CustomerBooking from '../../../features/modalComponent/customerBooking';
import ServiceAddress from '../../../features/serviceAddress/index.feature';
import { CommonButton, ModalWrapper } from '../../../components';
import Breadcrumb from '../../../components/UIElements/Breadcrumb';
import ServiceProviderList from './ServiceProviderList';
import ServiceProviderMap from './ServiceProviderMap';
import { Columns } from '../../../services/index.service';
import { useTranslation } from 'react-i18next';
import { accessRoute as bookingRoutes } from '../../../routes/CustomerApp/booking';
import { accessRoute as customerHomeRoute } from '../../../routes/CustomerApp/home';
import { getAddressService } from '../../../services/Home';
import { useSelector } from 'react-redux';

const PER_PAGE_DATA = 12;

const ScheduleProviderList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const placeId = useSelector((state) => state?.permission?.locationId);
  const [state, setState] = useState({
    providers: [],
    modal: '',
    isLoading: false,
    isLoadingLoadMore: false,
    isMapView: false,
    sortBy: 'all',
    count: 0,
    currentPage: 1,
    addressList: [],
    isBookingAll: false,
  });

  const { modal, isLoading, providers, isMapView } = state;
  const breadcrumb = Columns.customerProviderListBreadcrumb();
  let latLong = getLocalStorage({ keyName: 'latLong' });

  const getAddressList = async () => {
    try {
      const res = await getAddressService({
        scope: 'activeCustomerAddresses',
      });
      if (res?.success) {
        setState((prevState) => ({
          ...prevState,
          addressList: res?.data?.rows,
        }));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getServiceProviderList = async ({ newPage, isFetch = false } = {}) => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: !isFetch ? true : false,
        isLoadingLoadMore: newPage ? true : false,
        providers: !newPage ? [] : prevState.providers,
      }));
      let queryParams = {
        offset:
          ((newPage ? newPage : isFetch ? 1 : state?.currentPage) - 1) *
          PER_PAGE_DATA,
        limit: PER_PAGE_DATA,
        serviceSubcategoryId: location?.state?.id,
        latitudeCoordinate: latLong?.lat,
        longitudeCoordinate: latLong?.long,
        sortBy: state?.sortBy,
      };
      if (location?.state?.dateTime) {
        queryParams.bookingDate = location?.state?.dateTime?.bookingDate;
        queryParams.slotId = location?.state?.dateTime?.slotId;
      }
      const response = await getCustomerProviderListService({
        queryParams,
      });
      if (response?.success) {
        setState((prevState) => ({
          ...prevState,
          providers: newPage
            ? [...prevState.providers, ...(response?.data?.rows ?? [])]
            : response?.data?.rows ?? [],
          count: response?.data?.count ?? 0,
          currentPage: newPage ? newPage : isFetch ? 1 : prevState.currentPage,
          isLoading: false,
          isLoadingLoadMore: false,
        }));
      } else {
        setState({ ...state, isLoading: false, isLoadingLoadMore: false });
      }
    } catch (err) {
      setState({ ...state, isLoading: false, isLoadingLoadMore: false });
    }
  };

  useEffect(() => {
    if (!location.state) {
      navigate(customerHomeRoute.HOME.path);
    }
    if (latLong) {
      getServiceProviderList();
    }
  }, [state?.sortBy]);

  useEffect(() => {
    getAddressList();
    if (latLong) {
      getServiceProviderList();
    }
  }, []);

  const modalObj = {
    customerBookingNow: (
      <CustomerBooking
        isBookingAll={state?.isBookingAll}
        setModal={setState}
        stateModal={state}
        sortBy={state?.sortBy}
      />
    ),
    success: (
      <>
        <div className="paidEmergency">
          <p>{t('text.scheduleServiceProvider.successMessage')}</p>
        </div>
        <div className="d-flex justify-content-flex-end">
          <Link
            to={`${bookingRoutes.BOOKING.path}?tab=upcoming`}
            className="btn btn-primary ms-auto min-200"
          >
            {t('text.common.viewBooking')}
          </Link>
        </div>
      </>
    ),
  };
  const handleModal = () => {
    try {
      setState({ ...state, modal: 'customerBookingNow', isBookingAll: true });
    } catch (err) {
      console.log(err);
    }
  };

  const handleMapView = (status) => {
    try {
      setState({ ...state, isMapView: status });
      getServiceProviderList();
    } catch (err) {
      console.log(err);
    }
  };

  const loadMore =
    !state?.isLoading && state?.count !== state?.providers?.length ? (
      <div
        style={{
          textAlign: 'center',
          marginTop: 12,
          height: 32,
          lineHeight: '32px',
        }}
      >
        <CommonButton
          className="mt-2 mb-5 mx-auto min-100 btn-primary"
          onClick={() =>
            getServiceProviderList({
              newPage: state?.currentPage + 1,
              isFetch: true,
            })
          }
          disabled={state?.isLoadingLoadMore}
          loading={state?.isLoadingLoadMore}
        >
          {t('text.common.loadMore')}
        </CommonButton>
      </div>
    ) : null;

  const handleChangeSortBy = (value) => {
    setState({
      ...state,
      sortBy: value,
    });
  };

  return (
    <>
      <div className="main-content ServiceProviderPage">
        <div className="pageContent">
          <Container className="container-lg">
            <Breadcrumb
              type="customer"
              title={t('text.scheduleServiceProvider.providerListTitle')}
              breadcrumb={breadcrumb}
            />
            <BookAppointmentNow
              handleModal={handleModal}
              handleMapView={handleMapView}
              isMapView={isMapView}
              disabled={providers?.length === 0}
            />
            <ServiceAddress
              providerLIst={true}
              emergencyButton={false}
              scheduleProviderLIst={true}
              sortBy={state?.sortBy}
              handleChangeSortBy={(value) => handleChangeSortBy(value)}
              addressList={state?.addressList}
              selectedAddress={placeId}
            />
            {isMapView ? (
              <ServiceProviderMap
                providersList={providers}
                isLoading={isLoading}
                state={location?.state}
              />
            ) : (
              <ServiceProviderList
                providersList={providers}
                isLoading={isLoading}
                state={location?.state}
                loadMore={loadMore}
              />
            )}
          </Container>
        </div>
      </div>

      <ModalWrapper
        onCancel={() => setState({ ...state, modal: '' })}
        heading={
          modal === 'customerBookingNow'
            ? 'Booking Details'
            : 'Booking Requested'
        }
        show={modal}
        modalExtraClass="bookingDetails"
        extraClassName="modal-lg"
        extraTitleClassName="align-items-start"
        content={modalObj[modal]}
      ></ModalWrapper>
    </>
  );
};

export default ScheduleProviderList;
