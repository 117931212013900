import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import { AddEditLessonForm, PageContainerWrapper } from '../../../components';
import { accessRoute } from '../../../routes/OperatingProcedure';
import { editLessonBreadcrumb } from '../../../services/Columns';
import {
  lessonDetailsService,
  updateDetailsService,
  updateLessonStatus,
} from '../../../services/index.service';
import {
  FireAlert,
  backToPage,
  logger,
  modalNotification,
} from '../../../utils';

function EditProcedureLesson() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const redirectUrl = accessRoute.OPERATING_PROCEDURE.path;
  const breadcrumb = editLessonBreadcrumb();
  const { id } = useParams();
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState(1);
  const [selectedValues, setSelectedValues] = useState();
  const [selectedModuleValues, setSelectedModuleValues] = useState();
  const onSubmit = async (values) => {
    setIsLoading(true);
    try {
      let confirmStatus = true;
      if (
        values?.isRequired === 1 &&
        location?.state?.status === 'active' &&
        location?.state?.questionCount === 0
      ) {
        let isStatusConfirm = await FireAlert({
          title: t('text.common.areYouSure'),
          text: 'We were making lesson as inactive as you are enabling the lesson to required and total count of question in lesson is 0',
          icon: t('text.common.warning'),
          confirmButtonText: t('text.common.yes'),
          cancelButtonText: t('text.common.no'),
          preConfirm: () => handleStatus(),
        });
        confirmStatus = isStatusConfirm?.isConfirmed;
        setIsLoading(false);
      }
      if (confirmStatus) {
        let bodyData = { ...values };
        bodyData.type === 'admin' && delete bodyData.serviceIds;
        bodyData.type === 'provider' && delete bodyData.moduleIds;
        bodyData.isRequired === 0 && delete bodyData.percentage;
        bodyData.isSent =
          bodyData.isRequired === 1 && formData?.status === 'active'
            ? checked
            : 0;
        const response = await updateDetailsService({ id, bodyData });
        const { success, message } = response;
        if (success) {
          modalNotification({
            type: 'success',
            message,
          });
          navigate(redirectUrl);
        }
      }
    } catch (error) {
      logger(error);
    }
    setIsLoading(false);
  };

  async function handleStatus() {
    try {
      const payload = {
        lessonIds: [id],
        status: 'inactive',
      };
      const { success } = await updateLessonStatus(payload);
      if (success) {
        backToPage(navigate, redirectUrl);
      }
      return success ? true : false;
    } catch (err) {
      return false;
    }
  }

  async function getDetails(id) {
    setIsLoading(true);

    try {
      const { data, success } = await lessonDetailsService(id);
      if (success) {
        setFormData(data);
        if (data?.type === 'admin') {
          setSelectedModuleValues(data?.LessonModules);
        } else if (data?.type === 'provider') {
          setSelectedValues(data?.LessonServices);
        }
      }
    } catch (err) {
      logger(err);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (id) getDetails(id);
  }, [id]);
  const handleServiceChange = (value) => {
    if (value?.length === 0) {
      setSelectedValues([]);
    } else {
      setSelectedValues(value);
    }
  };
  const handleMasterModuleChange = (value) => {
    if (value?.length === 0) {
      setSelectedModuleValues([]);
    } else {
      setSelectedModuleValues(value);
    }
  };
  return (
    <div className="wide-md mx-auto">
      <PageContainerWrapper
        breadcrumb={breadcrumb}
        btnArray={['extraButton']}
        extraBtnText={t('text.common.back')}
        extraBtnClass="btn btn-outline-light bg-white"
        onExtraButtonHandleShow={() => backToPage(navigate, redirectUrl)}
        heading={t('text.operating.editLesson')}
      >
        <AddEditLessonForm
          onSubmit={onSubmit}
          loading={isLoading}
          backToPage={() => backToPage(navigate, redirectUrl)}
          formData={formData}
          setFormData={setFormData}
          checked={checked}
          setChecked={setChecked}
          selectedValues={selectedValues}
          selectedModuleValues={selectedModuleValues}
          handleServiceChange={handleServiceChange}
          handleMasterModuleChange={handleMasterModuleChange}
        />
      </PageContainerWrapper>
    </div>
  );
}

export default EditProcedureLesson;
