import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Select, Input, Space, Button } from 'antd';
import {
  getConditionOperatorText,
  getAndOrText,
} from '../../../services/index.service';

const { Option } = Select;

function PriceFilterForm({
  setSelectedKeys,
  fields = {},
  onFilterChange,
  confirm,
  filter,
  dataIndex,
  dataIndexKey,
}) {
  const { t } = useTranslation();
  const ref = useRef(null);

  const onFinish = async (values) => {
    if (dataIndexKey && values?.firstAmount && values?.secondAmount) {
      values[dataIndexKey] = dataIndex;
    }
    try {
      await onFilterChange(values);
      confirm();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (filter) {
      if (Object.keys(filter).length === 0) {
        ref.current.setFieldsValue({
          [fields.fromOperatorField]: 'eq',
          [fields.conditionalField]: 'and',
          [fields.toOperatorField]: 'eq',
          [fields.fromOperatorAmountField]: null,
          [fields.toOperatorAmountField]: null,
        });
      }
    }
  }, [filter]);

  const resetValues = {
    [fields.fromOperatorField]: null,
    [fields.fromOperatorAmountField]: null,
    [fields.conditionalField]: null,
    [fields.toOperatorField]: null,
    [fields.toOperatorAmountField]: null,
  };

  const onReset = async () => {
    try {
      if (dataIndexKey) {
        resetValues[dataIndexKey] = null;
        if (filter?.multiFilterIndex) {
          resetValues.multiFilterIndex = [
            ...(filter?.multiFilterIndex || []),
          ].filter((val) => val !== dataIndex);
        }
      }
      if (ref?.current?.resetFields) {
        await onFilterChange(resetValues);
        ref.current.resetFields();
        setSelectedKeys(undefined);
        confirm();
      }
    } catch (err) {
      console.log(err);
    }
  };

  function onChange(e, b) {
    try {
      setSelectedKeys([e]);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Form
          initialValues={{
            [fields.fromOperatorField]: 'eq',
            [fields.conditionalField]: 'and',
            [fields.toOperatorField]: 'eq',
          }}
          onValuesChange={onChange}
          ref={ref}
          onFinish={onFinish}
        >
          <Form.Item name={fields.fromOperatorField}>
            <Select
              // placeholder={t("text.common.equalTo")}
              style={{
                marginBottom: 8,
                display: 'block',
              }}
            >
              {(getConditionOperatorText(t) || []).map((item, key) => (
                <Option
                  key={key}
                  value={item.value || item.id}
                  disabled={item?.disabled || false}
                >
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={fields.fromOperatorAmountField}>
            <Input
              placeholder={t('text.common.amount')}
              type="number"
              style={{
                marginBottom: 8,
                display: 'block',
              }}
            />
          </Form.Item>
          <Form.Item name={fields.conditionalField}>
            <Select
              style={{
                marginBottom: 8,
                display: 'block',
              }}
            >
              {(getAndOrText(t) || []).map((item, key) => (
                <Option
                  key={key}
                  value={item.value || item.id}
                  disabled={item?.disabled || false}
                >
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={fields.toOperatorField}>
            <Select
              //  placeholder={t("text.common.equalTo")}
              style={{
                marginBottom: 8,
                display: 'block',
              }}
            >
              {(getConditionOperatorText(t) || []).map((item, key) => (
                <Option
                  key={key}
                  value={item.value || item.id}
                  disabled={item?.disabled || false}
                >
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={fields.toOperatorAmountField}>
            <Input
              placeholder={t('text.common.amount')}
              type="number"
              style={{
                marginBottom: 8,
                display: 'block',
              }}
            />
          </Form.Item>
          <Space>
            <Button
              type="primary"
              className="btn btn-primary"
              htmlType="submit"
              // onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{
                width: 90,
              }}
            >
              Filter
            </Button>
            <Button
              onClick={onReset}
              className="btn btn-info"
              style={{
                width: 90,
              }}
            >
              Reset
            </Button>
          </Space>
        </Form>
      </div>
    </>
  );
}

export default PriceFilterForm;
