export const text = {
  title: 'Tags Master',
  addTag: 'Add Tag',
  tagName: 'Tag Name',
  tagType: 'Tag Type',
  selectTagType: 'Select Tag Type',
  areYouSureDeleteTag: 'you want to delete this Tag',
  tag: 'Tag',
  tagNamePlace: 'Enter tag name',
};
