import i18next from 'i18next';
import { accessRoute } from '../../routes/CustomerApp/home';

export const requestViewDetailsBreadcrumb = () => {
  return [
    {
      path: accessRoute.HOME.path,
      name: i18next.t('text.common.home'),
    },
    {
      path: '#',
      name: i18next.t('text.requestViewDetails.requestViewDetails'),
    },
  ];
};

export const requestViewDetailData = [
  {
    name: 'Steven S. Hurd',
    rating: '4.2',
    category: 'QA & Category',
    subCategory: 'X1',
    discount: '$200.00',
    amountOne: '$200.00',
    amountTwo: '$100.00',
    amountThree: '+$25.00',
    subTotal: '$300.00',
    total: '$300.00',
    monthlyPayment: '$300.00',
    note: 'Payment Is Collected Only After Work Is Done. For Easier Payment Processing Please Add Mastercard, Visa, Discover Or American Express Card. You Will Not Be Charged Until Work Is Completed',
    expireDay: '30 Days',
  },
];

