import { Tooltip } from 'antd';
import { t } from 'i18next';
import React from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { firstLetterCaps } from '../../../utils/text.util';
import {
  GlobalLoader,
  ImageElement,
  ModalWrapper,
  StoreAddPromoCode,
  StoreAddQuestion,
  StoreChangePasswordForm,
  StoreEditAddress,
  StoreEditBankDetails,
  StoreEditProfile,
  StoreUpdateProfile,
} from '../../../components';
import StoreUpdateDocument from '../../../components/Forms/Store/UpdateDocumentForm/updateDocument.form';

import useSubmit from '../../../hooks/useSubmit.hook';
import { selectStoreData, updateStoreData } from '../../../redux/index.redux';
import { changePassword } from '../../../services/index.service';

import {
  addDocumentStoreService,
  addPromoCodeStoreService,
  addQuestionStoreService,
  editAddressService,
  editBankService,
  editDocumentStoreService,
  editProfileService,
  getDocumentAllService,
  getProfileService,
  updateProfileService,
} from '../../../services/Store/store.service';
import { accessRoute } from '../../../routes/StoreApp/store.home';
import { useDispatch } from 'react-redux';
import { formatPhoneNumber, openInNewTab } from '../../../utils';
import NoDataFound from '../../../components/UIElements/NoDataFound';

function StoreMyProfile() {
  const userData = useSelector(selectStoreData);
  const dispatch = useDispatch();
  const [profile, setProfile] = useState('');
  const [show, setShow] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [documentModal, setDocumentModal] = useState(false);
  const [promoCodeModal, setPromoCodeModal] = useState(false);
  const [questionModal, setQuestionModal] = useState(false);
  const [profileModal, setProfileModal] = useState(false);
  const [editaddressModal, setEditaddressModal] = useState(false);
  const [editpswModal, setEditpswModal] = useState(false);
  const [bookdetailModal, setBookModal] = useState(false);
  const [document, setDocument] = useState([]);
  const [documentDetails, setDocumentDetails] = useState({});
  const handleClose = () => {
    setShow(false);
    setDocumentModal(false);
    setPromoCodeModal(false);
    setQuestionModal(false);
    setProfileModal(false);
    setEditaddressModal(false);
    setEditpswModal(false);
    setBookModal(false);
  };
  const handleShow = () => setShow(true);
  const handleShowDocument = () => setDocumentModal(true);
  const handleShowPromo = () => setPromoCodeModal(true);
  const handlequestion = () => setQuestionModal(true);
  const handleprofile = () => setProfileModal(true);
  const handleeditaddress = () => setEditaddressModal(true);
  const handleeditpsw = () => setEditpswModal(true);
  const handlebookdetail = () => setBookModal(true);

  const [isSwitchOn, setIsSwitchOn] = useState(false);

  async function getProfile() {
    try {
      const res = await getProfileService(userData?.id);
      if (res?.data) {
        dispatch(updateStoreData(res?.data));
        setProfileData(res?.data);
        getDocument(res?.data?.Store?.countryId);
      } else getDocument(userData?.Store?.countryId);
    } catch (err) {
      console.log(err);
    }
  }

  async function getDocument(id) {
    try {
      const queryParams = {
        documentFor: 'store',
        countryId: id,
        userId: userData?.id,
        scope: 'activeDocument',
      };

      const res = await getDocumentAllService({ queryParams });
      if (res?.data) {
        setDocument(res?.data?.rows);
      }
    } catch (err) {
      console.log(err);
    }
  }
  const [onSubmitUpdateProfile, , isLoadingprofile] = useSubmit({
    service: updateProfileService,
    refresh: getProfile,
    afterSubmitActionType: 'close-modal',
    onModalCancel: handleClose,
  });
  const [onSubmit, , isLoadingedit] = useSubmit({
    service: editProfileService,
    refresh: () => {
      getProfile('callDocument');
    },
    afterSubmitActionType: 'close-modal',
    onModalCancel: handleClose,
    id: userData?.id,
  });
  const [onSubmitAdress, , isLoadingaddress] = useSubmit({
    service: editAddressService,
    refresh: () => {
      getProfile('callDocument');
    },
    afterSubmitActionType: 'close-modal',
    onModalCancel: handleClose,
    id: userData?.id,
  });
  const [onSubmitChangePassword, , isLoading] = useSubmit({
    service: changePassword,
    afterSubmitActionType: 'close-modal',
    refresh: handleClose,
  });
  const [onSubmitBankDetails, , isLoadingBank] = useSubmit({
    service: editBankService,
    refresh: getProfile,
    afterSubmitActionType: 'close-modal',
    onModalCancel: handleClose,
  });
  const [onSubmitQuestion, , isLoadingQuestion] = useSubmit({
    service: addQuestionStoreService,
    refresh: getProfile,
    afterSubmitActionType: 'close-modal',
    onModalCancel: handleClose,
  });
  const [onSubmitPromoCode, , isLoadingPromo] = useSubmit({
    service: addPromoCodeStoreService,
    refresh: getProfile,
    afterSubmitActionType: 'close-modal',
    onModalCancel: handleClose,
  });
  const [onSubmitDocument, , isLoadingDocument] = useSubmit({
    service: addDocumentStoreService,
    refresh: getProfile,
    afterSubmitActionType: 'close-modal',
    onModalCancel: handleClose,
  });
  const [onSubmitEditDocument, , isLoadingEditDocument] = useSubmit({
    service: editDocumentStoreService,
    id: documentDetails?.UserDocument?.id,
    refresh: getProfile,
    afterSubmitActionType: 'close-modal',
    onModalCancel: handleClose,
  });
  useEffect(() => {
    getProfile();
  }, []);
  const modalFormProfile = {
    edit: (
      <StoreEditProfile
        onCancel={handleClose}
        onSubmit={onSubmit}
        profileData={profileData}
        loading={isLoadingedit === 'SUBMIT'}
      />
    ),
  };
  const modalFormEditAddress = {
    edit: (
      <StoreEditAddress
        onCancel={handleClose}
        loading={isLoadingaddress === 'SUBMIT'}
        onSubmit={onSubmitAdress}
        profileData={profileData}
      />
    ),
  };
  const modalFormChangePassword = {
    edit: (
      <StoreChangePasswordForm
        onCancel={handleClose}
        loading={isLoading === 'SUBMIT'}
        onSubmit={onSubmitChangePassword}
      />
    ),
  };
  const modalFormBankDetails = {
    edit: profileData ? (
      <StoreEditBankDetails
        onCancel={handleClose}
        loading={isLoadingBank === 'SUBMIT'}
        onSubmit={onSubmitBankDetails}
        profileData={profileData?.UserBank}
      />
    ) : (
      <GlobalLoader />
    ),
  };

  const modalQuestion = {
    edit: (
      <StoreAddQuestion
        onCancel={handleClose}
        loading={isLoadingQuestion === 'SUBMIT'}
        onSubmit={onSubmitQuestion}
      />
    ),
  };
  const modalAddPromoCode = {
    edit: (
      <StoreAddPromoCode
        onCancel={handleClose}
        loading={isLoadingPromo === 'SUBMIT'}
        onSubmit={onSubmitPromoCode}
      />
    ),
  };
  const modalUpdateProfile = {
    edit: (
      <StoreUpdateProfile
        onCancel={handleClose}
        loading={isLoadingprofile === 'SUBMIT'}
        onSubmit={onSubmitUpdateProfile}
        setProfile={setProfile}
        profile={profile}
        profileData={profileData}
      />
    ),
  };
  const modalUpdateDocument = {
    edit: (
      <StoreUpdateDocument
        onCancel={handleClose}
        documentDetails={documentDetails}
        loading={
          (documentDetails?.UserDocument?.id &&
            isLoadingEditDocument === 'SUBMIT') ||
          (documentDetails?.UserDocument === null &&
            isLoadingDocument === 'SUBMIT')
        }
        onSubmit={onSubmitDocument}
        setProfile={setProfile}
        profileData={profileData}
      />
    ),
  };

  return (
    <div className="main-content profilePage profilePage-store">
      <div className="pageContent">
        <div className="pageContent_wide">
          <Container>
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="breadSec_title mb-0">Profile</h1>
              <div className="dropBtn">
                <Link
                  to="#"
                  onClick={() => setIsSwitchOn(!isSwitchOn)}
                  className="dropBtn_toggle d-block d-sm-none"
                >
                  <em className="gg-more-vertical"></em>
                </Link>
                <div
                  className={
                    'pb-3 dropBtn_card ' + (isSwitchOn ? 'd-block' : '')
                  }
                >
                  <div className="d-flex flex-wrap">
                    <Link
                      to={accessRoute.PROMO_CODE.path}
                      className="btn btn-primary me-2"
                    >
                      <span className="d-inline-block">
                        {t('text.store.viewPromoCode')}
                      </span>
                    </Link>
                    <Link
                      onClick={handleShowPromo}
                      className="btn btn-primary me-2"
                    >
                      <span className="d-inline-block">
                        {t('text.store.addPromoCode')}
                      </span>
                    </Link>
                    <Link onClick={handlequestion} className="btn btn-primary">
                      <span className="d-inline-block">
                        {t('text.store.haveQuestion')}
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="profileBox br-10 p-30 bg-white">
              <div className="d-flex align-items-center profileBox_info">
                <div className="position-relative">
                  <ImageElement
                    previewSource={profileData?.profilePictureThumbUrl}
                    alt="user"
                  />
                  <span
                    onClick={handleprofile}
                    className="upload position-absolute"
                  >
                    <em className="text-500 gg-camera" />
                  </span>
                </div>
                <div className="ms-md-4 ms-2 text-break me-2">
                  <h2 className="font-md mb-1">
                    {firstLetterCaps(profileData?.firstName)}
                  </h2>
                  <p className="ft-14 font-md mb-0">{profileData?.email}</p>
                  <p className="ft-14 font-md mb-0">
                    {formatPhoneNumber(
                      profileData?.phoneNumber,
                      profileData?.countryPhoneCode
                    )}
                  </p>
                </div>
                <div className="ms-auto">
                  <Link onClick={handleShow} className="editIcon">
                    <em className="gg-edit" />
                  </Link>
                </div>
              </div>
              <div className="profileBox_card">
                <Row className="justify-content-center">
                  <Col sm="6" md="4">
                    <Card>
                      <Card.Body>
                        <div className="d-flex justify-content-between">
                          <em className="gg-marker" />
                          <Link
                            onClick={handleeditaddress}
                            className="store-editIcon"
                          >
                            <em className="gg-edit mb-0 d-inline-block" />
                          </Link>
                        </div>
                        <Card.Title
                          as="h3"
                          className="font-bd text-uppercase mb-2"
                        >
                          {t('text.common.address')}
                        </Card.Title>
                        <Card.Text className="ft-14">
                          {profileData?.Store?.Location?.name}
                          {`${
                            profileData?.Store?.Country?.name
                              ? `, ${profileData?.Store?.Country?.name}`
                              : ''
                          }`}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm="6" md="4">
                    <Card>
                      <Card.Body>
                        <div className="d-flex justify-content-between">
                          <em className="gg-locks" />
                          <Link
                            onClick={handleeditpsw}
                            className="store-editIcon"
                          >
                            <em className="gg-edit mb-0 d-inline-block" />
                          </Link>
                        </div>
                        <Card.Title
                          as="h3"
                          className="font-bd text-uppercase mb-2"
                        >
                          {t('text.common.password')}
                        </Card.Title>
                        <Card.Text className="ft-14">*********</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm="6" md="4">
                    <Card>
                      <Card.Body>
                        <div className="d-flex justify-content-between">
                          <em className="gg-bank" />
                          <Link
                            onClick={handlebookdetail}
                            className="store-editIcon"
                          >
                            <em className="gg-edit mb-0 d-inline-block" />
                          </Link>
                        </div>
                        <Card.Title
                          as="h3"
                          className="font-bd text-uppercase mb-2 d-flex"
                        >
                          {t('text.store.bankDetail')}
                          <Tooltip
                            placement="top"
                            title={
                              <span>
                                To receive payment, please provide bank details.
                              </span>
                            }
                          >
                            <Link to="#">
                              <em className="gg-info ft-14 mb-0 ms-1" />
                            </Link>
                          </Tooltip>
                        </Card.Title>
                        <Card.Text className="ft-14">
                          {t('text.store.bankInfo')}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <div className="docbox">
                  <h4 className="font-md">
                    {t('text.store.requiredDocument')}
                  </h4>
                  <p className="mb-0 ft-14">{t('text.store.documentInfo')}</p>

                  <div className="docbox_desc">
                    <Row>
                      {document.length > 0 &&
                        document.map((item, itemIdx) => {
                          return (
                            <Col key={itemIdx} sm="12" md="6">
                              <Card>
                                <Card.Body>
                                  <h4 className="font-md">
                                    {firstLetterCaps(item?.name)}
                                  </h4>

                                  <p className="mb-0 ft-14 text-center">
                                    {item?.documentThumbUrl ? (
                                      <ImageElement
                                        style={{ cursor: 'pointer' }}
                                        onClick={() =>
                                          openInNewTab(item?.documentThumbUrl)
                                        }
                                        previewSource={item?.documentThumbUrl}
                                        alt="user"
                                        isImagePreview={false}
                                      />
                                    ) : (
                                      <NoDataFound
                                        source="/no-data-found-img.svg"
                                        text={t('text.common.noDocumentFound')}
                                        extraClass="noDataFoundWrapper"
                                        isCustomer={
                                          '/assets/images/admin/no-data-found-img.svg'
                                        }
                                      />
                                    )}
                                  </p>
                                </Card.Body>
                                <Card.Footer className="bg-white">
                                  <Link
                                    onClick={(e) => {
                                      handleShowDocument();
                                      e.preventDefault();
                                      setDocumentDetails(item);
                                    }}
                                    className="btn btn-outline-primary float-end"
                                  >
                                    <span className="d-inline-block">
                                      {t('text.store.addDocument')}
                                    </span>
                                  </Link>
                                </Card.Footer>
                              </Card>
                            </Col>
                          );
                        })}
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <ModalWrapper
          heading="Edit Profile"
          onCancel={handleClose}
          content={modalFormProfile['edit']}
          show={show}
          extraClassName="modal-lg"
        />

        <ModalWrapper
          heading={t('text.store.addDocument')}
          onCancel={handleClose}
          content={modalUpdateDocument['edit']}
          show={documentModal}
          extraClassName="modal-md modal docModal"
        />
        {/* AS document */}

        <ModalWrapper
          heading={t('text.store.addPromoCode')}
          onCancel={handleClose}
          content={modalAddPromoCode['edit']}
          show={promoCodeModal}
          extraClassName="modal-lg"
        />
        {/* Add Promo Code */}

        <ModalWrapper
          heading={t('text.store.haveQuestion')}
          onCancel={handleClose}
          content={modalQuestion['edit']}
          show={questionModal}
          extraClassName="modal-lg"
        />
        {/*Question Modal */}

        <ModalWrapper
          heading={t('text.store.profilePicture')}
          onCancel={handleClose}
          content={modalUpdateProfile['edit']}
          show={profileModal}
          extraClassName="modal-md docModal modal"
        />
        {/*Profile Picture Modal */}

        <ModalWrapper
          heading={t('text.store.editAddress')}
          onCancel={handleClose}
          content={modalFormEditAddress['edit']}
          show={editaddressModal}
          extraClassName="modal-lg"
        />
        {/*Edit Address Modal */}

        <ModalWrapper
          heading={t('text.store.editPassword')}
          onCancel={handleClose}
          content={modalFormChangePassword['edit']}
          show={editpswModal}
          extraClassName="modal-lg"
        />
        {/*Edit Password Modal */}

        <ModalWrapper
          heading={t('text.store.bankDetail')}
          onCancel={handleClose}
          content={modalFormBankDetails['edit']}
          show={bookdetailModal}
          extraClassName="modal-lg"
        />
        {/*Bank Details Modal */}
      </div>
    </div>
  );
}

export default StoreMyProfile;
