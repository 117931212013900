export const NEW_EQUIPMENT_ENDPOINT = {
  getNewEquipmentList: {
    url: '/v1/admin/new-equipment',
    method: 'GET',
  },
  putUpdateEquipmentStatus: (id) => ({
    url: `/v1/admin/equipment/status/${id}`,
    method: 'PUT',
  }),
};
