export const validation = {
  header_first_label: 'Header first label text is required',
  header_second_label: 'Header second label text is required',
  header_third_label: 'Header third label text is required',
  find_your_experts_heading: 'Find your experts heading is required',
  find_your_experts_text: 'Find your experts text is required',
  find_your_experts_image: 'Find your experts image is required',
  find_your_experts_point_one_heading:
    'Find your experts point one heading is required ',
  find_your_experts_point_one_text:
    'Find your experts point one text is required',
  find_your_experts_point_two_heading:
    'Find your experts point two heading is required ',
  find_your_experts_point_two_text:
    'Find your experts point two text is required',
  find_your_experts_point_three_heading:
    'Find your experts point three heading is required ',
  find_your_experts_point_three_text:
    'Find your experts point three text is required',
  third_sec_one_heading: 'Third section one heading is required ',
  third_sec_two_heading: 'Third section two heading is required',
  third_sec_two_text: 'Third section two text is required',
  third_sec_one_text: 'Third section one text is required',
  third_sec_three_text: 'Third section three text is required',
  third_sec_three_heading: ' Third section three heading is required',
  pageLabel: 'Page title is required',
  fourth_sec_heading: 'Fourth section heading is required',
  fourth_sec_text: 'Fourth section text is required',
  fourth_sec_link: 'Fourth section link is required',
  testimonial_heading: 'Testimonial heading is required',
  testimonial_text: 'Testimonial text is required',
  testimonial_one_image: 'Testimonial one image is required',
  testimonial_one_text: 'Testimonial one text is required',
  testimonial_one_name: 'Testimonial one name is required ',
  testimonial_two_image: 'Testimonial two image is  required',
  testimonial_two_text: 'Testimonial two text is required',
  testimonial_two_name: 'Testimonial two name is required ',
  testimonial_three_image: 'Testimonial three image is  required',
  testimonial_three_text: 'Testimonial three text is required',
  testimonial_three_name: 'Testimonial three name is required ',
  testimonial_four_image: 'Testimonial four image is  required',
  testimonial_four_text: 'Testimonial four text is required',
  testimonial_four_name: 'Testimonial four name is required ',
  enterDescription: 'Testimonial heading is required',
  third_sec_image: 'Fourth section image is required',
};
