import React from 'react';
import BusinessDetails from '../TeamSetting/Providers/Edit/BusinessDetails';

export default function NCABusinessDetail({ defaultKey, setDefaultKey }) {
  return (
    <BusinessDetails
      defaultKey={defaultKey}
      setDefaultKey={setDefaultKey}
      ncaProfileKey={true}
    />
  );
}
