let url = '/v1/admin/slot';

export const slotsEndpoints = {
  addSlots: {
    url: `${url}`,
    method: 'POST',
  },
  deleteSlot: (id) => ({
    url: `${url}/${id}`,
    method: 'DELETE',
  }),
  getSlot: {
    url: url,
    method: 'GET',
  },
};
