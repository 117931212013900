import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  AddEditLineItemsForm,
  PageContainerWrapper,
} from '../../../../components';
import { accessRoute } from '../../../../routes/PriceManagement';
import { backToPage } from '../../../../utils';
import {
  Columns,
  addLineItemsService,
} from '../../../../services/index.service';

function CreateLineItem() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const redirectUrl = accessRoute.LINE_ITEMS.path;
  const breadcrumb = Columns.addLineItemsBreadcrumb();

  async function onSubmit(values) {
    try {
      setIsLoading(true);
      if (values?.partNumber) {
        values.partNumber = `${values.partNumber}`;
      }
      const payload = { ...values };
      delete payload.viewImageUrl;
      const res = await addLineItemsService({ body: payload });
      if (res?.success) {
        backToPage(navigate, redirectUrl);
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="wide-md mx-auto">
      <PageContainerWrapper
        breadcrumb={breadcrumb}
        btnArray={['extraButton']}
        extraBtnText={t('text.common.back')}
        extraBtnClass="btn btn-outline-light bg-white"
        onExtraButtonHandleShow={() => backToPage(navigate, redirectUrl)}
        heading={t('text.lineItems.addLineItems')}
      >
        <AddEditLineItemsForm
          onSubmit={onSubmit}
          isLoading={isLoading}
          backToPage={() => backToPage(navigate, redirectUrl)}
        />
      </PageContainerWrapper>
    </div>
  );
}

export default CreateLineItem;
