import { getActions } from '../../services/index.service';
import {
  ScheduleBookingView,
  ListScheduledBooking,
} from '../../views/index.view';

export default function route() {
  return [
    {
      private: true,
      name: accessRoute.SCHEDULE_MANAGEMENT.name,
      key: accessRoute.SCHEDULE_MANAGEMENT.path,
      path: accessRoute.SCHEDULE_MANAGEMENT.path,
      icon: accessRoute.SCHEDULE_MANAGEMENT.icon,
      belongsToSidebar: true,
      element: <ListScheduledBooking />,
      ...accessRoute.SCHEDULE_MANAGEMENT,
    },
    {
      private: true,
      path: accessRoute.BOOKING_VIEW.path,
      name: 'View Booking',
      key: accessRoute.BOOKING_VIEW.key,
      belongsToSidebar: false,
      icon: accessRoute.BOOKING_VIEW.icon,
      element: <ScheduleBookingView />,
      ...accessRoute.BOOKING_VIEW,
    },
  ];
}

export const accessRoute = {
  SCHEDULE_MANAGEMENT: {
    path: '/admin/scheduled-bookings',
    moduleKey: 'scheduled-bookings',
    key: 'scheduled-bookings',
    label: 'Scheduled Booking',
    name: 'Scheduled Bookings',
    action: getActions('LIST').value,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-calendar-booking" />
      </span>
    ),
    order: 10,
  },
  BOOKING_VIEW: {
    path: '/admin/schedule/scheduled-bookings/:id/:viewQuote',
    key: 'scheduled-bookings',
    moduleKey: 'scheduled-bookings',
    action: getActions('LIST').value,
  },
  BOOKING_INVOICE: {
    path: '/admin/scheduled-bookings/invoice/:id',
    key: 'schedule-booking',
    moduleKey: 'schedule-booking',
    action: getActions('EDIT'),
  },
};

export const getChildrenRoutes = () => {
  return route();
};
