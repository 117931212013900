import React, { useEffect, useState } from 'react';
import {
  ActionWrapper,
  TablePlus,
  Toggle,
  ModalWrapper,
  PageContainerWrapper,
  AddEditCategoryForm,
} from '../../../../../components';
import { Link, useSearchParams } from 'react-router-dom';
import { modalNotification } from '../../../../../utils';
import {
  Columns,
  addCategoryService,
  categoryStatus,
  deleteCategoryService,
  discardImportCategoryService,
  editCategoryService,
  getActions,
  getCategoryDetailsService,
  getCategoryService,
  importCategoryService,
  importFileCategoryListService,
  saveImportCategoryService,
} from '../../../../../services/index.service';
import { useTranslation } from 'react-i18next';
import { getApiData, useTable } from '../../../../../hooks/index.hook';
import { categoryListBreadcrumb } from '../../../../../services/Columns';
import {
  importFileAPIData,
  importFileData,
  selectImportAPIData,
  selectImportData,
} from '../../../../../redux/index.redux';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import useSubmit from '../../../../../hooks/useSubmit.hook';
import ImportRecordTable from '../../../../../features/importRecordTable/index.feature';

function CategoryList() {
  const breadcrumb = categoryListBreadcrumb();
  const { t } = useTranslation();
  const [tableHandler, tableProps] = useTable(getCategoryService);
  const [importData, setImportData] = useState({});
  const [compareFileData, setCompareFileData] = useState([]);
  const dispatch = useDispatch();
  const importReduxData = useSelector(selectImportData);
  const importReduxAPIData = useSelector(selectImportAPIData);
  const [searchParams, setSearchParams] = useSearchParams();
  const [saveLoader, setSaveLoader] = useState(false);
  const [discardLoader, setDiscardLoader] = useState(false);
  const {
    data,
    isLoading,
    count,
    page,
    noOfPages,
    sizePerPage,
    sortBy,
    sortType,
    filter,
  } = tableProps;
  const {
    getData,
    onTableSort,
    // onToggleChange,
    onConfirmDeleteAlert,
    onRefresh,
    onExport,
    onFilterChange,
    onFilterResetChange,
  } = tableHandler;

  const [state, setState] = useState({
    loader: null,
    modal: null,
    modalId: null,
    selectedRows: [],
    submitText: 'add',
  });
  const { loader, modal, modalId, selectedRows, submitText } = state;

  function onAction(e, handler) {
    e.preventDefault();
    handler();
  }

  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler)}
              key={key}
              className="action_list"
              action={item?.action}
            >
              <Link to="#" onClick={(e) => e.preventDefault()}>
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  async function onToggleChange(e, row, type) {
    try {
      let status =
        e === 'active' ? 'active' : e === 'deleted' ? 'deleted' : 'inactive';
      if (type && type === 'action') {
        status = e;
      }
      const listOfIds = row?.id && row?.id;
      const payload = {
        status: status,
      };
      const id = listOfIds;
      const { success, message } = await categoryStatus({
        bodyData: payload,
        queryParams: id,
      });
      if (success) {
        modalNotification({
          type: 'success',
          message,
        });
        onRefresh();
      }
      return success ? true : false;
    } catch (err) {
      return false;
    }
  }
  const columns = Columns.categoryTableColumn({
    onSort: onTableSort,
    onFilterChange: onFilterChange,
    filter,
    page,
    sizePerPage,
    Action: (
      props
      // row
    ) =>
      actionFormatter([
        {
          name: t('text.common.edit'),
          icon: 'icon ni ni-edit',
          action: getActions('EDIT').value,
          handler: () => {
            setState({
              ...state,
              modal: 'edit-category',
              submitText: 'Update',
              modalId: props?.row?.id,
            });
          },
        },
        {
          name: t('text.common.delete'),
          icon: 'icon ni ni-trash',
          action: getActions('DELETE').value,
          handler: () =>
            // item
            {
              onConfirmDeleteAlert({
                id: props?.row?.id,
                text: t('text.shoppingCart.areYouSureCategory'),
                service: deleteCategoryService,
                key: 'id',
              });
              // document.body.click();
            },
        },
      ]),
    Toggle: (props) => (
      <Toggle
        isDropDown={true}
        shouldDeleteInclude={true}
        loading={loader === 'status-update'}
        defaultChecked={props?.row?.status}
        onChange={(e) => onToggleChange(e, props?.row)}
        onRefresh={onRefresh}
      />
    ),
  });

  async function getDetail(id) {
    try {
      const res = await getCategoryDetailsService({ id });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  function onRowSelect(e) {
    try {
      setState({ ...state, selectedRows: [...e] });
    } catch (err) {
      console.log('err', err);
    }
  }

  async function handleActionSelect(e) {
    try {
      if (e.target.value === 'delete') {
        await onConfirmDeleteAlert({
          id: selectedRows,
          text: t('text.common.areYouSureBanner'),
          service: '', //
          key: 'id',
        });
        getData();
      } else {
        await onToggleChange(
          e,
          // props?.row,
          'action',
          // categoryStatus,
          'resourceId'
        );
        getData();
      }
    } catch (err) {
      console.log(err);
    }
  }
  function onCancel() {
    setState({ ...state, modal: null, modalId: null });
  }
  async function onSubmitCategoryData(payload) {
    setState({ ...state, loader: 'add' });
    try {
      let response = '';
      if (modalId)
        response = await editCategoryService({
          bodyData: payload,
          id: modalId,
        });
      else
        response = await addCategoryService({
          bodyData: payload,
        });
      const { success, message } = response;

      if (success) {
        setState({ ...state, modal: null, loader: null, modalId: null });
        modalNotification({
          type: 'success',
          message,
        });
        onRefresh();
      } else {
        setState({ ...state, loader: null });
      }
    } catch (error) {
      console.log(error);
      setState({ ...state, loader: null });
    }
  }
  const formProps = {
    onCancel: onCancel,
    onSubmit: onSubmitCategoryData,
  };
  const modalForm = {
    add: (
      <AddEditCategoryForm
        onEdit={getDetail}
        onCancel={onCancel}
        loading={loader === 'add'}
        modalId={modalId}
        submitText={submitText}
        {...formProps}
      />
    ),
  };

  const handleAddImport = (recordData) => {
    try {
      if (recordData?.path && recordData?.data?.length > 0) {
        getApiData({
          service: importFileCategoryListService,
          setData: setCompareFileData,
          queryParams: {
            file: recordData?.path,
          },
          dispatch,
          setRedux: importFileAPIData,
        });
        dispatch(importFileData({ ...recordData }));
        setImportData(recordData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [onSubmit] = useSubmit({
    service: discardImportCategoryService,
  });
  const handleDiscard = async () => {
    setDiscardLoader(true);

    try {
      const payload = { file: importData?.path };
      let res = await onSubmit(payload);

      if (res) {
        dispatch(importFileData({}));
        dispatch(importFileAPIData({}));
        setImportData({});
        setCompareFileData([]);
        onRefresh();
        searchParams.set('pageSize', 10);
        setSearchParams(searchParams);
      }
    } catch (err) {
      console.log(err);
    }
    setDiscardLoader(false);
  };

  const [onSaveSubmit] = useSubmit({
    service: saveImportCategoryService,
  });

  const handleSave = async () => {
    setSaveLoader(true);
    try {
      const payload = { file: importData?.path };
      let res = await onSaveSubmit(payload);
      if (res) {
        dispatch(importFileData({}));
        dispatch(importFileAPIData({}));
        setImportData({});
        setCompareFileData([]);
        onRefresh();
        searchParams.set('pageSize', 10);
        setSearchParams(searchParams);
      }
    } catch (err) {
      console.log(err);
    }
    setSaveLoader(false);
  };
  const headerBtn = {
    // multipleButton: [
    //   {
    //     title: t('text.shoppingCart.downloadSample'),
    //     handler: () => {},
    //   },
    // ],
    add: {
      name: t('text.shoppingCart.addCategory'),
      handler: () => {
        setState({ ...state, submitText: 'Add', modal: 'add-category' });
      },
    },
    csvExport: {
      handler: () => onExport('', columns),
    },
    csvImport: {
      service: importCategoryService,
      basePath: 'file',
      handler: handleAddImport,
    },
  };
  // AddEditCategoryForm
  useEffect(() => {
    try {
      if (Object.keys(importReduxData).length) {
        setImportData(importReduxData);
      }
    } catch (err) {
      console.log(err);
    }
  }, [importReduxData]);

  useEffect(() => {
    try {
      if (Object.keys(importReduxAPIData).length) {
        setCompareFileData(importReduxAPIData);
      }
    } catch (err) {
      console.log(err);
    }
  }, [importReduxAPIData]);
  return (
    <>
      <PageContainerWrapper
        heading={t('text.shoppingCart.categoryTitle')}
        breadcrumb={breadcrumb}
        btn={headerBtn}
      >
        {importData?.data && importData?.data?.length ? (
          <ImportRecordTable
            tableProps={compareFileData}
            tableColumns={columns}
            loading={isLoading}
            recordData={importData}
            handleDiscard={handleDiscard}
            handleSave={handleSave}
            onSaveLoader={saveLoader}
            onDiscardLoader={discardLoader}
          />
        ) : (
          <TablePlus
            noOfPage={noOfPages}
            sizePerPage={sizePerPage}
            page={page}
            sortBy={sortBy}
            sortType={sortType}
            filter={filter}
            onFilterResetChange={onFilterResetChange}
            tableData={data}
            selectRow={true}
            hasLimit
            count={count}
            tableColumns={columns}
            showAction={false}
            // isSearch={true}
            // searchField
            tableLoader={isLoading}
          />
        )}

        <ModalWrapper
          heading={`${
            submitText === 'Add' ? t('text.common.add') : t('text.common.edit')
          } Category`}
          onCancel={onCancel}
          content={modalForm['add']}
          show={['add-category', 'edit-category'].includes(modal)}
        />
      </PageContainerWrapper>
    </>
  );
}

export default CategoryList;
