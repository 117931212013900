import i18next, { t } from 'i18next';
import { CommonButton, getInputSearchFilter, getMultiSelectSearchFilter } from '../../components';
import {
  checkNumberAndPercentage,
  dateFormatter,
  readMoreTextShow,
} from '../../utils';
import { firstLetterCaps, getFullName } from '../../utils/text.util';
import {
  checkValidCount,
  checkValidData,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import moment from 'moment';
import { getFromToDateFilter } from '../../components/Filter/Common/date.filter';

export const MarketingTemplateBreadCrumbs = () => {
  return [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: t('text.marketing.marketingSmsTemplate'),
    },
  ];
};

export const addMarketingTemplateBreadCrumbs = () => {
  return [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/marketing-template/sms',
      name: t('text.marketing.marketingSmsTemplate'),
    },
    {
      path: '#',
      name: t('text.marketing.addMarketingSmsTemplate'),
    },
  ];
};

export const editMarketingTemplateBreadCrumbs = () => {
  return [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/marketing-template/sms',
      name: t('text.marketing.marketingSmsTemplate'),
    },
    {
      path: '#',
      name: t('text.marketing.editMarketingSmsTemplate'),
    },
  ];
};

export const unSubscribeBreadcrumb = () => {
  return [
    {
      path: '/admin/dashboard',
      name: t('text.common.dashboard'),
    },
    {
      path: '/admin/marketing-review-message',
      name: t('text.marketing.headingReview'),
    },
    {
      path: '#',
      name: t('text.marketing.unSubscribe'),
    },
  ];
};

export const viewReportBreadcrumb = () => {
  return [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/marketing-review-message',
      name: t('text.marketing.headingReview'),
    },
    {
      path: '#',
      name: t('text.marketing.messageReport'),
    },
  ];
};

export const viewReportDetailDate = ({ data }) => {
  let arrays = [
    {
      message: t('text.marketing.messageSentDate'),
      date: moment(data?.MarketingReport?.updatedAt).format('MMM DD, YYYY'),
    },
    {
      message: t('text.marketing.approvedDate'),
      date: moment(data?.MarketingReport?.actionDate).format('MMM DD, YYYY'),
    },
    {
      message: t('text.marketing.audienceCount'),
      date: checkValidCount(data?.MarketingReport?.totalRecipient),
    },
  ];

  arrays = [
    ...arrays,
    ...(data?.MarketingReport?.templateType === 'email'
      ? [
          {
            message: t('text.marketing.pending'),
            date: checkNumberAndPercentage(
              data.MarketingReport?.pending,
              data?.MarketingReport?.pendingPercentage
            ),
          },
          {
            message: t('text.marketing.opened'),
            date: checkNumberAndPercentage(
              data?.MarketingReport?.open,
              data?.MarketingReport?.openPercentage
            ),
          },
          {
            message: t('text.marketing.bounced'),
            date: checkNumberAndPercentage(
              data?.MarketingReport?.bounced,
              data?.MarketingReport?.bouncedPercentage
            ),
          },
          {
            message: t('text.marketing.unsubscribed'),
            date: checkNumberAndPercentage(
              data?.MarketingReport?.unsubscribed,
              data?.MarketingReport?.unsubscribedPercentage
            ),
          },
          {
            message: t('text.marketing.complained'),
            date: checkNumberAndPercentage(
              data?.MarketingReport?.complained,
              data?.MarketingReport?.complainedPercentage
            ),
          },
          {
            message: t('text.marketing.clickedOnLink'),
            date: checkNumberAndPercentage(
              data?.MarketingReport?.click,
              data?.MarketingReport?.clickPercentage
            ),
          },
        ]
      : []),
  ];
  return arrays;
};

export const columnsSms = ({
  filter,
  page,
  onFilterChange,
  Action,
  Toggle,
  sizePerPage,
}) => {
  return [
    {
      dataIndex: 'id',
      title: t('text.common.sno'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'purpose',
      filterDataIndex: ['smsPurpose'],
      title: t('text.marketing.smsPurpose'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'smsPurpose',
        placeholder: 'sms purpose',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'body',
      filterDataIndex: ['smsBody'],
      title: t('text.marketing.smsBody'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) =>
        readMoreTextShow(
          checkValidData(cell),
          i18next.t('text.marketing.smsBody'),
          20
        ),
      ...getInputSearchFilter({
        dataIndex: 'smsBody',
        placeholder: 'sms body',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'status',
      title: t('text.common.status'),
      headerClasses: 'sorting',
      render: (cell, row) => <Toggle row={row} cell={cell} isDropDown />,
    },
    {
      dataIndex: 'action',
      title: t('text.common.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => {
        return <Action row={row} cell={cell} />;
      },
    },
  ];
};

const messageStatusValues = () => {
  return [
      {
          name: 'Pending',
          id: 'pending',
      },
      {
          name: 'Bounced',
          id: 'bounced',
      },
      {
          name: 'Unsubscribed',
          id: 'unsubscribed',
      },
      {
          name: 'Open',
          id: 'open',
      },
      {
          name: 'Complained',
          id: 'complained',
      },
  ];
};

export const viewReportColumns = ({ data, page, sizePerPage,filter, onFilterChange,onSort }) => {
  let arrays = [
    {
      dataIndex: 'id',
      title: t('text.common.sno'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'audienceName',
      title: t('text.marketing.audienceName'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => {
        return getFullName(row?.User?.firstName, '', row?.User?.lastName);
      },
      ...getInputSearchFilter({
        dataIndex: 'audienceName',
        placeholder: 'audience name',
        onFilterChange,
        filter,
    }),
    },
  ];
  arrays = [
    ...arrays,
    ...(data?.MarketingReport?.templateType === 'email'
      ? [
          data?.MarketingReport?.templateType === 'email' && {
            dataIndex: 'messageStatus',
            title: t('text.marketing.messageStatus'),
            filterDataIndex: ['messageStatus'],
            headerClasses: 'sorting',
            sorter: true,
            render: (cell, row) => {
              return checkValidData(firstLetterCaps(row?.messageStatus));
            },
            ...getMultiSelectSearchFilter({
              dataIndex: 'messageStatus',
              onFilterChange,
              filter,
              name: 'message status',
              list: [],
              mode: false,
              listService: messageStatusValues,
              searchOrNot: true,
          }),
          },
          data?.MarketingReport?.templateType === 'email' && {
            dataIndex: 'clickCount',
            title: t('text.marketing.clickPerformance'),
            filterDataIndex: ['clickCount'],
            headerClasses: 'sorting',
            sorter: true,
            render: (cell, row) => {
              return `${row?.clickCount} Clicks`;
            },
          },
        ]
      : ''),
  ];
  return arrays;
};

export const columnsShortCode = () => {
  return [
    {
      dataIndex: 'smsPurpose',
      title: 'Short Codes',
      className: 'nk-tb-col-tools text-end',
    },
    {
      dataIndex: 'description',
      title: 'Description',
      className: 'nk-tb-col-tools text-end',
    },
  ];
};

export const breadcrumbSend = () => {
  return [
    {
      path: '/admin/dashboard',
      name: t('text.common.dashboard'),
    },
    {
      path: '#',
      name: t('text.marketing.messageTypeSend'),
    },
  ];
};
export const messageTypeData = () => {
  return [
    {
      name: 'Email',
      value: 'Email',
    },
    {
      name: 'SMS',
      value: 'SMS',
    },
    {
      name: 'Push Notification',
      value: 'notification',
    },
  ];
};
export const templateData = () => {
  return [
    {
      name: 'marketingEmailTemplate',
      value: 'Marketing  Email Template',
    },
    {
      name: 'mailSubject',
      value: 'Mail Subject',
    },
    {
      name: 'customerWelcomeMarketing',
      value: 'Customer welcome marketing email template',
    },
    {
      name: 'newsletterSubscriptionEmail',
      value: 'Newsletter subscription email',
    },
  ];
};
export const audienceTypeData = (role) => {
  let arrays = [
    {
      name: 'Customer',
      value: 'Customer',
    },
    {
      name: 'Provider',
      value: 'Provider',
    },
  ];
  arrays = [
    ...arrays,
    ...(role === 'nca'
      ? []
      : [
          {
            name: 'Admin',
            value: 'Admin',
          },
        ]),
  ];
  return arrays;
};

const templateTypeValues = () => {
  return [
      {
          name: 'Email',
          id: 'email',
      },
      {
          name: 'SMS',
          id: 'sms',
      },
      {
          name: 'Notification',
          id: 'notification',
      },
  ];
};

export const MarketingReviewTemplateTableColumn = ({
  approveModalShow,
  declineModalShow,
  reportModalShow,
  moduleServicesTags,
  setIsOpenModal,
  setRowData,
  page,
  sizePerPage,
  onFilterChange,
  filter,
  shortCodeData,
  onSort
}) => {
  const audienceButton = {
    provider: t('text.marketing.viewServices'),
    admin: t('text.marketing.viewModules'),
    customer: t('text.marketing.viewTags'),
  };
  return [
    {
      dataIndex: 'id',
      title: t('text.common.sno'),
      className: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'createdAt',
      title: t('text.marketing.dateOfSubmission'),
      render: (cell, row) => checkValidData(dateFormatter(cell, 'LL')),
    },
    {
      dataIndex: 'senderName',
      title: t('text.marketing.senderName'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) =>{
        let messageData = {
          audience: row?.audience,
          id: row?.id,
          role: row?.User?.Role?.type,
         };
      return (
        <>
          {' '}
          {row?.audience === 'customer' && ['admin','subadmin','nca'].includes(row?.User?.Role.type) ?
          <CommonButton
            onClick={() => {
              setIsOpenModal(moduleServicesTags.viewProviderNameModalShow);
              setRowData(messageData);
            }}
            className="btn marketing_message_send"
          >
            
           {checkValidData(getFullName(row?.User?.firstName, row?.User?.lastName))}
          </CommonButton>
          : 
          <>
          {checkValidData(getFullName(row?.User?.firstName, row?.User?.lastName))}
          </>
          }
        </>
      );
    },
    ...getInputSearchFilter({
      dataIndex: 'senderName',
      placeholder: 'sender name',
      onFilterChange,
      filter,
     })
    },
    {
      dataIndex: 'templateType',
      title: t('text.marketing.typeofMessageSent'),
      sorter: true,
      headerClasses: 'sorting',
      render: (cell, row) => firstLetterCaps(checkValidData(cell)),
      ...getMultiSelectSearchFilter({
        dataIndex: 'templateType',
        onFilterChange,
        filter,
        name: 'template type',
        list: [],
        mode: false,
        listService: templateTypeValues,
        searchOrNot: true,
    }),
    },
    {
      dataIndex: 'audience',
      title: t('text.marketing.audience'),
      // recipientCount
      // filterDataIndex: ['recipientCount'],
      sorter: true,
      headerClasses: 'sorting',
      // onSort: (field, order) => onSort('recipientCount', order),
      render: (cell, row) =>
        cell ? firstLetterCaps(`${cell} (${row?.recipientCount})`) : '-',
    },
    {
      dataIndex: 'audience',
      title: t('text.marketing.moduleServicesTags'),
      render: (cell, row) => {
        return (
          <CommonButton
            onClick={() => {
              setRowData(row);
              setIsOpenModal(() => {
                shortCodeData(cell);
              });
            }}
            className="btn btn-primary btn-sm"
          >
            {audienceButton[cell]}
          </CommonButton>
        );
      },
    },
    {
      dataIndex: 'message',
      title: t('text.marketing.message'),
      render: (cell, row) => {
        let messageData = {
          subject: row?.subject,
          template: row?.template,
        };
        return (
          <>
            {' '}
            <CommonButton
              onClick={() => {
                setIsOpenModal(moduleServicesTags.viewMessageModalShow);
                setRowData(messageData);
              }}
              className="btn btn-primary btn-sm"
            >
              View Message
            </CommonButton>
          </>
        );
      },
    },
    {
      dataIndex: 'status',
      title: t('text.marketing.approve'),
      render: (cell, row) => {
        if (cell === 'approve') {
          return (
            <>
              {' '}
              <span className="text-success">
                {firstLetterCaps('Approved')}{' '}
              </span>{' '}
            </>
          );
        } else {
          if (cell === 'pending') {
            return (
              <>
                <CommonButton
                  onClick={() => approveModalShow(row)}
                  className="btn btn-success btn-sm"
                >
                  {t('text.common.approve')}
                </CommonButton>
              </>
            );
          } else if (cell === 'reject') {
            return <> -- </>;
          }
        }
      },
    },
    {
      dataIndex: 'status',
      title: t('text.marketing.decline'),
      render: (cell, row) => {
        if (cell === 'approve') {
          return <> -- </>;
        } else if (cell === 'reject') {
          return (
            <>
              {' '}
              <span className="text-danger">
                {' '}
                {firstLetterCaps('Declined')}
              </span>{' '}
            </>
          );
        } else {
          if (cell === 'pending') {
            return (
              <>
                <CommonButton
                  onClick={() => declineModalShow(row)}
                  className="btn btn-danger btn-sm"
                >
                  {t('text.common.decline')}
                </CommonButton>
              </>
            );
          }
        }
      },
    },
    {
      dataIndex: 'status',
      title: t('text.marketing.viewReport'),
      render: (cell, row) => {
        if (cell === 'approve') {
          return (
            <>
              <CommonButton
                onClick={() => reportModalShow(row?.id)}
                className="btn btn-primary btn-sm"
              >
                {t('text.common.views')}
              </CommonButton>
            </>
          );
        } else {
          return <> -- </>;
        }
      },
    },
    {
      dataIndex: 'actionDate',
      filterDataIndex: ['fromActionDate', 'toActionDate'],
      title: t('text.marketing.approvedDeclinedDate'),
      render: (cell, row) => (cell === null ? '--' : dateFormatter(cell, 'LL')),
      ...getFromToDateFilter({
        dataStartIndex: 'fromActionDate',
        dataEndIndex: 'toActionDate',
        placeholderStartDate: 'From date',
        placeholderEndDate: 'To date',
        dateFormat: 'YYYY-MM-DD',
        onFilterChange,
        filter,
      }),
    },
  ];
};

export const subscribeColumns = ({
  page,
  sizePerPage,
  deleteUnsubscribeData,
  onFilterChange,
  filter,
}) => {
  return [
    {
      dataIndex: 'id',
      title: t('text.common.sno'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'date',
      title: t('text.common.date'),
      render: (cell, row) =>
        row ? dateFormatter(row?.updatedAt, 'ddd, MMMM, DD, YYYY') : '-',
    },
    {
      dataIndex: 'customer',
      title: t('text.common.name'),
      render: (cell, row) =>
        checkValidData(
          getFullName(row?.User?.firstName, null, row?.User?.lastName)
        ),
      ...getInputSearchFilter({
        dataIndex: 'customer',
        placeholder: 'name',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'typeOfAudience',
      title: t('text.marketing.typeOfAudience'),
      render: (cell, row) => checkValidData(row?.User?.Role?.audience),
    },
    {
      dataIndex: 'action',
      title: t('text.common.action'),
      render: (cell, row) => {
        return (
          <>
            {' '}
            <CommonButton
              className="btn btn-primary btn-sm"
              onClick={() => deleteUnsubscribeData(row?.id)}
            >
              {t('text.common.subscribe')}
            </CommonButton>
          </>
        );
      },
    },
  ];
};

export const MarketingReviewTemplateService = () => {
  return [
    {
      id: 1,
      dateOfSubmission: 'May 12, 2023',
      senderName: 'Arpit Jai',
      typeOfMessageSent: 'Email',
      audience: 'Customer (2)',
      moduleServicesTags: 'viewTags',
      approve: 'pending',
      decline: 'button',
      viewReport: ' ',
      approvedDeclinedDate: '--',
    },
    {
      id: 2,
      dateOfSubmission: 'May 12, 2023',
      senderName: 'Kam Jacobs',
      typeOfMessageSent: 'Email',
      audience: 'Customer (1)',
      moduleServicesTags: 'viewTags',
      approve: 'Approve',
      decline: 'pending',
      viewReport: ' ',
      approvedDeclinedDate: '--',
    },
    {
      id: 3,
      dateOfSubmission: 'May 13, 2023',
      senderName: '	Ios Pro',
      typeOfMessageSent: 'Sms',
      audience: 'Customer (5)',
      moduleServicesTags: 'viewTags',
      approve: 'pending',
      decline: 'pending',
      viewReport: ' ',
      approvedDeclinedDate: '--',
    },
    {
      id: 4,
      dateOfSubmission: 'May 14, 2023',
      senderName: 'Main Administrator',
      typeOfMessageSent: 'Email',
      audience: 'Admin (5)',
      moduleServicesTags: 'viewModules',
      approve: 'Approved',
      decline: 'decline',
      viewReport: 'view',
      approvedDeclinedDate: 'Apr 17, 2023',
    },
    {
      id: 5,
      dateOfSubmission: 'Apr 19, 2023',
      senderName: 'Main Administrator',
      typeOfMessageSent: 'Email',
      audience: 'Admin (7)',
      moduleServicesTags: 'viewModules',
      approve: 'Approved',
      decline: 'decline',
      viewReport: 'view',
      approvedDeclinedDate: 'Apr 19, 2023',
    },
    {
      id: 6,
      dateOfSubmission: 'Apr 19, 2023',
      senderName: 'Main Administrator',
      typeOfMessageSent: 'Email',
      audience: 'Admin (7)',
      moduleServicesTags: 'viewModules',
      approve: 'Approved',
      decline: ' ',
      viewReport: 'view',
      approvedDeclinedDate: 'Apr 19, 2023',
    },
    {
      id: 7,
      dateOfSubmission: 'Apr 19, 2023',
      senderName: 'Ios Pro',
      typeOfMessageSent: 'Email',
      audience: 'Provider (219)',
      moduleServicesTags: 'viewServices',
      approve: 'Approved',
      decline: ' ',
      viewReport: 'view',
      approvedDeclinedDate: 'Apr 19, 2023',
    },
    {
      id: 8,
      dateOfSubmission: 'Apr 19, 2023',
      senderName: 'Ios Pro',
      typeOfMessageSent: 'Email',
      audience: 'Provider (10)',
      moduleServicesTags: 'viewServices',
      approve: 'Approved',
      decline: ' ',
      viewReport: 'view',
      approvedDeclinedDate: 'Apr 19, 2023',
    },
  ];
};

export const subscribeData = () => {
  return [
    {
      id: 1,
      date: 'Mar 24,2023',
      name: 'Ronak',
      typeOfAudience: 'Customer',
    },
    {
      id: 2,
      date: 'Mar 24,2023',
      name: 'Ravi',
      typeOfAudience: 'Admin',
    },
    {
      id: 3,
      date: 'Mar 24,2023',
      name: 'Ronak',
      typeOfAudience: 'Provider',
    },
    {
      id: 4,
      date: 'Mar 24,2023',
      name: 'Jhon',
      typeOfAudience: 'Customer',
    },
    {
      id: 5,
      date: 'Mar 24,2023',
      name: 'Jitendra',
      typeOfAudience: 'Customer',
    },
  ];
};
