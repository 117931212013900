import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';
import { SERVICES_SUB_CATEGORY_ENDPOINT } from '../../apiEndpoints/index.endpoint';

export const ListSubServiceCategoryService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...SERVICES_SUB_CATEGORY_ENDPOINT.getSubServiceCategoryList,
      queryParams,
    };

    const res = await APIRequest(payload);
    if (type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const ListCustomerSubServiceCategoryService = async ({
  queryParams,
  type,
}) => {
  try {
    const payload = {
      ...SERVICES_SUB_CATEGORY_ENDPOINT.customerSubCategoryUrl,
      queryParams,
    };

    const res = await APIRequest(payload);
    if (type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const StatusSubServiceCategoryService = async ({ bodyData }) => {
  try {
    const payload = {
      ...SERVICES_SUB_CATEGORY_ENDPOINT.statusSubServiceCategory,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const DeleteSubServiceCategoryService = async ({ bodyData }) => {
  try {
    const payload = {
      ...SERVICES_SUB_CATEGORY_ENDPOINT.deleteSubServiceCategory,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const addSubServiceCategoryService = async ({ values }) => {
  try {
    const payload = {
      ...SERVICES_SUB_CATEGORY_ENDPOINT.addSubServiceCategory,
      bodyData: values,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const editSubServiceCategoryService = async ({ values, id }) => {
  try {
    const payload = {
      ...SERVICES_SUB_CATEGORY_ENDPOINT.editSubServiceCategory(id),
      bodyData: values,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const viewSubServiceCategoryService = async (id) => {
  try {
    const payload = {
      ...SERVICES_SUB_CATEGORY_ENDPOINT?.getSubServiceCategory(id),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
