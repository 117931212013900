let url = '/v1/admin/membership';
export const MEMBERSHIP_ENDPOINT = {
  getMembership: {
    url,
    method: 'GET',
  },
  addMembership: {
    url: url,
    method: 'POST',
  },
  editMembership: (id) => ({
    url: `${url}/${id}`,
    method: 'PUT',
  }),
  getMembershipDetails: (id) => ({
    url: `${url}/${id}`,
    method: 'GET',
  }),
  cancelMembershipPlan: (id) => ({
    url: `/v1/membership/cancel/${id}`,
    method: 'PUT',
  }),
  membershipStatusUpdate: {
    url: `${url}/change-status`,
    method: 'PUT',
  },
  deleteMembership: {
    url,
    method: 'DELETE',
  },
};
