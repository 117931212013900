import React, { useState } from 'react';
import { CaseAndAdminForm } from '../../../../../../components';
import { modalNotification } from '../../../../../../utils';
import { waivePaymentService } from '../../../../../../services/index.service';
import i18next from 'i18next';

export default function AdminWaiveCost({
  rowData,
  setIsOpenModal,
  getData,
  authorizedUser,
  paymentDetails,
}) {
  const [loading, setLoading] = useState(false);
  const onSubmit = async (value) => {
    setLoading(true);
    try {
      let bodyData = {
        bookingId: rowData?.BookingDetail?.bookingId,
        paidAmount: rowData?.adminAttentionAmount,
        reason: paymentDetails?.reason,
      };
      const response = await waivePaymentService({ bodyData });
      if (response?.success) {
        modalNotification({
          type: 'success',
          message: response?.message,
        });
        setIsOpenModal(false);
        getData();
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  return (
    <CaseAndAdminForm
      onSubmit={onSubmit}
      loading={loading}
      rowData={rowData}
      title={i18next.t('text.report.adminWaiveTitle')}
      authorizedUser={authorizedUser}
      paymentDetails={paymentDetails}
    />
  );
}
