import { getActions } from '../../services/Auth/index.service';
import { VoiceAIRecords } from '../../views/VoiceAIRecords';

export default function route() {
  return [
    {
      private: true,
      path: accessRoute.VOICE_AI_RECORDS.path,
      name: 'Voice AI Records',
      key: accessRoute.VOICE_AI_RECORDS.key,
      belongsToSidebar: true,
      icon: accessRoute.VOICE_AI_RECORDS.icon,
      element: <VoiceAIRecords />,
      ...accessRoute.VOICE_AI_RECORDS,
    },
  ];
}

export const accessRoute = {
  VOICE_AI_RECORDS: {
    path: '/admin/voice-records',
    action: getActions('LIST').value,
    key: 'voiceRecords',
    moduleKey: 'voiceRecords',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-users-fill" />
      </span>
    ),
  },
};
export const getChildrenRoutes = () => {
  return route();
};

