import React, { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import {
  FireAlert,
  capitalizeFirstLetter,
  logger,
  modalNotification,
} from '../../../utils';
import {
  FormWrapper,
  FormElementWrapper,
  TextInput,
  CommonButton,
  GlobalLoader,
  Upload,
  AntTextArea,
  Select,
  RadioComponent as Radio,
  ModalWrapper,
} from '../../index';
import PaymentModal from '../../../features/modalComponent/payment';

import { roleOptions } from '../../../services/Columns/ncaProfile.service';
import {
  addCompanyProfileService,
  cancelPlanService,
  postNCAPlanPaymentService,
} from '../../../services/index.service';
import { listNCARoleService } from '../../../services/TeamSetting/role.service';
import { handleKey } from '../../UIElements/Formatter';
import { CompanyInitValues, CompanyValidation } from './validation';
import { accessRegisterRoute } from '../../../routes/Auth';
import { useSelector } from 'react-redux';

const LOADING_TYPE = {
  CANCEL: 'CANCEL',
  SAVE_CHANGES: 'SAVE_CHANGES',
  NCA_ROLE_LIST: 'NCA_ROLE_LIST',
};

function NcaCompanyProfileForm({
  formData,
  ncaSignUp = false,
  defaultKey,
  getProfile,
  companyProfile,
  getCompanyProfile,
}) {
  const [state, setState] = useState({
    ncaRoleLists: [],
    defaultPlanData: '',
    isOpenPaymentModal: false,
    isLoading: false,
    isPlanCancel: false,
    paymentFailedBillingDetails: {},
    payDuration: [],
    editFormData: {},
    roleLists: {},
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isCronPaymentPending = useSelector(
    (state) => state?.auth?.userData?.ncaBilling === 'unpaid'
  );
  const handleStateChange = (name, value) => {
    if (typeof name === 'object') {
      setState((prevState) => ({
        ...prevState,
        ...name,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleFormData = () => {
    try {
      setState({
        ...state,
        ncaRoleLists: [formData?.Role],
        editFormData: {
          ...state?.editFormData,
          ...formData?.CompanyNca,
          roleId: formData?.Role?.id,
          paymentMode: `${formData?.CompanyNca?.paymentMode}-${formData?.CompanyNca?.planAmount}`,
          viewImageUrl: formData?.CompanyNca?.logoUrl && [
            formData?.CompanyNca?.logoUrl,
          ],
        },
      });
    } catch (error) {
      logger(error);
    }
  };

  const handleUpdateCompanyDetails = async (values, defaultPlan) => {
    try {
      handleStateChange('isLoading', LOADING_TYPE.SAVE_CHANGES);
      let bodyData = {
        ...values,
        ...(defaultPlan && {
          roleId: state?.defaultPlanData?.Role?.id,
          paymentMode: `monthly-${state?.defaultPlanData?.monthlyPrice}`,
        }),
      };

      const response = await addCompanyProfileService({ bodyData });
      if (response?.success)
        if (response?.data?.billingStatus === 'failed') {
          modalNotification({
            type: 'error',
            message: t('validation.nca.paymentFailed'),
          });
          handleStateChange({
            paymentFailedBillingDetails: {
              billingId: response?.data?.billingId,
              roleId: values?.roleId,
              paymentMode: values?.paymentMode,
              ...(values?.isDefault === 1 && { isDefault: values.isDefault }),
            },
            isOpenPaymentModal: true,
          });
        } else {
          modalNotification({
            type: 'success',
            message: response?.message,
          });
        }
      getProfile();
      getCompanyProfile();
      handleStateChange('isLoading', '');
    } catch (error) {
      handleStateChange('isLoading', '');
      logger(error);
    }
  };

  const onConfirmAlert = async () => {
    await FireAlert({
      html: `<div class="bootbox-body"><p>${t(
        'text.nca.alertNote'
      )}</p><p><b>${t('text.nca.alert')}</b> </p></div>`,
      cancelButtonText: 'No',
      confirmButtonText: t('text.common.yes'),
      preConfirm: () => newConfirm(),
    });
    return true;
  };

  const newConfirm = async () => {
    await FireAlert({
      html: `<div class="bootbox-body"><p><b>${t(
        'text.nca.eliminateHistory'
      )}</b></p></div>`,
      cancelButtonText: 'No',
      confirmButtonText: t('text.common.yes'),
      preConfirm: () => onCancel(),
    });
    return true;
  };

  const onRadioAlert = async (e, formik) => {
    if (e === 0) {
      formik?.setFieldValue('roleId', undefined);
      formik?.setFieldValue('paymentMode', undefined);
      handleStateChange('editFormData', {
        ...state.editFormData,
        roleId: undefined,
        paymentMode: undefined,
        isDefault: 0,
      });
    } else {
      if (formData?.Role?.isDefaultPlan === 0) {
        delete formik.values.role;
        delete formik.values.viewImageUrl;
        let { isConfirmed } = await FireAlert({
          html: `<div class="bootbox-body"><p><b>${t(
            'text.nca.defaultPlan'
          )}</b></p>${
            isExpirePlan() ? `<p>${t('text.nca.defaultPlanBilling')}</p>` : ''
          }</div>`,
          cancelButtonText: 'No',
          confirmButtonText: t('text.common.yes'),
          preConfirm: () =>
            handleUpdateCompanyDetails(
              {
                ...formik.values,
                isDefault: e,
                roleId: state.defaultPlanData?.Role?.id,
              },
              true
            ),
        });
        if (!isConfirmed) {
          formik?.setFieldValue('isDefault', 0);
          handleStateChange('editFormData', {
            ...state.editFormData,
            isDefault: 0,
          });
        }
      } else {
        handleStateChange('editFormData', {
          ...state.editFormData,
          roleId: formData?.Role?.id,
          paymentMode: `${formData?.CompanyNca?.paymentMode}-${formData?.CompanyNca?.planAmount}`,
          isDefault: 1,
        });
      }
    }
  };

  const onCancel = async () => {
    try {
      handleStateChange('isLoading', LOADING_TYPE.CANCEL);
      const response = await cancelPlanService();
      if (response?.success) {
        modalNotification({
          type: 'success',
          message: response?.message,
        });
        navigate(accessRegisterRoute.CANCEL_NCA.path, { state: true });
      } else {
        if (response?.data?.billingStatus === 'failed') {
          modalNotification({
            type: 'error',
            message: t('validation.nca.paymentFailed'),
          });

          handleStateChange({
            paymentFailedBillingDetails: {
              billingId: response?.data?.billingId,
            },
            isPlanCancel: true,
            isOpenPaymentModal: true,
          });
        }
      }
      handleStateChange('isLoading', '');
    } catch (error) {
      handleStateChange('isLoading', '');
      logger(error);
    }
  };

  const radioOption = roleOptions();

  const convertPaymentMode = (data, e, isAPIRun) => {
    let durationArray = [];

    let getPlan = data?.rows
      .filter((item) => item.id === e)
      .map((plan) => plan?.NcaPlan);

    getPlan.forEach((item) => {
      durationArray.push(
        {
          name: `Monthly ($${parseFloat(item?.monthlyPrice).toFixed(2)})`,
          id: `monthly-${item?.monthlyPrice}`,
        },
        {
          name: `Yearly ($${parseFloat(item?.yearlyPrice).toFixed(2)})`,
          id: `yearly-${item?.yearlyPrice}`,
        }
      );
    });

    handleStateChange({
      payDuration: [...durationArray],
      isLoading: false,
      ...(isAPIRun && { defaultPlanData: data?.defaultPlan, roleLists: data }),
    });
  };

  async function onRoleChange(e, formik, isAPIRun) {
    handleStateChange('isLoading', LOADING_TYPE.NCA_ROLE_LIST);
    try {
      if (isAPIRun) {
        const { success, data } = await listNCARoleService({});
        if (success) {
          convertPaymentMode(data, e, isAPIRun);
        }
      } else {
        if (Object.keys(state?.roleLists)?.length) {
          convertPaymentMode(state?.roleLists, e, isAPIRun);
        }
      }
      if (formik) {
        formik?.setFieldValue('paymentMode', undefined);
      }
    } catch (err) {
      logger(err);
    }
  }

  const handleUpdatePlan = async () => {
    let { isConfirmed } = await FireAlert({
      html: `<div class="bootbox-body"><p>${t(
        'text.nca.updatePlan'
      )}</p></div>`,
      cancelButtonText: 'No',
      confirmButtonText: t('text.common.yes'),
      // preConfirm: () => defaultRoleAssign(e, formik),
    });
    return isConfirmed;
  };

  const handleOpenPaymentModal = async () => {
    let { isConfirmed } = await FireAlert({
      html: `<div class="bootbox-body"><p>${t(
        'text.nca.paymentPlan'
      )}</p></div>`,
      cancelButtonText: 'No',
      confirmButtonText: t('text.common.yes'),
    });

    return isConfirmed;
  };

  const handleSubmitPayNow = async (values) => {
    const bodyData = {
      ...values,
      ...state.paymentFailedBillingDetails,
    };
    const response = await postNCAPlanPaymentService(bodyData);
    if (response?.success) {
      modalNotification({ type: 'success', message: response?.message ?? '' });
      handleStateChange('isOpenPaymentModal', false);
      if (state.isPlanCancel) {
        navigate(accessRegisterRoute.CANCEL_NCA.path, { state: true });
      } else {
        getProfile();
        getCompanyProfile();
      }
    }
  };

  const getDayDifference = (firstDate, lastDate) => {
    return Math.abs(firstDate.diff(lastDate, 'days'));
  };

  function isExpirePlan() {
    const planUpdateDate = companyProfile?.CompanyNca?.planUpdateDate;
    const planStartDate = companyProfile?.CompanyNca?.planStartDate;
    const planCreatedAtDate = companyProfile?.CompanyNca?.createdAt;

    const isExpireFreePlan =
      !planUpdateDate && getDayDifference(moment(), planStartDate) > 15;
    const isExpireUpdatePlan =
      planUpdateDate && getDayDifference(moment(), planUpdateDate) > 15;
    const isExpireCreatedAtDate =
      getDayDifference(moment(), planCreatedAtDate) > 15;
    if (isExpireFreePlan || isExpireUpdatePlan || isExpireCreatedAtDate) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    if (formData) {
      handleFormData();
    }
  }, [formData, defaultKey]);

  useEffect(() => {
    if (formData?.Role?.id) onRoleChange(formData?.Role?.id, false, true);
  }, [formData]);

  // useEffect(() => {
  //   getNCARoleList();
  // }, []);

  return (
    <>
      <div className="card">
        <div className="card-inner">
          <FormWrapper
            row={true}
            onSubmit={async (values) => {
              let isOpenModal =
                values?.paymentMode === state?.editFormData?.paymentMode &&
                values?.roleId === state?.editFormData?.roleId &&
                values?.isDefault === state?.editFormData?.isDefault;

              if (values.isDefault === 1) {
                delete values.role;
                delete values.viewImageUrl;
              } else {
                delete values.viewImageUrl;
                delete values.role;
              }
              if (
                (values.isDefault === 0 &&
                  (values.roleId !== formData?.Role?.id ||
                    values.paymentMode !==
                      `${formData?.CompanyNca?.paymentMode}-${formData?.CompanyNca?.planAmount}`)) ||
                (isExpirePlan() && !isOpenModal)
              ) {
                if (isExpirePlan()) {
                  const paymentModal = await handleOpenPaymentModal();
                  if (paymentModal) {
                    handleUpdateCompanyDetails(values);
                  }
                } else {
                  let res = await handleUpdatePlan();
                  if (res) handleUpdateCompanyDetails(values);
                }
              } else {
                handleUpdateCompanyDetails(values);
              }
            }}
            enableReinitialize={true}
            initialValues={CompanyInitValues({
              ...state.editFormData,
            })}
            validation={() => CompanyValidation()}
            extraClassName="g-3"
            data={state.editFormData}
          >
            {(props) => {
              return (
                <>
                  <>
                    <FormElementWrapper
                      md={6}
                      mandatory={true}
                      label={t('text.common.companyName')}
                      element={() => (
                        <TextInput
                          name="name"
                          placeholder={t('text.common.enterCompanyName')}
                          formik={props}
                        />
                      )}
                    />
                    <FormElementWrapper
                      md={6}
                      label={t('text.nca.uploadCompanyLogo')}
                      mandatory={ncaSignUp}
                      element={() => (
                        <Upload
                          formik={props}
                          imageUrlList={props.values.viewImageUrl}
                          name="logo"
                          mediaFor="user"
                          mediaType="image"
                          publicUpload={true}
                        />
                      )}
                    />
                    <FormElementWrapper
                      md={`${ncaSignUp ? 12 : 6}`}
                      mandatory={true}
                      label={t('text.common.companyAddress')}
                      element={() =>
                        ncaSignUp ? (
                          <AntTextArea
                            className="form-control form-control-lg textarea"
                            name="address"
                            placeholder={t('text.common.enterCompanyAddress')}
                            formik={props}
                          />
                        ) : (
                          <TextInput
                            name="address"
                            placeholder={t('text.common.enterCompanyAddress')}
                            formik={props}
                          />
                        )
                      }
                    />
                    <FormElementWrapper
                      md={6}
                      mandatory={true}
                      label={t('text.common.numberOfficeStaff')}
                      element={() => (
                        <TextInput
                          name="noOfStaff"
                          type="number"
                          placeholder={t('text.common.enterNumberOfficeStaff')}
                          formik={props}
                          onWheel={(e) => e.target.blur()}
                          onKeyDown={(e) => handleKey(e)}
                        />
                      )}
                    />
                    <FormElementWrapper
                      md={6}
                      mandatory={true}
                      label={t('text.common.numberFieldTechniciansInstallers')}
                      element={() => (
                        <TextInput
                          name="noOfFieldTechnician"
                          type="number"
                          placeholder={t(
                            'text.common.enterNumberFieldTechniciansInstallers'
                          )}
                          formik={props}
                        />
                      )}
                    />
                    <FormElementWrapper
                      md={`${ncaSignUp ? 12 : 6}`}
                      mandatory={true}
                      label={t('text.common.areaServed')}
                      element={() =>
                        ncaSignUp ? (
                          <AntTextArea
                            className="form-control form-control-lg textarea"
                            name="areaServed"
                            placeholder={t('text.common.enterAreaServed')}
                            formik={props}
                          />
                        ) : (
                          <TextInput
                            name="areaServed"
                            placeholder={t('text.common.enterAreaServed')}
                            formik={props}
                          />
                        )
                      }
                    />
                  </>

                  <FormElementWrapper
                    md={12}
                    mandatory={true}
                    element={() => (
                      <Radio
                        name="isDefault"
                        radioGroupArray={radioOption}
                        onItemChange={(e) => onRadioAlert(e, props)}
                        disabled={
                          !companyProfile?.isEnable || isCronPaymentPending
                        }
                      />
                    )}
                  />
                  {props?.values?.isDefault === 1 ? (
                    <>
                      <FormElementWrapper
                        md={6}
                        label={t('text.common.role')}
                        element={() => (
                          <Select
                            name="roleId"
                            placeholder={t('text.common.selectRole')}
                            showSearch
                            formik={props}
                            size="medium"
                            options={state.ncaRoleLists.map((e) => ({
                              id: e?.id,
                              name: e?.name,
                            }))}
                            extraLoading={
                              state.isLoading === LOADING_TYPE.NCA_ROLE_LIST
                            }
                            disabled
                          />
                        )}
                      />
                      <FormElementWrapper
                        md={6}
                        label={t('text.common.paymentMode')}
                        element={() => (
                          <Select
                            name="paymentMode"
                            placeholder={t('text.common.selectDuration')}
                            options={state.payDuration.map((e) => ({
                              id: e?.id,
                              name: e?.name,
                            }))}
                            showSearch
                            formik={props}
                            size="medium"
                            disabled
                          />
                        )}
                      />
                    </>
                  ) : (
                    <>
                      <FormElementWrapper
                        md={6}
                        mandatory={true}
                        label={t('text.nca.whichRoleWouldYouLike')}
                        element={() => (
                          <Select
                            name="roleId"
                            placeholder={t('text.common.selectRole')}
                            showSearch
                            formik={props}
                            size="medium"
                            onItemChange={(e) => onRoleChange(e, props, false)}
                            disabled={
                              !companyProfile?.isEnable || isCronPaymentPending
                            }
                            options={
                              state.roleLists?.rows
                                ? state.roleLists?.rows.map((e) => ({
                                    id: e?.id,
                                    name: e?.name,
                                  }))
                                : []
                            }
                            extraLoading={
                              state.isLoading === LOADING_TYPE.NCA_ROLE_LIST
                            }
                          />
                        )}
                      />

                      <FormElementWrapper
                        md={6}
                        mandatory={true}
                        label={t('text.nca.WouldYouLikeToPay')}
                        element={() => (
                          <Select
                            name="paymentMode"
                            placeholder={t('text.common.selectDuration')}
                            options={state.payDuration.map((e) => ({
                              id: e?.id,
                              name: e?.name,
                            }))}
                            showSearch
                            formik={props}
                            size="medium"
                            disabled={
                              !companyProfile?.isEnable || isCronPaymentPending
                            }
                            extraLoading={
                              state.isLoading === LOADING_TYPE.NCA_ROLE_LIST
                            }
                          />
                        )}
                      />
                    </>
                  )}
                  <FormElementWrapper
                    md={12}
                    className={`${ncaSignUp ? 'text-center' : 'text-end'}`}
                    element={() => (
                      <>
                        <CommonButton
                          className={`btn btn-danger ${
                            ncaSignUp ? 'btn-lg' : 'me-2'
                          }`}
                          disabled={
                            state.isLoading === LOADING_TYPE.CANCEL ||
                            isCronPaymentPending
                          }
                          loading={state.isLoading === LOADING_TYPE.CANCEL}
                          onClick={onConfirmAlert}
                        >
                          {state.isLoading === LOADING_TYPE.CANCEL && (
                            <GlobalLoader
                              animation="grow"
                              size="sm"
                              variant="white"
                            />
                          )}
                          {t('text.nca.cancelPlan')}
                        </CommonButton>
                        <CommonButton
                          type="submit"
                          className={`btn btn-primary ${
                            ncaSignUp ? 'btn-lg' : ''
                          }`}
                          disabled={
                            state.isLoading === LOADING_TYPE.SAVE_CHANGES
                          }
                          loading={
                            state.isLoading === LOADING_TYPE.SAVE_CHANGES
                          }
                        >
                          {state.isLoading === LOADING_TYPE.SAVE_CHANGES && (
                            <GlobalLoader
                              animation="grow"
                              size="sm"
                              variant="white"
                            />
                          )}
                          {ncaSignUp
                            ? t('text.common.saveEndNext')
                            : t('text.common.saveChanges')}
                        </CommonButton>
                      </>
                    )}
                  />
                </>
              );
            }}
          </FormWrapper>
          <div className="bq-note mt-3">
            <div className="bq-note-text">
              <div className="nk-block-head nk-block-head-sm nk-block-between">
                <h5 className="title">Notes:</h5>
              </div>
              <ul className="list list-sm list-checked">
                <li>15 days free trial after registration for one time.</li>
                <li>
                  You can cancel or update your plan within 15 days free trial
                  without paying.
                </li>
                <li>
                  After 15 days if you have to cancel or update your plan then
                  you have to pay bill according to your current plan.
                </li>
                <li>
                  Once you update your plan you can't update current plan for 15
                  days.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {state.isOpenPaymentModal && (
        <ModalWrapper
          show={state.isOpenPaymentModal}
          onCancel={() => handleStateChange('isOpenPaymentModal', false)}
          heading={t('text.customerPayment.payNow')}
          content={
            <PaymentModal onSubmit={(values) => handleSubmitPayNow(values)} />
          }
        ></ModalWrapper>
      )}
    </>
  );
}

export default NcaCompanyProfileForm;
