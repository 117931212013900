import { getActions } from '../../services/Auth/index.service';
import { VoiceAIRecords } from '../../views/VoiceAIRecords';
import { ChatbotQuestionAnswerRecords } from '../../views/ChatbotQuestionAnswerRecord';

export default function route() {
  return [
    {
      private: true,
      path: accessRoute.CHATBOT_QUESTION_ANSWER_RECORDS.path,
      name: 'Questionnaire Records',
      key: accessRoute.CHATBOT_QUESTION_ANSWER_RECORDS.key,
      belongsToSidebar: true,
      icon: accessRoute.CHATBOT_QUESTION_ANSWER_RECORDS.icon,
      element: <ChatbotQuestionAnswerRecords />,
      ...accessRoute.CHATBOT_QUESTION_ANSWER_RECORDS,
    },
  ];
}

export const accessRoute = {
  CHATBOT_QUESTION_ANSWER_RECORDS: {
    path: '/admin/questionnaire-log',
    action: getActions('LIST').value,
    key: 'questionnaireLog',
    for: ['admin', 'subadmin'],
    moduleKey: 'questionnaireLog',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-help-fill" />
      </span>
    ),
  },
};
export const getChildrenRoutes = () => {
  return route();
};

