import { SUPPLY_HOUSE_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';

export const getSupplyHouseService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...SUPPLY_HOUSE_ENDPOINT?.getSupplyHouseList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const deleteSupplyHouseService = async ({ bodyData }) => {
  try {
    const payload = {
      ...SUPPLY_HOUSE_ENDPOINT.deleteSupplyHouse,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const updateSupplyHouseStatus = async ({ bodyData }) => {
  try {
    const payload = {
      ...SUPPLY_HOUSE_ENDPOINT.statusUpdateSupplyHouse,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const addSupplyHouseService = async ({body}) => {
  try {
    const payload = {
      ...SUPPLY_HOUSE_ENDPOINT.addSupplyHouse,
      bodyData:body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const editSupplyHouseService = async ({ body, id }) => {
  try {
    const payload = {
      ...SUPPLY_HOUSE_ENDPOINT.editSupplyHouse(id),
      bodyData:body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getSupplyHouseDetailsService = async ({ id }) => {
  try {
    const payload = {
      ...SUPPLY_HOUSE_ENDPOINT.getSupplyHouseDetails(id),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
