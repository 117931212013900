export const validation = {
  componyName: 'Company name is required.',
  dateOfBirth: 'Date of birth is required.',
  homeAddress: 'Home address is required.',
  employerIdNo: 'Employer ID number is required.',
  uploadDocument1: 'Document is required.',
  uploadDocument2: 'Document is required.',
  bankName: 'Bank name is required.',
  bankHolderName: 'Bank holder name is required.',
  accountNumber: 'Please enter account number',
  routingNumber: 'Bank account routing number is required.',
  bankLocation: 'Bank location is required.',
  email: 'Email is required.',
  businessType: 'Business type',
  industry: 'Please select industry',
  firstName: 'First name is required.',
  lastName: 'Last name is required.',
  jobTitle: 'Job title is required.',
  address: 'Address is required.',
  state: 'State is required.',
  city: 'City is required.',
  zipCode: 'Zip code is required.',
  phoneNumber: 'Phone number is required.',
  socialNumber: 'Social security number is required.',
  documentFront: 'Front side document is required.',
  documentBack: 'Back side document is required.',
  scheduledWorkAreaRadius: 'Schedule job radius is required. ',
  emergencyWorkAreaRadius: 'Emergency Job radius is required.  ',
  adminCommissionFees: 'Commission fee is required.',
  adminCommissionFeesValid: 'Please enter valid admin commission fee',
  adminCommissionFeesNegative: 'Admin commission fees should not be negative ',
  adminCommissionFeesMoreThan:
    'Admin commission fees should not be greater than 100 %',
  routingMinValid: 'Please enter minimum 9 characters.',
  companyName: 'Company name is required.',
  business: 'Doing business as is required.',
  employeeNumber: 'Employer identification number is required. ',
  businessWebsite: 'Business website is required.',
  businessIdNumber: 'Business ID number is required. ',
  vatNumber: 'Vat number is required.',
  accountNumberMatch: 'Please enter valid account number',
  accountMaxValid: 'Please enter less than 17 digit.',
  accountMinValid: 'Please enter at least 6 digit.',
  docImageRequired: 'Document is required.',
  businessAddress: 'Registered business address is required.',
  employerIdNoValid: 'Please enter valid employer ID number',
  employerIdNoValidNegative: 'Employer ID number should not be negative ',
  ownershipPercentage: 'Percentage ownership of business is required',
  ownershipPercentageNegative:
    'Percentage ownership of business should not be negative',
  ownershipPercentageMoreThan:
    'Percentage ownership of business should not be greater than 100 %',
  vatValid: 'Please enter valid vat number',
};
