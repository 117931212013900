export const validation = {
  planName: 'Please enter plan name',
  discountOrder: 'Please select discount order',
  monthlyOption: 'Please select monthly option',
  yearlyOption: 'Please select yearly option',
  typeOfBenefit: 'Please select type of benefit',
  monthlyCost: 'Please enter monthly cost',
  yearlyCost: 'Please enter yearly cost',
  selectSubCategoryService: 'Please select sub category service',
  enterSavingAmount: 'Please enter saving amount',
  selectType: 'Please select saving type',
  description: 'Please enter description',
  selectDiscount: 'Please select discount',
  enterLineSavingAmount :"Please enter line item saving amount"
};
