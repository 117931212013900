import { APIRequest } from '../../helpers/index.helper';
import { EQUIPMENT_ORDER_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { logger } from '../../utils';

export const EquipmentOrderListService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...EQUIPMENT_ORDER_ENDPOINT?.getEquipmentOrderList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const putUpdateEquipmentOrderStatusService = async ({
  bodyData,
  id,
}) => {
  try {
    const payload = {
      ...EQUIPMENT_ORDER_ENDPOINT?.putUpdateEquipmentOrderStatus(id),
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const EquipmentOrderDataService = async ({ id }) => {
  try {
    const payload = {
      ...EQUIPMENT_ORDER_ENDPOINT?.getEquipmentOrderData(id),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const materialDataService = async ({ id }) => {
  try {
    const payload = {
      ...EQUIPMENT_ORDER_ENDPOINT?.getMaterialDataService(id),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
