import { APIRequest } from '../../../helpers/index.helper';
import { logger } from '../../../utils';
import { NCA_CREATE_PROFILE_ENDPOINT } from '../../../apiEndpoints/NCA/ncaCreateProfile.endpoint';

// Sign up
export const signupNCAService = async ({ body }) => {
  try {
    const payload = {
      ...NCA_CREATE_PROFILE_ENDPOINT.ncaSignUp,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const createNCACompanyProfileService = async ({ body }) => {
  try {
    const payload = {
      ...NCA_CREATE_PROFILE_ENDPOINT.createCompanyProfile,
      bodyData: body,
      isAdminToken: true,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const createNCACardService = async ({ body }) => {
  try {
    const payload = {
      ...NCA_CREATE_PROFILE_ENDPOINT.createCard,
      bodyData: body,
      isErrorData: true,
      isAdminToken: true,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const createNCABusinessDetailsService = async ({ bodyData }) => {
  try {
    const payload = {
      ...NCA_CREATE_PROFILE_ENDPOINT.createBusinessDetails,
      bodyData,
      isAdminToken: true,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const createNCABankingDetailsService = async ({ body }) => {
  try {
    const payload = {
      ...NCA_CREATE_PROFILE_ENDPOINT.createBankingDetails,
      bodyData: body,
      isAdminToken: true,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
// Get
export const getNCAProfileService = async () => {
  try {
    const payload = {
      ...NCA_CREATE_PROFILE_ENDPOINT.getNCAProfile,
      isAdminToken: true,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getNCAProfileBusinessService = async () => {
  try {
    const payload = {
      ...NCA_CREATE_PROFILE_ENDPOINT.getNCABusinessDetail,
      isAdminToken: true,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const putNCAPlanUpdateService = async ({ body }) => {
  try {
    const payload = {
      ...NCA_CREATE_PROFILE_ENDPOINT.putNCAPlanUpdate,
      bodyData: body,
      isAdminToken: true,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
