const url = '/v1/admin/service-category';
const customerUrl = '/v1/service-category';

export const MANAGE_SERVICES_ENDPOINT = {
  getServiceCategoryList: {
    url,
    method: 'GET',
  },
  getCustomerServiceCategoryList: {
    url:customerUrl,
    method: 'GET',
  },
  addServiceCategory: {
    url,
    method: 'POST',
  },
  deleteServiceCategory: {
    url,
    method: 'DELETE',
  },
  statusServiceCategory: {
    url: `${url}/change-status`,
    method: 'PUT',
  },
  getServiceCategoryDetails: (id) => ({
    url: `${url}/${id}`,
    method: 'GET',
  }),
  editServiceCategory: (id) => ({
    url: `${url}/${id}`,
    method: 'PUT',
  }),
};
