import i18next, { t } from 'i18next';
import { getMultiSelectSearchFilter } from '../../components';
import {
  checkValidCount,
  checkValidData,
  commasFormatter,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { accessRoute } from '../../routes/Dashboard';
import { accessRoute as operatingRoute } from '../../routes/OperatingProcedure';
import { capitalizeFirstLetter, readMoreTextShow } from '../../utils';
import {
  getLessonListService,
  getModuleListService,
  getTupleStatusList,
  ManageServices,
} from '../index.service';

export const operatingProcedureTable = ({
  Action,
  onFilterChange,
  Toggle,
  filter,
  page,
  sizePerPage,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      render: (cell, row, index) =>
        serialNumberFormatter({ page, index, sizePerPage }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'title',
      title: i18next.t('text.operating.lessonTitle'),
      sorter: true,
      render: (cell, row) =>
        readMoreTextShow(
          checkValidData(cell),
          i18next.t('text.operating.lessonTitle'),
          20
        ),
      ...getMultiSelectSearchFilter({
        dataIndex: 'title',
        onFilterChange,
        name: 'lesson title',
        list: [],
        mode: 'multiple',
        listService: getLessonListService,
        scope: 'all',
        filter,
        listKeys: { nameKey: 'title', nameId: 'title' },
      }),
    },
    {
      dataIndex: 'type',
      title: i18next.t('text.operating.typeOfSop'),
      sorter: true,
      render: (cell, row) =>
        cell === 'provider'
          ? checkValidData(capitalizeFirstLetter('Service Provider'))
          : checkValidData(capitalizeFirstLetter(cell)),
      ...getMultiSelectSearchFilter({
        dataIndex: 'type',
        onFilterChange,
        filter,
        name: 'type',
        list: [],
        mode: false,
        listService: SOPOptions,
      }),
    },
    {
      dataIndex: 'manageService',
      filterDataIndex: ['serviceIds'],
      title: i18next.t('text.operating.manageService'),
      sorter: true,
      exportKey: 'LessonServices',
      exportFormatter: (cell, row) =>
        row?.LessonServices?.length > 0
          ? commasFormatter(
              row?.LessonServices?.map((item) => item?.ServiceCategory?.name)
            )
          : 'N/A',
      render: (cell, row) =>
        row?.LessonServices?.length > 0
          ? readMoreTextShow(
              commasFormatter(
                row?.LessonServices?.map((item) => item?.ServiceCategory?.name)
              ),
              i18next.t('text.operating.manageService')
            )
          : 'N/A',
      ...getMultiSelectSearchFilter({
        dataIndex: 'serviceIds',
        onFilterChange,
        name: 'services',
        list: [],
        mode: 'multiple',
        listService: ManageServices.ListServiceCategoryService,
        filter,
        scope: 'activeServiceCategory',
        addAll: true,
      }),
    },
    {
      dataIndex: 'adminModules',
      filterDataIndex: ['moduleIds'],
      title: i18next.t('text.operating.adminModule'),
      sorter: true,
      // For export data with formatter start
      exportKey: 'LessonModules',
      exportFormatter: (cell, row) =>
        row?.LessonModules?.length > 0
          ? commasFormatter(
              row?.LessonModules?.map((item) => item?.Module?.name)
            )
          : 'N/A',
      // For export data with formatter end
      render: (cell, row) =>
        row?.LessonModules?.length > 0
          ? readMoreTextShow(
              row?.LessonModules?.map((item) =>
                capitalizeFirstLetter(`${item?.Module?.name}, `)
              ),
              i18next.t('text.operating.adminModule')
            )
          : 'N/A',
      ...getMultiSelectSearchFilter({
        dataIndex: 'moduleIds',
        onFilterChange,
        name: 'admin module',
        list: [],
        mode: 'multiple',
        listService: getModuleListService,
        filter,
        addAll: true,
      }),
    },
    {
      dataIndex: 'questionCount',
      title: i18next.t('text.operating.numberOfQue'),
      sorter: true,
      render: (cell, row) => checkValidCount(cell),
    },
    {
      dataIndex: 'isRequired',
      title: i18next.t('text.operating.required'),
      sorter: true,
      exportFormatter: (cell, row) => {
        return row?.isRequired === 1 ? 'Yes' : 'No';
      },
      render: (cell, row) => {
        return row?.isRequired === 1 ? 'Yes' : 'No';
      },
      ...getMultiSelectSearchFilter({
        dataIndex: 'isRequired',
        onFilterChange,
        name: 'required',
        list: [],
        mode: false,
        listService: RequiredOptions,
        filter,
      }),
    },
    {
      dataIndex: 'status',
      title: i18next.t('text.localization.status'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => {
        return <Toggle row={row} cell={cell} />;
      },
      ...getMultiSelectSearchFilter({
        dataIndex: 'status',
        onFilterChange,
        filter,
        name: 'status',
        list: [],
        mode: false,
        listService: getTupleStatusList,
      }),
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.localization.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => {
        return <Action row={row} cell={cell} />;
      },
    },
  ];
};

export const operatingProcedureBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.operating.title'),
    },
  ];
};

export const addLessonBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: operatingRoute.OPERATING_PROCEDURE.path,
      name: i18next.t('text.operating.title'),
    },
    {
      path: '#',
      name: i18next.t('text.operating.addLesson'),
    },
  ];
};
export const editLessonBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: operatingRoute.OPERATING_PROCEDURE.path,
      name: i18next.t('text.operating.title'),
    },
    {
      path: '#',
      name: i18next.t('text.operating.editLesson'),
    },
  ];
};

export const SOPOptions = () => {
  return [
    {
      value: 'admin',
      label: t('text.common.admin'),
      id: 'admin',
      name: 'Admin',
    },
    {
      value: 'provider',
      label: t('text.operating.serviceProvider'),
      id: 'provider',
      name: 'Service Provider',
    },
  ];
};

export const RequiredOptions = () => {
  return [
    {
      value: 1,
      label: t('text.common.yes'),
      id: true,
      name: 'Yes',
    },
    {
      value: 0,
      label: t('text.common.no'),
      id: false,
      name: 'No',
    },
  ];
};

export const addQuestions = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: operatingRoute.OPERATING_PROCEDURE.path,
      name: i18next.t('text.operating.title'),
    },
    {
      path: '#',
      name: i18next.t('text.operating.addQuestion'),
    },
  ];
};

export const questionType = () => {
  return [
    {
      name: 'Single Answer',
      value: 'single_answer',
      label: t('text.operating.singleAnswer'),
    },
    {
      name: 'Multiple Answer',
      value: 'multiple_answer',
      label: t('text.operating.multipleAnswer'),
    },
  ];
};

export const adminOption = () => {
  return [
    {
      name: 'Test1',
      id: 1,
    },
    {
      name: 'Test2',
      id: 2,
    },
  ];
};

export const lessonListInfoBreadcrumbs = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: operatingRoute.OPERATING_PROCEDURE.path,
      name: i18next.t('text.operating.title'),
    },
    {
      path: '#',
      name: 'Lesson Info',
    },
  ];
};

export const questionAnswerBreadcrumbs = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: operatingRoute.OPERATING_PROCEDURE.path,
      name: i18next.t('text.operating.title'),
    },
    {
      path: '#',
      name: t('text.common.lessonQueAns'),
    },
  ];
};
