export const LOG_ENDPOINT = {
  getLogApi: {
    url: '/v1//winston-files',
    method: 'GET',
  },
  getLogDetailApi: {
    url: '/v1/winston-logs',
    method: 'GET',
  },
  getCountApi: {
    url: '/v1/winston-logs/level-count',
  },
};

