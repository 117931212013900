import React from 'react';
import { useTranslation } from 'react-i18next';
import { upperCaseHandler } from '../../utils';

function ProcedureView({ pendingData, completeData }) {
  const { t } = useTranslation();

  return (
    <div className="card">
      <div className="card-inner">
        <div className="bq-note">
          <div className="bq-note-text">
            <div className="nk-block-head nk-block-head-sm nk-block-between">
              <h5 className="title">{t('text.common.pendingTitle')}</h5>
            </div>
            <ul className="list list-sm list-checked">
              {pendingData?.length > 0 ? (
                pendingData?.map((item) => {
                  return (
                    <>
                      <li>{upperCaseHandler(item?.title)}</li>
                    </>
                  );
                })
              ) : (
                <li>{t('text.common.NoDataFound')}</li>
              )}
            </ul>
          </div>
          <div className="bq-note-text mt-3">
            <div className="nk-block-head nk-block-head-sm nk-block-between">
              <h5 className="title">{t('text.common.completed')}</h5>
            </div>
            <ul className="list list-sm list-checked">
              {completeData?.length > 0 ? (
                completeData?.map((item) => {
                  return (
                    <>
                      <li>{upperCaseHandler(item?.title)}</li>
                      {item?.score && <p>Score : {item?.score}%</p>}
                    </>
                  );
                })
              ) : (
                <li>{t('text.common.NoDataFound')}</li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProcedureView;
