import React from 'react';
import { useParams } from 'react-router-dom';
import ListService from '../../../PriceManagement/ManageService/Services/List';

function ViewNCAServiceType() {
  const { userId } = useParams();
  return <ListService userId={userId} ncaEdit={true} />;
}

export default ViewNCAServiceType;
