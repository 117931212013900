export const text = {
  title: 'Home Inventory',
  providerCompleted: 'Provider Completed',
  dateEquipment: 'Date Equipment Added',
  customerPhoneNumber: 'Customer Phone Number',
  customerName: 'Customer Name',
  customerNamePlaceholder: 'customer name',
  customerEmail: 'Customer Email',
  customerEmailPlaceholder: 'customer email',
  equipmentInstalled: 'Equipment Installed',
  equipmentInstalledPlaceholder: 'equipment installed',
  brand: 'Brand',
  modal: 'Model #',
  serial: 'Serial #',
  modelPlaceholder: 'model',
  serialPlaceholder: 'Serial',
  brandPlaceholder: 'brand',
  installationDate: 'Installation Date',
  applicationType: 'Application Type',
  typeOfPurchase: 'Type Of Purchase',
  systemCondition: 'System  Condition',
  applicationTypePlaceholder: 'application type',
  typeOfPurchasePlaceholder: 'type of purchase',
  systemConditionPlaceholder: 'system  condition',
  systemNotes: 'System Notes',
  linksToPhotosUploaded: 'Links to photos uploaded',
  customerAddress: 'Customer Address',
  installedEquipmentImage: 'Installed Equipment Image',
  provider: 'provider',
  noImageFound: 'No Image Found',
};
