import { APIRequest } from '../../helpers/index.helper';
import { INDUSTRY_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { logger } from '../../utils';

export const industryService = {
  getIndustryService: async ({ queryParams, type }) => {
    try {
      const payload = {
        ...INDUSTRY_ENDPOINT.industry,
        queryParams,
        isAdminToken: true,
      };

      const res = await APIRequest(payload);
      if (type === 'all') {
        return res?.data?.rows;
      }
      return res;
    } catch (err) {
      logger(err);
    }
  },
  addIndustryService: async ({ body }) => {
    try {
      const payload = {
        ...INDUSTRY_ENDPOINT.addIndustry,
        bodyData: body,
      };
      const res = await APIRequest(payload);
      return res;
    } catch (err) {
      logger(err);
    }
  },
  //uncomment delete service

  // deleteIndustryService: async ({ id, body }) => {
  //   try {
  //     const payload = {
  //       ...INDUSTRY_ENDPOINT.deleteIndustry,
  //       bodyData: body,
  //     };

  //     const res = await APIRequest(payload);
  //     return res;
  //   } catch (err) {
  //     console.log(err);
  //     logger(err);
  //   }
  // },

  getIndustryByIdService: async ({ resourceId }) => {
    try {
      const payload = {
        ...INDUSTRY_ENDPOINT.getIndustryById(resourceId),
      };

      const res = await APIRequest(payload);
      return res;
    } catch (err) {
      console.log(err);
      logger(err);
    }
  },

  updateIndustryStatus: async (bodyData) => {
    try {
      const payload = {
        ...INDUSTRY_ENDPOINT?.updateIndustryStatusId,
        bodyData,
      };

      const res = await APIRequest(payload);
      return res;
    } catch (err) {
      console.log(err);
      logger(err);
    }
  },
  updateIndustry: async ({ resourceId, body }) => {
    try {
      const payload = {
        ...INDUSTRY_ENDPOINT.updateIndustryId(resourceId),
        bodyData: body,
      };

      const res = await APIRequest(payload);
      return res;
    } catch (err) {
      console.log(err);
      logger(err);
    }
  },
};
