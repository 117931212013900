import React from 'react';
import { useTranslation } from 'react-i18next';
import { TablePlus, PageContainerWrapper } from '../../../components';
import { getNotificationService } from '../../../services/index.service';
import { useTable } from '../../../hooks/index.hook';
import {
  notificationBreadcrumb,
  notificationTableColumn,
} from '../../../services/Columns';

function ListNotification() {
  const { t } = useTranslation();

  // For get tags list
  const [, tableProps] = useTable(getNotificationService);
  const {
    data,
    isLoading,
    count,
    page,
    noOfPages,
    sizePerPage,
    filter,
    sortBy,
    sortType,
  } = tableProps;

  // For breadcrumb
  const breadcrumb = notificationBreadcrumb();
  const columns = notificationTableColumn({ page, sizePerPage });

  return (
    <>
      <PageContainerWrapper
        heading={t('text.notification.title')}
        breadcrumb={breadcrumb}
        listingHeader={false}
      >
        <TablePlus
          hasLimit
          noOfPage={noOfPages}
          sizePerPage={sizePerPage}
          page={page}
          count={count}
          tableData={data}
          tableColumns={columns}
          tableLoader={isLoading}
          searchField
          resetAll={false}
          selectRow
          showAction={false}
          filter={filter}
          sortBy={sortBy}
          sortType={sortType}
        />
      </PageContainerWrapper>
    </>
  );
}

export default ListNotification;
