import React, { useEffect } from 'react';
import { CLOUD_RENT, CLOUD_RENT_UI } from '../../config';

const LexChatbot = ({ pathname }) => {
  useEffect(() => {
    // Function to load Lex script
    const loadScript = () => {
      const script = document.createElement('script');
      script.id = 'chat-script';
      script.src = CLOUD_RENT_UI;
      // 'https://d1j2jbb96sbz8q.cloudfront.net/lex-web-ui-loader.min.js';
      script.async = true;

      document.head.appendChild(script);

      script.onload = () => {
        // Lex chatbot configuration and loading logic
        const loaderOpts = {
          baseUrl: CLOUD_RENT,
          // baseUrl: 'https://d1j2jbb96sbz8q.cloudfront.net/',
          shouldLoadMinDeps: true,
        };
        const loader = new window.ChatBotUiLoader.IframeLoader(loaderOpts);
        loader
          .load({
            ui: {
              toolbarTitle: 'Good Guys Booking',
            },
          })
          .then(() => {
            loader.api.deleteSession();
            const today = new Date();
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get timezone
            loader.api.postText(today.toLocaleDateString('en-US')+' '+timeZone,timeZone);
          })
          .catch((error) => {
            console.log(error);
          });
      };
    };

    // Load or remove script based on the pathname
    if (pathname === '/') {
      loadScript();
    } else {
      const scriptElementGet = document.getElementById('lex-web-ui-iframe');
      if (scriptElementGet) {
        scriptElementGet.remove();
      }
    }

    // Cleanup function to remove the script when the component unmounts
    return () => {
      const scriptElementGetTest = document.getElementById('lex-web-ui-iframe');
      if (scriptElementGetTest) {
        scriptElementGetTest.remove();
      }
    };
  }, [pathname]);

  return (
    <div>
      {/* Add any additional React components or content related to the Lex chatbot */}
    </div>
  );
};

export default LexChatbot;
