import { getActions } from '../../services/index.service';
import { ManageSlot } from '../../views/index.view';

export default function route() {
  return [
    {
      private: true,
      name: accessRoute.MANAGE_SLOT.name,
      key: accessRoute.MANAGE_SLOT.key,
      path: accessRoute.MANAGE_SLOT.path,
      icon: accessRoute.MANAGE_SLOT.icon,
      belongsToSidebar: true,
      element: <ManageSlot />,
      ...accessRoute.MANAGE_SLOT,
    },
  ];
}

export const accessRoute = {
  MANAGE_SLOT: {
    path: '/admin/time-slot',
    name: 'Manage Slot',
    action: getActions('LIST').value,
    moduleKey: 'slot',
    key: 'Time-Slot',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-calendar-alt-fill" />
      </span>
    ),
  },
};

export const getChildrenRoutes = () => {
  return route();
};
