import * as city from './city.service';
import * as state from './state.service';
import * as country from './country.service';
// export * from './state.service';
// export * from './country.service';

export const Localization = {
  ...city,
  ...state,
  ...country,
};
