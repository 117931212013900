import { COUPON_MANAGEMENT_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';

export const addCouponService = async ({ bodyData }) => {
  try {
    const payload = {
      ...COUPON_MANAGEMENT_ENDPOINT?.addCoupon,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const couponListService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...COUPON_MANAGEMENT_ENDPOINT?.getCouponList,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const editCouponCodeService = async ({ id, bodyData }) => {
  try {
    const payload = {
      ...COUPON_MANAGEMENT_ENDPOINT?.editCouponCode(id),
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const couponCodeStatusService = async (bodyData) => {
  try {
    const payload = {
      ...COUPON_MANAGEMENT_ENDPOINT?.changeCouponCodeStatus,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const deleteCouponCodeService = async ({ bodyData }) => {
  try {
    const payload = {
      ...COUPON_MANAGEMENT_ENDPOINT?.deleteCouponCode,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getCouponCodeService = async (id) => {
  try {
    let queryParams = {};
    const payload = {
      ...COUPON_MANAGEMENT_ENDPOINT?.getCouponCodeDetails(id),
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
