import { APIRequest } from '../../helpers/index.helper';
import { HOME_INVENTORY_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { logger } from '../../utils';

export const homeInventoryService = {
  getHomeInventoryService: async ({ queryParams, type }) => {
    try {
      const payload = {
        ...HOME_INVENTORY_ENDPOINT?.getHomeInventoryApi,
        queryParams,
      };
      const res = await APIRequest(payload);

      if (type === 'all') {
        return res?.data?.rows;
      }
      return res;
    } catch (err) {
      logger(err);
    }
  },
};
export const getInstalledEquipmentImages = async (id) => {
  try {
    const payload = {
      ...HOME_INVENTORY_ENDPOINT?.getInstalledEquipmentImages(id),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
export const getBrandService = async () => {
  try {
    const payload = {
      ...HOME_INVENTORY_ENDPOINT?.getBrandApi,
    };
    const res = await APIRequest(payload);
    return res?.data?.rows;
  } catch (err) {
    logger(err);
  }
};
export const getQuoteTypeService = async () => {
  try {
    const payload = {
      ...HOME_INVENTORY_ENDPOINT?.getQuoteTypeApi,
    };
    const res = await APIRequest(payload);

    return res?.data?.rows;
  } catch (err) {
    logger(err);
  }
};
export const getSerialNoService = async () => {
  try {
    const payload = {
      ...HOME_INVENTORY_ENDPOINT?.getSerialNoApi,
    };
    const res = await APIRequest(payload);

    return res?.data?.rows;
  } catch (err) {
    logger(err);
  }
};
export const getModelService = async () => {
  try {
    const payload = {
      ...HOME_INVENTORY_ENDPOINT?.getModelApi,
    };
    const res = await APIRequest(payload);
    return res?.data?.rows;
  } catch (err) {
    logger(err);
  }
};
