import { Image } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function PhotoContainer({ photoData }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="card">
        <div className="card-inner">
          <Image.PreviewGroup>
            <div className="row g-4">
              {photoData?.length > 0 ? (
                <>
                  {photoData?.map((item) => {
                    return (
                      <>
                        <div className="col-lg-3">
                          <div className="googleMediaListImage">
                            <Image
                              src={
                                item?.insights?.viewCount
                                  ? item?.thumbnailUrl
                                  : item?.googleUrl
                              }
                            />
                            <span className="googleMediaListImage_icon">
                              <em className="icon ni ni-eye-fill"></em>
                              {item?.insights?.viewCount}
                            </span>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </>
              ) : (
                t('text.googleConnect.noImageFound')
              )}
            </div>
          </Image.PreviewGroup>
        </div>
      </div>
    </>
  );
}
