import { getActions } from '../../services/index.service';
import { ViewSiteStatistics } from '../../views/index.view';

export default function route() {
  return [
    {
      path: accessRoute.SITE_STATS.path,
      private: true,
      name: 'Site Statistics',
      key: accessRoute.SITE_STATS.path,
      belongsToSidebar: true,
      icon: accessRoute.SITE_STATS.icon,
      element: <ViewSiteStatistics />,
      ...accessRoute.SITE_STATS,
    },
  ];
}

export const accessRoute = {
  SITE_STATS: {
    path: '/admin/site-statistics',
    moduleKey: 'site-statistics',
    action: getActions('LIST').value,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-bar-chart-fill" />
      </span>
    ),
    order: 2,
  },
};

export const getChildrenRoutes = () => {
  return route();
};
