import loadable from '@loadable/component';
import FullPageLoader from '../../components/UIElements/Loader/fullPageLoader';
export const ListLocation = loadable(() => import('./GeoLocation/List'), {
  fallback: <FullPageLoader />,
});
export const EditLocation = loadable(() => import('./GeoLocation/Edit'), {
  fallback: <FullPageLoader />,
});
export const AddLocation = loadable(() => import('./GeoLocation/Create'), {
  fallback: <FullPageLoader />,
});
