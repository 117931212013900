import { Outlet } from 'react-router-dom';
import { LessonInfo, LessonList, QuestionAnswer } from '../../views/LessonList';
import LessonReference from '../../views/LessonList/lessonRefrence';

export default function route() {
  return [
    {
      path: accessRoute.LESSON_LIST_VIEW.path,
      private: true,
      name: 'Lesson List',
      key: 'LessonList',
      belongsToSidebar: true,
      submenu: false,
      shouldBePartOf: ['subadmin'],
      ignorePermission: true,
      icon: accessRoute.LESSON_LIST_VIEW.icon,
      element: <Outlet />,
      children: [
        {
          private: true,
          path: accessRoute.LESSON_LIST_VIEW.path,
          name: 'lesson list',
          key: accessRoute.LESSON_LIST_VIEW.key,
          belongsToSidebar: true,
          icon: accessRoute.LESSON_LIST_VIEW.icon,
          element: <LessonList />,
          ignorePermission: true,
        },
        {
          private: true,
          path: `${accessRoute.LESSON_INFO.path}/:id`,
          name: 'lesson info',
          key: `${accessRoute.LESSON_INFO.path}/:id`,
          belongsToSidebar: false,
          icon: accessRoute.LESSON_INFO.icon,
          element: <LessonInfo />,
          ignorePermission: true,
        },
        {
          private: true,
          path: `${accessRoute.QUESTION_ANSWER.path}/:id`,
          name: 'lesson queAns',
          key: `${accessRoute.QUESTION_ANSWER.path}/:id`,
          belongsToSidebar: false,
          icon: accessRoute.QUESTION_ANSWER.icon,
          element: <QuestionAnswer />,
          ignorePermission: true,
        },
        {
          private: true,
          path: `${accessRoute.LESSON_REF.path}/:id`,
          name: 'lesson ref',
          key: `${accessRoute.LESSON_REF.path}/:id`,
          belongsToSidebar: false,
          icon: accessRoute.LESSON_REF.icon,
          element: <LessonReference />,
          ignorePermission: true,
        },
      ],
    },
  ];
}

export const accessRoute = {
  LESSON_LIST_VIEW: {
    path: '/admin/lesson-list',
    key: 'LessonList.list',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-edit" />
      </span>
    ),
  },
  LESSON_INFO: {
    path: '/admin/lesson-list/lesson-info',
    key: 'LessonList.list',
  },
  LESSON_REF: {
    path: '/admin/lesson-list/lesson-ref',
    key: 'LessonList.list',
  },
  QUESTION_ANSWER: {
    path: '/admin/lesson-list/lesson-info/question-list',
    key: 'LessonList.list',
  },
};

export const getChildrenRoutes = () => {
  return route()[0]?.children;
};
