import React from 'react';

import { BookingLogView } from '../../../../features/index.feature';
import { accessRoute } from '../../../../routes/Reports';
import { getBookingLogDetailsService } from '../../../../services/index.service';
import { paymentReportBookingLogBreadcrumb } from '../../../../services/Columns';
import { generatePath, useParams } from 'react-router-dom';
import { accessRoute as commonAccessRoutes } from '../../../../routes/CommonRoutes';

function PaymentBookingLog() {
  const breadcrumb = paymentReportBookingLogBreadcrumb();
  const { id, viewQuote } = useParams();
  const invoiceUrl = generatePath(commonAccessRoutes.INVOICE.path, {
    viewInvoice: 'quote',
    routePath: 'payment-report',
    id: id,
  });
  return (
    <>
      <BookingLogView
        apiService={getBookingLogDetailsService}
        bookingNumberKey={'BookingNumber'}
        backPageUrl={accessRoute.PAYMENT_REPORT.path}
        breadcrumb={breadcrumb}
        viewQuoteButton={viewQuote === 'isViewQuote'}
        inVoiceUrl={invoiceUrl}
      />
    </>
  );
}

export default PaymentBookingLog;
