let url = '/v1/admin/geo-fence-location';
export const MANAGE_LOCATION_ENDPOINT = {
  getGeoFenceLocationList: {
    url,
    method: 'GET',
  },
  addNCALocationList:{
    url:'/v1/nca/geo-fence-location',
    method:'POST'
  },
  getGeoFenceLocation: {
    url: '/v1/geo-fence-location',
    method: 'GET',
  },
  addGeoFenceLocation: {
    url,
    method: 'POST',
  },
  deleteGeoFenceLocation: {
    url,
    method: 'DELETE',
  },
  viewGeoFenceLocation: (id) => ({
    url: `${url}/${id}`,
    method: 'GET',
  }),
  editGeoFenceLocation: (id) => ({
    url: `${url}/${id}`,
    method: 'PUT',
  }),
  editNCAGeoFenceLocation: (id) => ({
    url: `/v1/nca/geo-fence-location/${id}`,
    method: 'PUT',
  }),
  statusGeoFenceLocation: {
    url: `${url}/change-status`,
    method: 'PUT',
  },
};
