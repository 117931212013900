import React from 'react';
import { useParams } from 'react-router-dom';
import AddGeoLocation from '../../../ManageLocation/GeoLocation/Create';

function AddNCAGeoFence() {
  const { id } = useParams();
  return <AddGeoLocation userId={id} />;
}

export default AddNCAGeoFence;
