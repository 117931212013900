import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import { uploadBulkProductImageService } from '../../../../services/index.service';
import { modalNotification } from '../../../../utils';
import {
  CommonButton,
  FormElementWrapper,
  FormWrapper,
  GlobalLoader,
  ImageElement,
  ModalComponent,
  MultiUpload,
} from '../../../../components';
import { bulkValidation } from './validation';
import { Media } from '../../../../apiEndpoints/index.endpoint';

function ProductBulkUpload({
  uploadFilter,
  uploadFilterHide,
  imageArray,
  setImageArray,
  fileState,
  setFileState,
}) {
  const [state, setState] = useState({
    isLoading: false,
  });
  const { isLoading } = state;

  const [basePathArray, setBasePathArray] = useState([]);

  const mediaUrl = (data) => {
    let tempArray = imageArray;
    let Arr = basePathArray;
    const { basePath } = data;
    Arr.push({ basePath });
    setBasePathArray([...Arr]);

    tempArray.push({ data });
    setImageArray([...tempArray]);
    setFileState({
      bulkUploadImage: basePathArray?.map((item) => {
        return item?.basePath;
      }),
    });
  };

  const removeBulkImage = (index) => {
    let tempArray = imageArray;
    let pathArray = basePathArray;
    tempArray.splice(index, 1);
    pathArray.splice(index, 1);
    setImageArray([...tempArray]);
    setBasePathArray([...pathArray]);
    setFileState({
      bulkUploadImage: basePathArray?.length > 0 ? basePathArray : null,
    });
  };

  async function uploadBulkImage() {
    setState({
      isLoading: true,
    });
    try {
      let bodyData = {
        productImages: basePathArray?.map((item) => {
          return item?.basePath;
        }),
      };
      const response = await uploadBulkProductImageService({ bodyData });

      if (response?.success) {
        modalNotification({
          type: 'success',
          message: response?.message,
        });
        setBasePathArray([]);
        uploadFilterHide();
        setState({
          isLoading: false,
        });
      }
    } catch (err) {
      console.log(err);
    }
    setState({
      isLoading: false,
    });
  }

  return (
    <>
      <ModalComponent
        backdrop
        show={uploadFilter}
        onHandleCancel={uploadFilterHide}
        title={t('text.common.uploadBtnText')}
      >
        <FormWrapper
          row={true}
          initialValues={{ ...fileState }}
          onSubmit={(values) => {
            uploadBulkImage(values);
          }}
          enableReinitialize={true}
          validation={bulkValidation}
          data={fileState}
        >
          {(props) => (
            <>
              <div className="col-md-12">
                <FormElementWrapper
                  md={12}
                  element={() => (
                    <MultiUpload
                      name="bulkUploadImage"
                      apiEndPoints={Media.bulkProductImage}
                      type="image"
                      id="bulkUploadImage"
                      mediaUrl={mediaUrl}
                      applyImageCropper={true}
                      validateFileType={[
                        'image/jpeg',
                        'image/png',
                        'image/jpg',
                      ]}
                      formik={props}
                    />
                  )}
                />
              </div>
              <div className="col-md-12">
                <div className="nk-upload-list mt-2">
                  {imageArray?.map((item, index) => (
                    <div key={index} className="nk-upload-item">
                      <div className="nk-upload-icon">
                        <ImageElement
                          previewSource={item?.data?.basePathThumbUrl}
                          alt="img"
                        />
                      </div>
                      <div className="nk-upload-info">
                        <div className="nk-upload-title">
                          <span className="title">{item?.data?.name}</span>
                        </div>
                      </div>
                      <div className="nk-upload-action">
                        <Link
                          to="#"
                          onClick={() => removeBulkImage(index)}
                          className="btn btn-icon btn-trigger"
                        >
                          <em className="icon ni ni-trash"></em>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-md-12 text-end mt-2">
                <CommonButton
                  className="btn btn-primary me-2"
                  disabled={isLoading}
                  type="submit"
                >
                  {t('text.common.upload')}
                  {isLoading && (
                    <GlobalLoader animation="grow" size="sm" variant="white" />
                  )}
                </CommonButton>
                <CommonButton
                  onClick={() => uploadFilterHide()}
                  className="btn btn-secondary"
                >
                  {t('text.common.cancel')}
                </CommonButton>
              </div>
            </>
          )}
        </FormWrapper>
      </ModalComponent>
    </>
  );
}

export default ProductBulkUpload;
