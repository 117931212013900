// import { Toggle } from '../../components';
import i18next from 'i18next';
import { Link } from 'react-router-dom';

import { numberToBoolean } from '../../components/UIElements/Formatter';

import { getTupleStatusList } from '../index.service';

export const googleAdwordsBreadcrumb = () => {
  return [
    {
      path: '/admin/dashboard',
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.googleConnect.googleAdwordsList'),
    },
  ];
};

const ModalOpenFormatter = (url, text) => {
  return (
    <>
      {text}{' '}
      <Link to="#" onClick={url} className="text-primary">
        <em className="icon ni ni-edit"></em>
      </Link>
    </>
  );
};
export const numberFormatter = (number) => {
  return `${'$'}${(number / 1000000).toFixed(2)}`;
};
export const googleAdwordsServiceTableColumn = ({
  budgetViewModal,
  onSort,
  Toggle,
  onFilterChange,
  filter,
  ToggleHealth,
  isUserCanEdit,
}) => {
  return [
    {
      dataIndex: 'name',
      title: i18next.t('text.common.name'),

      render: (cell, row) =>
        row?.list?.campaign?.name ? row?.list?.campaign?.name : '-',
    },
    {
      dataIndex: 'metrics.clicks',
      title: i18next.t('text.googleConnect.click'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) =>
        row?.list?.metrics ? row?.list?.metrics?.clicks : '-',
    },
    {
      dataIndex: 'metrics.average_cost',
      title: i18next.t('text.googleConnect.avgCost'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) =>
        row?.list?.metrics
          ? numberFormatter(row?.list?.metrics?.conversionsValue)
          : '-',
    },
    {
      dataIndex: 'metrics.cost_micros',
      title: i18next.t('text.googleConnect.cost'),
      headerClasses: 'sorting',
      sorter: true,
      // onSort: (field, order) => onSort('cost', order),
      render: (cell, row) =>
        row?.list?.metrics
          ? numberFormatter(row?.list?.metrics?.costMicros)
          : '-',
    },
    {
      dataIndex: 'metrics.impressions',
      title: i18next.t('text.googleConnect.impressions'),
      headerClasses: 'sorting',
      sorter: true,
      // onSort: (field, order) => onSort('impressions', order),
      render: (cell, row) =>
        row?.list?.metrics ? row?.list?.metrics?.impressions : '-',
    },
    {
      dataIndex: 'campaign_budget.amount_micros',
      title: i18next.t('text.googleConnect.budget'),
      headerClasses: 'sorting',
      sorter: true,
      // onSort: (field, order) => onSort('budget', order),
      render: (cell, row) =>
        isUserCanEdit
          ? ModalOpenFormatter(
              () => budgetViewModal(row?.list),

              numberFormatter(row?.list?.campaignBudget?.amountMicros)
            )
          : numberFormatter(row?.list?.campaignBudget?.amountMicros),
    },
    {
      dataIndex: 'metrics.conversions_value',
      title: i18next.t('text.googleConnect.conversionValue'),
      headerClasses: 'sorting',
      sorter: true,
      // onSort: (field, order) => onSort('conversionValue', order),
      render: (cell, row) =>
        row?.list?.metrics ? row?.list?.metrics?.conversionsValue : '-',
    },
    {
      dataIndex: 'status',
      title: i18next.t('text.common.status'),
      headerClasses: 'sorting',
      exportName: 'Status',
      sorter: false,
      onSort: (field, order) => onSort('status', order),
      render: (cell, row) => {
        return <Toggle row={row} cell={cell} />;
      },
    },
    {
      dataIndex: 'spInControl',
      title: i18next.t('text.googleConnect.sPInControl'),
      sorter: false,
      exportFormatter: (cell, row) => numberToBoolean(cell),
      render: (cell, row) => {
        return <ToggleHealth row={row} cell={cell} />;
      },
    },
  ];
};

export const selectDateFilterData = () => {
  return [
    {
      name: 'Last 30 Days',
      value: 'LAST_30_DAYS',
    },
    {
      name: 'Today',
      value: 'TODAY',
    },
    {
      name: 'Yesterday',
      value: 'YESTERDAY',
    },
    {
      name: 'Last Business Week',
      value: 'LAST_BUSINESS_WEEK',
    },
    {
      name: 'Last Month',
      value: 'LAST_MONTH',
    },
    {
      name: 'This Month',
      value: 'THIS_MONTH',
    },
  ];
};
