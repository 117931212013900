export const text = {
  title: 'Manage Location',
  heading: 'Geo Fence Locations',
  addLocation: 'Add Location',
  editLocation: 'Edit Location',
  selectLocation: 'Select Location',
  noteListOne: 'With use of this icon, you can edit exist polygon shape.',
  noteListTwo: 'With use of this icon, you can draw new polygon shape.',
  noteListThree: 'With use of this icon, you can draw new polygon lines.',
  locationName: 'Location Name',
  locationFor: 'Location For',
  pullPermit: 'Pull Permit Cost ($)',
  locationNameInput: 'Enter location name',
  locationForInput: 'Select location for',
  countryInput: 'Select country',
  pullPermitInput: 'Enter Pull Permit Cost',
};
