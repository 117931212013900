import { NCA_REPORT_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { getFilterListData, logger } from '../../utils';

export const getNcaList = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...NCA_REPORT_ENDPOINT?.getNcaList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getNcaUser = async ({ id, queryParams, type }) => {
  try {
    const payload = {
      ...NCA_REPORT_ENDPOINT?.getNcaUser(id),
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getPaymentReport = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...NCA_REPORT_ENDPOINT?.getPaymentReportList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};
export const getProviderListService = async ({ queryParams, type }) => {
  const { isFilter = false, ...restParams } = queryParams;
  try {
    const payload = {
      ...NCA_REPORT_ENDPOINT?.getProviderList,
      queryParams: restParams,
    };
    const res = await APIRequest(payload);
    if (isFilter) {
      return getFilterListData(res?.data?.rows ?? []);
    }
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getCustomerListService = async ({ queryParams, type }) => {
  const { isFilter = false, ...restParams } = queryParams;

  try {
    const payload = {
      ...NCA_REPORT_ENDPOINT?.getCustomerList,
      queryParams: restParams,
    };
    const res = await APIRequest(payload);
    if (isFilter) {
      return getFilterListData(res?.data?.rows ?? []);
    }
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getInvoiceDataService = async (bookingId) => {
  try {
    const payload = {
      ...NCA_REPORT_ENDPOINT?.getInvoice(bookingId),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const updateProviderDiscountService = async (bookingId, bodyData) => {
  try {
    const payload = {
      ...NCA_REPORT_ENDPOINT?.updateProviderDiscount(bookingId),
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
export const updateMarkAsSettledService = async (bodyData) => {
  try {
    const payload = {
      ...NCA_REPORT_ENDPOINT?.updateMarkAsSettled,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
export const getSplitPaymentService = async ({ queryParams }) => {
  let { bookingId, ...extraQuery } = queryParams;

  try {
    const payload = {
      ...NCA_REPORT_ENDPOINT?.getSplitPayment(bookingId),
      queryParams: extraQuery,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const checkPaymentService = async ({ bodyData }) => {
  try {
    const payload = {
      ...NCA_REPORT_ENDPOINT?.checkPayment,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
export const financingPaymentService = async ({ bodyData }) => {
  try {
    const payload = {
      ...NCA_REPORT_ENDPOINT?.financingPayment,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
export const creditCardPaymentService = async ({ bodyData }) => {
  try {
    const payload = {
      ...NCA_REPORT_ENDPOINT?.creditCardPayment,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
export const CashPaymentService = async ({ bodyData }) => {
  try {
    const payload = {
      ...NCA_REPORT_ENDPOINT?.cashPayment,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
export const warrantyPaymentService = async ({ bodyData }) => {
  try {
    const payload = {
      ...NCA_REPORT_ENDPOINT?.warrantyPayment,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
export const waivePaymentService = async ({ bodyData }) => {
  try {
    const payload = {
      ...NCA_REPORT_ENDPOINT?.waivePayment,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getJobAmountDetailsService = async (queryParams) => {
  try {
    const payload = {
      ...NCA_REPORT_ENDPOINT?.getJobAmountDetails,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
