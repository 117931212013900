import React from 'react';

import RequestDetails from '../CustomerApp/RequestDetails';
import { useLocation } from 'react-router-dom';

function GuestNewQuote() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('ref');

  return <RequestDetails isGuest={true} guestRefToken={token} />;
}

export default GuestNewQuote;
