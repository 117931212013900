import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Breadcrumb, GlobalLoader, NoDataFound } from '../../../components';
import { getPages } from '../../../services/CustomerApp/index.service';
import parse from 'html-react-parser';
import { Columns } from '../../../services/index.service';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

function HowItWork() {
  const { t } = useTranslation();
  const [worksData, setWorksData] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const breadcrumb = Columns.customerHowItWorksBreadcrumb(
    location?.pathname?.includes('store')
  );

  async function getTerms() {
    setLoading(true);

    try {
      const res = await getPages({ queryParams: { type: 'how_it_works' } });
      if (res?.success && res?.data?.rows) {
        setWorksData(res?.data?.rows);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
    setLoading(false);
  }
  useEffect(() => {
    getTerms();
  }, []);
  return (
    <>
      <main className="aboutPage">
        <Container>
          <Breadcrumb
            title={t('text.common.howItWorks')}
            breadcrumb={breadcrumb}
            type="customer"
          />
          <div className="aboutPage_inner">
            <div className="aboutPage_inner_terms">
              {loading ? (
                <GlobalLoader />
              ) : worksData && worksData?.length > 0 ? (
                <>
                  {worksData?.[0]?.pageDescription
                    ? parse(worksData?.[0]?.pageDescription)
                    : ''}
                </>
              ) : (
                <div className="noList m-auto text-center d-flex align-items-center justify-content-center">
                  <div className="noList_inner">
                    <NoDataFound
                      source="/nodata-found.svg"
                      text={t('text.common.noDataFound')}
                      isCustomer={'/assets/images/admin/nodata-found.svg'}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </Container>
      </main>
    </>
  );
}

export default HowItWork;
