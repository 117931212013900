import {
  CustomerLogin,
  CustomerForgetPassword,
  CustomerSignUp,
  CustomerResetPassword,
} from '../../views/index.view';

export default function route() {
  return [
    {
      path: accessRoute.CUSTOMER_LOGIN.path,
      private: false,
      name: 'Login',
      key: accessRoute.CUSTOMER_LOGIN.path,
      belongsToSidebar: false,
      icon: accessRoute.CUSTOMER_LOGIN.icon,
      element: <CustomerLogin />,
    },
    {
      path: accessRoute.CUSTOMER_SIGNUP.path,
      private: false,
      name: 'Sign Up',
      key: accessRoute.CUSTOMER_SIGNUP.key,
      belongsToSidebar: false,
      icon: accessRoute.CUSTOMER_SIGNUP.icon,
      element: <CustomerSignUp />,
    },
    {
      path: accessRoute.FORGET_PASSWORD.path,
      private: false,
      name: 'Forgot Password',
      key: accessRoute.FORGET_PASSWORD.key,
      belongsToSidebar: false,
      icon: accessRoute.FORGET_PASSWORD.icon,
      element: <CustomerForgetPassword />,
    },
    {
      path: accessRoute.RESET_PASSWORD.path,
      private: false,
      name: 'Forgot Password',
      key: accessRoute.RESET_PASSWORD.key,
      belongsToSidebar: false,
      icon: accessRoute.RESET_PASSWORD.icon,
      element: <CustomerResetPassword />,
    },
  ];
}

export const accessRoute = {
  CUSTOMER_LOGIN: {
    path: '/customer',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
  },
  CUSTOMER_SIGNUP: {
    path: '/customer/sign-up',
    key: 'customer-sign-up',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
  },
  FORGET_PASSWORD: {
    path: '/customer/forget-password',
    key: 'customer-forget',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
  },
  RESET_PASSWORD: {
    path: '/reset-password/:token',
    key: 'customer-reset',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
  },
};

export const getCustomerLoginRoutes = () => {
  return route()[0].children;
};
