import { Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ImageElement } from '../../../components';
import {
  checkValidData,
  checkValidPrice,
} from '../../../components/UIElements/Formatter';

export default function RequestDetailsModal({ type, modalData }) {
  const { t } = useTranslation();

  let discountDetails = modalData;
  let cost = 0;

  const totalCost = (discountDetails) => {
    discountDetails?.forEach((e) => {
      cost =
        cost +
        (e?.discountType === 'flat'
          ? parseFloat(e.cost)
          : e?.cost < 101
            ? e?.admin_material_total_amount > 0 ? parseFloat(e?.admin_material_total_amount / 100) * e?.cost : parseFloat(modalData?.admin_material_total_amount / 100) * e?.cost
            : 0);
    });
    return checkValidPrice(cost);
  };


  return (
    <>
      {type === 'information' && (
        <>
          <div className="requestImg">
            <ImageElement
              previewSource={modalData?.image?.imageThumbUrl}
              alt="water-heater"
            />
            <h5>{t('text.common.description')}</h5>
            <p className="description">
              {modalData?.quote_description
                ? modalData?.quote_description
                : 'No Description Found'}
            </p>
            <div className="d-flex justify-content-between amount">
              <p>{t('text.common.UpToSeerRating')}</p>

              <p>
                {modalData?.rebate?.brandRebate > 0
                  ? modalData?.rebate?.brandRebate
                  : 0}
              </p>
            </div>
            {modalData?.ahri_type && (
              <div className="d-flex justify-content-between amount">
                <p>AHRI</p>
                <p>
                  {modalData?.ahri_type ? `# ${modalData?.ahri_type}` : '-'}
                </p>
              </div>
            )}
            {modalData?.financeOption &&
            modalData?.monthlyPayment > 0 &&
            Object.keys(modalData?.financeOption)?.length ? (
              <div className=" d-flex justify-content-between ">
                <h5 className="ratingsubhead mb-0">
                  {t('text.requestViewDetails.monthlyApprovedCredit')}
                </h5>
                <p className="ratingamount mb-0 ">
                  {parseFloat(modalData?.appliedDiscount) <
                  parseFloat(modalData?.admin_material_total_amount)
                    ? checkValidPrice(modalData?.monthlyPayment)
                    : '$0.00'}
                </p>
              </div>
            ) : null}
          </div>
        </>
      )}
      {type === 'discountPrice' &&
        (modalData?.discount_list.length > 0 ? (
          <>
            <div className="table-responsive discountModal">
              <table>
                <tr>
                  <th> {t('text.requestViewDetails.appliedOn')}</th>
                  <th>{t('text.requestViewDetails.discount')}</th>
                  <th>{t('text.requestViewDetails.discountAmount')}</th>
                </tr>
                {modalData?.discount_list?.map((item) => (
                  <tr>
                    <td>
                      {modalData?.quote_type} (
                      {checkValidPrice(modalData?.admin_material_total_amount)})
                    </td>
                    <td>
                      {item?.discountType === 'flat'
                        ? checkValidData(`${'$'}${item?.cost}`)
                        : checkValidData(`${item?.cost}%`)}
                    </td>
                    <td>
                      {item?.discountType === 'flat'
                        ? checkValidPrice(item?.cost)
                        : parseFloat(item?.cost) < 100
                        ? checkValidData(
                            `${'$'}${(
                              (modalData?.admin_material_total_amount / 100) *
                              item?.cost
                            )?.toFixed(2)}`
                          )
                        : checkValidPrice(
                            modalData?.admin_material_total_amount - item?.cost
                          )}
                    </td>
                  </tr>
                ))}
              </table>
            </div>
            <div className="d-flex float-end text-end">
              <h5 className="me-5">{t('text.requestViewDetails.total')}</h5>
              <p>{totalCost(modalData?.discount_list)}</p>
            </div>
          </>
        ) : (
          '-'
        ))}
      {type === 'monthlyPayment' &&
        (modalData ? (
          <>
            <h4>{modalData?.financingTitle}</h4>
            <ul>
              <li>
                {t('text.requestViewDetails.term')} {modalData?.termsInMonth}
                {t('text.requestViewDetails.months')}
              </li>
              <li>
                {t('text.requestViewDetails.minLoanAmount')}
                {checkValidPrice(modalData?.minimumLoanAmount)}
              </li>
              <li>
                {t('text.requestViewDetails.paymentFactor')}
                {modalData?.paymentFactor}
              </li>
              <li>
                {t('text.requestViewDetails.df')} {modalData?.dealerFee}
              </li>
            </ul>
          </>
        ) : (
          '-'
        ))}

      {type === 'discountApplied' &&
        (discountDetails?.length > 0 ? (
          <>
            <Row className="row">
              <table>
                <tr>
                  <th> {t('text.requestViewDetails.appliedOn')}</th>
                  <th>{t('text.requestViewDetails.discount')}</th>
                  <th>{t('text.requestViewDetails.discountAmount')}</th>
                </tr>
                {discountDetails?.map((item) => (
                  <tr>
                    <td>
                      {item?.quoteType} (
                      {checkValidPrice(item?.admin_material_total_amount)})
                    </td>
                    <td>
                      {item?.discountType === 'flat'
                        ? checkValidData(`${'$'}${item?.cost}`)
                        : checkValidData(`${item?.cost}%`)}
                    </td>
                    <td>
                      {item?.discountType === 'flat'
                        ? checkValidPrice(item?.cost)
                        : checkValidData(
                            `${'$'}${(
                              (item?.admin_material_total_amount / 100) *
                              item?.cost
                            ).toFixed(2)}`
                          )}
                    </td>
                  </tr>
                ))}
              </table>
            </Row>
            <div className="d-flex float-end text-end">
              <h5 className="me-5">{t('text.requestViewDetails.total')}</h5>
              <p>{totalCost(discountDetails)}</p>
            </div>
          </>
        ) : (
          '-'
        ))}
      {type === 'rebates' && <p>{t('text.common.detailsRebates')}</p>}
    </>
  );
}
