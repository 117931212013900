import { getActions } from '../../services/index.service';
import { ProviderInvoiceUpdate } from '../../views/Reports/Payment';

export default function route() {
  return [
    {
      path: accessRoute.PROVIDER_INVOICE_UPDATE.path,
      private: accessRoute.PROVIDER_INVOICE_UPDATE.isSecure,
      name: accessRoute.PROVIDER_INVOICE_UPDATE.name,
      key: accessRoute.PROVIDER_INVOICE_UPDATE.key,
      belongsToSidebar: accessRoute.PROVIDER_INVOICE_UPDATE.belongsToSidebar,
      icon: accessRoute.PROVIDER_INVOICE_UPDATE.icon,
      element: <ProviderInvoiceUpdate />,
      ...accessRoute.PROVIDER_INVOICE_UPDATE,
    },
  ];
}

export const accessRoute = {
  PROVIDER_INVOICE_UPDATE: {
    path: '/admin/provider-invoice-update/:id',
    isSecure: true,
    key: 'report.payment-report',
    action: getActions('LIST').value,
    moduleKey: 'payment-report',
    belongsToSidebar: false,
    name: 'Provider Invoice Update Details',
  },
};

export const getChildrenRoutes = () => {
  return route();
};
