import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { accessRoute } from '../../../routes/OperatingProcedure';
import { addLessonBreadcrumb } from '../../../services/Columns';
import { AddEditLessonForm, PageContainerWrapper } from '../../../components';
import { backToPage, logger, modalNotification } from '../../../utils';
import { addLessonService } from '../../../services/index.service';

function CreateProcedureLesson() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const redirectUrl = accessRoute.OPERATING_PROCEDURE.path;
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const breadcrumb = addLessonBreadcrumb(id);
  const [selectedValues, setSelectedValues] = useState();
  const [selectedModuleValues, setSelectedModuleValues] = useState();

  const onSubmit = async (values) => {
    setIsLoading(true);
    try {
      let bodyData = { ...values };
      bodyData.type === 'admin' && delete bodyData.serviceIds;
      bodyData.type === 'provider' && delete bodyData.moduleIds;
      bodyData.isRequired === 0 && delete bodyData.percentage;

      const response = await addLessonService({ bodyData });
      const { success, message } = response;
      if (success) {
        modalNotification({
          type: 'success',
          message,
        });
        navigate(redirectUrl);
      }
    } catch (error) {
      logger(error);
    }
    setIsLoading(false);
  };
  const handleServiceChange = (value, formik) => {
    if (value?.length === 0) {
      setSelectedValues([]);
    } else {
      setSelectedValues(value);
    }
  };
  const handleMasterModuleChange = (value, formik) => {
    if (value?.length === 0) {
      setSelectedModuleValues([]);
    } else {
      setSelectedModuleValues(value);
    }
  };
  return (
    <div className="wide-md mx-auto">
      <PageContainerWrapper
        breadcrumb={breadcrumb}
        btnArray={['extraButton']}
        extraBtnText={t('text.common.back')}
        extraBtnClass="btn btn-outline-light bg-white"
        onExtraButtonHandleShow={() => backToPage(navigate, redirectUrl)}
        heading={
          id ? t('text.operating.editLesson') : t('text.operating.addLesson')
        }
      >
        <AddEditLessonForm
          onSubmit={onSubmit}
          loading={isLoading}
          backToPage={() => backToPage(navigate, redirectUrl)}
          selectedValues={selectedValues}
          selectedModuleValues={selectedModuleValues}
          handleServiceChange={handleServiceChange}
          handleMasterModuleChange={handleMasterModuleChange}
        />
      </PageContainerWrapper>
    </div>
  );
}

export default CreateProcedureLesson;
