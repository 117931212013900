import BookAppointment from '../../views/CustomerApp/BookingAppointment';

export default function route() {
  return [
    {
      path: accessRoute.BOOKING.path,
      private: true,
      name: accessRoute.BOOKING.name,
      key: accessRoute.BOOKING.key,
      belongsToSidebar: false,
      icon: accessRoute.BOOKING.icon,
      element: <BookAppointment />,
    },
  ];
}

export const accessRoute = {
  BOOKING: {
    path: '/customer/book-appointment',
    name: 'Book Appointment',
    key: 'book-appointment',
    for: 'customer',
    className: 'gg-my-bookings',
    icon: <em className="gg-my-bookings"></em>,
  },
};

export const getChildrenRoutes = () => {
  return route();
};
