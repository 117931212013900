import React, { useEffect, useState } from 'react';
import ProcedureView from '../../../../../features/Procedure/ProcedureView.feature';
import { TeamSetting } from '../../../../../services/TeamSetting';

function LessonsDetails({ defaultKey, userId }) {
  const [pendingData, setPendingData] = useState();
  const [completeData, setCompleteData] = useState();

  async function getLessonDetails() {
    try {
      const res = await TeamSetting.getAdminProviderLessonDetail({
        queryParams: {
          type: 'provider',
          isRequired: 'true',
          lessonUserId: userId,
          isLessonType: 'pending',
          scope: 'all',
        },
      });
      setPendingData(res?.data?.rows);
    } catch (err) {
      console.log(err);
    }
  }
  async function getLessonDetailsSingle() {
    try {
      const resSingle = await TeamSetting.getAdminProviderLessonDetail({
        queryParams: {
          type: 'provider',
          isRequired: 'true',
          lessonUserId: userId,
          isLessonType: 'completed',
          scope: 'all',
        },
      });
      setCompleteData(resSingle?.data?.rows);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getLessonDetails();
    getLessonDetailsSingle();
  }, [defaultKey]);

  return (
    <ProcedureView pendingData={pendingData} completeData={completeData} />
  );
}
export default LessonsDetails;
