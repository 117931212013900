// import { API_ENDPOINT_V1 } from '../../config/api.config';

export const HOME_ENDPOINT = {
  createAddress: {
    url: '/v1/address',
    method: 'POST',
  },
  getCustomerAddress: {
    url: '/v1/address',
    method: 'GET',
  },
};
