import i18next from 'i18next';
import {
  getInputSearchFilter,
  getMultiSelectSearchFilter,
} from '../../components';
import {
  badgeQuoteFormatter,
  checkValidData,
  checkValidPrice,
  imageNameFormatter,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { accessRoute } from '../../routes/Dashboard';
import { accessRoute as priceManagementRoutes } from '../../routes/PriceManagement';
import { TableImageFormatter, readMoreTextShow } from '../../utils';
import {
  getQuoteBrandListService,
  getQuoteTypeListService,
  getTupleStatusList,
  getQuoteSystemBtuListService,
  getQuoteSizeDescriptionService,
  getQuoteDirectionalListService,
  getQuoteMinBlowerDriverService,
  activeYesNoList,
} from '../index.service';
import { firstLetterCaps } from '../../utils/text.util';

export const quoteSystemTableColumn = ({
  Action,
  onFilterChange,
  AvailabilityToggle,
  Toggle,
  page,
  filter,
  sizePerPage,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportIdRemove: true,
      width: 70,
    },
    {
      dataIndex: 'companyName',
      title: i18next.t('text.common.companyName'),
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'companyName',
        placeholder: 'company name',
        onFilterChange,
        filter,
      }),
      width: 200,
    },
    {
      dataIndex: 'industryType',
      title: i18next.t('text.common.industryType'),
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'industryType',
        placeholder: 'industry type',
        onFilterChange,
        filter,
      }),
      width: 200,
    },
    {
      dataIndex: 'equipmentMaterialIaq',
      title: i18next.t('text.quote.equipmentMaterialIAQ'),
      sorter: true,
      render: (cell, row) => checkValidData(cell),

      ...getInputSearchFilter({
        dataIndex: 'equipmentMaterialIaq',
        placeholder: 'equipment, Material, Iaq',
        onFilterChange,
        filter,
      }),
      width: 250,
    },
    {
      dataIndex: 'quoteType',
      title: i18next.t('text.quote.quoteType'),
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getMultiSelectSearchFilter({
        dataIndex: 'quoteType',
        onFilterChange,
        filter,
        name: 'quote type',
        list: [],
        listService: getQuoteTypeListService,
        listKeys: { nameKey: 'quoteType', nameId: 'quoteType' },
        // addAll: true,
      }),
      width: 300,
    },
    {
      dataIndex: 'brand',
      title: i18next.t('text.common.brand'),
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getMultiSelectSearchFilter({
        dataIndex: 'brand',
        onFilterChange,
        filter,
        name: 'brand',
        list: [],
        listService: getQuoteBrandListService,
        listKeys: { nameKey: 'brand', nameId: 'brand' },
      }),
      width: 160,
    },
    {
      dataIndex: 'systemBtuCapacity',
      title: i18next.t('text.quote.systemBTUCapacity'),
      sorter: true,
      exportName: i18next.t('text.quote.systemBTUCapacity'),
      render: (cell, row) => checkValidData(cell),
      ...getMultiSelectSearchFilter({
        dataIndex: 'systemBtuCapacity',
        onFilterChange,
        filter,
        name: 'btu capacity',
        list: [],
        listService: getQuoteSystemBtuListService,
        listKeys: { nameKey: 'systemBtuCapacity', nameId: 'systemBtuCapacity' },
      }),
      width: 220,
    },
    {
      dataIndex: 'sizeDescription',
      title: i18next.t('text.quote.sizeDescription'),
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getMultiSelectSearchFilter({
        dataIndex: 'sizeDescription',
        onFilterChange,
        filter,
        name: 'size',
        list: [],
        listService: getQuoteSizeDescriptionService,
        listKeys: { nameKey: 'sizeDescription', nameId: 'sizeDescription' },
      }),
      width: 200,
    },
    {
      dataIndex: 'directional',
      title: i18next.t('text.common.directional'),
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getMultiSelectSearchFilter({
        dataIndex: 'directional',
        onFilterChange,
        filter,
        name: 'directional',
        list: [],
        listService: getQuoteDirectionalListService,
        listKeys: { nameKey: 'directional', nameId: 'directional' },
      }),
      width: 200,
    },
    {
      dataIndex: 'minBlowerDrive',
      title: i18next.t('text.quote.minimumBlowerDrive'),
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getMultiSelectSearchFilter({
        dataIndex: 'minBlowerDrive',
        onFilterChange,
        filter,
        name: 'minimum blower drive',
        list: [],
        listService: getQuoteMinBlowerDriverService,
        listKeys: { nameKey: 'minBlowerDrive', nameId: 'minBlowerDrive' },
      }),
      width: 230,
    },
    {
      dataIndex: 'materialListType',
      title: i18next.t('text.quote.materialListType'),
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'materialListType',
        placeholder: 'material list type',
        onFilterChange,
        filter,
      }),
      width: 350,
    },
    {
      dataIndex: 'materialName',
      title: i18next.t('text.quote.materialName'),
      sorter: true,
      render: (cell, row) =>
        readMoreTextShow(
          checkValidData(firstLetterCaps(cell)),
          i18next.t('text.quote.materialName'),
          25
        ),
      ...getInputSearchFilter({
        dataIndex: 'materialName',
        placeholder: 'material name',
        onFilterChange,
        filter,
      }),
      width: 290,
    },
    {
      dataIndex: 'materialCostToCustomer',
      title: i18next.t('text.quote.materialAdditionalCostCustomer'),
      sorter: true,
      render: (cell, row) => checkValidPrice(cell),
      ...getInputSearchFilter({
        dataIndex: 'materialCostToCustomer',
        placeholder: 'material cost to customer',
        onFilterChange,
        filter,
      }),
      width: 330,
    },
    {
      dataIndex: 'materialExpense',
      title: i18next.t('text.quote.materialExpense'),
      sorter: true,
      render: (cell, row) => checkValidPrice(cell),
      ...getInputSearchFilter({
        dataIndex: 'materialExpense',
        placeholder: 'material expense',
        onFilterChange,
        filter,
      }),
      width: 200,
    },
    {
      dataIndex: 'materialAutofill',
      title: i18next.t('text.quote.materialAutoFill'),
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'materialAutofill',
        placeholder: 'material auto fill',
        onFilterChange,
        filter,
      }),
      width: 200,
    },
    {
      dataIndex: 'optionStatus',
      title: i18next.t('text.quote.optionStatus'),
      sorter: true,
      exportKey: 'QuoteOrderAdminProfit',
      exportFormatter: (cell, row) =>
        checkValidData(row?.QuoteOrderAdminProfit?.providerQuoteOrder || '-'),

      render: (cell, row) =>
        checkValidData(row?.QuoteOrderAdminProfit?.providerQuoteOrder || '-'),
      ...getInputSearchFilter({
        dataIndex: 'optionStatus',
        placeholder: 'option status',
        onFilterChange,
        filter,
      }),
      width: 200,
    },
    {
      dataIndex: 'gasEfficiency',
      title: i18next.t('text.quote.gasEfficiency'),
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'gasEfficiency',
        placeholder: 'gas efficiency',
        onFilterChange,
        filter,
      }),
      width: 200,
    },
    {
      dataIndex: 'oldReferenceNumber',
      title: i18next.t('text.quote.oldAHRIReferenceNumber'),
      sorter: true,
      exportName: 'Old AHRI Reference Number',
      exportFormatter: (cell, row) =>
        checkValidData(row?.oldReferenceNumber || '-'),
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'oldReferenceNumber',
        placeholder: 'old AHRI reference number',
        onFilterChange,
        filter,
      }),
      width: 270,
    },
    {
      dataIndex: 'ahriType',
      title: i18next.t('text.quote.ahriType'),
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'ahriType',
        placeholder: 'AHRI type',
        onFilterChange,
        filter,
      }),
      width: 250,
    },
    {
      dataIndex: 'seriesName',
      title: i18next.t('text.common.seriesName'),
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'seriesName',
        placeholder: 'series name',
        onFilterChange,
        filter,
      }),
      width: 220,
    },
    {
      dataIndex: 'ratingsA2',
      title: i18next.t('text.quote.ahriCertifiedRatings'),
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'ratingsA2',
        placeholder:
          'AHRI certified ratings - cooling capacity (A2) - single or high stage (95F),btuh',
        onFilterChange,
        filter,
      }),
      width: 600,
    },
    {
      dataIndex: 'ratingsEerA2',
      title: i18next.t('text.quote.ahriCertifiedRatingsEER'),
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'ratingsEerA2',
        placeholder:
          'AHRI certified ratings - EER (A2) - single or high stage (95F)',
        onFilterChange,
        filter,
      }),
      width: 500,
    },
    {
      dataIndex: 'ratingsIeer',
      title: i18next.t('text.quote.ahriCertifiedRatingsIEER'),
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'ratingsIeer',
        placeholder: 'AHRI certified ratings - IEER',
        onFilterChange,
        filter,
      }),
      width: 330,
    },
    {
      dataIndex: 'ratingsSeer',
      title: i18next.t('text.quote.ahriCertifiedRatingsSEER'),
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'ratingsSeer',
        placeholder: 'AHRI certified ratings - SEER',
        onFilterChange,
        filter,
      }),
      width: 320,
    },
    {
      dataIndex: 'systemPartWarranty',
      title: i18next.t('text.quote.systemPartWarranty'),
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'systemPartWarranty',
        placeholder: 'system part warranty',
        onFilterChange,
        filter,
      }),
      width: 280,
    },
    {
      dataIndex: 'heatExchangerPartWarranty',
      title: i18next.t('text.quote.systemHeatExchangerPartWarranty'),
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'heatExchangerPartWarranty',
        placeholder: 'system heat exchanger part warranty',
        onFilterChange,
        filter,
      }),
      width: 350,
    },
    {
      dataIndex: 'benefits',
      title: i18next.t('text.common.benefits'),
      sorter: true,
      exportName: 'Benefits',
      exportFormatter: (cell, row) => row?.benefits || '',
      export: true,
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'benefits',
        placeholder: 'benefits',
        onFilterChange,
        filter,
      }),
      width: 220,
    },
    {
      dataIndex: 'description',
      title: i18next.t('text.quote.quoteTypeDescription'),
      sorter: true,
      render: (cell, row) =>
        readMoreTextShow(
          checkValidData(firstLetterCaps(cell)),
          i18next.t('text.quote.quoteTypeDescription'),
          20
        ),

      ...getInputSearchFilter({
        dataIndex: 'description',
        placeholder: 'quote type description',
        onFilterChange,
        filter,
      }),
      width: 300,
    },
    {
      dataIndex: 'miscRebate',
      title: i18next.t('text.quote.miscRebate'),
      sorter: true,
      render: (cell, row) => checkValidData(cell),

      ...getInputSearchFilter({
        dataIndex: 'miscRebate',
        placeholder: 'misc rebate',
        onFilterChange,
        filter,
      }),
      width: 220,
    },
    {
      dataIndex: 'stockAvailabilityStatus',
      title: i18next.t('text.quote.availabilityStatus'),
      sort: true,
      render: (cell, row) => {
        return row?.equipmentMaterialIaq?.trim() === 'Equipment' ? (
          <AvailabilityToggle row={row} cell={cell} />
        ) : (
          '-'
        );
      },
      ...getMultiSelectSearchFilter({
        dataIndex: 'stockAvailabilityStatus',
        onFilterChange,
        filter,
        name: 'stock availability status',
        list: [],
        mode: false,
        listService: activeYesNoList,
      }),
      width: 200,
    },
    {
      dataIndex: 'status',
      title: i18next.t('text.localization.status'),
      headerClasses: 'sorting',
      sort: true,
      render: (cell, row) => {
        return <Toggle row={row} cell={cell} />;
      },
      ...getMultiSelectSearchFilter({
        dataIndex: 'status',
        onFilterChange,
        filter,
        name: 'status',
        list: [],
        mode: false,
        listService: getTupleStatusList,
      }),
      width: 170,
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.localization.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => {
        return <Action row={row} cell={cell} />;
      },
      width: 160,
    },
  ];
};

export const quoteSystemBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.quote.quoteManagement'),
    },
  ];
};

export const quoteSystemAddBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: priceManagementRoutes.QUOTE_SYSTEM.path,
      name: i18next.t('text.quote.quoteManagement'),
    },
    {
      path: '#',
      name: i18next.t('text.quote.addQuote'),
    },
  ];
};
export const quoteEditSystemAddBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: priceManagementRoutes.QUOTE_SYSTEM.path,
      name: i18next.t('text.quote.quoteManagement'),
    },
    {
      path: '#',
      name: i18next.t('text.quote.editQuote'),
    },
  ];
};
export const optionStatusList = () => {
  return [
    {
      name: 'Good',
      value: 'good',
    },
    {
      name: 'Better',
      value: 'better',
    },
    {
      name: 'Best',
      value: 'best',
    },
    {
      name: 'Premium',
      value: 'premium',
    },
  ];
};

// Quote view image
export const quoteViewImageTableColumn = ({ Action, page, sizePerPage }) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.sno'),
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'imageThumbUrl',
      title: i18next.t('text.common.image'),
      render: (cell, row) => TableImageFormatter({ url: cell }),
    },
    {
      dataIndex: 'quoteId',
      title: i18next.t('text.common.availability'),
      sorter: true,
      render: (cell, row) => badgeQuoteFormatter(cell),
    },
    {
      dataIndex: 'image',
      title: i18next.t('text.common.imageName'),
      sorter: true,
      render: (cell, row) => imageNameFormatter(row.imageName),
    },
    {
      dataIndex: 'companyName',
      title: i18next.t('text.common.companyName'),
      sorter: true,
      render: (cell, row) => checkValidData(row?.Quote?.companyName),
    },
    {
      dataIndex: 'quoteType',
      title: i18next.t('text.quote.quoteTypeName'),
      sorter: true,
      render: (cell, row) => checkValidData(row?.Quote?.quoteType),
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.localization.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => {
        return <Action row={row} cell={cell} />;
      },
    },
  ];
};

export const quoteViewImageBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: priceManagementRoutes.QUOTE_SYSTEM.path,
      name: i18next.t('text.quote.quoteManagement'),
    },
    {
      path: '#',
      name: i18next.t('text.quote.allQuoteImages'),
    },
  ];
};
