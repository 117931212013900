let url = '/v1/nca';
export const NCA_CREATE_PROFILE_ENDPOINT = {
  // Create Signup
  ncaSignUp: {
    url: '/v1/user/signup',
    method: 'POST',
  },
  createCompanyProfile: {
    url: `${url}/nca-company-profile`,
    method: 'PUT',
  },
  createCard: {
    url: `${url}/nca-card`,
    method: 'PUT',
  },
  createBusinessDetails: {
    url: `${url}/nca-business-detail`,
    method: 'PUT',
  },
  createBankingDetails: {
    url: `${url}/nca-banking-detail`,
    method: 'PUT',
  },
  // Get
  getNCASignUp: (id) => ({
    url: `/v1/user/${id}`,
    method: 'GET',
  }),
  getNCAProfile: {
    url: `${url}/nca-profile`,
    method: 'GET',
  },
  getNCABusinessDetail: {
    url: `${url}/nca-business-detail`,
    method: 'GET',
  },
  putNCAPlanUpdate: {
    url: `${url}/plan/update`,
    method: 'PUT',
  },
};
