import { ListRequest } from '../../views/Request';
import { getActions } from '../../services/Auth/index.service';

export default function route() {
  return [
    {
      private: true,
      path: accessRoute.REQUEST.path,
      name: accessRoute.REQUEST.name,
      key: accessRoute.REQUEST.key,
      belongsToSidebar: true,
      icon: accessRoute.REQUEST.icon,
      submenu: false,
      action: accessRoute.REQUEST.action,
      element: <ListRequest />,
      ...accessRoute.REQUEST,
    },
  ];
}

export const accessRoute = {
  REQUEST: {
    path: '/admin/requests',
    key: 'requests',
    name: 'Requests',
    moduleKey: 'requests',
    action: getActions('LIST').value,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-reply-all-fill" />
      </span>
    ),
    order: 31,
  },
};

export const getChildrenRoutes = () => {
  return route();
};
