import i18next from 'i18next';
import { accessRoute } from '../../routes/CustomerApp/home';
export const reviewRatingBreadcrumbs = () => [
  {
    path: accessRoute.HOME.path,
    name: i18next.t('text.ratingReview.home'),
  },
  {
    path: '#',
    name: i18next.t('text.ratingReview.title'),
  },
];

export const quantityOptions = () => {
  return [
    {
      label: '1',
      value: 1,
    },
    {
      label: '2',
      value: 2,
    },
    {
      label: '3',
      value: 3,
    },
    {
      label: '4',
      value: 4,
    },
    {
      label: '5',
      value: 5,
    },
    {
      label: '6',
      value: 6,
    },
    {
      label: '7',
      value: 7,
    },
    {
      label: '8',
      value: 8,
    },
    {
      label: '9',
      value: 9,
    },
    {
      label: '10',
      value: 10,
    },
    {
      label: '11',
      value: 11,
    },
    {
      label: '12',
      value: 12,
    },
    {
      label: '13',
      value: 13,
    },
    {
      label: '14',
      value: 14,
    },
    {
      label: '15',
      value: 15,
    },
    {
      label: '16',
      value: 16,
    },
    {
      label: '17',
      value: 17,
    },
    {
      label: '18',
      value: 18,
    },
    {
      label: '19',
      value: 19,
    },
    {
      label: '20',
      value: 20,
    },
  ];
};
export const payOnlineGuestData = () => {
  return {
    jobNumber: '39410802',
    serviceAddress:
      'Suyash Hospital, AB Road, opposite MGM Medical College, South Tukoganj, Indore, Madhya Pradesh, India 452001 282001',
    totalAmount: '5196.09',
    remainingAmount: '5196.09',
    cardHolder: '',
    cardNumber: '',
    expMonth: '',
    expYear: '',
    cvv: '',
    alternativeEmail: '',
  };
};
