import React, { useEffect, useState } from 'react';
import { CustomTable, TablePlus } from '../../../../components';
import useTable from '../../../../hooks/useTable.hook';
import {
  Columns,
  getSplitPaymentService,
} from '../../../../services/index.service';
import { t } from 'i18next';

function SplitPaymentDetails({ providerDiscountData }) {
  const [searchData, setSearchData] = useState('');
  const [page, setPage] = useState({
    currentPage: 1,
    sizePerPage: 10,
  });
  const { currentPage, sizePerPage } = page;
  const [firstTimeFetch, setFirstTimeFetch] = useState(false);
  const [state, setState] = useState({
    loader: null,
    modal: null,
    modalId: null,
    selectedRows: [],
  });
  let bookingId = providerDiscountData?.id;
  let extraQuery = {
    bookingId,
  };
  // Extra update
  // let isAnotherTable = {
  //   sizePerPage: 10,
  //   page: 1,
  // };
  const [tableHandler, tableProps] = useTable(
    getSplitPaymentService,
    extraQuery,
    false,
    page // Extra update
  );
  const { data, isLoading, count, noOfPages, filter } = tableProps;
  const {
    onFilterChange,
    getData,
    onFilterResetChange,
    onTableSort,
    onSearch,
  } = tableHandler;
  // const { loader, modal, modalId, selectedRows, submitText } = state;

  const columns = Columns.splitPaymentColumn({
    onSort: onTableSort,
    onFilterChange: onFilterChange,
    filter: filter,
    page: currentPage,
    sizePerPage,
  });

  function onRowSelect(e) {
    try {
      setState({ ...state, selectedRows: [...e] });
    } catch (err) {
      console.log('err', err);
    }
  }

  async function handleActionSelect(e) {
    try {
      if (e.target.value === 'delete') {
        // await onDelete(selectedRows);
        getData();
      } else {
        getData();
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      if (
        (searchData.length >= 1 || searchData.length === 0) &&
        firstTimeFetch
      ) {
        onSearch(searchData);
      }
    }, 700);

    return () => {
      clearTimeout(handler);
    };
  }, [searchData]);

  useEffect(() => {
    setFirstTimeFetch(true);
  }, []);

  return (
    <CustomTable
      tableData={data}
      searchDisabled={data?.length > 1 || searchData?.length}
      tableColumns={columns}
      count={count}
      setSearchData={setSearchData}
      setPage={setPage}
      sizePerPage={sizePerPage}
      currentPage={currentPage}
      tableLoader={isLoading}
      searchPlaceholder={t('text.common.searchMessage')}
      isModalPadding={true}
      hasLimit
      handleActionSelect={handleActionSelect}
      modalParams={false}
      showPageSizeAction={false}
    />
  );
}

export default SplitPaymentDetails;
