import { createSlice } from '@reduxjs/toolkit';
import { getUserPermission } from '../../../services/TeamSetting/role.service';
import { getPurePermissionObjectFromArray } from '../../../utils/permission';
import { logger } from '../../../utils';
// import logger from '../../utilities/logger';

const init = {
  isLoaded: true,
  modules: null,
  subModules: null,
  permission: {},
  customerPermission: [],
  blockStatus: false,
  isTestCountShow: false,
  locationId: null,
  isAllowMentalHealthModule: false,
  isAllowAmazonLex: false,
};

export const permission = createSlice({
  name: 'permission',
  initialState: {
    ...init,
  },
  reducers: {
    setPermissionAction: (state, action) => {
      return (state = {
        ...state,
        isLoaded: false,
        permission: action.payload,
      });
    },
    clearPermissionAction: (state) => {
      return (state = {
        ...state,
        ...init,
      });
    },
    startLoadingAction: (state) => {
      return {
        ...state,
        isLoaded: true,
      };
    },
    stopLoadingAction: (state) => {
      return {
        ...state,
        isLoaded: false,
      };
    },

    customerPermissionAction: (state, action) => {
      return (state = {
        ...state,
        customerPermission: action.payload,
      });
    },
    setTestAction: (state, action) => {
      return (state = {
        ...state,
        blockStatus: action.payload?.blockStatus,
        isTestCountShow: action.payload?.isTestCountShow,
        isAllowMentalHealthModule: action.payload?.isAllowMentalHealthModule,
        isAllowAmazonLex: action.payload?.isAllowAmazonLex,
      });
    },
    locationIdAction: (state, action) => {
      return (state = {
        ...state,
        locationId: action.payload,
      });
    },
  },
});

// export const { setPermissionAction, clearPermissionAction } = permission;
export const {
  setPermissionAction,
  clearPermissionAction,
  startLoadingAction,
  stopLoadingAction,
  customerPermissionAction,
  setTestAction,
  locationIdAction,
} = permission.actions;
export const selectIsLoaded = (state) => state.permission?.isLoaded;
export const selectModules = (state) => state.permission?.modules;
export const selectSubModules = (state) => state.permission?.subModules;
export const selectPermission = (state) => state.permission?.permission;

export const loadPermission =
  (data, withApi = true) =>
  async (dispatch) => {
    try {
      dispatch(startLoadingAction());
      if (withApi) {
        const res = await getUserPermission({ type: 'all' });
        if (res) {
          const permissions = getPurePermissionObjectFromArray(res?.permission);
          dispatch(setPermissionAction(permissions));
          dispatch(setTestAction(res));
        }
      } else {
        const permissions = getPurePermissionObjectFromArray(data);
        dispatch(setPermissionAction(permissions));
      }
      dispatch(stopLoadingAction());
    } catch (error) {
      console.log(error);
    }
  };

export const clearPermission = () => (dispatch) => {
  try {
    dispatch(clearPermissionAction);
  } catch (error) {
    // logger(error);
  }
};

export const customerPermission = (data) => (dispatch) => {
  try {
    dispatch(customerPermissionAction(data));
  } catch (error) {
    // logger(error);
  }
};

export const updateLocationId = (data) => async (dispatch) => {
  try {
    dispatch(locationIdAction(data));
  } catch (error) {
    logger(error);
  }
};

export const getLocationId = (state) => state.permission.locationId;
export const getPermissionAlex = (state) => state.permission.isAllowAmazonLex;
export default permission.reducer;
