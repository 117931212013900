let url = '/v1/admin';

export const GEt_QUOTE_ENDPOINT = {
  getQuoteSampleList: {
    url: `${url}/quote/sample-file`,
    method: 'GET',
  },
  getQuoteBrandList: {
    url: `${url}/quote/brand`,
    method: 'GET',
  },
  getQuoteTypeList: {
    url: `${url}/quote-type`,
    method: 'GET',
  },
  getQuoteSystemBtuList: {
    url: `${url}/quote-system-btu`,
    method: 'GET',
  },
  getQuoteMaterialList: {
    url: `${url}/quote-material`,
    method: 'GET',
  },
  getQuoteDirectionalList: {
    url: `${url}/quote-directional`,
    method: 'GET',
  },
  getQuoteMinBlowerDriverList: {
    url: `${url}/quote-minimum-blower-drive`,
    method: 'GET',
  },
  getQuoteSizeDescriptionList: {
    url: `${url}/quote-size-description`,
    method: 'GET',
  },
};
