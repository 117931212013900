import Swal from 'sweetalert2';

const FireAlert = (props) => {
  return Swal.fire({
    showCancelButton: true,
    showLoaderOnConfirm: true,
    confirmButtonColor: '#1ee0ac',
    cancelButtonColor: '#d33',
    ...props,
  });
};

export default FireAlert;
