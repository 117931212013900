import loadable from '@loadable/component';
import FullPageLoader from '../../UIElements/Loader/fullPageLoader';

export const DashboardFilterForm = loadable(
  () => import('./DashboardFilter/index.filter'),
  {
    fallback: <FullPageLoader />,
  }
);
export const LocalizationFilterForm = loadable(
  () => import('./AdminSetting/index.filter'),
  {
    fallback: <FullPageLoader />,
  }
);
export const CityFilterForm = loadable(
  () => import('./AdminSetting/city.filter'),
  {
    fallback: <FullPageLoader />,
  }
);
export const GeoLocationFilterForm = loadable(
  () => import('./GeoLocationFilter/index.filter'),
  {
    fallback: <FullPageLoader />,
  }
);
export const ProvidersFilterForm = loadable(
  () => import('./ProvidersFilter/index.filter'),
  {
    fallback: <FullPageLoader />,
  }
);
export const AssignModuleFilter = loadable(
  () => import('./AssignModuleFilter/index.filter'),
  {
    fallback: <FullPageLoader />,
  }
);
export const CustomersFilterForm = loadable(
  () => import('./CustomersFilterForm/index.filter'),
  {
    fallback: <FullPageLoader />,
  }
);

export const FaqFilterForm = loadable(() => import('./FaqFilter/faq.filter'), {
  fallback: <FullPageLoader />,
});
export const IndustryFilterForm = loadable(
  () => import('./Industry/industry.filter'),
  {
    fallback: <FullPageLoader />,
  }
);
export const NotificationFilterForm = loadable(
  () => import('./Notification/notification.filter'),
  {
    fallback: <FullPageLoader />,
  }
);
export const CountryFilterForm = loadable(
  () => import('./AdminSetting/country.filter'),
  {
    fallback: <FullPageLoader />,
  }
);
export const StateFilterForm = loadable(
  () => import('./AdminSetting/state.filter'),
  {
    fallback: <FullPageLoader />,
  }
);

export const ServiceCategoryFilter = loadable(
  () => import('./ManageServiceFilter/serviceCategory.filter'),
  {
    fallback: <FullPageLoader />,
  }
);
export const ServiceTypeFilter = loadable(
  () => import('./ManageServiceFilter/serviceType.filter'),
  {
    fallback: <FullPageLoader />,
  }
);
export * from './LineItemsFilterForm/index.filter';

export const GoogleServiceFilter = loadable(
  () => import('./GoogleServiceFilter/index.filter'),
  {
    fallback: <FullPageLoader />,
  }
);
export const GoogleAdwordsFilter = loadable(
  () => import('./GoogleAdwords/index.filter'),
  {
    fallback: <FullPageLoader />,
  }
);
export const FromDateToDateFilter = loadable(
  () => import('./FromDateToDateFilter/index.filter'),
  {
    fallback: <FullPageLoader />,
  }
);
