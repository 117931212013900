import { APIRequest } from '../../helpers/index.helper';
import {
  LOGIN_ENDPOINT,
  CUSTOMER_SIGNUP_ENDPOINT,
  FOOTER_SOCIAL_LINK_ENDPOINT,
} from '../../apiEndpoints/auth.endpoint';
import { UPDATE_ADMIN_PROFILE } from '../../apiEndpoints/Profile';

export const LoginService = async (body) => {
  try {
    const payload = {
      ...LOGIN_ENDPOINT,
      bodyData: body,
    };
    return await APIRequest(payload);
  } catch (err) {
    console.log(err);
  }
};

export const SignupService = async (body) => {
  try {
    const payload = {
      ...CUSTOMER_SIGNUP_ENDPOINT,
      bodyData: body,
      isErrorData:true
    };
    return await APIRequest(payload);
  } catch (err) {
    console.log(err);
  }
};

export const updateProfileService = async (bodyData) => {
  try {
    const payload = {
      ...UPDATE_ADMIN_PROFILE,
      bodyData,
    };
    return await APIRequest(payload);
  } catch (error) {
    console.log(error);
  }
};

export const viewFooterSocialLinkService = async (queryParams) => {
  try {
    const payload = {
      ...FOOTER_SOCIAL_LINK_ENDPOINT,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
  }
};
const actions = {
  EDIT: {
    value: 'edit',
  },
  LIST: {
    value: 'view',
  },
  CREATE: {
    value: 'edit',
  },
  DELETE: {
    value: 'edit',
  },
  VIEW: {
    value: 'view',
  },
  STATUS: {
    value: 'edit',
  },
  WILDCARD: {
    value: '*',
  },
};

export const getActions = (action) => {
  return actions[action];
};
