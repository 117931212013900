import React, { useContext, useState, useEffect } from 'react';
import { ChatContext } from '../../../../context/chat.context';
import { useTranslation } from 'react-i18next';

import {
  CommonButton,
  FormWrapper,
  FormElementWrapper,
  TextInput,
  CheckBox,
  Upload,
  NoDataFound,
} from '../../../../components';
import ImageElement from '../../../../components/UIElements/ImageElement';
import ChatSearch from '../ChatSearch';
import { PlusOutlined } from '@ant-design/icons';

import toast from '../../../../utils/notifications';

import { postAddRoomService, putEditRoomService } from '../../../../services/Chat/index.service';
import { Spin } from 'antd';
import { firstLetterCaps } from '../../../../utils/text.util';
import { Badge } from 'react-bootstrap';
import { titleCaseFormatter } from '../../../../components/UIElements/Formatter';



const CreateGroup = ({ getChatRoomUserLists, chatroomDetails}) => {
  const initialState = {
    groupName:chatroomDetails?.action === 'chatEdit' ? chatroomDetails?.chatGroup?.roomName : '',
    groupLogo: null,
    members: [],
  };
  const {
    providers,
    setProviders,
    handleOpenGroupModal,
    getServiceProviderData,
    handleSearchProviders,
    isDisabledLoadMore,
    handleIncrementPerPage,
    handleResetProviderDetails,
    isProviderLoading,
    setSelectUser,
    isLoadMoreLoading,
    setIsLoadUpdate
  } = useContext(ChatContext);
  const { t } = useTranslation();
  const [groupDetails, setGroupDetails] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [imageUrl, setImageUrl] = useState();
  const [searchName, setSearchName] = useState('');
  const [selectedProvides, setSelectedProvides] = useState([]);

  const handleChangeCheckbox = (isChecked, providerId, firstName, lastName) => {
    const selectedName = firstName?.concat(' ', lastName);
    if (isChecked) {
      setSelectedProvides([
        ...selectedProvides,
        { providerName: selectedName, providerId },
      ]);
    } else {
      const uncheckProvider =
        selectedProvides?.length &&
        selectedProvides?.filter((provider) => {
          return provider.providerId !== providerId;
        });
      setSelectedProvides(uncheckProvider);
    }

    setGroupDetails({
      ...groupDetails,
      members: !groupDetails?.members?.includes(providerId)
        ? [...groupDetails?.members, providerId]
        : !isChecked
        ? groupDetails?.members?.filter(
            (memberItem) => memberItem !== providerId
          )
        : groupDetails?.members,
    });
  };

  const handleErrorValidation = () => {
    const errorObj = [];
    Object.keys(groupDetails)?.forEach((key) => {
      if (
        (!groupDetails[key]?.length ||
          (key === 'groupName' && !groupDetails[key]?.trim?.()?.length)) &&
        key !== 'groupLogo'
      ) {
        errorObj.push(key);
      }
    });

    return errorObj;
  };

  const handleCreateGroupSubmit = async () => {
    const errorResult = handleErrorValidation();

    setErrors(errorResult);
    if (errorResult?.length > 0) {
      let error = errorResult[0];
      if (error === 'groupName') {
        toast({ type: 'error', message: t('validation.chat.groupName') });
      } else if (error === 'members') {
        toast({ type: 'error', message: t('validation.chat.groupMembers') });
      }
    } else {
      setIsLoading(true);
      let body = {
        roomName: groupDetails?.groupName,
        chatType: 'group',
        image: imageUrl ? imageUrl?.basePath : '',
        userIds: groupDetails?.members,
      };
      const response = await postAddRoomService({ body });
      if (response?.success) {
        getChatRoomUserLists();
        handleOpenGroupModal();
        setSelectUser({
          chatRoom: response?.data?.rows,
        });
        toast({
          type: 'success',
          message: t('validation.chat.groupCreatedSuccess'),
        });
      }
      setIsLoading(false);
    }
  };

  const handleEditGroupSubmit = async()=>{
    const errorResult = await handleErrorValidation();
    setErrors(errorResult);
    if (errorResult?.length > 1) {
      let error = errorResult[0];
      if (error === 'groupName') {
        toast({ type: 'error', message: t('validation.chat.groupName') });
      } 

    } else {
      setIsLoading(true);

      let body = {
        roomName: groupDetails?.groupName,
        chatType: 'group',
        image: imageUrl ? imageUrl?.basePath : chatroomDetails?.chatGroup?.image,
      };
      const response = await putEditRoomService({roomId:chatroomDetails?.chatGroup?.id, body });
      if (response?.success) {
        // getChatRoomUserLists();
        handleOpenGroupModal();
        setIsLoadUpdate(true)
        // setSelectUser({
        //   chatRoom: response?.data?.rows,
        // });
        toast({
          type: 'success',
          message: t('validation.chat.groupEditSuccess'),
        });
      }
      setIsLoading(false);
    }
  }

  const getServiceProviderList = async () => {
    await getServiceProviderData();
    setSelectedProvides([]);
  };

  const handleRemoveGroupMember = (providerId) => {
    setSelectedProvides(
      selectedProvides.filter(
        (providerItem) => providerItem?.providerId !== providerId
      )
    );
    setGroupDetails((prevDetails) => ({
      ...prevDetails,
      members: groupDetails.members.filter((member) => member !== providerId),
    }));
  };
  useEffect(() => {
    getServiceProviderList();

    return () => handleResetProviderDetails();
  }, []);
  
  return (
    <div className="selectServiceProviderModal">
      <div className="nk-chat-aside-search p-0 mb-3">
        <FormWrapper row={true} initialValues={initialState} extraClassName="g-3 mb-2">
          {(props) => (
            <>
              <FormElementWrapper
                md={12}
                name="groupLogo"
                element={() => (
                  <>
                    <Upload
                      name="file"
                      formik={props}
                      imageUrl={imageUrl?.basePathThumbUrl ? imageUrl?.basePathThumbUrl: chatroomDetails?.chatGroup?.imageUrl}
                      defaultImageUrl="/assets/images/admin/groups.png"
                      isUploadLogo={true}
                      mediaFor="chatRoom"
                      mediaType="image"
                      setImageUrl={setImageUrl}
                    >
                      <PlusOutlined />
                    </Upload>
                  </>
                )}
              />
              <FormElementWrapper
                md={12}
                label={t('text.chat.groupName')}
                element={(formik) => (
                  <TextInput
                    name="groupName"
                    placeholder={t('text.chat.enterGroupName')}
                    formik={formik}
                    className="bg-white"
                    // value={groupDetails?.groupName}
                    onChange={(e) => {
                      setErrors(
                        errors.filter((error) => error !== 'groupName')
                      );
                      props.setFieldValue('groupName' , e.target.value)
                      setGroupDetails({
                        ...groupDetails,
                        groupName:e.target.value,
                      });
                    }}
                    status={errors.includes('groupName') ? 'error' : ''}
                  />
                )}
              />
            </>
          )}
        </FormWrapper>
        {selectedProvides?.length > 0 && (
          <div className="d-flex mt-2 flex-wrap groupSec">
            {selectedProvides?.map((selectedItem, i) => (
              <Badge key={i} className="p-1 mb-1 me-1" bg="light">
                {titleCaseFormatter(selectedItem?.providerName)}
                <div
                  className="groupSec_close"
                  onClick={() =>
                    handleRemoveGroupMember(selectedItem?.providerId)
                  }
                >
                  <em className="icon ni ni-cross"></em>
                </div>
              </Badge>
            ))}
          </div>
        )}
       {chatroomDetails?.action === 'chatEdit' ? null :
        <p className="mb-1">{t('text.chat.addMemberInGroup')}</p> }
        {chatroomDetails?.action === 'chatEdit' ? null :
        <ChatSearch
          search={searchName}
          handleSearch={(e) => setSearchName(e.target.value)}
          getSearchList={() => handleSearchProviders(searchName)}
          setEmptyList={() => setProviders([])}
        />
        }
      </div>

      {isProviderLoading && !providers?.length ? (
        <div className="p-2 mb-2">
          <Spin
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: 'auto',
            }}
          />
        </div>
      ) :chatroomDetails?.action === 'chatEdit' ? null : !isProviderLoading && providers?.length > 0 ? (
        <ul className="chat-list">
          {providers?.map((providerItem, idx) => (
            <li key={idx} className="chat-item mb-2">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center pe-1 pe-sm-2 mb-1 mb-sm-0 flex-shrink-0">
                  <div className="chat-media user-avatar bg-purple">
                    <ImageElement
                      previewSource={providerItem?.profilePictureUrl}
                      alt="img"
                      isProfile={true}
                    />
                  </div>
                  <div className="chat-from ms-2">
                    <div className="name">
                      {firstLetterCaps(providerItem?.firstName ?? '')}
                      &nbsp;
                      {firstLetterCaps(providerItem?.lastName ?? '')}
                    </div>
                  </div>
                </div>
                <div className="text-end">
                  <CheckBox
                    checked={groupDetails?.members?.includes(providerItem?.id)}
                    onChange={(e) =>
                      handleChangeCheckbox(
                        e?.target?.checked,
                        providerItem?.id,
                        providerItem?.firstName ?? '',
                        providerItem?.lastName ?? ''
                      )
                    }
                    withoutChecked={false}
                  />
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <NoDataFound
          source="/no-provider.svg"
          text={t('text.chat.noServiceProviderList')}
          extraClass="noDataFoundWrapper"
          isCustomer={'/assets/images/admin/no-provider.svg'}
        />
      )}
      {chatroomDetails?.action === 'chatEdit' ? null :
      <div className="text-center mt-1 pb-2">
        <CommonButton
          disabled={
            isDisabledLoadMore || isProviderLoading || isLoadMoreLoading
          }
          loading={isLoadMoreLoading}
          onClick={handleIncrementPerPage}
          className="btn btn-primary"
        >
          {t('text.chat.loadMore')}
        </CommonButton>
      </div>
       }
      <div className="border-top text-end pt-2">
        <CommonButton
          disabled={isLoading}
          onClick={chatroomDetails?.action === 'chatEdit' ? handleEditGroupSubmit: handleCreateGroupSubmit}
          loading={isLoading}
          className="btn btn-primary me-2"
        >
          {chatroomDetails?.action === 'chatEdit' ? t('text.chat.chatEdit') : t('text.chat.createGroup')}
        </CommonButton>
        <CommonButton
          className="btn btn-secondary"
          onClick={handleOpenGroupModal}
        >
          {t('text.common.close')}
        </CommonButton>
      </div>
    </div>
  );
};

export default CreateGroup;
