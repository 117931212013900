import { t } from 'i18next';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { StorePageContainer } from '../../../../components';
import ResetPasswordForm from '../../../../components/Forms/Auth/ResetPassword';
import { accessRoute } from '../../../../routes/Auth/store';
import { resetPasswordBreadcrumb } from '../../../../services/Columns';
import { resetPassword } from '../../../../services/CustomerApp/index.service';

function StoreResetPassword() {
  const [state, setState] = React.useState({ loading: false });
  const params = useParams();
  const navigate = useNavigate();
  const { token } = params;
  async function onSubmit(body) {
    try {
      body.token = token;
      setState({ ...state, loading: true });
      const res = await resetPassword({ body });
      if (res?.data) {
        setState({ ...state, loading: false });
        navigate(accessRoute.STORE_LOGIN.path);
        return true;
      }
      setState({ ...state, loading: false });
    } catch (err) {
      setState({ ...state, loading: false });
      console.log(err);
    }
  }
  return (
    <StorePageContainer breadcrumb={[]}>
      <div className="becomePage_inner">
        <div className="becomePage_signup text-center">
          <div className="becomePage_head mb-0">
            <h2 className="mb-1 font-sm">{t('text.common.resetPassword')}</h2>
          </div>
          <p>Hey, Nikola Laringo</p>
        </div>
        <div className="becomePage_auth mx-auto">
          <ResetPasswordForm onSubmit={onSubmit} />
          {/* <Form>
                                <FormGroup className="form-group position-relative" >                                                                  
                                    <Input type={password}  size="large" placeholder="First Name" />
                                        <label className="ant-label">Create New Password</label>
                                        <Link className=" input-icon">         
                                            <i onClick={Eye} className={`fa ${eye ? "gg-eye-off" : "gg-eye-on" }`}></i>
                                        </Link>
                                </FormGroup>
                                <FormGroup className="form-group position-relative" >
                                    <Input type={password2}  size="large" placeholder="First Name" />
                                        <label className="ant-label">Confirm New Password</label>
                                        <Link className=" input-icon">         
                                            <i onClick={Eye2} className={`fa ${eye2 ? "gg-eye-off" : "gg-eye-on" }`}></i>
                                        </Link>
                                </FormGroup>
                                <div className="text-center becomePage_affiliate_btn mb-0">
                                    <Link to="/affiliate-login" className="ms-auto w-100 btn-lg btn btn-primary">Submit</Link>
                                </div>
                            </Form> */}
        </div>
      </div>
    </StorePageContainer>
  );
}

export default StoreResetPassword;
