import i18next from 'i18next';
import { accessRoute } from '../../routes/Dashboard';
import { accessRoute as ncaAccessRoute } from '../../routes/AdminSetting';
import { accessRoute as ncaProfileRoute } from '../../routes/NcaProfile';
import { accessRoute as reportAccessRoute } from '../../routes/Reports';
import {
  badgeCompleteFormatter,
  ButtonFormatter,
  ButtonTextFormatter,
  checkValidData,
  checkValidPrice,
  getActiveCancelledResponse,
  // getActiveCancelledResponse,
  getBooleanResponse,
  iconFormatter,
  ModalOpenFormatter,
  // numberToBoolean,
  // numberToBooleanApposite,
  serialNumberFormatter,
  ToFixedPrice,
} from '../../components/UIElements/Formatter';
import {
  CompanyProfileForm,
  EditProfileForm,
  getInputSearchFilter,
  getMultiSelectSearchFilter,
  GlobalLoader,
  SetupCardDetailsForm,
} from '../../components';
import {
  editNCABankingService,
  // editNCABusinessService,
  editNCACardService,
  editNCACompanyProfileService,
  editNCAProfileService,
  getNCABankingService,
  getNCABusinessService,
  getNCACardService,
  getNCACompanyProfileService,
  getNCAProfileService,
  getPlanStatusList,
  getTuplePaymentSettleStatusList,
  getTuplePaymentStatusList,
  getTuplePaymentTypeList,
  getTupleStatusList,
  TeamSetting,
  YesNoWithNumberList,
} from '../index.service';
import { firstLetterCaps, getFullName } from '../../utils/text.util';
import ListGeoLocation from '../../views/ManageLocation/GeoLocation/List';
import { ListServiceCategory } from '../../views/PriceManagement';
import NCABankingDetails from '../../views/AdminSetting/NCA/Edit/bankingDetails';
import BusinessDetails from '../../views/TeamSetting/Providers/Edit/BusinessDetails';
import { accessRegisterRoute as accessRegisterStepRoute } from '../../routes/Auth/ncaRegisterSteps';
import { generatePath } from 'react-router-dom';
import { dateFormatter } from '../../utils';
// import { listNCARoleService } from '../TeamSetting/role.service';

export const ncaTableColumn = ({
  Action,
  Toggle,
  NCALink,
  onSort,
  onFilterChange,
  onDocument,
  handleModal,
  filter,
  page,
  sizePerPage,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'firstName',
      title: i18next.t('text.common.name'),
      filterDataIndex: ['search'],
      sorter: true,
      render: (cell, row) =>
        ModalOpenFormatter({
          text: getFullName(row?.firstName, row?.lastName),
          action: () => handleModal(row?.id, 'profile', row?.profileStatus),
        }),
      ...getInputSearchFilter({
        dataIndex: 'search',
        placeholder: 'name',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'email',
      title: i18next.t('text.common.email'),
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'email',
        placeholder: 'email',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'roleName',
      title: i18next.t('text.common.roles'),
      // filterDataIndex: ['roleName'],
      sorter: true,
      render: (cell, row) =>
        checkValidData(firstLetterCaps(row?.Role?.name || '')),
      exportKey: 'Role',
      exportFormatter: (cell, row) =>
        checkValidData(firstLetterCaps(row?.Role?.name || '')),

      ...getMultiSelectSearchFilter({
        dataIndex: 'roleName',
        onFilterChange,
        name: 'Role',
        list: [],
        mode: false,
        filter,
        addAll: true,
        listService: TeamSetting.listRoleService,
        extraQuary: { type: 'nca' },
      }),
    },
    {
      dataIndex: 'companyName',
      title: i18next.t('text.common.companyName'),
      sorter: true,

      exportKey: 'CompanyNca',
      exportFormatter: (cell, row) => checkValidData(row?.CompanyNca?.name),

      render: (cell, row) => checkValidData(row?.CompanyNca?.name),
      ...getInputSearchFilter({
        dataIndex: 'companyName',
        placeholder: 'company name',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'companyCode',
      title: i18next.t('text.common.companyCode'),
      sorter: true,
      exportKey: 'CompanyNca',
      exportFormatter: (cell, row) => checkValidData(row?.CompanyNca?.code),

      render: (cell, row) => checkValidData(row?.CompanyNca?.code),
      ...getInputSearchFilter({
        dataIndex: 'companyCode',
        placeholder: 'company code',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'profileStatus',
      title: i18next.t('text.common.profileStatus'),
      export: false,
      render: (cell, row) =>
        ModalOpenFormatter({
          flag: true,
          text: badgeCompleteFormatter(cell),
          action:
            cell === 'Incomplete'
              ? () => handleModal(row?.id, 'profileStatus')
              : false,
        }),
    },
    {
      dataIndex: 'isPlanCancelled',
      filterDataIndex: ['planStatus'],
      title: i18next.t('text.common.planStatus'),
      sorter: true,
      exportKey: 'CompanyNca',
      exportFormatter: (cell, row) =>
        getActiveCancelledResponse(row?.CompanyNca?.isPlanCancelled === 0),
      render: (cell, row) =>
        typeof row?.CompanyNca?.isPlanCancelled === 'number'
          ? getActiveCancelledResponse(row?.CompanyNca?.isPlanCancelled === 0)
          : '-',
      ...getMultiSelectSearchFilter({
        dataIndex: 'planStatus',
        onFilterChange,
        name: 'plan status',
        list: [],
        mode: false,
        filter,
        listService: getPlanStatusList,
      }),
    },
    {
      dataIndex: 'id',
      title: i18next.t('text.common.paymentReport'),
      export: false,
      render: (cell, row) =>
        ButtonTextFormatter(
          generatePath(ncaAccessRoute?.LIST_NCA_PAYMENT_REPORT?.path, {
            id: cell,
          }),
          'Click Here',
          false,
          {},
          '_blank'
        ),
    },
    {
      dataIndex: 'viewEditDocument',
      title: i18next.t('text.common.viewEditDocument'),
      render: (cell, row) =>
        iconFormatter('icon ni ni-file-doc', onDocument(row?.id)),
    },

    {
      dataIndex: 'ncaLink',
      title: i18next.t('text.common.ncaLink'),
      // filterDataIndex: ['ncaLink'],
      exportName: 'NCALinkStatus',
      ...getMultiSelectSearchFilter({
        dataIndex: 'ncaLink',
        onFilterChange,
        name: 'NCA Link',
        mode: false,
        filter,
        listService: YesNoWithNumberList,
      }),

      exportKey: 'CompanyNca',
      exportFormatter: (cell, row) =>
        getBooleanResponse(row?.CompanyNca?.isNcaLinkShow),

      render: (cell, row) => {
        return <NCALink row={row} cell={cell} />;
      },
    },
    {
      dataIndex: 'status',
      title: i18next.t('text.localization.status'),
      headerClasses: 'sorting',
      sort: true,
      onSort: (field, order) => onSort('status', order),
      exportName: 'Status',
      render: (cell, row) => {
        return <Toggle row={row} cell={cell} />;
      },
      ...getMultiSelectSearchFilter({
        dataIndex: 'status',
        onFilterChange,
        name: 'status',
        list: [],
        mode: false,
        filter,
        listService: getTupleStatusList,
      }),
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.localization.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => {
        return <Action row={row} cell={cell} />;
      },
    },
  ];
};

export const ncaListBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.common.nca'),
    },
  ];
};
export const ncaBookingLogBreadcrumb = (isNcaUser) => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: isNcaUser
        ? `${ncaProfileRoute?.NCA_PROFILE?.path}`
        : `${ncaAccessRoute?.LIST_NCA.path}`,
      name: isNcaUser
        ? i18next.t('text.common.editNCA')
        : i18next.t('text.common.nca'),
    },
    {
      path: '#',
      name: i18next.t('text.serviceManagement.bookingLog'),
    },
  ];
};

// For Payment Report
export const ncaPaymentReportTableColumn = ({
  onFilterChange,
  page,
  sizePerPage,
  filter,
}) => {
  return [
    {
      dataIndex: 'bookingNo',
      title: i18next.t('text.common.jobId'),
      render: (cell, row, index) => checkValidData(cell),
    },
    {
      dataIndex: 'typeOfPayment',
      title: i18next.t('text.common.typePayment'),
      render: (cell, row) => checkValidData(cell),
      ...getMultiSelectSearchFilter({
        dataIndex: 'typeOfPayment',
        onFilterChange,
        name: 'payment type',
        list: [],
        filter,
        mode: false,
        listService: getTuplePaymentTypeList,
      }),
    },
    {
      dataIndex: 'amountCollected',
      title: i18next.t('text.common.amountCollected'),
      render: (cell, row) => checkValidPrice(ToFixedPrice(row?.total)),
    },
    {
      dataIndex: 'transctionFee',
      title: i18next.t('text.common.transactionFee'),
      render: (cell, row) => checkValidPrice(ToFixedPrice(cell)),
    },
    {
      dataIndex: 'totalPayAmount',
      title: i18next.t('text.common.totalPayAmount'),
      render: (cell, row) => checkValidPrice(ToFixedPrice(cell)),
    },
    {
      dataIndex: 'paymentStatus',
      title: i18next.t('text.common.paymentStatus'),
      render: (cell, row) => checkValidData(row?.BookingDetail?.paymentStatus),
      ...getMultiSelectSearchFilter({
        dataIndex: 'paymentStatus',
        onFilterChange,
        name: 'payment status',
        list: [],
        mode: false,
        filter,
        listService: getTuplePaymentStatusList,
      }),
    },
    {
      dataIndex: 'settleStatus',
      title: i18next.t('text.common.paymentSettleStatus'),
      render: (cell, row) =>
        checkValidData(row?.BookingDetail?.providerPaymentStatus),
      ...getMultiSelectSearchFilter({
        dataIndex: 'settleStatus',
        onFilterChange,
        name: 'payment settle status',
        list: [],
        filter,
        mode: false,
        listService: getTuplePaymentSettleStatusList,
      }),
    },
    {
      dataIndex: 'selectAll',
      title: i18next.t('text.common.selectAll'),
      render: (cell, row) => (
        <>
          {ButtonFormatter(
            reportAccessRoute.PAYMENT_REPORT.path,
            'View Invoice'
          )}
        </>
      ),
    },
    {
      dataIndex: 'dateOfTransfer',
      title: i18next.t('text.common.paymentTransferStatus'),
      render: (cell, row) => {
        return row?.dateOfTransfer ? (
          <>
            {i18next.t('text.common.transferred')}
            <br />
            {dateFormatter(row?.dateOfTransfer, 'MM/DD/YYYY')}
          </>
        ) : (
          '-'
        );
      },
    },
  ];
};

export const ncaPaymentReportBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: ncaAccessRoute.LIST_NCA.path,
      name: i18next.t('text.common.nca'),
    },
    {
      path: '#',
      name: i18next.t('text.nca.ncaPaymentReport'),
    },
  ];
};

// For Document
export const ncaDocumentBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: ncaAccessRoute.LIST_NCA.path,
      name: i18next.t('text.common.nca'),
    },
    {
      path: '#',
      name: i18next.t('text.common.document'),
    },
  ];
};

// For Edit NCA Start
export const ncaGetApiList = () => {
  return {
    ncaProfile: getNCAProfileService,
    companyProfileForm: getNCACompanyProfileService,
    SetupCardDetailsForm: getNCACardService,
    businessDetails: getNCABusinessService,
    bankingDetails: getNCABankingService,
  };
};

export const ncaEditApiList = () => {
  return {
    ncaProfile: editNCAProfileService,
    companyProfileForm: editNCACompanyProfileService,
    SetupCardDetailsForm: editNCACardService,
    // businessDetails: editNCABusinessService,
    bankingDetails: editNCABankingService,
  };
};

export const editTabContent = ({
  formData,
  onSubmit,
  isLoading,
  defaultKey,
  id,
  setDefaultKey,
  ncaProfileLoading,
}) => {
  return [
    {
      name: i18next.t('text.common.ncaProfile'),
      key: 'ncaProfile',
      content: !ncaProfileLoading ? (
        <EditProfileForm
          formData={formData}
          isLoading={isLoading === 'SUBMIT'}
          onSubmit={onSubmit}
        />
      ) : (
        <GlobalLoader />
      ),
      disabled: false,
    },
    {
      name: i18next.t('text.common.companyProfileForm'),
      key: 'companyProfileForm',
      content: !ncaProfileLoading ? (
        <CompanyProfileForm
          formData={formData}
          isLoading={isLoading === 'SUBMIT'}
          onSubmit={onSubmit}
        />
      ) : (
        <GlobalLoader />
      ),
      disabled: false,
    },
    {
      name: i18next.t('text.common.setUpCardDetails'),
      key: 'SetupCardDetailsForm',
      content: !ncaProfileLoading ? (
        <SetupCardDetailsForm
          formData={formData}
          isLoading={isLoading === 'SUBMIT'}
          onSubmit={onSubmit}
        />
      ) : (
        <GlobalLoader />
      ),
      disabled:
        formData?.Role?.name !== 'nca' || formData?.Role?.isEditable > 0
          ? false
          : true,
      tooltip:
        formData?.Role?.name !== 'nca' || formData?.Role?.isEditable > 0
          ? false
          : true,
      tooltipText: 'Please complete previous step',
    },
    {
      name: i18next.t('text.common.setUpBusinessDetails'),
      key: 'businessDetails',
      content: (
        <BusinessDetails
          defaultKey={defaultKey}
          ncaEdit={true}
          setDefaultKey={setDefaultKey}
        />
      ),
      disabled: formData?.UserCard?.lastFour ? false : true,
      tooltip: formData?.UserCard?.lastFour ? false : true,
      tooltipText: 'Please complete previous step',
    },
    {
      name: i18next.t('text.common.setUpBankingDetails'),
      key: 'bankingDetails',
      content: !ncaProfileLoading ? (
        <NCABankingDetails
          formData={formData}
          isLoading={isLoading === 'SUBMIT'}
          onSubmit={onSubmit}
          ncaEdit={true}
        />
      ) : (
        <GlobalLoader />
      ),
      disabled:
        formData?.UserBusinessPersonDetail?.firstName &&
        formData?.UserCard?.lastFour
          ? false
          : true,
      tooltip:
        formData?.UserBusinessPersonDetail?.firstName &&
        formData?.UserCard?.lastFour
          ? false
          : true,
      tooltipText: 'Please complete previous step',
    },
    {
      name: i18next.t('text.common.geoFenceLocation'),
      key: 'geoFenceLocation',
      content: (
        <div className="card">
          <div className="card-inner">
            {defaultKey === 'geoFenceLocation' && (
              <ListGeoLocation editNca={false} userId={id} />
            )}
          </div>
        </div>
      ),
      disabled: formData?.UserBank?.accountNumber ? false : true,
      tooltip: formData?.UserBank?.accountNumber ? false : true,
      tooltipText: 'Please complete previous step',
    },
    {
      name: i18next.t('text.common.serviceCategory'),
      key: 'serviceCategory',
      content: (
        <div className="card">
          <div className="card-inner ">
            <ListServiceCategory editNca={false} userId={id} />
          </div>
        </div>
      ),
      disabled: formData?.UserBank?.accountNumber ? false : true,
      tooltip: formData?.UserBank?.accountNumber ? false : true,
      tooltipText: 'Please complete previous step',
    },
  ];
};

export const ncaEditBreadcrumb = (isNcaUser) => {
  if (!isNcaUser) {
    return [
      {
        path: accessRoute.DASHBOARD.path,
        name: i18next.t('text.common.dashboard'),
      },
      {
        path: '#',
        name: i18next.t('text.common.editNCA'),
      },
    ];
  } else {
    return [
      {
        path: accessRoute.DASHBOARD.path,
        name: i18next.t('text.common.dashboard'),
      },
      {
        path: ncaAccessRoute.LIST_NCA.path,
        name: i18next.t('text.common.nca'),
      },
      {
        path: '#',
        name: i18next.t('text.common.editNCA'),
      },
    ];
  }
};

export const routeObj = () => {
  return {
    1: accessRegisterStepRoute.COMPANY_PROFILE_ONE.path,
    2: accessRegisterStepRoute.COMPANY_PROFILE_TWO.path,
    3: accessRegisterStepRoute.BUSINESS_TYPE.path,
    4: accessRegisterStepRoute.BANK_DETAILS.path,
    5: accessRegisterStepRoute.SUCCESS_REGISTER_MESSAGE.path,
  };
};
