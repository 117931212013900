import i18next from 'i18next';
import * as yup from 'yup';
import { Regex } from '../../../../../helpers/regex.helper';
import { valueMapper } from '../../../../../helpers/schema.helper';

export const initValues = (data) => {
  const initVal = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    countryPhoneCode: '+1',
    password: '',
    confirmPassword: '',
    userType: 'nca',
    firebaseToken: 'string',
  };
  return valueMapper(initVal, data);
};

export const validation = () => {
  return yup.object().shape({
    firstName: yup
      .string()
      .trim()
      .max(30, i18next.t('validation.common.maxNameValid'))
      .min(2, i18next.t('validation.common.minFirstName'))
      .nullable()
      .required(i18next.t('validation.common.firstName')),
    lastName: yup
      .string()
      .trim()
      .max(30, i18next.t('validation.common.maxNameValid'))
      .min(2, i18next.t('validation.common.minLastName'))
      .nullable()
      .required(i18next.t('validation.common.lastName')),
    phoneNumber: yup
      .string()
      .nullable()
      .required(i18next.t('validation.signup.phoneNumber'))
      .matches(Regex.onlyNumber, {
        message: i18next.t('validation.common.onlyNumber'),
        excludeEmptyString: false,
      })
      .matches(Regex.phoneNumber, {
        message: i18next.t('validation.signup.minPhoneNo'),
      }),
    email: yup
      .string()
      .trim()
      .email(i18next.t('validation.common.validEmail'))
      .nullable()
      .required(i18next.t('validation.common.email')),
    password: yup
      .string()
      .matches(Regex.password, i18next.t('validation.signup.strongPassword'))
      .nullable()
      .required(i18next.t('validation.login.password')),
    confirmPassword: yup
      .string()
      .nullable()
      .required(i18next.t('validation.signup.confirmPassword'))
      .oneOf(
        [yup.ref('password')],
        i18next.t('validation.signup.matchPassword')
      ),
  });
};
