import React from 'react';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import * as yup from 'yup';

import {
  TextInput,
  CommonButton,
  FormElementWrapper,
  FormWrapper,
} from '../../../components';
import useSubmit from '../../../hooks/useSubmit.hook';

import { initValues } from '../../../components/Forms/Auth/ForgetPassword/validation';
import { forgetPassword } from '../../../services/CustomerApp/index.service';
import { useTranslation } from 'react-i18next';

const validation = () => {
  return yup.object().shape({
    emailOrMobile: yup
      .string()
      .trim()
      .email(i18next.t('text.nca.validEmail'))
      .required(i18next.t('text.nca.requiredEmail')),
  });
};

function ForgetPassword() {
  const {t} = useTranslation();
  const [onSubmit, , loading] = useSubmit({
    // service: changePassword,
    service: forgetPassword,
    afterSubmitActionType: 'redirect',
    serverMessage: true,
  });
  return (
    <div className="card card-bordered">
      <div className="card-inner card-inner-lg">
        <div className="nk-block-head">
          <div className="nk-block-head-content">
            <h5 className="nk-block-title">{t('text.forgotPassword.title')}</h5>
            <div className="nk-block-des">
              <p>
              {t('text.forgotPassword.heading')}

              </p>
            </div>
          </div>
        </div>
        <FormWrapper
          row={true}
          extraClassName="g-3"
          initialValues={initValues()}
          // onSubmit={onSubmit}
          onSubmit={(e) => {
            e.userType = 'nca';
            onSubmit(e);
          }}
          validation={validation}
        >
          {(props) => (
            <>
              <FormElementWrapper
                xs={12}
                label={t('text.common.email')}
                element={() => (
                  <TextInput
                    name="emailOrMobile"
                    formik={props}
                    placeholder="Enter your email address"
                  />
                )}
              />
              <div className="form-group">
                <CommonButton
                  type="submit"
                  disabled={loading}
                  className="btn btn-lg btn-primary btn-block"
                >
                  {t('text.common.sendResetLink')}
                </CommonButton>
              </div>
              <div className="form-note-s2 text-center">
                <Link to="/nca-login">{t('text.common.returnToLogin')}</Link>
              </div>
            </>
          )}
        </FormWrapper>
      </div>
    </div>
  );
}

export default ForgetPassword;
