import React, { useState, useEffect } from 'react';
import { Button, FormGroup } from 'react-bootstrap';
import { ModalWrapper, AddAddressForm } from '../../components';
import { Form, Select } from 'antd';
import { getGeneralSetting } from '../../services/Common/index.service';
import useLocation from '../../hooks/useLocation.hook';
import { addAddressService } from '../../services/Home/index';
import { modalNotification } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { accessRoute } from '../../routes/CustomerApp/home';
import { useTranslation } from 'react-i18next';

export default function Address({
  addressList,
  onAddressSelect,
  getAddressList,
  selectAddress,
  addressListLoaded,
  getCustomerCategoryList,
  setShow,
  show,
}) {
  const [isLoading, setLoading] = useState(false);
  const [state, setState] = useState({ mapKey: '', loading: true });
  const [data, method] = useLocation();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loading, mapKey } = state;
  const {
    cityList,
    stateList,
    countryList,
    isPlacePredictionsLoading,
    city,
    locality,
    country,
    zipCode,
    lat,
    lng,
    administrative_area_level_1,
  } = data;

  useEffect(() => {
    getApiKeys();
  }, []);

  function onSuccess(location) {
    try {
      if (addressList?.length > 0) {
        const address = addressList?.find(
          (e) =>
            e?.latitude === location?.coords?.latitude &&
            e?.longitude === location?.coords?.longitude
        );
        if (address) {
          onAddressSelect(address?.id);
        } else {
          if (Array.isArray(addressList) && addressList?.length > 0) {
            onAddressSelect(addressList[0]?.id);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  function onFail(err) {
    try {
      if (err?.code === 1) {
        if (Array.isArray(addressList) && addressList?.length > 0) {
          onAddressSelect(addressList[0]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  function getAddressValue() {
    if (
      !selectAddress &&
      Array.isArray(addressList) &&
      addressList?.length > 0
    ) {
      let currentAddress = addressList?.sort(
        (a, b) => new Date(b?.createdAt) - new Date(a?.createdAt)
      )[0]?.addressLine1;
      onAddressSelect(addressList[0]);
      return currentAddress;
    } else if (Array.isArray(selectAddress)) {
      onAddressSelect(selectAddress[0]);
      return selectAddress[0];
    } else {
      onAddressSelect(selectAddress);
      return selectAddress;
    }
  }

  useEffect(() => {
    if (!addressListLoaded) {
      navigator.geolocation.getCurrentPosition(onSuccess, onFail);
    }
  }, [addressList, addressListLoaded]);

  const handleChange = (value) => {
    const selectedAddress = addressList?.find((e) => e?.id === value);
    onAddressSelect(selectedAddress);
    navigate(accessRoute.HOME.path);
  };

  async function getApiKeys() {
    try {
      const res = await getGeneralSetting({
        queryParams: { type: 'installation_setting' },
      });
      if (Array.isArray(res?.data?.rows)) {
        const mapKey = res?.data?.rows?.find(
          (e) => e?.key === 'google_server_key'
        );
        if (mapKey?.value) {
          setState({ ...state, mapKey: mapKey?.value, loading: false });
        }
      }
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  async function onSubmit(payload) {
    try {
      setLoading(true);
      if (payload?.city) {
        payload.city = String(payload?.city);
      }
      if (payload?.state) {
        payload.state = String(payload?.state);
      }
      if (lat && lng) {
        payload.longitude = `${lng}`;
        payload.latitude = `${lat}`;
      } else {
        modalNotification({
          type: 'error',
          message:
            'longitude and latitude is required. Please select address Line 1 again',
        });
        return;
      }

      const res = await addAddressService({ body: payload });
      if (res?.success) {
        getAddressList();
        getCustomerCategoryList(res?.data);
        setLoading(false);
        setShow(false);
        modalNotification({ type: 'success', message: res?.message });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }

  const formProps = {
    onCancel: handleClose,
    onSubmit: onSubmit,
    stateList: stateList,
    cityList: cityList,
    countryList: countryList,
    localizationMethod: method,
    city,
    country,
    locality,
    administrative_area_level_1,
    zipCode,
    setLoading
  };

  return (
    <>
      <div className="yourAddress bg-white">
        <h2 className="font-sm h-20">
          {t('text.common.letsConfirmYourAddress')}
        </h2>
        <p className="mb-0">
          {t('text.common.selectConfirmAddressAndNewAddress')}
        </p>
        <div className="d-flex align-items-center">
          <Form className="w-100">
            <FormGroup className="form-group mb-0 d-flex align-items-center flex-wrap">
              <div className="position-relative yourAddress_form">
                <em className="gg-marker" />
                <Select
                  onChange={handleChange}
                  className="w-100"
                  value={getAddressValue()}
                  options={addressList?.map((e) => ({
                    label: e?.addressLine1,
                    value: e?.id,
                  }))}
                />
              </div>
              <Button
                variant="info"
                size="lg"
                type="submit"
                onClick={() => handleShow()}
                className="ms-lg-4 ms-md-2  mt-md-0 mt-2  font-sm"
              >
                {t('text.common.addLocation')}
              </Button>
            </FormGroup>
          </Form>
        </div>
      </div>
      <ModalWrapper
        onCancel={handleClose}
        heading={t('text.common.addAddress')}
        show={show}
        extraClassName="modal-lg"
        extraTitleClassName="align-items-start pb-0"
        content={
          loading && (
            <AddAddressForm
              isLoading={isLoading}
              {...formProps}
              mapKey={mapKey}
              isPlacePredictionsLoading={isPlacePredictionsLoading}
            />
          )
        }
      ></ModalWrapper>
    </>
  );
}
