import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Checkbox } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  ImageElement,
  Breadcrumb,
  GlobalLoader,
  ModalWrapper,
  CommonButton,
} from '../../../components';
import {
  getBookingLoader,
  getBookingStatusService,
  getCustomerProviderListService,
  MakeProviderFavorite,
  putCustomerBookingCancel,
  willCallList,
} from '../../../services/CustomerApp/index.service';

import {
  capitalizeFirstLetter,
  generateStarRating,
  getLocalStorage,
  logger,
  modalNotification,
} from '../../../utils';
import ServiceAddress from '../../../features/serviceAddress/index.feature';
import { getAddressService } from '../../../services/Home';
import CustomerBooking from '../../../features/modalComponent/customerBooking';
import ThankyouLoader from '../../../features/thankyouLoader/index.feature';
import { accessRoute } from '../../../routes/CustomerApp/booking';
import { accessRoute as accessCustomerRoute } from '../../../routes/CustomerApp/home';
import { accessRoute as customerHomeRoute } from '../../../routes/CustomerApp/home';
import ServiceType from '../../../features/modalComponent/servieType';
import { useTranslation } from 'react-i18next';
import { Columns } from '../../../services/index.service';
import { accessRoute as bookingRoutes } from '../../../routes/CustomerApp/booking';
import { useSelector } from 'react-redux';

function BookAppointment() {
  const { t } = useTranslation();
  const [stateDetails, setStateDetails] = useState({
    modal: '',
    providerUserId: '',
    thankyouLoader: false,
    isLoader: false,
    loaderTitle: t('text.customerBookAppointment.loaderMessageOne'),
    bookingId: '',
    sortBy: 'all',
  });

  const {
    modal,
    providerUserId,
    thankyouLoader,
    loaderTitle,
    isLoader,
    bookingId,
    sortBy,
  } = stateDetails;

  const navigate = useNavigate();
  const { state } = useLocation();
  const [providers, setProviders] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [favLoader, setFavLoader] = useState(null);
  const [addressList, setAddressList] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadMoreLoading, setIsMoreLoading] = useState(false);
  const [bookingLoaderTimer, setBookingLoaderTimer] = useState();
  const breadcrumb = Columns.customerBookAppointmentBreadcrumb();
  const placeId = useSelector((state) => state?.permission?.locationId);
  const [willCall, setWillCall] = React.useState(0);
  const [willLoading, setWillLoading] = useState(false);

  let latLong = getLocalStorage({ keyName: 'latLong' });
  const PER_PAGE_DATA = 12;

  const onCheckboxChange = async (userId) => {
    setFavLoader(userId);
    try {
      let bodyData = {};
      bodyData.providerUserId = userId;
      const res = await MakeProviderFavorite({ bodyData });
      if (res?.success) {
        modalNotification({
          type: 'success',
          message: res?.message,
        });
      }
    } catch (error) {
      logger(error);
    }
    setFavLoader(null);
  };

  async function getServiceProviderList({ newPage, isFetch } = {}) {
    if (!newPage) setLoading(true);
    if (newPage) setIsMoreLoading(true);
    try {
      let queryParams = {
        serviceSubcategoryId: state?.id,
        latitudeCoordinate: latLong?.lat,
        longitudeCoordinate: latLong?.long,
        offset:
          ((newPage ? newPage : isFetch ? 1 : currentPage) - 1) * PER_PAGE_DATA,
        limit: PER_PAGE_DATA,
        sortBy: sortBy,
      };

      if (state?.dateTime) {
        queryParams = {
          ...queryParams,
          ...state?.dateTime,
        };
      }
      const res = await getCustomerProviderListService({
        queryParams,
      });
      if (res?.success) {
        setProviders(
          newPage ? [...providers, ...(res?.data?.rows ?? [])] : res?.data?.rows
        );
        setCount(res?.data?.count);
        setWillCall(res?.data?.isWillCallList);
        if (newPage) {
          setCurrentPage(newPage);
          setIsMoreLoading(false);
        } else {
          setCurrentPage(1);
        }
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (latLong) {
      getServiceProviderList();
    }
  }, []);

  useEffect(() => {
    if (latLong && stateDetails?.sortBy) {
      getServiceProviderList({ isFetch: true });
    }
  }, [stateDetails?.sortBy]);

  async function getAddressList() {
    try {
      const res = await getAddressService({
        scope: 'activeCustomerAddresses',
      });
      if (res?.success) {
        setAddressList([...res?.data?.rows]);
      }
    } catch (err) {
      console.log(err);
    }
  }

  // For timer
  const loaderTileObj = {
    1: t('text.customerBookAppointment.loaderMessageOne'),
    2: t('text.customerBookAppointment.loaderMessageTwo'),
    3: t('text.customerBookAppointment.loaderMessageThree'),
    4: t('text.customerBookAppointment.loaderMessageFour'),
  };

  let timer = useRef(null);
  function handleTimerStart() {
    try {
      timer.current = setInterval(startTimer, bookingLoaderTimer);
    } catch (err) {
      console.log(err);
    }
  }

  let intervalBookingStatus = useRef(null);
  function handleBookingStatusStart() {
    try {
      intervalBookingStatus.current = setInterval(getBookingStatus, 5000);
    } catch (err) {
      console.log(err);
    }
  }

  let limit = 2;
  function handleTimerStop(modalType) {
    try {
      clearInterval(timer.current);
      clearInterval(intervalBookingStatus.current);
      setStateDetails({
        ...stateDetails,
        modal: modalType,
        thankyouLoader: false,
        bookingStatusId: '',
        loaderTitle: t('text.customerBookAppointment.loaderMessageOne'),
      });
      setBookingLoaderTimer(null);
      limit = 2;
    } catch (error) {
      console.log(error);
    }
  }

  function startTimer(status) {
    try {
      if (status) return;
      setStateDetails({
        ...stateDetails,
        loaderTitle: loaderTileObj[limit],
      });
      limit += 1;
      if (limit > 5) {
        handleTimerStop('serviceType');
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleCancel = () => {
    try {
      setStateDetails({
        ...stateDetails,
        modal: 'serviceType',
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleCancelBooking = async () => {
    try {
      setStateDetails({
        ...stateDetails,
        isLoader: true,
      });
      const response = await putCustomerBookingCancel({
        bookingId,
      });
      if (response?.success) {
        modalNotification({
          type: 'success',
          message: 'Your booking is successfully cancelled.',
        });
        setStateDetails({
          ...stateDetails,
          isLoader: false,
          modal: '',
        });
      }
    } catch (error) {
      logger(error);
      setStateDetails({
        ...stateDetails,
        isLoader: false,
      });
    }
  };

  const getBookingStatus = async () => {
    try {
      const response = await getBookingStatusService(bookingId);

      if (response?.success) {
        let fullName =
          response?.data?.Provider?.firstName ||
          response?.data?.Provider?.lastName
            ? `${capitalizeFirstLetter(
                response?.data?.Provider?.firstName
              )}${' '}${response?.data?.Provider?.lastName}`
            : 'Worker new';
        if (response?.data?.bookingStatus !== 'pending') {
          if (
            response?.data?.bookingStatus === 'active' ||
            response?.data?.bookingStatus === 'accepted'
          ) {
            modalNotification({
              type: 'success',
              message: `${fullName}${' '} ${t(
                'text.customerBookAppointment.acceptBookingMassage'
              )}${' '} ${response?.data?.bookingNo}`,
            });
            if (response?.data?.ServiceCategory?.servicePrice === 'paid') {
              navigate(`${bookingRoutes.BOOKING.path}?tab=ongoing`);
            } else {
              navigate(`${bookingRoutes.BOOKING.path}?tab=upcoming`);
            }
          } else {
            modalNotification({
              type: 'error',
              message: response?.data?.Provider
                ? `${fullName}${' '} ${t(
                    'text.customerBookAppointment.errorBookingMassage'
                  )}${' '} ${response?.data?.bookingNo}`
                : 'All the service providers are currently busy to accept the service',
            });
          }
          clearInterval(intervalBookingStatus?.current);
          clearInterval(timer?.current);
          setStateDetails({
            ...stateDetails,
            thankyouLoader: false,
          });
        }
      }
    } catch (error) {
      logger(error);
      setStateDetails({
        ...stateDetails,
        isLoader: false,
      });
    }
  };

  const bookingLoader = async () => {
    try {
      const res = await getBookingLoader({
        queryParams: { type: 'appSettings' },
      });
      let timerValue =
        Math.round(
          res?.data?.rows?.find((e) => {
            return e?.key === 'provider_timeout';
          })?.value * 1000
        ) / 4;
      setBookingLoaderTimer(timerValue);
    } catch (error) {
      console.log(error);
    }
  };

  const modalObj = {
    customerBooking: (
      <CustomerBooking
        emergencyProviderUserId={providerUserId}
        setModal={setStateDetails}
        stateModal={stateDetails}
        isSuccess={false}
        sortBy={sortBy}
      />
    ),
    serviceType: (
      <ServiceType
        title="Requesting"
        description={t('text.customerBookAppointment.requestingDescription')}
        buttonText="UPCOMING BOOKINGS"
        path={`${accessRoute.BOOKING.path}?tab=upcoming`}
      />
    ),
    confirmCancel: (
      <ServiceType
        title="Confirmation"
        description={t('text.customerBookAppointment.confirmationDescription')}
        buttonText={t('text.common.confirm')}
        cancelButton={true}
        handleCancel={handleCancel}
        handleCancelBooking={handleCancelBooking}
        path={false}
        confirmButton={true}
        isLoader={isLoader}
      />
    ),
  };

  const loadMore =
    !loading && count !== providers?.length ? (
      <div className="d-flex justify-content-center mt-3">
        <CommonButton
          variant="primary"
          className="min-100"
          onClick={() => getServiceProviderList({ newPage: currentPage + 1 })}
          loading={isLoadMoreLoading}
          disabled={isLoadMoreLoading}
        >
          Load more
        </CommonButton>
      </div>
    ) : null;

  useEffect(() => {
    if (!state) {
      navigate(customerHomeRoute.HOME.path);
    }
    getAddressList();
  }, []);

  useEffect(() => {
    if (thankyouLoader) {
      bookingLoader();
    }
  }, [thankyouLoader]);

  useEffect(() => {
    if (bookingId) {
      handleBookingStatusStart();
    }
  }, [bookingId]);

  useEffect(() => {
    if (bookingLoaderTimer) {
      handleTimerStart();
    }
  }, [bookingLoaderTimer]);

  const handleWillCallList = async () => {
    setWillLoading(true);
    try {
      const bodyData = {
        customerLocationId: state?.locationId,
        serviceTypeId: state?.serviceTypeDetails?.id,
        serviceCategoryId: state?.id,
        quantity: state?.quantity ? state?.quantity : 1,
        bookingType: 'all',
        bookingDate: new Date(),
      };

      const res = await willCallList(bodyData);
      if (res?.success) {
        setStateDetails({
          ...stateDetails,
          thankyouLoader: true,
          bookingId: res?.data?.id,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setWillLoading(false);
  };

  return (
    <>
      <div className="main-content ServiceProviderPage">
        <div className="pageContent">
          <Container className="container-lg">
            <Breadcrumb
              title={t('text.customerBookAppointment.title')}
              breadcrumb={breadcrumb}
              type="customer"
            />
            <ServiceAddress
              providerLIst={true}
              selectedAddress={placeId}
              addressList={addressList}
              setModal={setStateDetails}
              stateModal={stateDetails}
              providers={providers && providers?.length}
              handleChangeSortBy={(value) =>
                setStateDetails({
                  ...stateDetails,
                  sortBy: value,
                })
              }
              sortBy={sortBy}
              isLoading={stateDetails?.isLoader}
            />
            <div className="appointmentSection bg-white p-30 br-10">
              {loading ? (
                <GlobalLoader />
              ) : (
                <Row className="g-3">
                  {!loading && providers?.length > 0 ? (
                    providers?.map((item, index) => {
                      return (
                        <>
                          <Col sm={6} lg={4} xxl={3} key={index}>
                            <div className="appointmentList position-relative">
                              <div className="appointmentList_like">
                                <Checkbox
                                  defaultChecked={
                                    item?.isFavorite === 1 ? true : false
                                  }
                                  onChange={() =>
                                    onCheckboxChange(item?.Provider?.userId)
                                  }
                                >
                                  {favLoader === item?.Provider?.userId ? (
                                    <GlobalLoader />
                                  ) : (
                                    <em className="appointmentList_like_icon gg-heart-outline" />
                                  )}
                                </Checkbox>
                              </div>

                              <div className="appointmentList_top d-flex align-items-start">
                                <div className="appointmentList_top_images">
                                  <ImageElement
                                    previewSource={item?.profilePictureThumbUrl}
                                  />
                                </div>
                                <div className="appointmentList_top_content">
                                  <h2 className="font-md">
                                    {item?.firstName &&
                                      capitalizeFirstLetter(item?.firstName)}
                                    {item?.lastName &&
                                      ` ${capitalizeFirstLetter(
                                        item?.lastName
                                      )}`}
                                  </h2>
                                  <ul className="list-inline mb-0">
                                    <li className="list-inline-item">
                                      <div className="info">
                                        <ul className="list-unstyled d-flex starRating mb-0">
                                          {generateStarRating(
                                            item?.avgRating ?? 0
                                          )}
                                        </ul>
                                      </div>
                                    </li>
                                  </ul>{' '}
                                </div>
                              </div>

                              <div className="appointmentList_bottom">
                                <Button
                                  variant="outline-primary"
                                  className="w-100"
                                  onClick={() => {
                                    setStateDetails({
                                      ...stateDetails,
                                      modal: 'customerBooking',
                                      providerUserId: item?.Provider?.UserId,
                                    });
                                  }}
                                >
                                  {t('text.customerBookAppointment.bookNow')}
                                </Button>
                              </div>
                            </div>
                          </Col>
                        </>
                      );
                    })
                  ) : (
                    <Col className="col-12">
                      {willCall < 1 && (
                        <div className="noList m-auto text-center d-flex align-items-center justify-content-center">
                          <div className="noList_inner">
                            <ImageElement
                              source="no-provider.svg"
                              className="img-fluid"
                              isCustomer={
                                '/assets/images/admin/no-provider.svg'
                              }
                            />
                            <div className="noList_cnt">
                              <h5>
                                {t(
                                  'text.customerBookAppointment.providerNotFound'
                                )}
                              </h5>

                              <p className="mb-0">
                                {t(
                                  'text.customerBookAppointment.notAvailableMessage'
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="text-center mt-2">
                        <Link
                          to={accessCustomerRoute.HOME.path}
                          className="d-inline-block btn btn-primary text-wrap h-auto"
                        >
                          {t('text.customerBookAppointment.bookNextTimeSlot')}
                        </Link>
                      </div>
                    </Col>
                  )}
                  {willCall > 0 && (
                    <div>
                      <p className="text-center">
                        We have a service provider in your area currently with a
                        customer. We cannot guarantee a time but we can add you
                        to our Will Call List or you can book and appointment
                        for the next day.
                      </p>
                      <p className="text-center">Please choose an option :</p>
                      <div className="d-flex justify-content-center mt-2">
                        <CommonButton
                          variant="primary"
                          className="min-100"
                          onClick={handleWillCallList}
                          loading={willLoading}
                        >
                          {t('text.customerBookAppointment.WillCallList')}
                        </CommonButton>
                      </div>
                    </div>
                  )}
                </Row>
              )}
              {loadMore}
            </div>
          </Container>
        </div>
      </div>
      <ModalWrapper
        closable={
          modal === 'confirmCancel' || modal === 'serviceType' ? false : true
        }
        onCancel={() => setStateDetails({ ...stateDetails, modal: '' })}
        heading={
          modal === 'serviceType'
            ? 'Requesting'
            : modal === 'confirmCancel'
            ? 'Confirmation'
            : 'Booking Details'
        }
        show={modal}
        modalExtraClass={`${
          modal === 'customerBooking' ? 'bookingDetails' : ''
        }`}
        extraClassName="modal-lg"
        extraTitleClassName="align-items-start"
        content={modalObj[modal]}
      ></ModalWrapper>
      {thankyouLoader && (
        <ThankyouLoader
          title={loaderTitle}
          thankyouLoader={thankyouLoader}
          handleTimerStop={handleTimerStop}
        />
      )}
    </>
  );
}

export default BookAppointment;
