import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import {
  TablePlus,
  ActionWrapper,
  ModalWrapper,
  PageContainerWrapper,
  CommonButton,
  ServiceTypeForm,
} from '../../../../../components';
import { modalNotification } from '../../../../../utils';
import {
  addServiceTypeService,
  deleteServiceTypeService,
  editServiceTypeService,
  getServiceTypeDetailsService,
  ListServiceTypeService,
} from '../../../../../services/ManageServices/serviceType.service';
import { getApiData, useTable } from '../../../../../hooks/index.hook';
import {
  priceServiceTypeBreadcrumb,
  serviceTypeTableColumn,
} from '../../../../../services/Columns';
import NoteFooter from '../../../../../components/Common/NoteFooter';
import { getActions } from '../../../../../services/index.service';

function ListService() {
  // { ncaEdit = false, userId }
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const [subCategory, setSubCategory] = useState({});

  // For loader, modal, selected row, and form text
  const [state, setState] = useState({
    loader: null,
    modal: null,
    modalId: null,
    selectedRows: [],
    submitText: 'add',
  });
  const { loader, modal, modalId, selectedRows, submitText } = state;

  // For get tags list
  const id = searchParams.get('id');
  const extraQuery = {
    serviceSubcategoryId: id,
  };
  const extraCSVHandle = async (getData) => {
    let obj = {};
    let arr = [];

    getData?.rows?.length &&
      getData?.rows?.map((item) => {
        obj = {
          name: `${item?.ServiceCategory?.name}(${item?.ServiceCategory?.servicePrice} - ${item?.ServiceCategory?.serviceType})`,
        };

        arr.push(obj);
      });

    return { 'Sub Category': arr };
  };

  const [tableHandler, tableProps] = useTable(
    ListServiceTypeService,
    extraQuery,
    false,
    false,
    false,
    extraCSVHandle
  );

  const {
    data,
    isLoading,
    count,
    page,
    noOfPages,
    sizePerPage,
    filter,
    sortBy,
    sortType,
  } = tableProps;
  const {
    onRefresh,
    getData,
    onTableSort,
    onFilterChange,
    onExport,
    onFilterResetChange,
    onConfirmDeleteAlert,
  } = tableHandler;

  // For breadcrumb
  const breadcrumb = priceServiceTypeBreadcrumb();

  // For action with column
  function onAction(e, handler, item) {
    e.preventDefault();
    handler(item);
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options?.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item?.handler, item)}
              key={key}
              className="action_list"
              action={item?.action}
            >
              <CommonButton>
                <em className={item?.icon} />
                {item?.name}
              </CommonButton>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };

  const columns = serviceTypeTableColumn({
    onSort: onTableSort,
    onFilterChange: onFilterChange,
    filter,
    page,
    sizePerPage,
    Action: (props) =>
      actionFormatter([
        {
          name: 'Edit',
          icon: 'icon ni ni-edit',
          action: getActions('EDIT').value,
          handler: () => {
            setState({
              ...state,
              modal: 'edit-tag',
              submitText: 'Update',
              modalId: props?.row?.id,
            });
          },
        },
        {
          name: 'Delete',
          icon: 'icon ni ni-trash',
          action: getActions('DELETE').value,
          handler: () => {
            onConfirmDeleteAlert({
              id: props?.row?.id,
              text: t('text.manageServices.areYouSureDeleteServiceType'),
              service: deleteServiceTypeService,
              key: 'serviceTypeIds',
            });
            document.body.click();
          },
        },
      ]),
  });

  async function getDetail(id) {
    try {
      const res = await getServiceTypeDetailsService({ id });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  function onCancel() {
    setState({ ...state, modal: null, modalId: null });
  }

  // For handle submit
  async function onSubmit(payload) {
    setState({ ...state, loader: 'add' });
    try {
      let response = '';
      if (modalId)
        response = await editServiceTypeService({
          bodyData: payload,
          id: modalId,
        });
      else
        response = await addServiceTypeService({
          bodyData: payload,
        });
      const { success, message } = response;

      if (success) {
        setState({ ...state, modal: null, loader: null, modalId: null });
        modalNotification({
          type: 'success',
          message,
        });
        onRefresh();
      } else {
        setState({ ...state, loader: null });
      }
    } catch (error) {
      console.log(error);
      setState({ ...state, loader: null });
    }
  }

  // For bulk action
  function onRowSelect(e) {
    try {
      setState({ ...state, selectedRows: [...e] });
    } catch (err) {
      console.log('err', err);
    }
  }
  // For bulk action
  async function handleActionSelect(e) {
    try {
      if (e?.target?.value === 'delete') {
        await onConfirmDeleteAlert({
          id: selectedRows,
          text: t('text.manageServices.areYouSureDeleteServiceType'),
          service: deleteServiceTypeService,
          key: 'serviceTypeIds',
        });
        getData();
      }
    } catch (err) {
      console.log(err);
    }
  }
  // For modal
  const formProps = {
    onCancel: onCancel,
    onSubmit: onSubmit,
  };

  const modalForm = {
    add: (
      <ServiceTypeForm
        onEdit={getDetail}
        onCancel={onCancel}
        loading={loader === 'add'}
        modalId={modalId}
        count={() => {
          return Array.from(Array((count || 0) + 1)?.keys());
        }}
        submitText={submitText}
        {...formProps}
      />
    ),
  };

  // For header Button
  const headerBtn = {
    add: {
      name: t('text.manageServices.addServiceType'),
      handler: () => {
        setState({ ...state, submitText: 'Add', modal: 'add-tag' });
      },
    },
    csvExport: {
      handler: () => onExport('', columns),
    },
  };

  return (
    <>
      <PageContainerWrapper
        heading={t('text.manageServices.serviceType')}
        breadcrumb={breadcrumb}
        btn={headerBtn}
      >
        <ModalWrapper
          heading={`${
            submitText === 'Add' ? t('text.common.add') : t('text.common.edit')
          } Service Type`}
          onCancel={onCancel}
          content={modalForm['add']}
          show={['add-tag', 'edit-tag'].includes(modal)}
        />
        <TablePlus
          hasLimit
          noOfPage={noOfPages}
          sizePerPage={sizePerPage}
          page={page}
          handleSelectedRows={onRowSelect}
          handleActionSelect={handleActionSelect}
          count={count}
          disabledAction={['active', 'inactive']}
          tableData={data}
          tableColumns={columns}
          selectRow
          tableLoader={isLoading}
          onFilterResetChange={onFilterResetChange}
          filter={filter}
          sortBy={sortBy}
          sortType={sortType}
        />
        <NoteFooter title={t('text.common.notes')}>
          <li>{t('text.manageServices.serviceTypeNoteOne')}</li>
          <li>{t('text.manageServices.serviceTypeNoteTwo')}</li>
          <li>{t('text.localization.noteThree')}</li>
        </NoteFooter>
      </PageContainerWrapper>
    </>
  );
}

export default ListService;
