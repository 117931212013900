import { AboutUs, PromoCodeStore, StoreHome } from '../../views/Store';
import StoreMyProfile from '../../views/Store/MyProfile';

export default function route() {
  return [
    {
      path: accessRoute.HOME.path,
      name: accessRoute.HOME.name,
      key: accessRoute.HOME.path,
      private: true,
      belongsToHeader: true,
      element: <StoreHome />,
    },
    // {
    //   path: accessRoute.ABOUT_US.path,
    //   name: 'About Us',
    //   label: 'About Us',
    //   key: accessRoute.ABOUT_US.key,
    //   private: false,
    //   belongsToHeader: true,
    //   element: <AboutUs />,
    // },
    {
      path: accessRoute.MY_PROFILE.path,
      name: accessRoute.MY_PROFILE.name,
      key: accessRoute.MY_PROFILE.path,
      private: true,
      belongsToHeader: true,
      element: <StoreMyProfile />,
    },
    {
      path: accessRoute.PROMO_CODE.path,
      name: accessRoute.PROMO_CODE.name,
      key: accessRoute.PROMO_CODE.path,
      private: true,
      belongsToHeader: true,
      element: <PromoCodeStore />,
    },
  ];
}

export const accessRoute = {
  HOME: {
    path: '/store/home',
    name: 'Home',
    key: 'Home',
  },
  ABOUT_US: {
    path: '/store/about-us',
    name: 'About Us',
    key: 'about-us',
  },
  MY_PROFILE: {
    path: '/store/my-profile',
    name: 'My Profile',
    key: 'My-profile',
  },
  PROMO_CODE: {
    path: '/store/promo-code',
    name: 'Promo Code',
    key: 'promo-code',
  },
};

export const getChildrenRoutes = () => {
  return route();
};
