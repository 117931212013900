import { getActions } from '../../services/index.service';
import {
  AddProcedureLesson,
  AddQuestion,
  EditProcedureLesson,
  ListProcedureLesson,
} from '../../views/index.view';

export default function route() {
  return [
    {
      private: true,
      name: accessRoute.OPERATING_PROCEDURE.name,
      key: accessRoute.OPERATING_PROCEDURE.key,
      path: accessRoute.OPERATING_PROCEDURE.path,
      icon: accessRoute.OPERATING_PROCEDURE.icon,
      belongsToSidebar: true,
      element: <ListProcedureLesson />,
      ...accessRoute.OPERATING_PROCEDURE,
    },
    {
      private: true,
      name: accessRoute.ADD_LESSON.name,
      key: accessRoute.ADD_LESSON.key,
      path: accessRoute.ADD_LESSON.path,
      belongsToSidebar: false,
      element: <AddProcedureLesson />,
      ...accessRoute.ADD_LESSON,
    },
    {
      private: true,
      name: accessRoute.EDIT_LESSON.name,
      key: accessRoute.EDIT_LESSON.key,
      path: accessRoute.EDIT_LESSON.path,
      belongsToSidebar: false,
      element: <EditProcedureLesson />,
      ...accessRoute.EDIT_LESSON,
    },
    {
      private: true,
      name: accessRoute.ADD_QUESTION.name,
      key: accessRoute.ADD_QUESTION.key,
      path: accessRoute.ADD_QUESTION.path,
      belongsToSidebar: false,
      element: <AddQuestion />,
      ...accessRoute.ADD_QUESTION,
    },
  ];
}

export const accessRoute = {
  OPERATING_PROCEDURE: {
    path: '/admin/standard-operating-procedure',
    name: 'Operating Procedure',
    key: 'Operating-Procedure',
    moduleKey: 'operating-procedure',
    action: getActions('LIST').value,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-list-fill" />
      </span>
    ),
    order: 60,
  },
  ADD_LESSON: {
    path: '/admin/add-lesson',
    name: 'Add Lesson',
    key: 'Add-Lesson',
    moduleKey: 'operating-procedure',
    action: getActions('CREATE').value,
  },
  EDIT_LESSON: {
    path: '/admin/edit-lesson/:id',
    name: 'Edit Lesson',
    key: 'Edit-Lesson',
    moduleKey: 'operating-procedure',
    action: getActions('EDIT').value,
  },
  ADD_QUESTION: {
    path: '/admin/add-question',
    name: 'Add Question',
    key: 'Add-Question',
    moduleKey: 'operating-procedure',
    action: getActions('EDIT').value,
  },
};

export const getChildrenRoutes = () => {
  return route();
};
