import React from 'react';
// import { accessRoute } from '../../../routes/CustomerApp/searchProvider';
import { CommonButton } from '../../UIElements/Button';
import { TextInput } from '../../UIElements/index.element';
import AntTextArea from '../../UIElements/TextArea';

import { FormElementWrapper, FormWrapper } from '../../Wrappers';
import { contactValidation } from './validation';

function ProviderContactUsForm({ t, onSubmit }) {
  const initialValues = { subject: '', message: '' };

  return (
    <FormWrapper
      row={true}
      extraClassName="g-3"
      initialValues={{ ...initialValues }}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validation={contactValidation}
    >
      {(props) => {
        return (
          <>
            <FormElementWrapper
              md={12}
              extraLabel={t('text.customerService.subject')}
              element={() => (
                <TextInput
                  formik={props}
                  placeholder={t('text.customerService.subject')}
                  name="subject"
                />
              )}
            />
            <FormElementWrapper
              md={12}
              extraLabel={t('text.customerService.message')}
              element={() => (
                <AntTextArea
                  placeholder={t('text.customerService.message')}
                  formik={props}
                  name="message"
                  maxLength={500}
                  extraClassName="ant-input"
                />
              )}
            />
            <div className="d-flex justify-content-end mt-2">
              <CommonButton
                disabled={props.isSubmitting}
                loading={props.isSubmitting}
                variant="primary"
                className="min-200"
                type="submit"
              >
                {t('text.common.submit')}
              </CommonButton>
            </div>
          </>
        );
      }}
    </FormWrapper>
  );
}

export default ProviderContactUsForm;
