import { APIRequest } from '../../helpers/index.helper';
import { GEt_QUOTE_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { logger } from '../../utils';

export const getQuoteSampleListService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...GEt_QUOTE_ENDPOINT.getQuoteSampleList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getQuoteBrandListService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...GEt_QUOTE_ENDPOINT.getQuoteBrandList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getQuoteTypeListService = async ({ queryParams, type }) => {
  try {
    queryParams.equipmentMaterialIaq = 'Equipment';

    const payload = {
      ...GEt_QUOTE_ENDPOINT?.getQuoteTypeList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getQuoteSystemBtuListService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...GEt_QUOTE_ENDPOINT.getQuoteSystemBtuList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getQuoteMaterialListService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...GEt_QUOTE_ENDPOINT.getQuoteMaterialList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getQuoteDirectionalListService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...GEt_QUOTE_ENDPOINT.getQuoteDirectionalList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getQuoteMinBlowerDriverService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...GEt_QUOTE_ENDPOINT.getQuoteMinBlowerDriverList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getQuoteSizeDescriptionService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...GEt_QUOTE_ENDPOINT.getQuoteSizeDescriptionList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
