import { QUOTE_ORDER_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';

export const getQuoteOrderService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...QUOTE_ORDER_ENDPOINT?.getQuoteOrderList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const deleteQuoteOrderService = async ({ bodyData }) => {
  try {
    const payload = {
      ...QUOTE_ORDER_ENDPOINT.deleteQuoteOrder,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const addQuoteOrderService = async ({ bodyData }) => {
  try {
    const payload = {
      ...QUOTE_ORDER_ENDPOINT.addQuoteOrder,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const editQuoteOrderService = async ({ bodyData, id }) => {
  try {
    const payload = {
      ...QUOTE_ORDER_ENDPOINT.editQuoteOrder(id),
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getQuoteOrderDetailsService = async ({ id }) => {
  try {
    const payload = {
      ...QUOTE_ORDER_ENDPOINT.getQuoteOrderDetails(id),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
