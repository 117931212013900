import React, { useState, useEffect } from 'react';
import {
  ModalComponent,
  PageContainerWrapper,
} from '../../../../../components';
import { useTranslation } from 'react-i18next';
import { accessRoute } from '../../../../../routes/TeamSetting';
import {
  editAvailabilityBreadcrumb,
  editAvailabilityTabContent,
} from '../../../../../services/Columns';
import { backToPage, logger, modalNotification } from '../../../../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import Tabs from '../../../../../components/UIElements/Tabs';
import UnavailabilityModalForm from '../../../../../components/Forms/Providers/unavailabilityModal.form';
import { formSubmit } from '../../../../../hooks/useFormSubmit.hook';
import {
  blockProviderUnavailabilityService,
  editProviderAvailabilityService,
  getProviderUnavailabilityBlockService,
  editProviderUnAvailabilityFormService,
  unblockProviderUnavailabilityService,
} from '../../../../../services/TeamSetting/provider.service';
import { getApiData } from '../../../../../hooks/useGetApi.hook';
import { getSlotService } from '../../../../../services/index.service';

function EditAvailability() {
  const { userId } = useParams();
  const [blockDayData, setBlockDayData] = useState([]);
  const [unBlock, setUnBlock] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultKey, setDefaultKey] = useState('availability');
  const [modalShow, setModalShow] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [state, setState] = useState({ slots: [] });
  const { t } = useTranslation();
  const breadcrumb = editAvailabilityBreadcrumb();

  const navigate = useNavigate();
  const redirectUrl = accessRoute.PROVIDER.path;
  const { slots } = state;

  const tabContent = editAvailabilityTabContent({
    setModalShow,
    onSubmit,
    unavailabilitySubmit,
    isLoading,
    userId,
    slots,
  });

  async function onSubmit(values, action) {
    if (action === 'unblock') {
      const res = formSubmit({
        values,
        service: unblockProviderUnavailabilityService,
        setIsLoading,
        id: userId,
      });
      if (res) {
        getApiData({
          service: getProviderUnavailabilityBlockService,
          setData: setBlockDayData,
          id: { userId },
        });
      }
    } else {
      const res = formSubmit({
        values,
        service: editProviderAvailabilityService,
        setIsLoading,
        id: userId,
      });
      if (res) {
        getApiData({
          service: getProviderUnavailabilityBlockService,
          setData: setBlockDayData,
          id: { userId },
        });
      }
    }
  }

  useEffect(() => {
    getSlots();
  }, []);

  async function getSlots() {
    try {
      const res = await getSlotService();
      if (res?.success) {
        setState({ ...state, slots: res?.data?.rows });
      }
    } catch (err) {
      console.log(err);
    }
  }

  // for unavailability submit
  async function unavailabilitySubmit(values) {
    if (values.otherScheduleJob && values.scheduledJobRadius === 'other') {
      values.scheduledJobRadius = values.otherScheduleJob;
    }
    if (values.otherEmergencyJob && values.emergencyJobRadius === 'other') {
      values.emergencyJobRadius = values.otherEmergencyJob;
    }
    let { otherScheduleJob, otherEmergencyJob, ...restValues } = values;
    logger(otherScheduleJob, otherEmergencyJob);
    if (
      values.emergencyJobRadius === 'other' ||
      values.scheduledJobRadius === 'other'
    ) {
      modalNotification({
        type: 'error',
        message: 'Please enter other miles radius',
      });
      return;
    }
    restValues.autoApproveBooking = Number(
      restValues.autoApproveBooking || false
    );
    restValues.isAutoApproveBookingRestrict = Number(
      restValues.isAutoApproveBookingRestrict || false
    );
    restValues.isTimeOffRestrict = Number(
      restValues.isTimeOffRestrict || false
    );
    restValues.isWorkLocationRestrict = Number(
      restValues.isWorkLocationRestrict || false
    );
    restValues.isJobRadiusRestrict = Number(
      restValues.isJobRadiusRestrict || false
    );
    formSubmit({
      values: restValues,
      service: editProviderUnAvailabilityFormService,
      setIsLoading,
      id: userId,
    });
  }

  // for block unavailability submit
  async function blockUnavailabilitySubmit(values) {
    await formSubmit({
      values,
      service: blockProviderUnavailabilityService,
      setIsLoading: setIsModalLoading,
      id: userId,
    });
    setModalShow(false);
  }

  // for unblock unavailability submit
  async function unblockUnavailabilitySubmit(values) {
    let response = await formSubmit({
      values,
      service: unblockProviderUnavailabilityService,
      setIsLoading: setIsModalLoading,
      id: userId,
    });
    if (response) {
      setModalShow(false);
      setUnBlock(false)
    };
  }

  // For get slot details
  useEffect(() => {
    getApiData({
      service: getProviderUnavailabilityBlockService,
      setData: setBlockDayData,
      id: { userId },
    });
  }, [userId, isModalLoading]);

  return (
    <>
      <PageContainerWrapper
        breadcrumb={breadcrumb}
        btnArray={['extraButton']}
        extraBtnText={t('text.common.back')}
        extraBtnClass="btn btn-outline-light bg-white"
        onExtraButtonHandleShow={() => backToPage(navigate, redirectUrl)}
        heading={t('text.provider.editAvailability')}
      >
        <Tabs
          tabContent={tabContent}
          tabsFor="table"
          activeKey={defaultKey}
          setActiveKey={setDefaultKey}
        />
        <ModalComponent
          backdrop
          show={modalShow}
          onHandleCancel={() => setModalShow(false)}
          title={t('text.provider.selectBlockDays')}
          extraBodyClassName="p-0"
        >
          <UnavailabilityModalForm
            unblockSubmit={unblockUnavailabilitySubmit}
            onSubmit={blockUnavailabilitySubmit}
            isLoading={isModalLoading}
            unBlock={unBlock}
            slots={slots}
            userId={userId}
            selectedSlotData={blockDayData}
          />
        </ModalComponent>
      </PageContainerWrapper>
    </>
  );
}

export default EditAvailability;
