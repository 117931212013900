export const text = {
  heading: 'Line Items',
  partNumber: 'Part Number',
  partCost: 'Part Cost',
  customerTag: 'Customer Tag',
  jobTag: 'Job Tag',
  areYouSureDeleteLineItem: 'you want to delete this Line Items',
  addLineItems: 'Add Line Items',
  editLineItems: 'Edit Line Items',
  autoTag: 'Auto Tag',
  truckOrder: 'Truck Ordering',
  enterPartCost: 'Enter part cost',
  enterPartNumber: 'Enter part number',
  totalRecordUpload: 'Total Record Upload',
  recordToUpdate: 'Record to Update',
  recordToInsert: 'Record to Insert',
};
