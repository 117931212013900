import loadable from '@loadable/component';

export const BankDetails = loadable(() => import('./BankDetails'));
export const BusinessType = loadable(() => import('./BusinessType'));
export const CompanyProfileOne = loadable(() => import('./CompanyProfileOne'));
export const CompanyProfileTwo = loadable(() => import('./CompanyProfileTwo'));
export const CreateProfile = loadable(() => import('./CreateProfile'));
export const SuccessRegisterMessage = loadable(() =>
  import('./SuccessRegisterMessage')
);
