import i18next from 'i18next';
import { accessRoute } from '../../routes/Dashboard';
import {
  checkValidData,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import {
  getColumnSelectSearchProps,
  getInputSearchFilter,
  getMultiSelectSearchFilter,
} from '../../components';
import { getTupleStatusList } from '../index.service';
import { firstLetterCaps } from '../../utils/text.util';

export const tagsTableColumn = ({
  Action,
  Toggle,
  onSort,
  onFilterChange,
  filter,
  page,
  sizePerPage,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'name',
      filterDataIndex: ['search'],
      title: i18next.t('text.tags.tagName'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'search',
        placeholder: 'tag name',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'tagType',
      title: i18next.t('text.tags.tagType'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('tagType', order),
      render: (cell, row) => checkValidData(firstLetterCaps(cell || '')),
      ...getMultiSelectSearchFilter({
        dataIndex: 'tagType',
        onFilterChange,
        filter,
        name: 'tag type',
        list: [],
        mode: false,
        listService: tagTypeList,
      }),
    },
    {
      dataIndex: 'status',
      title: i18next.t('text.localization.status'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('status', order),
      render: (cell, row) => {
        return <Toggle row={row} cell={cell} />;
      },
      ...getColumnSelectSearchProps({
        dataIndex: 'status',
        placeholder: 'status',
        list: getTupleStatusList('all'),
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.localization.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => {
        return <Action row={row} cell={cell} />;
      },
    },
  ];
};

export const tagsListBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.tags.title'),
    },
  ];
};

export const tagTypeList = () => {
  return [
    // {
    //   id: 'all',
    //   name: 'All',
    // },
    {
      id: 'customer',
      name: 'Customer',
    },
    {
      id: 'job',
      name: 'Job',
    },
  ];
};
