import axios from 'axios';
import momentTimezone from 'moment-timezone';
import {
  removeLocalStorageToken,
  removeSessionStorageToken,
  // getSessionStorageToken,
  getLocalStorageToken,
  getLocalStorage,
  // getLocalStorageLanguage,
} from '../utils/common.util';

import { API_ENDPOINT_V1 } from '../config';
import logger from '../utils/logger';
import { accessRoute } from '../routes/Auth';
import { accessRoute as customerAccessRoute } from '../routes/Auth/customer';
import { accessRoute as ncaAccessRoute } from '../routes/Auth/login';
import { accessRoute as storeAccessRoute } from '../routes/Auth/store';
import store from '../redux/store';
import {
  customerLogout,
  logout,
  storeLogout,
} from '../redux/slices/auth/authSlice';

axios.defaults.headers.post['Content-Type'] = 'application/json';

const APIrequest = async ({
  method,
  url,
  baseURL,
  queryParams,
  bodyData,
  fileData,
  cancelFunction,
  formHeaders,
  removeHeaders,
  isErrorData = false,
  isErrorToast = true,
  isAdminToken,
  guestToken = false,
}) => {
  const browserPath = window.location.pathname.split('/')?.[1];

  let role = getLocalStorage({
    keyName: browserPath === 'store' ? 'storeRole' : 'role',
  });

  const apiToken =
    browserPath === 'admin' || isAdminToken
      ? getLocalStorageToken()
      : guestToken
      ? guestToken
      : getLocalStorageToken(role);

  // const language = getLocalStorageLanguage();
  try {
    const axiosConfig = {
      method: method || 'GET',
      baseURL: API_ENDPOINT_V1,
      headers: {
        'X-Frame-Options': 'sameorigin',
        timezone: momentTimezone.tz.guess(true),
        // language,
      },
    };

    if (formHeaders) {
      axiosConfig.headers = { ...axiosConfig.headers, ...formHeaders };
    }

    if (baseURL) {
      axiosConfig.baseURL = baseURL;
    }

    if (url) {
      axiosConfig.url = url;
    }

    if (queryParams) {
      const queryParamsPayload = {};
      for (const key in queryParams) {
        if (Object.hasOwnProperty.call(queryParams, key)) {
          let element = queryParams[key];
          if (typeof element === 'string') {
            element = element.trim();
          }
          if (!['', null, undefined, NaN].includes(element)) {
            queryParamsPayload[key] = element;
          }
        }
      }
      axiosConfig.params = queryParamsPayload;
    }

    if (bodyData) {
      const bodyPayload = {};
      for (const key in bodyData) {
        if (Object.hasOwnProperty.call(bodyData, key)) {
          let element = bodyData[key];
          if (typeof element === 'string') {
            element = element.trim();
          }
          if (![null, undefined, NaN].includes(element)) {
            bodyPayload[key] = element;
          }
        }
      }
      axiosConfig.data = bodyPayload;
    }

    if (fileData) {
      axiosConfig.data = fileData;
    }

    if (cancelFunction) {
      axiosConfig.cancelToken = new axios.CancelToken((cancel) => {
        cancelFunction(cancel);
      });
    }

    if (removeHeaders) {
      delete axiosConfig.headers;
    }

    if (apiToken) {
      axiosConfig.headers = {
        ...axiosConfig.headers,
        authorization: `Bearer ${apiToken}`,
      };
    }

    axiosConfig.isErrorToast = isErrorToast;

    const res = await axios(axiosConfig);
    return res.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      logger('API canceled', error);
      throw new Error(error);
    } else {
      const errorRes = error.response;
      logger('Error in the api request', errorRes);
      if (errorRes?.status === 401) {
        console.log('Un authorized');
        if (browserPath === 'admin') {
          store.dispatch(logout());
        } else if (browserPath === 'store') {
          store.dispatch(storeLogout());
        } else {
          store.dispatch(customerLogout());
        }

        removeLocalStorageToken(
          false,
          browserPath === 'store'
            ? 'store'
            : browserPath === 'customer'
            ? 'customer'
            : false
        );

        removeSessionStorageToken();

        let path =
          window.location.pathname.search('admin') > 0
            ? accessRoute.ADMIN_LOGIN.path
            : window.location.pathname.search('customer') > 0
            ? customerAccessRoute.CUSTOMER_LOGIN.path
            : window.location.pathname.search('nca-login') > 0
            ? ncaAccessRoute.NCA_LOGIN.path
            : window.location.pathname.search('store') > 0
            ? storeAccessRoute.STORE_LOGIN.path
            : ncaAccessRoute.LOGIN.path;

        // console.log('path =>', path);
        // console.log('path name =>', window.location.pathname);
        if (!(window.location.pathname === path)) {
          window.location.replace(path);
        }
      }
      if (errorRes && errorRes.status && errorRes.status === 403) {
        /** *Update permission***** */
        // store.dispatch(loadPermission({}, true));
      }
      if (errorRes && errorRes.status && errorRes.status === 400) {
        // modalNotification({
        //   type: "warning",
        //   message: errorRes.data.message,
        // });
        if ([401].includes(errorRes?.data?.error?.status)) {
          // removeLocalStorageToken();
          if (browserPath === 'admin') {
            store.dispatch(logout());
          } else if (browserPath === 'store') {
            store.dispatch(storeLogout());
          } else {
            store.dispatch(customerLogout());
          }
          removeLocalStorageToken(
            false,
            browserPath === 'store'
              ? 'store'
              : browserPath === 'customer'
              ? 'customer'
              : false
          );

          removeSessionStorageToken();
          console.log('Un authorized');

          let path =
            window.location.pathname.search('admin') > 0
              ? '/admin/dashboard'
              : '/login';
          window.location.replace(path);
        }
        if (isErrorData) {
          return errorRes?.data;
        } else {
          return false;
        }
      } else {
        // modalNotification({
        //   type: "warning",
        //   message: errorRes?.data?.error[0]?.message || "Not Found",
        // });
      }
      if (
        errorRes?.data &&
        'error' in errorRes?.data &&
        Object.keys(errorRes?.data?.error).length &&
        [401].includes(errorRes?.data?.error?.status)
      ) {
        removeSessionStorageToken();
      }
      if (errorRes && errorRes.status && errorRes.status === 401) {
        // modalNotification({
        //   type: "error",
        //   message: errorRes?.data?.message,
        // });
      }
      return errorRes;
    }
  }
};

export default APIrequest;
