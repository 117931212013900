let url = '/v1/admin/sms-template';
export const SMS_TEMPLATE_ENDPOINT = {
  getSmsTemplateList: {
    url,
    method: 'GET',
  },
  editSmsTemplate: (id) => ({
    url: `${url}/${id}`,
    method: 'PUT',
  }),
  getSmsTemplateDetails: (id) => ({
    url: `${url}/${id}`,
    method: 'GET',
  }),
  getSmsShortCodeList: {
    url: `${url}/short-code`,
    method: 'GET',
  },
};
