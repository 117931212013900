import { APIRequest } from '../../helpers/index.helper';
import { PROVIDER_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { convertSlotFormat, logger, setLocalStorage } from '../../utils';

export const providerListService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...PROVIDER_ENDPOINT?.getProviderList,
      queryParams,
    };
    const res = await APIRequest(payload);
    setLocalStorage({ keyName: 'isScrollCount', data: res?.data?.count });
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const providerDeleteService = async ({ bodyData }) => {
  try {
    const payload = {
      ...PROVIDER_ENDPOINT?.deleteProvider,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const providerStatusUpdate = async (body) => {
  try {
    const payload = {
      ...PROVIDER_ENDPOINT?.providerStatusUpdate,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const viewProviderService = async (id, type) => {
  try {
    const queryParams = { ignore: 'deleted' };
    if (type) {
      queryParams.requestType = type;
    }
    const payload = {
      ...PROVIDER_ENDPOINT?.viewProvider(id),
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
// for availability
export const addProviderService = async ({ values }) => {
  try {
    const payload = {
      ...PROVIDER_ENDPOINT?.addProvider,
      bodyData: values,
    };
    return await APIRequest(payload);
  } catch (err) {
    logger(err);
  }
};

export const viewAdminProviderService = async (id, queryParams) => {
  try {
    const payload = {
      ...PROVIDER_ENDPOINT?.viewProviderProfile(id),
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const editProviderService = async ({ id, values }) => {
  try {
    const payload = {
      ...PROVIDER_ENDPOINT?.editProviderProfile(id),
      bodyData: values,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const providerDocumentListService = async (id) => {
  try {
    let query = {};
    if (id) {
      query = { ...query, userId: id };
    }
    const payload = {
      ...PROVIDER_ENDPOINT?.getProviderDocument(),
      queryParams: query,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getProviderSlotService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...PROVIDER_ENDPOINT?.getSlotList,
      queryParams,
    };
    const res = await APIRequest(payload);

    if (res?.data?.rows && type === 'all') {
      return convertSlotFormat(res?.data?.rows ?? []);
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const editProviderAvailabilityService = async ({ id, values }) => {
  try {
    const payload = {
      ...PROVIDER_ENDPOINT?.editProviderAvailability(id),
      bodyData: values,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const editProviderUnAvailabilityFormService = async ({ id, values }) => {
  try {
    const payload = {
      ...PROVIDER_ENDPOINT?.editProviderUnAvailabilityForm(id),
      bodyData: values,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const editProviderUnavailabilityService = async ({ id, values }) => {
  try {
    const payload = {
      ...PROVIDER_ENDPOINT?.editProviderUnavailability(id),
      bodyData: values,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const blockProviderUnavailabilityService = async ({ id, values }) => {
  try {
    const payload = {
      ...PROVIDER_ENDPOINT?.blockProviderUnavailability(id),
      bodyData: values,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const unblockProviderUnavailabilityService = async ({ id, values }) => {
  try {
    const payload = {
      ...PROVIDER_ENDPOINT?.unblockProviderUnavailability(id),
      bodyData: values,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const updateProviderStatusService = async ({ id, body }) => {
  try {
    const payload = {
      ...PROVIDER_ENDPOINT.updateProviderStatus(id),
      bodyData: body,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getProviderServiceById = async ({ id, body, queryParams }) => {
  try {
    const payload = {
      ...PROVIDER_ENDPOINT.getProviderService,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getProviderAvailabilityService = async (queryParams) => {
  try {
    const payload = {
      ...PROVIDER_ENDPOINT?.getProviderSlotList,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getProviderUnAvailabilityFormService = async ({
  queryParams,
  id,
}) => {
  try {
    const payload = {
      ...PROVIDER_ENDPOINT?.getProviderUnavailabilityForm(id),
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getProviderUnavailabilityBlockService = async (queryParams) => {
  try {
    const payload = {
      ...PROVIDER_ENDPOINT?.getProviderUnavailabilityBlock,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const updateProviderDocumentService = async ({
  queryParams,
  bodyData,
  id,
}) => {
  try {
    const payload = {
      ...PROVIDER_ENDPOINT?.updateProviderDocument(id),
      queryParams,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getProviderUnavailabilityService = async (id) => {
  try {
    const payload = {
      ...PROVIDER_ENDPOINT?.getProviderUnavailability(id),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const createProviderDocument = async ({ queryParams, bodyData, id }) => {
  try {
    const payload = {
      ...PROVIDER_ENDPOINT?.createProviderDocument,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getUserDocument = async ({ queryParams, bodyData, id }) => {
  try {
    const payload = {
      ...PROVIDER_ENDPOINT?.getUserDocument(),
      queryParams,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getSubProviderLessonDetail = async ({
  queryParams,
  bodyData,
  id,
}) => {
  try {
    const payload = {
      ...PROVIDER_ENDPOINT?.getLessonBySubAdminDetails(),
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getAdminProviderLessonDetail = async ({
  queryParams,
  bodyData,
  id,
}) => {
  try {
    const payload = {
      ...PROVIDER_ENDPOINT?.getLessonByAdminDetails(),
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const updateInvoicePermissionStatus = async ({ bodyData}) => {
  try {
    const payload = {
      ...PROVIDER_ENDPOINT?.updateInvoiceMessagePermission,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
