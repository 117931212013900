import InputFilterComponent from '../../../Forms/LineItems/priceFilter.form';
import { FilterFilled } from '@ant-design/icons';

export function getPriceFilter({
  dataIndex = '',
  onFilterChange,
  fields,
  filter,
  dataIndexKey,
}) {
  return {
    filterMultiple: true,
    filterDropdown: (props) => (
      <InputFilterComponent
        fields={fields}
        filter={filter}
        dataIndex={dataIndex}
        onFilterChange={onFilterChange}
        dataIndexKey={dataIndexKey}
        {...props}
      />
    ),
    filterIcon: (filtered) => {
      return (
        <>
          <FilterFilled
            type="search"
            style={{ color: filtered ? '#F47721' : undefined }}
          />
        </>
      );
    },
    onFilterDropdownVisibleChange: () => {},
  };
}
