import i18next from 'i18next';
import {
  checkValidData,
  checkValidDiscountType,
  percentFormatter,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { accessRoute } from '../../routes/Dashboard/dashboard';
import {
  getInputSearchFilter,
  getColumnSearchSelectProps,
} from '../../components';
import { firstLetterCaps } from '../../utils/text.util';
import { getSign } from '../../services/Common/index.service';

export const AmountItemsBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.quote.amountCalculation'),
    },
  ];
};

export const AmountItemsTableColumn = ({
  Action,
  onSort,
  onFilterChange,
  filter,
  page,
  sizePerPage,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'quoteType',
      title: i18next.t('text.quote.orderType'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('quoteType', order),
      render: (cell, row) => (
        <>
          {checkValidData(
            firstLetterCaps(row?.QuoteOrderAdminProfit?.providerQuoteOrder)
          )}
        </>
      ),
      ...getInputSearchFilter({
        dataIndex: 'quoteType',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'locationId',
      filterDataIndex: ['location'],
      title: i18next.t('text.quote.locationName'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('location', order),
      render: (cell, row) => (
        <>{checkValidData(firstLetterCaps(row?.Location?.name))}</>
      ),
      ...getInputSearchFilter({
        dataIndex: 'location',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'stateTaxPercentage',
      title: i18next.t('text.quote.stateTax'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('description', order),
      render: (cell, row) => checkValidData(percentFormatter({ cell })),
      ...getInputSearchFilter({
        dataIndex: 'stateTaxPercentage',
        placeholder: 'state tax',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'salesTaxPercentage',
      title: i18next.t('text.quote.salesTax'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('salesTax', order),
      render: (cell, row) => checkValidData(percentFormatter({ cell })),
      ...getInputSearchFilter({
        dataIndex: 'salesTaxPercentage',
        placeholder: 'sales tax',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'locationProfitType',
      title: i18next.t('text.quote.locationProfit'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('partCost', order),
      render: (cell, row) => checkValidDiscountType(row?.profitType, cell),
      ...getColumnSearchSelectProps({
        filter,
        dataIndex: 'locationProfitType',
        list: getSign(),
        onFilterChange,
        selectIndex: 'locationProfitType',
        placeholder: {
          select: 'Select',
          selectIndex: 'profitType',
          input: 'Search location profit',
          inputName: 'inputType',
          inputIndex: 'locationProfitType',
        },
      }),
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.common.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => <Action row={row} cell={cell} />,
    },
  ];
};
