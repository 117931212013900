import i18next from 'i18next';

export const actionEditDelete = (actionHandler) => {
  let option = [
    {
      name: i18next.t('text.common.edit'),
      icon: 'icon ni ni-edit',
      handler: (name, id) => actionHandler && actionHandler(name, id),
    },
    {
      name: i18next.t('text.common.delete'),
      icon: 'icon ni ni-trash',
      type: 'redirect',
      handler: (name, id) => actionHandler && actionHandler(name, id),
    },
  ];
  return option;
};

export const customerActionOption = (actionHandler) => {
  let option = [
    {
      name: 'Edit',
      icon: 'icon ni ni-edit',
      handler: (name, id) => actionHandler && actionHandler(name, id),
    },

    {
      name: 'View',
      icon: 'icon ni ni-eye',
      handler: (name, id) => actionHandler && actionHandler(name, id),
    },
    {
      name: 'Delete',
      icon: 'icon ni ni-trash',
      handler: (name, id) => actionHandler && actionHandler(name, id),
    },
  ];
  return option;
};
