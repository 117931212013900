import { Outlet } from 'react-router-dom';
import { getActions } from '../../services/Auth/index.service';
import {
  AddMembership,
  EditMembership,
  MembershipList,
} from '../../views/index.view';

export default function route() {
  return [
    {
      private: true,
      path: accessRoute.MEMBERSHIP.path,
      name: 'Membership',
      key: accessRoute.MEMBERSHIP.key,
      belongsToSidebar: true,
      icon: accessRoute.MEMBERSHIP.icon,
      element: <MembershipList />,
      ...accessRoute.MEMBERSHIP,
    },
    {
      private: true,
      path: accessRoute.MEMBERSHIP_ADD.path,
      name: 'Add',
      key: accessRoute.MEMBERSHIP_ADD.key,
      belongsToSidebar: false,
      icon: accessRoute.MEMBERSHIP_ADD.icon,
      element: <AddMembership />,
      ...accessRoute.MEMBERSHIP_ADD,
    },
    {
      private: true,
      path: accessRoute.MEMBERSHIP_EDIT.path,
      name: 'Edit',
      key: accessRoute.MEMBERSHIP_EDIT.key,
      belongsToSidebar: false,
      icon: accessRoute.MEMBERSHIP_EDIT.icon,
      element: <EditMembership />,
      ...accessRoute.MEMBERSHIP_EDIT,
    },
  ];
}

export const accessRoute = {
  MEMBERSHIP: {
    path: '/admin/membership',
    action: getActions('LIST').value,
    key: 'membership',
    name: 'Membership',
    moduleKey: 'membership',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-cc-alt-fill" />
      </span>
    ),
    order: 31,
  },
  MEMBERSHIP_ADD: {
    path: '/admin/membership/add',
    action: getActions('CREATE').value,
    key: 'membership',
    moduleKey: 'membership',
  },
  MEMBERSHIP_EDIT: {
    path: '/admin/membership/edit/:id',
    action: getActions('EDIT').value,
    key: 'membership',
    moduleKey: 'membership',
  },
};
export const getChildrenRoutes = () => {
  return route();
};
