import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionWrapper,
  TablePlus,
  ModalWrapper,
  PageContainerWrapper,
  AddRoleForm,
  Toggle,
  CommonButton,
} from '../../../../components';
import {
  Columns,
  TeamSetting,
  getTupleStatusList,
  getActions,
} from '../../../../services/index.service';
import { modalNotification } from '../../../../utils';
import useTable from '../../../../hooks/useTable.hook';
import useSubmit from '../../../../hooks/useSubmit.hook';
import { accessRoute } from '../../../../routes/TeamSetting/teamSetting';
import { generatePath, Link } from 'react-router-dom';
import {
  updateAlexaToggleStatus,
  updateMentalHealthStatus,
} from '../../../../services/TeamSetting/role.service';

function ListRole() {
  const [state, setState] = useState({
    loader: null,
    modal: null,
    modalId: null,
    selectedRows: [],
    submitText: 'add',
  });
  const [tableHandler, tableProps] = useTable(TeamSetting.listRoleService);
  const {
    data,
    isLoading: tableLoading,
    count,
    page,
    noOfPages,
    sizePerPage,
    filter,
    sortBy,
    sortType,
  } = tableProps;
  const {
    onRefresh,
    getData,
    onExport,
    onTableSort,
    onFilterResetChange,
    onFilterChange,
    onToggleChange,
  } = tableHandler;
  const [onSubmit, values, isLoading] = useSubmit({
    service: state?.modalId
      ? TeamSetting.editRoleService
      : TeamSetting.addRoleService,
    detailsService: TeamSetting.getRoleByIdService,
    id: state?.modalId,
    afterSubmitActionType: 'close-modal',
    onModalCancel: onCancel,
    action: onRefresh,
  });
  const breadcrumb = Columns.roleBreadcrumb();
  const statusList = getTupleStatusList();
  const { t } = useTranslation();
  const { loader, modal, modalId, selectedRows, submitText } = state;

  function onAction(e, handler) {
    e.preventDefault();
    if (typeof handler === 'function') handler();
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item?.handler)}
              key={key}
              className="action_list"
              action={item?.action}
            >
              {item?.name === 'Assign' ? (
                <Link
                  to={generatePath(accessRoute.ASSIGN_MODULE.path, {
                    id: item.id,
                  })}
                >
                  <em className={item?.icon} /> {item?.name}
                </Link>
              ) : (
                <CommonButton>
                  <em className={item?.icon} /> {item?.name}
                </CommonButton>
              )}
            </li>
          );
        })}
      </ActionWrapper>
    );
  };

  const columns = Columns.roleList({
    onSort: onTableSort,
    filter,
    onFilterChange,
    page,
    sizePerPage,
    Action: (
      props
      // row
    ) =>
      actionFormatter([
        {
          name: t('text.common.edit'),
          icon: 'icon ni ni-edit',
          action: getActions('EDIT').value,
          handler: () => {
            setState({
              ...state,
              modal: 'edit-role',
              submitText: t('text.common.update'),
              modalId: props?.row?.id,
            });
          },
        },
        {
          name: t('text.common.assign'),
          icon: 'icon ni ni-file-text',
          id: props?.row?.id,
          action: getActions('VIEW').value,
        },
      ]),

    Toggle: (props) => (
      <Toggle
        isDropDown={true}
        loading={loader === 'status-update'}
        defaultChecked={props?.row?.status}
        disabled={
          props?.row?.isRoleAssign === 1 || props?.row?.isEditable === 0
        }
        onChange={(e) =>
          onToggleChange(
            e,
            props?.row,
            '',
            TeamSetting.updateRoleStatus,
            'roleIds',
            true
          )
        }
      />
    ),
    ToggleHealth: (props) => (
      <Toggle
        loading={loader === 'status-update'}
        defaultChecked={
          props?.row?.isAllowMentalHealthModule === 1 ? true : false
        }
        onChange={(e) => onToggleMentalChange(e, props?.row?.id)}
      />
    ),
    AlexaToggle: (props) => (
      <Toggle
        defaultChecked={props?.row?.isAllowAmazonLex === 1 ? true : false}
        onChange={(e) => onAlexaToggleChange(e, props?.row?.id)}
      />
    ),
  });

  async function onToggleMentalChange(e, id) {
    try {
      const bodyData = {
        mentalHealthPermission: e ? 1 : 0,
      };
      const { success, message } = await updateMentalHealthStatus({
        bodyData,
        id,
      });
      if (success) {
        modalNotification({
          type: 'success',
          message,
        });
      }
      return success ? true : false;
    } catch (err) {
      return false;
    }
  }

  async function onAlexaToggleChange(e, id) {
    try {
      const bodyData = {
        amazonLexPermission: e ? 1 : 0,
      };
      const { success, message } = await updateAlexaToggleStatus({
        bodyData,
        id,
      });
      if (success) {
        modalNotification({
          type: 'success',
          message,
        });
      }
      return success ? true : false;
    } catch (err) {
      return false;
    }
  }

  async function getDetail(id) {
    try {
      const res = await TeamSetting.getRoleByIdService({ resourceId: id });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  function onCancel() {
    setState({ ...state, modal: null, modalId: null });
  }

  async function onDelete(id) {
    try {
      const res = await TeamSetting.deleteRoleService({
        bodyData: { roleIds: Array.isArray(id) ? id : [id] },
      });
      return res?.success ? true : false;
    } catch (err) {
      console.log(err);
    }
  }

  const formProps = {
    status: statusList,
    onCancel: onCancel,
    onSubmit: onSubmit,
  };

  const modalForm = {
    add: (
      <AddRoleForm
        onEdit={getDetail}
        onCancel={onCancel}
        loading={isLoading}
        modalId={modalId}
        formData={modalId ? values : {}}
        submitText={submitText}
        {...formProps}
      />
    ),
  };

  const headerBtn = {
    add: {
      name: t('text.role.addRole'),
      handler: () => {
        setState({
          ...state,
          submitText: t('text.common.add'),
          modal: 'add-role',
        });
      },
    },
    csvExport: { handler: () => onExport('', columns) },
  };

  function onRowSelect(e) {
    try {
      setState({ ...state, selectedRows: [...e] });
    } catch (err) {
      console.log('err', err);
    }
  }

  async function handleActionSelect(e) {
    try {
      if (e.target.value === 'delete') {
        await onDelete(selectedRows);
        getData();
      } else {
        await onToggleChange(
          e.target.value,
          selectedRows,
          'action',
          TeamSetting.updateRoleStatus,
          'roleIds'
        );
        getData();
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <PageContainerWrapper
      form={modalForm['filter']}
      heading={t('text.role.title')}
      breadcrumb={breadcrumb}
      filter={filter}
      btn={headerBtn}
    >
      <ModalWrapper
        onCancel={onCancel}
        size="lg"
        content={modalForm['add']}
        heading={`${
          submitText === 'Add'
            ? t('text.role.addRole')
            : t('text.role.editRole')
        }`}
        show={['add-role', 'edit-role'].includes(modal)}
      />
      <TablePlus
        hasLimit
        noOfPage={noOfPages}
        sizePerPage={sizePerPage}
        page={page}
        handleSelectedRows={onRowSelect}
        handleActionSelect={handleActionSelect}
        count={count}
        tableData={data}
        tableColumns={columns}
        selectRow
        disabledAction={['delete']}
        tableLoader={tableLoading}
        onFilterResetChange={onFilterResetChange}
        disableSelectRowCheckboxKeys={{
          isRoleAssign: 1,
          isEditable: 0,
        }}
        rowClassName={(record) =>
          record?.isEditable === 0 && record?.type === 'nca' ? 'markedRow' : ''
        }
        filter={filter}
        sortBy={sortBy}
        sortType={sortType}
      />
    </PageContainerWrapper>
  );
}

export default ListRole;
