import React, { useState, useEffect } from 'react';
// import { Link } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { Link, useSearchParams } from 'react-router-dom';
import AntTooltip from '../../UIElements/Tooltip';

import './index.scss';

export default function Tabs({
  tabContent,
  tabsFor,
  setActiveKey,
  activeKey,
  onTabChange,
  tabWithToggle,
  asideToggle,
  asideView,
  tabsOnModal = false,
  setParams = true,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [key, setKey] = useState(
    searchParams.get('tab')
      ? searchParams.get('tab')
      : tabContent?.length && tabContent[0].key
  );

  useEffect(() => {
    if (setParams) {
      searchParams.set('tab', key);
      setSearchParams(searchParams);
    }
    setActiveKey(key);
  }, []);

  return (
    <>
      <Tab.Container
        id="controlled-tab-example"
        defaultActiveKey={key}
        activeKey={activeKey}
        onSelect={(k) => {
          setKey(k);
          if (setActiveKey) {
            if (setParams) {
              searchParams.set('tab', k);
              setSearchParams(searchParams);
            }
            setActiveKey(k);
          }
          if (onTabChange) {
            if (setParams) {
              searchParams.set('tab', k);
              setSearchParams(searchParams);
            }
            onTabChange(k);
          }
        }}
      >
        {tabsFor === 'table' ? (
          <div className={tabsOnModal ? 'tabsonModal' : 'card'}>
            <Nav
              variant="tabs"
              className={`nav-tabs-mb-icon nav-tabs-card nav-tabs nav ${
                tabsOnModal ? 'px-0' : 'border-0'
              }`}
            >
              {tabContent.map((item, index) => {
                return (
                  <Nav.Item key={index}>
                    <Nav.Link
                      eventKey={item.key}
                      disabled={item?.disabled ? true : false}
                    >
                      {item.icon && <em className={item.icon} />}
                      <span>{item.name}</span>
                      {item?.tooltip && (
                        <AntTooltip
                          promptText={item?.tooltipText}
                          className="icon ni ni-info-fill"
                        ></AntTooltip>
                      )}
                    </Nav.Link>{' '}
                  </Nav.Item>
                );
              })}
            </Nav>
          </div>
        ) : (
          <Nav variant="tabs" className="nav-tabs-mb-icon nav-tabs-card mb-3">
            <>
              {tabContent.map((item, index) => {
                return (
                  <Nav.Item key={index}>
                    <Nav.Link eventKey={item.key}>
                      {item.icon && <em className={item.icon} />}
                      <span>{item.name}</span>
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
              {tabWithToggle && (
                <Nav.Item className={tabWithToggle}>
                  <Link
                    to="#"
                    onClick={() => asideToggle()}
                    className={`toggle btn btn-icon btn-trigger ${
                      asideView ? 'active' : ''
                    }`}
                  >
                    <em className="icon ni ni-user-list-fill" />
                  </Link>
                </Nav.Item>
              )}
            </>
          </Nav>
        )}
        <div className={tabsFor === 'table' ? 'mt-3' : ''}>
          <Tab.Content>
            {tabContent.map((item, index) => {
              return (
                <Tab.Pane key={index} eventKey={item.key}>
                  {item.content}
                </Tab.Pane>
              );
            })}
          </Tab.Content>
        </div>
      </Tab.Container>
    </>
  );
}
