const url = '/v1/admin/quote';

export const QUOTE_SYSTEM_ENDPOINT = {
  getQuoteSystemList: {
    url,
    method: 'GET',
  },
  deleteQuoteSystem: {
    url,
    method: 'DELETE',
  },
  statusUpdateQuoteSystem: {
    url: `${url}/change-status`,
    method: 'PUT',
  },
  availabilityStatusUpdateQuoteSystem: (id) => ({
    url: `${url}/availability-status/${id}`,
    method: 'PUT',
  }),
  addQuoteSystem: {
    url,
    method: 'POST',
  },
  editQuoteSystem: (id) => ({
    url: `${url}/${id}`,
    method: 'PUT',
  }),
  getQuoteSystemDetails: (id) => ({
    url: `${url}/${id}`,
    method: 'GET',
  }),
  // View Quote Images
  getQuoteViewImageList: {
    url: `${url}/images`,
    method: 'GET',
  },
  deleteQuoteViewImage: (id) => ({
    url: `${url}/images/${id}`,
    method: 'DELETE',
  }),
  quoteUpload: {
    url: `${url}/file-upload`,
    method: 'POST',
  },
};
