import React from 'react';
import { Charts } from '../../components';
import { useTranslation } from 'react-i18next';

export default function GraphService({
  data,
  options,
  type,
  totalData,
  loading,
}) {
  const { t } = useTranslation();
  return (
    <>
      <div className="card mb-3">
        <div className="card-inner">
          {loading ? (
            <div>
              <div class="loadingGraph">
                <div class="loadingGraph-1"></div>
                <div class="loadingGraph-2"></div>
                <div class="loadingGraph-3"></div>
                <div class="loadingGraph-4"></div>
                <div class="loadingGraph-5"></div>
              </div>
              <div className="text-center">Loading</div>
            </div>
          ) : (
            <div className="d-lg-flex align-items-center">
              <div
                style={{ maxWidth: '1100px', width: '100%', height: '350px' }}
              >
                <Charts type={type} data={data} options={options} />
              </div>
              {totalData && (
                <ul className="nk-store-statistics mt-0 mt-lg-4 ms-lg-4">
                  <li className="item pt-0">
                    <div className="info me-3">
                      <div className="title">{t(`text.common.collected`)}</div>
                      <div className="count d-flex flex-wrap align-items-center">
                        ${totalData?.totalData?.bookingCollection}
                        <span className="change down me-3">
                          <em className="ni ni-arrow-long-down"></em>
                          {totalData?.totalData?.tolalCollectedJob} jobs
                        </span>
                      </div>
                    </div>
                    <em className="icon bg-info-dim ni ni-activity-round"></em>
                  </li>
                  <li className="item pt-0">
                    <div className="info me-3">
                      <div className="title">
                        {t(`text.common.uncollected`)}
                      </div>
                      <div className="count d-flex flex-wrap align-items-center">
                        ${totalData?.totalData?.bookingCollectionRemaining}
                        <span className="change up">
                          <em className="ni ni-arrow-long-up"></em>
                          {totalData?.totalData?.tolalPendingJob} jobs
                        </span>
                      </div>
                    </div>
                    <em className="icon bg-purple-dim ni ni-activity-round"></em>
                  </li>
                </ul>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
