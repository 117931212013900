export const text = {
  faq: 'Faq',
  addFaq: 'Add Faq',
  faqNoteOne: 'FAQ module will list all faqs on this page.',
  faqNoteTwo: 'Administrator can Activate / Deactivate / Delete any faq.',
  faqNoteThree: 'Administrator can export data in XLS format.',
  faqDelete: 'Faq Deleted Successfully',
  englishQuestion: 'Question',
  pageDescription: 'Page Description',
  pageTitle: 'Page Title',
  pageSection: 'Page/Section',
  metaTitle: 'Meta Title',
  metaKeyword: 'Meta Keyword',
  metaDescription: 'Meta Description',
  editStaticPage: 'Edit Static Page',
  pageHeading: 'Pages',
  pageNoteOne: 'Page module will list all pages on this page.',
  pageNoteTwo: 'Administrator can edit any page.',
  pageNoteThree: 'Administrator can export data in XLS format.',
};
