import { LOG_VIEW } from '../../config';
import { getActions } from '../../services/index.service';
import { LogView } from '../../views/index.view';

export default function route() {
  return [
    LOG_VIEW === 'on'&& {
      path: accessRoute.LOGS.path,
      private: true,
      name: accessRoute.LOGS.name,
      key: accessRoute.LOGS.key,
      belongsToSidebar: LOG_VIEW === 'on',
      icon: accessRoute.LOGS.icon,
      element: <LogView />,
      ...accessRoute.LOGS,
    },
  ];
}

export const accessRoute = {
  LOGS: {
    path: '/admin/logs',
    action: getActions('LIST').value,
    name: 'Log Viewer',
    key: 'logs',
    ignorePermission: true,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt-fill" />
      </span>
    ),
  },
};

export const getChildrenRoutes = () => {
  return route();
};
