import { getColumnSelectSearchProps } from '../../components';
import i18next from 'i18next';
import {
  checkValidCount,
  checkValidData,
  checkValidPrice,
} from '../../components/UIElements/Formatter';
import { dateFormatter } from '../../utils';

export const googleServiceBreadcrumb = () => {
  return [
    {
      path: '/admin/dashboard',
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.googleConnect.titleGoogleService'),
    },
  ];
};
export const googleServiceLeadBreadcrumb = () => {
  return [
    {
      path: '/admin/dashboard',
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '/admin/google-service',
      name: i18next.t('text.googleConnect.titleGoogleService'),
    },
    {
      path: '#',
      name: i18next.t('text.googleConnect.googleLocalServiceManagement'),
    },
  ];
};

const getStatusData = () => {
  return [
    {
      name: i18next.t('text.common.yes'),
      id: 'true',
    },
    {
      name: i18next.t('text.common.no'),
      id: 'false',
    },
  ];
};

const getGoogleStatusList = () => {
  return [
    {
      id: 'ENABLED',
      name: 'Active',
    },
    {
      id: 'PAUSED',
      name: 'Inactive',
    },
  ];
};

export const googleServiceListTableColumn = ({
  onSort,
  Action,
  onFilterChange,
  Toggle,
  filter = {},
  ToggleHealth,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.googleConnect.id'),
      headerClasses: 'w_70',
      render: (cell, row) => checkValidData(row?.account?.accountId),
    },
    {
      dataIndex: 'businessName',
      title: i18next.t('text.googleConnect.business'),
      headerClasses: 'sorting',
      render: (cell, row) => checkValidData(row?.account?.businessName),
    },
    {
      dataIndex: 'getStatusData',
      title: i18next.t('text.googleConnect.weeklyBudget'),
      headerClasses: 'sorting',
      sorter: (a, b) => {
        return a?.account?.averageWeeklyBudget < b?.account?.averageWeeklyBudget
          ? -1
          : a?.account?.averageWeeklyBudget > b?.account?.averageWeeklyBudget
          ? 1
          : 0;
      },
      onSort: (field, order) => onSort('averageWeeklyBudget', order),
      render: (cell, row) => checkValidPrice(row?.account?.averageWeeklyBudget),
    },
    {
      dataIndex: 'totalReview',
      title: i18next.t('text.googleConnect.totalReview'),
      headerClasses: 'sorting',
      sorter: (a, b) => {
        return a?.account?.totalReview < b?.account?.totalReview
          ? -1
          : a?.account?.totalReview > b?.account?.totalReview
          ? 1
          : 0;
      },
      onSort: (field, order) => onSort('totalReview', order),
      render: (cell, row) => checkValidCount(row?.account?.totalReview),
    },
    {
      dataIndex: 'averageFiveStarRating',
      title: i18next.t('text.googleConnect.averageFiveStarRating'),
      headerClasses: 'sorting',
      sorter: (a, b) => {
        return a?.account?.averageFiveStarRating <
          b?.account?.averageFiveStarRating
          ? -1
          : a?.account?.averageFiveStarRating >
            b?.account?.averageFiveStarRating
          ? 1
          : 0;
      },
      onSort: (field, order) => onSort('averageFiveStarRating', order),
      render: (cell, row) =>
        checkValidCount(row?.account?.averageFiveStarRating),
    },
    {
      dataIndex: 'currentPeriodChargedLeads',
      title: i18next.t('text.googleConnect.currentPeriodChargedLeads'),
      headerClasses: 'sorting',
      sorter: (a, b) => {
        return a?.account?.currentPeriodChargedLeads <
          b?.account?.currentPeriodChargedLeads
          ? -1
          : a?.account?.currentPeriodChargedLeads >
            b?.account?.currentPeriodChargedLeads
          ? 1
          : 0;
      },
      onSort: (field, order) => onSort('currentPeriodChargedLeads', order),
      render: (cell, row) =>
        checkValidCount(row?.account?.currentPeriodChargedLeads),
    },
    {
      dataIndex: 'currentPeriodConnectedPhoneCalls',
      title: i18next.t('text.googleConnect.currentPeriod'),
      headerClasses: 'sorting',
      sorter: (a, b) => {
        return a?.currentPeriodConnectedPhoneCalls <
          b?.currentPeriodConnectedPhoneCalls
          ? -1
          : a?.currentPeriodConnectedPhoneCalls >
            b?.currentPeriodConnectedPhoneCalls
          ? 1
          : 0;
      },
      onSort: (field, order) =>
        onSort('currentPeriodConnectedPhoneCalls', order),
      render: (cell, row) =>
        checkValidCount(row?.currentPeriodConnectedPhoneCalls),
    },
    {
      dataIndex: 'currentPeriodPhoneCalls',
      title: i18next.t('text.googleConnect.currentPeriodPhone'),
      headerClasses: 'sorting',
      sorter: (a, b) => {
        return a?.account.currentPeriodPhoneCalls <
          b?.account.currentPeriodPhoneCalls
          ? -1
          : a?.account.currentPeriodPhoneCalls >
            b?.account.currentPeriodPhoneCalls
          ? 1
          : 0;
      },
      onSort: (field, order) => onSort('currentPeriodPhoneCalls', order),
      render: (cell, row) =>
        checkValidCount(row?.account?.currentPeriodPhoneCalls),
    },
    {
      dataIndex: 'currentPeriodTotalCost',
      title: i18next.t('text.googleConnect.currentPeriodTotal'),
      headerClasses: 'sorting',
      sorter: (a, b) => {
        return a?.account.currentPeriodTotalCost <
          b?.account.currentPeriodTotalCost
          ? -1
          : a?.account.currentPeriodTotalCost >
            b?.account.currentPeriodTotalCost
          ? 1
          : 0;
      },
      onSort: (field, order) => onSort('currentPeriodTotalCost', order),
      render: (cell, row) =>
        checkValidPrice(row?.account?.currentPeriodTotalCost),
    },
    {
      dataIndex: 'impressionsLastTwoDays',
      title: i18next.t('text.googleConnect.impressionsLastTwoDays'),
      headerClasses: 'sorting',
      sorter: (a, b) => {
        return a?.account.impressionsLastTwoDays <
          b?.account.impressionsLastTwoDays
          ? -1
          : a?.account.impressionsLastTwoDays >
            b?.account.impressionsLastTwoDays
          ? 1
          : 0;
      },
      onSort: (field, order) => onSort('impressionsLastTwoDays', order),
      render: (cell, row) =>
        checkValidCount(row?.account?.impressionsLastTwoDays),
    },
    {
      dataIndex: 'phoneLeadResponsiveness',
      title: i18next.t('text.googleConnect.phoneLeadRes'),
      headerClasses: 'sorting',
      sorter: (a, b) => {
        return a?.account.phoneLeadResponsiveness <
          b?.account.phoneLeadResponsiveness
          ? -1
          : a?.account.phoneLeadResponsiveness >
            b?.account.phoneLeadResponsiveness
          ? 1
          : 0;
      },
      onSort: (field, order) => onSort('phoneLeadResponsiveness', order),
      render: (cell, row) =>
        checkValidCount(row?.account?.phoneLeadResponsiveness),
    },

    {
      dataIndex: 'previousPeriodChargedLeads',
      title: i18next.t('text.googleConnect.previousPeriodCharged'),
      headerClasses: 'sorting',
      sorter: (a, b) => {
        return a?.account.previousPeriodChargedLeads <
          b?.account.previousPeriodChargedLeads
          ? -1
          : a?.account.previousPeriodChargedLeads >
            b?.account.previousPeriodChargedLeads
          ? 1
          : 0;
      },
      onSort: (field, order) => onSort('previousPeriodChargedLeads', order),
      render: (cell, row) =>
        checkValidCount(row?.account?.previousPeriodChargedLeads),
    },
    {
      dataIndex: 'previousPeriodConnectedPhoneCalls',
      title: i18next.t('text.googleConnect.previousPeriodConnectedPhoneCalls'),
      headerClasses: 'sorting',
      sorter: (a, b) => {
        return a?.account.previousPeriodConnectedPhoneCalls <
          b?.account.previousPeriodConnectedPhoneCalls
          ? -1
          : a?.account.previousPeriodConnectedPhoneCalls >
            b?.account.previousPeriodConnectedPhoneCalls
          ? 1
          : 0;
      },
      onSort: (field, order) =>
        onSort('previousPeriodConnectedPhoneCalls', order),
      render: (cell, row) =>
        checkValidCount(row?.account?.previousPeriodConnectedPhoneCalls),
    },
    {
      dataIndex: 'previousPeriodPhoneCalls',
      title: i18next.t('text.googleConnect.previousPeriodPhoneCalls'),
      headerClasses: 'sorting',
      sorter: (a, b) => {
        return a?.account.previousPeriodPhoneCalls <
          b?.account.previousPeriodPhoneCalls
          ? -1
          : a?.account.previousPeriodPhoneCalls >
            b?.account.previousPeriodPhoneCalls
          ? 1
          : 0;
      },
      onSort: (field, order) => onSort('previousPeriodPhoneCalls', order),
      render: (cell, row) =>
        checkValidCount(row?.account?.previousPeriodPhoneCalls),
    },
    {
      dataIndex: 'previousPeriodTotalCost',
      title: i18next.t('text.googleConnect.previousPeriodTotalCost'),
      headerClasses: 'sorting',
      sorter: (a, b) => {
        return a?.account.previousPeriodTotalCost <
          b?.account.previousPeriodTotalCost
          ? -1
          : a?.account.previousPeriodTotalCost >
            b?.account.previousPeriodTotalCost
          ? 1
          : 0;
      },
      onSort: (field, order) => onSort('previousPeriodTotalCost', order),
      render: (cell, row) =>
        checkValidPrice(row?.account?.previousPeriodTotalCost),
    },
    {
      dataIndex: 'new',
      title: i18next.t('text.googleConnect.new'),
      headerClasses: 'sorting',
      sorter: false,
      render: (cell, row) => 'NA',
    },
    {
      dataIndex: 'active',
      title: i18next.t('text.googleConnect.active'),
      headerClasses: 'sorting',
      sorter: false,
      render: (cell, row) => 'NA',
    },
    {
      dataIndex: 'booked',
      title: i18next.t('text.googleConnect.booked'),
      headerClasses: 'sorting',
      sorter: false,
      render: (cell, row) => 'NA',
    },
    {
      dataIndex: 'completed',
      title: i18next.t('text.googleConnect.completed'),
      headerClasses: 'sorting',
      sorter: false,
      render: (cell, row) => 'NA',
    },
    {
      dataIndex: 'archive',
      title: i18next.t('text.googleConnect.archive'),
      headerClasses: 'sorting',
      sorter: false,
      render: (cell, row) => 'NA',
    },
    {
      dataIndex: 'biddingMode',
      title: i18next.t('text.googleConnect.biddingMode'),
      headerClasses: 'sorting',
      sorter: false,
      render: (cell, row) => 'NA',
    },
    {
      dataIndex: 'currentBalance',
      title: i18next.t('text.googleConnect.currentBalance'),
      headerClasses: 'sorting',
      sorter: false,
      render: (cell, row) => 'NA',
    },
    {
      dataIndex: 'status',
      title: i18next.t('text.common.status'),
      headerClasses: 'sorting',
      render: (cell, row) => <Toggle cell={cell} row={row} />,
      ...getColumnSelectSearchProps({
        dataIndex: 'status',
        onFilterChange,
        name: 'status',
        list: [],
        mode: false,
        listService: getGoogleStatusList,
        filter,
      }),
    },
    {
      dataIndex: 'spControlStatus',
      title: i18next.t('text.googleConnect.spInControl'),
      headerClasses: 'sorting',
      render: (cell, row) => {
        return <ToggleHealth row={row} cell={cell} />;
      },
      ...getColumnSelectSearchProps({
        dataIndex: 'spControlStatus',
        onFilterChange,
        name: 'SP in control',
        list: [],
        mode: false,
        listService: getStatusData,
        filter,
      }),
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.common.action'),
      headerClasses: 'sorting',
      render: (cell, row) => {
        return <Action row={row} cell={cell} />;
      },
    },
  ];
};

export const leadsColumns = () => {
  return [
    {
      dataIndex: 'leadId',
      title: i18next.t('text.googleConnect.leadId'),
      headerClasses: 'sorting',
      render: (cell, row) => checkValidData(row?.leadId),
    },
    {
      dataIndex: 'leadType',
      title: i18next.t('text.googleConnect.leadType'),
      headerClasses: 'sorting',
      render: (cell, row) => checkValidData(row?.leadType),
    },
    {
      dataIndex: 'consumerPhoneNumber',
      title: i18next.t('text.googleConnect.customerPhone'),
      headerClasses: 'sorting',
      render: (cell, row) =>
        checkValidData(row?.phoneLead?.consumerPhoneNumber),
    },
    {
      dataIndex: 'chargeStatus',
      title: i18next.t('text.googleConnect.chargeStatus'),
      headerClasses: 'sorting',
      render: (cell, row) => checkValidData(row?.chargeStatus),
    },
    {
      dataIndex: 'geo',
      title: i18next.t('text.googleConnect.geoLocation'),
      headerClasses: 'sorting',
      render: (cell, row) => checkValidData(row?.geo),
    },
    {
      dataIndex: 'leadCategory',
      title: i18next.t('text.googleConnect.leadCategory'),
      headerClasses: 'sorting',
      render: (cell, row) => checkValidData(row?.leadCategory),
    },
    {
      dataIndex: 'dateTime',
      title: i18next.t('text.googleConnect.DateAndTime'),
      render: (cell, row) =>
        checkValidData(
          dateFormatter(row?.leadCreationTimestamp, 'MM-DD-YYYY hh:mm:ss:a')
        ),
    },
  ];
};

export const customerData = () => {
  return [
    {
      leadId: '5796654',
      leadType: 'ABC',
      customerPhone: '(+91) 8769995023',
      chargeStatus: 'Yes',
      geoLocation: 'Yes',
      leadCategory: 'abc',
      dateTime: 'Fri, October 28, 2022 12:06 PM',
    },
    {
      leadId: '	75833147',
      leadType: 'XYZ',
      customerPhone: '(+91) 8369995023',
      chargeStatus: 'Yes',
      geoLocation: 'Yes',
      leadCategory: 'abc',
      dateTime: 'Sat, October 30, 2022 10:06 PM',
    },
    {
      leadId: '5796654',
      leadType: 'ABC',
      customerPhone: '(+91) 8769995023',
      chargeStatus: 'Yes',
      geoLocation: 'Yes',
      leadCategory: 'abc',
      dateTime: 'Fri, October 28, 2022 12:06 PM',
    },
    {
      leadId: '75833147',
      leadType: 'ABC',
      customerPhone: '(+91) 8769995023',
      chargeStatus: 'Yes',
      geoLocation: 'Yes',
      leadCategory: 'abc',
      dateTime: 'Fri, October 28, 2022 12:06 PM',
    },
  ];
};
export const GoogleConnectServiceList = () => {
  return [
    {
      id: '1897973164',
      business: 'Good Guys Heating and Air Conditioning',
      averageWeeklyBudget: '$ 999,999.00',
      totalReview: '6',
      averageFiveStarRating: '5',
      currentPeriodChargedLeads: '0',
      currentPeriodConnectedPhoneCalls: '0',
      currentPeriodPhoneCalls: '0',
      currentPeriodTotalCost: '$ 0.00',
      impressionsLastTwoDays: '0',
      phoneLeadResponsiveness: '0',
      previousPeriodChargedLeads: '0',
      previousPeriodConnectedPhoneCalls: '0',
      previousPeriodPhoneCalls: '0',
      previousPeriodTotalCost: '$ 5.00',
      new: 'NA',
      active: 'NA',
      booked: 'NA',
      completed: 'NA',
      archive: 'NA',
      biddingMode: 'NA',
      currentBalance: 'NA',
    },
    {
      id: '3134076415',
      business: 'Tekhne of Fort Worth, Texas',
      averageWeeklyBudget: '$ 700.00',
      totalReview: '10',
      averageFiveStarRating: '4.5',
      currentPeriodChargedLeads: '0',
      currentPeriodConnectedPhoneCalls: '0',
      currentPeriodPhoneCalls: '0',
      currentPeriodTotalCost: '$ 0.00',
      impressionsLastTwoDays: '0',
      phoneLeadResponsiveness: '0',
      previousPeriodChargedLeads: '0',
      previousPeriodConnectedPhoneCalls: '0',
      previousPeriodPhoneCalls: '0',
      previousPeriodTotalCost: '$ 5.00',
      new: 'NA',
      active: 'NA',
      booked: 'NA',
      completed: 'NA',
      archive: 'NA',
      biddingMode: 'NA',
      currentBalance: 'NA',
    },
    {
      id: '3257228609',
      business: 'Tekhne Home Services',
      averageWeeklyBudget: '$ 900.00',
      totalReview: '15',
      averageFiveStarRating: '4.5',
      currentPeriodChargedLeads: '0',
      currentPeriodConnectedPhoneCalls: '0',
      currentPeriodPhoneCalls: '0',
      currentPeriodTotalCost: '$ 0.00',
      impressionsLastTwoDays: '0',
      phoneLeadResponsiveness: '0',
      previousPeriodChargedLeads: '0',
      previousPeriodConnectedPhoneCalls: '0',
      previousPeriodPhoneCalls: '0',
      previousPeriodTotalCost: '$ 5.00',
      new: 'NA',
      active: 'NA',
      booked: 'NA',
      completed: 'NA',
      archive: 'NA',
      biddingMode: 'NA',
      currentBalance: 'NA',
    },
    {
      id: '7198054105',
      business: '',
      averageWeeklyBudget: '$ 1900.00',
      totalReview: '08',
      averageFiveStarRating: '4.9',
      currentPeriodChargedLeads: '0',
      currentPeriodConnectedPhoneCalls: '0',
      currentPeriodPhoneCalls: '0',
      currentPeriodTotalCost: '$ 0.00',
      impressionsLastTwoDays: '0',
      phoneLeadResponsiveness: '0',
      previousPeriodChargedLeads: '0',
      previousPeriodConnectedPhoneCalls: '0',
      previousPeriodPhoneCalls: '0',
      previousPeriodTotalCost: '$ 5.00',
      new: 'NA',
      active: 'NA',
      booked: 'NA',
      completed: 'NA',
      archive: 'NA',
      biddingMode: 'NA',
      currentBalance: 'NA',
    },
  ];
};
