import loadable from '@loadable/component';
import FullPageLoader from '../components/UIElements/Loader/fullPageLoader';

export * from './Auth';
export * from './Reports';
export * from './Marketing';
export * from './TeamSetting';
export * from './CompanySetting';
export * from './EmailMessage';
export * from './ManageLocation';
export * from './Tags';
export * from './PriceManagement';
export * from './Notification';
export * from './Customers';
export * from './EquipmentOrder';
export * from './TruckOrdering';
export * from './SiteStatistics';
export * from './Customers';
export * from './ManualBooking';
export * from './HomeInventory';
export * from './Request';
export * from './OperatingProcedure';
export * from './ServiceManagement';
export * from './GoogleConnect';
export * from './WillCallList';
export * from './AdminSetting';
export * from './Account';
export * from './NewEquipment';
export * from './ScheduledBooking';
export * from './WillCallList';
export const Dashboard = loadable(() => import('./Dashboard'), {
  fallback: <FullPageLoader />,
});
export const LogView = loadable(() => import('./LogViewer'), {
  fallback: <FullPageLoader />,
});
export const ManageSlot = loadable(() => import('./ManageSlot'), {
  fallback: <FullPageLoader />,
});
export const PageNotFound = loadable(() => import('./PageNotFound/index'), {
  fallback: <FullPageLoader />,
});
export const NcaProfile = loadable(() => import('./NcaProfile/index'), {
  fallback: <FullPageLoader />,
});
export const CancelNcaService = loadable(
  () => import('./NcaProfile/cancelNca'),
  {
    fallback: <FullPageLoader />,
  }
);
export * from './LessonList';
export const GuestNewQuote = loadable(() => import('./GuestNewQuote/index'), {
  fallback: <FullPageLoader />,
});
export * from './Membership';
export * from './ShopingCart/index';
export const OnlineProvider = loadable(() =>
  import('./Dashboard/onlineProviders')
);
