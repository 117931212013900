export const LOGIN_ENDPOINT = {
  method: 'POST',
  url: '/v1/login',
};

export const CUSTOMER_SIGNUP_ENDPOINT = {
  method: 'POST',
  url: '/v1/user/signup',
};

export const FOOTER_SOCIAL_LINK_ENDPOINT = {
  url: '/v1/general-setting',
  method: 'GET',
};
