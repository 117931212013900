let url = '/v1/admin/store';

export const STORE_ENDPOINT = {
  getStoreList: {
    url,
    method: 'GET',
  },
  deleteStore: {
    url,
    method: 'DELETE',
  },
  storeStatusUpdate: {
    url: `${url}/change-status`,
    method: 'PUT',
  },
  addStore: {
    url,
    method: 'POST',
  },
  editStore: (id) => ({
    url: `${url}/${id}`,
    method: 'PUT',
  }),
  getStoreById: (id) => ({
    url: `${url}/${id}`,
    method: 'GET',
  }),
  getStoreDocument: {
    url: `/v1/store/document`,
    method: 'GET',
  },
  getPaymentStoreList: {
    url: `/v1/admin/store/payment/report`,
    method: 'GET',
  },
  getStorePaymentReportSum: {
    url: '/v1/admin/store/payment/report-sum',
    method: 'GET',
  },
  getMarketingReviewList: {
    url: 'v1/admin/marketing/message-review',
    method: 'GET',
  },
  getUnsubscribeUserList: {
    url: 'v1/marketing/unsubscribe',
    method: 'GET',
  },
  deleteUnsubscribeUser: (id) => ({
    url: `v1/marketing/unsubscribe/${id}`,
    method: 'DELETE',
  }),
  updateApproveDeclineStatus: (id) => ({
    url: `v1/admin/marketing/approve-decline/${id}`,
    method: 'PUT',
  }),
  getRecipientCount: {
    url: 'v1/admin/marketing/recipient-count',
    method: 'GET',
  },
  addSendMessageTemplate: {
    url: '/v1/admin/marketing/submit-message',
    method: 'POST',
  },
  getMarketingTags: {
    url: '/v1/admin/marketing/tags',
    method: 'GET',
  },
};

export const ADVERTISEMENT_ENDPOINT = {
  getTexableAdsList: {
    url: '/v1/admin/slider',
    method: 'GET',
  },
  addTexableAds: {
    url: '/v1/admin/slider',
    method: 'POST',
  },
  updateTexableAdsStatus: (id) => ({
    url: `/v1/admin/slider/${id}`,
    method: 'PUT',
  }),
  deleteTexableAds:(id)=>({
    url: `/v1/admin/slider/${id}`,
    method: 'DELETE'
  }),
  getTextualAdsById: (id) => ({
    url: `/v1/admin/slider/${id}`,
    method: 'GET'
  }),
  updateTextualAdsById: {
    url: `/v1/admin/slider`,
    method: 'PUT'
  },
  getInterstitialAdsList: {
    url: '/v1/admin/interstitialAd',
    method: 'GET',
  },
  addInterstitialAds: {
    url: '/v1/admin/interstitialAd',
    method: 'POST',
  },
  updateInterstitialAdsStatus: (id) => ({
    url: `/v1/admin/interstitialAd/${id}`,
    method: 'PUT',
  }),
  deleteInterstitialAds:(id)=>({
    url: `/v1/admin/interstitialAd/${id}`,
    method: 'DELETE'
  }),
  getInterstitialAdsById: (id) => ({
    url: `/v1/admin/interstitialAd/${id}`,
    method: 'GET'
  }),
  updateInterstitialAds: {
    url: `/v1/admin/interstitialAd`,
    method: 'PUT'
  },

}

export const MARKETING_TEMPLATE_ENDPOINT = {
  addSmsTemplate: {
    url: 'v1/admin/marketing/sms-template',
    method: 'POST',
  },
  editSmsTemplate: (id) => ({
    url: `v1/admin/marketing/sms-template/${id}`,
    method: 'PUT',
  }),
  updateSMSStatusId: (id) => ({
    url: `/v1/admin/sms/status/${id}`,
    method: 'PUT',
  }),
  addMarketingEmail: {
    url: '/v1/admin/marketing/email-template',
    method: 'POST',
  },
  editMarketingEmail: (id) => ({
    url: `/v1/admin/marketing/email-template/${id}`,
    method: 'PUT',
  }),
  getMarketingEmailList: {
    url: 'v1/admin/email-template',
    method: 'GET',
  },
  updateMarketingEmailStatus: (id) => ({
    url: `v1/admin/email/status/${id}`,
    method: 'PUT',
  }),
  getMessageReportList: (id) => ({
    url: `/v1/admin/marketing/report/${id}`,
    method: 'GET',
  }),
  getTagFilterList: (id) => ({
    url: `/v1/admin/marketing/filter-list/${id}`,
    method: 'GET',
  }),
  getProviderNameFilterList: (id) => ({
    url: `/v1/admin/marketing/provider-filter-list/${id}`,
    method: 'GET',
  }),
};
