import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  GlobalLoader,
  NoDataFound,
  PageContainerWrapper,
} from '../../components';
import { Invoice } from '../../features/index.feature';

import { Columns, getInvoiceDataService } from '../../services/index.service';

import { useEffect } from 'react';
import { useState } from 'react';
import { getApiData } from '../../hooks/useGetApi.hook';
import { accessRoute as reportAccessRoute } from '../../routes/Reports';
import { closeToPage } from '../../utils';
import { accessRoute as serviceManagementAccessRoute } from '../../routes/ServiceManagement';
import { accessRoute as scheduledBookingAccessRoute } from '../../routes/ScheduledBooking';

function CommonInvoicePage() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  let pathArray = pathname.split('/');

  const [getInvoiceData, setGetInvoiceData] = useState();

  const { t } = useTranslation();
  const params = useParams();
  const { id, viewInvoice } = params;

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      getApiData({
        service: getInvoiceDataService,
        setData: setGetInvoiceData,
        setIsLoading: setLoading,
        id: id,
      });
    }
  }, [id]);

  const accordingRouteData = {
    'payment-report': {
      breadcrumb: Columns.invoiceBreadcrumb(),
      redirectUrl: reportAccessRoute.PAYMENT_REPORT.path,
    },
    'service-management': {
      breadcrumb: Columns.servicemanagementInvoiceServiceBreadcrumb(),
      redirectUrl: serviceManagementAccessRoute.LIST_SERVICE.path,
    },
    'scheduled-bookings': {
      breadcrumb: Columns.scheduledBookingsInvoiceServiceBreadcrumb(),
      redirectUrl: scheduledBookingAccessRoute.SCHEDULE_MANAGEMENT.path,
    },
  };

  return (
    <>
      <PageContainerWrapper
        heading={t('text.report.invoiceHeading')}
        breadcrumb={accordingRouteData?.[pathArray[3]]?.breadcrumb}
        btnArray={['extraButton']}
        extraBtnText="Close"
        extraBtnClass="btn btn-outline-light bg-white"
        onExtraButtonHandleShow={() => closeToPage()}
      >
        {loading ? (
          <GlobalLoader />
        ) : !!getInvoiceData ? (
          <Invoice
            invoiceData={getInvoiceData}
            bookingId={id}
            viewInvoice={viewInvoice}
          />
        ) : (
          <NoDataFound
            extraClass="p-4"
            source="/nodata-found.svg"
            text="Booking not found"
            isCustomer={'/assets/images/admin/nodata-found.svg'}
          />
        )}
      </PageContainerWrapper>
    </>
  );
}

export default CommonInvoicePage;
