import { useRoutes } from 'react-router-dom';
import { routes } from './routes/index.routes';
import { useLocation } from 'react-router-dom';
import LexChatbot from './components/Lex';

function RouteLayout({ path }) {
  const element = useRoutes(path);
  return element;
}

function App() {
  const location = useLocation();
  return (
    <div className="App">
      <RouteLayout path={routes()} />
      <LexChatbot pathname={location.pathname} />
    </div>
  );
}

export default App;
