export const PUSH_NOTIFICATION_ENDPOINT = {
  pushNotification: {
    url: '/v1/admin/notification',
    method: 'POST',
  },
  getUserList: {
    url: '/v1/user/list/details',
    method: 'GET',
  },
};
