import React, { useState, useEffect, forwardRef } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Spin, Upload } from 'antd';
import { COMMON_ENDPOINT } from '../../../apiEndpoints/Common/index.endpoint';
import { getLocalStorageToken, modalNotification } from '../../../utils';
import ImageElement from '../ImageElement';
import { uploadPublicFile } from '../../../services/index.service';

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = (file, mediaType) => {
  if (mediaType === 'doc') return true;
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    modalNotification({
      type: 'error',
      message: 'You can only upload JPG/PNG file!',
    });
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    modalNotification({
      type: 'error',
      message: 'Image must smaller than 2MB!',
    });
  }

  return isJpgOrPng && isLt2M;
};

const AvatarUpload = forwardRef(
  (
    { url, setProfile, children, mediaFor = 'user', mediaType = 'image' },
    ref
  ) => {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(url);
    const apiToken = getLocalStorageToken();

    const handleChange = (info) => {
      if (info.file.status === 'uploading') {
        setLoading(true);
        return;
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (url) => {
          if (info?.file?.response?.data?.basePath) {
            setProfile(info?.file?.response?.data?.basePath);
          }
          setLoading(false);
          setImageUrl(url);
        });
      }
      setLoading(false);
    };

    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div
          style={{
            marginTop: 8,
          }}
        >
          Upload
        </div>
      </div>
    );

    useEffect(() => {
      setImageUrl(url);
    }, [url]);

    return (
      <>
        <Upload
          name="file"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          headers={{
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${apiToken}`,
          }}
          // COMMON_ENDPOINT.uploadImageFile(mediaType, mediaFor, 'image/*').url
          action={uploadPublicFile(mediaFor, mediaType)?.url}
          beforeUpload={(e) => beforeUpload(e, mediaType)}
          onChange={handleChange}
          ref={ref}
        >
          {imageUrl ? (
            loading ? (
              <Spin />
            ) : (
              <ImageElement
                previewSource={
                  imageUrl ? imageUrl : '/assets/images/admin/profile-img.jpg'
                }
                alt="img"
                isImagePreview={false}
              />
            )
          ) : (
            uploadButton
          )}
          {children}
        </Upload>
      </>
    );
  }
);
export default AvatarUpload;
