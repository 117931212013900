export const validation = {
  countryId: 'Country name is required.',
  countryName: 'Country name is required.',
  countryNameMax: 'Country name should be less than 60 words',
  countryNameMin: 'Country name at least 2 characters long',
  stateNameMax: 'State name should be less than 60 words',
  cityNameMax: 'City name should be less than 60 words',
  stateId: 'State name is required.',
  stateName: 'State name is required.',
  name: 'City name is required.',
  status: 'Status is required.',
  countryCode: 'Country code is required.',
  countryCodeValid: 'Please enter valid country code',
  unit: 'Please select unit',
  emergencyNumber: 'Emergency number is required.',
  emergencyNumberValid: 'Please enter valid emergency number',
  cgst: 'Cgst is required.',
  cgstValid: 'Cgst must be number',
  phoneCode: 'Country phone code is required.',
  phoneCodeValid: 'Please enter valid country phone code',
  stateCode: 'State code is required.',
  stateCodeValid: 'Please enter valid state code',
  countryNameValid: 'Please enter valid country name',
  stateNameValid: 'Please enter valid state name',
  cityNameValid: 'Please enter valid city name',
  emergencyNumberGreater: 'Emergency number at least 3 characters long',
  emergencyNumberLess:
    'Emergency number should be less than and equal to 10 characters ',
};
