let url = '/v1/admin/line-item';
let url1 = `/v1/line-item`;
export const LINE_ITEMS_ENDPOINT = {
  getLineItemsList: {
    url,
    method: 'GET',
  },
  addLineItems: {
    url,
    method: 'POST',
  },
  editLineItems: (id) => ({
    url: `${url}/${id}`,
    method: 'PUT',
  }),
  deleteLineItems: (id) => ({
    url: `${url}/${id}`,
    method: 'DELETE',
  }),
  getLineItemsById: (id) => ({
    url: `${url}/${id}`,
    method: 'GET',
  }),
  importLineItems: {
    url: `${url}/file`,
    method: 'POST',
  },
  lineItemPart: {
    url: `${url1}/all`,
    method: 'GET',
  },
  getPartList: {
    url: `${url}/part/details`,
    method: 'GET',
  },
  getImportList: {
    url: `${url}/bulk/details`,
    method: 'GET',
  },
  discardImport: {
    url: `${url}/bulk/discard`,
    method: 'POST',
  },
  saveImport: {
    url: `${url}/bulk/save`,
    method: 'POST',
  },
  getPartTitleItems: {
    url: `${url}/part/name`,
    method: 'GET',
  },
};
