import React from 'react';
import { Container } from 'react-bootstrap';
import { Breadcrumb } from '../../../components';
import { useTranslation } from 'react-i18next';

function AboutUs() {

  const {t} =useTranslation();
  const breadcrumb = [
    {
      path: '/Home',
      name: t('text.common.home'),
    },
    {
      path: '#',
      name: t('text.common.aboutUs'),
    },
  ];

  return (
    <>
      <main className="aboutPage">
        <Container>
          <Breadcrumb
            title={t('text.common.aboutUs')}
            breadcrumb={breadcrumb}
            type="customer"
          />
          <div className="aboutPage_inner">
            <div className="aboutPage_inner_terms"></div>
          </div>
        </Container>
      </main>
    </>
  );
}

export default AboutUs;
