import { APIRequest } from '../../helpers/index.helper';
import {
  OPTIONAL_ITEM_ENDPOINT,
  BANK_FINANCING,
  BANK_FINANCING_OPTION,
} from '../../apiEndpoints/index.endpoint';
import { logger } from '../../utils';

export const optionalItemListService = async ({ queryParams }) => {
  try {
    const payload = {
      ...OPTIONAL_ITEM_ENDPOINT.getOptionalItemList,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const optionalItemAddService = async ({ body }) => {
  try {
    const payload = {
      ...OPTIONAL_ITEM_ENDPOINT.addOptionalItem,
      bodyData: body,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const optionalItemViewService = async ({ resourceId }) => {
  try {
    const payload = {
      ...OPTIONAL_ITEM_ENDPOINT?.getOptionalItemDetails(resourceId),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const optionalEditService = async ({ values, id }) => {
  try {
    const payload = {
      ...OPTIONAL_ITEM_ENDPOINT.editOptionalItem(id),
      bodyData: values,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const optionalDeleteService = async ({ id, bodyData }) => {
  try {
    const payload = {
      ...OPTIONAL_ITEM_ENDPOINT.deleteOptionalItem,
      bodyData: bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const updateOptionalItemStatus = async (body) => {
  try {
    const payload = {
      ...OPTIONAL_ITEM_ENDPOINT.itemStatusUpdate,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const bankFinanceListService = async ({ queryParams }) => {
  try {
    const payload = {
      ...BANK_FINANCING.getFinanceBankList,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const bankFinanceAddService = async ({ body }) => {
  try {
    const payload = {
      ...BANK_FINANCING.addFinanceBank,
      bodyData: body,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const bankFinanceViewService = async ({ id }) => {
  try {
    const payload = {
      ...BANK_FINANCING?.getFinanceBankDetails(id),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const bankFinanceEditService = async ({ body, id }) => {
  try {
    const payload = {
      ...BANK_FINANCING.editFinanceBank(id),
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const bankFinanceDeleteService = async ({ id, body }) => {
  try {
    const payload = {
      ...BANK_FINANCING.deleteFinanceBank,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const bankFinanceOptionalItemStatus = async (body) => {
  try {
    const payload = {
      ...BANK_FINANCING.financeBankStatusUpdate,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const optionFinanceListService = async ({ queryParams }) => {
  try {
    const payload = {
      ...BANK_FINANCING_OPTION.getFinanceOptionList,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const optionFinanceAddService = async ({ body }) => {
  try {
    const payload = {
      ...BANK_FINANCING_OPTION.addFinanceOption,
      bodyData: body,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const optionFinanceViewService = async ({ id }) => {
  try {
    const payload = {
      ...BANK_FINANCING_OPTION?.getFinanceOptionDetails(id),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const optionFinanceEditService = async ({ body, id }) => {
  try {
    const payload = {
      ...BANK_FINANCING_OPTION.editFinanceOption(id),
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const optionFinanceDeleteService = async ({ values }) => {
  try {
    const payload = {
      ...BANK_FINANCING_OPTION.deleteFinanceOption,
      bodyData: values,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const optionFinanceOptionalItemStatus = async (body) => {
  try {
    const payload = {
      ...BANK_FINANCING_OPTION.financeOptionStatusUpdate,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const optionalItemTagService = async ({ queryParams }) => {
  try {
    const payload = {
      ...OPTIONAL_ITEM_ENDPOINT.getTags,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
export const QuoteDataService = async ({ queryParams }) => {
  try {
    const payload = {
      ...OPTIONAL_ITEM_ENDPOINT.getQuoteList,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
