import { STORE_SIGNUP_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';

export const StoreSignupService = async (body) => {
  try {
    const payload = {
      ...STORE_SIGNUP_ENDPOINT,
      bodyData: body,
    };
    return await APIRequest(payload);
  } catch (err) {
    logger(err);
  }
};
