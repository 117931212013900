let url = '/v1/admin/document';

export const MANAGE_DOCUMENT_ENDPOINT = {
  getDocumentList: {
    url,
    method: 'GET',
  },
  deleteDocument: {
    url,
    method: 'DELETE',
  },
  addDocument: {
    url,
    method: 'POST',
  },
  editDocument: (id) => ({
    url: `${url}/${id}`,
    method: 'PUT',
  }),
  documentStatusUpdate: {
    url: `${url}/change-status`,
    method: 'PUT',
  },
  getDocumentDetails: (id) => ({
    url: `${url}/${id}`,
    method: 'GET',
  }),
};
