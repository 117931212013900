import React from 'react';
import { StoreBreadcrumb } from '../../UIElements/index.element';

function StorePageContainer({ title, breadcrumb, children }) {
  return (
    <>
      <StoreBreadcrumb title={title} breadcrumb={breadcrumb} />
      <div className="becomePage_inner">{children}</div>
    </>
  );
}

export default StorePageContainer;
