import React from 'react';
import { Col } from 'react-bootstrap';
import AntTooltip from '../../UIElements/Tooltip';
import { Link } from 'react-router-dom';
import ImageElement from '../../UIElements/ImageElement';
import { readMoreTextShow } from '../../../utils';

function FormElementWrapper({
  element,
  label,
  formik,
  extraTopRightElement = <></>,
  visible = true,
  tooltip = false,
  wrapper,
  tooltipText = '',
  mandatory = false,
  link = false,
  actionHandler,
  extraLabelClass = false,
  formNote = false,
  imgPreviewBox = false,
  // img,
  linkUrl = false,
  newLabel,
  extraLabel,
  extraClassName = '',
  button = false,
  actionButtonHandler,
  btnIcon = false,
  labelWidth = true,
  infoLabel = false,
  isReadMore = false,
  ...rest
}) {
  if (wrapper) {
    return wrapper(
      <>
        {visible && (
          <div className="form-group" {...rest}>
            {label && (
              <div className="form-label-group">
                <label className="form-label">
                  {label} {mandatory && <span className="text-danger"> *</span>}{' '}
                  {tooltip && (
                    <AntTooltip
                      promptText={tooltipText}
                      className="icon ni ni-question"
                    ></AntTooltip>
                  )}
                </label>
              </div>
            )}
            {element && (
              <div
                className="form-control-wrap"
                onClick={(e) => e.stopPropagation()}
              >
                {element(formik)}
              </div>
            )}
          </div>
        )}
      </>
    );
  }
  return (
    <>
      {visible && (
        <Col className={`form-group ${extraClassName}`} {...rest}>
          {label && (
            <div className="form-label-group">
              <label
                className={`form-label ${labelWidth ? 'w-100' : ''} ${
                  extraLabelClass ? extraLabelClass : ''
                }`}
              >
                {label} {mandatory && <span className="text-danger">*</span>}{' '}
                {infoLabel ? (
                  isReadMore ? (
                    <span className="text-primary">
                      (
                      {readMoreTextShow(infoLabel, 'Membership Plan', 30, true)}
                      )
                    </span>
                  ) : (
                    <span className="text-primary pe-none">{`(${infoLabel})`}</span>
                  )
                ) : (
                  ''
                )}
                {tooltip && (
                  <AntTooltip
                    promptText={tooltipText}
                    className="icon ni ni-info-fill"
                  ></AntTooltip>
                )}
                {button && (
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={actionButtonHandler}
                    type="button"
                  >
                    {btnIcon && <em class="icon ni ni-info-fill me-1"></em>}
                    {button}
                  </button>
                )}
                {link && (
                  <Link
                    to={linkUrl}
                    className="float-end"
                    onClick={actionHandler}
                  >
                    {link}
                  </Link>
                )}
              </label>
              {extraTopRightElement}
            </div>
          )}
          {extraLabel && (
            <label className="ant-label">
              {extraLabel} {mandatory && <span className="text-danger">*</span>}
            </label>
          )}{' '}
          {newLabel && (
            <h5 className="becomePage_steps_label">
              {newLabel}
              <span className="text-650">*</span>
            </h5>
          )}
          {element && (
            <div className="form-control-wrap">{element(formik)}</div>
          )}
          {formNote && (
            <p className="form-note">
              <b>{formNote}</b>
            </p>
          )}
          {imgPreviewBox && (
            <div className="imgPreviewBox">
              <ImageElement source="uploadImg.png" alt="img" />
              <div className="imgPreviewBox_icon">
                <Link href="#" className="btn btn-primary">
                  <em className="icon ni ni-cross"></em>
                </Link>
              </div>
            </div>
          )}
        </Col>
      )}
    </>
  );
}

export default FormElementWrapper;
