/* eslint-disable react-hooks/exhaustive-deps */
import i18next from 'i18next';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  FireAlert,
  capitalizeStringFirstLetter,
  modalNotification,
  updateTableData,
} from '../utils';
import { updateIsEnableExportStatus } from '../redux/index.redux';
import { useDispatch, useSelector } from 'react-redux';
import { serialNumberFormatter } from '../components/UIElements/Formatter';

/******
 * Decs: useTable hook handle table operations and pagination
 * Returns:
 * *** */
function useTable(
  apiHandler,
  extraQuery = {},
  extraDataKey = '',
  isAnotherTable = false,
  setExtraQuery,
  extraCSVHandle = false,
  isPageSize = false,
  isScope = true,
  sortObj = false
) {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const prevCountRef = useRef();
  const query = new URLSearchParams(useLocation()?.search);
  const displayPerPage = useSelector((state) => state?.auth?.displayPerPage);

  const [tData, setData] = useState({
    data: [],
    isLoading: true,
    count: 0,
    noOfPages: 0,
    extraData: '',
    allData: [],
  });
  const [filteredValue, setFilteredValue] = useState({});

  const [table, setTable] = useState({
    page: query.get('page') || 1,
    sizePerPage: isPageSize || query.get('pageSize') || displayPerPage || 10,
    search: query.get('search') || '',
    sortBy: sortObj?.sortBy || 'id',
    sortType: sortObj?.sortType || 'DESC',
    filter: { ...extraQuery },
  });
  const { sizePerPage, page, search, filter, sortBy, sortType } = table;

  const { noOfPages, count, isLoading, data, extraData, allData } = tData;
  let { sortColumn, sortOrder, searchData, sizePerPages, currentPage } =
    isAnotherTable;

  useEffect(() => {
    // prevCountRef.current = {...data};
    setData({
      data: [],
      isLoading: true,
      count: 0,
      noOfPages: 0,
      allData: [],
    });
    getData(getListParams());
  }, [table]);

  function onRefresh() {
    try {
      getData(getListParams());
    } catch (err) {
      console.log(err);
    }
  }

  const SORTER = {
    descend: 'desc',
    ascend: 'asc',
  };

  useEffect(() => {
    setTable({
      ...table,
      page: query.get('page') || 1,
      sizePerPage: isPageSize || query.get('pageSize') || displayPerPage || 10,
      search: query.get('search') || '',
      sortBy: query.get('sortBy') || sortObj?.sortBy || 'id',
      sortType: SORTER[query.get('sortType')] || sortObj?.sortType || 'DESC',
    });
  }, [location]);

  useEffect(() => {
    if (sortColumn || sortOrder || searchData || sizePerPages || currentPage) {
      setTable({
        ...table,
        page: currentPage || 1,
        sortBy: sortColumn || '',
        sortType: sortOrder || '',
        search: searchData || '',
        sizePerPage: sizePerPages || displayPerPage || 10,
      });
    }
  }, [sortColumn, sortOrder, searchData, sizePerPages, currentPage]);

  // For Availability change toggle function Start
  async function availabilityToggleChange(e, row, updateAvailablitilyAPI, key) {
    try {
      let status = e ? 'yes' : 'no';
      // Payload id array change like userIds
      const bodyData = {
        [key]: status,
      };
      const { success, message } = await updateAvailablitilyAPI({
        bodyData,
        id: row?.id,
      });
      if (success) {
        const getUpdatedTableData = updateTableData(
          data,
          row,
          status,
          'stockAvailabilityStatus'
        );
        setData({ ...tData, data: [...getUpdatedTableData] });
        modalNotification({
          type: 'success',
          message,
        });
      }
      return success ? true : false;
    } catch (err) {
      return false;
    }
  }

  // For Status change toggle function Start
  async function onToggleChange(
    e,
    row,
    type,
    updateItemStatusAPI,
    key,
    isDropdown
  ) {
    try {
      let status = e;
      if (isDropdown) {
        status = e;
      } else {
        status = e ? 'active' : 'inactive';
      }
      if (type && type === 'action') {
        status = e;
      }
      const listOfIds = row?.id ? [row?.id] : [...row];
      const payload = {
        [key]: listOfIds,
        status: status,
      };

      const { success, message } = await updateItemStatusAPI(payload);
      if (success) {
        const getUpdatedTableData = updateTableData(
          data,
          row,
          status,
          'status'
        );
        setData({ ...tData, data: [...getUpdatedTableData] });
        // onFilterChange();
        modalNotification({
          type: 'success',
          message,
        });
      }
      return success ? true : false;
    } catch (err) {
      return false;
    }
  }
  function getListParams() {
    try {
      console.log(sizePerPage);
      const query = {
        limit: sizePerPage,
        // Extra update
        offset: isAnotherTable
          ? isAnotherTable?.sizePerPage * (isAnotherTable?.currentPage - 1)
          : sizePerPage * (page - 1),
        search: search,
        sortBy,
        sortType,
        ...extraQuery,
        ...filter,
      };

      if (filter?.multiFilterIndex) {
        delete query.multiFilterIndex;
      }
      return query;
    } catch (err) {
      console.log(err);
    }
  }

  async function onPageSizeChange(e) {
    try {
      setTable({ ...table, page: 1, sizePerPage: parseInt(e) });
    } catch (err) {
      console.log(err);
    }
  }

  async function onSearch(e) {
    try {
      // console.log('Page size search');
      setTable({ ...table, search: e });
    } catch (err) {
      console.log(err);
    }
  }

  async function tableReset() {
    try {
      console.log('Page size reset');
      setTable({ ...table, page: 1 });
    } catch (err) {
      console.log(err);
    }
  }

  async function onTableSort(col, order) {
    try {
      setTable({ ...table, sortBy: col, sortType: order });
    } catch (err) {
      console.log(err);
    }
  }

  function onPageChange() {
    try {
      console.log('Location');
    } catch (err) {
      console.log(err);
    }
  }

  async function onFilterChange(e, shouldReset) {
    try {
      if (shouldReset) {
        onFilterResetChange();
        return true;
      }
      getListParams();
      setTable({
        ...table,
        page: 1,
        filter: { ...(table?.filter || {}), ...e },
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function onFilterResetChange() {
    try {
      console.log('Page size onFilterResetChange');
      setTable((prevState) => ({
        ...prevState,
        filter: {},
        page: 1,
        search: '',
        sortBy: sortObj?.sortBy || '',
        sortType: sortObj?.sortType || '',
        sizePerPage: displayPerPage || 10,
      }));
      if (setExtraQuery) setExtraQuery('');
    } catch (err) {
      console.log(err);
    }
  }

  async function onExport(
    scope = 'all',
    columns,
    extra,
    extraCol = [],
    extraKey,
    extraRowCol = []
  ) {
    try {
      const queries = getListParams();
      const { data } = await getData(
        {
          ...queries,
          ...(isScope && { scope: scope || 'all' }),
          offset: 0,
          limit: 500,
        },
        true
      );

      if (data?.rows) {
        let exprtaCSVData = {};
        if (extraCSVHandle) {
          exprtaCSVData = await extraCSVHandle(data);
        }

        let exportEntities = data.rows.map((e, i) => {
          const exportData = {};

          columns.forEach((ele) => {
            if (extraCol) {
              extraCol.forEach((el) => {
                if (el === 'sn') {
                  exportData[extraKey[el]] = i + 1;
                } else if (e[el]) {
                  exportData[extraKey[el]] = e[el];
                }
              });
            }

            if ((ele?.dataField || ele?.dataIndex).split('.')) {
              const sepCol = (ele?.dataField || ele?.dataIndex).split('.');

              if (sepCol.length === 3 && ele?.export !== false) {
                if (
                  e[sepCol[0]] &&
                  e[sepCol[0]][sepCol[1]] &&
                  e[sepCol[0]][sepCol[1]][sepCol[2]]
                ) {
                  if (ele?.exportFormatter) {
                    exportData[
                      capitalizeStringFirstLetter(
                        ele?.exportName || ele?.text || sepCol[0]
                      )
                    ] = ele?.exportFormatter(
                      e[sepCol[0]][sepCol[1]][sepCol[2]],
                      e
                    );
                  } else {
                    exportData[
                      capitalizeStringFirstLetter(ele?.exportName ?? sepCol[0])
                    ] = e[sepCol[0]][sepCol[1]][sepCol[2]];
                  }
                }
              } else if (sepCol.length === 2 && ele?.export !== false) {
                if (e[sepCol[0]] && e[sepCol[0]][sepCol[1]]) {
                  if (ele?.exportFormatter) {
                    // exportData[
                    //   firstLetterCaps(ele?.exportName || ele?.text || sepCol[0])
                    // ] = ele?.exportFormatter(e[ele?.dataField], e);

                    exportData[
                      capitalizeStringFirstLetter(
                        ele?.exportName || ele?.text || sepCol[0]
                      )
                    ] = ele?.exportFormatter(e[sepCol[0]][sepCol[1]], e);
                  } else {
                    exportData[
                      capitalizeStringFirstLetter(ele?.exportName ?? sepCol[0])
                    ] = e[sepCol[0]][sepCol[1]];
                  }
                }
              } else {
                if (
                  (e[ele?.exportKey || ele?.dataField || ele?.dataIndex] ||
                    typeof e[
                      ele?.exportKey || ele?.dataField || ele?.dataIndex
                    ] === 'number' ||
                    e[ele?.exportKey || ele?.dataField || ele?.dataIndex] ===
                      0) &&
                  ele?.export !== false
                ) {
                  if (ele?.exportFormatter) {
                    if (ele?.title === i18next.t('text.common.serialNo')) {
                      exportData[capitalizeStringFirstLetter(ele?.title)] =
                        serialNumberFormatter({
                          page: 1,
                          sizePerPage: 10,
                          index: i,
                        });

                      if (ele?.exportIdRemove) {
                        exportData[
                          capitalizeStringFirstLetter(ele?.dataIndex)
                        ] = e[ele?.dataField || ele?.dataIndex];
                      }
                    } else {
                      exportData[
                        capitalizeStringFirstLetter(
                          ele?.exportName ||
                            ele?.text ||
                            (ele?.title === i18next.t('text.common.serialNo') &&
                              i18next.t('text.common.id')) ||
                            ele?.title ||
                            ele?.dataField ||
                            ele?.dataIndex
                        )
                      ] = ele?.exportFormatter(
                        e[ele?.dataField || ele?.dataIndex],
                        e
                      );
                    }
                  } else {
                    exportData[
                      capitalizeStringFirstLetter(
                        ele?.exportName ||
                          ele?.text ||
                          (ele?.title === i18next.t('text.common.serialNo') &&
                            i18next.t('text.common.id')) ||
                          ele?.title ||
                          ele?.dataField ||
                          ele?.dataIndex
                      )
                    ] = e[ele?.dataField || ele?.dataIndex];
                  }
                }
              }
            }
          });
          return exportData;
        });
        if (extra || exprtaCSVData) {
          exportEntities = exportEntities.map((e, i) => {
            Object.keys(extra || exprtaCSVData).forEach((keys) => {
              e[keys] = extra
                ? extra[keys][i]?.name
                : exprtaCSVData
                ? exprtaCSVData[keys][i]?.name
                : '';
            });
            return e;
          });
        }
        if (extraRowCol) {
          extraRowCol?.forEach?.((extraRowColItem) => {
            exportEntities?.push?.(extraRowColItem);
          });
        }
        return exportEntities;
      }
      return [];
    } catch (err) {
      console.log(err);
    }
  }

  async function getData(params = getListParams(), isIsolated = false) {
    try {
      // if (prevCountRef.current === 'API_LOCK') {
      if (
        prevCountRef.current ===
        parseFloat(params?.offset) + parseFloat(params?.limit)
      ) {
        return;
      }
      // prevCountRef.current = 'API_LOCK';
      prevCountRef.current =
        parseFloat(params?.offset) + parseFloat(params?.limit);

      if (!isIsolated) {
        setData({
          isLoading: true,
        });
      }
      const res = await apiHandler({ queryParams: params });
      prevCountRef.current = null;

      if (res?.success && Array.isArray(res?.data?.rows)) {
        const noOfPages = Math.ceil(res?.data?.count / sizePerPage);

        dispatch(
          updateIsEnableExportStatus(
            res?.data?.rows ? res?.data?.rows : res?.data
          )
        );

        if (!isIsolated) {
          setData({
            data: [...res?.data?.rows],
            count: res?.data?.count,
            noOfPages,
            isLoading: false,
            extraData: res?.data && res?.data[extraDataKey],
            allData: res,
          });
        }
        return res;
      } else if (res?.success && res?.data) {
        setData({
          data: res?.data,
          isLoading: false,
          extraData: res?.data && res?.data[extraDataKey],
          allData: res,
        });
      } else {
        if (res?.success) {
          setData({
            data: res?.data,
            isLoading: false,
            extraData: res?.data && res?.data[extraDataKey],
            allData: res,
          });
        } else {
          setData({
            isLoading: false,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // For Delete
  async function onConfirmDeleteAlert({
    id,
    text,
    service,
    key,
    title,
    html,
    userId = null,
  }) {
    FireAlert({
      title: title ? title : t('text.common.areYouSure'),
      text,
      icon: t('text.common.warning'),
      confirmButtonText: t('text.common.yes'),
      cancelButtonText: t('text.common.no'),
      html,
      preConfirm: () => onDelete(id, service, key, userId),
    });
  }

  async function onDelete(id, service, key, userId) {
    try {
      let idArray = Array.isArray(id)
        ? // data.map((e) => e.id).filter((item) => id.includes(item))
          id
        : [id];
      const res = await service({
        bodyData: { [key]: idArray, ...(userId && { userId }) },
      });
      if (res?.success) {
        modalNotification({
          type: 'success',
          message: res?.message ? res?.message : 'Successfully Deleted',
        });
        onRefresh();
      }
      return res?.success ? true : false;
    } catch (err) {
      console.log(err);
    }
  }

  const tableProps = {
    data,
    isLoading,
    count,
    page,
    noOfPages,
    sizePerPage,
    search,
    filter,
    extraData,
    filteredValue,
    sortBy,
    sortType,
    allData,
  };
  const tableHandler = {
    onPageSizeChange,
    tableReset,
    onSearch,
    onPageChange,
    onRefresh,
    onFilterChange,
    onExport,
    getData,
    onTableSort,
    setFilteredValue,
    onFilterResetChange,
    onToggleChange,
    availabilityToggleChange,
    onConfirmDeleteAlert,
    getListParams,
  };
  return [tableHandler, tableProps];
}

export default useTable;
