import loadable from '@loadable/component';
import FullPageLoader from '../../components/UIElements/Loader/fullPageLoader';

export const StoreListing = loadable(() => import('./Store/Listing'), {
  fallback: <FullPageLoader />,
});
export const EditStore = loadable(() => import('./Store/Edit'), {
  fallback: <FullPageLoader />,
});
export const AddStore = loadable(() => import('./Store/Create'), {
  fallback: <FullPageLoader />,
});
export const DocumentsView = loadable(() => import('./Store/Documents'), {
  fallback: <FullPageLoader />,
});

export const PromoCodeListing = loadable(() => import('./PromoCode/List'), {
  fallback: <FullPageLoader />,
});
export const AddPromoCode = loadable(() => import('./PromoCode/Create'), {
  fallback: <FullPageLoader />,
});
export const EditPromoCode = loadable(() => import('./PromoCode/Edit'), {
  fallback: <FullPageLoader />,
});
export const MarketingEmailTemplate = loadable(
  () => import('./MarketingTemplate/MarketingEmailTemplate/View'),
  {
    fallback: <FullPageLoader />,
  }
);
export const AddMarketingEmailTemplate = loadable(
  () => import('./MarketingTemplate/MarketingEmailTemplate/Create'),
  {
    fallback: <FullPageLoader />,
  }
);
export const EditMarketingEmailTemplate = loadable(
  () => import('./MarketingTemplate/MarketingEmailTemplate/Edit'),
  {
    fallback: <FullPageLoader />,
  }
);
export const ViewMarketingSMSTemplate = loadable(
  () => import('./MarketingTemplate/MarketingSMSTemplates/View'),
  {
    fallback: <FullPageLoader />,
  }
);
export const AddMarketingSmsTemplate = loadable(
  () => import('./MarketingTemplate/MarketingSMSTemplates/Create'),
  {
    fallback: <FullPageLoader />,
  }
);
export const EditMarketingSmsTemplate = loadable(
  () => import('./MarketingTemplate/MarketingSMSTemplates/Edit'),
  {
    fallback: <FullPageLoader />,
  }
);
export const ViewMarketingReviewTemplate = loadable(
  () => import('./MarketingTemplate/MarketingReviewTemplate/View/index'),
  {
    fallback: <FullPageLoader />,
  }
);
export const MarketingMessageSend = loadable(
  () => import('./MarketingTemplate/MarketingMessageSend'),
  {
    fallback: <FullPageLoader />,
  }
);
export const AdvertisementListing = loadable(
  () => import('./Advertisement/Listing'),
  {
    fallback: <FullPageLoader />,
  }
);
