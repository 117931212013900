import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  ActionWrapper,
  PageContainerWrapper,
  TablePlus,
  CommonButton,
} from '../../../../../components';
import useTable from '../../../../../hooks/useTable.hook';
import { accessRoute } from '../../../../../routes/PriceManagement';
import {
  quoteViewImageBreadcrumb,
  quoteViewImageTableColumn,
} from '../../../../../services/Columns';
import {
  deleteViewQuoteImageService,
  getActions,
  listViewQuoteImageService,
} from '../../../../../services/index.service';
import { backToPage, FireAlert, modalNotification } from '../../../../../utils';

function ViewImages() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const redirectUrl = accessRoute.QUOTE_SYSTEM.path;
  const breadcrumb = quoteViewImageBreadcrumb();

  // Get table data by api
  const [tableHandler, tableProps] = useTable(listViewQuoteImageService);
  const {
    data,
    isLoading,
    count,
    page,
    noOfPages,
    sizePerPage,
    filter,
    sortBy,
    sortType,
  } = tableProps;
  const { onRefresh, onTableSort, onFilterResetChange } = tableHandler;

  // For Delete Alert Section Start
  const onConfirmAlert = async (id) => {
    FireAlert({
      title: t('text.common.areYouSure'),
      text: t('text.quote.areYouSureDeleteQuoteImage'),
      icon: t('text.common.warning'),
      confirmButtonText: t('text.common.delete'),
      preConfirm: () => onDelete(id),
    });
    return true;
  };
  // For delete
  async function onDelete(id) {
    try {
      const { success, message } = await deleteViewQuoteImageService({
        id: Number(id),
      });
      if (success && message) {
        modalNotification({
          type: 'success',
          message,
        });
        onRefresh();
      }
      return success ? true : false;
    } catch (err) {
      console.log(err);
    }
  }

  // For action with column
  function onAction(e, handler, item) {
    e.preventDefault();
    if (handler) handler(item);
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler, item)}
              key={key}
              className="action_list"
              action={item?.action}
            >
              <CommonButton>
                <em className={item?.icon} /> {item?.name}
              </CommonButton>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };

  const columns = quoteViewImageTableColumn({
    page,
    sizePerPage,
    onSort: onTableSort,
    Action: (props) =>
      actionFormatter([
        {
          name: 'Delete',
          icon: 'icon ni ni-trash',
          action: getActions('DELETE').value,
          handler: () => {
            onConfirmAlert(props?.row?.id);
            document.body.click();
          },
        },
      ]),
  });

  return (
    <>
      <PageContainerWrapper
        breadcrumb={breadcrumb}
        btnArray={['extraButton']}
        extraBtnText={t('text.common.back')}
        extraBtnClass="btn btn-outline-light bg-white"
        onExtraButtonHandleShow={() => backToPage(navigate, redirectUrl)}
        heading={t('text.quote.allQuoteImages')}
      >
        <TablePlus
          hasLimit
          noOfPage={noOfPages}
          sizePerPage={sizePerPage}
          page={page}
          count={count}
          tableData={data}
          tableColumns={columns}
          selectRow
          tableLoader={isLoading}
          showAction={false}
          statusOptDisabled={false}
          onFilterResetChange={onFilterResetChange}
          searchField
          resetAll={false}
          filter={filter}
          sortBy={sortBy}
          sortType={sortType}
        />
      </PageContainerWrapper>
    </>
  );
}

export default ViewImages;
