import React from 'react';
import {
  FormElementWrapper,
  FormWrapper,
  TextInput,
} from '../../../components';
import {
  paymentInitialValues,
  paymentValidation,
} from '../../../components/Forms/CustomerApp/validation';
import { useTranslation } from 'react-i18next';
import { months } from '../../../services/Register';
import { expireYearList } from '../../../utils';
import Select from '../../../components/UIElements/Select';
import CommonButton from '../../../components/UIElements/Button/Common/index.btn';

const PaymentModal = ({ onSubmit }) => {
  const { t } = useTranslation();

  const yearData = expireYearList();
  const monthsData = months();

  return (
    <div className="g-3">
      <FormWrapper
        enableReinitialize={true}
        initialValues={paymentInitialValues()}
        validation={paymentValidation}
        onSubmit={async (values, { setSubmitting }) => {
          await onSubmit(values);
          setSubmitting(false);
        }}
        extraClassName="g-3"
      >
        {(props) => (
          <>
            <FormElementWrapper
              md={12}
              label={t('text.customerPayment.cardHolderName')}
              mandatory={true}
              element={() => (
                <TextInput
                  name="name"
                  placeholder={t('text.customerPayment.cardHolderName')}
                  formik={props}
                />
              )}
            />
            <FormElementWrapper
              md={12}
              label={t('text.customerPayment.cardNumber')}
              mandatory={true}
              element={() => (
                <TextInput
                  name="cardNumber"
                  placeholder={t('text.customerPayment.cardNumber')}
                  formik={props}
                />
              )}
            />
            <FormElementWrapper
              md={12}
              label={t('text.customerPayment.expDate')}
              mandatory={true}
              element={() => (
                <Select
                  name="expMonth"
                  formik={props}
                  options={monthsData}
                  placeholder="Month"
                  showSearch
                />
              )}
            />
            <FormElementWrapper
              md={12}
              label={t('text.customerPayment.expYear')}
              mandatory={true}
              element={() => (
                <Select
                  name="expYear"
                  placeholder="Year"
                  formik={props}
                  options={yearData}
                  showSearch
                />
              )}
            />
            <FormElementWrapper
              md={12}
              label={t('text.customerPayment.cvv')}
              mandatory={true}
              element={() => (
                <TextInput
                  name="cvv"
                  placeholder={t('text.customerPayment.cvv')}
                  formik={props}
                />
              )}
            />
            <div className="text-center mt-2">
              <CommonButton
                type="submit"
                loading={props.isSubmitting}
                disabled={props.isSubmitting}
                className="btn btn-primary"
              >
                {t('text.customerPayment.payNow')}
              </CommonButton>
            </div>
          </>
        )}
      </FormWrapper>
    </div>
  );
};

export default PaymentModal;
