import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { saveAs } from 'file-saver';
import loadable from '@loadable/component';
import { Shimmer } from '../../../index';
import { firstLetterCaps } from '../../../../utils/text.util';
import CommonButton from '../Common/index.btn';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

const XLSX = loadable.lib(() => import('xlsx'));

export default function CsvExport({
  fileName,
  onClick,
  extraClassName = '',
  disabled,
}) {
  const { t } = useTranslation();
  const ref = useRef(null);
  const location = useLocation();
  const [state, setState] = useState({ exportLoader: false });
  const { exportLoader } = state;
  const fileNameArr = location?.pathname?.split('/');
  const exportName = `${fileNameArr?.pop()}-${Date.now()}`;

  useEffect(() => {
    if (ref?.current) {
      setState({ ...state, componentLoader: true });
    }
  }, [ref.current]);

  function exportExcel({ list }) {
    try {
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      const ws = ref.current.utils.json_to_sheet(list);

      /*********Converting header text case********** */
      // console.log("List", list);
      // Object.keys(ws).forEach(e => {
      //   if (ws[e]?.v && firstLetterCaps(ws[e].v)) {
      //     ws[e].v = firstLetterCaps(ws[e].v);
      //   }
      // })

      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = ref.current.write(wb, {
        bookType: 'xlsx',
        type: 'array',
      });
      const data = new Blob([excelBuffer], { type: fileType });
      saveAs(data, firstLetterCaps(exportName) + fileExtension);
    } catch (err) {
      console.log(err);
    }
  }

  async function onExport(e) {
    try {
      e.preventDefault();
      setState({
        ...state,
        exportLoader: true,
      });
      const res = await onClick();
      if (ref.current) {
        exportExcel({ list: res?.data?.rows || res, filename: fileName });
      }
      setState({
        ...state,
        exportLoader: false,
      });
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <>
      <XLSX ref={ref} fallback={<Shimmer />}>
        {() => (
          <Tooltip
            title={disabled ? t('validation.dashboard.noDataExport') : null}
          >
            <CommonButton
              onClick={(e) => !disabled && !state.exportLoader && onExport(e)}
              className={`btn  ${
                extraClassName ? extraClassName : 'btn-white btn-outline-light'
              }`}
            >
              <em className="icon ni ni-download-cloud" />
              <span>{exportLoader ? 'Exporting...' : 'Export'}</span>
            </CommonButton>
          </Tooltip>
        )}
      </XLSX>
    </>
  );
}
