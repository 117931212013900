import React, { useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
  PageContainerWrapper,
  ServiceSubcategoryForm,
} from '../../../../../../components';
import { backToPage } from '../../../../../../utils';
import { useTranslation } from 'react-i18next';
import { formSubmit } from '../../../../../../hooks/useFormSubmit.hook';
import {
  editBreadcrumbServiceSubcategory,
  editNCABreadcrumbServiceSubcategory,
} from '../../../../../../services/Columns';
import { accessRoute } from '../../../../../../routes/PriceManagement';
import { accessRoute as NCARoute } from '../../../../../../routes/AdminSetting';
import {
  editSubServiceCategoryService,
  ListSubServiceCategoryService,
  viewSubServiceCategoryService,
} from '../../../../../../services/ManageServices/serviceSubcategory.service';
import { useEffect } from 'react';
import { getApiData } from '../../../../../../hooks/useGetApi.hook';
import useTable from '../../../../../../hooks/useTable.hook';
import { Spin } from 'antd';
import NoteFooter from '../../../../../../components/Common/NoteFooter';

function EditSubServiceCategory({ ncaEdit = false, userId }) {
  const [formData, setFormData] = useState({});
  const [isGlobalLoading, setIsGlobalLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { id, subcategoryId } = useParams();
  const { t } = useTranslation();

  const ncaUrl =
    ncaEdit &&
    `${generatePath(NCARoute?.NCA_VIEW_SUB_CATEGORY.path, {
      id: subcategoryId,
      userId,
    })}`;

  const url = generatePath(accessRoute.LIST_SUB_SERVICE_CATEGORY.path, {
    id: subcategoryId,
  });

  const redirectUrl = ncaEdit ? ncaUrl : url;

  const ncaEditUrl =
    ncaEdit &&
    `${generatePath(NCARoute?.EDIT_NCA.path, {
      id: userId,
    })}`;

  const breadcrumb = ncaEdit
    ? editNCABreadcrumbServiceSubcategory({ ncaUrl, ncaEditUrl })
    : editBreadcrumbServiceSubcategory({ id: subcategoryId });

  let extraQuery = {
    serviceCategoryId: subcategoryId,
  };
  const [, tableProps] = useTable(
    ListSubServiceCategoryService,
    extraQuery,
    'category'
  );
  const { count, extraData } = tableProps;

  async function onSubmit(values) {
    values.categoryId = subcategoryId;
    delete values?.callingServiceName;
    formSubmit({
      values,
      service: editSubServiceCategoryService,
      navigate,
      redirectUrl,
      setIsLoading,
      id,
    });
  }

  useEffect(() => {
    getApiData({
      setIsLoading: setIsGlobalLoading,
      id,
      service: viewSubServiceCategoryService,
      setData: setFormData,
    });
  }, [id]);

  return (
    <div className="wide-md mx-auto">
      <PageContainerWrapper
        breadcrumb={breadcrumb}
        btnArray={['extraButton']}
        extraBtnText={t('text.common.back')}
        extraBtnClass="btn btn-outline-light bg-white"
        onExtraButtonHandleShow={() => backToPage(navigate, redirectUrl)}
        heading={`${t('text.manageServices.editSubCategory')} ${extraData ? `(${extraData})` : ''
          } `}
      >
        {!isGlobalLoading ? (
          <ServiceSubcategoryForm
            onSubmit={onSubmit}
            formData={formData}
            isLoading={isLoading}
            backToPage={() => backToPage(navigate, redirectUrl)}
            submitText={t('text.common.add')}
            count={() => {
              return Array.from(Array((count || 0) + 1)?.keys());
            }}
            serviceCategoryId={subcategoryId}
            removeFiled={true}
          />
        ) : isGlobalLoading ? (
          <div className="text-center">
            <Spin />
          </div>
        ) : null}
        <NoteFooter title={t('text.common.notes')}>
          <li>{t('text.manageServices.addEditSubCategoryNoteOne')}</li>
        </NoteFooter>
      </PageContainerWrapper>
    </div>
  );
}

export default EditSubServiceCategory;
