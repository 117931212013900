import React, { useState, useEffect, createContext, useCallback } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Spin } from 'antd';
import notification from '../utils/notifications';

import { providerListService } from '../services/TeamSetting/provider.service';
import moment from 'moment';

export const ChatContext = createContext({});

const PER_PAGE_DATA = 20;

const ChatProvider = ({ children, userDetails }) => {
  const location = useLocation();
  const [isOpenCallingDrawer, setIsOpenCallingDrawer] = useState(false);
  const [selectUser, setSelectUser] = useState();
  const [chatWithSPModal, setChatWithSPModal] = useState(false);
  const [isOpenCreateGroupModal, setIsOpenCreateGroupModal] = useState(false);
  const [isOpenChatProfileDrawer, setIsOpenChatProfileDrawer] = useState(false);
  const [isOpenAddMemberInGroupModal, setOpenAddMemberInGroupModal] =
    useState(false);
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [isUploadItems, setIsUploadItems] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [hasMoreFetch, setHasMoreFetch] = useState(true);
  const [messageCount, setMessageCount] = useState(0);
  const [currentMessagePage, setCurrentMessagePage] = useState(0);
  const [providers, setProviders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDisabledLoadMore, setIsDisabledLoadMore] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [searchProviderName, setSearchProviderName] = useState('');
  const [isProviderLoading, setIsProviderLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState();
  const [isMobileViewOpen, setIsMobileViewOpen] = useState(false);
  const [isLoadMoreLoading, setIsLoadMoreLoading] = useState(false);
  const [isMsgSendUserIds, setIsMsgSendUserIds] = useState([]);
  const [chatRoomUserLists, setChatRoomUserLists] = useState([]);
  const [chatroomDetails, setChatroomDetails] = useState('');
  const [chatUserList, setChatUserList] = useState(0);
  const [isLoadUpdate, setIsLoadUpdate] = useState(false);
  const [isLoadingChat, setIsLoadingChat] = useState(false);

  const getServiceProviderData = async ({
    search = '',
    page,
    isLoadMore,
  } = {}) => {
    if (isLoadMore) {
      setIsLoadMoreLoading(true);
    }
    if (!isLoadMore && typeof search === 'string') {
      setIsSearch(true);
      setIsProviderLoading(true);
      setProviders([]);
    }
    try {
      let queryParams = {
        search,
        offset: ((page ? page : currentPage) - 1) * PER_PAGE_DATA,
        limit: PER_PAGE_DATA,
        loginUserShow: true,
        mentalStatus: selectedFilter?.query && selectedFilter?.query,
      };
      const { success, data } = await providerListService({ queryParams });
      if (success) {
        const providersData = [
          ...(page && !search ? [...providers, ...data.rows] : data.rows),
        ];
        setProviders(providersData);
        if (page) {
          setCurrentPage(page);
        }
        if (providersData.length >= data.count) {
          setIsDisabledLoadMore(true);
        } else {
          setIsDisabledLoadMore(false);
        }
      }

      if (isLoadMore) {
        setIsLoadMoreLoading(false);
      } else {
        setIsProviderLoading(false);
      }
    } catch (err) {
      notification({ type: 'error', message: err?.response?.data?.message });
      if (page) {
        setIsLoadMoreLoading(false);
      } else {
        setIsProviderLoading(false);
      }
    }
  };

  const handleIncrementPerPage = () => {
    getServiceProviderData({
      page: currentPage + 1,
      isLoadMore: true,
    });
  };

  const handleSearchProviders = (search) => {
    setProviders([]);
    getServiceProviderData({ search, page: 1 });
    setSearchProviderName('');
  };

  const handleResetProviderDetails = () => {
    setCurrentPage(1);
    setProviders([]);
    setIsSearch(false);
    setSelectedFilter(null);
  };

  const handleOpenChatProfileDrawer = () => {
    setIsOpenChatProfileDrawer(!isOpenChatProfileDrawer);
  };

  const handleOpenAddMemberInGroupModal = () =>
    setOpenAddMemberInGroupModal(!isOpenAddMemberInGroupModal);

  const handleOpenChatWithSPModal = () => {
    setChatWithSPModal(!chatWithSPModal);
  };

  const handleOpenGroupModal = (selectUserDetails) => {
    setIsOpenCreateGroupModal(!isOpenCreateGroupModal);
    if(selectUserDetails){
      setChatroomDetails(selectUserDetails)
    }
  };

  const handleChangeProviderName = useCallback((e) => {
    setSearchProviderName(e.target.value);
  }, []);

  const showChatHandle = () => {
    setIsMobileViewOpen(!isMobileViewOpen);
  };

  const uploadItemsHandleClick = () => {
    if (!isUploadItems) setFileList([]);
    setIsUploadItems(!isUploadItems);
  };

  const getProfileImageUrl = (roomUser) => {
    let imageUrl = '';
    if (roomUser.chatRoom?.chatType === 'group') {
      if (!roomUser?.chatRoom?.imageUrl?.includes?.('default-images')) {
        imageUrl = roomUser?.chatRoom?.imageUrl;
      } else {
        imageUrl = '/assets/images/admin/groups.png';
      }
    } else {
      let singleUserImgUrl = roomUser?.chatRoom?.ChatRoomMembers?.find?.(
        (memberItem) => memberItem?.memberUserId !== userDetails?.id
      )?.User?.profilePictureThumbUrl;
      if (singleUserImgUrl) {
        imageUrl = singleUserImgUrl;
      } else {
        imageUrl = roomUser.chatRoom?.imageUrl;
      }
    }
    return imageUrl;
  };

  const handleNewMessageSend = (userId) => {
    setIsMsgSendUserIds(new Set([...isMsgSendUserIds, userId]));
  };

  const handleUpdateChatList = (data, message) => {
    let filterMessage = chatRoomUserLists
      .filter((e) => e?.chatRoom?.id === data)
      .map((item) => ({
        ...item,
        last_message: message,
        chatRoom: { ...item.chatRoom, lastCreatedAt: new Date() },
      }));
    let removeMessage = chatRoomUserLists.filter(
      (e) => e?.chatRoom?.id !== data
    );
    let newMessageLIst = [...filterMessage, ...removeMessage];
    if (filterMessage && filterMessage?.length) {
      setChatRoomUserLists(newMessageLIst);
    } else {
      setChatUserList(chatUserList + 1);
    }
  };

  useEffect(() => {
    if (location.state) {
      setSelectedFilter(location.state);
      setChatWithSPModal(true);
    }
  }, [location.state]);

  return (
    <ChatContext.Provider
      value={{
        userDetails,
        selectUser,
        isUploadItems,
        setIsUploadItems,
        isOpenCallingDrawer,
        chatWithSPModal,
        isOpenCreateGroupModal,
        isOpenChatProfileDrawer,
        isOpenAddMemberInGroupModal,
        isChatLoading,
        hasMoreFetch,
        setHasMoreFetch,
        setIsChatLoading,
        messageCount,
        setMessageCount,
        currentMessagePage,
        setCurrentMessagePage,
        fileList,
        setFileList,
        providers,
        setProviders,
        isSearch,
        setIsSearch,
        selectedFilter,
        setSelectedFilter,
        searchProviderName,
        setSearchProviderName,
        handleChangeProviderName,
        handleSearchProviders,
        handleResetProviderDetails,
        isDisabledLoadMore,
        isProviderLoading,
        handleIncrementPerPage,
        uploadItemsHandleClick,
        setIsOpenCallingDrawer,
        handleOpenChatProfileDrawer,
        handleOpenChatWithSPModal,
        handleOpenGroupModal,
        handleOpenAddMemberInGroupModal,
        setSelectUser,
        showChatHandle,
        getServiceProviderData,
        isMobileViewOpen,
        isLoadMoreLoading,
        getProfileImageUrl,
        isMsgSendUserIds,
        handleNewMessageSend,
        chatRoomUserLists,
        setChatRoomUserLists,
        handleUpdateChatList,
        chatroomDetails,
        chatUserList,
        setChatUserList,
        isLoadUpdate, 
        setIsLoadUpdate,
        setIsLoadingChat,
        isLoadingChat,
      }}
    >
      {isChatLoading && <Spin className="chatLoader" />}
      {children}
    </ChatContext.Provider>
  );
};

const mapStateToProps = (state) => ({
  userDetails: state.auth.userData,
});

export default connect(mapStateToProps)(ChatProvider);
