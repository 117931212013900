import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router';
import {
  ActionWrapper,
  CommonButton,
  PageContainerWrapper,
  TablePlus,
  Toggle,
} from '../../../components';
import useTable from '../../../hooks/useTable.hook';
import { accessRoute } from '../../../routes/OperatingProcedure';

import {
  operatingProcedureBreadcrumb,
  operatingProcedureTable,
} from '../../../services/Columns';
import {
  deleteLessonService,
  getActions,
  getLessonListService,
  updateLessonStatus,
} from '../../../services/index.service';
import { navigateWithParam } from '../../../utils';

function ListProcedureLesson() {
  const { t } = useTranslation();
  const [state, setState] = useState({
    loader: null,
    selectedRows: [],
  });
  const navigate = useNavigate();

  // For breadcrumb
  const breadcrumb = operatingProcedureBreadcrumb();

  const { loader, selectedRows } = state;

  // For get tags list
  const [tableHandler, tableProps] = useTable(getLessonListService);
  const {
    data,
    isLoading,
    count,
    page,
    noOfPages,
    sizePerPage,
    filter,
    sortBy,
    sortType,
  } = tableProps;
  const {
    getData,
    onExport,
    onFilterChange,
    onTableSort,
    onFilterResetChange,
    onToggleChange,
    onConfirmDeleteAlert,
  } = tableHandler;

  // For action with column
  function onAction(e, handler, item) {
    e.preventDefault();
    handler(item);
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options?.map((item, key) => {
          return (
            item?.addQue === 1 && (
              <li
                action={item?.action}
                onClick={(e) => onAction(e, item?.handler, item)}
                key={key}
                className="action_list"
              >
                <CommonButton>
                  <em className={item?.icon} />
                  {item?.name}
                </CommonButton>
              </li>
            )
          );
        })}
      </ActionWrapper>
    );
  };

  const columns = operatingProcedureTable({
    onSort: onTableSort,
    filter,
    onFilterChange: onFilterChange,
    page,
    sizePerPage,
    Action: (props) =>
      actionFormatter([
        {
          name: t('text.common.edit'),
          icon: 'icon ni ni-edit',
          id: props?.row?.id,
          addQue: 1,
          action: getActions('EDIT').value,
          handler: () => {
            const url = `${generatePath(accessRoute?.EDIT_LESSON?.path, {
              id: props?.row?.id,
            })}`;
            navigate(url, { state: props?.row });
          },
        },
        {
          name: t('text.operating.addQuestion'),
          icon: 'icon ni ni-plus-round',
          addQue: props.row.isRequired,
          action: getActions('CREATE').value,
          handler: () => {
            const data = {
              id: props?.row?.id,
              isActive: props?.row?.status,
              title: props?.row?.title,
            };
            navigateWithParam(data, navigate, accessRoute?.ADD_QUESTION?.path);
          },
        },
        {
          name: t('text.common.delete'),
          icon: 'icon ni ni-trash',
          action: getActions('DELETE').value,
          addQue: 1,
          handler: () => {
            onConfirmDeleteAlert({
              title: t('text.common.deleteRecordTitle'),
              id: props?.row?.id,
              text: t('text.operating.areYouSureDeleteLesson'),
              service: deleteLessonService,
              key: 'lessonIds',
              html: `${t(
                'text.operating.areYouSureDeleteLesson'
              )}<div class="mt-2 text-danger"><small> ${t(
                'text.operating.cautionMessage'
              )}</small> </div>`,
            });
            document.body.click();
          },
        },
      ]),
    Toggle: (props) => (
      <Toggle
        isDropDown={true}
        loading={loader === 'status-update'}
        defaultChecked={props?.row?.status}
        onChange={(e) =>
          onToggleChange(
            e,
            props?.row,
            '',
            updateLessonStatus,
            'lessonIds',
            true
          )
        }
        manageService={
          props?.row?.isRequired === 0 ||
          props?.row?.LessonQuestions?.map((item) => item?.status).filter(
            (e) => e === 'active'
          )?.length
        }
      />
    ),
  });

  // For bulk action
  function onRowSelect(e) {
    try {
      setState({ ...state, selectedRows: [...e] });
    } catch (err) {
      console.log('err', err);
    }
  }
  // For bulk action
  async function handleActionSelect(e) {
    try {
      if (e.target.value === 'delete') {
        await onConfirmDeleteAlert({
          title: t('text.common.deleteRecordTitle'),
          id: selectedRows,
          text: t('text.operating.areYouSureDeleteLesson'),
          service: deleteLessonService,
          key: 'lessonIds',
          html: `${t(
            'text.operating.areYouSureDeleteLesson'
          )}<div class="mt-2 text-danger"><small> ${t(
            'text.operating.cautionMessage'
          )}</small> </div>`,
        });
        getData();
      } else {
        await onToggleChange(
          e.target.value,
          selectedRows,
          'action',
          updateLessonStatus,
          'lessonIds'
        );
        getData();
      }
    } catch (err) {
      console.log(err);
    }
  }

  // For header Button
  const headerBtn = {
    link: {
      name: t('text.operating.addLesson'),
      url: accessRoute.ADD_LESSON.path,
    },
    csvExport: { handler: () => onExport('', columns) },
  };
  return (
    <>
      <PageContainerWrapper
        heading={t('text.operating.title')}
        breadcrumb={breadcrumb}
        btn={headerBtn}
      >
        <TablePlus
          hasLimit
          noOfPage={noOfPages}
          sizePerPage={sizePerPage}
          page={page}
          handleSelectedRows={onRowSelect}
          handleActionSelect={handleActionSelect}
          count={count}
          tableData={data}
          tableColumns={columns}
          selectRow={true}
          onFilterResetChange={onFilterResetChange}
          tableLoader={isLoading}
          filter={filter}
          sortBy={sortBy}
          sortType={sortType}
        />
      </PageContainerWrapper>
    </>
  );
}

export default ListProcedureLesson;
