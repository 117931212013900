import { APIRequest } from '../../helpers/index.helper';
import {
  ADVERTISEMENT_ENDPOINT
} from '../../apiEndpoints/index.endpoint';
import { logger } from '../../utils';

export const TexableAdsListService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...ADVERTISEMENT_ENDPOINT?.getTexableAdsList,
      queryParams,
    };

    const res = await APIRequest(payload);

    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const AddTexableAdsService = async (body) => {
  try {
    const payload = {
      ...ADVERTISEMENT_ENDPOINT?.addTexableAds,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    if (res?.data?.rows) {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const DeleteTexableAdsService = async ({ bodyData }) => {
  try {
    const payload = {
      ...ADVERTISEMENT_ENDPOINT?.deleteTexableAds(bodyData?.id),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const UpdateTexableAdsStatusService = async ({ status, id }) => {
  try {
    const payload = {
      ...ADVERTISEMENT_ENDPOINT?.updateTexableAdsStatus(id),
      bodyData: { status }
    };

    const res = await APIRequest(payload);

    if (res?.data?.rows) {
      return res?.data?.rows;
    }

    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getTextualAdsByIdService = async ({ id }) => {
  try {
    const payload = {
      ...ADVERTISEMENT_ENDPOINT?.getTextualAdsById(id),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const updateTextualAdsService = async ({ body }) => {
  try {
    const payload = {
      ...ADVERTISEMENT_ENDPOINT?.updateTextualAdsById,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const AddInterstitialAdsService = async (body) => {
  try {
    const payload = {
      ...ADVERTISEMENT_ENDPOINT?.addInterstitialAds,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const updateInterstitialAdsService = async ({ body }) => {
  try {
    const payload = {
      ...ADVERTISEMENT_ENDPOINT?.updateInterstitialAds,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getInterstitialAdsByIdService = async ({ id }) => {
  try {
    const payload = {
      ...ADVERTISEMENT_ENDPOINT?.getInterstitialAdsById(id),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const DeleteInterstitialAdsService = async ({ bodyData }) => {
  try {
    const payload = {
      ...ADVERTISEMENT_ENDPOINT?.deleteInterstitialAds(bodyData?.id),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const UpdateInterstitialAdsStatusService = async ({ status, id }) => {
  try {
    const payload = {
      ...ADVERTISEMENT_ENDPOINT?.updateInterstitialAdsStatus(id),
      bodyData: { status }
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows) {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const InterstitialAdsListService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...ADVERTISEMENT_ENDPOINT?.getInterstitialAdsList,
      queryParams,
    };

    const res = await APIRequest(payload);

    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }

    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};