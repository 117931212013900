import React, { useState } from 'react';
import CreditCardForm from '../../../../../../components/Forms/Report/Payment/creditCard.from';
import { modalNotification } from '../../../../../../utils';
import { creditCardPaymentService } from '../../../../../../services/index.service';
import { purchaseMemberShipPlan } from '../../../../../../services/Customers/customers.service';

export default function CreditCard({
  rowData,
  setIsOpenModal,
  getData,
  authorizedUser,
  paymentDetails,
  memberShip,
  isAlreadyActivate,
}) {
  const [formData] = useState();
  const [loading, setLoading] = useState(false);
  const monthsData = [
    {
      name: '01',
      value: '1',
    },
    {
      name: '02',
      value: '2',
    },
    {
      name: '03',
      value: '3',
    },
    {
      name: '04',
      value: '4',
    },
    {
      name: '05',
      value: '5',
    },
    {
      name: '07',
      value: '7',
    },
    {
      name: '08',
      value: '8',
    },
    {
      name: '09',
      value: '9',
    },
    {
      name: '10',
      value: '0',
    },
    {
      name: '11',
      value: '11',
    },
    {
      name: '12',
      value: '12',
    },
  ];

  const yearsData = [
    {
      name: new Date().getFullYear(),
      value: `${new Date().getFullYear()}`,
    },
    {
      name: new Date().getFullYear() + 1,
      value: `${new Date().getFullYear() + 1}`,
    },
    {
      name: new Date().getFullYear() + 2,
      value: `${new Date().getFullYear() + 2}`,
    },
    {
      name: new Date().getFullYear() + 3,
      value: `${new Date().getFullYear() + 3}`,
    },
    {
      name: new Date().getFullYear() + 4,
      value: `${new Date().getFullYear() + 4}`,
    },
  ];
  const onSubmit = async (value) => {
    setLoading(true);
    try {
      let bodyData = {
        ...value,
        bookingId: rowData?.BookingDetail?.bookingId,
        reason: paymentDetails?.reason,
      };
      let memberShipBodyData = {
        ...(isAlreadyActivate ? { isUpgradePlan: 1 } : ''),
        customerId: rowData?.customerId,
        membershipPlanId: rowData?.memberShipPlanId,
        durationType: rowData?.planType,
        paymentMethod: 'card',
        cardDetail: {
          cardHolderName: value?.cardHolderName,
          cardNumber: value?.cardNumber,
          expiryMonth: value?.expiryMonth,
          expiryYear: value?.expiryYear,
          cvv: value?.cvc,
        },
      };
      const response = memberShip
        ? await purchaseMemberShipPlan({ memberShipBodyData })
        : await creditCardPaymentService({ bodyData });
      if (response?.success) {
        modalNotification({
          type: 'success',
          message: response?.message,
        });
        setIsOpenModal(false);
        getData();
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  return (
    <CreditCardForm
      onSubmit={onSubmit}
      formData={formData}
      yearsData={yearsData}
      monthsData={monthsData}
      rowData={rowData}
      loading={loading}
      authorizedUser={authorizedUser}
      paymentDetails={paymentDetails}
    />
  );
}
