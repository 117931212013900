export const validation = {
  firstName: 'First name is required',
  lastName: 'Last name is required',
  phoneNumber: 'Phone number is required.',
  minFirstName: 'First name must be minimum 3 characters',
  minLastName: 'First name must be minimum 3 characters',
  maxFirstName: 'First name must be maximum 15 characters',
  maxLastName: 'First name must be maximum 15 characters',
  firstNameValid: 'First name should be in the alphabets ',
  lastNameValid: 'Last name should be in the alphabets',
  // minPhoneNo: 'Phone number must be at least 10 digits',
  minPhoneNo: 'Please enter valid phone number',
  strongPassword:
    'Password should be between 6 and 15 characters with one upper case letter, one number, and one special character(@#$%^&+!=).',
  matchPassword: 'Password & Confirm password must be same',
  confirmPassword: 'Confirm password is required',
  companyName: 'Company name is required.',
  companyLogo: 'Company logo is required.',
  companyAddress: 'Company address is required.',
  officeStaff: 'Number of office staff is required.',
  techniciansInstallers: 'Number of field technicians/installers is required.',
  areaServed: 'Area served is required.',
  role: 'Role is required.',
  duration: 'Pay duration is required.',
  cardHolder: 'Card holder is required.',
  cardNumber: 'Card number is required.',
  expMonth: 'Expire month is required.',
  expYear: 'Expire year is required.',
  cvv: 'CVV is required.',
  enterCharacters: 'Please enter at least 14 characters.',
  enterCvvCharacters: 'Please enter at least 3 characters.',
  enterValidNumber: 'Please enter a valid number.',
  businessType: 'Business type is required.',
  jobTitle: 'Job title is required.',
  homeAddress: 'Address is required.',
  addressLine1: 'Address line 1 is required.',
  addressLine2: 'Address line 2 is required.',
  state: 'State is required.',
  city: 'City is required.',
  zipCode: 'Zip code is required.',
  socialSecurityNumber: 'Social security number is required.',
  dob: 'Date of birth is required.',
  identityFront: 'Upload identity document front is required.',
  identityBack: 'Upload identity document back is required.',
  company: 'Company is required.',
  employerIdNumber: 'Employer identification number is required.',
  enterValidIdNumber: 'Please enter no more than 9 digits. ',
  doingBusiness: 'Doing business as is required.',
  industry: 'Industry is required.',
  addPerson: "Please select person's relationship to the account.",
  bankName: 'Bank name is required.',
  accountHolder: 'Bank account holder name is required.',
  accountNumber: 'Account number is required.',
  routingNumber: 'Bank account routing number is required.',
  bankLocation: 'Bank location is required.',
};
