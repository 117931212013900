import React from 'react';
import { useParams } from 'react-router-dom';
import CreateServiceCategory from '../../../PriceManagement/ManageService/ServiceCategory/Create';

function AddNCACategory() {
  const { id } = useParams();
  return <CreateServiceCategory userId={id} ncaEdit={true} />;
}

export default AddNCACategory;
