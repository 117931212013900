export const text = {
  infoDescription: 'Information Description',
  infoUrl: 'Information Url',
  infoHeading: 'Important Information',
  tipsHeading: 'Tips',
  tipTitleOne: 'Tip1 Title',
  tipTitleTwo: 'Tip2 Title',
  tipTitleThree: 'Tip3 Title',
  tipUrlOne: 'Tip1 Url',
  tipUrlTwo: 'Tip2 Url',
  tipUrlThree: 'Tip3 Url',
  tipDescriptionOne: 'Tip1 Description',
  tipDescriptionTwo: 'Tip2 Description',
  tipDescriptionThree: 'Tip3 Description',
  informationUrlPlaceholder: 'Enter information Url',
  infoDescriptionPlaceholder: 'Enter information description',
  tipDescriptionPlaceholder: 'Enter tip description',
  tipTitlePlaceholder: 'Enter tip title',
  tipUrlPlaceholder: 'Enter tip Url',
  financingInfoHeading: 'Financing Information',
  finDescriptionPlaceholder: 'Enter financing description',
  financeBankOne: 'Financial Bank 1',
  financeBankTwo: 'Financial Bank 2',
  financeBankUrlOne: 'Financial Bank 1 Url',
  financeBankUrlTwo: 'Financial Bank 2 Url',
  finBankUrlPlaceholder: 'Financing Bank1 Url',
  finBankPlaceholder: 'Financing Bank1',
  quoteHeading: 'Quote Information',
  quoteInfoPlaceholder: 'Enter quote information',
  finBankUrlPlaceholder2: 'Financing Bank2 Url',
  finBankPlaceholder2: 'Financing Bank2',
};
