export const validation = {
  emailMobile: 'Email/Mobile number is required',
  name: 'Your name or company name is required',
  captch: 'Captcha  is required',
  invalidCaptcha: 'Please enter valid code',
  currentPassword: 'Current password is required',
  newPassword: 'New password is required',
  confirmPassword: 'Confirm password is required',
  matchPassword: 'New password & Confirm password must be same',
  bankAccountRoutingNumber: 'BIC/SWIFT Code is required',
  bankAccountHolderName: 'Bank account holder name is required',
  message: 'Message is required',
  uploadNewDocument: 'Please Upload New Document',
  notUploadDocument: 'Please Upload Document',
};
