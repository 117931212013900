import React, { useEffect, useState } from 'react';
import {
  ActionWrapper,
  CommonButton,
  PageContainerWrapper,
  TablePlus,
  Toggle,
} from '../../../../components';
import {
  Columns,
  deleteProductService,
  discardImportProductService,
  downloadDemoProductFileService,
  getActions,
  getCategoryService,
  getProductListService,
  importFileProductListService,
  importProductService,
  saveImportProductService,
  updateProductStatusService,
} from '../../../../services/index.service';
import { useTranslation } from 'react-i18next';
import { accessRoute } from '../../../../routes/ShoppingCart';
import { Link, generatePath, useSearchParams } from 'react-router-dom';
import { getApiData, useTable } from '../../../../hooks/index.hook';
import { accessRoute as accessRouteShopping } from '../../../../routes/ShoppingCart';
import ImportRecordTable from '../../../../features/importRecordTable/index.feature';
import {
  importFileAPIData,
  importFileData,
  selectImportAPIData,
  selectImportData,
} from '../../../../redux/index.redux';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import useSubmit from '../../../../hooks/useSubmit.hook';
import ProductBulkUpload from '../ProductBulkUpload';

export default function ProductList() {
  const [loader, setLoader] = useState();
  let extraQuery = {
    scope: 'all',
  };
  const [importData, setImportData] = useState({});
  const [compareFileData, setCompareFileData] = useState([]);
  const dispatch = useDispatch();
  const importReduxData = useSelector(selectImportData);
  const importReduxAPIData = useSelector(selectImportAPIData);
  const [uploadFilter, setUploadFilter] = useState(false);
  const [imageArray, setImageArray] = useState([]);
  const [fileState, setFileState] = useState({
    bulkUploadImage: null,
  });
  const uploadFilterShow = () => {
    setUploadFilter(true);
  };
  const uploadFilterHide = () => {
    setUploadFilter(false);
    setImageArray([]);
    setFileState({ bulkUploadImage: null });
  };
  const [saveLoader, setSaveLoader] = useState(false);
  const [discardLoader, setDiscardLoader] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const extraCSVHandle = async (getData) => {
    let categoryId = {};
    let categoryNameObj = {};
    let imageObj = {};
    let imageArr = [];
    let imageArr1 = [];
    let imageObj1 = {};
    let imageArr2 = [];
    let imageObj2 = {};
    let discriptionObj = {};
    let productSkuObj = {};
    let categoryArray = [];
    let categoryNameArray = [];
    let categoryAllIds = [];
    let categoryAllNames = [];
    let discriptionArray = [];
    let productSkuObjArray = [];

    const res = await getCategoryService({
      queryParams: { scope: 'activeProductCategory' },
    });

    if (res?.success) {
      categoryAllIds = res?.data?.rows?.map((item) => {
        return {
          name: item?.id,
        };
      });
      categoryAllNames = res?.data?.rows?.map((item) => {
        return {
          name: item?.categoryName,
        };
      });
    }

    getData?.rows?.length &&
      getData?.rows?.map((item) => {
        imageObj = {
          name:
            item?.productImageDetail?.filter((item) => {
              return item?.imageNo === 1;
            })?.[0]?.productImage || '',
        };
        imageObj1 = {
          name:
            item?.productImageDetail?.filter((item) => {
              return item?.imageNo === 2;
            })?.[0]?.productImage || '',
        };
        imageObj2 = {
          name:
            item?.productImageDetail?.filter((item) => {
              return item?.imageNo === 3;
            })?.[0]?.productImage || '',
        };
        discriptionObj = {
          name: item?.description,
        };
        productSkuObj = {
          name: item?.productSku,
        };
        let arrTest = item?.categoryDetails?.map((categoryIds) => {
          return categoryIds?.ProductCategoryDetails?.id;
        });
        let categoryName = item?.categoryDetails?.map((categoryIds) => {
          return categoryIds.ProductCategoryDetails?.categoryName;
        });
        categoryId = {
          name: arrTest.join(','),
        };
        categoryNameObj = {
          name: categoryName.join(','),
        };
        imageArr.push(imageObj);
        imageArr1.push(imageObj1);
        imageArr2.push(imageObj2);
        categoryArray.push(categoryId);
        categoryNameArray.push(categoryNameObj);
        discriptionArray.push(discriptionObj);
        productSkuObjArray.push(productSkuObj);
      });

    return {
      'Category Id': categoryArray,
      'Category Name': categoryNameArray,
      Description: discriptionArray,
      'Product Sku': productSkuObjArray,
      'Image 1': imageArr,
      'Image 2': imageArr1,
      'Image 3': imageArr2,
      'All Category Ids': categoryAllIds,
      'All Category Name': categoryAllNames,
    };
  };
  const [tableHandler, tableProps] = useTable(
    getProductListService,
    extraQuery,
    false,
    false,
    false,
    extraCSVHandle
  );
  const {
    data,
    isLoading,
    count,
    page,
    noOfPages,
    sizePerPage,
    filter,
    sortBy,
    sortType,
  } = tableProps;
  const {
    onRefresh,
    onFilterChange,
    onExport,
    onFilterResetChange,
    onTableSort,
    onToggleChange,
    onConfirmDeleteAlert,
  } = tableHandler;
  const breadcrumb = Columns.ProductManagementBreadcrumb();
  const { t } = useTranslation();

  function onAction(e, handler) {
    e.preventDefault();
    if (handler) handler();
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options?.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item?.handler)}
              key={key}
              className="action_list"
              action={item?.action}
            >
              <Link
                to={
                  item?.id &&
                  generatePath(accessRouteShopping?.PRODUCTS_EDIT?.path, {
                    id: item?.id,
                  })
                }
                // onClick={(e) => e.preventDefault()}
                
              >
                <em className={item?.icon} /> {item?.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };

  const handleAddImport = (recordData) => {
    try {
      if (recordData?.path) {
        getApiData({
          service: importFileProductListService,
          setData: setCompareFileData,
          queryParams: {
            file: recordData?.path,
          },
          dispatch,
          setRedux: importFileAPIData,
        });
        dispatch(importFileData({ ...recordData }));
        setImportData(recordData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [onSubmit] = useSubmit({
    service: discardImportProductService,
  });

  const handleDiscard = async () => {
    try {
      const payload = { file: importData?.path };
      let res = await onSubmit(payload);

      if (res) {
        dispatch(importFileData({}));
        dispatch(importFileAPIData({}));
        setImportData({});
        setCompareFileData([]);
        onRefresh();
        searchParams.set('pageSize', 10);
        setSearchParams(searchParams);
      }
    } catch (err) {
      console.log(err);
    }
    setDiscardLoader(false);
  };

  const [onSaveSubmit] = useSubmit({
    service: saveImportProductService,
  });

  const columns = Columns.productsManagementTableColumn({
    onSort: onTableSort,
    onFilterChange: onFilterChange,
    filter,
    page,
    sizePerPage,
    Action: (
      props
      // row
    ) =>
      actionFormatter([
        {
          name: t('text.common.edit'),
          icon: 'icon ni ni-edit',
          id: props?.row?.id,
          action: getActions('EDIT').value,
        },
        {
          name: t('text.common.delete'),
          icon: 'icon ni ni-trash',
          action: getActions('DELETE').value,
          handler: () => {
            onConfirmDeleteAlert({
              id: props.row.id,
              text: t('text.shoppingCart.areYouSureDeleteProduct'),
              service: deleteProductService,
              key: 'productId',
            });
            document.body.click();
          },
        },
      ]),
    Toggle: (props) => (
      <Toggle
        isProductDropdown={true}
        // shouldDeleteInclude={true}
        loading={loader === 'status-update'}
        defaultChecked={props.row.status}
        onChange={(e) =>
          onToggleChange(
            e,
            props?.row,
            '',
            updateProductStatusService,
            'productId',
            true
          )
        }
        onRefresh={onRefresh}
      />
    ),
  });

  const handleSave = async () => {
    setSaveLoader(true);
    try {
      const payload = { file: importData?.path };
      let res = await onSaveSubmit(payload);
      if (res) {
        dispatch(importFileData({}));
        dispatch(importFileAPIData({}));
        setImportData({});
        setCompareFileData([]);
        onRefresh();
        searchParams.set('pageSize', 10);
        setSearchParams(searchParams);
      }
    } catch (err) {
      console.log(err);
    }
    setSaveLoader(false);
  };

  async function downloadDemoFile() {
    try {
      const response = await downloadDemoProductFileService();
      window.open(response?.data?.productSampleFileUrl);
    } catch (err) {
      console.log(err);
    }
  }
  const headerBtn = {
    multipleButton: [
      {
        title: t('text.shoppingCart.downloadSample'),
        handler: () => {
          downloadDemoFile();
        },
      },
    ],
    link: {
      name: t('text.common.addProduct'),
      url: accessRoute.PRODUCTS_ADD.path,
    },
    csvExport: {
      handler: () => onExport('', columns),
    },
    csvImport: {
      service: importProductService,
      basePath: 'file',
      handler: handleAddImport,
    },
    dropdownProductBtn: {},
  };
  const dropDownOptions = [
    {
      handler: () => uploadFilterShow(),
      title: 'Upload Image',
    },
    {
      path: accessRoute.BULK_UPLOAD_IMAGE_VIEW.path,
      title: 'View Image',
    },
  ];

  useEffect(() => {
    try {
      if (Object.keys(importReduxData).length) {
        setImportData(importReduxData);
      }
    } catch (err) {
      console.log(err);
    }
  }, [importReduxData]);
  useEffect(() => {
    try {
      if (Object.keys(importReduxAPIData).length) {
        setCompareFileData(importReduxAPIData);
      }
    } catch (err) {
      console.log(err);
    }
  }, [importReduxAPIData]);

  return (
    <>
      <PageContainerWrapper
        heading={t('text.common.productManagement')}
        breadcrumb={breadcrumb}
        btn={headerBtn}
        dropDownBtnTxt="Upload Images"
        dropDownOptions={dropDownOptions}
      >
        {importData?.data && importData?.data?.length ? (
          <ImportRecordTable
            tableProps={compareFileData}
            loading={isLoading}
            tableColumns={columns}
            recordData={importData}
            handleDiscard={handleDiscard}
            handleSave={handleSave}
            onSaveLoader={saveLoader}
            onDiscardLoader={discardLoader}
          />
        ) : (
          <TablePlus
            hasLimit
            noOfPage={noOfPages}
            sizePerPage={sizePerPage}
            page={page}
            count={count}
            tableData={data}
            tableColumns={columns}
            selectRow
            showAction={false}
            statusAction={false}
            isSearch={true}
            tableLoader={isLoading}
            onFilterResetChange={onFilterResetChange}
            filter={filter}
            sortBy={sortBy}
            sortType={sortType}
            setSizePerPage=""
          />
        )}
        <ProductBulkUpload
          uploadFilter={uploadFilter}
          uploadFilterHide={uploadFilterHide}
          imageArray={imageArray}
          setImageArray={setImageArray}
          fileState={fileState}
          setFileState={setFileState}
        />
      </PageContainerWrapper>
    </>
  );
}
