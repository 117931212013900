import React, { useState, useEffect } from 'react';
import { Checkbox, Radio } from 'antd';
import { Col, Container, FormGroup, Row } from 'react-bootstrap';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Breadcrumb, GlobalLoader, ImageElement } from '../../../../components';
import {
  createNewCard,
  getAllUserCard,
  postCustomerPayNow,
} from '../../../../services/CustomerApp/index.service';
import { accessRoute as profileURL } from '../../../../routes/CustomerApp/home';
import { decodeQueryData, logger, modalNotification } from '../../../../utils';
import { accessRoute as customerPaymentRoute } from '../../../../routes/CustomerApp/payment';

import PaymentForm from '../../../../components/Forms/CustomerApp/payment.form';
import { firstLetterCaps } from '../../../../utils/text.util';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { checkValidPrice } from '../../../../components/UIElements/Formatter';
import { purchaseMemberShipPlan } from '../../../../services/Customers/customers.service';
const breadcrumb = [
  {
    path: '/dashboard',
    name: i18next.t('text.common.home'),
  },
  {
    path: '#',
    name: i18next.t('text.common.payment'),
  },
];

const loadingTypes = {
  GET_LIST: 'GET_LIST',
  ADD_NEW_CARD: 'ADD_NEW_CARD',
  PAY_NOW_SUBMIT: 'PAY_NOW_SUBMIT',
};

function PayNowPayment() {
  const navigate = useNavigate();
  const location = useLocation();
  // const query = new URLSearchParams(useLocation()?.search).toString();
  const query = location?.search && decodeQueryData(location?.search);

  const paymentState = JSON.parse(localStorage.getItem('paymentState'));
  const memberState = JSON.parse(localStorage.getItem('memberShipState'));
  const params = useParams();
  const { t } = useTranslation();
  const [state, setState] = useState({
    isLoading: loadingTypes.GET_LIST,
    cardLists: [],
    isCardSelect: false,
    selectedCard: null,
    isAddNewCard: false,
  });
  const { id } = useParams();
  const { isMember } = query;

  const getCardList = async () => {
    try {
      let queryParams = {};
      const { data } = await getAllUserCard({ queryParams });
      setState({
        ...state,
        cardLists: data?.rows,
        isLoading: '',
      });
    } catch (error) {
      logger(error);
      setState({
        isLoading: '',
      });
    }
  };
  const handlePayNow = async (bodyData, resetForm, paymentInitialValues) => {
    if (
      !state?.selectedCard &&
      state?.cardLists?.length &&
      state?.selectedCard !== 'new_card'
    ) {
      modalNotification({ type: 'error', message: 'Please select card' });
      return;
    }

    try {
      setState({
        ...state,
        isLoading: !state?.cardLists?.length
          ? loadingTypes?.ADD_NEW_CARD
          : loadingTypes?.PAY_NOW_SUBMIT,
      });
      let memberShipBodyData = {
        ...(memberState?.isAlreadyActivate ? { isUpgradePlan: 1 } : ''),
        customerId: id,
        membershipPlanId: memberState?.memberShipId,
        durationType: memberState?.planType,
        cardId: state?.cardLists[state?.selectedCard - 1]?.id,
        paymentMethod: 'card',
      };
      const response = await (!state.cardLists?.length ||
      state.selectedCard === 'new_card'
        ? createNewCard({ bodyData })
        : !memberState?.isMemberShip
        ? postCustomerPayNow({
            bookingId: location?.state?.bookingId || params?.id,
            cardId: state?.cardLists[state?.selectedCard - 1]?.id,
          })
        : purchaseMemberShipPlan({ memberShipBodyData }));

      if (response?.success) {
        if (!state?.cardLists?.length || state?.selectedCard === 'new_card') {
          modalNotification({
            type: 'success',
            message: response?.message,
          });
          getCardList();
          resetForm(paymentInitialValues());
        } else if (response?.success) {
          if (paymentState) {
            navigate(
              generatePath(customerPaymentRoute.CUSTOMER_PAYMENT.path, {
                id: location?.state?.bookingId || params?.id,
              }),
              {
                state: location?.state?.bookingId || params?.id,
              }
            );
            localStorage.removeItem('paymentState');
          }

          if (memberState) {
            navigate(profileURL.PROFILE.path);
            localStorage.removeItem('memberShipState');
          }
        }
      }
      setState({
        ...state,
        isLoading: '',
      });
    } catch (error) {
      setState({
        ...state,
        isLoading: '',
      });
    }
  };

  const saveCardContent = () => {
    return (
      <Col sm={12} className="mt-2">
        <FormGroup className="form-group">
          <Checkbox>{t('text.common.saveCard')}</Checkbox>
        </FormGroup>
      </Col>
    );
  };

  const handleChangeRadio = (value) => {
    setState({
      ...state,
      selectedCard: value,
    });
  };

  useEffect(() => {
    getCardList();
    if (!memberState && isMember) {
      navigate('/customer/profile');
    }
    return () => {
      localStorage.removeItem('memberShipState');
    };
  }, []);

  return (
    <>
      <div className="main-content paymentPage">
        {/* page content */}
        <div className="pageContent">
          <div className="pageContent_wide">
            <Container className="container-lg">
              <Breadcrumb
                title={t('text.customerPayment.title')}
                type="customer"
                breadcrumb={breadcrumb}
              />
              {/* Payment page content */}
              <div className="br-10 p-30 bg-white">
                <div className="paymentPage_head d-flex align-items-center justify-content-between flex-wrap">
                  <div>
                    <h2 className="text-850 font-sm paymentPage_head_title mb-0">
                      {t('text.customerPayment.savedCard')}
                    </h2>
                    <span>{t('text.customerPayment.selectYourCard')}</span>
                  </div>
                  <div>
                    <p className="font-md paymentPage_amount mb-0">
                      {t('text.customerPayment.paymentAmount')}
                    </p>
                    <h3 className="paymentPage_price font-sm text-500 mb-0">
                      {!memberState?.isMemberShip
                        ? checkValidPrice(
                            location.state?.totalAmount ||
                              paymentState?.totalAmount
                          )
                        : checkValidPrice(memberState?.adminAttentionAmount)}
                    </h3>
                  </div>
                </div>

                <Radio.Group name="radiogroup" defaultValue={1}>
                  <div className="paymentPage_card form-group">
                    {state?.isLoading !== loadingTypes?.GET_LIST &&
                    state?.cardLists?.length > 0 ? (
                      state?.cardLists.map((cardItem, idx) => (
                        <div key={idx} className="d-flex w-full mb-3">
                          <div className="w-100">
                            <div className="d-flex align-items-center justify-content-between">
                              <Radio.Group
                                value={state.selectedCard}
                                onChange={() => handleChangeRadio(idx + 1)}
                                className="form-group mb-0 me-xxl-3 me-sm-2 me-1 "
                              >
                                <Radio value={idx + 1} />
                              </Radio.Group>
                              <div className="flex-grow-1 font-md">
                                {firstLetterCaps(cardItem?.name)}
                                <span className="paymentPage_card_no d-block  font-md">
                                  **** **** **** {cardItem?.lastFour}
                                </span>{' '}
                              </div>
                              <ImageElement
                                className="img-fluid paymentPage_card_logo"
                                previewSource={cardItem?.cardIconUrl}
                                alt="logo"
                              />
                            </div>
                          </div>
                        </div>
                      ))
                    ) : state?.isLoading !== loadingTypes?.GET_LIST &&
                      !state?.cardLists?.length ? (
                      <p className="text-center">No card available</p>
                    ) : (
                      <GlobalLoader />
                    )}
                  </div>

                  <div className="paymentPage_card form-group">
                    <div className="d-flex">
                      <div className="w-100">
                        <div className="d-flex">
                          <Radio.Group
                            value={state.selectedCard}
                            onChange={() => handleChangeRadio('new_card')}
                            className="form-group mb-0 me-xxl-3 me-sm-2 me-1 "
                          >
                            <Radio value="new_card" />
                          </Radio.Group>
                          <div className="flex-grow-1 font-md">Add Card</div>
                        </div>
                        <Row>
                          <PaymentForm
                            saveCardContent={saveCardContent}
                            onSubmit={handlePayNow}
                            buttonText={
                              state?.selectedCard === 'new_card' ||
                              !state?.cardLists?.length
                                ? 'Add Card '
                                : 'Pay Now'
                            }
                            isAddNewCard={
                              state?.selectedCard === 'new_card' ||
                              !state?.cardLists?.length
                            }
                          />
                        </Row>
                      </div>
                    </div>
                  </div>
                </Radio.Group>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}

export default PayNowPayment;
