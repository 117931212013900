import React, { useState, useEffect } from 'react';
import {
  PageContainerWrapper,
  TablePlus,
  FromDateToDateFilter,
} from '../../../components';
import { Columns, Store } from '../../../services/index.service';
import { useTranslation } from 'react-i18next';
import useTable from '../../../hooks/useTable.hook';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Spin } from 'antd';
import Table from 'react-bootstrap/Table';

import { accessRoute as dashboardRoute } from '../../../routes/Dashboard/dashboard';
import { checkValidPrice } from '../../../components/UIElements/Formatter';

const filterState = {
  fromDate: '',
  toDate: '',
  bookingNo: '',
  providerUserId: '',
  customerUserId: '',
};

function PromoCodeJobs() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const breadcrumb = Columns.storeBreadcrumb();
  const extraQuery = {
    storeId: params?.storeId,
  };

  const [tableHandler, tableProps] = useTable(
    Store.getStorePaymentReportListService,
    extraQuery
  );
  const {
    data,
    isLoading,
    count,
    page,
    noOfPages,
    sizePerPage,
    filter,
    sortBy,
    sortType,
  } = tableProps;
  const { onFilterChange, onTableSort, onFilterResetChange } = tableHandler;
  const [state, setState] = useState({
    isLoading: false,
    globalFilter: false,
    filterValue: filterState,
    reportSumDetails: {},
  });

  const columns = Columns.promoCodeJobsTableColumn({
    onSort: onTableSort,
    onFilterChange: onFilterChange,
    filter,
    page,
    sizePerPage,
  });

  const headerBtn = {
    add: {
      name: (
        <>
          <em className="icon ni ni-filter-fill me-1" /> Filter
        </>
      ),
      handler: () => {
        handleStateChange('globalFilter', true);
      },
    },
  };

  const handleStateChange = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      ...(typeof key === 'string' ? { [key]: value } : key),
    }));
  };

  const onSubmitFilter = (value) => {
    handleStateChange('filterValue', value);
    const queryParams = {
      startDate: value?.fromDate,
      endDate: value?.toDate,
      bookingNo: value?.bookingNo,
      providerUserId: value?.provider,
      customerUserId: value?.customer,
    };
    getReportSumDetails(queryParams);
    onFilterChange(queryParams);
  };

  function onReset() {
    handleStateChange('filterValue', filterState);
    onFilterResetChange();
  }

  const getReportSumDetails = async (searchQueryParams = {}) => {
    try {
      handleStateChange('isLoading', true);
      const queryParams = {
        storeId: params?.storeId,
        ...searchQueryParams,
      };
      const response = await Store.getStorePaymentReportSumService({
        queryParams,
      });
      if (response?.success) {
        handleStateChange({
          isLoading: false,
          reportSumDetails: response?.data ?? {},
        });
      } else {
        handleStateChange('isLoading', false);
      }
    } catch (error) {
      handleStateChange('isLoading', false);
    }
  };

  useEffect(() => {
    if (!params?.storeId) {
      navigate(dashboardRoute.DASHBOARD.path);
    } else {
      getReportSumDetails();
    }
  }, []);

  return (
    <>
      <PageContainerWrapper
        heading={t('text.marketing.promoCodeJobsHeading')}
        breadcrumb={breadcrumb}
        extraBtnClass="btn btn-outline-light bg-white"
        filter={filter}
        btn={headerBtn}
        extraClass="btn btn-primary d-block d-xl-none"
      >
        <div className="">
          <div className="globalFilters">
            <div className="card ">
              <div className="card-inner">
                <div
                  className={`globalFilters_inner ${
                    state.globalFilter ? 'filterToggle' : ''
                  }`}
                >
                  <div className="mb-3 d-block d-xl-none">
                    <Link
                      to="#"
                      onClick={() => handleStateChange('globalFilter', false)}
                    >
                      <em className="icon ni ni-arrow-long-left me-1"></em>
                      {t('text.common.back')}
                    </Link>
                  </div>
                  <FromDateToDateFilter
                    onSubmit={onSubmitFilter}
                    onReset={onReset}
                    isEnabledFields={['provider', 'customer']}
                    extra
                    filterValue={state.filterValue}
                    extraFields={[
                      {
                        type: 'input',
                        name: 'bookingNo',
                        placeholder: t('text.payOnlineGuest.jobNumber'),
                      },
                    ]}
                    propsInitialValues={() => filterState}
                  />
                </div>
                <div
                  className={` ${state.globalFilter ? 'toggle-overlay' : ''} `}
                  onClick={() => handleStateChange('globalFilter', false)}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <TablePlus
          hasLimit
          noOfPage={noOfPages}
          sizePerPage={sizePerPage}
          page={page}
          count={count}
          tableData={data}
          tableColumns={columns}
          tableLoader={isLoading}
          onFilterResetChange={onFilterResetChange}
          filter={filter}
          sortBy={sortBy}
          sortType={sortType}
        />

        {state.isLoading ? (
          <div className="text-center">
            <Spin />
          </div>
        ) : (
          <>
            <div className="card">
              <div className="card-inner">
                <Table bordered>
                  <tbody>
                    <tr>
                      <td colSpan={4}>
                        {t('text.marketing.earnFromCustomer')}
                      </td>
                      <td>
                        {checkValidPrice(
                          state.reportSumDetails?.customerAmount
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={4}>
                        {t('text.marketing.totalStoreAmount')}
                      </td>
                      <td>
                        {checkValidPrice(state.reportSumDetails?.storeAmount)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={4}>
                        {t('text.marketing.totalTransferAmount')}
                      </td>
                      <td>
                        {checkValidPrice(
                          state.reportSumDetails?.transferAmount
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={4}>
                        {t('text.marketing.totalPendingAmount')}
                      </td>
                      <td>
                        {checkValidPrice(state.reportSumDetails?.pendingAmount)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </>
        )}
      </PageContainerWrapper>
    </>
  );
}

export default PromoCodeJobs;
