import i18next from 'i18next';
import { accessRoute } from '../../routes/Dashboard';
import { actionEditDelete, actionFormatter } from '../../utils';

export const editHomepageTableColumn = ({ actionHandler, url }) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
    },
    {
      dataIndex: 'value',
      title: i18next.t('text.common.title'),
    },
    {
      dataIndex: 'id',
      title: i18next.t('text.common.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) =>
        actionFormatter({
          actionEditDelete: actionEditDelete(actionHandler),
          link: true,
          url,
          btnArray: ['Edit'],
          editId: false,
        }),
    },
  ];
};

export const homePageBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.common.homePage'),
    },
  ];
};

export const editHomePageBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.common.editHomePage'),
    },
  ];
};
