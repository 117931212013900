import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  CommonButton,
  EditBusinessDetailsForm,
  GlobalLoader,
  ImageElement,
  ModalComponent,
} from '../../../../../components';
import {
  businessDetailsService,
  editNCABusinessService,
  getNCABusinessService,
  industryService,
  putNcaBUsinessDetailService,
} from '../../../../../services/index.service';
import { Localization } from '../../../../../services/Localization';
import {
  editBusinessDetailsService,
  getBusinessDetailsService,
} from '../../../../../services/Providers/provider.service';
import { dateFormatter, logger, modalNotification } from '../../../../../utils';
import { Accordion, Col, Row } from 'react-bootstrap';
import { firstLetterCaps } from '../../../../../utils/text.util';
import { Radio } from 'antd';
import {
  createNCABusinessDetailsService,
  getNCAProfileBusinessService,
} from '../../../../../services/NCA/CreateProfile/ncaCreateProfile.service';
import { accessRegisterRoute } from '../../../../../routes/Auth/ncaRegisterSteps';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { checkValidData } from '../../../../../components/UIElements/Formatter';

const BUSINESS_TYPE = [
  'company_sole',
  'company_single',
  'company_multi',
  'company_private',
  'company_pcorporation',
  'company_unicorporated',
];

function BusinessDetails({
  defaultKey,
  getProfileData,
  ncaEdit = false,
  ncaSignUp = false,
  setDefaultKey,
  ncaProfileKey = false,
  provideEdit = false,
  providerData,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const param = useParams();
  const { id } = param;
  const { t } = useTranslation();

  const [countryCode, setCountryCode] = useState('01');
  const [countryId, setCountryId] = useState();
  const [stateId, setStateId] = useState();
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [industryData, setIndustryData] = useState([]);
  const [formData, setFormData] = useState();
  const [type, setType] = useState('individual');
  const [showModal, setShowModal] = useState(false);
  const [businessType, setBusinessType] = useState('');
  const [radioType, setRadioType] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [businessPersonDetail, setBusinessPersonDetail] = useState('');
  const [details, setDetails] = useState({ provider: [], loading: true });
  const [businessDetailLoader, setBusinessDetailLoader] = useState(false);
  const [loader, setLoader] = useState(false);

  const userType = useSelector((state) => state?.auth?.userData);

  async function onCountryChange(e, formik, getPhoneCode) {
    try {
      const res = await Localization.getStateService({
        type: 'all',
        queryParams: { scope: 'activeState', countryId: e || countryId },
      });
      formik?.setFieldValue(
        'countryPhoneCode',
        getPhoneCode ? getPhoneCode[0] : undefined
      );
      formik?.setFieldValue('stateId', null);
      setCountryCode(getPhoneCode ? getPhoneCode[0] : undefined);
      setState([...res]);
    } catch (err) {
      logger(err);
    }
  }

  async function onStateChange(e, formik, search) {
    try {
      setLoader(true);
      const res = await Localization.ListCityService({
        type: 'all',
        queryParams: {
          scope: 'activeCity',
          ...(search
            ? {
                search: e,
              }
            : {
                stateId: e,
              }),
          ...(search === null && { cityId: formData?.Provider?.City?.id }),
        },
      });
      if (formik) {
        formik?.setFieldValue('cityId', null);
      }
      setCity([...res]);
    } catch (err) {
      console.log(err);
    }
    setLoader(false);
  }

  const onSearchCity = (e, formik) => {
    const handler = setTimeout(() => {
      if (e.length > 2) {
        onStateChange(e, formik, true);
      }
    }, 1000);
    return () => {
      clearTimeout(handler);
    };
  };

  async function onSubmit(values) {
    setIsLoading(true);
    try {
      let bodyData = { ...values };
      bodyData.ownershipType = radioType;
      delete bodyData['identityDocumentFrontIdUrl'];
      delete bodyData['identityDocumentBackIdUrl'];
      if (values?.businessType !== 'individual') {
        delete bodyData.socialSecurityNumber;
      }
      // delete bodyData.countryCode;
      delete bodyData.countryPhoneCode;

      let response = '';

      if (ncaEdit) {
        response = await editNCABusinessService({ id, bodyData });
      } else if (ncaSignUp) {
        response = await createNCABusinessDetailsService({ bodyData });
      } else if (ncaProfileKey) {
        response = await putNcaBUsinessDetailService({ bodyData });
      } else {
        response = await editBusinessDetailsService({ id, bodyData });
      }
      const { success, message, data } = response;
      if (success) {
        if (ncaEdit && data?.Nca?.businessType === 'individual') {
          setDefaultKey('bankingDetails');
        }
        if (ncaEdit && data?.UserBusinessPersonDetail?.id) {
          setDefaultKey('bankingDetails');
        }
        //
        if (ncaProfileKey && data?.Nca?.businessType === 'individual') {
          setDefaultKey('bankingDetails');
        }
        if (ncaProfileKey && data?.UserBusinessPersonDetail?.id) {
          setDefaultKey('bankingDetails');
        }
        //
        if (!ncaEdit && !ncaSignUp && !ncaProfileKey) {
          getProfileData();
        }
        modalNotification({
          type: 'success',
          message,
        });
        getBusinessDetails(id);
        onCloseModal();
      }
    } catch (error) {
      logger(error);
    }
    setIsLoading(false);
  }

  const getBusinessDetails = async (id) => {
    setBusinessDetailLoader(true);
    try {
      let response = '';
      if (ncaEdit) {
        response = await getNCABusinessService({ id });
      } else if (ncaSignUp) {
        response = await getNCAProfileBusinessService();
      } else if (ncaProfileKey) {
        response = await businessDetailsService();
      } else {
        response = await getBusinessDetailsService(id);
      }

      const { success, data } = response;

      if (success) {
        setFormData(data);

        if (ncaEdit || ncaSignUp || ncaProfileKey) {
          // Remaining testing
          setCountryId(data?.UserBusinessDetail?.Country?.id);
          setBusinessType(data?.Nca?.businessType);
          setBusinessPersonDetail(data?.UserBusinessPersonDetail);

          if (
            data?.Nca?.isPlanCancelled === 1 &&
            userType?.Role?.type === 'nca'
          ) {
            navigate(accessRegisterRoute.CANCEL_COMPANY_PROFILE.path);
          } else if (
            ncaSignUp &&
            data?.Nca?.businessType === 'individual' &&
            data?.Nca?.onBoardingStep > 3
          ) {
            navigate(accessRegisterRoute.BANK_DETAILS.path);
          }
        } else {
          setCountryId(data?.UserBusinessPersonDetail?.countryId);
          setBusinessType(data?.Provider?.businessType);
          setBusinessPersonDetail(data?.UserBusinessPersonDetail);
        }

        const arr = [];
        if (data?.UserBusinessPersonDetail) {
          arr.push(data?.UserBusinessPersonDetail);
        }
        if (data?.UserBusinessDetail) {
          arr.push(data?.UserBusinessDetail);
        }
        setDetails({ data: [...arr], loading: false });
        if (data?.countryAddressId) {
          onCountryChange(onCountryChange);
        }
        setStateId(data?.stateId);
      }
    } catch (error) {
      logger(error);
    }
    setBusinessDetailLoader(false);
    // setLoading(false);
  };

  const getIndustryList = async () =>
    // id
    {
      try {
        let queryParams = {
          scope: 'activeIndustry',
        };
        const response = await industryService.getIndustryService({
          queryParams,
        });
        setIndustryData([...response?.data?.rows]);
      } catch (error) {
        logger(error);
      }
    };

  useEffect(() => {
    if (countryId) onCountryChange(countryId);
    if (stateId) {
      onStateChange(stateId, false, null);
    }
  }, [countryId, stateId]);

  useEffect(() => {
    if (defaultKey === 'businessDetails') {
      getBusinessDetails(id);
      getIndustryList();
    }
  }, [defaultKey]);

  const onCloseModal = () => {
    if (showModal) {
      setShowModal(false);
    } else {
      setShowForm(false);
    }
    getBusinessDetails(id);
  };

  useEffect(() => {
    setShowForm(!showModal);
  }, [showModal]);

  const onSubmitForm = () => {
    setShowForm(true);
    setShowModal(false);
  };

  useEffect(() => {
    if (defaultKey === 'businessDetails') {
      if (
        businessType === 'individual' ||
        businessType === null ||
        businessPersonDetail
      ) {
        setShowModal(false);
      } else {
        setShowModal(true);
      }
    }
  }, [businessType, defaultKey]);

  const handleChange = (e) => {
    const { value } = e.target;
    setRadioType(value);
  };

  if (details?.loading && !ncaSignUp) {
    return <GlobalLoader />;
  }

  function onBack() {
    setShowModal(true);
    setShowForm(false);
  }

  if (
    (BUSINESS_TYPE.includes(formData?.Provider?.businessType) ||
      BUSINESS_TYPE.includes(formData?.Nca?.businessType)) &&
    Array.isArray(details?.data) &&
    details?.data?.length === 2
  ) {
    return (
      <BusinessCard
        data={formData}
        ncaSignUp={ncaSignUp}
        ncaProfileKey={ncaProfileKey}
        loading={businessDetailLoader}
      />
    );
  }

  if (
    (BUSINESS_TYPE.includes(formData?.Provider?.businessType) ||
      BUSINESS_TYPE.includes(formData?.Nca?.businessType)) &&
    Array.isArray(details?.data) &&
    details?.data?.length === 1
  ) {
    /*********Provider filled only business details not individual********* */
    /***********Open an model of ownership************************ */
    return (
      <>
        <BusinessCard
          data={formData}
          ncaSignUp={ncaSignUp}
          ncaProfileKey={ncaProfileKey}
          loading={businessDetailLoader}
        />
        <ModalComponent
          title={t('text.provider.addPerson')}
          icon="icon ni ni-user-alt-fill"
          closable={false}
          show={showModal}
          onHandleCancel={onCloseModal}
        >
          <form>
            <Radio
              id="primary_representative"
              name="primary_representative"
              value="primary_representative"
              onChange={(e) => handleChange(e)}
              checked={radioType === 'primary_representative'}
            >
              <span htmlFor="primary_representative">
                {t('text.provider.primary_representative')}
              </span>
            </Radio>
            <br />
            <Radio
              id="percent_ownership"
              name="percent_ownership"
              value="percent_ownership"
              onChange={(e) => handleChange(e)}
              checked={radioType === 'percent_ownership'}
            >
              <span htmlFor="percent_ownership">
                {t('text.provider.percent_ownership')}
              </span>
            </Radio>
            <br />
            <Radio
              id="governing_board"
              name="governing_board"
              value="governing_board"
              onChange={(e) => handleChange(e)}
              checked={radioType === 'governing_board'}
            >
              <span htmlFor="governing_board">
                {t('text.provider.governing_board')}
              </span>
            </Radio>
            <br />
            <Radio
              id="senior_manager_executive"
              name="senior_manager_executive"
              value="senior_manager_executive"
              onChange={(e) => handleChange(e)}
              checked={radioType === 'senior_manager_executive'}
            >
              <span htmlFor="senior_manager_executive">
                {t('text.provider.senior_manager_executive')}
              </span>
            </Radio>
            <div className=" mt-2 text-end">
              <CommonButton
                onClick={() => onSubmitForm()}
                className="btn btn-primary"
              >
                {t('text.provider.saveAndContinue')}
              </CommonButton>
            </div>
          </form>
        </ModalComponent>
        <ModalComponent
          title={t('text.provider.businessType')}
          show={showForm}
          onHandleCancel={onCloseModal}
          extraClassName="modal-lg"
          onHeaderIconClick={onBack}
          icon="icon ni ni-arrow-left"
        >
          <div className="nk-block">
            <EditBusinessDetailsForm
              onSubmit={onSubmit}
              isLoading={isLoading}
              onCountryChange={onCountryChange}
              state={state}
              singleState={true}
              onStateChange={onStateChange}
              city={city}
              industryData={industryData}
              // formData={formData}
              setType={setType}
              type={type}
              countryCode={countryCode}
              radioType={radioType}
              ncaSignUp={ncaSignUp}
              ncaProfileKey={ncaProfileKey}
              modalSkip={false}
              ncaEdit={ncaEdit}
              isModal={true}
              provideEdit={provideEdit}
            />
          </div>
        </ModalComponent>
      </>
    );
  }

  if (
    ['individual'].includes(formData?.Provider?.businessType) ||
    (['individual'].includes(formData?.Nca?.businessType) && ncaEdit) ||
    (['individual'].includes(formData?.Nca?.businessType) && ncaProfileKey)
  ) {
    return (
      <BusinessCard
        data={formData}
        ncaSignUp={ncaSignUp}
        ncaProfileKey={ncaProfileKey}
        loading={businessDetailLoader}
      />
    );
  }

  return (
    <EditBusinessDetailsForm
      onSubmit={onSubmit}
      isLoading={isLoading}
      onCountryChange={onCountryChange}
      state={state}
      onStateChange={onStateChange}
      onSearchCity={onSearchCity}
      loader={loader}
      city={city}
      industryData={industryData}
      setType={setType}
      type={type}
      countryCode={countryCode}
      ncaEdit={ncaEdit}
      ncaSignUp={ncaSignUp}
      ncaProfileKey={ncaProfileKey}
      providerData={providerData}
    />
  );
}

export default BusinessDetails;

const BusinessCard = ({ data, ncaSignUp, ncaProfileKey, loading }) => {
  const { t } = useTranslation();

  const ownershipTypeObject = {
    primary_representative: t('text.provider.primary_representative'),
    percent_ownership: t('text.provider.percent_ownership'),
    governing_board: t('text.provider.governing_board'),
    senior_manager_executive: t('text.provider.senior_manager_executive'),
    saveAndContinue: t('text.provider.saveAndContinue'),
  };
  const { UserBusinessDetail, UserBusinessPersonDetail } = data;
  if (!UserBusinessDetail) {
    return !loading ? (
      <>
        <div className="card">
          <div className="card-inner">
            <div className="ncaBusinessDetails">
              <h5 className="title mb-3">{t(`text.common.businessDetails`)}</h5>
              <div className="divider sm"></div>
              <IndividualCard
                UserBusinessPersonDetail={UserBusinessPersonDetail}
              />
            </div>
          </div>
        </div>
      </>
    ) : (
      <GlobalLoader />
    );
  }

  return !loading ? (
    <>
      <>
        <div className="card">
          <div className="card-inner">
            <div className="ncaBusinessDetails">
              {(!ncaSignUp || !ncaProfileKey) && (
                <>
                  <h5 className="title mb-3">
                    {t(`text.common.businessDetails`)}
                  </h5>
                  <div className="divider sm"></div>
                </>
              )}

              <Row>
                <Col lg={6}>
                  <ul className="list-unstyled mb-0">
                    <li>
                      <label className="sub-text">
                        {t(`text.common.companyName`)}
                      </label>
                      {UserBusinessDetail?.company || '-'}
                    </li>
                    <li>
                      <label className="sub-text">
                        {t(`text.common.phoneNumber`)}
                      </label>
                      {UserBusinessDetail?.phoneNumber || '-'}
                    </li>
                    <li>
                      <label className="sub-text">
                        {t(`text.common.addressLine1`)}
                      </label>
                      {UserBusinessDetail?.addressLine1 || '-'}
                    </li>
                    <li>
                      <label className="sub-text">
                        {t(`text.common.state`)}
                      </label>
                      {UserBusinessDetail?.state || '-'}
                    </li>
                    <li>
                      <label className="sub-text">
                        {t(`text.common.zipCode`)}
                      </label>
                      {UserBusinessDetail?.zipCode || '-'}
                    </li>
                    <li>
                      <label className="sub-text">
                        {t(`text.common.industry`)}
                      </label>{' '}
                      <div className="d-flex flex-wrap">
                        {UserBusinessDetail?.UserBusinessIndustries &&
                        UserBusinessDetail?.UserBusinessIndustries?.length > 0
                          ? UserBusinessDetail?.UserBusinessIndustries?.filter(
                              (e) => e?.Industry
                            )?.map((e, idx) => (
                              <span key={idx} className="badge bg-light me-1">
                                {e?.Industry?.name}
                              </span>
                            ))
                          : '-'}
                      </div>{' '}
                    </li>
                    <li>
                      <label className="sub-text">
                        {t(`text.common.ownershipType`)}
                      </label>
                      {UserBusinessDetail?.ownershipType ||
                      UserBusinessPersonDetail?.ownershipType
                        ? ownershipTypeObject[
                            UserBusinessDetail?.ownershipType ||
                              UserBusinessPersonDetail?.ownershipType
                          ]
                        : '-'}
                    </li>
                    <li>
                      <label className="sub-text">
                        {t(`text.common.businessTaxID`)}
                      </label>{' '}
                      {UserBusinessDetail?.taxId || '-'}
                    </li>
                  </ul>
                </Col>
                <Col lg={6}>
                  <ul className="list-unstyled">
                    <li>
                      <label className="sub-text">
                        {t(`text.common.employerIdentification`)}
                      </label>
                      {UserBusinessDetail?.employeeId || '-'}
                    </li>
                    <li>
                      <label className="sub-text">
                        {t(`text.common.regBusinessAddress`)}
                      </label>
                      {UserBusinessDetail?.Country?.name || '-'}
                    </li>
                    <li>
                      <label className="sub-text">
                        {t(`text.common.addressLine2`)}
                      </label>
                      {UserBusinessDetail?.addressLine2 || '-'}
                    </li>
                    <li>
                      <label className="sub-text">
                        {t(`text.common.city`)}
                      </label>
                      {UserBusinessDetail?.city || '-'}
                    </li>
                    <li>
                      <label className="sub-text">
                        {t(`text.common.doingBusinessAss`)}
                      </label>
                      {firstLetterCaps(UserBusinessDetail?.doingBusinessAs) ||
                        '-'}
                    </li>
                    <li>
                      <label className="sub-text">
                        {t(`text.common.businessWebsite`)}
                      </label>{' '}
                      <Link to={UserBusinessDetail?.businessWebsite}>
                        {UserBusinessDetail?.businessWebsite || '-'}
                      </Link>
                    </li>
                    <li>
                      <label className="sub-text">
                        {' '}
                        {t(`text.common.businessVatID`)}{' '}
                      </label>{' '}
                      {UserBusinessDetail?.vatId || '-'}
                    </li>
                  </ul>
                </Col>
              </Row>
              {UserBusinessPersonDetail && (
                <Row className="mt-3">
                  <Accordion defaultActiveKey="0" flush className="p-0 border">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div>
                          <h6>
                            {firstLetterCaps(
                              UserBusinessPersonDetail?.firstName
                            )}{' '}
                            {firstLetterCaps(
                              UserBusinessPersonDetail?.lastName
                            )}
                          </h6>
                          <p>
                            Representative, Owner,{` `}
                            {checkValidData(UserBusinessPersonDetail?.jobTitle)}
                          </p>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body className="p-3">
                        <Row className="mt-3">
                          <Col md={6}>
                            <ul className="list-unstyled mb-0">
                              <li>
                                <label className="sub-text">
                                  {t(`text.common.percentOwnership`)}
                                </label>
                                {UserBusinessPersonDetail?.ownershipPercentage
                                  ? `${UserBusinessPersonDetail.ownershipPercentage}%`
                                  : '-'}
                              </li>
                              <li>
                                <label className="sub-text">
                                  {t(`text.common.dateOfBirth`)}
                                </label>
                                {dateFormatter(
                                  UserBusinessPersonDetail?.dateOfBirth,
                                  ' MMMM D, YYYY'
                                ) || '-'}
                              </li>
                              <li>
                                <label className="sub-text">
                                  {t(`text.common.SSNLastFour`)}
                                </label>
                                {UserBusinessPersonDetail?.socialSecurityNumber ||
                                  '-'}
                              </li>
                              <li>
                                <label className="sub-text">
                                  {' '}
                                  {t(`text.common.homeAddress`)}
                                </label>{' '}
                                {UserBusinessPersonDetail?.Country?.name || '-'}
                              </li>
                              <li>
                                <label className="sub-text">
                                  {t(`text.common.addressLine2`)}
                                </label>
                                {UserBusinessPersonDetail?.addressLine2 || '-'}
                              </li>
                              <li>
                                <label className="sub-text">
                                  {t(`text.common.state`)}
                                </label>
                                {UserBusinessPersonDetail?.state || '-'}
                              </li>
                              <li>
                                <label className="sub-text">
                                  {t(`text.common.zipCode`)}
                                </label>
                                {UserBusinessPersonDetail?.zipCode || '-'}{' '}
                              </li>
                              <li>
                                <label className="sub-text">
                                  {t(`text.common.documentFront`)}
                                </label>{' '}
                                <span className="badge rounded-pill bg-primary">
                                  Verified
                                </span>{' '}
                              </li>
                              <li className="documentImg">
                                <ImageElement
                                  previewSource={
                                    UserBusinessPersonDetail?.identityDocumentFrontThumbUrl ||
                                    ''
                                  }
                                />
                              </li>
                            </ul>
                          </Col>
                          <Col md={6}>
                            <ul className="list-unstyled">
                              <li>
                                <label className="sub-text">
                                  {t(`text.common.email`)}
                                </label>{' '}
                                {UserBusinessPersonDetail?.email || '-'}
                              </li>
                              <li>
                                <label className="sub-text">
                                  {t(`text.common.phoneNumber`)}
                                </label>
                                {UserBusinessPersonDetail?.phoneNumber || '-'}
                              </li>
                              <li>
                                <label className="sub-text">
                                  {t(`text.common.addressLine1`)}
                                </label>{' '}
                                {UserBusinessPersonDetail?.addressLine1 || '-'}
                              </li>
                              <li>
                                <label className="sub-text">
                                  {t(`text.common.city`)}
                                </label>{' '}
                                {UserBusinessPersonDetail?.city || '-'}
                              </li>
                              <li>
                                <label className="sub-text">
                                  {t(`text.common.documentBack`)}
                                </label>{' '}
                                <span className="badge rounded-pill bg-primary">
                                  Verified
                                </span>
                              </li>
                              <li className="documentImg">
                                <ImageElement
                                  previewSource={
                                    UserBusinessPersonDetail?.identityDocumentBackThumbUrl ||
                                    ''
                                  }
                                />{' '}
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Row>
              )}
            </div>
          </div>
        </div>
      </>
    </>
  ) : (
    <GlobalLoader />
  );
};

const IndividualCard = ({ UserBusinessPersonDetail }) => {
  return (
    <>
      <Row>
        <Col md={6}>
          <ul className="list-unstyled mb-0">
            <li>
              <label className="sub-text">{t(`text.common.name`)}</label>
              {firstLetterCaps(UserBusinessPersonDetail?.firstName)}{' '}
              {firstLetterCaps(UserBusinessPersonDetail?.lastName)}
            </li>
            <li>
              <label className="sub-text">{t(`text.common.phoneNumber`)}</label>
              {UserBusinessPersonDetail?.phoneNumber || '-'}
            </li>
            {/* <li>
              <label className="sub-text">
                {t(`text.common.addressLine1`)}
              </label>{' '}
              {UserBusinessPersonDetail?.addressLine1 || '-'}
            </li> */}
            <li>
              <label className="sub-text">{t(`text.common.state`)}</label>
              {UserBusinessPersonDetail?.state || '-'}
            </li>
            <li>
              <label className="sub-text">{t(`text.common.zipCode`)}</label>
              {UserBusinessPersonDetail?.zipCode || '-'}{' '}
            </li>
            <li>
              <label className="sub-text">
                {t(`text.common.documentFront`)}{' '}
                <span className="badge rounded-pill bg-primary">Verified</span>{' '}
              </label>
              {/* <span className='badge rounded-pill bg-primary'>{UserBusinessPersonDetail?.socialSecurityNumber || "-"}</span>  */}
            </li>
            <li className="documentImg">
              <ImageElement
                previewSource={
                  UserBusinessPersonDetail?.identityDocumentFrontThumbUrl || ''
                }
              />
            </li>
          </ul>
        </Col>
        <Col md={6}>
          <ul className="list-unstyled">
            <li>
              <label className="sub-text">{t(`text.common.homeAddress`)}</label>{' '}
              {UserBusinessPersonDetail?.Country?.name || '-'}
            </li>

            <li>
              <label className="sub-text">
                {t(`text.common.addressLine1`)}
              </label>{' '}
              {UserBusinessPersonDetail?.addressLine1 || '-'}
            </li>
            <li>
              <label className="sub-text">{t(`text.common.city`)}</label>{' '}
              {UserBusinessPersonDetail?.city || '-'}
            </li>
            <li>
              <label className="sub-text">{t(`text.common.dateOfBirth`)}</label>
              {dateFormatter(
                UserBusinessPersonDetail?.dateOfBirth,
                'MMMM D, YYYY'
              ) || '-'}
            </li>
            <li>
              <label className="sub-text">
                {t(`text.common.documentBack`)}{' '}
                <span className="badge rounded-pill bg-primary">Verified</span>{' '}
              </label>
            </li>
            <li className="documentImg">
              <ImageElement
                previewSource={
                  UserBusinessPersonDetail?.identityDocumentBackThumbUrl || ''
                }
              />
            </li>
          </ul>
        </Col>
      </Row>
    </>
  );
};
