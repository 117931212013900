export const APP_NAME = process.env.REACT_APP_APP_NAME || 'React App';

export const APP_KEY = process.env.REACT_APP_APP_KEY || 'react-app';

export const CUSTOMER_APP_KEY =
  process.env.REACT_APP_CUSTOMER_KEY || 'good-guyz-customer';

export const ENVIRONMENT = process.env.REACT_APP_ENV || 'development';

export const LANGUAGE = process.env.REACT_APP_LANG || 'en';

export const APP_DATE_FORMAT =
  process.env.REACT_APP_DATE_FORMAT || 'DD-MM-YYYY';

export const APP_DATE_INPUT_FORMAT =
  process.env.REACT_APP_DATE_FORMAT || 'YYYY-MM-DD';

export const IMAGE_URL =
  process.env.REACT_APP_IMAGE_PATH || '/assets/images/admin';

export const WEEK_START_FROM_DAY = 1;

export const GOOGLE_MAP_URL = (mapKey, libraries) =>
  mapKey &&
  `https://maps.googleapis.com/maps/api/js?key=${mapKey}&callback=initialize&sensor=false&libraries=${libraries}`;

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

//
export const SOCIAL_LINKS = {
  LINKDIN_LINK: '#',
  TWITTER_LINK: '#',
  INSTAGRAM_LINK: '#',
  FACEBOOK_LINK: 'https://www.facebook.com/GoodGuysHVAC/',
  GOOGLE_LINK:
    'https://www.google.com/maps/place/Good+Guys+Heating+%26+Air+Conditioning,+LLC/@40.0082985,-86.1793091,17z/data=!3m1!4b1!4m5!3m4!1s0x8814a95233606e53:0x5c80c487ef2d2741!8m2!3d40.0082985!4d-86.1793091',
  PLAY_STORE: 'https://play.google.com/store/apps/details?id=com.gghs.customer',
  APPLE_STORE:
    'https://apps.apple.com/in/app/good-guys-home-services/id1490393229',
  MAIL: 'mailto: info@goodguyshomeservices.com',
  PHONE: 'tel: 317-896-0494',
  FOOTER_MAIL: 'info@goodguyshomeservices.com',
  FOOTER_PHONE: '317-896-0494',
};

export const invoiceReviewUrl = 'https://goodguys.app';

export const MONTH_DATE_YEAR =
  process.env.REACT_APP_MONTH_DATE_YEAR || 'MM/DD/YY';
export const DATE_TH_MONTH_YEAR =
  process.env.REACT_APP_DATE_TH_MONTH_YEAR || 'Do MMMM, YYYY';
export const DATE_TH_MONTH_YEAR_TIME =
  process.env.REACT_APP_DATE_TH_MONTH_YEAR_TIME || 'Do MMMM, YYYY, HH:mm A';
export const DATE_MONTH__DATE_TH_YEAR =
  process.env.REACT_APP_DATE_MONTH__DATE_TH_YEAR || 'ddd, MMMM, DD, YYYY';
export const DATE_MONTH__DATE_TH_YEAR_TIME =
  process.env.REACT_APP_DATE_MONTH__DATE_TH_YEAR_TIME ||
  'ddd, MMMM, DD, YYYY, HH:mm A';
export const DATE_FULL_MONTH__DATE_TH_YEAR_TIME =
  process.env.REACT_APP_DATE_FULL_MONTH__DATE_TH_YEAR_TIME ||
  'ddd, MMM D, YYYY hh:mm A';
export const DATE_MONTH_YEAR_TIME =
  process.env.REACT_APP_DATE_MONTH_YEAR || 'DD/MM/YYYY hh:mm A';
export const DATE_MONTH_ORDER_YEAR =
  process.env.REACT_APP_DATE_MONTH_YEAR || 'MMM DD YYYY h:mm A';

export const INVOICE_REVIEW =
  process.env.REACT_APP_INVOICE_REVIEW_APP || 'https://goodguys.app';

export const LOG_VIEW = process.env.REACT_APP_LOG_VIEWER
  ? process.env.REACT_APP_LOG_VIEWER
  : 'off';

export const MANAGE_LOCATION_VIDEO =
  process.env.REACT_APP_DRAW_VIDEO ||
  'https://d310hlz49wlr0s.cloudfront.net/public/react-resources/video/manage_location.mp4';

export const STORE_APP_KEY =
  process.env.REACT_APP_STORE_KEY || 'good-guyz-store';
export const CLOUD_RENT_UI = process.env.REACT_APP_CLOUD_RENT_UI || '';
export const CLOUD_RENT = process.env.REACT_APP_CLOUD_RENT || '';
