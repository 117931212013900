import i18next from 'i18next';
import {
  checkValidData,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { accessRoute } from '../../routes/Dashboard';
import {
  getInputSearchFilter,
  getColumnSelectSearchProps,
} from '../../components';
import { getTupleStatusList } from '../index.service';
import { TableImageFormatter } from '../../utils/formatter.util';
import { accessRoute as FinanceAccessRoute } from '../../routes/PriceManagement';
import { readMoreTextShow } from '../../utils';

export const financeBankBreadcrumbBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.quote.financingBank'),
    },
  ];
};

export const financeBankBreadcrumbTableColumn = ({
  Action,
  onSort,
  onFilterChange,
  Toggle,
  filter,
  page,
  sizePerPage,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'bankLogo',
      title: i18next.t('text.quote.bankLogo'),
      headerClasses: 'sorting',
      sort: false,
      render: (cell, row) => <TableImageFormatter url={row?.bankLogoUrl} />,
    },
    {
      dataIndex: 'bankName',
      title: i18next.t('text.quote.bankName'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => checkValidData(row?.bankName),
      ...getInputSearchFilter({
        dataIndex: 'bankName',
        placeholder: 'bank name',
        name: 'bank',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'bankUrl',
      title: i18next.t('text.quote.url'),
      sorter: true,
      render: (cell, row) =>
        readMoreTextShow(checkValidData(cell), i18next.t('text.quote.url'), 20),
      ...getInputSearchFilter({
        dataIndex: 'bankUrl',
        onFilterChange,
        filter,
        placeholder: 'url',
      }),
    },
    {
      dataIndex: 'description',
      title: i18next.t('text.quote.description'),
      headerClasses: 'sorting',
      sort: true,
      render: (cell, row) =>
        readMoreTextShow(
          checkValidData(cell),
          i18next.t('text.quote.description'),
          20
        ),
      ...getInputSearchFilter({
        dataIndex: 'description',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'status',
      title: i18next.t('text.common.status'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => <Toggle row={row} cell={cell} />,
      ...getColumnSelectSearchProps({
        dataIndex: 'status',
        list: getTupleStatusList(true),
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.common.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => <Action row={row} cell={cell} />,
    },
  ];
};
export const addFinanceBankBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: FinanceAccessRoute.LIST_FINANCING_BANK.path,
      name: i18next.t('text.quote.financingBank'),
    },
    {
      path: '#',
      name: i18next.t('text.quote.editFinancingBank'),
    },
  ];
};
export const editFinanceBankBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: FinanceAccessRoute.LIST_FINANCING_BANK.path,
      name: i18next.t('text.quote.financingBank'),
    },
    {
      path: '#',
      name: i18next.t('text.quote.editFinancingBank'),
    },
  ];
};
