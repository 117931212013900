export const duration = () => {
  return [
    {
      name: 'Monthly ($15)',
      value: 'Monthly ($15)',
    },
    {
      name: 'Yearly ($30)',
      value: 'Yearly ($30)',
    },
  ];
};

export const roles = () => {
  return [
    {
      name: 'NCA Role 1',
      value: 'NCA Role 1',
    },
    {
      name: 'NCA Role 2',
      value: 'NCA Role 2',
    },
    {
      name: 'NCA Role 3',
      value: 'NCA Role 3',
    },
  ];
};

export const months = () => {
  return [
    {
      name: '01',
      value: 1,
    },
    {
      name: '02',
      value: 2,
    },
    {
      name: '03',
      value: 3,
    },
    {
      name: '04',
      value: 4,
    },
    {
      name: '05',
      value: 5,
    },
    {
      name: '06',
      value: 6,
    },
    {
      name: '07',
      value: 7,
    },
    {
      name: '08',
      value: 8,
    },
    {
      name: '09',
      value: 9,
    },
    {
      name: '10',
      value: 10,
    },
    {
      name: '11',
      value: 11,
    },
    {
      name: '12',
      value: 12,
    },
  ];
};

export const years = () => {
  return [
    {
      name: 2023,
      value: 2023,
    },
    {
      name: 2024,
      value: 2024,
    },
    {
      name: 2025,
      value: 2025,
    },
  ];
};

export const businessTypeData = () => {
  return [
    {
      name: 'Individual',
      value: 'Individual',
    },
    {
      name: 'Company-Sole Proprietorship',
      value: 'Company-Sole Proprietorship',
    },
    {
      name: 'Company-Single Member LLC',
      value: 'Company-Single Member LLC',
    },
  ];
};

export const homeAddress = () => {
  return [
    {
      name: 'India',
      value: 'India',
    },
    {
      name: 'United States',
      value: 'United States',
    },
  ];
};

export const industry = () => {
  return [
    {
      name: 'industry 1',
      value: 'industry 1',
    },
    {
      name: 'industry 2',
      value: 'industry 2',
    },
    {
      name: 'industry 3',
      value: 'industry 3',
    },
    {
      name: 'industry 4',
      value: 'industry 4',
    },
    {
      name: 'industry 5',
      value: 'industry 5',
    },
  ];
};

export const addPersonList = () => {
  return [
    {
      label:
        'This person is authorized as the primary representative of the account.',
      value: 1,
    },
    {
      label: 'This person owns 25% or more of the company.',
      value: 2,
    },
    {
      label: 'This person is a member of the governing board of the company.',
      value: 3,
    },
    {
      label:
        ' This person is an executive or senior manager with significant management responsibility.',
      value: 4,
    },
  ];
};
