import React from 'react';
import { Form, Calendar as AntCalendar } from 'antd';
import { useField } from 'formik';

function Calender({ name, ...rest }) {
  const [field, meta] = useField(name);
  const config = { ...field, ...rest };
  if (meta && meta.touched && meta.error) {
    config.error = true;
    config.helperText = meta.error;
  }
  // const handleChangeDate = (value) => {
  //   helpers.setValue(value);
  //   helpers.setError('');
  //   helpers.setTouched(false);
  // };
  return (
    <Form.Item
      label={rest?.label}
      name={name}
      help={meta?.error && meta?.error && meta?.touched ? meta.error : ''}
      validateStatus={config?.error ? 'error' : 'success'}
    >
      <AntCalendar {...rest} />
    </Form.Item>
  );
}

export default Calender;
