import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import socketio from 'socket.io-client';
import { API_ENDPOINT_V1 } from '../../../config';

import ChatMessageBox from './ChatMessageBox';
import ChatSidebar from './ChatSidebar';
import ChatProvider from '../../../context/chat.context';

import { logger } from '../../../utils';

const ViewChat = ({ userDetails }) => {
  const [newMessages, setNewMessages] = useState([]);

  const socket = useMemo(() => {
    return socketio(API_ENDPOINT_V1.replace(/\/api$/, ''), {
      transports: ['websocket'],
    });
  }, []);

  const handleReceiveMessage = (data) => {
    setNewMessages(data);
  };

  const handleMemberRemove = (data) => {
    logger('member-removed', data);
  };

  useEffect(() => {
    let userDetailsObj = {
      userId: userDetails?.id,
      name: userDetails?.firstName,
      userImage: userDetails?.profilePictureUrl,
    };
    socket.emit('register', userDetailsObj);
    return () => socket.disconnect();
  }, []);

  useEffect(() => {
    socket.on('new_msg_receive', handleReceiveMessage);
    socket.on('member-removed', handleMemberRemove);
  }, [socket]);

  return (
    <ChatProvider>
      <div className="nk-chat">
        <ChatSidebar newMessages={newMessages} socket={socket} />
        <ChatMessageBox newMessages={newMessages} socket={socket} />
      </div>
    </ChatProvider>
  );
};

const mapStateToProps = (state) => ({
  userDetails: state?.auth?.userData,
});

export default connect(mapStateToProps)(ViewChat);
