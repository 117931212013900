let url = '/v1/admin/service-management';
export const SERVICE_MANAGEMENT_ENDPOINT = {
  getServiceManagementList: {
    url,
    method: 'GET',
  },
  getViewQuoteDetails: (id) => ({
    url: `/v1/admin/booking/quote/${id}`,
    method: 'GET',
  }),
  getBookingLogDetails: (id) => ({
    url: `/v1/admin/booking-log/${id}`,
    method: 'GET',
  }),
  deleteBooking: {
    url: '/v1/admin/delete-booking',
    method: 'PUT',
  },
  getInvoiceDetails: (id) => ({
    url: `/v1/admin/booking/invoice/${id}`,
    method: 'GET',
  }),
  getServiceManagementExcel: {
    url: 'v1/admin/service-management-excel',
    method: 'GET',
  },
};
