import React, { useState } from 'react';
import {
  ActionWrapper,
  AddEditCoupon,
  ModalWrapper,
  PageContainerWrapper,
  TablePlus,
  Toggle,
} from '../../../../components';
import {
  Columns,
  addCouponService,
  couponCodeStatusService,
  couponListService,
  deleteCouponCodeService,
  editCouponCodeService,
  getActions,
  getCouponCodeService,
} from '../../../../services/index.service';
import { useTranslation } from 'react-i18next';
import useTable from '../../../../hooks/useTable.hook';
import { Link } from 'react-router-dom';
import { modalNotification } from '../../../../utils';
import { firstLetterCaps } from '../../../../utils/text.util';

export default function CouponList() {
  const breadcrumb = Columns.couponManagementBreadcrumb();
  // const productData = Columns.couponManagementService();
  // const columns = Columns.couponManagementTableColumn();
  const { t } = useTranslation();
  const [state, setState] = useState({
    loader: null,
    modal: null,
    modalId: null,
    selectedRows: [],
    submitText: 'Add',
  });
  const { loader, modal, modalId, selectedRows, submitText } = state;
  const [promoCodeState, setPromoCodeState] = useState();
  const [tableHandler, tableProps] = useTable(
    couponListService,
    false,
    false,
    false,
    false,
    false
  );
  const {
    data,
    isLoading,
    count,
    page,
    noOfPages,
    sizePerPage,
    filter,
    sortBy,
    sortType,
  } = tableProps;
  const {
    onRefresh,
    getData,
    onTableSort,
    onFilterChange,
    onFilterResetChange,
    onToggleChange,
    onConfirmDeleteAlert,
  } = tableHandler;

  const headerBtn = {
    add: {
      name: t('text.common.addCoupon'),
      handler: () => {
        setState({ ...state, submitText: 'Add', modal: 'add-coupon' });
      },
    },
  };

  function onAction(e, handler, item) {
    e.preventDefault();
    handler(item);
  }

  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options?.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item?.handler, item)}
              key={key}
              className="action_list"
              action={item?.action}
            >
              <Link
                // to={generatePath(accessRouteShopping.PRODUCTS_EDIT.path, {
                //   id: item?.id,
                // })}
                onClick={(e) => e.preventDefault()}
              >
                <em className={item?.icon} /> {item?.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };

  const columns = Columns.couponManagementTableColumn({
    onSort: onTableSort,
    onFilterChange: onFilterChange,
    filter,
    page,
    sizePerPage,
    Action: (
      props
      // row
    ) =>
      actionFormatter([
        {
          name: t('text.common.edit'),
          icon: 'icon ni ni-edit',
          action: getActions('EDIT').value,
          handler: () => {
            setState({
              ...state,
              modal: 'edit-coupon',
              submitText: 'Update',
              modalId: props?.row?.id,
            });
          },
        },
        {
          name: t('text.common.delete'),
          icon: 'icon ni ni-trash',
          action: getActions('DELETE').value,
          handler: () => {
            onConfirmDeleteAlert({
              id: props.row.id,
              text: t('text.shoppingCart.areYouSureDeleteCouponCode'),
              service: deleteCouponCodeService,
              key: 'couponCodeIds',
            });
            document.body.click();
          },
        },
      ]),
    Toggle: (props) => (
      <Toggle
        isDropDown={true}
        shouldDeleteInclude={true}
        loading={loader === 'status-update'}
        defaultChecked={firstLetterCaps(props?.row?.status)}
        onChange={(e) =>
          onToggleChange(
            e,
            props?.row,
            '',
            couponCodeStatusService,
            'couponCodeIds',
            true
          )
        }
        onRefresh={onRefresh}
      />
    ),
  });

  function onCancel() {
    setState({ ...state, modal: null, modalId: null });
  }

  const formProps = {
    onCancel: onCancel,
    onSubmit: onSubmit,
  };
  async function onSubmit(payload, props) {
    setState({ ...state, loader: 'add' });
    try {
      let payloadSub = {
        code: payload.couponCode,
        description: payload.description,
        discountValue: payload.discount,
        discountType: payload.discountType,
        validityType: payload.validity,
        usageLimit: payload.usageLimit,
      };
      if (!modalId) {
        payloadSub = {
          ...payloadSub,
          status: 'active',
        };
      }
      if (payload?.activationDate && payload?.expiredDate) {
        payloadSub = {
          ...payloadSub,
          activeDate: payload?.activationDate,
          expiryDate: payload?.expiredDate,
        };
      }
      let response = '';

      if (modalId)
        response = await editCouponCodeService({
          bodyData: payloadSub,
          id: modalId,
        });
      else
        response = await addCouponService({
          bodyData: payloadSub,
        });

      const { success, message } = response;

      if (success) {
        setState({ ...state, modal: null, loader: null, modalId: null });
        modalNotification({
          type: 'success',
          message,
        });
        onRefresh();
      } else {
        setState({ ...state, loader: null });
        props.setFieldValue('couponCode', promoCodeState);
      }
    } catch (error) {
      console.log(error);
      setState({ ...state, loader: null });
    }
  }

  async function getDetail(id) {
    try {
      const res = await getCouponCodeService({ id });
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  const modalForm = {
    add: (
      <AddEditCoupon
        onSubmit={onSubmit}
        onEdit={getDetail}
        onCancel={onCancel}
        loading={loader === 'add'}
        modalId={modalId}
        submitText={submitText}
        setPromoCodeState={setPromoCodeState}
        {...formProps}
      />
    ),
  };
  return (
    <>
      <PageContainerWrapper
        // form={modalForm['filter']}
        heading={t('text.common.couponManagement')}
        breadcrumb={breadcrumb}
        btn={headerBtn}
        // btnArr={['extraButton']}
        // extraBtnText={'Download'}
        // multipleButtonClass={true}
        // extraClass="btn btn-primary d-block d-xl-none"
        // extraMobileClass="p-xl-0"
      >
        <ModalWrapper
          heading={`${
            submitText === 'Add' ? t('text.common.add') : t('text.common.edit')
          } Coupon`}
          onCancel={onCancel}
          content={modalForm['add']}
          show={['add-coupon', 'edit-coupon'].includes(modal)}
        />
        <TablePlus
          hasLimit
          noOfPage={noOfPages}
          sizePerPage={sizePerPage}
          page={page}
          selectRow
          showAction={false}
          statusAction={false}
          count={count}
          tableData={data}
          tableColumns={columns}
          tableLoader={isLoading}
          onFilterResetChange={onFilterResetChange}
          filter={filter}
          sortBy={sortBy}
          sortType={sortType}
          SizePerPage=""
          // isSearch={true}
          // searchPlaceholder="Coupon Code "
        />
      </PageContainerWrapper>
    </>
  );
}
