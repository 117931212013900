export const text = {
  title: 'Email Templates',
  noteEmail: 'Email Templates module will list all emails on this page.',
  noteEdit: 'Administrator can edit any email template.',
  emailSubject: 'Email Subject',
  emailCode: 'Email Code',
  editEmailTemplates: 'Edit Email Templates',
  SMSTemplates: 'SMS Templates',
  shortCodes: 'Short Codes',
  smsShortCodes: 'SMS Short Codes',
  emailShortCodes: 'Email Short Codes',
  smsPurpose: 'SMS Purpose',
  smsBody: 'SMS Body',
  noteSmsOne: 'SMS Templates module will list all sms on this page.',
  noteSmsTwo: 'Administrator can edit any sms template.',
  editSmsTemplates: 'Edit SMS Templates',
};
