import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Localization } from '../../../../../services/Localization';
import {
  FormWrapper,
  FormElementWrapper,
  TextInput,
  CommonButton,
  Password,
  CheckBox,
  GlobalLoader,
  Select,
} from '../../../../index';
import { accessRoute } from '../../../../../routes/CustomerApp/terms';
import { initValues, validation } from './validation';

function AdminCreateProfile({ onSubmit, loading, formData }) {
  const { t } = useTranslation();
  const [agree, setAgree] = useState(true);
  return (
    <FormWrapper
      onSubmit={onSubmit}
      enableReinitialize={true}
      validation={validation}
      initialValues={initValues({ ...formData })}
      row={true}
      extraClassName="g-3"
      data={formData}
    >
      {(props) => (
        <>
          <FormElementWrapper
            md={6}
            label={t('text.common.fName')}
            mandatory={true}
            element={() => (
              <TextInput
                name="firstName"
                placeholder={t('text.common.enterFirstName')}
                formik={props}
                extraClassName="form-control-lg"
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label={t('text.common.lName')}
            mandatory={true}
            element={() => (
              <TextInput
                name="lastName"
                placeholder={t('text.common.enterLastName')}
                formik={props}
                extraClassName="form-control-lg"
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label={t('text.common.phoneNumber')}
            mandatory={true}
            element={() => (
              <div className="inputAndSelect">
                <div className="form-control-wrap ">
                  <div className="input-group flex-nowrap">
                    <div className="input-group-prepend w-25">
                      <Select
                        name="countryPhoneCode"
                        formik={props}
                        listService={Localization.CountryService}
                        listKeys={{
                          nameKey: 'phoneCode',
                          nameId: 'phoneCode',
                        }}
                        scope="activeCountry"
                        size="large"
                      />
                    </div>
                    <TextInput
                      extraClassName="form-control-lg"
                      name="phoneNumber"
                      placeholder={t('text.common.enterPhoneNumber')}
                      formik={props}
                    />
                  </div>
                </div>
              </div>
            )}
          />
          <FormElementWrapper
            md={6}
            mandatory={true}
            label={t('text.common.email')}
            element={() => (
              <TextInput
                name="email"
                placeholder={t('text.common.enterEmail')}
                formik={props}
                extraClassName="form-control-lg"
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label={t('text.common.password')}
            mandatory={true}
            element={() => (
              <Password
                name="password"
                type="password"
                placeholder={t('text.common.password')}
                formik={props}
                extraClassName="form-control-lg"
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label={t('text.common.cpassword')}
            mandatory={true}
            element={() => (
              <Password
                name="confirmPassword"
                type="password"
                placeholder={t('text.common.enterConfirmPassword')}
                formik={props}
                extraClassName="form-control-lg"
              />
            )}
          />
          <CheckBox
            formik={props}
            name="agree"
            element={[
              t('text.common.iAgree'),
              <Link to={accessRoute?.TERMS?.path} target="_blank">
                &nbsp;
                {t('text.common.termsService')}
              </Link>,
              ' & ',
              <Link to={accessRoute?.PRIVACY?.path} target="_blank">
                {t('text.common.privacyPolicy')}
              </Link>,
            ]}
            onClick={(e) => setAgree(!e.target.checked)}
          />
          <div className="form-group text-center">
            <CommonButton
              type="submit"
              className="btn btn-lg btn-primary"
              disabled={agree || loading}
            >
              {loading && (
                <GlobalLoader animation="grow" size="sm" variant="white" />
              )}
              {t('text.common.saveEndNext')}
            </CommonButton>
          </div>
        </>
      )}
    </FormWrapper>
  );
}

export default AdminCreateProfile;
