import i18next from 'i18next';
import {
  getInputSearchFilter,
  getMultiSelectSearchFilter,
} from '../../components';
import {
  checkValidData,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { accessRoute } from '../../routes/Dashboard';
import { getTupleStatusList } from '../index.service';

export const countryTableColumn = ({
  onFilterChange,
  filter,
  Action,
  Toggle,
  onSort,
  page,
  sizePerPage,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'name',
      filterDataIndex: ['countryName'],
      title: i18next.t('text.localization.country'),
      headerClasses: 'sorting',
      sorter: true,
      render: (col, row) => checkValidData(row?.name),
      onSort: (field, order) => onSort('name', order),
      ...getInputSearchFilter({
        dataIndex: 'countryName',
        placeholder: 'country',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'countryCode',
      title: i18next.t('text.common.countryCode'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('countryCode', order),
      render: (col, row) => checkValidData(row?.countryCode),
      ...getInputSearchFilter({
        dataIndex: 'countryCode',
        placeholder: 'country code',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'unit',
      title: i18next.t('text.localization.unit'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('unit', order),
      render: (col, row) => checkValidData(row?.unit),
      ...getInputSearchFilter({
        dataIndex: 'unit',
        onFilterChange,
        filter,
        name: 'unit',
      }),
    },
    {
      dataIndex: 'status',
      title: i18next.t('text.localization.status'),
      headerClasses: 'sorting',
      render: (cell, row) => {
        return <Toggle row={row} cell={cell} />;
      },
      sorter: true,
      onSort: (field, order) => onSort('status', order),
      ...getMultiSelectSearchFilter({
        dataIndex: 'status',
        onFilterChange,
        filter,
        name: 'status',
        listService: getTupleStatusList,
        mode: false,
      }),
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.localization.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => {
        return <Action row={row} cell={cell} />;
      },
    },
  ];
};
export const countryBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.localization.country'),
    },
  ];
};
