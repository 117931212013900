import { Card } from 'antd';
import React, { useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import {
  FormElementWrapper,
  FormWrapper,
  CommonButton,
  DatePicker,
} from '../../..';
import { disabledPastDate } from '../../../../utils';
import AvatarUpload from '../../../UIElements/AvatarUpload';
import notification from '../../../../utils/notifications';

import { initValues, validation } from './validation';

function StoreUpdateDocument({
  onSubmit,
  onCancel,
  loading,
  // values,
  profileData,
  documentDetails,
}) {
  const { t } = useTranslation();
  const [document, setDocument] = useState('');
  const uploadRef = useRef();

  const handleUploadClick = () => {
    uploadRef.current.upload.uploader.onClick();
  };

  return (
    <FormWrapper
      onSubmit={(e) => {
        if (document) {
          e.documentImage = document;
        } else if (!document && documentDetails.documentThumbUrl) {
          notification({
            type: 'error',
            message: t('validation.store.uploadNewDocument'),
          });
          return;
        } else {
          notification({
            type: 'error',
            message: t('validation.store.notUploadDocument'),
          });
          return;
        }
        e.documentId = documentDetails?.id;
        e.userId = profileData?.id;

        onSubmit(e);
      }}
      validation={() =>
        validation(documentDetails?.UserDocument || documentDetails)
      }
      initialValues={initValues({
        ...(documentDetails?.UserDocument || documentDetails),
      })}
      data={documentDetails}
      row={true}
      extraClassName="g-3"
      enableReinitialize={true}
    >
      {(props) => (
        <>
          <div className="profilePage">
            <Card className="card">
              <div className="profileBox_info mb-0">
                <AvatarUpload
                  setProfile={setDocument}
                  mediaFor="userDocument"
                  // mediaType="doc"
                  url={documentDetails?.documentThumbUrl}
                  ref={uploadRef}
                ></AvatarUpload>
                <CommonButton
                  onClick={handleUploadClick}
                  className="btn btn-outline-primary mx-auto mt-sm-3 mt-2  position-relative"
                >
                  {t('text.store.uploadPhoto')}
                </CommonButton>
              </div>
            </Card>
            {documentDetails?.isDateRequired === 1 && (
              <FormElementWrapper
                md={12}
                extraLabel={t('text.promoCode.expiryDate')}
                mandatory={true}
                element={() => (
                  <DatePicker
                    name="expireDate"
                    disabledDate={disabledPastDate}
                    className="ant-input ant-input-lg datePicker-ant"
                    placeholder={t('text.promoCode.expPlaceholder')}
                    formik={props}
                  />
                )}
              />
            )}
            {/* </FormGroup> */}
            <div className="d-flex align-items justify-content-end flex-wrap mt-sm-4 mt-3">
              <CommonButton
                variant="light"
                className="min-135 me-3"
                onClick={onCancel}
              >
                {t('text.common.cancel')}
              </CommonButton>
              <CommonButton
                type="submit"
                variant="primary"
                className="min-135"
                loading={loading}
              >
                {t('text.common.save')}
              </CommonButton>
            </div>
          </div>
        </>
      )}
    </FormWrapper>
  );
}

export default StoreUpdateDocument;
