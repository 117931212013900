import { HOME_PAGE_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';

export const homePageService = async () => {
  try {
    const payload = {
      ...HOME_PAGE_ENDPOINT.getHomePageList,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const editHomePageService = async ({ values }) => {
  try {
    const payload = {
      ...HOME_PAGE_ENDPOINT.editHomePage,
      bodyData: values,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
