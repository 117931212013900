// import { Outlet } from 'react-router-dom';
import {
  // BankDetails,
  // CompanyProfileOne,
  // CompanyProfileTwo,
  CreateProfile,
  // BusinessType,
  // SuccessRegisterMessage,
} from '../../views/index.view';
import CancelNcaService from '../../views/NcaProfile/cancelNca';

export default function route() {
  return [
    {
      path: accessRegisterRoute.REGISTER.path,
      private: false,
      name: 'Create Profile',
      key: accessRegisterRoute.REGISTER.path,
      belongsToSidebar: false,
      icon: accessRegisterRoute.REGISTER.icon,
      element: <CreateProfile />,
    },
    {
      path: accessRegisterRoute.CANCEL_NCA.path,
      private: true,
      name: 'cancel nca',
      key: accessRegisterRoute.CANCEL_NCA.path,
      belongsToSidebar: false,
      element: <CancelNcaService />,
    },
  ];
}

export const accessRegisterRoute = {
  REGISTER: {
    path: '/nca/register',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
  },
  CANCEL_NCA: {
    path: '/admin/cancel-plan-web-page',
  },
};

export const getRegisterRoutes = () => {
  return route();
};
