import React from 'react';
import { PageContainerWrapper, PhotoAddForm } from '../../../../../components';
import { useLocation, useNavigate } from 'react-router-dom';
import { Columns } from '../../../../../services/index.service';
import {
  decodeQueryData,
  modalNotification,
  navigateWithParam,
} from '../../../../../utils';
import { accessRoute } from '../../../../../routes/GoogleConnect';
import { addMediaService } from '../../../../../services/GoogleConnect/googleGMB.service';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function GMBPhotoAdd() {
  const navigate = useNavigate();
  const location = useLocation();
  const {t} =useTranslation()
  const params = decodeQueryData(location.search);
  const { accountId, name } = params;
  const breadcrumb = Columns.AddGoogleMediaBreadcrumb();
  const [loading, setLoading] = useState(false);
  const backPage = () => {
    navigateWithParam(
      {
        name: name,
        accountId: accountId,
      },
      navigate,
      accessRoute.GOOGLE_GMB_PHOTO.path
    );
  };

  const headerBtn = {
    extraButton: {
      name: t('text.common.back'),
      handler: () => {
        backPage();
      },
    },
  };
  const onSubmit = async (value) => {
    setLoading(true);
    try {
      let bodyData = {
        accountId: accountId,
        name: name,
        sourceUrl: value.postImage,
      };
      const response = await addMediaService({ bodyData });
      const { success, message } = response;
      if (success) {
        modalNotification({
          type: 'success',
          message,
        });
        backPage();
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="wide-md mx-auto">
        <PageContainerWrapper
          breadcrumb={breadcrumb}
          heading={t('text.googleConnect.mediaTitle')}
          btn={headerBtn}
          extraBtnClass="btn btn-outline-light bg-white"
        >
          <PhotoAddForm
            onSubmit={onSubmit}
            backPage={backPage}
            loading={loading}
          />
        </PageContainerWrapper>
      </div>
    </>
  );
}

export default GMBPhotoAdd;
