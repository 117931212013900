let url = '/v1/admin/provider';

export const PROVIDER_ENDPOINT = {
  getProviderList: {
    url,
    method: 'GET',
  },
  deleteProvider: {
    url,
    method: 'DELETE',
  },
  providerStatusUpdate: {
    url: `${url}/change-status`,
    method: 'PUT',
  },
  viewProvider: (id) => ({
    url: `/v1/admin/provider/${id}`,
    method: 'GET',
  }),
  getProviderDocument: () => ({
    url: `/v1/provider/document`,
    method: 'GET',
  }),
  addProvider: {
    url,
    method: 'POST',
  },
  getSlotList: {
    url: `/v1/admin/slot`,
    method: 'GET',
  },
  getProviderSlotList: {
    url: `/v1/admin/slot`,
    method: 'GET',
  },
  viewProviderProfile: (id) => ({
    url: `${url}/${id}`,
    method: 'GET',
  }),
  editProviderProfile: (id) => ({
    url: `${url}/${id}`,
    method: 'PUT',
  }),
  editProviderAvailability: (id) => ({
    url: `${url}/availability/${id}`,
    method: 'PUT',
  }),
  editProviderUnAvailabilityForm: (id) => ({
    url: `${url}/unavailability-restrict/${id}`,
    method: 'PUT',
  }),
  editProviderUnavailability: (id) => ({
    url: `${url}/unavailability/${id}`,
    method: 'PUT',
  }),
  getProviderAvailability: {
    url: `/v1/admin/provider/availability`,
    method: 'GET',
  },
  getProviderUnavailabilityBlock: {
    url: `/v1/provider/unavailability`,
    method: 'GET',
  },

  getProviderUnavailability: (id) => ({
    url: `${url}/unavailability/${id}`,
    method: 'GET',
  }),
  getProviderUnavailabilityForm: (id) => ({
    url: `${url}/unavailability-restrict/${id}`,
    method: 'GET',
  }),
  blockProviderUnavailability: (id) => ({
    url: `${url}/unavailability-block/${id}`,
    method: 'PUT',
  }),
  unblockProviderUnavailability: (id) => ({
    url: `${url}/unavailability-unblock/${id}`,
    method: 'PUT',
  }),
  editCompanyProfile: (id) => ({
    url: `/v1/provider/personal-detail/${id}`,
    method: 'PUT',
  }),
  getCompanyProfile: (id) => ({
    url: `/v1/provider/personal-detail/${id}`,
    method: 'GET',
  }),
  editBusinessDetails: (id) => ({
    url: `/v1/provider/business-type/${id}`,
    method: 'PUT',
  }),
  getBusinessDetails: (id) => ({
    url: `/v1/provider/business-type/${id}`,
    method: 'GET',
  }),
  editBankingDetails: (id) => ({
    url: `/v1/bank/${id}`,
    method: 'PUT',
  }),
  getBankingDetails: (id) => ({
    url: `${url}/bank-details/${id}`,
    method: 'GET',
  }),
  updateProviderStatus: (id) => ({
    url: `/v1/provider/services/change-status/${id}`,
    method: 'PUT',
  }),
  getProviderService: {
    url: `/v1/provider/services`,
    method: 'GET',
  },
  updateProviderDocument: (id) => ({
    url: `/v1/admin/provider/document/${id}`,
    method: 'PUT',
  }),
  createProviderDocument: {
    url: `/v1/admin/provider/document`,
    method: 'POST',
  },
  getUserDocument: () => ({
    url: `/v1/provider/user-document`,
    method: 'GET',
  }),
  getLessonByAdminDetails: () => ({
    url: `v1/admin/provider/lesson/procedure`,
    method: 'GET',
  }),
  getLessonBySubAdminDetails: () => ({
    url: `v1/admin/sub-admin/lesson/procedure`,
    method: 'GET',
  }),
  updateInvoiceMessagePermission: {
    url: `/v1/admin/provider/invoice-message`,
    method: 'PUT',
  },
};
