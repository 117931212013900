export const INDUSTRY_ENDPOINT = {
  industry: {
    url: '/v1/admin/industry',
    method: 'GET',
  },
  addIndustry: {
    url: '/v1/admin/industry',
    method: 'POST',
  },
  deleteIndustry:{
    url: `/v1/admin/industry`,
    method: 'DELETE'
  },
  getIndustryById: (id) => ({
    url: `/v1/admin/industry/${id}`,
    method: 'GET'
  }),
  updateIndustryStatusId:{
    url: `/v1/admin/industry/change-status`,
    method: 'PUT'
  },
  updateIndustryId: (id) => ({
    url: `/v1/admin/industry/${id}`,
    method: 'PUT'
  })
};
