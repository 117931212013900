import React, { useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
  PageContainerWrapper,
  ServiceSubcategoryForm,
} from '../../../../../../components';
import { backToPage } from '../../../../../../utils';
import { useTranslation } from 'react-i18next';
import { formSubmit } from '../../../../../../hooks/useFormSubmit.hook';
import {
  addBreadcrumbServiceSubcategory,
  addNCABreadcrumbServiceSubcategory,
} from '../../../../../../services/Columns';
import { accessRoute } from '../../../../../../routes/PriceManagement';
import { accessRoute as NCARoute } from '../../../../../../routes/AdminSetting';
import {
  addSubServiceCategoryService,
  ListSubServiceCategoryService,
} from '../../../../../../services/ManageServices/serviceSubcategory.service';
import useTable from '../../../../../../hooks/useTable.hook';
import { addNCASubCategoryService } from '../../../../../../services/NCA/ServiceCategory/serviceCategory.service';
import NoteFooter from '../../../../../../components/Common/NoteFooter';

function AddSubServiceCategory({ ncaEdit = false, ncaId, userId }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();

  const url =
    ncaEdit &&
    `${generatePath(NCARoute?.NCA_VIEW_SUB_CATEGORY.path, {
      id: ncaId,
      userId,
    })}`;

  const redirectUrl = ncaEdit
    ? url
    : generatePath(accessRoute.LIST_SUB_SERVICE_CATEGORY.path, {
      id,
    });

  const ncaEditUrl =
    ncaEdit &&
    `${generatePath(NCARoute?.EDIT_NCA.path, {
      id: userId,
    })}`;
  const breadcrumb = ncaEdit
    ? addNCABreadcrumbServiceSubcategory({ url, ncaEditUrl })
    : addBreadcrumbServiceSubcategory({ id });

  let extraQuery = {
    serviceCategoryId: id,
  };
  const [, tableProps] = useTable(
    ListSubServiceCategoryService,
    extraQuery,
    'category'
  );
  const { count, extraData } = tableProps;
  async function onSubmit(values) {
    values.categoryId = id;
    values.isAllowQty =
      values?.servicePrice === 'free' ? 0 : values?.isAllowQty;
    formSubmit({
      values: {
        ...(userId && { userId }),
        ...values,
      },
      service: ncaEdit
        ? addNCASubCategoryService
        : addSubServiceCategoryService,
      navigate,
      redirectUrl,
      setIsLoading,
    });
  }

  return (
    <div className="wide-md mx-auto">
      <PageContainerWrapper
        breadcrumb={breadcrumb}
        btnArray={['extraButton']}
        extraBtnText={t('text.common.back')}
        extraBtnClass="btn btn-outline-light bg-white"
        onExtraButtonHandleShow={() => backToPage(navigate, redirectUrl)}
        heading={`${t('text.manageServices.addSubCategory')} ( ${extraData} )`}
      >
        <ServiceSubcategoryForm
          onSubmit={onSubmit}
          isLoading={isLoading}
          backToPage={() => backToPage(navigate, redirectUrl)}
          submitText={t('text.common.add')}
          count={() => {
            return Array.from(Array((count || 0) + 1)?.keys());
          }}
          serviceCategoryId={id}
        />
        <NoteFooter title={t('text.common.notes')}>
          <li>{t('text.manageServices.addEditSubCategoryNoteOne')}</li>
        </NoteFooter>
      </PageContainerWrapper>
    </div>
  );
}

export default AddSubServiceCategory;
