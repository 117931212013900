import { t } from 'i18next';
import React from 'react';
import { useParams } from 'react-router';
import {
  FormWrapper,
  FormElementWrapper,
  CommonButton,
  Password,
} from '../../../index';
import { initValues, validation } from './validation';

function ResetPasswordForm({ onSubmit, loading }) {
  const { token } = useParams();

  return (
    <FormWrapper
      onSubmit={(values) => {
        onSubmit({ ...values, token });
      }}
      validation={validation}
      initialValues={initValues()}
      extraClassName="g-3"
      row={true}
    >
      {(props) => {
        return (
          <>
            <FormElementWrapper
              extraLabel={t('text.common.password')}
              md={12}
              element={() => (
                <Password
                  name="newPassword"
                  placeholder="Enter password"
                  formik={props}
                />
              )}
            />

            <FormElementWrapper
              extraLabel={t('text.common.cpassword')}
              md={12}
              element={() => (
                <Password
                  name="confirmPassword"
                  placeholder="Enter confirm password"
                  formik={props}
                />
              )}
            />
            <div className="authPage_top_reset">
              <CommonButton
                type="submit"
                className="btn btn-lg btn-primary w-100"
                loading={loading}
              >
                {t('text.common.submit')}
              </CommonButton>
            </div>
          </>
        );
      }}
    </FormWrapper>
  );
}

export default ResetPasswordForm;
