import React from 'react';
import { useSelector } from 'react-redux';
import { Localization } from '../services/Localization/index';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { GOOGLE_MAPS_API_KEY } from '../config/app.config';

function useLocation() {
  const [state, setState] = React.useState({
    countryList: [],
    stateList: [],
    cityList: [],
    loading: null,
    lat: undefined,
    lng: undefined,
    zipCode: '',
  });
  const [stateLess] = React.useState({
    country: undefined,
    locality: undefined,
    city: undefined,
  });
  const googleMapApiKey = useSelector((state) => state?.googleMap?.apiKey);
  const { cityList, stateList, countryList, lng, lat, zipCode } = state;
  // const { cityList, stateList, countryList, lng, lat } = state;
  const { city, locality, country } = stateLess;
  const [addresState, setAddresState] = React.useState({});

  React.useEffect(() => {
    getCountryList();
  }, []);

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: googleMapApiKey || GOOGLE_MAPS_API_KEY,
    options: {},
  });

  async function getCountryList(shouldSaved = false) {
    try {
      const res = await Localization.CountryListService({
        queryParams: { scope: 'activeCountry' },
        type: 'all',
      });
      if (res && !shouldSaved) {
        setState({ countryList: [...res], stateList: [], cityList: [] });
        return true;
      }
      return res || [];
    } catch (err) {
      console.log(err);
    }
  }

  async function onCountrySelected(query = {}, type) {
    try {
      if (type === 'form' && typeof query === 'string') {
        const country = countryList.find(
          (e) => e?.name?.toLowerCase() === query?.toLowerCase()
        );
        if (country?.id) {
          return await getStateList({
            queryParams: { countryId: country?.id },
          });
        }
      } else if (type === 'form' && typeof query === 'number') {
        return await getStateList({ queryParams: { countryId: query } });
      }
    } catch (err) {
      console.log(err);
    }
  }
  async function onStateSelected(query, type) {
    try {
      if (type === 'form' && typeof query === 'string') {
        const state = stateList.find(
          (e) => e?.name?.toLowerCase() === query?.toLowerCase()
        );
        if (state?.id) {
          return await getCityList({ queryParams: { stateId: state?.id } });
        }
      } else if (type === 'form' && typeof query === 'number') {
        return await getCityList({ queryParams: { stateId: query } });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function getStateList({ queryParams = {} }, shouldSaved = false) {
    try {
      const res = await Localization.getStateService({
        queryParams: { ...queryParams, scope: 'activeState' },
        type: 'all',
      });
      if (res && !shouldSaved) {
        setState({ ...state, stateList: [...res], cityList: [] });
        return res;
      }
      return res || [];
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  function getCityId(name) {
    try {
      return cityList?.find((e) => e.name === name)?.id;
    } catch (err) {
      console.log(err);
    }
  }

  function getStateId(name, stateList) {
    try {
      return stateList?.find((e) => e.name === name)?.id;
    } catch (err) {
      console.log(err);
    }
  }

  async function getCityList({ queryParams = {} }, shouldSaved = false) {
    try {
      const res = await Localization.ListCityService({
        queryParams: { ...queryParams, scope: 'activeCity' },
        type: 'all',
      });
      if (res && !shouldSaved) {
        setState({ ...state, cityList: [...res] });
        return res;
      }
      return res || [];
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  function getPredictionLocation() {
    return placePredictions;
  }

  function onPlacePredictions(e) {
    getPlacePredictions({ input: e });
  }

  async function onPlaceSelect(e, setFieldValue, keys, isLocationName) {
    if (placePredictions?.length) {
      const id = placePredictions.find(
        (ele) => ele?.description === e
      )?.place_id;

      if (id) {
        const place = await new Promise((resolve, reject) => {
          placesService?.getDetails({ placeId: id }, (placeDetails) => {
            if (placeDetails) {
              resolve(placeDetails);
            } else {
              reject(null);
            }
          });
        });

        var geocoder = new window.google.maps.Geocoder();
        const location = await new Promise((resolve, reject) => {
          geocoder.geocode(
            { placeId: place?.place_id },
            function (results, status) {
              if (status === window.google.maps.GeocoderStatus.OK) {
                resolve({
                  lat: results[0]?.geometry?.location?.lat(),
                  lng: results[0]?.geometry?.location?.lng(),
                });
              } else {
                reject({});
              }
            }
          );
        });

        const addressContainer = {};
        for (const component of place.address_components) {
          // @ts-ignore remove once typings fixed
          const componentType = component.types[0];
          switch (componentType) {
            case 'street_number':
              addressContainer['address1'] = `${component.long_name} ${
                addressContainer?.address1 || ''
              }`;
              break;
            case 'route':
              addressContainer['address1'] += component.short_name;
              break;
            case 'postal_code':
              addressContainer['postal_code'] = `${component.long_name}${
                addressContainer?.postcode || ''
              }`;
              break;
            case 'postal_code_suffix':
              addressContainer[
                'postal_code_suffix'
              ] = `${addressContainer?.postcode}-${component.long_name}`;
              break;

            case 'locality':
              addressContainer['locality'] = component.long_name;
              break;
            case 'administrative_area_level_1':
              addressContainer['administrative_area_level_1_code'] =
                component.short_name;
              addressContainer['administrative_area_level_1'] =
                component?.long_name;
              break;
            case 'country':
              addressContainer['country'] = component.long_name;
              addressContainer['country_code'] = component.short_name;
              break;
            default:
              break;
          }
        }

        let getAddressType =
          place.address_components?.length &&
          place.address_components.map((e) => e.types[0]);

        addresState &&
          Object.keys(addresState)?.length &&
          Object.keys(addresState).forEach((item) => {
            if (getAddressType?.length && !getAddressType.includes(item)) {
              addressContainer[item] = undefined;
            }
          });

        const localityList = {};
        const localityId = {};
        /*******Load country first***** */
        const countryL = await getCountryList(true);
        localityList.countryList = countryL;

        if (isLocationName && keys?.countryKey && addressContainer?.country) {
          setFieldValue?.(keys?.countryKey, addressContainer?.country);
        }
        if (
          isLocationName &&
          keys?.stateKey &&
          addressContainer?.administrative_area_level_1
        ) {
          setFieldValue?.(
            keys?.stateKey,
            addressContainer?.administrative_area_level_1
          );
        }
        if (isLocationName && keys?.cityKey) {
          setFieldValue?.(
            keys?.cityKey,
            addressContainer?.locality ? addressContainer?.locality : ''
          );
        }

        const cid = countryL.find(
          (e) =>
            e?.name?.toLowerCase() === addressContainer?.country?.toLowerCase()
        )?.id;
        const phoneCode = countryL.find(
          (e) =>
            e?.name?.toLowerCase() === addressContainer?.country?.toLowerCase()
        )?.phoneCode;
        localityId.country = cid;

        if (cid) {
          /****
           * if country found then load state
           */

          if (!isLocationName && keys?.countryKey) {
            setFieldValue?.(keys?.countryKey, cid);
            if (keys?.countryPhoneCodeKey) {
              setFieldValue?.(keys?.countryPhoneCodeKey, phoneCode);
            }
          }

          const stateL = await getStateList(
            { queryParams: { countryId: cid } },
            true
          );
          localityList.stateList = stateL;

          const sid = stateL.find(
            (e) =>
              e?.name?.toLowerCase() ===
                addressContainer?.administrative_area_level_1?.toLowerCase() ||
              e?.stateCode?.toLowerCase() ===
                addressContainer?.administrative_area_level_1_code?.toLowerCase()
          )?.id;
          localityId.locality = sid;

          if (sid) {
            /******
             * if state found then load country
             */
            if (!isLocationName && keys?.stateKey) {
              setFieldValue?.(keys?.stateKey, sid);
            }

            const cityL = await getCityList(
              { queryParams: { stateId: sid } },
              true
            );
            localityList.cityList = cityL;
            const cityId = cityL.find(
              (e) =>
                e?.name?.toLowerCase() ===
                addressContainer?.locality?.toLowerCase()
            )?.id;

            if (cityId) {
              if (!isLocationName && keys?.cityKey) {
                setFieldValue?.(keys?.cityKey, cityId);
              }
            } else {
              localityList.cityList = [];
              if (!isLocationName) setFieldValue?.(keys?.cityKey, null);
            }
            localityId.city = cityId;
          } else {
            localityList.stateList = [];
            if (!isLocationName) setFieldValue?.(keys?.stateKey, null);
          }
        } else {
          if (!isLocationName && keys) {
            setFieldValue?.(keys?.countryKey, null);
            setFieldValue?.(keys?.countryPhoneCodeKey, 1);
            setFieldValue?.(keys?.stateKey, null);
            setFieldValue?.(keys?.cityKey, null);
          }
          if (keys) {
            setFieldValue?.(keys?.zipKey, '');
          }
        }

        if (keys?.cityKey) {
          localityId.city = addressContainer?.locality;
        }

        if (keys?.zipKey && addressContainer?.postcode) {
          setFieldValue?.(keys?.zipKey, addressContainer?.postcode);
          localityList.zipCode = addressContainer?.postcode;
        } else if (keys?.zipKey && addressContainer?.postal_code) {
          setFieldValue?.(keys?.zipKey, addressContainer?.postal_code);
          localityList.zipCode = addressContainer?.postal_code;
        } else {
          if (keys) setFieldValue?.(keys?.zipKey, '');
          localityList.zipCode = '';
        }
        setAddresState({ ...addressContainer });

        setState({
          ...state,
          ...localityList,
          ...location,
          ...addressContainer,
        });
        return addressContainer;
      }
    }
  }

  const handleResetLocation = () => {
    setState({
      ...state,
      lat: undefined,
      lng: undefined,
    });
  };

  const method = {
    onCountrySelected,
    onStateSelected,
    getCityId,
    getStateId,
    getStateList,
    getPredictionLocation,
    onPlaceSelect,
    onPlacePredictions,
    handleResetLocation,
    setUseLocationState: (values) =>
      setState((prevState) => ({
        ...prevState,
        ...values,
      })),
  };
  const data = {
    stateList,
    countryList,
    cityList,
    zipCode: state?.postal_code,
    isPlacePredictionsLoading,
    city,
    locality,
    country,
    lat,
    lng,
    postcode: state?.postal_code,
    ...state,
  };

  return [data, method];
}
export default useLocation;
