import loadable from '@loadable/component';
import FullPageLoader from '../../components/UIElements/Loader/fullPageLoader';

export const ListAdmin = loadable(() => import('./Admin/Listing'), {
  fallback: <FullPageLoader />,
});
export const EditAdmin = loadable(() => import('./Admin/Edit'), {
  fallback: <FullPageLoader />,
});

export const AssignRole = loadable(() => import('./Roles/Assign'), {
  fallback: <FullPageLoader />,
});

export const ListRole = loadable(() => import('./Roles/Listing'), {
  fallback: <FullPageLoader />,
});

export const AddProvider = loadable(() => import('./Providers/Create'), {
  fallback: <FullPageLoader />,
});
export const EditProviderProfile = loadable(() => import('./Providers/Edit'), {
  fallback: <FullPageLoader />,
});
export const ListProvider = loadable(() => import('./Providers/Listing'), {
  fallback: <FullPageLoader />,
});
export const DocumentList = loadable(() => import('./Providers/Documents'), {
  fallback: <FullPageLoader />,
});
export const EditAvailability = loadable(
  () => import('./Providers/Edit/Availability'),
  {
    fallback: <FullPageLoader />,
  }
);
export const ListDocument = loadable(() => import('./ManageDocument/Listing'), {
  fallback: <FullPageLoader />,
});

export const AssignModules = loadable(() => import('./Roles/Assign'), {
  fallback: <FullPageLoader />,
});
