import { OPERATING_PROCEDURE_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';

export const getLessonListService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...OPERATING_PROCEDURE_ENDPOINT.getLessonList,
      queryParams,
    };

    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }

    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const deleteLessonService = async ({ bodyData }) => {
  try {
    const payload = {
      ...OPERATING_PROCEDURE_ENDPOINT.deleteLesson,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const updateLessonStatus = async (bodyData) => {
  try {
    const payload = {
      ...OPERATING_PROCEDURE_ENDPOINT.changeStatus,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const addLessonService = async ({ bodyData }) => {
  try {
    const payload = {
      ...OPERATING_PROCEDURE_ENDPOINT.addLesson,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const lessonDetailsService = async (id) => {
  try {
    const payload = {
      ...OPERATING_PROCEDURE_ENDPOINT.lessonDetails(id),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const updateDetailsService = async ({ id, bodyData }) => {
  try {
    const payload = {
      ...OPERATING_PROCEDURE_ENDPOINT.lessonUpdate(id),
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getQuestionService = async ({ queryParams }) => {
  try {
    const payload = {
      ...OPERATING_PROCEDURE_ENDPOINT.getQuestions,
      queryParams,
    };
    const res = await APIRequest(payload);

    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const deleteQuestionService = async (id) => {
  try {
    const payload = {
      ...OPERATING_PROCEDURE_ENDPOINT.deleteQuestions(id),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const addQuestionService = async ({ bodyData }) => {
  try {
    const payload = {
      ...OPERATING_PROCEDURE_ENDPOINT.addQuestions,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const questionDetailsService = async (id) => {
  try {
    const payload = {
      ...OPERATING_PROCEDURE_ENDPOINT.questionDetails(id),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getQuestionNotificationService = async ({ id }) => {
  try {
    const payload = {
      ...OPERATING_PROCEDURE_ENDPOINT.questionNotification(id),
    };
    const res = await APIRequest(payload);

    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const updateQuestionsService = async ({ id, bodyData }) => {
  try {
    const payload = {
      ...OPERATING_PROCEDURE_ENDPOINT.questionUpdate(id),
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
