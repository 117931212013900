export const FAQ_ENDPOINT = {
  getFaqApi: {
    url: '/v1/faq',
    method: 'GET',
  },
  addFaq: {
    url: '/v1/admin/faq',
    method: 'POST',
  },
  deleteFaq: {
    url: `/v1/admin/faq`,
    method: 'DELETE',
  },
  editFaq: (id) => ({
    url: `/v1/admin/faq/${id}`,
    method: 'PUT',
  }),
  getFaqById: (id) => ({
    url: `/v1/admin/faq/${id}`,
    method: 'GET',
  }),
  updateFaqStatusId: (id) => ({
    url: `/v1/admin/faq/change-status`,
    method: 'PUT',
  }),
};
