import { accessRoute } from '../../routes/Dashboard';
import i18next from 'i18next';
import moment from 'moment';
import { getFullName } from '../../utils/text.util';
import { minutes_to_hhmm } from '../../utils';

export const siteStatisticsBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.common.siteStatics'),
    },
  ];
};

export const JobsData = (jobData) => {
  let newCompleteArr = [
    { x: moment().subtract(2, 'months').format('MMM YYYY'), y: 0 },
    { x: moment().subtract(1, 'months').format('MMM YYYY'), y: 0 },
    { x: moment().format('MMM YYYY'), y: 0 },
  ];
  let newCancelledArr = [
    { x: moment().subtract(2, 'months').format('MMM YYYY'), y: 0 },
    { x: moment().subtract(1, 'months').format('MMM YYYY'), y: 0 },
    { x: moment().format('MMM YYYY'), y: 0 },
  ];
  // eslint-disable-next-line array-callback-return
  jobData?.completeJobs?.map((item) => {
    let newElement = newCompleteArr?.find((val, index) => {
      return (
        val.x ===
        moment()
          .month(item?.month - 1)
          .format('MMM YYYY')
      );
    });
    let index = newCompleteArr?.findIndex((ele) => ele?.x === newElement?.x);
    newCompleteArr?.splice(index, 1, {
      x: newElement?.x || '',
      y: item?.completeJobs || 0,
    });
  });

  jobData?.cancelledJobs?.map((item) => {
    let newElement = newCancelledArr?.find((val, index) => {
      return (
        val.x ===
        moment()
          .month(item?.month - 1)
          .format('MMM YYYY')
      );
    });
    let index = newCancelledArr?.findIndex((ele) => ele?.x === newElement?.x);
    newCancelledArr?.splice(index, 1, {
      x: newElement?.x || '',
      y: item?.cancelledJobs || 0,
    });
  });

  let data = {};
  data = {
    datasets: [
      {
        label: i18next.t('text.siteStatistics.finishedJobs'),
        data: newCompleteArr,
        backgroundColor: ['#1EE0AC'],
        borderWidth: 0,
        hoverOffset: 4,
      },
      {
        label: i18next.t('text.siteStatistics.cancelledJobs'),
        data: newCancelledArr,
        backgroundColor: ['#E85347'],
        borderWidth: 0,
        hoverOffset: 4,
      },
    ],
  };
  return data;
};

export const UsersData = (usersData) => {
  let newProviderArr = [
    { x: moment().subtract(2, 'months').format('MMM YYYY'), y: 0 },
    { x: moment().subtract(1, 'months').format('MMM YYYY'), y: 0 },
    { x: moment().format('MMM YYYY'), y: 0 },
  ];
  let newUserArr = [
    { x: moment().subtract(2, 'months').format('MMM YYYY'), y: 0 },
    { x: moment().subtract(1, 'months').format('MMM YYYY'), y: 0 },
    { x: moment().format('MMM YYYY'), y: 0 },
  ];
  // eslint-disable-next-line array-callback-return
  usersData?.providers?.map((item) => {
    let newElement = newProviderArr?.find((val, index) => {
      return (
        val?.x ===
        moment()
          .month(item?.month - 1)
          .format('MMM YYYY')
      );
    });
    let index = newProviderArr?.findIndex((ele) => ele?.x === newElement?.x);
    newProviderArr?.splice(index, 1, {
      x: newElement?.x || '',
      y: item?.totalProviders || 0,
    });
  });
  // eslint-disable-next-line array-callback-return
  usersData?.users?.map((item) => {
    let newElement = newUserArr?.find((val, index) => {
      return (
        val?.x ===
        moment()
          .month(item?.month - 1)
          .format('MMM YYYY')
      );
    });
    let index = newUserArr?.findIndex((ele) => ele?.x === newElement?.x);
    newUserArr?.splice(index, 1, {
      x: newElement?.x || '',
      y: item?.totalUsers || 0,
    });
  });
  return {
    datasets: [
      {
        label: i18next.t('text.siteStatistics.providers'),
        backgroundColor: ['#364A63'],
        borderWidth: 0,
        hoverOffset: 4,
        data: newProviderArr,
      },
      {
        label: i18next.t('text.siteStatistics.users'),
        data: newUserArr,
        backgroundColor: ['#09C2DE'],
        borderWidth: 0,
        hoverOffset: 4,
      },
    ],
  };
};

export const ProvidersCountData = (providerDatas) => {
  let countData = {};
  countData = {
    labels: [
      i18next.t('text.siteStatistics.activeProviders'),
      i18next.t('text.siteStatistics.pendingProviders'),
    ],
    datasets: [
      {
        label: i18next.t('text.siteStatistics.providerCount'),
        data: [providerDatas?.activeProvider, providerDatas?.inactiveProvider],
        backgroundColor: ['#1EE0AC', '#E85347'],
        borderWidth: 0,
        hoverOffset: 4,
        cutout: 90,
      },
    ],
  };
  return countData;
};

export const JobsCountData = (jobsDatas) => {
  let countData = {};
  countData = {
    labels: [
      i18next.t('text.siteStatistics.completedJobs'),
      i18next.t('text.siteStatistics.cancelledJobs'),
      i18next.t('text.siteStatistics.onGoingJobs'),
    ],
    datasets: [
      {
        label: i18next.t('text.siteStatistics.countData'),
        data: [
          jobsDatas?.totalCompleteJobs,
          jobsDatas?.totalCancelledJobs,
          jobsDatas?.totalOngoingJobs,
        ],
        backgroundColor: ['#1EE0AC', '#E85347', '#09C2DE'],
        borderWidth: 0,
        hoverOffset: 4,
        cutout: 90,
      },
    ],
  };
  return countData;
};

export const chartOptionsData = () => {
  let optionData = {};
  optionData = {
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
    },
  };
  return optionData;
};

export const ProviderLogData = (loginData) => {
  let providerLog = {};
  providerLog = {
    labels: loginData?.loginHours?.map((item) => {
      return getFullName(item?.User?.firstName, item?.User?.lastName);
    }),
    datasets: [
      {
        label: i18next.t('text.siteStatistics.providerLogReport'),

        data: loginData?.loginHours?.map((item) => {
          return minutes_to_hhmm(item?.totalOnlineTime);
        }),
        backgroundColor: ['#09C2DE'],
        borderWidth: 0,
        hoverOffset: 4,
        maintainAspectRatio: false,
      },
    ],
  };
  return providerLog;
};

export const providerDetails = (yearAndMonthData) => {
  let testData = {};
  testData = {
    jobsCount: yearAndMonthData?.totalProvider,
    Today: yearAndMonthData?.todayProvider,
    thisMonth: yearAndMonthData?.monthProvide,
    thisYear: yearAndMonthData?.yearProvider,
  };
  return testData;
};

export const jobDetails = (yearAndMonthData) => {
  let testData = {};
  testData = {
    jobsCount: yearAndMonthData?.totalJobs,
    Today: yearAndMonthData?.todayJobs,
    thisMonth: yearAndMonthData?.monthJobs,
    thisYear: yearAndMonthData?.yearJobs,
  };
  return testData;
};
