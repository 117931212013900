import React from 'react';
import Modal from 'react-bootstrap/Modal';

function ModalComponent({
  title,
  children,
  show,
  modalExtraClass = '',
  extraClassName = '',
  extraTitleClassName = '',
  extraBodyClassName = '',
  // onHandleVisible,
  scrollable = false,
  onHandleCancel,
  closeButton = true,
  size,
  onHeaderIconClick,
  closable,
  icon,
  subTitle,
}) {
  const ref = React.useRef(null);

  function onIconClick() {}
  return (
    <>
      <Modal
        className={modalExtraClass}
        show={show}
        id="kent-modal"
        ref={ref}
        onHide={onHandleCancel}
        backdrop="static"
        keyboard={false}
        enforceFocus={false}
        dialogClassName={extraClassName}
        size={size}
        centered
        closable
        scrollable={scrollable}
      >
        <Modal.Header
          className={`${extraTitleClassName}`}
          closeButton={closable === false ? closable : closeButton}
        >
          {title ? (
            <div>
              <Modal.Title as="h5">
                {icon && (
                  <em
                    onClick={onHeaderIconClick || onIconClick}
                    className={` ${icon} me-2 `}
                  ></em>
                )}
                {title}
              </Modal.Title>
              {subTitle && <p className="mb-0 mt-2">{subTitle}</p>}
            </div>
          ) : (
            ''
          )}
        </Modal.Header>
        <Modal.Body className={`${extraBodyClassName}`}>{children}</Modal.Body>
      </Modal>
    </>
  );
}

export default ModalComponent;
