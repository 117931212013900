export const text = {
  title: 'Optional Items',
  addOptionalItem: 'Add Optional Items',
  editOptionalItem: 'Edit Optional Items',
  itemName: 'Optional Item Name',
  itemType: 'Optional Item Type',
  selectItemType: 'Select Item Type',
  areYouSureDeleteTag: 'Are you sure to delete this item?',
  financingBank: 'Financing Bank',
  addFinancingBank: 'Add Financing Bank',
  editFinancingBank: 'Edit Financing Bank',
  bankName: 'Bank Name',
  url: 'URL',
  description: 'Description',
  selectLogo: 'Select Bank Logo',
  bankLogo: 'Bank Logo',
  enterBankName: 'Enter Bank Name',
  enterBankUrl: 'Enter Bank Url',
  bankUrl: 'Bank Url',
  enterBankDesc: 'Enter description',
  bankDesc: 'Bank Description',
  financingOption: 'Financing Option',
  addFinancingOption: 'Add Financing Option',
  editFinancingOption: 'Edit Financing Option',
  minLoadAmount: 'Min Loan Amount',
  paymentFactor: 'Payment Factor',
  dealerFee: 'Dealer Fee',
  financeTitle: 'Finance Title',
  termInMonth: 'Terms In Month',
  minLoanAmount: 'Minimum Loan Amount',
  enterTermInMonth: 'Enter terms in month',
  enterMinLoanAmount: 'Enter minimum loan amount',
  enterPaymentFactor: 'Enter payment factor',
  enterDealerFee: 'Enter dealer fee',
  enterFinanceTitle: 'Enter finance title',
  quoteManagement: 'Quote Management',
  addQuote: 'Add Quote',
  editQuote: 'Edit Quote',
  equipmentMaterialIAQ: 'Equipment, Material, IAQ',
  quoteType: 'Quote Type',
  systemBTUCapacity: 'System BTU/Capacity',
  sizeDescription: 'Size Description',
  minimumBlowerDrive: 'Minimum Blower Drive',
  materialListType: 'Material List Type',
  materialName: 'Material Name',
  materialAdditionalCostCustomer: 'Material Additional Cost to Customer',
  materialExpense: 'Material Expense',
  materialAutoFill: 'Material Auto Fill',
  optionStatus: 'Option Status',
  gasEfficiency: 'Gas Efficiency',
  oldAHRIReferenceNumber: 'Old AHRI Reference Number',
  ahriType: 'AHRI Type',
  ahriCertifiedRatings:
    'AHRI Certified Ratings - Cooling Capacity (A2) - Single or High Stage (95F), btuh',
  ahriCertifiedRatingsEER:
    'AHRI Certified Ratings - EER (A2) - Single or High Stage (95F)',
  ahriCertifiedRatingsIEER: 'AHRI Certified Ratings - IEER',
  ahriCertifiedRatingsSEER: 'AHRI Certified Ratings - SEER',
  systemPartWarranty: 'System Part Warranty',
  systemHeatExchangerPartWarranty: 'System Heat Exchanger Part Warranty',
  quoteTypeDescription: 'Quote Type Description',
  miscRebate: 'Misc Rebate',
  availabilityStatus: 'Availability Status',
  areYouSureDeleteQuoteManagement: 'you want to delete this Quote',
  allQuoteImages: 'All Quote Images',
  quoteTypeName: 'Quote Type Name',
  areYouSureDeleteQuoteImage: 'Are you sure to delete this quote image?',
  heading: 'Service Category',
  productImageOne: 'Product Image 1 (Default Product Image)',
  productImageTwo: 'Product Image 2',
  productImageThree: 'Product Image 3',
  productImageFour: 'Product Image 4',
  productImageFive: 'Product Image 5',
  EnterEquipmentMaterialIAQ: 'Enter equipment, material, IAQ',
  EnterQuoteType: 'Enter quote type',
  enterSystemBTUCapacity: 'Enter system BTU/Capacity',
  enterSizeDescription: 'Enter size description',
  enterMinimumBlowerDrive: 'Enter minimum blower drive',
  enterMaterialListType: 'Enter material list type',
  enterMaterialName: 'Enter material name',
  enterMaterialAdditionalCostCustomer:
    'Enter material additional cost to customer',
  enterMaterialExpense: 'Enter material expense',
  enterMaterialAutoFill: 'Enter material auto fill',
  selectOptionStatus: 'Select option status',
  enterGasEfficiency: 'Enter gas efficiency',
  programmableStat: 'Programmable Stat',
  enterProgrammableStat: 'Enter programmable stat',
  otherRebates: 'Other Rebates',
  enterOtherRebates: 'Enter other rebates',
  ahriCertifiedReferenceNumber: 'AHRI Certified Reference Number',
  enterAhriCertifiedReferenceNumber: 'Enter AHRI certified reference number',
  enterOldAHRIReferenceNumber: 'Enter old AHRI reference number',
  enterAHRIType: 'Enter AHRI type',
  outdoorUnitBrandName: 'Outdoor Unit - Brand Name',
  enterOutdoorUnitBrandName: 'Enter outdoor unit - brand name',
  outdoorUnitModelNumber:
    'Outdoor Unit - Model Number (Condenser or Single Package)',
  enterOutdoorUnitModelNumber:
    'Enter outdoor unit - model number (Condenser or Single Package)',
  indoorUnitBrandName: 'Indoor Unit - Brand Name',
  enterIndoorUnitBrandName: 'Enter indoor unit brand name',
  indoorUnitModelNumber:
    'Indoor Unit - Model Number (Evaporator and/or Air Handler)',
  enterIndoorUnitModelNumber:
    'Enter indoor unit - model number (evaporator and/or air handler)',
  furnaceModelNumber: 'Furnace - Model Number',
  enterFurnaceModelNumber: 'Enter furnace - model number',
  enterAHRICertifiedRatings:
    'Enter AHRI certified ratings - cooling capacity (A2) - single or high stage (95F),btuh',
  enterAHRICertifiedRatingsEER:
    'Enter AHRI certified ratings - EER (A2) - single or high stage (95F)',
  enterAHRICertifiedRatingsIEER: 'Enter AHRI certified ratings - IEER',
  enterAHRICertifiedRatingsSEER: 'Enter AHRI certified ratings - SEER',
  indoorFullLoadAir: 'Indoor Full-Load Air Volume Rate (A2 SCFM)',
  enterIndoorFullLoadAir: 'Enter indoor full-load air volume rate (A2 SCFM)',
  indoorCoolingIntermediateAirVolume:
    'Indoor Cooling Intermediate Air Volume Rate (Ev SCFM)',
  enterIndoorCoolingIntermediateAirVolume:
    'Enter indoor cooling intermediate air volume rate (Ev SCFM)',
  indoorCoolingMinimumAirVolumeRate:
    'Indoor Cooling Minimum Air Volume Rate (B1 SCFM)',
  enterIndoorCoolingMinimumAirVolumeRate:
    'Enter indoor cooling minimum air volume rate (B1 SCFM)',
  designatedTestedCombination: 'Designated Tested Combination',
  enterDesignatedTestedCombination: 'Enter designated tested combination',
  estimatedNationalAverage:
    'Estimated National Average Operating Cooling Cost ($)',
  enterEstimatedNationalAverage:
    'Enter estimated national average operating cooling cost ($)',
  energyGuideLabel: 'Energy Guide Label',
  enterEnergyGuideLabel: 'Enter energy guide label',
  eligibleForFederalTaxCredit:
    'Eligible For Federal Tax Credit (installed between Feb 17,2009 and Dec 31,2016)',
  enterEligibleForFederalTaxCredit:
    'Enter eligible for federal tax credit (installed between Feb 17,2009 and Dec 31,2016)',
  isRebated: 'Is Rebated',
  enterIsRebated: 'Enter is rebated',
  EnterSystemPartWarranty: 'Enter system part warranty',
  enterSystemHeatExchangerPartWarranty:
    'Enter system heat exchanger part warranty',
  enterMiscRebate: 'Enter misc rebate',
  enterQuoteTypeDescription: 'Enter quote type description ',
  discount: 'Discount',
  addDiscount: 'Add Discount',
  quoteOrder: 'Quote Order',
  minJobAmount: 'Min Job Amount',
  discountTitle: 'Discount Title',
  EnterDiscountTitle: 'Enter discount title',
  discountType: 'Discount Type',
  enterMinJobAmount: 'Enter min job amount',
  selectQuoteOrder: 'Select quote order',
  areYouSureDeleteDiscount: 'you want to delete this Discount',
  quoteOrderAdminProfit: 'Quote Order & Admin Profit',
  addOrder: 'Add Order',
  orderType: 'Order Type',
  laborCostFlat: 'Labor Cost (Flat In USD)',
  salesProfitFlat: 'Sales Profit (Flat In USD)',
  adjustmentSeason: 'Adjustment Season',
  areYouSureDeleteQuoteOrder:
    'Are you sure to delete this quote order & admin profit?',
  providerQuoteOrder: 'Provider Quote Order',
  enterProviderQuoteOrder: 'Enter provider quote order',
  enterLaborCostFlat: 'Enter labor cost (flat in USD)',
  enterSalesProfitFlat: 'Enter sales profit (flat in USD)',
  adjustmentSeasonPercentage: 'Adjustment For Season (In Percentage)',
  selectAdjustment: 'Select Adjustment',
  uploadBtnText: 'Upload Bulk Images',
  uploadLabel: 'Upload Bulk Data (Please upload only .xlsx file.)',
  uploadImageLabel: 'Upload Bulk Data (Please upload only image file.)',
  uploadPlaceHolder: 'Select Image',
  uploadBtn: 'Upload Bulk Data',
  dropFileText: ' Drop files here to upload',
  dropFileFormat: 'Only JPG, JPEG, PNG files types are',
  dropFileSize: 'supported. Maximum file size is 1MB.',
  titlePlaceHolder: 'Enter title',
  costPlaceHolder: 'Enter cost',
  displayOrderPlaceHolder: 'Select display order',
  quoteTypePlaceHolder: 'Select quote type',
  descriptionPlaceHolder: 'Enter description',
  customerTagsPlaceHolder: 'Select customer tag ',
  jobTagsPlaceHolder: 'Select job tag ',
  autoTag: 'Auto Tag ',

  amountCalculation: 'Amount Calculation Formula',
  addAmountCalculation: 'Add Calculation Formula',
  addAmountCalculationHeader: 'Add Amount Calculation Formula',
  editAmountCalculation: 'Edit Calculation Formula',
  editAmountCalculationHeader: 'Edit Amount Calculation Formula',
  locationName: 'Location Name',
  location: 'Location',
  selectLocation: 'Select location',
  stateTax: 'State Tax (%)',
  stateTaxEn: 'State Tax (In Percentage)',
  enterStateTaxEn: 'State tax',
  salesTax: 'Sales Tax (%)',
  salesTaxIn: 'Sales Tax (In Percentage)',
  enterSalesTaxIn: 'Sales tax',
  locationProfit: 'Location Profit',
  enterLocationProfit: 'Location profit',
  in: 'In (% OR $)',
  selectIn: 'Select % OR $',

  rebateType: 'Rebate Type',
  minRating: 'Min Rating',
  maxRating: 'Max Rating',
  quoteItems: 'Rebates',
  addRebates: 'Add Rebates',
  editRebates: 'Edit Rebates',
  rebates: 'Rebates',

  rebateTitle: 'Rebate Title',
  minCertified: 'Min Certified Rating',
  maxCertified: 'Max Certified Rating',

  selectRebateType: 'Select rebate type',
  selectQuoteType: 'Select quote type',
  enterRebateTitle: 'Enter rebate title',
  enterMinCertified: 'Enter min certified rating',
  enterMaxCertified: 'Enter max certified rating',

  supplyHouseLocation: 'Supply House Location',
  addEquipmentLocation: 'Add Equipment Location',
  addSupplyHouseLocation: 'Add Supply House Location',

  editSupplyHouseLocation: 'Edit Supply House Location',

  updateSupplyHouseLocation: 'Edit Equipment Location',
  supplyHouseName: 'Supply House Name',

  equipmentName: 'Equipment Name',
  equipmentLocationEmail: 'Enter email',
  enterEquipmentName: 'Enter equipment name',
  enterEquipmentLocation: 'Enter equipment location',
  equipmentLocation: 'Equipment Location',
  enterLocationName: 'Enter location name',
  geoLocation: 'Geo Fence Location Served',
  selectGeoLocation: 'Select geo fence location served',
  officeNumber: 'Office Number',
  enterOfficeNumber: 'Enter office number',
  smsTextNumber: 'SMS Text Number',
  enterSmsTextNumber: 'Enter SMS text number',
  emailContact: 'Email Contact',
  enterEmailContact: 'Enter email',
  showRolodex: 'Show on Rolodex',
  showEquip: 'Show In Equipment Order Location And Truck Stock Ordering',
  notes: 'Notes',
  enterNotes: 'Enter equipment note',
  addMore: 'Add More',
  sameNumberCheckbox: 'Use same number for SMS number also',
  showEquipmentLocation:
    'Show in Equipment Order Location And Truck Stock Ordering',
  supplyHouseCreatedSuccess: 'Supply house location created successfully',
  supplyHouseUpdatedSuccess: 'Supply house location created successfully',
  note1:
    'Supply House Location module will list all Supply House Location on this page.',
  note2: 'Administrator can Edit / Delete any Supply House Location.',
  orderNote1:
    'Quote order & Admin Profit module will list all Quote Order & Admin Profit on this page.',
  orderNote2: 'Administrator can Edit / Delete any Quote Order & Admin Profit.',
  amountNote1:
    'Amount Calculation Formula module will list all Calculation Formula on this page.',
  amountNote2:
    'Administrator can Edit / Delete any Amount Calculation Formula.',
  areYouSureDeleteAmount: 'you want to delete this Amount Calculation Formula',
  areYouSureDeleteRebate: 'you want to delete this Rebates',
  areYouSureSupplyHouseLocation:
    'you want to delete this Supply House Location',
  officePhoneNumber: 'Office Phone Number',
  enterOfficePhoneNumber: 'Enter office phone number',
  memberShipType: 'Member Ship Type',
  enterMember: 'Select member ship type',
  memberShipStatus: 'Membership Status',
  memberRenewalDate: 'Member Renewal Date',
};
