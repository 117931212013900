import i18next from 'i18next';
import { accessRoute } from '../../routes/Dashboard';
import {
  checkValidData,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { getFullName } from '../../utils/text.util';
import { readMoreTextShow } from '../../utils';
import { dateFormatter } from '../../utils';
import {
  DATE_FULL_MONTH__DATE_TH_YEAR_TIME,
  DATE_TH_MONTH_YEAR,
} from '../../config';

export const notificationTableColumn = ({ page, sizePerPage }) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.sno'),
      render: (cell, row, index) =>
        serialNumberFormatter({ page, index, sizePerPage }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'userName',
      title: i18next.t('text.common.UserName'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) =>
        checkValidData(
          getFullName(
            row?.Booking?.Customer?.firstName,
            row?.Booking?.Customer?.lastName,
            '',
            row?.Booking?.Customer?.CustomerMembership?.MembershipPlan?.name
            // 101,
            // 'percentage'
          )
        ),
    },
    {
      dataIndex: 'phoneNumberCustomer',
      title: i18next.t('text.common.phoneNumber'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) =>
        checkValidData(row?.Booking?.Customer?.phoneNumber),
    },
    {
      dataIndex: 'serviceType',
      title: i18next.t('text.common.serviceType'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => (
        <>
          <b>
            {checkValidData(
              row?.Booking?.ServiceCategory?.ServiceCategoryDetails?.name
            )}
          </b>{' '}
          - {checkValidData(row?.Booking?.ServiceCategory?.name)}
        </>
      ),
    },
    {
      dataIndex: 'location',
      title: i18next.t('text.common.location'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => checkValidData(row?.Booking?.jobLocationAddress),
    },
    {
      dataIndex: 'providerName',
      title: i18next.t('text.common.providerName'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) =>
        checkValidData(
          getFullName(
            row?.Booking?.Provider?.firstName,
            row?.Booking?.Provider?.lastName
          )
        ),
    },
    {
      dataIndex: 'phoneNumberProvider',
      title: i18next.t('text.common.phoneNumber'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) =>
        checkValidData(row?.Booking?.Provider?.phoneNumber),
    },
    {
      dataIndex: 'message',
      title: i18next.t('text.common.notification'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => readMoreTextShow(checkValidData(cell)),
    },
    {
      dataIndex: 'dateTime',
      title: i18next.t('text.common.dateTime'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) =>
        dateFormatter(row?.createdAt, DATE_FULL_MONTH__DATE_TH_YEAR_TIME),
    },
  ];
};

export const notificationBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.notification.title'),
    },
  ];
};
