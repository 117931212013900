import { ServiceProvider } from '../../views/CustomerApp';

export default function route() {
  return [
    {
      path: accessRoute.ASK_THE_EXPERT.path,
      private: true,
      name: accessRoute.ASK_THE_EXPERT.name,
      key: accessRoute.ASK_THE_EXPERT.key,
      belongsToSidebar: true,
      icon: accessRoute.ASK_THE_EXPERT.icon,
      element: <ServiceProvider />,
    },
  ];
}

export const accessRoute = {
  ASK_THE_EXPERT: {
    path: '/customer/ask-the-expert',
    name: 'Ask The Expert',
    key: 'ask_the_expert',
    for: 'customer',
    icon: <em className="gg-month"></em>,
  },
};

export const getChildrenRoutes = () => {
  return route();
};
