import { STORE_USER_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';

export const getProfileService = async (id) => {
  try {
    const payload = {
      ...STORE_USER_ENDPOINT.getProfile(id),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const editProfileService = async ({ id, body }) => {
  try {
    const payload = {
      ...STORE_USER_ENDPOINT.editProfile(id),
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const editAddressService = async ({ id, body }) => {
  try {
    const payload = {
      ...STORE_USER_ENDPOINT.editAddress(id),
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const editBankService = async ({ body }) => {
  try {
    const payload = {
      ...STORE_USER_ENDPOINT?.editBank,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const addQuestionStoreService = async ({ body }) => {
  try {
    const payload = {
      ...STORE_USER_ENDPOINT?.addQuestion,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const addPromoCodeStoreService = async ({ body }) => {
  try {
    const payload = {
      ...STORE_USER_ENDPOINT?.addPromoCode,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const updateProfileService = async ({ body }) => {
  try {
    const payload = {
      ...STORE_USER_ENDPOINT?.updateProfile,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const getDocumentAllService = async ({ queryParams }) => {
  try {
    const payload = {
      ...STORE_USER_ENDPOINT?.getDocumentAll,
      queryParams,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
export const addDocumentStoreService = async ({ body }) => {
  try {
    const payload = {
      ...STORE_USER_ENDPOINT?.addDocument,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const getStorePromoService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...STORE_USER_ENDPOINT?.getPromoCode,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};
export const editDocumentStoreService = async ({ id, body }) => {
  try {
    const payload = {
      ...STORE_USER_ENDPOINT.editDocument(id),
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
