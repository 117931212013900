import { APIRequest } from '../../helpers/index.helper';
import {
  GET_USER_ROLE_PERMISSION,
  GET_ROLE_PERMISSION_LIST,
  ROLE_ADD,
  GET_ROLE_PERMISSION_BY_ID,
  ROLE_LIST,
  ROLE_DELETE,
  GET_ROLE_BY_ID,
  ROLE_UPDATE,
  UPDATE_ROLE_PERMISSION_BY_ID,
  ROLE_STATUS_UPDATE,
  GET_ROLE_PERMISSION,
  ROLE_NCA_LIST,
  MENTAL_HEALTH_STATUS_UPDATE,
  ALEXA_TOGGLE_STATUS_UPDATE,
} from '../../apiEndpoints/index.endpoint';
import { logger, setLocalStorage } from '../../utils';

export const listRoleService = async ({ params, queryParams, body, type }) => {
  try {
    const payload = {
      ...ROLE_LIST,
      queryParams,
    };

    const res = await APIRequest(payload);
    if (type === 'all') {
      setLocalStorage({ keyName: 'isScrollCount', data: res?.data?.count });
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const addRoleService = async ({ params, queryParams, body }) => {
  try {
    const payload = {
      ...ROLE_ADD,
      bodyData: body,
    };
    return await APIRequest(payload);
  } catch (err) {
    console.log(err);
  }
};

export const editRoleService = async ({ body, id }) => {
  try {
    const payload = {
      ...ROLE_UPDATE(id),
      bodyData: body,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const deleteRoleService = async ({ bodyData }) => {
  try {
    const payload = {
      ...ROLE_DELETE,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const getRoleByIdService = async ({ id }) => {
  try {
    const payload = {
      ...GET_ROLE_BY_ID(id),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const updateRoleStatus = async (body) => {
  try {
    const payload = {
      ...ROLE_STATUS_UPDATE,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getPermission = async ({
  params,
  queryParams = {},
  body,
  type,
}) => {
  try {
    const payload = {
      ...GET_ROLE_PERMISSION,
      queryParams,
    };

    const res = await APIRequest(payload);
    if (type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getPermissionById = async ({
  params,
  id,
  queryParams = {},
  body,
  type,
}) => {
  try {
    const payload = {
      ...GET_ROLE_PERMISSION_BY_ID(id),
      queryParams,
    };

    const res = await APIRequest(payload);
    if (type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const updatePermissionBy = async ({
  params,
  id,
  queryParams = {},
  body,
  type,
}) => {
  try {
    const payload = {
      ...UPDATE_ROLE_PERMISSION_BY_ID(id),
      bodyData: body,
    };

    const res = await APIRequest(payload);
    if (type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getRolesList = async ({ id, queryParams }) => {
  try {
    const payload = {
      ...GET_ROLE_PERMISSION_LIST(id),
      queryParams,
      isAdminToken: true,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getUserPermission = async ({
  params,
  queryParams = {},
  body,
  type,
}) => {
  try {
    const payload = {
      ...GET_USER_ROLE_PERMISSION,
      queryParams,
    };

    const res = await APIRequest(payload);
    if (type === 'all') {
      return res?.data;
    }
    return res;
  } catch (err) {
    console.log(err);
  }
};

// NCA Role
export const listNCARoleService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...ROLE_NCA_LIST,
      queryParams,
      isAdminToken: true,
    };

    const res = await APIRequest(payload);
    if (type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const updateMentalHealthStatus = async ({ bodyData, id }) => {
  try {
    const payload = {
      ...MENTAL_HEALTH_STATUS_UPDATE(id),
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const updateAlexaToggleStatus = async ({ bodyData, id }) => {
  try {
    const payload = {
      ...ALEXA_TOGGLE_STATUS_UPDATE(id),
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
