import { Outlet } from 'react-router-dom';
import { ListInventory, ViewInventory } from '../../views/index.view';
import { getActions } from '../../services/index.service';

export default function route() {
  return [
    {
      path: accessRoute.HOME_INVENTORY.path,
      private: accessRoute.HOME_INVENTORY.isSecure,
      name: accessRoute.HOME_INVENTORY.name,
      key: accessRoute.HOME_INVENTORY.key,
      belongsToSidebar: accessRoute.HOME_INVENTORY.belongsToSidebar,
      icon: accessRoute.HOME_INVENTORY.icon,
      element: <ListInventory />,
      ...accessRoute.HOME_INVENTORY,
    },
    {
      path: accessRoute.HOME_INVENTORY_VIEW.path,
      private: accessRoute.HOME_INVENTORY_VIEW.isSecure,
      name: accessRoute.HOME_INVENTORY_VIEW.name,
      key: accessRoute.HOME_INVENTORY_VIEW.key,
      belongsToSidebar: accessRoute.HOME_INVENTORY_VIEW.belongsToSidebar,
      icon: accessRoute.HOME_INVENTORY_VIEW.icon,
      element: <ViewInventory />,
      ...accessRoute.HOME_INVENTORY_VIEW,
    },
    // {
    //   path: accessRoute.HOME_INVENTORY_ADD.path,
    //   private: accessRoute.HOME_INVENTORY_ADD.isSecure,
    //   name: accessRoute.HOME_INVENTORY_ADD.name,
    //   key: accessRoute.HOME_INVENTORY_ADD.key,
    //   belongsToSidebar: accessRoute.HOME_INVENTORY_ADD.belongsToSidebar,
    //   icon: accessRoute.HOME_INVENTORY_ADD.icon,
    //   element: <AddInventory />,
    //   ...accessRoute.HOME_INVENTORY_ADD,
    // },
    // {
    //   path: accessRoute.HOME_INVENTORY_EDIT.path,
    //   private: accessRoute.HOME_INVENTORY_EDIT.isSecure,
    //   name: accessRoute.HOME_INVENTORY_EDIT.name,
    //   key: accessRoute.HOME_INVENTORY_EDIT.key,
    //   belongsToSidebar: accessRoute.HOME_INVENTORY_EDIT.belongsToSidebar,
    //   icon: accessRoute.HOME_INVENTORY_EDIT.icon,
    //   element: <EditInventory />,
    //   ...accessRoute.HOME_INVENTORY_EDIT,
    // },
  ];
}

export const accessRoute = {
  HOME_INVENTORY: {
    path: '/admin/inventory',
    isSecure: true,
    key: 'home-inventory',
    action: getActions('LIST').value,
    moduleKey: 'home-inventory',
    belongsToSidebar: true,
    name: 'Home Inventory',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-home-fill" />
      </span>
    ),
    order: 53,
  },
  HOME_INVENTORY_EDIT: {
    path: '/admin/inventory/edit/:id',
    isSecure: true,
    key: 'home-inventory',
    action: getActions('EDIT').value,
    moduleKey: 'home-inventory',
    belongsToSidebar: false,
    name: 'Edit Home Inventory',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
  },
  HOME_INVENTORY_ADD: {
    path: '/admin/inventory/add',
    isSecure: true,
    key: 'home-inventory',
    action: getActions('CREATE').value,
    moduleKey: 'home-inventory',
    belongsToSidebar: false,
    name: 'Add Home Inventory',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
  },
  HOME_INVENTORY_VIEW: {
    path: '/admin/inventory/:id',
    isSecure: true,
    key: 'home-inventory',
    action: getActions('VIEW').value,
    moduleKey: 'home-inventory',
    belongsToSidebar: false,
    name: 'View Home Inventory',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
  },
};

export const getChildrenRoutes = () => {
  return route();
};
