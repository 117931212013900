import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionWrapper,
  CommonButton,
  DocumentNameForm,
  ModalWrapper,
  PageContainerWrapper,
  TablePlus,
  Toggle,
} from '../../../../components';
import useTable from '../../../../hooks/useTable.hook';
import {
  documentManagementBreadcrumb,
  documentManagementColumn,
} from '../../../../services/Columns';
import { getActions } from '../../../../services/index.service';

import {
  addDocumentService,
  documentDeleteService,
  editDocumentService,
  getDocumentDetailsService,
  manageDocumentService,
  updateDocumentStatusService,
} from '../../../../services/TeamSetting/document.service';
import { logger, modalNotification } from '../../../../utils';

function DocumentListing() {
  const { t } = useTranslation();
  // For loader, modal, selected row, and form text
  const [state, setState] = useState({
    loader: null,
    modal: null,
    modalId: null,
    selectedRows: [],
    submitText: 'add',
  });
  const { loader, modal, modalId, selectedRows, submitText } = state;

  const breadcrumb = documentManagementBreadcrumb();

  // For get tags list
  const [tableHandler, tableProps] = useTable(manageDocumentService);
  const {
    data,
    isLoading,
    count,
    page,
    noOfPages,
    sizePerPage,
    filter,
    sortBy,
    sortType,
  } = tableProps;
  const {
    onRefresh,
    getData,
    onExport,
    onFilterChange,
    onTableSort,
    onToggleChange,
    onFilterResetChange,
    onConfirmDeleteAlert,
  } = tableHandler;

  // For action with column
  function onAction(e, handler, item) {
    e.preventDefault();
    if (handler) handler(item);
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler, item)}
              key={key}
              className="action_list"
              action={item?.action}
            >
              <CommonButton>
                <em className={item?.icon} /> {item?.name}
              </CommonButton>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };

  const columns = documentManagementColumn({
    onSort: onTableSort,
    onFilterChange: onFilterChange,
    filter: filter,
    page,
    sizePerPage,
    Action: (
      props
      // row
    ) =>
      actionFormatter([
        {
          name: t(`text.common.edit`),
          icon: 'icon ni ni-edit',
          action: getActions('EDIT').value,
          handler: () => {
            setState({
              ...state,
              modal: 'edit-document',
              submitText: 'Update',
              modalId: props?.row?.id,
            });
          },
        },
        {
          name: t(`text.common.delete`),
          icon: 'icon ni ni-trash',
          action: getActions('DELETE').value,
          handler: () => {
            onConfirmDeleteAlert({
              id: props?.row?.id,
              text: t('text.documentManagement.areYouSureDeleteDocument'),
              service: documentDeleteService,
              key: 'documentIds',
            });
            document.body.click();
          },
        },
      ]),
    Toggle: (props) => (
      <Toggle
        isDropDown={true}
        loading={loader === 'status-update'}
        defaultChecked={props?.row?.status}
        onChange={(e) =>
          onToggleChange(
            e,
            props?.row,
            '',
            updateDocumentStatusService,
            'documentIds',
            true
          )
        }
      />
    ),
  });

  const getDocumentDetails = async (detailsId) => {
    try {
      setState({ ...state, loader: 'edit' });
      const response = await getDocumentDetailsService(detailsId);
      if (response?.success) {
        setState({ ...state, loader: null });
        return response;
      }
      setState({ ...state, loader: null });
    } catch (error) {
      logger(error);
    }
  };

  // For bulk action
  function onRowSelect(e) {
    try {
      setState({ ...state, selectedRows: [...e] });
    } catch (err) {
      console.log('err', err);
    }
  }
  // For bulk action
  async function handleActionSelect(e) {
    try {
      if (e.target.value === 'delete') {
        await onConfirmDeleteAlert({
          id: selectedRows,
          text: t('text.documentManagement.areYouSureDeleteDocument'),
          service: documentDeleteService,
          key: 'documentIds',
        });
        getData();
      } else {
        await onToggleChange(
          e.target.value,
          selectedRows,
          'action',
          updateDocumentStatusService,
          'documentIds'
        );
        getData();
      }
    } catch (err) {
      console.log(err);
    }
  }

  //For Edit Document Name
  async function onSubmit(values) {
    setState({ ...state, loader: 'add' });
    try {
      let bodyData = { ...values };
      bodyData.isDateRequired = values.isDateRequired === 1 ? true : false;
      const response = modalId
        ? await editDocumentService({ id: modalId, bodyData })
        : await addDocumentService({ bodyData });
      if (response?.success) {
        modalNotification({
          type: 'success',
          message: response?.message,
        });
        onCancel();
        onRefresh();
      } else {
        setState({ ...state, loader: null });
      }
    } catch (err) {
      console.log(err);
    }
  }

  // For header Button
  const headerBtn = {
    add: {
      name: t('text.documentManagement.addDocumentName'),
      handler: () => {
        setState({ ...state, submitText: 'Add', modal: 'add-document' });
      },
    },
    csvExport: { handler: () => onExport('', columns) },
  };

  function onCancel() {
    setState({ ...state, modal: null, modalId: null });
  }

  const formProps = {
    onCancel: onCancel,
    onSubmit: onSubmit,
  };

  const modalForm = {
    add: (
      <DocumentNameForm
        onEdit={getDocumentDetails}
        onCancel={onCancel}
        loading={loader}
        modalId={modalId}
        submitText={submitText}
        {...formProps}
      />
    ),
  };

  return (
    <PageContainerWrapper
      heading={t('text.documentManagement.documentManagement')}
      breadcrumb={breadcrumb}
      btn={headerBtn}
      extraBtnText="View All Images"
      extraBtnClass="btn btn-outline-light bg-white"
      extraLinkButton={true}
    >
      <ModalWrapper
        heading={`${
          submitText === 'Add' ? t('text.common.add') : t('text.common.edit')
        } Document Name`}
        onCancel={onCancel}
        content={modalForm['add']}
        show={['add-document', 'edit-document'].includes(modal)}
      />
      <TablePlus
        hasLimit
        noOfPage={noOfPages}
        sizePerPage={sizePerPage}
        page={page}
        handleSelectedRows={onRowSelect}
        handleActionSelect={handleActionSelect}
        count={count}
        tableData={data}
        tableColumns={columns}
        selectRow
        tableLoader={isLoading}
        onFilterResetChange={onFilterResetChange}
        filter={filter}
        sortBy={sortBy}
        sortType={sortType}
      />
      <div className="card mt-3">
        <div className="card-inner">
          <div className="bq-note">
            <div className="bq-note-text">
              <div className="nk-block-head nk-block-head-sm nk-block-between">
                <h5 className="title">{t('text.documentManagement.note')}</h5>
              </div>
              <ul className="list list-sm list-checked">
                <li>{t('text.documentManagement.docList')}</li>
                <li>{t('text.documentManagement.adminList')}</li>
                <li>{t('text.documentManagement.export')}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </PageContainerWrapper>
  );
}

export default DocumentListing;
