// import { API_ENDPOINT_V1 } from '../../config/api.config';

export const GOOGLE_CONNECT_ENDPOINT = {
  getAccountList: {
    url: '/v1/google/retrive-account-list',
    method: 'GET',
  },
  getBusinessList: {
    url: '/v1/google/retrive-business-list',
    method: 'GET',
  },
  getReviewData: {
    url: '/v1/google/retrive-business-review-list',
    method: 'GET',
  },
  reviewReply: {
    url: '/v1/google/business-review-reply',
    method: 'PUT',
  },
  getBusinessMedia: {
    url: '/v1/google/retrive-business-media-list',
    method: 'GET',
  },
  getBusinessPost: {
    url: '/v1/google/retrive-business-post-list',
    method: 'GET',
  },
  deleteReply: {
    url: '/v1/google/delete-business-review-reply',
    method: 'DELETE',
  },
  getGoogleService: {
    url: '/v1/google/local-service-campaign',
    method: 'GET',
  },
  getGoogleLead: {
    url: `/v1/google/lead`,
    method: 'GET',
  },
  statusGoogleService: {
    url: `/v1/google/campaign-status`,
    method: 'PUT',
  },
  addPost: {
    url: '/v1/google/add-post',
    method: 'POST',
  },
  addMedia: {
    url: '/v1/google/add-photo',
    method: 'POST',
  },
  getGoogleAdwordsAccount: {
    url: '/v1/google/campaign-account-list',
    method: 'GET',
  },
  getGoogleAdwordsList: {
    url: '/v1/google/adwords-list',
    method: 'GET',
  },
  deletePost: {
    url: '/v1/google/delete-business-post',
    method: 'DELETE',
  },
  updateGoogleAdwordsBudget: {
    url: '/v1/google/campaign-budget',
    method: 'PUT',
  },
  updateGoogleSPControlStatus: {
    url: `/v1/google/campaign-control`,
    method: 'PUT',
  },
  getGoogleToken: {
    url: '/v1/google/me',
    method: 'GET',
  },
};
