import loadable from '@loadable/component';
import FullPageLoader from '../../components/UIElements/Loader/fullPageLoader';

export const LoginParentView = loadable(() => import('./Login/index'), {
  fallback: <FullPageLoader />,
});
export const NCALogin = loadable(() => import('./Login/nca'), {
  fallback: <FullPageLoader />,
});
export const AdminLogin = loadable(() => import('./Login/admin'), {
  fallback: <FullPageLoader />,
});
export * from './Register/NCA';
export const LoginView = loadable(() => import('./Login/login'), {
  fallback: <FullPageLoader />,
});
export * from './Register/NCA';
export const CustomerLogin = loadable(() => import('./Login/customer'), {
  fallback: <FullPageLoader />,
});
export const CustomerSignUp = loadable(
  () => import('./Register/Customer/index'),
  {
    fallback: <FullPageLoader />,
  }
);
export const CustomerForgetPassword = loadable(
  () => import('./ForgetPassword/customer'),
  {
    fallback: <FullPageLoader />,
  }
);
export const CustomerResetPassword = loadable(
  () => import('./ResetPassword/customer'),
  {
    fallback: <FullPageLoader />,
  }
);
export * from './Register/NCA';
// export { default as RegisterView } from './Register/index';
export { default as ForgetView } from './ForgetPassword/admin';
