export const validation = {
  amountType: 'Please enter the amount pay',
  cardHolder: 'Please enter the card holder',
  cardNumber: 'Please enter the card number',
  expMonth: 'Expire month is required',
  expYear: 'Expire year is required',
  cvv: 'Please enter the cvv',
  checkNumber: 'Please enter the check number',
  bankName: 'Please enter the bank name',
  finance: 'Please enter the financing confirmation number',
};
