import {
  getColumnSearchMultipleInputProps,
  getColumnSelectSearchProps,
  getInputSearchFilter,
  getMultiSelectSearchFilter,
} from '../../components';
import {
  badgeQuoteFormatter,
  checkValidData,
  checkValidPrice,
  commasFormatter,
  imageNameFormatter,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { accessRoute } from '../../routes/Dashboard';
import { TableImageFormatter, readMoreTextShow } from '../../utils';
import { firstLetterCaps } from '../../utils/text.util';
import {
  getAllCategoryListService,
  getProductStatusList,
  getProductStatusListFilter,
  getStatusData,
} from '../index.service';
import i18next from 'i18next';
import { accessRoute as productRoutes } from '../../routes/ShoppingCart';

export const ProductManagementBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.common.productManagement'),
    },
  ];
};

export const ProductManagementEditBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: productRoutes.PRODUCTS_LIST.path,
      name: i18next.t('text.common.productManagement'),
    },
    {
      path: '#',
      name: i18next.t('text.common.addProduct'),
    },
  ];
};

export const ProductManagementUploadBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: productRoutes.PRODUCTS_LIST.path,
      name: i18next.t('text.common.productManagement'),
    },
    {
      path: '#',
      name: i18next.t('text.common.uploadBulk'),
    },
  ];
};

export const ProductViewBulkUploadBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: productRoutes.PRODUCTS_LIST.path,
      name: i18next.t('text.common.productManagement'),
    },
    {
      path: '#',
      name: i18next.t('text.shoppingCart.allProductImages'),
    },
  ];
};

export const productsManagementTableColumn = ({
  Action,
  Toggle,
  onSort,
  onFilterChange,
  filter,
  page,
  sizePerPage,
}) => {
  return [
    {
      dataIndex: 'id',
      title: 'S.No.',
      headerClasses: 'w_70',
      sorter: false,
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportIdRemove: true,
    },
    {
      dataIndex: 'productName',
      title: 'Product Name',
      filterDataIndex: ['productName'],
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) =>
        checkValidData(
          readMoreTextShow(
            checkValidData(cell),
            i18next.t('text.shoppingCart.productName'),
            25
          )
        ),

      ...getInputSearchFilter({
        dataIndex: 'productName',
        placeholder: 'product name',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'categoryName',
      title: 'Product Category',
      filterDataIndex: ['categoryId'],
      headerClasses: 'sorting',
      sorter: false,
      render: (cell, row) => {
        if (row?.categoryDetails?.length > 0) {
          let allProductCategory = [];
          for (const obj of row?.categoryDetails) {
            allProductCategory.push(obj?.ProductCategoryDetails?.categoryName);
          }
          return readMoreTextShow(
            commasFormatter(allProductCategory),
            i18next.t('text.shoppingCart.productName'),
            25
          );
        }else if(row?.categoryIdData){
          return readMoreTextShow(
            row?.categoryIdData,
            i18next.t('text.shoppingCart.productName'),
            25
          );
        } else {
          return '-';
        }
      },

      ...getMultiSelectSearchFilter({
        dataIndex: 'categoryId',
        onFilterChange,
        name: 'Category name',
        list: [],
        mode: 'multiple',
        scope: 'allProductCategory',
        searchOrNot: false,
        listService: getAllCategoryListService,
        listKeys: { nameKey: 'categoryName', nameId: 'id' },
        filter,
      }),
    },
    // {
    //   dataIndex: 'quantity',
    //   title: 'Product Quantity',
    //   headerClasses: 'sorting',
    //   sorter: true,
    //   render: (cell, row) => checkValidData(cell),
    //   // ...getInputSearchFilter({
    //   //   dataIndex: 'quantity',
    //   //   placeholder: 'Product Quantity',
    //   //   onFilterChange,
    //   //   filter,
    //   // }),
    // },
    {
      dataIndex: 'price',
      title: 'Regular Price',
      // filterDataIndex: ['price'],
      headerClasses: 'sorting',
      filterDataIndex: ['fromPrice', 'toPrice'],
      sorter: true,
      exportName: 'Price',
      render: (cell, row) => checkValidPrice(cell),
      ...getColumnSearchMultipleInputProps({
        dataStartIndex: 'fromPrice',
        dataEndIndex: 'toPrice',
        placeholderStartPrice: 'From Price',
        placeholderEndPrice: 'To Price',
        onFilterChange,
        filter,
      }),
      // ...getInputSearchFilter({
      //   dataIndex: 'price',
      //   placeholder: 'product price',
      //   onFilterChange,
      //   filter,
      // }),
    },
    {
      dataIndex: 'sellingPrice',
      title: 'Selling Price',
      filterDataIndex: ['fromSellingPrice', 'toSellingPrice'],
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => (cell === '0.00' ? '-' : checkValidPrice(cell)),
      // ...getInputSearchFilter({
      //   dataIndex: 'sellingPrice',
      //   placeholder: 'selling price',
      //   onFilterChange,
      //   filter,
      // }),
      ...getColumnSearchMultipleInputProps({
        dataStartIndex: 'fromSellingPrice',
        dataEndIndex: 'toSellingPrice',
        placeholderStartPrice: 'From Price',
        placeholderEndPrice: 'To Price',
        onFilterChange,
        filter,
      }),
    },
    // {
    //     dataIndex: "productImage",
    //     title: "Product Image",
    //     headerClasses: "sorting",
    //     sorter: true,
    //     render: (cell, row) => {
    //         return <>
    //             <div className="table-img">
    //                 <img src='/assets/images/admin/product-image.jpg' alt="img" />
    //             </div>
    //     </>
    //     }
    // },
    // {
    //     dataIndex: "companyName",
    //     title: "Company Name",
    //     headerClasses: "sorting",
    //     sorter: true,

    // },
    // {
    //     dataIndex: "category",
    //     title: "Category",
    //     headerClasses: "sorting",
    //     sorter: true,
    // },
    // {
    //     dataIndex: "variants",
    //     title: "Variants",
    //     headerClasses: "sorting",
    //     sorter: true,
    // },
    // {
    //     dataIndex: "price",
    //     title: "Price",
    //     headerClasses: "sorting",
    //     sorter: true,
    // },
    // {
    //     dataIndex: "availableQty",
    //     title: "Available Qty.",
    //     headerClasses: "sorting",
    //     sorter: true,
    // },
    // {
    //     dataIndex: "productStatus",
    //     title: "Product Status",
    //     headerClasses: "sorting",
    //     sorter: true,
    // },
    {
      dataIndex: 'status',
      title: 'Status',
      headerClasses: 'sorting',
      sorter: false,
      //   onSort: (field, order) => onSort('status', order),
      render: (cell, row) => {
        return <Toggle row={row} cell={cell} />;
      },
      ...getColumnSelectSearchProps({
        dataIndex: 'status',
        name: 'status',
        onFilterChange,
        filter,
        mode: false,
        list: getProductStatusListFilter(),
      }),
    },
    {
      dataIndex: 'action',
      title: 'Action',
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => {
        return <Action row={row} cell={cell} />;
      },
    },
  ];
};

export const productViewImageTableColumn = ({ Action, page, sizePerPage }) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.sno'),
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'productImageUrl',
      title: i18next.t('text.common.image'),
      render: (cell, row) => TableImageFormatter({ url: row?.productImageUrl }),
    },
    {
      dataIndex: 'productId',
      title: i18next.t('text.common.availability'),
      sorter: true,
      render: (cell, row) => badgeQuoteFormatter(row?.Product),
    },

    {
      dataIndex: 'productImage',
      title: i18next.t('text.common.imageName'),
      sorter: true,
      render: (cell, row) => checkValidData(row?.productImage),
    },
    {
      dataIndex: 'productName',
      title: i18next.t('text.common.productName'),
      sorter: true,
      render: (cell, row) => checkValidData(row?.Product?.productName),
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.localization.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => {
        return <Action row={row} cell={cell} />;
      },
    },
  ];
};
