import React from 'react';
import { useNavigate } from 'react-router-dom';
import { StorePageContainer, StoreSignUpForm } from '../../../../components';
import { StoreSignupService } from '../../../../services/index.service';
import { accessRoute } from '../../../../routes/Auth/store';
import { t } from 'i18next';

function StoreSignUp() {
  const [state, setState] = React.useState({ loading: false });
  const navigate = useNavigate();

  async function onSubmit(payload) {
    try {
      setState({ ...state, loading: true });
      const res = await StoreSignupService(payload);
      if (res?.data) {
        setState({ ...state, loading: false });
        navigate(accessRoute.STORE_LOGIN.path);
        return true;
      }
      setState({ ...state, loading: false });
    } catch (err) {
      setState({ ...state, loading: false });
      console.log(err);
    }
  }

  return (
    <StorePageContainer breadcrumb={[]} title="Become A Super Fan">
      <div className="becomePage_signup text-left">
        <div className="becomePage_head mb-0">
          <h2 className="mb-md-3 mb-1 font-sm">{t('text.store.signUp')}</h2>
        </div>
        <p>{t('text.store.thankYou')}</p>
        <p>{t('text.store.goodGuys')}</p>
        <h3>{t('text.store.howWork')}</h3>
        <ul className="list-unstyled">
          <li>{t('text.store.signUpwith')}</li>
          <li>{t('text.store.submita')}</li>
          <li>{t('text.store.providerThis')}</li>
          <li>{t('text.store.haveThem')}</li>

          <li>{t('text.store.theCustomer')}</li>
        </ul>
        <p>{t('text.store.added')}</p>
      </div>
      <div className="becomePage_steps">
        <StoreSignUpForm
          onSubmit={onSubmit}
          onBack={accessRoute.STORE_LOGIN.path}
        />
      </div>
    </StorePageContainer>
  );
}

export default StoreSignUp;
