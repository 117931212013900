import loadable from '@loadable/component';

import { getActions } from '../../services/index.service';
import { BookingLog } from '../../views/EquipmentOrder';

const ListEquipmentOrder = loadable(() =>
  import('../../views/EquipmentOrder/List')
);
const EquipmentList = loadable(() =>
  import('../../views/EquipmentOrder/View/Equipment')
);
const MaterialList = loadable(() =>
  import('../../views/EquipmentOrder/View/Material')
);

export default function route() {
  return [
    {
      private: true,
      path: accessRoute.EQUIPMENT_ORDER.path,
      name: 'Equipment Order',
      key: accessRoute.EQUIPMENT_ORDER.path,
      belongsToSidebar: true,
      icon: accessRoute.EQUIPMENT_ORDER.icon,
      element: <ListEquipmentOrder />,
      ...accessRoute.EQUIPMENT_ORDER,
    },
    {
      private: true,
      path: accessRoute.BOOKING_LOG.path,
      name: 'View Booking',
      key: accessRoute.BOOKING_LOG.key,
      belongsToSidebar: false,
      icon: accessRoute.BOOKING_LOG.icon,
      element: <BookingLog />,
      ...accessRoute.BOOKING_LOG,
    },
    {
      private: true,
      path: accessRoute.EQUIPMENT_LIST.path,
      name: 'Material List 1',
      key: accessRoute.EQUIPMENT_LIST.path,
      belongsToSidebar: false,
      element: <EquipmentList />,
      ...accessRoute.EQUIPMENT_LIST,
    },
    {
      private: true,
      path: accessRoute.MATERIAL_LIST.path,
      name: 'Material List 1',
      key: accessRoute.MATERIAL_LIST.path,
      belongsToSidebar: false,
      element: <MaterialList />,
      ...accessRoute.MATERIAL_LIST,
    },
  ];
}

export const accessRoute = {
  EQUIPMENT_ORDER: {
    path: '/admin/equipment-order',
    action: getActions('LIST').value,
    key: 'equipment-order',
    moduleKey: 'equipment-order',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-money" />
      </span>
    ),
    order: 55,
  },
  EQUIPMENT_LIST: {
    path: '/admin/equipment-order/equipment-list',
    action: getActions('VIEW').value,
    moduleKey: 'equipment-order',
  },
  BOOKING_LOG: {
    path: '/admin/equipment-order/booking/:id',
    moduleKey: 'equipment-order',
    action: getActions('LIST').value,
    key: 'equipment-order',
  },
  MATERIAL_LIST: {
    path: '/admin/equipment-order/material-list',
    action: getActions('VIEW').value,
    moduleKey: 'equipment-order',
  },
};

export const getChildrenRoutes = () => {
  return route();
};
