import i18next from 'i18next';
import * as yup from 'yup';
// import { Regex } from '../../../helpers/regex.helper';

export const checkInitValues = (formData) => {
  return {
    amountToPay: '',
    bankName: '',
    checkNumber: '',
    finance: '',
    ...formData,
  };
};

export const checkInitValuesValidation = (defaultKey) => {
  return yup.object().shape({
    amountToPay: yup
      .string()
      .nullable()
      .required(i18next.t('validation.report.amountType')),
    bankName: yup.string().nullable().required(i18next.t('validation.report.bankName')),
    checkNumber:
      !defaultKey &&
      yup.string().nullable().required(i18next.t('validation.report.checkNumber')),
    finance:
      defaultKey &&
      yup.string().nullable().required(i18next.t('validation.report.finance')),
  });
};

export const creditCardInitValues = (formData) => {
  return {
    paidAmount: '',
    cardHolderName: '',
    cardNumber: '',
    expiryMonth: '',
    expiryYear: '',
    cvc: '',
    ...formData,
  };
};

export const creditCardValidation = () => {
  return yup.object().shape({
    paidAmount: yup
      .string()
      .nullable()
      .required(i18next.t('validation.report.amountType')),
    cardHolderName: yup
      .string()
      .nullable()
      .required(i18next.t('validation.report.cardHolder')),
    cardNumber: yup
      .string()
      .nullable()
      .required(i18next.t('validation.report.cardNumber')),
    expiryMonth: yup.string().nullable().required(i18next.t('validation.report.expMonth')),
    expiryYear: yup.string().nullable().required(i18next.t('validation.report.expYear')),
    cvc: yup.string().nullable().required(i18next.t('validation.report.cvv')),
  });
};

export const cashAdminInitValues = (formData) => {
  return {
    paidAmount: '',
    ...formData,
  };
};

export const cashAdminValidation = () => {
  return yup.object().shape({
    paidAmount: yup
      .string()
      .required(i18next.t('validation.report.amountType')),
  });
};
