const url = '/v1/admin/nca/service-category';
const ncaUrl = '/v1/admin/nca/service/category';

export const NCA_SERVICE_CATEGORY_ENDPOINT = {
  getServiceCategoryList: {
    url,
    method: 'GET',
  },
  getNCAServiceCategoryList: {
    url: ncaUrl,
    method: 'GET',
  },
  addNCASubServiceCategory: {
    url: '/v1/nca/service/sub-categories',
    method: 'POST',
  },
  addNCAServiceType: {
    url: `/v1/nca/service-type`,
    method: 'POST',
  },
};
