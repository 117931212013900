import i18next from 'i18next';
import {
  checkValidData,
  checkValidPrice,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { accessRoute } from '../../routes/Dashboard';
import {
  getInputSearchFilter,
  getMultiSelectSearchFilter,
  getPriceFilter,
} from '../../components';
import { accessRoute as PriceManagementRoute } from '../../routes/PriceManagement';
import { getTagService } from '../index.service';
import { readMoreTextShow, readMoreTextShowArray } from '../../utils';
import { getPartTitleItems, partListService } from '../LineItems/index.service';

export const lineItemsBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.lineItems.heading'),
    },
  ];
};

const priceFields = {
  fromOperatorField: 'priceFromOperator',
  fromOperatorAmountField: 'priceFromAmount',
  conditionalField: 'priceCondition',
  toOperatorField: 'priceToOperator',
  toOperatorAmountField: 'priceToAmount',
};

const partCostFields = {
  fromOperatorField: 'partCostFromOperator',
  fromOperatorAmountField: 'partCostFromAmount',
  conditionalField: 'partCostCondition',
  toOperatorField: 'partCostToOperator',
  toOperatorAmountField: 'partCostToAmount',
};

export const lineItemsTableColumn = ({
  Action,
  onSort,
  onFilterChange,
  filter,
  page,
  sizePerPage,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportIdRemove: true,
    },
    {
      dataIndex: 'name',
      title: i18next.t('text.common.name'),
      headerClasses: 'sorting',
      sorter: false,
      onSort: (field, order) => onSort('name', order),
      render: (cell, row) => checkValidData(cell),
      ...getMultiSelectSearchFilter({
        dataIndex: 'name',
        name: 'name',
        list: [],
        onFilterChange,
        listService: getPartTitleItems,
        scope: 'activeLineItem',
        filter,
        listKeys: { nameKey: 'name', nameId: 'name' },
      }),
    },
    {
      dataIndex: 'price',
      filterDataIndex: ['priceCondition'],
      title: i18next.t('text.common.price'),
      headerClasses: 'sorting',
      sorter: false,
      onSort: (field, order) => onSort('price', order),
      render: (cell, row) => checkValidPrice(cell),
      exportFormatter: (cell, row) => checkValidPrice(cell),

      ...getPriceFilter({
        dataIndex: 'price',
        onFilterChange,
        fields: priceFields,
        filter,
      }),
    },
    {
      dataIndex: 'description',
      title: i18next.t('text.common.description'),
      headerClasses: 'sorting',
      sorter: false,
      onSort: (field, order) => onSort('description', order),
      render: (cell, row) =>
        checkValidData(
          readMoreTextShow(
            checkValidData(cell),
            i18next.t('text.common.description'),
            25
          )
        ),
      ...getInputSearchFilter({
        dataIndex: 'description',
        onFilterChange,
        filter,
      }),
    },

    {
      dataIndex: 'partNumber',
      title: i18next.t('text.lineItems.partNumber'),
      headerClasses: 'sorting',
      sorter: false,
      onSort: (field, order) => onSort('partNumber', order),
      exportName: 'Part Number',
      render: (cell, row) => checkValidData(cell),
      ...getMultiSelectSearchFilter({
        dataIndex: 'partNumber',
        name: 'part number',
        list: [],
        onFilterChange,
        listService: partListService,
        // scope: 'activeLineItem',
        filter,
        listKeys: { nameKey: 'partNumber', nameId: 'partNumber' },
      }),
    },
    {
      dataIndex: 'partCost',
      filterDataIndex: ['partCostCondition'],
      title: i18next.t('text.lineItems.partCost'),
      headerClasses: 'sorting',
      sorter: false,
      onSort: (field, order) => onSort('partCost', order),
      exportName: 'Part Cost',
      render: (cell, row) => checkValidPrice(cell),
      exportFormatter: (cell, row) => checkValidPrice(cell),
      ...getPriceFilter({
        dataIndex: 'price',
        onFilterChange,
        fields: partCostFields,
        filter,
      }),
    },
    {
      dataIndex: 'CustomerTags',
      filterDataIndex: ['customerTag'],
      title: i18next.t('text.lineItems.customerTag'),
      headerClasses: 'sorting',
      sort: true,
      export: true,
      onSort: (field, order) => onSort('customerTag', order),
      exportName: 'Customer Tags',
      exportKey: 'LineItemTags',
      exportFormatter: (cell, row) => {
        if (Array.isArray(row?.LineItemTags)) {
          return row.LineItemTags.filter((e) => e?.Tag?.tagType === 'customer')
            .map((e) => {
              return e?.Tag?.name;
            })
            .join(',');
        }
        return '-';
      },
      render: (cell, row) => {
        if (Array.isArray(row?.LineItemTags) && row?.LineItemTags?.length > 0) {
          return readMoreTextShowArray(
            row.LineItemTags.filter((e) => e?.Tag?.tagType === 'customer').map(
              (e, i) => {
                return (
                  <>
                    <span className="badge badge-xs badge-pill badge-dim badge-outline-success text-success">
                      {e?.Tag?.name}
                    </span>
                    <br />
                  </>
                );
              }
            ),
            'Customer Tags',
            3,
            'tags-layout'
          );
        }
        return <>-</>;
      },
      ...getMultiSelectSearchFilter({
        dataIndex: 'customerTag',
        onFilterChange,
        name: 'customer tag',
        list: [],
        listService: getTagService,
        scope: 'activeTag',
        extraQuary: { tagType: 'customer' },
        filter,
      }),
    },
    {
      dataIndex: 'JobTags',
      filterDataIndex: ['jobTag'],
      title: i18next.t('text.lineItems.jobTag'),
      headerClasses: 'sorting',
      sort: true,
      onSort: (field, order) => onSort('jobTag', order),
      exportName: 'Job Tags',
      exportKey: 'LineItemTags',
      exportFormatter: (cell, row) => {
        if (Array.isArray(row?.LineItemTags)) {
          return row.LineItemTags.filter((e) => e?.Tag?.tagType === 'job')
            .map((e) => {
              return e?.Tag?.name;
            })
            .join(',');
        }
        return '-';
      },
      render: (cell, row) => {
        if (Array.isArray(row?.LineItemTags) && row?.LineItemTags?.length > 0) {
          return readMoreTextShowArray(
            row.LineItemTags.filter((e) => e?.Tag?.tagType === 'job').map(
              (e, i) => {
                return (
                  <>
                    <span className="badge badge-xs badge-pill badge-dim badge-outline-success text-success">
                      {e?.Tag?.name}
                    </span>
                    <br />
                  </>
                );
              }
            ),
            'Job Tags',
            3,
            'tags-layout'
          );
        }
        return <>-</>;
      },
      ...getMultiSelectSearchFilter({
        dataIndex: 'jobTag',
        onFilterChange,
        name: 'job tag',
        list: [],
        listService: getTagService,
        extraQuary: { tagType: 'job' },
        scope: 'activeTag',
        filter,
      }),
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.common.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => <Action row={row} cell={cell} />,
    },
  ];
};
export const addLineItemsBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: PriceManagementRoute.LINE_ITEMS.path,
      name: i18next.t('text.lineItems.heading'),
    },
    {
      path: '#',
      name: i18next.t('text.lineItems.addLineItems'),
    },
  ];
};
export const editLineItemsBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: PriceManagementRoute.LINE_ITEMS.path,
      name: i18next.t('text.lineItems.heading'),
    },
    {
      path: '#',
      name: i18next.t('text.lineItems.editLineItems'),
    },
  ];
};
