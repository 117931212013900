import i18next from 'i18next';
import { getInputSearchFilter } from '../../components';
import {
  checkValidData,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { accessRoute } from '../../routes/Dashboard';
import { getFromToDateFilter } from '../../components/Filter/Common/date.filter';
import { getFullName, getFullNameWithRating } from '../../utils/text.util';
import { dateFormatter, readMoreTextShow } from '../../utils';
import { DATE_TH_MONTH_YEAR_TIME } from '../../config';

export const reviewsProviderBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.report.reviews'),
    },
  ];
};

export const reviewProviderColumn = ({
  onFilterChange,
  filter = {},
  onSort,
}) => {
  return [
    // {
    //   dataIndex: 'id',
    //   title: i18next.t('text.common.serialNo'),
    //   headerClasses: 'w_70',
    //   render: (cell, row, index) =>
    //     serialNumberFormatter({ page, sizePerPage, index }),
    //   exportFormatter: (cell, row, index) =>
    //     serialNumberFormatter({ page, sizePerPage, index }),
    // },
    {
      dataIndex: 'booking',
      filterDataIndex: ['bookingNo'],
      title: i18next.t('text.report.jobNo'),
      sorter: true,
      export: true,
      exportKey: 'Booking',
      exportName: 'RiderNumber',
      headerClasses: 'sorting',
      exportFormatter: (cell, row) => checkValidData(row?.Booking?.bookingNo),
      render: (cell, row) => checkValidData(row?.Booking?.bookingNo),
      ...getInputSearchFilter({
        dataIndex: 'bookingNo',
        placeholder: 'job number',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'provider',
      title: i18next.t('text.report.providerNameAvg'),
      sorter: true,
      headerClasses: 'sorting',
      export: true,
      exportKey: 'Booking',
      exportName: 'DriverName',
      exportFormatter: (cell, row) =>
        getFullName(row?.toUser?.firstName, undefined, row?.toUser?.lastName),
      onSort: (field, order) => onSort('firstName', order),
      render: (cell, row) =>
        getFullNameWithRating(
          row?.toUser?.firstName,
          undefined,
          row?.toUser?.lastName,
          row?.toUser?.avgUserRating
        ),

      ...getInputSearchFilter({
        dataIndex: 'provider',
        placeholder: 'provider name',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'customer',
      title: i18next.t('text.common.UserName'),
      sorter: true,
      headerClasses: 'sorting',
      exportKey: 'Booking',
      exportName: 'RiderName',
      exportFormatter: (cell, row) =>
        getFullName(
          row?.User?.firstName,
          undefined,
          row?.User?.lastName,
          row?.User?.CustomerMembership?.MembershipPlan?.name
        ),
      render: (cell, row) =>
        getFullName(
          row?.User?.firstName,
          undefined,
          row?.User?.lastName,
          row?.User?.CustomerMembership?.MembershipPlan?.name
        ),
      ...getInputSearchFilter({
        dataIndex: 'customer',
        placeholder: 'user name',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'rating',
      title: i18next.t('text.report.rate'),
      sorter: true,
      headerClasses: 'sorting',
      exportName: 'Rate',
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'rating',
        placeholder: 'rate',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'createdAt',
      filterDataIndex: ['startDate', 'endDate'],
      title: i18next.t('text.report.date'),
      sorter: true,
      export: true,
      headerClasses: 'sorting',
      exportKey: 'Booking',
      exportFormatter: (cell, row) =>
        dateFormatter(row?.createdAt, DATE_TH_MONTH_YEAR_TIME),
      render: (cell, row) =>
        dateFormatter(row?.createdAt, DATE_TH_MONTH_YEAR_TIME),
      //  dateFormatter(cell, 'Do MMMM, YYYY'),
      ...getFromToDateFilter({
        dataStartIndex: 'startDate',
        dataEndIndex: 'endDate',
        placeholderStartDate: 'From date',
        placeholderEndDate: 'To date',
        dateFormat: 'YYYY-MM-DD',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'review',
      title: i18next.t('text.report.comment'),
      sorter: true,

      render: (cell, row) =>
        readMoreTextShow(
          checkValidData(row?.review),
          i18next.t('text.report.comment'),
          20
        ),
      headerClasses: 'sorting',
      ...getInputSearchFilter({
        dataIndex: 'review',
        placeholder: 'comment',
        onFilterChange,
        filter,
      }),
    },
  ];
};

// User Details
export const reviewUserColumn = ({ onFilterChange, filter = {}, onSort }) => {
  return [
    // {
    //   dataIndex: 'id',
    //   title: i18next.t('text.common.serialNo'),
    //   headerClasses: 'w_70',
    //   render: (cell, row, index) =>
    //     serialNumberFormatter({ page, sizePerPage, index }),
    // },
    {
      dataIndex: 'booking',
      filterDataIndex: ['bookingNo'],
      title: i18next.t('text.report.jobNo'),
      sorter: true,
      headerClasses: 'sorting',
      exportKey: 'Booking',
      exportName: 'RiderNumber',
      exportFormatter: (cell, row) => checkValidData(row?.Booking?.bookingNo),
      render: (cell, row) => checkValidData(row?.Booking?.bookingNo),
      ...getInputSearchFilter({
        dataIndex: 'bookingNo',
        placeholder: 'job number',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'customer',
      title: i18next.t('text.report.userNameAvg'),
      sorter: true,
      headerClasses: 'sorting',
      exportKey: 'Booking',
      exportName: 'RiderName',
      exportFormatter: (cell, row) =>
        getFullName(row?.toUser?.firstName, undefined, row?.toUser?.lastName),
      render: (cell, row) =>
        getFullNameWithRating(
          row?.toUser?.firstName,
          undefined,
          row?.toUser?.lastName,
          row?.toUser?.avgUserRating,
          row?.User?.CustomerMembership?.MembershipPlan?.name
        ),
      ...getInputSearchFilter({
        dataIndex: 'customer',
        placeholder: 'user name',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'provider',
      title: i18next.t('text.common.providerName'),
      sorter: true,
      headerClasses: 'sorting',
      exportKey: 'Booking',
      exportName: 'DriverName',
      exportFormatter: (cell, row) =>
        getFullName(row?.User?.firstName, undefined, row?.User?.lastName),
      render: (cell, row) =>
        getFullName(row?.User?.firstName, undefined, row?.User?.lastName),
      ...getInputSearchFilter({
        dataIndex: 'provider',
        placeholder: 'provider name',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'rating',
      title: i18next.t('text.report.rate'),
      sorter: true,
      headerClasses: 'sorting',
      exportName: 'Rate',
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'rating',
        placeholder: 'rate',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'createdAt',
      filterDataIndex: ['startDate', 'endDate'],
      title: i18next.t('text.report.date'),
      sorter: true,
      headerClasses: 'sorting',
      exportKey: 'Booking',
      exportFormatter: (cell, row) =>
        dateFormatter(row?.createdAt, 'Do MMMM, YYYY, HH:mm A'),
      render: (cell, row) =>
        dateFormatter(row?.createdAt, 'Do MMMM, YYYY, HH:mm A'),
      ...getFromToDateFilter({
        dataStartIndex: 'startDate',
        dataEndIndex: 'endDate',
        placeholderStartDate: 'From date',
        placeholderEndDate: 'To date',
        dateFormat: 'YYYY-MM-DD',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'review',
      title: i18next.t('text.report.comment'),
      sorter: true,
      headerClasses: 'sorting',
      render: (cell, row) =>
        readMoreTextShow(
          checkValidData(row?.review),
          i18next.t('text.report.comment'),
          20
        ),
      ...getInputSearchFilter({
        dataIndex: 'review',
        placeholder: 'comment',
        onFilterChange,
        filter,
      }),
    },
  ];
};
