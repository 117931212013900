import React, { useEffect, useContext, memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ChatContext } from '../../../context/chat.context';

import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const ChatSearch = ({
  search,
  handleSearch,
  getSearchList,
  setEmptyList,
  ...props
}) => {
  const { t } = useTranslation();
  const { isSearch, setIsSearch } = useContext(ChatContext);

  useEffect(() => {
    let getData;
    if (search || isSearch) {
      getData = setTimeout(() => {
        setEmptyList([]);
        getSearchList?.();
        setIsSearch(!search && isSearch ? false : true);
      }, 1200);
    }
    return () => clearTimeout(getData);
  }, [search]);

  return (
    <Input
      placeholder={t('text.chat.search')}
      size="large"
      prefix={<SearchOutlined />}
      value={search}
      onChange={handleSearch}
      {...props}
    />
  );
};

ChatSearch.propTypes = {
  search: PropTypes.string,
  handleSearch: PropTypes.func,
  getSearchList: PropTypes.func,
  setEmptyList: PropTypes.func,
};

export default memo(ChatSearch);
