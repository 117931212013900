import { QUOTE_DISCOUNT_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';

export const getQuoteDiscountService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...QUOTE_DISCOUNT_ENDPOINT?.getQuoteDiscountList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const deleteQuoteDiscountService = async ({ bodyData }) => {
  try {
    const payload = {
      ...QUOTE_DISCOUNT_ENDPOINT.deleteQuoteDiscount,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const updateQuoteDiscountStatus = async (bodyData) => {
  try {
    const payload = {
      ...QUOTE_DISCOUNT_ENDPOINT.statusUpdateQuoteDiscount,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const addQuoteDiscountService = async ({ bodyData }) => {
  try {
    const payload = {
      ...QUOTE_DISCOUNT_ENDPOINT.addQuoteDiscount,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const editQuoteDiscountService = async ({ bodyData, id }) => {
  try {
    const payload = {
      ...QUOTE_DISCOUNT_ENDPOINT.editQuoteDiscount(id),
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getQuoteDiscountDetailsService = async ({ id }) => {
  try {
    const payload = {
      ...QUOTE_DISCOUNT_ENDPOINT.getQuoteDiscountDetails(id),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
