import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageContainerWrapper, TablePlus } from '../../../components';
import useTable from '../../../hooks/useTable.hook';
import {
  requestBreadcrumb,
  requestTableColumn,
} from '../../../services/Columns';
import { getRequestService } from '../../../services/Request/request.service';

function ListRequest() {
  const { t } = useTranslation();
  const [tableHandler, tableProps] = useTable(getRequestService);
  const {
    data,
    isLoading,
    count,
    page,
    noOfPages,
    sizePerPage,
    filter,
    sortBy,
    sortType,
  } = tableProps;
  const { onTableSort, onFilterResetChange } = tableHandler;
  const breadcrumb = requestBreadcrumb();
  const columns = requestTableColumn({
    onSort: onTableSort,
    page,
    sizePerPage,
  });

  return (
    <PageContainerWrapper
      heading={t('text.request.title')}
      breadcrumb={breadcrumb}
      listingHeader={false}
    >
      <TablePlus
        hasLimit
        noOfPage={noOfPages}
        sizePerPage={sizePerPage}
        page={page}
        count={count}
        tableData={data}
        tableColumns={columns}
        selectRow
        tableLoader={isLoading}
        onFilterResetChange={onFilterResetChange}
        showAction={false}
        searchField
        resetAll={false}
        filter={filter}
        sortBy={sortBy}
        sortType={sortType}
      />
    </PageContainerWrapper>
  );
}

export default ListRequest;
