import {
  BookingView,
  ListServiceManagement,
  ServiceManagementInvoice,
  ServiceManagementViewQuote,
} from '../../views/index.view';
import { getActions } from '../../services/index.service';

export default function route() {
  return [
    {
      private: true,
      name: accessRoute.LIST_SERVICE.name,
      path: accessRoute.LIST_SERVICE.path,
      key: accessRoute.LIST_SERVICE.key,
      belongsToSidebar: true,
      icon: accessRoute.LIST_SERVICE.icon,
      element: <ListServiceManagement />,
      ...accessRoute.LIST_SERVICE,
    },
    {
      private: true,
      path: accessRoute.BOOKING_VIEW.path,
      name: 'View Booking',
      key: accessRoute.BOOKING_VIEW.key,
      belongsToSidebar: false,
      icon: accessRoute.BOOKING_VIEW.icon,
      element: <BookingView />,
      ...accessRoute.BOOKING_VIEW,
    },
    {
      private: true,
      path: accessRoute.SERVICE_MANAGEMENT_INVOICE.path,
      name: 'Service Management Invoice',
      key: accessRoute.SERVICE_MANAGEMENT_INVOICE.key,
      belongsToSidebar: false,
      element: <ServiceManagementInvoice />,
      ...accessRoute.SERVICE_MANAGEMENT_INVOICE,
    },
    {
      private: true,
      path: accessRoute.SERVICE_MANAGEMENT_QUOTE.path,
      name: 'Service Management Quote',
      key: accessRoute.SERVICE_MANAGEMENT_QUOTE.key,
      belongsToSidebar: false,
      element: <ServiceManagementViewQuote />,
      ...accessRoute.SERVICE_MANAGEMENT_QUOTE,
    },
  ];
}

export const accessRoute = {
  LIST_SERVICE: {
    path: '/admin/service',
    name: 'Service Management',
    moduleKey: 'service-management',
    key: 'service-management',
    action: getActions('LIST').value,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-wallet-fill" />
      </span>
    ),
    order: 9,
  },
  BOOKING_VIEW: {
    path: '/admin/service/booking/:id/:viewQuote',
    key: 'service-management',
    moduleKey: 'service-management',
    action: getActions('LIST').value,
  },
  SERVICE_MANAGEMENT_INVOICE: {
    path: '/admin/service/invoice/:id/:viewInvoice',
    key: 'service-management',
    moduleKey: 'service-management',
    action: getActions('LIST').value,
  },
  SERVICE_MANAGEMENT_QUOTE: {
    // path: '/admin/service/invoice',
    path: '/admin/service/quote-details/:id',
    key: 'service-management',
    moduleKey: 'service-management',
    action: getActions('LIST').value,
  },
};

export const getChildrenRoutes = () => {
  return route();
};
