export const text = {
  title: 'Unaccepted Requests',
  jobForLast: 'Jobs For last 3 Months',
  registeredUser: 'Registered Users For last 3 Months',
  jobs: 'Jobs',
  providers: 'Providers',
  providerLogReport: 'Provider Log Report Of Current Month (In Hours)',
  footer: '* This is count for all Jobs (Finished, ongoing, cancelled.)',
  finishedJobs: 'Finished Jobs',
  cancelledJobs: 'Cancelled Jobs',
  users: 'Users',
  activeProviders: 'Active Providers',
  pendingProviders: 'Pending Providers',
  providerCount: `# of Providers Count Data`,
  completedJobs: 'Completed Jobs',
  onGoingJobs: 'On Going Jobs',
  countData: '# of Jobs Count Data',
};
