export const validation = {
  address: 'Address is required',
  gender: 'Select gender',
  profileImage: 'Profile image is required',
  country: 'Country is required',
  field: 'This field is required.',
  notes: 'Notes is required',
  state: 'State is required. ',
  city: 'City is required.',
  zipCode: 'Zip code is required',
  name: 'Name is required',
  bankName: ' Bank Name is required',
  checkNumber: 'Check number is required',
  cardHolder: 'Card holder name is required',
  cardNumber: 'Card number is required',
  cvv: 'cvv number is required',
  expireYear: 'Select expire year',
  expireMonth: 'Select expire month',
  dateOfBirth: 'Select date of birth',
  companyName: ' Company name is required',
  homeAddress: ' Home address is required',
  employerId: ' Employer id number is required',
  uploadDocument: 'Please upload documents',
  userRole: 'Select user role',
  location: 'Location is required',
  documentName: ' Document name is required',
  roleName: ' Role name is required',
  roleAssign: 'Select role assign',
  description: ' Description is required ',
  status: 'Select status',
  image: 'Image is required',
  title: 'Title is required',
  order: 'Select order',
  englishTitle: 'English purpose is required',
  englishContent: 'English body is required',
  firstName: 'First name is required',
  lastName: 'Last name is required',
  noOfMonth: 'Select month',
  membership: 'Select membership',
  phone: 'Phone number is required',
  maxValid: 'Please enter less than 100 characters.',
  maxValid50: 'Please enter less than 50 characters.',
  maxValid250: 'Please enter less than 250 characters.',
  maxValid20: 'Please enter less than 20 characters.',
  maxValid255: 'Please enter less than 255 characters.',

  maxNameValid: 'Please enter less than 30 characters.',
  email: 'Email is required',
  validEmail: 'Please enter valid email address',
  password: 'Password is required',
  minValidPassword: 'Password at least 6 characters long.',
  maxValidPassword: 'Please enter less than 16 characters.',
  strongPassword:
    'Password should be between 6 and 15 characters with one upper case letter, one number, and one special character(@#$%^&+!=).',
  phoneValid: 'Please enter valid phone number',
  zipCodeValid: 'Please enter valid zip code',
  price: 'Price is required',
  maxAddress: 'Address should be less than 100',
  maxName: 'Name should be less than 30',
  minFirstName: 'First name at least 2 characters long',
  minLastName: 'Last name at least 2 characters long',
  invalidZip: 'Zip code is required ',
  suiteNumber: 'Suite number should be less than 50',
  minSuiteNumber: 'Suite number must be at least 3 characters long',
  emailMax: 'Email should be less than 50',
  customerTaqRequire: 'Select customer tag',
  jobTaqRequire: 'Select job tag',
  customerTaqMax: 'Customer tag should be less than 15',
  jobTaqMax: 'Job tag should be less than 15',
  onlyNumber: 'Please enter only digits.',
  websiteUrl: 'Please enter valid url',
  noMoreThanNineDigits: 'Please enter no more than 9 digits.',
  enterDescription: 'Description is required',
  selectSubcategory: ' Select subcategory',
  pleaseEnterCategory: 'Category is required',
  pleaseCategoryMax: 'Category should be less than 100 words',
  pleaseEnterCategoryDescription: 'Category description is required',
  pleaseEnterSubcategoryPrice: 'Subcategory price is required',
  pleaseEnterSubcategoryDescription: 'Subcategory description is required',
  pleaseEnterSubcategory: 'Subcategory is required',
  pleaseEnterSubject: 'Subject is required',
  subjectMax: 'Subject should be less than 60 words',
  pleaseEnterBody: 'Body is required',
  pleaseEnterPurpose: 'Purpose is required',
  purposeMax: 'Purpose should be less than 100 words',
  selectCountryCode: 'Select country code',
  // minPhone: 'Please enter at least 10 digit Number',
  minPhone: 'Please enter valid phone number',
  startDateRequired: 'Start date is required',
  endDateRequired: 'End date is required',
  descriptionRequired: 'Description is required',
  nameReq: 'Name is required',
  subCatReq: 'Sub Category is required',
  suiteReq: 'Suite number is required',
  cityReq: 'City is required',
  countryReq: 'Country is required',
  stateReq: 'State is required',
  address1Req: 'Address Line1 is required',
  zipcodeReq: 'Zipcode is required',
  confirmPasswordReq: 'Confirm password is required',
  newPasswordReq: 'New password is required',
  currentPasswordReq: 'Current password is required',
  noWhiteSpaceAllowed: 'No white space allowed',
  twoDecimalAllowed:
    'The amount should be a decimal with maximum two digits after comma',
  selectLocation: 'Location is required',
  nameOrCompanyName: 'Name or company name is required',
  budget: 'Budget is required',
  minName: 'Name at least 2 characters long',
  companyAddress: 'Company address is required.',
  numberOfficeStaff: 'Number of office staff is required.',
  techniciansInstallers: 'Number of field technicians/installers is required.',
  areaServed: 'Area served is required.',
  taxNegative: 'Tax should not be negative',
  taxMoreThan: 'Tax should not be greater than 100 %',
  matchPassword: 'Password and confirm password should match',
  validMonth: 'Only valid month is allowed',
  positiveNumber: 'Valid value is required',
  validNumber: 'Please enter valid number',
  price10Char: 'Price should not be greater than 10 characters',
  oldPasswordReqV2: 'Old password field is required',
  confirmPasswordSame: 'Confirm password should be same as new password',

  numberNegative: 'Number should not be negative',
  officeMoreThan: 'Number of office should not be greater than 10000',
  techniciansMoreThan:
    'Number of field technicians/installers should not be greater than 10000',
  positive: 'Values should be positive number',
  maxValidNine: 'Please enter less than 9 digit',
  minValidNine: 'Please enter minimum 9 digit.',
  expireDateRequired: 'Expire date is required',
  UrlValid: 'Please enter valid url',
  androidApp: 'Android app link is required',
  iphoneApp: 'Iphone app link is required',
  atLeastOneEmail: 'Please add at least one email.',
  discount: 'Discount is required',
  number: 'Value should be  number',
  priceNegative: 'Price should not be negative',
  costPositive: 'Cost should not be negative',
  fieldRequired: 'This field is required',
  onlyIntNumber: 'Please enter only integer digits.',
  greaterZero: 'Please enter value greater then zero.',
  amountValid: 'Amount must be number',
  amountPositive: 'Amount should not be negative number',
  maxAmount: 'Amount should not be greater than 100 %',
  amountGreater: 'Amount should be greater than 0',
  maxDescription:
    'Description length must be less than or equal to 500 characters long',
  minCouponCode: 'Please enter greater than 0 used limit',
  maxCouponCode: 'Please enter Less than 10000 used limit',
  bulkUploadImage: 'Image is required.',
  onlyWholeNumber: 'Usage Limit must be an integer',
  descriptionLimit: 'Description must not be greater than 5000 characters',
};
