import React, { useEffect, useState } from 'react';
import { Button, Space, DatePicker } from 'antd';
import { dateFormatter, disabledEndDate } from '../../../utils';
import { FilterFilled } from '@ant-design/icons';
import { useRef } from 'react';
import moment from 'moment';

function DateFilter({
  clearFilters,
  onFilterChange,
  placeholderStartDate,
  placeholderEndDate,
  dataStartIndex,
  dataEndIndex,
  dateFormat,
  confirm,
  selectedKeys,
  setSelectedKeys,
  dataIndex,
  picker,
  ...rest
}) {
  const searchInput = useRef(null);
  const [dateObj, setDateObj] = useState({
    startDate: '',
    endDate: '',
  });
  let { startDate, endDate } = dateObj;

  const handleSearch = async (selectedKeys, confirm, dataIndex) => {
    try {
      await onFilterChange({
        ...(startDate && {
          [dataStartIndex]: dateFormatter(startDate, dateFormat),
        }),
        ...(endDate && {
          [dataEndIndex]: dateFormatter(endDate, dateFormat),
        }),
      });
      setSelectedKeys([startDate]);
      confirm();
    } catch (err) {
      console.log(err);
    }
  };

  const handleReset = async (clearFilters, confirm) => {
    try {
      await onFilterChange({
        [dataStartIndex]: null,
        [dataEndIndex]: null,
      });
      setDateObj({});
      clearFilters();
      confirm();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setDateObj({
      ...dateObj,
      startDate: rest?.filter?.fromDate
        ? moment(rest.filter.fromDate)
        : rest?.filter?.[dataStartIndex]
        ? moment(rest.filter[dataStartIndex])
        : '',
      endDate: rest?.filter?.toDate
        ? moment(rest.filter.toDate)
        : rest?.filter?.[dataEndIndex]
        ? moment(rest.filter[dataEndIndex])
        : '',
    });
  }, [rest?.filter]);

  return (
    <div
      style={{
        padding: 8,
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <DatePicker
        name={dataStartIndex}
        className="form-control date-picker shadow-none"
        placeholder={placeholderStartDate}
        style={{
          marginBottom: 8,
          display: 'block',
        }}
        value={startDate}
        onChange={(date) => {
          setDateObj({
            ...dateObj,
            startDate: date,
          });
        }}
        ref={searchInput}
        picker={picker}
      />
      <DatePicker
        name={dataEndIndex}
        className="form-control date-picker shadow-none"
        placeholder={placeholderEndDate}
        style={{
          marginBottom: 8,
          display: 'block',
        }}
        value={endDate}
        disabledDate={(current) => disabledEndDate(current, startDate)}
        onChange={(date) => {
          setDateObj({
            ...dateObj,
            endDate: date,
          });
        }}
        ref={searchInput}
        picker={picker}
      />
      <Space>
        <Button
          type="primary"
          className="btn btn-primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            width: 90,
          }}
        >
          Search
        </Button>
        <Button
          onClick={() => clearFilters && handleReset(clearFilters, confirm)}
          className="btn btn-info"
          style={{
            width: 90,
          }}
        >
          Reset
        </Button>
      </Space>
    </div>
  );
}

export default DateFilter;

export function getFromToDateFilter({
  onFilterChange,
  placeholderStartDate,
  placeholderEndDate,
  dataStartIndex,
  dataEndIndex,
  dateFormat,
  dataIndex,
  filter,
  picker,
  ...rest
}) {
  return {
    filterDropdown: (props) => (
      <DateFilter
        onFilterChange={onFilterChange}
        placeholderStartDate={placeholderStartDate}
        placeholderEndDate={placeholderEndDate}
        dataStartIndex={dataStartIndex}
        dataEndIndex={dataEndIndex}
        dateFormat={dateFormat}
        dataIndex={dataIndex}
        filter={filter}
        picker={picker}
        {...props}
        {...rest}
      />
    ),
    filterIcon: (filtered) => (
      <>
        <FilterFilled
          type="search"
          style={{ color: filtered ? '#F47721' : undefined }}
        />
      </>
    ),
    onFilterDropdownVisibleChange: (visible) => {},
  };
}
