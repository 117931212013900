export const getApiData = async ({
  setIsLoading,
  setData,
  service,
  id = '',
  queryParams = '',
  setRedux = false,
  dispatch = false,
}) => {
  try {
    if (setIsLoading) setIsLoading(true);
    let getService = null;
    if (id) getService = service(id);
    else getService = service(queryParams);
    const { data, success } = await getService;
    if (success && data) {
      if (setData) setData(data);
      if (dispatch) dispatch(setRedux({ ...data }));
      if (setIsLoading) setIsLoading(false);
      return data;
    } else {
      setData(null);
    }
  } catch (error) {
    console.log(error);
  }
  if (setIsLoading) setIsLoading(false);
};
