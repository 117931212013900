import { REPORT_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';

export const getUserService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...REPORT_ENDPOINT?.getUserReview,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getProviderService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...REPORT_ENDPOINT?.getProviderReview,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getDataOfSubLabour = async ({ queryParams }) => {
  let { bookingId, ...extraQuery } = queryParams;
  try {
    const payload = {
      ...REPORT_ENDPOINT?.getSubLabour(bookingId),
      queryParams: extraQuery,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getCreateSubLabour = async ({ bodyData }) => {
  try {
    const payload = {
      ...REPORT_ENDPOINT?.getCreatePurchase,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
export const getProviderInvoiceReportDetailsService = async ({
  queryParams,
  type,
}) => {
  const bookingId = queryParams.bookingId;
  const queryParamsObj = { ...queryParams };
  delete queryParamsObj.bookingId;
  try {
    const payload = {
      ...REPORT_ENDPOINT?.getProviderInvoiceReportDetailsById(bookingId),
      queryParams: queryParamsObj,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const deleteSubLaborService = async ({ bodyData }) => {
  try {
    const payload = {
      ...REPORT_ENDPOINT?.deleteSubLabor,
      bodyData: bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
export const getProviderPaymentReportService = async ({
  queryParams,
  type,
}) => {
  try {
    const payload = {
      ...REPORT_ENDPOINT?.getProviderPaymentReport,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};
export const getProviderPaymentReportTotalSublaborPayService = async ({
  queryParams,
  type,
}) => {
  const providerUserId = queryParams.providerUserId;

  try {
    const payload = {
      ...REPORT_ENDPOINT?.getProviderPaymentReportTotalSublaborPay(
        providerUserId
      ),
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getInvoicePayDetailsByIdService = async (bookingId, isSecure) => {
  try {
    let isAuth = isSecure === 'True' ? true : JSON.parse(isSecure);
    let endPoint = isAuth
      ? REPORT_ENDPOINT.getPublicInvoicePayDetailsById(bookingId)
      : REPORT_ENDPOINT.getInvoicePayDetailsById(bookingId);

    const payload = {
      ...endPoint,
      isAdminToken: true,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const postBookingPaymentService = async (bodyData) => {
  try {
    const payload = {
      ...REPORT_ENDPOINT?.postBookingPayment,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getPaymentReportExcelService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...REPORT_ENDPOINT?.getPaymentReportExcel,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};
