import { Empty, Select } from 'antd';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  ActionWrapper,
  CommonButton,
  FormElementWrapper,
  PageContainerWrapper,
  TablePlus,
} from '../../../../components';
import useTable from '../../../../hooks/useTable.hook';
import { accessRoute } from '../../../../routes/GoogleConnect';
import {
  getAccountListService,
  getBusinessListService,
  getGoogleTokenService,
} from '../../../../services/GoogleConnect/googleGMB.service';
import { Columns, getActions } from '../../../../services/index.service';
import { navigateWithParam } from '../../../../utils';

function GMBListing() {
  const [globalFilter, setGlobalFilter] = useState(false);
  const [accountListData, setAccountListData] = useState([]);
  const [businessListData, setBusinessListData] = useState([]);
  const [accountListLoading, setAccountListLoading] = useState(false);
  const [businessListLoading, setBusinessListLoading] = useState(false);
  const [account, setAccount] = useState();
  const [nextPageToken, setNextPageToken] = useState('');
  const [token, setToken] = useState('');
  const breadcrumb = Columns.googleGMBBreadcrumb();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  function onAction(e, handler, url) {
    if (!url) {
      e.preventDefault();
      handler();
    }
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler, item?.url)}
              key={key}
              className="action_list"
              action={item?.action}
            >
              {item?.url ? (
                <a href={item?.url} target="_blank" rel="noreferrer">
                  <em className={item.icon} />
                  {item.name}
                </a>
              ) : (
                <Link to="#">
                  <em className={item.icon} />
                  {item.name}
                </Link>
              )}
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  const BackBtnFilter = () => {
    setGlobalFilter(false);
  };
  const OpenBtnFilter = () => {
    setGlobalFilter(true);
  };
  if (globalFilter) {
    document.querySelector('body').classList.add('toggle-shown');
  } else {
    document.querySelector('body').classList.remove('toggle-shown');
  }

  const headerBtn = {
    add: {
      name: (
        <>
          <em className="icon ni ni-filter-fill me-1" />{' '}
          {t('text.common.filter')}
        </>
      ),
      handler: () => {
        OpenBtnFilter();
      },
    },
  };

  function ActionFormatter({ row }) {
    return actionFormatter([
      {
        name: t('text.googleConnect.seeYourProfile'),
        icon: 'icon ni ni-eye',
        action: getActions('VIEW').value,
        url: row?.business?.metadata?.newReviewUri,
        handler: () => {},
      },
      {
        name: t('text.googleConnect.photos'),
        icon: 'icon ni ni-camera',
        action: getActions('VIEW').value,
        handler: () =>
          navigateWithParam(
            {
              name: row?.business?.name,
              accountId: account,
            },
            navigate,
            accessRoute.GOOGLE_GMB_PHOTO.path
          ),
      },
      {
        name: t('text.googleConnect.post'),
        icon: 'icon ni ni-file-docs',
        action: getActions('VIEW').value,
        handler: () => {
          navigateWithParam(
            {
              name: row?.business?.name,
              accountId: account,
            },
            navigate,
            accessRoute.GOOGLE_GMB_POST.path
          );
        },
      },
    ]);
  }
  const columns = Columns.googleGMBServiceTableColumn({
    Action: ActionFormatter,
    account,
    navigate,
  });

  const checkGoogleRefreshToken = async () => {
    try {
      let queryParams = {};
      const response = await getGoogleTokenService({ queryParams });
      const { data } = response;
      if (data === true) {
        localStorage.removeItem('googleAuth');
        getAccountList();
      } else {
        localStorage.setItem('googleAuth', location?.pathname);
        window.open(data?.url, '_self');
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    checkGoogleRefreshToken();
  }, []);

  async function getAccountList() {
    setAccountListLoading(true);
    try {
      const response = await getAccountListService();
      const { data, success } = response;
      if (success) {
        setAccount(data?.accounts[0]?.name);
        setAccountListData(data?.accounts);
        handleFilter(data?.accounts[0]?.name);
      }
    } catch (err) {
      console.log(err);
    }
    setAccountListLoading(false);
  }

  const handleFilter = (id) => {
    try {
      setAccount(id);
      getBusinessData(id);
      setGlobalFilter(false);
    } catch (err) {
      console.log(err);
    }
  };

  async function getBusinessData(value) {
    setBusinessListLoading(true);
    try {
      let queryParams = {
        accountId: value,
        nextPageToken,
      };
      const response = await getBusinessListService({ queryParams });
      const { data, success } = response;
      if (success) {
        setBusinessListData(data);
        setToken(data?.nextPageToken);
      }
    } catch (err) {
      console.log(err);
    }
    setBusinessListLoading(false);
  }

  useEffect(() => {
    if (nextPageToken) {
      getBusinessData(account);
    }
  }, [nextPageToken]);

  return (
    <>
      <PageContainerWrapper
        heading={t('text.googleConnect.titleGMB')}
        breadcrumb={breadcrumb}
        btn={headerBtn}
        extraClass="btn btn-primary d-block d-xl-none"
      >
        <div className="">
          <div className="globalFilters">
            <div className="card ">
              <div className="card-inner">
                <div
                  className={`globalFilters_inner ${
                    globalFilter ? 'filterToggle' : ''
                  }`}
                >
                  <div className="mb-3 d-block d-xl-none">
                    <Link to="#" onClick={() => BackBtnFilter()}>
                      <em className="icon ni ni-arrow-long-left me-1"></em> Back
                    </Link>
                  </div>
                  <FormElementWrapper
                    sm={12}
                    xl={3}
                    xxl={2}
                    element={(formik) => (
                      <div style={{ position: 'relative' }}>
                        <Select
                          name="account"
                          placeholder="Select google GMB"
                          options={accountListData?.map((item) => {
                            return {
                              value: item?.name,
                              label: item?.accountName,
                            };
                          })}
                          value={account}
                          showSearch
                          onChange={handleFilter}
                          loading={accountListLoading}
                          getPopupContainer={(trigger) => trigger.parentElement}
                        />
                      </div>
                    )}
                  />
                </div>
                <div
                  className={` ${globalFilter ? 'toggle-overlay' : ''} `}
                  onClick={() => BackBtnFilter()}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <TablePlus
          header={false}
          hasLimit
          // noOfPage={noOfPages}
          // sizePerPage={sizePerPage}
          // page={page}
          // count={count}
          tableData={businessListData}
          tableColumns={columns}
          selectRow
          // onFilterResetChange={onFilterResetChange}
          showAction={false}
          statusAction
          tableLoader={businessListLoading}
          setSizePerPage=""
          isSearch={false}
          showPageSizeAction={false}
          pagination={false}
          locale={{
            emptyText: <Empty description={t('text.common.noDataFound')} />,
          }}
        />
        {token && (
          <CommonButton
            className="btn btn-primary float-end mt-3"
            type="submit"
            onClick={() => setNextPageToken(token)}
          >
            {t('text.googleConnect.next')}
          </CommonButton>
        )}
      </PageContainerWrapper>
    </>
  );
}

export default GMBListing;
