import i18next from 'i18next';
import { accessRoute } from '../../routes/Dashboard';
import { accessRoute as pageRoute } from '../../routes/AdminSetting';
import { getInputSearchFilter } from '../../components';
import {
  checkValidData,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';

export const pageList = ({
  onFilterChange,
  filter,
  Action,
  url,
  onSort,
  t,
  page,
  sizePerPage,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'name',
      title: t('text.common.name'),
      headerClasses: 'sorting',
      render: (cell, row) => checkValidData(row?.name),
      sorter: true,
      onSort: (field, order) => onSort('pageSection', order),
      ...getInputSearchFilter({
        dataIndex: 'pageSection',
        placeholder: 'name',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'pageTitle',
      filterDataIndex: ['search'],
      title: t('text.page.pageTitle'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('pageTitle', order),
      render: (cell, row) => checkValidData(row?.pageTitle),
      ...getInputSearchFilter({
        dataIndex: 'search',
        placeholder: 'page title',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'id',
      title: i18next.t('text.common.action'),
      export: false,
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => <Action cell={cell} row={row} />,
    },
  ];
};
export const pageBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: 'Pages',
    },
  ];
};

export const pageEditBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: pageRoute.LIST_PAGE.path,
      name: 'Pages',
    },
    {
      path: '#',
      name: 'Edit Page',
    },
  ];
};
