import { Button, Space } from 'antd';
import Input from 'antd/lib/input/Input';
import React, { useEffect, useRef, useState } from 'react';
import { FilterFilled } from '@ant-design/icons';
import { t } from 'i18next';

export default function InputPriceFilterComponent({
  clearFilters,
  onFilterChange,
  placeholderStartPrice,
  placeholderEndPrice,
  dataStartIndex,
  dataEndIndex,
  confirm,
  selectedKeys,
  setSelectedKeys,
  dataIndex,
  picker,
  filter,
  ...rest
}) {
  // const { t } = useTranslation();
  const searchInput = useRef(null);
  const [priceObj, setPriceObj] = useState({
    [dataStartIndex]: '',
    [dataEndIndex]: '',
  });

  const handleSearch = async (_, confirm, dataIndex) => {
    try {
      await onFilterChange({
        ...(priceObj?.[dataStartIndex] && {
          [dataStartIndex]: priceObj?.[dataStartIndex],
        }),
        ...(priceObj?.[dataEndIndex] && {
          [dataEndIndex]: priceObj?.[dataEndIndex],
        }),
      });
      setSelectedKeys(priceObj);
      confirm();
    } catch (err) {
      console.log(err);
    }
  };

  const handleReset = async (clearFilters, confirm) => {
    try {
      await onFilterChange({
        [dataStartIndex]: null,
        [dataEndIndex]: null,
      });
      setSelectedKeys(undefined);
      setPriceObj({
        [dataStartIndex]: '',
        [dataEndIndex]: '',
      });
      clearFilters();
      confirm();
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    setPriceObj({
      ...priceObj,
      [dataStartIndex]: priceObj?.[dataStartIndex]
        ? filter?.[dataStartIndex]
        : '',
      [dataEndIndex]: priceObj?.[dataEndIndex] ? filter?.[dataEndIndex] : '',
    });
  }, [filter]);
  return (
    <div
      style={{
        padding: 8,
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Input
        ref={searchInput}
        placeholder={`${placeholderStartPrice || dataStartIndex}`}
        className="ant-input form-control"
        value={priceObj?.[dataStartIndex]}
        onChange={(e) => {
          setPriceObj({
            ...priceObj,
            [dataStartIndex]: e?.target?.value,
          });
        }}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataStartIndex)}
        style={{
          marginBottom: 8,
          display: 'block',
        }}
      />
      <Input
        ref={searchInput}
        placeholder={`${placeholderEndPrice || dataEndIndex}`}
        className="ant-input form-control"
        value={priceObj?.[dataEndIndex]}
        onChange={(e) => {
          setPriceObj({
            ...priceObj,
            [dataEndIndex]: e?.target?.value,
          });
        }}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataEndIndex)}
        style={{
          marginBottom: 8,
          display: 'block',
        }}
      />
      <Space>
        <Button
          type="primary"
          className="btn btn-primary"
          onClick={() => handleSearch(selectedKeys, confirm, '')}
          style={{
            width: 90,
          }}
        >
          {t('text.common.search').toUpperCase()}
        </Button>
        <Button
          onClick={() => clearFilters && handleReset(clearFilters, confirm)}
          className="btn btn-info"
          style={{
            width: 90,
          }}
        >
          {t('text.common.reset').toUpperCase()}
        </Button>
      </Space>
    </div>
  );
}

export function getColumnSearchMultipleInputProps({
  onFilterChange,
  placeholderStartPrice,
  placeholderEndPrice,
  dataStartIndex,
  dataEndIndex,
  dataIndex,
  filter,
  picker,
  ...rest
}) {
  return {
    filterDropdown: (props) => (
      <InputPriceFilterComponent
        onFilterChange={onFilterChange}
        placeholderStartPrice={placeholderStartPrice}
        placeholderEndPrice={placeholderEndPrice}
        dataStartIndex={dataStartIndex}
        dataEndIndex={dataEndIndex}
        dataIndex={dataIndex}
        filter={filter}
        picker={picker}
        {...props}
        {...rest}
      />
    ),
    filterIcon: (filtered) =>
      filter ? (
        <span className="reset-filter">
          <FilterFilled
            type="search"
            style={{ color: filtered ? '#F47721' : undefined }}
          />
        </span>
      ) : (
        <FilterFilled
          type="search"
          style={{ color: filtered ? '#F47721' : undefined }}
        />
      ),
    onFilterDropdownVisibleChange: () => {},
  };
}
