import i18next from 'i18next';
import { accessRoute } from '../../routes/Dashboard';
import { accessRoute as customerRoute } from '../../routes/Customers';
import {
  checkValidData,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { getFullName } from '../../utils/text.util';

export const userAddressTableColumn = ({ Action, page, sizePerPage }) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'name',
      title: i18next.t('text.common.name'),
      // headerClasses: 'sorting',
      render: (cell, row) => checkValidData(getFullName(cell)),
    },
    {
      dataIndex: 'addressLine1',
      title: i18next.t('text.common.address'),
      // headerClasses: 'sorting',
      render: (cell, row) => checkValidData(cell),
    },
    // {
    //   dataIndex: 'landmark',
    //   title: i18next.t('text.common.landmark'),
    //   // headerClasses: 'sorting',
    //   formatter: (cell, row) => checkValidData(cell),
    // },
    {
      dataIndex: 'State',
      title: i18next.t('text.common.state'),
      // headerClasses: 'sorting',
      render: (cell, row) => checkValidData(row?.state),
    },
    {
      dataIndex: 'City',
      title: i18next.t('text.common.city'),
      // headerClasses: 'sorting',
      render: (cell, row) => checkValidData(row?.city),
    },
    {
      dataIndex: 'zipCode',
      title: i18next.t('text.common.zipCode'),
      // headerClasses: 'sorting',
      render: (cell, row) => checkValidData(cell),
    },
    {
      dataIndex: 'update',
      title: i18next.t('text.common.action'),
      className: 'ant-table-cell nk-tb-col-tools text-end',
      render: (cell, row) => {
        return <Action row={row} cell={cell} />;
      },
    },
  ];
};
export const userAddressBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: customerRoute.CUSTOMERS.path,
      name: i18next.t('text.customers.heading'),
    },
    {
      path: '#',
      name: i18next.t('text.customers.userAddress'),
    },
  ];
};
