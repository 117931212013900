import { API_ENDPOINT_V1 } from '../../config/api.config';

export const COMMON_ENDPOINT = {
  getCountryList: {
    url: '/v1/admin/country',
    method: 'GET',
  },
  uploadImageFile: (type, mediaFor, accept) => ({
    url: `${API_ENDPOINT_V1}/v1/media/upload/${mediaFor}/${type}`,
    method: 'POST',
    accept: accept,
  }),
  uploadPublicImageFile: (type, mediaFor, accept) => ({
    url: `${API_ENDPOINT_V1}/v1/public/media/upload/${mediaFor}/${type}`,
    method: 'POST',
    accept: accept,
  }),
  getNotificationList: {
    url: '/v1/admin/notification',
    method: 'GET',
  },
  getSetting: (type) => ({
    url: `v1/admin/general-setting`,
    method: 'GET',
  }),
  getCountry: {
    url: '/v1/country',
    method: 'GET',
  },
  sendEmail: (id) => ({
    url: `/v1/admin/bookings/receipt/${id}`,
    method: 'GET',
  }),
  uploadUsedImageFile: (type, mediaFor, accept) => ({
    url: `${API_ENDPOINT_V1}/v1/media/upload-used/${mediaFor}/${type}`,
    method: 'POST',
    accept: accept,
  }),
  resetPassword: {
    url: '/v1/reset-password',
    method: 'POST',
  },
  resetPasswordGet: (token) => ({
    url: `/v1/user/forgot/${token}`,
    method: 'GET',
  }),
  logout: {
    url: '/v1/logout',
    method: 'GET',
  },
  marketingShortCode: {
    url: 'v1/admin/marketing/short-code',
    method: 'GET',
  },
  skipLessonTest: {
    url: 'v1/sub-admin/lesson/test/skip',
    method: 'GET',
  },
  sendOrderInvoiceEmail: (id) => ({
    url: `v1/admin/order/email-send/${id}`,
    method: 'PUT',
  }),
};
export const Media = {
  bulkUploadImage: `${API_ENDPOINT_V1}/v1/media/upload/quote/image`,
  bulkProductImage: `${API_ENDPOINT_V1}/v1/media/upload/product/image`,
};

export const MENTAL_HEALTH_STATUS = {
  getMentalHealthStatusCount: {
    url: 'v1/admin/mental-health-status',
    method: 'GET',
  },
};
