import { TAG_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger, setLocalStorage } from '../../utils';

export const getTagService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...TAG_ENDPOINT?.getTagList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const deleteTagService = async ({ bodyData }) => {
  try {
    const payload = {
      ...TAG_ENDPOINT.deleteTag,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const updateTagStatus = async (bodyData) => {
  try {
    const payload = {
      ...TAG_ENDPOINT.tagStatusUpdate,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const addTagService = async ({ bodyData }) => {
  try {
    const payload = {
      ...TAG_ENDPOINT.addTag,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const editTagService = async ({ bodyData, id }) => {
  try {
    const payload = {
      ...TAG_ENDPOINT.editTag(id),
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getTagDetailsService = async ({ id }) => {
  try {
    const payload = {
      ...TAG_ENDPOINT.getTagDetails(id),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const customerTagService = async ({ bodyData, id }) => {
  try {
    const payload = {
      ...TAG_ENDPOINT.customerTag(id),
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const jobTagService = async ({ bodyData, id }) => {
  try {
    const payload = {
      ...TAG_ENDPOINT.jobTag(id),
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const tagDetailsService = async ({ queryParams }) => {
  try {
    const payload = {
      ...TAG_ENDPOINT.tagDetails,
      queryParams,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const customerBookingListService = async ({ queryParams, type }) => {
  try {
    const { id, ...restQuery } = queryParams;
    const payload = {
      ...TAG_ENDPOINT.customerBookingList(queryParams?.id),
      queryParams: restQuery,
    };
    const res = await APIRequest(payload);

    if (type === 'all') {
      setLocalStorage({ keyName: 'isScrollCount', data: res?.data?.count });
      return res?.data?.rows;
    }

    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const customerBookingTagService = async ({ queryParams }) => {
  try {
    const payload = {
      ...TAG_ENDPOINT.customerBookingTag,
      queryParams,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
