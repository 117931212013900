import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ChatContext } from '../../../context/chat.context';
import { useTranslation } from 'react-i18next';
// import data from '@emoji-mart/data';
// import Picker from '@emoji-mart/react';

import { Upload } from 'antd';
import {
  AntTextArea,
  CommonButton,
  FormElementWrapper,
  FormWrapper,
} from '../../../components';

import {
  acceptChatMediaFiles,
  getLocalStorageToken,
  modalNotification,
} from '../../../utils';
import { uploadFile } from '../../../services/index.service';
import notification from '../../../utils/notifications';
// import useOutsideClick from '../../../hooks/useOutSideClick.hook';

const validUploadFileSize = 25;

const ChatMessageFooter = (props) => {
  const { isUploadItems, fileList, setFileList, uploadItemsHandleClick } =
    useContext(ChatContext);
  const {
    message,
    setMessage,
    onSubmitMessage,
    isDisableTextInput,
    // handleChangeEmojiSelector
  } = props;
  const { t } = useTranslation();
  const [mediaType, setMediaType] = useState('image');
  // const [isOpenEmojiSelector, setIsOpenEmojiSelector] = useState(false);
  // const emojiSelectorRef = useOutsideClick(() => setIsOpenEmojiSelector(false));
  // const [inputCursorPosition, setInputCursorPosition] = useState(
  //   message.length
  // );
  // const inputRef = useRef();

  const apiToken = getLocalStorageToken();
  const actionUrl = uploadFile('chatRoomMedia', mediaType)?.url;
  const accept = uploadFile('chatRoomMedia', mediaType)?.accept;

  const handleKeyPress = (e) => {
    const keyCode = e.which || e.keyCode;
    if (keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      if (e?.target?.value.trim()) {
        onSubmitMessage();
      }
    }
  };

  const handleChange = (info) => {
    setFileList(info?.fileList);

    if (info?.file?.status === 'uploading') {
      return;
    }
    if (info?.file?.status === 'done') {
      // const { status } = info.file;
      // if (status === 'done') {
      // }
    } else if (info?.file?.status === 'error') {
      const fileSize = info?.file?.size / 1024 / 1024;
      notification({
        type: 'error',
        message:
          fileSize && fileSize > validUploadFileSize
            ? t('validation.chat.uploadFileSize')
            : `${info?.file?.name} file upload failed.`,
      });
    }
  };

  const handleBeforeUpload = (file) => {
    const fileSize = file?.size / 1024 / 1024;
    const isValidFile = acceptChatMediaFiles?.includes(
      file?.name?.split('.')?.pop()
    );
    if (!isValidFile) {
      modalNotification({
        type: 'error',
        message: 'Uploaded file not valid',
      });
      uploadItemsHandleClick();
      return false;
    } else if (fileSize && fileSize >= validUploadFileSize) {
      notification({
        type: 'error',
        message: t('validation.chat.uploadFileSize'),
      });
      uploadItemsHandleClick();
      return false;
    } else {
      setMediaType(file?.type?.split('/')[0]);
      return true;
    }
  };

  const uploadMediaProps = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${apiToken}`,
    },
    action: actionUrl,
    method: 'POST',
    name: 'file',
    accept: '*',
    multiple: false,
    maxCount: 1,
    showUploadList: {
      showPreviewIcon: false,
    },
    onChange: handleChange,
    beforeUpload: handleBeforeUpload,
    fileList: fileList,
    // onPreview: handlePreview,
  };

  return (
    <div className="nk-chat-editor">
      <div className="nk-chat-editor-upload  ms-n1">
        <Link
          to="#"
          className={`btn btn-sm btn-icon btn-trigger text-primary toggle-opt ${
            isUploadItems ? 'active' : ''
          }`}
          onClick={uploadItemsHandleClick}
        >
          <em className="icon ni ni-plus-circle-fill"></em>
        </Link>
        {isUploadItems && (
          <div
            className={`chat-upload-option ${isUploadItems ? 'expanded' : ''}`}
          >
            <ul className="">
              <li>
                <Upload {...uploadMediaProps}>
                  <Link to="#">
                    <em className="icon ni ni-img-fill"></em>
                  </Link>
                </Upload>
              </li>
            </ul>
          </div>
        )}
      </div>
      <div className="nk-chat-editor-form">
        <FormWrapper onSubmit={onSubmitMessage}>
          <div className="d-flex align-items-center">
            <div className="form-control-wrap w-100">
              <FormElementWrapper
                element={() => (
                  <AntTextArea
                    name="message"
                    className="form-control"
                    extraClassName="form-control-simple no-resize bg-white"
                    placeholder={t('text.chat.messageType')}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={handleKeyPress}
                    // onBlur={(e) =>
                    //   setInputCursorPosition(e.target.selectionStart)
                    // }
                    // ref={inputRef}
                    disabled={isDisableTextInput}
                    style={{ whiteSpace: 'pre-wrap' }}
                  />
                )}
              />
            </div>
            <ul className="nk-chat-editor-tools g-2">
              {/* <li>
                <div style={{ position: 'relative' }}>
                  <CommonButton
                    onClick={() => setIsOpenEmojiSelector(true)}
                    className="btn btn-sm btn-icon btn-trigger text-primary"
                  >
                    <em className="icon ni ni-happyf-fill"></em>
                  </CommonButton>
                  {isOpenEmojiSelector && (
                    <div
                      ref={emojiSelectorRef}
                      style={{
                        position: 'absolute',
                        right: 0,
                        bottom: 10,
                        zIndex: 9,
                      }}
                    >
                      <Picker
                        data={data}
                        autoFocus={true}
                        onEmojiSelect={(e) => {
                          handleChangeEmojiSelector(e, inputCursorPosition);
                        }}
                        showPreview={true}
                        emoji="point_up"
                        previewPosition="none"
                      />
                    </div>
                  )}
                </div>
              </li> */}
              <li>
                <CommonButton
                  className="btn btn-round btn-primary btn-icon"
                  type="submit"
                >
                  <em className="icon ni ni-send-alt"></em>
                </CommonButton>
              </li>
            </ul>
          </div>
        </FormWrapper>
      </div>
    </div>
  );
};

ChatMessageFooter.propTypes = {
  message: PropTypes.string,
  setMessage: PropTypes.func,
  onSubmitMessage: PropTypes.func,
};

export default ChatMessageFooter;
