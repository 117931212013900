import {
  NcaCompanyProfileForm,
  NcaProfileForm,
  SetupCardForm,
} from '../../components/Forms/NcaProfile';
import SetPermissionForm from '../../components/Forms/NcaProfile/setProfilePermission';
import { logger } from '../../utils';
import SetupBankingDetails from '../../views/NcaProfile/setUpBankingDetails';
import NCABusinessDetail from '../../views/NcaProfile/setupBusinessDetails';
import {
  // addCompanyProfileService,
  businessDetailsService,
  editNCAProfileService,
  getNCABankingService,
  getNCACardService,
} from '../index.service';
import { getNCAProfileService } from '../NCA/CreateProfile/ncaCreateProfile.service';

export const editNcaTabContent = ({
  formData,
  companyProfile,
  setDefaultKey,
  loading,
  setLoading,
  onCardSubmit,
  cardLoading,
  cardList,
  getCardList,
  onPermissionSubmit,
  companyLoad,
  setCompanyLoad,
  pageData,
  getCompanyProfile,
  getProfile,
  defaultKey,
}) => {
  const profileSubmit = async (values) => {
    setLoading(true);
    try {
      let bodyData = { ...values };
      const res = await editNCAProfileService({
        body: bodyData,
        id: formData?.id,
      });
      console.log(res);
    } catch (error) {
      logger(error);
    }
    setLoading(false);
  };

  return [
    {
      name: 'Profile',
      key: 'ncaProfile',
      content: (
        <NcaProfileForm
          formData={formData}
          isLoading={loading}
          onSubmit={profileSubmit}
          setDefaultKey={setDefaultKey}
        />
      ),
      disabled: false,
    },
    {
      name: 'Setup Company Profile',
      key: 'companyProfileForm',
      content: (
        <NcaCompanyProfileForm
          formData={formData}
          setDefaultKey={setDefaultKey}
          isLoading={companyLoad}
          defaultKey={defaultKey}
          getProfile={getProfile}
          companyProfile={companyProfile}
          getCompanyProfile={getCompanyProfile}
        />
      ),
      disabled: false,
    },
    {
      name: 'Setup Card Details',
      key: 'SetupCardDetailsForm',
      content: (
        <SetupCardForm
          formData={formData}
          onSubmit={onCardSubmit}
          cardLoading={cardLoading}
          cardList={cardList}
          getCardList={getCardList}
        />
      ),
      disabled: false,
      tooltip:
        formData?.Role?.name !== 'nca' || formData?.Role?.isEditable > 0
          ? false
          : true,
      tooltipText: 'Please complete previous step',
    },
    {
      name: 'Setup Business Details',
      key: 'businessDetails',
      content: (
        <NCABusinessDetail
          defaultKey={defaultKey}
          setDefaultKey={setDefaultKey}
        />
      ),
      tooltip: formData?.UserCard?.lastFour ? false : true,
      tooltipText: 'Please complete previous step',
      disabled: formData?.UserCard?.lastFour ? false : true,
    },
    {
      name: 'Setup Banking Details',
      key: 'bankingDetails',
      content: <SetupBankingDetails defaultKey={defaultKey} />,
      disabled:
        formData?.UserBusinessPersonDetail?.firstName &&
        formData?.UserCard?.lastFour
          ? false
          : true,
      tooltip:
        formData?.UserBusinessPersonDetail?.firstName &&
        formData?.UserCard?.lastFour
          ? false
          : true,
      tooltipText: 'Please complete previous step',
    },
    {
      name: 'Set Permission',
      key: 'setpermission',
      content: (
        <SetPermissionForm
          onSubmit={onPermissionSubmit}
          pageData={pageData}
          getProfile={getProfile}
        />
      ),
    },
  ];
};

export const roleOptions = () => {
  return [
    {
      label: 'Default Role',
      value: Number(1),
    },
    {
      label: 'Selected Role',
      value: Number(0),
    },
  ];
};

export const EditApiList = () => {
  return {
    ncaProfile: editNCAProfileService,
    // companyProfileForm: addCompanyProfileService,
    // SetupCardDetailsForm: addNcaCardService,
    // businessDetails: editNCABusinessService,
  };
};

export const getApiList = () => {
  return {
    ncaProfile: getNCAProfileService,
    // companyProfileForm: getCompanyProfileService,
    SetupCardDetailsForm: getNCACardService,
    businessDetails: businessDetailsService,
    bankingDetails: getNCABankingService,
  };
};
