import { Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useLocation, useNavigate } from 'react-router-dom';
import { GlobalLoader, ModalWrapper, NoDataFound } from '../../../components';
import { accessRoute } from '../../../routes/CustomerApp/home';

import { ManageServices } from '../../../services/ManageServices';

import { accessRoute as bookingAppointmentRoutes } from '../../../routes/CustomerApp/bookingAppointment';
import { accessRoute as scheduledRoutes } from '../../../routes/CustomerApp/scheduleServiceProvider';
import { accessRoute as customerHomeRoute } from '../../../routes/CustomerApp/home';

import DateTimeModal from '../ServiceDetails/DateTimeModal';

const ServiceType = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loader, setLoader] = useState(false);
  const [serviceTypeLists, setServiceTypeLists] = useState([]);
  const [selectedServiceType, setSelectedServiceType] = useState(null);
  const [isOpenSelectDateTimeModal, setIsOpenSelectDateTimeModal] =
    useState(false);

  async function getCustomerServiceTypeList() {
    setLoader(true);
    try {
      const res = await ManageServices.ListServiceTypeCustomerService({
        queryParams: {
          serviceSubcategoryId: state?.id,
        },
      });
      if (res?.success) {
        setServiceTypeLists([...res?.data?.rows]);
      }
    } catch (err) {
      console.log(err);
    }
    setLoader(false);
  }

  const handleContinue = (serviceType) => {
    if (state?.servicePrice === 'free' && state?.serviceType === 'scheduled') {
      setIsOpenSelectDateTimeModal(true);
    } else {
      navigate(bookingAppointmentRoutes.BOOKING.path, {
        state: { ...state, serviceTypeDetails: serviceType },
      });
    }
  };

  const handleContinueDateTime = (dateTime) => {
    navigate(scheduledRoutes.SCHEDULE_PROVIDER_LIST.path, {
      state: {
        ...state,
        dateTime,
        serviceTypeDetails: selectedServiceType,
      },
    });
  };

  useEffect(() => {
    if (!state) {
      navigate(customerHomeRoute.HOME.path);
    }
    getCustomerServiceTypeList();
  }, []);

  return (
    <>
      <div className="serviceDetailPage">
        <div className="appointment bg-white mt-3 mb-4 serviceDetailPage">
          <div className="appointment_header d-flex justify-content-between align-items-center">
            <h2 className="font-sm h-20 appointment_title">Select Services</h2>
            <div className="yourAddress p-0 mt-sm-0 mt-2">
              <div className="d-flex align-items-center justify-content-end">
                <Link
                  to={
                    state &&
                    generatePath(accessRoute.SERVICE_DETAILS.path, {
                      id: state?.parentCategoryId,
                      locationId: state?.locationId,
                    })
                  }
                  variant="info"
                  className="btn btn-outline-info ms-0 ms-sm-3 mb-3"
                >
                  {t('text.common.back')}
                </Link>
              </div>
            </div>
          </div>

          {loader ? (
            <GlobalLoader />
          ) : serviceTypeLists && serviceTypeLists?.length ? (
            <Radio.Group className="w-100 appointment_cover " name="radiogroup">
              {serviceTypeLists.map((e, idx) => (
                <div
                  key={idx}
                  className="appointment_list border-top-0 d-flex justify-content-between align-items-center"
                >
                  <div>
                    <FormGroup className="form-group mb-0 me-3">
                      <Radio
                        onClick={() => setSelectedServiceType(e)}
                        value={e?.id}
                      ></Radio>
                    </FormGroup>                    
                  </div>
                  <div className="d-xl-flex w-100 justify-content-between align-items-center">
                    <h3>{e?.name}</h3>
                    <p className="mb-0 py-2 py-xl-0 px-xl-5">{e?.description}</p>
                    <div className="appointment_btn justify-content-xl-end justify-content-start flex-shrink-0">
                      <button
                        variant="info"
                        size="lg"
                        type="button"
                        disabled={
                          selectedServiceType?.id === e?.id ? false : true
                        }
                        className={`btn btn-info`}
                        onClick={() => handleContinue(e)}
                      >
                        {t('text.common.continue')}
                      </button>
                    </div>
                  </div>                  
                </div>
              ))}
            </Radio.Group>
          ) : (
            <NoDataFound
              source="/no-data-found-img.svg"
              text={t('text.common.noServiceFound')}
              extraClass="noDataFoundWrapper p-5"
              isCustomer={'/assets/images/admin/no-data-found-img.svg'}
            />
          )}
        </div>
      </div>

      {isOpenSelectDateTimeModal && (
        <ModalWrapper
          heading="Choose Booking Date & Time"
          show={isOpenSelectDateTimeModal}
          extraClassName="modal-xl"
          extraTitleClassName="align-items-start"
          modalExtraClass="chooseBookingmodal"
          onCancel={() => setIsOpenSelectDateTimeModal(false)}
          content={
            <DateTimeModal
              handleContinueDateTime={(dateTime) =>
                handleContinueDateTime(dateTime)
              }
              state={state}
            />
          }
        ></ModalWrapper>
      )}
    </>
  );
};

export default ServiceType;
