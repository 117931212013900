import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AddProviderForm,
  ListingHeader,
  PageContainerWrapper,
  Tabs,
} from '../../../../components';
import { formSubmit } from '../../../../hooks/useFormSubmit.hook';
import { getApiData } from '../../../../hooks/useGetApi.hook';
import { accessRoute } from '../../../../routes/TeamSetting';
import { Columns } from '../../../../services/index.service';
import {
  editProviderService,
  viewAdminProviderService,
} from '../../../../services/TeamSetting/provider.service';
import { backToPage } from '../../../../utils';
import BankingDetails from './BankingDetails';
import LessonsDetails from './LessonsDetails';
import BusinessDetails from './BusinessDetails';
import { useTranslation } from 'react-i18next';
const BUSINESS_TYPE = [
  'company_single_member',
  'company_sole_proprietorship',
  'company_single',
  'individual',
  'company_sole',
  'company_pcorporation',
];

function EditProviderProfile() {
  const [isLoading, setIsLoading] = useState(false);
  const [getApiLoader, setGetApiLoader] = useState(false);
  const [profileData, setProfileData] = useState([]);
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const breadcrumb = Columns.EditProviderBreadcrumb();
  const [defaultKey, setDefaultKey] = useState('profile');
  const redirectUrl = accessRoute.PROVIDER.path;

  const backPage = () => {
    navigate('/admin/provider');
  };

  // For submit
  async function onSubmit(values) {
    const payload = { ...values };
    delete payload?.viewImageUrl;
    delete payload?.profilePictureUrl;

    if (payload.scheduledWorkAreaRadius === 'other') {
      payload.scheduledWorkAreaRadius = `${payload.scheduledWorkAreaRadius1}`;
    }

    if (payload.emergencyWorkAreaRadius === 'other') {
      payload.emergencyWorkAreaRadius = Number(
        payload.emergencyWorkAreaRadius1
      );
      delete payload?.emergencyWorkAreaRadius1;
    }

    if (payload.scheduledWorkAreaRadius) {
      payload.scheduledWorkAreaRad = `${payload.scheduledWorkAreaRad}`;
    }

    if (payload.emergencyJobRadius === 'other') {
      payload.emergencyJobRadius = `${payload.emergencyJobRadius1}`;
    }

    if (payload.feeType === '') {
      payload.feeType = null;
    }

    if (payload.scheduledJobRadius === 'other') {
      payload.scheduledJobRadius = `${payload.scheduledJobRadius1}`;
    }
    delete payload?.scheduledJobRadius1;
    delete payload?.emergencyJobRadius1;
    //  if(payload.adminCommissionFees===""){
    //   payload.adminCommissionFees = null;
    // }
    formSubmit({
      values: payload,
      service: editProviderService,
      navigate,
      redirectUrl,
      setIsLoading,
      id,
    });
  }

  // For get profile details
  useEffect(() => {
    if (id) {
      getProfileData();
    }
  }, [id]);

  async function getProfileData() {
    setGetApiLoader(true);
    try {
      const queryParams = {
        ignore: 'deleted',
        requestType: 'admin-provider-min-details',
      };
      const response = await viewAdminProviderService(id, queryParams);
      if (response?.success) {
        setProfileData(response?.data);
      }
    } catch (err) {
      console.log(err);
    }
    setGetApiLoader(false);
  }

  const tabContent = [
    {
      name: t('text.provider.profile'),
      key: 'profile',
      content: (
        <AddProviderForm
          formData={{
            ...profileData,
            ...(profileData?.Provider ? profileData.Provider : {}),
          }}
          hideField={!profileData?.Provider?.CompanyNca}
          onSubmit={onSubmit}
          isLoading={isLoading}
          backToPage={() => backToPage(navigate, redirectUrl)}
          getApiLoader={getApiLoader}
        />
      ),
    },

    {
      name: t('text.provider.businessProfile'),
      key: 'businessDetails',
      content: (
        <BusinessDetails
          getProfileData={getProfileData}
          defaultKey={defaultKey}
          provideEdit={true}
          providerData={!profileData?.Provider?.CompanyNca}
        />
      ),
      disabled: false,
    },
    {
      name: t('text.provider.bankingProfile'),
      key: 'bankingDetails',
      content: <BankingDetails defaultKey={defaultKey} />,
      disabled:
        (profileData?.Provider?.businessType &&
          profileData?.Provider?.businessType === 'individual' &&
          profileData?.UserBusinessPersonDetail) ||
        (profileData?.Provider?.businessType &&
          BUSINESS_TYPE.includes(profileData?.Provider?.businessType) &&
          (profileData?.UserBusinessPersonDetail ||
            profileData?.UserBusinessDetail))
          ? false
          : true,
      tooltip:
        (profileData?.Provider?.businessType &&
          profileData?.Provider?.businessType === 'individual' &&
          profileData?.UserBusinessPersonDetail) ||
        (profileData?.Provider?.businessType &&
          BUSINESS_TYPE.includes(profileData?.Provider?.businessType) &&
          (profileData?.UserBusinessPersonDetail ||
            profileData?.UserBusinessDetail))
          ? false
          : true,
      tooltipText: 'Please complete previous step',
    },
    {
      name: t('text.provider.lessonProfile'),
      key: 'lessonsDetails',
      content: <LessonsDetails defaultKey={defaultKey} userId={id} />,
      disabled: false,
    },
  ];
  return (
    <div className="wide-md mx-auto">
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <PageContainerWrapper
              heading={`Edit Provider ${profileData?.firstName ?? ''}`}
              listingHeader={false}
              breadcrumb={breadcrumb}
            />
          </div>
          <ListingHeader
            btnArray={['extraButton']}
            extraBtnText="Back"
            extraBtnClass="btn btn-outline-light bg-white"
            onExtraButtonHandleShow={backPage}
          />
        </div>
      </div>
      <div className="nk-block">
        <Tabs
          tabContent={tabContent}
          tabsFor="table"
          activeKey={defaultKey}
          setActiveKey={setDefaultKey}
          // onTabChange={onTabChange}
        />
      </div>
    </div>
  );
}

export default EditProviderProfile;
