export const text = {
  title: 'Roles',
  heading: 'Role',
  addRole: 'Add Role',
  editRole: 'Edit Role',
  noteListOne: 'With use of this icon, you can edit exist polygon shape.',
  noteListTwo: 'With use of this icon, you can draw new polygon shape.',
  noteListThree: 'With use of this icon, you can draw new polygon lines.',
  roleName: 'Role Name',
  RoleFor: 'Role For',
  pullPermit: 'Pull Permit Cost ($)',
  monthlyPrice: 'Monthly price',
  yearlyPrice: 'Yearly price',
  pricePerActiveProvider: 'Price per active service provider',
  totalSettledSales: 'Total Settled sales percentage (%)',
  percentTotalSale: 'Percentage of total sale (credit card)',
  costPerTrans: 'Cost per transaction (credit card)',
  percentSale: 'Percentage of total sale (check)',
  costPerTranCheque: 'Cost per transaction (check)',
  totalSaleFinance: 'Percentage of total sale (financing)',
  constPerTransFinance: 'Cost per transaction (financing)',
  percentSaleCash: 'Percentage of total sale (cash)',
  costPerTransCash: 'Cost per transaction (cash)',
  admins: 'Admins',
  addSubAdmin: 'Add SubAdmin',
  editSubAdmin: 'Edit SubAdmin',
  adminNote1: 'Administrator module will list all administrators on this page.',
  adminNote2:
    'Administrator can Activate / Deactivate / Delete any administrator. Super Admin cannot be Activated / Deactivated / Deleted.',
  adminNote3: 'Administrator can export data in XLS  format.',
  otherMilesRadiusSchedule: 'Other Miles Radius (For Schedule Job)',
  otherMilesRadiusEmergency: 'Other Miles Radius (For Emergency Job)',
  alexaToggle: 'Alexa Toggle',
};
