import { APIRequest } from '../../helpers/index.helper';
import { FAQ_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { logger } from '../../utils';

export const FaqListService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...FAQ_ENDPOINT.getFaqApi,
      queryParams,
    };

    const res = await APIRequest(payload);
    if (type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const addFaqService = async ({ body }) => {
  try {
    const payload = {
      ...FAQ_ENDPOINT.addFaq,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const editFaqService = async ({ id, body }) => {
  try {
    const payload = {
      ...FAQ_ENDPOINT.editFaq(id),
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const deleteFaqService = async ({ bodyData }) => {
  try {
    const payload = {
      ...FAQ_ENDPOINT.deleteFaq,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const getFaqByIdService = async ({ resourceId }) => {
  try {
    const payload = {
      ...FAQ_ENDPOINT.getFaqById(resourceId),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const updateFaqStatus = async (bodyData) => {
  try {
    const payload = {
      ...FAQ_ENDPOINT.updateFaqStatusId(),
      bodyData,
    };

    const res = await APIRequest(payload);

    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
