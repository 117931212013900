export const COUPON_MANAGEMENT_ENDPOINT = {
  addCoupon: {
    url: 'v1/admin/add-coupon',
    method: 'POST',
  },
  getCouponList: {
    url: 'v1/admin/coupon-list',
    method: 'GET',
  },
  changeCouponCodeStatus: {
    url: 'v1/admin/coupon-code/change-status',
    method: 'PUT',
  },

  deleteCouponCode: {
    url: `v1/admin/delete-coupon-code`,
    method: 'DELETE',
  },
  editCouponCode: (id) => ({
    url: `v1/admin/edit-coupon-code/${id}`,
    method: 'PUT',
  }),
  getCouponCodeDetails: ({ id }) => ({
    url: `v1/admin/get-coupon/${id}`,
    method: 'GET',
  }),
};
