import i18next from 'i18next';
import * as yup from 'yup';

import { valueMapper } from '../../../../helpers/schema.helper';

export const initValues = (data) => {
  const initVal = {
    documentImage: '',
    expireDate: '',
  };

  return valueMapper(initVal, data);
};

export const validation = (data) => {
  return yup.object().shape({
    expireDate:
      data?.isDateRequired === 1
        ? yup
            .string()
            .required(i18next.t('validation.promoCode.expDate'))
            .nullable()
        : null,
  });
};
