import React, { useRef, useEffect } from 'react';
import { Formik } from 'formik';
import { Form } from 'antd';
import GlobalLoader from '../../UIElements/GlobalLoader';

function defaultFn() {}

function FormWrapper({
  children,
  onSubmit = defaultFn,
  validation = defaultFn,
  validate,
  initialValues = {},
  data,
  enableReinitialize,
  row = false,
  extraClassName = '',
  isChildrenLoader = false,
  isModal = false,
}) {
  const form = useRef(null);
  const formProps = {
    initialValues: { ...initialValues },
    validationSchema: validation() || validate,
    onSubmit: onSubmit,
    enableReinitialize: enableReinitialize,
  };

  useEffect(() => {
    if (data && form?.current?.setFieldsValue) {
      form.current.setFieldsValue({ ...data });
    }
  }, [data]);

  return (
    <Formik {...formProps}>
      {(props) => {
        return (
          <>
            {isChildrenLoader ? (
              <GlobalLoader
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: isModal ? 100 : 350,
                }}
              />
            ) : (
              <Form
                ref={form}
                onFinish={props.handleSubmit}
                className={`${extraClassName ? extraClassName : ''} ${
                  row ? 'row' : ''
                }`}
              >
                {React.Children.map(
                  typeof children === 'function' ? children(props) : children,

                  (child) => {
                    if (React.isValidElement(child)) {
                      return React.cloneElement(child, props);
                    }
                    return child;
                  }
                )}
              </Form>
            )}
          </>
        );
      }}
    </Formik>
  );
}

export default FormWrapper;
