import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { ChatContext } from '../../../context/chat.context';

import ImageElement from '../../../components/UIElements/ImageElement';

import { capitalizeFirstLetter } from '../../../utils/common.util';

const ChatMessageHeader = (props) => {
  const {
    handleOpenChatProfileDrawer,
    showChatHandle,
    userDetails,
    getProfileImageUrl,
  } = useContext(ChatContext);
  const { selectUserDetails } = props;

  // const getGroupNames = () => {
  //   let userNames = [];

  //   selectUserDetails.members.forEach((member) => {
  //     userNames.push(
  //       `${capitalizeFirstLetter(
  //         member.User.firstName
  //       )} ${capitalizeFirstLetter(member.User.lastName)}`
  //     );
  //   });

  //   return userNames.join(', ');
  // };

  return (
    <>
      <div className="nk-chat-head">
        <ul className="nk-chat-head-info">
          <li className="nk-chat-body-close">
            <Link
              to="#"
              onClick={showChatHandle}
              className="btn btn-icon btn-trigger nk-chat-hide ms-n1"
            >
              <em className="icon ni ni-arrow-left"></em>
            </Link>
          </li>
          <li className="nk-chat-head-user">
            <div className="user-card">
              <div className="user-avatar">
                <ImageElement
                  previewSource={getProfileImageUrl({
                    chatRoom: {
                      ChatRoomMembers: selectUserDetails?.members,
                      chatType: selectUserDetails?.chatRoom?.chatType,
                      imageUrl: selectUserDetails?.chatRoom?.imageUrl,
                    },
                  })}
                  loading="lazy"
                  alt="img"
                  isProfile={true}
                />
              </div>
              <div className="user-info">
                <div className="lead-text">
                  {selectUserDetails?.chatRoom?.chatType === 'group'
                    ? capitalizeFirstLetter(
                        selectUserDetails?.chatRoom?.roomName ?? ''
                      )
                    : `${capitalizeFirstLetter(
                        selectUserDetails?.members.find(
                          (d) => d.memberUserId !== userDetails.id
                        )?.User?.firstName ?? ''
                      )} ${capitalizeFirstLetter(
                        selectUserDetails?.members.find(
                          (d) => d?.memberUserId !== userDetails.id
                        )?.User?.lastName ?? ''
                      )}`}
                </div>
                {selectUserDetails?.chatRoom?.chatType === 'single' &&
                  (selectUserDetails?.members.find(
                    (member) =>
                      member?.memberUserId ===
                      selectUserDetails?.chatRoom?.toUserId
                  )?.User?.lastOnlineAt ? (
                    <div className="sub-text">
                      <span className="d-none d-sm-inline me-1">Active</span>{' '}
                      {moment(
                        selectUserDetails?.members.find(
                          (member) =>
                            member?.memberUserId ===
                            selectUserDetails?.chatRoom?.toUserId
                        )?.User?.lastOnlineAt
                      )
                        .startOf('hour')
                        .fromNow()}
                    </div>
                  ) : null)}
              </div>
            </div>
          </li>
        </ul>
        {selectUserDetails?.chatRoom?.chatType === 'group' && (
          <ul className="nk-chat-head-tools">
            <li>
              <Link
                to="#"
                className="btn btn-icon btn-trigger text-primary"
                onClick={handleOpenChatProfileDrawer}
              >
                <em className="icon ni ni-info-fill"></em>
              </Link>
            </li>
          </ul>
        )}
      </div>
    </>
  );
};

ChatMessageHeader.propTypes = {
  selectUserDetails: PropTypes.object,
};

export default ChatMessageHeader;
