import i18next from 'i18next';
import * as yup from 'yup';
import { valueMapper } from '../../../helpers/schema.helper';

export const initValues = (formData) => {
  return valueMapper(
    {
      name: '',
      countryId: null,
      locationFor: null,
      pullPermitCost: '',
    },
    formData
  );
};

export const validation = () => {
  return yup.object().shape({
    name: yup
      .string()
      .max(255, i18next.t('validation.common.maxValid255'))
      .nullable()
      .required(i18next.t('validation.manageLocation.name')),
    countryId: yup
      .string()
      .typeError(i18next.t('validation.manageLocation.countryId'))
      .nullable()
      .required(i18next.t('validation.manageLocation.countryId')),
    locationFor: yup
      .string()
      .typeError(i18next.t('validation.manageLocation.locationFor'))
      .nullable()
      .required(i18next.t('validation.manageLocation.locationFor')),
    pullPermitCost: yup
      .number()
      .nullable()
      .required(i18next.t('validation.manageLocation.pullPermitCost'))
      .test(
        'is-positive',
        i18next.t('validation.common.costPositive'),
        (val) => {
          if (!isNaN(val)) {
            return val > 0;
          }
          return true;
        }
      ),
  });
};

export const initNCAValues = (formData) => {
  return valueMapper(
    {
      name: '',
      geoFenceLocationId: null,
      locationFor: null,
      pullPermitCost: '',
    },
    formData
  );
};

export const validationNCA = () => {
  return yup.object().shape({
    name: yup.string().required(i18next.t('validation.manageLocation.name')),
    geoFenceLocationId: yup
      .string()
      .typeError(i18next.t('validation.manageLocation.countryId'))
      .nullable()
      .required(i18next.t('validation.manageLocation.countryId')),
    locationFor: yup
      .string()
      .typeError(i18next.t('validation.manageLocation.locationFor'))
      .nullable()
      .required(i18next.t('validation.manageLocation.locationFor')),
    pullPermitCost: yup
      .number()
      .nullable()
      .required(i18next.t('validation.manageLocation.pullPermitCost')),
  });
};
