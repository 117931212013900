import i18next from 'i18next';
import { getInputSearchFilter } from '../../components';
import {
  addSubFormatter,
  checkValidData,
  checkValidPrice,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { accessRoute } from '../../routes/Dashboard';

export const quoteOrderTableColumn = ({
  Action,
  onFilterChange,
  filter,
  page,
  sizePerPage,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'providerQuoteOrder',
      filterDataIndex: ['orderType'],
      title: i18next.t('text.quote.orderType'),
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'orderType',
        placeholder: 'order type',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'displayOrder',
      title: i18next.t('text.common.displayOrder'),
      sorter: true,
      render: (cell, row) => checkValidData(cell),
    },
    {
      dataIndex: 'laborCost',
      title: i18next.t('text.quote.laborCostFlat'),
      render: (cell, row) => checkValidPrice(cell),
    },
    {
      dataIndex: 'salesProfit',
      title: i18next.t('text.quote.salesProfitFlat'),
      render: (cell, row) => checkValidPrice(cell),
    },
    {
      dataIndex: 'adjustmentSeasonPercentage',
      filterDataIndex: ['adjustmentSeason'],
      title: i18next.t('text.quote.adjustmentSeason'),
      sorter: true,
      render: (cell, row) => addSubFormatter({ cell, row }),
      ...getInputSearchFilter({
        dataIndex: 'adjustmentSeason',
        placeholder: 'adjustment season',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.localization.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => {
        return <Action row={row} cell={cell} />;
      },
    },
  ];
};

export const quoteOrderBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.quote.quoteOrderAdminProfit'),
    },
  ];
};
