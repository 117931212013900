import { CHATBOT_QUESTIONNAIRE_RECORD_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';

export const getQuestionnaireRecordService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...CHATBOT_QUESTIONNAIRE_RECORD_ENDPOINT?.getVoiceAIRecordList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
