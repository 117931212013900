import { Image } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NoDataFound } from '../../components';
import { useState } from 'react';

function DocumentPreview({ data }) {
  const { t } = useTranslation();
  const [imageError, setImageError] = useState(false);
  const [identityImageError, setIdentityImageError] = useState(false);

  const onError = ({ currentTarget }) => {
    setImageError(true);
    setIdentityImageError(true);
    try {
      currentTarget.onerror = null; // prevents looping
      currentTarget.src = '/assets/images/admin/img_default-placeholder.png';
    } catch (err) {
      return currentTarget;
    }
  };
  return (
    <div className="nk-fmg-quick-list nk-block">
      <div className="nk-files nk-files-view-grid documentsBoxSec">
        <div className="nk-files-list">
          {data?.CompanyNca?.logoThumbUrl && (
            <div className="nk-file-item nk-file p-0">
              <div className="nk-file-info">
                <div className="nk-file-title">
                  <div className="nk-file-name my-3 mx-1">
                    <h6 className="mb-0">{t('text.common.companyLogo')}</h6>
                    <p>
                      <small>({t('text.common.forCompanyLogo')})</small>
                    </p>
                  </div>

                  <div className="nk-file-icon">
                    <span className="nk-file-icon-type nk-file-icon-type-showImage p-0">
                      <Image
                        src={
                          imageError
                            ? '/assets/images/admin/img_default-placeholder.png'
                            : data?.CompanyNca?.logoThumbUrl
                        }
                        alt="isLogo"
                        onError={onError}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {data?.UserBusinessPersonDetail?.identityDocumentFrontThumbUrl && (
            <div className="nk-file-item nk-file p-0">
              <div className="nk-file-info">
                <div className="nk-file-title">
                  <div className="nk-file-name my-3 mx-1">
                    <h6 className="mb-0">
                      {t('text.common.identityDocument')}
                    </h6>
                    <p>
                      <small>
                        ( {t('text.common.forBusinessTypeIdentityDocument')} )
                      </small>
                    </p>
                  </div>
                  <div className="nk-file-icon">
                    <span className="nk-file-icon-type nk-file-icon-type-showImage p-0">
                      <Image
                        src={
                          identityImageError
                            ? '/assets/images/admin/img_default-placeholder.png'
                            : data?.UserBusinessPersonDetail
                                ?.identityDocumentFrontThumbUrl
                        }
                        alt="isIdentity"
                        onError={onError}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!data?.CompanyNca?.logoThumbUrl &&
            !data?.UserBusinessPersonDetail?.identityDocumentFrontThumbUrl && (
              <div className="w-100 card">
                <div className="card-inner">
                  <NoDataFound
                    source="/no-data-found-img.svg"
                    text={t('text.common.noDocumentFound')}
                    extraClass="noDataFoundWrapper"
                    isCustomer={'/assets/images/admin/no-data-found-img.svg'}
                  />
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}
export default DocumentPreview;
