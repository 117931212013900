export const EQUIPMENT_ORDER_ENDPOINT = {
  getEquipmentOrderList: {
    url: '/v1/admin/equipment-order',
    method: 'GET',
  },
  putUpdateEquipmentOrderStatus: (id) => ({
    url: `/v1/admin/equipment-order/order-status/${id}`,
    method: 'PUT',
  }),
  getEquipmentOrderData: (id) => ({
    url: `/v1/admin/equipment-quote/${id}`,
    method: 'GET',
  }),
  getMaterialDataService: (id) => ({
    url: `/v1/admin/equipment-materials/${id}`,
    method: 'GET',
  }),
};
