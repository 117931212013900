import i18next from 'i18next';
import { accessRoute } from '../../routes/Dashboard';
import { accessRoute as emailSetting } from '../../routes/EmailSetting';
import {
  checkValidData,
  dangerouslySetInnerHTML,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { getInputSearchFilter } from '../../components';

export const emailMessageTableColumn = ({
  Action,
  onFilterChange,
  filter,
  page,
  sizePerPage,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'subject',
      title: i18next.t('text.emailMessage.emailSubject'),
      className: 'sorting',
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'subject',
        placeholder: 'email subject',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'emailCode',
      exportName: 'Email Code',
      title: i18next.t('text.emailMessage.emailCode'),
      className: 'sorting',
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'emailCode',
        placeholder: 'email code',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.localization.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => {
        return <Action row={row} cell={cell} />;
      },
    },
  ];
};

export const emailMessageBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.emailMessage.title'),
    },
  ];
};

export const editEmailMessageBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: emailSetting.EMAIL_TEMPLATES.path,
      name: i18next.t('text.emailMessage.title'),
    },
    {
      path: '#',
      name: i18next.t('text.emailMessage.title'),
    },
  ];
};
// For Sms Template
export const smsMessageTableColumn = ({ Action, onFilterChange, filter }) => {
  return [
    {
      dataIndex: 'purpose',
      filterDataIndex: ['smsPurpose'],
      title: i18next.t('text.emailMessage.smsPurpose'),
      className: 'sorting',
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'smsPurpose',
        placeholder: 'sms purpose',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'body',
      filterDataIndex: ['smsBody'],
      title: i18next.t('text.emailMessage.smsBody'),
      className: 'sorting',
      sorter: true,
      render: (cell, row) =>
        dangerouslySetInnerHTML({
          data: cell,
          extraClassName: 'postDescription',
        }),
      ...getInputSearchFilter({
        dataIndex: 'smsBody',
        placeholder: 'sms body',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.localization.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => {
        return <Action row={row} cell={cell} />;
      },
    },
  ];
};

export const smsMessageBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.emailMessage.SMSTemplates'),
    },
  ];
};

export const editSmsMessageBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: emailSetting.SMS_TEMPLATES.path,
      name: i18next.t('text.emailMessage.SMSTemplates'),
    },
    {
      path: '#',
      name: i18next.t('text.emailMessage.editSmsTemplates'),
    },
  ];
};
