import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Charts } from '../../UIElements/index.element';
import { checkValidCount } from '../../UIElements/Formatter';

export default function PieChart({
  title,
  type,
  iconClass,
  JobsCountData,
  chartOptionsData,
  JobsDetails,
  footer,
}) {
  return (
    <>
      <Col lg={6}>
        <div className="card card-full">
          <div className="card-inner-group">
            <div className="card-inner">
              <div className="card-title-group">
                <div className="card-title">
                  <h6 className="title d-flex align-items-center">
                    {' '}
                    <span className="iconView me-2">
                      <em className={`icon bg-primary-dim ${iconClass}`}></em>
                    </span>
                    {title}
                  </h6>
                </div>
              </div>
            </div>
            <div className="card-inner">
              <Row>
                <Col md={12}>
                  <div style={{ maxWidth: '300px', margin: '0 auto' }}>
                    <Charts
                      type={type}
                      data={JobsCountData}
                      options={chartOptionsData}
                    />
                  </div>
                </Col>
                <Col md={12} className="mt-2">
                  <ul className="nk-store-statistics">
                    <li className="item pt-0">
                      <div className="info">
                        <div className="title">{title} Count</div>
                        <div className="count">
                          {checkValidCount(JobsDetails?.jobsCount)}{' '}
                        </div>
                      </div>
                      <em className="icon bg-purple-dim ni ni-calc"></em>
                    </li>
                    <li className="item pt-0">
                      <div className="info">
                        <div className="title">Today</div>
                        <div className="count">
                          {' '}
                          {checkValidCount(JobsDetails?.Today)}{' '}
                        </div>
                      </div>
                      <em className="icon bg-purple-dim ni ni-calender-date"></em>
                    </li>
                    <li className="item pt-0">
                      <div className="info">
                        <div className="title">This Month</div>
                        <div className="count">
                          {' '}
                          {checkValidCount(JobsDetails?.thisMonth)}{' '}
                        </div>
                      </div>
                      <em className="icon bg-purple-dim ni ni-calender-date"></em>
                    </li>
                    <li className="item pt-0">
                      <div className="info">
                        <div className="title">This Year</div>
                        <div className="count">
                          {' '}
                          {checkValidCount(JobsDetails?.thisYear)}{' '}
                        </div>
                      </div>
                      <em className="icon bg-purple-dim ni ni-calender-date"></em>
                    </li>
                  </ul>
                  <p>{footer}</p>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Col>
    </>
  );
}
