import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageContainerWrapper } from '../../../components';
import Tabs from '../../../components/UIElements/Tabs';
import { Columns } from '../../../services/index.service';
import ReviewUserList from './Listing/User';
import ReviewProviderList from './Listing/Provider';

function Reviews() {
  const [defaultKey, setDefaultKey] = useState('provider');
  const { t } = useTranslation();
  const breadcrumb = Columns.reviewsProviderBreadcrumb();

  const childRef = useRef(null);
  const onExportData = () => {
    if (childRef.current) {
      return childRef.current.fetchData();
    }
  };

  const headerBtn = {
    csvExport: {
      handler: () => onExportData(),
    },
  };

  const tabContent = [
    {
      name: t('text.report.provider'),
      key: 'provider',
      content: defaultKey === 'provider' && (
        <ReviewProviderList defaultKey={defaultKey} ref={childRef} />
      ),
    },
    {
      name: t('text.report.user'),
      key: 'user',
      content: defaultKey === 'user' && (
        <ReviewUserList defaultKey={defaultKey} ref={childRef} />
      ),
    },
  ];

  return (
    <>
      <PageContainerWrapper
        heading={t('text.report.reviews')}
        breadcrumb={breadcrumb}
        btn={headerBtn}
      >
        <Tabs
          tabContent={tabContent}
          tabsFor="table"
          activeKey={defaultKey}
          setActiveKey={setDefaultKey}
        />
        <div className="card mt-3">
          <div className="card-inner">
            <div className="bq-note">
              <div className="bq-note-text">
                <div className="nk-block-head nk-block-head-sm nk-block-between">
                  <h5 className="title">{t('text.common.notes')}</h5>
                </div>
                <ul className="list list-sm list-checked">
                  <li>{t('text.common.reviewNoteOne')}</li>
                  <li>{t('text.common.reviewNoteTwo')}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </PageContainerWrapper>
    </>
  );
}

export default Reviews;
