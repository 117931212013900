import React from 'react';
import { Form, Switch as AntSwitch } from 'antd';
import { useField } from 'formik';

function Switch({ name, formik, disabled, ...rest }) {
  const [field, meta, helpers] = useField(name);
  const config = { ...field, ...rest };

  if (meta && meta?.touched && meta?.error) {
    config.error = true;
    config.helperText = meta.error;
  }
  const handleChangeSelect = (value) => {
    helpers.setValue(value);
  };

  return (
    <Form.Item
      label={rest?.label}
      help={meta?.error && meta?.error && meta?.touched ? meta.error : ''}
      validateStatus={config?.error ? 'error' : 'success'}
    >
      <AntSwitch
        disabled={disabled}
        checked={formik?.values[name]}
        onChange={handleChangeSelect}
        {...rest}
      />
    </Form.Item>
  );
}

export default Switch;
