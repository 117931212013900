import { SERVICE_TYPE_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';

export const ListServiceTypeService = async ({ queryParams }) => {
  try {
    const payload = {
      ...SERVICE_TYPE_ENDPOINT?.getServiceTypeList,
      queryParams,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const deleteServiceTypeService = async ({ bodyData }) => {
  try {
    const payload = {
      ...SERVICE_TYPE_ENDPOINT.deleteServiceType,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const addServiceTypeService = async ({ bodyData }) => {
  try {
    const payload = {
      ...SERVICE_TYPE_ENDPOINT.addServiceType,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const editServiceTypeService = async ({ bodyData, id }) => {
  try {
    const payload = {
      ...SERVICE_TYPE_ENDPOINT.editServiceType(id),
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getServiceTypeDetailsService = async ({ id }) => {
  try {
    const payload = {
      ...SERVICE_TYPE_ENDPOINT.getServiceTypeDetails(id),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const ListServiceTypeCustomerService = async ({ queryParams }) => {
  try {
    const payload = {
      ...SERVICE_TYPE_ENDPOINT?.getServiceTypeCustomerList,
      queryParams,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
