import { slotsEndpoints } from '../../apiEndpoints/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';

export const addSlotService = async ({ bodyData }) => {
  try {
    const payload = {
      ...slotsEndpoints.addSlots,
      bodyData,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const deleteSlotsService = async (id) => {
  try {
    const payload = {
      ...slotsEndpoints.deleteSlot(id),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getSlotService = async (queryParams) => {
  try {
    const payload = {
      ...slotsEndpoints.getSlot,
      queryParams,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
