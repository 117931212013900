export const text = {
  heading: 'Promo Code',
  giftCode: 'Gift/Certificate Code',
  discount: 'Discount',
  companyName: 'Name or Company Name',
  validity: 'Validity',
  activationDate: 'Activation Date',
  expiryDate: 'Expiry Date',
  usageLimit: 'Usage Limit',
  used: 'Used',
  allCoupon: 'Coupon module will list all coupons on this page',
  activeCoupon: 'Administrator can Activate / Deactivate / Delete any coupon',
  addPromoTitle: 'Add Promo Code',
  editPromoCode: 'Edit Promo Code',
  deletePromo: 'you want to delete this Promo Code',
  couponCode: 'Coupon Code',
  generateCode: 'Generate Coupon Code',
  hardwareStore: 'Hardware Store ',
  couponPlaceholder: 'Enter coupon code',
  discountPlaceholder: 'Enter discount',
  selectDiscount: 'Select discount',
  descriptionPlaceholder: 'Enter description',
  activePlaceholder: 'Select activation date',
  usageLimitPlaceholder: 'Enter usage limit',
  selectStatus: 'Select status',
  hardwarePlaceholder: 'Select hardware store',
  description: 'Description ',
  expPlaceholder: 'Select expiry date',
  tooltipPromo:
    'Promo code can be used one time only for each user. So if you set Usage limit to 100 then 100 unique user can use this promo code.',
  usageLimitTooltip:
    'Usage Limit is the number of times your promo code can be used by a customer.',
  customerUsageTooltip:
    'This is the number of times customers have used the promo code while requesting a service.',
  pendingAmountTooltip:
    'This is the dollar amount to be deposited into your account at the first of the month.',
};
