import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import i18next from 'i18next';

import { ImageElement } from '../../../../components';
import {
  FormWrapper,
  FormElementWrapper,
  CommonButton,
  Select,
} from '../../../../components';

import { putNCAPlanUpdateService } from '../../../../services/NCA/CreateProfile/ncaCreateProfile.service';
import {
  getRolesList,
  listNCARoleService,
} from '../../../../services/TeamSetting/role.service';
import { logger } from '../../../../utils';

const initialValues = {
  roleId: undefined,
  paymentMode: undefined,
};

const validation = () => {
  return yup.object().shape({
    roleId: yup.string().required(i18next.t('validation.signup.role')),
    paymentMode: yup.string().required(i18next.t('validation.signup.duration')),
  });
};

const CompanyProfileCancel = () => {
  const redirectUrl = '/admin/dashboard';
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [state, setState] = useState({
    rolePermission: [],
    payDuration: [],
    isLoading: false,
  });

  const handleStateChange = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  async function onRoleChange(e, formik) {
    try {
      getRolePermission({ id: e, queryParams: { scope: 'active' } });
      const { success, data } = await listNCARoleService({});

      let durationArray = [];

      if (success) {
        let getPlan = data.rows
          .filter((item) => item.id === e)
          .map((plan) => plan?.NcaPlan);

        getPlan.forEach((item) => {
          durationArray.push(
            {
              name: `Monthly ($${parseFloat(item?.monthlyPrice).toFixed(2)})`,
              id: `monthly-${item?.monthlyPrice}`,
            },
            {
              name: `Yearly ($${parseFloat(item?.yearlyPrice).toFixed(2)})`,
              id: `yearly-${item?.yearlyPrice}`,
            }
          );
        });
        formik?.setFieldValue('paymentMode', undefined);
        handleStateChange('payDuration', [...durationArray]);
      }
    } catch (err) {
      console.log(err);
    }
  }
  async function getRolePermission({ id, queryParams }) {
    try {
      const { data, success } = await getRolesList({ id, queryParams });
      if (success) {
        let moduleKeyArray = data?.permission
          ?.map((e) => e.name.split('.').shift())
          .map((i) => i.split('-').join(' '));
        handleStateChange('rolePermission', moduleKeyArray);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function handleSubmit(values) {
    try {
      const response = await putNCAPlanUpdateService({ body: values });
      if (response?.success) {
        navigate(redirectUrl);
      }
    } catch (error) {
      logger(error);
    }
  }

  return (
    <div className="nk-block nk-block-middle nk-auth-body wide-md">
      <div className="brand-logo pb-4 text-center">
        <Link className="logo-link" to="#">
          <ImageElement
            source="logo.svg"
            className="logo-dark logo-img logo-img-lg"
            alt="logo-dark"
          />
        </Link>
      </div>
      <div className="card card-bordered">
        <div className="card-inner card-inner-lg">
          <div className="nk-block-head">
            <div className="nk-block-head-content">
              <h5 className="nk-block-title">
                {t('text.common.setUpCompanyProfile')}
              </h5>
            </div>
          </div>
          <div className="card-inner">
            <FormWrapper
              row={true}
              onSubmit={handleSubmit}
              enableReinitialize={true}
              initialValues={initialValues}
              validation={validation}
              extraClassName="g-3"
            >
              {(props) => (
                <>
                  <FormElementWrapper
                    md={6}
                    mandatory={true}
                    label={t('text.nca.whichRoleWouldYouLike')}
                    element={() => (
                      <Select
                        name="roleId"
                        placeholder={t('text.common.selectRole')}
                        showSearch
                        formik={props}
                        size="large"
                        listService={listNCARoleService}
                        onItemChange={onRoleChange}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={6}
                    mandatory={true}
                    label={t('text.nca.WouldYouLikeToPay')}
                    element={() => (
                      <Select
                        name="paymentMode"
                        placeholder={t('text.common.selectDuration')}
                        options={state.payDuration.map((e) => ({
                          id: e?.id,
                          name: e?.name,
                        }))}
                        showSearch
                        formik={props}
                        size="large"
                      />
                    )}
                  />
                  <FormElementWrapper
                    visible={
                      state?.rolePermission &&
                      (state?.rolePermission.length || false)
                    }
                    md={12}
                    label={t('text.common.permissionThisRole')}
                    element={() => (
                      <ul className="custom-control-group">
                        {state?.rolePermission && state?.rolePermission.length
                          ? state?.rolePermission.map((item, index) => (
                              <li key={index}>
                                <div className="custom-control custom-checkbox custom-control-pro no-control">
                                  <label className="custom-control-label text-capitalize">
                                    {item}
                                  </label>
                                </div>
                              </li>
                            ))
                          : null}
                      </ul>
                    )}
                  />

                  <FormElementWrapper
                    md={12}
                    className="text-center"
                    element={() => (
                      <>
                        <CommonButton
                          type="submit"
                          className="btn btn-primary"
                          loading={props.isSubmitting}
                          disabled={props.isSubmitting}
                        >
                          {t('text.common.saveEndNext')}
                        </CommonButton>
                      </>
                    )}
                  />
                </>
              )}
            </FormWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyProfileCancel;
