import { getFromToDateFilter } from '../../components/Filter/Common/date.filter';
import { accessRoute } from '../../routes/Dashboard';
import i18next from 'i18next';
import {
  getAmountSign,
  getTupleStatusList,
  validityFilterData,
} from '../index.service';
import {
  getColumnSearchSelectProps,
  getColumnSelectSearchProps,
  getInputSearchFilter,
} from '../../components';
import {
  checkValidData,
  checkValidDiscountType,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { dateFormatter } from '../../utils';
import { firstLetterCaps } from '../../utils/text.util';

export const couponManagementBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.common.couponManagement'),
    },
  ];
};

export const couponManagementTableColumn = ({
  Action,
  Toggle,
  onSort,
  onFilterChange,
  filter,
  page,
  sizePerPage,
}) => {
  return [
    {
      dataIndex: 'id',
      title: 'S.No.',
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'code',
      title: i18next.t('text.shoppingCart.couponCode'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'code',
        placeholder: 'coupon code',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'discountValue',
      title: 'Discount',
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => checkValidDiscountType(row?.discountType, cell),
      ...getColumnSearchSelectProps({
        dataIndex: 'discountValue',
        list: getAmountSign(true),
        onFilterChange,
        filter,
        selectIndex: 'discountType',
        placeholder: {
          select: 'Select',
          selectIndex: 'discountType',
          input: 'Discount',
          inputIndex: 'discountValue',
        },
      }),
    },
    {
      dataIndex: 'validityType',
      title: 'Validity',
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => checkValidData(firstLetterCaps(cell)),
      ...getColumnSelectSearchProps({
        dataIndex: 'validityType',
        onFilterChange,
        filter,
        name: 'validity type',
        list: validityFilterData(),
        mode: false,
      }),
    },
    {
      dataIndex: 'usageLimit',
      title: 'Maximum Use',
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'usageLimit',
        placeholder: 'maximum use',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'activeDate',
      filterDataIndex: ['activationFromDate', 'activationToDate'],
      title: i18next.t('text.common.startDate'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) =>
        cell ? dateFormatter(cell, 'ddd, MMMM, DD, YYYY') : '-',
      ...getFromToDateFilter({
        dataIndex: 'activeDate',
        dataStartIndex: 'activationFromDate',
        dataEndIndex: 'activationToDate',
        placeholderStartDate: 'Start date',
        placeholderEndDate: 'End date',
        dateFormat: 'YYYY/MM/DD',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'expiryDate',
      filterDataIndex: ['expiryFromDate', 'expiryToDate'],
      title: i18next.t('text.common.endDate'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) =>
        cell ? dateFormatter(cell, 'ddd, MMMM, DD, YYYY') : '-',
      ...getFromToDateFilter({
        dataIndex: 'expiryDate',
        dataStartIndex: 'expiryFromDate',
        dataEndIndex: 'expiryToDate',
        placeholderStartDate: 'Start date',
        placeholderEndDate: 'End date',
        dateFormat: 'YYYY/MM/DD',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'status',
      title: 'Status',
      headerClasses: 'sorting',
      render: (cell, row) => {
        return <Toggle row={row} cell={cell} />;
      },
      sorter: true,
      ...getColumnSelectSearchProps({
        dataIndex: 'status',
        placeholder: 'status',
        list: getTupleStatusList(false),
        onFilterChange,
        filter,
      }),
      // ...getColumnSelectSearchProps('status', getActionData()),
    },
    {
      dataIndex: 'action',
      title: 'Action',
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => {
        return <Action row={row} cell={cell} />;
      },
    },
  ];
};

export const couponManagementService = () => {
  return [
    {
      id: 1,
      couponCode: 'disk',
      discount: 'Percent',
      validity: '2',
      maximumUse: '1',
      startDate: '04/21/2022',
      endDate: '04/27/2022',
    },
    {
      id: 2,
      couponCode: 'flat100',
      discount: 'Flat',
      validity: '3',
      maximumUse: '5',
      startDate: '04/21/2022',
      endDate: '04/27/2022',
    },
  ];
};
