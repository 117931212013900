import i18next from 'i18next';
import {
  getInputSearchFilter,
  getMultiSelectSearchFilter,
} from '../../components';
import {
  checkValidData,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { accessRoute } from '../../routes/Dashboard';
import { getTupleStatusList } from '../index.service';

export const industryTableColumn = ({
  Action,
  onFilterChange,
  filter,
  Toggle,
  page,
  sizePerPage,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'name',
      title: i18next.t('text.industry.heading'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'name',
        placeholder: i18next.t('text.industry.industryName'),
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'status',
      title: i18next.t('text.common.status'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => {
        return <Toggle row={row} cell={cell} />;
      },
      ...getMultiSelectSearchFilter({
        dataIndex: 'status',
        onFilterChange,
        name: 'status',
        list: [],
        mode: false,
        filter,
        listService: getTupleStatusList,
      }),
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.common.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => {
        return <Action row={row} cell={cell} />;
      },
    },
  ];
};
export const industryBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },

    {
      path: '#',
      name: i18next.t('text.industry.heading'),
    },
  ];
};
