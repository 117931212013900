export const text = {
  titleGMB: 'Google My Business',
  business: 'Business',
  totalReview: 'Total Review',
  googleRating: 'Google Rating',
  reviewsToRespondTo: 'Reviews To Respond To',
  verified: 'Verified',
  seeYourProfile: 'See Your Profile',
  photos: 'Photos',
  post: 'Post',
  notification: 'Notification',
  googleAdwordsList: 'Google Adwords List',
  click: 'Click',
  avgCost: 'Avg. Cost',
  cost: 'Cost',
  impressions: 'Impressions',
  budget: 'Budget',
  conversionValue: 'Conversion Value',
  sPInControl: 'SP in control',
  enterBudget: 'Enter budget',
  actualDaily: 'Actual daily spend may vary',
  titleGoogleService: 'Google Guaranteed List',
  googleLocalServiceManagement: 'Google Local Service Management',
  viewLeads: 'View Leads',
  weeklyBudget: 'Average Weekly Budget',
  averageFiveStarRating: 'Average Five Star Rating',
  currentPeriodChargedLeads: 'Current Period Charged Leads ',
  currentPeriod: 'Current Period Connected Phone Calls ',
  currentPeriodPhone: 'Current Period Phone Calls ',
  currentPeriodTotal: 'Current Period Total Cost ',
  impressionsLastTwoDays: 'Impressions Last Two Days',
  phoneLeadRes: 'Phone Lead Responsiveness',
  previousPeriodCharged: 'Previous Period Charged Leads',
  previousPeriodConnectedPhoneCalls: 'Previous Period Connected Phone Calls ',
  previousPeriodPhoneCalls: 'Previous Period Phone Calls',
  previousPeriodTotalCost: 'Previous Period Total Cost',
  new: 'New',
  active: 'Active',
  booked: 'Booked',
  completed: 'Completed',
  archive: 'Archive',
  biddingMode: 'Bidding mode',
  currentBalance: 'Current Balance',
  spInControl: 'SP in control',
  leadId: 'Lead Id',
  leadType: 'Lead Type',
  customerPhone: 'Customer Phone',
  chargeStatus: 'Charge Status',
  geoLocation: 'Geo Location',
  leadCategory: 'Lead Category',
  DateAndTime: 'Date/Time',
  googleMediaList: 'Google Media List',
  postPhoto: 'Post Photo',
  googlePostList: 'Google Post List',
  areYouSure: 'Are you sure ?',
  createGooglePost: 'Create  Google Post',
  addPhoto: 'Add Photo',
  writeYourPost: 'Write your post',
  enterWriteYourPost: 'Enter write your post',
  moreURL: 'Want to add learn more URL?',
  LearnMoreURL: 'Learn More URL',
  enterLearnMoreURL: 'Enter learn more URL',
  publish: 'Publish',
  googleReviewList: 'Google Review List',
  showCustomerYouCare: 'Show customers you care, one response at a time',
  ourCustomersNowGet:
    'Your customers now get notified when you reply to their review',
  customerPost: 'Customer Post',
  admin: 'Admin',
  AdminReply: 'Admin Reply',
  yourReply: 'Your Reply',
  next: 'Next',
  replyTextPlaceHolder: 'Enter your reply',
  postReply: 'Post Reply',
  reply: 'Reply',
  noImageFound: 'No Image Found',
  learnMore: 'Learn more',
  createPost: 'Create Post',
  onDeleteReplySuccess: 'Reply Deleted Successfully',
  onDeleteConfirm: 'you want to delete this reply?',
  onPostDeleteConfirm: 'you want to delete this post?',
  deletePostMessage: 'Post Delete Successfully',
  editBudget: 'Edit Budget',
  id: 'ID',
  mediaTitle:'Add Google Media'
};
