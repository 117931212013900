import { Button, Select, Skeleton, Space } from 'antd';
import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterFilled } from '@ant-design/icons';
import { capitalizeFirstLetter, removeDuplicates } from '../../../utils';

const { Option } = Select;

function MultiSelectFilter({
  name,
  onFilterChange,
  listKeys = { nameKey: 'name', nameId: 'id', useKey: 'phoneCode' },
  extraQuary,
  scope,
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  dataIndex,
  filter = {},
  listService,
  mode = 'multiple',
  addAll = false,
  showSearch = true,
  innerKey = '',
  options,
  type = 'all',
  searchOrNot = false,
  defaultFilter = false,
  addExtraObj = false,
}) {
  const { t } = useTranslation();
  const searchInput = useRef(null);
  const [state, setState] = useState({
    data: [],
    loading: false,
    isExtraLoader: false,
  });
  const { data, loading, isExtraLoader } = state;
  const [search, setSearch] = useState();
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    if (listService && searchOrNot) {
      const handler = setTimeout(() => {
        if (search?.length > 2 || search?.length === 0) {
          getList(listService, false, false, addExtraObj?.id);
        } else if (search === false) {
          getList(listService);
        }
      }, 1000);
      return () => {
        clearTimeout(handler);
      };
    } else {
      if (searchOrNot) {
        setState({ ...state, data: [] });
      }
    }
  }, [search]);

  useEffect(() => {
    if (searchOrNot) {
      if (search?.length > 2) {
        getList(listService);
      }
    } else {
      getList(listService);
    }
  }, []);

  useEffect(() => {
    if (options?.length) {
      setState({ ...state, data: options });
    }
  }, [options]);

  useEffect(() => {
    if (
      Object.keys(filter).length === 0 &&
      clearFilters &&
      confirm &&
      (Array.isArray(selectedKeys) ? selectedKeys.length > 0 : !!selectedKeys)
    ) {
      setSearch(false);
      clearFilters();
      confirm();
    }
  }, [filter]);

  const handleSearch = async (selectedKeys, confirm, dataIndex) => {
    try {
      await onFilterChange({
        [dataIndex]:
          mode === 'multiple'
            ? selectedKeys
            : Array.isArray(selectedKeys)
            ? [...selectedKeys].shift()
            : typeof selectedKeys === 'number'
            ? selectedKeys.toString()
            : selectedKeys,
      });

      confirm();
    } catch (err) {
      console.log(err);
    }
  };

  const handleReset = async (clearFilters, confirm) => {
    try {
      await onFilterChange({ [dataIndex]: null });
      setSearch(false);
      clearFilters();
      confirm();
    } catch (err) {
      console.log(err);
    }
  };

  async function getList(listService, defaultFilter, limit, isLoader) {
    try {
      setState({
        ...state,
        loading: true,
        isExtraLoader: isLoader ? false : true,
      });

      const res = await listService({
        type: type,
        queryParams: {
          ...limit,
          ...extraQuary,
          ...(scope && { scope: scope }),
          search: search ? search : defaultFilter,
        },
      });
      let list = [];
      if (Array.isArray(res)) {
        list = [
          ...res.map((e) => ({
            name: innerKey
              ? e[innerKey][listKeys?.nameKey]
              : typeof listKeys?.nameKey === 'string'
              ? e[listKeys?.nameKey]
              : listKeys?.nameKey
                  ?.map((listItem) =>
                    e?.[listItem] ? capitalizeFirstLetter(e?.[listItem]) : ''
                  )
                  ?.join(' ')
                  ?.trim(),
            id: innerKey ? e[innerKey][listKeys?.nameId] : e[listKeys?.nameId],
            useKey: e[listKeys?.useKey],
          })),
        ];
      }
      if (addAll) {
        list = [
          {
            name: 'All',
            id: 'all',
            useKey: 'all',
          },
          ...list,
        ];
      }
      if (addExtraObj) {
        list = removeDuplicates([addExtraObj, ...list], false, 'no');
      }

      setState({
        ...state,
        data: [...list],
        loading: false,
        isExtraLoader: false,
      });
    } catch (err) {
      setState({ ...state, loading: false, isExtraLoader: false });
      console.log(err);
    }
  }
  function onChange(event) {
    // setSelectedKeys(event);
    setSelectedKeys(mode === 'multiple' ? event : [event]);
  }

  function onSearch(e) {
    if (e?.length > 2 && searchOrNot) {
      setSearch(e);
    }
  }

  let apiLimit = limit;
  const handleScroll = (e) => {
    const { target } = e;

    if (searchOrNot) {
      if (
        Math.ceil(target?.scrollTop + target?.offsetHeight) ===
        target?.scrollHeight
      ) {
        apiLimit = apiLimit + 10;

        getList(
          listService,
          undefined,
          {
            limit: apiLimit,
            offset: 0,
          },
          addExtraObj?.id
        );
      }
    }
    setLimit(apiLimit);
  };

  // function handleVisibleChange(e) {
  //   console.log('run');
  // }

  useEffect(() => {
    if (defaultFilter && searchOrNot) {
      getList(listService, defaultFilter);
    } else {
      getList(listService, undefined, { limit: 10, offset: 0 });
    }
    // return () => {
    //   handleVisibleChange();
    // };
  }, [defaultFilter]);

  return (
    <div
      style={{ position: 'relative', padding: 8 }}
      className={`selectWrapper ${addExtraObj?.id ? 'wid-200' : ''}`}
      onKeyDown={(e) => e.stopPropagation()}
    >
      {(defaultFilter && searchOrNot && loading) ||
      (isExtraLoader && addExtraObj?.id) ? (
        <Skeleton paragraph={{ rows: 0 }} active />
      ) : (
        <>
          <Select
            showSearch={showSearch}
            ref={searchInput}
            placeholder={`Select ${name}`}
            value={selectedKeys}
            onChange={onChange}
            filterOption={(input, option) => {
              return (option?.children ?? '')
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            mode={mode}
            loading={loading}
            style={{
              marginBottom: 8,
              display: 'block',
            }}
            showArrow
            onSearch={(e) => onSearch(e)}
            onPopupScroll={handleScroll}
            // onVisibleChange={handleVisibleChange}
          >
            {data.map(
              (item, key) =>
                item.name && (
                  <Option
                    key={key}
                    value={item?.value || String(item?.id)}
                    disabled={item?.disabled || false}
                    label={item?.name}
                  >
                    {item.name}
                  </Option>
                )
            )}
          </Select>
          <Space>
            <Button
              type="primary"
              className="btn btn-primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{
                width: 90,
              }}
            >
              {t('text.common.search')}
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters, confirm)}
              className="btn btn-info"
              style={{
                width: 90,
              }}
            >
              {t('text.common.reset')}
            </Button>
          </Space>
        </>
      )}
    </div>
  );
}

export default MultiSelectFilter;

export function getMultiSelectSearchFilter({
  dataIndex,
  list,
  name,
  mode,
  filter,
  showSearch,
  searchOrNot,
  defaultFilter,
  addExtraObj,
  ...rest
}) {
  return {
    filterDropdown: (props) => (
      <MultiSelectFilter
        name={name}
        dataIndex={dataIndex}
        list={list}
        filter={filter}
        mode={mode}
        showSearch={showSearch}
        searchOrNot={searchOrNot}
        defaultFilter={defaultFilter}
        addExtraObj={addExtraObj}
        {...props}
        {...rest}
      />
    ),
    filterIcon: (filtered) => (
      <>
        <FilterFilled
          type="search"
          style={{ color: filtered ? '#F47721' : undefined }}
        />
      </>
    ),
    onFilterDropdownVisibleChange: () => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  };
}
